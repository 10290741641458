export const PARTNER_FORM_STEPS = {
  BASIC_INFO: 'basicInformation',
  PARTNER_SUMMARY: 'partnerSummary',
  PARTNER_PROFILE: 'partnerProfile',
  CONTACTS: 'contacts',
  PARTNER_SERVICES: 'partnerServices',
  DECLARATIONS: 'declarations',
  FINAL_SECTION: 'final',
};

export const COMPANY_FORM_STEPS = {
  BASIC_INFO: 'basicInformation',
  COMPANY_SUMMARY: 'companySummary',
  COMPANY_PROFILE: 'companyProfile',
  DIRECTORS: 'directors',
  FUNDAMENTALS: 'fundamentals',
  DECLARATIONS: 'declarations',
  FINAL_SECTION: 'final',
};

export const financialsKeys = [
  'lastFiscalYear',
  'revenue',
  'ebitda',
  'ebitdaPerc',
  'ebit',
  'ebitPerc',
  'netIncome',
  'netDebt',
  'netEquity',
  'capitalEmployed',
  'eMargin',
  'totalAssets',
  'nfpEbitda',
  'cashEquivalent',
  'commercialCredit',
  'currentLiabilities',
];
