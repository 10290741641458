import {
  GET_PEERS_START,
  GET_PEERS_ERROR,
  GET_PEERS_SUCCESS,
  CLEAR_PEERS,
  GET_REFERRERS_REQUESTED,
  GET_REFERRERS_SUCCEEDED,
  GET_REFERRERS_FAILED,
  ADD_REFERRERS_REQUESTED,
  ADD_REFERRERS_SUCCEEDED,
  ADD_REFERRERS_FAILED,
  CALL_REFERRER_REQUESTED,
  CALL_REFERRER_SUCCEEDED,
  CALL_REFERRER_FAILED,
  GET_PEERS_LIST_ERROR,
  GET_PEERS_LIST_START,
  GET_PEERS_LIST_SUCCESS,
} from '@src/js/actions/user/users';

export const initialState = {
  loadingPeers: false,
  errorPeers: null,
  peers: [],
  hasMorePeers: true,
  referrersList: null,
  referrersLoading: false,
  addReferrers: {
    loading: false,
    error: null,
    added: false,
  },
  callReferrer: {
    loading: false,
    error: null,
    callSent: false,
  },
  peersList: [],
  peersListHasMore: true,
  peersListLoading: false,
  peersListError: null,
};

const actionsMap = {
  [GET_PEERS_START]: (state) => ({
    ...state,
    loadingPeers: true,
    errorPeers: null,
    peers: [],
  }),
  [GET_PEERS_ERROR]: (state, action) => ({
    ...state,
    loadingPeers: false,
    errorPeers: action.error.message,
  }),
  [GET_PEERS_SUCCESS]: (state, action) => ({
    ...state,
    loadingPeers: false,
    peers: action.data,
    hasMorePeers: (action.data) ? action.data.length > 0 : false,
  }),
  [CLEAR_PEERS]: (state) => ({
    ...state,
    loadingPeers: false,
    error: null,
    peers: null,
    hasMorePeers: true,

    addReferrers: {
      loading: false,
      error: null,
      added: false,
    },
  }),
  [GET_REFERRERS_REQUESTED]: (state) => ({
    ...state,
    referrersList: null,
    referrersLoading: true,
  }),
  [GET_REFERRERS_SUCCEEDED]: (state, action) => ({
    ...state,
    referrersList: action.payload,
    referrersLoading: false,
  }),
  [GET_REFERRERS_FAILED]: (state) => ({
    ...state,
    referrersList: null,
    referrersLoading: false,
  }),
  [ADD_REFERRERS_REQUESTED]: (state) => ({
    ...state,
    addReferrers: {
      ...state.addReferrers,
      loading: true,
    },
  }),
  [ADD_REFERRERS_SUCCEEDED]: (state) => ({
    ...state,
    addReferrers: {
      loading: false,
      error: null,
      added: true,
    },
  }),
  [ADD_REFERRERS_FAILED]: (state, action) => ({
    ...state,
    addReferrers: {
      loading: false,
      error: action.payload,
      added: false,
    },
  }),
  [CALL_REFERRER_REQUESTED]: (state) => ({
    ...state,
    callReferrer: {
      ...state.callReferrer,
      loading: true,
    },
  }),
  [CALL_REFERRER_SUCCEEDED]: (state) => ({
    ...state,
    callReferrer: {
      loading: false,
      error: null,
      callSent: true,
    },
  }),
  [CALL_REFERRER_FAILED]: (state, action) => ({
    ...state,
    callReferrer: {
      loading: false,
      error: action.payload,
      callSent: false,
    },
  }),
  [GET_PEERS_LIST_START]: (state, action) => ({
    ...state,
    peersList: action.reset ? [] : state.peersList,
    peersListHasMore: true,
    peersListLoading: true,
    peersListError: null,
  }),
  [GET_PEERS_LIST_ERROR]: (state, action) => ({
    ...state,
    peersList: [],
    peersListHasMore: false,
    peersListLoading: false,
    peersListError: action.error.message,
  }),
  [GET_PEERS_LIST_SUCCESS]: (state, action) => {
    const peers = [...state.peersList];
    return {
      ...state,
      peersList: action.reset ? action.data : [...peers, ...action.data],
      peersListHasMore: action.data ? action.data.length > 0 : false,
      peersListLoading: false,
      peersListError: null,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
