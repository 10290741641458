import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { purifyHtml } from '@src/js/utils';
import { injectIntl } from 'react-intl';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import {
  Caption, Content, Image, PaddedRow,
} from './styled';
import OnboardingHeader from './components/OnboardingHeader';

class OnboardingSimpleTextPage extends Component {
  render() {
    const {
      translation, caption, image, content, headerText, pageTitle, intl,
    } = this.props;

    return (
      <>
        <MetaTitle title={ pageTitle } />
        <OnboardingHeader caption={ headerText } />

        <PaddedRow>
          <Col>
            { caption && (
            <Caption
              className='blue-a'
              dangerouslySetInnerHTML={
                { __html: purifyHtml(translation?.[caption] || intl.formatMessage({ id: caption })) }
              }
            />
            ) }
            { image && (
              <Image>
                <img src={ image } alt='Message Sent' />
              </Image>
            ) }
            { content && (
            <Content
              className='blue-a'
              dangerouslySetInnerHTML={
                { __html: purifyHtml(translation?.[content] || intl.formatMessage({ id: content })) }
              }
            />
            ) }
          </Col>
        </PaddedRow>
      </>
    );
  }
}

OnboardingSimpleTextPage.propTypes = {
  intl: PropTypes.object,
  translation: PropTypes.object,
  caption: PropTypes.string,
  image: PropTypes.string,
  content: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default injectIntl(withRouter(OnboardingSimpleTextPage));
