import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DataRoomPermissions } from '@src/js/constants/entities';
import { injectIntl } from 'react-intl';

const PermissionsItem = ({ user, grantPermissions, intl }) => {
  const [permissions, setCurrentPermissions] = useState(user.permission);

  const grant = (permission) => {
    if (user.permission === DataRoomPermissions.addUser) return;
    setCurrentPermissions(permission);
    grantPermissions(user.id, permission);
  };

  const cursor = permissions >= DataRoomPermissions.addUser
    ? 'peer-item-button cursor-blocked'
    : 'peer-item-button cursor-pointer';

  return (
    <div className='peer-item cursor-default'>
      <div className='peer-item-logo'>
        <img src={ user.avatar } alt='avatar' />
      </div>
      <div className='peer-item-subject'>
        <div
          className='peer-item-subject--bold'
        >
          { user.fullName }
        </div>
        <div className='peer-item-subject__info'>
          <span className='peer-item-subject__info--bold'>
            { user.jobTitle }
          </span>
          { user.company && <span>{ `, ${ user.company }` }</span> }
        </div>

      </div>
      <div className={ cursor } title={ intl.formatMessage({ id: 'denied' }) }>
        <span
          className={ `icon ${ permissions === DataRoomPermissions.denied ? 'icon__selected' : '' } icomoon-blocked` }
          onClick={ () => grant(DataRoomPermissions.denied) }
          role='presentation'
        />
      </div>
      <div className={ cursor } title={ intl.formatMessage({ id: 'view' }) }>
        <span
          className={ `icon ${ permissions >= DataRoomPermissions.view ? 'icon__selected' : '' } icomoon-eye` }
          onClick={ () => grant(DataRoomPermissions.view) }
          role='presentation'
        />
      </div>
      <div className={ cursor } title={ intl.formatMessage({ id: 'watermark' }) }>
        <span
          className={ `icon ${ permissions >= DataRoomPermissions.watermark ? 'icon__selected' : '' } icomoon-key` }
          onClick={ () => grant(DataRoomPermissions.watermark) }
          role='presentation'
        />
      </div>
      <div className={ cursor } title={ intl.formatMessage({ id: 'download' }) }>
        <span
          className={ `icon  ${ permissions >= DataRoomPermissions.download ? 'icon__selected' : '' } icon-Download` }
          onClick={ () => grant(DataRoomPermissions.download) }
          role='presentation'
        />
      </div>
      <div className={ cursor } title={ intl.formatMessage({ id: 'edit' }) }>
        <span
          className={ `icon ${ permissions >= DataRoomPermissions.edit ? 'icon__selected' : '' } icomoon-pencil` }
          onClick={ () => grant(DataRoomPermissions.edit) }
          role='presentation'
        />
      </div>
    </div>
  );
};

PermissionsItem.propTypes = {
  user: PropTypes.object.isRequired,
  grantPermissions: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(PermissionsItem);
