import fetchResource from '@src/js/api/fetch-resource';

export function deleteSingleSession(session) {
  return fetchResource('api/web/session/destroy', {
    method: 'DELETE',
    body: session,
  });
}

export function deleteAllSessions() {
  return fetchResource('api/web/session/destroy/all', {
    method: 'DELETE',
  });
}
