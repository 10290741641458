export const COMPANY_DOCUMENTS_DELETE_START = 'COMPANY_DOCUMENTS_DELETE_START';
export const COMPANY_DOCUMENTS_DELETE_ERROR = 'COMPANY_DOCUMENTS_DELETE_ERROR';
export const COMPANY_DOCUMENTS_DELETE_SUCCESS = 'COMPANY_DOCUMENTS_DELETE_SUCCESS';

export function companyDocumentsDelete(fileSlug, slug) {
  return {
    type: COMPANY_DOCUMENTS_DELETE_START,
    fileSlug,
    slug,
  };
}
