import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@src/_v2/js/components/buttons/Button/Button';
import { Container, FormError } from './styled';

const FormSubmit = (props) => {
  const [hasErrors, setHasErrors] = useState(false);
  const { form, redirectFunc, setLanguageCode } = props;

  useEffect(() => {
    setHasErrors(!form.getState().valid && form.getState().submitFailed);
  }, [form]);

  const setDefaultLang = () => {
    const errors = form.getState().errors;
    if (errors?.details?.en?.description) {
      setLanguageCode('en');
    }
  };

  return (
    <Container>
      <Button
        className='m-l-0'
        buttonType='submit'
        text='save'
        disabled={ form.getState().submitting }
        onClick={ setDefaultLang }
      />
      <Button
        text='cancel'
        className='m-r-0'
        blank
        onClick={ () => redirectFunc(true) }
      />
      { hasErrors && (
        <FormError>
          <FormattedMessage id='error.form.notValid' />
        </FormError>
      ) }
    </Container>
  );
};

FormSubmit.propTypes = {
  form: PropTypes.object,
  redirectFunc: PropTypes.func,
  setLanguageCode: PropTypes.func,
};

export default FormSubmit;
