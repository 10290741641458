import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@src/js/components/global';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { getTourId } from '@src/js/helpers/helpers';
import { useIntercom } from 'react-use-intercom';

const ConfirmData = () => {
  const userData = selectUserInfo();
  const { clearModalQueue, hideModal } = useGlobalModalContext();
  const tourId = getTourId();
  const { startTour } = useIntercom();

  const history = useHistory();
  const goToMyProfile = () => {
    history.push(withLang(routeCodes.MY_PROFILE));
    clearModalQueue();
  };

  const cancel = () => {
    if (tourId) startTour(tourId);
    hideModal();
  };

  return (
    <>
      <ModalBody className='d-flex justify-content-center m-t-2'>
        <div className='font-size-body'>
          <p>
            <FormattedMessage id='user.email' />
            {': '}
            <span className='font-size-body font-weight-bold'>{ userData.email }</span>
          </p>
          <p>
            <FormattedMessage id='user.jobTitle' />
            {': '}
            <span className='font-size-body font-weight-bold'>{ userData.jobRoleName }</span>
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='dialog.confirm'
          onClickFunc={ cancel }
        />
        <Button
          size='lg'
          buttonText='edit'
          onClickFunc={ goToMyProfile }
        />
      </ModalFooter>
    </>
  );
};

export default ConfirmData;
