import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import { acceptExtensions } from '@src/js/constants/acceptExtensions';

/* Options value:
  - roundedButtonType: true
  - textButtonType: true
  - size: lg, sm
  - emptyButtonType: empty-grey, empty-blue
  - additionalClass: string of any required classes
  - buttonType: button, submit, link (href attribute is required)
  - icon: icon class */

export default class Button extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    buttonType: PropTypes.string,
    size: PropTypes.string,
    customClass: PropTypes.string,
    icon: PropTypes.string,
    iconPositionLeft: PropTypes.bool,
    roundedButtonType: PropTypes.bool,
    emptyButtonType: PropTypes.string,
    textButtonType: PropTypes.bool,
    additionalClass: PropTypes.string,
    onClickFunc: PropTypes.func,
    downArrow: PropTypes.bool,
    disabled: PropTypes.bool,
    shouldTranslateText: PropTypes.bool,
    isLinkTargetBlank: PropTypes.bool,
    isLocked: PropTypes.bool,
    href: (props, propName, componentName) => {
      if (props.buttonType === 'link' && !props[propName]) {
        return new Error(
          `Invalid prop ${ propName } supplied to ${ componentName }. Validation failed.`
        );
      }
      return null;
    },
  };

  static defaultProps = {
    buttonType: 'button',
    shouldTranslateText: true,
    customClass: '',
    iconPositionLeft: true,
  };

  setButtonClass() {
    const {
      roundedButtonType,
      size,
      emptyButtonType,
      textButtonType,
      additionalClass,
      disabled,
      customClass,
    } = this.props;

    if (customClass) {
      return customClass;
    }

    let className = 'eop-btn';

    className = roundedButtonType ? `${ className } eop-btn-rounded` : className;
    className = size ? `${ className } eop-btn-${ size }` : className;
    className = emptyButtonType ? `${ className } eop-btn-${ emptyButtonType }` : className;
    className = textButtonType ? `${ className } eop-btn-text` : className;
    className = disabled ? `${ className } eop-btn-disabled eop-btn--disabled` : className;
    className = additionalClass ? `${ className } ${ additionalClass }` : className;

    return className;
  }

  render() {
    const {
      buttonType,
      buttonText,
      href,
      icon,
      onClickFunc,
      disabled,
      downArrow,
      shouldTranslateText,
      isLinkTargetBlank,
      iconPositionLeft,
      isLocked,
    } = this.props;

    const target = isLinkTargetBlank ? '_blank' : '';
    const rel = isLinkTargetBlank ? 'noopener noreferrer' : '';
    if (buttonType === 'link') {
      return (
        <a
          className={ this.setButtonClass() }
          href={ href }
          target={ target }
          rel={ rel }
        >
          <span>
            { icon && <span className={ `button-icon ${ icon }` } /> }
            {
              shouldTranslateText
                ? (
                  <FormattedMessage id={ buttonText } />
                ) : (
                  buttonText
                )
            }
          </span>
        </a>
      );
    }

    if (buttonType === 'input-documentsArea') {
      return (
        <div
          className={ this.setButtonClass() }
        >
          <label
            htmlFor='eop-dataroom-upload-button'
            className='eop-dataroom-upload-btn-label button-text cursor-pointer'
          >
            {
              shouldTranslateText
                ? <FormattedMessage id={ buttonText } />
                : buttonText
            }
          </label>
          <input
            onChange={ onClickFunc }
            type='file'
            id='eop-dataroom-upload-button'
            name='eop-dataroom-upload-button'
            accept={ acceptExtensions.DATAROOM_MIMETYPE }
            value=''
            className='d-none'
          />
        </div>
      );
    }

    if (buttonType === 'input-company-documents') {
      return (
        <div
          className='input-documents'
        >
          <label htmlFor='eop-documents-upload-button' className='eop-dataroom-upload-btn-label'>
            <span className='icon-medium-Upload'>
              {
                shouldTranslateText
                  ? (
                    <div className='button-text'><FormattedMessage id={ buttonText } /></div>
                  ) : (
                    buttonText
                  )
              }
            </span>
          </label>
          <input
            onChange={ onClickFunc }
            id='eop-documents-upload-button'
            name='eop-documents-upload-button'
            type='file'
            accept={ acceptExtensions.MEDIA_AND_DOCUMENTS_MIMETYPE }
          />
        </div>
      );
    }

    if (buttonType === 'input-documents') {
      return (
        <button
          type={ buttonType }
          className='input-documents eop-btn p-0'
          onClick={ onClickFunc }
          disabled={ disabled && 'disabled' }
        >
          {
            shouldTranslateText
              ? (
                <FormattedMessage id={ buttonText } />
              ) : (
                buttonText
              )
          }
        </button>
      );
    }

    return (
      <button
        type={ buttonType }
        className={ this.setButtonClass() }
        onClick={ onClickFunc }
        disabled={ disabled && 'disabled' }
      >
        <span className='button-text'>
          { iconPositionLeft && icon && <span className={ `button-icon ${ icon }` } /> }
          {
            shouldTranslateText
              ? (
                <FormattedMessage id={ buttonText } />
              ) : (
                buttonText
              )
          }
          { !iconPositionLeft && icon && <span className={ `button-icon__right ${ icon }` } /> }
          { downArrow && <i className='downArrow' /> }
          { isLocked && (<span className='eop-text-input-input-icon icon-LockerSmall m-l-1' />) }
        </span>
      </button>
    );
  }
}
