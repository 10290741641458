import React from 'react';
import { ModalFooter } from 'reactstrap';

import { Button } from '@src/js/components/global';
import { makeSelectUser, makeSelectRequestSent } from '@app/store/etinerary/selectors/index';
import { useShallowSelector } from '@src/js/utils/index';
import { Company } from '@src/js/constants/entities';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';

const Footer = () => {
  const user = useShallowSelector(makeSelectUser);
  const requestSent = useShallowSelector(makeSelectRequestSent);
  const enableUpgradeModal = user?.etineraryStatus === Company.ETINERARY_STATUS_NOREQUEST && !requestSent;
  const alreadySubmitted = user?.etineraryStatus === Company.ETINERARY_STATUS_PENDING
    || user?.etineraryStatus === Company.ETINERARY_STATUS_REJECTED
    || requestSent;

  const { showModal } = useGlobalModalContext();

  const handleModalRegistration = () => {
    if (enableUpgradeModal) {
      showModal({
        type: ModalTypes.Upgrade,
        title: 'etinerary.upgradeModal.title',
        config: { size: 'lg' },
        replace: true,
      });
    }
  };
  return (
    <ModalFooter className='etinerary-modal-footer'>
      <Button
        size='lg'
        buttonText={ alreadySubmitted ? 'etinerary.modal.buttonInvited' : 'etinerary.modal.button' }
        shouldTranslateText={ true }
        onClickFunc={ handleModalRegistration }
        disabled={ alreadySubmitted }
      />
    </ModalFooter>
  );
};

export default Footer;
