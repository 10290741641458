import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ONBOARDING_INIT_START,
  ONBOARDING_INIT_ERROR,
  ONBOARDING_INIT_SUCCESS,
  LOGIN_ONBOARDING_USER_ERROR,
  LOGIN_ONBOARDING_USER_START,
  LOGIN_ONBOARDING_USER_SUCCESS,
  LOGOUT_ONBOARDING_USER_ERROR,
  LOGOUT_ONBOARDING_USER_START,
  LOGOUT_ONBOARDING_USER_SUCCESS,
} from '@src/js/actions/onboarding/onboardingBasic';

import * as api from '@src/js/api/onboarding/onboardingBasic';

function* onboardingInit() {
  try {
    const data = yield call(() => api.onboardingInit());
    yield put({ type: ONBOARDING_INIT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ONBOARDING_INIT_ERROR, error });
  }
}

function* loginOnboardingUser(options) {
  try {
    const data = yield call(() => api.loginOnboardingUser(options.credentialsData));
    yield put({ type: LOGIN_ONBOARDING_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: LOGIN_ONBOARDING_USER_ERROR, error });
  }
}

function* logoutOnboardingUser() {
  try {
    const data = yield call(() => api.logoutOnboardingUser());
    yield put({ type: LOGOUT_ONBOARDING_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: LOGOUT_ONBOARDING_USER_ERROR, error });
  }
}

export function* loginOnboardingUserWatcher() {
  yield takeLatest(LOGIN_ONBOARDING_USER_START, loginOnboardingUser);
}

export function* onboardingInitWatcher() {
  yield takeLatest(ONBOARDING_INIT_START, onboardingInit);
}

export function* logoutOnboardingUserWatcher() {
  yield takeLatest(LOGOUT_ONBOARDING_USER_START, logoutOnboardingUser);
}

export default [
  loginOnboardingUserWatcher(),
  onboardingInitWatcher(),
  logoutOnboardingUserWatcher(),
];
