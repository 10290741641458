import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import * as api from '@app/store/compliance/api';
import { Compliance } from '@src/js/constants/entities';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  UPDATE_DATA_START,
  UPDATE_DATA_FAIL,
  UPDATE_DATA_SUCCESS,
  GET_LIST_START,
  GET_LIST_FAIL,
  GET_LIST_SUCCESS,
  SIGN_START,
  SIGN_FAIL,
  SIGN_SUCCESS,
  APPROVE_START,
  APPROVE_FAIL,
  APPROVE_SUCCESS, CLEAR_SIGN_SUCCESS, CLEAR_SIGN_START, CLEAR_SIGN_FAIL,
  SET_ACTIVE_STEP,
  STATUS_START, STATUS_FAIL, STATUS_SUCCESS,
  MEMBERS_START, MEMBERS_FAIL, MEMBERS_SUCCESS,
  CREATE_FAIL, CREATE_START, CREATE_SUCCESS,
  FUNDING_OPTION_FAIL, FUNDING_OPTION_START, FUNDING_OPTION_SUCCESS,
} from '@app/store/compliance/actions';

function* getCompliance({ slug, isAdmin }) {
  try {
    const data = yield call(() => api.getCompliance(slug, isAdmin));
    if (data.status === Compliance.NEW) {
      yield all([
        put({ type: GET_DATA_SUCCESS, data }),
        put({
          type: UPDATE_DATA_START, slug, data, isAdmin: false,
        }),
      ]);
    } else {
      yield put({ type: GET_DATA_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: GET_DATA_FAIL, error });
  }
}

function* updateCompliance({
  slug, data, message, isAdmin, params, onSave,
}) {
  try {
    const result = yield call(() => api.updateCompliance(slug, data, isAdmin, params));
    if (onSave.action === 'stepChange' && onSave.newStep) {
      yield all([
        put({ type: UPDATE_DATA_SUCCESS, validation: result, data }),
        put({ type: SET_ACTIVE_STEP, step: onSave.newStep }),
      ]);
    } else {
      yield put({ type: UPDATE_DATA_SUCCESS, validation: result, data });
      if (onSave.action === 'save' || onSave.action === 'submit') {
        yield put(showToastrSuccess(message.successTitle, message.successMsg, false));
      }
    }
  } catch (error) {
    yield put({ type: UPDATE_DATA_FAIL, error });
    if (message) {
      yield put(showToastrError(message.errorTitle, message.errorMsg, false));
    }
  }
}

function* getComplianceList({ filter }) {
  try {
    const data = yield call(() => api.getComplianceList(filter));
    yield put({ type: GET_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_LIST_FAIL, error });
  }
}

function* signCompliance({ slug, tableKey, section }) {
  try {
    const result = yield call(() => api.signCompliance(slug, tableKey));
    yield put({
      type: SIGN_SUCCESS,
      data: result,
      section,
      tableKey,
    });
  } catch (error) {
    yield put({ type: SIGN_FAIL, error });
  }
}

function* clearSignCompliance({ signId, tableKey, section }) {
  try {
    yield call(() => api.clearSignCompliance(signId));
    yield put({
      type: CLEAR_SIGN_SUCCESS,
      section,
      tableKey,
    });
  } catch (error) {
    yield put({ type: CLEAR_SIGN_FAIL, error });
  }
}

function* approveCompliance({ slug, state }) {
  try {
    yield call(() => api.approveCompliance(slug, state));
    yield put({
      type: APPROVE_SUCCESS, slug, state,
    });
  } catch (error) {
    yield put({ type: APPROVE_FAIL, error });
    yield put(showToastrError());
  }
}

function* getComplianceStatus() {
  try {
    const data = yield call(() => api.getComplianceStatus());
    yield put({ type: STATUS_SUCCESS, data });
  } catch (error) {
    yield put({ type: STATUS_FAIL, error });
  }
}

function* getMembers({ isAdmin, slug }) {
  try {
    const data = yield call(() => api.getMembers(isAdmin, slug));
    yield put({ type: MEMBERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: MEMBERS_FAIL, error });
  }
}

function* createCompliance({
  slug, fundingOption, desiredAmount, method,
}) {
  try {
    const data = yield call(() => api.createCompliance(
      slug, { fundingOption, desiredAmount: Number(desiredAmount) }, method
    ));
    yield put({ type: CREATE_SUCCESS, data });
  } catch (error) {
    yield all([
      put({ type: CREATE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* fundingOptionAction({ data }) {
  try {
    const result = yield call(() => api.fundingOption(data));
    yield put({ type: FUNDING_OPTION_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: FUNDING_OPTION_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_DATA_START, getCompliance);
  yield takeLatest(UPDATE_DATA_START, updateCompliance);
  yield takeLatest(GET_LIST_START, getComplianceList);
  yield takeLatest(SIGN_START, signCompliance);
  yield takeLatest(CLEAR_SIGN_START, clearSignCompliance);
  yield takeLatest(APPROVE_START, approveCompliance);
  yield takeLatest(STATUS_START, getComplianceStatus);
  yield takeLatest(MEMBERS_START, getMembers);
  yield takeLatest(CREATE_START, createCompliance);
  yield takeLatest(FUNDING_OPTION_START, fundingOptionAction);
}

export default root;
