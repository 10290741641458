import { ItemObj } from '@app/components/global/forms/select/select';
import ConfirmPasswordModal from '@app/components/public/user/ConfirmPasswordModal';
import { userTabs } from '@app/components/public/user/Tabs';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import { hideNavigationAction, showNavigationAction } from '@src/js/actions/navigation/navigation';
import { adminCreateUser, adminEditUser, adminGetUser } from '@src/js/actions/user/adminUser';
import { getUserFormOptions } from '@src/js/actions/user/formOptions';
import { editMyProfile, getMyProfile } from '@src/js/actions/user/user';
import { Loader, MetaTitle } from '@src/js/components/global';
import { RolesUser } from '@src/js/constants/entities';
import { adminRouteCodes, routeCodes, withLang } from '@src/js/constants/routes';
import { RootState } from '@src/js/store';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'react-tabs';
import { Col, Container, Row } from 'reactstrap';
import * as yup from 'yup';
import { useQuery } from '@src/js/helpers/hooks';

const tabsList = ['edit', 'preferences', 'devices'];

export type UserForm = {
  firstName: string,
  lastName: string,
  avatar: string|{id: number, url: string}|null,
  email: string,
  jobTitle: string,
  company: ItemObj<string>,
  userRoles: ItemObj<string>[],
  companyRelationshipManagers: ItemObj<string>[],
  programmeAdmins: ItemObj<string>[],
  programmeEditors: ItemObj<string>[],
  currentPassword?: string,
  newPassword?: string,
  confirmedPassword?: string,
  modalCurrentPassword?: string,
  isSubscribed?: boolean,
  messages?: boolean,
  isTracking?: boolean,
  network?: boolean,
  loginReminder?: boolean,
  businessOpportunity?: boolean,
}

type UserState = {
  loading: boolean,
  error: object,
  user?: UserForm,
}

export type FormType = 'create'|'edit'|'my_profile';
type Props = {
  formType: FormType,
}

const title = (formType: FormType) => {
  switch (formType) {
    case 'create':
      return 'navigation.admin.userCreate';
    case 'edit':
      return 'navigation.admin.userEdit';
    case 'my_profile':
      return 'navigation.editMyProfile';
    default:
      return '';
  }
};

const UserProfile = ({ formType }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const userInfo = selectUserInfo();
  const requiredMessage = intl.formatMessage({ id: 'global_form.error.required' });
  const formSchema = yup.object().shape({
    currentPassword: yup.string().when(['newPassword', 'confirmedPassword'],
      ([newPassword, confirmedPassword]) => ((!!newPassword || !!confirmedPassword)
        ? yup.string().required(requiredMessage) : yup.string())),
    confirmedPassword: yup.string()
      .oneOf([yup.ref('newPassword')], intl.formatMessage({ id: 'global_form.error.password_repeat' })),
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    email: yup.string().required(requiredMessage),
    jobTitle: yup.string().required(requiredMessage),
  });
  const { user, loading, error } = useSelector<RootState, UserState>(
    state => state[formType === 'my_profile' ? 'user' : 'adminUser']);
  const { id } = useParams<{ id?: string }>();
  const methods = useForm<UserForm>({
    values: formType === 'create' ? undefined : user,
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });
  const query = useQuery();
  const tab = query.get('tab') || '';
  const { handleSubmit, formState, setValue } = methods;
  const [showAvailableTabs, setShowAvailableTabs] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tab && tabsList.indexOf(tab) > 0 ? tabsList.indexOf(tab) : 0);
  const [showModal, setShowModal] = useState(false);
  const [requestPassword, setRequestPassword] = useState(false);

  useEffect(() => {
    setRequestPassword(error !== null);
  }, [error]);

  useEffect(() => {
    if (formType !== 'my_profile') {
      dispatch(getUserFormOptions());
    }
    dispatch(hideNavigationAction());
    return () => {
      dispatch(showNavigationAction());
    };
  }, [dispatch, formType]);

  useEffect(() => {
    if (id) {
      dispatch(adminGetUser(id));
    } else if (formType === 'my_profile') {
      dispatch(getMyProfile());
    }
  }, [id, formType, dispatch]);

  const toggleModal = () => {
    if (showModal) setValue('modalCurrentPassword', undefined);
    setShowModal(!showModal);
  };

  const onSubmit = handleSubmit((data: UserForm) => {
    let avatar: number|null|undefined = data.avatar && typeof data.avatar === 'object' ? data.avatar.id : null;
    if (typeof data.avatar === 'string') {
      avatar = undefined;
    }
    const formData = { ...data, avatar };
    if (formType !== 'create' && userInfo.userRole !== RolesUser.ROLE_ADMIN
    && formData.email !== user?.email && (!formData.modalCurrentPassword || requestPassword)) {
      setRequestPassword(false);
      toggleModal();
      return;
    }
    if (formData.modalCurrentPassword) {
      formData.currentPassword = formData.modalCurrentPassword;
      toggleModal();
      delete formData.modalCurrentPassword;
      delete formData.newPassword;
      delete formData.confirmedPassword;
    }
    if (!formData.newPassword) {
      delete formData.newPassword;
      delete formData.confirmedPassword;
    }
    if (formType === 'edit') {
      dispatch(adminEditUser(formData, history));
    } else if (formType === 'create') {
      dispatch(adminCreateUser(formData, history));
    } else {
      dispatch(editMyProfile(formData, history));
    }
  });

  const toggleAvailableTabs = () => {
    setShowAvailableTabs(!showAvailableTabs);
  };

  const tabs = userTabs(formType);

  const cancel = () => {
    const url = formType === 'my_profile' ? routeCodes.HOME : adminRouteCodes.USERS;
    history.push(withLang(url));
  };

  return (
    <>
      <MetaTitle title={ title(formType) } />
      <FormProvider { ...methods }>
        <form onSubmit={ onSubmit }>
          <div className='edit-user-page'>
            <Container>
              { (loading || formState.isSubmitting) && <Loader /> }
              <Tabs
                className='d-flex--sm'
                selectedTabClassName='secondary-mobile edit-user-page-tab-navigation-items-item--selected'
                selectedIndex={ selectedTab }
                onSelect={ tabIndex => setSelectedTab(tabIndex) }
              >
                <aside className='edit-user-page-tab-navigation w-100'>
                  <h2 className='edit-user-page-tab-navigation-title w-100'>
                    <FormattedMessage id='user_accountData' />
                    <span
                      role='presentation'
                      className='edit-user-page-tab-navigation-title__selected-item-name'
                      onClick={ toggleAvailableTabs }
                    >
                      <span className='edit-user-page-tab-navigation-title__item-delimiter'>
                        /
                      </span>
                      <FormattedMessage id={ tabs[selectedTab].title } />
                    </span>
                  </h2>
                  <div className='edit-user-page-tab-navigation-items'>
                    <TabList className={ `react-tabs__tab-list ${ showAvailableTabs ? 'show' : 'hide' }` }>
                      { tabs.map(tab => (
                        <Tab className='edit-user-page-tab-navigation-items-item' key={ tab.title }>
                          <span className={ `icon icon-small-${ tab.icon }` } />
                          <FormattedMessage id={ tab.title } />
                        </Tab>
                      ))}
                    </TabList>
                  </div>
                </aside>
                <section className='edit-user-page-tab-content col-sm-9 w-100'>
                  {tabs.map(tab => (
                    <TabPanel key={ tab.title }>
                      <Row>
                        <Col className='col-sm-offset-2'>
                          <div className='edit-user-page-tab-content-title'>
                            <FormattedMessage id={ tab.title } />
                          </div>
                        </Col>
                      </Row>
                      <Row className='center-xs'>
                        <Col xs sm={ 8 } className='edit-user-form-container'>
                          { tab.component }
                        </Col>
                      </Row>
                    </TabPanel>
                  ))}
                  <div className='row buttons-block m-t-4'>
                    <div className='col-xs-12 col-sm-8 col-sm-offset-2 d-flex'>
                      <button
                        type='submit'
                        className='eop-btn m-r-2'
                        disabled={ formState.isSubmitting }
                      >
                        <FormattedMessage id='save' />
                      </button>
                      <button
                        type='button'
                        className='eop-btn eop-btn-empty-grey m-r-2'
                        disabled={ formState.isSubmitting }
                        onClick={ cancel }
                      >
                        <FormattedMessage id='cancel' />
                      </button>
                      { formState.isDirty
                        && formState.isSubmitted
                        && (!formState.isValid || error)
                        && (
                        <div className='eop-text-textarea__helper eop-text-textarea__helper--warning p-t-2'>
                          <FormattedMessage id='error.form.notValid' />
                        </div>
                        ) }
                    </div>
                  </div>
                </section>
              </Tabs>
            </Container>
          </div>
          <ConfirmPasswordModal showModal={ showModal } toggle={ toggleModal } onSubmit={ onSubmit } />
        </form>
      </FormProvider>
    </>
  );
};

export default UserProfile;
