import { apiPath } from '@src/js/constants/routes';
import { store } from '@src/js/store';

async function readChunck(reader, callback) {
  const { done, value } = await reader.read();
  if (done) {
    return;
  }

  callback(new TextDecoder('utf-8').decode(value));
  await readChunck(reader, callback);
}

async function fetchStream(path, userOptions, callback) {
  let defaultHeaders = {
    'Accept-Language': store.getState().initInfo.activeLanguage || 'en',
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  // Define default headers
  if (!userOptions.noContentType) {
    defaultHeaders = {
      ...defaultHeaders,
      'Content-Type': 'application/json',
    };
  }

  if (!userOptions.noAccept) {
    defaultHeaders = {
      ...defaultHeaders,
      Accept: 'application/json',
    };
  }
  const options = {
    ...userOptions,
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
    credentials: 'include',
  };
  const url = `${ apiPath() }/${ path }`;
  const isFile = typeof window !== 'undefined' && options.body instanceof FormData;
  if (options.body && typeof options.body === 'object' && !isFile) {
    options.body = JSON.stringify(options.body);
  }

  const response = await fetch(url, options);
  if (response.status < 200 || response.status >= 300) {
    callback(false);
    return;
  }
  const reader = response.body.getReader();
  await readChunck(reader, callback);
}

export default fetchStream;
