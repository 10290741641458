import {
  ADMIN_GET_TEMPLATE_LIST_START,
  ADMIN_GET_TEMPLATE_LIST_ERROR,
  ADMIN_GET_TEMPLATE_LIST_SUCCESS,
  ADMIN_CHANGE_STATUS_TEMPLATES_START,
  ADMIN_CHANGE_STATUS_TEMPLATES_ERROR,
  ADMIN_CHANGE_STATUS_TEMPLATES_SUCCESS,
  ADMIN_GET_ONE_TEMPLATE_START,
  ADMIN_GET_ONE_TEMPLATE_ERROR,
  ADMIN_GET_ONE_TEMPLATE_SUCCESS,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateIndex';
import {
  ADMIN_RESET_TEMPLATE,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateModal';

const initialState = {
  loading: false,
  error: null,
  indexTemplates: null,
  statusLoading: false,
  statusError: null,
  statusChanged: null,
  selectedTemplate: null,
};

const actionsMap = {
  [ADMIN_GET_TEMPLATE_LIST_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_GET_TEMPLATE_LIST_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_GET_TEMPLATE_LIST_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    indexTemplates: action.data,
  }),
  [ADMIN_CHANGE_STATUS_TEMPLATES_START]: (state) => ({
    ...state,
    statusLoading: true,
    statusError: null,
  }),
  [ADMIN_CHANGE_STATUS_TEMPLATES_ERROR]: (state, action) => ({
    ...state,
    statusLoading: false,
    statusError: action.error.response,
  }),
  [ADMIN_CHANGE_STATUS_TEMPLATES_SUCCESS]: (state, action) => ({
    ...state,
    statusLoading: false,
    statusError: null,
    statusChanged: action.data,
  }),
  [ADMIN_GET_ONE_TEMPLATE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_GET_ONE_TEMPLATE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
    selectedTemplate: null,
  }),
  [ADMIN_GET_ONE_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    selectedTemplate: action.data,
  }),
  [ADMIN_RESET_TEMPLATE]: (state) => ({
    ...state,
    selectedTemplate: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
