import { takeLatest, call, put } from 'redux-saga/effects';

import {
  getParametersPartnersSuccess,
  getParametersPartnersFail,
} from '@app/store/funding/actions/partner';
import * as api from '@app/store/funding/api/partner';

import { GET_PARAMETERS_PARTNERS_REQUEST } from '@app/store/funding/constants';

function* getParametersPartners({ search, parameters }) {
  try {
    const data = yield call(() => api.getParametersPartners(search, parameters));
    yield put(getParametersPartnersSuccess(data));
  } catch (error) {
    yield put(getParametersPartnersFail(error));
  }
}

function* root() {
  yield takeLatest(GET_PARAMETERS_PARTNERS_REQUEST, getParametersPartners);
}

export default root;
