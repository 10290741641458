import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import PhoneInput, { getCountries } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import en from 'react-phone-number-input/locale/en.json';
import Select from '@app/components/global/forms/select/EliteSelect';

const InputMobile = ({
  intl, name, label, translate, disabled, placeholder, control, tooltip,
  required, errors, onChange, defaultValue, className, defaultCountry,
}) => {
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  const options = [
    { value: '', label: 'International' },
    ...getCountries().map(country => ({ value: country, label: en[country] })),
  ];
  const [country, setCountry] = useState(defaultCountry || '');
  const [value, setValue] = useState(defaultValue);

  const renderInput = (field) => {
    const inputProps = {
      ...field,
    };

    if (disabled) {
      inputProps.disabled = true;
    }

    if (placeholder) {
      inputProps.placeholder = placeholder;
    }

    const change = (e) => {
      const val = e || null;
      if (!val) {
        setCountry(undefined);
      }
      if (onChange) {
        onChange(val);
        setValue(val);
        return;
      }
      inputProps.onChange(val);
      setValue(val);
    };

    const changeCountry = (e) => {
      change('');
      setCountry(e);
    };

    return (
      <div className='PhoneInput__wrapper'>
        <PhoneInput
          { ...field }
          flags={ flags }
          defaultCountry={ country }
          country={ country }
          value={ field.value || value }
          onChange={ change }
        />
        <Select
          value={ country }
          options={ options }
          onChange={ (e) => changeCountry(e?.value || undefined) }
          fieldNoClear
          isSearchable={ false }
        />
      </div>
    );
  };

  return (
    <div className={ `eop-text-input ${ className }` }>
      <label className='eop-text-input__label'>
        { required && <span className='required-icon' /> }
        { label && fieldLabel }
        { tooltip && (
          <>
            <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ `${ name }-tooltip` } />
            <ReactTooltip
              id={ `${ name }-tooltip` }
              place='top'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              { fieldTooltip }
            </ReactTooltip>
          </>
        )}
      </label>
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        rules={ { required: requiredError } }
        render={ ({ field }) => renderInput(field) }
      />
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

InputMobile.propTypes = {
  intl: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType(([
    PropTypes.string,
    PropTypes.number,
  ])),
  className: PropTypes.string,
  tooltip: PropTypes.string,
  defaultCountry: PropTypes.string,
};

InputMobile.defaultProps = {
  translate: true,
  disabled: false,
  required: false,
  placeholder: 'global_form.select.placeholder',
  className: '',
};

export default injectIntl(InputMobile);
