import {
  FormSection, InputFileUpload,
} from '@app/components/global/forms';
import InputText from '@app/components/global/forms/InputText';
import InputSelect from '@app/components/global/forms/select/InputSelect';
import CorporateActions from '@app/components/public/onboarding/sections/CorporateActions';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import { dateToISOString } from '@app/components/public/onboarding/utils';
import { selectCurrencies, selectTurnovers } from '@app/store/global/selectors/taxonomy';
import {
  selectCurrentStep,
  selectFirstVisit,
  selectOnboardingData, selectOnboardingSave,
} from '@app/store/onboarding/selectors';
import { getCurrencies } from '@src/js/actions/taxonomy/currencies';
import { getTurnovers } from '@src/js/actions/taxonomy/turnovers';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { OnboardingBoolean } from '@src/js/constants/entities';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import { turnoverFormatter } from '@src/js/utils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import FundingHistory from './FundingHistory';

const Fundamentals = ({ handleSave, intl }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const dispatch = useDispatch();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const turnoversOption = selectTurnovers()?.map(it => ({ label: turnoverFormatter(it.name), value: it.id })) || [];
  const currenciesOption = selectCurrencies()?.map(it => ({
    label: <FormattedMessage id={ `company_form.currency.${ it.name }` } />,
    value: it.id,
  })) || [];
  const form = useForm({
    defaultValues: onboardingData?.company,
    mode: 'onChange',
  });

  const {
    control, formState: { errors }, getValues, watch, register, trigger,
  } = form;

  const corporateActionCleanup = (submitForm) => {
    const capitalRaisingNames = ['capitalRaisingIPO', 'capitalRaisingIPOAmount',
      'capitalRaisingIPODate', 'capitalRaisingIPOAdvisor', 'capitalRaisingMinibond',
      'capitalRaisingMinibondAmount', 'capitalRaisingMinibondDate', 'capitalRaisingMinibondAdvisor',
      'capitalRaisingPrivateEquity', 'capitalRaisingPrivateEquityChoices'];
    const formData = { ...submitForm };
    if (formData.fundamentals?.main !== OnboardingBoolean.TRUE) {
      ['acquisition', 'capitalRaising', 'other', 'crossBorder', 'financing', 'acquisitionInfos', 'otherText']
        .forEach(name => { delete formData.fundamentals?.[name]; });
      capitalRaisingNames
        .forEach(name => { delete formData.fundamentals?.[name]; });
      return formData;
    }
    if (!formData.fundamentals?.acquisition) {
      ['crossBorder', 'financing', 'acquisitionInfos']
        .forEach(name => { delete formData.fundamentals?.[name]; });
    }
    if (!formData.fundamentals?.other) {
      delete formData.fundamentals?.otherText;
    }
    if (!formData.fundamentals?.capitalRaising) {
      capitalRaisingNames.forEach(
        name => { delete formData.fundamentals?.[name]; });
      return formData;
    }
    if (!formData.fundamentals?.capitalRaisingIPO) {
      ['capitalRaisingIPOAmount', 'capitalRaisingIPODate', 'capitalRaisingIPOAdvisor']
        .forEach(name => { delete formData.fundamentals?.[name]; });
    }
    if (!formData.fundamentals?.capitalRaisingMinibond) {
      ['capitalRaisingMinibondAmount', 'capitalRaisingMinibondDate', 'capitalRaisingMinibondAdvisor']
        .forEach(name => { delete formData.fundamentals?.[name]; });
    }
    if (!formData.fundamentals?.capitalRaisingPrivateEquity) {
      delete formData.fundamentals?.capitalRaisingPrivateEquityChoices;
    }
    return formData;
  };

  useEffect(() => {
    if (onSave?.action) {
      let formData = { ...getValues() };
      formData.companyFundingHistory = formData.companyFundingHistory.map(fundingHistory => ({
        ...fundingHistory,
        dateOfFunding: dateToISOString(fundingHistory.dateOfFunding),
      }));
      formData.fundamentals = {
        ...formData.fundamentals,
        capitalRaisingIPODate: dateToISOString(formData.fundamentals?.capitalRaisingIPODate),
        capitalRaisingMinibondDate: dateToISOString(formData.fundamentals?.capitalRaisingMinibondDate),
      };
      if (formData.alreadyAudited !== OnboardingBoolean.TRUE) {
        delete formData.advisorName;
      }
      if (formData.fundamentals?.sustainabilityReport !== OnboardingBoolean.TRUE) {
        delete formData.fundamentals.fileSustainabilityReport;
      }
      if (formData.fundamentals?.businessPlan !== OnboardingBoolean.TRUE) {
        delete formData.fundamentals.fileBusinessPlan;
      }
      formData = corporateActionCleanup(formData);
      handleSave({ company: formData });
    }
  }, [onSave]);

  const options = [{ value: 1, label: intl.formatMessage({ id: 'yes' }) },
    { value: 2, label: intl.formatMessage({ id: 'no' }) }];

  const alreadyAudited = watch('alreadyAudited');
  const sustainabilityReport = watch('fundamentals.sustainabilityReport');
  const businessPlan = watch('fundamentals.businessPlan');
  const isAdmin = isAdminRole() || isPra();

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    dispatch(getTurnovers());
    dispatch(getCurrencies());
  }, []);

  return (
    <FormProvider { ...form }>
      <form>
        <FormSection sectionTitle='onboarding.fundamentals.financials'>
          <EmailSupport />
          <InputSelect
            name='consolidatedStatements'
            label='onboarding.fundamentals.statementsConsolidated'
            errors={ errors }
            control={ control }
            options={ options }
            fieldNoClear
            required
          />
          <InputFileUpload
            name='fileLatestFinancial'
            label='onboarding.fundamentals.statementCopy'
            acceptedTypes={ dropzoneExtensions.SIGNED_PDF }
            required
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding_company/latest_financial` }
          />
          <InputSelect
            name='alreadyAudited'
            label='onboarding.fundamentals.statementsAudited'
            errors={ errors }
            control={ control }
            options={ options }
            fieldNoClear
            required
          />
          { alreadyAudited === OnboardingBoolean.TRUE && (
          <InputText
            name='advisorName'
            label='onboarding.fundamentals.auditorName'
            errors={ errors }
            register={ register }
            required
          />
          )}
          { alreadyAudited !== OnboardingBoolean.TRUE && (
          <div />
          )}
          <div className='elite-form__section__half'>
            <InputSelect
              name='fundamentals.sustainabilityReport'
              label='onboarding.fundamentals.sustainabilityReport'
              errors={ errors }
              control={ control }
              options={ options }
              fieldNoClear
              required
            />
          </div>
          { sustainabilityReport === OnboardingBoolean.TRUE ? (
            <InputFileUpload
              name='fundamentals.fileSustainabilityReport'
              label='onboarding.fundamentals.fileSustainabilityReport'
              acceptedTypes={ dropzoneExtensions.SIGNED_PDF }
              required
              url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding/document` }
            />
          ) : (
            <div />
          )
       }
          <InputSelect
            name='fundamentals.businessPlan'
            label='onboarding.fundamentals.businessPlan'
            errors={ errors }
            control={ control }
            options={ options }
            fieldNoClear
            required
          />
          { businessPlan === OnboardingBoolean.TRUE ? (
            <InputFileUpload
              name='fundamentals.fileBusinessPlan'
              errors={ errors }
              control={ control }
              label='onboarding.fundamentals.fileBusinessPlan'
              acceptedTypes={ dropzoneExtensions.SIGNED_PDF }
              required
              url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding/document` }
            />
          ) : (
            <div />
          )}
          <InputSelect
            name='fundamentals.turnover'
            label='onboarding.fundamentals.turnover'
            errors={ errors }
            control={ control }
            options={ turnoversOption }
            fieldNoClear
            required
          />
          <InputSelect
            name='fundamentals.turnoverCurrency'
            label='onboarding.fundamentals.turnoverCurrency'
            errors={ errors }
            control={ control }
            options={ currenciesOption }
            fieldNoClear
            required
          />
          <InputText
            name='fundamentals.employeesNumber'
            label='onboarding.fundamentals.employeesNumber'
            errors={ errors }
            register={ register }
            type='number'
            minValue={ 0 }
            integerOnly
            required
          />
        </FormSection>
        <FundingHistory form={ form } />
        <CorporateActions form={ form } />
      </form>
    </FormProvider>
  );
};

Fundamentals.propTypes = {
  handleSave: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(Fundamentals);
