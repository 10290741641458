export function errorResolver(error) {
  if (error.status === 413) {
    return 'error.fileSizeUploadError';
  }

  if (error.status === 500 || !error.message) {
    return 'error.unexpectedError';
  }

  return error.message;
}
