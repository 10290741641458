import styled from 'styled-components';
import { FontSize, ThemeColors } from '@src/_v2/js/constants';

export const Label = styled.div`
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.caption };
`;

export const RequiredIcon = styled.span`
  margin-right: 0.5rem;
  font-size: ${ FontSize.caption };
  color: ${ ThemeColors.red };

  &::after {
    content: '*';
  }
`;

export const Warning = styled.span`
  font-size: ${ FontSize.small };
  line-height: 1.6rem;
  min-height: 2.6rem;
  text-align: left;
  padding: 0.5rem 0;
  color: ${ ThemeColors.red };
`;

export const TextInputContainer = styled.div`
  display: flex;
`;

export const FixedInputPrefix = styled.div`
  font-size: ${ FontSize.body };
  margin-right: 0.75rem;
  color: ${ ThemeColors.mediumGrey };
  line-height: 3rem;
  height: 3rem;
`;
