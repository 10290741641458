import React, { ChangeEvent } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Props = {
  name: string,
  checkedLabel?: string,
  uncheckedLabel?: string,
  translate?: boolean,
  disabled?: boolean,
  required?: boolean,
  errors: object,
  control: Control<any>,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  defaultValue?: boolean,
  className?: string,
}

const InputSwitch = ({
  name, checkedLabel = 'on', uncheckedLabel = 'off', translate = true, disabled, control,
  required, errors, onChange, defaultValue = false, className = '',
}: Props) => {
  const intl = useIntl();
  const fieldCheckedLabel = translate && checkedLabel ? intl.formatMessage({ id: checkedLabel }) : checkedLabel;
  const fieldUncheckedLabel = translate && uncheckedLabel ? intl.formatMessage({ id: uncheckedLabel }) : uncheckedLabel;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;

  const renderCheckbox = (fieldProps: ControllerRenderProps<any, string>) => {
    const change = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        return onChange(e);
      }
      return fieldProps.onChange(e?.target?.checked);
    };

    return (
      <input
        { ...fieldProps }
        id={ name }
        type='checkbox'
        onChange={ change }
        disabled={ disabled }
        checked={ !!fieldProps.value }
      />
    );
  };

  return (
    <>
      <div className={ `switch ${ className }` } data-toggle='buttons'>
        <Controller
          name={ name }
          control={ control }
          defaultValue={ defaultValue }
          rules={ { required: requiredError } }
          render={ ({ field }) => renderCheckbox(field) }
        />
        <label htmlFor={ name } className='switch__label switch__label--on'>
          { fieldCheckedLabel }
        </label>
        <label htmlFor={ name } className='switch__label switch__label--off'>
          { fieldUncheckedLabel }
        </label>
      </div>
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </>
  );
};

export default InputSwitch;
