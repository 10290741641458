export const FontWeight = {
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

export const FontSize = {
  tiny: '1rem',
  small: '1.1rem',
  medium: '1.2rem',
  icon: '1.3rem',
  caption: '1.4rem',
  subTitle: '1.5rem',
  body: '1.6rem',
  iconLarge: '1.7rem',
  xl: '1.9rem',
  subHeader: '2rem',
  headlineDescription: '2.2rem',
  title: '2.4rem',
  headline: '3rem',
  display: '3.5rem',
  jumbo: '5rem',
  mega: '7.2rem',
};
