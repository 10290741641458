import fetchResource from '@src/js/api/fetch-resource';

export function getExternalCompanyFormOptionsApi() {
  return fetchResource('api/self-registration/companies/form-options');
}

export function selfRegistrationCreateCompanyApi(company) {
  return fetchResource('api/self-registration/company', {
    method: 'POST',
    body: company,
  });
}
