export const VIEW_ALL_OPPORTUNITIES_START = 'VIEW_ALL_OPPORTUNITIES_START';
export const VIEW_ALL_OPPORTUNITIES_ERROR = 'VIEW_ALL_OPPORTUNITIES_ERROR';
export const VIEW_ALL_OPPORTUNITIES_SUCCESS = 'VIEW_ALL_OPPORTUNITIES_SUCCESS';

export function viewAllOpportunities(id, filters, opportunityType) {
  return {
    type: VIEW_ALL_OPPORTUNITIES_START,
    id,
    filters,
    opportunityType,
  };
}
