const scope = 'Translations';

const GET_TRANSLATIONS_DATA_REQUEST = `${ scope }/GET_TRANSLATIONS_DATA_REQUEST`;
const GET_TRANSLATIONS_DATA_SUCCESS = `${ scope }/GET_TRANSLATIONS_DATA_SUCCESS`;
const GET_TRANSLATIONS_DATA_FAIL = `${ scope }/GET_TRANSLATIONS_DATA_FAIL`;

const SEND_TRANSLATIONS_DATA_REQUEST = `${ scope }/SEND_TRANSLATIONS_DATA_REQUEST`;
const SEND_TRANSLATIONS_DATA_SUCCESS = `${ scope }/SEND_TRANSLATIONS_DATA_SUCCESS`;
const SEND_TRANSLATIONS_DATA_FAIL = `${ scope }/SEND_TRANSLATIONS_DATA_FAIL`;

export {
  GET_TRANSLATIONS_DATA_REQUEST,
  GET_TRANSLATIONS_DATA_SUCCESS,
  GET_TRANSLATIONS_DATA_FAIL,
  SEND_TRANSLATIONS_DATA_REQUEST,
  SEND_TRANSLATIONS_DATA_SUCCESS,
  SEND_TRANSLATIONS_DATA_FAIL,
};
