import {
  GET_CONTACT_USER_START,
  GET_CONTACT_USER_ERROR,
  GET_CONTACT_USER_SUCCESS,
  ContactType,
} from '@app/store/funding/actions/contactUser';
import { Action } from 'redux';

export type ContactUserState = {
  loading: boolean,
  error: unknown,
  funding: null|ContactUserResult,
  inspiring: null|ContactUserResult,
};

export type ContactUserResult = {
  id?: string,
  email: string,
  fullName?: string;
  jobTitle?: string;
  company?: string;
  avatar?: string;
}

export const initialState: ContactUserState = {
  loading: true,
  error: null,
  funding: null,
  inspiring: null,
};

interface ContactUserPayload extends Action {
  error?: { message: string };
  contactType: ContactType;
  data: null|ContactUserResult;
}

type ActionsMap = {
  [key: string]: (state: ContactUserState, action: ContactUserPayload) => ContactUserState;
};

const actionsMap: ActionsMap = {
  [GET_CONTACT_USER_START]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
    [action.contactType]: null,
  }),
  [GET_CONTACT_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error?.message,
  }),
  [GET_CONTACT_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    [action.contactType]: action.data,
  }),
};

export default function reducer(state = initialState, action: ContactUserPayload) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
