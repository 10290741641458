import React from 'react';
import PropTypes from 'prop-types';
import { formatFileSize } from '@src/js/helpers/helpers';
import { ectPath } from '@src/js/constants/routes';
import { downloadFile } from '@app/components/global/forms/utils';
import { DataRoomPermissions } from '@src/js/constants/entities';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { dateFormat } from '@src/js/utils/date';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import Actions from './Actions';

const File = ({
  file, slug, folder, cantBeMoved, dataRoomType, edit,
}) => {
  const { showModal } = useGlobalModalContext();
  const activeLanguage = selectActiveLanguage();
  const viewable = ['pdf', 'jpg', 'jpeg', 'png', 'gif'].includes(file.type);
  const clickable = viewable
    || file.permission > DataRoomPermissions.watermark;

  const handleClick = () => {
    if (edit) return;
    if (file.permission > DataRoomPermissions.watermark
      || (viewable && file.permission === DataRoomPermissions.watermark)) {
      const url = `${ ectPath() }/api/media/file/${ file.training
        ? 'event' : 'dataroom' }/${ file.id }?slug=${ slug }`;
      downloadFile(url);
    } else if (viewable && file.permission === DataRoomPermissions.view) {
      showModal({
        type: ModalTypes.DocumentViewer,
        config: { fullscreen: true },
        data: { file, slug },
      });
    }
  };

  return (
    <div className={ `fs-node--grid-file ${ folder > 0 ? 'p-l-4' : '' }` }>
      <div
        className={ clickable ? 'fs-node__primary-infos--file--clickable' : 'fs-node__primary-infos--file' }
        role='presentation'
        onClick={ handleClick }
      >
        <div className='fs-node__file-logo'>
          <small>{ file.type }</small>
          <span className='icon icon-small-Doc' />
        </div>
        <h5 className='fs-node__title--file m-0' title={ file?.title }>{ file?.title }</h5>
      </div>

      <div className='fs-node__other-infos'>
        <small>{ formatFileSize(file?.size, true) }</small>
        <small>{ file.modified ? dateFormat(file.modified, 'dd MMM yyyy', activeLanguage, false) : '' }</small>
      </div>

      <Actions
        slug={ slug }
        node={ file }
        type='file'
        cantBeMoved={ cantBeMoved }
        training={ file?.training }
        dataRoomType={ dataRoomType }
      />
    </div>
  );
};

File.propTypes = {
  file: PropTypes.object,
  slug: PropTypes.string.isRequired,
  folder: PropTypes.number,
  cantBeMoved: PropTypes.bool,
  dataRoomType: PropTypes.string,
  edit: PropTypes.bool,
};

File.defaultProps = {
  folder: 0,
};

export default File;
