import React from 'react';
import * as PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { purifyHtml } from '@src/js/utils';
import { Images } from '@src/js/constants/images';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import { messages } from './constant';

const Tab = ({
  selected, onSelectTab, tabKey, intl,
}) => {
  const language = selectActiveLanguage();
  const message = messages(tabKey);

  return (
    <div className={ `etinerary-modal-tab ${ selected && !isMobile ? 'selected-desktop' : '' }` }>
      <div
        className={ `tab-header ${ selected ? 'selected' : '' }` }
        onClick={ () => onSelectTab() }
        role='presentation'
      >
        <div className='label'>
          <div className='title title-header'>
            <FormattedMessage id={ message.title } />
          </div>
          <div className='subtitle'>
            { (language === 'en' && tabKey === 'funding') ? ' ' : <FormattedMessage id={ message.subtitle } /> }
          </div>
          <div />
        </div>
        <div className='icon'>
          { isMobile ? (
            <span className='icon-ArrowDownSmall icon-up' />
          ) : (
            <span className='icon-ArrowRightSmall icon-up' />
          ) }
        </div>
      </div>
      {selected && isMobile && (
        <div className='tab-body'>
          <div>
            <img src={ Images[`ETINERARY_${ tabKey.toUpperCase() }`] } alt={ `elite-${ tabKey }` } />
          </div>
          <div className='title description'>
            <FormattedMessage id={ message.title } />
          </div>
          <div className='description'>
            {intl.formatMessage(message.description, {
              span: chunks => purifyHtml(`<span>${ chunks.join('') }</span>`),
            })}
          </div>
        </div>
      )
          }
    </div>
  );
};

Tab.propTypes = {
  selected: PropTypes.bool.isRequired,
  onSelectTab: PropTypes.func.isRequired,
  tabKey: PropTypes.string.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(Tab);
