import {
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAIL,
  SEND_TEMPLATE_REQUEST,
  SEND_TEMPLATE_SUCCESS,
  SEND_TEMPLATE_FAIL,
  DOWNLOAD_TEMPLATE_REQUEST,
  DOWNLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_TEMPLATE_FAIL,
  CREATE_NEW_LEGAL_REQUEST,
  CREATE_NEW_LEGAL_SUCCESS,
  CREATE_NEW_LEGAL_FAIL,
} from '@app/store/etinerary/constants';

function getTemplateRequest() {
  return {
    type: GET_TEMPLATE_REQUEST,
  };
}

function getTemplateSuccess(data) {
  return {
    type: GET_TEMPLATE_SUCCESS,
    payload: data,
  };
}

function getTemplateFail(error) {
  return {
    type: GET_TEMPLATE_FAIL,
    payload: error,
  };
}

function sendTemplateRequest(payload) {
  return {
    type: SEND_TEMPLATE_REQUEST,
    payload,
  };
}

function sendTemplateSuccess(data) {
  return {
    type: SEND_TEMPLATE_SUCCESS,
    payload: data,
  };
}

function sendTemplateFail(error) {
  return {
    type: SEND_TEMPLATE_FAIL,
    payload: error,
  };
}

function downloadTemplateRequest() {
  return {
    type: DOWNLOAD_TEMPLATE_REQUEST,
  };
}

function downloadTemplateSuccess() {
  return {
    type: DOWNLOAD_TEMPLATE_SUCCESS,
  };
}

function downloadTemplateFail(error) {
  return {
    type: DOWNLOAD_TEMPLATE_FAIL,
    payload: error,
  };
}

function createNewLegalRequest(payload) {
  return {
    type: CREATE_NEW_LEGAL_REQUEST,
    payload,
  };
}

function createNewLegalSuccess(data) {
  return {
    type: CREATE_NEW_LEGAL_SUCCESS,
    payload: data,
  };
}

function createNewLegalFail(error) {
  return {
    type: CREATE_NEW_LEGAL_FAIL,
    payload: error,
  };
}

export {
  getTemplateRequest,
  getTemplateSuccess,
  getTemplateFail,
  sendTemplateRequest,
  sendTemplateSuccess,
  sendTemplateFail,
  downloadTemplateRequest,
  downloadTemplateSuccess,
  downloadTemplateFail,
  createNewLegalRequest,
  createNewLegalSuccess,
  createNewLegalFail,
};
