import { takeLatest, call, put } from 'redux-saga/effects';

import {
  COMPANY_COURSERA_ACCESS_START,
  COMPANY_COURSERA_ACCESS_ERROR,
  COMPANY_COURSERA_ACCESS_SUCCESS,
} from '@src/js/actions/company/companyCourseraAccess';

import * as api from '@src/js/api/company/companyCourseraAccess';

function* toggleCompanyCourseraAccess(options) {
  try {
    const data = yield call(() => api.toggleCompanyCourseraAccess(options.slug));
    yield put({ type: COMPANY_COURSERA_ACCESS_SUCCESS, data });
  } catch (error) {
    yield put({ type: COMPANY_COURSERA_ACCESS_ERROR, error });
  }
}

function* toggleCompanyCourseraAccessWatcher() {
  yield takeLatest(COMPANY_COURSERA_ACCESS_START, toggleCompanyCourseraAccess);
}

export default [
  toggleCompanyCourseraAccessWatcher(),
];
