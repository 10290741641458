import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_NEWS_LIST_START,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_ERROR,
  NewsListAction,
} from '@src/js/actions/news/newsList';

import * as api from '@src/js/api/news/newsList';

function* getNewsList({ newsType, page }: NewsListAction): Generator {
  try {
    const data = yield call(() => api.getNewsList(newsType, page));
    yield put({ type: GET_NEWS_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NEWS_LIST_ERROR, error });
  }
}

function* getNewsListWatcher() {
  yield takeLatest(GET_NEWS_LIST_START, getNewsList);
}

export default [
  getNewsListWatcher(),
];
