import React, { Component } from 'react';
import SetPasswordForm from '@src/js/components/forms/SetPasswordForm';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import { routeCodes, withLang } from '@src/js/constants/routes';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { Images } from '@src/js/constants/images';

class SetPassword extends Component {
  render() {
    return (
      <div className='container set-password-page'>
        <MetaTitle title='resetPassword.formTitle' />
        <div className='row'>
          <div className='col-xs-12 row-no-padding'>
            <img
              src={ Images.PasswordForm }
              alt=''
              className='background-image'
            />

            <SetPasswordForm
              loadingMessage='resetPassword.loadingMessage'
              title='resetPassword.formTitle'
              urlSetPassword='api/users/password/set'
              urlValidateToken='api/users/token/validate'
              urlRecreateUserToken='api/user/recreate-user-token'
              redirectUrl={ withLang(routeCodes.HOME) }
              publicRedirectUrl={ loadEnvVariable('PUBLIC_WEBSITE_URL') }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SetPassword;
