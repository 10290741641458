import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
import {
  Company, Partner, RolesUser, Event,
} from '@src/js/constants/entities';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { store } from '@src/js/store';

const EPW_LANGS = ['en', 'fr', 'it', 'nl', 'pt'];

export function getLanguages() {
  const localizationMessages = store.getState().initInfo.localizationMessages;
  const first = 'en';

  return Object.keys(localizationMessages).sort((x, y) => {
    if (x === first) {
      return -1;
    }

    if (y === first) {
      return 1;
    }

    return 0;
  });
}

export function getEOPCookieLanguage() {
  return Cookies.get('eopLanguage') || Cookies.get('epw_lang');
}

export function setDefaultLanguageEOP(lang) {
  Cookies.set('epw_lang', EPW_LANGS.includes(lang) ? lang : 'en', {
    domain: `.${ loadEnvVariable('APP_BASE_DOMAIN') }`,
    secure: true,
  });

  Cookies.set('eopLanguage', lang, {
    domain: `.${ loadEnvVariable('APP_BASE_DOMAIN') }`,
    secure: true,
  });
}

export function copyObject(target) {
  return JSON.parse(JSON.stringify(target, (key, value) => {
    if (value !== null && value !== undefined && !Number.isNaN(value)) {
      if (typeof value === 'object' && value.value !== undefined && value.label !== undefined) {
        return value.value;
      }
      return value;
    }
    return null;
  }));
}

export function dropdownHasChildren(options, id) {
  return options.filter(option => option.parentId === id).length > 0;
}

export function formatFileSize(size, sizeThreshold) {
  const thresh = sizeThreshold ? 1000 : 1024;

  if (Math.abs(size) < thresh) {
    return `${ size } B`;
  }

  const units = sizeThreshold
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let unitThreshold = -1;
  let formattedSize = size;

  do {
    formattedSize /= thresh;
    ++unitThreshold;
  } while (Math.abs(formattedSize) >= thresh && unitThreshold < units.length - 1);

  return `${ formattedSize.toFixed(2) } ${ units[unitThreshold] }`;
}

export function ellipsisMiddle(str) {
  if (str.length > 30) {
    return `${ str.substr(0, 17) }...${ str.substr(str.length - 10, str.length) }`;
  }
  return str;
}

export function stripWhiteSpaces(str) {
  if ((str === null) || (str === '') || (undefined === str)) {
    return false;
  }

  const string = str.toString();

  return string.replace(/ /gi, '');
}

export function userHasPermission(permission) {
  const userBase64Permissions = store.getState().initInfo.userInfo.type;
  const userPermissions = Base64.decode(userBase64Permissions);

  // eslint-disable-next-line no-bitwise
  return userPermissions & permission;
}

export function isComplianceUserRole() {
  return store.getState().initInfo?.userInfo?.userRoles?.find(
    el => el === RolesUser.ROLE_COMPLIANCE_CLUB_DEAL || el === RolesUser.ROLE_COMPLIANCE_SIM) !== undefined;
}

export function isAdminRole() {
  return store.getState().initInfo?.userInfo?.userRoles?.find(
    el => el === RolesUser.ROLE_SUPER_ADMIN || el === RolesUser.ROLE_ADMIN) !== undefined;
}

export function isPra() {
  return store.getState().initInfo?.userInfo?.userRoles?.find(
    el => el === RolesUser.ROLE_PROGRAMME_ADMIN) !== undefined;
}

export const isTrainingType = (eventTypeId) => eventTypeId?.value === Event.TYPE_TRAINING
  || eventTypeId === Event.TYPE_TRAINING;

export const isLangEn = () => store?.getState().initInfo?.activeLanguage === 'en';
export const isLangIt = () => store?.getState().initInfo?.activeLanguage === 'it';
export const isGrowth = () => store?.getState().initInfo?.userInfo?.companyType === Company.TYPE_GROWTH;
export const isGrowthStartup = () => store?.getState().initInfo?.userInfo?.companyType === Company.TYPE_GROWTH_STARTUP;
export const isEtinerary = () => store?.getState().initInfo?.userInfo?.companyType === Company.TYPE_ETINERARY;
export const isEtineraryNotAccepted = () => (
  store?.getState().initInfo?.userInfo?.companyType === Company.TYPE_ETINERARY
  && store?.getState().initInfo?.userInfo?.etineraryStatus !== Company.ETINERARY_STATUS_ACCEPTED
);
export const isInstitutionalInvestor = () => store?.getState().initInfo?.userInfo?.companyType
  === Company.TYPE_INSTITUTIONAL_INVESTOR;
export const isInvestorRelator = () => store?.getState().initInfo?.userInfo?.companyType
    === Company.TYPE_INVESTOR_RELATOR;
export const isProgramme = () => store?.getState().initInfo?.userInfo?.partnerType === Partner.TYPE_PROGRAMME;
export const isBoth = () => store?.getState().initInfo?.userInfo?.partnerType === Partner.TYPE_BOTH;
export const isPublic = () => store?.getState().initInfo?.userInfo?.companyVisibility === Company.VISIBILITY_PUBLIC;
export const isHidden = () => store?.getState().initInfo?.userInfo?.companyVisibility === Company.VISIBILITY_HIDDEN;
export const isPrivate = () => store?.getState().initInfo?.userInfo?.companyVisibility === Company.VISIBILITY_PRIVATE;
export const isRoleUser = () => store?.getState().initInfo?.userInfo?.userRole === RolesUser.ROLE_USER;
export const partnerBasic = () => store?.getState().initInfo?.userInfo?.partnerType === Partner.TYPE_BASIC;
export const partnerPublic = () => store?.getState().initInfo?.userInfo?.partnerVisibility
  === Partner.VISIBILITY_PUBLIC;
export const partnerPrivate = () => store?.getState().initInfo?.userInfo?.partnerVisibility
  === Partner.VISIBILITY_PRIVATE;
export const partnerHidden = () => store?.getState().initInfo?.userInfo?.partnerVisibility
  === Partner.VISIBILITY_HIDDEN;

export const isPartner = () => store?.getState().initInfo?.userInfo?.partnerId;
export const isEliteProfessionalPartner = () => store?.getState().initInfo?.userInfo?.partnerType
    === Partner.TYPE_ACCOUNTANT;
export const isFundingPartner = () => store?.getState().initInfo?.userInfo?.partnerType
    === Partner.TYPE_FUNDING_PARTNER;
export const getProgramme = () => store?.getState().initInfo?.userInfo?.programme;

export const myCompanySlug = () => (isPartner()
  ? store?.getState().initInfo?.userInfo?.partnerSlug
  : store?.getState().initInfo?.userInfo?.companySlug);

const intercomLinks = loadEnvVariable('INTERCOM_LINKS');
export const getTourId = () => {
  let key = '';

  key += isLangIt() ? 'it' : 'en';

  if (isGrowth()) {
    key += 'Growth';
  } else if (isGrowthStartup()) {
    key += 'GrowthStartup';
  } else if (isProgramme()) {
    key += 'Programme';
  }

  if (isHidden() || partnerHidden()) {
    key += 'Hidden';
  } else {
    key += 'Public';
  }

  return intercomLinks?.[key] || '';
};
