import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_TEMPLATE_OPTIONS_START,
  ADMIN_GET_TEMPLATE_OPTIONS_ERROR,
  ADMIN_GET_TEMPLATE_OPTIONS_SUCCESS, GetTemplateOptionsAction,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateOptions';

import * as api from '@src/js/api/onboarding/templates/admin/adminTemplateOptions';

function* adminGetTemplateOptions({ kind }: GetTemplateOptionsAction): Generator {
  try {
    const data = yield call(() => api.adminGetTemplateOptions(kind));
    yield put({ type: ADMIN_GET_TEMPLATE_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_TEMPLATE_OPTIONS_ERROR, error });
  }
}

function* adminGetTemplateOptionsWatcher(): Generator {
  yield takeLatest(ADMIN_GET_TEMPLATE_OPTIONS_START, adminGetTemplateOptions);
}

export default [
  adminGetTemplateOptionsWatcher(),
];
