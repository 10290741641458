import {
  GET_NETWORKING_ERROR,
  GET_NETWORKING_START,
  GET_NETWORKING_SUCCESS,
} from '@src/js/actions/networking/companyAndPartner';

const initialState = {
  loading: false,
  error: null,
  companyPartner: [],
};

const actionsMap = {
  // Async action
  [GET_NETWORKING_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    companyPartner: [],
  }),
  [GET_NETWORKING_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_NETWORKING_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    companyPartner: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const reducerFunction = actionsMap[action.type];
  return reducerFunction ? reducerFunction(state, action) : state;
}
