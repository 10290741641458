export const ADMIN_GET_USERS_START = 'ADMIN_GET_USERS_START';
export const ADMIN_GET_USERS_ERROR = 'ADMIN_GET_USERS_ERROR';
export const ADMIN_GET_USERS_SUCCESS = 'ADMIN_GET_USERS_SUCCESS';

export const GET_USER_FILTER_COMPANY_OPTIONS_START = 'GET_USER_FILTER_COMPANY_OPTIONS_START';
export const GET_USER_FILTER_COMPANY_OPTIONS_SUCCESS = 'GET_USER_FILTER_COMPANY_OPTIONS_SUCCESS';
export const GET_USER_FILTER_COMPANY_OPTIONS_ERROR = 'GET_USER_FILTER_COMPANY_OPTIONS_ERROR';

export function adminGetUsersAction(filters) {
  return {
    type: ADMIN_GET_USERS_START,
    filters,
  };
}

export function getUserFilterCompanyOptionsAction(filterOptions) {
  return {
    type: GET_USER_FILTER_COMPANY_OPTIONS_START,
    filterOptions,
  };
}
