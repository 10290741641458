const scope = 'EVENT';

export const GET_EVENT_START = `${ scope }/GET_EVENT_START`;
export const GET_EVENT_SUCCESS = `${ scope }/GET_EVENT_SUCCESS`;
export const GET_EVENT_FAIL = `${ scope }/GET_EVENT_FAIL`;
export const REMINDER_TEMPLATE_LIST_START = `${ scope }/REMINDER_TEMPLATE_LIST_START`;
export const REMINDER_TEMPLATE_LIST_SUCCESS = `${ scope }/REMINDER_TEMPLATE_LIST_SUCCESS`;
export const REMINDER_TEMPLATE_LIST_FAIL = `${ scope }/REMINDER_TEMPLATE_LIST_FAIL`;
export const GET_REMINDER_TEMPLATE_START = `${ scope }/GET_REMINDER_TEMPLATE_START`;
export const GET_REMINDER_TEMPLATE_SUCCESS = `${ scope }/GET_REMINDER_TEMPLATE_SUCCESS`;
export const GET_REMINDER_TEMPLATE_FAIL = `${ scope }/GET_REMINDER_TEMPLATE_FAIL`;
export const CREATE_REMINDER_TEMPLATE_START = `${ scope }/CREATE_REMINDER_TEMPLATE_START`;
export const CREATE_REMINDER_TEMPLATE_SUCCESS = `${ scope }/CREATE_REMINDER_TEMPLATE_SUCCESS`;
export const CREATE_REMINDER_TEMPLATE_FAIL = `${ scope }/CREATE_REMINDER_TEMPLATE_FAIL`;
export const EDIT_REMINDER_TEMPLATE_START = `${ scope }/EDIT_REMINDER_TEMPLATE_START`;
export const EDIT_REMINDER_TEMPLATE_SUCCESS = `${ scope }/EDIT_REMINDER_TEMPLATE_SUCCESS`;
export const EDIT_REMINDER_TEMPLATE_FAIL = `${ scope }/EDIT_REMINDER_TEMPLATE_FAIL`;
export const REMINDER_TEMPLATE_RESET = `${ scope }/REMINDER_TEMPLATE_RESET`;
