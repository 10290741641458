import {
  COMPANY_DOCUMENTS_DELETE_START,
  COMPANY_DOCUMENTS_DELETE_ERROR,
  COMPANY_DOCUMENTS_DELETE_SUCCESS,
} from '@src/js/actions/company/companyDocumentsDelete';

const initialState = {
  loading: false,
  error: {},
  documentSlug: null,
};

const actionsMap = {
  [COMPANY_DOCUMENTS_DELETE_START]: (state) => ({
    ...state,
    loading: true,
    error: {},
    documentSlug: null,
  }),
  [COMPANY_DOCUMENTS_DELETE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    documentSlug: null,
  }),
  [COMPANY_DOCUMENTS_DELETE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: {},
    documentSlug: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
