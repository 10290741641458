import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '@src/js/layouts/forms/company/CompanyForm';
import { purifyHtml } from '@src/js/utils';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import Button from '@src/_v2/js/components/buttons/Button/Button';

class OnboardingWelcomePage extends Component {
  render() {
    const { translation, onClick, isPartner } = this.props;

    return (
      <section className='container m-y-3 text-center eop-text-black'>
        <MetaTitle title='onboarding.welcomePage.pageTitle' />
        <h2
          className='font-weight-bold font-size-headline blue-a'
          dangerouslySetInnerHTML={ {
            __html: purifyHtml(translation[`onboarding.welcomePage.caption${ isPartner ? '.partner' : '' }`]),
          } }
        />

        <h4
          className='font-weight-normal font-size-subheader blue-a'
          dangerouslySetInnerHTML={ {
            __html: purifyHtml(translation[`onboarding.welcomePage.content${ isPartner ? '.partner' : '' }`]),
          } }
        />

        <Button
          text={ translation['onboarding.welcomePage.buttonText'] }
          onClick={ onClick }
          translate={ false }
          className='eop-btn'
        />
      </section>
    );
  }
}

OnboardingWelcomePage.propTypes = {
  translation: PropTypes.object,
  onClick: PropTypes.func,
  isPartner: PropTypes.bool,
};

/**
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state) => ({
  translation: state.initInfo.localizationMessages[state?.onboardingTemplate?.templateData?.lang || DEFAULT_LANGUAGE],
});

const connectWrapper = connect(mapStateToProps)(OnboardingWelcomePage);

export default withRouter(connectWrapper);
