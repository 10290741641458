import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_PARTNER_BASIC_DATA_START,
  GET_PARTNER_BASIC_DATA_ERROR,
  GET_PARTNER_BASIC_DATA_SUCCESS,
} from '@src/js/actions/partner/partnerBasicData';

import * as api from '@src/js/api/partner/partnerBasicData';

function* getPartnerBasicData(options) {
  try {
    const data = yield call(() => api.getPartnerBasicData(options.slug));
    yield put({ type: GET_PARTNER_BASIC_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PARTNER_BASIC_DATA_ERROR, error });
  }
}

function* getPartnerBasicDataWatcher() {
  yield takeLatest(GET_PARTNER_BASIC_DATA_START, getPartnerBasicData);
}

export default [
  getPartnerBasicDataWatcher(),
];
