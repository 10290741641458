import {
  ADMIN_GET_USER_START,
  ADMIN_GET_USER_ERROR,
  ADMIN_GET_USER_SUCCESS,
  ADMIN_CREATE_USER_START,
  ADMIN_CREATE_USER_ERROR,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_EDIT_USER_START,
  ADMIN_EDIT_USER_ERROR,
  ADMIN_EDIT_USER_SUCCESS,
  ADMIN_DISABLE_USER_START,
  ADMIN_DISABLE_USER_ERROR,
  ADMIN_DISABLE_USER_SUCCESS,
  ADMIN_DISABLE_USER_NOTIFICATIONS_START,
  ADMIN_DISABLE_USER_NOTIFICATIONS_ERROR,
  ADMIN_DISABLE_USER_NOTIFICATIONS_SUCCESS,
  ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START,
  ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_ERROR,
  ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_SUCCESS,
} from '@src/js/actions/user/adminUser';

const initialState = {
  loading: null,
  error: null,
  user: null,
  disabledUserLoading: null,
  disabledUserError: null,
  disabledUserSuccess: null,
  disabledUserNotificationsLoading: null,
  disabledUserNotificationsError: null,
  disabledUserNotificationsSuccess: null,
  changeCompanyCreationPermissionsLoading: null,
  changeCompanyCreationPermissionsError: null,
  changeCompanyCreationPermissionsSuccess: null,
  impersonateUserLoading: null,
  impersonateUserError: null,
  impersonateUserSuccess: null,
  impersonatedUser: null,
  exitImpersonateSessionLoading: null,
  exitImpersonateSessionError: null,
  exitImpersonateSessionSuccess: null,
  initInfo: null,
};

const actionsMap = {
  [ADMIN_GET_USER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_GET_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_GET_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [ADMIN_CREATE_USER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_CREATE_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_CREATE_USER_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
  [ADMIN_EDIT_USER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_EDIT_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_EDIT_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [ADMIN_DISABLE_USER_START]: (state) => ({
    ...state,
    disabledUserLoading: true,
    disabledUserError: null,
    disabledUserSuccess: false,
  }),
  [ADMIN_DISABLE_USER_ERROR]: (state, action) => ({
    ...state,
    disabledUserLoading: false,
    disabledUserError: action.error.response,
  }),
  [ADMIN_DISABLE_USER_SUCCESS]: (state) => ({
    ...state,
    disabledUserLoading: false,
    disabledUserSuccess: true,
  }),
  [ADMIN_DISABLE_USER_NOTIFICATIONS_START]: (state) => ({
    ...state,
    disabledUserNotificationsLoading: true,
    disabledUserNotificationsError: null,
    disabledUserNotificationsSuccess: false,
  }),
  [ADMIN_DISABLE_USER_NOTIFICATIONS_ERROR]: (state, action) => ({
    ...state,
    disabledUserNotificationsLoading: false,
    disabledUserNotificationsError: action.error.response,
  }),
  [ADMIN_DISABLE_USER_NOTIFICATIONS_SUCCESS]: (state) => ({
    ...state,
    disabledUserNotificationsLoading: false,
    disabledUserNotificationsSuccess: true,
  }),
  [ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START]: (state) => ({
    ...state,
    changeCompanyCreationPermissionsLoading: true,
    changeCompanyCreationPermissionsError: null,
    changeCompanyCreationPermissionsSuccess: false,
  }),
  [ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    changeCompanyCreationPermissionsLoading: false,
    changeCompanyCreationPermissionsError: action.error.response,
  }),
  [ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_SUCCESS]: (state) => ({
    ...state,
    changeCompanyCreationPermissionsLoading: false,
    changeCompanyCreationPermissionsSuccess: true,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
