import {
  GET_ACTIVE_STEP,
  GET_FORM_STEP_REQUESTED,
  GET_FORM_STEP_SUCCEEDED,
  GET_FORM_STEP_FAILED,
  GET_MEMBERSHIP_COMPANY_REQUESTED,
  GET_MEMBERSHIP_COMPANY_SUCCEEDED,
  GET_MEMBERSHIP_COMPANY_FAILED,
  SAVE_MEMBERSHIP_PROCESS_REQUESTED,
  SAVE_MEMBERSHIP_PROCESS_SUCCEEDED,
  SAVE_MEMBERSHIP_PROCESS_FAILED,
  UPLOAD_FILE_REQUESTED,
  UPLOAD_FILE_SUCCEEDED,
  UPLOAD_FILE_FAILED,
} from '@src/js/oneplatform/membership/pages/form/container/constants';

function formGetActiveStep(data) {
  return {
    type: GET_ACTIVE_STEP,
    payload: data,
  };
}

function getMembershipCompanyRequested(templateHash, isAdminForm, isReport) {
  return {
    type: GET_MEMBERSHIP_COMPANY_REQUESTED,
    templateHash,
    isAdminForm,
    isReport,
  };
}

function getMembershipCompanySucceeded(data) {
  return {
    type: GET_MEMBERSHIP_COMPANY_SUCCEEDED,
    payload: data,
  };
}

function getMembershipCompanyFailed(error) {
  return {
    type: GET_MEMBERSHIP_COMPANY_FAILED,
    payload: error,
  };
}

function saveMembershipCompanyRequested(payload) {
  return {
    type: SAVE_MEMBERSHIP_PROCESS_REQUESTED,
    ...payload,
  };
}

function saveMembershipCompanySucceeded(data) {
  return {
    type: SAVE_MEMBERSHIP_PROCESS_SUCCEEDED,
    payload: data,
  };
}

function saveMembershipCompanyFailed(error) {
  return {
    type: SAVE_MEMBERSHIP_PROCESS_FAILED,
    payload: error,
  };
}

function formStepValidationRequested(formData) {
  return {
    type: GET_FORM_STEP_REQUESTED,
    payload: formData,
  };
}

function formStepValidationSucceeded(validated) {
  return {
    type: GET_FORM_STEP_SUCCEEDED,
    payload: validated,
  };
}

function formStepValidationFailed(error) {
  return {
    type: GET_FORM_STEP_FAILED,
    payload: error,
  };
}

function uploadMembershipFileRequested(file, entity, fileType) {
  return {
    type: UPLOAD_FILE_REQUESTED,
    file,
    entity,
    fileType,
  };
}

function uploadMembershipFileSucceeded(data) {
  return {
    type: UPLOAD_FILE_SUCCEEDED,
    payload: data,
  };
}

function uploadMembershipFileFailed(error) {
  return {
    type: UPLOAD_FILE_FAILED,
    payload: error,
  };
}

export {
  getMembershipCompanyRequested,
  getMembershipCompanySucceeded,
  getMembershipCompanyFailed,
  saveMembershipCompanyRequested,
  saveMembershipCompanySucceeded,
  saveMembershipCompanyFailed,
  formGetActiveStep,
  formStepValidationRequested,
  formStepValidationSucceeded,
  formStepValidationFailed,
  uploadMembershipFileRequested,
  uploadMembershipFileSucceeded,
  uploadMembershipFileFailed,
};
