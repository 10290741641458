import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { getLibraryDimensionData, increaseRotation } from '@app/store/library/actions';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import Header from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components/Header/Header';
import { ButtonLink } from '@src/_v2/js/components/buttons';
import { Footer } from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components';
import LibraryBlock from '@app/components/public/network/library/LibraryBlock';
import { selectActiveLanguage, selectUserInfo } from '@app/store/global/selectors/initInfo';
import { selectLibraryData, selectLibraryLoading } from '@app/store/library/selectors';
import { Company } from '@src/js/constants/entities';

const LibraryWidget = ({
  disabled, className, showHeader,
}) => {
  const dispatch = useDispatch();
  const activeLanguage = selectActiveLanguage();
  const libraryData = selectLibraryData();
  const loading = selectLibraryLoading()?.featured;
  const userInfo = selectUserInfo();

  const widgetLibraries = libraryData?.featured || [];
  const rotationIdx = useSelector(state => state.library.rotationIdx);

  useEffect(() => {
    dispatch(getLibraryDimensionData({ featured: true, limit: 3 }, 'featured'));
  }, [activeLanguage]);

  useEffect(() => () => dispatch(increaseRotation()), []);

  if (!widgetLibraries?.length) {
    return null;
  }

  let libraries = [widgetLibraries[rotationIdx]];

  if (userInfo.companyType === Company.TYPE_ETINERARY) {
    libraries = widgetLibraries?.length > 2
      ? widgetLibraries.filter((_, idx) => (rotationIdx !== idx))
      : widgetLibraries;
  }

  return (
    <DashboardWidget
      id='dashboard-library-widget'
      className={ `${ className || '' } p-x-1 p-y-1` }
    >
      { showHeader && (
      <Header>
        <FormattedMessage id='navigation.library' />
      </Header>
      ) }
      <LibraryBlock
        libraries={ libraries }
        loading={ loading }
        inWidget
        disabled={ disabled }
      />
      <Footer>
        { !disabled ? (
          <ButtonLink text='viewAll' to={ `${ withLang(routeCodes.LIBRARY) }` } />
        ) : (
          <ButtonLink text='viewAll' />
        ) }
      </Footer>
    </DashboardWidget>
  );
};

LibraryWidget.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
};

LibraryWidget.defaultProps = {
  disabled: false,
  showHeader: true,
};

export default LibraryWidget;
