export const GET_ALL_OPPORTUNITIES_START = 'GET_ALL_OPPORTUNITIES_START';
export const GET_ALL_OPPORTUNITIES_ERROR = 'GET_ALL_OPPORTUNITIES_ERROR';
export const GET_ALL_OPPORTUNITIES_SUCCESS = 'GET_ALL_OPPORTUNITIES_SUCCESS';
export const GET_ALL_OPPORTUNITIES_CLEAR = 'GET_ALL_OPPORTUNITIES_CLEAR';
export const UPDATE_OPPORTUNITY = 'UPDATE_OPPORTUNITY';

export function getAllOpportunities(filter) {
  return {
    type: GET_ALL_OPPORTUNITIES_START,
    filter,
  };
}
