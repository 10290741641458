import fetchResource from '@src/js/api/fetch-resource';

export function getTemplates() {
  return fetchResource('api/admin/etinerary/template', {
    method: 'GET',
  });
}

export function updateTemplate(templateData, templateId) {
  return fetchResource(`api/admin/etinerary/template/${ templateId }`, {
    method: 'PUT',
    body: templateData,
  });
}
