import produce from 'immer';

import {
  GET_TRANSLATIONS_DATA_REQUEST,
  GET_TRANSLATIONS_DATA_SUCCESS,
  GET_TRANSLATIONS_DATA_FAIL,
  SEND_TRANSLATIONS_DATA_REQUEST,
  SEND_TRANSLATIONS_DATA_SUCCESS,
  SEND_TRANSLATIONS_DATA_FAIL,
} from '@app/store/translations/constants';

export const initialState = {
  loading: false,
  error: null,
  data: {},

  translationSent: {
    loading: false,
    error: false,
    isSent: false,
  },
};

/* eslint-disable default-case,no-param-reassign */
function translationsReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_TRANSLATIONS_DATA_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_TRANSLATIONS_DATA_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = action.payload;
        break;
      case GET_TRANSLATIONS_DATA_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case SEND_TRANSLATIONS_DATA_REQUEST:
        draft.translationSent = {
          loading: false,
          error: false,
          isSent: false,
        };
        break;
      case SEND_TRANSLATIONS_DATA_SUCCESS: {
        draft.translationSent = {
          loading: false,
          error: false,
          isSent: true,
        };
        const index = state.data.messages.findIndex((item) => item.name === action.data.name);
        draft.data.messages[index].lang[action.data.lang] = action.data.value;
        break;
      }
      case SEND_TRANSLATIONS_DATA_FAIL:
        draft.translationSent = {
          loading: false,
          error: action.error,
          isSent: false,
        };
        break;
    }
  }
  );
}

/* eslint-enable */

export default translationsReducer;
