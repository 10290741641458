import fetchResource from '@src/js/api/fetch-resource';

export function setPassword(token, email, password, url) {
  return fetchResource(url, {
    method: 'POST',
    body: {
      token,
      email,
      password,
    },
  });
}
