import CompanyOnboarding from '@app/components/public/onboarding/CompanyOnboarding';
import PartnerOnboarding from '@app/components/public/onboarding/PartnerOnboarding';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import PageLoader from '@src/js/components/global/pageLoader/PageLoader';
import { PARTNER_TYPES } from '@src/js/constants/entities';
import { Icons } from '@src/js/constants/icons';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { DEFAULT_LANGUAGE } from '@src/js/layouts/forms/company/CompanyForm';
import IntercomComponent from '@src/js/layouts/header/intercom/Intercom';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getTemplateData } from '@src/js/actions/onboarding/onboardingTemplate';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import OnboardingErrorPage from './OnboardingErrorPage';
import OnboardingSimpleTextPage from './OnboardingSimpleTextPage';
import OnboardingWelcomePage from './OnboardingWelcomePage';
import {
  OnboardingHeaderContainer,
} from './styled';

class OnboardingPublic extends Component {
  static propTypes = {
    onboarding: PropTypes.object,
    match: PropTypes.object,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const {
      match,
      dispatch,
    } = this.props;

    this.state = {
      showWelcomePage: true,
      showSectionOne: false,
      showSectionOneThankYouPage: false,
    };
    if (match.params.templateHash) {
      dispatch(getTemplateData(match.params.templateHash));
    }
  }

  showFirstStep = () => {
    this.setState({
      showWelcomePage: false,
      showSectionOne: true,
    });
  };

  renderHeader = () => (
    <OnboardingHeaderContainer>
      <Container>
        <NavLink
          className='main-navigation__logo'
          to={ withLang(routeCodes.HOME) }
        >
          <img src='/assets/img/svg/logo-white.svg' alt='logo' className='h-5' />
        </NavLink>
      </Container>
      <IntercomComponent hideLauncher={ false } />
    </OnboardingHeaderContainer>
  );

  render() {
    const { onboarding } = this.props;
    const {
      showWelcomePage, showSectionOne, showSectionOneThankYouPage,
    } = this.state;

    const isPartner = PARTNER_TYPES.includes(onboarding?.templateData?.kind);

    return (
      <>
        <MetaTitle title='onboarding.genericTitle' />
        { this.renderHeader() }
        { onboarding.error && <OnboardingErrorPage error={ onboarding.error } /> }
        { !onboarding.error && (
          <>
            { onboarding.loading && <PageLoader fixed /> }
            { onboarding.templateData && (
              <>
                { showWelcomePage && (
                  <OnboardingWelcomePage onClick={ this.showFirstStep } isPartner={ isPartner } />
                ) }
                { showSectionOne && (
                  isPartner
                    ? <PartnerOnboarding />
                    : <CompanyOnboarding />
                ) }
                { showSectionOneThankYouPage && (
                  <OnboardingSimpleTextPage
                    translation={ onboarding.translation }
                    caption={ 'onboarding.sectionOneThankYouPage.caption' }
                    image={ Icons.Sent }
                    content={ 'onboarding.sectionOneThankYouPage.content' }
                    headerText={ 'onboarding.thankYouPage.header' }
                    pageTitle={ 'onboarding.thankYouPage.pageTitle' }
                  />
                ) }
              </>
            ) }
          </>
        ) }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  onboarding: {
    templateData: state.onboardingTemplate.templateData,
    translation: state.initInfo.localizationMessages[state?.onboardingTemplate?.templateData?.lang || DEFAULT_LANGUAGE],
    loading: state.onboardingTemplate.templateLoading,
    error: state.onboardingTemplate.templateError,
  },
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(OnboardingPublic);

export default withRouter(connectWrapper);
