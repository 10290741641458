import fetchResource from '@src/js/api/fetch-resource';

export function validateToken(token, email, urlValidateToken) {
  return fetchResource(urlValidateToken, {
    method: 'POST',
    body: {
      token,
      email,
    },
  });
}
