import fetchResource from '@src/js/api/fetch-resource';

export function createMultipleTask(task) {
  return fetchResource('api/batch/taskCreate', {
    method: 'POST',
    body: task,
  });
}

export function changeStateMultipleTask(task) {
  return fetchResource('api/batch/taskState', {
    method: 'POST',
    body: task,
  });
}

export function createTask(task) {
  return fetchResource('api/tasks', {
    method: 'POST',
    body: task,
  });
}

export function setTaskStatus(task, statusName) {
  return fetchResource(`api/tasks/${ task }/status`, {
    method: 'POST',
    body: { statusToUpdate: statusName },
  });
}

export function getTaskList(filters) {
  let url = 'api/tasks';

  if (filters) {
    url += `?${ new URLSearchParams(filters) }`;
  }

  return fetchResource(url, {
    method: 'GET',
  });
}

export function getCompaniesForTaskCreation(filters) {
  let url = 'api/task/companies';

  if (filters) {
    const esc = encodeURIComponent;

    const query = Object.keys(filters)
      .map(k => `${ esc(k) }=${ esc(filters[k]) }`)
      .join('&');

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}

export function getCompaniesSlugsForTaskCreation(filters) {
  let url = 'api/task/companies/slugs';

  if (filters) {
    const esc = encodeURIComponent;

    const query = Object.keys(filters)
      .map(k => `${ esc(k) }=${ esc(filters[k]) }`)
      .join('&');

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}

export function getTaskCompaniesList(taskSlug, companiesSlugs, page, searchParam) {
  return fetchResource('api/task/companies/list', {
    method: 'POST',
    body: {
      taskSlug,
      companiesSlugs,
      page,
      searchParam,
    },
  });
}

export function getTaskDocuments(documentsIds) {
  return fetchResource('api/task/documents', {
    method: 'POST',
    body: { documentsIds },
  });
}

export function addTaskDocument(requestData) {
  return fetchResource(`api/task/documents/${ requestData.slug }`, {
    method: 'PUT',
    body: requestData.body,
  });
}

export function removeTaskDocument(documentId) {
  return fetchResource('api/task/documents', {
    method: 'DELETE',
    body: { documentId },
  });
}

export function getTaskPermissions(task) {
  return fetchResource(`api/tasks/permissions/${ task }`, {
    method: 'GET',
  });
}

export function getTaskData(task) {
  return fetchResource(`api/tasks/${ task }`, {
    method: 'GET',
  });
}
export function getHeaderData() {
  return fetchResource('api/task/notification', {
    method: 'GET',
  });
}

export function getTaskComments(task) {
  return fetchResource(`api/tasks/${ task }/comments`, {
    method: 'GET',
  });
}

export function addTaskComment(task, comment) {
  return fetchResource(`api/tasks/${ task }/comments`, {
    method: 'POST',
    body: { comment },
  });
}
