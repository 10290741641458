import { CLEAR_PARTNER_PUBLIC_PROFILE } from '@src/js/actions/partner/partners';
import {
  GET_PARTNER_BASIC_DATA_START,
  GET_PARTNER_BASIC_DATA_ERROR,
  GET_PARTNER_BASIC_DATA_SUCCESS,
} from '@src/js/actions/partner/partnerBasicData';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const actionsMap = {
  [GET_PARTNER_BASIC_DATA_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [CLEAR_PARTNER_PUBLIC_PROFILE]: () => initialState,
  [GET_PARTNER_BASIC_DATA_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [GET_PARTNER_BASIC_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    data: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
