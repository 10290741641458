import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loader } from '@src/js/components/global';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from '@app/components/global/forms/select/EliteSelect';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { selectUserInfo, selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import SafetyModal from '@src/js/views/admin/SafetyModal';
import { selectCompanyMembers, selectEventSubscribeMembersLoading } from '@app/store/event/selectors';
import { getMyCompanyMembersAction } from '@src/js/actions/user/user';
import { Event } from '@src/js/constants/entities';
import {
  EVENT_SUBSCRIBE_MEMBERS_FAIL, EVENT_SUBSCRIBE_MEMBERS_START, EVENT_SUBSCRIBE_MEMBERS_SUCCESS, getEventBasicData,
} from '@src/js/actions/events/events';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { useHistory } from 'react-router-dom';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import useFetch from '@app/services/useFetch';
import { dateFormat } from '@src/js/utils/date';
import EventSubscribeParticipantAccordion from './EventSubscribeParticipantAccordion';

const EventSubscribeParticipantModal = ({ event, actions }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { hideModal } = useGlobalModalContext();
  const userInfo = selectUserInfo();
  const companyMembers = selectCompanyMembers();
  const [confirmSubscriptionModal, setConfirmSubscriptionModal] = useState(false);
  const [participantsNumber, setParticipantsNumber] = useState(undefined);
  const [accordionIndex, setAccordionIndex] = useState(undefined);
  const eventSubscribeMembersLoading = selectEventSubscribeMembersLoading();
  const eventBasicData = useSelector(state => state.events?.eventBasicData);
  const eventParticipantsEmails = eventBasicData?.eventParticipants?.map(p => p.email) || [];
  const eventTabs = eventBasicData?.tabs || [];
  const activeLanguage = selectActiveLanguage();

  const methods = useForm({
    mode: 'all',
  });
  const {
    getValues, control, trigger, watch,
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'participant',
  });
  const defaultMember = {
    name: '',
    email: '',
    jobPosition: '',
    uuid: null,
    userSelect: null,
    location: event.location !== Event.LOCATION_MIXED ? event.location : null,
    dietaryRequirements: '',
    companyName: userInfo?.companyName,
  };
  if (event.dinner) {
    defaultMember.dinner = null;
    defaultMember.speaker = null;
  }

  const participants = watch('participant');
  const participantsUuid = participants?.map(usr => usr.uuid) || [];
  const membersOptions = (companyMembers && companyMembers
    .filter(member => !eventParticipantsEmails.includes(member.email) && !participantsUuid.includes(member.uuid))
    .map(companyMember => ({ label: companyMember.fullName, value: companyMember.uuid }))) || [];

  const participantOptions = [];
  const maxParticipants = event.maxParticipants;
  const currentRegistration = (participants || []).filter((it) => it.location === Event.LOCATION_PHYSICAL).length;
  const canRegisterDinner = (participants || [])
    .filter((participant) => participant.dinner).length < event.remainingDinner;
  const canRegisterSpeaker = (participants || [])
    .filter((participant) => participant.speaker).length < event.remainingSpeaker;

  const remainingPhysicalRegistrations = event.remainingPhysicalRegistrations - currentRegistration;
  const date = dateFormat(event?.physicalCloseRegistrationDate, 'dd MMM yyyy', activeLanguage);
  const time = dateFormat(event?.physicalCloseRegistrationDate, 'HH:mm');

  for (let i = 1; i <= event.remainingDigitalRegistrations; i++) {
    participantOptions.push({ value: i, label: i });
  }

  const updateFormArray = () => {
    const changes = [];
    if (participantsNumber > fields.length) {
      for (let i = fields.length; i < participantsNumber; i++) {
        changes.push(defaultMember);
      }
      append(changes);
    } else if (participantsNumber < fields.length) {
      for (let i = fields.length; i > participantsNumber; i--) {
        changes.push(i - 1);
      }
      remove(changes);
    }
  };

  const checkForm = async () => {
    const valid = await trigger();
    if (valid) {
      setConfirmSubscriptionModal(true);
    }
  };

  const redirect = () => {
    if (!event.slug || !eventTabs?.includes('community')) return;
    if (!history.location?.pathname.endsWith(`${ event.slug }/community`)) {
      history.push(withLang(`${ routeCodes.TRAININGS }/${ event.slug }/community`));
      dispatch(showToastrSuccess('', intl.formatMessage({ id: 'trainingCommunity.contact' }), false));
    }
  };

  const executeActions = () => {
    dispatch(showToastrSuccess());
    dispatch({ type: EVENT_SUBSCRIBE_MEMBERS_SUCCESS });
    if (!actions) return;
    if (Array.isArray(actions)) {
      actions.forEach(action => dispatch(action));
    } else {
      dispatch(actions);
    }
  };

  const submitdata = () => (
    getValues('participant')?.map(p => {
      if (p?.userSelect && typeof p.userSelect === 'string' && !p?.uuid) {
        return { ...p, uuid: p.userSelect };
      }
      return p;
    })
  );

  const { request: subscribeMembers } = useFetch(
    `api/event/${ event.slug }/subscribe`,
    {
      method: 'PUT',
      body: { subscribe: submitdata() },
    },
    false,
    () => {
      executeActions();
      redirect();
      hideModal();
    },
    (error) => {
      dispatch(showToastrError('notification.error_title', error?.response.description || 'notification.submit.error'));
      dispatch({ type: EVENT_SUBSCRIBE_MEMBERS_FAIL });
    }
  );

  const submit = () => {
    dispatch({ type: EVENT_SUBSCRIBE_MEMBERS_START });
    setConfirmSubscriptionModal(false);
    subscribeMembers();
  };

  useEffect(() => {
    dispatch(getMyCompanyMembersAction());
    dispatch(getEventBasicData(event.slug));
  }, []);

  useEffect(() => {
    if (event.remainingDigitalRegistrations === 1) {
      setParticipantsNumber(1);
    }
  }, [event]);

  useEffect(() => {
    updateFormArray();
    setAccordionIndex(0);
  }, [participantsNumber]);

  return (
    <>
      <ModalBody>
        { eventSubscribeMembersLoading && <Loader />}
        <div className='event-subscribe-modal'>
          <div className='event-subscribe-modal__header'>
            <div>
              <FormattedMessage
                id={ event.location === Event.LOCATION_MIXED
                  ? 'subscribeModal.description.mixed' : 'subscribeModal.description' }
                values={ {
                  maxParticipants,
                  maxPhysicalParticipants: event.remainingPhysicalRegistrations,
                } }
              />
            </div>
            { time && date && event.location !== Event.LOCATION_DIGITAL
            && event.remainingPhysicalRegistrations > 0 && (
              <div>
                <FormattedMessage id='subscribeModal.physicalCloseRegistrationDate' values={ { time, date } } />
              </div>
            )
            }
            <div>
              { event.attending > 0 && (
                <FormattedMessage
                  id={ `subscribeModal.question.add${
                    event.location === Event.LOCATION_MIXED ? '.mixed' : '' }`
                  }
                  values={ { attending: event.eventParticipants?.length, physicalAttending: event.physicalAttendees } }
                />
              ) }
            </div>
            { !event.closePhysicalRegistrations && event.remainingPhysicalRegistrations === 0
                && event.maxPhysicalParticipants !== event.physicalAttendees && (
                <div>
                  <FormattedMessage id='subscribeModal.maxPhysicalReached' />
                </div>
            )}
            { event.location === Event.LOCATION_MIXED && event.closePhysicalRegistrations && (
              <div>
                <FormattedMessage id='subscribeModal.closedPhysicalRegistrations' />
              </div>
            )}
            { event.dinner && (event.remainingDinner > 0 || event.remainingSpeaker > 0) && (
              <>
                <div>
                  <FormattedMessage
                    id='subscribeModal.dinner.description'
                    values={ { dinnerNumber: event.remainingDinner } }
                  />
                </div>
                { event.speaker && !!event.remainingSpeaker && (
                  <div>
                    <FormattedMessage
                      id='subscribeModal.dinner.description.speaker'
                      values={ { speakerNumber: event.remainingSpeaker } }
                    />
                  </div>
                ) }
              </>
            ) }
            { event.remainingDigitalRegistrations > 1 && (
            <div className='event-subscribe-modal__select'>
              <span>
                <FormattedMessage
                  id='subscribeModal.question.register'
                />
              </span>
              <Select
                className='filters m-l-1'
                value={ { value: participantsNumber, label: participantsNumber } }
                options={ participantOptions }
                placeholder=''
                onChange={ (e) => {
                  setParticipantsNumber(e?.value);
                } }
                clearable={ false }
              />
            </div>
            ) }
          </div>
          <FormProvider { ...methods }>
            <form>
              {fields.map((item, index) => (
                <EventSubscribeParticipantAccordion
                  key={ item.id }
                  methods={ { ...methods } }
                  item={ item }
                  setAccordionIndex={ setAccordionIndex }
                  accordionIndex={ accordionIndex }
                  index={ index }
                  membersOptions={ membersOptions }
                  defaultMember={ defaultMember }
                  location={ event.location }
                  companyMembers={ companyMembers }
                  canRegisterPhysical={ remainingPhysicalRegistrations > 0 }
                  isDinnerChecked={ event.dinner }
                  isSpeakerChecked={ event.speaker }
                  canRegisterDinner={ canRegisterDinner }
                  canRegisterSpeaker={ canRegisterSpeaker }
                />
              ))}
            </form>
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='dialog.confirm'
          onClickFunc={ checkForm }
          disabled={ !participantsNumber }
        />
        <Button
          size='lg'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
      <SafetyModal
        isOpen={ confirmSubscriptionModal }
        cancelFunc={ () => setConfirmSubscriptionModal(false) }
        yesFunc={ submit }
        message='subscribeModal.confirmation'
      />
    </>
  );
};

EventSubscribeParticipantModal.propTypes = {
  event: PropTypes.object.isRequired,
  actions: PropTypes.array,
};

export default EventSubscribeParticipantModal;
