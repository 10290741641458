export const ADMIN_GET_INDEX_NEWS_START = 'ADMIN_GET_INDEX_NEWS_START';
export const ADMIN_GET_INDEX_NEWS_ERROR = 'ADMIN_GET_INDEX_NEWS_ERROR';
export const ADMIN_GET_INDEX_NEWS_SUCCESS = 'ADMIN_GET_INDEX_NEWS_SUCCESS';

export function adminGetNews(filters) {
  return {
    type: ADMIN_GET_INDEX_NEWS_START,
    filters,
  };
}
