import globalMessages from '@src/js/oneplatform/messages';

const scope = 'Membership';

const ADMIN_GET_LIST_PROCESSES_REQUESTED = `${ scope }/ADMIN_GET_LIST_PROCESSES_REQUESTED`;
const ADMIN_GET_LIST_PROCESSES_SUCCEEDED = `${ scope }/ADMIN_GET_LIST_PROCESSES_SUCCEEDED`;
const ADMIN_GET_LIST_PROCESSES_FAILED = `${ scope }/ADMIN_GET_LIST_PROCESSES_FAILED`;

const ADMIN_GET_LIST_TEMPLATES_REQUESTED = `${ scope }/ADMIN_GET_LIST_TEMPLATES_REQUESTED`;
const ADMIN_GET_LIST_TEMPLATES_SUCCEEDED = `${ scope }/ADMIN_GET_LIST_TEMPLATES_SUCCEEDED`;
const ADMIN_GET_LIST_TEMPLATES_FAILED = `${ scope }/ADMIN_GET_LIST_TEMPLATES_FAILED`;

const GET_FORM_COMPANY_OPTIONS_REQUESTED = `${ scope }/GET_FORM_COMPANY_OPTIONS_REQUESTED`;
const GET_FORM_COMPANY_OPTIONS_SUCCEEDED = `${ scope }/GET_FORM_COMPANY_OPTIONS_SUCCEEDED`;
const GET_FORM_COMPANY_OPTIONS_FAILED = `${ scope }/GET_FORM_COMPANY_OPTIONS_FAILED`;

const SUBMIT_MEMBERSHIP_FORM_REQUESTED = `${ scope }/SUBMIT_MEMBERSHIP_FORM_REQUESTED`;
const SUBMIT_MEMBERSHIP_FORM_SUCCEEDED = `${ scope }/SUBMIT_MEMBERSHIP_FORM_SUCCEEDED`;
const SUBMIT_MEMBERSHIP_FORM_FAILED = `${ scope }/SUBMIT_MEMBERSHIP_FORM_FAILED`;

const ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED = `${ scope }/ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED`;
const ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED = `${ scope }/ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED`;
const ADMIN_GET_TEMPLATE_OPTIONS_FAILED = `${ scope }/ADMIN_GET_TEMPLATE_OPTIONS_FAILED`;

const ADMIN_APPROVE_PROCESS_REQUESTED = `${ scope }/ADMIN_APPROVE_PROCESS_REQUESTED`;
const ADMIN_APPROVE_PROCESS_SUCCEEDED = `${ scope }/ADMIN_APPROVE_PROCESS_SUCCEEDED`;
const ADMIN_APPROVE_PROCESS_FAILED = `${ scope }/ADMIN_APPROVE_PROCESS_FAILED`;

const HANDLE_TEMPLATE_TRAININGS_REQUESTED = `${ scope }/HANDLE_TEMPLATE_TRAININGS_REQUESTED`;
const HANDLE_TEMPLATE_TRAININGS_SUCCEDED = `${ scope }/HANDLE_TEMPLATE_TRAININGS_SUCCEDED`;
const HANDLE_TEMPLATE_TRAININGS_FAILED = `${ scope }/HANDLE_TEMPLATE_TRAININGS_FAILED`;

const CREATE_TEMPLATE_CLEAR = `${ scope }/CREATE_TEMPLATE_CLEAR`;

const BASE_FILTERS = {
  countries: '',
  name: '',
  page: 1,
  programme: [],
};

const {
  processes: {
    completionPercentage,
  },
  templates,
  dateCompletion,
  processes,
  url,
  action,
} = globalMessages;

const ITEMS_MEMBERSHIP_THEAD = {
  companyName: {
    ...processes.companyName,
    width: 25,
  },
  templateName: templates.templateName,
  completionPercentage,
  dateCompletion,
  action,
};

const ITEMS_TEMPLATES_THEAD = {
  templateName: globalMessages.name,
  url,
  dateCreation: templates.dateCreation,
  status: globalMessages.status,
  action,
};

const PROCESSES = 'processes';
const PROGRAMMES = 'programmes';
const TEMPLATES = 'templates';

export {
  ADMIN_GET_LIST_PROCESSES_REQUESTED,
  ADMIN_GET_LIST_PROCESSES_SUCCEEDED,
  ADMIN_GET_LIST_PROCESSES_FAILED,
  ADMIN_GET_LIST_TEMPLATES_REQUESTED,
  ADMIN_GET_LIST_TEMPLATES_SUCCEEDED,
  ADMIN_GET_LIST_TEMPLATES_FAILED,
  GET_FORM_COMPANY_OPTIONS_REQUESTED,
  GET_FORM_COMPANY_OPTIONS_SUCCEEDED,
  GET_FORM_COMPANY_OPTIONS_FAILED,
  BASE_FILTERS,
  ITEMS_MEMBERSHIP_THEAD,
  ITEMS_TEMPLATES_THEAD,
  PROCESSES,
  PROGRAMMES,
  TEMPLATES,
  SUBMIT_MEMBERSHIP_FORM_REQUESTED,
  SUBMIT_MEMBERSHIP_FORM_SUCCEEDED,
  SUBMIT_MEMBERSHIP_FORM_FAILED,
  ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED,
  ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED,
  ADMIN_GET_TEMPLATE_OPTIONS_FAILED,
  ADMIN_APPROVE_PROCESS_REQUESTED,
  ADMIN_APPROVE_PROCESS_SUCCEEDED,
  ADMIN_APPROVE_PROCESS_FAILED,
  HANDLE_TEMPLATE_TRAININGS_REQUESTED,
  HANDLE_TEMPLATE_TRAININGS_SUCCEDED,
  HANDLE_TEMPLATE_TRAININGS_FAILED,
  CREATE_TEMPLATE_CLEAR,
};
