export const ADMIN_GET_COMPANY_START = 'ADMIN_GET_COMPANY_START';
export const ADMIN_GET_COMPANY_ERROR = 'ADMIN_GET_COMPANY_ERROR';
export const ADMIN_GET_COMPANY_SUCCESS = 'ADMIN_GET_COMPANY_SUCCESS';
export const ADMIN_CREATE_COMPANY_START = 'ADMIN_CREATE_COMPANY_START';
export const ADMIN_CREATE_COMPANY_ERROR = 'ADMIN_CREATE_COMPANY_ERROR';
export const ADMIN_CREATE_COMPANY_SUCCESS = 'ADMIN_CREATE_COMPANY_SUCCESS';
export const ADMIN_EDIT_COMPANY_START = 'ADMIN_EDIT_COMPANY_START';
export const ADMIN_EDIT_COMPANY_ERROR = 'ADMIN_EDIT_COMPANY_ERROR';
export const ADMIN_EDIT_COMPANY_SUCCESS = 'ADMIN_EDIT_COMPANY_SUCCESS';
export const ADMIN_DISABLE_COMPANY_START = 'ADMIN_DISABLE_COMPANY_START';
export const ADMIN_DISABLE_COMPANY_ERROR = 'ADMIN_DISABLE_COMPANY_ERROR';
export const ADMIN_DISABLE_COMPANY_SUCCESS = 'ADMIN_DISABLE_COMPANY_SUCCESS';
export const EDIT_COMPANY_FINANCIALS_START = 'EDIT_COMPANY_FINANCIALS_START';
export const EDIT_COMPANY_FINANCIALS_ERROR = 'EDIT_COMPANY_FINANCIALS_ERROR';
export const EDIT_COMPANY_FINANCIALS_SUCCESS = 'EDIT_COMPANY_FINANCIALS_SUCCESS';
export const UPDATE_COMPANY_FINANCIALS_START = 'UPDATE_COMPANY_FINANCIALS_START';
export const UPDATE_COMPANY_FINANCIALS_ERROR = 'UPDATE_COMPANY_FINANCIALS_ERROR';
export const UPDATE_COMPANY_FINANCIALS_SUCCESS = 'UPDATE_COMPANY_FINANCIALS_SUCCESS';

export function adminGetCompany(slug) {
  return {
    type: ADMIN_GET_COMPANY_START,
    slug,
  };
}

export function adminEditCompany(company) {
  return {
    type: ADMIN_EDIT_COMPANY_START,
    company,
  };
}

export function adminCreateCompany(company, userType) {
  return {
    type: ADMIN_CREATE_COMPANY_START,
    company,
    userType,
  };
}

export function adminDisableCompany(slug) {
  return {
    type: ADMIN_DISABLE_COMPANY_START,
    slug,
  };
}

export function editCompanyFinancials(slug, lang) {
  return {
    type: EDIT_COMPANY_FINANCIALS_START,
    slug,
    lang,
  };
}

export function updateCompanyFinancials(data, lang) {
  return {
    type: UPDATE_COMPANY_FINANCIALS_START,
    data,
    lang,
  };
}
