import React from 'react';
import PropTypes from 'prop-types';
import ClampLines from 'react-clamp-lines';
import { Link } from 'react-router-dom';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { dateFormat } from '@src/js/utils/date';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';

const WidgetNewsCard = ({ news, disabled }) => {
  const activeLanguage = selectActiveLanguage();
  const urlPath = `${ withLang(routeCodes.NEWS) }/${ news.slug }`;
  return (
    <div className='card card-news card-news-row card-widget-news'>
      { !disabled ? (
        <Link
          className='news-image'
          to={ urlPath }
        >
          <img alt={ news.title } src={ news.image } />
        </Link>
      ) : (
        <div className='news-image'>
          <img alt={ news.title } src={ news.image } />
        </div>
      )}
      <div className='card-body'>
        <div className='news-category-date'>
          <div className='news-category'>{ news.type_text }</div>
          <div className='news-date'>{ dateFormat(news.date, 'dd MMM yyyy', activeLanguage) }</div>
        </div>
        { !disabled ? (
          <Link
            className='card-title underline'
            to={ urlPath }
          >
            <ClampLines
              text={ news.title }
              buttons={ false }
              lines={ 2 }
            />
          </Link>
        ) : (
          <div className='card-title underline'>
            <ClampLines
              text={ news.title }
              buttons={ false }
              lines={ 2 }
            />
          </div>
        )}
        <div className='news-category-date-bottom'>
          { dateFormat(news.date, 'dd MMM yyyy', activeLanguage) }
        </div>
      </div>
    </div>
  );
};

WidgetNewsCard.propTypes = {
  news: PropTypes.object,
  disabled: PropTypes.bool,
};

export default WidgetNewsCard;
