import styled from 'styled-components';
import { ThemeColors } from '@src/_v2/js/constants';

export const Icon = styled.span`
  vertical-align: middle;
  font-size: 1.6rem;
  margin-right: 1rem;
  color: ${ ThemeColors.blue };
  cursor: pointer;
`;
