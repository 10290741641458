import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function uploadFile(file) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/file/upload`, {
    method: 'POST',
    externalUrl: true,
    body: file,
    noContentType: true,
  });
}

export function crossSellingPopup() {
  return fetchResource('api/cross-selling/popup', {
    method: 'GET',
  });
}

export function crossSellingView() {
  return fetchResource('api/cross-selling/view', {
    method: 'POST',
    noContentType: true,
  });
}

export function getDigitalSpecialistContact() {
  return fetchResource('public/user/digital-specialist-contact', {
    method: 'GET',
  });
}
