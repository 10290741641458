import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError } from '@app/store/global/actions';
import * as api from '@app/store/reminderArchive/api';
import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
} from '@app/store/reminderArchive/actions';

function* getReminderArchiveList({ params }) {
  try {
    const data = yield call(() => api.getReminderArchiveList(params));
    yield put({ type: GET_DATA_SUCCESS, data });
  } catch (error) {
    yield all([
      put({ type: GET_DATA_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_DATA_START, getReminderArchiveList);
}

export default root;
