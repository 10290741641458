export const GET_USER_START = 'GET_USER_START';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_MY_PROFILE_START = 'GET_MY_PROFILE_START';
export const GET_MY_PROFILE_ERROR = 'GET_MY_PROFILE_ERROR';
export const GET_MY_PROFILE_SUCCESS = 'GET_USER_SUCCESS';
export const GET_MY_COMPANY_MEMBERS_START = 'GET_MY_COMPANY_MEMBERS_START';
export const GET_MY_COMPANY_MEMBERS_ERROR = 'GET_MY_COMPANY_MEMBERS_ERROR';
export const GET_MY_COMPANY_MEMBERS_SUCCESS = 'GET_COMPANY_MEMBERS_SUCCESS';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_MY_PROFILE_START = 'EDIT_MY_PROFILE_START';
export const EDIT_MY_PROFILE_ERROR = 'EDIT_MY_PROFILE_ERROR';
export const EDIT_MY_PROFILE_SUCCESS = 'EDIT_MY_PROFILE_SUCCESS';
export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_START = 'LOGOUT_USER_START';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const IMPERSONATE_USER_START = 'IMPERSONATE_USER_START';
export const IMPERSONATE_USER_ERROR = 'IMPERSONATE_USER_ERROR';
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS';
export const EXIT_IMPERSONATE_SESSION_START = 'EXIT_IMPERSONATE_SESSION_START';
export const EXIT_IMPERSONATE_SESSION_ERROR = 'EXIT_IMPERSONATE_SESSION_ERROR';
export const EXIT_IMPERSONATE_SESSION_SUCCESS = 'EXIT_IMPERSONATE_SESSION_SUCCESS';
export const CLEAR_USER_WIDGET = 'CLEAR_USER_WIDGET';
export const RECREATE_USER_TOKEN_START = 'RECREATE_USER_TOKEN_START';
export const RECREATE_USER_TOKEN_ERROR = 'RECREATE_USER_TOKEN_ERROR';
export const RECREATE_USER_TOKEN_SUCCESS = 'RECREATE_USER_TOKEN_SUCCESS';
export const UNLOCK_USER_START = 'UNLOCK_USER_START';
export const UNLOCK_USER_ERROR = 'UNLOCK_USER_ERROR';
export const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS';

export function loginUser(credentialsData) {
  return {
    type: LOGIN_USER_START,
    credentialsData,
  };
}

export function getUser(id) {
  return {
    type: GET_USER_START,
    id,
  };
}

export function getMyProfile() {
  return {
    type: GET_MY_PROFILE_START,
  };
}

export function getMyCompanyMembersAction() {
  return {
    type: GET_MY_COMPANY_MEMBERS_START,
  };
}

export function editUser(user) {
  return {
    type: EDIT_USER_START,
    user,
  };
}

export function editMyProfile(user, history) {
  return {
    type: EDIT_MY_PROFILE_START,
    user,
    history,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER_START,
  };
}

export function impersonateUserAction(user) {
  return {
    type: IMPERSONATE_USER_START,
    user,
  };
}

export function exitImpersonateSession() {
  return {
    type: EXIT_IMPERSONATE_SESSION_START,
  };
}

export function recreateUserToken(userData, urlRecreateUserToken) {
  return {
    type: RECREATE_USER_TOKEN_START,
    userData,
    urlRecreateUserToken,
  };
}

export function unlockUser(uuid) {
  return {
    type: UNLOCK_USER_START,
    uuid,
  };
}
