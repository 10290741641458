import { useSelector } from 'react-redux';

const selectPeersList = () => useSelector((state) => state.users?.peersList);
const selectPeersListHasMore = () => useSelector((state) => state.users?.peersListHasMore);
const selectReferrers = () => useSelector((state) => state.users?.referrersList);
const selectReferrersLoading = () => useSelector((state) => state.users?.referrersLoading);
const selectAddReferrers = () => useSelector((state) => state.users?.addReferrers);

export {
  selectPeersList,
  selectReferrers,
  selectAddReferrers,
  selectPeersListHasMore,
  selectReferrersLoading,
};
