import { store } from '@src/js/store';
import loadEnvVariable from '@src/js/static/LoadEnv';

export const apiPath = () => loadEnvVariable('API_PATH');
export const ectPath = () => loadEnvVariable('COMPLIANCE_URL');

export const langRouteParam = '/:language(en|it|fr|es|pt|ar)?';

export const publicPath = `${ langRouteParam }/`;
export const adminPath = `${ publicPath }admin/`;
export const defaultPath = `${ publicPath }:page*`;
export const onboardingBasePath = `${ publicPath }onboarding/`;

export const routeCodes = {
  HOME: `${ publicPath }`,
  LOGIN: `${ publicPath }login`,
  SELF_REGISTRATION: `${ publicPath }self-registration`,
  OPPORTUNITIES: `${ publicPath }opportunities`,
  EOP_FUNDING: `${ publicPath }opportunities/funding`,
  TRANSACTIONS: `${ publicPath }opportunities/transactions`,
  BASKET_BONDS: `${ publicPath }opportunities/basket-bonds`,
  FUNDING_IPO: `${ publicPath }opportunities/ipo`,
  FUNDING_DATA_ROOM_FOLDER: `${ publicPath }opportunities/data-room/:folderId([0-9]*)?`,
  FUNDING_DATA_ROOM: `${ publicPath }opportunities/data-room`,
  FINANZA_AGEVOLATA: `${ publicPath }opportunities/finanza-agevolata`,
  FUNDING_SUCCESS_STORY: `${ publicPath }opportunities/success-story`,
  SUCCESS_STORIES: `${ publicPath }opportunities/story`,
  INTRODUCTION_SERVICES: `${ publicPath }opportunities/introduction-services`,
  BROKER: `${ publicPath }opportunities/broker`,
  DASHBOARD: `${ publicPath }dashboard`,
  SEARCH: `${ publicPath }search`,
  MESSAGES: `${ publicPath }messages`,
  MESSAGES_THREAD: `${ publicPath }messages/:thread`,
  TASKS: `${ publicPath }tasks`,
  SET_PASSWORD: `${ publicPath }users/password/set`,
  RESET_PASSWORD: `${ publicPath }users/reset-password`,
  REQUEST_RESET_PASSWORD: `${ publicPath }users/password/reset`,
  MY_PROFILE: `${ publicPath }profile`,
  MY_COMPANIES: `${ publicPath }my_companies`,
  COMPANY: `${ publicPath }companies/:slug`,
  COMPANY_FINANCIALS: `${ publicPath }companies/:slug/financials`,
  COMPANY_DOCUMENTS: `${ publicPath }companies/:slug/documents`,
  COMPANY_VIEWS: `${ publicPath }companies/:slug/views`,
  MY_COMPANY: `${ publicPath }myCompany`,
  MY_COMPANY_EDIT: `${ publicPath }myCompany/edit`,
  MY_COMPANY_FINANCIALS: `${ publicPath }myCompany/financials`,
  MY_COMPANY_FINANCIALS_EDIT: `${ publicPath }myCompany/financials/edit`,
  MY_COMPANY_DOCUMENTS: `${ publicPath }myCompany/documents`,
  MY_COMPANY_VIEWS: `${ publicPath }myCompany/views`,
  MY_COMPANY_TASKS: `${ publicPath }myTasks`,
  COMPANY_TASKS: `${ publicPath }companies/:slug/tasks`,
  COMPANY_TASKS_CREATE: `${ publicPath }companies/:slug/tasks/create`,
  COMPANY_TRAININGS: `${ publicPath }companies/:slug/trainings`,
  COMPANY_TASKS_EDIT: `${ publicPath }companies/:slug/tasks/:taskSlug`,
  COMPANY_DIGITAL_COMPASS_REPORT: `${ apiPath() }/digital-compass/company`,
  COMPANIES: `${ publicPath }companies`,
  COMPANY_PDF_GENERATOR: `${ apiPath() }/company/:slug/language/:language/download/pdf`,
  COMPANY_ETINERARY_TERMS: `${ apiPath() }/admin/company/:slug/etinerary/download`,
  MY_DATAROOMS: `${ publicPath }data-rooms`,
  MY_ENTITY: `${ publicPath }myCompany`,
  EDIT_COMPANIES: `${ publicPath }companies/edit`,
  PARTNER: `${ publicPath }partners/:slug`,
  PARTNER_DOCUMENTS: `${ publicPath }partners/:slug/documents`,
  PARTNER_VIEWS: `${ publicPath }partners/:slug/views`,
  MY_PARTNER: `${ publicPath }partners/:slug`,
  MY_PARTNER_DOCUMENTS: `${ publicPath }partner/:slug/documents`,
  MY_PARTNER_VIEWS: `${ publicPath }partner/:slug/views`,
  PARTNERS: `${ publicPath }partners`,
  SINGLE_PARTNER: `${ publicPath }partner`,
  PARTNER_COMPANIES: `${ publicPath }partners/:slug/companies`,
  RELATIONSHIP_MANAGER: `${ publicPath }relationship-manager/companies`,
  COMPASS_TEASER: `${ publicPath }compass/teaser`,
  COMPASS_TYPE_GROWTH: loadEnvVariable('COMPASS_TYPE_GROWTH'),
  COMPASS_TYPE_GROWTH_REPORT: `${ loadEnvVariable('COMPASS_TYPE_GROWTH') }/show-pdf/`,
  COMPASS_TYPE_DIGITAL: loadEnvVariable('COMPASS_TYPE_DIGITAL'),
  COMPASS_TYPE_DIGITAL_REPORT: `${ apiPath() }/digital-compass/partner`,
  NETWORK: `${ publicPath }network`,
  DIRECTORY: `${ publicPath }network/directory`,
  LIBRARY: `${ publicPath }network/library`,
  LIBRARY_ALL: `${ publicPath }network/library/view-all`,
  EVENTS: `${ publicPath }network/events`,
  DIRECTORY_VIEW_ALL: `${ publicPath }network/directory/view-all/:section`,
  SINGLE_LIBRARY: `${ publicPath }network/library/:slug`,
  MARKETPLACE: `${ publicPath }network/marketplace`,
  MARKETPLACE_MATCHMAKING: `${ publicPath }network/marketplace/matchmaking`,
  MARKETPLACE_ALL_OPPORTUNITIES: `${ publicPath }network/marketplace/all-opportunities`,
  MARKETPLACE_ADMIN: `${ publicPath }network/marketplace/admin`,
  MARKETPLACE_BACKOFFICE: `${ publicPath }network/marketplace/backoffice`,
  PROGRAMME: `${ publicPath }programme`,
  OLD_COMPANY_DATA_ROOM: `${ publicPath }companies/:slug/data_room`,
  COMPANY_DATA_ROOM: `${
    publicPath }companies/:slug/data-room/:type(funding|company)?/:training(training)?/:folderId([0-9]*)?`,
  MY_COMPANY_DATA_ROOM: `${ publicPath }myCompany/data-room/:type(funding)?/:training(training)?/:folderId([0-9]*)?`,
  MY_COMPANY_DATA_ROOM_BASE: `${ publicPath }myCompany/data-room`,
  USER_DATA_ROOM: `${ publicPath }data-room/:slug/:type(funding)/:folderId([0-9]*)?`,
  DOCUMENTS: `${ publicPath }programme/documents`,
  PROGRAMME_DOCUMENTS: `${ publicPath }programme/documents/:training(training)?/:folderId([0-9]*)?`,
  NEWS: `${ publicPath }programme/news`,
  NEWS_LIST: `${ publicPath }programme/news/list/:type`,
  SINGLE_NEWS: `${ publicPath }programme/news/:slug`,
  CREATE_NEWS: `${ publicPath }programme/news/create`,
  TRAININGS: `${ publicPath }programme/trainings`,
  VIDEO_ON_DEMAND: `${ publicPath }programme/video-on-demand`,
  PARTNER_LIBRARIES: `${ publicPath }partner/library`,
  PARTNER_LIBRARIES_CREATE: `${ publicPath }partner/library/create`,
  PARTNER_LIBRARIES_EDIT: `${ publicPath }partner/library/:slug/edit`,
  FORBIDDEN: `${ publicPath }forbidden`,
  IMAGE_LOGO: `${ apiPath() }/api/image/:entityName/:entityId/logo?size=company-profile&modified=:entityModified`,
  ONBOARDING_LOGIN: `${ onboardingBasePath }login`,
  ONBOARDING_SET_PASSWORD: `${ onboardingBasePath }users/password/set`,
  ONBOARDING_RESET_PASSWORD: `${ onboardingBasePath }users/reset-password`,
  ONBOARDING_REQUEST_RESET_PASSWORD: `${ onboardingBasePath }users/password/reset`,
  ONBOARDING_FORM: `${ onboardingBasePath }process/:processHash`,
  ONBOARDING_PUBLIC_FORM: `${ onboardingBasePath }public/:templateHash`,
  OPPORTUNITY_PROFILE: `${ publicPath }network/marketplace/profile/:id`,
  OPPORTUNITY_FORM: `${ publicPath }network/marketplace/opportunity`,
  DEAL_PAGE_BASE: `${ publicPath }opportunities/deal`,
  DEAL_PAGE: `${ publicPath }opportunities/deal/:id`,
  DEAL_BOOK_PAGE: `${ publicPath }opportunities/deal/:id/book`,
  MEMBERSHIP_FORM: `${ publicPath }membership/process/:templateHash`,
  SUCCESS_STORY: `${ publicPath }opportunities/story/:slug`,
  COMPLIANCE: `${ publicPath }compliance/:slug`,
  COMPLIANCE_LIST: `${ publicPath }compliance`,
  ADDENDUM_MANAGEMENT: `${ publicPath }document/sign/:uuid`,
  CERTIFICATES: `${ publicPath }certificates`,
  TOPIC_PAGE: `${ publicPath }topic/:slug`,
};

export const externalRoutes = ['funding', 'compass', 'coursera', 'babelee', 'growth_compass'];
export const homeRouteChildren = [
  'dashboard',
  'compass',
  'my_entity',
  'certificate',
  'myCompany',
  'partner_companies',
  'relationship_manager',
];
export const dynamicRoutes = ['my_entity', 'partner_companies', 'relationship_manager'];

export const adminRouteCodes = {
  USERS: `${ adminPath }users`,
  COMPANY: `${ adminPath }companies/:slug`,
  COMPANIES: `${ adminPath }companies`,
  PROGRAMMES: `${ adminPath }programmes`,
  PARTNERS: `${ adminPath }partners`,
  EVENTS: `${ adminPath }events`,
  EVENT: `${ adminPath }event`,
  MESSAGES: `${ publicPath }messages`,
  DATA_ROOM: `${ publicPath }data-room`,
  CONTENT: `${ adminPath }content`,
  NEWS: `${ adminPath }content/news`,
  CREATE_NEWS: `${ adminPath }content/news/create`,
  EDIT_NEWS: `${ adminPath }content/news/:slug/edit`,
  LIBRARIES: `${ adminPath }content/libraries`,
  BROKERS: `${ adminPath }content/brokers`,
  TOPIC: `${ adminPath }content/topic`,
  TOPIC_CREATE: `${ adminPath }content/topic/create`,
  TOPIC_EDIT: `${ adminPath }content/topic/edit/:id`,
  PATHS: `${ adminPath }content/paths`,
  PATHS_CREATE: `${ adminPath }content/paths/create`,
  PATHS_EDIT: `${ adminPath }content/paths/edit/:id`,
  BANNER: `${ adminPath }content/banner`,
  BANNER_CREATE: `${ adminPath }content/banner/create`,
  BANNER_EDIT: `${ adminPath }content/banner/edit/:id`,
  BROKER_EDIT: `${ adminPath }content/brokers/:id/edit`,
  BROKER_CREATE: `${ adminPath }content/brokers/create`,
  CREATE_LIBRARY: `${ adminPath }content/libraries/create`,
  EDIT_LIBRARY: `${ adminPath }content/libraries/:slug/edit`,
  TRANSLATIONS: `${ adminPath }content/translations`,
  PROMOTED: `${ adminPath }content/promoted`,
  NETWORK: `${ adminPath }content/network`,
  ONBOARDING: `${ adminPath }onboarding`,
  ONBOARDING_PROCESSES: `${ adminPath }onboarding/admissions`,
  ONBOARDING_TEMPLATES: `${ adminPath }onboarding/templates`,
  ONBOARDING_ADMIN_FORM: `${ adminPath }onboarding/process/:processHash`,
  ONBOARDING_PARTNER_FORM: `${ adminPath }onboarding/partner/:processHash`,
  ONBOARDING_PARTNER_DETAILS: `${ adminPath }onboarding/partner/:processHash/details`,
  ONBOARDING_ADMIN_DETAILS: `${ adminPath }onboarding/process/:processHash/details`,
  BULK_MESSAGE: `${ adminPath }bulk_message`,
  MEMBERSHIP: `${ adminPath }membership`,
  MEMBERSHIP_FORM: `${ adminPath }membership/process/:templateHash`,
  MEMBERSHIP_ADMIN_DETAILS: `${ adminPath }membership/process/:processHash/details`,
  ADD_TRAININGS: `${ adminPath }companies/:slug/:id/add-trainings`,
  COMPLIANCE: `${ adminPath }compliance`,
  COMPLIANCE_EDIT: `${ adminPath }compliance/:slug`,
  DEALS: `${ adminPath }content/deals`,
  DEAL_EDIT: `${ adminPath }content/deals/:id/edit`,
  DEAL_CREATE: `${ adminPath }content/deals/create`,
};

export const legalLinks = {
  TERMS: 'https://www.elite-network.com/terms-and-policies',
  TERMS_IT: 'https://www.elite-network.com/it/terms-and-policies ',
  RISK_WARNING: 'https://www.elite-network.com/risk-warning',
  RISK_WARNING_IT: 'https://www.elite-network.com/it/risk-warning',
};

export function withLang(route) {
  const activeLanguage = store.getState().initInfo?.activeLanguage ?? 'en';

  const routeWithoutRegexLanguage = route?.replace(/^\/[^/]+?(\/.*)$/gm, '$1') || '';
  return `/${ activeLanguage }${ routeWithoutRegexLanguage }`;
}

export function withoutLang(route) {
  return route.replace(/^\/[^/]+?(\/.*)$/gm, '$1');
}
