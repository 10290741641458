import {
  GET_BULK_MESSAGE_OPTIONS_START,
  GET_BULK_MESSAGE_OPTIONS_ERROR,
  GET_BULK_MESSAGE_OPTIONS_SUCCESS,
  GET_BULK_MESSAGE_USER_IDS_START,
  GET_BULK_MESSAGE_USER_IDS_ERROR,
  GET_BULK_MESSAGE_USER_IDS_SUCCESS,
  UPLOAD_BULK_FILE_START,
  UPLOAD_BULK_FILE_ERROR,
  UPLOAD_BULK_FILE_SUCCESS,

} from '@src/js/actions/bulkMessage/bulkMessageGetUsers';

const initialState = {
  loading: false,
  error: null,
  bulkMessageOptions: {},
  bulkMessageUserIdsLoading: false,
  bulkMessageUserIdsError: null,
  bulkMessageUserIds: [],
  bulkFileLoading: true,
  bulkFileError: null,
};

const actionsMap = {
  [GET_BULK_MESSAGE_OPTIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    bulkMessageOptions: {},
  }),
  [GET_BULK_MESSAGE_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_BULK_MESSAGE_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    bulkMessageOptions: action.data,
  }),
  [GET_BULK_MESSAGE_USER_IDS_START]: (state) => ({
    ...state,
    bulkMessageUserIdsLoading: true,
    bulkMessageUserIdsError: null,
    bulkMessageUserIds: [],
  }),
  [GET_BULK_MESSAGE_USER_IDS_ERROR]: (state, action) => ({
    ...state,
    bulkMessageUserIdsLoading: false,
    bulkMessageUserIdsError: action.error.message,
  }),
  [GET_BULK_MESSAGE_USER_IDS_SUCCESS]: (state, action) => ({
    ...state,
    bulkMessageUserIdsLoading: false,
    bulkMessageUserIds: action.data,
  }),
  [UPLOAD_BULK_FILE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    bulkFileLoading: true,
    bulkFileError: null,
  }),
  [UPLOAD_BULK_FILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    bulkFileLoading: false,
    bulkFileError: action.error.message,
  }),
  [UPLOAD_BULK_FILE_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: null,
    bulkFileLoading: false,
    bulkFileError: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const reducerFunction = actionsMap[action.type];
  return reducerFunction ? reducerFunction(state, action) : state;
}
