export const acceptExtensions = {
  IMAGES: '.jpeg,.jpg,.png',
  DOCUMENTS: '.pdf,.pptx,.ppt,.docx,.doc,.xlsx,.xsl,.csv',
  CSV: '.csv',
  DOCS_AND_IMAGES: '.pdf,.pptx,.ppt,.docx,.doc,.xls,.xlsx,.xsl,.zip,.jpg,.jpeg,.png,.gif',
  PDF: '.pdf, application/pdf',
  SIGNED_PDF: 'application/pdf, application/pkcs7-mime, application/x-pkcs7-mime, .pdf, .p7m, .p7s, .p7c',
  DATAROOM_MIMETYPE: 'text/plain,'
    + 'image/png,'
    + 'image/gif,'
    + 'image/jpeg,'
    + 'image/jpg,'
    + 'application/pdf,'
    + 'application/x-pdf,'
    + 'application/msword,'
    + 'application/vnd.ms-excel,'
    + 'application/vnd.ms-powerpoint,'
    + 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'
    + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,'
    + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
    + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
    + 'application/zip,'
    + 'application/x-zip,'
    + 'application/x-zip-compressed,'
    + '.ppt,'
    + '.pot,'
    + '.pps,'
    + '.ppa,'
    + '.pptx,'
    + '.potx,'
    + '.ppsx,'
    + '.ppam,'
    + '.pptm,'
    + '.potm,'
    + '.ppsm,'
    + '.xls,'
    + '.xlt,'
    + '.xla,'
    + '.xlsx,'
    + '.xltx,'
    + '.xlsm,'
    + '.xltm,'
    + '.xlam,'
    + '.xlsb,'
    + '.csv,'
    + '.doc,'
    + '.dot,'
    + '.docx,'
    + '.dotx,'
    + '.docm,'
    + '.dotm,'
    + '.zip',
  MEDIA_AND_DOCUMENTS_MIMETYPE: 'application/pdf,'
    + '.ppt,'
    + '.pot,'
    + '.pps,'
    + '.ppa,'
    + '.pptx,'
    + '.potx,'
    + '.ppsx,'
    + '.ppam,'
    + '.pptm,'
    + '.potm,'
    + '.ppsm,'
    + '.xls,'
    + '.xlt,'
    + '.xla,'
    + '.xlsx,'
    + '.xltx,'
    + '.xlsm,'
    + '.xltm,'
    + '.xlam,'
    + '.xlsb,'
    + '.doc,'
    + '.dot,'
    + '.docx,'
    + '.dotx,'
    + '.docm,'
    + '.dotm,'
    + '.csv,',
};

export const dropzoneExtensions = {
  IMAGES: {
    'image/*': ['.jpeg', '.jpg', '.png'],
  },
  DOCUMENTS: {
    'text/csv': ['.csv'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  },
  CSV: {
    'text/csv': ['.csv'],
  },
  DOCS_AND_IMAGES: {
    'text/csv': ['.csv'],
    'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    'application/zip': ['.zip'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],

  },
  PDF: {
    'application/pdf': ['.pdf'],
  },
  SIGNED_PDF: {
    // 'application/x-pkcs7-mime'
    'application/pdf': ['.pdf'],
    'application/pkcs7-mime': ['.p7c', '.p7m'],
  },
  DATAROOM_MIMETYPE: {
    // "application/x-pdf",
    // "application/x-zip",
    // "application/x-zip-compressed",
    'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    'text/plain': ['.csv'],
    'text/csv': ['.csv'],
    'application/zip': ['.zip'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc', '.dot', '.wiz'],
    'application/vnd.ms-excel': ['.xls', '.xlt', '.xla', '.xlb', '.xlc', '.xlm', '.xlw'],
    'application/vnd.ms-powerpoint': ['.ppt', '.pot', '.pps', '.ppa', '.pwz'],
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/xml': ['.xsl'],
    'application/pkcs7-mime': ['.p7m'],
  },
  CHAT_IMAGE_MIMETYPE: {
    'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
  },
  CHAT_DOCUMENT_MIMETYPE: {
    'text/plain': ['.csv'],
    'text/csv': ['.csv'],
    'application/zip': ['.zip'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc', '.dot', '.wiz'],
    'application/vnd.ms-excel': ['.xls', '.xlt', '.xla', '.xlb', '.xlc', '.xlm', '.xlw'],
    'application/vnd.ms-powerpoint': ['.ppt', '.pot', '.pps', '.ppa', '.pwz'],
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/xml': ['.xsl'],
    'application/pkcs7-mime': ['.p7m'],
  },
  MEDIA_AND_DOCUMENTS_MIMETYPE: {
    'text/csv': ['.csv'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc', '.dot'],
    'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
    'application/vnd.ms-word.template.macroEnabled.12': ['.dotm'],
    'application/vnd.ms-excel': ['.xls', '.xlt', '.xla'],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    'application/vnd.ms-excel.template.macroEnabled.12': ['.xltm'],
    'application/vnd.ms-excel.addin.macroEnabled.12': ['.xlam'],
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': ['.xlsb'],
    'application/vnd.ms-powerpoint': ['.ppt', '.pot', '.pps', '.ppa'],
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': ['.ppam'],
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': ['.pptm'],
    'application/vnd.ms-powerpoint.template.macroEnabled.12': ['.potm'],
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': ['.ppsm'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.presentationml.template': ['.potx'],
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': ['.xltx'],
  },
};
