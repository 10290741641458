import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS_START,
  GET_REGIONS_ERROR,
  GET_REGIONS_SUCCESS,
  GET_PROVINCES_START,
  GET_PROVINCES_ERROR,
  GET_PROVINCES_SUCCESS,
} from '@src/js/actions/taxonomy/countries';

import * as api from '@src/js/api/taxonomy/countries';

function* getCountries() {
  try {
    const data = yield call(() => api.getCountries());
    yield put({ type: GET_COUNTRIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COUNTRIES_ERROR, error });
  }
}

function* getRegions({ country }) {
  try {
    const data = yield call(() => api.getRegions(country));
    yield put({ type: GET_REGIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_REGIONS_ERROR, error });
  }
}

function* getProvinces({ country }) {
  try {
    const data = yield call(() => api.getProvinces(country));
    yield put({ type: GET_PROVINCES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PROVINCES_ERROR, error });
  }
}

export function* getCountriesWatcher() {
  yield takeLatest(GET_COUNTRIES_START, getCountries);
}

export function* getRegionsWatcher() {
  yield takeLatest(GET_REGIONS_START, getRegions);
}

export function* getProvincesWatcher() {
  yield takeLatest(GET_PROVINCES_START, getProvinces);
}

export default [
  getCountriesWatcher(),
  getRegionsWatcher(),
  getProvincesWatcher(),
];
