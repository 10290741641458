import produce from 'immer';

import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
} from '@app/store/reminderArchive/actions';

export const initialState = {
  data: null,
  dataLoading: false,
  dataError: null,
};

function reminderArchiveReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_DATA_START:
        draft.dataLoading = true;
        draft.dataError = null;
        draft.data = null;
        break;
      case GET_DATA_SUCCESS:
        draft.dataLoading = false;
        draft.dataError = null;
        draft.data = action.data;
        break;
      case GET_DATA_FAIL:
        draft.dataLoading = false;
        draft.dataError = action.error;
        draft.data = null;
        break;
      default:
        break;
    }
  }
  );
}

export default reminderArchiveReducer;
