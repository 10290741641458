import fetchResource from '@src/js/api/fetch-resource';

export function adminGetProgrammes(filters) {
  let url = 'api/admin/programmes';

  if (filters) {
    const esc = encodeURIComponent;

    const query = Object.keys(filters)
      .map(k => `${ esc(k) }=${ esc(filters[k]) }`)
      .join('&');

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}

export function adminCreateProgramme(programme) {
  return fetchResource('api/admin/programmes', {
    method: 'POST',
    body: programme,
  });
}
