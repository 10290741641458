import { showToastrError } from '@app/store/global/actions/index';
import {
  takeEvery, call, put, all,
} from 'redux-saga/effects';

import {
  UPLOAD_FILE_START,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
} from '@src/js/actions/file/uploadFile';

import * as api from '@src/js/api/file/uploadFile';

function* uploadFile(options) {
  try {
    const data = yield call(() => api.uploadFile(options.file.file, options.entity, options.fileType));
    yield put({ type: UPLOAD_FILE_SUCCESS, data, options });
    if (options.file.resolve) {
      options.file.resolve(data);
    }
  } catch (error) {
    yield all([
      put({ type: UPLOAD_FILE_ERROR, error }),
      put(showToastrError('notification.error_title', 'notification.error_upload')),
    ]);
    if (options.file.reject) {
      options.file.reject(error.response);
    }
  }
}

function* uploadFileWatcher() {
  yield takeEvery(UPLOAD_FILE_START, uploadFile);
}

export default [
  uploadFileWatcher(),
];
