import produce from 'immer';

import {
  GET_MEMBERS_LIST_START,
  GET_MEMBERS_LIST_FAIL,
  GET_MEMBERS_LIST_SUCCESS,
  SEND_SIGN_DOCUMENT_START,
  SEND_SIGN_DOCUMENT_FAIL,
  SEND_SIGN_DOCUMENT_SUCCESS,
  SIGN_DOCUMENT_START,
  SIGN_DOCUMENT_FAIL,
  SIGN_DOCUMENT_SUCCESS,
  GET_SIGN_DOCUMENT_START,
  GET_SIGN_DOCUMENT_FAIL,
  GET_SIGN_DOCUMENT_SUCCESS,
  CLEAR_REDIRECT_URL,
} from '@app/store/company/actions';

export const initialState = {
  data: null,
  loading: false,
  error: null,
  signDocumentLoading: false,
  file: null,
  countryCode: '',
  mobilePhone: '',
  redirectUrl: null,
};

function signDocumentReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_MEMBERS_LIST_START:
        if (action.filters?.page === 1) {
          draft.data = null;
        }
        draft.loading = true;
        draft.error = null;
        break;
      case GET_MEMBERS_LIST_SUCCESS:
        draft.loading = false;
        if (action.reset) {
          draft.data = action.result;
        } else {
          draft.data.hasMore = action.result.hasMore;
          draft.data.members = [...state.data.members, ...action.result.members];
        }
        draft.error = null;
        break;
      case GET_MEMBERS_LIST_FAIL:
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;
      case SEND_SIGN_DOCUMENT_START:
        draft.signDocumentLoading = true;
        break;
      case SEND_SIGN_DOCUMENT_FAIL:
      case SEND_SIGN_DOCUMENT_SUCCESS:
        draft.signDocumentLoading = false;
        break;
      case SIGN_DOCUMENT_START:
        draft.loading = true;
        break;
      case SIGN_DOCUMENT_FAIL:
        draft.loading = false;
        break;
      case SIGN_DOCUMENT_SUCCESS:
        draft.loading = false;
        draft.redirectUrl = action.result?.redirectUrl;
        break;
      case GET_SIGN_DOCUMENT_START:
        draft.loading = true;
        draft.signDocument = null;
        draft.countryCode = '';
        draft.mobilePhone = '';
        break;
      case GET_SIGN_DOCUMENT_FAIL:
        draft.error = action.error;
        draft.loading = false;
        break;
      case GET_SIGN_DOCUMENT_SUCCESS:
        draft.loading = false;
        draft.file = {
          name: action.result.documentName,
          url: action.result.documentUrl,
          id: action.result.documentId,
        };
        draft.countryCode = action.result.countryCode;
        draft.mobilePhone = action.result.mobilePhone;
        break;
      case CLEAR_REDIRECT_URL:
        draft.redirectUrl = null;
        break;
      default:
        break;
    }
  }
  );
}

export default signDocumentReducer;
