import { useSelector } from 'react-redux';

const selectMessageDataLoading = () => useSelector((state) => state.companyContactMessageSend?.loading);
const selectMessageDataError = () => useSelector((state) => state.companyContactMessageSend?.error);

const selectRequestInfoLoading = () => useSelector(
  (state) => state.marketplaceReducers.requestInfoModal.messageDataLoading);
const selectRequestInfoError = () => useSelector(
  (state) => state.marketplaceReducers.requestInfoModal.messageDataError);

export {
  selectMessageDataLoading,
  selectMessageDataError,
  selectRequestInfoError,
  selectRequestInfoLoading,
};
