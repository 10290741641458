export const GET_USER_FORM_OPTIONS_START = 'GET_USER_FORM_OPTIONS_START';
export const GET_USER_FORM_OPTIONS_SUCCESS = 'GET_USER_FORM_OPTIONS_SUCCESS';
export const GET_USER_FORM_OPTIONS_ERROR = 'GET_USER_FORM_OPTIONS_ERROR';

export const GET_USER_FORM_COMPANY_OPTIONS_START = 'GET_USER_FORM_COMPANY_OPTIONS_START';
export const GET_USER_FORM_COMPANY_OPTIONS_SUCCESS = 'GET_USER_FORM_COMPANY_OPTIONS_SUCCESS';
export const GET_USER_FORM_COMPANY_OPTIONS_ERROR = 'GET_USER_FORM_COMPANY_OPTIONS_ERROR';

export function getUserFormOptions() {
  return {
    type: GET_USER_FORM_OPTIONS_START,
  };
}

export function getUserFormCompanyOptionsAction(formOptions) {
  return {
    type: GET_USER_FORM_COMPANY_OPTIONS_START,
    formOptions,
  };
}
