import styled from 'styled-components';
import { Container as rsContainer, Col, Row } from 'reactstrap';
import { ThemeColors, FontSize, FontWeight } from '@src/_v2/js/constants';
import { Breakpoints } from '@src/_v2/js/constants/breakpoints';
import { TextInput, TextArea } from '@src/js/components/global/inputs';

export const OnboardingHeaderContainer = styled.div`
  padding-block: 1.2rem;
  background-color: ${ ThemeColors.blue };
`;

export const OnboardingHeader = styled(rsContainer)`
  padding: 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${ Breakpoints.SMALL }px) and (max-width: ${ Breakpoints.MEDIUM }px) {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
`;

export const HamburgerContainer = styled.span`
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: ${ ThemeColors.black };
  height: 100%;
  width: 5.5rem;
  cursor: pointer;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    display: flex;
    height: 5.5rem;
  }
`;

export const HamburgerIcon = styled.span.attrs({
  className: 'icon-medium-Hamburger',
})`
  color: ${ ThemeColors.white };
  font-size: ${ FontSize.title };
  font-family: eliteIconfontMedium, sans-serif;
`;

export const CloseIcon = styled.span.attrs({
  className: 'icon-medium-Close',
})`
  color: ${ ThemeColors.white };
  font-size: ${ FontSize.title };
  font-family: eliteIconfontMedium, sans-serif;
`;

export const OnboardingContainer = styled(rsContainer)`
  padding: 2rem 0 4rem;
  min-height: 40rem;
  background-color: ${ ThemeColors.softBlue };
  position: relative;

  h2 {
    font-weight: ${ FontWeight.bold };
    font-size: ${ FontSize.headline };
    text-align: center;

    @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
      font-size: ${ FontSize.title };
    }
  }

  h4 {
    font-weight: ${ FontWeight.normal };
    font-size: ${ FontSize.headlineDescription };
    text-align: center;

    @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
      font-size: ${ FontSize.iconLarge };
    }
  }

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    padding-bottom: 1rem;
  }
`;

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const CenteredText = styled(Col)`
  text-align: center;
`;

export const LogoutButton = styled(Col)`
  background: transparent;
  border: none;
`;

export const PageContent = styled(rsContainer)`
  min-height: 10rem;
  background-color: ${ ThemeColors.white };
  margin-top: 3.2rem;
  margin-bottom: 5rem;
  padding: 0;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  hr {
    margin: 0 -1.5rem;
    border: none;
    border-bottom: 0.1rem solid ${ ThemeColors.lightGrey };
  }
`;

export const SectionWrapper = styled(Row)`
  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    margin: 0;
  }

  form {
    width: 100%;
  }
`;

export const ButtonsBlock = styled(rsContainer)`
  border-top: 0.2rem solid ${ ThemeColors.softBlue };
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 0;
  flex-direction: row;
  align-items: flex-end;
`;

// Combine this and ButtonsBlock, or separate it by creating a new, base container for both
export const ButtonsContainer = styled(rsContainer)`
  border-top: 0.2rem solid ${ ThemeColors.softBlue };
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    flex-direction: column;
  }
`;

export const ButtonsBlockLeft = styled.div`
  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    button {
      width: 100%;
    }
  }
`;

export const ButtonsBlockRight = styled.div`
  display: flex;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    justify-content: space-between;
    flex-direction: column;

    button {
      width: 100%;
      margin-top: 3rem;
    }
  }
`;

export const FormErrorRight = styled(rsContainer)`
  font-size: ${ FontSize.small };
  line-height: 1.6rem;
  min-height: 2.6rem;
  text-align: right;
  padding: 2rem 0 0.5rem;
  color: ${ ThemeColors.red };
`;

export const SectionBlock = styled.div`
  padding: 5.5rem 8rem;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    padding: 3.2rem 4rem;
  }
`;
export const DetailsSectionBlock = styled.div`
  padding: 5.5rem 9rem 0;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    padding: 4.5rem 1rem 0;
  }
`;

export const SectionTitle = styled.div(props => `
  margin: 0 0 ${ (props.marginBottom ? props.marginBottom : 4) }rem;
  font-size: ${ FontSize.title };
  font-weight: ${ FontWeight.medium };
  color: ${ ThemeColors.blue };

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    margin: 0 0 3rem;
  }
`);

export const SectionLabel = styled.div`
  width: 100%;
  margin: 0 0 4rem;
  font-size: ${ FontSize.subHeader };
  font-weight: ${ FontWeight.medium };
  color: ${ ThemeColors.blue };

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    margin: 0 0 3rem 1.5rem;
  }
`;

export const SubSectionLabel = styled.div`
  font-size: ${ FontSize.body };
  letter-spacing: 0.05rem;
  color: ${ ThemeColors.mediumGrey };
  font-weight: ${ FontWeight.normal };
`;

export const SectionText = styled.p`
  font-size: ${ FontSize.body };
  line-height: 2.5rem;
  letter-spacing: 0.05rem;
  color: ${ ThemeColors.darkGrey };
  margin-bottom: 6rem;
`;

export const SectionQuestion = styled.p(props => `
  font-size: ${ FontSize.body };
  font-weight: ${ FontWeight.bold };
  line-height: 2.5rem;
  letter-spacing: 0.05rem;
  color: ${ ThemeColors.darkGrey };

  ${ props.required && `
    &::before {
      margin-right: 0.5rem;
      color: ${ ThemeColors.red };
      content: '*';
    }
  ` }
`);

export const ButtonContainer = styled.div`
  a {
    text-decoration: none;
  }

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    text-align: center;

    button {
      margin-bottom: 2.5rem;
      font-size: ${ FontSize.medium };
    }
  }
`;

export const SectionAnswerContainer = styled.div`
  width: 8.5rem;
  padding: 1rem 0 5rem;
`;

export const PaddedRow = styled(Row)`
  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    padding: 1.5rem !important;
  }
`;

export const Caption = styled.div`
  font-size: ${ FontSize.title };
  color: ${ ThemeColors.blue };
  font-weight: ${ FontWeight.medium };
  margin-top: 5rem;
  text-align: center;
`;

export const Image = styled.div`
  margin-top: 3rem;
  text-align: center;

  img {
    height: 10rem;
  }
`;

export const UploadCaption = styled.p`
  font-size: ${ FontSize.icon };
  color: ${ ThemeColors.mediumGrey };
`;

export const Content = styled.div`
  font-size: ${ FontSize.iconLarge };
  color: ${ ThemeColors.mediumGrey };
  margin: 5rem 0;
  text-align: center;
`;

export const DocumentContainer = styled.div`
  padding: 2rem;
  height: 50rem;
  font-size: ${ FontSize.body };
  overflow-y: scroll;
  border: 0.1rem solid ${ ThemeColors.lightGrey };
  margin: 1rem 0 3rem;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    height: 40rem;
  }
`;

export const CompanyLogoContainer = styled.div`
  color: ${ ThemeColors.mediumGrey };
  background-color: ${ ThemeColors.white };
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OnboardingDetailsContainer = styled(rsContainer)`
  color: ${ ThemeColors.mediumGrey };
  background-color: ${ ThemeColors.white };
  display: flex;
  flex-direction: column;
`;

export const WebsiteField = styled(TextInput)`
  margin-top: 5.4rem;
`;

export const TwitterField = styled(TextInput)`
  margin-top: 2.8rem;
`;

export const LinkedinField = styled(TextInput)`
  margin-top: 1.7rem;
`;

export const CustomTextarea = styled(TextArea)`
  textarea {
    height: 20rem;
  }

  .eop-text-textarea__label {
    justify-content: unset;

    span {
      color: ${ ThemeColors.red };
    }
  }
`;

export const Label = styled.div`
  font-size: ${ FontSize.icon };
`;

export const RequiredField = styled.span`
  margin-right: 0.5rem;
  font-size: ${ FontSize.small };
  color: ${ ThemeColors.red };
`;
