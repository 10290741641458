const scope = 'REMINDER_ARCHIVE';

export const GET_DATA_START = `${ scope }/GET_DATA_START`;
export const GET_DATA_FAIL = `${ scope }/GET_DATA_FAIL`;
export const GET_DATA_SUCCESS = `${ scope }/GET_DATA_SUCCESS`;

export function getReminderArchiveList(params) {
  return {
    type: GET_DATA_START,
    params,
  };
}
