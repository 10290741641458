import loadEnvVariable from '@src/js/static/LoadEnv';
import inputValidation from '@src/js/helpers/InputValidation';

export const setFormLoader = (Component, isFormLoading) => {
  Component.setState({
    formLoader: isFormLoading,
  });
};

export const onSubmit = (Component, submittedData, loadCompanies) => {
  const {
    createCompany,
    toggle,
    parentComponent,
    showToastrErrorAction,
    showToastrSuccessAction,
  } = Component.props;

  setFormLoader(Component, true);

  return new Promise((resolve, reject) => { // eslint-disable-line consistent-return
    createCompany({ values: submittedData, resolve, reject });
  }).then(() => {
    Component.closeForm(
      () => toggle(parentComponent, null, true),
      true
    );
    loadCompanies({});
    showToastrSuccessAction('notification.success_title', 'company.companyForm.createSuccessMessage');
    setFormLoader(Component, false);
    return null;
  }).catch(submitError => {
    showToastrErrorAction('notification.error_title', 'submitError');
    setFormLoader(Component, false);

    return submitError;
  });
};

export const copyObject = (obj) => JSON.parse(JSON.stringify(obj, (key, val) => {
  if (val) {
    if (typeof val === 'object' && val.value && val.label) {
      return val.value;
    }
    return val;
  }
  return null;
}));

export const handleSectorValue = (Component, firstLevelSectorId) => {
  if (!firstLevelSectorId) {
    return null;
  }
  const { companyFormOptions } = Component.props;
  const sectors = companyFormOptions.results.sectors;

  const secondLevelSectors = sectors.filter(sector => sector.id === firstLevelSectorId);
  return secondLevelSectors[0].id;
};

export const handleSubmit = (Component, submittedCompany, loadCompanies) => {
  const companyData = copyObject(submittedCompany);
  companyData.basic.turnoverCurrency = loadEnvVariable('ID_EURO_VALUE');

  if (!companyData.basic) {
    companyData.basic = {};
  }

  companyData.dates = {
    'eliteMemberSince': null,
    'dateIncorporation': null,
  };

  if (companyData?.basic?.sectors?.length === 2) {
    companyData.basic.sector = companyData.basic.sectors[0]?.id;
    companyData.basic.secondLevelSector = companyData.basic.sectors[1]?.id;
  }

  return onSubmit(Component, companyData, loadCompanies);
};

export const validateForm = (Component, values, isRequiredTermsAndCondition = false) => {
  const errors = {};

  errors.firstname = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.onlyLettersValidation,
    inputValidation.minLengthValidator(2),
    inputValidation.maxLengthValidator(256)
  )(values.firstname);
  errors.lastname = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.onlyLettersValidation,
    inputValidation.minLengthValidator(2),
    inputValidation.maxLengthValidator(256)
  )(values.lastname);
  errors.email = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.emailValidator,
    inputValidation.minLengthValidator(2),
    inputValidation.maxLengthValidator(256)
  )(values.email);
  errors.jobTitle = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.minLengthValidator(2),
    inputValidation.maxLengthValidator(256)
  )(values.jobTitle);

  errors.basic = {};

  errors.basic.name = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.minLengthValidator(2),
    inputValidation.maxLengthValidator(256)
  )(values.basic ? values.basic.name : null);

  if (!values?.basic?.secondLevelSector && !values?.basic?.sector
    && values?.basic?.sectors?.filter(v => v !== null && v !== undefined).length < 2) {
    errors.basic.sector = 'global_form.error.required';
  }

  errors.basic.turnover = inputValidation.requiredValidator(values.basic ? values.basic.turnover : null);
  errors.basic.country = inputValidation.requiredValidator(values.basic ? values.basic.country : null);
  errors.basic.etineraryCompanyType = inputValidation.requiredValidator(
    values?.basic?.etineraryCompanyType ?? null
  );
  errors.basic.employeeNumber = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.floatValidator,
    inputValidation.minLengthValidator(1),
    inputValidation.maxLengthValidator(10)
  )(values.basic?.employeeNumber || null);

  errors.basic.vatNumber = inputValidation.composeValidators(
    inputValidation.requiredValidator,
    inputValidation.minLengthValidator(2),
    inputValidation.maxLengthValidator(100)
  )(values.basic?.vatNumber || null);

  if (isRequiredTermsAndCondition) {
    errors.termsIsAccepted = inputValidation.requiredValidator(values.termsIsAccepted);
  }

  return errors;
};

export const cancelModalHandler = (Component) => {
  const { showCloseModal } = Component.state;

  return Component.setState({
    showCloseModal: !showCloseModal,
  });
};
