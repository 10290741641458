import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_PROGRAMMES_START,
  GET_PROGRAMMES_ERROR,
  GET_PROGRAMMES_SUCCESS,
} from '@src/js/actions/programme/programmes';

import * as api from '@src/js/api/programme/programmes';

function* adminGetProgrammes(options) {
  try {
    const data = yield call(() => api.adminGetProgrammes(options.filters));
    yield put({ type: GET_PROGRAMMES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PROGRAMMES_ERROR, error });
  }
}

export function* adminGetProgrammesWatcher() {
  yield takeLatest(GET_PROGRAMMES_START, adminGetProgrammes);
}

export default [
  adminGetProgrammesWatcher(),
];
