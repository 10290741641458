import {
  ADMIN_GET_COMPANIES_START,
  ADMIN_GET_COMPANIES_ERROR,
  ADMIN_GET_COMPANIES_SUCCESS,
} from '@src/js/actions/company/adminCompanies';

import { COMPANY_COURSERA_ACCESS_SUCCESS } from '@src/js/actions/company/companyCourseraAccess';
import { COMPANY_CERTIFICATION_SUCCESS } from '@src/js/actions/company/companyCertification';

const initialState = {
  loading: false,
  error: null,
  companies: null,
};

const actionsMap = {
  [ADMIN_GET_COMPANIES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    companies: null,
  }),
  [ADMIN_GET_COMPANIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [ADMIN_GET_COMPANIES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    companies: action.data,
  }),
  [COMPANY_CERTIFICATION_SUCCESS]: (state, action) => {
    const newResults = state.companies.results.map(company => {
      if (company.slug === action.data) {
        return {
          ...company,
          'isCertified': !company.isCertified,
        };
      }
      return company;
    });
    return {
      ...state,
      companies: { ...state.companies, results: newResults },
    };
  },
  [COMPANY_COURSERA_ACCESS_SUCCESS]: (state, action) => {
    const newResults = state.companies.results.map(company => {
      if (company.slug === action.data) {
        return {
          ...company,
          'coursera': !company.coursera,
        };
      }
      return company;
    });
    return {
      ...state,
      companies: { ...state.companies, results: newResults },
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
