import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { RenderSelect, validateSelect } from './ReactSelectComponents';
import { InputSelectProps } from './select';

const InputSelect = (props: InputSelectProps) => {
  const {
    name, label, translate = true, control, required = false, errors, defaultValue,
    className = '', boolRequired = false, tooltip, validator,
  } = props;
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;

  const rules = validateSelect({
    validator, boolRequired, intl, required,
  });

  return (
    <div className={ `eop-selectbox ${ className }` } data-name={ name }>
      <label className='eop-selectbox__label'>
        { ((required || boolRequired) && label) && <span className='required-icon' /> }
        { label && fieldLabel }
        { tooltip && (
          <>
            <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ `${ name }-tooltip` } />
            <ReactTooltip
              id={ `${ name }-tooltip` }
              place='top'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              { fieldTooltip }
            </ReactTooltip>
          </>
        )}
      </label>
      <div className='flex'>
        { ((required || boolRequired) && !label) && <span className='required-icon' /> }
        <Controller
          name={ name }
          control={ control }
          defaultValue={ defaultValue }
          rules={ rules }
          render={ ({ field }) => <RenderSelect { ...props } field={ field } /> }
        />
      </div>
      { boolRequired && !validator?.validate && (
        <ErrorMessage
          errors={ errors }
          name={ name }
          render={ () => (
            <span className='error-message'>
              {intl.formatMessage({ id: 'global_form.error.required' })}
            </span>
          ) }
        />
      )}
      { !boolRequired && !validator?.validate && (
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
      )}
      { validator?.validate && validator?.message && (
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ () => (
          <span className='error-message'>
            { validator.translate ? intl.formatMessage({ id: validator.message }) : validator.message }
          </span>
        ) }
      />
      )}
    </div>
  );
};

export default InputSelect;
