import { funding, library, news } from '@app/components/public/etinerary/Modal/constant';
import { TargetBlank } from '@src/_v2/js/constants';
import { Language } from '@src/js/constants/entities';
import {
  dynamicRoutes,
  externalRoutes,
  publicPath,
  routeCodes, withLang,
} from '@src/js/constants/routes';
import { isInstitutionalInvestor } from '@src/js/helpers/helpers';
import { useEtineraryModal } from '@src/js/layouts/header/navigation/utils';
import * as PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const SubMenuDesktop = ({ navigation }) => {
  const myCompany = useSelector(state => state.initInfo.userInfo.companySlug);
  const userData = useSelector(state => state.initInfo.userInfo);
  const activeLanguage = useSelector(state => state.initInfo?.activeLanguage);
  const { showModal, checkType } = useEtineraryModal();

  const setActiveItem = (match, location, item) => {
    const pathName = location.pathname.toLowerCase();
    const itemName = item.toLowerCase();
    /* Checks if route is Partners Companies and checks for valid slug in content. */
    const partnerCompaniesRegex = /partners\/(?:[:]?[a-z0-9-]*)\/companies/g;
    const isPartnerCompaniesRoute = pathName.match(partnerCompaniesRegex) && itemName.match(partnerCompaniesRegex);
    let isActiveItem = false;

    // Exception for directory since the company profile is not under directory url
    if (itemName === withLang(routeCodes.DIRECTORY)) {
      if (pathName.includes(withLang(routeCodes.COMPANIES)) || pathName.includes(withLang(routeCodes.PARTNERS))) {
        isActiveItem = true;
      }
    }

    if (pathName.includes(withLang(routeCodes.SUCCESS_STORIES))
      && itemName === withLang(routeCodes.FUNDING_SUCCESS_STORY)) {
      isActiveItem = true;
    }

    /**
     * Standard check for selected item.
     */
    if (pathName.includes(itemName) || isPartnerCompaniesRoute) {
      isActiveItem = true;
    }

    /**
     * This is hack for basic partner which has not available default dashboard sub menu,
     * and first one must be selected if not selected any of available.
     */
    if (!isActiveItem
      && pathName.includes('partner')
      && !pathName.includes('partner/library')
      && item.toLowerCase().includes('company')
      && !pathName.toLowerCase().match(partnerCompaniesRegex)
    ) {
      isActiveItem = true;
    }

    if (pathName.includes('data-rooms')
        && itemName === withLang(routeCodes.MY_DATAROOMS) && isInstitutionalInvestor()) {
      isActiveItem = true;
    }

    return isActiveItem;
  };

  const getExternalNavigationClass = () => {
    let activeClass = 'secondary-navigation__links-item';

    // eslint-disable-next-line no-restricted-globals
    if (location.pathname === withLang(routeCodes.COMPASS_TEASER)) {
      activeClass += ' secondary-navigation__links-item--active';
    }

    return activeClass;
  };

  const createCompassUrl = (link) => `${ link }/company/${ myCompany }/assessment/`;

  const partner = navigation && navigation.partner_companies && navigation.partner_companies.partner;
  const navKeys = activeLanguage === Language.IT
    ? Object.keys(navigation)
    : Object.keys(navigation)
      .filter(nav => nav !== 'finanza_agevolata' && nav !== 'certificates');
  return (
    <nav className='secondary-navigation sub-navigation'>
      <Container>
        <Row className='middle-xs'>
          <div className='secondary-navigation__links'>
            {
                navKeys.map(key => {
                  if (externalRoutes.includes(key)) {
                    return (
                      <a
                        key={ key }
                        { ...TargetBlank }
                        id={ `secondary-navigation-${ key }` }
                        className={ getExternalNavigationClass() }
                        href={ ['compass', 'growth_compass'].includes(key)
                          ? createCompassUrl(navigation[key].link) : navigation[key].link }
                        data-translation={ `navigation.${ key }` }
                      >
                        <FormattedMessage id={ `navigation.${ key }` } />
                      </a>
                    );
                  }

                  if (dynamicRoutes.includes(key)) {
                    return (
                      <NavLink
                        key={ key }
                        activeClassName='secondary-navigation__links-item--active'
                        className='secondary-navigation__links-item'
                        isActive={
                          (match, location) => setActiveItem(
                            match,
                            location,
                            withLang(routeCodes[key.toUpperCase()])
                          ) }
                        exact
                        to={ withLang(`${ publicPath }${ navigation[key].route }`) }
                        data-translation={ `navigation.${ navigation[key].label }` }
                      >
                        { partner ? (
                          <FormattedMessage id={ `navigation.${ navigation[key].label }` } values={ { partner } } />
                        ) : (
                          <FormattedMessage id={ `navigation.${ navigation[key].label }` } />
                        ) }
                      </NavLink>
                    );
                  }
                  const etineraryModalType = checkType(key, userData);
                  return (
                    <NavLink
                      key={ key }
                      activeClassName='secondary-navigation__links-item--active'
                      className='secondary-navigation__links-item'
                      exact
                      isActive={
                        (match, location) => routeCodes[key.toUpperCase()] && setActiveItem(
                          match,
                          location,
                          withLang(routeCodes[key.toUpperCase()])
                        ) }
                      to={ etineraryModalType ? false : withLang(routeCodes[key.toUpperCase()]) }
                      onClick={ () => {
                        if (etineraryModalType) {
                          showModal(etineraryModalType, [funding, news, library].includes(key) ? key : null);
                        }
                      } }
                      data-translation={ `navigation.${ key }` }
                    >
                      <FormattedMessage id={ `navigation.${ key }` } />
                      { etineraryModalType && <span className='etinerary-indicator' /> }
                    </NavLink>
                  );
                })
              }
          </div>
        </Row>
      </Container>
    </nav>
  );
};

SubMenuDesktop.propTypes = {
  navigation: PropTypes.oneOfType([
    PropTypes.array, PropTypes.object,
  ]),
};

export default SubMenuDesktop;
