import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_TEMPLATE_DATA_START,
  GET_TEMPLATE_DATA_ERROR,
  GET_TEMPLATE_DATA_SUCCESS,
} from '@src/js/actions/onboarding/onboardingTemplate';

import * as api from '@src/js/api/onboarding/onboardingTemplate';

function* getTemplateData(options) {
  try {
    const data = yield call(() => api.getTemplateData(options.templateHash));
    yield put({ type: GET_TEMPLATE_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TEMPLATE_DATA_ERROR, error });
  }
}

export function* getTemplateDataWatcher() {
  yield takeLatest(GET_TEMPLATE_DATA_START, getTemplateData);
}

export default [
  getTemplateDataWatcher(),
];
