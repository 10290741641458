import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@src/js/components/global';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { useDispatch } from 'react-redux';
import { renameFolder } from '@app/store/documentsArea/actions';
import { InputText } from '@app/components/global/forms';
import { useForm } from 'react-hook-form';
import { Folder } from '@app/models/DataRoom';

type Props = {
  folder: Folder;
  folderId: number,
  dataRoomType: string,
  slug: string,
}

const RenameFolderModal = ({
  folder, slug, dataRoomType, folderId,
}: Props) => {
  const { hideModal } = useGlobalModalContext();
  const dispatch = useDispatch();
  const {
    register, formState: { errors, isValid }, handleSubmit,
  } = useForm<{ folderName: string }>({
    defaultValues: { folderName: folder.name },
  });

  const onSubmit = (data: {folderName: string}) => {
    dispatch(renameFolder(slug, folder.id, { name: data.folderName }, dataRoomType, folderId));
    hideModal();
  };

  return (
    <ModalBody className='m-y-4'>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <InputText
          label='main.name'
          name='folderName'
          register={ register }
          errors={ errors }
          required
        />
        <ModalFooter>
          <Button
            size='md'
            buttonText='dialog.confirm'
            buttonType='submit'
            disabled={ !isValid }
          />
          <Button
            size='md'
            buttonText='cancel'
            emptyButtonType='empty-grey'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </form>
    </ModalBody>
  );
};

export default RenameFolderModal;
