export const ADMIN_GET_INDEX_LIBRARY_START = 'ADMIN_GET_INDEX_LIBRARY_START';
export const ADMIN_GET_INDEX_LIBRARY_ERROR = 'ADMIN_GET_INDEX_LIBRARY_ERROR';
export const ADMIN_GET_INDEX_LIBRARY_SUCCESS = 'ADMIN_GET_INDEX_LIBRARY_SUCCESS';

export function adminGetLibrary(filters) {
  return {
    type: ADMIN_GET_INDEX_LIBRARY_START,
    filters,
  };
}
