import {
  GET_EVENTS_START,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  CLEAR_EVENTS,
  GET_SINGLE_EVENT_BASIC_START,
  GET_SINGLE_EVENT_BASIC_ERROR,
  GET_SINGLE_EVENT_BASIC_SUCCESS,
  CLEAR_SINGLE_EVENT_BASIC_DATA,
  GET_SINGLE_EVENT_DATA_START,
  GET_SINGLE_EVENT_DATA_ERROR,
  GET_SINGLE_EVENT_DATA_SUCCESS,
  EVENT_SUBSCRIBE_MEMBERS_START,
  EVENT_SUBSCRIBE_MEMBERS_FAIL,
  EVENT_SUBSCRIBE_MEMBERS_SUCCESS,
  EVENT_SUBSCRIBE_MEMBERS_UPDATED,
  GET_UPCOMING_EVENTS_START,
  GET_UPCOMING_EVENTS_ERROR,
  GET_UPCOMING_EVENTS_SUCCESS,
  GET_COMMUNITY_START,
  GET_COMMUNITY_ERROR,
  GET_COMMUNITY_SUCCESS,
  CLEAR_COMMUNITY_DATA,
  GET_COMMUNITY_PERMISSIONS_START,
  GET_COMMUNITY_PERMISSIONS_ERROR,
  GET_COMMUNITY_PERMISSIONS_SUCCESS,
  EDIT_PERMISSION_START,
  EDIT_PERMISSION_ERROR,
  EDIT_PERMISSION_SUCCESS,
  SUBSCRIBE_EXTERNAL_START,
  SUBSCRIBE_EXTERNAL_FAIL,
  SUBSCRIBE_EXTERNAL_SUCCESS,
  UNSUBSCRIBE_EXTERNAL_START,
  UNSUBSCRIBE_EXTERNAL_FAIL,
  UNSUBSCRIBE_EXTERNAL_SUCCESS,
  UNSUBSCRIBE_START,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAIL,
} from '@src/js/actions/events/events';

const initialState = {
  loading: false,
  error: null,
  codeError: null,
  events: null,
  eventBasicLoading: false,
  eventBasicError: null,
  eventBasicData: null,
  eventDataLoading: false,
  eventDataError: null,
  eventData: null,
  upcomingEventLoading: false,
  upcomingEventError: false,
  upcomingEventData: false,
  communityLoading: false,
  communityError: false,
  communityData: null,
  permissionsLoading: false,
  permissionsError: false,
  permissionsData: null,
  subscribeExternalLoading: false,
  unsubscribeLoading: false,
  unsubscribeError: null,
};

const actionsMap = {
  [GET_EVENTS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    events: null,
    codeError: null,
  }),
  [GET_EVENTS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    codeError: action.error.status,
  }),
  [GET_EVENTS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    events: action.data,
    codeError: null,
  }),
  [CLEAR_EVENTS]: (state) => ({
    ...state,
    loading: false,
    error: null,
    events: null,
    codeError: null,
  }),
  [GET_SINGLE_EVENT_BASIC_START]: (state) => ({
    ...state,
    eventBasicLoading: true,
    eventBasicError: null,
    eventBasicData: null,
    eventEventData: null,
  }),
  [GET_SINGLE_EVENT_BASIC_ERROR]: (state, action) => ({
    ...state,
    eventBasicLoading: false,
    eventBasicError: action.error,
  }),
  [GET_SINGLE_EVENT_BASIC_SUCCESS]: (state, action) => ({
    ...state,
    eventBasicLoading: false,
    eventBasicError: null,
    eventBasicData: action.data,
  }),
  [CLEAR_SINGLE_EVENT_BASIC_DATA]: (state) => ({
    ...state,
    eventBasicData: null,
  }),
  [GET_SINGLE_EVENT_DATA_START]: (state) => ({
    ...state,
    eventDataLoading: true,
    eventDataError: null,
    eventData: null,
  }),
  [GET_SINGLE_EVENT_DATA_ERROR]: (state, action) => ({
    ...state,
    eventDataLoading: false,
    eventDataError: action.error.message,
  }),
  [GET_SINGLE_EVENT_DATA_SUCCESS]: (state, action) => ({
    ...state,
    eventDataLoading: false,
    eventDataError: null,
    eventData: action.data,
  }),
  [EVENT_SUBSCRIBE_MEMBERS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    subscribed: false,
  }),
  [EVENT_SUBSCRIBE_MEMBERS_FAIL]: (state) => ({
    ...state,
    loading: false,
  }),
  [EVENT_SUBSCRIBE_MEMBERS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    subscribed: true,
  }),
  [EVENT_SUBSCRIBE_MEMBERS_UPDATED]: (state) => ({
    ...state,
    subscribed: null,
  }),
  [GET_UPCOMING_EVENTS_START]: (state) => ({
    ...state,
    upcomingEventLoading: true,
    upcomingEventError: null,
    upcomingEventData: null,
  }),
  [GET_UPCOMING_EVENTS_ERROR]: (state, action) => ({
    ...state,
    upcomingEventLoading: false,
    upcomingEventError: action.error.message,
  }),
  [GET_UPCOMING_EVENTS_SUCCESS]: (state, action) => ({
    ...state,
    upcomingEventLoading: false,
    upcomingEventData: action.data,
  }),
  [GET_COMMUNITY_START]: (state, action) => {
    const page = action?.params?.page || 1;
    return page === 1 ? {
      ...state,
      communityLoading: true,
      communityError: null,
      communityData: null,
    }
      : {
        ...state,
        communityLoading: true,
        communityError: null,
      };
  },
  [GET_COMMUNITY_SUCCESS]: (state, action) => {
    const results = { ...action.results };
    const stateResults = state.communityData?.results || [];
    if (action.page !== 1) {
      results.results = [...stateResults, ...action.results.results];
    }
    return {
      ...state,
      communityLoading: false,
      communityData: results,
    };
  },
  [GET_COMMUNITY_ERROR]: (state, action) => ({
    ...state,
    communityLoading: false,
    communityError: action.error.message,
  }),
  [CLEAR_COMMUNITY_DATA]: (state) => ({
    ...state,
    communityLoading: false,
    communityError: null,
    communityData: null,
  }),
  [GET_COMMUNITY_PERMISSIONS_START]: (state, action) => {
    const page = action?.params?.page || 1;
    return page === 1 ? {
      ...state,
      permissionsLoading: true,
      permissionsError: null,
      permissionsData: null,
    }
      : {
        ...state,
        permissionsLoading: true,
        permissionsError: null,
      };
  },
  [GET_COMMUNITY_PERMISSIONS_SUCCESS]: (state, action) => {
    const results = { ...action.results };
    const stateResults = state.permissionsData?.results || [];
    if (action.page !== 1) {
      results.results = [...stateResults, ...action.results.results];
    }
    return {
      ...state,
      permissionsLoading: false,
      permissionsData: results,
    };
  },
  [GET_COMMUNITY_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    permissionsLoading: false,
    permissionsError: action.error.message,
  }),
  [EDIT_PERMISSION_START]: (state) => state,
  [EDIT_PERMISSION_SUCCESS]: (state, action) => {
    const { uuid } = action;
    const permissionsDataList = [];
    const permissionsDataPagination = { ...state.permissionsData.pagination };
    state.permissionsData.results.forEach(user => {
      const newUser = { ...user };
      if (newUser.uuid === uuid) {
        newUser.communityAccess = !newUser.communityAccess;
      }
      permissionsDataList.push(newUser);
    });
    return {
      ...state,
      permissionsData: {
        results: permissionsDataList,
        pagination: permissionsDataPagination,
      },
    };
  },
  [EDIT_PERMISSION_ERROR]: (state) => state,
  [SUBSCRIBE_EXTERNAL_START]: (state) => ({
    ...state,
    subscribeExternalLoading: true,
  }),
  [SUBSCRIBE_EXTERNAL_FAIL]: (state) => ({
    ...state,
    subscribeExternalLoading: false,
  }),
  [SUBSCRIBE_EXTERNAL_SUCCESS]: (state) => ({
    ...state,
    subscribeExternalLoading: false,
  }),
  [UNSUBSCRIBE_EXTERNAL_START]: (state) => ({
    ...state,
    subscribeExternalLoading: true,
  }),
  [UNSUBSCRIBE_EXTERNAL_FAIL]: (state) => ({
    ...state,
    subscribeExternalLoading: false,
  }),
  [UNSUBSCRIBE_EXTERNAL_SUCCESS]: (state) => ({
    ...state,
    subscribeExternalLoading: false,
  }),
  [UNSUBSCRIBE_START]: (state) => ({
    ...state,
    unsubscribeLoading: true,
    unsubscribeError: null,
  }),
  [UNSUBSCRIBE_SUCCESS]: (state) => ({
    ...state,
    unsubscribeLoading: false,
  }),
  [UNSUBSCRIBE_FAIL]: (state, action) => ({
    ...state,
    unsubscribeLoading: false,
    unsubscribeError: action.error,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const reducerFunction = actionsMap[action.type];
  return reducerFunction ? reducerFunction(state, action) : state;
}
