import styled from 'styled-components';
import { Breakpoints } from '@src/_v2/js/constants/breakpoints';

export const EopCheckboxGrid = styled.div`
  margin-bottom: 2.5rem;

  .react-checkbox-tree {
    border: none;
    overflow-y: auto;
    max-height: unset;

    ol {
      padding-top: 1rem;

      li {
        display: inline-block;
        width: 33%;
        margin: 0;
        padding-left: 0;
        padding-bottom: 1rem;
        list-style-type: none;

        @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
          width: 100%;
        }

      }
    }
  }
`;
