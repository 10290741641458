import { showToastrError, showToastrSuccess } from '@app/store/global/actions';

import {
  takeEvery, put, takeLatest, call, all,
} from 'redux-saga/effects';
import {
  SET_SELECTED_ADMIN_TAB_START,
  SET_SELECTED_ADMIN_TAB_SUCCESS,
  GET_ADMIN_OPPORTUNITIES_START,
  GET_ADMIN_OPPORTUNITIES_SUCCESS,
  GET_ADMIN_OPPORTUNITIES_ERROR,
  GET_ADMIN_REQUESTS_START,
  GET_ADMIN_REQUESTS_ERROR,
  GET_ADMIN_REQUESTS_SUCCESS,
  SET_PRODUCTS_FILTERS_SUCCESS,
  SET_PRODUCTS_FILTERS,
  GET_ADMIN_TYPES_ERROR,
  GET_ADMIN_TYPES_SUCCESS,
  GET_ADMIN_TYPES_START,
  GET_ADMIN_STATISTICS_ERROR,
  GET_ADMIN_STATISTICS_SUCCESS,
  GET_ADMIN_STATISTICS_START,
  ADMIN_PRODUCT_STATUS_UPDATE_ERROR,
  ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS,
  ADMIN_PRODUCT_STATUS_UPDATE_START,
  SET_REQUESTS_FILTERS_SUCCESS,
  SET_REQUESTS_FILTERS,
  ADMIN_REQUEST_STATUS_UPDATE_SUCCESS,
  ADMIN_REQUEST_STATUS_UPDATE_ERROR,
  ADMIN_REQUEST_STATUS_UPDATE_START,
} from '@src/js/marketplace/actions/admin/marketplaceAdmin';
import * as api from '@src/js/marketplace/api/admin/marketplaceAdmin';

function* setAdminSelectedTab(data) {
  yield put({ type: SET_SELECTED_ADMIN_TAB_SUCCESS, index: data.index });
}

function* setProductsFilters(data) {
  yield put({ type: SET_PRODUCTS_FILTERS_SUCCESS, filters: data.filters });
}

function* setRequestsFilters(data) {
  yield put({ type: SET_REQUESTS_FILTERS_SUCCESS, filters: data.filters });
}

function* getAdminOpportunities(options) {
  try {
    const data = yield call(() => api.getAdminOpportunities(options.filters));
    yield put({ type: GET_ADMIN_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ADMIN_OPPORTUNITIES_ERROR, error });
  }
}

function* getAdminTypes(options) {
  try {
    const data = yield call(() => api.getAdminTypes(options.filters));
    yield put({ type: GET_ADMIN_TYPES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ADMIN_TYPES_ERROR, error });
  }
}

function* getAdminRequests(options) {
  try {
    const data = yield call(() => api.getAdminRequests(options.filters));
    yield put({ type: GET_ADMIN_REQUESTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ADMIN_REQUESTS_ERROR, error });
  }
}

function* getAdminStatistics() {
  try {
    const data = yield call(() => api.getAdminStatistics());
    yield put({ type: GET_ADMIN_STATISTICS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ADMIN_STATISTICS_ERROR, error });
  }
}

function* adminProductStatusUpdate(options) {
  try {
    const data = yield call(() => api.adminProductStatusUpdate(options.options.values));
    yield put({ type: ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS, data });
    if (options.options.resolve) {
      options.options.resolve(data);
    } else {
      yield all([
        put(showToastrSuccess('marketplace.admin.successUpdateTitle', 'marketplace.admin.successUpdate')),
        put({
          type: GET_ADMIN_OPPORTUNITIES_START,
          filters: options.options.filters,
        }),
      ]);
    }
  } catch (error) {
    yield put({ type: ADMIN_PRODUCT_STATUS_UPDATE_ERROR, error });
    if (options.options.reject) {
      options.options.reject(error);
    } else {
      yield put(showToastrError('marketplace.admin.errorUpdateTitle', 'marketplace.admin.errorUpdate'));
    }
  }
}

function* adminRequestStatusUpdate(options) {
  try {
    const data = yield call(() => api.adminRequestStatusUpdate(options.options.values));
    yield put({ type: ADMIN_REQUEST_STATUS_UPDATE_SUCCESS, data });
    options.options.resolve(data);
  } catch (error) {
    yield put({ type: ADMIN_REQUEST_STATUS_UPDATE_ERROR, error });
    options.options.reject(error);
  }
}

function* setAdminSelectedTabWatcher() {
  yield takeEvery(SET_SELECTED_ADMIN_TAB_START, setAdminSelectedTab);
}

function* setProductsFiltersWatcher() {
  yield takeEvery(SET_PRODUCTS_FILTERS, setProductsFilters);
}

function* setRequestsFiltersWatcher() {
  yield takeEvery(SET_REQUESTS_FILTERS, setRequestsFilters);
}

function* getAdminOpportunitiesWatcher() {
  yield takeLatest(GET_ADMIN_OPPORTUNITIES_START, getAdminOpportunities);
}

function* getAdminRequestsWatcher() {
  yield takeLatest(GET_ADMIN_REQUESTS_START, getAdminRequests);
}

function* getAdminTypesWatcher() {
  yield takeLatest(GET_ADMIN_TYPES_START, getAdminTypes);
}

function* getAdminStatisticsWatcher() {
  yield takeLatest(GET_ADMIN_STATISTICS_START, getAdminStatistics);
}

function* adminProductStatusUpdateWatcher() {
  yield takeLatest(ADMIN_PRODUCT_STATUS_UPDATE_START, adminProductStatusUpdate);
}

function* adminRequestStatusUpdateWatcher() {
  yield takeLatest(ADMIN_REQUEST_STATUS_UPDATE_START, adminRequestStatusUpdate);
}

export default [
  setAdminSelectedTabWatcher(),
  setProductsFiltersWatcher(),
  setRequestsFiltersWatcher(),
  getAdminOpportunitiesWatcher(),
  getAdminRequestsWatcher(),
  getAdminTypesWatcher(),
  getAdminStatisticsWatcher(),
  adminProductStatusUpdateWatcher(),
  adminRequestStatusUpdateWatcher(),
];
