import { takeLatest, call, put } from 'redux-saga/effects';

import {
  TASK_GET_USER_PERMISSIONS_START,
  TASK_GET_USER_PERMISSIONS_ERROR,
  TASK_GET_USER_PERMISSIONS_SUCCESS,
} from '@src/js/actions/tasks/taskGetUserPermissions';

import * as api from '@src/js/api/tasks/taskGetUserPermissions';

function* taskGetUserPermissions(options) {
  try {
    const data = yield call(() => api.taskGetUserPermissions(options.slug, options.offset, options.search));
    yield put({ type: TASK_GET_USER_PERMISSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TASK_GET_USER_PERMISSIONS_ERROR, error });
  }
}

export function* taskGetUserPermissionsWatcher() {
  yield takeLatest(TASK_GET_USER_PERMISSIONS_START, taskGetUserPermissions);
}

export default [
  taskGetUserPermissionsWatcher(),
];
