export const GET_COMPANY_FORM_OPTIONS_START = 'GET_COMPANY_FORM_OPTIONS_START';
export const GET_COMPANY_FORM_OPTIONS_SUCCESS = 'GET_COMPANY_FORM_OPTIONS_SUCCESS';
export const GET_COMPANY_FORM_OPTIONS_ERROR = 'GET_COMPANY_FORM_OPTIONS_ERROR';
export const CLEAR_COMPANY_FORM_OPTIONS = 'CLEAR_COMPANY_FORM_OPTIONS';

export const GET_COMPANY_FINANCIALS_FORM_OPTIONS_ERROR = 'GET_COMPANY_FINANCIALS_FORM_OPTIONS_ERROR';
export const GET_COMPANY_FINANCIALS_FORM_OPTIONS_START = 'GET_COMPANY_FINANCIALS_FORM_OPTIONS_START';
export const GET_COMPANY_FINANCIALS_FORM_OPTIONS_SUCCESS = 'GET_COMPANY_FINANCIALS_FORM_OPTIONS_SUCCESS';

export const GET_COMPANY_FORM_ATECO_FAIL = 'GET_COMPANY_FORM_ATECO_FAIL';
export const GET_COMPANY_FORM_ATECO_START = 'GET_COMPANY_FORM_ATECO_START';
export const GET_COMPANY_FORM_ATECO_SUCCESS = 'GET_COMPANY_FORM_ATECO_SUCCESS';

export function getCompanyFormOptions(slug, userType) {
  return {
    type: GET_COMPANY_FORM_OPTIONS_START,
    slug,
    userType,
  };
}

export function getCompanyFinancialsFormOptions(slug) {
  return {
    type: GET_COMPANY_FINANCIALS_FORM_OPTIONS_START,
    slug,
  };
}

export function clearCompanyFormOptions() {
  return {
    type: CLEAR_COMPANY_FORM_OPTIONS,
  };
}

export function getAtecoCodesOptions({ values, resolve, reject }) {
  return {
    type: GET_COMPANY_FORM_ATECO_START,
    values,
    resolve,
    reject,
  };
}
