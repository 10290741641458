import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_FEATURED_LIBRARY_START,
  GET_FEATURED_LIBRARY_ERROR,
  GET_FEATURED_LIBRARY_SUCCESS,
} from '@src/js/actions/library/featuredLibrary';

import * as api from '@src/js/api/library/featuredLibrary';

function* getFeaturedLibrary(options) {
  try {
    const data = yield call(() => api.getFeaturedLibrary(options.filters));
    yield put({ type: GET_FEATURED_LIBRARY_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_FEATURED_LIBRARY_ERROR, error });
  }
}

function* getFeaturedLibraryWatcher() {
  yield takeLatest(GET_FEATURED_LIBRARY_START, getFeaturedLibrary);
}

export default [
  getFeaturedLibraryWatcher(),
];
