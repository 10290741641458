import {
  GET_PARTNERS_START,
  GET_PARTNERS_ERROR,
  GET_PARTNERS_SUCCESS,
} from '@src/js/actions/partner/partners';

import { PARTNER_COURSERA_ACCESS_SUCCESS } from '@src/js/actions/partner/partnerCourseraAccess';
import { PARTNER_PARAMETERS_VISIBILITY_SUCCESS } from '@src/js/actions/partner/partnerParametersVisibility';

const initialState = {
  loading: false,
  error: null,
  partners: null,
};

const actionsMap = {
  [GET_PARTNERS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    partners: null,
  }),

  [GET_PARTNERS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_PARTNERS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    partners: action.data,
  }),
  [PARTNER_COURSERA_ACCESS_SUCCESS]: (state, action) => {
    const newResults = state.partners.results.map(partner => {
      if (partner.slug === action.data) {
        return {
          ...partner,
          'coursera': !partner.coursera,
        };
      }
      return partner;
    });
    return {
      ...state,
      partners: { ...state.partners, results: newResults },
    };
  },
  [PARTNER_PARAMETERS_VISIBILITY_SUCCESS]: (state, action) => {
    const type = `${ action.parameters }Visibility`;
    const idx = state.partners.results.findIndex(p => p.slug === action.data);
    const newResults = [...state.partners.results];
    if (idx >= 0) {
      newResults[idx] = {
        ...newResults[idx],
        [type]: !newResults[idx][type],
      };
    }
    return {
      ...state,
      partners: { ...state.partners, results: newResults },
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
