// eslint-disable-next-line max-len
const emailPattern = (/^(([a-zA-Z\-_0-9+]+(\.[a-zA-Z\-_0-9+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const passwordPattern = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*~])/);

const phonePattern = (/^[+]{0,1}[0-9 ]*$/);

// eslint-disable-next-line max-len
const urlPattern = (/^(https?:\/\/)?(([A-Za-z0-9-]+\.){1,2})([A-Za-z0-9-]{2,25})?((\/[-+~%/.\w_$]*)?\??([-+=&$;%@.\w_]*)#?([-+=&$;%@.\w_]*))$/);

const specialPattern = (/^(.*[!@+;"#$_%^&*~])/);

const percentagePattern = (/^>?[0-9]+([,.]{1}[0-9]+)?%?$/);

const slugPattern = (/^[a-z0-9]+([a-z0-9-]*[a-z0-9]+)*$/);

const numberPattern = (/^[0-9]*$/);

export {
  emailPattern,
  passwordPattern,
  phonePattern,
  urlPattern,
  specialPattern,
  percentagePattern,
  slugPattern,
  numberPattern,
};
