import {
  GET_FEATURED_OPPORTUNITY_START,
  GET_FEATURED_OPPORTUNITY_ERROR,
  GET_FEATURED_OPPORTUNITY_SUCCESS,
} from '@src/js/marketplace/actions/getFeaturedOpportunities';

const initialState = {
  loading: false,
  error: null,
  opportunities: { Items: [] },
};

const actionsMap = {
  [GET_FEATURED_OPPORTUNITY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_FEATURED_OPPORTUNITY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_FEATURED_OPPORTUNITY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    opportunities: action.data,
  }),
  'UPDATE_OPPORTUNITY': (state, action) => {
    const opportunities = [...state.opportunities.Items];
    const idx = opportunities.findIndex(o => o.Id === action.opportunity.Id);
    if (idx >= 0) {
      opportunities[idx] = action.opportunity;
    }
    return {
      ...state,
      opportunities: { ...state.opportunities, Items: opportunities },
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
