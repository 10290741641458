import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@src/js/components/global';
import { InputText, InputSelect } from '@app/components/global/forms';
import { useForm, FormProvider } from 'react-hook-form';
import { addAttendance } from '@app/store/eventAttendance/actions';
import GridContainer from '@app/components/global/list/GridContainer';
import { Event } from '@src/js/constants/entities';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const TrainingAttendanceModal = ({ slug, filters }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const methods = useForm({
    mode: 'all',
  });
  const { hideModal } = useGlobalModalContext();

  const {
    formState: { errors }, register, control, handleSubmit,
  } = methods;

  const locationOptions = [
    { value: Event.LOCATION_DIGITAL, label: intl.formatMessage({ id: 'subscribeModal.digital' }) },
    { value: Event.LOCATION_PHYSICAL, label: intl.formatMessage({ id: 'subscribeModal.physical' }) },
  ];

  const onSubmit = data => {
    dispatch(addAttendance(slug, data, filters));
    hideModal();
  };

  return (
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(onSubmit) } className='m-t-3'>
        <ModalBody>
          <GridContainer
            columns={ 2 }
            typeListGrid={ 'search' }
          >
            <InputText
              name='firstName'
              errors={ errors }
              register={ register }
              label='user.firstName'
              required
            />
            <InputText
              name='lastName'
              errors={ errors }
              register={ register }
              label='user.lastName'
              required
            />
            <InputText
              name='email'
              type='email'
              errors={ errors }
              register={ register }
              label='user.email'
              required
            />
            <InputText
              name='companyName'
              errors={ errors }
              register={ register }
              label='user.company'
              required
            />
            <InputText
              name='jobPosition'
              errors={ errors }
              register={ register }
              label='subscribeModal.role'
              required
            />
            <InputSelect
              name='location'
              errors={ errors }
              control={ control }
              label='event_form.location'
              options={ locationOptions }
              required
              fieldNoClear
            />
          </GridContainer>
        </ModalBody>
        <ModalFooter className='m-t-3'>
          <Button
            size='lg'
            buttonText='dialog.confirm'
            buttonType='submit'
            disabled={ false }
          />
          <Button
            size='lg'
            buttonText='cancel'
            emptyButtonType='empty-grey'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

TrainingAttendanceModal.propTypes = {
  filters: PropTypes.object,
  slug: PropTypes.string,
};

export default TrainingAttendanceModal;
