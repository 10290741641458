import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_BULK_MESSAGE_SENDER_START,
  GET_BULK_MESSAGE_SENDER_ERROR,
  GET_BULK_MESSAGE_SENDER_SUCCESS,
} from '@src/js/actions/bulkMessage/bulkMessageGetSender';
import * as api from '@src/js/api/bulkMessage/bulkMessageGetSender';

function* getBulkMessageSender(filters) {
  try {
    const data = yield call(() => api.getBulkMessageSender(filters.filters.values));
    yield put({ type: GET_BULK_MESSAGE_SENDER_SUCCESS, data });
    filters.filters.resolve(data);
  } catch (error) {
    yield put({ type: GET_BULK_MESSAGE_SENDER_ERROR, error });
    filters.filters.reject(error.response);
  }
}

function* getBulkMessageSenderWatcher() {
  yield takeLatest(GET_BULK_MESSAGE_SENDER_START, getBulkMessageSender);
}

export default [
  getBulkMessageSenderWatcher(),
];
