import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { purifyHtml } from '@src/js/utils';
import loadEnvVariable from '@src/js/static/LoadEnv';
import NetworkingCard from '@app/components/global/cards/NetworkingCard';

class ProgrammeHomeProfile extends Component {
  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    loading: PropTypes.bool,
    members: PropTypes.array,
  };

  render() {
    const {
      error,
      loading,
      members,
    } = this.props;

    return (
      <div className='container'>
        <div className='content'>
          <div className='elite-profile-content'>
            <div className='elite-background-white elite-shadow-bottom'>
              <div className='elite-content-block elite-content-block-bottom-border'>
                <div className='col-sm-7'>
                  <div className='elite-content-block-title'>
                    <p>
                      <FormattedMessage id='programmeTeaser.title' />
                      &nbsp;
                    </p>
                  </div>
                  <div className='elite-content-block-caption mb-12'>
                    <FormattedMessage id='programmeTeaser.subtitle' />
                  </div>
                  <FormattedMessage id='programmeTeaser.content'>
                    {
                      innerHTMLMessage => (
                        <div
                          className='elite-content-block-content blue-a'
                          dangerouslySetInnerHTML={ { __html: purifyHtml(innerHTMLMessage) } }
                        />
                      )
                    }
                  </FormattedMessage>
                  <div className='row' />
                </div>
                <div className='col-sm-5 programme-card'>
                  <div className='embed-container'>
                    <iframe
                      title='Teaser page video'
                      width='100%'
                      height='100%'
                      src={ loadEnvVariable('PROGRAMME_TEASER_VIDEO_LINK') }
                      frameBorder='0'
                      scrolling='no'
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='content'>
          { !loading && !error && members && members.length > 0 && (
            <div className='elite-profile-content elite-profile-content-additional'>
              <div className='elite-background-white elite-shadow-bottom'>
                <div className='content'>
                  <span className='elite-profile-content-text-title d-block m-b-1'>
                    <FormattedMessage id='programmeTeaser.communityManager' />
                  </span>
                  <div className='row'>
                    { members.map(member => (
                      <div className='col-sm-6 col-xs-12 networking-card__p-y' key={ member.id }>
                        <NetworkingCard user={ { uuid: member.id, ...member } } />
                      </div>
                    )) }
                  </div>
                </div>
              </div>
            </div>
          ) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.relationManagers.error,
  loading: state.relationManagers.loading,
  members: state.relationManagers.members,
});

const connectWrapper = connect(mapStateToProps)(ProgrammeHomeProfile);

export default withRouter(connectWrapper);
