import fetchResource from '@src/js/api/fetch-resource';

export function getUser(id) {
  return fetchResource(`api/users/${ id }`, {
    method: 'GET',
  });
}
export function getMyProfile() {
  return fetchResource('api/user/profile', {
    method: 'GET',
  });
}

export function editUser(user) {
  return fetchResource(`api/users/${ user.uuid }`, {
    method: 'PUT',
    'body': user,
  });
}

export function editMyProfile(user) {
  return fetchResource('api/user/profile', {
    method: 'PATCH',
    'body': user,
  });
}

export function getMyCompanyMembers() {
  return fetchResource('api/user/my-members', {
    method: 'GET',
  });
}

export function loginUser(credentialsData) {
  return fetchResource('api/login', {
    method: 'POST',
    'body': credentialsData,
  });
}

export function logoutUser() {
  return fetchResource('api/logout', {
    method: 'POST',
  });
}

export function impersonateUser(user) {
  let url = 'api/impersonate';
  url += `?${ new URLSearchParams({ '_switch_user': user.email }) }`;

  return fetchResource(url, {
    noContentType: true,
    noAccept: true,
  });
}

export function exitImpersonateSession() {
  let url = 'api/impersonate/exit';
  url += `?${ new URLSearchParams({ '_switch_user': '_exit' }) }`;

  return fetchResource(url, {
    noContentType: true,
    noAccept: true,
  });
}

export function recreateUserToken(userData, urlRecreateUserToken) {
  return fetchResource(urlRecreateUserToken, {
    method: 'POST',
    'body': { userData },
  });
}

export function unlockUser(uuid) {
  return fetchResource(`api/admin/users/${ uuid }/unlock`, {
    method: 'POST',
  });
}
