export const GET_PARTNER_COMPANIES_START = 'GET_PARTNER_COMPANIES_START';
export const GET_PARTNER_COMPANIES_ERROR = 'GET_PARTNER_COMPANIES_ERROR';
export const GET_PARTNER_COMPANIES_SUCCESS = 'GET_PARTNER_COMPANIES_SUCCESS';

export function getPartnerCompanies(slug, filters) {
  return {
    type: GET_PARTNER_COMPANIES_START,
    slug,
    filters,
  };
}
