import fetchResource from '@src/js/api/fetch-resource';
import { isPartner } from '@src/js/helpers/helpers';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getTrainingsSummary() {
  return fetchResource('api/companies/training/summary', {
    method: 'GET',
  });
}

export function getTasksSummary() {
  return fetchResource('api/companies/tasks/summary', {
    method: 'GET',
  });
}

export function getProfileSummary() {
  return fetchResource(`api/${ isPartner() ? 'partners' : 'companies' }/completion`, {
    method: 'GET',
  });
}

export function getPartnerEventsSummary() {
  return fetchResource('api/partners/events/summary', {
    method: 'GET',
  });
}

export function sendEventsPublicationRequest() {
  return fetchResource('api/partners/events/publication-request', {
    method: 'POST',
  });
}

export function getPartnerLibrariesSummary() {
  return fetchResource('api/partners/libraries/summary', {
    method: 'GET',
  });
}

export function getPartnerOpportunitySummary() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/opportunities/summary`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
