import { SUCCESS_STORIES_TYPE } from '@app/components/public/funding/constants';
import UserProfile from '@app/components/public/user/UserProfile';
import { CROSS_SELLING_START } from '@app/store/global/actions';
import { getCountries } from '@src/js/actions/taxonomy/countries';
import { getIndustries } from '@src/js/actions/taxonomy/industries';
import { getSecondLevelSectors, getSectors } from '@src/js/actions/taxonomy/sectors';
import Loader from '@src/js/components/global/pageLoader/GlobalLoader';
import { Partner as PartnerType, User } from '@src/js/constants/entities';
import { adminPath, defaultPath, routeCodes } from '@src/js/constants/routes';
import {
  isComplianceUserRole,
  isEtinerary,
  isInstitutionalInvestor,
  isRoleUser,
  partnerBasic,
  partnerHidden,
} from '@src/js/helpers/helpers';
import EOPNetworkRoute from '@src/js/views/EOPNetworkRoute';
import EOPProgrammeRoute from '@src/js/views/EOPProgrammeRoute';
import EOPRoute from '@src/js/views/EOPRoute';
import React, {
  Suspense, lazy, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';

const Dashboard = lazy(() => import('../views/user/pages/Dashboard/Dashboard'));
const CompassTeaser = lazy(() => import('../views/user/pages/CompassTeaser/CompassTeaser'));
const Funding = lazy(() => import('@app/components/public/funding/Funding'));
const FundingSuccessStories = lazy(() => import('@app/components/public/funding/FundingSuccessStories'));
const BrokerList = lazy(() => import('@app/components/public/funding/Broker/BrokerList'));
const Search = lazy(() => import('../views/user/pages/Search/Search'));
const Admin = lazy(() => import('../views/admin/admin'));
const NotFound = lazy(() => import('../views/user/pages/NotFound'));
const Forbidden = lazy(() => import('../views/user/pages/Forbidden'));
const Messages = lazy(() => import('../views/user/pages/Messages/Messages'));
const TrainingsList = lazy(() => import('../views/user/pages/events/TrainingsList/TrainingsList'));
const Events = lazy(() => import('@app/components/public/network/events/Events'));
const Partner = lazy(() => import('../views/user/pages/Partner/Partner'));
const CreatePartner = lazy(() => import('../views/admin/pages/partner/CreatePartner'));
const Training = lazy(() => import('../views/user/pages/events/Training'));
const CompanyTrainingIndex = lazy(() => import('../views/admin/pages/events/CompanyTrainingIndex'));
const Company = lazy(() => import('../views/user/pages/Company/Company'));
const DealPage = lazy(() => import('@app/components/public/funding/Deal/DealPage'));
const DataRoomList = lazy(() => import('@app/components/global/dataroom/DataRoomList'));
const Directory = lazy(() => import('../views/user/pages/Directory/Directory'));
const ViewAllPage = lazy(() => import('@src/js/views/user/pages/Directory/components/ViewAllPage/ViewAllPage'));
const CompanyEditMain = lazy(() => import('../views/user/pages/CompanyEdit/CompanyEditMain'));
const TasksShow = lazy(() => import('../views/user/pages/Tasks/TasksShow'));
const TaskCreate = lazy(() => import('../views/user/pages/Tasks/create/TaskCreate'));
const TaskEdit = lazy(() => import('../views/user/pages/Tasks/edit/TaskEdit'));
const CompaniesSelector = lazy(() => import('../views/user/pages/Tasks/create/CompaniesSelector'));
const PartnerCompanyList = lazy(() => import('../views/user/pages/PartnerCompanies/PartnerCompanyList'));
const ManagerCompanyList = lazy(() => import('../views/user/pages/ManagerCompanies/ManagerCompanyList'));
const PartnerLibraries = lazy(() => import('../views/user/pages/PartnerLibraries/PartnerLibraries'));
const NewsIndex = lazy(() => import('@src/js/views/user/pages/news/NewsSection/NewsIndex'));
const NewsList = lazy(() => import('@src/js/views/user/pages/news/NewsSection/NewsList'));
const SingleNews = lazy(() => import('../views/user/pages/news/singleNews/SingleNews'));
const LibraryIndex = lazy(() => import('@app/components/public/network/library/LibraryIndex'));
const LibraryList = lazy(() => import('@app/components/public/network/library/LibraryList'));
const SingleLibrary = lazy(() => import('@app/components/public/network/library/singleLibrary/SingleLibrary'));
const ProgrammeDocuments = lazy(() => import('../views/user/pages/Programme/Documents'));
const VideoOnDemand = lazy(() => import('@src/js/views/user/pages/VideoOnDemand/VideoOnDemand'));
const PartnerCreateLibrary = lazy(() => import('../views/admin/pages/library/PartnerCreateLibrary'));
const PartnerEditLibrary = lazy(() => import('../views/admin/pages/library/PartnerEditLibrary'));
const MarketplaceDashboard = lazy(() => import('../marketplace/layouts/dashboard/MarketplaceDashboard'));
const OpportunityProfile = lazy(() => import('../marketplace/layouts/OpportunityProfile'));
const OpportunityForm = lazy(() => import('../marketplace/layouts/OpportunityForm'));
const MembershipForm = lazy(() => import('@src/js/oneplatform/membership/pages/form/container'));
const FundingOptionDetails = lazy(() => import('@app/components/public/funding/Options/Details'));
const ComplianceForm = lazy(() => import('@app/components/public/compliance/ComplianceForm'));
const ComplianceList = lazy(() => import('@app/components/admin/compliance/ComplianceList'));
const DataRoom = lazy(() => import('@app/components/global/dataroom/DataRoom'));
const EOPEditCompanyRoute = lazy(() => import('@src/js/views/EOPEditCompanyRoute'));
const AddendumManagement = lazy(() => import('@app/components/public/company/AddendumManagement'));
const Certificates = lazy(() => import('@app/components/public/eliteCertificates/Certificates'));
const TopicPage = lazy(() => import('@app/components/public/topic/TopicPage'));

const programmePartnerAdmin = 8192;
const permissionOnlyRelationshipManager = 32768;

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.initInfo.userInfo);

  useEffect(() => {
    dispatch({ type: CROSS_SELLING_START });
    dispatch(getCountries());
    dispatch(getSectors());
    dispatch(getSecondLevelSectors());
    dispatch(getIndustries());
  }, [dispatch]);

  const partnerSlug = userData?.partnerSlug;
  const isPartnerBasicFunding = (userData?.partnerType === PartnerType.TYPE_BASIC
        || userData?.partnerType === PartnerType.TYPE_FUNDING_PARTNER);

  if (isComplianceUserRole()) {
    return (
      <Suspense fallback={ <Loader /> }>
        <Switch>
          <Redirect exact from={ routeCodes.HOME } to={ routeCodes.COMPLIANCE_LIST } />
          <Redirect exact from={ routeCodes.DASHBOARD } to={ routeCodes.COMPLIANCE_LIST } />
          <Route exact path={ routeCodes.COMPLIANCE_LIST } render={ () => <ComplianceList /> } />
          <Route
            exact
            path={ routeCodes.COMPLIANCE }
            render={ () => <ComplianceForm /> }
          />
          <Route exact path={ routeCodes.MY_PROFILE } render={ () => <UserProfile formType='my_profile' /> } />
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={ <Loader /> }>
      <Switch>
        <Redirect exact from={ routeCodes.HOME } to={ routeCodes.DASHBOARD } />
        { isPartnerBasicFunding && (
        <Redirect exact from={ `${ routeCodes.NETWORK }*` } to={ routeCodes.MY_COMPANY } />
        )}

        <EOPNetworkRoute
          exact
          path={ routeCodes.NETWORK }
        />
        <EOPProgrammeRoute
          exact
          path={ routeCodes.PROGRAMME }
        />
        {/* todo - navigation is not working properly, should be refactored */}
        <Redirect exact from={ routeCodes.OPPORTUNITIES } to={ routeCodes.EOP_FUNDING } />
        <Route exact path={ routeCodes.EOP_FUNDING } render={ () => <Funding /> } />
        <Route exact path={ routeCodes.BROKER } render={ () => <BrokerList /> } />
        <Route path={ routeCodes.DEAL_PAGE } render={ () => <DealPage /> } />
        <Route exact path={ routeCodes.DASHBOARD } render={ () => <Dashboard /> } />
        <Route path={ routeCodes.COMPASS_TEASER } render={ () => <CompassTeaser /> } />
        <Route exact path={ routeCodes.MY_PROFILE } render={ () => <UserProfile formType='my_profile' /> } />
        <Route exact path={ routeCodes.SEARCH } render={ () => <Search /> } />
        <Route exact path={ routeCodes.TRAININGS } render={ () => <TrainingsList /> } />
        <Route exact path={ routeCodes.EVENTS } render={ () => <Events /> } />
        <Route exact path={ `${ routeCodes.TRAININGS }/:slug` } render={ () => <Training /> } />
        <Route exact path={ `${ routeCodes.TRAININGS }/:slug/:urlTab` } render={ () => <Training /> } />
        <Route exact path={ `${ routeCodes.EVENTS }/:slug` } render={ () => <Training /> } />
        <Route exact path={ `${ routeCodes.EVENTS }/:slug/:urlTab` } render={ () => <Training /> } />
        <Route exact path={ `${ routeCodes.PROGRAMME_DOCUMENTS }` } render={ () => <ProgrammeDocuments /> } />
        <Route exact path={ `${ routeCodes.VIDEO_ON_DEMAND }` } render={ () => <VideoOnDemand /> } />
        <Route
          exact
          path={ routeCodes.TRANSACTIONS }
          render={ () => (
            <FundingOptionDetails
              title='funding.transactions.title'
              body='funding.transactions.body'
              type={ SUCCESS_STORIES_TYPE.TRANSACTIONS }
            />
          ) }
        />
        <Route
          exact
          path={ routeCodes.BASKET_BONDS }
          render={ () => (
            <FundingOptionDetails
              title='funding.basketBonds.title'
              body='funding.basketBonds.body'
              type={ SUCCESS_STORIES_TYPE.BASKET_BONDS }
            />
          ) }
        />
        <Route
          exact
          path={ routeCodes.INTRODUCTION_SERVICES }
          render={ () => (
            <FundingOptionDetails
              title='funding.introductionServices.title'
              body='funding.introductionServices.body'
              type={ SUCCESS_STORIES_TYPE.INTRODUCTION }
            />
          ) }
        />
        <Route
          exact
          path={ routeCodes.FUNDING_IPO }
          render={ () => (
            <FundingOptionDetails
              title='funding.ipo.title'
              body='funding.ipo.body'
              type={ SUCCESS_STORIES_TYPE.IPO }
            />
          ) }
        />
        <Route
          path={ [routeCodes.FUNDING_DATA_ROOM_FOLDER, routeCodes.FUNDING_DATA_ROOM_FOLDER] }
          render={ () => <DataRoom /> }
        />
        <Route
          exact
          path={ routeCodes.CERTIFICATES }
          render={ () => <Certificates /> }
        />
        <Route
          exact
          path={ routeCodes.TOPIC_PAGE }
          render={ () => <TopicPage /> }
        />
        <Route
          exact
          path={ routeCodes.COMPLIANCE }
          render={ () => <ComplianceForm /> }
        />
        <Route path={ routeCodes.SUCCESS_STORY } render={ () => <SingleNews /> } />
        <Route
          exact
          path={ `${ routeCodes.EDIT_COMPANIES }/:slug/financials` }
          render={ () => <CompanyEditMain /> }
        />
        <Route
          exact
          path={ routeCodes.MY_COMPANY_FINANCIALS_EDIT }
          render={ () => <CompanyEditMain mine={ true } /> }
        />
        <Route
          exact
          path={ `${ routeCodes.COMPANY }/edit` }
          render={ () => <EOPEditCompanyRoute /> }
        />
        <Route
          exact
          path={ `${ routeCodes.MY_COMPANY }/edit` }
          render={ () => (partnerSlug
            ? <CreatePartner userType={ User.TYPE_USER } mine={ true } />
            : <EOPEditCompanyRoute mine={ true } />) }
        />
        <EOPRoute
          exact
          path={ `${ routeCodes.PARTNER_COMPANIES }` }
          render={ () => <PartnerCompanyList /> }
          permissions={ programmePartnerAdmin }
        />
        <EOPRoute
          exact
          path={ `${ routeCodes.RELATIONSHIP_MANAGER }` }
          render={ () => <ManagerCompanyList /> }
          permissions={ permissionOnlyRelationshipManager }
        />
        {!isInstitutionalInvestor() && (
        <Redirect exact from={ routeCodes.MY_DATAROOMS } to={ routeCodes.DASHBOARD } />
        )}
        <EOPRoute
          exact
          path={ `${ routeCodes.MY_DATAROOMS }` }
          render={ () => <DataRoomList /> }
        />
        <EOPRoute
          exact
          path={ `${ routeCodes.FUNDING_SUCCESS_STORY }` }
          render={ () => <FundingSuccessStories /> }
        />
        <Route exact path={ `${ routeCodes.COMPANY_TASKS }/create` } render={ () => <TaskCreate /> } />
        <Route
          exact
          path={ `${ routeCodes.COMPANY_TASKS }/chose-companies` }
          render={ () => <CompaniesSelector /> }
        />
        <Route exact path={ routeCodes.COMPANY_TASKS_EDIT } render={ () => <TaskEdit /> } />
        <Route
          path={ routeCodes.MY_COMPANY_TASKS }
          render={ () => <TasksShow mine /> }
        />
        <Route
          path={ routeCodes.COMPANY_TASKS }
          render={ () => <TasksShow /> }
        />
        <Route exact path={ routeCodes.COMPANY_TRAININGS } render={ () => <CompanyTrainingIndex /> } />
        <Route path={ routeCodes.COMPANY } render={ () => <Company /> } />
        <Route
          path={ routeCodes.MY_COMPANY }
          render={ () => (partnerSlug ? <Partner /> : <Company key='my-company' />) }
        />
        <Route
          exact
          path={ `${ routeCodes.OPPORTUNITY_FORM }/create` }
          render={ () => <OpportunityForm /> }
        />
        <Route
          exact
          path={ `${ routeCodes.OPPORTUNITY_FORM }/:id/edit` }
          render={ () => <OpportunityForm /> }
        />
        <Route exact path={ routeCodes.OPPORTUNITY_PROFILE } render={ () => <OpportunityProfile /> } />
        { !isEtinerary() && (
        <Route
          path={ routeCodes.MARKETPLACE }
          render={ () => <MarketplaceDashboard /> }
        />
        )
            }
        <Route
          exact
          path={ `${ routeCodes.MY_PARTNER }/edit` }
          render={ () => <CreatePartner userType={ User.TYPE_USER } /> }
        />
        <EOPRoute
          exact
          path={ routeCodes.PARTNER_LIBRARIES }
          render={ () => <PartnerLibraries /> }
        />
        <EOPRoute
          exact
          path={ routeCodes.PARTNER_LIBRARIES_CREATE }
          render={ () => <PartnerCreateLibrary /> }
        />
        <Route
          exact
          path={ routeCodes.PARTNER_LIBRARIES_EDIT }
          render={ () => <PartnerEditLibrary /> }
        />
        <Route
          exact
          path={ routeCodes.USER_DATA_ROOM }
          render={ () => <DataRoom /> }
        />
        <Route
          exact
          path={ routeCodes.ADDENDUM_MANAGEMENT }
          render={ () => <AddendumManagement /> }
        />
        <Route path={ `${ routeCodes.PARTNER }` } render={ () => <Partner /> } />
        <Route path={ `${ routeCodes.MY_PARTNER }` } render={ () => <Partner /> } />
        <Route exact path={ routeCodes.MESSAGES } render={ () => <Messages /> } />
        <Route exact path={ routeCodes.MESSAGES_THREAD } render={ () => <Messages /> } />
        { !isEtinerary()
            && <Route exact path={ routeCodes.DIRECTORY } render={ () => <Directory /> } />
            }
        <Route exact path={ routeCodes.DIRECTORY_VIEW_ALL } render={ () => <ViewAllPage /> } />
        <Route exact path={ routeCodes.NEWS } render={ () => <NewsIndex /> } />
        <Route exact path={ routeCodes.NEWS_LIST } render={ () => <NewsList /> } />
        <Route exact path={ routeCodes.SINGLE_NEWS } render={ () => <SingleNews /> } />
        {(!isRoleUser() || (isRoleUser() && !partnerBasic() && !partnerHidden())) && (
        <Route exact path={ routeCodes.LIBRARY } render={ () => <LibraryIndex /> } />
        )}
        {(!isRoleUser() || (isRoleUser() && !partnerBasic() && !partnerHidden())) && (
        <Route exact path={ routeCodes.LIBRARY_ALL } render={ () => <LibraryList /> } />
        )}
        <Route exact path={ routeCodes.SINGLE_LIBRARY } render={ () => <SingleLibrary /> } />
        <Route exact path={ routeCodes.MEMBERSHIP_FORM } render={ () => <MembershipForm /> } />
        <Route path={ adminPath } render={ () => <Admin /> } />
        <Route path={ routeCodes.FORBIDDEN } render={ () => <Forbidden /> } />
        { /* NOTE: this is a default matcher, don't add anything after it */ }
        <Route path={ defaultPath } render={ () => <NotFound /> } />
      </Switch>
    </Suspense>
  );
};

export default PrivateRoutes;
