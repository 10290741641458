export const PARTNER_GET_LIBRARY_OPTIONS_START = 'PARTNER_GET_LIBRARY_OPTIONS_START';
export const PARTNER_GET_LIBRARY_OPTIONS_ERROR = 'PARTNER_GET_LIBRARY_OPTIONS_ERROR';
export const PARTNER_GET_LIBRARY_OPTIONS_SUCCESS = 'PARTNER_GET_LIBRARY_OPTIONS_SUCCESS';
export const PARTNER_CLEAR_LIBRARY_OPTIONS = 'PARTNER_CLEAR_LIBRARY_OPTIONS';

export function partnerGetLibraryOptions(slug) {
  return {
    type: PARTNER_GET_LIBRARY_OPTIONS_START,
    slug,
  };
}

export function partnerClearLibraryOptions() {
  return {
    type: PARTNER_CLEAR_LIBRARY_OPTIONS,
  };
}
