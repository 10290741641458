import fetchResource from '@src/js/api/fetch-resource';
import { User } from '@src/js/constants/entities';

export function getPartnerFormOptions(slug, userType) {
  let urlType = '';
  if (userType === User.TYPE_ADMIN) {
    urlType = '/admin';
  } else if (userType === User.TYPE_ONBOARDING) {
    urlType = '/onboarding';
  }
  let url = `api${ urlType }/partners/form-options`;

  if (slug) {
    url = `${ url }/${ slug }`;
  }

  return fetchResource(url);
}
