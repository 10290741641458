import {
  GET_OPPORTUNITY_PROFILE_START,
  GET_OPPORTUNITY_PROFILE_ERROR,
  GET_OPPORTUNITY_PROFILE_SUCCESS,
  GET_RELATED_OPPORTUNITIES_START,
  GET_RELATED_OPPORTUNITIES_ERROR,
  GET_RELATED_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getOpportunityProfile';

const initialState = {
  loading: false,
  error: null,
  opportunity: {},
  opportunities: {},
};

const actionsMap = {
  [GET_OPPORTUNITY_PROFILE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    opportunity: {},
  }),
  [GET_OPPORTUNITY_PROFILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    opportunity: {},
  }),
  [GET_OPPORTUNITY_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    opportunity: action.data,
  }),
  [GET_RELATED_OPPORTUNITIES_START]: (state) => ({
    ...state,
    opportunities: {},
  }),
  [GET_RELATED_OPPORTUNITIES_ERROR]: (state) => ({
    ...state,
    opportunities: {},
  }),
  [GET_RELATED_OPPORTUNITIES_SUCCESS]: (state, action) => ({
    ...state,
    opportunities: action.data,
  }),
  'UPDATE_OPPORTUNITY': (state, action) => {
    if (state.opportunity && state.opportunity.Id === action.opportunity.Id) {
      return {
        ...state,
        opportunity: action.opportunity,
      };
    }
    return state;
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
