import React from 'react';
import PropTypes from 'prop-types';

const Background = ({ image }) => (
  <>
    <img
      src={ image }
      className='elite-profile-header-image-full'
      alt='background'
    />
    <div className='elite-background-overlay elite-background-dark-teal' />
  </>
);

Background.propTypes = {
  image: PropTypes.string,
};

export default Background;
