import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import ClipboardObserver from '@ckeditor/ckeditor5-clipboard/src/clipboardobserver';
import ClipboardPipeline from '@ckeditor/ckeditor5-clipboard/src/clipboardpipeline';
import { Plugin } from '@ckeditor/ckeditor5-core';

/**
 * The plugin forces paste as plain text.
 *
 * @extends module:core/plugin~Plugin
 */
export default class PastePlainText extends Plugin {
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return 'PastePlainTextForce';
  }

  /**
   * @inheritDoc
   */
  static get requires() {
    return [ClipboardPipeline];
  }

  /**
   * @inheritDoc
   */
  init() {
    const { editor } = this;
    const { view } = editor.editing;

    view.addObserver(ClipboardObserver);

    const editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (_, data) => {
      const { dataTransfer } = data;
      const content = plainTextToHtml(dataTransfer.getData('text/plain'));
      // eslint-disable-next-line no-param-reassign
      data.content = editor.data.htmlProcessor.toView(content);

      editingView.scrollToTheSelection();
    }, { priority: 'high' });
  }
}
