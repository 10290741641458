export const GET_SECTORS_START = 'GET_SECTORS_START';
export const GET_SECTORS_ERROR = 'GET_SECTORS_ERROR';
export const GET_SECTORS_SUCCESS = 'GET_SECTORS_SUCCESS';
export const GET_SECOND_SECTORS_START = 'GET_SECOND_SECTORS_START';
export const GET_SECOND_SECTORS_ERROR = 'GET_SECOND_SECTORS_ERROR';
export const GET_SECOND_SECTORS_SUCCESS = 'GET_SECOND_SECTORS_SUCCESS';

export function getSectors(parent) {
  return {
    type: GET_SECTORS_START,
    parent,
  };
}

export function getSecondLevelSectors() {
  return {
    type: GET_SECOND_SECTORS_START,
  };
}
