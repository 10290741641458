import { createDropdown } from '@ckeditor/ckeditor5-ui';
import { Plugin } from '@ckeditor/ckeditor5-core';
import VideoFormView from './VideoFormView';
import { videoIcon } from './Icons';
/**
 * The plugin insert wistia/thron/video content.
 *
 * @extends module:core/plugin~Plugin
 */
export default class VideoControl extends Plugin {
  source = 'wistia';
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return 'VideoControl';
  }

  /**
   * @inheritDoc
   */
  init() {
    const { editor } = this;

    editor.ui.componentFactory.add('videoControl', locale => {
      const dropdown = createDropdown(locale);
      const form = this.createFormView();
      dropdown.panelView.children.add(form);

      dropdown.buttonView.set({
        label: 'Video Control',
        icon: videoIcon,
        tooltip: true,
      });

      dropdown.render();

      return dropdown;
    });
  }

  createFormView() {
    const editor = this.editor;
    const formView = new VideoFormView(editor.locale);

    this.listenTo(formView, 'submit', () => {
      const linkElement = formView.linkInputView.fieldView.element as HTMLInputElement;
      const url = linkElement.value;

      if (url && this.source) {
        editor.model.change(writer => {
          editor.model.insertContent(writer.createText(`{{${ this.source }:${ url }}}`));
        });
      }

      editor.editing.view.focus();
    });

    this.listenTo(formView, 'cancel', () => {
      editor.editing.view.focus();
    });
    this.listenTo(formView, 'sourceSelect', (val) => {
      const src = val.source as { label: string };
      this.source = src.label;
    });

    return formView;
  }
}
