import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@src/js/components/global/buttons/Buttons';

import {
  clearChat, closeChatPopup, closeMemberPopup, selectCurrentThread, selectUserUuid, showChatPopup,
} from '@app/store/chat/chatSlice';
import { RootState, useAppDispatch } from '@src/js/store';
import { findCreateNewThread } from '@app/store/chat/chatApi';

const MemberPopUp = () => {
  const dispatch = useAppDispatch();
  const userUuid = useSelector(selectUserUuid);
  const userWidget = useSelector(selectCurrentThread);
  const loggedUserUuid = useSelector<RootState, string>(state => state.initInfo.userInfo.uuid);

  useEffect(() => {
    dispatch(findCreateNewThread({ participants: [userUuid] }));
  }, [dispatch, userUuid]);

  const closeWidget = () => {
    dispatch(closeMemberPopup());
    dispatch(closeChatPopup());
    dispatch(clearChat());
  };

  const onClick = () => {
    dispatch(closeMemberPopup());
    dispatch(showChatPopup(''));
  };

  const showButton = !!(loggedUserUuid && userUuid && !(loggedUserUuid === userUuid));

  return (
    <div className='wrapper'>
      <div className='member-popUp__wrapper'>
        <div className='member-popUp__sub'>
          { userWidget && (
          <div className='member-popUp'>
            <div className={ `member-popUp__content ${ showButton ? '' : 'no-border' }` }>
              <div
                className='bgImage elite-background-model3d'
              />
              <span
                className='close'
                aria-label='close'
                onKeyUp={ closeWidget }
                onClick={ closeWidget }
                role='presentation'
              />
              <div className='avatar-input'>
                <div className='avatar-input__preview'>
                  <img alt='avatar' src={ userWidget.participantImages[0] } />
                </div>
              </div>
              <div className='member-popUp__content--info'>
                <span className='name'>{ userWidget.subject ?? '' }</span>
                <span className='company'>{ userWidget.participants[0].company ?? '' }</span>
                <span className='job_title'>{ userWidget.participants[0].jobTitle ?? '' }</span>
              </div>
            </div>
            { showButton && (
            <Button
              buttonType='button'
              buttonText='sendMessage'
              onClickFunc={ onClick }
            />
            )}
          </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default MemberPopUp;
