import { Thread } from '@app/models/Chat';
import useBreakpoint from '@app/services/useBreakpoint';
import { loadLatestThreads, loadMessages } from '@app/store/chat/chatApi';
import {
  closeChatPopup,
  selectThreadsList, selectThreadsLoading, setCurrentThread, showChatPopup,
} from '@app/store/chat/chatSlice';
import { ButtonLink } from '@src/_v2/js/components/buttons';
import { routeCodes, withLang } from '@src/js/constants/routes';
import ThreadItem from '@src/js/layouts/global/panels/messaging/components/ThreadItem';
import { useAppDispatch } from '@src/js/store';
import {
  Content, Footer, Loader,
} from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components';
import Header from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components/Header/Header';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const LatestConversationWidget = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectThreadsLoading);
  const latestThreads = useSelector(selectThreadsList);
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    dispatch(loadLatestThreads());

    return () => {
      dispatch(closeChatPopup());
    };
  }, [dispatch]);

  if (!latestThreads || latestThreads.length === 0) {
    return null;
  }

  const getMessages = (thread: Thread) => {
    if (!thread.id) return;
    dispatch(showChatPopup(''));
    if (breakpoint === 'mobile') {
      document.body.classList.add('noScroll');
    }
    dispatch(setCurrentThread(thread.id));
    dispatch(loadMessages({ threadId: thread.id || '' }));
  };

  return (
    <DashboardWidget className='p-x-1 p-y-1'>
      <div className='dashboard-conversations' id='dashboard-conversations'>
        <Header>
          <FormattedMessage id='latestConversations.header' />
        </Header>
        <Content>
          { loading && <Loader /> }
          {latestThreads.map((thread) => (
            <ThreadItem
              key={ thread.id }
              thread={ thread }
              isSelected={ false }
              getMessages={ getMessages }
            />
          )
          )}
        </Content>
        <Footer>
          <ButtonLink text='latestConversations.goToMessages' to={ `${ withLang(routeCodes.MESSAGES) }` } />
        </Footer>
      </div>
    </DashboardWidget>
  );
};

export default LatestConversationWidget;
