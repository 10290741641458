import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ellipsisMiddle } from '@src/js/helpers/helpers';
import { downloadFile } from '@app/components/global/forms/utils';
import { Container, FileName, RemoveIcon } from './styled';
import { DocumentIcon } from './components';

class FileCard extends Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    removeFile: PropTypes.func.isRequired,
    downloadable: PropTypes.bool,
    removable: PropTypes.bool,
    fileType: PropTypes.string,
  };

  static defaultProps = {
    removable: true,
    fileType: 'file',
  }

  removeItem(item) {
    const { removeFile } = this.props;

    removeFile(item);
  }

  download(currentFile) {
    const { downloadable } = this.props;
    if (!downloadable) return;
    downloadFile(currentFile.url, currentFile.name);
  }

  render() {
    const {
      file, downloadable, removable, fileType,
    } = this.props;

    if (file && !file.id) {
      return null;
    }

    return (
      <Container>
        <div className={ `card file-preview-card ${ fileType === 'image' ? 'image-preview-card' : '' }` }>
          { removable && <RemoveIcon onClick={ () => this.removeItem(file.id) } /> }
          {fileType === 'image'
            ? (
              <div className='upload-card__content'>
                <img
                  src={ file.preview || file.url }
                  alt=''
                  onLoad={ () => URL.revokeObjectURL(file.preview || '') }
                />
              </div>
            )
            : (
              <>
                <DocumentIcon />
                <div
                  className={
                  `card file-preview-card__content ${ downloadable ? 'cursor-pointer enabled-download' : '' }`
                  }
                  onClick={ () => this.download(file) }
                  role='presentation'
                >
                  {file?.name && (
                  <FileName>
                    {ellipsisMiddle(file.name)}
                  </FileName>
                  )}
                </div>
              </>
            )
          }

        </div>
      </Container>
    );
  }
}

export default FileCard;
