import fetchResource from '@src/js/api/fetch-resource';

export function getLibraryDimensionData(params) {
  const query = new URLSearchParams(params);
  return fetchResource(`api/libraries?${ query }`, {
    method: 'GET',
  });
}

export function getLibraryDimensions() {
  return fetchResource('api/libraries/dimensions', {
    method: 'GET',
  });
}
