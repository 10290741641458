import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import EliteCKEditor from './EliteCKEditor';

type Props = {
  name: string,
  className?: string,
  label?: string,
  tooltip?: string,
  control: Control<any>,
  required?: boolean,
  errors: object,
  translate?: boolean,
  placeholder?: string,
  imageControl?: ImageControl,
  videoControl?: boolean,
  htmlControl?: boolean,
  formControl?: boolean,
  maxLength?: number,
  disabled?: boolean,
}

type ImageControl ={
  imageType: string,
  entity: string
}

const InputRichText = ({
  name, className, label, required, tooltip, control, errors, translate = true, placeholder,
  imageControl, videoControl, htmlControl, maxLength, formControl, disabled,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  const maxLengthError = maxLength ? {
    value: maxLength,
    message: intl.formatMessage({ id: 'global_form.error.max_length' }, { maxLength }),
  } : undefined;

  return (
    <div className={ `eop-text-textarea ${ className }` } data-name={ name }>
      <label className='eop-text-textarea__label'>
        { label && required && <span className='required-icon' /> }
        { label && fieldLabel }
        { tooltip && (
        <>
          <span className='icon-medium-Info font-size-icon' data-tip data-for={ `${ name }-tooltip` } />
          <ReactTooltip
            id={ `${ name }-tooltip` }
            place='top'
            effect='solid'
            multiline
            className='w-25'
          >
            { fieldTooltip }
          </ReactTooltip>
        </>
        )}
      </label>
      <Controller
        name={ name }
        control={ control }
        rules={ { required: requiredError, maxLength: maxLengthError } }
        render={ ({ field }) => (
          <EliteCKEditor
            field={ field }
            translate={ translate }
            placeholder={ placeholder }
            imageControl={ imageControl }
            videoControl={ videoControl }
            htmlControl={ htmlControl }
            formControl={ formControl }
            disabled={ disabled }
          />
        ) }
      />
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

export default InputRichText;
