import {
  emailPattern, numberPattern, passwordPattern, percentagePattern, phonePattern, urlPattern,
} from '@src/js/constants/patterns';
import { IntlShape } from 'react-intl';

type Input = {
  type: 'text'|'password'|'email'|'number'|'tel'|'url'|'percentage'|'textNumber'|'textEmail',
  intl: IntlShape,
}

type ValidateInput = Input & {
  minValue?: number,
  maxValue?: number,
  integerOnly?: boolean,
  required?: boolean,
}

type LengthInput = {
  intl: IntlShape,
  maxLength?: number,
  minLength?: number,
}

type Validation = {
  positive: (v: string) => true|string,
  negative: (v: string) => true|string,
  integerOnly: (v: string) => true|string,
}
export const validateText = ({
  maxValue, minValue, type, integerOnly, intl,
}: ValidateInput): Validation => {
  const validate: Validation = {
    positive: () => true,
    negative: () => true,
    integerOnly: () => true,
  };
  if (minValue !== null && minValue !== undefined && type === 'number') {
    validate.positive = (v: string) => (
      parseInt(v, 10) >= minValue
      || intl.formatMessage({ id: 'global_form.error.min_value' }, { minValue })
    );
  }
  if (maxValue !== null && maxValue !== undefined && type === 'number') {
    validate.negative = (v: string) => (
      parseInt(v, 10) <= maxValue
      || intl.formatMessage({ id: 'global_form.error.max_value' }, { maxValue })
    );
  }
  if (integerOnly && type === 'number') {
    validate.integerOnly = (v: string) => (
      Number.isInteger(v)
      || intl.formatMessage({ id: 'global_form.error.float' }, { maxValue })
    );
  }

  return validate;
};

export const validatePattern = ({ intl, type }: Input) => {
  const emailError = type === 'textEmail' || type === 'email' ? {
    value: emailPattern,
    message: intl.formatMessage({ id: 'global_form.error.email' }),
  } : undefined;

  const passwordError = type === 'password' ? {
    value: passwordPattern,
    message: intl.formatMessage({ id: 'global_form.error.password' }),
  } : undefined;

  const phoneError = type === 'tel' ? {
    value: phonePattern,
    message: intl.formatMessage({ id: 'global_form.error.telephone' }),
  } : undefined;

  const urlError = type === 'url' ? {
    value: urlPattern,
    message: intl.formatMessage({ id: 'global_form.error.url' }),
  } : undefined;

  const percentageError = type === 'percentage' ? {
    value: percentagePattern,
    message: intl.formatMessage({ id: 'global_form.error.only_percent_chars' }),
  } : undefined;

  const numberError = type === 'textNumber' ? {
    value: numberPattern,
    message: intl.formatMessage({ id: 'global_form.error.float' }),
  } : undefined;

  return emailError || passwordError || phoneError || urlError || percentageError || numberError;
};

export const validateLength = ({ maxLength, minLength, intl }: LengthInput) => {
  const maxLengthError = maxLength ? {
    value: maxLength,
    message: intl.formatMessage({ id: 'global_form.error.max_length' }, { maxLength }),
  } : undefined;
  const minLengthError = minLength ? {
    value: minLength,
    message: intl.formatMessage({ id: 'global_form.error.min_length' }, { minLength }),
  } : undefined;

  return { maxLengthError, minLengthError };
};
