import {
  GET_MATCHMAKING_MEMBERS_START,
  GET_MATCHMAKING_MEMBERS_ERROR,
  GET_MATCHMAKING_MEMBERS_SUCCESS,
} from '@src/js/marketplace/actions/matchmakingMembers';

const initialState = {
  loading: false,
  error: null,
  members: {},
};

const actionsMap = {
  [GET_MATCHMAKING_MEMBERS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_MATCHMAKING_MEMBERS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_MATCHMAKING_MEMBERS_SUCCESS]: (state, action) => {
    const membersCount = JSON.parse(localStorage.getItem('matchmaking-members-count'));
    membersCount[action.businessNeedId] = action.data.CurrentPage + 1;
    localStorage.setItem('matchmaking-members-count', JSON.stringify(membersCount));
    const data = { ...state.members };
    data[action.businessNeedId] = action.data;

    return {
      ...state,
      loading: false,
      members: data,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
