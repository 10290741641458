import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { ButtonLink } from '@src/_v2/js/components/buttons';
import {
  Task, CreatedDate, TaskActions, Title, Status,
  TaskActionsMobile, TaskAction, ReadMoreContainer,
} from './styled';

const TaskCard = ({ task }) => {
  const userInfo = useSelector(state => state.initInfo.userInfo);
  const taskInfoUrl = `/companies/${ userInfo?.companySlug }/tasks?selected=${ task.slug }`;

  return (
    <Task className={ `task-card ${ task.status }` }>
      <Status>
        <FormattedMessage id={ `task.navigation.single.${ task.status }` } />
      </Status>
      <Row>
        <Title xs sm={ 9 }>
          { task.title }
        </Title>
        <Col sm={ 3 }>
          <TaskActions>
            <Row>
              <ReadMoreContainer xs={ 12 }>
                <ButtonLink text='read_more' to={ taskInfoUrl } />
              </ReadMoreContainer>
            </Row>
          </TaskActions>
        </Col>
      </Row>

      <CreatedDate>{ task.dueDate }</CreatedDate>
      <TaskActionsMobile>
        <Row>
          <TaskAction xs={ 12 } className='end-xs read-more'>
            <ButtonLink text='read_more' to={ taskInfoUrl } />
          </TaskAction>
        </Row>
      </TaskActionsMobile>
    </Task>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object,
};

export default TaskCard;
