export const GET_EVENTS_START = 'GET_EVENTS_START';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const GET_SINGLE_EVENT_BASIC_START = 'GET_SINGLE_EVENT_BASIC_START';
export const GET_SINGLE_EVENT_BASIC_ERROR = 'GET_SINGLE_EVENT_BASIC_ERROR';
export const GET_SINGLE_EVENT_BASIC_SUCCESS = 'GET_SINGLE_EVENT_BASIC_SUCCESS';
export const CLEAR_SINGLE_EVENT_BASIC_DATA = 'CLEAR_SINGLE_EVENT_BASIC_DATA';
export const GET_SINGLE_EVENT_DATA_START = 'GET_SINGLE_EVENT_DATA_START';
export const GET_SINGLE_EVENT_DATA_ERROR = 'GET_SINGLE_EVENT_DATA_ERROR';
export const GET_SINGLE_EVENT_DATA_SUCCESS = 'GET_SINGLE_EVENT_DATA_SUCCESS';
export const EVENT_SUBSCRIBE_MEMBERS_START = 'EVENT_SUBSCRIBE_MEMBERS_START';
export const EVENT_SUBSCRIBE_MEMBERS_FAIL = 'EVENT_SUBSCRIBE_MEMBERS_FAIL';
export const EVENT_SUBSCRIBE_MEMBERS_SUCCESS = 'EVENT_SUBSCRIBE_MEMBERS_SUCCESS';
export const EVENT_SUBSCRIBE_MEMBERS_UPDATED = 'EVENT_SUBSCRIBE_MEMBERS_UPDATED';
export const GET_UPCOMING_EVENTS_START = 'GET_UPCOMING_EVENTS_START';
export const GET_UPCOMING_EVENTS_ERROR = 'GET_UPCOMING_EVENTS_ERROR';
export const GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS';
export const GET_COMMUNITY_START = 'GET_COMMUNITY_START';
export const GET_COMMUNITY_ERROR = 'GET_COMMUNITY_ERROR';
export const GET_COMMUNITY_SUCCESS = 'GET_COMMUNITY_SUCCESS';
export const GET_COMMUNITY_PERMISSIONS_START = 'GET_COMMUNITY_PERMISSIONS_START';
export const GET_COMMUNITY_PERMISSIONS_ERROR = 'GET_COMMUNITY_PERMISSIONS_ERROR';
export const GET_COMMUNITY_PERMISSIONS_SUCCESS = 'GET_COMMUNITY_PERMISSIONS_SUCCESS';
export const EDIT_PERMISSION_START = 'EDIT_PERMISSION_START';
export const EDIT_PERMISSION_ERROR = 'EDIT_PERMISSION_ERROR';
export const EDIT_PERMISSION_SUCCESS = 'EDIT_PERMISSION_SUCCESS';
export const CLEAR_COMMUNITY_DATA = 'CLEAR_COMMUNITY_DATA';
export const SUBSCRIBE_EXTERNAL_START = 'SUBSCRIBE_EXTERNAL_START';
export const SUBSCRIBE_EXTERNAL_FAIL = 'SUBSCRIBE_EXTERNAL_FAIL';
export const SUBSCRIBE_EXTERNAL_SUCCESS = 'SUBSCRIBE_EXTERNAL_SUCCESS';
export const UNSUBSCRIBE_EXTERNAL_START = 'UNSUBSCRIBE_EXTERNAL_START';
export const UNSUBSCRIBE_EXTERNAL_FAIL = 'UNSUBSCRIBE_EXTERNAL_FAIL';
export const UNSUBSCRIBE_EXTERNAL_SUCCESS = 'UNSUBSCRIBE_EXTERNAL_SUCCESS';
export const UNSUBSCRIBE_START = 'UNSUBSCRIBE_START';
export const UNSUBSCRIBE_FAIL = 'UNSUBSCRIBE_FAIL';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';

export function getEventsForUser({
  page, eventType, selectedTab = 'upcoming', slug, companyType = '', search = '',
}: {
  page: number, eventType: string, selectedTab?: string, slug?: string, companyType?: string, search?: string
  }) {
  return {
    type: GET_EVENTS_START,
    page,
    eventType,
    selectedTab,
    slug,
    companyType,
    search,
  };
}

export function clearEvents() {
  return {
    type: CLEAR_EVENTS,
  };
}

export function getEventBasicData(slug: string) {
  return {
    type: GET_SINGLE_EVENT_BASIC_START,
    slug,
  };
}

export function getEventData(slug: string) {
  return {
    type: GET_SINGLE_EVENT_DATA_START,
    slug,
  };
}

export function subscribeMembersIsUpdatedAction() {
  return {
    type: EVENT_SUBSCRIBE_MEMBERS_UPDATED,
  };
}

export function getUpcomingEvents(finanzaAgevolata = false) {
  return {
    type: GET_UPCOMING_EVENTS_START,
    finanzaAgevolata,
  };
}

export function getCommunity(slug: string, params: object) {
  return {
    type: GET_COMMUNITY_START,
    slug,
    params,
  };
}

export function clearCommunityData() {
  return {
    type: CLEAR_COMMUNITY_DATA,
  };
}

export function getCommunityPermissions(slug: string, params: object) {
  return {
    type: GET_COMMUNITY_PERMISSIONS_START,
    slug,
    params,
  };
}

export function editPermission(slug: string, uuid: string, action: string) {
  return {
    type: EDIT_PERMISSION_START,
    slug,
    uuid,
    action,
  };
}

export function subscribeExternal(slug: string, data: object) {
  return {
    type: SUBSCRIBE_EXTERNAL_START,
    slug,
    data,
  };
}

export function unsubscribeUser(slug: string, data: object, filters: object) {
  return {
    type: UNSUBSCRIBE_EXTERNAL_START,
    slug,
    data,
    filters,
  };
}

export function unsubscribe(slug: string, removeParams: () => void) {
  return {
    type: UNSUBSCRIBE_START,
    slug,
    removeParams,
  };
}

export function clearEventBasicData() {
  return {
    type: CLEAR_SINGLE_EVENT_BASIC_DATA,
  };
}
