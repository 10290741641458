import { User } from '@src/js/constants/entities';
import { adminRouteCodes, routeCodes, withLang } from '@src/js/constants/routes';

/**
 *
 * @param Component
 * @param back
 * @param slug
 *
 * Admins should be redirected to the back office company list
 * Programme Partner Admins should be redirected to My Elite Companies page
 * Company Admins should be redirected to myCompany. /myCompany does not work for relationship managers who manage other
 * companies, so we redirect to the companies/slug page even though I have update my company
 */
export const setRedirect = (Component, back = null, slug = null) => {
  const { userType, partnerSlug } = Component.props;
  let path = '';

  if (userType === User.TYPE_ADMIN) {
    path = adminRouteCodes.COMPANIES;
  } else if (back) {
    path = routeCodes.DASHBOARD;
  } else if (userType === User.TYPE_PROGRAMME_PARTNER_ADMIN) {
    path = `${ routeCodes.PARTNERS }/${ partnerSlug }/companies`;
  } else if (userType === User.TYPE_USER && slug) {
    path = `${ withLang(routeCodes.COMPANY.replace(':slug', slug)) }`;
  } else {
    path = routeCodes.DASHBOARD;
  }

  Component.setState({
    redirectTo: withLang(path),
  });
};

/**
 *
 * @param Component
 * @param prevProps
 */
export const receiveFormOptions = (Component, prevProps) => {
  const { companyFormOptions } = Component.props;

  if (prevProps.companyFormOptions !== companyFormOptions && companyFormOptions && companyFormOptions.results) {
    if (companyFormOptions.results.company) {
      const optionCompany = companyFormOptions.results.company;
      let dateMemberSince = null;
      let dateIncorporation = null;
      let companyLogoPreview = null;
      let companyTestimonialImagePreview = null;
      let ateco = null;

      if (optionCompany.basic.ateco) {
        ateco = { value: optionCompany.basic.ateco.id, label: optionCompany.basic.ateco.code };
      }

      if (optionCompany.basic.dateMemberSince) {
        dateMemberSince = new Date(optionCompany.basic.dateMemberSince.date);
      }

      if (optionCompany.basic.dateIncorporation) {
        dateIncorporation = new Date(optionCompany.basic.dateIncorporation.date);
      }

      if (optionCompany.basic.logoPreview) {
        companyLogoPreview = { preview: optionCompany.basic.logoPreview };
      }

      if (optionCompany.testimonial && optionCompany.testimonial.avatarPreview) {
        companyTestimonialImagePreview = { preview: optionCompany.testimonial.avatarPreview };
      }

      Component.setState({
        checkedOfficeLocation: optionCompany.basic.officeLocations,
        checkedSalesArea: optionCompany.basic.salesAreas,
        companyData: optionCompany,
        companyType: optionCompany.basic.type,
        dateMemberSince,
        dateIncorporation,
        companyLogoPreview,
        companyTestimonialImagePreview,
        ateco,
      });
    } else if (
      // if only one partner is available and the field is disabled, add that value to the initial form values
      companyFormOptions.results.canEditPartner === false
      && companyFormOptions.results.partners
      && companyFormOptions.results.partners.length === 1
    ) {
      Component.setState({
        companyData: {
          basic: {
            partner: companyFormOptions.results.partners[0].slug,
          },
        },
      });
    }
  }
};

/**
 *
 * @param Component
 * @param prevProps
 */
export const manageRedirection = (Component, prevProps) => {
  const { company, error, loading } = Component.props;
  if (prevProps.loading && !loading && !error && company && company.status === 'success') {
    setRedirect(Component);
  }
};
