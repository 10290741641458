import fetchResource from '@src/js/api/fetch-resource';

export function getProgrammesList(payload) {
  const params = new URLSearchParams(payload);
  return fetchResource(`api/programmes/list?${ params }`, { method: 'GET' });
}

export function getLoungesList() {
  return fetchResource('api/admin/lounges', { method: 'GET' });
}

export function getProgrammesLounges(id) {
  return fetchResource(`api/admin/programmes/${ id }/lounges`, { method: 'GET' });
}

export function createLounge(data) {
  return fetchResource('api/admin/lounges', { method: 'POST', body: data });
}

export function updateLounge(data) {
  return fetchResource(`api/admin/lounges/${ data.id }`, { method: 'PUT', body: data });
}

export function deleteLounge(id) {
  return fetchResource(`api/admin/lounges/${ id }`, { method: 'DELETE' });
}
