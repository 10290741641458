export const COMPANY_DOCUMENTS_GET_FILES_START = 'COMPANY_DOCUMENTS_GET_FILES_START';
export const COMPANY_DOCUMENTS_GET_FILES_ERROR = 'COMPANY_DOCUMENTS_GET_FILES_ERROR';
export const COMPANY_DOCUMENTS_GET_FILES_SUCCESS = 'COMPANY_DOCUMENTS_GET_FILES_SUCCESS';

export function companyDocumentsGetFiles(slug, offset) {
  return {
    type: COMPANY_DOCUMENTS_GET_FILES_START,
    slug,
    offset,
  };
}
