import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { DebounceInput } from 'react-debounce-input';
import { Button, Loader } from '@src/js/components/global';
import GridContainer from '@app/components/global/list/GridContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { adminCreateCompany } from '@src/js/actions/onboarding/processes/adminProcesses';
import { selectCrmAccounts, selectCrmPagination } from '@app/store/onboarding/selectors';
import { getCrmAccounts } from '@app/store/onboarding/actions';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const FinalizeCompanyModal = ({ hash, filters }) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const crmAccounts = selectCrmAccounts();
  const crmPagination = selectCrmPagination();
  const [crmId, setCrmId] = useState();
  const [modalFilters, setModalFilters] = useState({ page: 1 });

  const nextAccount = () => {
    const nextPage = { ...modalFilters, page: modalFilters.page + 1 };
    setModalFilters(nextPage);
  };

  const handleFilters = (name, value) => {
    const newFilters = { ...modalFilters, page: 1 };
    if (value !== null && value !== '') {
      newFilters[name] = value;
    } else {
      delete newFilters[name];
    }
    setModalFilters(newFilters);
  };

  useEffect(() => {
    dispatch(getCrmAccounts(modalFilters));
  }, [modalFilters]);

  useEffect(() => () => {
    hideModal();
  }, []);

  const submit = () => {
    dispatch(adminCreateCompany(hash, filters, crmId));
    hideModal();
  };

  return (
    <>
      <ModalBody>
        <GridContainer
          columns={ 1 }
          typeListGrid={ 'search' }
          className='justify-content-center'
        >
          <p
            className={ 'finalize-company-modal__tab' }
          >
            <FormattedMessage id='onboarding.finalizeModal.associate' />
          </p>
        </GridContainer>
        <div className='finalize-company-modal__associate-container'>
          <FormattedMessage id='search'>
            { placeholder => (
              <DebounceInput
                debounceTimeout={ 500 }
                placeholder={ placeholder }
                onChange={ (e) => handleFilters('search', e.target.value) }
                className='search-input'
              />
            )}
          </FormattedMessage>
          <div className='finalize-company-modal__associate' id='finalize-company'>
            <InfiniteScroll
              dataLength={ crmAccounts.length }
              next={ nextAccount }
              hasMore={ ((crmPagination?.totalCount || 0) - (crmPagination?.pageCount || 0)) > 0 }
              loader={ <Loader position='relative' background='transparent' /> }
              scrollableTarget='finalize-company'
            >
              { crmAccounts.map(account => (
                <div
                  key={ account.crmId }
                  className={ `finalize-company-modal__account${ crmId === account.crmId ? '-selected' : '' }` }
                  role='presentation'
                  onClick={ () => setCrmId(account.crmId) }
                >
                  <p>{ account.accountName }</p>
                  <p className='eop-text-color-medium-grey font-size-icon w-50'>
                    <FormattedMessage id='company_form.crmId' />
                    {': '}
                    { account.crmId }
                    <br />
                    <FormattedMessage id='company_form.vatNumber' />
                    {': '}
                    { account.vatNumber }
                  </p>
                </div>
              )) }
            </InfiniteScroll>
            { crmAccounts.length === 0 && (
            <div className='no-data'>
              <FormattedMessage id='noResults' />
            </div>
            ) }
          </div>
          <p className='finalize-company-modal__or'>
            <FormattedMessage id='multiple_upload.or' />
          </p>
          <div
            className={ `finalize-company-modal__account${ crmId === null ? '-selected' : '' } justify-content-center` }
            role='presentation'
            onClick={ () => setCrmId(null) }
          >
            <p className=''><FormattedMessage id='onboarding.finalizeModal.create' /></p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='m-t-3'>
        <Button
          size='lg'
          buttonText='dialog.confirm'
          onClickFunc={ submit }
          disabled={ crmId === undefined }
        />
        <Button
          size='lg'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </>
  );
};

FinalizeCompanyModal.propTypes = {
  hash: PropTypes.string.isRequired,
  filters: PropTypes.object,
};

export default FinalizeCompanyModal;
