import produce from 'immer';
import {
  CREATE_FOLDER_START,
  RENAME_FOLDER_START,
  FOLDER_SUCCESS,
  FILE_LIST_START,
  FILE_SUCCESS,
  CREATE_FILE_START,
  MOVE_FILE_START, FILE_LIST_FAIL,
  DELETE_FILE_START, FOLDER_FAIL, FILE_FAIL, FILE_LIST_SUCCESS,
  GET_PERMISSIONS_FAIL, GET_PERMISSIONS_START, GET_PERMISSIONS_SUCCESS,
  GET_LIST_FAIL, GET_LIST_SUCCESS, DOWNLOAD_TASK_CONTINUE,
  ASSOCIATE_SIGNED_DOCUMENT_START,
  DOWNLOAD_TASK_CANCEL,
  MOVE_FILE_LIST_START,
  MOVE_FILE_LIST_FAIL,
  MOVE_FILE_LIST_SUCCESS,
} from '@app/store/documentsArea/actions';

export const initialState = {
  folderLoading: false,
  fileLoading: false,
  fileListData: null,
  fileListError: null,
  permissions: {
    loading: false,
    error: null,
    data: [],
    hasMore: true,
  },
  dataRoomListLoading: true,
  dataRoomListError: null,
  dataRoomList: null,
  downloadTaskId: null,
  downloadTaskCompleted: false,
  moveFileListData: null,
  moveFileListError: null,
  moveFileListLoading: false,
};

/* eslint-disable default-case,no-param-reassign,no-case-declarations,consistent-return */
function dataroomReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CREATE_FOLDER_START:
      case RENAME_FOLDER_START:
      case FOLDER_SUCCESS:
        draft.folderLoading = false;
        break;
      case FOLDER_FAIL:
        draft.folderLoading = false;
        break;
      case FILE_LIST_START:
        draft.fileListData = null;
        draft.fileLoading = true;
        draft.fileListError = null;
        break;
      case CREATE_FILE_START:
      case MOVE_FILE_START:
      case DELETE_FILE_START:
        draft.fileLoading = true;
        draft.fileListError = null;
        break;
      case FILE_LIST_SUCCESS:
        draft.fileListData = action.result;
        draft.fileLoading = false;
        break;
      case FILE_SUCCESS:
        draft.fileLoading = false;
        break;
      case FILE_FAIL:
        draft.fileLoading = false;
        break;
      case FILE_LIST_FAIL:
        draft.fileLoading = false;
        draft.fileListError = action.error;
        break;
      case GET_PERMISSIONS_START:
        draft.permissions.loading = true;
        draft.permissions.hasMore = true;
        draft.permissions.error = null;
        if (action.reset) draft.permissions.data = [];
        break;
      case GET_PERMISSIONS_SUCCESS:
        draft.permissions.loading = false;
        draft.permissions.hasMore = action.result ? action.result.length > 0 : false;
        if (action.reset) {
          draft.permissions.data = action.result;
        } else {
          draft.permissions.data = [...state.permissions.data, ...action.result];
        }
        break;
      case GET_PERMISSIONS_FAIL:
        draft.permissions.loading = false;
        draft.permissions.hasMore = false;
        draft.permissions.error = action.error;
        draft.permissions.data = [];
        break;
      case GET_LIST_FAIL:
        draft.dataRoomListError = action;
        draft.dataRoomListLoading = false;
        break;
      case GET_LIST_SUCCESS:
        draft.dataRoomList = action.result.dataRooms;
        draft.dataRoomListLoading = false;
        break;
      case DOWNLOAD_TASK_CONTINUE:
        draft.downloadTaskCompleted = false;
        draft.downloadTaskId = action.id;
        break;
      case ASSOCIATE_SIGNED_DOCUMENT_START:
        draft.fileLoading = true;
        draft.fileListError = null;
        break;
      case DOWNLOAD_TASK_CANCEL:
        draft.downloadTaskCompleted = true;
        break;
      case MOVE_FILE_LIST_START:
        draft.moveFileListData = null;
        draft.moveFileListLoading = true;
        draft.moveFileListError = null;
        break;
      case MOVE_FILE_LIST_FAIL:
        draft.moveFileListData = null;
        draft.moveFileListLoading = false;
        draft.moveFileListError = action.error;
        break;
      case MOVE_FILE_LIST_SUCCESS:
        draft.moveFileListData = action.result;
        draft.moveFileListLoading = false;
        draft.moveFileListError = null;
        break;
    }
  }
  );
}
/* eslint-enable */

export default dataroomReducer;
