import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ModalComponents, ModalConfig } from '@app/components/global/ModalComponents';

type Props = {
  modalProps: ModalConfig,
  hideModal: () => void,
}

const BaseModal = ({ modalProps, hideModal }: Props) => {
  if (!modalProps) return null;
  const {
    config, type, title, data, translate, hideHeader,
  } = modalProps;

  const renderComponent = () => {
    const ModalComponent = ModalComponents[type];
    if (!ModalComponent) {
      return null;
    }
    return <ModalComponent { ...data } />;
  };

  return (
    <Modal { ...config } isOpen>
      { !hideHeader && (
        <ModalHeader toggle={ hideModal }>
          { title && (
            translate !== false
              ? <FormattedMessage id={ title } />
              : title
          ) }
        </ModalHeader>
      ) }
      { renderComponent() }
    </Modal>
  );
};

export default BaseModal;
