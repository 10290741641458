import {
  ADMIN_GET_EVENT_FORM_OPTIONS_ERROR,
  ADMIN_GET_EVENT_FORM_OPTIONS_START,
  ADMIN_GET_EVENT_FORM_OPTIONS_SUCCESS,
} from '@src/js/actions/events/adminEvents';
import { EventFormOptions, isOrganiserObj } from '@app/models/Event';
import { Action } from 'redux';
import produce from 'immer';
import { gmtToDate } from '@src/js/utils/date';

export type EventFormOptionsState = {
  loading: boolean,
  error: unknown,
  eventFormOptions: null|EventFormOptions,
};

const initialState: EventFormOptionsState = {
  loading: false,
  error: null,
  eventFormOptions: null,
};

interface ActionPayload extends Action {
  status: string,
  error?: { response: string, message?: string },
  data?: {
    results: EventFormOptions,
  }
}

function adminEventFormOptionsReducer(state = initialState, action: ActionPayload) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case ADMIN_GET_EVENT_FORM_OPTIONS_START:
        draft.loading = true;
        draft.error = null;
        draft.eventFormOptions = null;
        break;
      case ADMIN_GET_EVENT_FORM_OPTIONS_SUCCESS:
        draft.eventFormOptions = action.data?.results || null;
        if (draft.eventFormOptions?.event?.basic?.startDate) {
          draft.eventFormOptions.event.basic.startDate = gmtToDate(draft.eventFormOptions.event.basic.startDate);
        }
        if (draft.eventFormOptions?.event?.basic?.endDate) {
          draft.eventFormOptions.event.basic.endDate = gmtToDate(draft.eventFormOptions.event.basic.endDate);
        }
        if (action.data && draft.eventFormOptions && isOrganiserObj(action.data?.results?.event?.basic?.organiser)) {
          draft.eventFormOptions.event.platformOrganiser = true;
          switch (action.data.results.event.basic.organiser.type) {
            case 'custom':
              draft.eventFormOptions.event.basic.organiser = action.data.results.event.basic.organiser.label;
              draft.eventFormOptions.event.platformOrganiser = false;
              break;
            case 'company':
              draft.eventFormOptions.event.basic.organiserCompany = action.data?.results.event.basic.organiser.value;
              break;
            case 'partner':
              draft.eventFormOptions.event.basic.organiserPartner = action.data?.results.event.basic.organiser.value;
              break;
            default:
              break;
          }
        }
        draft.loading = false;
        break;
      case ADMIN_GET_EVENT_FORM_OPTIONS_ERROR:
        draft.loading = false;
        draft.error = action.error?.message;
        break;
      default:
        break;
    }
  }
  );
}
export default adminEventFormOptionsReducer;
