import { useSelector, shallowEqual } from 'react-redux';

import DOMPurify from 'dompurify';
import { allowedAttributes, allowedTags } from '@app/store/global/api/sanitize';
import loadEnvVariable from '@src/js/static/LoadEnv';

function useShallowSelector(selector) {
  return useSelector(selector(), shallowEqual);
}

const handleUrlImage = (url) => (url?.includes('http')
  ? url
  : `${ loadEnvVariable('MARKETPLACE_URL') }${ url }`);

const handleUrlCsv = (url, filters) => {
  const formatFilters = new URLSearchParams(filters);
  const link = url?.includes('http')
    ? url : `${ loadEnvVariable('API_PATH') }${ url }${ filters ? `?${ formatFilters }` : ''
    }`;
  window.open(link, '_blank');
};

const turnoverFormatter = (turnover, currency = null) => (currency
  ? `${ turnover } M ${ currency }` : `${ turnover } M`);

const purifyHtml = (string) => DOMPurify.sanitize(string, {
  ALLOWED_TAGS: allowedTags,
  ADD_ATTR: allowedAttributes,
});

const purifyAllHtml = (string) => DOMPurify.sanitize(string, {
  ALLOWED_TAGS: [],
  ADD_ATTR: [],
});

const purifyHtmlBanners = (string) => DOMPurify.sanitize(string, {
  ALLOWED_TAGS: [...allowedTags, ['script']],
  ADD_ATTR: allowedAttributes,
});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${ parseFloat((bytes / (k ** i)).toFixed(dm)) } ${ sizes[i] }`;
}

const capitalize = (string) => string.replace(/(?:^|\s|["'`([{\\/\-_])+\S/g, char => char.toUpperCase());

export {
  useShallowSelector,
  handleUrlImage,
  handleUrlCsv,
  turnoverFormatter,
  purifyHtml,
  purifyAllHtml,
  formatBytes,
  capitalize,
  purifyHtmlBanners,
};
