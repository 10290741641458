export const GET_INDEX_LIBRARY_START = 'GET_INDEX_LIBRARY_START';
export const GET_INDEX_LIBRARY_ERROR = 'GET_INDEX_LIBRARY_ERROR';
export const GET_INDEX_LIBRARY_SUCCESS = 'GET_INDEX_LIBRARY_SUCCESS';

export function getIndexLibrary(filters) {
  return {
    type: GET_INDEX_LIBRARY_START,
    filters,
  };
}
