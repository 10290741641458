import {
  takeEvery, call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrSuccess, showToastrError } from '@app/store/global/actions';

import {
  GET_PEERS_ERROR,
  GET_PEERS_START,
  GET_PEERS_SUCCESS,
  GET_REFERRERS_REQUESTED,
  ADD_REFERRERS_REQUESTED,
  CALL_REFERRER_REQUESTED,
  getReferrersSucceeded,
  getReferrersFailed,
  addReferrersSucceeded,
  addReferrersFailed,
  callReferrerSucceeded,
  callReferrerFailed,
  GET_PEERS_LIST_ERROR,
  GET_PEERS_LIST_START,
  GET_PEERS_LIST_SUCCESS,
} from '@src/js/actions/user/users';

import * as api from '@src/js/api/user/users';

function* getPeers({ threadId, offset, search }) {
  try {
    const data = yield call(() => api.getPeers(threadId, offset, search));
    yield put({ type: GET_PEERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PEERS_ERROR, error });
  }
}

function* getPeersList({
  offset, search, referrerType, companyId, isCompany,
}) {
  try {
    const data = yield call(() => api.getPeersList(offset, search, referrerType, companyId, isCompany));
    yield put({ type: GET_PEERS_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PEERS_LIST_ERROR, error });
  }
}

function* getReferrers({
  slug, isCompany, referrerType, isAdmin,
}) {
  try {
    const data = yield call(() => api.getReferrers(slug, isCompany, referrerType, isAdmin));
    yield put(getReferrersSucceeded(data));
  } catch (error) {
    yield put(getReferrersFailed(error));
  }
}

function* addReferrers({
  slug, isCompany, referrers, referrerType, messages,
}) {
  try {
    const data = yield call(() => api.addReferrers(slug, isCompany, referrers, referrerType));
    yield all([
      put(addReferrersSucceeded(data)),
      put(showToastrSuccess(messages.successTitle, messages.successMessage)),
    ]);
  } catch (error) {
    yield all([
      addReferrersFailed(error),
      showToastrError(messages.errorTitle, messages.errorMessage),
    ]);
  }
}

function* callReferrer({ phone, requestType }) {
  try {
    const data = yield call(() => api.callReferrer(phone, requestType));
    yield all([
      put(callReferrerSucceeded(data)),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put(callReferrerFailed(error)),
      put(showToastrError()),
    ]);
  }
}

export function* getPeersWatcher() {
  yield takeEvery(GET_PEERS_START, getPeers);
}

export function* getPeersListWatcher() {
  yield takeEvery(GET_PEERS_LIST_START, getPeersList);
}

export function* getReferrersWatcher() {
  yield takeLatest(GET_REFERRERS_REQUESTED, getReferrers);
}

export function* addReferrersWatcher() {
  yield takeLatest(ADD_REFERRERS_REQUESTED, addReferrers);
}

export function* callReferrerWatcher() {
  yield takeLatest(CALL_REFERRER_REQUESTED, callReferrer);
}

export default [
  getPeersWatcher(),
  getReferrersWatcher(),
  addReferrersWatcher(),
  getPeersListWatcher(),
  callReferrerWatcher(),
];
