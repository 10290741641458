import styled from 'styled-components';
import { Breakpoints } from '@src/_v2/js/constants/breakpoints';
import { FontSize, ThemeColors } from '@src/_v2/js/constants';

export const Container = styled.div`
  padding: 8.5rem 10rem;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    padding: auto 0;
    display: flex;
    justify-content: center;
  }
`;

export const FormError = styled.div`
  font-size: ${ FontSize.small };
  line-height: 1.6rem;
  min-height: 2.6rem;
  text-align: left;
  padding: 2rem 0 0.5rem;
  color: ${ ThemeColors.red };
`;
