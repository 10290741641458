import {
  GET_SINGLE_LIBRARY_START,
  GET_SINGLE_LIBRARY_ERROR,
  GET_SINGLE_LIBRARY_SUCCESS,
  CLEAR_SINGLE_LIBRARY,
} from '@src/js/actions/library/singleLibrary';

const initialState = {
  loading: false,
  error: null,
  singleLibrary: null,
};

const actionsMap = {
  [GET_SINGLE_LIBRARY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    singleLibrary: null,
  }),
  [GET_SINGLE_LIBRARY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_SINGLE_LIBRARY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    singleLibrary: action.data,
  }),
  [CLEAR_SINGLE_LIBRARY]: () => initialState,
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
