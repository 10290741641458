import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_CHANGE_NEWS_STATUS_START,
  ADMIN_CHANGE_NEWS_STATUS_ERROR,
  ADMIN_CHANGE_NEWS_STATUS_SUCCESS,
} from '@src/js/actions/news/adminNewsSingle';

import * as api from '@src/js/api/news/adminNewsSingle';

function* adminChangeNewsStatus(options) {
  try {
    const data = yield call(() => api.adminChangeNewsStatus(options.slug, options.status));
    yield put({ type: ADMIN_CHANGE_NEWS_STATUS_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: ADMIN_CHANGE_NEWS_STATUS_ERROR });
  }
}

function* adminChangeNewsStatusWatcher() {
  yield takeLatest(ADMIN_CHANGE_NEWS_STATUS_START, adminChangeNewsStatus);
}

export default [
  adminChangeNewsStatusWatcher(),
];
