import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_COMPANY_WIDGET_START,
  GET_COMPANY_WIDGET_ERROR,
  GET_COMPANY_WIDGET_SUCCESS,
} from '@src/js/actions/widget';

import * as api from '@src/js/api/widget';

function* getCompanyWidget() {
  try {
    const data = yield call(() => api.getCompanyWidget());
    yield put({ type: GET_COMPANY_WIDGET_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANY_WIDGET_ERROR, error });
  }
}

function* getCompanyWidgetWatcher() {
  yield takeLatest(GET_COMPANY_WIDGET_START, getCompanyWidget);
}

export default [
  getCompanyWidgetWatcher(),
];
