import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_MY_STATUS_START,
  GET_MY_STATUS_ERROR,
  GET_MY_STATUS_SUCCESS,
} from '@src/js/marketplace/actions/getMyStatus';

import * as api from '@src/js/marketplace/api/getMyStatus';

function* getMyStatus() {
  const data = yield call(() => api.getMyStatus());
  try {
    yield put({ type: GET_MY_STATUS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MY_STATUS_ERROR, error });
  }
}

function* getMyStatusWatcher() {
  yield takeLatest(GET_MY_STATUS_START, getMyStatus);
}

export default [
  getMyStatusWatcher(),
];
