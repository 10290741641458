export const GET_FEATURED_LIBRARY_START = 'GET_FEATURED_LIBRARY_START';
export const GET_FEATURED_LIBRARY_ERROR = 'GET_FEATURED_LIBRARY_ERROR';
export const GET_FEATURED_LIBRARY_SUCCESS = 'GET_FEATURED_LIBRARY_SUCCESS';

export function getFeaturedLibrary(filters) {
  return {
    type: GET_FEATURED_LIBRARY_START,
    filters,
  };
}
