import {
  TASK_GET_USER_PERMISSIONS_START,
  TASK_GET_USER_PERMISSIONS_ERROR,
  TASK_GET_USER_PERMISSIONS_SUCCESS,
} from '@src/js/actions/tasks/taskGetUserPermissions';

const initialState = {
  loading: false,
  error: null,
  users: null,
  hasMoreUsers: true,
};

const actionsMap = {
  [TASK_GET_USER_PERMISSIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    users: null,
  }),
  [TASK_GET_USER_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [TASK_GET_USER_PERMISSIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    users: action.data,
    hasMoreUsers: action.data.users.length > 0,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
