import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Step = ({
  step, color, iconClass, label, stepChange, enabled,
}) => {
  const changeStep = () => {
    if (!enabled) return;
    stepChange(step);
  };
  return (
    <div className={ `progressbar-step ${ enabled ? '' : 'disabled' }` }>
      <div className='progressbar-step-wrapper'>
        <div className='progressbar-step-line' />
        <div
          role='presentation'
          onClick={ changeStep }
          className={ `progressbar-step-circle ${ color ? `${ color }` : 'grey' }` }
        >
          <div className={ `progressbar-step-circle-icon ${ iconClass } ${ color }` } />
        </div>
      </div>
      {label && (
      <div
        className={ `progressbar-step-caption ${ color } ` }
        role='presentation'
        onClick={ changeStep }
      >
        <FormattedMessage id={ label } />
      </div>
      )}
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  label: PropTypes.string,
  stepChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
};

Step.defaultProps = {
  enabled: true,
};

export default Step;
