export const ELASTICSEARCH_START = 'ELASTICSEARCH_START';
export const ELASTICSEARCH_ERROR = 'ELASTICSEARCH_ERROR';
export const ELASTICSEARCH_SUCCESS = 'ELASTICSEARCH_SUCCESS';
export const ELASTICSEARCH_CLEAR = 'ELASTICSEARCH_CLEAR';
export const ELASTICSEARCH_COMPANY_START = 'ELASTICSEARCH_COMPANY_START';
export const ELASTICSEARCH_COMPANY_ERROR = 'ELASTICSEARCH_COMPANY_ERROR';
export const ELASTICSEARCH_COMPANY_SUCCESS = 'ELASTICSEARCH_COMPANY_SUCCESS';
export const ELASTICSEARCH_SEARCH_START = 'ELASTICSEARCH_SEARCH_START';
export const ELASTICSEARCH_SEARCH_ERROR = 'ELASTICSEARCH_SEARCH_ERROR';
export const ELASTICSEARCH_SEARCH_SUCCESS = 'ELASTICSEARCH_SEARCH_SUCCESS';

export function getElasticsearchResults(query, page, limit, entity) {
  return {
    type: ELASTICSEARCH_START,
    query,
    page,
    limit,
    entity,
  };
}

export function getElasticsearchCompanyResults(query, params, page, selectedType) {
  return {
    type: ELASTICSEARCH_COMPANY_START,
    query,
    params,
    page,
    selectedType,
  };
}

export function getElasticsearchResultSearch(query) {
  return {
    type: ELASTICSEARCH_SEARCH_START,
    query,
  };
}

export function clearSearchResults() {
  return {
    type: ELASTICSEARCH_CLEAR,
  };
}
