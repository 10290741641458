import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ONBOARDING_REGISTER_SUCCESS,
  ONBOARDING_REGISTER_ERROR,
  ONBOARDING_REGISTER_START,
} from '@src/js/actions/onboarding/onboardingRegister';

import * as api from '@src/js/api/onboarding/onboardingRegister';

function* onboardingRegister(options) {
  try {
    const data = yield call(() => api.onboardingRegister(
      options.data.values.data,
      options.data.values.templateHash
    ));
    yield put({ type: ONBOARDING_REGISTER_SUCCESS, data });
    options.data.resolve();
  } catch (error) {
    yield put({ type: ONBOARDING_REGISTER_ERROR, error });
    options.data.reject(error.response);
  }
}

function* onboardingRegisterWatcher() {
  yield takeLatest(ONBOARDING_REGISTER_START, onboardingRegister);
}

export default [
  onboardingRegisterWatcher(),
];
