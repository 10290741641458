import LoginForm from '@src/js/components/forms/onboarding/LoginForm';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import { routeCodes, withLang } from '@src/js/constants/routes';
import React from 'react';

const OnboardingLogin = () => (
  <>
    <div className='login-header'>
      <div className='container'>
        <div className='main-navigation__logo-icon icon-EliteLogo' />
      </div>
    </div>
    <div className='login-sub-header' />
    <MetaTitle title='login.formTitle' />
    <div className='container login-page'>
      <LoginForm url={ withLang(routeCodes.REQUEST_RESET_PASSWORD) } />
    </div>
  </>
);

export default OnboardingLogin;
