import styled, { css } from 'styled-components';
import { ThemeColors, FontSize, FontWeight } from '@src/_v2/js/constants';

const getBorderRadius = props => (props.rounded ? '2.5rem' : 'initial');

const getCursor = props => (props.disabled ? 'initial' : 'pointer');

const getColor = props => {
  if (props.color) {
    return props.color;
  }

  return props.blank ? ThemeColors.mediumGrey : ThemeColors.white;
};

const getBorder = props => {
  const borderColor = props.blank ? ThemeColors.lightGrey : ThemeColors.blue;
  return `solid ${ borderColor } 1px`;
};

const getBackgroundColor = props => (props.blank ? 'rgba(255, 255, 255, 0.1)' : ThemeColors.blue);

const BaseButtonStyle = css`
  height: 4rem;
  padding: 0 2.3rem;
  box-sizing: border-box;
  text-align: center;
  outline: none;
  transition: all 100ms linear;
  font-size: ${ FontSize.icon };
  font-weight: ${ FontWeight.bold };
  text-transform: uppercase;
  min-width: 12rem;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.04rem;
  margin: 1rem;

  &:disabled {
    opacity: 0.5;
  }
`;

const ButtonInitial = styled.button(props => ({
  color: getColor(props),
  borderRadius: getBorderRadius(props),
  cursor: getCursor(props),
  backgroundColor: getBackgroundColor(props),
  border: getBorder(props),
}));

const Button = styled(ButtonInitial)`
  ${ BaseButtonStyle }
`;

export const Icon = styled.span`
  vertical-align: middle;
  font-size: ${ FontSize.subHeader };
  margin-right: 1rem;
`;

export const ButtonSmall = styled(Button)`
  height: 3rem;
  line-height: 3rem;
  min-width: 9rem;
  padding: 0 1rem;

  ${ Icon } {
    font-size: ${ FontSize.small };
  }
`;

export const ButtonMedium = styled(Button)`
  line-height: 3.5rem;
  padding: '1.3rem 3rem';
  font-size: ${ FontSize.icon };
`;

export const ButtonLarge = styled(Button)`
  height: 5rem;
  line-height: 5rem;
  padding: 0 2rem;
  min-width: 19rem;
  font-size: ${ FontSize.subTitle };
`;

export const DownArrow = styled.i`
  border: solid ${ ThemeColors.blue };
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 0.3rem;
  transform: rotate(45deg);
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

export const ButtonText = styled.span`
  width: 100%;
  font-size: inherit;
`;
