import { GlobalModal } from '@app/components/global/GlobalModal';
import { routeCodes, withLang } from '@src/js/constants/routes';
import isTouchDevice from '@src/js/helpers/IsTouchDevice';
import Header from '@src/js/layouts/Header';
import Footer from '@src/js/layouts/footer/Footer';
import OnboardingPrivateRoutes from '@src/js/routes/OnboardingPrivateRoutes';
import OnboardingPublicRoutes from '@src/js/routes/OnboardingPublicRoutes';
import PrivateRoutes from '@src/js/routes/PrivateRoutes';
import PublicRoutes from '@src/js/routes/PublicRoutes';
import Cookies from 'js-cookie';
import React from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Redirect, useLocation, useParams,
} from 'react-router-dom';
import IntercomComponent from '@src/js/layouts/header/intercom/Intercom';
import Startup from '@src/js/views/Startup';

const EOPRouter = () => {
  const { language, page } = useParams();
  const { pathname, search, hash } = useLocation();
  const localizationMessages = useSelector(state => state.initInfo.localizationMessages);
  const userData = useSelector(state => state.initInfo.userInfo);
  const logoutSuccess = useSelector(state => state.initInfo.logoutSuccess);
  const onboardingUser = useSelector(state => state.onboardingBasic.onboardingUser);
  const activeLanguage = useSelector(state => state.initInfo.activeLanguage);
  const isUserLoggedIn = userData?.isLoggedIn ?? false;

  const shouldRedirect = (userLoggedIn) => !userLoggedIn
      && !logoutSuccess
      && !pathname.includes('/login')
      && !pathname.includes('/password/set')
      && !pathname.includes('/password/reset')
      && !pathname.includes('/users/password')
      && !pathname.includes('/self-registration')
      && (!pathname.includes('/onboarding') || pathname.includes('/admin/onboarding'));

  const isOnboardingRoute = () => page && page.indexOf('onboarding') === 0 && page.indexOf('admin/onboarding') !== 0;

  const renderStandardRoutes = (userLoggedIn, isSelfRegistrationPage) => (userLoggedIn && !isSelfRegistrationPage
    ? <PrivateRoutes />
    : <PublicRoutes />);

  const renderOnboardingRoutes = (onboardingUserLoggedIn) => (onboardingUserLoggedIn
    ? <OnboardingPrivateRoutes />
    : <OnboardingPublicRoutes />);

  const isSelfRegistrationPage = pathname.includes('/self-registration');
  const onboardingUserLoggedIn = onboardingUser !== null ? onboardingUser.isLoggedIn : false;

  if (language !== activeLanguage) {
    Cookies.set('networking-offset-company', 0, { secure: true });
    Cookies.set('networking-offset-partner', 0, { secure: true });

    let path = pathname;
    if (language) {
      path = pathname.slice(3);
    }

    return <Redirect to={ `/${ activeLanguage }${ path }${ search }` } />;
  }

  if (isUserLoggedIn && !isSelfRegistrationPage && localStorage.getItem('redirect') !== null) {
    const redirectUrl = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    if (redirectUrl !== pathname) {
      return <Redirect to={ redirectUrl } />;
    }
  }

  if (!isOnboardingRoute() && shouldRedirect(isUserLoggedIn)) {
    localStorage.setItem('redirect', pathname + hash + search);
  }

  if (isUserLoggedIn && pathname.includes('login') && !isOnboardingRoute()) {
    return <Redirect to={ withLang(routeCodes.DASHBOARD) } />;
  }

  if (onboardingUserLoggedIn
      && !pathname.includes(onboardingUser.userProcessHash)
  ) {
    return (
      <Redirect
        to={ withLang(routeCodes.ONBOARDING_FORM.replace(':processHash', onboardingUser.userProcessHash)) }
      />
    );
  }

  if (!isTouchDevice()) {
    document.body.className = 'non-touch-device';
  }

  return (
    <IntlProvider
      textComponent={ React.Fragment }
      locale={ language }
      messages={ localizationMessages[language] }
    >
      <div className='App'>
        <IntercomComponent hideLauncher>
          <GlobalModal>
            <Helmet>
              <html lang={ language } />
              <meta charSet='utf-8' />
              <title>ELITE Platform</title>
            </Helmet>
            { !isOnboardingRoute() && isUserLoggedIn && !isSelfRegistrationPage && <Header /> }
            <div className='Page'>
              { isOnboardingRoute()
                ? renderOnboardingRoutes(onboardingUserLoggedIn)
                : renderStandardRoutes(isUserLoggedIn, isSelfRegistrationPage)
            }
            </div>
            { !isSelfRegistrationPage && <Footer /> }
            <Startup />
          </GlobalModal>
        </IntercomComponent>
      </div>
    </IntlProvider>
  );
};

export default EOPRouter;
