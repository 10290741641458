export const COMPANY_DOCUMENTS_UPLOAD_START = 'COMPANY_DOCUMENTS_UPLOAD_START';
export const COMPANY_DOCUMENTS_UPLOAD_ERROR = 'COMPANY_DOCUMENTS_UPLOAD_ERROR';
export const COMPANY_DOCUMENTS_UPLOAD_SUCCESS = 'COMPANY_DOCUMENTS_UPLOAD_SUCCESS';

export function companyDocumentsUpload(files, slug) {
  return {
    type: COMPANY_DOCUMENTS_UPLOAD_START,
    files,
    slug,
  };
}
