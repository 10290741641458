import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_LATEST_OPPORTUNITIES_START,
  GET_LATEST_OPPORTUNITIES_ERROR,
  GET_LATEST_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getLatestOpportunities';

import * as api from '@src/js/marketplace/api/getLatestOpportunities';

function* getLatestOpportunities(options) {
  try {
    const data = yield call(() => api.getLatestOpportunities(options));
    yield put({ type: GET_LATEST_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_LATEST_OPPORTUNITIES_ERROR, error });
  }
}

function* getLatestOpportunitiesWatcher() {
  yield takeLatest(GET_LATEST_OPPORTUNITIES_START, getLatestOpportunities);
}

export default [
  getLatestOpportunitiesWatcher(),
];
