export function handleDimensionsArray(options, intl) {
  const dimensions = [];

  options.forEach(option => {
    dimensions.push({ value: option.id, label: intl.formatMessage({ id: `dimension.${ option.name }` }) });
  });

  return dimensions;
}

export function handleDimensionsStrings(options, intl) {
  const dimensions = [];
  options.forEach(option => {
    dimensions.push(intl.formatMessage({ id: `dimension.${ option }` }));
  });

  return dimensions;
}
