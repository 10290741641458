import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getOpportunityProfile(id) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/product/${ id }`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
