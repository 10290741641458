import { takeLatest, call, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import {
  ELASTICSEARCH_START,
  ELASTICSEARCH_ERROR,
  ELASTICSEARCH_SUCCESS,
  ELASTICSEARCH_COMPANY_START,
  ELASTICSEARCH_COMPANY_ERROR,
  ELASTICSEARCH_COMPANY_SUCCESS,
  ELASTICSEARCH_SEARCH_START,
  ELASTICSEARCH_SEARCH_ERROR,
  ELASTICSEARCH_SEARCH_SUCCESS,
} from '@src/js/actions/elasticsearch';

import * as api from '@src/js/api/elasticsearch';

function* getResults(options) {
  try {
    const offsetCompany = Cookies.get('networking-offset-company');
    const offsetPartner = Cookies.get('networking-offset-partner');

    const data = yield call(() => api.getElasticsearchResults(
      options.query,
      options.page,
      options.limit,
      options.entity,
      offsetCompany,
      offsetPartner
    ));
    yield put({ type: ELASTICSEARCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: ELASTICSEARCH_ERROR, error });
  }
}

function* getElasticsearchCompanyResults(options) {
  try {
    const offsetCompany = Cookies.get('networking-offset-company');
    const offsetPartner = Cookies.get('networking-offset-partner');

    const data = yield call(() => api.getElasticsearchCompanyResults(options, offsetCompany, offsetPartner));
    const networking = data.results.networking;
    if (networking !== undefined) {
      if (parseInt(offsetCompany, 10) + 4 !== networking.offsetCompany) {
        Cookies.set('networking-offset-company', networking.offsetCompany - 1, { secure: true });
      }
      if (parseInt(offsetPartner, 10) + 4 !== networking.offsetPartner) {
        Cookies.set('networking-offset-partner', networking.offsetPartner === 0
          ? networking.offsetPartner : networking.offsetPartner - 1, { secure: true });
      }
    }
    yield put({ type: ELASTICSEARCH_COMPANY_SUCCESS, data });
  } catch (error) {
    yield put({ type: ELASTICSEARCH_COMPANY_ERROR, error });
  }
}

function* getElasticsearchResultSearch({ query }) {
  try {
    const data = yield call(() => api.getElasticsearchResultSearch(query));
    yield put({ type: ELASTICSEARCH_SEARCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: ELASTICSEARCH_SEARCH_ERROR, error });
  }
}

function* createElasticsearchWatcher() {
  yield takeLatest(ELASTICSEARCH_START, getResults);
}

function* getElasticsearchCompanyResultsWatcher() {
  yield takeLatest(ELASTICSEARCH_COMPANY_START, getElasticsearchCompanyResults);
  yield takeLatest(ELASTICSEARCH_SEARCH_START, getElasticsearchResultSearch);
}

export default [
  createElasticsearchWatcher(),
  getElasticsearchCompanyResultsWatcher(),
];
