import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  FormSection, InputText,
} from '@app/components/global/forms';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from '@src/js/components/global';
import {
  selectOnboardingSave, selectOnboardingData, selectCurrentStep, selectFirstVisit,
} from '@app/store/onboarding/selectors';
import { Language, OnboardingCompanyUserContact } from '@src/js/constants/entities';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';

const Directors = ({ handleSave }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onboardingData = selectOnboardingData();
  const langIT = onboardingData?.template?.lang === Language.IT;

  const onSave = selectOnboardingSave();

  const {
    getValues, formState: { errors }, register, control, trigger,
  } = useForm({
    defaultValues: onboardingData?.company,
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companyDirector',
  });
  const shareholderFields = useFieldArray({
    control,
    name: 'companyShareholders',
  });
  const userContactFields = useFieldArray({
    control,
    name: 'companyUserContact',
  });

  const baseContact = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  };
  const defaultContact = {
    ...baseContact,
    jobTitle: null,
    platformAccount: false,
  };
  const invoicingContact = {
    ...baseContact,
    electronicInvoicingMail: null,
    type: OnboardingCompanyUserContact.TYPE_CONTACT_INVOICING,
  };
  const assistantContact = { ...baseContact, type: OnboardingCompanyUserContact.TYPE_DIRECTOR_ASSISTANT };
  const shareholderContact = {
    name: null,
    percentage: null,
  };

  const assistantFields = userContactFields.fields
    .filter(f => f.type === OnboardingCompanyUserContact.TYPE_DIRECTOR_ASSISTANT);
  const invoicingFields = userContactFields.fields
    .filter(f => f.type === OnboardingCompanyUserContact.TYPE_CONTACT_INVOICING);

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultContact);
    }
    if (shareholderFields.fields.length === 0) {
      shareholderFields.append(shareholderContact);
    }
    if (assistantFields.length === 0) {
      userContactFields.append(assistantContact);
    }
    if (invoicingFields.length === 0) {
      userContactFields.append(invoicingContact);
    }
  }, [fields, shareholderFields, userContactFields]);

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      handleSave({ company: { ...getValues() } });
    }
  }, [onSave]);

  return (
    <>
      <FormSection
        sectionTitle='onboarding.directors.directors'
        titleClass='d-flex gap-8 align-items-center'
      >
        <EmailSupport />
        <div className='elite-form__subtitle'><FormattedMessage id='onboarding.directors.subSectionLabel' /></div>
        {fields.map((item, index) => (
          <Fragment key={ item.id }>
            { index !== 0 ? (
              <div className='w-100 d-flex justify-content-end m-b-1'>
                <div role='presentation' className='compliance__button-close' onClick={ () => remove(index) }>
                  <span className='icon-medium-Close' />
                </div>
              </div>
            ) : (
              <div className='w-100' />
            )
            }
            <>
              <InputText
                name={ `companyDirector[${ index }].firstName` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.firstName'
                defaultValue={ item.firstName }
                required
              />
              <InputText
                name={ `companyDirector[${ index }].lastName` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.lastName'
                defaultValue={ item.lastName }
                required
              />
              <InputText
                name={ `companyDirector[${ index }].jobTitle` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.jobTitle'
                defaultValue={ item.jobTitle }
                required
              />
              <InputText
                name={ `companyDirector[${ index }].email` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.email'
                defaultValue={ item.email }
                required
                type='textEmail'
              />
              <InputText
                name={ `companyDirector[${ index }].phone` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.phone'
                defaultValue={ item.phone }
                required
                type='tel'
              />
            </>
          </Fragment>

        ))}
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => append(defaultContact) }
          />
        </div>
      </FormSection>
      <FormSection
        sectionTitle='onboarding.shareholders.shareholders'
        titleClass='d-flex gap-8 align-items-center'
      >
        <div className='elite-form__subtitle'>
          <FormattedMessage id='onboarding.shareholders.shareholders.subtitle' />
        </div>
        {shareholderFields.fields.map((item, index) => (
          <Fragment key={ item.id }>
            { index !== 0 ? (
              <div className='w-100 d-flex justify-content-end m-b-1'>
                <div
                  role='presentation'
                  className='compliance__button-close'
                  onClick={ () => shareholderFields.remove(index) }
                >
                  <span className='icon-medium-Close' />
                </div>
              </div>
            ) : (
              <div className='w-100' />
            )
            }
            <InputText
              name={ `companyShareholders[${ index }].name` }
              errors={ errors }
              register={ register }
              label='onboarding.shareholders.name'
              defaultValue={ item.name }
              required
            />
            <InputText
              name={ `companyShareholders[${ index }].percentage` }
              errors={ errors }
              register={ register }
              label='onboarding.shareholders.percentage'
              defaultValue={ item.lastName }
              required
              type='percentage'
              minValue={ 10 }
              maxValue={ 100 }
            />
          </Fragment>
        ))}
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => shareholderFields.append(defaultContact) }
          />
        </div>
      </FormSection>
      <FormSection
        sectionTitle='onboarding.assistants.assistants'
        titleClass='d-flex gap-8 align-items-center'
      >
        {userContactFields.fields.map((item, index) => (
          item.type === OnboardingCompanyUserContact.TYPE_DIRECTOR_ASSISTANT && (
          <Fragment key={ item.id }>
            { assistantFields.length > 1 && (
              <div className='w-100 d-flex justify-content-end m-b-1'>
                <div
                  role='presentation'
                  className='compliance__button-close'
                  onClick={ () => userContactFields.remove(index) }
                >
                  <span className='icon-medium-Close' />
                </div>
              </div>
            )
            }
            <InputText
              name={ `companyUserContact[${ index }].firstName` }
              errors={ errors }
              register={ register }
              label='onboarding.directors.firstName'
              defaultValue={ item.firstName }
              required
            />
            <InputText
              name={ `companyUserContact[${ index }].lastName` }
              errors={ errors }
              register={ register }
              label='onboarding.directors.lastName'
              defaultValue={ item.lastName }
              required
            />
            <InputText
              name={ `companyUserContact[${ index }].email` }
              errors={ errors }
              register={ register }
              label='onboarding.directors.email'
              defaultValue={ item.email }
              required
              type='textEmail'
            />
            <InputText
              name={ `companyUserContact[${ index }].phone` }
              errors={ errors }
              register={ register }
              label='onboarding.assistants.phone'
              defaultValue={ item.phone }
              required
              type='tel'
            />
          </Fragment>
          )
        ))}
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => userContactFields.append(assistantContact) }
          />
        </div>
      </FormSection>
      <FormSection
        sectionTitle='onboarding.invoicing.invoicing'
        titleClass='d-flex gap-8 align-items-center'
      >
        {userContactFields.fields.map((item, index) => (
          item.type === OnboardingCompanyUserContact.TYPE_CONTACT_INVOICING && (
          <Fragment key={ item.id }>
            { invoicingFields.length > 1 && (
              <div className='w-100 d-flex justify-content-end m-b-1'>
                <div
                  role='presentation'
                  className='compliance__button-close'
                  onClick={ () => userContactFields.remove(index) }
                >
                  <span className='icon-medium-Close' />
                </div>
              </div>
            )
            }
            <InputText
              name={ `companyUserContact[${ index }].firstName` }
              errors={ errors }
              register={ register }
              label='onboarding.directors.firstName'
              defaultValue={ item.firstName }
              required
            />
            <InputText
              name={ `companyUserContact[${ index }].lastName` }
              errors={ errors }
              register={ register }
              label='onboarding.directors.lastName'
              defaultValue={ item.lastName }
              required
            />
            <InputText
              name={ `companyUserContact[${ index }].email` }
              errors={ errors }
              register={ register }
              label='onboarding.directors.email'
              defaultValue={ item.email }
              required
              type='textEmail'
            />
            <InputText
              name={ `companyUserContact[${ index }].phone` }
              errors={ errors }
              register={ register }
              label='onboarding.assistants.phone'
              defaultValue={ item.phone }
              required
              type='tel'
            />
            { langIT && (
            <InputText
              name={ `companyUserContact[${ index }].electronicInvoicingMail` }
              errors={ errors }
              register={ register }
              label='onboarding.assistants.electronicInvoicingMail'
              defaultValue={ item.electronicInvoicingMail }
              tooltip='onboarding.assistants.electronicInvoicingMail.tooltip'
              required
            />
            )}
          </Fragment>
          )
        ))}
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => userContactFields.append(invoicingContact) }
          />
        </div>
      </FormSection>
    </>
  );
};

Directors.propTypes = {
  handleSave: PropTypes.func,
};

export default Directors;
