export const allowedTags = [
  'a',
  'b',
  'blockquote',
  'br',
  'div',
  'em',
  'figure',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'i',
  'iframe',
  'img',
  'italic',
  'li',
  'ol',
  'p',
  'pre',
  's',
  'span',
  'strike',
  'strong',
  'table',
  'tbody',
  'td',
  'tr',
  'u',
  'ul',
  'underline',
];

export const allowedAttributes = [
  'allowFullScreen',
  'alt',
  'height',
  'href',
  'mallowFullScreen',
  'mozallowFullScreen',
  'oallowFullScreen',
  'src',
  'style',
  'target',
  'title',
  'webkitallowFullScreen',
  'width',
];
