import produce from 'immer';
import { SelectValue } from '@app/components/global/forms/select/select';
import { MultiValue, SingleValue } from 'react-select';

import {
  GET_BANNER_LIST_START,
  GET_BANNER_LIST_FAIL,
  GET_BANNER_LIST_SUCCESS,
  GET_BANNER_START,
  GET_BANNER_FAIL,
  GET_BANNER_SUCCESS,
  UPDATE_BANNER_START,
  UPDATE_BANNER_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_STATUS_START,
  UPDATE_BANNER_STATUS_FAIL,
  UPDATE_BANNER_STATUS_SUCCESS,
} from '@app/store/content/banner/actions';
import { Banner, BannerListItem } from '@app/models/Banner';
import { Action } from 'redux';
import { PaginatedResponse, isPaginatedResponse } from '@app/models/Pagination';
import { ResponseError } from '@app/models/Error';

export type BannerState = {
  banner: Banner|null,
  loading: boolean,
  bannerList: BannerListItem[],
  error: unknown,
  totalCount: number,
  bannerItemLoading: {
    [id: number]: boolean
  }
}

export interface BannerAction extends Action<string> {
  error: ResponseError,
  result: PaginatedResponse<Banner>|Banner,
  search: string,
  page: number,
  companyTypes: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerType: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerLang: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerStatus: SingleValue<SelectValue> | MultiValue<SelectValue>,
  id: number,
  status: 'published'|'deleted'
  formData: Banner,
  history: any,
}

const initialState: BannerState = {
  loading: false,
  error: null,
  banner: null,
  bannerList: [],
  totalCount: 0,
  bannerItemLoading: {},
};

function bannerReducer(state = initialState, action: BannerAction) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_BANNER_LIST_START:
        draft.loading = true;
        draft.error = null;
        draft.totalCount = 0;
        break;
      case GET_BANNER_START:
        draft.loading = true;
        draft.error = null;
        draft.banner = null;
        break;
      case UPDATE_BANNER_START:
        break;
      case GET_BANNER_LIST_FAIL:
      case GET_BANNER_FAIL:
      case UPDATE_BANNER_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        break;
      case GET_BANNER_LIST_SUCCESS:
        draft.loading = false;
        if (!isPaginatedResponse<BannerListItem>(action.result)) break;
        draft.bannerList = action.result.results;
        draft.totalCount = action.result.pagination.totalCount;
        draft.error = null;
        break;
      case GET_BANNER_SUCCESS:
        draft.loading = false;
        draft.banner = action.result as Banner;
        draft.error = null;
        break;
      case UPDATE_BANNER_SUCCESS:
        draft.loading = false;
        draft.error = null;
        break;
      case UPDATE_BANNER_STATUS_START:
        draft.error = null;
        break;
      case UPDATE_BANNER_STATUS_FAIL:
        draft.bannerItemLoading[action.id] = false;
        draft.error = action.error.message;
        break;
      case UPDATE_BANNER_STATUS_SUCCESS:
        draft.bannerItemLoading[action.id] = false;
        draft.bannerList = state.bannerList.map(banner => {
          if (action.id === banner.id) {
            return {
              ...banner,
              status: action.status,
            };
          }
          return banner;
        });
        draft.loading = false;
        draft.error = null;
        break;
      default:
        break;
    }
  }
  );
}

export default bannerReducer;
