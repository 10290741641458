import fetchResource from '@src/js/api/fetch-resource';
import { ectPath } from '@src/js/constants/routes';

export function createFolder(dataRoomType, slug, data) {
  return fetchResource(`${ ectPath() }/api/data-room/${ slug }/${ dataRoomType }/folder`, {
    method: 'POST',
    body: data,
    externalUrl: true,
  });
}

export function renameFolder(slug, id, data, dataRoomType) {
  return fetchResource(`${ ectPath() }/api/data-room/${ slug }/${ dataRoomType }/folder/${ id }`, {
    method: 'PATCH',
    body: data,
    externalUrl: true,
  });
}

export function fileList({
  slug, dataRoomType, folderId, search, training, move,
}) {
  const query = new URLSearchParams({ search, training, move });
  return fetchResource(
    `${ ectPath() }/api/data-room/${ slug }/${ dataRoomType }${
      folderId ? `/${ folderId }` : '' }${ query ? `?${ query }` : '' }`, {
      method: 'GET',
      externalUrl: true,
    });
}

export function createFile(files, slug, id, dataRoomType) {
  return fetchResource(`api/dataroom/${ slug }/${ dataRoomType }/folder/${ id }/file`, {
    method: 'POST',
    body: files,
    noContentType: true,
  });
}

export function moveFile(slug, id, data, dataRoomType, isFolder) {
  return fetchResource(`api/dataroom/${ slug }/${ dataRoomType }/${ isFolder ? 'folder' : 'file' }/${ id }`, {
    method: 'PATCH',
    body: data,
  });
}

export function deleteFile(slug, id, dataRoomType, isFolder) {
  const path = isFolder
    ? `${ ectPath() }/api/data-room/${ slug }/${ dataRoomType }/folder/${ id }`
    : `api/dataroom/${ slug }/${ dataRoomType }/file/${ id }`;

  return fetchResource(path, {
    method: 'DELETE',
    externalUrl: isFolder,
  });
}

export function getPermissions({
  slug, id, dataRoomType, entity, offset, search,
}) {
  const query = new URLSearchParams({ offset, search });
  return fetchResource(
    `api/dataroom/${ slug }/${ dataRoomType }/permissions/${ entity }/${ id }?${ query }`, {
      method: 'GET',
    });
}

export function setPermissions(slug, id, dataRoomType, entity, data) {
  return fetchResource(
    `api/dataroom/${ slug }/${ dataRoomType }/permissions/${ entity }/${ id }`, {
      method: 'POST',
      body: data,
    });
}

export function getDataRoomList() {
  return fetchResource(
    'api/dataroom/folder', {
      method: 'GET',
    }
  );
}

export function download(id) {
  return fetchResource(
    `${ ectPath() }/api/media/folder/${ id }`, {
      method: 'GET',
      externalUrl: true,
    }
  );
}
export function downloadStart(task) {
  return fetchResource(
    `${ ectPath() }/api/media/folder`, {
      method: 'POST',
      externalUrl: true,
      body: task,
    }
  );
}
export function downloadCancel(id) {
  return fetchResource(
    `${ ectPath() }/api/media/folder/${ id }`, {
      method: 'DELETE',
      externalUrl: true,
    }
  );
}

export function associateSignedDocument(companySlug, dataRoomType) {
  return fetchResource('api/signdocument/associate', {
    method: 'POST',
    body: { companySlug, dataRoomType },
  });
}
