import { localeFormatNumber } from '@app/components/global/forms/utils';
import { DOCUSIGN_FAIL, DOCUSIGN_START } from '@app/store/onboarding/actions/index';
import { financialsKeys } from '@app/store/onboarding/constants';
import { UPLOAD_ONBOARDING_CLEAR_FILE_STATE } from '@src/js/actions/onboarding/file/uploadOnboardingFile';
import {
  GET_ONBOARDING_DATA_START,
  GET_ONBOARDING_DATA_ERROR,
  GET_ONBOARDING_DATA_SUCCESS,
  SUBMIT_ONBOARDING_START,
  SUBMIT_ONBOARDING_ERROR,
  SUBMIT_ONBOARDING_SUCCESS,
} from '@src/js/actions/onboarding/onboardingProcess';
import {
  ADMIN_GET_PROCESS_SUCCESS,
  ADMIN_UPDATE_PROCESS_ERROR,
  ADMIN_UPDATE_PROCESS_START,
  ADMIN_UPDATE_PROCESS_SUCCESS,
} from '@src/js/actions/onboarding/processes/adminProcesses';

const initialState = {
  loading: false,
  error: null,
  processData: null,
  translation: null,
  isSubmitted: null,
  processFormOptions: null,
  processLanguage: null,
};

const formatFinancials = (processData, language) => {
  if (!processData?.company) {
    return processData;
  }
  const data = { ...processData };
  const financials = data.company.companyFinancials.map(financial => {
    const formattedFinancial = financial;
    financialsKeys.forEach((key) => {
      formattedFinancial[key] = localeFormatNumber(financial[key], language);
    });

    return formattedFinancial;
  });
  const fundingHistory = data.company.companyFundingHistory.map(history => {
    const formattedHistory = history;
    formattedHistory.amountRaised = localeFormatNumber(history.amountRaised, language);
    return formattedHistory;
  });
  data.company.companyFinancials = financials;
  data.company.companyFundingHistory = fundingHistory;

  return data;
};

const actionsMap = {
  [GET_ONBOARDING_DATA_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_ONBOARDING_DATA_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_ONBOARDING_DATA_SUCCESS]: (state, action) => ({
    ...state,
    processData: formatFinancials(action.data.processData, action.data.processLanguage),
    isSubmitted: action.data.submitted,
    translation: action.data.translation,
    processFormOptions: action.data.processFormOptions,
    processLanguage: action.data.processLanguage,
    loading: false,
    error: null,
  }),
  [ADMIN_GET_PROCESS_SUCCESS]: (state, action) => ({
    ...state,
    processData: formatFinancials(action.data.processData, action.data.processLanguage),
    isSubmitted: action.data.submitted,
    translation: action.data.translation,
    processFormOptions: action.data.processFormOptions,
    processLanguage: action.data.processLanguage,
    loading: false,
    error: null,
  }),
  [SUBMIT_ONBOARDING_START]: (state) => ({
    ...state,
    submitLoading: true,
    submitError: null,
    submitData: null,
  }),
  [SUBMIT_ONBOARDING_ERROR]: (state, action) => ({
    ...state,
    submitLoading: false,
    submitError: action.error.response,
    submitData: null,
  }),
  [SUBMIT_ONBOARDING_SUCCESS]: (state, action) => {
    const processData = { ...state.processData };
    return {
      ...state,
      submitLoading: false,
      submitError: null,
      submitData: action.data,
      processData,
    };
  },
  [UPLOAD_ONBOARDING_CLEAR_FILE_STATE]: (state) => {
    const processData = {
      ...state.processData,
      terms: { ...state.processData.terms, signedDocument: null },
    };
    return {
      ...state,
      submitLoading: false,
      submitError: null,
      processData,
    };
  },
  [ADMIN_UPDATE_PROCESS_START]: (state) => ({
    ...state,
    submitLoading: true,
    submitError: null,
    submitData: null,
    created: false,
  }),
  [ADMIN_UPDATE_PROCESS_ERROR]: (state, action) => ({
    ...state,
    submitLoading: false,
    submitError: action.error.response,
    submitData: null,
  }),
  [ADMIN_UPDATE_PROCESS_SUCCESS]: (state, action) => {
    const processData = { ...state.processData };
    return {
      ...state,
      submitLoading: false,
      submitError: null,
      submitData: action.data,
      processData,
    };
  },
  [DOCUSIGN_START]: (state) => ({
    ...state,
    submitLoading: true,
  }),
  [DOCUSIGN_FAIL]: (state) => ({
    ...state,
    submitLoading: false,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
