import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ContentHeaderContainer from '@src/js/layouts/global/menuBoard/wrappers/ContentHeaderContainer';
import Background from '@src/js/layouts/global/menuBoard/background/Background';
import ProfileHeaderWrapper from '@src/js/layouts/global/menuBoard/wrappers/ProfileHeaderWrapper';
import { Images } from '@src/js/constants/images';

class ProgrammeHomeMenu extends Component {
  constructor(props) {
    super(props);

    this.defaultLogo = Images.DefaultLogo;
    this.state = {
      logo: this.defaultLogo,
    };
  }

  render() {
    const {
      logo,
    } = this.state;

    return (
      <div className='eop-menu'>
        <ProfileHeaderWrapper>
          <ContentHeaderContainer>
            <span className='programme-title'>
              <FormattedMessage id='programmeTeaser.headline' />
            </span>
          </ContentHeaderContainer>
          <Background image={ logo } />
        </ProfileHeaderWrapper>
      </div>
    );
  }
}

export default withRouter(ProgrammeHomeMenu);
