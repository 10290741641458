import React from 'react';
import LoginForm from '@src/js/components/forms/LoginForm';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import { routeCodes, withLang } from '@src/js/constants/routes';
import loadEnvVariable from '@src/js/static/LoadEnv';

const Login = () => (
  <>
    <div className='login-header'>
      <div className='container'>
        <div className='main-navigation__logo-icon icon-EliteLogo' />
      </div>
    </div>
    <div className='login-sub-header' />
    <MetaTitle title='login.formTitle' />

    {/* This will be refactored in a later phase */}
    <iframe title='etinerary' height='0' width='0' hidden src={ `${ loadEnvVariable('ETINERARY_URL') }/logout` } />

    <div className='container login-page'>
      <LoginForm url={ withLang(routeCodes.REQUEST_RESET_PASSWORD) } />
    </div>
  </>
);

export default Login;
