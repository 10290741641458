export const DELETE_SINGLE_SESSION_START = 'DELETE_SINGLE_SESSION_START';
export const DELETE_SINGLE_SESSION_ERROR = 'DELETE_SINGLE_SESSION_ERROR';
export const DELETE_SINGLE_SESSION_SUCCESS = 'DELETE_SINGLE_SESSION_SUCCESS';

export const DELETE_ALL_SESSIONS_START = 'DELETE_ALL_SESSIONS_START';
export const DELETE_ALL_SESSIONS_ERROR = 'DELETE_ALL_SESSIONS_ERROR';
export const DELETE_ALL_SESSIONS_SUCCESS = 'DELETE_ALL_SESSIONS_SUCCESS';

export function deleteSingleSession(session) {
  return {
    type: DELETE_SINGLE_SESSION_START,
    session,
  };
}

export function deleteAllSessions(session) {
  return {
    type: DELETE_ALL_SESSIONS_START,
    session,
  };
}
