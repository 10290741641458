import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_DOCUMENTS_DELETE_START,
  PARTNER_DOCUMENTS_DELETE_ERROR,
  PARTNER_DOCUMENTS_DELETE_SUCCESS,
} from '@src/js/actions/partner/partnerDocumentDelete';

import * as api from '@src/js/api/partner/partnerDocumentDelete';

function* deletePartnerDocumentsFiles(options) {
  try {
    const data = yield call(() => api.partnerDocumentsDelete(options.fileSlug, options.slug));
    yield put({ type: PARTNER_DOCUMENTS_DELETE_SUCCESS, data });
  } catch (error) {
    yield put({ type: PARTNER_DOCUMENTS_DELETE_ERROR, error });
  }
}

function* deletePartnerDocumentsFileWatcher() {
  yield takeLatest(PARTNER_DOCUMENTS_DELETE_START, deletePartnerDocumentsFiles);
}

export default [
  deletePartnerDocumentsFileWatcher(),
];
