import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import SelectBox from '@src/js/components/global/inputs/Selectbox';

class CompanyFormMembers extends Component {
  static propTypes = {
    companyFormOptions: PropTypes.object,
    members: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isDisabledFunc: PropTypes.func,
  };

  render() {
    const {
      companyFormOptions,
      isDisabledFunc,
    } = this.props;

    return (
      <div className='edit-company-page edit-company-page__section edit-company-page__members'>
        <div className='row'>
          <div className='col-xs'>
            <div className='edit-company-page__title edit-user-page-tab-content-title'>
              <FormattedMessage id='company_form.members' />
            </div>
          </div>
        </div>

        <Field
          name='members'
          fieldName='members'
          multi={ true }
          options={ companyFormOptions
          && companyFormOptions.results.members.map(option => ({ value: option.id, label: option.fullName }))
          }
          component={ SelectBox }
          fieldNoClear
          fieldDisabled={ isDisabledFunc('members') }
          simpleValue={ false }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyFormOptions: state.companyFormOptions.companyFormOptions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormMembers);

export default withRouter(connectWrapper);
