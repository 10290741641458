import { exitImpersonateSession, logoutUser } from '@src/js/actions/user/user';
import LanguageSelector from '@src/js/components/dropdowns/LanguageSelector';
import { Company, Impersonate, Partner } from '@src/js/constants/entities';
import {
  externalRoutes, routeCodes, withLang,
} from '@src/js/constants/routes';
import { formatUrlToSecure } from '@src/js/helpers/formatUrlToSecure';
import { usePrevious } from '@src/js/helpers/hooks';
import Search from '@src/js/layouts/header/Search';
import { useEtineraryModal } from '@src/js/layouts/header/navigation/utils';
import loadEnvVariable from '@src/js/static/LoadEnv';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isInstitutionalInvestor, isInvestorRelator } from '@src/js/helpers/helpers';

const MainMenuMobile = ({
  navigation, setSubNavigation, setActiveItem, toggleSearch, showSearch,
}) => {
  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(state => state.initInfo.userInfo);
  const impersonateUserSuccess = useSelector(state => state.user.impersonateUserSuccess);
  const prevImpersonateUserSuccess = usePrevious(impersonateUserSuccess);
  const exitImpersonateSessionSuccess = useSelector(state => state.user.exitImpersonateSessionSuccess);
  const prevExitImpersonateSessionSuccess = usePrevious(exitImpersonateSessionSuccess);
  const unreadThreads = useSelector(state => state.chat.unreadThreads);
  const taskNotificationNumber = useSelector(state => state.socketTaskNotifications.taskNotificationNumber);
  const { showModal, checkType } = useEtineraryModal();

  useEffect(() => {
    if (
      (!prevImpersonateUserSuccess && impersonateUserSuccess)
      || (!prevExitImpersonateSessionSuccess && exitImpersonateSessionSuccess)
    ) {
      window.location.replace(withLang(routeCodes.HOME)); // redirect with page reload on purpose
    }
  }, [prevImpersonateUserSuccess, impersonateUserSuccess,
    exitImpersonateSessionSuccess, prevExitImpersonateSessionSuccess]);

  const toggleMenuPanel = () => {
    setShowMenuPanel(!showMenuPanel);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  const stopImpersonatingUser = () => {
    dispatch(exitImpersonateSession());
  };

  const showTaskNumberNotification = () => {
    if (taskNotificationNumber && taskNotificationNumber > 0) {
      return true;
    }

    return false;
  };

  const renderTaskLink = () => (
    <NavLink
      id='main-navigation-mobile__tasks'
      activeClassName='main-navigation-mobile__right-section__nav-item--active'
      className='main-navigation-mobile__right-section__nav-item'
      exact
      to={ withLang(routeCodes.MY_COMPANY_TASKS) }
    >
      { showTaskNumberNotification()
        && (
          <span className='main-navigation-mobile__right-section__nav-item-notification'>
            { taskNotificationNumber }
          </span>
        ) }
      <span className='main-navigation-mobile__right-section__nav-item-icon icon-medium-Notification' />
    </NavLink>

  );

  if (userData === undefined || userData.isLoggedIn === false) {
    window.location = formatUrlToSecure(loadEnvVariable('APP_BASE_DOMAIN'));
    return null;
  }

  const isComplianceUser = userData.userRoles
    .find((el) => el === 'ROLE_COMPLIANCE_CLUB_DEAL' || el === 'ROLE_COMPLIANCE_SIM');
  const investor = isInstitutionalInvestor() || isInvestorRelator();

  const hasAccess = userData
      && userData.companyType !== Company.TYPE_ETINERARY
      && userData.partnerType !== Partner.TYPE_BASIC;

  return (
    <nav className='main-navigation-mobile'>
      { showSearch && <Search toggleSearch={ toggleSearch } /> }

      <span
        className='main-navigation-mobile__toggle'
        onClick={ toggleMenuPanel }
        role='presentation'
      >
        <span className='icon main-navigation-mobile__toggle-icon icon-medium-Hamburger' />
      </span>

      <a className='main-navigation-mobile__logo' href={ withLang(routeCodes.HOME) } aria-label='ELITE Logo'>
        <div className='main-navigation-mobile__logo-icon icon-EliteWhite' />
      </a>

      { /* Mobile main navigation bar */ }
      <div className='main-navigation-mobile__right-section'>

        { hasAccess
          && userData?.companyVisibility !== Company.VISIBILITY_HIDDEN
          && userData?.partnerVisibility !== Partner.VISIBILITY_HIDDEN
          && !isComplianceUser && !investor
          && (
            <NavLink
              id='main-navigation-mobile__messages'
              activeClassName='main-navigation-mobile__right-section__nav-item--active'
              className='main-navigation-mobile__right-section__nav-item'
              exact
              to={ withLang(routeCodes.MESSAGES) }
            >
              { unreadThreads && unreadThreads.length > 0
              && (
                <span className='main-navigation-mobile__right-section__nav-item-notification'>
                  { unreadThreads.length }
                </span>
              ) }
              <span className='main-navigation-mobile__right-section__nav-item-icon icon-medium-Chat' />
            </NavLink>
          ) }

        {
            userData && userData.companySlug && !isComplianceUser
            && (
              renderTaskLink()
            )
          }
        <LanguageSelector className='main-navigation-mobile__right-section__nav-item' />
        { hasAccess && !isComplianceUser && !investor
          && (
            <span
              id='main-navigation-mobile__search'
              className='main-navigation-mobile__right-section__nav-item'
              onClick={ toggleSearch }
              role='presentation'
            >
              <span className='main-navigation-mobile__right-section__nav-item-icon icon-SearchMedium' />
            </span>
          ) }
      </div>
      { /* --Mobile main navigation bar */ }

      { /* Mobile main navigation panel */ }
      { showMenuPanel
        && (
          <div>
            <div className='navigation-panel-mobile'>
              <div className='navigation-panel-mobile__scroll'>
                <div className='navigation-panel-mobile__header'>
                  <span
                    className='navigation-panel-mobile__header__close'
                    onClick={ toggleMenuPanel }
                    role='presentation'
                  >
                    <span className='navigation-panel-mobile__header__close-icon icon-medium-Close' />
                  </span>

                  <img
                    className='navigation-panel-mobile__header__avatar'
                    alt='avatar'
                    src={ userData.avatar }
                  />

                  <div className='navigation-panel-mobile__header__profile'>
                    { userData.userType === Impersonate.IS_IMPERSONATED
                      ? (
                        <div>
                          <span
                            className='
                              navigation-panel-mobile__header__profile-name
                              navigation-panel-mobile__header__profile-name--impersonated-user'
                          >
                            { userData.firstName }
                            { ' ' }
                            { userData.lastName }
                          </span>
                          <br />
                          <span
                            className='
                            navigation-panel-mobile__header__profile-email
                            navigation-panel-mobile__header__profile-email--impersonated-user'
                          >
                            { userData.email }
                          </span>
                        </div>
                      )
                      : (
                        <div>
                          <span className='navigation-panel-mobile__header__profile-name'>
                            { userData.firstName }
                            { ' ' }
                            { userData.lastName }
                          </span>
                          <br />
                          <span className='navigation-panel-mobile__header__profile-email'>
                            { userData.email }
                          </span>
                        </div>
                      )
                    }
                  </div>
                </div>

                <div className='navigation-panel-mobile__body'>

                  <div className='navigation-panel-mobile__body__group group-main'>
                    {
                      Object.keys(navigation).map(key => {
                        if (externalRoutes.includes(key)) {
                          return (
                            <a
                              key={ key }
                              className='navigation-panel-mobile__body__group__link'
                              href={ routeCodes[key.toUpperCase()] }
                            >
                              <span className={ `icon ${ navigation[key].icon }` } />
                              <FormattedMessage id={ `navigation.${ key }` } />
                            </a>
                          );
                        }
                        const etineraryModalType = checkType(key, userData);
                        return (
                          <NavLink
                            id={ `main-navigation-mobile__${ key }` }
                            key={ key }
                            activeClassName={ `navigation-panel-mobile__body__group__link${ !etineraryModalType
                              ? '--active' : '' }` }
                            className='navigation-panel-mobile__body__group__link'
                            exact={ key === 'home' }
                            to={ withLang(routeCodes[key.toUpperCase()]) }
                            onClick={ () => {
                              if (etineraryModalType) {
                                showModal(etineraryModalType);
                              } else {
                                setSubNavigation(key);
                              }
                              toggleMenuPanel();
                            } }
                            isActive={ (match, location) => setActiveItem(match, location, key) }
                          >
                            <span className={ `icon ${ navigation[key].icon }` } />
                            <FormattedMessage id={ `navigation.${ key }` } />
                            { etineraryModalType && <span className='etinerary-indicator' /> }
                          </NavLink>
                        );
                      })
                    }
                  </div>
                  <div className='navigation-panel-mobile__body__group group-profile'>
                    <NavLink
                      className='navigation-panel-mobile__body__group__link'
                      exact
                      to={ `${ withLang(routeCodes.MY_PROFILE) }` }
                      onClick={ toggleMenuPanel }
                    >
                      <span className='icon icon-small-Edit' />
                      <FormattedMessage id='editProfile' />
                    </NavLink>
                  </div>

                  <div className='navigation-panel-mobile__body__group group-secondary'>
                    { userData.userType === Impersonate.IS_IMPERSONATED
                    && (
                      <li
                        className='
                          navigation-panel-mobile__body__group__link
                          navigation-panel-mobile__body__group__link--impersonated-user'
                        onClick={ stopImpersonatingUser }
                        role='presentation'
                      >
                        <span className='icon icon-medium-Switch' />
                        <FormattedMessage id='exitImpersonateSession' />
                      </li>
                    ) }

                    <li
                      className='navigation-panel-mobile__body__group__link'
                      onClick={ handleLogout }
                      onKeyPress={ handleLogout }
                      role='presentation'
                    >
                      <span className='icon icon-medium-Logout' />
                      <FormattedMessage id='logout' />
                    </li>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='navigation-panel-mobile__overlay'
              onClick={ toggleMenuPanel }
              role='presentation'
            />
          </div>
        ) }
      { /* --Mobile main navigation panel */ }

    </nav>
  );
};

MainMenuMobile.propTypes = {
  navigation: PropTypes.object,
  setSubNavigation: PropTypes.func,
  setActiveItem: PropTypes.func,
  toggleSearch: PropTypes.func,
  showSearch: PropTypes.bool,
};

export default MainMenuMobile;
