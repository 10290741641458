import styled from 'styled-components';
import { Row as ReactstrapRow, Col as ReactstrapCol } from 'reactstrap';
import { ThemeColors } from '@src/_v2/js/constants';
import Button from '@src/_v2/js/components/buttons/Button/Button';
import { FontSize } from '@src/_v2/js/constants/typography';

export const Container = styled(ReactstrapRow).attrs({
  className: 'static-page',
})`
  td {
    padding: 0.4rem 0.6rem 0.4rem 2.8rem;
  }
`;

export const ButtonAction = styled(Button)`
  background-color: ${ ThemeColors.white };
  border-color: ${ ThemeColors.mediumGrey };
  color: ${ ThemeColors.mediumGrey };
  margin: 0;

  &:hover {
    border-color: ${ ThemeColors.blue };
    color: ${ ThemeColors.blue };
  }
`;

export const TopButton = styled(Button)`
  margin: 0;
`;

export const TopRow = styled(ReactstrapRow)`
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  margin: 0 -1.5rem 1rem;
`;

export const FileCard = styled(ReactstrapCol)`
  flex-grow: 1;
  max-width: 75%;
  margin: 1rem -1.5rem;
`;
export const AdmissionsFileCard = styled(ReactstrapCol)`
  flex-grow: 1;
  max-width: 75%;
  margin: 1rem -1.5rem;
`;

export const TermsLabel = styled.div`
  margin-top: 1rem;
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.caption };
`;

export const AdmissionsLabel = styled.div`
  margin-top: 1rem;
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.caption };
`;

export const UploadFieldLabel = styled.div`
  margin-top: 1rem;
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.caption };
`;
