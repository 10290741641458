import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import ClassNames from 'classnames';
import { validateToken } from '@src/js/actions/tokenValidation';
import Button from '@src/js/components/global/buttons/Buttons';
import TextInput from '@src/js/components/global/inputs/TextInput';
import inputValidation from '@src/js/helpers/InputValidation';
import { recreateUserToken } from '@src/js/actions/user/user';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { setPassword } from '@src/js/api/setPassword';

class SetPasswordForm extends Component {
  static propTypes = {
    tokenValidationError: PropTypes.string,
    tokenValidationLoading: PropTypes.bool,
    tokenValidationData: PropTypes.string,
    recreateUserTokenData: PropTypes.string,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    title: PropTypes.string,
    loadingMessage: PropTypes.string,
    className: PropTypes.string,
    urlSetPassword: PropTypes.string,
    urlValidateToken: PropTypes.string,
    urlRecreateUserToken: PropTypes.string,
    redirectUrl: PropTypes.string,
    publicRedirectUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    this.state = {
      token: params.get('token'),
      email: params.get('email'),
      passwordVisible: false,
      confirmPasswordVisible: false,
      submitting: false,
    };
  }

  componentDidMount() {
    const { dispatch, urlValidateToken } = this.props;
    const { token, email } = this.state;

    dispatch(validateToken(token, email, urlValidateToken));
  }

  handleSubmit = async passwordData => {
    const {
      tokenValidationData,
      urlSetPassword,
      redirectUrl,
      publicRedirectUrl,
    } = this.props;
    const { email } = this.state;
    this.setState({ submitting: true });

    return setPassword(tokenValidationData, email, passwordData.newPassword, urlSetPassword)
      .then(() => {
        this.setState({ submitting: false });
        window.location = redirectUrl;
        return null;
      }).catch(errors => {
        this.setState({ submitting: false });
        if (errors.response.signedTerms !== undefined && !errors.response.signedTerms) {
          window.location = publicRedirectUrl;

          return null;
        }

        return errors;
      });
  };

  validate = values => {
    const errors = {};

    errors.newPassword = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.minLengthValidator(7),
      inputValidation.passwordValidator
    )(values.newPassword);
    errors.confirmedPassword = inputValidation.samePasswordValidator(values.newPassword)(values.confirmedPassword);

    return errors;
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      passwordVisible: !prevState.passwordVisible,
    }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      confirmPasswordVisible: !prevState.confirmPasswordVisible,
    }));
  };

  resendToken = () => {
    const { dispatch, urlRecreateUserToken } = this.props;
    const { email } = this.state;

    dispatch(recreateUserToken({ email }, urlRecreateUserToken));
  };

  render() {
    const { passwordVisible, confirmPasswordVisible, submitting } = this.state;
    const {
      tokenValidationLoading, tokenValidationData, tokenValidationError,
      recreateUserTokenData, title, loadingMessage, className,
    } = this.props;

    return (
      <Form
        validate={ this.validate }
        onSubmit={ this.handleSubmit }
        render={ ({ handleSubmit }) => (
          <form
            className={ ClassNames(className) }
            onSubmit={ handleSubmit }
          >
            <div className='set-password-form-container'>
              { submitting && <Loader /> }
              { tokenValidationLoading && (
                <div className='set-password-form-content'>
                  <div className='set-password-title'>
                    <FormattedMessage id={ loadingMessage } />
                  </div>
                </div>
              ) }

              { tokenValidationData && (
                <div className='set-password-form-content'>
                  <div className='set-password-form-header'>
                    <h2 className='set-password-form-title'>
                      <FormattedMessage id={ title } />
                    </h2>
                  </div>

                  <div className='set-password-form-body'>
                    <div className='eop-text-input col-12 mb-1 position-relative'>
                      <Field
                        name='newPassword'
                        fieldName='newPassword'
                        fieldLabel='setPassword.inputPassword'
                        placeholder='setPassword.inputPassword'
                        fieldType={ passwordVisible ? 'text' : 'password' }
                        component={ TextInput }
                      />
                      <span
                        className={ passwordVisible ? 'icon-EyeOpenSmall show-password-eye'
                          : 'icon-EyeClosedSmall show-password-eye' }
                        onClick={ this.togglePasswordVisibility }
                        onKeyDown={ this.togglePasswordVisibility }
                        role='presentation'
                      />
                    </div>

                    <div className='eop-text-input col-12 mb-2 position-relative'>
                      <Field
                        name='confirmedPassword'
                        fieldName='confirmedPassword'
                        fieldLabel='setPassword.inputConfirmPassword'
                        placeholder='setPassword.inputConfirmPassword'
                        fieldType={ confirmPasswordVisible ? 'text' : 'password' }
                        component={ TextInput }
                      />
                      <span
                        className={ confirmPasswordVisible
                          ? 'icon-EyeOpenSmall show-password-eye'
                          : 'icon-EyeClosedSmall show-password-eye' }
                        onClick={ this.toggleConfirmPasswordVisibility }
                        onKeyDown={ this.toggleConfirmPasswordVisibility }
                        role='presentation'
                      />
                    </div>
                  </div>

                  <div className='set-password-form-footer'>
                    <Button
                      additionalClass='w-100'
                      buttonType='submit'
                      buttonText='dialog.confirm'
                    />
                  </div>
                </div>
              ) }

              { tokenValidationError && !recreateUserTokenData && (
                <div className='set-password-form-content'>
                  <div className='set-password-form-body'>
                    <div className='set-password-title'>
                      <FormattedMessage id='setPassword.invalidToken' />
                    </div>
                    <div className='set-password-title'>
                      <Button buttonText='resendToken.buttonLabel' onClickFunc={ this.resendToken } />
                    </div>
                  </div>
                </div>
              ) }

              { recreateUserTokenData && (
                <div className='set-password-form-content'>
                  <div className='set-password-form-body'>
                    <div className='set-password-title'>
                      <FormattedMessage id={ recreateUserTokenData } />
                    </div>
                  </div>
                </div>
              ) }
            </div>
          </form>
        ) }
      />
    );
  }
}

const mapStateToProps = state => ({
  tokenValidationError: state.tokenValidation.error,
  tokenValidationLoading: state.tokenValidation.loading,
  tokenValidationData: state.tokenValidation.data,
  recreateUserTokenData: state.user.recreateUserToken,
  recreateUserTokenLoading: state.user.recreateUserTokenLoading,
  recreateUserTokenError: state.user.recreateUserTokenError,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(SetPasswordForm);

export default withRouter(connectWrapper);
