export const ADMIN_TAB_PRODUCTS = 'ADMIN_TAB_PRODUCTS';
export const ADMIN_TAB_USERS = 'ADMIN_TAB_USERS';
export const ADMIN_TAB_RESTRICTED_VISIBILITY = 'ADMIN_TAB_INTRODUCTIONS';

export const AdminTabs = [
  { id: 0, tab: ADMIN_TAB_PRODUCTS, label: 'products' },
  { id: 1, tab: ADMIN_TAB_USERS, label: 'businessNeeds' },
  { id: 2, tab: ADMIN_TAB_RESTRICTED_VISIBILITY, label: 'introductions' },
];

export const PlatformStatus = {
  STATUS_PENDING: 0,
  STATUS_ACTIVE: 1,
  STATUS_REJECTED: 2,
};

export const ProductStatus = {
  STATUS_CLOSED: 0,
  STATUS_OPEN: 1,
};

export const RequestStatus = {
  STATUS_CLOSED_UNSUCCESSFULLY: 0,
  STATUS_OPEN: 1,
  STATUS_CLOSED_SUCCESSFULLY: 2,
};

export const AdminType = {
  ADMIN_TYPE_PENDING: 'pending',
  ADMIN_TYPE_ACTIVE: 'active',
  ADMIN_TYPE_REJECTED: 'rejected',
  ADMIN_TYPE_OPEN: 'open',
  ADMIN_TYPE_CLOSE: 'close',
  ADMIN_TYPE_FEATURED: 'featured',
};

export const TagType = {
  TAG_TYPE_EXPERTISE: 6,
  TAG_TYPE_DIMENSION: 8,
};
