import CompanyOnboarding from '@app/components/public/onboarding/CompanyOnboarding';
import PartnerOnboarding from '@app/components/public/onboarding/PartnerOnboarding';
import { logoutOnboardingUser } from '@src/js/actions/onboarding/onboardingBasic';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import PageLoader from '@src/js/components/global/pageLoader/PageLoader';
import { PARTNER_TYPES } from '@src/js/constants/entities';
import { Icons } from '@src/js/constants/icons';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { DEFAULT_LANGUAGE } from '@src/js/layouts/forms/company/CompanyForm';
import IntercomComponent from '@src/js/layouts/header/intercom/Intercom';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import OnboardingErrorPage from './OnboardingErrorPage';
import OnboardingSimpleTextPage from './OnboardingSimpleTextPage';
import {
  CenteredText,
  LogoutButton,
  OnboardingHeader,
  OnboardingHeaderContainer,
} from './styled';

class OnboardingProcess extends Component {
  static propTypes = {
    onboardingUser: PropTypes.object,
    isSubmitted: PropTypes.bool,
    error: PropTypes.object,
    logoutError: PropTypes.object,
    loading: PropTypes.bool,
    logoutLoading: PropTypes.bool,
    match: PropTypes.object,
    translation: PropTypes.object,
    dispatch: PropTypes.func,
  };

  handleLogout = (e) => {
    e.preventDefault();

    const { dispatch } = this.props;

    dispatch(logoutOnboardingUser());
  };

  renderHeader = () => (
    <OnboardingHeaderContainer>
      <OnboardingHeader>
        <NavLink
          onClick={ () => {
            window.location.replace(withLang(routeCodes.HOME));
          } }
          className='main-navigation__logo'
          to='#'
        >
          <img src='/assets/img/svg/logo-white.svg' alt='logo' className='h-5' />
        </NavLink>
        <div className='main-navigation__right-section'>
          <LogoutButton
            onClick={ this.handleLogout }
            className='main-navigation__right-section__nav-item'
          >
            <span className='icon main-navigation__right-section__nav-item-icon icon-medium-Logout' />
            <span className='main-navigation__right-section__nav-item-caption'>
              <CenteredText>
                <FormattedMessage id='logout' />
              </CenteredText>
            </span>
          </LogoutButton>
        </div>
        <IntercomComponent hideLauncher={ false } />
      </OnboardingHeader>
    </OnboardingHeaderContainer>
  );

  render() {
    const {
      onboardingUser, isSubmitted, loading, error, logoutError, logoutLoading, match, translation,
    } = this.props;

    if (onboardingUser && !onboardingUser.isLoggedIn) {
      return <Redirect to={ withLang(routeCodes.ONBOARDING_LOGIN) } />;
    }

    // redirect to user onboarding form if not user unique form
    if (onboardingUser && onboardingUser.userProcessHash !== match.params.processHash) {
      return (
        <Redirect
          to={ withLang(routeCodes.ONBOARDING_FORM.replace(':processHash', onboardingUser.userProcessHash)) }
        />
      );
    }

    return (
      <>
        <MetaTitle title='onboarding.genericTitle' />

        { this.renderHeader() }

        <Container className='compliance__container'>
          { (loading || logoutLoading) && <PageLoader fixed /> }

          { error && <OnboardingErrorPage error={ error } /> }
          { logoutError && <OnboardingErrorPage error={ logoutError } /> }
          { !PARTNER_TYPES.includes(onboardingUser.templateKind) && isSubmitted && (
            <OnboardingSimpleTextPage
              translation={ translation }
              caption={ 'onboarding.thankYouPage.caption' }
              image={ Icons.Sent }
              content={ 'onboarding.thankYouPage.content' }
              headerText={ 'onboarding.thankYouPage.header' }
              pageTitle={ 'onboarding.thankYouPage.pageTitle' }
            />
          ) }

          { !loading && onboardingUser && onboardingUser.isLoggedIn && (
            (PARTNER_TYPES.includes(onboardingUser.templateKind))
              ? <PartnerOnboarding />
              : <CompanyOnboarding />
          ) }
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  processData: state.onboardingProcess.processData,
  isSubmitted: state.onboardingProcess.isSubmitted,
  loading: state.onboardingProcess.loading,
  error: state.onboardingProcess.error,
  translation: state.initInfo.localizationMessages[state?.onboardingProcess?.processLanguage || DEFAULT_LANGUAGE],
  onboardingUser: state.onboardingBasic.onboardingUser,
  logoutLoading: state.onboardingBasic.logoutLoading,
  logoutError: state.onboardingBasic.logoutError,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(OnboardingProcess);

export default withRouter(connectWrapper);
