export const GET_NAVIGATION_START = 'GET_NAVIGATION_START';
export const GET_NAVIGATION_SUCCESS = 'GET_NAVIGATION_SUCCESS';
export const GET_NAVIGATION_ERROR = 'GET_NAVIGATION_ERROR';
export const SHOW_NAVIGATION = 'SHOW_NAVIGATION';
export const HIDE_NAVIGATION = 'HIDE_NAVIGATION';

export function getNavigation() {
  return {
    type: GET_NAVIGATION_START,
  };
}

export function hideNavigationAction() {
  return {
    type: HIDE_NAVIGATION,
  };
}

export function showNavigationAction() {
  return {
    type: SHOW_NAVIGATION,
  };
}
