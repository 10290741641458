import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_EXTERNAL_COMPANY_FORM_OPTIONS_START,
  GET_EXTERNAL_COMPANY_FORM_OPTIONS_SUCCESS,
  GET_EXTERNAL_COMPANY_FORM_OPTIONS_ERROR,
  SELF_REGISTRATION_CREATE_COMPANY_START,
  SELF_REGISTRATION_CREATE_COMPANY_ERROR,
  SELF_REGISTRATION_CREATE_COMPANY_SUCCESS,
} from '@src/js/actions/self-registration/company/externalCompanyFormOptions';

import {
  getExternalCompanyFormOptionsApi,
  selfRegistrationCreateCompanyApi,
} from '@src/js/api/self-registration/company/externalCompanyFormOptions';

function* getCompanyFormOptions() {
  try {
    const data = yield call(() => getExternalCompanyFormOptionsApi());
    yield put({ type: GET_EXTERNAL_COMPANY_FORM_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_EXTERNAL_COMPANY_FORM_OPTIONS_ERROR, error });
  }
}

function* selfRegistrationCreateCompany(options) {
  try {
    const data = yield call(() => selfRegistrationCreateCompanyApi(options.company.values));
    yield put({ type: SELF_REGISTRATION_CREATE_COMPANY_SUCCESS, data });
    options.company.resolve();
  } catch (error) {
    yield put({ type: SELF_REGISTRATION_CREATE_COMPANY_ERROR, error });
    options.company.reject(error.response);
  }
}

export function* getExternalCompanyFormOptionsWatcher() {
  yield takeLatest(GET_EXTERNAL_COMPANY_FORM_OPTIONS_START, getCompanyFormOptions);
}

function* selfRegistrationCreateCompanyWatcher() {
  yield takeLatest(SELF_REGISTRATION_CREATE_COMPANY_START, selfRegistrationCreateCompany);
}

export default [
  getExternalCompanyFormOptionsWatcher(),
  selfRegistrationCreateCompanyWatcher(),
];
