import React, { useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { logoutUser } from '@src/js/actions/user/user';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@src/js/components/global';
import { purifyHtml } from '@src/js/utils';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { RootState } from '@src/js/store';

const ResetPasswordModal = () => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const logoutSuccess = useSelector<RootState, boolean>(state => state.initInfo.logoutSuccess);

  useEffect(() => {
    if (logoutSuccess) {
      hideModal();
      window.location.href = withLang(routeCodes.REQUEST_RESET_PASSWORD);
    }
  }, [logoutSuccess, hideModal]);

  return (
    <>
      <ModalBody className='membership-modal-body'>
        <FormattedMessage id='dashboard.modal.resetPwd.body'>
          { htmlMessage => (
            <p className='blue-a' dangerouslySetInnerHTML={ { __html: purifyHtml(htmlMessage) } } />
          ) }
        </FormattedMessage>
      </ModalBody>
      <div className='modal__footer-buttons'>
        <Button buttonText='dashboard.modal.resetPwd.title' onClickFunc={ () => dispatch(logoutUser()) } />
        <Button buttonText='dashboard.modal.resetPwd.skip' onClickFunc={ hideModal } />
      </div>
    </>
  );
};

export default ResetPasswordModal;
