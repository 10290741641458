import React from 'react';
import { purifyHtmlBanners } from '@src/js/utils';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import { BannerListItem } from '@app/models/Banner';
import { ModalBody } from 'reactstrap';

const BannerPreview = ({ banner }: { banner: BannerListItem }) => {
  let wrapperClass;
  switch (banner.bannerType) {
    case 'sidebar':
      wrapperClass = 'dashboard-banner__right';
      break;
    case 'dashboard':
      wrapperClass = 'dashboard-banner';
      break;
    case 'leaderboard':
      wrapperClass = 'flex leaderboard-banner';
      break;
    default:
      break;
  }

  return (
    <ModalBody>
      <div className={ `banner-preview m-y-5 ${ wrapperClass }` }>
        <DashboardWidget>
          <div
            id={ banner.id.toString() }
            className='blue-a'
            dangerouslySetInnerHTML={ { __html: purifyHtmlBanners(banner.contentHtml) } }
          />
        </DashboardWidget>
      </div>
    </ModalBody>
  );
};

export default BannerPreview;
