import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';

import { ThemeColors } from '@src/_v2/js/constants/index';

import StepIcon from './StepIcon';

import styles from './styles';

const StepperConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 4px)',
  },
  active: {
    '& $line': {
      borderColor: ThemeColors.blue,
    },
  },
  completed: {
    '& $line': {
      borderColor: ThemeColors.blue,
    },
  },
  line: {
    borderColor: ThemeColors.lightGrey,
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles(styles);

const Steppers = ({ activeStep = 1, steps = [] }) => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <Stepper alternativeLabel activeStep={ activeStep } connector={ <StepperConnector /> }>
        {steps.map((label) => (
          <Step key={ label }>
            <StepLabel StepIconComponent={ StepIcon } />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

Steppers.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

export default Steppers;
