import fetchResource from '@src/js/api/fetch-resource';

export function adminChangeNewsStatus(slug, status) {
  return fetchResource(`api/admin/news/${ slug }`, {
    method: 'PATCH',
    body: {
      status,
    },
    slug,
  });
}
