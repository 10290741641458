export const GET_ONBOARDING_DATA_START = 'GET_ONBOARDING_DATA_START';
export const GET_ONBOARDING_DATA_ERROR = 'GET_ONBOARDING_DATA_ERROR';
export const GET_ONBOARDING_DATA_SUCCESS = 'GET_ONBOARDING_DATA_SUCCESS';
export const SUBMIT_ONBOARDING_START = 'SUBMIT_ONBOARDING_START';
export const SUBMIT_ONBOARDING_ERROR = 'SUBMIT_ONBOARDING_ERROR';
export const SUBMIT_ONBOARDING_SUCCESS = 'SUBMIT_ONBOARDING_SUCCESS';

export function getOnboardingData(processHash) {
  return {
    type: GET_ONBOARDING_DATA_START,
    processHash,
  };
}

export function submitOnboarding(data) {
  return {
    type: SUBMIT_ONBOARDING_START,
    data,
  };
}
