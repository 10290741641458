const scope = 'DEAL';
export const UPDATE_DEAL_START = `${ scope }/UPDATE_START`;
export const UPDATE_DEAL_FAIL = `${ scope }/UPDATE_FAIL`;
export const UPDATE_DEAL_SUCCESS = `${ scope }/UPDATE_SUCCESS`;
export const GET_DEAL_START = `${ scope }/GET_START`;
export const GET_DEAL_FAIL = `${ scope }/GET_FAIL`;
export const GET_DEAL_SUCCESS = `${ scope }/GET_SUCCESS`;
export const GET_DEAL_LIST_START = `${ scope }/GET_LIST_START`;
export const GET_DEAL_LIST_FAIL = `${ scope }/GET_LIST_FAIL`;
export const GET_DEAL_LIST_SUCCESS = `${ scope }/GET_LIST_SUCCESS`;
export const DELETE_DEAL_START = `${ scope }/DELETE_START`;
export const DELETE_DEAL_FAIL = `${ scope }/DELETE_FAIL`;
export const DELETE_DEAL_SUCCESS = `${ scope }/DELETE_SUCCESS`;

export function getDeal(id) {
  return {
    type: GET_DEAL_START,
    id,
  };
}

export function getDealList(params) {
  return {
    type: GET_DEAL_LIST_START,
    params,
  };
}

export function updateDeal(id, data, history) {
  return {
    type: UPDATE_DEAL_START,
    data,
    id,
    history,
  };
}

export function deleteDeal(id) {
  return {
    type: DELETE_DEAL_START,
    id,
  };
}
