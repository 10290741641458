import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DebounceInput } from 'react-debounce-input';
import { Container, Tab } from './styled';

const Tabs = (props) => {
  const {
    tabs, selectedTab, onClick, labelPrefix, setSearch, search,
  } = props;
  const ref = useRef();

  const hasSearchTab = tabs.find(t => t.text === 'search');

  useEffect(() => {
    if (search && ref.current) {
      ref.current.focus();
    }
  }, [search, tabs]);

  return (
    <>
      <Container>
        {
          tabs.map(tab => {
            if (tab.text !== 'search') {
              return (
                <Tab
                  key={ tab.id }
                  selected={ tab.text.toLowerCase() === selectedTab.toLowerCase() }
                  onClick={ () => onClick(tab.text) }
                >
                  <FormattedMessage id={ labelPrefix + tab.text } />
                </Tab>
              );
            }
            return null;
          })
        }
      </Container>
      { hasSearchTab && setSearch && (
        <div className='documents-area__search'>
          <span className='icon eop-text-color-light-grey font-size-subheader icon-SearchMedium' />
          <FormattedMessage id='trainingList.search'>
            {(placeholder) => (
              <DebounceInput
                inputRef={ ref }
                debounceTimeout={ 500 }
                placeholder={ placeholder }
                onChange={ (e) => setSearch(e.target.value) }
                value={ search }
                className='search-input documents-area__search-input'
              />
            )}
          </FormattedMessage>
          { search.trim()
            && (
            <span
              className='icon eop-text-color-light-grey font-size-subheader icon-small-Close'
              role='presentation'
              onClick={ () => setSearch('') }
            />
            )
          }
        </div>
      ) }
    </>
  );
};

Tabs.defaultProps = {
  labelPrefix: '',
  search: '',
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.string,
  onClick: PropTypes.func,
  labelPrefix: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func,
};

export default Tabs;
