import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const FileDownload = ({
  file, intl, label, translate, name,
}) => {
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;

  const fileDownload = () => {
    const link = document.createElement('a');
    const url = file?.url;
    link.href = url;
    link.setAttribute('download', 'file.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className={ 'eop-text-input' }>
      <label className='eop-text-input__label'>
        { label && fieldLabel }
      </label>
      <div className='eop-text-input__input d-flex justify-content-between'>
        <input
          className='eop-text-input__input-item'
          defaultValue={ file?.name }
          disabled
        />
        <button
          type='button'
          name={ name }
          className={ `eop-btn eop-btn-sm eop-btn-empty-grey 
            ${ file ? 'enabled-download' : 'eop-btn-empty-grey--disabled' }` }
          onClick={ fileDownload }
          disabled={ !file }
        >
          {intl.formatMessage({ id: 'download' })}
        </button>
      </div>
    </div>
  );
};

FileDownload.propTypes = {
  intl: PropTypes.object,
  label: PropTypes.string,
  file: PropTypes.object,
  translate: PropTypes.bool,
  name: PropTypes.string,
};

FileDownload.defaultProps = {
  translate: true,
};

export default injectIntl(FileDownload);
