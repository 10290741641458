import produce from 'immer';
import { PARTNER_FORM_STEPS, COMPANY_FORM_STEPS } from '@app/store/onboarding/constants';

import * as Actions from '@app/store/onboarding/actions';

const validation = {
  [COMPANY_FORM_STEPS.BASIC_INFO]: true,
};
const firstVisit = {};
Object.values(PARTNER_FORM_STEPS).forEach(step => {
  validation[step] = false;
  firstVisit[step] = true;
});
Object.values(COMPANY_FORM_STEPS).forEach(step => {
  validation[step] = false;
  firstVisit[step] = true;
});

export const initialState = {
  currentStep: null,
  touchedSteps: [PARTNER_FORM_STEPS.BASIC_INFO],
  validation,
  firstVisit,
  updateLoading: false,
  updateError: null,
  data: null,
  onSave: {
    action: null, step: null, newStep: null, downloadPath: null,
  },
  loading: false,
  error: null,
  submitted: false,
  crmAccounts: [],
  crmPagination: {},
};

function onboardingReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    let draft = draftState;
    switch (action.type) {
      case Actions.GET_DATA_START:
        draft.loading = true;
        draft.error = null;
        draft.data = null;
        break;
      case Actions.GET_DATA_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = action.data;
        break;
      case Actions.GET_DATA_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.data = null;
        break;
      case Actions.SET_ACTIVE_STEP:
        draft.onSave = { action: null, step: null, newStep: null };
        draft.currentStep = action.step;
        if (!draft.touchedSteps.includes(action.step)) {
          draft.touchedSteps = [...state.touchedSteps, action.step];
        }
        break;
      case Actions.UPDATE_DATA_START:
        draft.updateLoading = true;
        draft.updateError = null;
        break;
      case Actions.UPDATE_DATA_SUCCESS:
        draft.updateLoading = false;
        draft.updateError = null;
        draft.validation = action.result?.steps;
        draft.data = JSON.parse(JSON.stringify(action.data));
        if (draft.onSave?.action === 'submit') {
          draft.data.submitted = true;
        }
        draft.onSave = { action: null, step: null, newStep: null };
        break;
      case Actions.UPDATE_DATA_FAIL:
        draft.updateLoading = false;
        draft.updateError = action.error;
        draft.onSave = { action: null, step: null, newStep: null };
        break;
      case Actions.ON_SAVE_ONBOARDING:
        draft.onSave.step = action.step;
        draft.firstVisit[action.step] = false;
        draft.onSave.action = action.action;
        draft.onSave.newStep = action.newStep;
        draft.onSave.downloadPath = action.downloadPath;
        break;
      case Actions.REGISTER_START:
        draft.registerLoading = true;
        draft.registerError = null;
        draft.registerData = null;
        break;
      case Actions.REGISTER_SUCCESS:
        draft.registerLoading = false;
        draft.registerError = null;
        draft.registerData = action.data;
        break;
      case Actions.REGISTER_FAIL:
        draft.registerLoading = false;
        draft.registerError = action.error;
        draft.registerData = null;
        break;
      case Actions.UPDATE_VALIDATION:
        draft.validation = action.validation;
        break;
      case Actions.CLEAR_DATA:
        draft = initialState;
        break;
      case Actions.DOCUSIGN_START:
        draft.loading = true;
        break;
      case Actions.DOCUSIGN_SUCCESS:
        window.location.href = action.result?.redirectUrl;
        break;
      case Actions.DOCUSIGN_FAIL:
        draft.loading = false;
        break;
      case Actions.GET_CRM_ACCOUNTS_START:
        if (action.params?.page === 1) {
          draft.crmAccounts = [];
        }
        break;
      case Actions.GET_CRM_ACCOUNTS_SUCCESS:
        draft.crmPagination = action.result?.pagination;
        if (action.params?.page === 1) {
          draft.crmAccounts = action.result?.results;
        } else if (action.result?.results) {
          draft.crmAccounts = [...state.crmAccounts, ...action.result.results];
        }
        break;
      case Actions.UPDATE_BASIC_SUCCESS:
        if (state.data.country !== undefined) {
          draft.data.country = action.data.country;
        }
        if (state.data.company?.country !== undefined) {
          draft.data.company.country = action.data.country;
        }
        if (state.data.mobilePhone !== undefined) {
          draft.data.mobilePhone = action.data.mobilePhone;
        }
        if (state.data.terms?.mobilePhone !== undefined) {
          draft.data.terms.mobilePhone = action.data.mobilePhone;
        }
        if (state.data.vatNumber !== undefined) {
          draft.data.vatNumber = action.data.vatNumber;
        }
        if (state.data.name !== undefined) {
          draft.data.name = action.data.companyName;
        }
        if (state.data.taxCode !== undefined) {
          draft.data.taxCode = action.data.taxCode;
        }
        draft.data.basic = action.data;
        draft.validation = { ...state.validation, [PARTNER_FORM_STEPS.BASIC_INFO]: true };
        break;
      case Actions.UPDATE_BASIC_FAIL:
        draft.validation = { ...state.validation, [PARTNER_FORM_STEPS.BASIC_INFO]: false };
        break;
      default:
        break;
    }
    return draft;
  });
}

export default onboardingReducer;
