import React, { Component } from 'react';

export default class GlobalLoader extends Component {
  render() {
    return (
      <div className='global-spinner'>
        <div className='global-spinner-spinner-icon' />
      </div>
    );
  }
}
