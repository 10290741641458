import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_USERS_START,
  ADMIN_GET_USERS_ERROR,
  ADMIN_GET_USERS_SUCCESS,
  GET_USER_FILTER_COMPANY_OPTIONS_START,
  GET_USER_FILTER_COMPANY_OPTIONS_SUCCESS,
  GET_USER_FILTER_COMPANY_OPTIONS_ERROR,
} from '@src/js/actions/user/adminUsers';

import * as api from '@src/js/api/user/adminUsers';

function* adminGetUsers(options) {
  try {
    const data = yield call(() => api.adminGetUsers(options.filters));
    yield put({ type: ADMIN_GET_USERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_USERS_ERROR, error });
  }
}

function* getUserFilterCompanyOptions(options) {
  try {
    const data = yield call(() => api.getUserFilterCompanyOptions(options.filterOptions.values));
    yield put({ type: GET_USER_FILTER_COMPANY_OPTIONS_SUCCESS, data });
    options.filterOptions.resolve(data);
  } catch (error) {
    yield put({ type: GET_USER_FILTER_COMPANY_OPTIONS_ERROR, error });
    options.filterOptions.reject(error.response);
  }
}

// Watcher
export function* adminGetUsersWatcher() {
  yield takeLatest(ADMIN_GET_USERS_START, adminGetUsers);
}

export function* getUserFilterCompanyOptionsWatcher() {
  yield takeLatest(GET_USER_FILTER_COMPANY_OPTIONS_START, getUserFilterCompanyOptions);
}

export default [
  adminGetUsersWatcher(),
  getUserFilterCompanyOptionsWatcher(),
];
