import Sent from '@assets/img/svg/icons/SentIcon.svg';
import Mail from '@assets/img/svg/icons/mail.svg';
import Doc from '@assets/img/svg/icons/Big/Doc.svg';
import ar from '@assets/img/svg/icons/flags/ar.svg';
import fr from '@assets/img/svg/icons/flags/fr.svg';
import it from '@assets/img/svg/icons/flags/it.svg';
import en from '@assets/img/svg/icons/flags/en.svg';
import es from '@assets/img/svg/icons/flags/es.svg';
import pt from '@assets/img/svg/icons/flags/pt.svg';

export const Icons = {
  Sent,
  Mail,
  Doc,
  flags: {
    ar,
    fr,
    it,
    en,
    es,
    pt,
  },
};
