import React, { useEffect, useState } from 'react';
import CircleAvatar from '@src/js/components/global/cards/components/Avatar/CircleAvatar';
import { Button, Loader } from '@src/js/components/global';
import { useDispatch } from 'react-redux';
import { Event } from '@src/js/constants/entities';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import useFetch from '@app/services/useFetch';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { showChatPopup } from '@app/store/chat/chatSlice';

export type MeetingTypes = 'pending'|'accepted'|'rejected';

export type NetworkingUser = {
  id?: string,
  avatar: string,
  company: string,
  email: string,
  fullName: string,
  jobTitle: string,
  uuid: string,
  location?: 1|2,
  meeting?: MeetingTypes,
  companySlug: string,
  companyUrl: string
}

type MeetingResponse = {
  meeting: MeetingTypes
}

const NetworkingCard = ({
  user, slug, canMeet = false, sendEmail = false,
}: { user: NetworkingUser, slug?: string, canMeet: boolean, sendEmail: boolean}) => {
  const dispatch = useDispatch();
  const userInfo = selectUserInfo();
  const [meeting, setMeeting] = useState(user.meeting);
  const { data, loading, request: requestMeeting } = useFetch<MeetingResponse>(
    `api/event/${ slug }/community/meeting/${ user.uuid }}`,
    { method: 'POST' },
    false,
    () => dispatch(showToastrSuccess()),
    (err) => dispatch(
      showToastrError('notification.error_title', err?.response?.status || 'notification.submit.error'))
  );

  useEffect(() => {
    if (data?.meeting) {
      setMeeting(data.meeting);
    }
  }, [data]);

  return (
    <div className='networking-card'>
      <div className='networking-card__header'>
        <div className='networking-card__avatar'>
          <CircleAvatar src={ user.avatar } />
        </div>
        <div className='networking-card__fullname'>
          <p data-tip data-for={ `${ user.uuid }-tooltip-name` }>{user.fullName}</p>
          {user.fullName?.length > 20 && (
            <ReactTooltip
              id={ `${ user.uuid }-tooltip-name` }
              place='top'
              effect='solid'
              multiline={ true }
            >
              {user.fullName}
            </ReactTooltip>
          )}
          <p data-tip data-for={ `${ user.uuid }-tooltip-company` }>
            <a className='networking-card__company' href={ user.companyUrl } rel='noreferrer' target='_blank'>
              {user.company}
            </a>
          </p>
          {user.company?.length > 20 && (
            <ReactTooltip
              id={ `${ user.uuid }-tooltip-company` }
              place='top'
              effect='solid'
              multiline={ true }
            >
              {user.company}
            </ReactTooltip>
          )}
        </div>
        {user.location && (
          <div className='networking-card__location'>
            <span
              className={
                user.location === Event.LOCATION_DIGITAL
                  ? 'icomoon-display'
                  : 'icon-small-Users'
              }
            />
          </div>
        )}
      </div>
      <p className='networking-card__job-title' data-tip data-for={ `${ user.uuid }-tooltip-job` }>{user.jobTitle}</p>
      {user.jobTitle?.length > 30 && (
        <ReactTooltip
          id={ `${ user.uuid }-tooltip-job` }
          place='top'
          effect='solid'
          multiline={ true }
        >
          {user.jobTitle}
        </ReactTooltip>
      )}
      <div className='networking-card__buttons'>
        { user.uuid && userInfo?.uuid !== user.uuid && (
          <>
            <Button
              size='md'
              buttonText='sendAMessage'
              onClickFunc={ () => dispatch(showChatPopup(user.uuid)) }
            />
            { canMeet && user.location === Event.LOCATION_PHYSICAL && (
            <div className='relative'>
              {loading && (<Loader />)}
              <Button
                size='md'
                additionalClass={ `w-100 ${ meeting || 'default' }` }
                buttonText={ meeting === Event.MEETING_ACCEPTED
                  ? 'trainingCommunity.meeting.accepted'
                  : 'trainingCommunity.meeting' }
                onClickFunc={ requestMeeting }
                disabled={ !!meeting }
              />
            </div>
            )}
          </>
        )}
        { sendEmail && (
        <a
          className='eop-btn'
          href={ `mailto:${ user.email }` }
        >
          <FormattedMessage id='sendEmail' />
        </a>
        )
        }
      </div>
    </div>
  );
};

export default NetworkingCard;
