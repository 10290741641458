import {
  ADMIN_TEMPLATE_SHOW_MODAL,
  ADMIN_TEMPLATE_HIDE_MODAL,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateModal';

const initialState = {
  modalShown: false,
  isKindPartner: false,
  templateType: null,
};

const actionsMap = {
  [ADMIN_TEMPLATE_SHOW_MODAL]: (state, action) => ({
    ...state,
    modalShown: true,
    templateType: action.templateType,
  }),
  [ADMIN_TEMPLATE_HIDE_MODAL]: (state) => ({
    ...state,
    modalShown: false,
    templateType: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
