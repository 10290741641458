import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  FormSection, InputSelect, InputCheckbox, InputText,
  InputDate,
} from '@app/components/global/forms';

const CorporateActions = ({ intl, form }) => {
  const {
    control, formState: { errors }, register, watch, getValues, clearErrors,
  } = form;

  const options = [
    { value: 1, label: intl.formatMessage({ id: 'yes' }) },
    { value: 2, label: intl.formatMessage({ id: 'no' }) },
  ];

  const crossBorderOptions = [
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.crossBorder' }), value: 'cross_border' },
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.domestic' }), value: 'domestic' },
  ];

  const financingOptions = [
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.bankLoan' }), value: 'loan' },
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.selfFinancing' }), value: 'self_financing' },
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.bondOrEquity' }), value: 'bond' },
  ];

  const privateEquityOptions = [
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.minority' }), value: 'minority' },
    { label: intl.formatMessage({ id: 'onboarding.fundamentals.majority' }), value: 'majority' },
  ];

  const validateChoices = () => {
    const values = getValues();
    clearErrors('fundamentals.acquisition');
    clearErrors('fundamentals.capitalRaising');
    clearErrors('fundamentals.other');
    return values.fundamentals?.acquisition
      || values.fundamentals?.capitalRaising
      || values.fundamentals?.other;
  };

  const validateCapitalRaisingChoices = () => {
    const values = getValues();
    clearErrors('fundamentals.capitalRaisingIPO');
    clearErrors('fundamentals.capitalRaisingMinibond');
    clearErrors('fundamentals.capitalRaisingPrivateEquity');
    return values.fundamentals.capitalRaisingIPO
      || values.fundamentals.capitalRaisingMinibond
      || values.fundamentals.capitalRaisingPrivateEquity;
  };

  useEffect(() => {
    register('fundamentals.acquisition', { validate: validateChoices });
    register('fundamentals.capitalRaising', { validate: validateChoices });
    register('fundamentals.other', { validate: validateChoices });

    register('fundamentals.capitalRaisingIPO', { validate: validateCapitalRaisingChoices });
    register('fundamentals.capitalRaisingMinibond',
      { validate: validateCapitalRaisingChoices }
    );
    register('fundamentals.capitalRaisingPrivateEquity',
      { validate: validateCapitalRaisingChoices }
    );
  }, [register]);

  const { fundamentals } = watch();

  return (
    <FormSection>
      <h2 className='compliance__title w-100'>
        <FormattedMessage id='onboarding.fundamentals.extraOrdinaryCorporateActions.title' />
      </h2>
      <div>
        <InputSelect
          name='fundamentals.main'
          label='onboarding.fundamentals.extraOrdinaryCorporateActions'
          errors={ errors }
          control={ control }
          options={ options }
          fieldNoClear
          required
        />
      </div>
      <div />
      { fundamentals?.main === 1 && (
        <>
          <div className='m-b-2'>
            <p className='eop-selectbox eop-selectbox__label m-b-0'>
              <span className='required-icon' />
              <FormattedMessage id='onboarding.fundamentals.whichType' />
            </p>
            { (
              errors?.fundamentals?.acquisition
              || errors?.fundamentals?.capitalRaising
              || errors?.fundamentals?.other
            ) && (
              <span className='error-message'>
                <FormattedMessage id='onboarding.fundamentals.atLeastOneChoice' />
              </span>
            )}
          </div>
          <div className='w-100'>
            <InputCheckbox
              name='fundamentals.acquisition'
              label='onboarding.fundamentals.acquisition'
              errors={ errors }
              control={ control }
            />
          </div>
          <div className='w-100'>
            <InputCheckbox
              name='fundamentals.capitalRaising'
              label='onboarding.fundamentals.capitalRaising'
              errors={ errors }
              control={ control }
            />
          </div>
          <div className='w-100'>
            <InputCheckbox
              name='fundamentals.other'
              label='onboarding.fundamentals.other'
              errors={ errors }
              control={ control }
            />
          </div>
        </>
      )}
      { fundamentals?.main === 1 && fundamentals?.acquisition && (
        <>
          <hr className='compliance__divider w-100 m-t-2' />
          <p className='w-100 font-size-body'>
            <FormattedMessage id='onboarding.fundamentals.acquisition' />
          </p>
          <InputSelect
            name='fundamentals.crossBorder'
            errors={ errors }
            control={ control }
            options={ crossBorderOptions }
            label='onboarding.fundamentals.crossBorder.label'
            required
          />
          <InputSelect
            name='fundamentals.financing'
            errors={ errors }
            control={ control }
            options={ financingOptions }
            label='onboarding.fundamentals.financing'
            multi
            required
          />
          <InputText
            name='fundamentals.acquisitionInfos'
            errors={ errors }
            register={ register }
            label='onboarding.fundamentals.acquisitionInfos'
          />
        </>
      )}
      { fundamentals?.main === 1 && fundamentals?.capitalRaising && (
        <>
          <hr className='compliance__divider w-100 m-t-2' />
          <p className='w-100 font-size-body'>
            <span className='required-icon' />
            <FormattedMessage id='onboarding.fundamentals.capitalRaising' />
            { (
              errors?.fundamentals?.capitalRaisingIPO
            || errors?.fundamentals?.capitalRaisingMinibond
            || errors?.fundamentals?.capitalRaisingPrivateEquity
            ) && (
              <>
                <br />
                <span className='error-message'>
                  <FormattedMessage id='onboarding.fundamentals.atLeastOneChoice' />
                </span>
              </>
            )}
          </p>
          <div className='w-100'>
            <InputCheckbox
              name='fundamentals.capitalRaisingIPO'
              label='onboarding.fundamentals.capitalRaisingIPO'
              errors={ errors }
              control={ control }
            />
          </div>
          { fundamentals?.capitalRaisingIPO && (
            <>
              <InputText
                name='fundamentals.capitalRaisingIPOAmount'
                errors={ errors }
                register={ register }
                label='onboarding.fundamentals.capitalRaisingIPOAmount'
                type='number'
              />
              <InputDate
                name='fundamentals.capitalRaisingIPODate'
                control={ control }
                errors={ errors }
                label='onboarding.fundamentals.capitalRaisingIPODate'
                dateFormat='dd/MM/yyyy'
              />
              <InputText
                name='fundamentals.capitalRaisingIPOAdvisor'
                errors={ errors }
                register={ register }
                label='onboarding.fundamentals.capitalRaisingIPOAdvisor'
              />
            </>
          )}
          <div className='w-100'>
            <InputCheckbox
              name='fundamentals.capitalRaisingMinibond'
              label='onboarding.fundamentals.capitalRaisingMinibond'
              errors={ errors }
              control={ control }
            />
          </div>
          { fundamentals?.capitalRaisingMinibond && (
            <>
              <InputText
                name='fundamentals.capitalRaisingMinibondAmount'
                errors={ errors }
                register={ register }
                label='onboarding.fundamentals.capitalRaisingMinibondAmount'
                type='number'
              />
              <InputDate
                name='fundamentals.capitalRaisingMinibondDate'
                control={ control }
                errors={ errors }
                label='onboarding.fundamentals.capitalRaisingMinibondDate'
                dateFormat='dd/MM/yyyy'
              />
              <InputText
                name='fundamentals.capitalRaisingMinibondAdvisor'
                errors={ errors }
                register={ register }
                label='onboarding.fundamentals.capitalRaisingMinibondAdvisor'
              />
            </>
          )}
          <div className='w-100'>
            <InputCheckbox
              name='fundamentals.capitalRaisingPrivateEquity'
              label='onboarding.fundamentals.capitalRaisingPrivateEquity'
              errors={ errors }
              control={ control }
            />
          </div>
          { fundamentals?.capitalRaisingPrivateEquity && (
            <>
              <InputSelect
                name='fundamentals.capitalRaisingPrivateEquityChoices'
                errors={ errors }
                control={ control }
                label='onboarding.fundamentals.capitalRaisingPrivateEquity.label'
                options={ privateEquityOptions }
              />
              <InputText
                name='fundamentals.capitalRaisingPrivateEquityAmount'
                errors={ errors }
                register={ register }
                label='onboarding.fundamentals.capitalRaisingMinibondAmount'
                type='number'
              />
              <InputText
                name='fundamentals.capitalRaisingPrivateEquityAdvisor'
                errors={ errors }
                register={ register }
                label='onboarding.fundamentals.capitalRaisingPrivateEquityAdvisor'
              />
            </>
          )}
        </>
      )}
      { fundamentals?.main === 1 && fundamentals?.other && (
        <>
          <hr className='compliance__divider w-100 m-t-2' />
          <p className='w-100 font-size-body'>
            <FormattedMessage id='onboarding.fundamentals.other' />
          </p>
          <InputText
            name='fundamentals.otherText'
            errors={ errors }
            register={ register }
            label='onboarding.fundamentals.other.label'
            required
          />
        </>
      )}
    </FormSection>
  );
};

CorporateActions.propTypes = {
  intl: PropTypes.object,
  form: PropTypes.object,
};

export default injectIntl(CorporateActions);
