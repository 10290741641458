import React from 'react';
import { Row, Col } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

import IconButton from '@mui/material/IconButton';

import { TextInput } from '@src/js/components/global/inputs/index';
import { apiPath } from '@src/js/constants/routes';

import { TargetBlank } from '@src/_v2/js/constants';
import { Checkbox } from '@src/js/components/global/inputs';

import UploadDocumentsField from '@src/_v2/js/views/admin/Onboarding/Templates/UploadDocumentsField';
import { LINK_DOWNLOAD } from '@app/store/etinerary/constants';
import FieldReadOnly from '@app/components/global/forms/FieldReadOnly';
import { purifyHtml } from '@src/js/utils';

const scope = 'etinerary.upgradeModal';

const FieldsLegalRepresentative = ({
  data,
  mutators,
  submitAttempted,
  user,
  intl,
}) => {
  const downloadPath = `${ apiPath() }${ LINK_DOWNLOAD }`;
  const roleLabel = intl.formatMessage({ id: 'main.role' });
  return (
    <div>
      <Row>
        <Col className='etinerary__fields__readonly'>
          <FieldReadOnly
            isInput
            label='name'
            value={ user?.firstName }
          />
          <FieldReadOnly
            isInput
            label='main.surname'
            value={ user?.lastName }
          />
          <Field
            additionalClass='m-t-2'
            name='jobTitle'
            fieldName='jobTitle'
            fieldLabel={ roleLabel }
            fieldType='text'
            component={ TextInput }
            fieldRequired={ !user?.jobRoleName }
            min={ 2 }
            maxLength={ 256 }
            translate={ false }
            placeholder={ user?.jobRoleName }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='etinerary__container__dangerously'>
            <div
              dangerouslySetInnerHTML={
              { __html: purifyHtml(data?.editorial) }
              }
              className='etinerary___dangerously blue-a'
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='etinerary-container-list-form'>
            <FormattedMessage id={ `${ scope }.capitalRaising` } />
            <ul className='etinerary-list-form'>
              <li>
                <FormattedMessage id={ `${ scope }.caseEquity` } />
              </li>
              <li>
                <FormattedMessage id={ `${ scope }.caseDebt` } />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name='checkTerms'
            fieldName='checkTerms'
            type='checkbox'
            fieldLabel='etinerary.upgradeModal.termsConditions'
            component={ Checkbox }
            fieldRequired
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='etinerary__download'>
            <a { ...TargetBlank } href={ downloadPath } className='etinerary__link'>
              <IconButton
                classes={ {
                  root: 'etinerary-icon-button',
                  label: 'etinerary-icon-label',
                } }
                aria-label='download terms and conditions'
                component='span'
                disableRipple
              >
                <CloudDownloadOutlinedIcon />
                <FormattedMessage id={ `${ scope }.download` } />
              </IconButton>
            </a>
          </div>
        </Col>
      </Row>
      {data?.docType && (
        <UploadDocumentsField
          fieldLabel='etinerary.upgradeModal.terms'
          setDocumentsCallback={ mutators.setTerms }
          submitAttempted={ submitAttempted }
          className='etinerary__upload__terms'
        />
      )}
    </div>
  );
};

FieldsLegalRepresentative.propTypes = {
  data: PropTypes.shape({
    docType: PropTypes.bool,
    editorial: PropTypes.string,
    legalUser: PropTypes.object,
  }).isRequired,
  mutators: PropTypes.object,
  submitAttempted: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    jobRoleName: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.object,
};

export default injectIntl(FieldsLegalRepresentative);
