import fetchResource from '@src/js/api/fetch-resource';

export function requestResetPassword(email, gRecaptchaResponse, url) {
  return fetchResource(url, {
    method: 'POST',
    body: {
      email,
      gRecaptchaResponse,
    },
  });
}
