/*
 *
 * Main messages
 *
 */

import { defineMessages } from 'react-intl';

const scope = 'main';
const processes = `${ scope }.processes`;
const filters = `${ scope }.processesFilters`;
const templates = `${ scope }.templates`;
const notification = `${ scope }.notification`;

const messages = defineMessages({
  dateCompletion: {
    id: `${ scope }.dateCompletion`,
    defaultMessage: 'date completion',
  },
  url: {
    id: `${ scope }.url`,
    defaultMessage: 'url',
  },
  processes: {
    email: {
      id: `${ processes }.email`,
      defaultMessage: 'email',
    },
    companyName: {
      id: `${ processes }.companyName`,
      defaultMessage: 'company name',
    },
    completionPercentage: {
      id: `${ processes }.completionPercentage`,
      defaultMessage: 'completion percentage',
    },
    editProcess: {
      id: `${ processes }.editProcess`,
      defaultMessage: 'edit process',
    },
    button: {
      id: `${ processes }.button`,
      defaultMessage: 'actions',
    },
  },
  templates: {
    dateCreation: {
      id: `${ templates }.dateCreation`,
      defaultMessage: 'date of creation',
    },
    disable: {
      id: `${ templates }.disable`,
      defaultMessage: 'disable',
    },
    enable: {
      id: `${ templates }.enable`,
      defaultMessage: 'enable',
    },
    templateName: {
      id: `${ templates }.templateName`,
      defaultMessage: 'Name',
    },
    modified: {
      id: `${ templates }.modified`,
      defaultMessage: 'modified date',
    },
  },
  processesFilters: {
    country: {
      id: `${ filters }.country`,
      defaultMessage: 'country',
    },
    companyName: {
      id: `${ filters }.companyName`,
      defaultMessage: 'company name',
    },
    template: {
      id: `${ filters }.template`,
      defaultMessage: 'template',
    },
  },
  filters: {
    programme: {
      id: `${ scope }.programme`,
      defaultMessage: 'Filter by company program',
    },
    eventDateAfter: {
      id: `${ scope }.eventDateAfter`,
      defaultMessage: 'Event date after',
    },
    eventDateBefore: {
      id: `${ scope }.eventDateBefore`,
      defaultMessage: 'Event date before',
    },
  },
  notification: {
    active: {
      id: `${ notification }.active`,
      defaultMessage: 'on',
    },
    notActive: {
      id: `${ notification }.notActive`,
      defaultMessage: 'off',
    },
  },
  action: {
    id: `${ scope }.action`,
    defaultMessage: 'actions',
  },
  add: {
    id: `${ scope }.add`,
    defaultMessage: 'Add',
  },
  added: {
    id: `${ scope }.added`,
    defaultMessage: 'Added',
  },
  disable: {
    id: `${ scope }.disable`,
    defaultMessage: 'Disable',
  },
  disabled: {
    id: `${ scope }.disabled`,
    defaultMessage: 'Disabled',
  },
  enable: {
    id: `${ scope }.enable`,
    defaultMessage: 'Enable',
  },
  enabled: {
    id: `${ scope }.enabled`,
    defaultMessage: 'Enabled',
  },
  name: {
    id: `${ scope }.name`,
    defaultMessage: 'Name',
  },
  no: {
    id: `${ scope }.no`,
    defaultMessage: 'No',
  },
  type: {
    id: `${ scope }.type`,
    defaultMessage: 'Type',
  },
  search: {
    id: `${ scope }.search`,
    defaultMessage: 'Search',
  },
  status: {
    id: `${ scope }.status`,
    defaultMessage: 'Status',
  },
  yes: {
    id: `${ scope }.yes`,
    defaultMessage: 'Yes',
  },
});

export default messages;
