import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import MultipleDocumentCard from '@app/components/global/modals/MultipleDocumentCard';
import FileToUpload from '@app/models/FileToUpload';
import ConfirmModal from '@src/js/components/ConfirmModal';
import { MultipleUploadStatus } from '@src/js/constants/entities';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
  title: string,
  onCloseAction: () => void,
  filesToUpload: FileToUpload[],
};

const MultipleDocumentUploadingModal = ({ title, onCloseAction, filesToUpload }: Props) => {
  const { hideModal } = useGlobalModalContext();
  const [, setProgress] = useState('');
  const [exiting, setExiting] = useState(false);
  const total = filesToUpload.length;
  const processed = filesToUpload.filter(f => f.getStatus() === MultipleUploadStatus.uploaded).length;
  const failed = filesToUpload.filter(f => f.getStatus() === MultipleUploadStatus.failed).length;
  const status = (processed + failed) === total ? MultipleUploadStatus.uploaded : MultipleUploadStatus.uploading;

  const clearUploads = () => {
    filesToUpload.forEach(file => {
      file.abort();
    });
    onCloseAction();
    hideModal();
  };

  useEffect(() => {
    filesToUpload.forEach(file => {
      if (file.getStatus() === MultipleUploadStatus.init) {
        file.uploadFile();
        file.onChangeProgress = (progress) => setProgress(file.getFileName() + progress);
        file.onChangeStatus = (stat) => setProgress(file.getFileName() + stat);
      }
    });
  }, [filesToUpload]);

  const close = () => {
    if (status === MultipleUploadStatus.uploading) {
      setExiting(true);
    } else {
      onCloseAction();
      hideModal();
    }
  };

  return (
    <>
      <ModalHeader />
      <ModalBody>
        { title }
        <p className='multiple-uploading-modal__subtitle'>
          { status === MultipleUploadStatus.uploading && <FormattedMessage id='multiple_upload.dontLeave' />}
        </p>
        <div className='multiple-uploading-modal__files'>
          { filesToUpload.map(file => (
            <MultipleDocumentCard file={ file } key={ file.getFileName() } />
          )) }
        </div>
      </ModalBody>
      <ModalFooter>
        <p className='font-size-body'>
          <FormattedMessage id='multiple_upload.processed' />
          <b>
            {processed}
            /
            {total}
          </b>
          { failed > 0 && (
            <>
              {' - '}
              <FormattedMessage id='multiple_upload.failed' />
              <span className='font-size-body font-weight-bold eop-text-color-red'>
                {failed}
                <FormattedMessage id={ `multiple_upload.${ failed === 1 ? 'file' : 'files' }` } />
              </span>
            </>
          ) }
        </p>
        { status === MultipleUploadStatus.uploading && (
        <button
          className='eop-btn eop-btn-sm eop-btn-empty-blue multiple-uploading-modal__button'
          onClick={ close }
        >
          <FormattedMessage id='multiple_upload.cancel' />
        </button>
        )}
        { status === MultipleUploadStatus.uploaded && (
        <button
          className='eop-btn eop-btn-sm eop-btn-blue multiple-uploading-modal__button'
          onClick={ close }
        >
          <FormattedMessage id='multiple_upload.close' />
        </button>
        )}
      </ModalFooter>
      <ConfirmModal
        show={ exiting }
        title={ { id: 'multiple_upload.closeMessage' } }
        toggleShow={ () => setExiting(!exiting) }
        handleAccept={ clearUploads }
      />
    </>
  );
};

export default MultipleDocumentUploadingModal;
