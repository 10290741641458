import React from 'react';
import PropTypes from 'prop-types';
import File from '@src/js/views/admin/pages/company/documentsArea/File';
import Folder from '@src/js/views/admin/pages/company/documentsArea/Folder';
import { DataRoomPermissions } from '@src/js/constants/entities';

const FilesTree = ({
  companySlug, onFolderClick, dataroomData,
  dataRoomType, noFilesAndButtons, exclude, destinationFolder,
}) => (
  <div>
    { dataroomData?.folders?.map(folder => (
      folder.id !== exclude && (
        <Folder
          key={ folder.id }
          folder={ folder }
          slug={ companySlug }
          handleClick={ () => onFolderClick(folder) }
          companySlug={ companySlug }
          dataRoomType={ dataRoomType }
          noFilesAndButtons={ noFilesAndButtons }
          destinationFolder={ destinationFolder }
        />
      )
    )) }
    { !noFilesAndButtons && dataroomData?.files?.map(file => (
      <File
        key={ file.slug }
        file={ file }
        slug={ companySlug }
        dataRoomType={ dataRoomType }
        cantBeMoved={ dataroomData?.folders.filter(p => p.id > 0).length === 0
          && dataroomData?.parents.filter(
            p => p.permission > DataRoomPermissions.edit && p.id !== dataroomData.id).length === 0 }
      />
    )) }
  </div>
);

FilesTree.propTypes = {
  companySlug: PropTypes.string,
  onFolderClick: PropTypes.func,
  dataroomData: PropTypes.object,
  dataRoomType: PropTypes.string,
  noFilesAndButtons: PropTypes.bool,
  exclude: PropTypes.number,
  destinationFolder: PropTypes.number,
};

export default FilesTree;
