import fetchResource from '@src/js/api/fetch-resource';

export function getListMembership(type = 'processes', filters) {
  let url = `api/admin/membership/${ type }/list`;

  url += filters ? `?${ new URLSearchParams(filters) }` : '';

  return fetchResource(url);
}

export function getMembershipCompany(hashTemplate, isAdminForm, isReport) {
  if (isReport) {
    return fetchResource(`api/${ isAdminForm ? 'admin/' : '' }membership/${ hashTemplate }?isReport=true`, {
      method: 'GET',
    });
  }
  return fetchResource(`api/${ isAdminForm ? 'admin/' : '' }membership/${ hashTemplate }`, {
    method: 'GET',
  });
}
