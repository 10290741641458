import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@app/components/global/oldTableStyles';
import { Loader } from '@src/js/components/global';
import { getPaginatorLocaleMessages } from '@src/js/helpers/PaginatorTranslateHelper';
import Pagination from 'rc-pagination';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListFooterContainer from './ListFooterContainer';

type TableColDefBase = {
  headerName: string,
  width: number,
}

export type TableColDef<T> = TableColDefBase & ({
  component: (item: T, column?: TableColDef<T>) => JSX.Element|string|number,
  field: string,
} | {
  field: keyof T,
  component?: undefined,
})

export type ItemBase = {
  id: number|string|null,
  slug?: string,
}

type ListProps<T extends ItemBase> = {
  loading?: boolean,
  page: number,
  handlePage?: (page: number, pageSize: number) => void,
  columns: TableColDef<T>[],
  items: T[],
  total: number,
  pageSize?: number,
}

const renderColumn = <T extends ItemBase>(column: TableColDef<T>, item: T) : JSX.Element => {
  const { field, width, component } = column;
  return (
    <TableCell percentageWidth={ width } key={ field }>
      { component ? component(item) : item[field] }
    </TableCell>
  );
};

export const DataTable = <T extends ItemBase>({
  loading, handlePage, page, columns, items, total, pageSize = 10,
}: ListProps<T>) => (
  <div>
    { loading && <Loader /> }
    <Table>
      <TableHead>
        <TableRow>
          { columns.map(({ width, headerName, field }) => (
            <TableCell percentageWidth={ width } key={ field }>
              {headerName && <FormattedMessage id={ headerName } />}
            </TableCell>
          )) }
        </TableRow>
      </TableHead>
      { items?.length > 0 && (
        <TableBody>
          { items.map(item => (
            <TableRow key={ item.id }>
              { columns.map((column) => renderColumn(column, item))}
            </TableRow>
          )) }
        </TableBody>
      ) }
    </Table>
    { items.length > 0 && total > pageSize && (
      <ListFooterContainer
        pagination={ (
          <Pagination
            onChange={ handlePage }
            current={ page }
            total={ total }
            pageSize={ pageSize }
            locale={ getPaginatorLocaleMessages('en') }
            showLessItems
          />
             )
          }
      />
    )}
  </div>
  );

export default DataTable;
