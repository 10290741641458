import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type Props = {
  isInput?: boolean,
  label: string,
  value: string|object,
}

const FieldReadOnly = ({ isInput, label, value }: Props) => (
  <div className='eop-text-input m-t-2'>
    <div className='eop-text-input__label'>
      <FormattedMessage id={ label } />
      <div className={ classNames('eop-text-input__input', {
        'input-form': isInput,
        'no-input-form': !isInput,
      }) }
      >
        <div className='eop-text-input__input-item no-input-form-item'>
          {value}
        </div>
      </div>
    </div>
  </div>
);

export default FieldReadOnly;
