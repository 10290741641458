import fetchResource from '@src/js/api/fetch-resource';

export function getSearchResults(search) {
  let action = 'api';

  switch (search.type) {
    case 'users':
      action = `${ action }/admin/users?search=${ search.value }`;
      break;
    default:
      action = `${ action }/users`;
  }

  return fetchResource(action, {
    method: 'GET',
  });
}
