import { takeLatest, call, put } from 'redux-saga/effects';

import {
  SEARCH_START,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
} from '@src/js/actions/search';

import * as api from '@src/js/api/search';

function* getSearchResults(options) {
  try {
    const data = yield call(() => api.getSearchResults(options.search));
    yield put({ type: SEARCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: SEARCH_ERROR, error });
  }
}

function* createSearchWatcher() {
  yield takeLatest(SEARCH_START, getSearchResults);
}

export default [
  createSearchWatcher(),
];
