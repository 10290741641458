import { takeLatest, call, put } from 'redux-saga/effects';

import {
  CREATE_PROGRAMME_START,
  CREATE_PROGRAMME_ERROR,
  CREATE_PROGRAMME_SUCCESS,
} from '@src/js/actions/programme/programmes';

import * as api from '@src/js/api/programme/programmes';

function* adminCreateProgramme(options) {
  try {
    const data = yield call(() => api.adminCreateProgramme(options.programme.programme));
    yield put({ type: CREATE_PROGRAMME_SUCCESS, data });
    options.programme.resolve();
  } catch (error) {
    yield put({ type: CREATE_PROGRAMME_ERROR, error });
    options.programme.reject(error.response);
  }
}

function* adminCreateProgrammeWatcher() {
  yield takeLatest(CREATE_PROGRAMME_START, adminCreateProgramme);
}

export default [
  adminCreateProgrammeWatcher(),
];
