import fetchResource from '@src/js/api/fetch-resource';
import { getFormattedFilters } from '@src/js/api/user/adminUsers';
import { User } from '@src/js/constants/entities';

export function getPartners(filters) {
  const newFilters = getFormattedFilters(filters);
  let url = 'api/admin/partners';

  if (newFilters) {
    url += `?${ new URLSearchParams(newFilters) }`;
  }

  return fetchResource(url);
}

export function getPartner(slug) {
  return fetchResource(`api/admin/partners/${ slug }`, {
    method: 'GET',
  });
}

export function editPartner(partner, userType) {
  const urlType = (userType === User.TYPE_ADMIN) ? '/admin' : '';

  return fetchResource(`api${ urlType }/partners/${ partner.slug }`, {
    method: 'PUT',
    'body': partner,
  });
}

export function createPartner(partner) {
  return fetchResource('api/admin/partners', {
    method: 'POST',
    body: partner,
  });
}

export function disablePartner(slug) {
  return fetchResource(`api/admin/partners/${ slug }`, {
    method: 'DELETE',
    slug,
  });
}

export function getPartnerPublicProfile(slug) {
  return fetchResource(`api/partners/${ slug }/profile`, {
    method: 'GET',
  });
}
