import {
  takeLatest, call, put, takeEvery, all, select,
} from 'redux-saga/effects';

import {
  GET_BROKER_START,
  GET_BROKER_FAIL,
  GET_BROKER_SUCCESS,
  UPDATE_BROKER_START,
  UPDATE_BROKER_FAIL,
  UPDATE_BROKER_SUCCESS,
  GET_BROKER_LIST_START,
  GET_BROKER_LIST_FAIL,
  GET_BROKER_LIST_SUCCESS,
  DELETE_BROKER_START,
  DELETE_BROKER_FAIL,
  DELETE_BROKER_SUCCESS,
} from '@app/store/funding/actions/broker';

import * as api from '@app/store/funding/api/broker';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions/index';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';

function* getBrokerList({ params }) {
  try {
    const data = yield call(() => api.getBrokerList(params));
    if (params?.infinite) {
      const newPage = data.count / data.pageSize > data.currentPage + 1 ? data.currentPage + 1 : 0;
      localStorage.setItem('broker-list-funding', newPage);
    }
    yield put({ type: GET_BROKER_LIST_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: GET_BROKER_LIST_FAIL, error });
  }
}

function* getBroker(options) {
  try {
    const data = yield call(() => api.getBroker(options.brokerId));
    yield put({ type: GET_BROKER_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_BROKER_FAIL, error });
  }
}

function* updateBroker({ id, data, history }) {
  try {
    if (id) {
      yield call(() => api.updateBroker(id, data));
    } else {
      yield call(() => api.createBroker(data));
    }
    yield put({ type: UPDATE_BROKER_SUCCESS, data });
    history.push(withLang(adminRouteCodes.BROKERS));
  } catch (error) {
    yield put({ type: UPDATE_BROKER_FAIL, error });
    yield put(showToastrError());
  }
}

function* deleteBroker({ id }) {
  try {
    const data = yield call(() => api.deleteBroker(id));
    const params = yield select((state) => state.broker.params);
    yield all([
      put({ type: DELETE_BROKER_SUCCESS, data }),
      put(showToastrSuccess()),
    ]);
    if (params) {
      yield put({ type: GET_BROKER_LIST_START, params: { ...params, page: 0 } });
    }
  } catch (error) {
    yield all([
      put({ type: DELETE_BROKER_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getBrokerWatcher() {
  yield takeLatest(GET_BROKER_START, getBroker);
}

function* editBrokerWatcher() {
  yield takeLatest(UPDATE_BROKER_START, updateBroker);
}

function* getBrokerListWatcher() {
  yield takeEvery(GET_BROKER_LIST_START, getBrokerList);
}

function* deleteBrokerWatcher() {
  yield takeLatest(DELETE_BROKER_START, deleteBroker);
}

export default [
  getBrokerWatcher(),
  editBrokerWatcher(),
  getBrokerListWatcher(),
  deleteBrokerWatcher(),
];
