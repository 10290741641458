import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import * as api from '@app/store/customReminder/api';
import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  GET_USERS_START,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  SEND_CUSTOM_REMINDER_START,
  SEND_CUSTOM_REMINDER_FAIL,
  SEND_CUSTOM_REMINDER_SUCCESS,
  CLEAR_USERS,
} from '@app/store/customReminder/actions';

function* getCustomReminderList({ slug, params }) {
  try {
    const result = yield call(() => api.getCustomReminderList(slug, params));
    yield put({ type: GET_DATA_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_DATA_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getCustomReminderUsers({ slug, params }) {
  try {
    const result = yield call(() => api.getCustomReminderUsers(slug, params));
    yield put({ type: GET_USERS_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_USERS_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* sendCustomReminder({
  slug, data, templateId, scheduling,
}) {
  try {
    const result = yield call(() => api.sendCustomReminder(slug, data, templateId, scheduling));
    yield all([
      put({ type: SEND_CUSTOM_REMINDER_SUCCESS, result }),
      put({ type: CLEAR_USERS }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: SEND_CUSTOM_REMINDER_FAIL, error }),
      put(showToastrError('notification.error_title', error.response?.description || 'notification.error_message')),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_DATA_START, getCustomReminderList);
  yield takeLatest(GET_USERS_START, getCustomReminderUsers);
  yield takeLatest(SEND_CUSTOM_REMINDER_START, sendCustomReminder);
}

export default root;
