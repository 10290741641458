import fetchResource from '@src/js/api/fetch-resource';

import loadEnvVariable from '@src/js/static/LoadEnv';

const baseUrl = loadEnvVariable('MARKETPLACE_API_PATH');
const adminUserUrl = `${ baseUrl }/admin/users`;
const adminBusinessNeedsUrl = `${ baseUrl }/admin/business-needs`;

export function getBusinessNeedData(filters) {
  return fetchResource(`${ adminUserUrl }?${ new URLSearchParams(filters) }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getAdminBusinessNeedsApi(id) {
  return fetchResource(`${ adminBusinessNeedsUrl }?id=${ id }`, {
    method: 'GET',
    externalUrl: true,
  });
}
