import { takeLatest, call, put } from 'redux-saga/effects';

import {
  COMPANY_DOCUMENTS_GET_FILES_START,
  COMPANY_DOCUMENTS_GET_FILES_ERROR,
  COMPANY_DOCUMENTS_GET_FILES_SUCCESS,
} from '@src/js/actions/company/companyDocumentsFiles';

import * as api from '@src/js/api/company/companyDocumentsFiles';

function* getCompanyDocumentsFiles(options) {
  try {
    const data = yield call(() => api.companyDocumentsGetFiles(options.slug, options.offset));
    yield put({ type: COMPANY_DOCUMENTS_GET_FILES_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: COMPANY_DOCUMENTS_GET_FILES_ERROR, error });
  }
}

function* getCompanyDocumentsFilesWatcher() {
  yield takeLatest(COMPANY_DOCUMENTS_GET_FILES_START, getCompanyDocumentsFiles);
}

export default [
  getCompanyDocumentsFilesWatcher(),
];
