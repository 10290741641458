import {
  GET_COMPANY_PUBLIC_PROFILE_START,
  GET_COMPANY_PUBLIC_PROFILE_ERROR,
  GET_COMPANY_PUBLIC_PROFILE_SUCCESS,
  CLEAR_COMPANY_PUBLIC_PROFILE,
  GET_COMPANY_BASIC_DATA_START,
  GET_COMPANY_BASIC_DATA_ERROR,
  GET_COMPANY_BASIC_DATA_SUCCESS,
  GET_COMPANY_FINANCIALS_DATA_START,
  GET_COMPANY_FINANCIALS_DATA_ERROR,
  GET_COMPANY_FINANCIALS_DATA_SUCCESS,
  EDIT_COMPANY_START,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_SUCCESS,
  EDIT_ETINEARY_STATUS_START,
  EDIT_ETINEARY_STATUS_ERROR,
  EDIT_ETINEARY_STATUS_SUCCESS,
} from '@src/js/actions/company/company';
import { UPDATE_COMPANY_FINANCIALS_SUCCESS } from '@src/js/actions/company/adminCompany';
import { formatFinancials } from './adminCompany';

const initialState = {
  companyLoading: false,
  companyError: null,
  company: null,
  basicDataLoading: false,
  basicDataError: null,
  companyBasicData: null,
  financialsDataLoading: false,
  financialsDataError: null,
  companyFinancialsData: null,
  editEtineraryStatusAccepted: null,
  editEtineraryStatusError: null,
};

const actionsMap = {
  [GET_COMPANY_PUBLIC_PROFILE_START]: (state) => ({
    ...state,
    companyLoading: true,
    companyError: null,
    company: null,
  }),
  [GET_COMPANY_PUBLIC_PROFILE_ERROR]: (state, action) => ({
    ...state,
    companyLoading: false,
    companyError: action.error,
    company: null,
  }),
  [GET_COMPANY_PUBLIC_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    companyLoading: false,
    companyError: null,
    company: action.data,
  }),
  [CLEAR_COMPANY_PUBLIC_PROFILE]: () => initialState,
  [GET_COMPANY_BASIC_DATA_START]: (state) => ({
    ...state,
    basicDataLoading: true,
    basicDataError: null,
    financialsDataError: null,
    companyBasicData: null,
    companyError: null,
  }),
  [GET_COMPANY_BASIC_DATA_ERROR]: (state, action) => ({
    ...state,
    basicDataLoading: false,
    basicDataError: action.error,
  }),
  [GET_COMPANY_BASIC_DATA_SUCCESS]: (state, action) => ({
    ...state,
    basicDataLoading: false,
    companyBasicData: action.data,
  }),
  [GET_COMPANY_FINANCIALS_DATA_START]: (state) => ({
    ...state,
    financialsDataLoading: true,
    financialsDataError: null,
  }),
  [GET_COMPANY_FINANCIALS_DATA_ERROR]: (state, action) => ({
    ...state,
    financialsDataLoading: false,
    financialsDataError: action.error,
  }),
  [GET_COMPANY_FINANCIALS_DATA_SUCCESS]: (state, action) => ({
    ...state,
    financialsDataLoading: false,
    companyFinancialsData: formatFinancials(action.data, action.lang),
  }),
  [UPDATE_COMPANY_FINANCIALS_SUCCESS]: (state) => ({
    ...state,
    financialsDataLoading: false,
  }),
  [EDIT_COMPANY_START]: (state) => ({
    ...state,
    companyLoading: true,
    companyError: null,
  }),
  [EDIT_COMPANY_ERROR]: (state, action) => ({
    ...state,
    companyLoading: false,
    companyError: action.error,
  }),
  [EDIT_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    companyLoading: false,
    companyError: null,
    company: action.data,
  }),
  [EDIT_ETINEARY_STATUS_START]: (state) => ({
    ...state,
    editEtineraryStatusAccepted: null,
    editEtineraryStatusError: null,
  }),
  [EDIT_ETINEARY_STATUS_ERROR]: (state) => ({
    ...state,
    editEtineraryStatusAccepted: null,
    editEtineraryStatusError: true,
  }),
  [EDIT_ETINEARY_STATUS_SUCCESS]: (state) => ({
    ...state,
    editEtineraryStatusAccepted: true,
    editEtineraryStatusError: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
