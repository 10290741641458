import fetchResource from '@src/js/api/fetch-resource';

const baseUrl = 'api/etinerary';
const url = `${ baseUrl }/template`;

function getTemplate() {
  return fetchResource(url, {
    method: 'GET',
  });
}

function sendTemplate(values) {
  return fetchResource(url, {
    method: 'POST',
    body: values,
  });
}

function downloadTemplate() {
  return fetchResource(`${ baseUrl }/download/terms`, {
    method: 'GET',
  });
}

function createNewLegal(values) {
  return fetchResource(`${ baseUrl }/new-legal`, {
    method: 'POST',
    body: values,
  });
}

function contactRequest() {
  return fetchResource(`${ baseUrl }/contact`, {
    method: 'POST',
  });
}

export {
  getTemplate,
  sendTemplate,
  downloadTemplate,
  createNewLegal,
  contactRequest,
};
