import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import BusinessNeedTreeFilter from './BusinessNeedTreeFilter';

export default
class TreeFilter extends Component {
  static propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    fieldLabel: PropTypes.string,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    onChange: PropTypes.func,
    selectedValues: PropTypes.array,
    prefix: PropTypes.string,
    formMutator: PropTypes.func,
    toggleOpened: PropTypes.bool,
  }

  render() {
    const {
      input, options, fieldLabel, fieldName, placeholder, onChange, selectedValues, prefix, formMutator,
      toggleOpened,
    } = this.props;

    const treeFilterProps = {
      ...input,
      placeholder,
      name: fieldName,
    };

    if (onChange) {
      treeFilterProps.onChange = onChange;
    }

    if (selectedValues) {
      treeFilterProps.value = selectedValues;
    }

    if (prefix) {
      treeFilterProps.prefix = prefix;
    }

    treeFilterProps.options = options;

    return (
      <BusinessNeedTreeFilter
        { ...treeFilterProps }
        label={ fieldLabel }
        fieldName={ fieldName }
        formMutator={ formMutator }
        toggleOpened={ toggleOpened }
      />
    );
  }
}
