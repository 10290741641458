import {
  ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START,
  ADMIN_LIST_EVENT_ATTENDING_COMPANIES_ERROR,
  ADMIN_LIST_EVENT_ATTENDING_COMPANIES_SUCCESS,
  ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START,
  ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS,
  ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START,
  ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START,
  ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_SUCCESS,
  ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS,
  ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START,
  ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_SUCCESS,
} from '@src/js/actions/events/adminEventAttendingCompanies';

const initialState = {
  loading: false,
  loadingSave: false,
  error: null,
  companies: null,
};

const actionsMap = {
  [ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    companies: null,
  }),

  [ADMIN_LIST_EVENT_ATTENDING_COMPANIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),

  [ADMIN_LIST_EVENT_ATTENDING_COMPANIES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    companies: action.data,
  }),

  [ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START]: (state) => ({
    ...state,
    loading: true,
    loadingSave: true,
    error: null,
    companies: null,
  }),

  [ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: action.error.response,
  }),

  [ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: null,
    companies: action.data,
  }),

  [ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START]: (state) => ({
    ...state,
    loading: true,
    loadingSave: true,
    error: null,
  }),

  [ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: action.error.response,
  }),

  [ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: null,
  }),

  [ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START]: (state) => ({
    ...state,
    loading: true,
    loadingSave: true,
    error: null,
    companies: null,
  }),

  [ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: action.error.response,
  }),

  [ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: null,
    companies: action.data,
  }),

  [ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START]: (state) => ({
    ...state,
    loading: true,
    loadingSave: true,
    error: null,
    companies: null,
  }),

  [ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: action.error.response,
  }),

  [ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loadingSave: false,
    error: null,
    companies: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const reducerFunction = actionsMap[action.type];
  return reducerFunction ? reducerFunction(state, action) : state;
}
