import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_DOCUMENTS_UPLOAD_START,
  PARTNER_DOCUMENTS_UPLOAD_ERROR,
  PARTNER_DOCUMENTS_UPLOAD_SUCCESS,
} from '@src/js/actions/partner/partnerDocumentsUpload';

import * as api from '@src/js/api/partner/partnerDocumentsUpload';

function* uploadPartnerDocumentsFiles(options) {
  try {
    const data = yield call(() => api.partnerDocumentsUpload(options.files, options.slug));
    yield put({ type: PARTNER_DOCUMENTS_UPLOAD_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: PARTNER_DOCUMENTS_UPLOAD_ERROR, error });
  }
}

function* uploadPartnerDocumentsFileWatcher() {
  yield takeLatest(PARTNER_DOCUMENTS_UPLOAD_START, uploadPartnerDocumentsFiles);
}

export default [
  uploadPartnerDocumentsFileWatcher(),
];
