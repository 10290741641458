import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_MANAGER_COMPANIES_START,
  GET_MANAGER_COMPANIES_ERROR,
  GET_MANAGER_COMPANIES_SUCCESS,
} from '@src/js/actions/company/companyManager';

import * as api from '@src/js/api/company/companyManager';

function* getManagerCompanies(options) {
  try {
    const data = yield call(() => api.getManagerCompanies(options.filters));
    yield put({ type: GET_MANAGER_COMPANIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MANAGER_COMPANIES_ERROR, error });
  }
}

function* getManagerCompaniesWatcher() {
  yield takeLatest(GET_MANAGER_COMPANIES_START, getManagerCompanies);
}

export default [
  getManagerCompaniesWatcher(),
];
