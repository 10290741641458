import React, { useState } from 'react';

import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { useShallowSelector } from '@src/js/utils/index';
import { makeSelectLoading } from '@app/store/etinerary/selectors/index';
import Steppers from '@app/components/global/Steppers/Steppers';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import UpgradeModalFooter from './UpgradeModalFooter';
import UpgradeModalBody from './UpgradeModalBody';

const STEPS = [1, 2, 3];

const UpgradeModal = () => {
  const loading = useShallowSelector(makeSelectLoading);

  const [isRepresentative, setIsRepresentative] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { clearModalQueue } = useGlobalModalContext();

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const renderFooter = (disabled = false) => (
    <UpgradeModalFooter
      activeStep={ activeStep }
      handleRepresentative={ (flag) => setIsRepresentative(flag) }
      handleClose={ clearModalQueue }
      handleBack={ handleBack }
      handleNext={ handleNext }
      disabled={ disabled }
      isRepresentative={ isRepresentative }
    />
  );

  return (
    <div className='etinerary-upgrade-modal'>
      { loading && <Loader /> }
      <div className='etinerary-upgrade-modal-container'>
        <Steppers activeStep={ activeStep } steps={ STEPS } />
        <UpgradeModalBody
          activeStep={ activeStep }
          isRepresentative={ isRepresentative }
          footer={ renderFooter }
          handleNext={ handleNext }
        />
        { activeStep !== 1 && renderFooter() }
      </div>
    </div>
  );
};

export default UpgradeModal;
