/* eslint-disable promise/always-return */
import fetchResource from '@src/js/api/fetch-resource';
import { useState, useEffect } from 'react';

type UserOptions = {
  method: string,
  body?: object,
  noContentType?: boolean,
  externalUrl?: boolean,
}

const useFetch = <T>(
  url: string,
  options?: UserOptions,
  loadOnStart = true,
  onSuccess?: () => void,
  onError?: (err?: { response?: { status?: string } }) => void
) => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = () => {
    setLoading(true);
    fetchResource(url, options)
      .then(d => {
        setData(d);
        setLoading(false);
        setError(null);
        if (onSuccess) onSuccess();
      })
      .catch(err => {
        setLoading(false);
        setError(err);
        if (onError) onError(err);
      });
  };

  useEffect(() => {
    if (loadOnStart) request();
  }, []);

  return {
    data, loading, error, request,
  };
};

export default useFetch;
