import fetchResource from '@src/js/api/fetch-resource';

export function adminGetTemplateOptions(kind = '') {
  let url = 'api/admin/templates/create';
  if (kind !== '') {
    url += `?kind=${ kind }`;
  }

  return fetchResource(url);
}
