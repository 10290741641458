import styled from 'styled-components';
import {
  ThemeColors, Breakpoints, FontSize, FontWeight,
} from '@src/_v2/js/constants';

export const Container = styled.div`
  border-bottom: 0.1rem solid ${ ThemeColors.lightGrey };

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    border-top: 0.1rem solid ${ ThemeColors.lightGrey };
    border-bottom: unset;
    background-color: ${ ThemeColors.white };
    width: 95%;
    margin: 0 auto;
  }

  .filter-select-box {
    margin: 1.6rem 0 2.6rem;
    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      border: 0.1rem solid ${ ThemeColors.lightGrey };
    }

    .Select-control {
      border-bottom: none;
      height: 3.9rem;
    }

    .Select-placeholder {
      font-size: ${ FontSize.icon };
      font-weight: ${ FontWeight.normal };
      line-height: 3.9rem;
      padding-left: 2rem;
    }

    .Select-value-label {
      font-size: ${ FontSize.icon };
      font-weight: ${ FontWeight.normal };
      line-height: 3.9rem;
      padding-left: 2rem;
      color: ${ ThemeColors.mediumGrey } !important;
    }

    .Select-arrow-zone {
      padding-right: 2rem;
    }
  }
`;

export const FilterLabel = styled.div.attrs({
  role: 'presentation',
})`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 2.5rem 0;

  .label {
    font-size: ${ FontSize.icon };
    font-weight: ${ FontWeight.medium };

    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      order: 2;
      padding-left: 1rem;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .icon-up {
    margin-left: auto;
    font-size: ${ FontSize.caption };
    line-height: 0.8rem;

    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      margin-left: unset;
      order: 1;
      line-height: 1rem;
      color: ${ ThemeColors.mediumGrey };
    }
  }

  .icon-ArrowRightSmall {
    &::before {
      content: '\\e91e';
    }
  }


`;

export const FilterContentWrapper = styled.div(`
  max-height: 18rem;
  margin-bottom: 2.5rem;
  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    max-height: unset;
    overflow-y: unset;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${ ThemeColors.lightGrey };
  }
`);

export const PublisherScroll = styled.div`
  max-height: 17rem;
  overflow-y: scroll;
`;

export const ResultsCounter = styled.span`
  margin-left: 0.3rem;
  font-weight: ${ FontWeight.light };
  font-size: ${ FontSize.icon };
`;

export const EliteInputCheckbox = styled.div`
  display: flex;
  line-height: 1.5;
  margin-top: 1.5rem;

  label {
    font-weight: ${ FontWeight.medium };
    color: ${ ThemeColors.mediumGrey };
    margin: 0 0 0 1.2rem;
    font-size: ${ FontSize.icon };
  }

  input:checked + label {
    font-size: ${ FontSize.icon };
    color: ${ ThemeColors.blue };
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border: none;
    background-color: ${ ThemeColors.white };
    font-size: ${ FontSize.icon };

    &::-ms-check {
      content: '';
      width: unset;
      height: unset;
      border: 0.1rem solid ${ ThemeColors.lightGrey };
      background: ${ ThemeColors.white };
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:checked::-ms-check {
      font-family: eliteIcons, serif;
      content: '\\e922';
      text-align: center;
      color: ${ ThemeColors.white };
      background-color: ${ ThemeColors.blue };
      border: ${ ThemeColors.blue };
    }

    &::after {
      content: '';
      display: inline-block;
      width: 2rem;
      height: 2rem;
      border: 0.1rem solid ${ ThemeColors.lightGrey };
      background: ${ ThemeColors.white };
      cursor: pointer;
    }

    &:checked::after {
      font-family: eliteIcons, serif;
      content: '\\E922';
      text-align: center;
      color: ${ ThemeColors.white };
      background-color: ${ ThemeColors.blue };
      border: ${ ThemeColors.blue };
    }
  }
`;

export const FilterContainer = styled.div`
  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    padding: 0 2.4rem;
  }

  .filter-search-wrapper {
    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      border: 0.1rem solid ${ ThemeColors.lightGrey };
    }

    font-size: ${ FontSize.body };
    color: ${ ThemeColors.mediumGrey };
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 2.6rem;

    span {
      position: absolute;
      left: 2rem;
      font-size: ${ FontSize.body };
      line-height: 0.5rem;
    }

    input {
      color: ${ ThemeColors.mediumGrey };
      border: none;
      width: 100%;
      padding: 1rem 1rem 1rem 5.5rem;
      font-size: ${ FontSize.icon };
      line-height: 1.95rem;
    }
  }
`;
