import { takeLatest, call, put } from 'redux-saga/effects';

import {
  COMPANY_GET_VIEWS_START,
  COMPANY_GET_VIEWS_ERROR,
  COMPANY_GET_VIEWS_SUCCESS,
  GetCompanyViewsAction,
} from '@src/js/actions/company/companyViews';

import * as api from '@src/js/api/company/companyViews';

function* getCompanyViews(options: GetCompanyViewsAction): Generator {
  try {
    const data = yield call(() => api.getCompanyViews(options.slug, options.entityType, options.limit));
    yield put({ type: COMPANY_GET_VIEWS_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: COMPANY_GET_VIEWS_ERROR, error });
  }
}

function* companyGetViewsWatcher() {
  yield takeLatest(COMPANY_GET_VIEWS_START, getCompanyViews);
}

export default [
  companyGetViewsWatcher(),
];
