import React, { Fragment, useEffect } from 'react';
import {
  FormSection,
} from '@app/components/global/forms';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useFieldArray } from 'react-hook-form';
import InputText from '@app/components/global/forms/InputText';
import { Button } from '@src/js/components/global';
import InputDate from '@app/components/global/forms/InputDate';

const FundingHistory = ({ form }) => {
  const {
    control, formState: { errors }, register,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companyFundingHistory',
  });

  const defaultContact = {
    investorName: null,
    investorType: null,
    amountRaised: null,
    dateOfFunding: null,
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultContact);
    }
  }, []);

  return (
    <FormSection sectionTitle='onboarding.fundamentals.fundingHistory'>
      {fields.map((item, index) => (
        <Fragment key={ item.id }>
          { index !== 0 ? (
            <div className='w-100 d-flex justify-content-end m-b-1'>
              <div role='presentation' className='compliance__button-close' onClick={ () => remove(index) }>
                <span className='icon-medium-Close' />
              </div>
            </div>
          ) : (
            <div className='w-100' />
          )
          }
          <InputText
            name={ `companyFundingHistory[${ index }].investorName` }
            errors={ errors }
            register={ register }
            label='onboarding.fundamentals.investorName'
            defaultValue={ item.investorName }
          />
          <InputText
            name={ `companyFundingHistory[${ index }].investorType` }
            errors={ errors }
            register={ register }
            label='onboarding.fundamentals.investorType'
            defaultValue={ item.investorType }
          />
          <InputText
            name={ `companyFundingHistory[${ index }].amountRaised` }
            errors={ errors }
            register={ register }
            label='onboarding.fundamentals.amountRaised'
            defaultValue={ item.amountRaised }
            type='textNumber'
          />
          <InputDate
            name={ `companyFundingHistory[${ index }].dateOfFunding` }
            errors={ errors }
            control={ control }
            label='onboarding.fundamentals.dateOfFunding'
            defaultValue={ item.dateOfFunding }
            dateFormat='dd/MM/yyyy'
          />
        </Fragment>
      ))}
      <div className='w-100'>
        <Button
          buttonText='company_form.addNew'
          onClickFunc={ () => append(defaultContact) }
        />
      </div>
    </FormSection>
  );
};

FundingHistory.propTypes = {
  form: PropTypes.object,
};

export default injectIntl(FundingHistory);
