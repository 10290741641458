import { combineReducers } from 'redux';
import getMyOpportunities from './getMyOpportunities';
import getMyStatus from './getMyStatus';
import getAllOpportunities from './getAllOpportunities';
import requestInfoModal from './modal/requestInfoModal';
import marketplaceAdmin from './admin/marketplaceAdmin';
import getOpportunityProfile from './getOpportunityProfile';
import getLatestOpportunities from './getLatestOpportunities';
import getUserInfo from './getUserInfo';
import getFeaturedOpportunities from './getFeaturedOpportunities';
import getMostViewedOpportunities from './getMostViewedOpportunities';
import getRelevantOpportunities from './getRelevantOpportunities';
import getOpportunitiesData from './getOpportunitiesData';
import getBusinessNeeds from './businessNeeds';
import businessNeedModal from './modal/businessNeedModal';
import matchmakingMembers from './matchmakingMembers';
import viewAllOpportunities from './viewAllOpportunities';

export default combineReducers({
  getMyOpportunities,
  getMyStatus,
  getAllOpportunities,
  requestInfoModal,
  marketplaceAdmin,
  getOpportunityProfile,
  getUserInfo,
  getFeaturedOpportunities,
  getMostViewedOpportunities,
  getLatestOpportunities,
  getRelevantOpportunities,
  getOpportunitiesData,
  getBusinessNeeds,
  businessNeedModal,
  matchmakingMembers,
  viewAllOpportunities,
});
