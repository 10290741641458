import fetchResource from '@src/js/api/fetch-resource';

export function submitTemplateFormData(submitFormData) {
  return fetchResource('api/admin/templates', {
    method: 'POST',
    body: {
      ...submitFormData,
      membership: true,
    },
  });
}
