import fetchResource from '@src/js/api/fetch-resource';
import { parsePhoneNumber } from 'react-phone-number-input';

export function getCompanyMembers(slug, filters) {
  const params = new URLSearchParams(filters);
  return fetchResource(`api/companies/${ slug }/members?${ params }`, {
    method: 'GET',
  });
}

export function sendSignDocument(id, uuid) {
  return fetchResource('api/admin/signdocument', {
    method: 'POST',
    body: {
      userUuid: uuid,
      documentId: id,
    },
  });
}

export function signDocument(mobilePhone, uuid) {
  return fetchResource('api/signdocument', {
    method: 'POST',
    body: {
      mobilePhone: parsePhoneNumber(mobilePhone).nationalNumber,
      countryCode: parsePhoneNumber(mobilePhone).countryCallingCode,
      uuid,
    },
  });
}

export function getSignDocument(uuid) {
  return fetchResource(`api/signdocument/${ uuid }`, {
    method: 'GET',
  });
}
