import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@src/js/components/global';
import { Icons } from '@src/js/constants/icons';

const EmailSupport = ({ bottom }) => {
  const digitalSpecialist = useSelector(state => state.global?.digitalSpecialist?.email);
  if (!digitalSpecialist) {
    return null;
  }

  return bottom
    ? (
      <div className='compliance__buttons-group elite-white-box--form email-support__bottom'>
        <p><FormattedMessage id='onboarding.form.doubts' /></p>
        <a
          className={ 'm-0' }
          href={ `mailto:${ digitalSpecialist }` }
          rel='noreferrer'
          target='_blank'
        >
          <Button size='sm' emptyButtonType='empty-blue' buttonText='funding.questions.body' />
        </a>
      </div>
    ) : (
      <a
        className={ 'm-0 email-support__top' }
        href={ `mailto:${ digitalSpecialist }` }
        rel='noreferrer'
        target='_blank'
      >
        <img src={ Icons.Mail } alt='Mail' />
        <p><FormattedMessage id='onboarding.form.support' /></p>
      </a>
    );
};

EmailSupport.propTypes = {
  bottom: PropTypes.bool,
};

export default EmailSupport;
