import { InputSelect, InputText } from '@app/components/global/forms';
import InputImage from '@app/components/global/forms/InputImage';
import { UserForm } from '@app/components/public/user/UserProfile';
import { getUserFormCompanyOptions } from '@src/js/api/user/formOptions';
import { Images } from '@src/js/constants/images';
import { RootState } from '@src/js/store';
import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

type Props = {
  formType: string,
}

const UserBasicInfo = ({ formType }: Props) => {
  const {
    formState: { errors: formErrors }, register,
    control, watch,
  } = useFormContext<UserForm>();
  const { error } = useSelector<RootState, {error: FieldErrors<UserForm>}>(
    state => state[formType === 'my_profile' ? 'user' : 'adminUser']);

  const formatOptions = (v: { type: string, company: { slug: string, name: string }}) => (
    { type: v.type, value: v.company.slug, label: v.company.name });

  let errors = formErrors || {};

  if (error) {
    errors = {
      ...errors,
      ...Object.fromEntries(Object.entries(error).map(([key, message]) => [key, { message }])),
    };
  }

  return (
    <div>
      <InputImage
        name='avatar'
        required
        className='elite-form__full-row'
        url='api/image/user/avatar'
        defaultImage={ Images.UserAvatar }
      />
      <InputText
        name='firstName'
        placeholder='user.firstName'
        label='user.firstName'
        required
        disabled={ formType === 'my_profile' }
        register={ register }
        errors={ errors }
      />
      <InputText
        name='lastName'
        placeholder='user.lastName'
        label='user.lastName'
        required
        disabled={ formType === 'my_profile' }
        register={ register }
        errors={ errors }
      />
      { ((formType !== 'my_profile') || (formType === 'my_profile' && watch('company'))) && (
      <InputSelect
        name='company'
        label='user.companyOrPartner'
        loadAsyncOptions={ getUserFormCompanyOptions }
        required
        disabled={ formType === 'my_profile' }
        simpleValue={ formType !== 'my_profile' }
        control={ control }
        errors={ errors }
        formatOptions={ formatOptions }
      />
      ) }
      <InputText
        name='email'
        placeholder='user.email'
        label='user.email'
        type='email'
        required
        register={ register }
        errors={ errors }
      />
      <InputText
        name='jobTitle'
        placeholder='user.jobTitle'
        label='user.jobTitle'
        required
        register={ register }
        errors={ errors }
      />
      { (formType === 'my_profile') && (
      <div>
        <div className='row m-b-2 m-t-3' />
        <InputText
          name='currentPassword'
          placeholder='user.currentPassword'
          label='user.currentPassword'
          type='password'
          minLength={ 7 }
          register={ register }
          errors={ errors }
          required={ !!watch('newPassword') || !!watch('confirmedPassword') }
          autoComplete='off'
        />
        <InputText
          name='newPassword'
          placeholder='user.newPassword'
          label='user.newPassword'
          type='password'
          minLength={ 7 }
          register={ register }
          errors={ errors }
          autoComplete='off'
        />
        <InputText
          name='confirmedPassword'
          placeholder='user.confirmPassword'
          label='user.confirmPassword'
          type='password'
          minLength={ 7 }
          register={ register }
          errors={ errors }
          autoComplete='off'
        />
      </div>
      ) }
    </div>
  );
};

export default UserBasicInfo;
