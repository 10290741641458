import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import inputValidation from '@src/js/helpers/InputValidation';

import {
  downloadTemplateRequest, getTemplateRequest, sendTemplateRequest,
} from '@app/store/etinerary/actions';
import { useShallowSelector } from '@src/js/utils';
import {
  makeSelectData, makeSelectUser, makeSelectUploadFile,
} from '@app/store/etinerary/selectors';
import FieldsLegalRepresentative from './FieldsLegalRepresentative';

const LegalRepresentative = ({
  callbackFailed,
  footer,
  handleNext,
}) => {
  const dispatch = useDispatch();
  const data = useShallowSelector(makeSelectData);
  const user = useShallowSelector(makeSelectUser);
  const uploadFile = useShallowSelector(makeSelectUploadFile);

  const validate = values => {
    const errors = {};

    if (data?.docType) {
      errors.terms = inputValidation.composeValidators(
        inputValidation.requiredValidator,
        inputValidation.minLengthUploadValidator(1)
      )(values.termsFiles);
    }

    errors.checkTerms = inputValidation.composeValidators(
      inputValidation.requiredValidator
    )(values.checkTerms);

    errors.jobTitle = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.minLengthValidator(2),
      inputValidation.maxLengthValidator(50)
    )(values.jobTitle ?? user?.jobRoleName);

    return errors;
  };

  useEffect(() => {
    dispatch(getTemplateRequest());
  }, []);

  const handleFormSubmit = useCallback(formData => {
    let values = {
      ...formData,
      callbackSuccess: handleNext,
      callbackFailed,
    };
    if (data?.docType) {
      values = {
        ...values,
        name: uploadFile?.name,
        slug: uploadFile?.slug,
      };
    }
    dispatch(sendTemplateRequest(values));
  }, [uploadFile]);

  const downloadTemplate = () => dispatch(downloadTemplateRequest());
  return (
    <Form
      onSubmit={ values => handleFormSubmit?.({
        jobTitle: values?.jobTitle ?? user?.jobRoleName,
      }) }
      validate={ validate }
      mutators={ {
        setTerms: (args, state, utils) => utils.changeValue(state, 'termsFiles', () => args[0]),
        setIsFeatured: (args, state, utils) => utils.changeValue(state, 'isFeatured', () => args[0]),
      } }
      render={ ({
        handleSubmit, form, invalid,
      }) => (
        <form
          onSubmit={ handleSubmit }
          id='new_legal_user_form'
          role='presentation'
          className='etinerary-new-legar-user-form'
        >
          <FieldsLegalRepresentative
            data={ data }
            handleDowload={ downloadTemplate }
            mutators={ form.mutators }
            user={ user }
          />
          {footer(invalid)}
        </form>
      ) }
    />
  );
};

LegalRepresentative.propTypes = {
  callbackFailed: PropTypes.func.isRequired,
  footer: PropTypes.node.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default LegalRepresentative;
