import styled from 'styled-components';
import { Col as rsCol } from 'reactstrap';
import {
  Breakpoints, FontWeight, FontSize, ThemeColors, TruncateLine,
} from '@src/_v2/js/constants';

export const Status = styled.div`
  color: ${ ThemeColors.blue };
  font-size: ${ FontSize.medium };
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

export const TaskWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 5rem;
  background: transparent;
  box-shadow: none;
`;

export const EmptyList = styled.div`
  width: 100%;
  padding: 1.5rem;
  background: ${ ThemeColors.white };
  margin: 1rem 0;
  text-align: center;
  font-size: ${ FontSize.body };
`;

export const TaskList = styled.div`
  padding-right: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    padding: 0;
  }

  .pending {
    border-left: 0.6rem solid ${ ThemeColors.orange };

    ${ Status } {
      color: ${ ThemeColors.orange };
    }
  }

  .approved {
    border-left: 0.6rem solid ${ ThemeColors.green };

    ${ Status } {
      color: ${ ThemeColors.green };
    }
  }
`;

export const Title = styled(rsCol)`
  ${ TruncateLine };
  color: ${ ThemeColors.black };
  font-size: ${ FontSize.iconLarge };
  font-weight: ${ FontWeight.medium };
`;

export const CreatedDate = styled.div`
  margin-top: 0.7rem;
  font-size: ${ FontSize.subTitle };
  font-weight: ${ FontWeight.medium };
  color: ${ ThemeColors.mediumGrey };
`;

export const Task = styled.div`
  padding: 1.5rem 3.5rem;
  border: 0.1rem solid ${ ThemeColors.lightGrey };
  border-left: 0.6rem solid ${ ThemeColors.blue };
  background-color: initial;
  height: 9rem;
  margin: 1rem;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    height: auto;
    padding: 1.5rem;
    margin: 0.5rem 0.5rem 2rem;
  }
`;

export const ReadMoreContainer = styled(rsCol)`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  white-space: nowrap;
`;

export const TaskAction = styled(rsCol)`
  padding-top: 1.7rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 4rem;

  &.read-more {
    white-space: nowrap;
  }

  a.eop-btn.eop-btn-action-blue {
    padding: 0;
    font-size: ${ FontSize.caption };
    height: inherit;
    background-color: ${ ThemeColors.white };
    border: none;
    color: ${ ThemeColors.blue };
    white-space: normal;
  }
`;

export const TaskActions = styled.div`
  margin-right: -1.5rem;
  padding-top: 0.4rem;
  white-space: nowrap;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    display: none;
  }

  a.eop-btn.eop-btn-action-blue {
    ${ TruncateLine };
    margin-left: 2rem;
    padding: 0;
    font-size: ${ FontSize.caption };
    height: inherit;
    background-color: ${ ThemeColors.white };
    border: none;
    color: ${ ThemeColors.blue };

    &:first-child {
      width: 100%;
      text-align: left;
      display: inherit;
    }
  }
`;

export const TaskActionsMobile = styled.div`
  display: none;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: 1rem;
    border-top: 0.1rem solid ${ ThemeColors.lightGrey };
    height: auto;
    display: block;
    text-align: right;

    .row {
      margin-right: 0;
    }
  }
`;
