import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { get as lodashGet } from 'lodash';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { localeFormatNumber, stringToNumber } from '@app/components/global/forms/utils';
import ReactTooltip from 'react-tooltip';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import { financialsKeys } from '@app/store/onboarding/constants';
import { showToastrSuccess } from '@app/store/global/actions/index';
import TextInput from '@src/js/components/global/inputs/TextInput';
import inputValidation from '@src/js/helpers/InputValidation';
import { adminRouteCodes, routeCodes, withLang } from '@src/js/constants/routes';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import Button from '@src/js/components/global/buttons/Buttons';
import SelectBox from '@src/js/components/global/inputs/Selectbox';
import { editCompanyFinancials, updateCompanyFinancials } from '@src/js/actions/company/adminCompany';
import { Company } from '@src/js/constants/entities';
import { financialsCalculator } from './CompanyFinancialsFormDecorator';

const financialsFormCalculator = createDecorator(financialsCalculator);

class CompanyFormFinancials extends Component {
  static propTypes = {
    companyFinancialsFormOptionsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    companyFinancialsFormOptionsLoading: PropTypes.bool,
    companyFinancialsFormOptions: PropTypes.object,
    companyFinancialsData: PropTypes.object,
    companyFinancialsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    companyFinancialsLoading: PropTypes.bool,
    dispatch: PropTypes.func,
    companySlug: PropTypes.string,
    activeLanguage: PropTypes.string,
    translation: PropTypes.object,
    mine: PropTypes.bool,
  };

  componentDidMount() {
    const {
      companySlug,
      dispatch,
      companyFinancialsData,
      companyFinancialsFormOptions,
      companyFinancialsFormOptionsLoading,
      companyFinancialsFormOptionsError,
      companyFinancialsError,
      companyFinancialsLoading,
      activeLanguage,
    } = this.props;

    if ((!companyFinancialsData
      || companyFinancialsData.slug !== companySlug)
      && !companyFinancialsFormOptionsLoading
      && companyFinancialsFormOptions && Object.keys(companyFinancialsFormOptionsError).length === 0
      && !companyFinancialsError && !companyFinancialsLoading
    ) {
      dispatch(editCompanyFinancials(companySlug, activeLanguage));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeLanguage, dispatch, companySlug,
    } = this.props;
    if (activeLanguage !== prevProps.activeLanguage) {
      dispatch(editCompanyFinancials(companySlug, activeLanguage));
    }
  }

  formatFinancial = (companyFinancials) => {
    const data = [...companyFinancials];
    const financials = data.map(financial => {
      const formattedFinancial = financial;
      financialsKeys.forEach((key) => {
        formattedFinancial[key] = stringToNumber(formattedFinancial[key])?.toString() || null;
      });

      return formattedFinancial;
    });

    return financials;
  }

  handleSubmit = (values) => {
    const {
      dispatch,
      companySlug,
      activeLanguage,
    } = this.props;

    const financialData = this.formatFinancial(values.companyFinancials);

    const submitData = {
      lastFiscalMonth: (values.lastFiscalMonth && values.lastFiscalMonth.value)
        ? values.lastFiscalMonth.value
        : values.lastFiscalMonth,
      financialData,
    };

    return new Promise((resolve, reject) => {
      dispatch(updateCompanyFinancials({
        values: {
          companySlug,
          companyFinancials: submitData,
        },
        resolve,
        reject,
      }));
    }).then(() => {
      dispatch(editCompanyFinancials(companySlug, activeLanguage));
      dispatch(showToastrSuccess('notification.success_title', 'notification.company_form.edit.success_message'));
      return null;
    }).catch(errors => errors);
  };

  editCompanyUrl = () => {
    const { mine, companySlug } = this.props;
    if (mine) {
      return withLang(routeCodes.MY_COMPANY_EDIT);
    }
    return `${
      withLang(isAdminRole() || isPra() ? adminRouteCodes.COMPANIES : routeCodes.COMPANIES)
    }/${ companySlug }/edit`;
  }

  render() {
    const {
      companySlug,
      companyFinancialsFormOptions,
      companyFinancialsFormOptionsLoading,
      companyFinancialsLoading,
      companyFinancialsData,
      activeLanguage,
      translation,
      mine,
    } = this.props;

    const companyType = lodashGet(companyFinancialsFormOptions, 'results.companyType');

    if (![Company.TYPE_GROWTH, Company.TYPE_GROWTH_STARTUP].includes(companyType)) {
      return <Redirect to={ `${ withLang(routeCodes.COMPANIES) }/${ companySlug }` } />;
    }

    let financialsData = null;
    let currencies = [];
    if (companyFinancialsFormOptions) {
      currencies = companyFinancialsFormOptions.results.platformCurrencies;
    }
    if (companyFinancialsData && companyFinancialsData.companyFinancials.length > 0) {
      financialsData = { ...companyFinancialsData };
    }

    let fiscalMonthOptions = [];

    if (
      financialsData
      && companyFinancialsFormOptions
      && companyFinancialsFormOptions.results
      && companyFinancialsFormOptions.results.fiscalMonths
    ) {
      fiscalMonthOptions = companyFinancialsFormOptions.results.fiscalMonths
        .map((fiscalMonth) => ({ value: fiscalMonth.id, label: <FormattedMessage id={ fiscalMonth.label } /> }));
      financialsData.lastFiscalMonth = companyFinancialsData.lastFiscalMonth;
    }

    if (financialsData) financialsData.lang = activeLanguage;

    return (
      <Form
        onSubmit={ this.handleSubmit }
        mutators={ {
          setFinancialsCurrency: (args, state, utils) => {
            for (let i = 0; i < state.formState.values.companyFinancials.length; i++) {
              utils.changeValue(state, `companyFinancials[${ i }][currency]`, () => args[0].value);
            }
          },
        } }
        initialValues={ financialsData }
        decorators={ [financialsFormCalculator] }
        render={ ({ handleSubmit, form }) => (
          <form onSubmit={ handleSubmit } className='edit-company-financials-page edit-company-page__form'>
            { companyFinancialsLoading && !companyFinancialsFormOptionsLoading && <Loader /> }
            { !companyFinancialsLoading && !companyFinancialsFormOptionsLoading
              && (
                <div className='edit-company-nav-links'>
                  <NavLink
                    className='edit-financials uppercase'
                    exact
                    to={ this.editCompanyUrl() }
                  >
                    <button type='button' className='eop-btn'>
                      <span className='button-text'>
                        <FormattedMessage id='company_edit' />
                      </span>
                    </button>

                  </NavLink>
                  <div className='right-links d-flex'>
                    <NavLink
                      className='uppercase'
                      exact
                      to={ mine
                        ? withLang(routeCodes.MY_COMPANY_FINANCIALS)
                        : `${ withLang(routeCodes.COMPANIES) }/${ companySlug }/financials` }
                    >
                      <button type='button' className='eop-btn'>
                        <span className='button-text'>
                          <FormattedMessage id='company_view_financials' />
                        </span>
                      </button>
                    </NavLink>
                    <NavLink
                      className='uppercase'
                      exact
                      to={ mine
                        ? withLang(routeCodes.MY_COMPANY)
                        : `${ withLang(routeCodes.COMPANIES) }/${ companySlug }` }
                    >
                      <button type='button' className='eop-btn'>
                        <span className='button-text'>
                          <FormattedMessage id='company_view_profile' />
                        </span>
                      </button>
                    </NavLink>
                  </div>
                </div>
              ) }
            { financialsData
              && companyFinancialsFormOptions
              && !companyFinancialsLoading
              && !companyFinancialsFormOptionsLoading && (
                <div className='edit-company-financials-page edit-company-financials-page__section'>
                  <div className='row p-l-1 p-r-1 m-b-5'>
                    <div className='col-lg-12'>
                      <Field
                        name='lastFiscalMonth'
                        fieldName='lastFiscalMonth'
                        fieldType='text'
                        fieldLabel='company_form.lastFiscalMonth'
                        options={ fiscalMonthOptions }
                        component={ SelectBox }
                        additionalClass='w-100'
                      />
                    </div>
                  </div>
                  <div className='row p-l-1 p-r-1'>
                    <div className='col-lg-12 financials-table-wrapper'>
                      <Table className='financials-edit-table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Field
                                name='companyFinancials[1][currency]'
                                fieldName='companyFinancials[1][currency]'
                                options={ currencies }
                                component={ SelectBox }
                                onChange={ (value) => form.mutators.setFinancialsCurrency(value) }
                                fieldRequired
                                fieldNoClear
                                fieldNoSearch
                                simpleValue={ false }
                                additionalClass='w-70'
                              />
                              /000
                            </TableCell>
                            { financialsData.companyFinancials.filter(i => ![
                              'noEmployees', 'cashEquivalent', 'commercialCredit', 'currentLiabilities',
                            ].includes(i)).map((elem, key) => (
                              <TableCell key={ `header-${ financialsData.companyFinancials[key].year }` }>
                                <FormattedMessage id='company.financialsTable.annual' />
                                { ` ${ financialsData.companyFinancials[key].year }` }

                                <Field
                                  name={ `companyFinancials[${ key }][actual]` }
                                  fieldName={ `companyFinancials[${ key }][actual]` }
                                  fieldType='text'
                                  options={ [{ value: true, label: '(actual)' }, {
                                    value: false,
                                    label: '(expected)',
                                  }] }
                                  component={ SelectBox }
                                  fieldDisabled={
                                      ![
                                        (new Date()).getFullYear(),
                                        (new Date()).getFullYear() - 1,
                                      ].includes(financialsData.companyFinancials[key].year)
                                    }
                                  fieldRequired
                                  fieldNoClear
                                  fieldNoSearch
                                />
                              </TableCell>
                            )) }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { Object.keys(financialsData.companyFinancials[0])
                            .filter(i => {
                              const skippedRows = [
                                'currency',
                                'lastFiscalYear',
                                'year',
                                'actual',
                                'noEmployees',
                                'cashEquivalent',
                                'commercialCredit',
                                'currentLiabilities',
                              ];
                              return !skippedRows.includes(i);
                            })
                            .map(i => {
                              const percentRows = ['ebitPerc', 'ebitdaPerc', 'eMargin', 'nfpEbitda', 'capitalEmployed'];
                              return (
                                <TableRow key={ i } className={ i === 'id' ? 'hidden' : '' }>
                                  { i !== 'id' && (
                                    <TableCell>
                                      <FormattedMessage id={ `company.financialsTable.${ i }` } />
                                      { translation[`company.financialsTable.tooltip.${ i }`]
                                      && (
                                        <>
                                          <span
                                            className='icon-medium-Info font-size-icon m-l-1'
                                            data-tip
                                            data-for={ `${ i }-tooltip` }
                                          />
                                          <ReactTooltip
                                            id={ `${ i }-tooltip` }
                                            place='top'
                                            effect='solid'
                                            multiline={ true }
                                          >
                                            { translation[`company.financialsTable.tooltip.${ i }`] }
                                          </ReactTooltip>
                                        </>
                                      )}
                                    </TableCell>
                                  ) }
                                  { financialsData.companyFinancials.map((e, k) => (
                                    <TableCell key={ `cell-${ financialsData.companyFinancials[k].year }-${ i }` }>
                                      <Field
                                        name={ `companyFinancials[${ k }][${ i }]` }
                                        fieldName={ `companyFinancials[${ k }][${ i }]` }
                                        fieldType='text'
                                        component={ TextInput }
                                        validate={ percentRows.includes(i) ? '' : inputValidation.floatValidator }
                                        fieldDisabled={ percentRows.includes(i) }
                                        format={ value => localeFormatNumber(value, activeLanguage) }
                                        formatOnBlur
                                      />
                                    </TableCell>
                                  )) }
                                </TableRow>
                              );
                            }) }
                        </TableBody>
                      </Table>
                    </div>
                    <div className='m-t-1'>
                      <div className='col-lg-12'>
                        <Button
                          buttonType='submit'
                          buttonText='save'
                          additionalClass='m-r-2'
                        />
                      </div>
                    </div>
                  </div>
                </div>
            ) }
          </form>
        ) }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  companyFinancialsError: state.adminCompany.companyFinancialsError,
  companyFinancialsLoading: state.adminCompany.companyFinancialsLoading,
  companyFinancialsData: state.adminCompany.companyFinancialsData,
  companyFinancialsFormOptionsLoading: state.companyFormOptions.loadingFinancials,
  companyFinancialsFormOptionsError: state.companyFormOptions.errorFinancials,
  companyFinancialsFormOptions: state.companyFormOptions.companyFinancialsFormOptions,
  activeLanguage: state.initInfo.activeLanguage,
  translation: state.initInfo.localizationMessages?.[state.initInfo.activeLanguage],
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormFinancials);

export default withRouter(connectWrapper);
