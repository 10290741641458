import {
  ChatMessage, MessageBody, Peer, Thread,
} from '@app/models/Chat';
import { setCurrentThread } from '@app/store/chat/chatSlice';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchResource from '@src/js/api/fetch-resource';
import { RootState } from '@src/js/store';

export const CHAT_SCOPE = 'CHAT';

type RequestParams = {
  page: number,
  timestamp: number,
  search: string,
}

export const loadMessages = createAsyncThunk<ChatMessage[], { offset?: number, threadId: string }>(
  `${ CHAT_SCOPE }/loadMessages`,
  async ({ offset, threadId }, { dispatch }) => {
    try {
      const sp = { offset: offset?.toString() || '' };
      const query = new URLSearchParams(sp);
      const result = await fetchResource(`api/chat/threads/${ threadId }/messages?${ query }`, {
        method: 'GET',
      });
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const loadThreads = createAsyncThunk<Thread[], RequestParams>(
  `${ CHAT_SCOPE }/loadThreads`,
  async (params, { dispatch, getState }) => {
    try {
      const sp = {
        ...params,
        page: params.page.toString(),
        timestamp: params.timestamp.toString(),
      };
      const query = new URLSearchParams(sp);
      const result = await fetchResource(`api/chat/threads?${ query }`, {
        method: 'GET',
      });
      const { chat: { currentThreadId } } = getState() as RootState;
      if (result.length > 0 && !currentThreadId) {
        dispatch(setCurrentThread(result[0].id));
        dispatch(loadMessages({ threadId: result[0].id || '' }));
      }
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const unsubscribeChat = createAsyncThunk<Thread, { threadId: string }>(
  `${ CHAT_SCOPE }/unsubscribeChat`,
  async ({ threadId }, { dispatch }) => {
    try {
      const result = await fetchResource(`api/chat/threads/${ threadId }`, {
        method: 'DELETE',
      });
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const addMembersToChat = createAsyncThunk<Thread, { threadId?: string|null, users: Peer[] }>(
  `${ CHAT_SCOPE }/addMembersToChat`,
  async ({ threadId, users }, { dispatch }) => {
    try {
      const result = await fetchResource(`api/chat/threads/${ threadId }`, {
        method: 'PATCH',
        body: { participants: users.map(u => u.uuid) },
      });
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const sendMessage = createAsyncThunk<{thread: string},
  { threadId: string, body: MessageBody, toastr?: boolean }>(
    `${ CHAT_SCOPE }/sendMessage`,
    async ({ threadId, body, toastr }, { dispatch }) => {
      try {
        const result = await fetchResource(`api/chat/threads/${ threadId }/messages`, {
          method: 'POST',
          body,
        });
        if (toastr) {
          dispatch(showToastrSuccess());
        }
        return result;
      } catch (e: any) {
        dispatch(showToastrError('notification.error_title', e.response.body));
        throw e;
      }
    }
  );

export const setThreadSubject = createAsyncThunk<Thread, { threadId?: string|null, subject: string }>(
  `${ CHAT_SCOPE }/setThreadSubject`,
  async ({ threadId, subject }, { dispatch }) => {
    try {
      const result = await fetchResource(`api/chat/threads/${ threadId }`, {
        method: 'PATCH',
        body: { subject },
      });
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const loadUnreadThreads = createAsyncThunk<string[]>(
  `${ CHAT_SCOPE }/loadUnreadThreads`,
  async (_, { dispatch }) => {
    try {
      const result = await fetchResource('api/chat/threads/unread');
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const loadLatestThreads = createAsyncThunk<Thread[]>(
  `${ CHAT_SCOPE }/loadLatestThreads`,
  async (_, { dispatch }) => {
    try {
      const result = await fetchResource(`api/chat/threads?limit=4&timestamp=${ Date.now() }`);
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const loadSingleThread = createAsyncThunk<Thread, {threadId: string}>(
  `${ CHAT_SCOPE }/loadSingleThread`,
  async ({ threadId }, { dispatch }) => {
    try {
      const result = await fetchResource(`api/chat/threads/${ threadId }`);
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);

export const findCreateNewThread = createAsyncThunk<Thread, {participants: string[], message?: MessageBody}>(
  `${ CHAT_SCOPE }/findCreateNewThread`,
  async ({ participants, message }, { dispatch }) => {
    try {
      const result = await fetchResource('api/chat/threads', {
        method: 'POST',
        body: participants,
      });
      if (message) {
        dispatch(sendMessage({ threadId: result.id, body: message, toastr: true }));
      } else {
        dispatch(loadMessages({ threadId: result.id }));
      }
      return result;
    } catch (e) {
      dispatch(showToastrError());
      throw e;
    }
  }
);
