import {
  CREATE_TASK_START,
  CREATE_TASK_ERROR,
  CREATE_TASK_SUCCESS,
  GET_TASKS_ERROR,
  GET_TASKS_START,
  GET_TASKS_SUCCESS,
  GET_TASK_ERROR,
  GET_TASK_START,
  GET_TASK_SUCCESS,
  GET_TASK_COMMENTS_ERROR,
  GET_TASK_COMMENTS_START,
  GET_TASK_COMMENTS_SUCCESS,
  GET_TASKS_PERMISSIONS_START,
  GET_TASKS_PERMISSIONS_ERROR,
  GET_TASKS_PERMISSIONS_SUCCESS,
  SET_TASK_STATUS_START,
  SET_TASK_STATUS_ERROR,
  SET_TASK_STATUS_SUCCESS,
  GET_TASK_DOCUMENTS_START,
  GET_TASK_DOCUMENTS_ERROR,
  GET_TASK_DOCUMENTS_SUCCESS,
  ADD_TASK_DOCUMENT_START,
  ADD_TASK_DOCUMENT_ERROR,
  ADD_TASK_DOCUMENT_SUCCESS,
  ADD_TASK_COMMENT_START,
  ADD_TASK_COMMENT_ERROR,
  ADD_TASK_COMMENT_SUCCESS,
  REMOVE_TASK_DOCUMENT_START,
  REMOVE_TASK_DOCUMENT_ERROR,
  REMOVE_TASK_DOCUMENT_SUCCESS,
  EMPTY_TASK_DOCUMENTS,
  ADD_UPLOADED_DOCUMENT,
  GET_TASKS_HEADER_ERROR,
  GET_TASKS_HEADER_START,
  GET_TASKS_HEADER_SUCCESS,
  GET_COMPANIES_FOR_TASK_CREATION_START,
  GET_COMPANIES_FOR_TASK_CREATION_ERROR,
  GET_COMPANIES_FOR_TASK_CREATION_SUCCESS,
  GET_COMPANIES_SLUG_FOR_TASK_CREATION_START,
  GET_COMPANIES_SLUG_FOR_TASK_CREATION_ERROR,
  GET_COMPANIES_SLUG_FOR_TASK_CREATION_SUCCESS,
  SAVE_COMPANIES_SLUGS,
  EMPTY_COMPANIES_SLUGS,
  GET_TASK_COMPANIES_LIST_START,
  GET_TASK_COMPANIES_LIST_ERROR,
  GET_TASK_COMPANIES_LIST_SUCCESS,
  CLEAR_TASK_LIST,
} from '@src/js/actions/tasks/task';

const initialState = {
  loading: false,
  error: null,
  task: null,
  taskListLoading: true,
  taskListError: null,
  taskList: null,
  taskDocuments: [],
  uploadedFilesSlugs: [],
  taskDocumentsError: null,
  taskDocumentsLoading: false,
  taskListHasMore: true,
  permissionsLoading: false,
  permissionsError: null,
  permissions: null,
  statusLoading: false,
  statusError: null,
  status: null,
  taskDataLoading: false,
  taskDataError: null,
  taskData: null,
  uploadedDocuments: [],
  initialHeaderData: null,
  initialHeaderDataError: null,
  initialHeaderDataLoading: false,
  commentsLoading: false,
  commentsError: null,
  comments: null,
  addCommentLoading: false,
  addCommentError: null,
  addComment: null,
  taskCompanies: null,
  taskCompaniesError: null,
  taskCompaniesLoading: false,
  taskCompaniesSlugs: [],
  taskCompaniesSlugsError: null,
  taskCompaniesSlugsLoading: false,
  selectedCompanySlugs: [],
  hasMoreCompanies: true,
};

const actionsMap = {
  [ADD_UPLOADED_DOCUMENT]: (state, action) => {
    const uploadedDocuments = [...state.uploadedDocuments, action.uploadedFileId];

    return {
      ...state,
      uploadedDocuments,
    };
  },
  [SAVE_COMPANIES_SLUGS]: (state, action) => ({
    ...state,
    selectedCompanySlugs: action.companySlugs,
  }),
  [EMPTY_COMPANIES_SLUGS]: (state) => ({
    ...state,
    selectedCompanySlugs: [],
  }),
  [GET_TASK_COMPANIES_LIST_START]: (state) => ({
    ...state,
    selectedTaskCompanies: null,
    selectedTaskCompaniesLoading: true,
    selectedTaskCompaniesError: null,
  }),
  [GET_TASK_COMPANIES_LIST_ERROR]: (state, action) => ({
    ...state,
    selectedTaskCompanies: null,
    selectedTaskCompaniesLoading: false,
    selectedTaskCompaniesError: action.error,
  }),
  [GET_TASK_COMPANIES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    selectedTaskCompanies: action.data,
    selectedTaskCompaniesLoading: false,
    selectedTaskCompaniesError: null,
    hasMoreCompanies: action.data && action.data.length > 0,
  }),
  [GET_COMPANIES_FOR_TASK_CREATION_START]: (state) => ({
    ...state,
    taskCompanies: null,
    taskCompaniesLoading: true,
    taskCompaniesError: null,
  }),
  [GET_COMPANIES_FOR_TASK_CREATION_ERROR]: (state, action) => ({
    ...state,
    taskCompanies: null,
    taskCompaniesLoading: false,
    taskCompaniesError: action.error,
  }),
  [GET_COMPANIES_FOR_TASK_CREATION_SUCCESS]: (state, action) => ({
    ...state,
    taskCompanies: action.data,
    taskCompaniesLoading: false,
    taskCompaniesError: null,
  }),
  [GET_COMPANIES_SLUG_FOR_TASK_CREATION_START]: (state) => ({
    ...state,
    taskCompaniesSlugs: [],
    taskCompaniesSlugsLoading: true,
    taskCompaniesSlugsError: null,
  }),
  [GET_COMPANIES_SLUG_FOR_TASK_CREATION_ERROR]: (state, action) => ({
    ...state,
    taskCompaniesSlugs: [],
    taskCompaniesSlugsLoading: false,
    taskCompaniesSlugsError: action.error,
  }),
  [GET_COMPANIES_SLUG_FOR_TASK_CREATION_SUCCESS]: (state, action) => ({
    ...state,
    taskCompaniesSlugs: action.data,
    taskCompaniesSlugsLoading: false,
    taskCompaniesSlugsError: null,
  }),
  [GET_TASK_DOCUMENTS_START]: (state) => ({
    ...state,
    taskDocuments: [],
    uploadedFilesSlugs: [],
    taskDocumentsLoading: true,
    error: null,
  }),
  [GET_TASK_DOCUMENTS_ERROR]: (state, action) => ({
    ...state,
    taskDocumentsLoading: false,
    error: action.error,
  }),
  [GET_TASK_DOCUMENTS_SUCCESS]: (state, action) => ({
    ...state,
    taskDocumentsLoading: false,
    error: null,
    taskDocuments: action.data,
  }),
  [ADD_TASK_DOCUMENT_START]: (state) => ({
    ...state,
    taskDocumentsLoading: true,
    error: null,
    uploadedFilesSlugs: [],
  }),
  [ADD_TASK_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    taskDocumentsLoading: false,
    error: action.error,
    uploadedFilesSlugs: [],
  }),
  [ADD_TASK_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    taskDocumentsLoading: false,
    error: null,
    uploadedFilesSlugs: action.data,
  }),
  [ADD_TASK_COMMENT_START]: (state) => ({
    ...state,
    addCommentLoading: true,
    addCommentError: null,
    addComment: null,
  }),
  [ADD_TASK_COMMENT_ERROR]: (state, action) => ({
    ...state,
    addCommentLoading: false,
    addCommentError: action.error,
    addComment: null,
  }),
  [ADD_TASK_COMMENT_SUCCESS]: (state, action) => ({
    ...state,
    addCommentLoading: false,
    addCommentError: null,
    addComment: action.data,
  }),
  [EMPTY_TASK_DOCUMENTS]: (state) => ({
    ...state,
    taskDocumentsLoading: false,
    error: null,
    taskDocuments: [],
  }),
  [REMOVE_TASK_DOCUMENT_START]: (state) => ({
    ...state,
    taskDocumentsLoading: true,
    error: null,
  }),
  [REMOVE_TASK_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    taskDocumentsLoading: false,
    error: action.error,
  }),
  [REMOVE_TASK_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    taskDocumentsLoading: false,
    error: null,
    taskDocuments: state.taskDocuments.filter(document => document.id !== action.data.removed),
  }),
  [CREATE_TASK_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [CREATE_TASK_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [CREATE_TASK_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    task: action.data,
  }),
  [GET_TASKS_START]: (state) => ({
    ...state,
    taskListLoading: true,
    taskListError: null,
  }),
  [GET_TASKS_ERROR]: (state, action) => ({
    ...state,
    taskListLoading: false,
    taskListHasMore: false,
    taskListError: action.error,
  }),
  [GET_TASKS_SUCCESS]: (state, action) => ({
    ...state,
    taskListLoading: false,
    taskListError: null,
    taskList: action.data,
    taskListHasMore: action.data.length > 0,
  }),
  [GET_TASK_START]: (state) => ({
    ...state,
    taskDataLoading: true,
    taskDataError: null,
  }),
  [GET_TASK_ERROR]: (state, action) => ({
    ...state,
    taskDataLoading: false,
    taskDataError: action.error,
  }),
  [GET_TASK_SUCCESS]: (state, action) => ({
    ...state,
    taskDataLoading: false,
    taskDataError: null,
    taskData: action.data,
  }),
  [GET_TASKS_PERMISSIONS_START]: (state) => ({
    ...state,
    permissionsLoading: true,
    permissionsError: null,
  }),
  [GET_TASKS_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    permissionsLoading: false,
    permissionsError: action.error,
  }),
  [GET_TASKS_PERMISSIONS_SUCCESS]: (state, action) => ({
    ...state,
    permissionsLoading: false,
    permissionsError: null,
    permissions: action.data,
  }),
  [SET_TASK_STATUS_START]: (state) => ({
    ...state,
    statusLoading: true,
    statusError: null,
  }),
  [SET_TASK_STATUS_ERROR]: (state, action) => ({
    ...state,
    statusLoading: false,
    statusError: action.error,
  }),
  [SET_TASK_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    statusLoading: false,
    statusError: null,
    status: action.data,
  }),
  [GET_TASKS_HEADER_START]: (state) => ({
    ...state,
    initialHeaderDataLoading: true,
    initialHeaderDataError: null,
  }),
  [GET_TASKS_HEADER_ERROR]: (state, action) => ({
    ...state,
    initialHeaderDataLoading: false,
    initialHeaderDataError: action.error,
  }),
  [GET_TASKS_HEADER_SUCCESS]: (state, action) => ({
    ...state,
    initialHeaderDataLoading: false,
    initialHeaderDataError: null,
    initialHeaderData: action.data,
  }),
  [GET_TASK_COMMENTS_START]: (state) => ({
    ...state,
    commentsLoading: true,
    commentsError: null,
  }),
  [GET_TASK_COMMENTS_ERROR]: (state, action) => ({
    ...state,
    commentsLoading: false,
    commentsError: action.error,
  }),
  [GET_TASK_COMMENTS_SUCCESS]: (state, action) => ({
    ...state,
    commentsLoading: false,
    commentsError: null,
    comments: action.data,
  }),
  [CLEAR_TASK_LIST]: () => initialState,
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
