import { ModalTypes } from '@app/components/global/ModalComponents';
import { useQuery } from '@src/js/helpers/hooks';
import { clearFilters } from '@src/js/marketplace/actions/businessNeeds';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import BusinessNeedItem from './BusinessNeedItem';

const BusinessNeedBlock = ({
  businessNeeds, isAdmin, userId, noBox,
}) => {
  const [modalViewed, setModalViewed] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();
  const loading = useSelector(state => state.marketplaceReducers.getBusinessNeeds.loading);
  const { showModal } = useGlobalModalContext();

  const toggleBusinessNeedModal = (businessNeed) => {
    if (!businessNeed) {
      setModalViewed(true);
    }
    showModal({
      type: ModalTypes.BusinessNeed,
      title: '',
      config: { size: 'lg', scrollable: true },
      onTop: true,
      data: {
        businessNeed, isAdmin, userId,
      },
    });
    dispatch(clearFilters());
  };

  useEffect(() => {
    const initModal = query.get('init') !== null;

    if (initModal) {
      toggleBusinessNeedModal();
      history.push(pathname);
      setModalViewed(true);
    }
  }, []);

  useEffect(() => {
    if (businessNeeds?.length === 0 && !modalViewed && !loading && !isAdmin) {
      toggleBusinessNeedModal();
    }
  }, [businessNeeds]);

  return (
    <div className={ `${ noBox ? 'businessNeed-widget--no-box m-y-3' : 'businessNeed-widget' }` }>
      <div className='businessNeed-widget__heading'>
        { !isAdmin && <FormattedMessage id='marketplace.businessNeeds.myBusinessNeeds' /> }
      </div>
      <div className='businessNeed-widget__add'>
        <span
          role='button'
          tabIndex='0'
          onClick={ () => toggleBusinessNeedModal() }
          onKeyUp={ () => toggleBusinessNeedModal() }
        >
          <FormattedMessage id='marketplace.businessNeeds.newBusinessNeed' />
        </span>
      </div>
      { businessNeeds && businessNeeds.length > 0
          && businessNeeds.map((businessNeed, index) => (
            <BusinessNeedItem
              key={ businessNeed.Id }
              index={ index }
              businessNeed={ businessNeed }
              toggleBusinessNeedModal={ toggleBusinessNeedModal }
              isAdmin={ isAdmin }
              userId={ userId }
            />
          ))
        }
    </div>
  );
};

BusinessNeedBlock.propTypes = {
  businessNeeds: PropTypes.array,
  noBox: PropTypes.bool,
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
};

export default BusinessNeedBlock;
