import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { get as lodashGet } from 'lodash';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import * as PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import {
  getLanguages, isAdminRole, isPra,
} from '@src/js/helpers/helpers';
import LanguageSelect from '@src/js/layouts/global/panels/form/LanguageSelect';
import { objectValue } from '@app/helpers/objectValue';
import CompanyFormCertificates from '@src/js/layouts/forms/company/components/CompanyFormCertificates';
import RichTextField from '@app/components/global/forms/RichTextField';
import { getCompanyFormOptions, clearCompanyFormOptions } from '@src/js/actions/company/companyFormOptions';
import { routeCodes, withLang } from '@src/js/constants/routes';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import {
  Checkbox, Datepicker, Selectbox, TextArea, TextInput,
} from '@src/js/components/global/inputs';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import MultiDropDown from '@src/js/components/global/inputs/MultiDropDown';
import { Company, Language } from '@src/js/constants/entities';
import ErrorHandler from '@src/js/views/user/pages/ErrorHandler';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import { getProvinces, getRegions } from '@src/js/actions/taxonomy/countries';
import TranslateButton from '@src/js/layouts/global/panels/form/TranslateButton';
import * as CommonService from './services/CommonService';
import * as CompanyFormService from './services/CompanyFormService';
import {
  CompanyFormBasicInfo, CompanyFormGeneralInfo, CompanyFormMembers, CompanyFormTurnoverFields, FormSubmit,
  CompanyFormShareholders, CompanyFormSlides, CompanyFormTestimonial, CompanyFormTopManagement, CompanyFormProgramInfo,
} from './components';

export const DEFAULT_LANGUAGE = 'en';

class CompanyForm extends Component {
  static propTypes = {
    intl: PropTypes.object,
    companyFormOptionsLoading: PropTypes.bool,
    companyFormOptionsError: PropTypes.object,
    companyFormOptions: PropTypes.object,
    dispatch: PropTypes.func,
    companySlug: PropTypes.string,
    userType: PropTypes.number.isRequired,
    location: PropTypes.object,
    activeLanguage: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      formType: props.companySlug ? 'edit' : 'create',
      companyType: null,
      companyData: null,
      companyLogoPreview: null,
      companyTestimonialImagePreview: null,
      isImageUploading: null,
      isTestimonialImageUploading: null,
      ateco: null,
      redirectTo: null,
      dateMemberSince: null,
      dateIncorporation: null,
      checkedSalesArea: [],
      checkedOfficeLocation: [],
      expandedSalesArea: [],
      expandedOfficeLocation: [],
      languageCode: props.activeLanguage,
    };
  }

  componentDidMount() {
    const { dispatch, companySlug, userType } = this.props;

    dispatch(getCompanyFormOptions(companySlug, userType));
    dispatch(getRegions(Language.IT));
    dispatch(getProvinces(Language.IT));
  }

  componentDidUpdate(prevProps) {
    CommonService.receiveFormOptions(this, prevProps);
    CommonService.manageRedirection(this, prevProps);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearCompanyFormOptions());
  }

  isDisabled = (fieldName) => {
    const { companyFormOptions } = this.props;

    return !!objectValue(companyFormOptions?.results?.disabledFields, fieldName);
  };

  setLogoPreview = (image) => {
    this.setState({
      companyLogoPreview: image ? { ...image, preview: URL.createObjectURL(image) } : null,
    });
  };

  setTestimonialImagePreview = (image) => {
    this.setState({
      companyTestimonialImagePreview: image ? { ...image, preview: URL.createObjectURL(image) } : null,
    });
  };

  setLogoUploadingStatus = (isUploading) => {
    this.setState({
      isImageUploading: isUploading,
    });
  };

  setTestimonialImageUploadingStatus = (isUploading) => {
    this.setState({
      isTestimonialImageUploading: isUploading,
    });
  };

  setLanguageCode = (lang) => {
    this.setState({
      languageCode: lang,
    });
  }

  handleChangeType = (option) => {
    this.setState({
      companyType: option,
    });
  };

  handleChangeDateMemberSince = (date) => {
    this.setState({
      dateMemberSince: date,
    });
  };

  handleChangeDateIncorporation = (date) => {
    this.setState({
      dateIncorporation: date,
    });
  };

  getThirdPartyInvestors = () => {
    const { companyFormOptions } = this.props;
    return lodashGet(companyFormOptions, 'results.thirdPartyInvestors') || [];
  };

  getFundraisingSources = () => {
    const { companyFormOptions } = this.props;

    return companyFormOptions && companyFormOptions.results.fundraisingSource
      ? companyFormOptions.results.fundraisingSource
      : [];
  };

  getSector = () => {
    const { companyFormOptions } = this.props;
    return lodashGet(companyFormOptions, 'results.company.basic.sector')
      || lodashGet(companyFormOptions, 'results.company.basic.secondLevelSector');
  };

  handleSectorByLevel = (form, value, name) => {
    form.mutators.setSecondLevelSector(null);
    form.mutators.setSector(null);
    form.change(name, value);
  };

  isProgrammeItaly = (programme) => {
    const { companyFormOptions } = this.props;

    if (!programme) return false;

    const option = companyFormOptions?.results?.programmes?.find(p => p.name === 'Italy');
    return programme === option?.id;
  }

  getTranslatedOptions = (values) => {
    const { intl } = this.props;
    const options = [];
    if (values.details?.it?.description) {
      options.push({ value: 'it', label: intl.formatMessage({ id: 'lang_it' }), quote: values.details.it.description });
    }
    if (values.details?.fr?.description) {
      options.push({ value: 'fr', label: intl.formatMessage({ id: 'lang_fr' }), quote: values.details.fr.description });
    }
    if (values.details?.pt?.description) {
      options.push({ value: 'pt', label: intl.formatMessage({ id: 'lang_pt' }), quote: values.details.pt.description });
    }
    return options;
  }

  renderBusinessDescription = (form, languageCode, languages) => {
    const { companyFormOptions } = this.props;
    const {
      companyType, dateIncorporation, checkedSalesArea, expandedSalesArea,
      checkedOfficeLocation, expandedOfficeLocation,
    } = this.state;
    const isGrowth = (companyType === Company.TYPE_GROWTH);
    const isGrowthStartup = (companyType === Company.TYPE_GROWTH_STARTUP);
    const isEtinerary = (companyType === Company.TYPE_ETINERARY);
    return (
      <div className='edit-company-page edit-company-page__section'>
        <Row>
          <Col>
            <div className='edit-company-page__title edit-user-page-tab-content-title'>
              <FormattedMessage id='company_form.businessDescription' />
            </div>
            { (isGrowth || isGrowthStartup) && (
              <>
                <Field
                  name='basic.dateIncorporation'
                  fieldLabel='company_form.dateIncorporation'
                  selected={ dateIncorporation }
                  onChange={ this.handleChangeDateIncorporation }
                  component={ Datepicker }
                />
                <div id='businessLine'>
                  <LanguageSelect
                    fieldLabel='company_form.businessLine'
                    lang={ languageCode }
                    setLang={ (el) => this.setLanguageCode(el) }
                    tooltip='company_form.tooltip'
                  />
                  {languages.map(lang => (
                    <div
                      key={ `${ lang }-businessLine` }
                    >
                      <RichTextField
                        name={ `details.${ lang }.businessLine` }
                        maxLength={ 5000 }
                        hidden={ lang !== languageCode }
                      />
                    </div>
                  ))}
                </div>
                <div id='distinctiveFactors'>
                  <LanguageSelect
                    fieldLabel='company_form.distinctiveFactors'
                    lang={ languageCode }
                    setLang={ (el) => this.setLanguageCode(el) }
                    tooltip='company_form.tooltip'
                  />
                  {languages.map(lang => (
                    <div
                      key={ `${ lang }-distinctiveFactors` }
                    >
                      <RichTextField
                        name={ `details.${ lang }.distinctiveFactors` }
                        maxLength={ 5000 }
                        hidden={ lang !== languageCode }
                      />
                    </div>
                  ))}
                </div>
                <div id='referenceSectors'>
                  <LanguageSelect
                    fieldLabel='company_form.referenceSectors'
                    lang={ languageCode }
                    setLang={ (el) => this.setLanguageCode(el) }
                    tooltip='company_form.tooltip'
                  />
                  {languages.map(lang => (
                    <div
                      key={ `${ lang }-referenceSectors` }
                    >
                      <RichTextField
                        name={ `details.${ lang }.referenceSectors` }
                        maxLength={ 5000 }
                        hidden={ lang !== languageCode }
                      />
                    </div>
                  ))}
                </div>
                <div id='strategy'>
                  <LanguageSelect
                    fieldLabel='company_form.strategy'
                    lang={ languageCode }
                    setLang={ (el) => this.setLanguageCode(el) }
                    tooltip='company_form.tooltip'
                  />
                  {languages.map(lang => (
                    <div
                      key={ `${ lang }-strategy` }
                    >
                      <RichTextField
                        name={ `details.${ lang }.strategy` }
                        maxLength={ 5000 }
                        hidden={ lang !== languageCode }
                      />
                    </div>
                  ))}
                </div>
                { !isGrowthStartup && (
                  <Field
                    name='basic.thirdPartyInvestors'
                    fieldName='basic.thirdPartyInvestors'
                    fieldType='text'
                    fieldLabel='company_form.thirdPartyInvestors'
                    options={ this.getThirdPartyInvestors().map(investor => (
                      { value: investor.value, label: <FormattedMessage id={ investor.label } /> }))
                    }
                    component={ Selectbox }
                  />
                ) }
                { isGrowthStartup && (
                  <Field
                    name='basic.fundraisingSource'
                    fieldName='basic.fundraisingSource'
                    fieldType='text'
                    fieldLabel='company_form.fundraisingSource'
                    options={ this.getFundraisingSources().map(investor => (
                      { value: investor.value, label: <FormattedMessage id={ investor.label } /> }))
                    }
                    component={ Selectbox }
                  />
                )}

                { isGrowth && (
                  <>
                    <Field
                      name='basic.auditorName'
                      fieldName='basic.auditorName'
                      fieldType='text'
                      fieldLabel='company_form.auditorName'
                      fieldMaxLength={ 200 }
                      component={ TextInput }
                    />
                    <Field
                      name='basic.auditorWebsite'
                      fieldName='basic.auditorWebsite'
                      fieldType='text'
                      fieldLabel='company_form.auditorWebsite'
                      fieldMaxLength={ 200 }
                      component={ TextInput }
                    />
                    <Field
                      name='basic.customerNumber'
                      fieldName='basic.customerNumber'
                      fieldType='text'
                      fieldLabel='company_form.customerNumber'
                      fieldMaxLength={ 200 }
                      component={ TextInput }
                    />
                    <Field
                      name='basic.bondIssue'
                      fieldName='basic.bondIssue'
                      type='checkbox'
                      fieldLabel='company_form.bondIssue'
                      component={ Checkbox }
                    />
                  </>

                ) }
                <Field
                  name='basic.employeeNumber'
                  fieldName='basic.employeeNumber'
                  fieldType='text'
                  fieldLabel='company_form.employeeNumber'
                  fieldMaxLength={ 200 }
                  component={ TextInput }
                />
                { isGrowthStartup && (
                  <Field
                    name='basic.investmentStage'
                    fieldName='basic.investmentStage'
                    type='checkbox'
                    fieldLabel='company_form.investmentStage'
                    component={ Checkbox }
                  />
                )}
              </>
            ) }
            { (isGrowth || isGrowthStartup || isEtinerary) && (
              <CompanyFormTurnoverFields
                isEtinerary={ isEtinerary }
              />
            ) }
            { (isGrowth || isGrowthStartup) && (
              <>
                <div className='eop-text-textarea__label'>
                  <FormattedMessage id='company_form.salesArea' />
                </div>
                { companyFormOptions && companyFormOptions.results.locations && (
                  <CheckboxTree
                    name='salesArea'
                    nodes={ companyFormOptions.results.locations }
                    checked={ checkedSalesArea }
                    expanded={ expandedSalesArea }
                    onCheck={ value => this.setState({ checkedSalesArea: value }) }
                    onExpand={ value => this.setState({ expandedSalesArea: value }) }
                  />
                ) }
                <div className='eop-text-textarea__label'>
                  <FormattedMessage id='company_form.officeLocation' />
                </div>
                { companyFormOptions && companyFormOptions.results.locations && (
                  <CheckboxTree
                    name='officeLocation'
                    nodes={ companyFormOptions.results.locations }
                    checked={ checkedOfficeLocation }
                    expanded={ expandedOfficeLocation }
                    onCheck={ value => this.setState({ checkedOfficeLocation: value }) }
                    onExpand={ value => this.setState({ expandedOfficeLocation: value }) }
                  />
                ) }
              </>
            ) }
            { companyFormOptions && (
              <MultiDropDown
                name='basic.sectors'
                labels={ ['company_form.sectors'] }
                requiredLevels={ (isGrowth || isGrowthStartup || isEtinerary) ? 2 : 0 }
                defaultValue={ this.getSector() }
                valueOptions={ companyFormOptions.results.sectors }
                setValue={ (value, name) => this.handleSectorByLevel(form, value, name) }
                maxLevel={ 4 }
                form={ form }
              />
            ) }
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const {
      companyFormOptionsLoading, companyFormOptionsError, companyFormOptions, companySlug, location,
    } = this.props;
    const {
      formType, companyType, companyData, companyLogoPreview, companyTestimonialImagePreview,
      redirectTo, dateMemberSince, isImageUploading, isTestimonialImageUploading, languageCode, ateco,
    } = this.state;
    const languages = getLanguages();
    const isGrowth = (companyType === Company.TYPE_GROWTH);
    const isGrowthStartup = (companyType === Company.TYPE_GROWTH_STARTUP);
    const isEtinerary = (companyType === Company.TYPE_ETINERARY);
    const isInvestor = (companyType === Company.TYPE_INSTITUTIONAL_INVESTOR);
    const isBroker = (companyType === Company.TYPE_INVESTOR_RELATOR);
    if (redirectTo) {
      return <Redirect to={ redirectTo } />;
    }
    if (companyFormOptionsError && companyFormOptionsError.status) {
      return <ErrorHandler error={ companyFormOptionsError } />;
    }
    if (companyFormOptionsLoading) {
      return <Loader />;
    }

    return (
      <Form
        validate={ (values) => CompanyFormService.validate(this, values) }
        onSubmit={ (company) => CompanyFormService.handleSubmit(this, company) }
        mutators={ CompanyFormService.formMutators() }
        initialValues={ companyData }
        render={ ({ handleSubmit, form, values }) => (
          <form onSubmit={ handleSubmit } className='edit-company-page edit-company-page__form'>
            <MetaTitle title={ formType === 'create' ? 'create_company' : 'edit_company' } />
            { form.getState().submitting && <Loader /> }
            <div className='edit-company-nav-links'>
              { companySlug && (isGrowth || isGrowthStartup) ? (
                <NavLink
                  className='edit-financials uppercase'
                  exact
                  to={ {
                    pathname: `${ withLang(routeCodes.EDIT_COMPANIES) }/${ companySlug }/financials`,
                    admin: location.pathname.includes('/admin/'),
                  } }
                >
                  <button type='button' className='eop-btn'>
                    <span className='button-text'>
                      <FormattedMessage id='company_edit_financials' />
                    </span>
                  </button>
                </NavLink>
              ) : <Col /> }
              <div className='right-links d-flex'>
                { companySlug && (isGrowth || isGrowthStartup) && (
                  <NavLink
                    className='uppercase'
                    exact
                    to={ `${ withLang(routeCodes.COMPANIES) }/${ companySlug }/financials` }
                  >
                    <button type='button' className='eop-btn'>
                      <span className='button-text'>
                        <FormattedMessage id='company_view_financials' />
                      </span>
                    </button>
                  </NavLink>
                ) }
                { formType === 'edit' && (
                  <NavLink
                    className='uppercase'
                    exact
                    to={ `${ withLang(routeCodes.COMPANIES) }/${ companySlug }` }
                  >
                    <button type='button' className='eop-btn'>
                      <span className='button-text'>
                        <FormattedMessage id='company_view_profile' />
                      </span>
                    </button>
                  </NavLink>
                ) }
              </div>
            </div>

            <CompanyFormBasicInfo
              formMutators={ form.mutators }
              handleChangeType={ this.handleChangeType }
              isImageUploading={ isImageUploading }
              setLogoPreviewFunc={ this.setLogoPreview }
              setLogoUploadingStatusFunc={ this.setLogoUploadingStatus }
              companyType={ companyType }
              companyLogoPreview={ companyLogoPreview }
              companyId={ lodashGet(companyFormOptions, 'results.company.companyId') }
              referrerUrl={ lodashGet(companyFormOptions, 'results.company.referrerUrl') }
              isDisabledFunc={ this.isDisabled }
            />
            <hr />

            <div className='edit-company-page edit-company-page__section'>
              <Row>
                <Col>
                  <div className='edit-company-page__title edit-user-page-tab-content-title'>
                    <FormattedMessage id='company_form.introduction' />
                  </div>
                </Col>
              </Row>

              <CompanyFormSlides
                entity='company'
                formMutators={ form.mutators }
                languageCode={ languageCode }
              />

              <Row>
                <Col id='description' className='description__language'>
                  <div className='description__language-wrapper'>
                    <LanguageSelect
                      additionalClass='description__language-selector'
                      fieldLabel='company_form.description'
                      lang={ languageCode }
                      setLang={ (el) => this.setLanguageCode(el) }
                      tooltip='company_form.tooltip'
                      fieldRequired={ languageCode === DEFAULT_LANGUAGE }
                    />
                    {
                    (values.details?.it?.description
                      || values.details?.fr?.description
                      || values.details?.pt?.description
                    ) && languageCode === DEFAULT_LANGUAGE && (
                      <TranslateButton
                        key={ `${ values.details?.it?.description }${
                          values.details?.fr?.description }${ values.details?.pt?.description }` }
                        outputLang='en'
                        onTranslate={ form.mutators.setTranslatedDescription }
                        options={ this.getTranslatedOptions(values) }
                      />
                    ) }
                  </div>
                  {languages.map(lang => (
                    <div
                      key={ `${ lang }-description` }
                    >
                      <RichTextField
                        name={ `details.${ lang }.description` }
                        maxLength={ 5000 }
                        hidden={ lang !== languageCode }
                      />
                    </div>
                  ))}
                </Col>
              </Row>

              { ((isGrowth || isGrowthStartup) && (isAdminRole() || isPra())) && (
              <Row>
                <Col id='tags'>
                  <LanguageSelect
                    fieldLabel='company_form.tags'
                    lang={ languageCode }
                    setLang={ (el) => this.setLanguageCode(el) }
                  />
                  {languages.map(lang => (
                    <div
                      key={ `${ lang }-tags` }
                    >
                      { lang !== languageCode
                        ? (
                          <Field
                            name={ `details.${ lang }.tags` }
                            render={ () => null }
                          />
                        )
                        : (
                          <Field
                            name={ `details.${ languageCode }.tags` }
                            fieldName={ `details.${ languageCode }.tags` }
                            component={ TextArea }
                            onContentUpdate={ form.mutators.setTags }
                            className='edit-company-page__tags'
                          />
                        )}
                    </div>
                  ))}
                </Col>
              </Row>
              )}
            </div>
            <hr />

            { (isGrowth || isGrowthStartup || isEtinerary) && (
            <>
              <CompanyFormMembers
                members={ values.members }
                isDisabledFunc={ this.isDisabled }
              />
              <hr />
            </>
            ) }

            <CompanyFormGeneralInfo
              isGrowth={ isGrowth }
              isGrowthStartup={ isGrowthStartup }
              isInvestor={ isInvestor }
              isBroker={ isBroker }
              isEtinerary={ isEtinerary }
              isProgrammeItaly={ this.isProgrammeItaly(values?.basic?.programme) }
              ateco={ ateco }
              formValues={ values }
              formMutators={ form.mutators }
            />
            <hr />
            { ((isGrowth || isGrowthStartup) && (isAdminRole() || isPra())) && (
              <>
                <CompanyFormCertificates />
                <hr />
              </>
            )}
            { (isGrowth || isGrowthStartup) && (
            <>
              <CompanyFormProgramInfo
                companyType={ companyType }
                selectedMemberSince={ dateMemberSince }
                handleMemberSince={ this.handleChangeDateMemberSince }
                languageCode={ languageCode }
                mutators={ form.mutators }
                isDisabledFunc={ this.isDisabled }
                setLanguageCode={ (el) => this.setLanguageCode(el) }
              />
              <hr />
            </>
            ) }

            { this.renderBusinessDescription(form, languageCode, languages) }
            <hr />

            { (isGrowth || isGrowthStartup) && (
            <>
              <CompanyFormTestimonial
                isImageUploading={ isTestimonialImageUploading }
                setImagePreviewFunc={ this.setTestimonialImagePreview }
                setImageUploadingStatusFunc={ this.setTestimonialImageUploadingStatus }
                setTestimonialAvatar={ form.mutators.setTestimonialAvatar }
                imagePreview={ companyTestimonialImagePreview }
              />
              <hr />
              <CompanyFormShareholders
                pushMutator={ form.mutators.push }
              />
              <hr />
              <CompanyFormTopManagement
                pushMutator={ form.mutators.push }
              />
            </>
            ) }

            <FormSubmit
              redirectFunc={ () => CommonService.setRedirect(this) }
              form={ form }
              setLanguageCode={ (el) => this.setLanguageCode(el) }
            />
          </form>
        ) }
      />
    );
  }
}

const mapStateToProps = state => ({
  error: state.company.companyError,
  loading: state.company.companyLoading,
  company: state.company.company,
  companyFormOptionsLoading: state.companyFormOptions.loading,
  companyFormOptionsError: state.companyFormOptions.error,
  companyFormOptions: state.companyFormOptions.companyFormOptions,
  companyData: state.company.companyData,
  localizationMessages: state.initInfo.localizationMessages,
  partnerSlug: state.initInfo.userInfo.partnerSlug,
  activeLanguage: state.initInfo.activeLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyForm);

export default injectIntl(withRouter(connectWrapper));
