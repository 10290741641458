import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withWindow } from 'react-window-decorators';
import { withRouter } from 'react-router-dom';
import AppBanner from '@src/js/layouts/header/appBanner/AppBanner';
import { BannerType } from '@src/js/constants/entities';
import { isInstitutionalInvestor } from '@src/js/helpers/helpers';
import { DashboardBanner } from '@src/js/views/user/pages/Dashboard/components/widgets';
import BannerRenew from '@src/js/layouts/header/bannerRenew/BannerRenew';
import TopicNavigation from '@app/components/public/topic/TopicNavigation';
import { getNavigation } from '@src/js/actions/navigation/navigation';
import { breakpoints } from '@src/js/constants/breakpoints';
import { homeRouteChildren, routeCodes, withLang } from '@src/js/constants/routes';
import { clearSearchResults } from '@src/js/actions/elasticsearch';
import { getHeaderData } from '@src/js/actions/tasks/task';
import { setTaskSocketNotificationNumber } from '@src/js/actions/tasks/socketTaskNotifications';
import WebsocketComponent from '@src/js/layouts/WebsocketComponent';
import Chat from './global/panels/messaging/Chat';
import MemberPopUp from './global/panels/messaging/MemberPopUp';
import SubMenuMobile from './header/navigation/menuMobile/SubMenuMobile';
import MainMenuMobile from './header/navigation/menuMobile/MainMenuMobile';
import SubMenuDesktop from './header/navigation/menuDesktop/SubMenuDesktop';
import MainMenuDesktop from './header/navigation/menuDesktop/MainMenuDesktop';
import AdminMenu from './header/navigation/menuGlobal/AdminMenu';

class Header extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    navigation: PropTypes.object,
    showNavigation: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    breakpoint: PropTypes.string,
    dimensions: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    loggedUser: PropTypes.object,
    loggedUserId: PropTypes.string,
    displayPreview: PropTypes.bool,
    displayChat: PropTypes.bool,
    session: PropTypes.object,
    initialHeaderData: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      adminNavigation: false,
      mainNavigation: {},
      subNavigation: false,
      activeNavItem: false,
      showSearch: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getNavigation());
    dispatch(getHeaderData());
  }

  componentDidUpdate(prevProps) {
    const {
      navigation, breakpoint,
      dimensions, displayPreview, displayChat, match, initialHeaderData, dispatch,
    } = this.props;

    if (navigation && ((prevProps.navigation !== navigation) || (match && match.url !== prevProps.match.url))) {
      this.formatNavigation();
    }

    if (prevProps.dimensions !== dimensions
      || prevProps.breakpoint !== breakpoint
      || prevProps.displayPreview !== displayPreview) {
      const body = document.body;
      if ((breakpoint === 'mobile' || dimensions.width <= breakpoints.MEDIUM) && (displayPreview || displayChat)) {
        body.classList.add('noScroll');
        body.ontouchmove = e => e.preventDefault();
      } else if (body.classList.value !== undefined && body.classList.value.indexOf('noScroll') !== -1) {
        body.classList.remove('noScroll');
        body.ontouchmove = () => true;
      }
    }
    if (initialHeaderData?.count && prevProps?.initialHeaderData !== initialHeaderData) {
      dispatch(setTaskSocketNotificationNumber(initialHeaderData.count));
    }
  }

  /* Set sub navigation */
  setSubNavigation = (activeNavItem) => {
    const { mainNavigation } = this.state;

    if (mainNavigation[activeNavItem]) {
      this.setState({ subNavigation: mainNavigation[activeNavItem].children, activeNavItem });
    }
  };

  /* Set main navigation item on match or depending on child navigation item */
  setActiveItem = (match, location, item) => {
    if (match) {
      return true;
    }

    const institutionalInvestor = isInstitutionalInvestor();

    const { mainNavigation } = this.state;

    if (item === 'network') {
      return ((location.pathname.toLowerCase().includes(withLang(routeCodes.COMPANIES))
        || location.pathname.toLowerCase().includes(withLang(routeCodes.PARTNERS))) && !institutionalInvestor);
    }

    if (item === 'opportunities') {
      return location.pathname.toLowerCase().includes(withLang(routeCodes.COMPLIANCE_LIST));
    }

    if (item === 'home') {
      const isDynamicHomeChildren = Object.keys(mainNavigation.home.children).some(
        key => location.pathname.toLowerCase().includes(mainNavigation.home.children[key].route)
      );
      const isStaticHomeChildren = homeRouteChildren.some(
        word => location.pathname.toLowerCase().includes(word.toLowerCase())
      );

      const isTaskRoute = location.pathname.toLowerCase().includes(withLang(routeCodes.COMPANIES));
      const isDataRoomRoute = location.pathname.toLowerCase().includes(withLang(routeCodes.MY_DATAROOMS));

      if (!isTaskRoute && (isDynamicHomeChildren || isStaticHomeChildren)) {
        return true;
      }
      if ((isTaskRoute || isDataRoomRoute) && institutionalInvestor) {
        return true;
      }
    }

    return false;
  };

  /* Toggle search input */
  toggleSearch = () => {
    const { dispatch } = this.props;
    const { showSearch } = this.state;

    dispatch(clearSearchResults());
    this.setState({ showSearch: !showSearch });
  };

  handleCurrentPath = () => {
    const { navigation, match, location } = this.props;
    let currentPath = '';

    if (match.params && match.params.page) {
      currentPath = match.params.page.split('/', 1);

      if ((location.pathname.toLowerCase().includes(withLang(routeCodes.COMPANIES))
        || location.pathname.toLowerCase().includes(withLang(routeCodes.PARTNERS))) && !isInstitutionalInvestor()) {
        currentPath = 'network';
      }

      if (location.pathname.toLowerCase().includes(withLang(routeCodes.COMPLIANCE_LIST))) {
        currentPath = 'opportunities';
      }

      Object.keys(navigation.mainNavigation.home.children).forEach(key => {
        if (navigation.mainNavigation.home.children[key].route === match.params.page) {
          currentPath = key;
        }
      });
    }

    return currentPath;
  };

  /* Format navigation response */
  formatNavigation() {
    const { navigation, match } = this.props;

    this.setState({
      adminNavigation: navigation.adminNavigation,
    });

    if (navigation.mainNavigation) {
      this.setState({
        mainNavigation: navigation.mainNavigation,
      }, () => {
        const mainNavigation = navigation.mainNavigation;
        let activeNavItem = 'home';
        let currentPath = this.handleCurrentPath();

        if (currentPath) {
          activeNavItem = Array.isArray(currentPath) ? currentPath[0] : currentPath;
          if (
            (homeRouteChildren && homeRouteChildren.indexOf(activeNavItem) !== -1)
            || !mainNavigation[activeNavItem]
          ) {
            activeNavItem = 'home';
          }
        }
        // EXCEPTION
        if (activeNavItem === 'partner_libraries') {
          activeNavItem = 'home';
          currentPath = 'my_entity';
        }
        if (activeNavItem === 'company' || activeNavItem === 'partner') { // Exception for my company url since it
          // doesn't match the navigation
          const current3rdPath = match.params.page.split('/', 3);
          if (current3rdPath[2]) {
            activeNavItem = 'home';
            currentPath = 'my_entity';
          }
        }

        this.setState({ activeNavItem });
        this.setSubNavigation(activeNavItem);
      });
    }
  }

  render() {
    const {
      breakpoint, dimensions, displayPreview, displayChat, loggedUserId,
      showNavigation, loggedUser, session,
    } = this.props;

    const {
      adminNavigation, mainNavigation, subNavigation, activeNavItem, showSearch,
    } = this.state;

    return (
      <header>
        { session?.token && <WebsocketComponent userId={ loggedUserId } />}
        { breakpoint === 'mobile' && <AppBanner /> }
        <DashboardBanner
          type={ BannerType.leaderboard }
          wrapperClass={ `flex leaderboard-banner ${ dimensions.width <= breakpoints.SMALL ? 'd-none' : '' }` }
          widgetClass='m-y-1'
        />
        { adminNavigation && <AdminMenu navigation={ adminNavigation } /> }
        <TopicNavigation />
        { mainNavigation && (
          <div onClick={ () => null } role='presentation'>
            { (breakpoint === 'mobile' || dimensions.width < breakpoints.MEDIUM) ? (
              <div>
                <MainMenuMobile
                  navigation={ mainNavigation }
                  showSearch={ showSearch }
                  setSubNavigation={ this.setSubNavigation }
                  setActiveItem={ this.setActiveItem }
                  toggleSearch={ this.toggleSearch }
                />

                { subNavigation && showNavigation && (
                  <SubMenuMobile
                    navigation={ subNavigation }
                    activeNavItem={ activeNavItem }
                  />
                ) }
              </div>
            ) : (
              <div>
                <MainMenuDesktop
                  navigation={ mainNavigation }
                  setSubNavigation={ this.setSubNavigation }
                  setActiveItem={ this.setActiveItem }
                  toggleSearch={ this.toggleSearch }
                  showSearch={ showSearch }
                />

                { subNavigation
                && (
                <SubMenuDesktop
                  navigation={ subNavigation }
                />
                ) }
              </div>
            ) }
            {loggedUser?.membershipRenew && (
              <BannerRenew />
            )}
          </div>

        ) }
        { displayPreview && <MemberPopUp /> }
        { displayChat && <Chat /> }
      </header>
    );
  }
}

const mapStateToProps = state => ({
  navigationLoading: state.navigation.loading,
  navigationError: state.navigation.error,
  navigation: state.navigation.navigation,
  showNavigation: state.navigation.showNavigation,
  unreadThreads: state.chat.unreadThreads,
  loggedUserId: state.initInfo.userInfo.uuid,
  loggedUser: state.initInfo.userInfo,
  displayPreview: state.chat.displayMemberPopup,
  displayChat: state.chat.displayChat,
  session: state.initInfo.session,
  initialHeaderData: state.tasks.initialHeaderData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(Header);

export default withWindow(withRouter(connectWrapper));
