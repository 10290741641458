import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_USER_FORM_OPTIONS_START,
  GET_USER_FORM_OPTIONS_SUCCESS,
  GET_USER_FORM_OPTIONS_ERROR,
  GET_USER_FORM_COMPANY_OPTIONS_START,
  GET_USER_FORM_COMPANY_OPTIONS_SUCCESS,
  GET_USER_FORM_COMPANY_OPTIONS_ERROR,
} from '@src/js/actions/user/formOptions';

import {
  getUserFormOptions as getUserFormOptionsApi,
  getUserFormCompanyOptions as getUserFormCompanyOptionsApi,
} from '@src/js/api/user/formOptions';

function* getUserFormOptions() {
  try {
    const data = yield call(() => getUserFormOptionsApi());
    yield put({ type: GET_USER_FORM_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_USER_FORM_OPTIONS_ERROR, error });
  }
}

function* getUserFormCompanyOptions(options) {
  try {
    const data = yield call(() => getUserFormCompanyOptionsApi(options.formOptions.values));
    yield put({ type: GET_USER_FORM_COMPANY_OPTIONS_SUCCESS, data });
    if (options.formOptions.resolve) {
      options.formOptions.resolve(data);
    }
  } catch (error) {
    yield put({ type: GET_USER_FORM_COMPANY_OPTIONS_ERROR, error });
    if (options.formOptions.reject) {
      options.formOptions.reject(error.response);
    }
  }
}

export function* getUserFormOptionsWatcher() {
  yield takeLatest(GET_USER_FORM_OPTIONS_START, getUserFormOptions);
}

export function* getUserFormCompanyOptionsWatcher() {
  yield takeLatest(GET_USER_FORM_COMPANY_OPTIONS_START, getUserFormCompanyOptions);
}

export default [
  getUserFormOptionsWatcher(),
  getUserFormCompanyOptionsWatcher(),
];
