import {
  GET_COMPANY_WIDGET_START,
  GET_COMPANY_WIDGET_ERROR,
  GET_COMPANY_WIDGET_SUCCESS,
} from '@src/js/actions/widget';

export const initialState = {
  loading: false,
  error: null,
  companyWidget: null,
};

const actionsMap = {
  [GET_COMPANY_WIDGET_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_COMPANY_WIDGET_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [GET_COMPANY_WIDGET_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    companyWidget: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
