import {
  SEND_REQUEST_START,
  SEND_REQUEST_ERROR,
  SEND_REQUEST_SUCCESS,
} from '@src/js/marketplace/actions/modal/requestInfoModal';

const initialState = {
  toggleRequestModal: false,
  messageDataLoading: false,
  messageDataError: null,
  messageData: null,
  opportunity: null,
};

const actionsMap = {
  [SEND_REQUEST_START]: (state) => ({
    ...state,
    messageDataLoading: true,
    messageDataError: null,
    messageData: null,
  }),
  [SEND_REQUEST_ERROR]: (state, action) => ({
    ...state,
    messageDataLoading: false,
    messageDataError: action.error,
    messageData: null,
  }),
  [SEND_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    messageDataLoading: false,
    messageDataError: null,
    messageData: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
