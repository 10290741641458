import fetchResource from '@src/js/api/fetch-resource';

const getFormattedFilters = (filters) => {
  const newFilters = { ...filters };

  if (filters && filters.programme) {
    newFilters.programme = filters.programme.map(programme => programme.value).join();
  }

  if (filters && filters.partner) {
    newFilters.partner = filters.partner.map(partner => partner.value).join();
  }

  return newFilters;
};

export function adminGetCompanies(filters) {
  const newFilters = getFormattedFilters(filters);
  let url = 'api/admin/companies';

  if (newFilters) {
    url += `?${ new URLSearchParams(newFilters) }`;
  }

  return fetchResource(url);
}
