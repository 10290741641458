import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_FEATURED_OPPORTUNITY_START,
  GET_FEATURED_OPPORTUNITY_ERROR,
  GET_FEATURED_OPPORTUNITY_SUCCESS,
} from '@src/js/marketplace/actions/getFeaturedOpportunities';
import * as api from '@src/js/marketplace/api/getFeaturedOpportunities';

function* getFeaturedOpportunities(options) {
  try {
    const data = yield call(() => api.getFeaturedOpportunities(options));
    yield put({ type: GET_FEATURED_OPPORTUNITY_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_FEATURED_OPPORTUNITY_ERROR, error });
  }
}

function* getFeaturedOpportunitiesWatcher() {
  yield takeLatest(GET_FEATURED_OPPORTUNITY_START, getFeaturedOpportunities);
}

export default [
  getFeaturedOpportunitiesWatcher(),
];
