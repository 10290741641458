import {
  View,
  LabeledFieldView,
  createLabeledInputText,
  ButtonView,
  submitHandler,
  ViewCollection,
  FocusableView,
} from '@ckeditor/ckeditor5-ui';
import { icons } from '@ckeditor/ckeditor5-core';
import { Locale } from '@ckeditor/ckeditor5-utils';

export default class DynamicsFormView extends View {
  blockInputView: LabeledFieldView;
  scriptInputView: LabeledFieldView;
  divInputView: LabeledFieldView;
  trackingInputView: LabeledFieldView;
  websiteInputView: LabeledFieldView;
  hostnameInputView: LabeledFieldView;
  saveButtonView: ButtonView;
  cancelButtonView: ButtonView;
  childViews: ViewCollection<FocusableView|View>;

  constructor(locale: Locale) {
    super(locale);

    this.blockInputView = this.createInput('Block Id');
    this.scriptInputView = this.createInput('Script Loader');
    this.divInputView = this.createInput('Div Id');
    this.trackingInputView = this.createInput('Script Tracking');
    this.websiteInputView = this.createInput('Website Id');
    this.hostnameInputView = this.createInput('Hostname');

    this.saveButtonView = this.createButton('Save', icons.check, 'ck-button-save');
    this.saveButtonView.type = 'submit';

    this.cancelButtonView = this.createButton('Cancel', icons.cancel, 'ck-button-cancel');
    this.cancelButtonView.delegate('execute').to(this, 'cancel');

    const actionButtons = this.buttonGroup([
      this.cancelButtonView,
      this.saveButtonView,
    ], 'ck-action-buttons');

    this.childViews = this.createCollection([
      this.blockInputView,
      this.scriptInputView,
      this.divInputView,
      this.trackingInputView,
      this.websiteInputView,
      this.hostnameInputView,
      actionButtons,
    ]);

    this.setTemplate({
      tag: 'form',
      attributes: {
        class: ['ck', 'ck-video-form'],
        tabindex: '-1',
      },
      children: this.childViews,
    });
  }

  render() {
    super.render();

    submitHandler({
      view: this,
    });
  }

  focus() {
    this.blockInputView.focus();
  }

  createInput(label: string) {
    const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
    labeledInput.label = label;

    return labeledInput;
  }

  createButton(label: string, icon: string, className: string) {
    const button = new ButtonView();
    button.set({
      label,
      icon,
      tooltip: true,
      class: className,
    });

    return button;
  }

  buttonGroup(children: View[], className: string) {
    const buttonGroup = new View();
    const buttons = this.createCollection(children);

    buttonGroup.setTemplate({
      tag: 'div',
      attributes: {
        class: ['ck', className],
      },
      children: buttons,
    });

    return buttonGroup;
  }
}
