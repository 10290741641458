import {
  ChatMessage, Thread,
} from '@app/models/Chat';
import {
  addMembersToChat, loadLatestThreads, loadMessages, loadSingleThread, loadThreads,
  loadUnreadThreads,
  findCreateNewThread,
  sendMessage, setThreadSubject, unsubscribeChat,
} from '@app/store/chat/chatApi';
import { createSlice, SerializedError } from '@reduxjs/toolkit';

const CHAT_SCOPE = 'CHAT';

export type ChatState = {
  threadsList: Thread[];
  threadsError: null | SerializedError;
  threadsLoading: boolean;
  currentThreadId: string | null;
  hasMoreThreads: boolean;
  messagesList: ChatMessage[],
  messagesError: null | SerializedError,
  messagesLoading: boolean,
  hasMoreMessages: boolean,
  socketMessage: ChatMessage|null,
  unreadThreads: string[],
  displayChat: boolean,
  displayMemberPopup: boolean,
  userUuid: string,
};

const initialState: ChatState = {
  threadsList: [],
  threadsError: null,
  threadsLoading: false,
  currentThreadId: null,
  hasMoreThreads: false,
  messagesList: [],
  messagesError: null,
  messagesLoading: false,
  hasMoreMessages: false,
  socketMessage: null,
  unreadThreads: [],
  displayChat: false,
  displayMemberPopup: false,
  userUuid: '',
};

export const chatSlice = createSlice({
  name: CHAT_SCOPE,
  initialState,
  reducerPath: 'chat',
  reducers: {
    setCurrentThread: (state, action) => {
      state.currentThreadId = action.payload;
      state.hasMoreMessages = false;
      const idx = state.threadsList.findIndex(t => t.id === action.payload);
      if (idx >= 0) {
        state.threadsList[idx].hasUnreadMessages = false;
      }
      state.unreadThreads = state.unreadThreads.filter(u => u !== action.payload);
      state.threadsList = state.threadsList.filter(t => t.left === false);
    },
    addSocketMessage: (state, action) => {
      if (state.currentThreadId === action.payload.threadId) {
        state.messagesList = state.messagesList.concat(action.payload);
      }
      const idx = state.threadsList.findIndex(t => t.id === action.payload.threadId);
      if (idx >= 0) {
        const selected = state.threadsList.splice(idx, 1)[0];
        selected.lastMessage = action.payload;
        state.threadsList = [selected, ...state.threadsList];
      }
      state.socketMessage = action.payload;
    },
    setSocketUnreadThreads: (state, action) => {
      if (state.currentThreadId !== null) {
        state.unreadThreads = action.payload.filter((u: string) => u !== state.currentThreadId);
      } else {
        state.unreadThreads = action.payload;
      }
      action.payload.forEach((id: string) => {
        const idx = state.threadsList.findIndex(t => t.id === id);
        if (idx >= 0) {
          state.threadsList[idx] = { ...state.threadsList[idx], hasUnreadMessages: true };
        }
      });
    },
    showMemberPopup: (state, action) => {
      state.userUuid = action.payload;
      state.currentThreadId = null;
      state.displayChat = false;
      state.displayMemberPopup = true;
    },
    closeMemberPopup: (state) => {
      state.userUuid = '';
      state.displayMemberPopup = false;
    },
    showChatPopup: (state, action) => {
      state.displayChat = true;
      state.userUuid = action.payload;
    },
    closeChatPopup: (state) => {
      state.displayChat = false;
      state.userUuid = '';
    },
    clearChat: (state) => {
      state.currentThreadId = null;
      state.messagesList = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(loadThreads.pending, (state) => {
      state.threadsError = null;
      state.threadsLoading = true;
    });
    builder.addCase(loadThreads.fulfilled, (state, action) => {
      if (action.meta.arg.page > 0) {
        state.threadsList = state.threadsList.concat(action.payload);
      } else {
        state.threadsList = action.payload;
      }
      state.threadsError = null;
      state.threadsLoading = false;
      state.hasMoreThreads = action.payload.length > 0;
      const idx = state.threadsList.findIndex(t => t.id === state.currentThreadId);
      if (idx >= 0) {
        state.threadsList[idx].hasUnreadMessages = false;
      }
    });
    builder.addCase(loadThreads.rejected, (state, action) => {
      state.threadsList = [];
      state.threadsError = action.error;
      state.threadsLoading = false;
    });
    builder.addCase(loadMessages.pending, state => {
      state.messagesError = null;
      state.messagesLoading = true;
    });
    builder.addCase(loadMessages.fulfilled, (state, action) => {
      const messages = action.payload.reverse();
      if (action.meta.arg.offset) {
        state.messagesList = messages.concat(state.messagesList || []);
      } else {
        state.messagesList = messages;
      }
      state.messagesError = null;
      state.messagesLoading = false;
      state.hasMoreMessages = messages.length === 20;
    });
    builder.addCase(loadMessages.rejected, (state, action) => {
      state.messagesList = [];
      state.messagesError = action.error;
      state.messagesLoading = false;
    });
    builder.addCase(sendMessage.pending, state => {
      state.messagesLoading = true;
    });
    builder.addCase(sendMessage.fulfilled, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.messagesError = action.error;
      state.messagesLoading = false;
    });
    builder.addCase(unsubscribeChat.pending, state => {
      state.messagesLoading = true;
    });
    builder.addCase(unsubscribeChat.fulfilled, (state, action) => {
      const idx = state.threadsList.findIndex(t => t.id === action.payload.id);
      if (idx >= 0) {
        state.threadsList[idx] = action.payload;
      }
      state.messagesLoading = false;
    });
    builder.addCase(unsubscribeChat.rejected, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(addMembersToChat.pending, state => {
      state.messagesLoading = true;
    });
    builder.addCase(addMembersToChat.fulfilled, (state, action) => {
      const idx = state.threadsList.findIndex(t => t.id === action.payload.id);
      if (idx >= 0) {
        state.threadsList[idx] = action.payload;
      }
      state.messagesLoading = false;
    });
    builder.addCase(addMembersToChat.rejected, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(setThreadSubject.pending, state => {
      state.messagesLoading = true;
    });
    builder.addCase(setThreadSubject.fulfilled, (state, action) => {
      const idx = state.threadsList.findIndex(t => t.id === action.payload.id);
      if (idx >= 0) {
        state.threadsList[idx] = action.payload;
      }
      state.messagesLoading = false;
    });
    builder.addCase(setThreadSubject.rejected, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(loadUnreadThreads.fulfilled, (state, action) => {
      state.unreadThreads = action.payload;
    });
    builder.addCase(loadLatestThreads.pending, (state) => {
      state.threadsList = [];
      state.threadsLoading = true;
    });
    builder.addCase(loadLatestThreads.fulfilled, (state, action) => {
      state.threadsList = action.payload;
      state.threadsLoading = false;
    });
    builder.addCase(loadLatestThreads.rejected, (state) => {
      state.threadsList = [];
      state.threadsLoading = false;
    });
    builder.addCase(findCreateNewThread.pending, (state) => {
      state.messagesLoading = true;
    });
    builder.addCase(findCreateNewThread.fulfilled, (state, action) => {
      const idx = state.threadsList.findIndex(t => t.id === action.payload.id);
      if (idx >= 0) {
        state.threadsList.splice(idx, 1);
      }
      state.threadsList = [action.payload, ...state.threadsList];
      state.currentThreadId = action.payload.id;
      state.messagesLoading = false;
    });
    builder.addCase(findCreateNewThread.rejected, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(loadSingleThread.pending, (state) => {
      state.messagesLoading = true;
    });
    builder.addCase(loadSingleThread.fulfilled, (state, action) => {
      state.threadsList = [action.payload, ...state.threadsList];
      state.messagesLoading = false;
    });
    builder.addCase(loadSingleThread.rejected, (state) => {
      state.messagesLoading = false;
    });
  },
  selectors: {
    selectThreadsList: (state) => state.threadsList,
    selectThreadsLoading: (state) => state.threadsLoading,
    selectHasMoreThreads: (state) => state.hasMoreThreads,
    selectCurrentThread: (state) => state.threadsList.find(t => t.id === state.currentThreadId) || null,
    selectMessagesList: (state) => state.messagesList,
    selectMessagesLoading: (state) => state.messagesLoading,
    selectMessagesError: (state) => state.messagesError,
    selectHasMoreMessages: (state) => state.hasMoreMessages,
    selectSocketMessage: (state) => state.socketMessage,
    selectUnreadThreads: (state) => state.unreadThreads,
    selectUserUuid: (state) => state.userUuid,
  },
});

export const {
  setCurrentThread, addSocketMessage,
  setSocketUnreadThreads, showChatPopup, clearChat, showMemberPopup,
  closeMemberPopup, closeChatPopup,
} = chatSlice.actions;
export const {
  selectThreadsList,
  selectThreadsLoading,
  selectHasMoreThreads,
  selectCurrentThread,
  selectMessagesList,
  selectMessagesLoading,
  selectMessagesError,
  selectSocketMessage,
  selectUnreadThreads,
  selectHasMoreMessages,
  selectUserUuid,
} = chatSlice.selectors;

export default chatSlice.reducer;
