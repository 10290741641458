import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import { Images } from '@src/js/constants/images';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, withRouter } from 'react-router-dom';

// This component is used for Server rendering
// When you want to return 40x http statuses
const RouteStatus = ({ code, children }) => (
  <Route
    render={
      ({ staticContext }) => {
        if (staticContext) {
          staticContext.status = code; // eslint-disable-line no-param-reassign
        }

        return children;
      }
    }
  />
);

RouteStatus.propTypes = {
  code: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

class UnexpectedError extends Component {
  render() {
    return (
      <RouteStatus code={ 403 }>
        <MetaTitle title='error.unexpectedError' />
        <div className='elite-404-container'>
          <img
            src={ Images.Bg404 }
            alt='Not Found Background'
            className='elite-404-bg-image'
          />
          <div className='elite-404-text-container'>
            <span className='elite-404-subtitle'>
              <FormattedMessage id='error.unexpectedError' />
            </span>
          </div>
        </div>
      </RouteStatus>
    );
  }
}

export default withRouter(UnexpectedError);
