import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getBusinessNeeds() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/business-needs`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function deleteBusinessNeed(businessNeed) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/business-need`, {
    method: 'DELETE',
    externalUrl: true,
    body: businessNeed,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function updateBusinessNeed(data) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/business-need`, {
    method: 'PUT',
    externalUrl: true,
    body: data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
