import { takeLatest, put, call } from 'redux-saga/effects';
import * as privateMarketTemplatesApi from '@src/js/api/privateMarket';

import {
  GET_TEMPLATES_REQUESTED, UPDATE_TEMPLATE_REQUESTED,
} from '@src/js/oneplatform/privateMarketNetwork/container/constants';
import {
  getTemplatesSucceeded,
  getTemplatesFailed,
  updateTemplateSucceeded,
  updateTemplateFailed,
} from './actions';

function* getTemplates() {
  try {
    const data = yield call(() => privateMarketTemplatesApi.getTemplates());
    yield put(getTemplatesSucceeded(data));
  } catch (error) {
    yield put(getTemplatesFailed(error));
  }
}

function* updateTemplate({ payload, id }) {
  try {
    const data = yield call(() => privateMarketTemplatesApi.updateTemplate(payload, id));
    yield put(updateTemplateSucceeded(data));
  } catch (error) {
    yield put(updateTemplateFailed(error));
  }
}

function* root() {
  yield takeLatest(GET_TEMPLATES_REQUESTED, getTemplates);
  yield takeLatest(UPDATE_TEMPLATE_REQUESTED, updateTemplate);
}

export default root;
