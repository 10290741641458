export const GET_MY_LIBRARY_START = 'GET_MY_LIBRARY_START';
export const GET_MY_LIBRARY_ERROR = 'GET_MY_LIBRARY_ERROR';
export const GET_MY_LIBRARY_SUCCESS = 'GET_MY_LIBRARY_SUCCESS';
export const CHANGE_LIBRARY_STATUS_START = 'CHANGE_LIBRARY_STATUS_START';
export const CHANGE_LIBRARY_STATUS_ERROR = 'CHANGE_LIBRARY_STATUS_ERROR';
export const CHANGE_LIBRARY_STATUS_SUCCESS = 'CHANGE_LIBRARY_STATUS_SUCCESS';

export function getPartnerLibrariesAction(filters) {
  return {
    type: GET_MY_LIBRARY_START,
    filters,
  };
}

export function changeLibraryStatusAction(library) {
  return {
    type: CHANGE_LIBRARY_STATUS_START,
    library,
  };
}
