import produce from 'immer';

import {
  GET_COUNTRIES_TREE_START, GET_COUNTRIES_TREE_SUCCESS, GET_COUNTRIES_TREE_FAIL,
  GET_SECTORS_TREE_START, GET_SECTORS_TREE_SUCCESS, GET_SECTORS_TREE_FAIL,
  GET_TURNOVER_ETINERARIES_START, GET_TURNOVER_ETINERARIES_SUCCESS, GET_TURNOVER_ETINERARIES_FAIL,
  GET_PARTNER_EXPERTISES_START, GET_PARTNER_EXPERTISES_SUCCESS, GET_PARTNER_EXPERTISES_FAIL,
  GET_PARTNER_SECTORS_START, GET_PARTNER_SECTORS_SUCCESS, GET_PARTNER_SECTORS_FAIL,
  GET_PARTNER_SECTORS_TREE_START, GET_PARTNER_SECTORS_TREE_SUCCESS, GET_PARTNER_SECTORS_TREE_FAIL,
  GET_DIMENSIONS_START, GET_DIMENSIONS_SUCCESS, GET_DIMENSIONS_FAIL,
  GET_STARTUP_STAGES_START, GET_STARTUP_STAGES_SUCCESS, GET_STARTUP_STAGES_FAIL,
  GET_ETINERARY_COMPANY_TYPES_START, GET_ETINERARY_COMPANY_TYPES_SUCCESS, GET_ETINERARY_COMPANY_TYPES_FAIL,
} from '@app/store/taxonomy/actions';

export const initialState = {
  data: {},
  loading: {},
  error: {},
};

const objectKeys = [
  ['countriesTree', [GET_COUNTRIES_TREE_START, GET_COUNTRIES_TREE_SUCCESS, GET_COUNTRIES_TREE_FAIL]],
  ['sectorsTree', [GET_SECTORS_TREE_START, GET_SECTORS_TREE_SUCCESS, GET_SECTORS_TREE_FAIL]],
  ['turnoverEtineraries', [GET_TURNOVER_ETINERARIES_START,
    GET_TURNOVER_ETINERARIES_SUCCESS, GET_TURNOVER_ETINERARIES_FAIL]],
  ['partnerExpertises', [GET_PARTNER_EXPERTISES_START, GET_PARTNER_EXPERTISES_SUCCESS, GET_PARTNER_EXPERTISES_FAIL]],
  ['partnerSectors', [GET_PARTNER_SECTORS_START, GET_PARTNER_SECTORS_SUCCESS, GET_PARTNER_SECTORS_FAIL]],
  ['partnerSectorsTree', [GET_PARTNER_SECTORS_TREE_START,
    GET_PARTNER_SECTORS_TREE_SUCCESS, GET_PARTNER_SECTORS_TREE_FAIL]],
  ['dimensions', [GET_DIMENSIONS_START, GET_DIMENSIONS_SUCCESS, GET_DIMENSIONS_FAIL]],
  ['startupStages', [GET_STARTUP_STAGES_START, GET_STARTUP_STAGES_SUCCESS, GET_STARTUP_STAGES_FAIL]],
  ['etineraryCompanyTypes', [GET_ETINERARY_COMPANY_TYPES_START,
    GET_ETINERARY_COMPANY_TYPES_SUCCESS, GET_ETINERARY_COMPANY_TYPES_FAIL]],
];

function taxonomyReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    let found = null;
    switch (action.type) {
      case GET_COUNTRIES_TREE_START:
      case GET_SECTORS_TREE_START:
      case GET_TURNOVER_ETINERARIES_START:
      case GET_PARTNER_EXPERTISES_START:
      case GET_PARTNER_SECTORS_START:
      case GET_PARTNER_SECTORS_TREE_START:
      case GET_DIMENSIONS_START:
      case GET_STARTUP_STAGES_START:
      case GET_ETINERARY_COMPANY_TYPES_START:
        found = objectKeys.find(k => k[1].includes(action.type));
        if (found) {
          draft.loading[found[0]] = true;
          draft.error[found[0]] = null;
          draft.data[found[0]] = null;
        }
        break;
      case GET_COUNTRIES_TREE_SUCCESS:
      case GET_SECTORS_TREE_SUCCESS:
      case GET_TURNOVER_ETINERARIES_SUCCESS:
      case GET_PARTNER_EXPERTISES_SUCCESS:
      case GET_PARTNER_SECTORS_SUCCESS:
      case GET_PARTNER_SECTORS_TREE_SUCCESS:
      case GET_DIMENSIONS_SUCCESS:
      case GET_STARTUP_STAGES_SUCCESS:
      case GET_ETINERARY_COMPANY_TYPES_SUCCESS:
        found = objectKeys.find(k => k[1].includes(action.type));
        if (found) {
          draft.loading[found[0]] = false;
          draft.error[found[0]] = null;
          draft.data[found[0]] = action.result;
        }
        break;
      case GET_COUNTRIES_TREE_FAIL:
      case GET_SECTORS_TREE_FAIL:
      case GET_TURNOVER_ETINERARIES_FAIL:
      case GET_PARTNER_EXPERTISES_FAIL:
      case GET_PARTNER_SECTORS_FAIL:
      case GET_PARTNER_SECTORS_TREE_FAIL:
      case GET_DIMENSIONS_FAIL:
      case GET_STARTUP_STAGES_FAIL:
      case GET_ETINERARY_COMPANY_TYPES_FAIL:
        found = objectKeys.find(k => k[1].includes(action.type));
        if (found) {
          draft.loading[found[0]] = false;
          draft.error[found[0]] = action.error;
          draft.data[found[0]] = null;
        }
        break;
      default:
        break;
    }
  }
  );
}

export default taxonomyReducer;
