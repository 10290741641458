import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_ONBOARDING_DATA_START,
  GET_ONBOARDING_DATA_ERROR,
  GET_ONBOARDING_DATA_SUCCESS,
  SUBMIT_ONBOARDING_ERROR,
  SUBMIT_ONBOARDING_START,
  SUBMIT_ONBOARDING_SUCCESS,
} from '@src/js/actions/onboarding/onboardingProcess';

import * as api from '@src/js/api/onboarding/onboardingProcess';

function* getOnboardingData(options) {
  try {
    const data = yield call(() => api.getOnboardingData(options.processHash));
    yield put({ type: GET_ONBOARDING_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ONBOARDING_DATA_ERROR, error });
  }
}

function* submitOnboarding(options) {
  try {
    const data = yield call(() => {
      if (options && options.data.isSubmit) {
        return api.submitOnboarding(options.data.values);
      }

      return api.saveOnboarding(options.data.values, options.data.sendEmail);
    });

    yield put({ type: SUBMIT_ONBOARDING_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: SUBMIT_ONBOARDING_ERROR, error });
    options.data.reject(error.response);
  }
}

export function* getOnboardingDataWatcher() {
  yield takeLatest(GET_ONBOARDING_DATA_START, getOnboardingData);
}

export function* submitOnboardingDataWatcher() {
  yield takeLatest(SUBMIT_ONBOARDING_START, submitOnboarding);
}

export default [
  getOnboardingDataWatcher(),
  submitOnboardingDataWatcher(),
];
