import { Action } from 'redux';

export const GET_CONTACT_USER_START = 'GET_FUNDING_CONTACT_USER_START';
export const GET_CONTACT_USER_ERROR = 'GET_FUNDING_CONTACT_USER_ERROR';
export const GET_CONTACT_USER_SUCCESS = 'GET_FUNDING_CONTACT_USER_SUCCESS';

export type ContactType = 'funding'|'inspiring';

export interface ContactUserAction extends Action {
  contactType: ContactType;
}

export function getContactUser(contactType: ContactType): ContactUserAction {
  return {
    type: GET_CONTACT_USER_START,
    contactType,
  };
}
