import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

const baseUrl = loadEnvVariable('MARKETPLACE_API_PATH');

export function createBusinessNeed(businessNeeds, isAdmin) {
  return fetchResource(`${ baseUrl }/${ isAdmin ? 'admin' : 'user' }/business-need`, {
    method: 'POST',
    body: businessNeeds,
    externalUrl: true,
  });
}

export function editBusinessNeed(businessNeeds, isAdmin) {
  return fetchResource(`${ baseUrl }/${ isAdmin ? 'admin' : 'user' }/business-need`, {
    method: 'PUT',
    body: businessNeeds,
    externalUrl: true,
  });
}

export function getBusinessNeedSectors() {
  return fetchResource(`${ baseUrl }/user/sectors?first=true`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
