export const DELETE_IMAGE_START = 'DELETE_IMAGE_START';
export const DELETE_IMAGE_ERROR = 'DELETE_IMAGE_ERROR';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';

export default function deleteImage(id, entity, imageType) {
  return {
    type: DELETE_IMAGE_START,
    id,
    entity,
    imageType,
  };
}
