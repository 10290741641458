import AsyncSelectPaginate from '@app/components/global/forms/select/AsyncSelectPaginate';
import { ItemObj } from '@app/components/global/forms/select/select';
import DataTable, { TableColDef } from '@app/components/global/list/DataTable';
import { Event } from '@app/models';
import { getAdminEvents } from '@src/js/actions/events/adminEvents';
import { getUserFormCompanyOptions } from '@src/js/api/user/formOptions';
import { Button } from '@src/js/components/global';
import { RootState } from '@src/js/store';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { dateFormat } from '@src/js/utils/date';
import { AdminEventFilters } from '@src/js/reducers/events/adminEvent';
import ActionButton from './ActionButton';

const columnsDef = (lang: string, values: Event[], setValues: (v: Event[]) => void): TableColDef<Event>[] => [
  {
    field: 'name', headerName: 'name', width: 40,
  },
  {
    field: 'programme', headerName: 'programme_label', width: 25,
  },
  {
    field: 'startDate',
    headerName: 'event_list.eventDate',
    width: 15,
    component: (item) => dateFormat(new Date(item.startDate), 'dd-MM-yyyy'),
  },
  {
    field: `details.${ lang }.events`,
    headerName: '',
    width: 20,
    component: (item) => <ActionButton item={ item } values={ values } setValues={ setValues } />,
  },
];

type Props = {
  lang: string,
  addValues: (values: Event[]) => void,
  excludedIds: number[],
}

const PageSize = 5;

const EventModal = ({ lang, addValues, excludedIds }: Props) => {
  const [values, setValues] = useState<Event[]>([]);
  const { hideModal } = useGlobalModalContext();
  const columns = columnsDef(lang, values, setValues);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [filters, setFilters] = useState<AdminEventFilters>({
    page: 1, search: '', type: '2', limit: PageSize, excludedIds, programme: [], status: 'published',
  });

  const { events, loading } = useSelector<RootState, any>((state) => state.adminEvents);

  const confirm = () => {
    addValues(values);
    hideModal();
  };

  useEffect(() => {
    dispatch(getAdminEvents(filters));
  }, [filters, dispatch]);

  const searchPlaceholder = intl.formatMessage({ id: 'search' });

  return (
    <ModalBody>
      <div className='grid-container__search grid-col-3 m-t-4'>
        <DebounceInput
          debounceTimeout={ 500 }
          placeholder={ searchPlaceholder }
          onChange={ (e) => setFilters({ ...filters, page: 1, search: e.target.value }) }
          value={ filters.search }
          className='search-input'
        />
        <AsyncSelectPaginate
          onChange={ (programme) => setFilters({ ...filters, page: 1, programme: programme as ItemObj<string>[] }) }
          loadAsyncOptions={ getUserFormCompanyOptions }
          placeholder='filterPlaceholders.programme'
          name='programmes'
          value={ filters.programme }
          className='filters'
          type='programmes'
          multi
        />
      </div>
      <DataTable
        page={ filters.page || 1 }
        columns={ columns }
        items={ events?.results || [] }
        total={ events?.count || 0 }
        pageSize={ PageSize }
        loading={ loading }
        handlePage={ (page) => setFilters({ ...filters, page }) }
      />
      <ModalFooter>
        <Button
          size='md'
          buttonText='dialog.confirm'
          onClickFunc={ confirm }
        />
        <Button
          size='md'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </ModalBody>
  );
};

export default EventModal;
