export const GET_LIBRARY_OPTIONS_START = 'GET_LIBRARY_OPTIONS_START';
export const GET_LIBRARY_OPTIONS_ERROR = 'GET_LIBRARY_OPTIONS_ERROR';
export const GET_LIBRARY_OPTIONS_SUCCESS = 'GET_LIBRARY_OPTIONS_SUCCESS';
export const CLEAR_LIBRARY_OPTIONS = 'CLEAR_LIBRARY_OPTIONS';

export function getLibraryOptions(slug) {
  return {
    type: GET_LIBRARY_OPTIONS_START,
    slug,
  };
}

export function clearLibraryOptions() {
  return {
    type: CLEAR_LIBRARY_OPTIONS,
  };
}
