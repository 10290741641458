import fetchResource from '@src/js/api/fetch-resource';
import { User } from '@src/js/constants/entities';

export function getCompanyPublicProfile(slug) {
  return fetchResource(`api/companies/${ slug }/profile`, {
    method: 'GET',
  });
}

export function getCompanyBasicData(slug) {
  return fetchResource(`api/companies/${ slug }/main`, {
    method: 'GET',
  });
}

export function getCompanyFinancialsData(slug) {
  return fetchResource(`api/companies/${ slug }/financials`, {
    method: 'GET',
  });
}

export function editCompany(company, userType) {
  const urlType = (userType === User.TYPE_ADMIN) ? '/admin' : '';

  return fetchResource(`api${ urlType }/companies/${ company.slug }`, {
    method: 'PUT',
    'body': company,
  });
}

export function editEtineraryStatus(slug, status) {
  return fetchResource(`api/admin/companies/${ slug }/etinerary-status`, {
    method: 'POST',
    body: {
      'etineraryStatus': status,
    },
  });
}
