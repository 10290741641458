import { css } from 'styled-components';
import { ThemeColors } from './colors';

export const TruncateLine = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoxShadow = css`
  box-shadow: 0 0.2rem 0.5rem ${ ThemeColors.lightGrey };
`;
