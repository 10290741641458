import {
  GET_COMPANY_PERMISSIONS_START,
  GET_COMPANY_PERMISSIONS_ERROR,
  GET_COMPANY_PERMISSIONS_SUCCESS,
} from '@src/js/actions/company/companyPermissions';

const initialState = {
  permissionsLoading: false,
  permissionsError: null,
  permissions: null,
};

const actionsMap = {
  [GET_COMPANY_PERMISSIONS_START]: (state) => ({
    ...state,
    permissions: null,
    permissionsLoading: true,
    permissionsError: null,
  }),
  [GET_COMPANY_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    permissionsLoading: false,
    permissionsError: action.error,
  }),
  [GET_COMPANY_PERMISSIONS_SUCCESS]: (state, action) => ({
    ...state,
    permissionsLoading: false,
    permissionsError: null,
    permissions: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
