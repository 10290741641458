import {
  call, all, put, takeLatest,
} from 'redux-saga/effects';
import { showToastrSuccess, showToastrError } from '@app/store/global/actions';
import * as api from '@app/store/programme/api';
import {
  GET_LOUNGES_LIST_START, GET_LOUNGES_LIST_SUCCESS, GET_LOUNGES_LIST_FAIL,
  GET_PROGRAMMES_LOUNGES_START, GET_PROGRAMMES_LOUNGES_SUCCESS, GET_PROGRAMMES_LOUNGES_FAIL,
  CREATE_LOUNGE_START, CREATE_LOUNGE_SUCCESS, CREATE_LOUNGE_FAIL,
  UPDATE_LOUNGE_START, UPDATE_LOUNGE_SUCCESS, UPDATE_LOUNGE_FAIL,
  DELETE_LOUNGE_START, DELETE_LOUNGE_SUCCESS, DELETE_LOUNGE_FAIL,
} from '@app/store/programme/actions';

function* getLoungesList() {
  try {
    const result = yield call(() => api.getLoungesList());
    yield put({
      type: GET_LOUNGES_LIST_SUCCESS,
      result,
    });
  } catch (error) {
    yield all([
      put({
        type: GET_LOUNGES_LIST_FAIL,
        error,
      }),
      put(showToastrError()),
    ]);
  }
}

function* getProgrammesLounges({ id }) {
  try {
    const result = yield call(() => api.getProgrammesLounges(id));
    yield put({
      type: GET_PROGRAMMES_LOUNGES_SUCCESS,
      result,
    });
  } catch (error) {
    yield all([
      put({
        type: GET_PROGRAMMES_LOUNGES_FAIL,
        error,
      }),
      put(showToastrError()),
    ]);
  }
}

function* createLounge({ data }) {
  try {
    const result = yield call(() => api.createLounge(data));
    yield all([
      put({
        type: CREATE_LOUNGE_SUCCESS,
        result,
        data,
      }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({
        type: CREATE_LOUNGE_FAIL,
        error,
      }),
      put(showToastrError()),
    ]);
  }
}

function* updateLounge({ data }) {
  try {
    yield call(() => api.updateLounge(data));
    yield all([
      put({
        type: UPDATE_LOUNGE_SUCCESS,
        data,
      }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({
        type: UPDATE_LOUNGE_FAIL,
        error,
      }),
      put(showToastrError()),
    ]);
  }
}

function* deleteLounge(data) {
  try {
    const { id } = data;
    yield call(() => api.deleteLounge(id));
    yield all([
      put({
        type: DELETE_LOUNGE_SUCCESS,
        id,
      }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({
        type: DELETE_LOUNGE_FAIL,
        error,
      }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_LOUNGES_LIST_START, getLoungesList);
  yield takeLatest(GET_PROGRAMMES_LOUNGES_START, getProgrammesLounges);
  yield takeLatest(CREATE_LOUNGE_START, createLounge);
  yield takeLatest(UPDATE_LOUNGE_START, updateLounge);
  yield takeLatest(DELETE_LOUNGE_START, deleteLounge);
}

export default root;
