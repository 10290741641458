export type FieldsObject = {
    [key:string]:string
}

export class EventGa {
    /**
     * GA4:Event names must start with a letter. Use only letters, numbers, and underscores. Don't use spaces.
     * https://support.google.com/analytics/answer/13316687?sjid=10464301572048200642-EU
     */
    eventAction: string;

    eventCategory:string;

    label?: string;

    fieldsObject: FieldsObject;

    constructor(eventCategory: string, eventAction: string, label?:string, fieldsObject?: FieldsObject) {
      this.eventAction = eventAction;
      this.eventCategory = eventCategory;
      this.label = label;
      this.fieldsObject = fieldsObject ?? {};
    }
}
