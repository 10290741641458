import produce from 'immer';

import {
  GET_PARAMETERS_PARTNERS_REQUEST,
  GET_PARAMETERS_PARTNERS_SUCCESS,
  GET_PARAMETERS_PARTNERS_FAIL,
} from '@app/store/funding/constants';

export const initialState = {
  loading: false,
  error: null,
  partners: null,
};

/* eslint-disable default-case,no-param-reassign */
function parametersPartnersReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_PARAMETERS_PARTNERS_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_PARAMETERS_PARTNERS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case GET_PARAMETERS_PARTNERS_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.partners = action.payload;
        break;
    }
  });
}
/* eslint-enable */

export default parametersPartnersReducer;
