import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TextInput from '@src/js/components/global/inputs/TextInput';
import { uploadImage } from '@src/js/actions/image/uploadImage';
import { UploadDropzone } from '@src/js/components/global/uploadDropzone/UploadDropzone';
import { Images } from '@src/js/constants/images';
import Field from '@src/js/helpers/FinalFormFieldAdapter';

class CompanyFormTestimonial extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    uploadedImage: PropTypes.object,
    imagePreview: PropTypes.object,
    isImageUploading: PropTypes.bool,
    setTestimonialAvatar: PropTypes.func,
    setImagePreviewFunc: PropTypes.func,
    setImageUploadingStatusFunc: PropTypes.func,
  };

  /* Constructor */
  constructor(props) {
    super(props);

    this.state = {
      uploadErrors: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      uploadedImage,
      setTestimonialAvatar,
      setImageUploadingStatusFunc,
    } = this.props;

    if (prevProps.uploadedImage.image !== uploadedImage.image) {
      if (uploadedImage.options.entity === 'ctestimonial') {
        setImageUploadingStatusFunc(false);
        if (uploadedImage.image && uploadedImage.image.id) {
          setTestimonialAvatar(uploadedImage.image.id);
        } else {
          setTestimonialAvatar(null);
        }
      }
    }
  }

  handleDropImage = (acceptedFiles) => {
    const {
      dispatch,
      setImageUploadingStatusFunc,
      setImagePreviewFunc,
    } = this.props;

    const newImage = acceptedFiles[0];
    const image = new FormData();
    image.append('image', newImage);

    return new Promise((resolve, reject) => {
      dispatch(uploadImage({ image, resolve, reject }, 'ctestimonial', 'avatar'));
      setImageUploadingStatusFunc(true);
      this.setState({
        uploadErrors: null,
      });
    }).then(() => {
      setImagePreviewFunc(newImage);
      return null;
    }).catch((errors) => {
      setImageUploadingStatusFunc(false);
      this.setState({
        uploadErrors: errors,
      });
      return errors;
    });
  };

  handleDeleteImage = () => {
    const {
      setTestimonialAvatar,
      setImagePreviewFunc,
    } = this.props;

    setImagePreviewFunc(null);
    setTestimonialAvatar(null);
    this.setState({
      uploadErrors: null,
    });
  };

  render() {
    const {
      isImageUploading,
      imagePreview,
    } = this.props;

    const {
      uploadErrors,
    } = this.state;

    return (
      <div className='edit-company-page edit-company-page__section'>
        <div className='row'>
          <div className='col-xs'>
            <div className='edit-company-page__title edit-user-page-tab-content-title'>
              <FormattedMessage id='company_form.testimonial' />
            </div>
          </div>
        </div>

        <UploadDropzone
          handleDrop={ this.handleDropImage }
          handleDelete={ this.handleDeleteImage }
          image={ imagePreview }
          isLoading={ isImageUploading }
          defaultImage={ Images.UserAvatar }
          customError={ uploadErrors && uploadErrors.ctestimonialAvatar }
        />
        <Field
          name='testimonial.avatar'
          fieldName='testimonial.avatar'
          fieldType='text'
          fieldLabel=''
          component={ TextInput }
          validationMessageOnly
        />
        <Field
          name='testimonial.name'
          fieldName='testimonial.name'
          fieldType='text'
          fieldLabel='name'
          component={ TextInput }
          fieldMaxLength={ 200 }
        />

        <Field
          name='testimonial.jobTitle'
          fieldName='testimonial.jobTitle'
          fieldType='text'
          fieldLabel='user.jobTitle'
          component={ TextInput }
          fieldMaxLength={ 100 }
        />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadedImage: state.uploadImage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormTestimonial);

export default withRouter(connectWrapper);
