import fetchResource from '@src/js/api/fetch-resource';

export function getCountriesTree() {
  return fetchResource('api/taxonomy/countries?tree=true', {
    method: 'GET',
  });
}
export function getSectorsTree() {
  return fetchResource('api/taxonomy/sectors?tree=true', {
    method: 'GET',
  });
}
export function getTurnoverEtineraries() {
  return fetchResource('api/taxonomy/etinerary_turnovers', {
    method: 'GET',
  });
}
export function getPartnerExpertises() {
  return fetchResource('api/taxonomy/partner_expertises', {
    method: 'GET',
  });
}
export function getPartnerSectors() {
  return fetchResource('api/taxonomy/partner_sectors?tree=false', {
    method: 'GET',
  });
}
export function getPartnerSectorsTree() {
  return fetchResource('api/taxonomy/partner_sectors?tree=true', {
    method: 'GET',
  });
}
export function getDimensions() {
  return fetchResource('api/taxonomy/dimensions', {
    method: 'GET',
  });
}
export function getStartupStages() {
  return fetchResource('api/taxonomy/startup_stages', {
    method: 'GET',
  });
}
export function getEtineraryCompanyTypes() {
  return fetchResource('api/taxonomy/etinerary_company_types', {
    method: 'GET',
  });
}
