import {
  ADMIN_GET_TEMPLATE_OPTIONS_START,
  ADMIN_GET_TEMPLATE_OPTIONS_ERROR,
  ADMIN_GET_TEMPLATE_OPTIONS_SUCCESS,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateOptions';

const initialState = {
  loading: false,
  error: null,
  options: null,
};

const actionsMap = {
  [ADMIN_GET_TEMPLATE_OPTIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    options: null,
  }),
  [ADMIN_GET_TEMPLATE_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    options: null,
  }),
  [ADMIN_GET_TEMPLATE_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    options: action.data.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
