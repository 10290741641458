import useFetch from '@app/services/useFetch';
import { MessageTypes } from '@src/js/constants/entities';
import { purifyHtml } from '@src/js/utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { showToastrError } from '@app/store/global/actions';
import { useDispatch } from 'react-redux';
import { MeetingTypes } from '@app/components/global/cards/NetworkingCard';
import { ChatMessage } from '@app/models/Chat';

type Props = {
  bigStyle?: boolean,
  message: ChatMessage,
}

const MessageBox = ({
  message, bigStyle,
}: Props) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<MeetingTypes|null>(null);
  const [disableMeeting, setDisableMeeting] = useState<boolean>(false);
  const messageData = message.messageData;

  const { request, loading } = useFetch(
    `api/event/community/meeting/${ messageData?.meetingId }`,
    {
      method: 'PATCH',
      body: { status },
    },
    false,
    () => setDisableMeeting(true),
    () => dispatch(showToastrError())
  );

  useEffect(() => {
    if (status) {
      request();
      setStatus(null);
    }
  }, [status, request]);

  const addBigClass = bigStyle ? '--big' : '';
  const className = message.user === 'you' ? 'message-box message-box__right' : 'message-box message-box__left';
  return (
    <div className={ className } key={ message.id }>
      <div className='message-content__header'>
        { message.user === 'you' ? (<FormattedMessage id='you' />) : message.user }
      </div>
      <div className={ `relative message-content__body${ addBigClass } blue-a` }>
        <pre className='blue-a' dangerouslySetInnerHTML={ { __html: purifyHtml(message.body) } } />
        { messageData && messageData.type === MessageTypes.MEETING
        && messageData.meetingId && !disableMeeting && (
          <div className='message-content__buttons'>
            <button
              className='message-content__button-accept'
              type='button'
              onClick={ () => setStatus('accepted') }
              disabled={ loading }
            >
              <FormattedMessage id='accept' />
            </button>
            <button
              className='message-content__button-reject'
              type='button'
              onClick={ () => setStatus('rejected') }
              disabled={ loading }
            >
              <FormattedMessage id='decline' />
            </button>
          </div>
        )}
      </div>
      <div className={ `message-content__date${ addBigClass }` }>{ message.created }</div>
    </div>
  );
};

export default MessageBox;
