export const GET_MATCHMAKING_MEMBERS_START = 'GET_MATCHMAKING_MEMBERS_START';
export const GET_MATCHMAKING_MEMBERS_ERROR = 'GET_MATCHMAKING_MEMBERS_ERROR';
export const GET_MATCHMAKING_MEMBERS_SUCCESS = 'GET_MATCHMAKING_MEMBERS_SUCCESS';

export function getMatchmakingMembers(id, page, limit) {
  return {
    type: GET_MATCHMAKING_MEMBERS_START,
    id,
    page,
    limit,
  };
}
