import fetchResource from '@src/js/api/fetch-resource';
import { parsePhoneNumber } from 'react-phone-number-input';

export function saveMembershipCompany(saveFormData, hashTemplate, isAdminForm) {
  return fetchResource(`api/${ isAdminForm ? 'admin/' : '' }membership/${ hashTemplate }`, {
    method: 'PUT',
    body: saveFormData,
  });
}

export function docusign({ mobilePhone, terms, hash }) {
  return fetchResource(`api/membership/sign/${ hash }`, {
    method: 'POST',
    body: {
      mobilePhone: parsePhoneNumber(mobilePhone)?.nationalNumber,
      countryCode: parsePhoneNumber(mobilePhone)?.countryCallingCode,
      terms,
    },
  });
}
