import {
  GET_TEMPLATES_REQUESTED,
  GET_TEMPLATES_SUCCEEDED,
  GET_TEMPLATES_FAILED, UPDATE_TEMPLATE_REQUESTED, UPDATE_TEMPLATE_SUCCEEDED, UPDATE_TEMPLATE_FAILED,
} from '@src/js/oneplatform/privateMarketNetwork/container/constants';

function getTemplatesRequested() {
  return {
    type: GET_TEMPLATES_REQUESTED,
  };
}

function getTemplatesSucceeded(data) {
  return {
    type: GET_TEMPLATES_SUCCEEDED,
    payload: data,
  };
}

function getTemplatesFailed(error) {
  return {
    type: GET_TEMPLATES_FAILED,
    payload: error,
  };
}

function updateTemplateRequested(payload, id) {
  return {
    type: UPDATE_TEMPLATE_REQUESTED,
    payload,
    id,
  };
}

function updateTemplateSucceeded(payload) {
  return {
    type: UPDATE_TEMPLATE_SUCCEEDED,
    payload,
  };
}

function updateTemplateFailed(error) {
  return {
    type: UPDATE_TEMPLATE_FAILED,
    payload: error,
  };
}

export {
  getTemplatesRequested,
  getTemplatesSucceeded,
  getTemplatesFailed,
  updateTemplateRequested,
  updateTemplateSucceeded,
  updateTemplateFailed,
};
