import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_MOST_VIEWED_OPPORTUNITIES_START,
  GET_MOST_VIEWED_OPPORTUNITIES_ERROR,
  GET_MOST_VIEWED_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getMostViewedOpportunities';
import * as api from '@src/js/marketplace/api/getMostViewedOpportunities';

function* getMostViewedOpportunity(options) {
  try {
    const data = yield call(() => api.getMostViewedOpportunities(options));
    yield put({ type: GET_MOST_VIEWED_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MOST_VIEWED_OPPORTUNITIES_ERROR, error });
  }
}

function* getMostViewedOpportunityWatcher() {
  yield takeLatest(GET_MOST_VIEWED_OPPORTUNITIES_START, getMostViewedOpportunity);
}

export default [
  getMostViewedOpportunityWatcher(),
];
