import ReactGA from 'react-ga4';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { Company } from '@src/js/constants/entities';
import { EventGa, FieldsObject } from '@app/models/EventGa';

export class ReactGaService {
    public static instance = new ReactGaService();

    private defaultFields: FieldsObject = {};

    constructor() {
      ReactGA.initialize([{
        trackingId: loadEnvVariable('GA_TRACKING_ID'),
        gaOptions: {
          siteSpeedSampleRate: 100,
          cookieFlags: 'Secure',
        },
      }]);
    }

    defaultValues = (userData: any) => {
      if (userData?.companyType !== null) {
        if (Company.TYPE_GROWTH === userData?.companyType) {
          this.defaultFields.dimension2 = 'Growth';
        } else if (Company.TYPE_ETINERARY === userData?.companyType) {
          this.defaultFields.dimension2 = 'DM';
        } else if (Company.TYPE_GROWTH_STARTUP === userData?.companyType) {
          this.defaultFields.dimension2 = 'Growth Startup';
        }
      } else if (userData?.partnerType !== null) {
        this.defaultFields.dimension2 = 'Partner';
      }
    }

    event = (event: EventGa) => {
      const fieldsObject = {
        ...event.fieldsObject,
        ...this.defaultFields,
      };
      // eslint-disable-next-line no-underscore-dangle
      ReactGA._gaCommandSendEvent(event.eventCategory, event.eventAction, event.label, undefined, fieldsObject);
    }

    pageView = (data: FieldsObject) => {
      ReactGA.send({ hitType: 'pageview', ...data });
    }
}
