export const ADMIN_GET_USER_START = 'ADMIN_GET_USER_START';
export const ADMIN_GET_USER_ERROR = 'ADMIN_GET_USER_ERROR';
export const ADMIN_GET_USER_SUCCESS = 'ADMIN_GET_USER_SUCCESS';
export const ADMIN_CREATE_USER_START = 'ADMIN_CREATE_USER_START';
export const ADMIN_CREATE_USER_ERROR = 'ADMIN_CREATE_USER_ERROR';
export const ADMIN_CREATE_USER_SUCCESS = 'ADMIN_CREATE_USER_SUCCESS';
export const ADMIN_EDIT_USER_START = 'ADMIN_EDIT_USER_START';
export const ADMIN_EDIT_USER_ERROR = 'ADMIN_EDIT_USER_ERROR';
export const ADMIN_EDIT_USER_SUCCESS = 'ADMIN_EDIT_USER_SUCCESS';
export const ADMIN_DISABLE_USER_START = 'ADMIN_DISABLE_USER_START';
export const ADMIN_DISABLE_USER_ERROR = 'ADMIN_DISABLE_USER_ERROR';
export const ADMIN_DISABLE_USER_SUCCESS = 'ADMIN_DISABLE_USER_SUCCESS';
export const ADMIN_DISABLE_USER_NOTIFICATIONS_START = 'ADMIN_DISABLE_USER_NOTIFICATIONS_START';
export const ADMIN_DISABLE_USER_NOTIFICATIONS_ERROR = 'ADMIN_DISABLE_USER_NOTIFICATIONS_ERROR';
export const ADMIN_DISABLE_USER_NOTIFICATIONS_SUCCESS = 'ADMIN_DISABLE_USER_NOTIFICATIONS_SUCCESS';
export const ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START = 'ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START';
export const ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_ERROR = 'ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_ERROR';
export const ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_SUCCESS = 'ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_SUCCESS';

export function adminGetUser(id) {
  return {
    type: ADMIN_GET_USER_START,
    id,
  };
}

export function adminEditUser(user, history) {
  return {
    type: ADMIN_EDIT_USER_START,
    user,
    history,
  };
}

export function adminCreateUser(user, history) {
  return {
    type: ADMIN_CREATE_USER_START,
    user,
    history,
  };
}

export function adminDisableUserAction(userId) {
  return {
    type: ADMIN_DISABLE_USER_START,
    userId,
  };
}

export function adminDisableUserNotificationsAction(userId) {
  return {
    type: ADMIN_DISABLE_USER_NOTIFICATIONS_START,
    userId,
  };
}

export function changeCompanyCreationPermissionAction(data) {
  return {
    type: ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START,
    data,
  };
}
