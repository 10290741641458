import { getTopicListNavigation } from '@app/store/content/topic/actions';
import { TopicState } from '@app/store/content/topic/reducer';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { isGrowth, isGrowthStartup } from '@src/js/helpers/helpers';
import { RootState } from '@src/js/store';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const TopicNavigation = () => {
  const dispatch = useDispatch();
  const language = selectActiveLanguage();
  const { topicListNavigation } = useSelector<RootState, TopicState>((state) => state.topic);

  useEffect(() => {
    dispatch(getTopicListNavigation());
  }, [dispatch, language]);

  if (
    (!isGrowth() && !isGrowthStartup())
    || topicListNavigation?.length === 0
  ) return null;

  return (
    <nav className='secondary-navigation topic-navigation' onClick={ () => null } role='presentation'>
      <div className='container topic-navigation'>
        <div className='row'>
          <div className='secondary-navigation__links'>
            <div className='topic-navigation__title'>
              <FormattedMessage id='navigation.topic' />
            </div>
            { topicListNavigation.map((topic) => (
              <NavLink
                key={ topic.slug }
                activeClassName='secondary-navigation__links-item--active'
                className='secondary-navigation__links-item'
                to={ withLang(routeCodes.TOPIC_PAGE).replace(':slug', topic.slug) }
              >
                {topic.title}
              </NavLink>
            )) }
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopicNavigation;
