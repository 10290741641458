import {
  ADMIN_GET_LIST_PROCESSES_REQUESTED,
  ADMIN_GET_LIST_PROCESSES_SUCCEEDED,
  ADMIN_GET_LIST_PROCESSES_FAILED,
  ADMIN_GET_LIST_TEMPLATES_REQUESTED,
  ADMIN_GET_LIST_TEMPLATES_SUCCEEDED,
  ADMIN_GET_LIST_TEMPLATES_FAILED,
  GET_FORM_COMPANY_OPTIONS_REQUESTED,
  GET_FORM_COMPANY_OPTIONS_SUCCEEDED,
  GET_FORM_COMPANY_OPTIONS_FAILED,
  SUBMIT_MEMBERSHIP_FORM_REQUESTED,
  SUBMIT_MEMBERSHIP_FORM_SUCCEEDED,
  SUBMIT_MEMBERSHIP_FORM_FAILED,
  ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED,
  ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED,
  ADMIN_GET_TEMPLATE_OPTIONS_FAILED,
  ADMIN_APPROVE_PROCESS_REQUESTED,
  ADMIN_APPROVE_PROCESS_SUCCEEDED,
  ADMIN_APPROVE_PROCESS_FAILED,
  HANDLE_TEMPLATE_TRAININGS_REQUESTED,
  HANDLE_TEMPLATE_TRAININGS_SUCCEDED,
  HANDLE_TEMPLATE_TRAININGS_FAILED,
  CREATE_TEMPLATE_CLEAR,
} from '@src/js/oneplatform/membership/pages/home/container/constants';

function adminGetListProcessesRequested(filters) {
  return {
    type: ADMIN_GET_LIST_PROCESSES_REQUESTED,
    filters,
  };
}
function adminGetListProcessesSucceeded(data) {
  return {
    type: ADMIN_GET_LIST_PROCESSES_SUCCEEDED,
    payload: data,
  };
}
function adminGetListProcessesFailed(error) {
  return {
    type: ADMIN_GET_LIST_PROCESSES_FAILED,
    payload: error,
  };
}
function adminGetListTemplatesRequested(page) {
  return {
    type: ADMIN_GET_LIST_TEMPLATES_REQUESTED,
    page,
  };
}
function adminGetListTemplatesSucceeded(data) {
  return {
    type: ADMIN_GET_LIST_TEMPLATES_SUCCEEDED,
    payload: data,
  };
}
function adminGetListTemplatesFailed(error) {
  return {
    type: ADMIN_GET_LIST_TEMPLATES_FAILED,
    payload: error,
  };
}

function getFormCompanyOptionsRequested(formOptions) {
  return {
    type: GET_FORM_COMPANY_OPTIONS_REQUESTED,
    formOptions,
  };
}

function getFormCompanyOptionsSucceded(data) {
  return {
    type: GET_FORM_COMPANY_OPTIONS_SUCCEEDED,
    payload: data,
  };
}

function getFormCompanyOptionsFailed(error) {
  return {
    type: GET_FORM_COMPANY_OPTIONS_FAILED,
    paylaod: error,
  };
}

function submitTemplateFormRequested(formData) {
  return {
    type: SUBMIT_MEMBERSHIP_FORM_REQUESTED,
    formData,
  };
}

function submitTemplateFormSucceeded() {
  return {
    type: SUBMIT_MEMBERSHIP_FORM_SUCCEEDED,
  };
}

function submitTemplateFormFailed(error) {
  return {
    type: SUBMIT_MEMBERSHIP_FORM_FAILED,
    payload: error,
  };
}
function adminGetTemplateOptionsRequested() {
  return {
    type: ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED,
  };
}
function adminGetTemplateOptionsSucceeded(data) {
  return {
    type: ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED,
    payload: data,
  };
}
function adminGetTemplateOptionsFailed() {
  return {
    type: ADMIN_GET_TEMPLATE_OPTIONS_FAILED,
  };
}

function adminApproveProcessRequested(hash) {
  return {
    type: ADMIN_APPROVE_PROCESS_REQUESTED,
    hash,
  };
}

function adminApproveProcessSucceeded() {
  return {
    type: ADMIN_APPROVE_PROCESS_SUCCEEDED,
  };
}

function adminApproveProcessFailed(error) {
  return {
    type: ADMIN_APPROVE_PROCESS_FAILED,
    payload: error,
  };
}

function handleTemplateTrainingRequested(payload) {
  return {
    type: HANDLE_TEMPLATE_TRAININGS_REQUESTED,
    payload,
  };
}
function handleTemplateTrainingSucceeded(payload) {
  return {
    type: HANDLE_TEMPLATE_TRAININGS_SUCCEDED,
    payload,
  };
}
function handleTemplateTrainingFailed(error) {
  return {
    type: HANDLE_TEMPLATE_TRAININGS_FAILED,
    payload: error,
  };
}
function createTemplateClear() {
  return {
    type: CREATE_TEMPLATE_CLEAR,
  };
}

export {
  adminGetListProcessesRequested,
  adminGetListProcessesSucceeded,
  adminGetListProcessesFailed,
  adminGetListTemplatesRequested,
  adminGetListTemplatesSucceeded,
  adminGetListTemplatesFailed,
  getFormCompanyOptionsRequested,
  getFormCompanyOptionsSucceded,
  getFormCompanyOptionsFailed,
  submitTemplateFormRequested,
  submitTemplateFormSucceeded,
  submitTemplateFormFailed,
  adminGetTemplateOptionsRequested,
  adminGetTemplateOptionsSucceeded,
  adminGetTemplateOptionsFailed,
  adminApproveProcessRequested,
  adminApproveProcessSucceeded,
  adminApproveProcessFailed,
  handleTemplateTrainingRequested,
  handleTemplateTrainingSucceeded,
  handleTemplateTrainingFailed,
  createTemplateClear,
};
