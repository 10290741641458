export const SEND_REQUEST_START = 'SEND_REQUEST_START';
export const SEND_REQUEST_ERROR = 'SEND_REQUEST_ERROR';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';

export function sendRequestInfo(data) {
  return {
    type: SEND_REQUEST_START,
    data,
  };
}
