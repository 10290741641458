import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_MY_LIBRARY_START,
  GET_MY_LIBRARY_ERROR,
  GET_MY_LIBRARY_SUCCESS,
  CHANGE_LIBRARY_STATUS_START,
  CHANGE_LIBRARY_STATUS_ERROR,
  CHANGE_LIBRARY_STATUS_SUCCESS,
} from '@src/js/actions/library/partnerLibraries';

import * as api from '@src/js/api/library/partnerLibraries';

function* getPartnerLibraries(options) {
  try {
    const data = yield call(() => api.getPartnerLibraries(options.filters));
    yield put({ type: GET_MY_LIBRARY_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MY_LIBRARY_ERROR, error });
  }
}

function* changeLibraryStatus(options) {
  try {
    const data = yield call(() => api.changeLibraryStatus(options.library));
    yield put({ type: CHANGE_LIBRARY_STATUS_SUCCESS, data });
  } catch (error) {
    yield put({ type: CHANGE_LIBRARY_STATUS_ERROR, error });
  }
}

function* getPartnerLibrariesWatcher() {
  yield takeLatest(GET_MY_LIBRARY_START, getPartnerLibraries);
}
function* changeLibraryStatusWatcher() {
  yield takeLatest(CHANGE_LIBRARY_STATUS_START, changeLibraryStatus);
}

export default [
  getPartnerLibrariesWatcher(),
  changeLibraryStatusWatcher(),
];
