import { ThemeColors } from '@src/_v2/js/constants/index';

const root = {
  color: ThemeColors.lightGrey,
  display: 'flex',
  height: 22,
  alignItems: 'center',
};

const active = {
  color: ThemeColors.blue,
  zIndex: 1,
};

const circle = {
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: 'currentColor',
};

const circleActive = {
  width: 16,
  height: 16,
};

const completed = {
  color: ThemeColors.blue,
  zIndex: 1,
  fontSize: 18,
};

export default {
  root,
  active,
  circle,
  circleActive,
  completed,
};
