export const COMPANY_CERTIFICATION_START = 'COMPANY_CERTIFICATION_START';
export const COMPANY_CERTIFICATION_ERROR = 'COMPANY_CERTIFICATION_ERROR';
export const COMPANY_CERTIFICATION_SUCCESS = 'COMPANY_CERTIFICATION_SUCCESS';
export const REQUEST_CERTIFICATION_START = 'REQUEST_CERTIFICATION_START';
export const REQUEST_CERTIFICATION_ERROR = 'REQUEST_CERTIFICATION_ERROR';
export const REQUEST_CERTIFICATION_SUCCESS = 'REQUEST_CERTIFICATION_SUCCESS';

export function toggleCompanyCertification(slug) {
  return {
    type: COMPANY_CERTIFICATION_START,
    slug,
  };
}

export function requestCertificate(notificationType) {
  return {
    type: REQUEST_CERTIFICATION_START,
    notificationType,
  };
}
