export const GET_PROGRAMMES_START = 'GET_PROGRAMMES_START';
export const GET_PROGRAMMES_ERROR = 'GET_PROGRAMMES_ERROR';
export const GET_PROGRAMMES_SUCCESS = 'GET_PROGRAMMES_SUCCESS';

export const CREATE_PROGRAMME_START = 'CREATE_PROGRAMME_START';
export const CREATE_PROGRAMME_ERROR = 'CREATE_PROGRAMME_ERROR';
export const CREATE_PROGRAMME_SUCCESS = 'CREATE_PROGRAMME_SUCCESS';

export function adminGetProgrammes(filters) {
  return {
    type: GET_PROGRAMMES_START,
    filters,
  };
}

export function adminCreateProgramme(programme) {
  return {
    type: CREATE_PROGRAMME_START,
    programme,
  };
}
