const scope = 'DOCUMENTS_AREA';

export const CREATE_FOLDER_START = `${ scope }/CREATE_FOLDER_START`;
export const RENAME_FOLDER_START = `${ scope }/RENAME_FOLDER_START`;
export const FILE_LIST_START = `${ scope }/FILE_LIST_START`;
export const FILE_LIST_FAIL = `${ scope }/FILE_LIST_FAIL`;
export const FILE_LIST_SUCCESS = `${ scope }/FILE_LIST_SUCCESS`;
export const MOVE_FILE_LIST_START = `${ scope }/MOVE_FILE_LIST_START`;
export const MOVE_FILE_LIST_FAIL = `${ scope }/MOVE_FILE_FAIL`;
export const MOVE_FILE_LIST_SUCCESS = `${ scope }/MOVE_FILE_LIST_SUCCESS`;
export const CREATE_FILE_START = `${ scope }/CREATE_FILE_START`;
export const MOVE_FILE_START = `${ scope }/MOVE_FILE_START`;
export const DELETE_FILE_START = `${ scope }/DELETE_FILE_START`;
export const FOLDER_SUCCESS = `${ scope }/FOLDER_SUCCESS`;
export const FILE_SUCCESS = `${ scope }/FILE_SUCCESS`;
export const FOLDER_FAIL = `${ scope }/FOLDER_FAIL`;
export const FILE_FAIL = `${ scope }/FILE_FAIL`;
export const SET_PERMISSIONS_START = `${ scope }/SET_PERMISSIONS_START`;
export const SET_PERMISSIONS_SUCCESS = `${ scope }/SET_PERMISSIONS_SUCCESS`;
export const SET_PERMISSIONS_FAIL = `${ scope }/SET_PERMISSIONS_FAIL`;
export const GET_PERMISSIONS_START = `${ scope }/GET_PERMISSIONS_START`;
export const GET_PERMISSIONS_SUCCESS = `${ scope }/GET_PERMISSIONS_SUCCESS`;
export const GET_PERMISSIONS_FAIL = `${ scope }/GET_PERMISSIONS_FAIL`;
export const GET_LIST_START = `${ scope }/GET_LIST_START`;
export const GET_LIST_SUCCESS = `${ scope }/GET_LIST_SUCCESS`;
export const GET_LIST_FAIL = `${ scope }/GET_LIST_FAIL`;
export const DOWNLOAD_TASK_START = `${ scope }/DOWNLOAD_TASK_START`;
export const DOWNLOAD_TASK_CANCEL = `${ scope }/DOWNLOAD_TASK_CANCEL`;
export const DOWNLOAD_TASK_CONTINUE = `${ scope }/DOWNLOAD_TASK_CONTINUE`;
export const DOWNLOAD_TASK_FORCE_CANCEL = `${ scope }/DOWNLOAD_TASK_FORCE_CANCEL`;
export const ASSOCIATE_SIGNED_DOCUMENT_START = `${ scope }/ASSOCIATE_SIGNED_DOCUMENT_START`;
export const ASSOCIATE_SIGNED_DOCUMENT_SUCCESS = `${ scope }/ASSOCIATE_SIGNED_DOCUMENT_SUCCESS`;
export const ASSOCIATE_SIGNED_DOCUMENT_FAIL = `${ scope }/ASSOCIATE_SIGNED_DOCUMENT_FAIL`;

export function createFolder(dataRoomType, slug, data, folderId) {
  return {
    type: CREATE_FOLDER_START,
    slug,
    data,
    dataRoomType,
    folderId,
  };
}

export function renameFolder(slug, id, data, dataRoomType, folderId) {
  return {
    type: RENAME_FOLDER_START,
    slug,
    id,
    data,
    dataRoomType,
    folderId,
  };
}

export function fileList(slug, dataRoomType, folderId, search, training) {
  return {
    type: FILE_LIST_START,
    slug,
    dataRoomType,
    folderId,
    search,
    training,
  };
}

export function moveFileList(slug, dataRoomType, folderId) {
  return {
    type: MOVE_FILE_LIST_START,
    slug,
    dataRoomType,
    folderId,
  };
}

export function createFile(files, slug, id, dataRoomType, folderId) {
  return {
    type: CREATE_FILE_START,
    files,
    slug,
    id,
    dataRoomType,
    folderId,
  };
}

export function moveFile({
  slug, id, parent, isFolder, dataRoomType, folderId,
}) {
  return {
    type: MOVE_FILE_START,
    slug,
    id,
    data: { parent },
    dataRoomType,
    isFolder,
    folderId,
  };
}

export function deleteFile(slug, id, dataRoomType, isFolder, folderId) {
  return {
    type: DELETE_FILE_START,
    slug,
    id,
    dataRoomType,
    isFolder,
    folderId,
  };
}

export function getPermissions(options) {
  return {
    type: GET_PERMISSIONS_START,
    ...options,
  };
}

export function setPermissions(slug, id, dataRoomType, entity, data) {
  return {
    type: SET_PERMISSIONS_START,
    slug,
    id,
    dataRoomType,
    entity,
    data,
  };
}

export function getDataRoomList() {
  return {
    type: GET_LIST_START,
  };
}

export function downloadTask(task) {
  return {
    type: DOWNLOAD_TASK_START,
    task,
  };
}

export function associateSignedDocument(companySlug, dataRoomType) {
  return {
    type: ASSOCIATE_SIGNED_DOCUMENT_START,
    companySlug,
    dataRoomType,
  };
}
