import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { ReactGaService } from '@app/services/ReactGaService';
import { EventGa } from '@app/models/EventGa';
import { defaultPath } from '@src/js/constants/routes';
import { getInitInfo } from '@src/js/actions/initInfo';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { ReduxToaster } from '@src/_v2/js/components/Toaster/style';
import { onboardingInit } from '@src/js/actions/onboarding/onboardingBasic';
import EOPRouter from './EOPRouter';

class App extends Component {
  static propTypes = {
    initInfoError: PropTypes.string,
    initInfoLoading: PropTypes.bool,
    dispatch: PropTypes.func,
    localizationMessages: PropTypes.array,
    location: PropTypes.object,
    onboardingLoading: PropTypes.bool,
    onboardingUser: PropTypes.object,
    userData: PropTypes.object,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getInitInfo());
    dispatch(onboardingInit());
  }

  componentDidUpdate(prevProps) {
    const { location, userData } = this.props;
    const query = new URLSearchParams(location.search);
    const tracking = query.get('tracking');

    if (userData?.uuid !== prevProps.userData?.uuid) {
      ReactGaService.instance.defaultValues(userData);
      if (tracking) {
        ReactGaService.instance.event(new EventGa('Email Tracking', 'email_tracking', tracking));
      }
    }

    if ((location.pathname).search('/self-registration') === -1) {
      const path = (location.pathname).search('/search') ? location.pathname + location.search : location.pathname;
      ReactGaService.instance.pageView({ page: path, title: document.title });
    }
  }

  render() {
    const {
      initInfoLoading, initInfoError, localizationMessages, onboardingUser, onboardingLoading,
    } = this.props;

    return (
      <div>
        <ReduxToaster
          timeOut={ 5000 }
          preventDuplicates
          position='top-center'
          transitionIn='fadeIn'
          transitionOut='fadeOut'
          closeOnToastrClick
        />

        { (initInfoLoading || initInfoError || onboardingLoading) && <Loader /> }

        { localizationMessages && onboardingUser && (
          <Switch>
            <Route path={ defaultPath } render={ () => <EOPRouter /> } />
          </Switch>
        ) }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initInfoLoading: state.initInfo.initInfoLoading,
  initInfoError: state.initInfo.initInfoError,
  localizationMessages: state.initInfo.localizationMessages,
  onboardingUser: state.onboardingBasic.onboardingUser,
  onboardingLoading: state.onboardingBasic.loading,
  userData: state.initInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(connectWrapper);
