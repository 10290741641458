import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_INDEX_NEWS_START,
  ADMIN_GET_INDEX_NEWS_ERROR,
  ADMIN_GET_INDEX_NEWS_SUCCESS,
} from '@src/js/actions/news/adminNews';

import * as api from '@src/js/api/news/adminNews';

function* adminGetNews(options) {
  try {
    const data = yield call(() => api.adminGetNews(options.filters));
    yield put({ type: ADMIN_GET_INDEX_NEWS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_INDEX_NEWS_ERROR, error });
  }
}

function* adminGetNewsWatcher() {
  yield takeLatest(ADMIN_GET_INDEX_NEWS_START, adminGetNews);
}

export default [
  adminGetNewsWatcher(),
];
