export const PARTNER_DOCUMENTS_PERMISSIONS_START = 'PARTNER_DOCUMENTS_PERMISSIONS_START';
export const PARTNER_DOCUMENTS_PERMISSIONS_ERROR = 'PARTNER_DOCUMENTS_PERMISSIONS_ERROR';
export const PARTNER_DOCUMENTS_PERMISSIONS_SUCCESS = 'PARTNER_DOCUMENTS_PERMISSIONS_SUCCESS';

export function partnerDocumentsPermissions(slug) {
  return {
    type: PARTNER_DOCUMENTS_PERMISSIONS_START,
    slug,
  };
}
