export const ADMIN_GET_INDEX_PROCESSES_START = 'ADMIN_GET_INDEX_PROCESSES_START';
export const ADMIN_GET_INDEX_PROCESSES_ERROR = 'ADMIN_GET_INDEX_PROCESSES_ERROR';
export const ADMIN_GET_INDEX_PROCESSES_SUCCESS = 'ADMIN_GET_INDEX_PROCESSES_SUCCESS';
export const ADMIN_GET_PROCESS_START = 'ADMIN_GET_PROCESS_START';
export const ADMIN_GET_PROCESS_ERROR = 'ADMIN_GET_PROCESS_ERROR';
export const ADMIN_GET_PROCESS_SUCCESS = 'ADMIN_GET_PROCESS_SUCCESS';
export const ADMIN_UPDATE_PROCESS_START = 'ADMIN_UPDATE_PROCESS_START';
export const ADMIN_UPDATE_PROCESS_ERROR = 'ADMIN_UPDATE_PROCESS_ERROR';
export const ADMIN_UPDATE_PROCESS_SUCCESS = 'ADMIN_UPDATE_PROCESS_SUCCESS';
export const ADMIN_CREATE_COMPANY_ONBOARDING_START = 'ADMIN_CREATE_COMPANY_ONBOARDING_START';
export const ADMIN_CREATE_COMPANY_ONBOARDING_ERROR = 'ADMIN_CREATE_COMPANY_ONBOARDING_ERROR';
export const ADMIN_CREATE_COMPANY_ONBOARDING_SUCCESS = 'ADMIN_CREATE_COMPANY_ONBOARDING_SUCCESS';
export const ADMIN_DELETE_COMPANY_ONBOARDING_START = 'ADMIN_DELETE_COMPANY_ONBOARDING_START';
export const ADMIN_DELETE_COMPANY_ONBOARDING_ERROR = 'ADMIN_DELETE_COMPANY_ONBOARDING_ERROR';
export const ADMIN_DELETE_COMPANY_ONBOARDING_SUCCESS = 'ADMIN_DELETE_COMPANY_ONBOARDING_SUCCESS';

export function adminGetProcesses(filters) {
  return {
    type: ADMIN_GET_INDEX_PROCESSES_START,
    filters,
  };
}

export function getAdminProcess(hash) {
  return {
    type: ADMIN_GET_PROCESS_START,
    hash,
  };
}

export function submitAdminOnboarding(data) {
  return {
    type: ADMIN_UPDATE_PROCESS_START,
    data,
  };
}

export function adminCreateCompany(hash, filters, crmId) {
  return {
    type: ADMIN_CREATE_COMPANY_ONBOARDING_START,
    hash,
    filters,
    crmId,
  };
}

export function adminDeleteCompany(data) {
  return {
    type: ADMIN_DELETE_COMPANY_ONBOARDING_START,
    data,
  };
}
