import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_TURNOVERS_START,
  GET_TURNOVERS_ERROR,
  GET_TURNOVERS_SUCCESS,
} from '@src/js/actions/taxonomy/turnovers';

import * as api from '@src/js/api/taxonomy/turnovers';

function* getTurnovers() {
  try {
    const data = yield call(() => api.getTurnovers());
    yield put({ type: GET_TURNOVERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TURNOVERS_ERROR, error });
  }
}

export function* getTurnoversWatcher() {
  yield takeLatest(GET_TURNOVERS_START, getTurnovers);
}

export default [
  getTurnoversWatcher(),
];
