import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MultiValue, SingleValue } from 'react-select';
import { cloneDeep } from 'lodash';
import InputSelect from './InputSelect';
import { SelectValue, ValueObj, isSelectValueObj } from './select';

type Props = {
  name: string,
  errors: object,
  control: Control<FieldValues>,
  setValue: UseFormSetValue<FieldValues>
  defaultValue: SelectValue,
  valueOptions: ValueObj[],
  labels?: string[],
  placeholders?: string[],
  translate?: boolean,
  classes?: string[],
  maxLevels: number,
  requiredLevels?: number,
  disabledLevels?: number,
  prefix: string,
}

function isValueObj(value: ValueObj|null|undefined): value is ValueObj {
  return typeof value === 'object' && value !== null;
}

const MultiSelect = ({
  valueOptions, maxLevels = 4, name, control, errors, labels, defaultValue,
  placeholders, requiredLevels = 0, classes, setValue, translate = true,
  disabledLevels = 0, prefix,
}: Props) => {
  const intl = useIntl();
  const [allOptions, setAllOptions] = useState<ValueObj[][]>([]);
  const [defaultValues, setDefaultValues] = useState<(ValueObj|null|undefined)[]>([]);

  const findOption = useCallback((id: SelectValue) => cloneDeep(valueOptions).find(el => el.id === id), [valueOptions]);

  const updateDefaults = useCallback(() => {
    const values = [findOption(defaultValue)];
    let parentId = findOption(defaultValue)?.parentId;
    for (;parentId;) {
      values.unshift(findOption(parentId));
      parentId = findOption(parentId)?.parentId;
    }
    return values;
  }, [defaultValue, findOption]);
  const buildOptions = (index: number, value: ValueObj|null) => {
    const options = [...allOptions];
    if (value && index < maxLevels - 1) {
      const values = valueOptions.filter(el => el.parentId === value?.id);
      if (values.length > 0) {
        options[index + 1] = values;
      }
    } else {
      options.splice(index + 1);
    }
    options.length = index + 2;
    setAllOptions(options);
  };

  const onChange = (index: number, value: SingleValue<SelectValue>|MultiValue<SelectValue>) => {
    if (!isSelectValueObj(value) && value !== null) return;
    const values = [...defaultValues];
    buildOptions(index, value);
    values[index] = value;
    setValue(`${ name }.${ index }`, value, { shouldValidate: true });
    for (let i = index + 1; i < maxLevels; i++) {
      values[i] = null;
      setValue(`${ name }.${ i }`, null, { shouldValidate: true });
    }
    setDefaultValues(values);
  };

  useEffect(() => {
    const options = [valueOptions.filter(el => el.parentId === null)];
    if (defaultValue) {
      const values = updateDefaults();
      values.forEach((value, index) => {
        const opts = valueOptions.filter(el => el.parentId === value?.id);
        if (opts.length) options[index + 1] = opts;
      });
      setDefaultValues(values);
    }
    setAllOptions(options);
  }, [valueOptions, defaultValue, updateDefaults]);

  return (
    Object.keys(allOptions).map((item, index) => {
      const options = translate
        ? allOptions[index]
          .map(opt => ({ ...opt, label: intl.formatMessage({ id: `${ prefix }.${ opt.name }` }), value: opt.name }))
        : allOptions[index];

      const defaultVal = defaultValues ? defaultValues[index] : null;
      if (defaultValues && isValueObj(defaultVal)) {
        if (translate) {
          defaultVal.label = intl.formatMessage({ id: `${ prefix }.${ defaultValues[index]?.name }` });
        }
        defaultVal.value = defaultVal.id;
      }

      return (
        <InputSelect
          key={ item }
          name={ `${ name }.${ index }` }
          label={ labels?.[index] ?? '' }
          control={ control }
          errors={ errors }
          translate={ translate }
          className={ classes?.[index] ?? '' }
          required={ index < requiredLevels }
          disabled={ index < disabledLevels }
          options={ options }
          defaultValue={ defaultVal }
          placeholder={ placeholders?.[index] ?? '' }
          onChange={ e => onChange(index, e) }
          simpleValue={ false }
        />
      );
    })
  );
};

export default MultiSelect;
