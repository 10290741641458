/*
 *
 * Trainings messages
 *
 */

import { defineMessages } from 'react-intl';

const scope = 'trainings';

const messages = defineMessages({
  eventDate: {
    id: `${ scope }.eventDate`,
    defaultMessage: 'event date',
  },
  eventDateBefore: {
    id: `${ scope }.eventDateBefore`,
    defaultMessage: 'event date before',
  },
  eventDateAfter: {
    id: `${ scope }.eventDateAfter`,
    defaultMessage: 'event date after',
  },
  notification: {
    id: `${ scope }.notification`,
    defaultMessage: 'notification active or not',
  },
  addAllTrainingsFiltered: {
    id: `${ scope }.addAllTrainingsFiltered`,
    defaultMessage: 'Add all filteres trainings',
  },
  selectAttendingTrainingsModalTitle: {
    id: `${ scope }.selectAttendingTrainingsModalTitle`,
    defaultMessage: 'Add all filteres trainings',
  },
});

export default messages;
