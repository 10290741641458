import fetchResource from '@src/js/api/fetch-resource';
import { User } from '@src/js/constants/entities';

export function adminCreateCompany(company, userType) {
  const urlType = (userType === User.TYPE_ADMIN) ? '/admin' : '';

  return fetchResource(`api${ urlType }/companies`, {
    method: 'POST',
    body: company,
  });
}

export function adminDisableCompany(slug) {
  return fetchResource(`api/admin/companies/${ slug }`, {
    method: 'DELETE',
    slug,
  });
}

export function adminGetCompany(slug) {
  return fetchResource(`api/admin/companies/${ slug }`, {
    method: 'GET',
  });
}

export function adminEditCompany(company) {
  return fetchResource(`api/admin/companies/${ company.slug }`, {
    method: 'PUT',
    body: company,
  });
}

export function editCompanyFinancials(slug) {
  return fetchResource(`api/companies/edit/${ slug }/financials`, {
    method: 'GET',
  });
}

export function updateCompanyFinancials(companySlug, companyFinancials) {
  return fetchResource(`api/companies/update/${ companySlug }/financials`, {
    method: 'POST',
    body: companyFinancials,
  });
}
