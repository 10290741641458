import { funding, library, news } from '@app/components/public/etinerary/Modal/constant';
import { TargetBlank } from '@src/_v2/js/constants';
import { Language } from '@src/js/constants/entities';
import {
  dynamicRoutes, externalRoutes,
  publicPath,
  routeCodes, withLang,
} from '@src/js/constants/routes';
import { useEtineraryModal } from '@src/js/layouts/header/navigation/utils';
import classNames from 'classnames';
import * as propTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

const SubMenuMobile = ({ navigation, activeNavItem }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [toggleElBottom, setToggleElBottom] = useState(0);
  const [toggleElActive, setToggleElActive] = useState(false);
  const [isActiveExternalNavigation, setIsActiveExternalNavigation] = useState(false);
  const userData = useSelector(state => state.initInfo.userInfo);
  const activeLanguage = useSelector(state => state.initInfo?.activeLanguage);
  const { page } = useParams();
  const { showModal, checkType } = useEtineraryModal();

  const toggleMenu = (e, toggleEl = false) => {
    let newToggleElActive = false;

    if (toggleEl) {
      newToggleElActive = true;
      const toggleRect = e.target.getBoundingClientRect();

      setToggleElBottom(toggleRect.bottom);
    }
    setShowMenu(!showMenu);
    setToggleElActive(newToggleElActive);
  };

  const showSubmenu = () => (
    page
      && !page.includes('programme/news/')
      && !page.includes('programme/trainings/')
      && activeNavItem !== 'messages'
  );

  const getExternalNavigationClass = () => {
    let activeClass = 'secondary-navigation-mobile__panel__item';

    if (isActiveExternalNavigation) {
      activeClass += ' secondary-navigation-mobile__panel__item--active';
    }

    return activeClass;
  };

  const setActiveDropdownItem = () => {
    setIsActiveExternalNavigation(true);
  };

  const partner = navigation && navigation.partner_companies && navigation.partner_companies.partner;

  const navEntries = activeLanguage === Language.IT
    ? Object.entries(navigation)
    : Object.entries(navigation)
      .filter(nav => nav[0] !== 'finanza_agevolata' && nav[0] !== 'certificates');

  const navKeys = Object.keys(Object.fromEntries(navEntries));

  return showSubmenu()
    ? (
      <nav className='secondary-navigation-mobile' onClick={ () => null } role='presentation'>
        <div className='secondary-navigation-mobile__links'>
          <span
            className='secondary-navigation-mobile__links-item secondary-navigation-mobile__links-item--main'
          >
            <FormattedMessage id={ `navigation.${ activeNavItem }` } />
            <span className='secondary-navigation-mobile__links-item__delimiter'>/</span>
          </span>
          { navEntries.map(([key, value]) => {
            const keyLabel = value.label;
            const route = value.route;
            if (
              page === keyLabel
                  || page.includes(`${ activeNavItem }/${ keyLabel }`)
                  || page === route
                  || page === `${ route }/financials`
                  || page === `${ route }/documents`
                  || page === `${ route }/data-room`
                  || page === 'programme'
                  || page === `opportunities/${ route }`
                  || (page.includes('data-rooms') && route === 'myDataRooms')
            ) {
              const translationKey = dynamicRoutes.includes(key) ? navigation[key].label : key;
              return (
                <span
                  className={
                        classNames(
                          'secondary-navigation-mobile__toggle',
                          'secondary-navigation-mobile__links-item',
                          'secondary-navigation-mobile__links-item--active',
                          { show: toggleElActive }
                        ) }
                  key={ keyLabel }
                  onClick={ (e) => toggleMenu(e, true) }
                  role='presentation'
                >
                  { partner
                    ? <FormattedMessage id={ `navigation.${ translationKey }` } values={ { partner } } />
                    : <FormattedMessage id={ `navigation.${ translationKey }` } />
                      }
                </span>
              );
            }

            return false;
          }) }

          { showMenu && (
            <div className='secondary-navigation-mobile__panel' style={ { top: toggleElBottom } }>
              { navKeys.map(key => {
                if (externalRoutes.includes(key)) {
                  return (
                    <a
                      id={ `secondary-navigation-${ key }` }
                      onClick={ setActiveDropdownItem }
                      key={ key }
                      { ...TargetBlank }
                      className={ getExternalNavigationClass() }
                      href={ navigation[key].link }
                    >
                      { partner
                        ? <FormattedMessage id={ `navigation.${ key }` } values={ { partner } } />
                        : <FormattedMessage id={ `navigation.${ key }` } />
                          }
                    </a>
                  );
                }

                if (dynamicRoutes.includes(key)) {
                  const label = `navigation.${ navigation[key].label }`;
                  return (
                    <NavLink
                      key={ key }
                      activeClassName='secondary-navigation-mobile__panel__item--active'
                      className='secondary-navigation-mobile__panel__item'
                      exact
                      to={ withLang(`${ publicPath }${ navigation[key].route }`) }
                      onClick={ toggleMenu }
                    >
                      { partner
                        ? <FormattedMessage id={ label } values={ { partner } } />
                        : <FormattedMessage id={ label } />
                          }
                    </NavLink>
                  );
                }

                const checkedEtinerary = checkType(key, userData);
                return (
                  <NavLink
                    key={ key }
                    activeClassName='secondary-navigation-mobile__panel__item--active'
                    className='secondary-navigation-mobile__panel__item'
                    exact
                    to={ checkedEtinerary ? false : withLang(routeCodes[key.toUpperCase()]) }
                    onClick={ () => {
                      if (checkedEtinerary) {
                        showModal('contactModal', [funding, news, library].includes(key) ? key : null);
                      }
                      toggleMenu();
                    } }
                  >
                    <FormattedMessage id={ `navigation.${ key }` } />
                    { checkedEtinerary && <span className='etinerary-indicator' /> }
                  </NavLink>
                );
              }) }
            </div>
          ) }

          { showMenu && (
            <div
              className='secondary-navigation-mobile__overlay'
              onClick={ toggleMenu }
              role='presentation'
            />
          ) }
        </div>
      </nav>
    )
    : null;
};

SubMenuMobile.propTypes = {
  navigation: propTypes.object,
  activeNavItem: propTypes.string,
};

export default SubMenuMobile;
