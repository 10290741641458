import React from 'react';
import { Col } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_LANGUAGE } from '@src/js/layouts/forms/company/CompanyForm';

const OnboardingHeader = props => {
  const { translation, caption } = props;

  return (
    <Col xs={ 12 }>
      <h2 className='font-size-headline text-center'>
        { translation?.[caption] || <FormattedMessage id={ caption } /> }
      </h2>
    </Col>
  );
};

OnboardingHeader.propTypes = {
  caption: PropTypes.string,
  translation: PropTypes.object,
};

OnboardingHeader.defaultProps = {
  caption: 'onboarding.formCaption',
};

/**
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state) => ({
  translation: state.initInfo.localizationMessages[state?.onboardingTemplate?.templateData?.lang || DEFAULT_LANGUAGE],
});

export default connect(mapStateToProps)(OnboardingHeader);
