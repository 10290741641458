export const RM_CREATE_COMPANY_START = 'RM_CREATE_COMPANY_START';
export const RM_CREATE_COMPANY_ERROR = 'RM_CREATE_COMPANY_ERROR';
export const RM_CREATE_COMPANY_SUCCESS = 'RM_CREATE_COMPANY_SUCCESS';

export function rmCreateCompany(company) {
  return {
    type: RM_CREATE_COMPANY_START,
    company,
  };
}
