export const ADMIN_GET_TEMPLATE_LIST_START = 'ADMIN_GET_TEMPLATE_LIST_START';
export const ADMIN_GET_TEMPLATE_LIST_ERROR = 'ADMIN_GET_TEMPLATE_LIST_ERROR';
export const ADMIN_GET_TEMPLATE_LIST_SUCCESS = 'ADMIN_GET_TEMPLATE_LIST_SUCCESS';
export const ADMIN_CHANGE_STATUS_TEMPLATES_START = 'ADMIN_CHANGE_STATUS_TEMPLATES_START';
export const ADMIN_CHANGE_STATUS_TEMPLATES_ERROR = 'ADMIN_CHANGE_STATUS_TEMPLATES_ERROR';
export const ADMIN_CHANGE_STATUS_TEMPLATES_SUCCESS = 'ADMIN_CHANGE_STATUS_TEMPLATES_SUCCESS';
export const ADMIN_GET_ONE_TEMPLATE_START = 'ADMIN_GET_ONE_TEMPLATE_START';
export const ADMIN_GET_ONE_TEMPLATE_ERROR = 'ADMIN_GET_ONE_TEMPLATE_ERROR';
export const ADMIN_GET_ONE_TEMPLATE_SUCCESS = 'ADMIN_GET_ONE_TEMPLATE_SUCCESS';

export function adminGetTemplateList(filters) {
  return {
    type: ADMIN_GET_TEMPLATE_LIST_START,
    filters,
  };
}

export function adminChangeTemplateStatus({ hash, status, filters }) {
  return {
    type: ADMIN_CHANGE_STATUS_TEMPLATES_START,
    hash,
    status,
    filters,
  };
}

export function adminGetOneTemplate(selectedTemplate) {
  return {
    type: ADMIN_GET_ONE_TEMPLATE_START,
    selectedTemplate,
  };
}
