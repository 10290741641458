import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import Cookies from 'js-cookie';

import { GET_SESSION_INFO_SUCCESS, UPDATE_USER_AVATAR } from '@src/js/actions/initInfo';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  GET_USER_START,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GET_MY_PROFILE_START,
  GET_MY_PROFILE_ERROR,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_COMPANY_MEMBERS_START,
  GET_MY_COMPANY_MEMBERS_ERROR,
  GET_MY_COMPANY_MEMBERS_SUCCESS,
  EDIT_USER_START,
  EDIT_USER_ERROR,
  EDIT_USER_SUCCESS,
  EDIT_MY_PROFILE_START,
  EDIT_MY_PROFILE_ERROR,
  EDIT_MY_PROFILE_SUCCESS,
  LOGIN_USER_START,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_START,
  LOGOUT_USER_ERROR,
  LOGOUT_USER_SUCCESS,
  IMPERSONATE_USER_START,
  IMPERSONATE_USER_ERROR,
  IMPERSONATE_USER_SUCCESS,
  EXIT_IMPERSONATE_SESSION_START,
  EXIT_IMPERSONATE_SESSION_ERROR,
  EXIT_IMPERSONATE_SESSION_SUCCESS,
  RECREATE_USER_TOKEN_START,
  RECREATE_USER_TOKEN_ERROR,
  RECREATE_USER_TOKEN_SUCCESS,
  UNLOCK_USER_START,
  UNLOCK_USER_SUCCESS,
  UNLOCK_USER_ERROR,
} from '@src/js/actions/user/user';
import * as api from '@src/js/api/user/user';
import * as apiUserInfo from '@src/js/api/initInfo';
import { routeCodes, withLang } from '@src/js/constants/routes';

function* getUser(options) {
  try {
    const data = yield call(() => api.getUser(options.id));
    yield put({ type: GET_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_USER_ERROR, error });
  }
}

function* getMyProfile() {
  try {
    const data = yield call(() => api.getMyProfile());
    yield put({ type: GET_MY_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MY_PROFILE_ERROR, error });
  }
}

function* getMyCompanyMembers() {
  try {
    const data = yield call(() => api.getMyCompanyMembers());
    yield put({ type: GET_MY_COMPANY_MEMBERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MY_COMPANY_MEMBERS_ERROR, error });
  }
}

function* editUser(options) {
  try {
    const data = yield call(() => api.editUser(options.user.values));
    yield put({ type: EDIT_USER_SUCCESS, data });
    options.user.resolve();
  } catch (error) {
    yield put({ type: EDIT_USER_ERROR, error });
    options.user.reject(error.response);
  }
}

function* editMyProfile({ user, history }) {
  try {
    const data = yield call(() => api.editMyProfile(user));
    yield all([
      put({ type: EDIT_MY_PROFILE_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.edit.success_message')),
    ]);
    if (data.avatar) {
      yield put({ type: UPDATE_USER_AVATAR, url: data.avatar });
    }
    history.push(withLang(routeCodes.HOME));
  } catch (error) {
    yield all([
      put({ type: EDIT_MY_PROFILE_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* loginUser(options) {
  try {
    const data = yield call(() => api.loginUser(options.credentialsData));

    Cookies.set('networking-offset-company', 0, { secure: true });
    Cookies.set('networking-offset-partner', 0, { secure: true });

    const session = yield call(() => apiUserInfo.getSessionInfo());
    yield put({ type: GET_SESSION_INFO_SUCCESS, session });

    yield put({ type: LOGIN_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: LOGIN_USER_ERROR });
  }
}

function* logoutUser() {
  try {
    const data = yield call(() => api.logoutUser());
    yield put({ type: LOGOUT_USER_SUCCESS, data });
    Cookies.set('networking-offset-company', 0, { secure: true });
    Cookies.set('networking-offset-partner', 0, { secure: true });

    localStorage.clear();
  } catch (error) {
    yield put({ type: LOGOUT_USER_ERROR });
  }
}

function* impersonateUser(options) {
  try {
    const data = yield call(() => api.impersonateUser(options.user));
    yield put({ type: IMPERSONATE_USER_SUCCESS, data });
    Cookies.set('networking-offset-company', 0, { secure: true });
    Cookies.set('networking-offset-partner', 0, { secure: true });
    localStorage.clear();
  } catch (error) {
    yield put({ type: IMPERSONATE_USER_ERROR });
  }
}

function* exitImpersonateSession() {
  try {
    const data = yield call(() => api.exitImpersonateSession());
    yield put({ type: EXIT_IMPERSONATE_SESSION_SUCCESS, data });
    Cookies.set('networking-offset-company', 0, { secure: true });
    Cookies.set('networking-offset-partner', 0, { secure: true });
    localStorage.clear();
  } catch (error) {
    yield put({ type: EXIT_IMPERSONATE_SESSION_ERROR });
  }
}

function* recreateUserToken(options) {
  try {
    const data = yield call(() => api.recreateUserToken(options.userData, options.urlRecreateUserToken));
    yield put({ type: RECREATE_USER_TOKEN_SUCCESS, data });
  } catch (error) {
    yield put({ type: RECREATE_USER_TOKEN_ERROR, error });
  }
}

function* unlockUser({ uuid }) {
  try {
    const data = yield call(() => api.unlockUser(uuid));
    yield all([
      put({ type: UNLOCK_USER_SUCCESS, data }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: UNLOCK_USER_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* getUserWatcher() {
  yield takeLatest(GET_USER_START, getUser);
}

function* getMyProfileWatcher() {
  yield takeLatest(GET_MY_PROFILE_START, getMyProfile);
}

function* getMyCompanyMembersWatcher() {
  yield takeLatest(GET_MY_COMPANY_MEMBERS_START, getMyCompanyMembers);
}

function* editUserWatcher() {
  yield takeLatest(EDIT_USER_START, editUser);
}

function* editMyProfileWatcher() {
  yield takeLatest(EDIT_MY_PROFILE_START, editMyProfile);
}

function* loginUserWatcher() {
  yield takeLatest(LOGIN_USER_START, loginUser);
}

function* logoutUserWatcher() {
  yield takeLatest(LOGOUT_USER_START, logoutUser);
}

function* impersonateUserWatcher() {
  yield takeLatest(IMPERSONATE_USER_START, impersonateUser);
}

function* exitImpersonateSessionWatcher() {
  yield takeLatest(EXIT_IMPERSONATE_SESSION_START, exitImpersonateSession);
}

function* recreateUserTokenWatcher() {
  yield takeLatest(RECREATE_USER_TOKEN_START, recreateUserToken);
}

function* unlockUserWatcher() {
  yield takeLatest(UNLOCK_USER_START, unlockUser);
}

export default [
  getUserWatcher(),
  getMyProfileWatcher(),
  getMyCompanyMembersWatcher(),
  editUserWatcher(),
  editMyProfileWatcher(),
  loginUserWatcher(),
  logoutUserWatcher(),
  impersonateUserWatcher(),
  exitImpersonateSessionWatcher(),
  recreateUserTokenWatcher(),
  unlockUserWatcher(),
];
