import fetchResource from '@src/js/api/fetch-resource';
import { News } from '@src/js/constants/entities';
import { FUNDING_SECTIONS } from '@app/components/public/funding/constants';

export function getSpecificOpportunities() {
  const params = {
    type: News.SPECIFIC_OPPORTUNITY, page: 1, strict_type: true, cover: false,
  };
  const query = new URLSearchParams(params);
  return fetchResource(`api/news?${ query }`, {
    method: 'GET',
  });
}

export function getSuccessStories(type, page, limit) {
  const params = {
    type, page, strict_type: true, cover: false, limit,
  };
  const query = new URLSearchParams(params);
  return fetchResource(`api/news?${ query }`, {
    method: 'GET',
  });
}

export function getFundingInterviews(page, limit) {
  const params = {
    type: 'video', page, strict_type: true, cover: false, limit, interviews: true,
  };
  const query = new URLSearchParams(params);
  return fetchResource(`api/news?${ query }`, {
    method: 'GET',
  });
}

export function getFundingInsights(section, limit) {
  const params = {
    limit,
  };
  let key;
  switch (section) {
    case FUNDING_SECTIONS.IPO:
      key = 'ipo';
      break;
    case FUNDING_SECTIONS.SINGLE_TRANSACTIONS:
      key = 'singleTransactions';
      break;
    default:
      key = 'insights';
      break;
  }
  params[key] = true;

  const query = new URLSearchParams(params);
  return fetchResource(`api/libraries?${ query }`, {
    method: 'GET',
  });
}

export function getFinanzaOpportunities() {
  const params = {
    type: News.FINANZA_AGEVOLATA, page: 1, strict_type: true, cover: false,
  };
  const query = new URLSearchParams(params);
  return fetchResource(`api/news?${ query }`, {
    method: 'GET',
  });
}
