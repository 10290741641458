import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button, Loader } from '@src/js/components/global';
import { callReferrer } from '@src/js/actions/user/users';
import { useForm } from 'react-hook-form';
import { InputMobile } from '@app/components/global/forms';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import { Language } from '@src/js/constants/entities';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const RequestCallModal = ({ requestType }) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const isLangIt = selectActiveLanguage() === Language.IT;
  const loading = useSelector(state => state.users?.callReferrer?.loading);

  const {
    control, formState: { errors }, handleSubmit,
  } = useForm({ mode: 'all' });

  const send = data => {
    if (!data) return;
    dispatch(callReferrer(data, requestType));
    hideModal();
  };

  return (
    <form onSubmit={ handleSubmit(send) } className='contact-modal'>
      {loading && <Loader />}
      <ModalBody className='d-flex justify-content-center'>
        <div className='eop-text-input m-t-3'>
          <InputMobile
            name='phone'
            defaultCountry={ isLangIt ? 'IT' : '' }
            control={ control }
            errors={ errors }
            label='company_form.phone'
            required
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='send'
          onClickFunc={ handleSubmit(send) }
        />
        <Button
          size='lg'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </form>
  );
};

RequestCallModal.propTypes = {
  requestType: PropTypes.string.isRequired,
};

export default RequestCallModal;
