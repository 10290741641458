const scope = 'TOPIC';

export const GET_TOPIC_LIST_START = `${ scope }/GET_TOPIC_LIST_START`;
export const GET_TOPIC_LIST_FAIL = `${ scope }/GET_TOPIC_LIST_FAIL`;
export const GET_TOPIC_LIST_SUCCESS = `${ scope }/GET_TOPIC_LIST_SUCCESS`;

export const GET_TOPIC_LIST_NAVIGATION_START = `${ scope }/GET_TOPIC_LIST_NAVIGATION_START`;
export const GET_TOPIC_LIST_NAVIGATION_FAIL = `${ scope }/GET_TOPIC_LIST_NAVIGATION_FAIL`;
export const GET_TOPIC_LIST_NAVIGATION_SUCCESS = `${ scope }/GET_TOPIC_LIST_NAVIGATION_SUCCESS`;

export const GET_TOPIC_START = `${ scope }/GET_TOPIC_START`;
export const GET_TOPIC_FAIL = `${ scope }/GET_TOPIC_FAIL`;
export const GET_TOPIC_SUCCESS = `${ scope }/GET_TOPIC_SUCCESS`;

export const UPDATE_TOPIC_START = `${ scope }/UPDATE_TOPIC_START`;
export const UPDATE_TOPIC_FAIL = `${ scope }/UPDATE_TOPIC_FAIL`;
export const UPDATE_TOPIC_SUCCESS = `${ scope }/UPDATE_TOPIC_SUCCESS`;

export const UPDATE_TOPIC_STATUS_START = `${ scope }/UPDATE_TOPIC_STATUS_START`;
export const UPDATE_TOPIC_STATUS_FAIL = `${ scope }/UPDATE_TOPIC_STATUS_FAIL`;
export const UPDATE_TOPIC_STATUS_SUCCESS = `${ scope }/UPDATE_TOPIC_STATUS_SUCCESS`;

export function getTopicList({
  search, page, programmes, statuses,
}) {
  return {
    type: GET_TOPIC_LIST_START,
    search,
    page,
    programmes,
    statuses,
  };
}

export function getTopicListNavigation() {
  return {
    type: GET_TOPIC_LIST_NAVIGATION_START,
  };
}

export function getTopic(id) {
  return {
    type: GET_TOPIC_START,
    id,
  };
}

export function updateTopic({ id, formData, history }) {
  return {
    type: UPDATE_TOPIC_START,
    id,
    formData,
    history,
  };
}

export function updateTopicStatus({ id, status }) {
  return {
    type: UPDATE_TOPIC_STATUS_START,
    id,
    status,
  };
}
