import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Base64 } from 'js-base64';
import { Route, withRouter } from 'react-router-dom';
import { RolesUser } from '@src/js/constants/entities';
import NotFound from './user/pages/NotFound';

class EOPRoute extends Component {
  static propTypes = {
    userData: PropTypes.object,
    permissions: PropTypes.number,
    checkRoleAdmin: PropTypes.bool,
  };

  render() {
    const {
      userData,
      permissions,
      checkRoleAdmin,
    } = this.props;

    // @see UserFormatterService->formatPermissions
    // eslint-disable-next-line max-len
    // @see http://api.dev-elite-network.com/permissions.php?q=6YrU3FGlRBGoLbGHhAtYq1x9ztumf39J6KIIG3NcmKQ90PS8NUYsyo9egiIxPD0S
    // eslint-disable-next-line no-bitwise
    if ((!permissions || (userData.type && Base64.decode(userData.type) && permissions))
    && (!checkRoleAdmin || (checkRoleAdmin && userData?.userRole === RolesUser.ROLE_ADMIN))) {
      return <Route { ...this.props } />;
    }

    return <NotFound />;
  }
}

const mapStateToProps = (state) => ({
  userData: state.initInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(EOPRoute);

export default withRouter(connectWrapper);
