import styled from 'styled-components';
import {
  ThemeColors,
  FontSize,
  FontWeight,
} from '@src/_v2/js/constants';

export const ProgrammePartnerHolder = styled.div`
  position: relative;
  display: flex;
  margin-top: 1rem;
  height: 8rem;
  padding: 2rem;
`;

export const ProgrammePartnerContent = styled.div`
  display: block;
  font-size: ${ FontSize.body };
  position: absolute;
  left: 8rem;
  top: 2rem;
  right: 2.5rem;
`;

export const ProgrammePartnerNameHolder = styled.div`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  height: 2.2rem;
  letter-spacing: 0.05rem;
  font-size: ${ FontSize.body };
  color: ${ ThemeColors.black };
  font-weight: ${ FontWeight.medium };
`;

export const ProgrammePartnerPositionHolder = styled.div`
  color: ${ ThemeColors.mediumGrey };
  height: 2rem;
  font-size: ${ FontSize.caption };
`;

export const Arrow = styled.span`
  position: absolute;
  right: 1rem;
  align-self: center;
  justify-content: center;

  &::after {
    font-family: eliteIcons, sans-serif;
    font-size: ${ FontSize.icon };
    content: '\\e920';
    color: ${ ThemeColors.mediumGrey };
  }
`;
