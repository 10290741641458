import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@src/js/components/global';
import { useDispatch } from 'react-redux';
import { sendTranslation } from '@app/store/translations/actions';
import { purifyHtml } from '@src/js/utils';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const ModalPreview = ({ newTranslation }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { hideModal } = useGlobalModalContext();

  const updateTranslation = () => {
    const toastr = {
      successTitle: intl.formatMessage({ id: 'notification.success_title' }),
      successMsg: intl.formatMessage({ id: 'notification.success_message' }),
      errorTitle: intl.formatMessage({ id: 'notification.error_title' }),
      errorMsg: intl.formatMessage({ id: 'notification.error_message' }),
    };
    dispatch(sendTranslation(newTranslation, toastr));
    hideModal();
  };

  if (!newTranslation) {
    return null;
  }

  return (
    <>
      <ModalBody className='m-y-4'>
        <h3 className='font-weight-normal eop-text-color-medium-grey m-b-1'><FormattedMessage id='preview' /></h3>
        <p
          className='m-b-0 font-size-body eop-text-color-black lh-24 blue-a'
          dangerouslySetInnerHTML={ { __html: purifyHtml(newTranslation?.value) } }
        />
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='dialog.confirm'
          onClickFunc={ updateTranslation }
        />
        <Button
          size='lg'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </>
  );
};

ModalPreview.propTypes = {
  newTranslation: PropTypes.object,
};

export default ModalPreview;
