import {
  takeLatest, put, call, all,
} from 'redux-saga/effects';

import * as api from '@src/js/api/membership';
import * as submitMembershipCompanyApi from '@src/js/api/membership/submitMembershipCompany';
import * as membershipUploadFile from '@src/js/api/membership/uploadMembershipSignedDocument';

import { showToastrError } from '@app/store/global/actions/index';
import {
  GET_MEMBERSHIP_COMPANY_REQUESTED,
  SAVE_MEMBERSHIP_PROCESS_REQUESTED, UPLOAD_FILE_REQUESTED,
  DOCUSIGN_MEMBERSHIP_START, DOCUSIGN_MEMBERSHIP_SUCCESS, DOCUSIGN_MEMBERSHIP_FAIL,
} from '@src/js/oneplatform/membership/pages/form/container/constants';
import {
  getMembershipCompanySucceeded,
  getMembershipCompanyFailed,
  saveMembershipCompanySucceeded,
  saveMembershipCompanyFailed, uploadMembershipFileSucceeded, uploadMembershipFileFailed,
} from './actions';

function* getMembershipCompany({ templateHash, isAdminForm, isReport }) {
  try {
    const data = yield call(() => api.getMembershipCompany(templateHash, isAdminForm, isReport));
    yield put(getMembershipCompanySucceeded(data));
  } catch (error) {
    yield put(getMembershipCompanyFailed(error));
  }
}

function* saveMembershipCompany({
  formData, hashTemplate, isAdminForm, callbacks = {},
}) {
  try {
    const data = yield call(() => submitMembershipCompanyApi.saveMembershipCompany(
      { company: formData.company, terms: formData.terms, submitType: formData.submitType },
      hashTemplate,
      isAdminForm
    ));
    yield put(saveMembershipCompanySucceeded(data));
    if (callbacks.success) callbacks.success(data?.steps);
  } catch (error) {
    yield put(saveMembershipCompanyFailed(error));
    if (callbacks.error) callbacks.error();
  }
}

function* uploadMembershipSignedDocument({
  file, entity, fileType,
}) {
  try {
    const data = yield call(() => membershipUploadFile.uploadMembershipDocument(file, entity, fileType));
    yield put(uploadMembershipFileSucceeded(data));
  } catch (error) {
    yield all([
      put(uploadMembershipFileFailed(error)),
      put(showToastrError('notification.error_title', 'notification.error_upload')),
    ]);
  }
}

function* docusign({ mobilePhone, terms, hash }) {
  try {
    const result = yield call(() => submitMembershipCompanyApi.docusign({
      mobilePhone,
      terms,
      hash,
    }));
    yield put({ type: DOCUSIGN_MEMBERSHIP_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: DOCUSIGN_MEMBERSHIP_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_MEMBERSHIP_COMPANY_REQUESTED, getMembershipCompany);
  yield takeLatest(SAVE_MEMBERSHIP_PROCESS_REQUESTED, saveMembershipCompany);
  yield takeLatest(UPLOAD_FILE_REQUESTED, uploadMembershipSignedDocument);
  yield takeLatest(DOCUSIGN_MEMBERSHIP_START, docusign);
}

export default root;
