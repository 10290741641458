import { localeFormatNumber, stringToNumber } from '@app/components/global/forms/utils';

const checkValue = (val) => val && (String(parseFloat(val)) === String(val) || !Number.isNaN(parseFloat(val)));

const returnDecimal = (v) => (v ? v.toFixed(2) : null);

const handleEMargin = (data, ind, allValues) => {
  const netIncome = stringToNumber(allValues.companyFinancials[ind].netIncome);
  const revenue = stringToNumber(allValues.companyFinancials[ind].revenue);

  let calculated = 0;
  if (checkValue(netIncome) && checkValue(revenue)) {
    calculated = returnDecimal((netIncome / revenue) * 100);
  }

  return {
    ...data,
    [`companyFinancials[${ ind }][eMargin]`]: localeFormatNumber(calculated, allValues.lang),
  };
};

/**
 * Calculates EBITDA percentage.
 * EBITDA stands for: Earnings before interest, taxes, depreciation, and amortization
 *
 * @param data
 * @param ind
 * @param allValues
 *
 * @returns {Object}
 */
const handleEbitdaPerc = (data, ind, allValues) => {
  const ebitda = stringToNumber(allValues.companyFinancials[ind].ebitda);
  const revenue = stringToNumber(allValues.companyFinancials[ind].revenue);

  let calculated = 0;
  if (checkValue(revenue) && checkValue(ebitda)) {
    calculated = returnDecimal((ebitda / revenue) * 100);
  }

  return {
    ...data,
    [`companyFinancials[${ ind }][ebitdaPerc]`]: localeFormatNumber(calculated, allValues.lang),
  };
};

/**
 * Calculates EBIT percentage.
 * Ebit stands for: Earnings before interest and taxes
 *
 * @param data
 * @param ind
 * @param allValues
 *
 * @returns {Object}
 */
const handleEbitPerc = (data, ind, allValues) => {
  const ebit = stringToNumber(allValues.companyFinancials[ind].ebit);
  const revenue = stringToNumber(allValues.companyFinancials[ind].revenue);

  let calculated = 0;
  if (checkValue(revenue) && checkValue(ebit)) {
    calculated = returnDecimal((ebit / revenue) * 100);
  }

  return {
    ...data,
    [`companyFinancials[${ ind }][ebitPerc]`]: localeFormatNumber(calculated, allValues.lang),
  };
};

const handleCapitalEmployed = (data, ind, allValues) => {
  const netDebt = stringToNumber(allValues.companyFinancials[ind].netDebt);
  const netEquity = stringToNumber(allValues.companyFinancials[ind].netEquity);

  let calculated = 0;
  if (checkValue(netDebt) && checkValue(netEquity)) {
    calculated = returnDecimal(parseFloat(netDebt) + parseFloat(netEquity));
  }

  return {
    ...data,
    [`companyFinancials[${ ind }][capitalEmployed]`]: localeFormatNumber(calculated, allValues.lang),
  };
};

/**
 * Nfp stands for: Non-Farm Payroll
 *
 * @param data
 * @param ind
 * @param allValues
 *
 * @returns {Object}
 */
const handleNfpEbitda = (data, ind, allValues) => {
  const netDebt = stringToNumber(allValues.companyFinancials[ind].netDebt);
  const ebitda = stringToNumber(allValues.companyFinancials[ind].ebitda);

  let calculated = 0;
  if (checkValue(netDebt) && checkValue(ebitda)) {
    calculated = returnDecimal(netDebt / ebitda);
  }

  return {
    ...data,
    [`companyFinancials[${ ind }][nfpEbitda]`]: localeFormatNumber(calculated, allValues.lang),
  };
};

export const financialsCalculator = {
  field: /companyFinancials/,
  updates: (value, name, allValues) => {
    const str = name.split('[');
    const ind = str[1].substring(0, str[1].length - 1);
    const field = str[2].substring(0, str[2].length - 1);

    let returnData = {};

    const ebitdaPerc = ['ebitda', 'revenue'];
    if (ebitdaPerc.includes(field)) {
      returnData = handleEbitdaPerc(returnData, ind, allValues);
    }

    const ebitPerc = ['revenue', 'ebit'];
    if (ebitPerc.includes(field)) {
      returnData = handleEbitPerc(returnData, ind, allValues);
    }

    const capitalEmployed = ['netDebt', 'netEquity'];
    if (capitalEmployed.includes(field)) {
      returnData = handleCapitalEmployed(returnData, ind, allValues);
    }

    const eMargin = ['netIncome', 'revenue'];
    if (eMargin.includes(field)) {
      returnData = handleEMargin(returnData, ind, allValues);
    }

    const nfpEbitda = ['netDebt', 'ebitda'];
    if (nfpEbitda.includes(field)) {
      returnData = handleNfpEbitda(returnData, ind, allValues);
    }

    return returnData;
  },
};
