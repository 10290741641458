const scope = 'etinerary.modal';
const funding = 'funding';
const news = 'news';
const library = 'library';
const singleTransactions = 'single_transactions';
const financialAdvisors = 'financial_advisors';

const tabs = [funding, singleTransactions, financialAdvisors];

const messages = (type) => ({
  title: `${ scope }.${ type }.title`,
  subtitle: `${ scope }.${ type }.subtitle`,
  description: `${ scope }.${ type }.description`,
});

export {
  messages,
  funding,
  news,
  library,
  tabs,
};
