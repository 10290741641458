import { SelectValue } from '@app/components/global/forms/select/select';
import { Banner } from '@app/models/Banner';
import { MultiValue, SingleValue } from 'react-select';

const scope = 'BANNER';

export const GET_BANNER_LIST_START = `${ scope }/GET_BANNER_LIST_START`;
export const GET_BANNER_LIST_FAIL = `${ scope }/GET_BANNER_LIST_FAIL`;
export const GET_BANNER_LIST_SUCCESS = `${ scope }/GET_BANNER_LIST_SUCCESS`;

export const GET_BANNER_START = `${ scope }/GET_BANNER_START`;
export const GET_BANNER_FAIL = `${ scope }/GET_BANNER_FAIL`;
export const GET_BANNER_SUCCESS = `${ scope }/GET_BANNER_SUCCESS`;

export const UPDATE_BANNER_START = `${ scope }/UPDATE_BANNER_START`;
export const UPDATE_BANNER_FAIL = `${ scope }/UPDATE_BANNER_FAIL`;
export const UPDATE_BANNER_SUCCESS = `${ scope }/UPDATE_BANNER_SUCCESS`;

export const UPDATE_BANNER_STATUS_START = `${ scope }/UPDATE_BANNER_STATUS_START`;
export const UPDATE_BANNER_STATUS_FAIL = `${ scope }/UPDATE_BANNER_STATUS_FAIL`;
export const UPDATE_BANNER_STATUS_SUCCESS = `${ scope }/UPDATE_BANNER_STATUS_SUCCESS`;

export function getBannerList({
  search, page, companyTypes, bannerType, bannerLang, bannerStatus,
}: {
  search: string,
  page: number,
  companyTypes: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerType: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerLang: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerStatus: SingleValue<SelectValue> | MultiValue<SelectValue>, }) {
  return {
    type: GET_BANNER_LIST_START,
    search,
    page,
    companyTypes,
    bannerType,
    bannerLang,
    bannerStatus,
  };
}

export function getBanner(id: number) {
  return {
    type: GET_BANNER_START,
    id,
  };
}

export function updateBanner({ id, formData, history }: { id: number, formData: Banner, history: any }) {
  return {
    type: UPDATE_BANNER_START,
    id,
    formData,
    history,
  };
}

export function updateBannerStatus({ id, status }: {id: number, status: 'published'|'deleted' }) {
  return {
    type: UPDATE_BANNER_STATUS_START,
    id,
    status,
  };
}
