import {
  SEARCH_START,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
} from '@src/js/actions/search';

const initialState = {
  loading: false,
  error: null,
  search: null,
};

const actionsMap = {
  [SEARCH_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [SEARCH_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    search: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
