import loadEnvVariable from '@src/js/static/LoadEnv';
import { useMemo } from 'react';

const CookieControl = window.CookieControl;

const conf = {
  apiKey: loadEnvVariable('CIVICUK_SECRET'),
  product: loadEnvVariable('CIVICUK_PRODUCT'),
  subDomains: true,
  iabCMP: true,
};

const useCookieControl = () => {
  if (!CookieControl) return;
  useMemo(() => CookieControl.load(conf), [conf, CookieControl]);
};

export default useCookieControl;
