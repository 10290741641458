export const GET_OPPORTUNITIES_FOR_COMPANY_START = 'GET_OPPORTUNITIES_FOR_COMPANY_START';
export const GET_OPPORTUNITIES_FOR_COMPANY_ERROR = 'GET_OPPORTUNITIES_FOR_COMPANY_ERROR';
export const GET_OPPORTUNITIES_FOR_COMPANY_SUCCESS = 'GET_OPPORTUNITIES_FOR_COMPANY_SUCCESS';

export function getOpportunitiesForCompany(company, publisherType) {
  return {
    type: GET_OPPORTUNITIES_FOR_COMPANY_START,
    company,
    publisherType,
  };
}
