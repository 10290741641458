import {
  GET_TEMPLATE_DATA_START,
  GET_TEMPLATE_DATA_ERROR,
  GET_TEMPLATE_DATA_SUCCESS,
} from '@src/js/actions/onboarding/onboardingTemplate';

const initialState = {
  templateLoading: false,
  templateError: null,
  templateData: {},
  templateFormOptions: null,
};

const actionsMap = {
  [GET_TEMPLATE_DATA_START]: (state) => ({
    ...state,
    templateLoading: true,
    templateError: null,
  }),
  [GET_TEMPLATE_DATA_ERROR]: (state, action) => ({
    ...state,
    templateLoading: false,
    templateError: action.error,
  }),
  [GET_TEMPLATE_DATA_SUCCESS]: (state, action) => ({
    ...state,
    templateData: action.data.template,
    templateFormOptions: action.data.templateFormOptions,
    templateLoading: false,
    templateError: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
