import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_CURRENCIES_START,
  GET_CURRENCIES_ERROR,
  GET_CURRENCIES_SUCCESS,
} from '@src/js/actions/taxonomy/currencies';

import * as api from '@src/js/api/taxonomy/currencies';

function* getCurrencies() {
  try {
    const data = yield call(() => api.getCurrencies());
    yield put({ type: GET_CURRENCIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_CURRENCIES_ERROR, error });
  }
}

export function* getCurrenciesWatcher() {
  yield takeLatest(GET_CURRENCIES_START, getCurrencies);
}

export default [
  getCurrenciesWatcher(),
];
