import {
  CREATE_BUSINESS_NEED_START,
  CREATE_BUSINESS_NEED_ERROR,
  CREATE_BUSINESS_NEED_SUCCESS,
  EDIT_BUSINESS_NEED_START,
  EDIT_BUSINESS_NEED_ERROR,
  EDIT_BUSINESS_NEED_SUCCESS,
  GET_BUSINESS_NEED_SECTORS_START,
  GET_BUSINESS_NEED_SECTORS_ERROR,
  GET_BUSINESS_NEED_SECTORS_SUCCESS,
} from '@src/js/marketplace/actions/modal/businessNeedModal';

const initialState = {
  businessNeedsLoading: false,
  businessNeedsError: null,
  businessNeedsData: null,
};

const actionsMap = {
  [CREATE_BUSINESS_NEED_START]: (state) => ({
    ...state,
    businessNeedsLoading: true,
    businessNeedsError: null,
    businessNeedsData: null,
  }),
  [CREATE_BUSINESS_NEED_ERROR]: (state, action) => ({
    ...state,
    businessNeedsLoading: false,
    businessNeedsError: action.error,
    businessNeedsData: null,
  }),
  [CREATE_BUSINESS_NEED_SUCCESS]: (state, action) => ({
    ...state,
    businessNeedsLoading: false,
    businessNeedsError: null,
    businessNeedsData: action.data,
  }),
  [EDIT_BUSINESS_NEED_START]: (state) => ({
    ...state,
    businessNeedsLoading: true,
    businessNeedsError: null,
    businessNeedsData: null,
  }),
  [EDIT_BUSINESS_NEED_ERROR]: (state, action) => ({
    ...state,
    businessNeedsLoading: false,
    businessNeedsError: action.error,
    businessNeedsData: null,
  }),
  [EDIT_BUSINESS_NEED_SUCCESS]: (state, action) => ({
    ...state,
    businessNeedsLoading: false,
    businessNeedsError: null,
    businessNeedsData: action.data,
  }),
  [GET_BUSINESS_NEED_SECTORS_START]: (state) => ({
    ...state,
    loadingSectors: true,
    errorSectors: null,
    businessNeedSectors: {},
  }),
  [GET_BUSINESS_NEED_SECTORS_ERROR]: (state, action) => ({
    ...state,
    loadingSectors: false,
    errorSectors: action.error,
    businessNeedSectors: {},
  }),
  [GET_BUSINESS_NEED_SECTORS_SUCCESS]: (state, action) => ({
    ...state,
    loadingSectors: false,
    errorSectors: null,
    businessNeedSectors: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
