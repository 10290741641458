import { stringToNumber } from '@app/components/global/forms/utils';
import {
  emailPattern, passwordPattern, phonePattern, specialPattern, urlPattern, percentagePattern, slugPattern,
} from '@src/js/constants/patterns';

const inputValidation = {
  requiredValidator: value => ((value !== undefined && value !== null) ? undefined : 'global_form.error.required'),
  requiredEditorValidator: value => (value ? undefined : { id: 'global_form.error.required', values: {} }),
  requiredBoolValidator: value => ((
    typeof value === 'boolean' || (typeof value === 'object' && typeof value?.value === 'boolean')
  ) ? undefined : 'global_form.error.required'),

  requiredArrayValidator: value => (value && value.length >= 1 ? undefined : 'global_form.error.required'),

  emailValidator: (value) => {
    if (value && value.match(emailPattern)) {
      return undefined;
    }

    return 'global_form.error.email';
  },

  // validate (spaces, numbers, +)
  telephoneValidator: (value) => {
    if (value && value.match(phonePattern)) {
      return undefined;
    }

    return 'global_form.error.telephone';
  },

  floatValidator: (value) => {
    const val = stringToNumber(value);

    if (!val || !Number.isNaN(val)) {
      return undefined;
    }

    return 'global_form.error.float';
  },

  urlValidator: (value) => {
    if (value) {
      if (value.match(urlPattern)) {
        return undefined;
      }

      return 'global_form.error.url';
    }

    return undefined;
  },

  minValueValidation: min => value => {
    if (value) {
      return Number.isNaN(value) || value >= min ? undefined : `Should be greater than ${ min }`;
    }

    return undefined;
  },

  maxValueValidation: max => value => {
    if (value) {
      return Number.isNaN(value) || value < max ? undefined : `Should be smaller than ${ max }`;
    }

    return undefined;
  },

  minLengthValidator: minLength => value => {
    if (value) {
      const trimValue = value.trim();
      return trimValue.length >= minLength ? undefined : { id: 'global_form.error.min_length', values: { minLength } };
    }

    return undefined;
  },

  minLengthUploadValidator: minLength => value => {
    if (value) {
      return value.length >= minLength
        ? undefined
        : { id: 'global_form.error.min_length_upload', values: { minLength } };
    }

    return undefined;
  },

  maxLengthValidator: maxLength => value => {
    if (value) {
      return value.length < maxLength ? undefined : { id: 'global_form.error.max_length', values: { maxLength } };
    }

    return undefined;
  },

  specialCharsValidator: (value) => {
    if (value) {
      if (value.match(specialPattern)) {
        return 'global_form.error.special_chars';
      }
    }

    return undefined;
  },

  onlyLettersValidation: (value) => {
    const pattern = /^(.*[0-9])/;

    if (value) {
      if (value.match(pattern)) {
        return 'global_form.error.only_letters';
      }
    }

    return undefined;
  },

  passwordValidator: (value) => {
    if (value) {
      if (!value.match(passwordPattern)) {
        return 'global_form.error.password';
      }
    }

    return undefined;
  },

  percentageValidator: (value) => {
    if (value) {
      if (!value.match(percentagePattern)) {
        return 'global_form.error.only_percent_chars';
      }
    }

    return undefined;
  },

  samePasswordValidator: newPasswordValue => (value) => {
    if (newPasswordValue) {
      if (newPasswordValue !== value) {
        return 'global_form.error.password_repeat';
      }
    }

    return undefined;
  },

  slugValidator: (value) => {
    if (!value) {
      return undefined;
    }

    // quick check to avoid huge regex processing times with unicode characters
    if (!value.match(/^[a-z0-9-]+$/)) {
      return 'news_form.invalidSlug';
    }

    if (value.match(slugPattern)) {
      return undefined;
    }

    return 'news_form.invalidSlug';
  },

  composeValidators: (...validators) => value => validators.reduce(
    (error, validator) => error || validator(value), undefined),
};

export default inputValidation;
