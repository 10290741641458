import React, { Component } from 'react';
import EliteLoader from '@src/js/components/global/pageLoader/EliteLoader';
import { Container } from './styled';

export default class Loader extends Component {
  render() {
    return (
      <Container>
        <EliteLoader />
      </Container>
    );
  }
}
