import {
  ADMIN_GET_INDEX_LIBRARY_START,
  ADMIN_GET_INDEX_LIBRARY_ERROR,
  ADMIN_GET_INDEX_LIBRARY_SUCCESS,
} from '@src/js/actions/library/adminLibrary';

const initialState = {
  loading: false,
  error: null,
  indexLibrary: null,
};

const actionsMap = {
  [ADMIN_GET_INDEX_LIBRARY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_GET_INDEX_LIBRARY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_GET_INDEX_LIBRARY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    indexLibrary: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
