import { Language } from '@app/models/Language';
import { selectActiveLanguage, selectLocalizationMessages, selectUserInfo } from '@app/store/global/selectors/initInfo';
import { setPreferredLanguage, updateActiveLanguage } from '@src/js/actions/initInfo';
import { Icons } from '@src/js/constants/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

type Props = {
  className: string;
}

const LanguageSelector = ({ className = 'main-navigation__right-section__nav-item' }: Props) => {
  const dispatch = useDispatch();
  const userData = selectUserInfo();
  const activeLanguage = selectActiveLanguage() as Language;
  const localizationMessages = selectLocalizationMessages();

  const onClickHandler = (languageCode: string) => () => {
    if (userData) {
      dispatch(setPreferredLanguage(languageCode));
    } else {
      dispatch(updateActiveLanguage(languageCode));
    }
    window.scrollTo(0, 0);
  };

  return (
    <UncontrolledDropdown className='dropdown-lang'>
      <DropdownToggle tag='a' className={ `${ className } dropdown-profile__toggle` }>
        <img
          className='dropdown-profile__toggle-avatar'
          alt={ activeLanguage }
          src={ Icons.flags[activeLanguage] }
        />
        <span className='main-navigation__right-section__nav-item-caption dropdown-profile__toggle-caption'>
          <span
            className='main-navigation__right-section__nav-item-caption dropdown-profile__toggle-caption'
          >
            <FormattedMessage id={ `lang_${ activeLanguage }` } />
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu end>
        { (Object.keys(localizationMessages) as Language[])
          .filter((lang) => lang !== activeLanguage).map((languageCode) => (
            <div key={ languageCode } className='dropdown-menu-group'>
              <DropdownItem onClick={ onClickHandler(languageCode) } className='dropdown-item'>
                <img alt={ languageCode } src={ Icons.flags[languageCode] } />
                <FormattedMessage id={ `lang_${ languageCode }` } />
              </DropdownItem>
            </div>
          )) }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default LanguageSelector;
