import { FileObj } from '@app/models';
import { showToastrError } from '@app/store/global/actions';
import { ErrorMessage } from '@hookform/error-message';
import fetchResource from '@src/js/api/fetch-resource';
import Button from '@src/js/components/global/buttons/Buttons';
import PageLoader from '@src/js/components/global/pageLoader/PageLoader';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { fileConstants } from '@src/js/constants/entities';
import FileCard from '@src/js/views/user/pages/Onboarding/OnboardingProcess/components/FileCard/FileCard';
import { UploadCaption } from '@src/js/views/user/pages/Onboarding/OnboardingProcess/styled';
import React, { useState } from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

type Props = {
  name: string,
  label?: string,
  translate?: boolean,
  disabled?: boolean,
  required?: boolean,
  className?: string,
  acceptedTypes?: Accept,
  downloadable?: boolean,
  removable?: boolean,
  url: string,
  multiple?: boolean,
  externalUrl?: boolean,
  formType?: 'image'|'file',
}

const InputFileUpload = ({
  name, label, translate = true, disabled, required, multiple,
  className = '', acceptedTypes = dropzoneExtensions.DOCS_AND_IMAGES,
  downloadable = true, removable = true, url, externalUrl, formType = 'file',
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    control, formState: { errors }, setValue, watch,
  } = useFormContext<FieldValues>();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const value: FileObj|FileObj[]|null|undefined = watch(name);
  let files: FileObj[] = [];
  if (value) {
    files = Array.isArray(value) ? value : [value];
  }
  const [loading, setLoading] = useState(false);

  const handleDrop = (uploadedFiles: File[]) => {
    if (uploadedFiles.length === 0) return null;

    const formData = new FormData();
    formData.append(formType, uploadedFiles[0]);
    setLoading(true);
    fetchResource(url, {
      method: 'POST',
      body: formData,
      noContentType: true,
      externalUrl,
    }).then(resp => {
      setLoading(false);
      const response = resp?.file || resp;
      if (formType === 'image') response.preview = URL.createObjectURL(uploadedFiles[0]);
      if (multiple) {
        setValue(name, files.concat(response));
      } else {
        setValue(name, response);
      }
      return response;
    }).catch(() => {
      setLoading(false);
      dispatch(showToastrError('notification.error_title', 'notification.error_upload'));
    });

    return null;
  };

  const handleDelete = (f: FileObj) => {
    const filtered = files.filter(i => i !== f);
    setValue(name, filtered.length > 0 ? filtered : null);
  };

  const validate = () => {
    if (!required) {
      return true;
    }
    if (multiple && files) {
      return files.length > 0;
    }
    return !!files;
  };

  return (
    <div className={ className } data-name={ name }>
      <UploadCaption>
        { required && <span className='required-icon' /> }
        { label && fieldLabel }
      </UploadCaption>
      { files.length > 0 && files.map(currentFile => {
        if (!currentFile) return null;
        return (
          <div key={ currentFile?.id } className='col-xs-12 card p-x-0'>
            <FileCard
              file={ currentFile }
              removeFile={ () => handleDelete(currentFile) }
              downloadable={ downloadable }
              removable={ removable }
              fileType={ formType }
            />
          </div>
        );
      })}
      <Controller
        name={ name }
        control={ control }
        rules={ { validate } }
        render={ () => (
          <div className={ `card m-b-1 ${ !files.length || multiple ? '' : 'd-none' }` }>
            <div className='upload-card__container long-text'>
              { loading && <PageLoader /> }
              <Dropzone
                accept={ acceptedTypes }
                maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
                onDrop={ f => handleDrop(f) }
                multiple={ multiple }
                disabled={ disabled }
                useFsAccessApi={ false }
              >
                {({ getRootProps, getInputProps }) => (
                  <div { ...getRootProps({ style: { height: '100%' } }) }>
                    <input { ...getInputProps() } />
                    <div className='upload-card__placeholder'>
                      <p>
                        <span className='upload-card__placeholder-text'>
                          <FormattedMessage id='onboarding.upload_drag_drop' />
                        </span>
                        <span className='upload-card__placeholder-content'>
                          <FormattedMessage id='onboarding.button.upload_browse' />
                        </span>
                        <span className='upload-card__placeholder-button'>
                          <Button
                            buttonText='onboarding.button.upload_browse'
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        ) }
      />
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ () => (
          <span className='error-message'>
            {intl.formatMessage({ id: 'global_form.error.required' })}
          </span>
        ) }
      />
    </div>
  );
};

export default InputFileUpload;
