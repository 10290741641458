import React from 'react';
import { useFormContext } from 'react-hook-form';
import { UserForm } from '@app/components/public/user/UserProfile';
import { FormattedMessage } from 'react-intl';
import { InputText } from '@app/components/global/forms';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

type Props = {
  showModal: boolean,
  toggle: () => void,
  onSubmit: () => void,
}

const ConfirmPasswordModal = ({ showModal, toggle, onSubmit }: Props) => {
  const { register, formState: { errors } } = useFormContext<UserForm>();
  return (
    <Modal isOpen={ showModal } size='lg'>
      <ModalHeader className='m-b-2' toggle={ toggle }>
        <div className='modal-title modal-title__big'><FormattedMessage id='user.confirmPassword' /></div>
        <div className='modal-subtitle'><FormattedMessage id='messageConfirmPassword' /></div>
      </ModalHeader>
      <ModalBody className='m-b-4 p-2'>
        <InputText
          name='modalCurrentPassword'
          placeholder='user.currentPassword'
          label='user.currentPassword'
          type='password'
          register={ register }
          errors={ errors }
          required={ showModal }
        />
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          className='eop-btn eop-btn-empty-grey'
          onClick={ toggle }
        >
          <FormattedMessage id='close' />
        </button>
        <button
          type='button'
          className='eop-btn'
          onClick={ onSubmit }
        >
          <FormattedMessage id='accept' />
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmPasswordModal;
