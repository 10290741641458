import {
  UPLOAD_ONBOARDING_IMAGE_START,
  UPLOAD_ONBOARDING_IMAGE_ERROR,
  UPLOAD_ONBOARDING_IMAGE_SUCCESS,
  ADMIN_UPLOAD_ONBOARDING_IMAGE_START,
  ADMIN_UPLOAD_ONBOARDING_IMAGE_ERROR,
  ADMIN_UPLOAD_ONBOARDING_IMAGE_SUCCESS,
  CLEAR_ONBOARDING_IMAGE_STATE,
} from '@src/js/actions/onboarding/image/uploadOnboardingImage';

const initialState = {
  loading: false,
  error: null,
  image: null,
};

const actionsMap = {
  [UPLOAD_ONBOARDING_IMAGE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [UPLOAD_ONBOARDING_IMAGE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [UPLOAD_ONBOARDING_IMAGE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    image: action.data,
    options: action.options,
  }),
  [ADMIN_UPLOAD_ONBOARDING_IMAGE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_UPLOAD_ONBOARDING_IMAGE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_UPLOAD_ONBOARDING_IMAGE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    image: action.data,
    options: action.options,
  }),
  [CLEAR_ONBOARDING_IMAGE_STATE]: (state) => ({
    ...state,
    image: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
