import { MultipleUploadStatus } from '@src/js/constants/entities';
import { formatBytes } from '@src/js/utils';
import { ResponseError } from './Error';

const chunkSize = 5000000;

export type FileToUploadParams = {
  file: File;
  uploadUrl: string;
  entity: string;
  parentType: string;
  parentId: number;
  dataRoomType: string;
  companySlug: string;
  notification: string;
}

type FileStatus = keyof typeof MultipleUploadStatus;

export default class FileToUpload {
  private readonly file: File;
  private readonly uploadUrl: string;
  private readonly entity: string;
  private readonly parentType: string;
  private readonly parentId: number;
  private readonly dataRoomType: string;
  private readonly companySlug: string;
  private readonly notification: string;
  private currentChunkStartByte = 0;
  private currentChunkFinalByte: number;
  private readonly controller = new AbortController();
  private status: FileStatus = 'init';
  private progress = 0;
  onChangeStatus: (status: string) => void = () => undefined;
  onChangeProgress: (prgress: number) => void = () => undefined;

  constructor(params: FileToUploadParams) {
    this.file = params.file;
    this.uploadUrl = params.uploadUrl;
    this.entity = params.entity;
    this.parentType = params.parentType;
    this.parentId = params.parentId;
    this.dataRoomType = params.dataRoomType;
    this.companySlug = params.companySlug;
    this.notification = params.notification;
    this.currentChunkFinalByte = chunkSize > this.file.size ? this.file.size : chunkSize;
  }

  abort() {
    this.controller.abort();
  }

  private setStatus(status: FileStatus) {
    this.status = status;
    this.onChangeStatus(status);
  }
  private setProgress(progress: number) {
    this.progress = progress;
    this.onChangeProgress(progress);
  }

  getStatus() {
    return this.status;
  }
  getProgress() {
    return this.progress;
  }
  getFileName() {
    return this.file.name;
  }
  getFileSize() {
    return formatBytes(this.file.size);
  }

  uploadFile() {
    this.setStatus('uploading');
    const chunk = this.file.slice(this.currentChunkStartByte, this.currentChunkFinalByte);
    const formData = new FormData();
    formData.append('file', chunk, this.file.name);
    formData.append('entity', this.entity);
    formData.append('parentType', this.parentType);
    formData.append('parentId', this.parentId.toString());
    formData.append('dataRoomType', this.dataRoomType);
    formData.append('notification', this.notification);
    formData.append('companySlug', this.companySlug);
    const signal = this.controller.signal;
    const options: RequestInit = {
      method: 'POST',
      signal,
      credentials: 'include',
      headers: {
        'Content-Range':
        `bytes ${ this.currentChunkStartByte }-${ this.currentChunkFinalByte }/${ this.file.size }`,
      },
      body: formData,
    };
    const request = fetch(this.uploadUrl, options);

    request
      .then((res) => {
        if (!res.ok) {
          const err = new ResponseError(`HTTP status code: ${ res.status }`);
          err.response = res;
          err.status = res.status;
          throw err;
        }
        return res.json();
      })
      .then(() => {
        const remainingBytes = this.file.size - this.currentChunkFinalByte;
        if (this.currentChunkFinalByte === this.file.size) {
          this.setStatus('uploaded');
          this.setProgress(100);
          return;
        }
        // eslint-disable-next-line promise/always-return
        if (remainingBytes < chunkSize) {
          this.currentChunkStartByte = this.currentChunkFinalByte;
          this.currentChunkFinalByte = this.currentChunkStartByte + remainingBytes;
        } else {
          this.currentChunkStartByte = this.currentChunkFinalByte;
          this.currentChunkFinalByte = this.currentChunkStartByte + chunkSize;
        }

        this.setProgress(Math.floor((this.currentChunkFinalByte / this.file.size) * 100));

        this.uploadFile();
      })
      .catch((error) => {
        if (error.name !== 'AbortError') this.setStatus('failed');
      });

    return request;
  }
}
