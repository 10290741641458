import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_RELATION_MANAGERS_START,
  GET_RELATION_MANAGERS_ERROR,
  GET_RELATION_MANAGERS_SUCCESS,
} from '@src/js/actions/user/getRelationManagers';

import * as api from '@src/js/api/user/getRelationManagers';

function* getRelationManagers() {
  try {
    const data = yield call(() => api.getRelationManagers());
    yield put({ type: GET_RELATION_MANAGERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_RELATION_MANAGERS_ERROR, error });
  }
}

export function* getRelationManagersWatcher() {
  yield takeLatest(GET_RELATION_MANAGERS_START, getRelationManagers);
}

export default [
  getRelationManagersWatcher(),
];
