import React from 'react';
import * as PropTypes from 'prop-types';
import Forbidden from '@src/js/views/user/pages/Forbidden';
import NotFound from '@src/js/views/user/pages/NotFound';
import UnexpectedError from '@src/js/views/user/pages/UnexpectedError';
import CustomErrorPage from '@src/js/views/user/pages/CustomErrorPage';

const OnboardingErrorPage = (props) => {
  const {
    error,
  } = props;

  switch (error.status) {
    case 403:
      return <Forbidden />;
    case 404:
      return <NotFound />;
    case 400:
      return <CustomErrorPage errors={ error.response.errors || [error.message] } />;
    default:
      return <UnexpectedError />;
  }
};

OnboardingErrorPage.propTypes = {
  error: PropTypes.object,
};

export default OnboardingErrorPage;
