import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import UploadPlaceholder from '@src/js/components/global/imageEditor/UploadPlaceholder';
import FilePreview from '@src/js/components/global/cards/FilePreview';
import { UploadCaption } from '@src/js/views/user/pages/Onboarding/OnboardingProcess/styled';
import { useIntl } from 'react-intl';
import { uploadFile } from '@src/js/api/file/uploadFile';

type Props = {
  onChange?: (f: UploadedFile|null) => void,
  entity: string,
  fileType: string,
  accept: Accept,
  maxSize: number,
  value: UploadedFile|null,
  downloadable?: boolean,
  translate: boolean,
  label: string,
  required?: boolean,
  className?: string,
  name: string,
  disabled?: boolean,
  removable?: boolean,
}
type UploadedFile = {
  id: number,
  name: string,
  slug: string,
  url: string,
  userUuid: string,
  preview?: string,
}

const DropZoneInput = ({
  onChange, entity, fileType, accept, maxSize, value,
  downloadable = true, translate = true, label, required, className, name, disabled, removable = true,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;

  const addFile = (acceptedFiles: File[]) => {
    const file = new FormData();
    file.append('file', acceptedFiles[0]);
    uploadFile(file, entity, fileType).then((data) => {
      const f = {
        ...data,
        preview: data.url,
      };
      if (onChange) {
        onChange(f);
      }
      return data;
    }).catch((err: unknown) => err);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    maxSize,
    useFsAccessApi: false,
    disabled,
    onDrop: acceptedFiles => addFile(acceptedFiles),
  });

  const removeFile = () => {
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <div className={ className } data-name={ name }>
      <UploadCaption>
        { required && <span className='required-icon' /> }
        { label && fieldLabel }
      </UploadCaption>
      {value?.url
        ? (
          <FilePreview
            file={ value }
            arrow={ false }
            removeFileAction={ removeFile }
            downloadable={ downloadable }
            removable={ removable }
          />
        ) : (
          <div className='upload-card__container'>
            <div { ...getRootProps() }>
              <input { ...getInputProps() } />
              <UploadPlaceholder />
            </div>
          </div>
        )}
    </div>
  );
};

export default DropZoneInput;
