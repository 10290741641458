import { Participant } from '@app/models/Chat';
import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  participants: Participant[];
}

const MessageDescription = ({ participants }: Props) => {
  if (participants.length === 1) {
    return (<span>{ participants[0].jobTitle }</span>);
  }
  return (
    <>
      {participants.map((p, i) => (
        <Fragment key={ p.uuid }>
          { `${ i !== 0 ? ', ' : '' }${ p.name } ` }
          <span
            className='icon-medium-Info font-size-icon'
            data-tip
            data-for={ `participant-${ i }-tooltip` }
          />
          <ReactTooltip
            id={ `participant-${ i }-tooltip` }
            place='top'
            effect='solid'
            className='p-1'
          >
            {p.company}
            ,
            {p.jobTitle}
          </ReactTooltip>
        </Fragment>
      ))}
    </>
  );
};

export default MessageDescription;
