import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import {
  ShowMoreButton,
  ShowMoreButtonInner,
  ShowMoreContainer,
} from '@src/js/views/user/pages/Dashboard/components/CompanyWidget/styled';
import { Icon } from '@src/_v2/js/components/Icons';
import SelectBoxItem from './SelectBoxItem';
import {
  Container,
  FilterContainer,
  FilterContentWrapper,
  FilterLabel,
} from './styled';

const MIN_ITEMS_SHOWN = 6;

class SelectBoxFilterBlock extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    toggleOpened: PropTypes.bool,
    fieldName: PropTypes.string,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    intl: PropTypes.object,
    formMutator: PropTypes.func,
    value: PropTypes.array,
    hiddenSearchBox: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    const { toggleOpened, value, hiddenSearchBox } = this.props;

    this.state = {
      ...this.state,
      showMoreOptions: hiddenSearchBox,
      open: toggleOpened,
      selectedItems: value || [],
      filteredItems: props.options,
    };
  }

  toggleShowMore = () => {
    const { showMoreOptions } = this.state;

    this.setState({
      showMoreOptions: !showMoreOptions,
    });
  }

  toggleFilter = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  handleSelectItem = (selectedItem) => {
    const { onChange, formMutator, fieldName } = this.props;
    const { selectedItems } = this.state;

    const tmpValue = selectedItems;
    tmpValue.push(selectedItem);
    this.setState({
      selectedItems: tmpValue,
    });

    onChange(tmpValue, formMutator, fieldName);
  }

  handleDeleteItem = (selected) => {
    const { selectedItems } = this.state;
    const { onChange, formMutator, fieldName } = this.props;

    const tmpFilters = selectedItems.filter(item => item !== selected);
    this.setState({
      selectedItems: tmpFilters,
    });
    onChange(tmpFilters, formMutator, fieldName);
  }

  renderFilterContent = () => {
    const { prefix, options } = this.props;
    const { selectedItems } = this.state;

    if (options) {
      return (
        <div className='select-box-filter-content'>
          { options.filter(item => selectedItems.includes(item.Id))
            .map(item => (
              <SelectBoxItem
                key={ item.Id }
                item={ item }
                prefix={ prefix }
                closeButton={ true }
                deleteFunc={ this.handleDeleteItem }
              />
            )) }
        </div>
      );
    }

    return null;
  }

  renderSelectBoxItems = () => {
    const { prefix } = this.props;
    const { showMoreOptions, selectedItems, filteredItems } = this.state;

    if (filteredItems) {
      const selectBoxItems = filteredItems
        .filter(item => !selectedItems.find(selectedFilter => item.Id === selectedFilter));

      return selectBoxItems.filter((_, index) => index < MIN_ITEMS_SHOWN || showMoreOptions).map(item => (
        <SelectBoxItem
          key={ item.Id }
          item={ item }
          prefix={ prefix }
          handleClick={ this.handleSelectItem }
        />
      ));
    }
    return null;
  }

  renderFilters = () => {
    const { showMoreOptions, filteredItems } = this.state;
    const { hiddenSearchBox } = this.props;

    return (
      <div className='select-box-filter-container'>
        <div className={ 'select-box-filter-content' }>
          { this.renderSelectBoxItems() }
        </div>
        { !hiddenSearchBox && (
        <ShowMoreContainer className='align-right'>
          { filteredItems?.length > MIN_ITEMS_SHOWN && (
          <ShowMoreButton
            onClick={ () => this.toggleShowMore() }
            onKeyPress={ () => this.toggleShowMore() }
            role='presentation'
          >
            <ShowMoreButtonInner>
              <FormattedMessage id={ showMoreOptions ? 'show_less' : 'show_more' } />
              <Icon icon={ showMoreOptions ? 'up' : 'down' } />
            </ShowMoreButtonInner>
          </ShowMoreButton>
          )}
        </ShowMoreContainer>
        )}
      </div>
    );
  }

  searchFilters = (e) => {
    const { options, prefix, intl } = this.props;
    const searchValue = e.target.value || '';
    const callback = (item) => {
      const label = !prefix || prefix.length === 0 ? item.Name
        : intl.formatMessage({ id: prefix + item.Name });
      return label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    };
    const filteredItems = options.filter(callback);
    this.setState({
      filteredItems,
    });
  }

  render() {
    const { open, filterSearchValue } = this.state;
    const { placeholder, label, hiddenSearchBox } = this.props;
    return (
      <Container className='filter'>
        <div className='filters-programme'>
          <FilterLabel>
            <div
              className='filter-title'
              role='button'
              tabIndex='0'
              onClick={ this.toggleFilter }
              onKeyUp={ this.toggleFilter }
            >
              <span className='filter-title-label__uppercase'>
                <FormattedMessage id={ label } />
              </span>
              <span
                className={ `${ open ? 'icon-ArrowUpSmall' : 'icon-ArrowRightSmall' } icon-up` }
              />
            </div>
            { this.renderFilterContent() }
          </FilterLabel>
          { open && (
            <FilterContainer>
              { !hiddenSearchBox && (
              <div className='filter-search-wrapper'>
                <span className='icon-SearchSmall' />
                <FormattedMessage id={ placeholder }>
                  { placeholderMessage => (
                    <DebounceInput
                      debounceTimeout={ 500 }
                      placeholder={ placeholderMessage }
                      onChange={ this.searchFilters }
                      value={ filterSearchValue }
                    />
                  ) }
                </FormattedMessage>
              </div>
              )}
              <FilterContentWrapper>
                { this.renderFilters() }
              </FilterContentWrapper>
            </FilterContainer>
          ) }
        </div>
      </Container>
    );
  }
}

export default injectIntl(SelectBoxFilterBlock);
