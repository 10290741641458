import {
  GET_PARTNER_PERMISSIONS_START,
  GET_PARTNER_PERMISSIONS_ERROR,
  GET_PARTNER_PERMISSIONS_SUCCESS,
} from '@src/js/actions/partner/partnerPermissions';

const initialState = {
  loading: false,
  error: null,
  permissions: null,
};

const actionsMap = {
  [GET_PARTNER_PERMISSIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    permissions: null,
  }),
  [GET_PARTNER_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_PARTNER_PERMISSIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    permissions: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
