import fetchResource from '@src/js/api/fetch-resource';

export function getSectors(parent) {
  let url = 'api/taxonomy/sectors';

  if (parent) {
    url = `${ url }?parent=${ parent }`;
  }

  return fetchResource(url);
}

export function getSecondLevelSectors() {
  return fetchResource('api/taxonomy/secondLevelSectors');
}
