import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  sectionTitle?: string,
  className?: string,
  titleClass?: string,
  children: JSX.Element|JSX.Element[]|any,
  infoIcon?: boolean,
  onClick?: () => void,
  translate?: boolean,
}

const FormSection = ({
  sectionTitle, children, className = '', infoIcon = false, onClick, titleClass, translate = true,
}: Props) => (
  <div className='elite-white-box--form flex-h-gap-48 relative'>
    { sectionTitle && (
    <h2 className={ `compliance__title ${ titleClass || '' }` }>
      { translate ? <FormattedMessage id={ sectionTitle } /> : sectionTitle }
      { infoIcon && (
      <span
        role='presentation'
        className='icon-medium-Info font-size-subheader eop-text-color-blue cursor-pointer'
        onClick={ onClick }
      />
      ) }
    </h2>
    ) }
    <section className={ `elite-form__section ${ className }` }>
      { children }
    </section>
  </div>
);

export default FormSection;
