import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_COMPANY_FORM_OPTIONS_START,
  GET_COMPANY_FORM_OPTIONS_SUCCESS,
  GET_COMPANY_FORM_OPTIONS_ERROR,
  GET_COMPANY_FINANCIALS_FORM_OPTIONS_START,
  GET_COMPANY_FINANCIALS_FORM_OPTIONS_SUCCESS,
  GET_COMPANY_FINANCIALS_FORM_OPTIONS_ERROR,
  GET_COMPANY_FORM_ATECO_FAIL,
  GET_COMPANY_FORM_ATECO_START,
  GET_COMPANY_FORM_ATECO_SUCCESS,
} from '@src/js/actions/company/companyFormOptions';

import {
  getCompanyFormOptions as getCompanyFormOptionsApi,
  getCompanyFinancialsFormOptions as getCompanyFinancialsFormOptionsApi,
  getAtecoCodesOptions as getAtecoCodesOptionsApi,
} from '@src/js/api/company/companyFormOptions';

function* getCompanyFormOptions(options) {
  try {
    const data = yield call(() => getCompanyFormOptionsApi(options.slug, options.userType));
    yield put({ type: GET_COMPANY_FORM_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANY_FORM_OPTIONS_ERROR, error });
  }
}

export function* getCompanyFormOptionsWatcher() {
  yield takeLatest(GET_COMPANY_FORM_OPTIONS_START, getCompanyFormOptions);
}

function* getCompanyFinancialsFormOptions(options) {
  try {
    const data = yield call(() => getCompanyFinancialsFormOptionsApi(options.slug));
    yield put({ type: GET_COMPANY_FINANCIALS_FORM_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANY_FINANCIALS_FORM_OPTIONS_ERROR, error });
  }
}

function* getAtecoCodesOptions({ values, resolve, reject }) {
  try {
    const data = yield call(() => getAtecoCodesOptionsApi(values));
    yield put({ type: GET_COMPANY_FORM_ATECO_SUCCESS, data });
    if (resolve) {
      resolve(data);
    }
  } catch (error) {
    yield put({ type: GET_COMPANY_FORM_ATECO_FAIL, error });
    if (reject) {
      reject(error);
    }
  }
}

export function* getCompanyFinancialsFormOptionsWatcher() {
  yield takeLatest(GET_COMPANY_FINANCIALS_FORM_OPTIONS_START, getCompanyFinancialsFormOptions);
  yield takeLatest(GET_COMPANY_FORM_ATECO_START, getAtecoCodesOptions);
}

export default [
  getCompanyFormOptionsWatcher(),
  getCompanyFinancialsFormOptionsWatcher(),
];
