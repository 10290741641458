import React from 'react';
import { FormattedMessage } from 'react-intl';

export const YES_OR_NO = [
  { value: true, label: <FormattedMessage id='yes' /> },
  { value: false, label: <FormattedMessage id='no' /> },
];

export const PARTNER_SERVICES = [
  { value: 'standard', label: <FormattedMessage id='onboarding.partnerServices.standardServices' /> },
  { value: 'premium', label: <FormattedMessage id='onboarding.partnerServices.premiumServices' /> },
];

export const taskAssigned = [
  {
    value: 0,
    label: <FormattedMessage id='onboarding.form.partnerProfile.taskAssigned.annualAuditing' />,
  },
  {
    value: 1,
    label: <FormattedMessage id='onboarding.form.partnerProfile.taskAssigned.draftingAnnualBudget' />,
  },
  {
    value: 2,
    label: <FormattedMessage id='onboarding.form.partnerProfile.taskAssigned.managementControlSystem' />,
  },
  {
    value: 3,
    label: <FormattedMessage id='onboarding.form.partnerProfile.taskAssigned.governanceModel' />,
  },
  {
    value: 4,
    label: <FormattedMessage id='onboarding.form.partnerProfile.taskAssigned.organizationalStructure' />,
  },
  {
    value: 5,
    label: <FormattedMessage id='onboarding.form.partnerProfile.taskAssigned.communication' />,
  },
];
