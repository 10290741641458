const scope = 'NETWORK';

export const GET_RELEVANT_START = `${ scope }/GET_RELEVANT_START`;
export const GET_RELEVANT_FAIL = `${ scope }/GET_RELEVANT_FAIL`;
export const GET_RELEVANT_SUCCESS = `${ scope }/GET_RELEVANT_SUCCESS`;

export const GET_VIEW_ALL_START = `${ scope }/GET_VIEW_ALL_START`;
export const GET_VIEW_ALL_FAIL = `${ scope }/GET_VIEW_ALL_FAIL`;
export const GET_VIEW_ALL_SUCCESS = `${ scope }/GET_VIEW_ALL_SUCCESS`;

export function getDirectoryRelevant(section, boxNumber) {
  return {
    type: GET_RELEVANT_START,
    section,
    boxNumber,
  };
}

export function getDirectoryViewAll(section, boxNumber, page, filters) {
  return {
    type: GET_VIEW_ALL_START,
    section,
    page,
    filters,
    boxNumber,
  };
}
