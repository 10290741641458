import fetchResource from '@src/js/api/fetch-resource';
import { User } from '@src/js/constants/entities';

export function getCompanyFormOptions(slug, userType) {
  const urlType = userType === User.TYPE_ADMIN ? '/admin' : '';
  let url = `api${ urlType }/companies/form-options`;

  if (slug) {
    url = `${ url }/${ slug }`;
  }

  return fetchResource(url);
}

export function getCompanyFinancialsFormOptions(slug) {
  return fetchResource(`api/companies/financials-options/${ slug }`);
}

export function getAtecoCodesOptions(values) {
  const query = new URLSearchParams(values);
  return fetchResource(`api/ateco?${ query }`);
}
