import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@src/js/components/global';
import Loader from '@src/js/components/global/pageLoader/PageLoader';

const LoadingModal = ({ message, action, loading }) => {
  const dispatch = useDispatch();

  return (
    <>
      <ModalBody className='m-y-4 flex-h-gap-24'>
        { message && (
        <div className='flex'>
          <p className='font-size-subheader'><FormattedMessage id={ message } /></p>
        </div>
        ) }
        { loading && (<div><Loader isOverlay={ false } position='relative' /></div>) }
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ () => dispatch(action) }
        />
      </ModalFooter>
    </>
  );
};

LoadingModal.propTypes = {
  message: PropTypes.string,
  action: PropTypes.any,
  loading: PropTypes.bool,
};

export default LoadingModal;
