import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Icon from '@src/_v2/js/components/Icons/Icon/Icon';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { AppLink } from '@src/js/constants/entities';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import { isAndroid } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { Images } from '@src/js/constants/images';

const AppBanner = () => {
  const [bannerStatus, setBannerStatus] = useState(Cookies.get('appBanner'));

  const activeLanguage = selectActiveLanguage();
  const appleUrl = activeLanguage === 'it' ? AppLink.APPLE_IT : AppLink.APPLE_EN;
  const androidUrl = activeLanguage === 'it' ? AppLink.ADNDROID_IT : AppLink.ADNDROID_EN;

  const setBannerCookie = (e) => {
    e.preventDefault();
    setBannerStatus('closed');
    Cookies.set('appBanner', 'closed', { domain: `.${ loadEnvVariable('APP_BASE_DOMAIN') }` });
  };

  return bannerStatus !== 'closed'
    ? (
      <article id='appBanner' className='banner'>
        <a id='appBannerLink' target='_blank' rel='noopener noreferrer' href={ isAndroid ? androidUrl : appleUrl }>
          <div className='banner__main'>
            <Icon onClick={ (e) => setBannerCookie(e) } />
            <img
              className='banner__logo-app'
              src={ Images.BannerLogo }
              alt='elite-logo'
            />
            <div className='banner__text'>
              <h4><FormattedMessage id='app_banner_message' /></h4>
              <small><FormattedMessage id='app_get_banner_message' /></small>
            </div>
            <img
              id='appBannerStoreImage'
              className='banner__store'
              src={ isAndroid
                ? Images.PlayStore
                : Images.AppStore }
              alt='store'
            />
          </div>
        </a>
      </article>
    )
    : null;
};

export default AppBanner;
