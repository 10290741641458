export const COMPANY_COURSERA_ACCESS_START = 'COMPANY_COURSERA_ACCESS_START';
export const COMPANY_COURSERA_ACCESS_ERROR = 'COMPANY_COURSERA_ACCESS_ERROR';
export const COMPANY_COURSERA_ACCESS_SUCCESS = 'COMPANY_COURSERA_ACCESS_SUCCESS';

export function toggleCompanyCourseraAccess(slug) {
  return {
    type: COMPANY_COURSERA_ACCESS_START,
    slug,
  };
}
