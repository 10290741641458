import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue, SingleValue } from 'react-select';
import {
  SelectPayload, SelectResponse, SelectValue, ValueObj,
} from './select';
import { ArrowRenderer, LoadingRenderer, LockerRenderer } from './Renderers';

type Props = {
  loadAsyncOptions: (payload: SelectPayload) => Promise<SelectResponse>,
  onChange: (v: SingleValue<SelectValue>|MultiValue<SelectValue>) => void,
  value: SingleValue<SelectValue>|MultiValue<SelectValue>,
  placeholder?: string,
  type?: string,
  name: string,
  className?: string,
  multi?: boolean,
  cacheUniqs?: readonly any[],
  formatOptions?: (o: any) => any,
  disabled?: boolean,
  fieldNoClear?: boolean,
};

type InitialOptions = {
  page: number,
  query: string,
  type?: string,
}

const AsyncSelectPaginate = ({
  loadAsyncOptions, onChange, placeholder, type, value, name, className,
  multi, cacheUniqs, formatOptions, disabled, fieldNoClear,
}: Props) => {
  const initialOptions: InitialOptions = { page: 0, query: '' };
  if (type) {
    initialOptions.type = type;
  }
  const [selectOptions, setSelectOptions] = useState(initialOptions);
  const format = formatOptions || ((c: ValueObj) => ({ label: c.name || '', value: c.id }));
  const loadOptions: LoadOptions<SelectValue, GroupBase<SelectValue>, SelectValue> = async (query, options) => {
    const values = { ...initialOptions, page: selectOptions.page, query };
    if (selectOptions.query !== query) {
      values.page = 0;
    }
    const data = await loadAsyncOptions(values);
    setSelectOptions({ ...values, page: data.pagination.nextPage || 0 });

    return {
      options: data.results?.map(format) || options,
      hasMore: !!data.pagination?.hasMoreItems,
    };
  };

  return (
    <AsyncPaginate
      className={ `eop-selectbox w-100 ${ className || '' }` }
      name={ name }
      placeholder={ <FormattedMessage id={ placeholder } /> }
      value={ value }
      onChange={ onChange }
      loadOptions={ loadOptions }
      debounceTimeout={ 500 }
      components={ {
        DropdownIndicator: disabled ? LockerRenderer : ArrowRenderer,
        LoadingIndicator: LoadingRenderer,
      } }
      isMulti={ multi }
      cacheUniqs={ cacheUniqs }
      isDisabled={ disabled }
      isClearable={ !fieldNoClear }
    />
  );
};

export default AsyncSelectPaginate;
