import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_SPECIFIC_OPPORTUNITIES_ERROR,
  GET_SPECIFIC_OPPORTUNITIES_START,
  GET_SPECIFIC_OPPORTUNITIES_SUCCESS,
  GET_SUCCESS_STORIES_START,
  GET_SUCCESS_STORIES_ERROR,
  GET_SUCCESS_STORIES_SUCCESS,
  GET_FUNDING_INTERVIEWS_START, GET_FUNDING_INTERVIEWS_ERROR, GET_FUNDING_INTERVIEWS_SUCCESS,
  GET_FUNDING_INSIGHTS_START, GET_FUNDING_INSIGHTS_ERROR, GET_FUNDING_INSIGHTS_SUCCESS,
  GET_FINANZA_OPPORTUNITIES_ERROR, GET_FINANZA_OPPORTUNITIES_START, GET_FINANZA_OPPORTUNITIES_SUCCESS,
} from '@app/store/funding/actions';

import * as api from '@app/store/funding/api';

function* getSpecificOpportunities() {
  try {
    const data = yield call(() => api.getSpecificOpportunities());
    yield put({ type: GET_SPECIFIC_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SPECIFIC_OPPORTUNITIES_ERROR, error });
  }
}

function* getSuccessStories({ storyType, page, limit }) {
  try {
    const data = yield call(() => api.getSuccessStories(storyType, page, limit));
    yield put({ type: GET_SUCCESS_STORIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SUCCESS_STORIES_ERROR, error });
  }
}

function* getFundingInterviews({ page, limit }) {
  try {
    const data = yield call(() => api.getFundingInterviews(page, limit));
    yield put({ type: GET_FUNDING_INTERVIEWS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_FUNDING_INTERVIEWS_ERROR, error });
  }
}

function* getFundingInsights({ section, limit }) {
  try {
    const data = yield call(() => api.getFundingInsights(section, limit));
    yield put({ type: GET_FUNDING_INSIGHTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_FUNDING_INSIGHTS_ERROR, error });
  }
}

function* getFinanzaOpportunities() {
  try {
    const data = yield call(() => api.getFinanzaOpportunities());
    yield put({ type: GET_FINANZA_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_FINANZA_OPPORTUNITIES_ERROR, error });
  }
}

function* getSpecificOpportunitiesWatcher() {
  yield takeLatest(GET_SPECIFIC_OPPORTUNITIES_START, getSpecificOpportunities);
}

function* getSuccessStoriesWatcher() {
  yield takeLatest(GET_SUCCESS_STORIES_START, getSuccessStories);
}

function* getFundingInterviewsWatcher() {
  yield takeLatest(GET_FUNDING_INTERVIEWS_START, getFundingInterviews);
}

function* getFundingInsightsWatcher() {
  yield takeLatest(GET_FUNDING_INSIGHTS_START, getFundingInsights);
}

function* getFinanzaOpportunitiesWatcher() {
  yield takeLatest(GET_FINANZA_OPPORTUNITIES_START, getFinanzaOpportunities);
}

export default [
  getSpecificOpportunitiesWatcher(),
  getSuccessStoriesWatcher(),
  getFinanzaOpportunitiesWatcher(),
  getFundingInterviewsWatcher(),
  getFundingInsightsWatcher(),
];
