import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import React from 'react';

class FilterDatePicker extends DatePicker {
  static propTypes = {
    placeholderText: PropTypes.string,
    dateFormat: PropTypes.string,
    showTime: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.pickerRef = null;
  }

  clickOutside = () => {
    this.pickerRef.cancelFocusInput();
  };

  render() {
    const {
      placeholderText,
      dateFormat,
      showTime,
    } = this.props;

    if (placeholderText) {
      return (
        <FormattedMessage id={ placeholderText }>
          {
            placeholderMessage => (
              <div className='datapicker-wrapper'>
                <DatePicker
                  { ...this.props }
                  { ...showTime }
                  dateFormat={ dateFormat }
                  aria-label={ placeholderMessage }
                  placeholderText={ placeholderMessage }
                  ref={ (node) => { this.pickerRef = node; } }
                  onClickOutside={ this.clickOutside }
                />
              </div>
            )
          }
        </FormattedMessage>
      );
    }
    return (
      <div>
        <DatePicker
          { ...this.props }
          { ...showTime }
          dateFormat={ dateFormat }
          ref={ (node) => { this.pickerRef = node; } }
          onClickOutside={ this.clickOutside }
        />
      </div>
    );
  }
}

export default FilterDatePicker;
