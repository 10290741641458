import React from 'react';
import PropTypes from 'prop-types';
import { IntercomProvider } from 'react-use-intercom';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import loadEnvVariable from '@src/js/static/LoadEnv';

const IntercomComponent = ({
  hideLauncher, children,
}) => {
  const loggedUser = selectUserInfo();

  const visibleUser = () => {
    const companyVisibility = loggedUser.companyVisibility;
    const visibility = companyVisibility != null ? companyVisibility : loggedUser.partnerVisibility;
    return visibility < 3 ? 'public' : 'hidden';
  };

  const companyType = () => {
    let formatType = '';
    switch (loggedUser.companyType) {
      case 1:
        formatType = 'growth';
        break;
      case 2:
        if (loggedUser.compassType === 1) {
          formatType = 'etinerary_digital_compass';
        }
        if (loggedUser.compassType === 2) {
          formatType = 'etinerary_growth_compass';
        }
        break;
      case 5:
        formatType = 'startup';
        break;
      default:
        break;
    }
    switch (loggedUser.partnerType) {
      case 1:
        formatType = 'programme_partner';
        break;
      case 2:
        formatType = 'funding_partner';
        break;
      case 3:
        formatType = 'both_partner';
        break;
      case 4:
        formatType = 'basic_partner';
        break;
      default:
        break;
    }
    return formatType;
  };

  const configData = loggedUser ? {
    userId: loggedUser.uuid,
    email: `${ loggedUser.uuid }@elite-network.com`,
    name: loggedUser.firstName,
    hideDefaultLauncher: hideLauncher,
    customAttributes: {
      pa: loggedUser.userRoles.includes('ROLE_ADMIN'),
      pra: loggedUser.userRoles.includes('ROLE_PROGRAMME_ADMIN'),
      rm: loggedUser.userRoles.includes('ROLE_RELATIONSHIP_MANAGER'),
      editor: loggedUser.userRoles.includes('ROLE_EDITOR'),
      Company_Type: companyType(),
      Company_Visibility: visibleUser(),
      User_Language: loggedUser.preferredLanguage,
    },
  } : {
    hideDefaultLauncher: hideLauncher,
  };

  return (
    <IntercomProvider
      appId={ loadEnvVariable('INTERCOM_APP_ID') }
      autoBootProps={ configData }
      autoBoot
    >
      { children }
    </IntercomProvider>
  );
};

IntercomComponent.propTypes = {
  hideLauncher: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default IntercomComponent;
