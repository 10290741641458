import fetchResource from '@src/js/api/fetch-resource';
import { AdminEventFilters } from '@src/js/reducers/events/adminEvent';
import { format } from 'date-fns';

const DATE_FORMAT = 'dd-MM-yyyy';

type FormattedFilters = {
  search: string
  eventCreatedStart?: string
  eventCreatedEnd?: string
  programme: string
  type: string
  category?: string
  location?: string
  page: string
}

const getFormattedFilters = ((filters: AdminEventFilters): FormattedFilters => ({
  ...filters,
  programme: filters?.programme?.map((programme) => programme.value).join() ?? '',
  eventCreatedStart: filters?.eventCreatedStart ? format(filters.eventCreatedStart, DATE_FORMAT) : '',
  eventCreatedEnd: filters?.eventCreatedEnd ? format(filters.eventCreatedEnd, DATE_FORMAT) : '',
  category: filters?.category || '',
  location: filters?.location || '',
  page: filters?.page?.toString() ?? '',
}));

export function getAdminEvents(filters: AdminEventFilters) {
  const newFilters = getFormattedFilters(filters);
  let url = 'api/admin/events';

  if (newFilters) {
    url += `?${ new URLSearchParams(newFilters) }`;
  }

  return fetchResource(url);
}

export function getAdminEventsFilterOptions(filterOptions: any) {
  const url = `api/admin/events/filter-options?page=${ filterOptions.page }`
    + `&query=${ filterOptions.query }`;

  return fetchResource(url, {
    method: 'GET',
  });
}

export function postReminderEvent(slug: string, registered: boolean, templateId: number, scheduling?: object) {
  const options = {
    method: 'POST',
    body: scheduling,
  };
  return fetchResource(`api/event/${ slug }/reminder/${ templateId }/${ registered ? 'registered' : 'unregistered' }`,
    options);
}
