import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import TextInput from '@src/js/components/global/inputs/TextInput';
import Button from '@src/js/components/global/buttons/Buttons';
import Field from '@src/js/helpers/FinalFormFieldAdapter';

export default class CompanyFormShareholders extends Component {
  static propTypes = {
    pushMutator: PropTypes.func,
  };

  render() {
    const {
      pushMutator,
    } = this.props;

    return (
      <div className='edit-company-page edit-company-page__section'>
        <div className='row'>
          <div className='col-xs'>
            <div className='edit-company-page__title edit-user-page-tab-content-title'>
              <FormattedMessage id='company_form.shareholders' />
            </div>
          </div>
        </div>

        <div className='row m-b-2'>
          <div className='col-xs-12 col-lg-12'>
            <Field
              name='basic.shareholdersSource'
              fieldName='basic.shareholdersSource'
              fieldType='text'
              fieldLabel='company_form.source'
              fieldMaxLength={ 200 }
              component={ TextInput }
            />
          </div>

          <div className='col-xs-12 col-lg-12'>
            <Field
              name='basic.shareholdersUpdate'
              fieldName='basic.shareholdersUpdate'
              fieldType='text'
              fieldLabel='company_form.update'
              fieldMaxLength={ 200 }
              component={ TextInput }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-xs'>
            <FieldArray name='shareholders'>
              { ({ fields }) => fields.map((name, index) => (
                <div key={ name }>
                  <div className='row position-relative'>
                    <div className='col-lg-5 col-xs-10'>
                      <Field
                        name={ `${ name }.name` }
                        fieldName='shareholders.name'
                        fieldType='text'
                        fieldLabel='name'
                        component={ TextInput }
                        fieldMaxLength={ 255 }
                      />
                    </div>
                    <div className='col-lg-5 col-xs-10'>
                      <Field
                        name={ `${ name }.percentage` }
                        fieldName='shareholders.percentage'
                        fieldType='text'
                        fieldLabel='company_form.percentage'
                        component={ TextInput }
                        fieldMaxLength={ 255 }
                      />
                    </div>
                    <div className='col-lg-2 col-xs-2'>
                      <div
                        className='edit-company-page__section--remove'
                        role='presentation'
                        onClick={ () => fields.remove(index) }
                      >
                        <span className='icon icon-CloseSmall' />
                      </div>
                    </div>
                  </div>
                </div>
              )) }
            </FieldArray>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs p-b-3'>
            <Button
              buttonType='button'
              buttonText='company_form.addNew'
              onClickFunc={ () => pushMutator('shareholders', undefined) }
              additionalClass='m-r-2'
            />
          </div>
        </div>

      </div>
    );
  }
}
