import { ItemBase } from '@app/components/global/list/DataTable';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props<T> = {
  item: T,
  values: T[],
  setValues: (i: T[]) => void,
}

const ActionButton = <T extends ItemBase>({ item, values, setValues: setValue }: Props<T>) => {
  const removeItem = () => {
    if (!values.length) return;
    setValue(values.filter(v => v?.id !== item.id));
  };

  const addItem = () => {
    setValue([...values, item]);
  };

  return values.map(v => v?.id).includes(item.id) ? (
    <div
      className='eop-btn eop-btn-sm eop-btn-empty eop-btn-red'
      onClick={ removeItem }
      role='presentation'
    >
      <FormattedMessage id='bulkMessage.action.remove' />
    </div>
  ) : (
    <div
      className='eop-btn eop-btn-sm eop-btn-empty'
      onClick={ addItem }
      role='presentation'
    >
      <FormattedMessage id='bulkMessage.action.add' />
    </div>
  );
};

export default ActionButton;
