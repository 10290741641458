import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import * as api from '@app/store/taxonomy/api';
import {
  GET_COUNTRIES_TREE_START, GET_COUNTRIES_TREE_SUCCESS, GET_COUNTRIES_TREE_FAIL,
  GET_SECTORS_TREE_START, GET_SECTORS_TREE_SUCCESS, GET_SECTORS_TREE_FAIL,
  GET_TURNOVER_ETINERARIES_START, GET_TURNOVER_ETINERARIES_SUCCESS, GET_TURNOVER_ETINERARIES_FAIL,
  GET_PARTNER_EXPERTISES_START, GET_PARTNER_EXPERTISES_SUCCESS, GET_PARTNER_EXPERTISES_FAIL,
  GET_PARTNER_SECTORS_START, GET_PARTNER_SECTORS_SUCCESS, GET_PARTNER_SECTORS_FAIL,
  GET_PARTNER_SECTORS_TREE_START, GET_PARTNER_SECTORS_TREE_SUCCESS, GET_PARTNER_SECTORS_TREE_FAIL,
  GET_DIMENSIONS_START, GET_DIMENSIONS_SUCCESS, GET_DIMENSIONS_FAIL,
  GET_STARTUP_STAGES_START, GET_STARTUP_STAGES_SUCCESS, GET_STARTUP_STAGES_FAIL,
  GET_ETINERARY_COMPANY_TYPES_START, GET_ETINERARY_COMPANY_TYPES_SUCCESS, GET_ETINERARY_COMPANY_TYPES_FAIL,
} from '@app/store/taxonomy/actions';

function* getCountriesTree() {
  try {
    const result = yield call(() => api.getCountriesTree());
    yield put({ type: GET_COUNTRIES_TREE_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_COUNTRIES_TREE_FAIL, error });
  }
}
function* getSectorsTree() {
  try {
    const result = yield call(() => api.getSectorsTree());
    yield put({ type: GET_SECTORS_TREE_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_SECTORS_TREE_FAIL, error });
  }
}
function* getTurnoverEtineraries() {
  try {
    const result = yield call(() => api.getTurnoverEtineraries());
    yield put({ type: GET_TURNOVER_ETINERARIES_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_TURNOVER_ETINERARIES_FAIL, error });
  }
}
function* getPartnerExpertises() {
  try {
    const result = yield call(() => api.getPartnerExpertises());
    yield put({ type: GET_PARTNER_EXPERTISES_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_PARTNER_EXPERTISES_FAIL, error });
  }
}
function* getPartnerSectors() {
  try {
    const result = yield call(() => api.getPartnerSectors());
    yield put({ type: GET_PARTNER_SECTORS_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_PARTNER_SECTORS_FAIL, error });
  }
}
function* getPartnerSectorsTree() {
  try {
    const result = yield call(() => api.getPartnerSectorsTree());
    yield put({ type: GET_PARTNER_SECTORS_TREE_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_PARTNER_SECTORS_TREE_FAIL, error });
  }
}
function* getDimensions() {
  try {
    const result = yield call(() => api.getDimensions());
    yield put({ type: GET_DIMENSIONS_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_DIMENSIONS_FAIL, error });
  }
}
function* getStartupStages() {
  try {
    const result = yield call(() => api.getStartupStages());
    yield put({ type: GET_STARTUP_STAGES_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_STARTUP_STAGES_FAIL, error });
  }
}
function* getEtineraryCompanyTypes() {
  try {
    const result = yield call(() => api.getEtineraryCompanyTypes());
    yield put({ type: GET_ETINERARY_COMPANY_TYPES_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_ETINERARY_COMPANY_TYPES_FAIL, error });
  }
}

function* root() {
  yield takeLatest(GET_COUNTRIES_TREE_START, getCountriesTree);
  yield takeLatest(GET_SECTORS_TREE_START, getSectorsTree);
  yield takeLatest(GET_TURNOVER_ETINERARIES_START, getTurnoverEtineraries);
  yield takeLatest(GET_PARTNER_EXPERTISES_START, getPartnerExpertises);
  yield takeLatest(GET_PARTNER_SECTORS_START, getPartnerSectors);
  yield takeLatest(GET_PARTNER_SECTORS_TREE_START, getPartnerSectorsTree);
  yield takeLatest(GET_DIMENSIONS_START, getDimensions);
  yield takeLatest(GET_STARTUP_STAGES_START, getStartupStages);
  yield takeLatest(GET_ETINERARY_COMPANY_TYPES_START, getEtineraryCompanyTypes);
}

export default root;
