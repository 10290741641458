import React from 'react';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

type Props = {
  name: string,
  label?: string,
  tooltip?: string,
  translate?: boolean,
  required?: boolean,
}

const InputLabel = ({
  translate = true, label, tooltip, required, name,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  return (
    <label className='eop-text-input__label font-size-icon'>
      { required === true && <span className='required-icon' /> }
      { label && fieldLabel }
      { tooltip && fieldTooltip && (
      <>
        <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ `${ name }-tooltip` } />
        <ReactTooltip
          id={ `${ name }-tooltip` }
          place='top'
          effect='solid'
          multiline={ true }
          className='w-25'
        >
          { fieldTooltip }
        </ReactTooltip>
      </>
      )}
    </label>

  );
};

export default InputLabel;
