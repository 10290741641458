import {
  all, delay, take, select,
  call, put, takeLatest, race,
} from 'redux-saga/effects';
import * as api from '@app/store/documentsArea/api';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import { ectPath } from '@src/js/constants/routes';
import {
  CREATE_FOLDER_START, RENAME_FOLDER_START, FILE_LIST_START,
  FILE_LIST_SUCCESS, CREATE_FILE_START, MOVE_FILE_START,
  DELETE_FILE_START, FOLDER_FAIL, FOLDER_SUCCESS,
  FILE_FAIL, FILE_SUCCESS, FILE_LIST_FAIL,
  GET_PERMISSIONS_FAIL, GET_PERMISSIONS_START, GET_PERMISSIONS_SUCCESS,
  SET_PERMISSIONS_FAIL, SET_PERMISSIONS_START, SET_PERMISSIONS_SUCCESS,
  GET_LIST_START, GET_LIST_FAIL, GET_LIST_SUCCESS, DOWNLOAD_TASK_CANCEL,
  DOWNLOAD_TASK_START, DOWNLOAD_TASK_FORCE_CANCEL, DOWNLOAD_TASK_CONTINUE,
  ASSOCIATE_SIGNED_DOCUMENT_START, ASSOCIATE_SIGNED_DOCUMENT_SUCCESS, ASSOCIATE_SIGNED_DOCUMENT_FAIL,
  MOVE_FILE_LIST_SUCCESS,
  MOVE_FILE_LIST_FAIL,
  MOVE_FILE_LIST_START,
} from '@app/store/documentsArea/actions';

function* createFolder({
  slug, data, dataRoomType, folderId,
}) {
  try {
    yield call(() => api.createFolder(dataRoomType, slug, data));
    yield all([
      put({ type: FOLDER_SUCCESS }),
      put({
        type: FILE_LIST_START, slug, dataRoomType, folderId,
      }),
    ]);
  } catch (error) {
    yield put({ type: FOLDER_FAIL, error });
  }
}

function* renameFolder({
  slug, id, data, dataRoomType, folderId,
}) {
  try {
    yield call(() => api.renameFolder(slug, id, data, dataRoomType));
    yield all([
      put({ type: FOLDER_SUCCESS }),
      put({
        type: FILE_LIST_START, slug, dataRoomType, folderId,
      }),
    ]);
  } catch (error) {
    yield put({ type: FOLDER_FAIL, error });
  }
}

function* fileList({
  slug, dataRoomType, folderId, search = '', training = '',
}) {
  try {
    const result = yield call(() => api.fileList({
      slug, dataRoomType, folderId, search, training, move: '',
    }));
    yield put({ type: FILE_LIST_SUCCESS, result });
  } catch (error) {
    yield put({ type: FILE_LIST_FAIL, error });
  }
}

function* moveFileList({
  slug, dataRoomType, folderId,
}) {
  try {
    const result = yield call(() => api.fileList({
      slug, dataRoomType, folderId, search: '', training: '', move: 'true',
    }));
    yield put({ type: MOVE_FILE_LIST_SUCCESS, result });
  } catch (error) {
    yield put({ type: MOVE_FILE_LIST_FAIL, error });
  }
}

function* createFile({
  files, slug, id, dataRoomType, folderId,
}) {
  try {
    yield call(() => api.createFile(files, slug, id, dataRoomType));
    yield all([
      put({ type: FILE_SUCCESS }),
      put({
        type: FILE_LIST_START, slug, dataRoomType, folderId,
      }),
    ]);
  } catch (error) {
    yield put({ type: FILE_FAIL, error });
  }
}

function* moveFile({
  slug, id, data, dataRoomType, isFolder, folderId,
}) {
  try {
    yield call(() => api.moveFile(slug, id, data, dataRoomType, isFolder));
    yield all([
      put({ type: FILE_SUCCESS }),
      put({
        type: FILE_LIST_START, slug, dataRoomType, folderId,
      }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: FILE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* deleteFile({
  slug, id, dataRoomType, isFolder, folderId,
}) {
  try {
    yield call(() => api.deleteFile(slug, id, dataRoomType, isFolder));
    yield all([
      put({ type: FILE_SUCCESS }),
      put({
        type: FILE_LIST_START, slug, dataRoomType, folderId,
      }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: FILE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getPermissions(options) {
  try {
    const result = yield call(() => api.getPermissions(options));
    yield put({ type: GET_PERMISSIONS_SUCCESS, result: result?.users, reset: options.reset });
  } catch (error) {
    yield put({ type: GET_PERMISSIONS_FAIL, error });
  }
}

function* setPermissions({
  slug, id, dataRoomType, entity, data,
}) {
  try {
    yield call(() => api.setPermissions(slug, id, dataRoomType, entity, data));
    yield all([
      put({ type: SET_PERMISSIONS_SUCCESS }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: SET_PERMISSIONS_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getDataRoomList() {
  try {
    const result = yield call(() => api.getDataRoomList());
    yield put({ type: GET_LIST_SUCCESS, result });
  } catch (error) {
    yield put({ type: GET_LIST_FAIL, error });
  }
}

const POLLING_DELAY = 1000;

function* downloadPollingWorker(id) {
  while (true) {
    try {
      // eslint-disable-next-line no-loop-func
      const result = yield call(() => api.download(id));
      if (result?.status === 'completed') {
        const url = `${ ectPath() }/api/media/folder/${ id }`;
        window.onunload = undefined;
        window.location.assign(url);
        yield put({ type: DOWNLOAD_TASK_CANCEL });
      } else {
        yield delay(POLLING_DELAY);
      }
    } catch (error) {
      yield all([
        put({ type: DOWNLOAD_TASK_CANCEL }),
        put(showToastrError()),
      ]);
    }
  }
}

function* downloadWatchWorker({ task }) {
  try {
    const result = yield call(() => api.downloadStart(task));
    if (!result?.id) {
      throw new Error('missing task id');
    }
    yield put({ type: DOWNLOAD_TASK_CONTINUE, id: result.id });
    window.onunload = () => api.downloadCancel(result.id);
    yield race({
      task: call(() => downloadPollingWorker(result.id)),
      cancel: take(DOWNLOAD_TASK_CANCEL),
    });
  } catch (error) {
    yield put(showToastrError());
  }
}

function* downloadCancel() {
  const taskId = yield select((state) => state.dataroom?.downloadTaskId);
  yield put({ type: DOWNLOAD_TASK_CANCEL });
  if (taskId) {
    yield call(() => api.downloadCancel(taskId));
  }
}

function* associateSignedDocument({ companySlug, dataRoomType }) {
  try {
    yield call(() => api.associateSignedDocument(companySlug, dataRoomType));
    yield all([
      put({ type: ASSOCIATE_SIGNED_DOCUMENT_SUCCESS }),
      put({ type: FILE_LIST_START, slug: companySlug, dataRoomType }),
    ]);
  } catch (error) {
    yield all([
      put({ type: ASSOCIATE_SIGNED_DOCUMENT_FAIL, error }),
      put({ type: FILE_LIST_START, slug: companySlug, dataRoomType }),
    ]);
  }
}

function* root() {
  yield takeLatest(CREATE_FOLDER_START, createFolder);
  yield takeLatest(RENAME_FOLDER_START, renameFolder);
  yield takeLatest(FILE_LIST_START, fileList);
  yield takeLatest(MOVE_FILE_LIST_START, moveFileList);
  yield takeLatest(CREATE_FILE_START, createFile);
  yield takeLatest(MOVE_FILE_START, moveFile);
  yield takeLatest(DELETE_FILE_START, deleteFile);
  yield takeLatest(GET_PERMISSIONS_START, getPermissions);
  yield takeLatest(SET_PERMISSIONS_START, setPermissions);
  yield takeLatest(GET_LIST_START, getDataRoomList);
  yield takeLatest(DOWNLOAD_TASK_START, downloadWatchWorker);
  yield takeLatest(DOWNLOAD_TASK_FORCE_CANCEL, downloadCancel);
  yield takeLatest(ASSOCIATE_SIGNED_DOCUMENT_START, associateSignedDocument);
}

export default root;
