import { takeLatest, call, put } from 'redux-saga/effects';

import {
  TOKEN_VALIDATION_START,
  TOKEN_VALIDATION_ERROR,
  TOKEN_VALIDATION_SUCCESS,
} from '@src/js/actions/tokenValidation';

import * as api from '@src/js/api/tokenValidation';

function* validateToken(options) {
  try {
    const data = yield call(() => api.validateToken(options.token, options.email, options.urlValidateToken));
    yield put({ type: TOKEN_VALIDATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: TOKEN_VALIDATION_ERROR, error });
  }
}

export function* validateTokenWatcher() {
  yield takeLatest(TOKEN_VALIDATION_START, validateToken);
}

export default [
  validateTokenWatcher(),
];
