import { Compliance } from '@src/js/constants/entities';
import produce from 'immer';

import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  UPDATE_DATA_START,
  UPDATE_DATA_FAIL,
  UPDATE_DATA_SUCCESS,
  GET_LIST_START,
  GET_LIST_FAIL,
  GET_LIST_SUCCESS,
  SIGN_START,
  SIGN_FAIL,
  SIGN_SUCCESS,
  APPROVE_START,
  APPROVE_FAIL,
  APPROVE_SUCCESS,
  SET_ACTIVE_STEP,
  CLEAR_SIGN_START,
  CLEAR_SIGN_SUCCESS,
  CLEAR_SIGN_FAIL,
  STATUS_START,
  STATUS_SUCCESS,
  STATUS_FAIL,
  MEMBERS_START,
  MEMBERS_SUCCESS,
  MEMBERS_FAIL,
  CLEAR_COMPLIANCE,
  ON_SAVE_COMPLIANCE,
  CREATE_FAIL, CREATE_START, CREATE_SUCCESS,
  CLEAR_REQUEST,
} from '@app/store/compliance/actions';
import { FORM_STEPS } from '@app/store/compliance/constants';

const validation = {};
Object.keys(FORM_STEPS).forEach(step => {
  validation[step] = false;
});

export const initialState = {
  data: null,
  dataLoading: false,
  dataError: null,
  updateDataLoading: false,
  updateDataError: null,
  list: null,
  listLoading: false,
  listError: null,
  signData: {},
  signLoading: false,
  signSuccess: false,
  signError: null,
  clearSignLoading: false,
  clearSignSuccess: false,
  clearSignError: null,
  approveLoading: false,
  approveSuccess: false,
  approveError: null,
  status: null,
  statusLoading: false,
  statusError: null,
  currentStep: FORM_STEPS.COMPANY_SUMMARY,
  onSave: { action: null, step: null, newStep: null },
  validation,
  touchedSteps: [FORM_STEPS.COMPANY_SUMMARY],
  submitted: false,
  membersLoading: false,
  membersError: null,
  members: null,
};

/* eslint-disable default-case,no-param-reassign,no-case-declarations,consistent-return */
function complianceReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_DATA_START:
        draft.dataLoading = true;
        draft.dataError = null;
        draft.data = null;
        break;
      case GET_DATA_SUCCESS:
        draft.dataLoading = false;
        draft.dataError = null;
        draft.data = action.data;
        break;
      case GET_DATA_FAIL:
        draft.dataLoading = false;
        draft.dataError = action.error;
        draft.data = null;
        break;
      case UPDATE_DATA_START:
        draft.updateDataLoading = true;
        draft.updateDataError = null;
        break;
      case UPDATE_DATA_SUCCESS:
        if (draft.onSave?.action === 'submit') {
          draft.submitted = true;
        }
        draft.updateDataLoading = false;
        draft.updateDataError = null;
        draft.onSave = { action: null, step: null, newStep: null };
        draft.validation = action.validation;
        draft.data = JSON.parse(JSON.stringify(action.data));
        break;
      case UPDATE_DATA_FAIL:
        draft.updateDataLoading = false;
        draft.updateDataError = action.error;
        draft.onSave = { action: null, step: null, newStep: null };
        break;
      case GET_LIST_START:
        draft.listLoading = true;
        draft.listError = null;
        draft.list = null;
        break;
      case GET_LIST_SUCCESS:
        draft.listLoading = false;
        draft.listError = null;
        draft.list = action.data;
        break;
      case GET_LIST_FAIL:
        draft.listLoading = false;
        draft.listError = action.error;
        draft.list = null;
        break;
      case SIGN_START:
        if (!draft.signData[action.section]) draft.signData[action.section] = {};
        draft.signLoading = true;
        draft.signError = null;
        break;
      case SIGN_SUCCESS:
        draft.signData[action.section][action.tableKey] = action.data;
        draft.signLoading = false;
        draft.signError = null;
        draft.signSuccess = true;
        break;
      case SIGN_FAIL:
        draft.signLoading = false;
        draft.signError = action.error;
        draft.signSuccess = false;
        break;
      case CLEAR_SIGN_START:
        if (!draft.signData[action.section]) draft.signData[action.section] = {};
        draft.clearSignLoading = false;
        draft.clearSignError = null;
        break;
      case CLEAR_SIGN_SUCCESS:
        draft.signData[action.section][action.tableKey] = {
          id: 0,
          name: '',
          email: '',
          date: '',
        };
        draft.clearSignLoading = false;
        draft.clearSignError = null;
        draft.clearSignSuccess = true;
        break;
      case CLEAR_SIGN_FAIL:
        draft.clearSignLoading = false;
        draft.clearSignError = action.error;
        draft.clearSignSuccess = false;
        break;
      case APPROVE_START:
        draft.approveLoading = true;
        draft.approveError = null;
        break;
      case APPROVE_SUCCESS:
        draft.approveLoading = false;
        draft.approveError = null;
        draft.approveSuccess = true;
        const idx = state.list?.items?.findIndex(c => c.slug === action.slug);
        if (idx > -1) {
          if (action.state === Compliance.ACCEPTED) {
            draft.list.items[idx].approvalDate = new Date();
          }
          draft.list.items[idx].status = action.state;
        }
        break;
      case APPROVE_FAIL:
        draft.approveLoading = false;
        draft.approveError = action.error;
        draft.approveSuccess = false;
        break;
      case SET_ACTIVE_STEP:
        draft.onSave = { action: null, step: null, newStep: null };
        draft.currentStep = action.step;
        if (!draft.touchedSteps.includes(action.step)) {
          draft.touchedSteps = [...draft.touchedSteps, action.step];
        }
        break;
      case STATUS_START:
      case CREATE_START:
        draft.statusLoading = true;
        draft.statusError = null;
        break;
      case STATUS_SUCCESS:
        draft.statusLoading = false;
        draft.statusError = null;
        draft.status = action.data;
        break;
      case CREATE_SUCCESS:
        draft.statusLoading = false;
        draft.statusError = null;
        draft.status = action.data;
        draft.requestSent = true;
        break;
      case STATUS_FAIL:
      case CREATE_FAIL:
        draft.statusLoading = false;
        draft.statusError = action.error;
        draft.status = null;
        break;
      case CLEAR_REQUEST:
        draft.requestSent = undefined;
        break;
      case ON_SAVE_COMPLIANCE:
        draft.onSave.step = action.step;
        draft.onSave.action = action.action;
        draft.onSave.newStep = action.newStep;
        break;
      case CLEAR_COMPLIANCE:
        return initialState;
      case MEMBERS_START:
        draft.membersLoading = true;
        draft.membersError = null;
        break;
      case MEMBERS_SUCCESS:
        draft.membersLoading = false;
        draft.membersError = null;
        draft.members = action.data;
        break;
      case MEMBERS_FAIL:
        draft.membersLoading = false;
        draft.membersError = action.error;
        draft.members = null;
        break;
    }
  }
  );
}
/* eslint-enable */

export default complianceReducer;
