import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

export default class PeerItem extends Component {
  static propTypes = {
    peer: PropTypes.object,
    selectPeer: PropTypes.func,
    isMemberSelection: PropTypes.bool,
    isSelected: PropTypes.bool,
  };

  static defaultProps = {
    isMemberSelection: false,
    isSelected: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };
    this.setSelected = this.setSelected.bind(this);
  }

  setSelected() {
    const {
      selectPeer,
      peer,
    } = this.props;

    if (peer.permissionByRole) {
      return;
    }

    selectPeer(peer);
  }

  render() {
    const {
      peer, isMemberSelection, isSelected,
    } = this.props;

    const isSelectedPeer = (isSelected || peer.permissionByRole || peer.selected);

    const peerClassNames = ClassNames('peer-item', {
      'peer-item__selected': isSelectedPeer,
      'peer-item__disabled': peer.permissionByRole,
    });

    const peerButtonClassNames = ClassNames('icon', {
      [`icon__selected ${ isMemberSelection ? 'icon-small-Check' : 'icon-small-Send' }`]: isSelectedPeer,
      'icon-medium-Add': !isSelectedPeer,
    });

    return (
      <div
        key={ peer.id }
        role='presentation'
        className={ peerClassNames }
        onClick={ this.setSelected }
      >
        <div className='peer-item-logo'>
          <img src={ peer.avatar } alt='avatar' />
        </div>
        <div className='peer-item-subject'>
          <div
            className={ `peer-item-subject--bold ${ (isSelectedPeer) ? 'bold__selected' : '' }` }
          >
            { peer.fullName }
          </div>
          <div className='peer-item-subject__info'>
            <span className='peer-item-subject__info--bold'>
              { peer.jobTitle }
            </span>
            { peer.company
            && (
              <span>, </span>
            ) }
            { peer.company && peer.company }
          </div>

        </div>
        <div className='peer-item-button'>
          <span className={ peerButtonClassNames } />
        </div>
      </div>
    );
  }
}
