import produce from 'immer';

import {
  GET_BROKER_START,
  GET_BROKER_SUCCESS,
  GET_BROKER_FAIL,
  UPDATE_BROKER_FAIL,
  UPDATE_BROKER_SUCCESS,
  UPDATE_BROKER_START,
  GET_BROKER_LIST_START,
  GET_BROKER_LIST_FAIL,
  GET_BROKER_LIST_SUCCESS,
} from '@app/store/funding/actions/broker';

const initialState = {
  loading: false,
  error: null,
  broker: null,
  brokerListLoading: false,
  brokerListError: null,
  brokerList: [],
  hasMore: false,
  params: null,
};

function broker(state = initialState, action) {
  return produce(state, (d) => {
    const draft = d;
    switch (action.type) {
      case GET_BROKER_START:
        draft.loading = true;
        draft.error = null;
        draft.broker = null;
        break;
      case GET_BROKER_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        draft.broker = null;
        break;
      case GET_BROKER_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.broker = action.data;
        break;
      case UPDATE_BROKER_START:
        draft.loading = true;
        draft.error = null;
        break;
      case UPDATE_BROKER_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        break;
      case UPDATE_BROKER_SUCCESS:
        draft.brokerList.concat(action.data);
        draft.loading = false;
        draft.error = null;
        draft.updated = true;
        break;
      case GET_BROKER_LIST_START:
        draft.brokerListLoading = true;
        draft.brokerListError = null;
        draft.brokerPageCount = 0;
        break;
      case GET_BROKER_LIST_FAIL:
        draft.brokerListLoading = false;
        draft.brokerListError = action.error.message;
        break;
      case GET_BROKER_LIST_SUCCESS:
        draft.brokerListLoading = false;
        draft.brokerList = action.data.currentPage > 0
          && !action.params?.infinite ? state.brokerList.concat(action.data.items) : action.data.items;
        draft.hasMore = action.data.currentPage + 1 < (action.data.count / action.data.pageSize);
        draft.brokerPageCount = action.data.pagesCount;
        draft.params = action.params;
        break;
      default:
        break;
    }
  });
}

export default broker;
