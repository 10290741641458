import { takeLatest, call, put } from 'redux-saga/effects';

import {
  RM_CREATE_COMPANY_START,
  RM_CREATE_COMPANY_ERROR,
  RM_CREATE_COMPANY_SUCCESS,
} from '@src/js/actions/company/relationshipManagerCompany';

import * as api from '@src/js/api/company/relationshipManagerCompany';

function* rmCreateCompany(options) {
  try {
    const data = yield call(() => api.rmCreateCompany(options.company.values, options.userType));
    yield put({ type: RM_CREATE_COMPANY_SUCCESS, data });
    options.company.resolve();
  } catch (error) {
    yield put({ type: RM_CREATE_COMPANY_ERROR, error });
    options.company.reject(error.response);
  }
}

function* rmCreateCompanyWatcher() {
  yield takeLatest(RM_CREATE_COMPANY_START, rmCreateCompany);
}

export default [
  rmCreateCompanyWatcher(),
];
