const scope = 'Compliance';

export const GET_DATA_START = `${ scope }/GET_DATA_START`;
export const GET_DATA_FAIL = `${ scope }/GET_DATA_FAIL`;
export const GET_DATA_SUCCESS = `${ scope }/GET_DATA_SUCCESS`;

export const UPDATE_DATA_START = `${ scope }/UPDATE_DATA_START`;
export const UPDATE_DATA_FAIL = `${ scope }/UPDATE_DATA_FAIL`;
export const UPDATE_DATA_SUCCESS = `${ scope }/UPDATE_DATA_SUCCESS`;

export const GET_LIST_START = `${ scope }/GET_LIST_START`;
export const GET_LIST_FAIL = `${ scope }/GET_LIST_FAIL`;
export const GET_LIST_SUCCESS = `${ scope }/GET_LIST_SUCCESS`;

export const SIGN_START = `${ scope }/SIGN_START`;
export const SIGN_FAIL = `${ scope }/SIGN_FAIL`;
export const SIGN_SUCCESS = `${ scope }/SIGN_SUCCESS`;

export const CLEAR_SIGN_START = `${ scope }/CLEAR_SIGN_START`;
export const CLEAR_SIGN_FAIL = `${ scope }/CLEAR_SIGN_FAIL`;
export const CLEAR_SIGN_SUCCESS = `${ scope }/CLEAR_SIGN_SUCCESS`;

export const APPROVE_START = `${ scope }/APPROVE_START`;
export const APPROVE_FAIL = `${ scope }/APPROVE_FAIL`;
export const APPROVE_SUCCESS = `${ scope }/APPROVE_SUCCESS`;

export const SET_ACTIVE_STEP = `${ scope }/SET_ACTIVE_STEP`;

export const STATUS_START = `${ scope }/STATUS_START`;
export const STATUS_FAIL = `${ scope }/STATUS_FAIL`;
export const STATUS_SUCCESS = `${ scope }/STATUS_SUCCESS`;

export const CLEAR_COMPLIANCE = `${ scope }/CLEAR_COMPLIANCE`;
export const ON_SAVE_COMPLIANCE = `${ scope }/ON_SAVE_COMPLIANCE`;

export const MEMBERS_START = `${ scope }/MEMBERS_START`;
export const MEMBERS_FAIL = `${ scope }/MEMBERS_FAIL`;
export const MEMBERS_SUCCESS = `${ scope }/MEMBERS_SUCCESS`;

export const CREATE_START = `${ scope }/CREATE_START`;
export const CREATE_FAIL = `${ scope }/CREATE_FAIL`;
export const CREATE_SUCCESS = `${ scope }/CREATE_SUCCESS`;

export const FUNDING_OPTION_START = `${ scope }/FUNDING_OPTION_START`;
export const FUNDING_OPTION_FAIL = `${ scope }/FUNDING_OPTION_FAIL`;
export const FUNDING_OPTION_SUCCESS = `${ scope }/FUNDING_OPTION_SUCCESS`;

export const CLEAR_REQUEST = `${ scope }/CLEAR_REQUEST`;

export function getCompliance(slug, isAdmin) {
  return {
    type: GET_DATA_START,
    slug,
    isAdmin,
  };
}

export function updateCompliance({
  slug, data, message, isAdmin, params, onSave,
}) {
  return {
    type: UPDATE_DATA_START,
    slug,
    data,
    message,
    isAdmin,
    params,
    onSave,
  };
}
export function getComplianceList(filter) {
  return {
    type: GET_LIST_START,
    filter,
  };
}

export function signCompliance(slug, tableKey, section) {
  return {
    type: SIGN_START,
    slug,
    tableKey,
    section,
  };
}

export function clearSignCompliance(signId, tableKey, section) {
  return {
    type: CLEAR_SIGN_START,
    signId,
    tableKey,
    section,
  };
}

export function approveCompliance(slug, state) {
  return {
    type: APPROVE_START,
    slug,
    state,
  };
}

export function setActiveStep(step) {
  return {
    type: SET_ACTIVE_STEP,
    step,
  };
}

export function getComplianceStatus() {
  return {
    type: STATUS_START,
  };
}

export function clearCompliance() {
  return {
    type: CLEAR_COMPLIANCE,
  };
}

export function onSaveCompliance(step, action, newStep) {
  return {
    type: ON_SAVE_COMPLIANCE,
    step,
    action,
    newStep,
  };
}

export function getMembers(isAdmin, slug) {
  return {
    type: MEMBERS_START,
    isAdmin,
    slug,
  };
}

export function createCompliance(slug, fundingOption, desiredAmount, method) {
  return {
    type: CREATE_START,
    slug,
    fundingOption,
    desiredAmount,
    method,
  };
}

export function fundingOptionAction(data) {
  return {
    type: FUNDING_OPTION_START,
    data,
  };
}

export function clearRequest() {
  return {
    type: CLEAR_REQUEST,
  };
}
