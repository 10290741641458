import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DatePicker from './FilterDatepicker';
import { Label, RequiredIcon, Warning } from './style';

export default class Datepicker extends Component {
  static propTypes = {
    selected: PropTypes.object,
    form: PropTypes.object,
    fieldLabel: PropTypes.string,
    onChange: PropTypes.func,
    fieldRequired: PropTypes.bool,
    fieldDisabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    dateFormat: PropTypes.string,
    showTime: PropTypes.object,
    input: PropTypes.object,
    minDate: PropTypes.object,
    translate: PropTypes.bool,
  };

  static defaultProps = {
    isClearable: true,
    dateFormat: 'dd/MM/yyyy',
    translate: true,
  };

  render() {
    const {
      onChange, selected, fieldLabel, fieldRequired, fieldDisabled, isClearable,
      placeholder, meta, form, dateFormat, showTime, input, minDate, translate,
    } = this.props;

    return (
      <>
        { fieldLabel && (
          <Label>
            { fieldRequired && <RequiredIcon /> }
            { translate ? <FormattedMessage id={ fieldLabel } /> : fieldLabel }
          </Label>
        ) }
        <div className={ 'eop-text-datepicker__wrapper m-b-2' }>
          <DatePicker
            selected={ selected }
            onChange={ (e) => onChange(e, form) }
            className='eop-text-datepicker__input'
            disabled={ fieldDisabled }
            isClearable={ !fieldDisabled && isClearable }
            placeholderText={ placeholder }
            dateFormat={ dateFormat }
            showTime={ showTime }
            onBlur={ () => input.onBlur(input.value) }
            minDate={ minDate }
          />
          { !selected && !fieldDisabled && (
            <span className='eop-text-datepicker__input-icon icon-small-Calendar' />
          ) }
          { fieldDisabled && (
          <span className='eop-text-datepicker__input-icon icon-LockerSmall' />
          ) }
        </div>
        { meta && (
          <Warning>
            {
              (meta.error || (meta.submitError && meta.dirtySinceLastSubmit === false))
              && (meta.touched || ((meta.dirty || meta.dirtySinceLastSubmit) && meta.submitFailed)
              ) && <FormattedMessage id={ meta.error || meta.submitError } />
            }
          </Warning>
        ) }
      </>
    );
  }
}
