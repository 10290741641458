import { Action } from 'redux';

export const GET_SINGLE_NEWS_START = 'GET_SINGLE_NEWS_START';
export const GET_SINGLE_NEWS_ERROR = 'GET_SINGLE_NEWS_ERROR';
export const GET_SINGLE_NEWS_SUCCESS = 'GET_SINGLE_NEWS_SUCCESS';

export interface SingleNewsAction extends Action {
  slug: string
}

export function getSingleNews(slug: string): SingleNewsAction {
  return {
    type: GET_SINGLE_NEWS_START,
    slug,
  };
}
