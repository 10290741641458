const scope = 'COMPANY';

export const GET_MEMBERS_LIST_START = `${ scope }/GET_MEMBERS_LIST_START`;
export const GET_MEMBERS_LIST_FAIL = `${ scope }/GET_MEMBERS_LIST_FAIL`;
export const GET_MEMBERS_LIST_SUCCESS = `${ scope }/GET_MEMBERS_LIST_SUCCESS`;

export const SEND_SIGN_DOCUMENT_START = `${ scope }/SEND_SIGN_DOCUMENT_START`;
export const SEND_SIGN_DOCUMENT_FAIL = `${ scope }/SEND_SIGN_DOCUMENT_FAIL`;
export const SEND_SIGN_DOCUMENT_SUCCESS = `${ scope }/SEND_SIGN_DOCUMENT_SUCCESS`;

export const SIGN_DOCUMENT_START = `${ scope }/SIGN_DOCUMENT_START`;
export const SIGN_DOCUMENT_FAIL = `${ scope }/SIGN_DOCUMENT_FAIL`;
export const SIGN_DOCUMENT_SUCCESS = `${ scope }/SIGN_DOCUMENT_SUCCESS`;

export const GET_SIGN_DOCUMENT_START = `${ scope }/GET_SIGN_DOCUMENT_START`;
export const GET_SIGN_DOCUMENT_FAIL = `${ scope }/GET_SIGN_DOCUMENT_FAIL`;
export const GET_SIGN_DOCUMENT_SUCCESS = `${ scope }/GET_SIGN_DOCUMENT_SUCCESS`;

export const CLEAR_REDIRECT_URL = `${ scope }/CLEAR_REDIRECT_URL`;

export function getCompanyMembers(slug, filters) {
  return {
    type: GET_MEMBERS_LIST_START,
    slug,
    filters,
  };
}

export function sendSignDocument(id, uuid) {
  return {
    type: SEND_SIGN_DOCUMENT_START,
    id,
    uuid,
  };
}

export function signDocument(mobilePhone, uuid) {
  return {
    type: SIGN_DOCUMENT_START,
    mobilePhone,
    uuid,
  };
}

export function getSignDocument(uuid) {
  return {
    type: GET_SIGN_DOCUMENT_START,
    uuid,
  };
}

export function clearRedirectUrl() {
  return {
    type: CLEAR_REDIRECT_URL,
  };
}
