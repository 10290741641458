import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { scrollToAnchor } from '@src/js/layouts/header/navigation/utils';

class ScrollToTopOnMount extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    location: PropTypes.object,
  };

  componentDidMount() {
    const { location } = this.props;
    const hash = location.hash;
    if (hash) {
      scrollToAnchor(hash);
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
    } = this.props;

    return children;
  }
}

export default withRouter(ScrollToTopOnMount);
