export const HEADER_ELASTICSEARCH_START = 'HEADER_ELASTICSEARCH_START';
export const HEADER_ELASTICSEARCH_ERROR = 'HEADER_ELASTICSEARCH_ERROR';
export const HEADER_ELASTICSEARCH_SUCCESS = 'HEADER_ELASTICSEARCH_SUCCESS';
export const HEADER_ELASTICSEARCH_CLEAR = 'HEADER_ELASTICSEARCH_CLEAR';

export function getHeaderElasticsearchResults(query, page, limit) {
  return {
    type: HEADER_ELASTICSEARCH_START,
    query,
    page,
    limit,
  };
}

export function clearHeaderSearchResults() {
  return {
    type: HEADER_ELASTICSEARCH_CLEAR,
  };
}
