import {
  ADMIN_GET_EVENTS_START,
  ADMIN_GET_EVENTS_ERROR,
  ADMIN_GET_EVENTS_SUCCESS,
  ADMIN_GET_EVENTS_FILTER_OPTIONS_START,
  ADMIN_GET_EVENTS_FILTER_OPTIONS_ERROR,
  ADMIN_GET_EVENTS_FILTER_OPTIONS_SUCCESS,
  ADMIN_POST_EVENTS_REMINDER_START,
  ADMIN_POST_EVENTS_REMINDER_SUCCESS,
  ADMIN_POST_EVENTS_REMINDER_ERROR,
  ADMIN_CLEAR_REMINDER,
} from '@src/js/actions/events/adminEvents';

const initialState = {
  loading: false,
  error: null,
  events: null,
  filterOptionsLoading: false,
  filterOptionsError: null,
  filterOptions: null,
  reminderLoading: false,
  reminderDone: false,
};

const actionsMap = {
  // Async action
  [ADMIN_GET_EVENTS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    events: null,
  }),
  [ADMIN_GET_EVENTS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [ADMIN_GET_EVENTS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    events: action.data,
  }),
  [ADMIN_GET_EVENTS_FILTER_OPTIONS_START]: (state) => ({
    ...state,
    filterOptionsLoading: true,
    filterOptionsError: null,
    filterOptions: null,
  }),
  [ADMIN_GET_EVENTS_FILTER_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    filterOptionsLoading: false,
    filterOptionsError: action.error.message,
  }),
  [ADMIN_GET_EVENTS_FILTER_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    filterOptionsLoading: false,
    filterOptions: action.data,
  }),
  [ADMIN_POST_EVENTS_REMINDER_START]: (state) => ({
    ...state,
    reminderLoading: true,
  }),
  [ADMIN_POST_EVENTS_REMINDER_SUCCESS]: (state) => ({
    ...state,
    reminderLoading: false,
    reminderDone: true,
  }),
  [ADMIN_POST_EVENTS_REMINDER_ERROR]: (state) => ({
    ...state,
    reminderLoading: false,
  }),
  [ADMIN_CLEAR_REMINDER]: (state) => ({
    ...state,
    reminderDone: false,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
