import {
  GET_OPPORTUNITY_LOCATIONS_START,
  GET_OPPORTUNITY_LOCATIONS_ERROR,
  GET_OPPORTUNITY_LOCATIONS_SUCCESS,
  GET_OPPORTUNITY_SECTORS_START,
  GET_OPPORTUNITY_SECTORS_ERROR,
  GET_OPPORTUNITY_SECTORS_SUCCESS,
  GET_OPPORTUNITY_TYPE_START,
  GET_OPPORTUNITY_TYPE_ERROR,
  GET_OPPORTUNITY_TYPE_SUCCESS,
  GET_OPPORTUNITY_EXPERTISE_START,
  GET_OPPORTUNITY_EXPERTISE_ERROR,
  GET_OPPORTUNITY_EXPERTISE_SUCCESS,
  GET_OPPORTUNITY_DIMENSION_START,
  GET_OPPORTUNITY_DIMENSION_ERROR,
  GET_OPPORTUNITY_DIMENSION_SUCCESS,
  GET_OPPORTUNITY_ADVISOR_TYPE_START,
  GET_OPPORTUNITY_ADVISOR_TYPE_ERROR,
  GET_OPPORTUNITY_ADVISOR_TYPE_SUCCESS,
  GET_OPPORTUNITY_COVER_IMAGE_START,
  GET_OPPORTUNITY_COVER_IMAGE_ERROR,
  GET_OPPORTUNITY_COVER_IMAGE_SUCCESS,
  GET_OPPORTUNITY_COMPANY_START,
  GET_OPPORTUNITY_COMPANY_ERROR,
  GET_OPPORTUNITY_COMPANY_SUCCESS,
  GET_OPPORTUNITY_DATA_START,
  GET_OPPORTUNITY_DATA_ERROR,
  GET_OPPORTUNITY_DATA_SUCCESS,
  SAVE_OPPORTUNITY_START,
  SAVE_OPPORTUNITY_ERROR,
  SAVE_OPPORTUNITY_SUCCESS,
  EDIT_OPPORTUNITY_START,
  EDIT_OPPORTUNITY_ERROR,
  EDIT_OPPORTUNITY_SUCCESS,
  GET_OPPORTUNITY_PUBLISHERS_CLEAR,
  GET_OPPORTUNITY_PUBLISHERS_START,
  GET_OPPORTUNITY_PUBLISHERS_ERROR,
  GET_OPPORTUNITY_PUBLISHERS_SUCCESS,
  SET_FILTERS_SUCCESS,
  CLEAR_FILTERS,
  SET_OPPORTUNITY_PUBLISHER,
  RESET_OPPORTUNITY_DATA,
} from '@src/js/marketplace/actions/getOpportunityData';

const initialState = {
  loadingLocations: false,
  errorLocations: null,
  opportunityLocations: {},
  loadingSectors: false,
  errorSectors: null,
  opportunitySectors: {},
  loadingType: false,
  errorType: null,
  opportunityType: {},
  loadingExpertise: false,
  errorExpertise: null,
  opportunityExpertise: {},
  loadingDimension: false,
  errorDimension: null,
  opportunityDimension: {},
  loadingAdvisorType: false,
  errorAdvisorType: null,
  opportunityAdvisorType: {},

  loadingOpportunityCoverImage: false,
  errorOpportunityCoverImage: null,
  opportunityCoverImage: null,

  loadingOpportunityCompany: false,
  errorOpportunityCompany: null,
  opportunityCompany: [],

  loadingOpportunityData: false,
  errorOpportunityData: null,
  opportunityData: {},

  loading: false,
  error: null,
  data: {},

  loadingEditOpportunityData: false,
  errorEditOpportunityData: null,
  dataEditOpportunityData: {},

  loadingPublishers: false,
  errorPublishers: null,
  opportunityPublishers: {
    CurrentPage: 0,
    TotalPage: 0,
    PageSize: 0,
    Items: [],
  },
  publisherFilters: {},

  editFilters: {},
};

const actionsMap = {
  [GET_OPPORTUNITY_LOCATIONS_START]: (state) => ({
    ...state,
    loadingLocations: true,
    errorLocations: null,
    opportunityLocations: {},
  }),
  [GET_OPPORTUNITY_LOCATIONS_ERROR]: (state, action) => ({
    ...state,
    loadingLocations: false,
    errorLocations: action.error,
    opportunityLocations: {},
  }),
  [GET_OPPORTUNITY_LOCATIONS_SUCCESS]: (state, action) => ({
    ...state,
    loadingLocations: false,
    errorLocations: null,
    opportunityLocations: action.data,
  }),
  [GET_OPPORTUNITY_SECTORS_START]: (state) => ({
    ...state,
    loadingSectors: true,
    errorSectors: null,
    opportunitySectors: {},
  }),
  [GET_OPPORTUNITY_SECTORS_ERROR]: (state, action) => ({
    ...state,
    loadingSectors: false,
    errorSectors: action.error,
    opportunitySectors: {},
  }),
  [GET_OPPORTUNITY_SECTORS_SUCCESS]: (state, action) => ({
    ...state,
    loadingSectors: false,
    errorSectors: null,
    opportunitySectors: action.data,
  }),
  [GET_OPPORTUNITY_TYPE_START]: (state) => ({
    ...state,
    loadingType: true,
    errorType: null,
    opportunityType: {},
  }),
  [GET_OPPORTUNITY_TYPE_ERROR]: (state, action) => ({
    ...state,
    loadingType: false,
    errorType: action.error,
    opportunityType: {},
  }),
  [GET_OPPORTUNITY_TYPE_SUCCESS]: (state, action) => ({
    ...state,
    loadingType: false,
    errorType: null,
    opportunityType: action.data,
  }),
  [GET_OPPORTUNITY_EXPERTISE_START]: (state) => ({
    ...state,
    loadingExpertise: true,
    errorExpertise: null,
    opportunityExpertise: {},
  }),
  [GET_OPPORTUNITY_EXPERTISE_ERROR]: (state, action) => ({
    ...state,
    loadingExpertise: false,
    errorExpertise: action.error,
    opportunityExpertise: {},
  }),
  [GET_OPPORTUNITY_EXPERTISE_SUCCESS]: (state, action) => ({
    ...state,
    loadingExpertise: false,
    errorExpertise: null,
    opportunityExpertise: action.data,
  }),
  [GET_OPPORTUNITY_DIMENSION_START]: (state) => ({
    ...state,
    loadingDimension: true,
    errorDimension: null,
    opportunityDimension: {},
  }),
  [GET_OPPORTUNITY_DIMENSION_ERROR]: (state, action) => ({
    ...state,
    loadingDimension: false,
    errorDimension: action.error,
    opportunityDimension: {},
  }),
  [GET_OPPORTUNITY_DIMENSION_SUCCESS]: (state, action) => ({
    ...state,
    loadingDimension: false,
    errorDimension: null,
    opportunityDimension: action.data,
  }),
  [GET_OPPORTUNITY_ADVISOR_TYPE_START]: (state) => ({
    ...state,
    loadingAdvisorType: true,
    errorAdvisorType: null,
    opportunityAdvisorType: {},
  }),
  [GET_OPPORTUNITY_ADVISOR_TYPE_ERROR]: (state, action) => ({
    ...state,
    loadingAdvisorType: false,
    errorAdvisorType: action.error,
    opportunityAdvisorType: {},
  }),
  [GET_OPPORTUNITY_ADVISOR_TYPE_SUCCESS]: (state, action) => ({
    ...state,
    loadingAdvisorType: false,
    errorAdvisorType: null,
    opportunityAdvisorType: action.data,
  }),

  [GET_OPPORTUNITY_COVER_IMAGE_START]: (state) => ({
    ...state,
    loadingOpportunityCoverImage: true,
    errorOpportunityCoverImage: null,
    opportunityCoverImage: '',
  }),
  [GET_OPPORTUNITY_COVER_IMAGE_ERROR]: (state, action) => ({
    ...state,
    loadingOpportunityCoverImage: false,
    errorOpportunityCoverImage: action.error,
    opportunityCoverImage: '',
  }),
  [GET_OPPORTUNITY_COVER_IMAGE_SUCCESS]: (state, action) => ({
    ...state,
    loadingOpportunityCoverImage: false,
    errorOpportunityCoverImage: null,
    opportunityCoverImage: action.data,
  }),

  [GET_OPPORTUNITY_COMPANY_START]: (state) => ({
    ...state,
    loadingOpportunityCompany: true,
    errorOpportunityCompany: null,
    opportunityCompany: null,
  }),
  [GET_OPPORTUNITY_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loadingOpportunityCompany: false,
    errorOpportunityCompany: action.error,
    opportunityCompany: null,
  }),
  [GET_OPPORTUNITY_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loadingOpportunityCompany: false,
    errorOpportunityCompany: null,
    opportunityCompany: action.data,
  }),

  [GET_OPPORTUNITY_DATA_START]: (state) => ({
    ...state,
    loadingOpportunityData: true,
    errorOpportunityData: null,
    opportunityData: [],
  }),
  [GET_OPPORTUNITY_DATA_ERROR]: (state, action) => ({
    ...state,
    loadingOpportunityData: false,
    errorOpportunityData: action.error,
    opportunityData: [],
  }),
  [GET_OPPORTUNITY_DATA_SUCCESS]: (state, action) => ({
    ...state,
    loadingOpportunityData: false,
    errorOpportunityData: null,
    opportunityData: action.data,
  }),

  [SAVE_OPPORTUNITY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    data: [],
  }),
  [SAVE_OPPORTUNITY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    data: [],
  }),
  [SAVE_OPPORTUNITY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.data,
  }),

  [EDIT_OPPORTUNITY_START]: (state) => ({
    ...state,
    loadingEditOpportunityData: true,
    errorEditOpportunityData: null,
    dataEditOpportunityData: [],
  }),
  [EDIT_OPPORTUNITY_ERROR]: (state, action) => ({
    ...state,
    loadingEditOpportunityData: false,
    errorEditOpportunityData: action.error,
    dataEditOpportunityData: [],
  }),
  [EDIT_OPPORTUNITY_SUCCESS]: (state, action) => ({
    ...state,
    loadingEditOpportunityData: false,
    errorEditOpportunityData: null,
    dataEditOpportunityData: action.data,
  }),

  [GET_OPPORTUNITY_PUBLISHERS_CLEAR]: () => initialState,
  [GET_OPPORTUNITY_PUBLISHERS_START]: (state, action) => ({
    ...state,
    loadingPublishers: true,
    errorPublishers: null,
    publisherFilters: action.filters,
  }),
  [GET_OPPORTUNITY_PUBLISHERS_ERROR]: (state, action) => ({
    ...state,
    loadingPublishers: false,
    errorPublishers: action.error,
    opportunityPublishers: {
      CurrentPage: 0,
      TotalPage: 0,
      PageSize: 0,
      Items: [],
    },
  }),
  [GET_OPPORTUNITY_PUBLISHERS_SUCCESS]: (state, action) => {
    const concatArray = action.data.CurrentPage !== 0
      ? state.opportunityPublishers.Items.concat(action.data.Items)
      : action.data.Items;
    return {
      ...state,
      loadingPublishers: false,
      errorPublishers: null,
      opportunityPublishers: {
        ...action.data,
        Items: concatArray,
      },
    };
  },

  [SET_FILTERS_SUCCESS]: (state, action) => ({
    ...state,
    editFilters: action.filters,
  }),
  [CLEAR_FILTERS]: (state) => ({
    ...state,
    filters: initialState.editFilters,
  }),

  [SET_OPPORTUNITY_PUBLISHER]: (state, action) => ({
    ...state,
    opportunityPublisher: {
      Company: action.publisher?.isCompany === true
        ? { value: action.publisher.value, label: action.publisher.label }
        : null,
      Partner: action.publisher?.isCompany === false
        ? { value: action.publisher.value, label: action.publisher.label }
        : null,
    },
  }),

  [RESET_OPPORTUNITY_DATA]: () => (initialState),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
