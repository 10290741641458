import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ModalFooter } from 'reactstrap';
import Button from '@src/js/components/global/buttons/Buttons';
import { selectContactUser } from '@app/store/funding/selectors';
import { getContactUser } from '@app/store/funding/actions/contactUser';
import { ContactUsers } from '@src/js/constants/entities';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { findCreateNewThread } from '@app/store/chat/chatApi';

const BrokerModal = ({ broker }) => {
  const dispatch = useDispatch();
  const brokerContact = selectContactUser(ContactUsers.Funding);
  const { hideModal } = useGlobalModalContext();
  const intl = useIntl();

  const confirmContactRequest = () => {
    const recipient = [brokerContact?.id];
    const bodyMsg = intl.formatMessage({ id: 'broker.contact_message' });
    const subject = intl.formatMessage({ id: 'broker.contact_subject' });
    const message = {
      body: `${ bodyMsg } ${ broker?.name }`,
      subject,
      recipient_uids: recipient,
    };

    dispatch(findCreateNewThread({ participants: recipient, message }));
    hideModal();
  };

  useEffect(() => {
    if (!brokerContact) {
      dispatch(getContactUser(ContactUsers.Funding));
    }
  }, [brokerContact]);

  return (
    <ModalFooter>
      <Button
        size='md'
        buttonText='dialog.confirm'
        onClickFunc={ confirmContactRequest }
      />
      <Button
        size='md'
        buttonText='cancel'
        emptyButtonType='empty-grey'
        onClickFunc={ hideModal }
      />
    </ModalFooter>
  );
};

BrokerModal.propTypes = {
  broker: PropTypes.object,
};

export default BrokerModal;
