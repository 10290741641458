import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { Button } from '@src/js/components/global';

const ConfirmModal = ({
  description,
  handleAccept,
  title,
  show,
  toggleShow,
}) => (
  <Modal className='membership-modal' isOpen={ show }>
    <div className='modal-header'>
      <h2 className='modal-title'><FormattedMessage { ...title } /></h2>
      <span className='close' onClick={ () => toggleShow() } role='presentation' />
    </div>
    { description && (
      <ModalBody>
        <div className='modal-body-description'>
          <FormattedMessage { ...description } />
        </div>
      </ModalBody>
    ) }
    <ModalFooter>
      <Button
        size='lg'
        buttonText='yes'
        onClickFunc={ () => {
          handleAccept();
          toggleShow();
        } }
      />
      <Button
        size='lg'
        buttonText='cancel'
        onClickFunc={ () => toggleShow() }
      />
    </ModalFooter>
  </Modal>
);

ConfirmModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  handleAccept: PropTypes.func,
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
};

/**
 * @deprecated use ConfirmationModal
 */
export default ConfirmModal;
