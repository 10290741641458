import {
  GET_TRANSLATIONS_DATA_REQUEST,
  GET_TRANSLATIONS_DATA_SUCCESS,
  GET_TRANSLATIONS_DATA_FAIL,
  SEND_TRANSLATIONS_DATA_REQUEST,
} from '@app/store/translations/constants';

function getTranslationsRequest(filters) {
  return {
    type: GET_TRANSLATIONS_DATA_REQUEST,
    filters,
  };
}

function getTranslationsSuccess(data) {
  return {
    type: GET_TRANSLATIONS_DATA_SUCCESS,
    payload: data,
  };
}

function getTranslationsFail(error) {
  return {
    type: GET_TRANSLATIONS_DATA_FAIL,
    payload: error,
  };
}

function sendTranslation(text, toastr) {
  return {
    type: SEND_TRANSLATIONS_DATA_REQUEST,
    text,
    message: toastr,
  };
}

export {
  getTranslationsRequest,
  getTranslationsSuccess,
  getTranslationsFail,
  sendTranslation,
};
