export const GET_COMPANY_PERMISSIONS_START = 'GET_COMPANY_PERMISSIONS_START';
export const GET_COMPANY_PERMISSIONS_ERROR = 'GET_COMPANY_PERMISSIONS_ERROR';
export const GET_COMPANY_PERMISSIONS_SUCCESS = 'GET_COMPANY_PERMISSIONS_SUCCESS';

export function getCompanyPermissions(slug, source) {
  return {
    type: GET_COMPANY_PERMISSIONS_START,
    slug,
    source,
  };
}
