import produce from 'immer';

import {
  GET_TOPIC_LIST_START,
  GET_TOPIC_LIST_FAIL,
  GET_TOPIC_LIST_SUCCESS,
  GET_TOPIC_LIST_NAVIGATION_START,
  GET_TOPIC_LIST_NAVIGATION_FAIL,
  GET_TOPIC_LIST_NAVIGATION_SUCCESS,
  GET_TOPIC_START,
  GET_TOPIC_FAIL,
  GET_TOPIC_SUCCESS,
  UPDATE_TOPIC_START,
  UPDATE_TOPIC_FAIL,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_STATUS_START,
  UPDATE_TOPIC_STATUS_FAIL,
  UPDATE_TOPIC_STATUS_SUCCESS,
} from '@app/store/content/topic/actions';
import { Topic } from '@app/models';
import { TopicListItem, TopicNavigation } from '@app/models/Topic';
import { Action } from 'redux';
import { PaginatedResponse, isPaginatedResponse } from '@app/models/Pagination';
import { ResponseError } from '@app/models/Error';

export type TopicState = {
  topic: Topic|null,
  loading: boolean,
  topicList: TopicListItem[],
  error: unknown,
  topicListNavigation: TopicNavigation[],
  totalCount: number,
}

interface TopicAction extends Action<string> {
  error: ResponseError,
  result: PaginatedResponse<Topic|TopicNavigation>|Topic,
  id: string,
  status: string,
}

const initialState: TopicState = {
  loading: false,
  error: null,
  topic: null,
  topicList: [],
  topicListNavigation: [],
  totalCount: 0,
};

function topicReducer(state = initialState, action: TopicAction) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_TOPIC_LIST_START:
        draft.loading = true;
        draft.error = null;
        draft.totalCount = 0;
        break;
      case GET_TOPIC_LIST_NAVIGATION_START:
        draft.loading = true;
        draft.error = null;
        draft.topicListNavigation = [];
        break;
      case GET_TOPIC_LIST_FAIL:
      case GET_TOPIC_LIST_NAVIGATION_FAIL:
      case GET_TOPIC_FAIL:
      case UPDATE_TOPIC_FAIL:
      case UPDATE_TOPIC_STATUS_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        break;
      case GET_TOPIC_LIST_SUCCESS:
        draft.loading = false;
        if (!isPaginatedResponse<TopicListItem>(action.result)) break;
        draft.topicList = action.result.results;
        draft.totalCount = action.result.pagination.totalCount;
        draft.error = null;
        break;
      case GET_TOPIC_LIST_NAVIGATION_SUCCESS:
        draft.loading = false;
        if (!isPaginatedResponse<TopicNavigation>(action.result)) break;
        draft.topicListNavigation = action.result.results;
        draft.error = null;
        break;
      case GET_TOPIC_START:
        draft.loading = true;
        draft.error = null;
        draft.topic = null;
        break;
      case GET_TOPIC_SUCCESS:
        draft.loading = false;
        draft.topic = action.result as Topic;
        draft.error = null;
        break;
      case UPDATE_TOPIC_START:
      case UPDATE_TOPIC_STATUS_START:
      case UPDATE_TOPIC_SUCCESS:
        draft.loading = false;
        draft.error = null;
        break;
      case UPDATE_TOPIC_STATUS_SUCCESS:
        draft.topicList = state.topicList.map(topic => {
          if (action.id === topic.slug) {
            return {
              ...topic,
              status: action.status,
            };
          }
          return topic;
        });
        draft.loading = false;
        draft.error = null;
        break;
      default:
        break;
    }
  }
  );
}

export default topicReducer;
