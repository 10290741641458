import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import CircleAvatar from '@src/js/components/global/cards/components/Avatar/CircleAvatar';
import { routeCodes, withLang } from '@src/js/constants/routes';
import {
  ProgrammePartnerHolder, ProgrammePartnerContent, ProgrammePartnerNameHolder, ProgrammePartnerPositionHolder, Arrow,
} from './styled';

const ProgrammePartner = (props) => {
  const {
    partner,
  } = props;

  const route = `${ withLang(routeCodes.PARTNERS) }/${ partner.slug }`;

  return (
    <ProgrammePartnerHolder>
      { partner.logo && (
        <NavLink exact to={ route }>
          <CircleAvatar src={ partner.logo } unsetMinHeight={ true } />
        </NavLink>
      ) }
      <ProgrammePartnerContent>
        <NavLink exact to={ route }>
          <ProgrammePartnerNameHolder title={ partner.name }>{ partner.name }</ProgrammePartnerNameHolder>
        </NavLink>
        <ProgrammePartnerPositionHolder>
          <FormattedMessage id='companyWidget.programPartner' />
        </ProgrammePartnerPositionHolder>
      </ProgrammePartnerContent>
      <Arrow />
    </ProgrammePartnerHolder>
  );
};

ProgrammePartner.propTypes = {
  partner: PropTypes.object.isRequired,
};

export default ProgrammePartner;
