const scope = 'LIBRARY';

export const GET_DATA_START = `${ scope }/GET_DATA_START`;
export const GET_DATA_SUCCESS = `${ scope }/GET_DATA_SUCCESS`;
export const GET_DATA_FAIL = `${ scope }/GET_DATA_FAIL`;

export const GET_DIMENSIONS_START = `${ scope }/GET_DIMENSIONS_START`;
export const GET_DIMENSIONS_SUCCESS = `${ scope }/GET_DIMENSIONS_SUCCESS`;
export const GET_DIMENSIONS_FAIL = `${ scope }/GET_DIMENSIONS_FAIL`;

export const INCREASE_ROTATION = `${ scope }/INCREASE_ROTATION`;

export function getLibraryDimensionData(params, name) {
  return {
    type: GET_DATA_START,
    params,
    name,
  };
}

export function getLibraryDimensions() {
  return {
    type: GET_DIMENSIONS_START,
  };
}

export function increaseRotation() {
  return {
    type: INCREASE_ROTATION,
  };
}
