import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import * as api from '@src/js/api/session/deleteSessions';

import {
  DELETE_SINGLE_SESSION_START,
  DELETE_SINGLE_SESSION_ERROR,
  DELETE_SINGLE_SESSION_SUCCESS,
  DELETE_ALL_SESSIONS_START,
  DELETE_ALL_SESSIONS_ERROR,
  DELETE_ALL_SESSIONS_SUCCESS,
} from '@src/js/actions/session/deleteSessions';
import { GET_ALL_SESSIONS_START } from '@src/js/actions/session/getAllSessions';
import { showToastrError } from '@app/store/global/actions';

function* deleteSingleSession({ session }) {
  try {
    const data = yield call(() => api.deleteSingleSession(session));
    yield all([
      put({ type: DELETE_SINGLE_SESSION_SUCCESS, data }),
      put({ type: GET_ALL_SESSIONS_START }),
    ]);
  } catch (error) {
    yield all([
      put({ type: DELETE_SINGLE_SESSION_ERROR }),
      showToastrError(),
    ]);
  }
}

function* deleteAllSessions() {
  try {
    const data = yield call(() => api.deleteAllSessions());
    yield all([
      put({ type: DELETE_ALL_SESSIONS_SUCCESS, data }),
      put({ type: GET_ALL_SESSIONS_START }),
    ]);
  } catch (error) {
    yield all([
      put({ type: DELETE_ALL_SESSIONS_ERROR }),
      showToastrError(),
    ]);
  }
}

function* deleteSingleSessionWatcher() {
  yield takeLatest(DELETE_SINGLE_SESSION_START, deleteSingleSession);
}

function* deleteAllSessionsWatcher() {
  yield takeLatest(DELETE_ALL_SESSIONS_START, deleteAllSessions);
}

export default [
  deleteSingleSessionWatcher(),
  deleteAllSessionsWatcher(),
];
