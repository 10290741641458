export const ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START = 'ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START';
export const ADMIN_LIST_EVENT_ATTENDING_COMPANIES_ERROR = 'ADMIN_LIST_EVENT_ATTENDING_COMPANIES_ERROR';
export const ADMIN_LIST_EVENT_ATTENDING_COMPANIES_SUCCESS = 'ADMIN_LIST_EVENT_ATTENDING_COMPANIES_SUCCESS';

export const ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START = 'ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START';
export const ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR = 'ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR';
export const ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS = 'ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS';

export const ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START = 'ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START';
export const ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_ERROR = 'ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_ERROR';
export const ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_SUCCESS = 'ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_SUCCESS';

export const ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START = 'ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START';
export const ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR = 'ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR';
export const ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS = 'ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS';

export const ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START = 'ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START';
export const ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_ERROR = 'ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_ERROR';
export const ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_SUCCESS = 'ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_SUCCESS';

export function getListEventAttendingCompaniesAction(slug: string, filters: object) {
  return {
    type: ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START,
    slug,
    filters,
  };
}

export function saveAttendCompaniesAction(slug: string, filters: object) {
  return {
    type: ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START,
    slug,
    filters,
  };
}

export function uninviteCompaniesAction(slug: string) {
  return {
    type: ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START,
    slug,
  };
}

export function removeAttendCompaniesAction(slug: string, filters: object) {
  return {
    type: ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START,
    slug,
    filters,
  };
}

export function removeSubscribeCompaniesAction(slug: string, filters: object) {
  return {
    type: ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START,
    slug,
    filters,
  };
}
