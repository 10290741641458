import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isEtineraryNotAccepted } from '@src/js/helpers/helpers';
import { getRelationManagers } from '@src/js/actions/user/getRelationManagers';
import ProgrammeHomeProfile from './ProgrammeHomeProfile';
import ProgrammeHomeMenu from './ProgrammeHomeMenu';

class ProgrammeHome extends Component {
  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    loading: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    const {
      dispatch,
    } = this.props;

    if (!isEtineraryNotAccepted()) {
      dispatch(getRelationManagers());
    }
  }

  render() {
    const {
      error,
      loading,
    } = this.props;

    return (
      <div className='programme-home'>
        { !loading && !error && (
        <div>
          <ProgrammeHomeMenu />
          <ProgrammeHomeProfile />
        </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.relationManagers.error,
  loading: state.relationManagers.loading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(ProgrammeHome);

export default withRouter(connectWrapper);
