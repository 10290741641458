import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '@src/js/components/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PeerItem from '@src/js/layouts/global/panels/messaging/components/PeerItem';
import { DebounceInput } from 'react-debounce-input';
import {
  getReferrers,
  getPeersList,
} from '@src/js/actions/user/users';
import { selectPeersList, selectPeersListHasMore } from '@app/store/global/selectors/users';

const PeersList = ({
  company, isCompany, referrerType, members, handleSelectMember,
}) => {
  const dispatch = useDispatch();
  const peers = selectPeersList(referrerType);
  const hasMore = selectPeersListHasMore();
  const [page, setPage] = useState(1);
  const peersLength = peers ? peers.length : 0;
  const filters = { page: 1, searchString: '', reset: true };

  const loadPeers = (f) => {
    const options = {
      offset: f.page, search: f.searchString, referrerType, companyId: company.id, isCompany, reset: f.reset,
    };
    dispatch(getPeersList(options));
  };

  const nextPeers = () => {
    loadPeers({ ...filters, page: page + 1, reset: false });
    setPage(page + 1);
  };

  useEffect(() => {
    setPage(1);
    loadPeers(filters);
    dispatch(getReferrers(company.slug, isCompany, referrerType, true));
  }, [referrerType]);

  const handleSearchChange = (e) => {
    setPage(1);
    loadPeers({ page: 1, searchString: e.target.value || '', reset: true });
  };

  const rows = peers?.map(peer => (
    <PeerItem
      key={ peer.uuid }
      peer={ peer }
      isSelected={ members?.current?.includes(peer.uuid) }
      selectPeer={ handleSelectMember }
      isMemberSelection
    />
  ));

  return (
    <>
      <div className='search-peers'>
        <DebounceInput
          debounceTimeout={ 500 }
          onChange={ handleSearchChange }
          placeholder='Search'
        />
      </div>
      <div className='peers-list-container-members' id='peersContainer'>
        <InfiniteScroll
          dataLength={ peersLength }
          next={ nextPeers }
          hasMore={ hasMore }
          loader={ <Loader position='relative' background='transparent' /> }
          scrollableTarget='peersContainer'
        >
          { rows }
        </InfiniteScroll>
        { peers?.length === 0 && (
        <div className='no-data'>
          <FormattedMessage id='noResults' />
        </div>
        ) }
      </div>
    </>
  );
};

PeersList.propTypes = {
  company: PropTypes.object.isRequired,
  isCompany: PropTypes.bool.isRequired,
  referrerType: PropTypes.string.isRequired,
  members: PropTypes.object.isRequired,
  handleSelectMember: PropTypes.func.isRequired,
};

export default PeersList;
