import { TargetBlank } from '@src/_v2/js/constants';
import { AppLink } from '@src/js/constants/entities';
import { legalLinks } from '@src/js/constants/routes';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class FooterMobile extends Component {
  static propTypes = {
    activeLanguage: PropTypes.string,
  };

  render() {
    const { activeLanguage } = this.props;
    const termsUrl = activeLanguage === 'it' ? legalLinks.TERMS_IT : legalLinks.TERMS;
    const appleUrl = activeLanguage === 'it' ? AppLink.APPLE_IT : AppLink.APPLE_EN;
    const androidUrl = activeLanguage === 'it' ? AppLink.ADNDROID_IT : AppLink.ADNDROID_EN;

    return (
      <footer className='mobile'>
        <div className='primary-footer'>
          <div className='container'>
            <div className='row around-xs primary-footer-links'>
              <a { ...TargetBlank } className='primary-footer-links-item' href={ termsUrl }>
                <FormattedMessage id='navigation.terms' />
              </a>
            </div>
          </div>
        </div>

        <div className='secondary-footer'>
          <div className='container'>
            <div className='row around-xs'>
              <div className='footer-logo' />
            </div>
            <div className='row around-xs'>
              <div className='copyright'>
                { `© ${ (new Date()).getFullYear() } ELITE Group` }
              </div>
            </div>
            <div className='row around-xs'>
              <div className='social-block'>
                <a
                  { ...TargetBlank }
                  className='ios-android-icons social-icons'
                  href={ appleUrl }
                  aria-label='Apple'
                  role='button'
                >
                  <span className='icon-Apple' />
                </a>
                <a
                  { ...TargetBlank }
                  className='ios-android-icons social-icons'
                  href={ androidUrl }
                  aria-label='Android'
                  role='button'
                >
                  <span className='icon-Android' />
                </a>
              </div>
            </div>
            <div className='row around-xs'>
              <div className='social-block'>
                <a
                  { ...TargetBlank }
                  className='social-icons'
                  href='https://www.linkedin.com/company/elite_group/'
                  aria-label='LinkedIn'
                  role='button'
                >
                  <span className='icon-Linkedin' />
                </a>
                <a
                  { ...TargetBlank }
                  className='social-icons'
                  href='https://www.youtube.com/channel/UCeuczF5_iDMgQpVlr25B4IA'
                  aria-label='YouTube'
                  role='button'
                >
                  <span className='icon-Youtube' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  activeLanguage: state.initInfo.activeLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(FooterMobile);

export default withRouter(connectWrapper);
