import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fileList } from '@app/store/documentsArea/actions';
import { selectFileListData } from '@app/store/documentsArea/selectors';
import { acceptExtensions, dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { FormattedMessage } from 'react-intl';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { dateFormat } from '@src/js/utils/date';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { useParams } from 'react-router-dom';
import Actions from './Actions';

const Folder = ({
  folder, slug, handleClick, dataRoomType,
  open, companySlug, noFilesAndButtons, destinationFolder,
}) => {
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const dataroomData = selectFileListData();
  const { showModal } = useGlobalModalContext();
  const activeLanguage = selectActiveLanguage();

  const addFile = (event) => {
    event.stopPropagation();
    showModal({
      type: ModalTypes.UploadMultipleDocument,
      title: '',
      config: { size: 'lg', className: 'multi-upload-modal' },
      data: {
        title: (
          <p className='title'>
            <FormattedMessage id='multiple_upload.uploadingIn' />
            <span className='eop-text-color-blue'>
              {folder?.name}
            </span>
          </p>
        ),
        maxSize: 500000000,
        acceptedExtensions: dropzoneExtensions.DATAROOM_MIMETYPE,
        parentType: 'folder',
        parentId: folder.id,
        entity: 'dataroom',
        dataRoomType,
        companySlug,
        onClose: () => dispatch(fileList(companySlug, dataRoomType, folderId)),
      },
    });
  };

  const cantBeMoved = dataroomData?.folders.filter(f => folder.id !== f.id).length === 0
    && dataroomData?.parents.length === 0;

  return (
    <div className='folder-wrapper'>
      <div className={ `fs-node--grid-folder ${ open ? 'fs-node__opened-folder' : ''
      } ${ destinationFolder === folder.id ? 'fs-node__selected-folder' : '' }` }
      >
        <div className='fs-node__primary-infos--file--clickable' role='presentation' onClick={ handleClick }>
          {!folder.id && folder.training && <span className='icomoon-pushpin' />}
          {!open && <span className='fs-node__icon-folder icon-Folder' />}
          {open && <span className='fs-node__icon-folder icon-FolderOpen' />}
          <input
            id={ `file-upload-${ folder.id }` }
            name={ `file-upload-${ folder.id }` }
            accept={ acceptExtensions.DATAROOM_MIMETYPE }
            className='d-none'
            onClick={ (e) => addFile(e) }
          />
          <h5
            className={
              `fs-node__title--folder m-0 ${ destinationFolder === folder.id ? 'fs-node__title--selected' : '' }`
             }
            title={ folder?.name }
          >
            {folder?.name}
          </h5>
        </div>
        <div className='fs-node__other-infos'>
          <small>{folder.created ? dateFormat(folder.created, 'dd MMM yyyy', activeLanguage, false) : ''}</small>
        </div>
        {!noFilesAndButtons && (
          <Actions
            slug={ slug }
            node={ folder }
            type='folder'
            training={ folder?.training }
            dataRoomType={ dataRoomType }
            cantBeMoved={ cantBeMoved }
          />
        )}
      </div>
    </div>
  );
};

Folder.propTypes = {
  folder: PropTypes.object,
  slug: PropTypes.string,
  handleClick: PropTypes.func,
  open: PropTypes.bool,
  dataRoomType: PropTypes.string,
  companySlug: PropTypes.string,
  noFilesAndButtons: PropTypes.bool,
  destinationFolder: PropTypes.number,
};

export default Folder;
