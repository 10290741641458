import fetchResource from '@src/js/api/fetch-resource';

export function adminGetTemplateList(filters) {
  let url = 'api/admin/onboarding/templates';

  url += filters ? `?${ new URLSearchParams(filters) }` : '';

  return fetchResource(url);
}

export function adminChangeTemplateStatus(hash, status) {
  return fetchResource(`api/admin/onboarding/templates/${ hash }`, {
    method: 'PATCH',
    body: { status },
  });
}

export function adminGetOneTemplate(hash) {
  return fetchResource(`api/admin/onboarding/templates/${ hash }`, {
    method: 'GET',
  });
}
