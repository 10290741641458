import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getLatestOpportunities(options) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/latest-products`;
  if (options?.filters) {
    const query = new URLSearchParams(options.filters);
    url = `${ url }?${ query }`;
  }

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
