import { LanguageObj } from '@app/models/Language';
import { ItemObj } from '@app/components/global/forms/select/select';
import { FileObj } from '@app/models/File';
import { Partner } from '@app/models/Partner';

export type Event = {
  id: number
  name: string,
  slug: string,
  type: number,
  created: string,
  isDraft: number,
  category: string,
  programme: number | null | ItemObj<number>,
  startDate: string,
  attendanceEnabled: boolean,
  isDeleted: number,
  isFeatured: number,
  participants: number,
  attending: number,
  location: number,
  openRegistration: boolean
  image?: string,
  eventTypeId?: number,
  dinner?: boolean,
  endDate?: string,
  daysLasting?: number,
  organiser?: OrganiserObj|string,
  organiserCompany?: string,
  organiserPartner?: string,
  timezone?: string,
  communityDate?: string,
}

export type EventObj = {
  id: number
  name: string,
  slug: string,
  participants: boolean,
  invited: boolean,
}

export type EventDetails = {
  enabled: boolean,
  description: string,
  feedback: string,
  howToJoin: string,
  networkTitle: string,
  recordings: string|null,
  reminderAttachments: string[],
  subjectReminder: string,
  zoomDescription: string,
}

export type EventAgenda = {
  day?: ItemObj<number>,
  description: string,
  hour?: ItemObj<number>,
  id?: number,
  minute?: ItemObj<number>,
  title: string,
}

export type EventContact = {
  name: string,
  email: string,
  jobPosition: string,
  avatar: FileObj|null,
}

export type EventSpeaker = {
  name: string,
  email: string,
  jobPosition: string,
  avatar: FileObj|null,
  details?: LanguageObj<{ bio: string }>
}

export type EventFormObj = {
  agenda: EventAgenda[],
  basic: Event,
  details: LanguageObj<EventDetails>,
  contacts: EventContact[],
  speakers: EventSpeaker[],
  partners: Partner[],
  customPartners: EventPartner[],
  files: FileObj[],
  platformOrganiser: boolean,
}

export type EventFormOptions = {
  event: EventFormObj
  maxTotal: string,
  categories: ItemObj<string>[],
  countries: ItemObj<string>[],
  dinnerParticipants: ItemObj<number>[],
  participants: ItemObj<number>[],
  programmes: ItemObj<string>[],
  timezones: string[],
}

type OrganiserObj = {
  label: string,
  value: string,
  type: string
};

export type EventPartner = {
  id: number | null,
  name: string,
  logo: FileObj | null,
  details: LanguageObj<{ description: string | null }>
}

export type EventListItem = {
  id: number
  title: string,
  slug: string,
  type: number,
  created: string,
  fullAddress: string,
  category: string,
  eventParticipants: {name: string, location: number}[],
  startDate: string,
  meetingParticipants: string,
  speaker: boolean,
  hasSpeakers: boolean,
  showMaterialsButton: boolean,
  attending: boolean,
  closedRegistrations: boolean,
  mandatory: boolean,
  location: number,
  meetingUrl?: string,
  remainingDigitalRegistrations: number
  remainingPhysicalRegistrations: number,
  timeZone?: string,
  agenda: boolean,
  avatars: string[]|null,
  subscribedCount: number|null
}

export function isOrganiserObj(value: undefined|string|OrganiserObj): value is OrganiserObj {
  return typeof value === 'object' && value.type !== undefined;
}
