import {
  DELETE_SINGLE_SESSION_START,
  DELETE_SINGLE_SESSION_ERROR,
  DELETE_SINGLE_SESSION_SUCCESS,
  DELETE_ALL_SESSIONS_START,
  DELETE_ALL_SESSIONS_ERROR,
  DELETE_ALL_SESSIONS_SUCCESS,
} from '@src/js/actions/session/deleteSessions';

const initialState = {
  singleSessionLoading: false,
  singleSessionError: null,
  singleSession: null,
  allSessionsLoading: false,
  allSessionsError: null,
  allSessions: null,
};

const actionsMap = {
  [DELETE_SINGLE_SESSION_START]: (state) => ({
    ...state,
    singleSessionLoading: true,
    singleSessionError: null,
  }),
  [DELETE_SINGLE_SESSION_ERROR]: (state, action) => ({
    ...state,
    singleSessionLoading: false,
    singleSessionError: action.error,
  }),
  [DELETE_SINGLE_SESSION_SUCCESS]: (state, action) => ({
    ...state,
    singleSessionLoading: false,
    singleSession: action.data,
  }),
  [DELETE_ALL_SESSIONS_START]: (state) => ({
    ...state,
    allSessionsLoading: true,
  }),
  [DELETE_ALL_SESSIONS_ERROR]: (state, action) => ({
    ...state,
    allSessionsLoading: false,
    allSessionsError: action.error,
  }),
  [DELETE_ALL_SESSIONS_SUCCESS]: (state, action) => ({
    ...state,
    allSessionsLoading: false,
    allSessions: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
