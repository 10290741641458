import { ContactType } from '@app/store/funding/actions/contactUser';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

export const User = {
  TYPE_ADMIN: 1,
  TYPE_USER: 2,
  TYPE_PROGRAMME_PARTNER_ADMIN: 3,
  TYPE_ONBOARDING: 4,
};

export const RolesUser = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_PROGRAMME_ADMIN: 'ROLE_PROGRAMME_ADMIN',
  ROLE_EDITOR: 'ROLE_EDITOR',
  ROLE_RELATIONSHIP_MANAGER: 'ROLE_RELATIONSHIP_MANAGER',
  ROLE_USER: 'ROLE_USER',
  ROLE_MARKETPLACE_ADMIN: 'ROLE_MARKETPLACE_ADMIN',
  ROLE_COMPLIANCE_CLUB_DEAL: 'ROLE_COMPLIANCE_CLUB_DEAL',
  ROLE_COMPLIANCE_SIM: 'ROLE_COMPLIANCE_SIM',
};

export const RolesUserPriority = [
  RolesUser.ROLE_SUPER_ADMIN,
  RolesUser.ROLE_ADMIN,
  RolesUser.ROLE_PROGRAMME_ADMIN,
  RolesUser.ROLE_EDITOR,
  RolesUser.ROLE_RELATIONSHIP_MANAGER,
  RolesUser.ROLE_USER,
  RolesUser.ROLE_MARKETPLACE_ADMIN,
  RolesUser.ROLE_COMPLIANCE_CLUB_DEAL,
  RolesUser.ROLE_COMPLIANCE_SIM,
];

export const hasRoleInheritance = (roleUser: string, role: string) => (
  RolesUserPriority.indexOf(roleUser) <= RolesUserPriority.indexOf(role)
);

export const CompanyCreationPermission = {
  PERMISSION_DIGITAL_COMPASS: 1,
  PERMISSION_ASSISTED_GROWTH_COMPASS: 2,
  PERMISSION_NONE: 3,
};

export const Company = {
  TYPE_GROWTH: 1,
  TYPE_ETINERARY: 2,
  TYPE_INSTITUTIONAL_INVESTOR: 3,
  TYPE_INVESTOR_RELATOR: 4,
  TYPE_GROWTH_STARTUP: 5,
  VISIBILITY_PUBLIC: 1,
  VISIBILITY_PRIVATE: 2,
  VISIBILITY_HIDDEN: 3,
  ROLE_ADMIN: 1,
  ROLE_MEMBER: 2,
  COMPASS_TYPE_NONE: 3,
  COMPASS_TYPE_DIGITAL: 1,
  COMPASS_TYPE_GROWTH: 2,
  ETINERARY_STATUS_NOREQUEST: 0,
  ETINERARY_STATUS_PENDING: 1,
  ETINERARY_STATUS_ACCEPTED: 2,
  ETINERARY_STATUS_REJECTED: 3,
};

export const Partner = {
  TYPE_PROGRAMME: 1,
  TYPE_FUNDING_PARTNER: 2,
  TYPE_BOTH: 3,
  TYPE_BASIC: 4,
  TYPE_ACCOUNTANT: 5,
  VISIBILITY_PUBLIC: 1,
  VISIBILITY_PRIVATE: 2,
  VISIBILITY_HIDDEN: 3,
};

export const CompanySlide = {
  TYPE_IMAGE: 1,
  TYPE_WISTIA: 2,
  TYPE_THRON: 3,
};

export const Task = {
  APPROVED: 'approved',
};

export const Impersonate = {
  IS_IMPERSONATED: 1,
  CAN_IMPERSONATE: 2,
};

export const fileConstants = {
  UPLOAD_SIZE_LIMIT: 64000000, // 64Mb with size in bytes
};
export const Participants = {
  STATUS_ACCEPTED: 1,
  STATUS_DECLINED: 2,
  STATUS_PENDING: 3,
};

export const Event = {
  TYPE_CLASSIC_EVENT: 1,
  TYPE_TRAINING: 2,
  CATEGORY_WORKSHOP: 'Workshop',
  CATEGORY_TRAINING: 'Training',
  CONTENT_TYPE_HTML: 'html',
  CONTENT_TYPE_THRON: 'thron',
  CONTENT_TYPE_WISTIA: 'wistia',
  CONTENT_TYPE_IFRAME: 'iframe',
  DEFAULT_TIMEZONE: 'GMT',
  LOCATION_DIGITAL: 1,
  LOCATION_PHYSICAL: 2,
  LOCATION_MIXED: 3,
  TIMEZONE_ITALY: 'CET',
  PROGRAMME_IT: 1,
  MEETING_PENDING: 'pending',
  MEETING_ACCEPTED: 'accepted',
  MEETING_REJECTED: 'rejected',
};

export const News = {
  STATUS_DRAFT: 1,
  STATUS_PUBLISHED: 2,
  STATUS_DELETED: 3,
  CONTENT_TYPE_HTML: 'html',
  CONTENT_TYPE_THRON: 'thron',
  CONTENT_TYPE_WISTIA: 'wistia',
  CONTENT_TYPE_IFRAME: 'iframe',
  TEMPLATE_STANDARD: 1,
  TEMPLATE_INSPIRING_STORIES_HEADER_CTA: 2,
  TEMPLATE_MEDIA_CENTER_HEADER_CTA: 3,
  TEMPLATE_MEDIA_CENTER_SLIDER: 4,
  TYPE_INSPIRING_STORIES: 1,
  TYPE_VIDEO: 3,
  TYPE_SPECIFIC_OPPORTUNITY: 4,
  TYPE_SUCCESS_STORY_TRANSACTIONS: 5,
  TYPE_SUCCESS_STORY_BASKET: 6,
  TYPE_SUCCESS_STORY_INTRODUCTION: 7,
  TYPE_FINANZA_AGEVOLATA: 9,
  TYPE_SUCCESS_STORY_IPO: 10,
  TEMPLATE_SPECIFIC_OPPORTUNITY: 5,
  TEMPLATE_SUCCESS_STORY: 6,
  PUBLIC_VISIBILITY: 1,
  PRIVATE_VISIBILITY: 2,
  BOTH_VISIBILITY: 3,
  SPECIFIC_OPPORTUNITY: 'specific_opportunity',
  FINANZA_AGEVOLATA: 'finanza_agevolata',
  INSPIRING_STORIES: 'inspiring_stories',
  MEDIA_CENTER: 'media_center',
  VIDEO: 'video',
  SUCCESS_STORY_TRANSACTIONS: 'success_stories_transactions',
  SUCCESS_STORY_BASKET: 'success_stories_basket',
  SUCCESS_STORY_INTRODUCTION: 'success_stories_introduction',
  SUCCESS_STORY_ALL: 'success_stories',
};

export const Library = {
  STATUS_DRAFT: 1,
  STATUS_PUBLISHED: 2,
  STATUS_DELETED: 3,
  CONTENT_TYPE_HTML: 'html',
  CONTENT_TYPE_THRON: 'thron',
  CONTENT_TYPE_WISTIA: 'wistia',
  CONTENT_TYPE_IFRAME: 'iframe',
};

export const Video = {
  TYPE_WISTIA: 'wistia',
  TYPE_THRON: 'thron',
  TYPE_VIDEO: 'video',
};

export const Form = {
  TYPE_DYNAMICS: 'dynamics',
};

export const NewsStatusTitle = {
  1: 'draft',
  2: 'publish',
  3: 'deleted',
};

export const OnboardingTemplate = {
  LANG_EN: 1,
  LANG_IT: 2,
  STATUS_ENABLED: 1,
  STATUS_DISABLED: 2,
  statusLabels: {
    1: 'onboarding.templates.enabled',
    2: 'onboarding.templates.disabled',
  },
  KIND_COMPANY: 'company',
  KIND_MEMBERSHIP: 'membership',
  KIND_PARTNER: 'partner',
  KIND_PARTNER_PROGRAMME: 'partner_programme',
  KIND_ELITE_PROFESSIONAL: 'elite_professional',
  TYPE_UPLOAD_NOT_REQUIRED: 0,
  TYPE_UPLOAD_REQUIRED: 1,
  TYPE_SIGNATURE_REQUIRED: 2,
};

export const PARTNER_TYPES = [OnboardingTemplate.KIND_PARTNER_PROGRAMME, OnboardingTemplate.KIND_ELITE_PROFESSIONAL];

export const OnboardingCompanyUserContact = {
  TYPE_CONTACT_INVOICING: 1,
  TYPE_DIRECTOR_TRAINING: 2,
  TYPE_DIRECTOR_ASSISTANT: 3,
};

export const OnboardingSteps = {
  BASIC_INFORMATION: 1,
  COMPANY_SUMMARY: 2,
  COMPANY_PROFILE: 3,
  DIRECTORS: 4,
  FUNDAMENTALS: 5,
  DECLARATIONS: 6,
  FINAL: 7,
};

export const OnboardingBoolean = {
  NOT_SET: 0,
  TRUE: 1,
  FALSE: 2,
};

export const Message = {
  USER_LEFT_CHAT: 'USER_LEFT_CHAT',
};

export const Compliance = {
  DRAFT: 'draft',
  NEW: 'new',
  ONGOING: 'ongoing',
  AML_VALIDATION: 'amlValidation',
  COMPLIANCE_VALIDATION: 'complianceValidation',
  PA_VALIDATION: 'paValidation',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const AppLink = {
  ADNDROID_IT: 'https://play.google.com/store/apps/details?id=com.elitenetwork.mobileapp',
  ADNDROID_EN: 'https://play.google.com/store/apps/details?id=com.elitenetwork.mobileapp',
  APPLE_IT: 'https://apps.apple.com/it/app/elite-network/id1535025542',
  APPLE_EN: 'https://apps.apple.com/us/app/elite-network/id1535025542',
};

export const Language = {
  EN: 'en',
  IT: 'it',
  FR: 'fr',
};

export const DataRoomTypes = {
  company: 'company',
  funding: 'funding',
  training: 'training',
};

export const DataRoomPermissions = {
  denied: 0,
  view: 10,
  watermark: 15,
  download: 20,
  edit: 30,
  addUser: 40,
};

export const BannerType = {
  sidebar: 'sidebar',
  dashboard: 'dashboard',
  leaderboard: 'leaderboard',
};

export const YES_OR_NO = [
  { value: true, label: <FormattedMessage id='yes' /> },
  { value: false, label: <FormattedMessage id='no' /> },
];

export const ACTIVE_DISABLED = [
  { value: true, label: <FormattedMessage id='active' /> },
  { value: false, label: <FormattedMessage id='disabled' /> },
];

export const FundingOption = {
  debt: 'debt',
  equityPrivate: 'equityPrivate',
  equityPublic: 'equityPublic',
  basketBond: 'basketBond',
  all: 'all',
};

export const MultipleUploadStatus = {
  init: 'init',
  uploading: 'uploading',
  uploaded: 'uploaded',
  cancelled: 'cancelled',
  failed: 'failed',
};

export const Opportunity = {
  SEARCH: 0,
  OFFER: 1,
  INNOVATION_CHALLENGE: 2,
  INNOVATION_SOLUTION: 3,
  ELITE_RESTRICTED_VISIBILITY: 4,
};

export const MembershipStatus = {
  MEMBERSHIP: 'membership',
  MEMBERSHIP_INITIAL: 'initial_period',
  MEMBERSHIP_NOT_PAYING: 'not_paying',
  MEMBERSHIP_WITHDRAW: 'withdraw',
};

export const PartnerProgramme = {
  INTERNATIONAL: 'International',
  ITALY: 'Italy',
};

export const DirectorySections = {
  BUSINESS_NEED: 'businessneed',
  LATEST: 'latest',
  VISITED: 'visited',
  WORKSHOP: 'workshop',
  LOUNGE: 'lounge',
  MOST_VIEWED: 'mostview',
};

export const DirectoryRelevantSections = [
  DirectorySections.VISITED,
  DirectorySections.BUSINESS_NEED,
  DirectorySections.LATEST,
  DirectorySections.MOST_VIEWED,
];

export const RequestCallTypes = {
  RequestCall: 'request_call',
  MembershipRenew: 'membership_renew',
};

type EContactType = {
  [key: string]: ContactType
};

export const ContactUsers: EContactType = {
  Funding: 'funding',
  Inspiring: 'inspiring',
};

export const CertificateTypes = [
  'finance', 'sustainability', 'governance', 'attendance',
];

export const yesOrNo = (intl: IntlShape) => [
  { value: true, label: intl.formatMessage({ id: 'yes' }) },
  { value: false, label: intl.formatMessage({ id: 'no' }) },
];

export const MessageTypes = {
  MEETING: 'meeting',
  SYSTEM: 'system',
};

export const TimeZones = [
  { label: 'CET', value: 'CET' },
  { label: 'GMT', value: 'GMT' },
  { label: 'EST', value: 'EST' },
  { label: 'BST', value: 'BST' },
];
