import produce from 'immer';

import {
  GET_LIST_START,
  GET_LIST_FAIL,
  GET_LIST_SUCCESS,
  ADD_ATTENDANCE_START,
  ADD_ATTENDANCE_FAIL,
  ADD_ATTENDANCE_SUCCESS,
  REMOVE_ATTENDANCE_START,
  REMOVE_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_LOCATION_START,
  UPDATE_ATTENDANCE_LOCATION_SUCCESS,
  UPDATE_ATTENDANCE_LOCATION_FAIL,
} from '@app/store/eventAttendance/actions';

export const initialState = {
  pagination: null,
  data: null,
  loading: false,
  error: null,
};

function eventAttendanceReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_LIST_START:
        draft.data = null;
        draft.loading = true;
        draft.error = null;
        break;
      case GET_LIST_SUCCESS:
        draft.loading = false;
        draft.data = action.result.results;
        draft.pagination = action.result.pagination;
        draft.error = null;
        break;
      case GET_LIST_FAIL:
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      case ADD_ATTENDANCE_START:
        if (action.data?.userEmail) {
          draft.data = state.data.map(user => {
            if (user.userEmail === action.data?.userEmail) {
              return { ...user, ...{ loading: true } };
            }
            return user;
          });
        }
        break;
      case ADD_ATTENDANCE_FAIL:
        if (action.data?.userEmail) {
          draft.data = state.data.map(user => {
            if (user.userEmail === action.data?.userEmail) {
              return { ...user, ...{ loading: false } };
            }
            return user;
          });
        }
        break;
      case ADD_ATTENDANCE_SUCCESS:
        if (action.data?.userEmail) {
          draft.data = state.data.map(user => {
            if (user.userEmail === action.data?.userEmail) {
              return { ...user, ...{ attendance: true, loading: false } };
            }
            return user;
          });
        }
        break;

      case REMOVE_ATTENDANCE_START:
        if (action.id) {
          draft.data = state.data.map(user => {
            if (user.id === action.id) {
              return { ...user, ...{ loading: true } };
            }
            return user;
          });
        }
        break;
      case REMOVE_ATTENDANCE_FAIL:
        if (action.id) {
          draft.data = state.data.map(user => {
            if (user.id === action.id) {
              return { ...user, ...{ loading: false } };
            }
            return user;
          });
        }
        break;
      case UPDATE_ATTENDANCE_LOCATION_START:
        if (action.id) {
          draft.data = state.data.map(user => {
            if (user.id === action.id) {
              return { ...user, ...{ loading: true } };
            }
            return user;
          });
        }
        break;
      case UPDATE_ATTENDANCE_LOCATION_SUCCESS:
        if (action.id) {
          draft.data = state.data.map(user => {
            if (user.id === action.id) {
              return { ...user, ...{ location: action.data.location, loading: false } };
            }
            return user;
          });
        }
        break;
      case UPDATE_ATTENDANCE_LOCATION_FAIL:
        if (action.id) {
          draft.data = state.data.map(user => {
            if (user.id === action.id) {
              return { ...user, ...{ loading: false } };
            }
            return user;
          });
        }
        break;
      default:
        break;
    }
  }
  );
}

export default eventAttendanceReducer;
