import { takeLatest, call, put } from 'redux-saga/effects';

import {
  UPLOAD_ONBOARDING_IMAGE_START,
  UPLOAD_ONBOARDING_IMAGE_ERROR,
  UPLOAD_ONBOARDING_IMAGE_SUCCESS,
  ADMIN_UPLOAD_ONBOARDING_IMAGE_START,
  ADMIN_UPLOAD_ONBOARDING_IMAGE_ERROR,
  ADMIN_UPLOAD_ONBOARDING_IMAGE_SUCCESS,
} from '@src/js/actions/onboarding/image/uploadOnboardingImage';

import * as api from '@src/js/api/onboarding/image/uploadOnboardingImage';

function* uploadOnboardingImage(options) {
  try {
    const data = yield call(() => api.uploadOnboardingImage(
      options.image.image, options.entity, options.imageType, options.value
    ));
    yield put({ type: UPLOAD_ONBOARDING_IMAGE_SUCCESS, data, options });
    options.image.resolve(data);
  } catch (error) {
    yield put({ type: UPLOAD_ONBOARDING_IMAGE_ERROR, error });
    options.image.reject(error.response);
  }
}
function* adminUploadOnboardingImage(options) {
  try {
    const data = yield call(() => api.adminUploadOnboardingImage(
      options.image.image, options.entity, options.imageType, options.value
    ));
    yield put({ type: ADMIN_UPLOAD_ONBOARDING_IMAGE_SUCCESS, data, options });
    options.image.resolve(data);
  } catch (error) {
    yield put({ type: ADMIN_UPLOAD_ONBOARDING_IMAGE_ERROR, error });
    options.image.reject(error.response);
  }
}

function* uploadOnboardingImageWatcher() {
  yield takeLatest(UPLOAD_ONBOARDING_IMAGE_START, uploadOnboardingImage);
}

function* adminUploadOnboardingImageWatcher() {
  yield takeLatest(ADMIN_UPLOAD_ONBOARDING_IMAGE_START, adminUploadOnboardingImage);
}

export default [
  uploadOnboardingImageWatcher(),
  adminUploadOnboardingImageWatcher(),
];
