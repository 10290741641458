import { takeLatest, call, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import {
  GET_NETWORKING_START,
  GET_NETWORKING_ERROR,
  GET_NETWORKING_SUCCESS,
} from '@src/js/actions/networking/companyAndPartner';
import * as api from '@src/js/api/networking/networking';

function* getNetworking(basicData) {
  try {
    const offsetCompany = Cookies.get('networking-offset-company');
    const offsetPartner = Cookies.get('networking-offset-partner');

    const data = yield call(() => api.getNetworking(offsetCompany, offsetPartner, basicData.basicData));

    // aggiorno l'offset nel cookie incrementandolo della dimensione della pagina (data.length)
    Cookies.set('networking-offset-company', data.offsetCompany, { secure: true });
    Cookies.set('networking-offset-partner', data.offsetPartner, { secure: true });

    yield put({ type: GET_NETWORKING_SUCCESS, data: data.results });
  } catch (error) {
    yield put({ type: GET_NETWORKING_ERROR, error });
  }
}

function* getNetworkingWatcher() {
  yield takeLatest(GET_NETWORKING_START, getNetworking);
}

export default [
  getNetworkingWatcher(),
];
