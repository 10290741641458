import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { setConfirmedProfile } from '@app/store/global/actions';
import { Company } from '@src/js/constants/entities';
import { usePrevious } from '@src/js/helpers/hooks';
import { RootState } from '@src/js/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Startup = () => {
  const { showModal } = useGlobalModalContext();
  const dispatch = useDispatch();
  const userData = useSelector<RootState, {confirmData: boolean, companyType: number, etineraryStatus: number}>(
    state => state.initInfo.userInfo);
  const prevConfirmData = usePrevious(userData?.confirmData);
  const crossSellingData = useSelector<RootState, null|{popup: string, name: string}>(
    (state) => state.global.crossSellingData);

  useEffect(() => {
    if (userData?.confirmData && prevConfirmData !== userData?.confirmData) {
      showModal({
        type: ModalTypes.ConfirmUserData,
        title: 'confirmData.modal',
        config: { size: 'md' },
        onTop: true,
      });
      dispatch(setConfirmedProfile());
    } else if (userData?.confirmData === false && prevConfirmData !== userData?.confirmData) {
      if ([
        Company.ETINERARY_STATUS_NOREQUEST,
        Company.ETINERARY_STATUS_PENDING,
      ].includes(userData?.etineraryStatus)
        && userData?.companyType === Company.TYPE_ETINERARY) {
        showModal({
          type: ModalTypes.EtineraryContact,
          title: 'etinerary.contact_modal.title',
          config: {
            size: 'lg',
            className: 'etinerary-contact-modal',
          },
        });
      }
      if (crossSellingData?.popup === 'resetPassword') {
        showModal({
          type: ModalTypes.ResetPassword,
          title: 'dashboard.modal.resetPwd.title',
          config: { size: 'md' },
        });
      } else if (crossSellingData?.popup === 'crossSelling' && crossSellingData.name) {
        showModal({
          type: ModalTypes.CrossSelling,
          title: 'cross_selling.title',
          config: { size: 'md' },
          data: { name: crossSellingData.name },
        });
      }
    }
  }, [dispatch, userData, crossSellingData]);

  return <div />;
};

export default Startup;
