import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { Alert, Col, Row } from 'reactstrap';
import UploadPlaceholder from '@src/js/components/global/imageEditor/UploadPlaceholder';
import FilePreview from '@src/js/components/global/cards/FilePreview';
import { errorResolver } from '@src/js/helpers/errorResolver';
import PageLoader from '@src/js/components/global/pageLoader/PageLoader';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { fileConstants } from '@src/js/constants/entities';
import { RequiredIcon, Warning } from '@src/js/components/global/inputs/style';
import { uploadFile } from '@src/js/api/file/uploadFile';
import { FileCard, UploadFieldLabel } from './style';

class UploadDocumentsField extends Component {
  static propTypes = {
    uploadDocumentsFile: PropTypes.func,
    submitAttempted: PropTypes.bool,
    setDocumentsCallback: PropTypes.func,
    fieldLabel: PropTypes.string,
    termsFiles: PropTypes.array,
    multipleUpload: PropTypes.bool,
    className: PropTypes.string,
    downloadable: PropTypes.bool,
  };

  static defaultProps = {
    multipleUpload: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      uploadFilesLoading: false,
      uploadFilesError: null,
      files: props.termsFiles || [],
    };
  }

  componentDidMount() {
    const { setDocumentsCallback } = this.props;
    const { files } = this.state;

    if (files?.length !== 0) {
      setDocumentsCallback(files);
    }
  }

  addfile = (file) => {
    const { setDocumentsCallback } = this.props;
    const { files } = this.state;
    const filesList = [...files];

    filesList.push(file);
    this.setState({
      uploadFilesLoading: false,
      files: filesList,
    });
    setDocumentsCallback(filesList);
  };

  handleFileDropRejected = (droppedFiles) => {
    let error = 'dataRoom.documentMimeTypeMessage';

    if (droppedFiles.length > 1) {
      error = 'dataRoom.numberOfFiles';
    }

    if (droppedFiles[0].size > fileConstants.UPLOAD_SIZE_LIMIT) {
      error = 'dataRoom.maxSizeMessage';
    }

    this.setState({
      uploadFilesError: error,
      uploadFilesLoading: false,
    });
  };

  handleFileDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return null;
    }

    if (acceptedFiles[0].size > fileConstants.UPLOAD_SIZE_LIMIT) {
      this.setState({
        uploadFilesError: 'dataRoom.maxSizeMessage',
        uploadFilesLoading: false,
      });

      return null;
    }

    this.setState({
      uploadFilesError: null,
      uploadFilesLoading: true,
    });

    const file = new FormData();
    file.append('file', acceptedFiles[0]);
    const addfile = this.addfile;
    return uploadFile(file, 'onboarding', 'document').then((r) => {
      addfile(r);
      return r;
    }).catch(errors => {
      this.setState({
        uploadFilesError: errors.message || errors.onboardingDocument || errors,
        uploadFilesLoading: false,
      });

      return errors;
    });
  };

  handleUploadedFilesRemove = (idx) => {
    const { setDocumentsCallback } = this.props;
    const { files } = this.state;

    const tmpFiles = files.filter(el => el.id !== idx);

    setDocumentsCallback(tmpFiles);

    this.setState({
      uploadFilesLoading: false,
      files: tmpFiles,
    });
  };

  renderFiles = () => {
    const { files } = this.state;
    const { downloadable } = this.props;

    return files.map(file => (
      <FileCard xs={ 12 } sm={ 6 } className='card' key={ file.id }>
        <FilePreview
          file={ file }
          arrow={ false }
          removeFileAction={ this.handleUploadedFilesRemove }
          downloadable={ downloadable }
        />
      </FileCard>
    ));
  };

  renderFileBrowser = () => {
    const {
      uploadFilesLoading,
      uploadFilesError,
    } = this.state;

    const { multipleUpload } = this.props;

    return (
      <Col lg={ 12 } xs={ 12 } className='card'>
        <Row>
          <Col lg={ 12 } className='m-t-1'>
            { uploadFilesError
            && (
              <Alert color='red'>
                <FormattedMessage id={ errorResolver(uploadFilesError) } />
              </Alert>
            )
            }
          </Col>
        </Row>

        <Row>
          <Col lg={ 12 } xs={ 12 }>
            <div className='card m-b-1'>
              <div className='upload-card__container upload-card__container-fixed'>
                { uploadFilesLoading && <PageLoader /> }
                <Dropzone
                  accept={ dropzoneExtensions.PDF }
                  maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
                  onDrop={ this.handleFileDrop }
                  onDropRejected={ this.handleFileDropRejected }
                  multiple={ multipleUpload }
                  useFsAccessApi={ false }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div { ...getRootProps({ style: { height: '100%' } }) }>
                      <input { ...getInputProps() } />
                      <UploadPlaceholder />
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    );
  };

  render() {
    const { submitAttempted, fieldLabel, className } = this.props;
    const { files } = this.state;

    return (
      <Row className={ className }>
        <Col xs={ 12 }>
          <UploadFieldLabel>
            <RequiredIcon />
            <FormattedMessage id={ fieldLabel } />
          </UploadFieldLabel>
          { this.renderFiles() }
          <Row>
            { this.renderFileBrowser() }
          </Row>
          { files.length < 1 && submitAttempted
          && (
            <Warning>
              <FormattedMessage id='global_form.error.required' />
            </Warning>
          ) }
        </Col>
      </Row>
    );
  }
}

export default UploadDocumentsField;
