export const UPLOAD_ONBOARDING_IMAGE_START = 'UPLOAD_ONBOARDING_IMAGE_START';
export const UPLOAD_ONBOARDING_IMAGE_ERROR = 'UPLOAD_ONBOARDING_IMAGE_ERROR';
export const UPLOAD_ONBOARDING_IMAGE_SUCCESS = 'UPLOAD_ONBOARDING_IMAGE_SUCCESS';
export const ADMIN_UPLOAD_ONBOARDING_IMAGE_START = 'ADMIN_UPLOAD_ONBOARDING_IMAGE_START';
export const ADMIN_UPLOAD_ONBOARDING_IMAGE_ERROR = 'ADMIN_UPLOAD_ONBOARDING_IMAGE_ERROR';
export const ADMIN_UPLOAD_ONBOARDING_IMAGE_SUCCESS = 'ADMIN_UPLOAD_ONBOARDING_IMAGE_SUCCESS';
export const CLEAR_ONBOARDING_IMAGE_STATE = 'CLEAR_ONBOARDING_IMAGE_STATE';

export function uploadOnboardingImage(image, entity, imageType, value, languageCode) {
  return {
    type: UPLOAD_ONBOARDING_IMAGE_START,
    image,
    entity,
    imageType,
    value,
    languageCode,
  };
}

export function adminUploadOnboardingImage(image, entity, imageType, value, languageCode) {
  return {
    type: ADMIN_UPLOAD_ONBOARDING_IMAGE_START,
    image,
    entity,
    imageType,
    value,
    languageCode,
  };
}

export function clearOnboardingImage() {
  return {
    type: CLEAR_ONBOARDING_IMAGE_STATE,
  };
}
