import {
  ADMIN_GET_INDEX_PROCESSES_START,
  ADMIN_GET_INDEX_PROCESSES_ERROR,
  ADMIN_GET_INDEX_PROCESSES_SUCCESS,
  ADMIN_GET_PROCESS_START,
  ADMIN_GET_PROCESS_ERROR,
  ADMIN_CREATE_COMPANY_ONBOARDING_START,
  ADMIN_CREATE_COMPANY_ONBOARDING_ERROR,
  ADMIN_CREATE_COMPANY_ONBOARDING_SUCCESS,
  ADMIN_DELETE_COMPANY_ONBOARDING_START,
  ADMIN_DELETE_COMPANY_ONBOARDING_ERROR,
  ADMIN_DELETE_COMPANY_ONBOARDING_SUCCESS,
} from '@src/js/actions/onboarding/processes/adminProcesses';

export const initialState = {
  loading: false,
  error: null,
  indexProcesses: null,
  process: null,
  created: false,
};

const actionsMap = {
  [ADMIN_GET_INDEX_PROCESSES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    created: false,
  }),
  [ADMIN_GET_INDEX_PROCESSES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [ADMIN_GET_INDEX_PROCESSES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    indexProcesses: action.data,
  }),
  [ADMIN_GET_PROCESS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    process: null,
    created: false,
  }),
  [ADMIN_GET_PROCESS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),

  [ADMIN_CREATE_COMPANY_ONBOARDING_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    created: false,
  }),
  [ADMIN_CREATE_COMPANY_ONBOARDING_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_CREATE_COMPANY_ONBOARDING_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: null,
    created: true,
  }),

  [ADMIN_DELETE_COMPANY_ONBOARDING_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    created: false,
  }),
  [ADMIN_DELETE_COMPANY_ONBOARDING_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_DELETE_COMPANY_ONBOARDING_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
