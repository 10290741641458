import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { Warning } from './style';
import SelectBox from './Selectbox';

const MultiDropDown = ({
  valueOptions,
  maxLevels,
  labels,
  defaultValue,
  placeholders,
  requiredLevels,
  classes,
  setValue,
  translate,
  intl,
  disabledLevels,
  name,
  form,
}) => {
  const [allOptions, setAllOptions] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const formState = form.getState();

  const findOption = (id) => valueOptions.find((el) => el.id === id);
  const updateDefaults = () => {
    const values = [findOption(defaultValue)];
    let parentId = findOption(defaultValue)?.parentId;
    for (;parentId;) {
      values.unshift(findOption(parentId));
      parentId = findOption(parentId)?.parentId;
    }
    return values;
  };
  const buildOptions = (index, value) => {
    const options = [...allOptions];
    if (value && index < maxLevels - 1) {
      const values = valueOptions.filter((el) => el.parentId === value?.id);
      if (values.length > 0) {
        options[index + 1] = values;
      }
    } else {
      options.splice(index + 1);
    }
    options.length = index + 2;
    setAllOptions(options);
  };

  const onChange = (index, value) => {
    const values = [...defaultValues];
    buildOptions(index, value);
    values[index] = value;
    values.forEach((v, i) => {
      if (i <= index) {
        if (setValue) setValue(v, `${ name }.${ i }`);
      } else {
        values[i] = null;
        if (setValue) setValue(null, `${ name }.${ i }`);
      }
    });
    setDefaultValues(values);
  };

  useEffect(() => {
    const options = [valueOptions.filter((el) => el.parentId === null)];
    if (defaultValue) {
      const values = updateDefaults();
      values.forEach((value, index) => {
        const opts = valueOptions.filter((el) => el.parentId === value?.id);
        if (opts.length) options[index + 1] = opts;
      });
      setDefaultValues(values);
    }
    setAllOptions(options);
  }, [valueOptions]);

  return Object.keys(allOptions).map((item, index) => {
    const dirty = formState.dirtyFields[`${ name }.${ index }`];

    const options = translate
      ? allOptions[index].map((opt) => ({
        ...opt,
        label: intl.formatMessage({ id: opt.name }),
      }))
      : allOptions[index];

    let defaultVal = null;
    if (defaultValues && defaultValues[index]) {
      defaultVal = translate
        ? {
          ...defaultValues[index],
          label: intl.formatMessage({
            id: defaultValues[index].name,
          }),
        }
        : defaultValues[index];
      defaultVal.value = defaultValues[index].name;
    }

    return (
      <>
        <Field
          key={ item }
          name={ `${ name }.${ index }` }
          fieldName={ `${ name }.${ index }` }
          additionalClass={ classes?.[index] || 'm-t-0' }
          fieldType='text'
          fieldLabel={ labels[index] ?? '' }
          options={ options }
          onChange={ (val) => onChange(index, val) }
          simpleValue={ false }
          selectedValues={ defaultVal || [] }
          component={ SelectBox }
          fieldRequired={ index < requiredLevels }
          fieldDisabled={ index < disabledLevels }
          placeholder={ placeholders?.[index] ?? '' }
          translate={ translate }
        />
        <Warning>
          {
            (dirty || formState.submitFailed) && !defaultVal && index < requiredLevels
            && (<FormattedMessage id='global_form.error.required' />)
          }
        </Warning>
      </>
    );
  });
};

MultiDropDown.propTypes = {
  valueOptions: PropTypes.array.isRequired,
  maxLevels: PropTypes.number,
  name: PropTypes.string.isRequired,
  labels: PropTypes.array,
  defaultValues: PropTypes.array,
  placeholders: PropTypes.array,
  translate: PropTypes.bool,
  classes: PropTypes.array,
  intl: PropTypes.object,
  requiredLevels: PropTypes.number,
  disabledLevels: PropTypes.number,
  prefix: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

MultiDropDown.defaultProps = {
  maxLevels: 4,
  translate: true,
  labels: [],
};

export default injectIntl(MultiDropDown);
