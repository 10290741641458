import fetchResource from '@src/js/api/fetch-resource';

export function getEventAttendanceList(slug, params) {
  const query = new URLSearchParams(params);
  return fetchResource(`api/admin/event/${ slug }/attendances?${ query }`, {
    method: 'GET',
  });
}

export function addAttendance(slug, data) {
  return fetchResource(`api/admin/event/${ slug }/attendances/add`, {
    method: 'POST',
    body: data,
  });
}

export function removeAttendance(slug, id) {
  return fetchResource(`api/admin/event/${ slug }/attendances/${ id }/delete`, {
    method: 'DELETE',
  });
}

export function updateAttendanceLocation(slug, id, data) {
  return fetchResource(`api/admin/event/${ slug }/attendances/${ id }/update`, {
    method: 'PATCH',
    body: data,
  });
}
