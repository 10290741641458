import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ClassNames from 'classnames';
import { get as lodashGet } from 'lodash';
import { RequiredIcon, Warning } from './style';

export default class NumberInput extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string,
    fieldDisabled: PropTypes.bool,
    fieldRequired: PropTypes.bool,
    additionalClass: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func,
    currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  constructor(props) {
    super(props);
    let prevValue = props.min;
    if (props.currentValue) {
      prevValue = props.currentValue;
    }

    this.state = {
      prevValue,
    };
  }

  handleChange = (evt) => {
    const { onChange } = this.props;

    const { prevValue } = this.state;

    let value = prevValue;
    const regex = /^\d+$/;
    if (regex.test(evt.target.value) || !evt.target.value) {
      value = evt.target.value;
      this.setState({
        prevValue: value,
      });
    }
    onChange(value);
  };

  render() {
    const {
      max,
      min,
      input,
      meta,
      fieldLabel,
      fieldDisabled,
      fieldRequired,
      additionalClass,
    } = this.props;

    const inputEl = (
      <input
        { ...input }
        className='eop-text-input__input-item'
        type='number'
        maxLength={ max }
        min={ min }
        disabled={ fieldDisabled && 'disabled' }
        onChange={ (e) => this.handleChange(e) }
      />
    );

    let minValue = '';
    let maxValue = '';

    let validationErrorId = '';

    if (meta) {
      const validationError = (meta.error || meta.submitError);
      if (validationError) {
        validationErrorId = (validationError.id || validationError);
        maxValue = lodashGet(validationError, 'validationError.values.maxValue');
        minValue = lodashGet(validationError, 'validationError.values.minValue');
      }
    }

    return (
      <div className={ ClassNames('eop-text-input', additionalClass) }>
        <label className='eop-text-input__label'>
          { fieldRequired && <RequiredIcon /> }
          { fieldLabel && <FormattedMessage id={ fieldLabel } /> }
          <div className='eop-text-input__input'>
            { inputEl }

            { fieldDisabled
            && <span className='eop-text-input__input-icon icon-LockerSmall' />
            }
          </div>
        </label>
        { meta
        && (
          <Warning>
            {
              (
                meta.error
                || (meta.submitError && meta.dirtySinceLastSubmit === false)
              )
              && (
                meta.touched
                || (
                  (meta.dirty || meta.dirtySinceLastSubmit)
                  && meta.submitFailed
                )
              )
              && validationErrorId
              && (
              <FormattedMessage
                id={ validationErrorId }
                values={ {
                  minValue,
                  maxValue,
                } }
              />
              )
            }
          </Warning>
        ) }
      </div>
    );
  }
}
