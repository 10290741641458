import EtineraryAR from '@assets/img/compass-etinerary/compass_ar.jpg';
import EtineraryEN from '@assets/img/compass-etinerary/compass_en.jpg';
import EtineraryES from '@assets/img/compass-etinerary/compass_es.jpg';
import EtineraryFR from '@assets/img/compass-etinerary/compass_fr.jpg';
import EtineraryIT from '@assets/img/compass-etinerary/compass_it.jpg';
import EtineraryPT from '@assets/img/compass-etinerary/compass_pt.jpg';
import GrowthAR from '@assets/img/compass-growth/growth_ar.jpg';
import GrowthEN from '@assets/img/compass-growth/growth_en.jpg';
import GrowthES from '@assets/img/compass-growth/growth_es.jpg';
import GrowthFR from '@assets/img/compass-growth/growth_fr.jpg';
import GrowthIT from '@assets/img/compass-growth/growth_it.jpg';
import GrowthPT from '@assets/img/compass-growth/growth_pt.jpg';

export const CompassImages = {
  etinerary: {
    ar: EtineraryAR,
    en: EtineraryEN,
    es: EtineraryES,
    fr: EtineraryFR,
    it: EtineraryIT,
    pt: EtineraryPT,
  },
  growth: {
    ar: GrowthAR,
    en: GrowthEN,
    es: GrowthES,
    fr: GrowthFR,
    it: GrowthIT,
    pt: GrowthPT,
  },
};
