import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function marketplaceProductSendMessage(data) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/product/${ data.productId }/send-request`, {
    method: 'POST',
    body: data,
    externalUrl: true,
  });
}
