import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import {
  Bold, Italic, Strikethrough, Underline,
} from '@ckeditor/ckeditor5-basic-styles';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { DocumentListProperties } from '@ckeditor/ckeditor5-list';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { Link, LinkImage } from '@ckeditor/ckeditor5-link';
import {
  Image, ImageResize, ImageStyle, ImageToolbar, ImageUpload,
} from '@ckeditor/ckeditor5-image';
import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { useIntl } from 'react-intl';
import { ControllerRenderProps } from 'react-hook-form';
import loadEnvVariable from '@src/js/static/LoadEnv';
import './ck-editor.scss';
import { FieldInputProps } from 'react-final-form';
import {
  HeadingConfig, HeadingElementOption, HeadingParagraphOption,
} from '@ckeditor/ckeditor5-heading/src/headingconfig';
import PastePlainText from './plugins/PastePlainText';
import VideoControl from './plugins/VideoControl';
import DynamicsControl from './plugins/DynamicsControl';

type Props = {
  translate: boolean,
  placeholder?: string,
  imageControl?: ImageControl,
  videoControl?: boolean,
  htmlControl?: boolean,
  formControl?: boolean,
  disabled?: boolean,
  field: ControllerRenderProps|FieldInputProps<any, HTMLElement>,
}

type ImageControl ={
  imageType: string,
  entity: string
}

interface EliteHeadingElementOption extends Omit<HeadingElementOption, 'model'> {
  model: 'blockquote' | 'code';
}

export type EliteHeadingOption = HeadingElementOption | HeadingParagraphOption | EliteHeadingElementOption;

interface EliteHeadingConfig extends Omit<HeadingConfig, 'options'> {
  options?: Array<EliteHeadingOption>
}

interface EliteEditorConfig extends Omit<EditorConfig, 'heading'> {
  heading: EliteHeadingConfig
}

const EliteCKEditor = ({
  field, imageControl, videoControl, htmlControl, translate, placeholder, formControl, disabled,
}: Props) => {
  const intl = useIntl();
  const fieldPlacheholder = translate && placeholder ? intl.formatMessage({ id: placeholder }) : placeholder;
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);

    return () => setInit(false);
  }, []);

  const config: EliteEditorConfig = {
    plugins: [
      Heading, Paragraph, Bold, Italic, Underline,
      Strikethrough, DocumentListProperties, Indent,
      PastePlainText, Link, Essentials,
    ],
    toolbar: {
      items: [
        'heading',
        '|', 'bold', 'italic', 'underline', 'strikethrough',
        '|', 'bulletedList', 'numberedList',
        '|', 'outdent', 'indent',
        '|', 'link', 'uploadImage',
        '|', 'videoControl', 'dynamicsControl',
        '|', 'sourceEditing',
      ],
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: fieldPlacheholder,
    simpleUpload: {
      uploadUrl: `${ loadEnvVariable('API_PATH') }/api/image/${ imageControl?.entity }/${ imageControl?.imageType }`,
      withCredentials: true,
    },
    image: {
      toolbar: [
        'imageStyle:alignBlockLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignBlockRight',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageResize',
        'linkImage',
      ],
    },
    htmlSupport: {
      allow: [{
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      }],
    },
    link: {
      decorators: {
        target: {
          mode: 'manual',
          label: 'Open in new window',
          defaultValue: true,
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
      defaultProtocol: 'https://',
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        {
          model: 'heading1', view: 'h1', title: 'Heading 1', class: 'heading-h1',
        },
        {
          model: 'heading2', view: 'h2', title: 'Heading 2', class: 'heading-h2',
        },
        {
          model: 'heading3', view: 'h3', title: 'Heading 3', class: 'heading-h3',
        },
        {
          model: 'heading4', view: 'h4', title: 'Heading 4', class: 'heading-h4',
        },
        {
          model: 'heading5', view: 'h5', title: 'Heading 5', class: 'heading-h5',
        },
        {
          model: 'heading6', view: 'h6', title: 'Heading 6', class: 'heading-h6',
        },
        {
          model: 'blockquote', view: 'blockquote', title: 'Blockquote', class: 'message-box-left',
        },
        {
          model: 'code', view: 'pre', title: 'Code', class: 'message-box-right',
        },
      ],
    },
  };

  if (imageControl) {
    config.plugins = config.plugins?.concat(
      Image, SimpleUploadAdapter, ImageUpload, ImageToolbar, ImageStyle, ImageResize, LinkImage);
  }
  if (videoControl) {
    config.plugins = config.plugins?.concat(VideoControl);
  }
  if (formControl) {
    config.plugins = config.plugins?.concat(DynamicsControl);
  }
  if (htmlControl) {
    config.plugins = config.plugins?.concat(SourceEditing, GeneralHtmlSupport);
  }

  if (!init) return null;

  return (
    <CKEditor
      editor={ ClassicEditor }
      disabled={ disabled }
      data={ field.value || '' }
      // @ts-expect-error: extended EditorConfig
      config={ config }
      onChange={ (_, editor) => {
        const data = editor.getData();
        field.onChange(data);
      } }
    />
  );
};

export default EliteCKEditor;
