import { takeLatest, call, put } from 'redux-saga/effects';

import {
  CREATE_NEWS_START,
  CREATE_NEWS_ERROR,
  CREATE_NEWS_SUCCESS,
} from '@src/js/actions/news/createNews';

import * as api from '@src/js/api/news/createNews';

function* createNews(options) {
  try {
    const data = yield call(() => api.createNews(options.data.values));
    yield put({ type: CREATE_NEWS_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: CREATE_NEWS_ERROR, error });
    options.data.reject(error);
  }
}

function* createNewsWatcher() {
  yield takeLatest(CREATE_NEWS_START, createNews);
}

export default [
  createNewsWatcher(),
];
