import {
  GET_OPPORTUNITIES_FOR_COMPANY_START,
  GET_OPPORTUNITIES_FOR_COMPANY_ERROR,
  GET_OPPORTUNITIES_FOR_COMPANY_SUCCESS,
} from '@src/js/actions/marketplace/getOpportunitiesForCompany';

const initialState = {
  loading: false,
  error: null,
  opportunitiesForCompany: null,
};

const actionsMap = {
  [GET_OPPORTUNITIES_FOR_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_OPPORTUNITIES_FOR_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_OPPORTUNITIES_FOR_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    opportunitiesForCompany: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
