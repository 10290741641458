import React from 'react';
import { FormattedMessage } from 'react-intl';
import Classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { ReactTooltipStyled, TooltipContainer } from '@src/_v2/js/components/tooltips/EopTooltip/style';
import {
  Icon,
} from './style';

export const icons = {
  check: 'icon-CheckSmall',
  chat: 'icon-ChatMedium',
  investors: 'icon-InvestorsMedium',
  download: 'icon-DownloadMedium',
  link: 'icon-small-Link',
  edit: 'icon-small-Edit',
  users: 'icon-small-Users',
  calendar: 'icon-small-Calendar',
  close: 'icon-small-Close',
  message: 'icon-medium-Messages',
  meetings: 'icon-medium-Meetings',
  agenda: 'icon-medium-Agenda',
  up: 'icon-ArrowUpSmall',
  down: 'icon-ArrowDownSmall',
  locker: 'icon-LockerSmall',
  info: 'icon-small-Info',
};

const IconStyled = (props) => {
  const {
    icon,
    onClick,
    className,
    title,
    translate,
  } = props;

  return (
    <>
      <Icon
        onClick={ onClick }
        data-tip
        data-for={ title }
        className={
          Classnames(
            icons[icon],
            className
          )
        }
      />
      { title && (
        <ReactTooltipStyled id={ title } effect='solid'>
          <TooltipContainer>
            { translate
              ? <FormattedMessage id={ title } />
              : <span>{ title }</span>
            }
          </TooltipContainer>
        </ReactTooltipStyled>
      )
      }
    </>
  );
};

export const iconKeys = [
  'check',
  'chat',
  'investors',
  'download',
  'link',
  'edit',
  'users',
  'calendar',
  'close',
  'message',
  'meetings',
  'agenda',
  'up',
  'down',
  'locker',
  'info',
];

IconStyled.propTypes = {
  icon: PropTypes.oneOf(iconKeys),
  onClick: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.bool,
};

IconStyled.defaultProps = {
  translate: true,
};

export default IconStyled;
