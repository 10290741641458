import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@src/js/components/global';
import { createCompliance, clearRequest } from '@app/store/compliance/actions';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { Compliance, FundingOption } from '@src/js/constants/entities';
import {
  selectComplianceStatus, selectComplianceStatusLoading, selectComplianceRequestSent,
} from '@app/store/compliance/selectors';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const options = Object.values(FundingOption);

const ComplianceModal = ({ status, onCloseAction }) => {
  const [selected, setSelected] = useState(null);
  const [desiredAmount, setDesiredAmount] = useState(1);
  const complianceStatus = selectComplianceStatus();
  const requestSent = selectComplianceRequestSent();
  const draftCompliance = complianceStatus?.status === Compliance.DRAFT;
  const complianceStatusLoading = selectComplianceStatusLoading();
  const userInfo = selectUserInfo();

  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();

  useEffect(() => () => dispatch(clearRequest()), []);

  const onSelect = (e) => {
    setSelected(e.target?.value);
    setDesiredAmount(e.target?.value === FundingOption.equityPublic ? null : 1);
  };

  const save = () => {
    if (!selected || !userInfo?.companySlug) return;
    dispatch(createCompliance(userInfo.companySlug, selected, desiredAmount, status ? 'PATCH' : 'POST'));
  };

  const close = () => {
    if (onCloseAction) onCloseAction();
    hideModal();
  };

  if (requestSent) {
    return (
      <>
        <ModalHeader toggle={ hideModal } className='funding-modal-header'>
          <FormattedMessage id='funding.modal.sent.title' />
        </ModalHeader>
        <ModalBody>
          <p className='funding-modal-body'>
            <FormattedMessage id='funding.modal.sent.body' />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            size='lg'
            buttonText='close'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader toggle={ close } className='funding-modal-header'>
        <FormattedMessage id='funding.modal.title' />
      </ModalHeader>
      <ModalBody>
        { complianceStatusLoading && <Loader /> }
        <div className='p-2'>
          { options.map(option => (
            <Fragment key={ option }>
              <div className='elite-radio-input'>
                <div className='radio-input'>
                  <input
                    id={ `input-${ option }` }
                    name={ `input-${ option }` }
                    type='radio'
                    value={ option }
                    checked={ option === selected }
                    onChange={ onSelect }
                  />
                  <span className='checkmark' />
                  <label className='companies-filter-body-block-content-checkbox' htmlFor={ `input-${ option }` }>
                    <FormattedMessage id={ `funding.modal.option.${ option }` } />
                  </label>
                </div>
              </div>
              { option === selected && option !== FundingOption.equityPublic && (
              <>
                <p className='funding-modal-slider__label'>
                  <FormattedMessage id='funding.modal.desiredIndicativeAmount' />
                </p>
                <div className='funding-modal-slider__container'>
                  <div className='funding-modal-slider'>
                    <p className='funding-modal-slider__label-before'>1 mln</p>
                    <input
                      type='range'
                      min='1'
                      max='100'
                      step={ 1 }
                      value={ desiredAmount }
                      onChange={ e => setDesiredAmount(e.target.value) }
                      style={ {
                        background: `linear-gradient(to right,
                                #009DCD,
                                #009DCD ${ desiredAmount }%,
                                #d3d3d3 ${ desiredAmount }%,
                                #d3d3d3)`,
                      } }
                    />
                    <p className='funding-modal-slider__label-after'>100 mln</p>
                  </div>
                  <p className='funding-modal-slider__label-value'>
                    {desiredAmount}
                    {' '}
                    mln
                  </p>
                </div>
              </>
              ) }
            </Fragment>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='onboarding.button.submit'
          onClickFunc={ save }
        />
        <Button
          size='lg'
          buttonText={ draftCompliance ? 'close' : 'cancel' }
          emptyButtonType='empty-grey'
          onClickFunc={ close }
        />
      </ModalFooter>
    </>
  );
};

ComplianceModal.propTypes = {
  status: PropTypes.string,
  onCloseAction: PropTypes.func,
};

export default ComplianceModal;
