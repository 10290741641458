import { takeLatest, call, put } from 'redux-saga/effects';

import {
  COMPANY_DOCUMENTS_DELETE_START,
  COMPANY_DOCUMENTS_DELETE_ERROR,
  COMPANY_DOCUMENTS_DELETE_SUCCESS,
} from '@src/js/actions/company/companyDocumentsDelete';

import * as api from '@src/js/api/company/companyDocumentsDelete';

function* deleteCompanyDocumentsFiles(options) {
  try {
    const data = yield call(() => api.companyDocumentsDelete(options.fileSlug, options.slug));
    yield put({ type: COMPANY_DOCUMENTS_DELETE_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: COMPANY_DOCUMENTS_DELETE_ERROR, error });
  }
}

function* deleteCompanyDocumentsFileWatcher() {
  yield takeLatest(COMPANY_DOCUMENTS_DELETE_START, deleteCompanyDocumentsFiles);
}

export default [
  deleteCompanyDocumentsFileWatcher(),
];
