import * as PropTypes from 'prop-types';
import React from 'react';
import Forbidden from './Forbidden';
import NotFound from './NotFound';
import UnexpectedError from './UnexpectedError';

const ErrorHandler = (props) => {
  const {
    error,
  } = props;

  switch (error.status) {
    case 403:
      return <Forbidden />;
    case 404:
      return <NotFound />;
    default:
      return <UnexpectedError />;
  }
};

ErrorHandler.propTypes = {
  error: PropTypes.object,
};

export default ErrorHandler;
