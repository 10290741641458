import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { useIntl } from 'react-intl';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { useAppDispatch } from '@src/js/store';
import { findCreateNewThread } from '@app/store/chat/chatApi';

export const GET_SPECIFIC_OPPORTUNITIES_START = 'GET_SPECIFIC_OPPORTUNITIES_START';
export const GET_SPECIFIC_OPPORTUNITIES_SUCCESS = 'GET_SPECIFIC_OPPORTUNITIES_SUCCESS';
export const GET_SPECIFIC_OPPORTUNITIES_ERROR = 'GET_SPECIFIC_OPPORTUNITIES_ERROR';
export const GET_SUCCESS_STORIES_START = 'GET_SUCCESS_STORIES_START';
export const GET_SUCCESS_STORIES_ERROR = 'GET_SUCCESS_STORIES_ERROR';
export const GET_SUCCESS_STORIES_SUCCESS = 'GET_SUCCESS_STORIES_SUCCESS';
export const GET_FUNDING_INTERVIEWS_START = 'GET_FUNDING_INTERVIEWS_START';
export const GET_FUNDING_INTERVIEWS_ERROR = 'GET_FUNDING_INTERVIEWS_ERROR';
export const GET_FUNDING_INTERVIEWS_SUCCESS = 'GET_FUNDING_INTERVIEWS_SUCCESS';
export const GET_FUNDING_INSIGHTS_START = 'GET_FUNDING_INSIGHTS_START';
export const GET_FUNDING_INSIGHTS_ERROR = 'GET_FUNDING_INSIGHTS_ERROR';
export const GET_FUNDING_INSIGHTS_SUCCESS = 'GET_FUNDING_INSIGHTS_SUCCESS';
export const GET_FINANZA_OPPORTUNITIES_START = 'GET_FINANZA_OPPORTUNITIES_START';
export const GET_FINANZA_OPPORTUNITIES_SUCCESS = 'GET_FINANZA_OPPORTUNITIES_SUCCESS';
export const GET_FINANZA_OPPORTUNITIES_ERROR = 'GET_FINANZA_OPPORTUNITIES_ERROR';

export function usePartnerModal() {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { showModal, hideModal } = useGlobalModalContext();
  const confirmContactRequest = (partner) => {
    const recipient = [partner?.partnerContact?.id];
    const bodyMsg = intl.formatMessage({ id: 'partner.contact_message' });
    const subject = intl.formatMessage({ id: 'partner.contact_subject' });
    const message = {
      body: `${ bodyMsg } ${ partner?.name }`,
      subject,
      recipient_uids: recipient,
    };

    dispatch(findCreateNewThread({ participants: recipient, message }));
  };

  const showPartnerModal = (partner) => {
    showModal({
      type: ModalTypes.Confirmation,
      title: 'broker.dialog.message',
      config: { size: 'md' },
      data: { action: () => confirmContactRequest(partner) },
    });
  };

  return {
    showModal: showPartnerModal,
    hideModal,
  };
}

export function getSpecificOpportunities() {
  return {
    type: GET_SPECIFIC_OPPORTUNITIES_START,
  };
}

export function getSuccessStories(storyType, page = 1, limit = 3) {
  return {
    type: GET_SUCCESS_STORIES_START,
    storyType,
    page,
    limit,
  };
}

export function getFundingInterviews(page = 1, limit = 3) {
  return {
    type: GET_FUNDING_INTERVIEWS_START,
    page,
    limit,
  };
}

export function getFundingInsights(section, limit = 3) {
  return {
    type: GET_FUNDING_INSIGHTS_START,
    section,
    limit,
  };
}

export function getFinanzaOpportunities() {
  return {
    type: GET_FINANZA_OPPORTUNITIES_START,
  };
}
