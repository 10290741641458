import {
  all,
  call, put, takeEvery,
} from 'redux-saga/effects';
import * as api from '@app/store/library/api';
import {
  GET_DATA_START, GET_DATA_FAIL, GET_DATA_SUCCESS,
  GET_DIMENSIONS_START, GET_DIMENSIONS_FAIL, GET_DIMENSIONS_SUCCESS,
} from '@app/store/library/actions';
import { showToastrError } from '@app/store/global/actions';

function* getLibraryDimensionData(options) {
  const { params, name } = options;
  try {
    const data = yield call(() => api.getLibraryDimensionData(params));
    yield put({
      type: GET_DATA_SUCCESS,
      data: data?.items,
      name,
      page: params.page || 0,
      count: data?.count,
    });
  } catch (error) {
    yield all([
      put({ type: GET_DATA_FAIL, error, name }),
      put(showToastrError()),
    ]);
  }
}

function* getLibraryDimensions() {
  try {
    const data = yield call(() => api.getLibraryDimensions());
    yield put({
      type: GET_DIMENSIONS_SUCCESS, data,
    });
  } catch (error) {
    yield put({ type: GET_DIMENSIONS_FAIL, error });
  }
}

function* root() {
  yield takeEvery(GET_DATA_START, getLibraryDimensionData);
  yield takeEvery(GET_DIMENSIONS_START, getLibraryDimensions);
}

export default root;
