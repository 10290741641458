import produce from 'immer';
import { ResponseError } from '@app/models/Error';
import { EventObj } from '@app/models/Event';
import { EventReminder } from '@app/models/EventReminder';
import { PaginatedResponse } from '@app/models/Pagination';
import * as Actions from '@app/store/event/actions';
import { Action } from 'redux';
import { History } from 'history';

export type EventState = {
  loading: boolean,
  templateList: PaginatedResponse<EventReminder.TemplateListItem>|null,
  error: unknown,
  pageCount: number,
  event: EventObj|null,
  template?: EventReminder.Template,
  finish: boolean,
}

export interface EventAction extends Action<string> {
  error?: ResponseError,
  data?: PaginatedResponse<EventReminder.TemplateListItem>,
  slug: string,
  search: string,
  types: string[],
  page: number,
  event?: EventObj|null,
  id?: number,
  template?: EventReminder.Template,
  history?: History<unknown>,
}

const initialState: EventState = {
  loading: false,
  error: null,
  templateList: null,
  pageCount: 0,
  event: null,
  template: undefined,
  finish: false,
};

function eventReducer(state = initialState, action: EventAction) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case Actions.GET_EVENT_START:
        draft.loading = true;
        draft.error = null;
        break;
      case Actions.GET_EVENT_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.event = action.event || null;
        break;
      case Actions.GET_EVENT_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case Actions.GET_REMINDER_TEMPLATE_START:
        draft.loading = true;
        draft.error = null;
        draft.template = undefined;
        break;
      case Actions.GET_REMINDER_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.template = action.template;
        break;
      case Actions.GET_REMINDER_TEMPLATE_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case Actions.REMINDER_TEMPLATE_LIST_START:
        draft.loading = true;
        draft.error = null;
        draft.templateList = null;
        break;
      case Actions.REMINDER_TEMPLATE_LIST_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.templateList = action.data || null;
        break;
      case Actions.REMINDER_TEMPLATE_LIST_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case Actions.CREATE_REMINDER_TEMPLATE_START:
      case Actions.EDIT_REMINDER_TEMPLATE_START:
        draft.loading = true;
        draft.error = null;
        break;
      case Actions.CREATE_REMINDER_TEMPLATE_SUCCESS:
      case Actions.EDIT_REMINDER_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.finish = true;
        break;
      case Actions.CREATE_REMINDER_TEMPLATE_FAIL:
      case Actions.EDIT_REMINDER_TEMPLATE_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case Actions.REMINDER_TEMPLATE_RESET:
        draft.finish = false;
        break;
      default:
        break;
    }
  }
  );
}

export default eventReducer;
