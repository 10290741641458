import produce from 'immer';

import {
  GET_PROMOTED_LIST_START,
  GET_PROMOTED_LIST_FAIL,
  GET_PROMOTED_LIST_SUCCESS,
  GET_PROMOTED_OPTIONS_START,
  GET_PROMOTED_OPTIONS_FAIL,
  GET_PROMOTED_OPTIONS_SUCCESS,
  POST_PROMOTED_CONTENT_START,
  POST_PROMOTED_CONTENT_FAIL,
  POST_PROMOTED_CONTENT_SUCCESS,
  DELETE_PROMOTED_CONTENT_START,
  DELETE_PROMOTED_CONTENT_FAIL,
  DELETE_PROMOTED_CONTENT_SUCCESS,
  GET_PROMOTED_CONTENT_START,
  GET_PROMOTED_CONTENT_FAIL,
  GET_PROMOTED_CONTENT_SUCCESS,
  SET_OPPORTUNITY_REQUESTED,
} from '@app/store/content/promotedContent/actions';

export const initialState = {
  listLoading: false,
  buttonLoading: {},
  promotedList: [],
  promotedOptions: {},
  promotedContent: {},
  promotedLoading: false,
  postLoading: false,
  postSuccess: false,
};

function promotedContentReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_PROMOTED_LIST_START:
        draft.listLoading = true;
        draft.promotedList = [];
        break;
      case GET_PROMOTED_LIST_FAIL:
        draft.listLoading = false;
        break;
      case GET_PROMOTED_LIST_SUCCESS:
        draft.listLoading = false;
        draft.promotedList = action.result.data;
        break;
      case GET_PROMOTED_OPTIONS_START:
        draft.promotedOptions = {};
        break;
      case GET_PROMOTED_OPTIONS_FAIL:
        break;
      case GET_PROMOTED_OPTIONS_SUCCESS:
        draft.promotedOptions = action.result;
        break;
      case POST_PROMOTED_CONTENT_START:
        draft.postLoading = true;
        draft.postSuccess = false;
        break;
      case POST_PROMOTED_CONTENT_FAIL:
        draft.postLoading = false;
        draft.postSuccess = false;
        break;
      case POST_PROMOTED_CONTENT_SUCCESS:
        draft.postLoading = false;
        draft.postSuccess = true;
        break;
      case DELETE_PROMOTED_CONTENT_START:
        draft.buttonLoading[action.id] = true;
        break;
      case DELETE_PROMOTED_CONTENT_FAIL:
        draft.buttonLoading[action.id] = false;
        break;
      case DELETE_PROMOTED_CONTENT_SUCCESS:
        draft.buttonLoading[action.id] = false;
        draft.promotedList = state.promotedList
          .filter(promoted => promoted.id !== action.id);
        break;
      case GET_PROMOTED_CONTENT_SUCCESS:
        draft.promotedContent = action.result;
        break;
      case SET_OPPORTUNITY_REQUESTED:
        if (state.promotedContent?.content) {
          draft.promotedContent.content.Requested = true;
        }
        break;
      case GET_PROMOTED_CONTENT_START:
      case GET_PROMOTED_CONTENT_FAIL:
      default:
        break;
    }
  }
  );
}

export default promotedContentReducer;
