import fetchResource from '@src/js/api/fetch-resource';

export function getFeaturedLibrary(filters) {
  let url = 'api/library';

  if (filters) {
    const esc = encodeURIComponent;

    const query = Object.keys(filters)
      .map(k => (esc(filters[k]) ? `${ esc(k) }=${ esc(filters[k]) }` : ''))
      .join('&');

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}
