const FUNDING_OPTIONS = {
  TRANSACTIONS: 1,
  BASKET_BONDS: 2,
  INTRODUCTION: 3,
  IPO: 4,
};

const SUCCESS_STORIES_TYPE = {
  TRANSACTIONS: 'success_stories_transactions',
  BASKET_BONDS: 'success_stories_basket',
  INTRODUCTION: 'success_stories_introduction',
  IPO: 'success_stories_ipo',
  ALL: 'success_stories',
};

const FUNDING_SECTIONS = {
  SINGLE_TRANSACTIONS: 'single_transactions',
  BASKET_BONDS: 'basket_bonds',
  IPO: 'ipo',
};

export {
  FUNDING_OPTIONS,
  SUCCESS_STORIES_TYPE,
  FUNDING_SECTIONS,
};
