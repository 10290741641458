import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getWidgetNews } from '@src/js/actions/news/widgetNews';
import { ButtonLink } from '@src/_v2/js/components/buttons';
import { routeCodes, withLang } from '@src/js/constants/routes';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import WidgetNewsCard from '@src/js/components/global/cards/WidgetNewsCard';
import {
  Content, Footer, Header, Loader,
} from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components';

class NewsWidget extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    dispatch: PropTypes.func,
    widgetNews: PropTypes.array,
    activeLanguage: PropTypes.string,
    disabled: PropTypes.bool,
    showHeader: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    showHeader: true,
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getWidgetNews());
  }

  componentDidUpdate(prevProps) {
    this.onLanguageChange(prevProps);
  }

  onLanguageChange = (prevProps) => {
    const { activeLanguage, dispatch } = this.props;

    if (prevProps.activeLanguage !== activeLanguage) {
      dispatch(getWidgetNews());
    }
  };

  render() {
    const {
      loading, widgetNews, disabled, showHeader, className,
    } = this.props;

    return (
      <DashboardWidget
        id='dashboard-news-widget'
        className={ `${ className || '' } p-x-1 p-y-1` }
      >
        { showHeader && (
          <Header>
            <FormattedMessage id='navigation.news' />
          </Header>
        ) }
        <Content>
          { loading && <Loader /> }
          { widgetNews?.length > 0 && !loading && widgetNews.map(news => (
            <WidgetNewsCard
              key={ news.slug }
              news={ news }
              disabled={ disabled }
            />
          )) }
        </Content>
        { widgetNews?.length > 2 && (
          <Footer>
            { !disabled ? (
              <ButtonLink text='viewAll' to={ `${ withLang(routeCodes.NEWS) }` } />
            ) : (
              <ButtonLink text='viewAll' />
            )}
          </Footer>
        )}
      </DashboardWidget>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.widgetNews.loading,
  error: state.widgetNews.error,
  widgetNews: state.widgetNews.widgetNews,
  activeLanguage: state.initInfo.activeLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(NewsWidget);

export default withRouter(connectWrapper);
