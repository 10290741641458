import React from 'react';
import * as PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { get as lodashGet } from 'lodash';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import { turnoverFormatter } from '@src/js/utils';
import SelectBox from '@src/js/components/global/inputs/Selectbox';
import { TextInput, MultiDropDown } from '@src/js/components/global/inputs';
import { FieldContainer } from './styled';

const handleSectorByLevel = (form, value, name) => {
  form.mutators.setSecondLevelSector(null);
  form.mutators.setSector(null);
  form.change(name, value);
};

const CompanyInfo = (props) => {
  const {
    form,
    companyFormOptions,
    intl,
  } = props;

  return (
    <>
      <Field
        name='basic.name'
        fieldName='basic.name'
        fieldLabel='company'
        placeholder='company'
        fieldType='text'
        component={ TextInput }
        fieldRequired={ true }
        min={ 2 }
        maxLength={ 256 }
      />
      <FieldContainer>
        { companyFormOptions && (
          <MultiDropDown
            name='basic.sectors'
            labels={ ['company_form.sectors'] }
            requiredLevels={ 2 }
            valueOptions={ companyFormOptions.results.sectors }
            setValue={ (value, name) => handleSectorByLevel(form, value, name) }
            maxLevels={ 2 }
            form={ form }
          />
        ) }
      </FieldContainer>
      <FieldContainer>
        <Field
          name='basic.etineraryCompanyType'
          fieldName='basic.etineraryCompanyType'
          fieldLabel='companyType.label'
          options={ companyFormOptions?.results?.etineraryCompanyTypes?.sort((a, b) => a.id - b.id).map(type => ({
            value: type.id,
            label: intl.formatMessage({ id: type.name }),
          }))
          }
          component={ SelectBox }
          fieldRequired
          fieldNoClear
          fieldNoSearch
        />
      </FieldContainer>
      <FieldContainer>
        <div className='eop-selectbox__label d-flex align-items-center eop-text-color-medium-grey w-100'>
          <span className='eop-text-input__label__required-icon'>*</span>
          <FormattedMessage id='country' />
          <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ 'country-tooltip' } />
          <ReactTooltip
            id={ 'country-tooltip' }
            place='top'
            effect='solid'
            multiline={ true }
            className='w-25'
          >
            <FormattedMessage id='selfRegistration.country.tooltip' />
          </ReactTooltip>
        </div>
        <Field
          name='basic.country'
          fieldName='basic.country'
          fieldType='text'
          fieldRequired
          options={ lodashGet(companyFormOptions, 'results.countries')
          && companyFormOptions.results.countries.map(country => ({ value: country.id, label: country.name }))
          }
          fieldDisabled={ false }
          component={ SelectBox }
        />
      </FieldContainer>
      <FieldContainer>
        <Field
          name='basic.turnover'
          fieldName='basic.turnover'
          fieldType='text'
          fieldLabel='company_form.turnover'
          options={
            companyFormOptions?.results?.etineraryTurnovers?.map(turnover => ({
              value: turnover.id,
              label: turnoverFormatter(turnover.name,
                intl.formatMessage({ id: 'company_form.currency.EUR' })),
            }))
          }
          component={ SelectBox }
          fieldRequired
        />
      </FieldContainer>
      <Field
        name='basic.employeeNumber'
        fieldName='basic.employeeNumber'
        fieldLabel='company_form.employeeNumber'
        placeholder='company_form.employeeNumber'
        fieldType='text'
        component={ TextInput }
        fieldRequired
        min={ 1 }
      />
      <Field
        name='basic.vatNumber'
        fieldName='basic.vatNumber'
        fieldType='text'
        fieldLabel='partner_form.vatNumber'
        fieldMaxLength={ 100 }
        component={ TextInput }
        fieldRequired
      />
    </>
  );
};

CompanyInfo.propTypes = {
  form: PropTypes.object,
  companyFormOptions: PropTypes.object,
  intl: PropTypes.object,
};

export default CompanyInfo;
