import { Participant } from '@app/models/Chat';
import { routeCodes, withLang } from '@src/js/constants/routes';
import React from 'react';

type Props = {
  participants: Participant[];
}

const MessageCompany = ({ participants }: Props) => {
  if (participants.length === 1) {
    if (participants[0].companySlug && participants[0].company) {
      return (
        <span className='messages-panel__header--regular'>
          <span role='presentation'>, </span>
          <a
            href={ participants[0].isCompany
              ? `${ withLang(routeCodes.COMPANY.replace(':slug', participants[0].companySlug)) }`
              : `${ withLang(routeCodes.PARTNER.replace(':slug', participants[0].companySlug)) }` }
            className='company-link'
          >
            { participants[0].company }
          </a>
        </span>
      );
    }
    if (participants[0].company) {
      return (
        <span className='messages-panel__header--regular'>
          <span role='presentation'>, </span>
          { participants[0].company }
        </span>
      );
    }
  }
  return null;
};

export default MessageCompany;
