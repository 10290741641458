import fetchResource from '@src/js/api/fetch-resource';

export function getElasticsearchResults(query, page, limit, entity) {
  let url = `api/search?query=${ query }&page=${ page }`;

  if (typeof limit === 'number') {
    url = `${ url }&limit=${ limit }`;
  }

  if (typeof entity === 'string') {
    url = `${ url }&entity=${ entity }`;
  }

  return fetchResource(url, {
    method: 'GET',
  });
}

export function getElasticsearchCompanyResults(options, offsetCompany, offsetPartner) {
  let url = `api/companies?query=${ options.query }&page=${ options.page }`;
  if (options.selectedType) {
    url = `${ url }&selectedType=${ options.selectedType }`;
  } else {
    url = `${ url }&selectedType=homePage`;
  }

  if (Object.keys(options.params).length) {
    const esc = encodeURIComponent;

    const filterParams = Object.keys(options.params)
      .map(k => `${ esc(k) }=${ esc(options.params[k]) }`)
      .join('&');

    url = `${ url }&${ filterParams }`;
  }

  if (typeof offsetCompany === 'string') {
    url = `${ url }&offsetCompany=${ offsetCompany }&offsetPartner=${ offsetPartner }`;
  }

  return fetchResource(url, {
    method: 'GET',
  });
}

export function getElasticsearchResultSearch(query) {
  const params = new URLSearchParams({ query });
  return fetchResource(`api/companies/search?${ params }`, {
    method: 'GET',
  });
}
