import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ClassNames from 'classnames';
import { get as lodashGet } from 'lodash';
import { Company, YES_OR_NO, MembershipStatus } from '@src/js/constants/entities';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import { Selectbox as SelectBox, TextInput } from '@src/js/components/global/inputs';
import { uploadImage } from '@src/js/actions/image/uploadImage';
import { UploadDropzone } from '@src/js/components/global/uploadDropzone/UploadDropzone';
import { Images } from '@src/js/constants/images';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import { getUserFormCompanyOptionsAction } from '@src/js/actions/user/formOptions';

class CompanyFormBasicInfo extends Component {
  static propTypes = {
    companyFormOptions: PropTypes.object,
    companyType: PropTypes.number,
    companyLogoPreview: PropTypes.object,
    referrerUrl: PropTypes.string,
    dispatch: PropTypes.func,
    handleChangeType: PropTypes.func,
    setLogoPreviewFunc: PropTypes.func,
    setLogoUploadingStatusFunc: PropTypes.func,
    isDisabledFunc: PropTypes.func,
    isImageUploading: PropTypes.bool,
    uploadedImage: PropTypes.object,
    formMutators: PropTypes.object,
    isDefaultLanguage: PropTypes.bool,
    companyId: PropTypes.number,
    loggedUser: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploadErrors: null,
      oldImage: null,
      search: {
        query: '',
        page: 0,
      },
    };
  }

  componentDidMount() {
    this.handleCompanyType();
    this.handleCompassType();
  }

  componentDidUpdate(prevProps) {
    const {
      uploadedImage, formMutators, setLogoUploadingStatusFunc, companyFormOptions,
    } = this.props;

    this.handleCompanyType();
    this.setOldLogo();

    if (!prevProps.companyFormOptions && companyFormOptions.results) {
      this.handleCompassType();
    }

    if (prevProps.uploadedImage.image !== uploadedImage.image) {
      if (uploadedImage.options.imageType === 'logo') {
        setLogoUploadingStatusFunc(false);
        if (uploadedImage.image && uploadedImage.image.id) {
          formMutators.setLogo(uploadedImage.image.id);
        } else {
          formMutators.setLogo(null);
        }
        this.setState({
          uploadErrors: {
            companyLogo: null,
          },
        });
      }
    }
  }

  setOldLogo = () => {
    const { companyLogoPreview } = this.props;
    const { oldImage } = this.state;

    if (!oldImage && companyLogoPreview) {
      this.setState({
        oldImage: companyLogoPreview,
      });
    }
  };

  // If there is only one option, select and disable it
  handleCompanyType = () => {
    const { formMutators, handleChangeType, companyFormOptions } = this.props;
    const isSingleType = this.isSingleType();

    if (isSingleType && lodashGet(companyFormOptions, 'results.companyTypes')[0].value === isSingleType.value) {
      handleChangeType(isSingleType);
      formMutators.setBasicType(isSingleType.value);
    }
  };

  handleCompassType = () => {
    const {
      companyFormOptions,
      formMutators,
    } = this.props;

    const defaultValues = companyFormOptions
      && companyFormOptions.results
      && companyFormOptions.results.defaultValues;

    const compassType = formMutators.getCompassType();
    const isSetCompassType = compassType && compassType !== Company.COMPASS_TYPE_NONE;

    if (defaultValues && !isSetCompassType) {
      formMutators.setCompassType(defaultValues.compass.id);
    }
  };

  isSingleType = () => {
    const { companyFormOptions } = this.props;

    return companyFormOptions
      && companyFormOptions.results
      && companyFormOptions.results.companyTypes.length === 1
      && companyFormOptions.results.companyTypes[0];
  };

  handleDropImage = (acceptedFiles) => {
    const { dispatch, setLogoPreviewFunc, setLogoUploadingStatusFunc } = this.props;
    const newImage = acceptedFiles[0];
    const image = new FormData();

    image.append('image', newImage);

    return new Promise((resolve, reject) => {
      dispatch(uploadImage({ image, resolve, reject }, 'company', 'logo'));
      setLogoUploadingStatusFunc(true);
      this.setState({
        uploadErrors: null,
      });
    }).then(() => {
      setLogoPreviewFunc(newImage);
      return null;
    }).catch(errors => {
      setLogoUploadingStatusFunc(false);
      this.setState({
        uploadErrors: errors,
      });

      return errors;
    });
  };

  handleDeleteImage = () => {
    const { formMutators, setLogoPreviewFunc } = this.props;
    const { oldImage, uploadErrors } = this.state;

    if (uploadErrors && uploadErrors.companyLogo) {
      setLogoPreviewFunc(oldImage);
    } else {
      setLogoPreviewFunc(null);
      formMutators.setLogo(null);
    }

    this.setState({
      uploadErrors: null,
    });
  };

  handleChangeBasicType = (option) => {
    const { formMutators, handleChangeType } = this.props;

    handleChangeType(option);
    formMutators.setTurnover(null);

    if (option) {
      formMutators.setBasicType(option);
    } else {
      formMutators.setBasicType(null);
    }

    formMutators.setVisibility(null);
  };

  handleChangeCompassType = (option) => {
    const {
      formMutators,
    } = this.props;

    formMutators.setCompassType(option);
  };

  handleChangeMembership = (option) => {
    const {
      formMutators,
    } = this.props;
    formMutators.setMembership(option);
    formMutators.setPaying(option === MembershipStatus.MEMBERSHIP || option === MembershipStatus.MEMBERSHIP_INITIAL);
  };

  handleChangeEtineraryType = (option) => {
    const {
      formMutators,
    } = this.props;

    formMutators.setEtineraryCompanyType(option);
  };

  loadPartnerOptions = async (currentQuery) => {
    const { dispatch } = this.props;
    const { search } = this.state;

    const dataToSend = {
      type: 'partner',
      query: currentQuery,
      page: search.page ? search.page : 0,
    };

    if (search.query !== currentQuery) {
      dataToSend.page = 0;
    }

    return new Promise((resolve, reject) => {
      dispatch(getUserFormCompanyOptionsAction({ values: dataToSend, resolve, reject }));
    }).then((data) => {
      this.setState({
        search: {
          query: currentQuery,
          page: data && data.pagination ? data.pagination.nextPage : 0,
        },
      });

      return {
        options: data && data.results ? data.results
          .map(item => ({ value: item.slug, label: item.name, id: item.id })) : {},
        hasMore: data && data.pagination ? data.pagination.hasMoreItems : false,
      };
    }).catch(errors => errors);
  };

  getTypeOptions = () => {
    const { companyFormOptions } = this.props;

    return (companyFormOptions && companyFormOptions.results)
      ? companyFormOptions.results.companyTypes.map(typeItem => ({
        value: typeItem.value,
        label: typeItem.label,
      }))
      : null;
  };

  getCompassOptions = () => {
    const { companyFormOptions } = this.props;

    return (companyFormOptions && companyFormOptions.results)
      ? companyFormOptions.results.compass.map(typeItem => ({
        value: typeItem.value,
        label: <FormattedMessage id={ typeItem.label } />,
      }))
      : null;
  };

  getVisibilityOptions = () => [
    { value: 2, label: <FormattedMessage id='company_form.private' /> },
    { value: 3, label: <FormattedMessage id='company_form.hidden' /> },
  ];

  getMembershipOptions = () => [
    { value: 'membership', label: <FormattedMessage id='company_form.membership' /> },
    { value: 'initial_period', label: <FormattedMessage id='company_form.membershipInitial' /> },
    { value: 'not_paying', label: <FormattedMessage id='company_form.membershipNotPaying' /> },
    { value: 'withdraw', label: <FormattedMessage id='company_form.membershipWithdraw' /> },
  ];

  getEtineraryType = () => {
    const { companyFormOptions } = this.props;

    return (companyFormOptions?.results)
      ? companyFormOptions.results.etineraryCompanyTypes.map(type => ({
        value: type.id,
        label: <FormattedMessage id={ type.name } />,
      }))
      : null;
  };

  canEditPartner = () => {
    const { companyFormOptions } = this.props;

    return companyFormOptions
      && companyFormOptions.results
      && companyFormOptions.results.canEditPartner;
  };

  render() {
    const {
      companyType, companyLogoPreview, isDefaultLanguage, referrerUrl,
      isDisabledFunc, isImageUploading, companyId, loggedUser, formMutators,
    } = this.props;
    const { uploadErrors } = this.state;
    const isGrowth = (companyType === Company.TYPE_GROWTH);
    const isGrowthStartup = (companyType === Company.TYPE_GROWTH_STARTUP);
    const isEtinerary = (companyType === Company.TYPE_ETINERARY);
    const isSingleType = !!(this.isSingleType());
    const typeOptions = this.getTypeOptions();
    const compassOptions = this.getCompassOptions();
    const visibilityOptions = this.getVisibilityOptions();
    const membershipOptions = this.getMembershipOptions();
    const etineraryType = this.getEtineraryType();

    if (isGrowth || isGrowthStartup) {
      visibilityOptions.unshift({ value: 1, label: <FormattedMessage id='company_form.public' /> });
    }

    return (
      <div className='edit-company-page edit-company-page__section'>
        <div className='row'>
          <div className='col-xs'>
            <div className='edit-company-page__title edit-user-page-tab-content-title'>
              <FormattedMessage id='company_form.basic' />
            </div>
          </div>
        </div>

        <div className='logo-wrapper'>
          <UploadDropzone
            handleDrop={ this.handleDropImage }
            handleDelete={ this.handleDeleteImage }
            image={ companyLogoPreview }
            isLoading={ isImageUploading }
            defaultImage={ Images.EliteSmall }
            customError={ uploadErrors && uploadErrors.companyLogo }
          />
          <div className='required-label'>
            <Field
              name='basic.logo'
              fieldName='basic.logo'
              fieldType='text'
              fieldLabel=''
              component={ TextInput }
              validationMessageOnly
            />
          </div>
        </div>
        <Field
          name='basic.name'
          fieldName='basic.name'
          fieldType='text'
          fieldLabel='name'
          component={ TextInput }
          fieldMaxLength={ 100 }
          fieldRequired
        />

        { companyId > 0 && (
          <Field
            name='companyId'
            fieldName='companyId'
            fieldType='text'
            fieldLabel='company_form.companyId'
            component={ TextInput }
            fieldDisabled
          />
        ) }

        {(isGrowth || isGrowthStartup) && this.canEditPartner() && (
          <Field
            name='basic.crmId'
            fieldName='basic.crmId'
            fieldType='text'
            fieldLabel='company_form.crmId'
            component={ TextInput }
          />
        ) }

        { companyId > 0 && referrerUrl && (
          <Field
            name='referrerUrl'
            fieldName='referrerUrl'
            fieldType='text'
            fieldLabel='company_form.referrerUrl'
            component={ TextInput }
            fieldDisabled
          />
        ) }

        { typeOptions && (
          <Field
            name='basic.type'
            additionalClass={ ClassNames('m-t-0', (!isDefaultLanguage || isDisabledFunc('basic.type') || isSingleType)
            && loggedUser.companyType === Company.TYPE_ETINERARY ? 'hidden' : '') }
            fieldName='basic.type'
            fieldType='text'
            fieldLabel='company_form.type'
            fieldNoClear
            fieldRequired
            fieldDisabled={ isDisabledFunc('basic.type') || isSingleType }
            options={ typeOptions.map(type => ({ value: type.value, label: <FormattedMessage id={ type.label } /> })) }
            onChange={ this.handleChangeBasicType }
            component={ SelectBox }
          />
        ) }

        { isEtinerary && (
          <Field
            name='basic.etineraryCompanyType'
            fieldName='basic.etineraryCompanyType'
            fieldType='text'
            fieldLabel='etinerary.type'
            fieldRequired
            options={ etineraryType }
            component={ SelectBox }
            onChange={ this.handleChangeEtineraryType }
            fieldDisabled={ isDisabledFunc('basic.etineraryCompanyType') }
          />
        ) }

        <Field
          name='basic.visibility'
          fieldName='basic.visibility'
          fieldType='text'
          fieldLabel='company_form.visibility'
          fieldRequired
          options={ visibilityOptions }
          component={ SelectBox }
          fieldDisabled={ isDisabledFunc('basic.visibility') }
        />

        { (isGrowth || isGrowthStartup || isEtinerary) && (
          <Field
            name='basic.partner'
            fieldName='basic.partner'
            fieldType='text'
            fieldLabel='company_form.partner'
            loadOptions={ this.loadPartnerOptions }
            component={ SelectBox }
            fieldDisabled={ !this.canEditPartner() }
          />
        ) }

        { (isGrowth || isGrowthStartup) && (isAdminRole() || isPra()) && (
          <>
            <Field
              name='basic.membershipStatus'
              fieldName='basic.membershipStatus'
              fieldType='text'
              fieldLabel='company_form.membership'
              options={ membershipOptions }
              component={ SelectBox }
              onChange={ this.handleChangeMembership }
              fieldDisabled={ !this.canEditPartner() }
            />
            { this.canEditPartner() && (
            <Field
              name='basic.paying'
              fieldName='basic.paying'
              fieldType='text'
              fieldLabel='company_form.paying'
              options={ YES_OR_NO }
              component={ SelectBox }
              fieldNoClear
              simpleValue
              onChange={ val => formMutators.setPaying(val) }
            />
            )}
          </>
        ) }

        { isEtinerary && (
          <Field
            name='basic.compass'
            fieldName='basic.compass'
            fieldType='text'
            fieldLabel='company_form.compass'
            fieldRequired
            options={ compassOptions }
            component={ SelectBox }
            onChange={ this.handleChangeCompassType }
            fieldDisabled={ isDisabledFunc('basic.compass') }
          />
        ) }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.adminCompany.loading,
  companyFormOptions: state.companyFormOptions.companyFormOptions,
  uploadedImage: state.uploadImage,
  partnerOptions: state.userFormOptions.userFormOptions,
  loggedUser: state.initInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormBasicInfo);

export default withRouter(connectWrapper);
