import fetchResource from '@src/js/api/fetch-resource';

export function getTopicList({
  search, page, programmes, statuses,
}) {
  const params = new URLSearchParams({
    page, search, programmes, statuses,
  });
  return fetchResource(`api/topic?${ params }`, {
    method: 'GET',
  });
}

export function getTopicListNavigation() {
  return fetchResource('api/topic?navigation', {
    method: 'GET',
  });
}

export function getTopic({ id }) {
  return fetchResource(`api/topic/${ id }`, {
    method: 'GET',
  });
}

export function updateTopic(id, data) {
  return fetchResource(`api/topic/${ id }`, {
    method: 'PUT',
    body: { ...data },
  });
}

export function createTopic(data) {
  return fetchResource('api/topic', {
    method: 'POST',
    body: { ...data },
  });
}

export function updateTopicStatus({ id, status }) {
  return fetchResource(`api/topic/${ id }`, {
    method: 'PATCH',
    body: { status },
  });
}
