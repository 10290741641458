import React from 'react';
import PropTypes from 'prop-types';

const ListFooterContainer = ({
  pagination,
  children,
  className,
}) => (
  <div className={ `list-footer ${ className || '' }` }>
    <div className='list-footer__pages'>
      { pagination }
    </div>
    { children
        && (
        <div className='list-footer__actions'>
          { children }
        </div>
        )
      }
  </div>
);

ListFooterContainer.propTypes = {
  pagination: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  className: PropTypes.string,
};

export default ListFooterContainer;
