import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import FormattedMessageHTML from '@app/components/global/FormattedMessageHTML';
import { FormattedMessage } from 'react-intl';
import { selectActiveLanguage } from '@app/store/global/selectors/initInfo';

const DirectorsModal = () => {
  const activeLang = selectActiveLanguage();
  return (
    <>
      <ModalBody className='m-y-4 flex-h-gap-24'>
        <FormattedMessageHTML
          id='compliance.form.directors.modal.intro'
          className='m-b-0 font-size-body eop-text-color-medium-grey lh-24'
        />
        <div className='flex-h-gap-16'>
          <h5 className='m-b-0 font-size-body eop-text-color-blue lh-24 compliance__modal-title-list'>
            <FormattedMessage id='compliance.form.directors.modal.identityVerification' />
          </h5>
          <ol className='m-t-0 font-size-body eop-text-color-medium-grey lh-24'>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.signedPassport' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.residencePermit' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.drivingLicense' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.identityCard' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.shotgunLicense' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.identityCard.ireland' />
            </li>
            { activeLang === 'en' && (
            <li>
              <FormattedMessage id='compliance.form.directors.modal.noteUk' />
            </li>
            )}
            <li>
              <FormattedMessage id='compliance.form.directors.modal.allCountries' />
            </li>
          </ol>
        </div>
        <div className='d-flex-col flex-h-gap-16'>
          <h5 className='m-b-0 font-size-body eop-text-color-blue lh-24 compliance__modal-title-list'>
            <FormattedMessage id='compliance.form.directors.modal.addressVerification' />
          </h5>
          <ol className='m-t-0 font-size-body eop-text-color-medium-grey lh-24'>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.taxBill' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.bank' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.recentBill' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.drivingLicense.notProduced' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.currentDrivingLicense' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.mortgageStatement' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.solicitor' />
            </li>
            <li>
              <FormattedMessage id='compliance.form.directors.modal.localCouncil' />
            </li>
          </ol>
        </div>
      </ModalBody>
      <ModalFooter />
    </>
  );
};

DirectorsModal.propTypes = {

};

export default DirectorsModal;
