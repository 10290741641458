import React from 'react';
import * as PropTypes from 'prop-types';
import { Container } from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/styled';

const DashboardWidget = ({ id, className, children }) => (
  <Container id={ id } className={ `${ className || '' } dashboard-widget` }>
    { children }
  </Container>
);
DashboardWidget.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DashboardWidget;
