import React from 'react';
import * as PropTypes from 'prop-types';
import Select from '@app/components/global/forms/select/EliteSelect';
import {
  ArrowRenderer, LockerRenderer, LoadingRenderer,
} from '@app/components/global/forms/select/Renderers';
import { injectIntl, FormattedMessage } from 'react-intl';
import ClassNames from 'classnames';
import { AsyncPaginate } from 'react-select-async-paginate';
import { RequiredIcon, Warning } from './style';

const Selectbox = ({
  input, options, fieldLabel, placeholder, fieldName, additionalClass,
  fieldRequired, fieldDisabled, fieldNoClear, noResultsText, multi, onChange, loadOptions,
  selectedValues, meta, searchable, translate, labelClass, intl, simpleValue, initialValue,
}) => {
  const noResultsTextDefault = intl.formatMessage({ id: 'global_form.select.no_results' });
  const selectboxProps = {
    ...input,
    simpleValue,
    components: {
      DropdownIndicator: ArrowRenderer,
      LoadingIndicator: LoadingRenderer,
    },
    placeholder,
    multi,
    name: fieldName,
  };

  if (onChange) {
    selectboxProps.onChange = onChange;
  }

  selectboxProps.options = options;

  if (selectedValues) {
    selectboxProps.value = selectedValues;
  }

  if (fieldDisabled) {
    selectboxProps.className = 'eop-input-disabled';
    selectboxProps.disabled = true;
    selectboxProps.components.DropdownIndicator = LockerRenderer;
  }

  if (fieldNoClear) {
    selectboxProps.components.ClearIndicator = () => '';
  }

  if (initialValue) {
    selectboxProps.defaultValue = initialValue;
  }

  selectboxProps.isSearchable = searchable;

  return (
    <div className={ ClassNames('eop-selectbox', additionalClass) }>
      <label className={ ClassNames('eop-selectbox__label', labelClass) }>
        { fieldLabel && fieldRequired && <RequiredIcon /> }
        { fieldLabel && (translate ? <FormattedMessage id={ fieldLabel } /> : fieldLabel) }
        { placeholder && translate && (
        <FormattedMessage id={ placeholder }>
          {
                placeholderMessage => (
                  loadOptions
                    ? (
                      <AsyncPaginate
                        { ...selectboxProps }
                        noResultsText={ noResultsText
                          ? intl.formatMessage({ id: noResultsText })
                          : noResultsTextDefault
                        }
                        placeholder={ placeholderMessage }
                        loadOptions={ loadOptions }
                        key={ loadOptions }
                        isMulti={ selectboxProps.multi }
                        isClearable={ !selectboxProps?.disabled }
                        isDisabled={ selectboxProps?.disabled }
                      />
                    )
                    : (
                      <Select
                        { ...selectboxProps }
                        noResultsText={ noResultsText
                          ? intl.formatMessage({ id: noResultsText })
                          : noResultsTextDefault
                        }
                        placeholder={ placeholderMessage }
                      />
                    )
                )
              }
        </FormattedMessage>
        ) }
        { placeholder && !translate && (
          loadOptions
            ? (
              <AsyncPaginate
                { ...selectboxProps }
                noResultsText={ noResultsText || noResultsTextDefault }
                placeholder={ placeholder }
                loadOptions={ loadOptions }
                key={ loadOptions }
                isMulti={ selectboxProps.multi }
                isDisabled={ selectboxProps?.disabled }
              />
            )
            : (
              <Select
                { ...selectboxProps }
                noResultsText={ noResultsText || noResultsTextDefault }
                placeholder={ placeholder }
              />
            )
        ) }
        { !placeholder && (loadOptions
          ? (
            <AsyncPaginate
              { ...selectboxProps }
              loadOptions={ loadOptions }
              isMulti={ selectboxProps.multi }
            />
          )
          : <Select { ...selectboxProps } />)
          }
        { meta && (
        <Warning>
          {
                (meta.error || (meta.submitError && meta.dirtySinceLastSubmit === false))
                && meta.touched && (<FormattedMessage id={ meta.error || meta.submitError } />)
              }
        </Warning>
        )
          }
      </label>
    </div>
  );
};

Selectbox.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  fieldLabel: PropTypes.string,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  fieldRequired: PropTypes.bool,
  fieldDisabled: PropTypes.bool,
  fieldNoClear: PropTypes.bool,
  noResultsText: PropTypes.string,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  loadOptions: PropTypes.any,
  selectedValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number]),
  meta: PropTypes.object,
  searchable: PropTypes.bool,
  translate: PropTypes.bool,
  labelClass: PropTypes.string,
  intl: PropTypes.object,
  simpleValue: PropTypes.bool,
  initialValue: PropTypes.object,
};

Selectbox.defaultProps = {
  searchable: true,
  placeholder: 'global_form.select.placeholder',
  translate: true,
  simpleValue: true,
};

export default injectIntl(Selectbox);
