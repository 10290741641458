import { takeLatest, call, put } from 'redux-saga/effects';

import {
  EDIT_NEWS_START,
  EDIT_NEWS_ERROR,
  EDIT_NEWS_SUCCESS,
} from '@src/js/actions/news/editNews';

import * as api from '@src/js/api/news/editNews';

function* editNews(options) {
  try {
    const data = yield call(() => api.editNews(options.data.values, options.data.slug));
    yield put({ type: EDIT_NEWS_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: EDIT_NEWS_ERROR, error });
    options.data.reject(error);
  }
}

function* editNewsWatcher() {
  yield takeLatest(EDIT_NEWS_START, editNews);
}

export default [
  editNewsWatcher(),
];
