import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getOpportunityLocations() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/countries?hierarchy=true`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function getOpportunitySectors() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/sectors`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function getOpportunityType() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/user/opportunities-type`, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function getOpportunityCoverImage(image) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_URL') }/file/upload`, {
    method: 'POST',
    externalUrl: true,
    body: image.image,
    noContentType: true,
  });
}

export function getOpportunityCompany(filterOptions) {
  const url = `${ loadEnvVariable('MARKETPLACE_URL') }/user/publishers`
      + `?all=${ filterOptions.filterOptions.values.all }`
      + `&page=${ filterOptions.filterOptions.values.page }&search=${ filterOptions.filterOptions.values.search }`;

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function getOpportunityPublishers(filters) {
  let url = `${ loadEnvVariable('MARKETPLACE_URL') }/user/publishers`;
  if (filters) {
    const query = new URLSearchParams(filters);
    url = `${ url }?${ query }`;
  }
  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function getOpportunities(filters) {
  let url = `${ loadEnvVariable('MARKETPLACE_URL') }/user/products`;
  if (filters) {
    const query = new URLSearchParams(filters, { arrayFormat: 'comma' });
    url = `${ url }?${ query }`;
  }
  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getOpportunityTags(tagType, advisorTypes) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/tag?type=${ tagType }`;
  if (advisorTypes) {
    const query = new URLSearchParams({ type: tagType, advisorTypes });
    url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/tag?${ query }`;
  }

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getOpportunityAdvisorType() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/advisors`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getOpportunityData(opportunityId) {
  const url = `${ loadEnvVariable('MARKETPLACE_URL') }/user/`
    + `product/${ opportunityId.opportunityId }?disabledVisit=true`;

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function saveOpportunityData(opportunityData) {
  const url = `${ loadEnvVariable('MARKETPLACE_URL') }/user/product`;

  return fetchResource(url, {
    method: 'POST',
    body: opportunityData,
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function editOpportunityData(opportunityData) {
  const url = `${ loadEnvVariable('MARKETPLACE_URL') }/user/product`;

  return fetchResource(url, {
    method: 'PUT',
    body: opportunityData,
    externalUrl: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}
