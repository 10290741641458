import arrayMutators from 'final-form-arrays';
import ClassNames from 'classnames';
import { get as lodashGet } from 'lodash';
import { copyObject, getLanguages } from '@src/js/helpers/helpers';
import { format } from 'date-fns';
import { showToastrSuccess } from '@app/store/global/actions';
import { editCompany } from '@src/js/actions/company/company';
import { adminCreateCompany } from '@src/js/actions/company/adminCompany';
import { CertificateTypes, Company } from '@src/js/constants/entities';
import inputValidation from '@src/js/helpers/InputValidation';
import * as CommonService from './CommonService';

const DEFAULT_LANGUAGE = 'en';

const filterEmptyLanguages = (Component, companyFormData) => {
  const languages = getLanguages();

  if (!companyFormData.details || Object.keys(companyFormData.details).length === 0) {
    return companyFormData;
  }

  languages.forEach((language) => {
    if (language === DEFAULT_LANGUAGE) {
      return;
    }

    if (Object.prototype.hasOwnProperty.call(companyFormData.details, language)) {
      if (companyFormData.details[language]) {
        const stringified = JSON.stringify(companyFormData.details[language], (key, value) => {
          if (value !== null) return value;
          return undefined;
        });
        if (!(stringified && JSON.parse(stringified) && Object.keys(JSON.parse(stringified)).length > 0)) {
          delete companyFormData.details[language]; // eslint-disable-line no-param-reassign
        }
      } else {
        delete companyFormData.details[language]; // eslint-disable-line no-param-reassign
      }
    }
  });

  return companyFormData;
};

const handleFormData = (Component, company) => {
  const {
    dateMemberSince, dateIncorporation, checkedSalesArea, checkedOfficeLocation,
  } = Component.state;
  const companyData = copyObject(company);
  if (!companyData.basic) {
    companyData.basic = {};
  }
  companyData.dates = {
    eliteMemberSince: null,
    dateIncorporation: null,
  };

  if (dateMemberSince) {
    companyData.dates.eliteMemberSince = format(dateMemberSince, 'yyyy/MM/dd');
  }
  if (dateIncorporation) {
    companyData.dates.dateIncorporation = format(dateIncorporation, 'yyyy/MM/dd');
  }

  companyData.locations = {
    salesArea: checkedSalesArea,
    officeLocation: checkedOfficeLocation,
  };

  if (
    companyData.testimonial
    && companyData.testimonial.avatar
    && typeof companyData.testimonial.avatar === 'string'
  ) {
    delete companyData.testimonial.avatar;
  }

  if (companyData.basic.dateIncorporation) {
    delete companyData.basic.dateIncorporation;
  }

  if (companyData.basic.dateMemberSince) {
    delete companyData.basic.dateMemberSince;
  }

  if (companyData.basic.sectors) {
    companyData.basic.sector = companyData.basic.sectors[3]?.id || companyData.basic.sectors[2]?.id || null;
    companyData.basic.secondLevelSector = companyData.basic.sectors[1]?.id || companyData.basic.sectors[0]?.id || null;
  }
  CertificateTypes.forEach((type) => {
    if (companyData.certificates?.[type]) {
      companyData.certificates[type] = companyData.certificates[type].id;
    }
  });
  return filterEmptyLanguages(Component, companyData);
};

export const handleSubmit = async (Component, company) => {
  const {
    dispatch, companySlug, userType,
  } = Component.props;
  const { formType } = Component.state;
  const filteredCompanyData = handleFormData(Component, company);

  if (filteredCompanyData && formType === 'edit') {
    filteredCompanyData.slug = companySlug;
    return new Promise((resolve, reject) => {
      dispatch(editCompany({
        values: filteredCompanyData,
        resolve,
        reject,
      }, userType));
    }).then((data) => {
      CommonService.setRedirect(Component, false, data.slug);
      dispatch(showToastrSuccess('notification.success_title', 'notification.company_form.edit.success_message'));
      return data;
    }).catch(errors => errors);
  }

  return new Promise((resolve, reject) => {
    dispatch(adminCreateCompany({
      values: filteredCompanyData,
      resolve,
      reject,
    }, userType));
  }).then(() => {
    CommonService.setRedirect(Component);
    dispatch(showToastrSuccess('notification.success_title', 'notification.company_form.create.success_message'));
    return null;
  }).catch(errors => errors);
};

const addLanguageValidations = (err, details) => {
  const languages = getLanguages();
  const errors = { ...err, details: {} };

  languages.forEach((language) => {
    if (!details && language !== DEFAULT_LANGUAGE) {
      return;
    }
    const stringified = details && JSON.stringify(details[language], (key, value) => {
      if (value !== null) return value;
      return undefined;
    });

    if (
      language === DEFAULT_LANGUAGE
      || (
        details
        && details[language]
        && stringified
        && JSON.parse(stringified)
        && Object.keys(JSON.parse(stringified)).length > 0
      )
    ) {
      const languageDetails = (details && details[language]) ? details[language] : {};

      if (!languageDetails.description) {
        errors.details = {
          ...errors.details,
          [language]: {
            description: inputValidation.requiredEditorValidator(null),
          },
        };
      }
    }
  });

  if (Object.keys(errors.details).length === 0) {
    delete errors.details;
  }

  return errors;
};

export const validate = (Component, values) => {
  const errors = {
    basic: {},
  };

  if (values.basic) {
    const isGrowth = (values.basic.type === Company.TYPE_GROWTH);
    const isStartup = (values.basic.type === Company.TYPE_GROWTH_STARTUP);
    const isEtinerary = (values.basic.type === Company.TYPE_ETINERARY);
    errors.basic.name = inputValidation.requiredValidator(values.basic.name);
    errors.basic.type = inputValidation.requiredValidator(values.basic.type);

    if (isGrowth) {
      errors.basic.programme = inputValidation.requiredValidator(values.basic.programme);
      errors.basic.dateMemberSince = inputValidation.requiredValidator(values.basic.dateMemberSince);
    }

    if (isGrowth || isEtinerary || isStartup) {
      if (!values.basic.secondLevelSector && !values.basic.sector
        && values.basic.sectors?.filter(v => v !== null && v !== undefined).length < 2) {
        errors.basic.sector = 'global_form.error.required';
      }

      errors.basic.turnover = inputValidation.requiredValidator(values.basic.turnover);
      errors.basic.turnoverCurrency = inputValidation.requiredValidator(values.basic.turnoverCurrency);
    }

    errors.basic.visibility = inputValidation.requiredValidator(values.basic.visibility);
    errors.basic.website = inputValidation.urlValidator(values.basic.website);
    errors.basic.auditorWebsite = inputValidation.urlValidator(values.basic.auditorWebsite);
    if (isEtinerary) {
      errors.basic.etineraryCompanyType = inputValidation.requiredValidator(values.basic.etineraryCompanyType);
      errors.basic.employeeNumber = inputValidation.requiredValidator(values.basic.employeeNumber);
      errors.basic.vatNumber = inputValidation.requiredValidator(values.basic.vatNumber);
    }
  }

  return addLanguageValidations(errors, values.details);
};

export const formMutators = () => ({
  ...arrayMutators,
  setLogo: (args, state, utils) => {
    utils.changeValue(state, 'basic.logo', () => args[0]);
  },
  setVisibility: (args, state, utils) => {
    utils.changeValue(state, 'basic.visibility', () => args[0]);
  },
  setSlide: (args, state, utils) => {
    utils.changeValue(state, `slides.${ args[0] }.${ args[1] }.${ args[2] }`, () => args[3]);
  },
  newSlide: (args, state, utils) => {
    utils.changeValue(state, `slides.${ args[0] }`, () => args[1]);
  },
  setSector: (args, state, utils) => {
    utils.changeValue(state, 'basic.sector', () => args[0]);
  },
  setSecondLevelSector: (args, state, utils) => {
    utils.changeValue(state, 'basic.secondLevelSector', () => args[0]);
  },
  setBasicDateMemberSince: (args, state, utils) => {
    utils.changeValue(state, 'basic.dateMemberSince', () => args[0]);
  },
  setBasicType: (args, state, utils) => {
    utils.changeValue(state, 'basic.type', () => args[0]);
  },
  getBasicProgramme: (args, state) => lodashGet(state, 'formState.values.basic.programme'),
  setBasicProgramme: (args, state, utils) => {
    utils.changeValue(state, 'basic.programme', () => args[0]);
  },
  setLounge: (args, state, utils) => {
    utils.changeValue(state, 'basic.lounges', () => args[0]);
  },
  setTestimonialAvatar: (args, state, utils) => {
    utils.changeValue(state, 'testimonial.avatar', () => args[0]);
  },
  setTurnover: (args, state, utils) => {
    utils.changeValue(state, 'basic.turnover', () => args[0]);
  },
  setTurnoverCurrency: (args, state, utils) => {
    utils.changeValue(state, 'basic.turnoverCurrency', () => args[0]);
  },
  setCompassType: (args, state, utils) => {
    utils.changeValue(state, 'basic.compass', () => args[0]);
  },
  setPaying: (args, state, utils) => {
    utils.changeValue(state, 'basic.paying', () => args[0]);
  },
  setMembership: (args, state, utils) => {
    utils.changeValue(state, 'basic.membershipStatus', () => args[0]);
  },
  getCompassType: (args, state) => lodashGet(state, 'formState.values.basic.compass'),
  setEtineraryCompanyType: (args, state, utils) => {
    utils.changeValue(state, 'basic.etineraryCompanyType', () => args[0]);
  },
  setTags: (args, state, utils) => {
    utils.changeValue(state, `details.${ args[1] }.tags`, () => args[0]);
  },
  setCountry: (args, state, utils) => {
    utils.changeValue(state, 'basic.country', () => args[0]);
    utils.changeValue(state, 'basic.province', () => '');
    utils.changeValue(state, 'basic.provinceId', () => null);
    utils.changeValue(state, 'basic.region', () => null);
  },
  setTranslatedDescription: (args, state, utils) => {
    utils.changeValue(state, 'details.en.description', () => args[0]);
  },
});

/**
 * language tab has errors if:
 *  - language details have errors
 *  - language is english and basic fields have errors
 */
const tabHasError = (form, languageCode) => {
  const formStateErrors = form.getState().errors;

  return form.getState().dirty && form.getState().submitFailed && (
    (formStateErrors && formStateErrors.details && formStateErrors.details[languageCode])
    || (languageCode === DEFAULT_LANGUAGE
      && JSON.stringify(form.getState().errors.basic, (key, value) => {
        if (value !== null) return value;
        return undefined;
      }) !== '{}'
    )
  );
};

export const getTabClasses = (form, languageCode, values) => {
  const isUsed = !!(values && values.details && values.details[languageCode]);
  return ClassNames('react-tabs__tab', {
    'react-tabs__tab--filled': isUsed,
    'react-tabs__tab--has-errors': tabHasError(form, languageCode),
  });
};
