const scope = 'PROMOTED_CONTENT';

export const GET_PROMOTED_LIST_START = `${ scope }/GET_PROMOTED_LIST_START`;
export const GET_PROMOTED_LIST_FAIL = `${ scope }/GET_PROMOTED_LIST_FAIL`;
export const GET_PROMOTED_LIST_SUCCESS = `${ scope }/GET_PROMOTED_LIST_SUCCESS`;

export const GET_PROMOTED_OPTIONS_START = `${ scope }/GET_PROMOTED_OPTIONS_START`;
export const GET_PROMOTED_OPTIONS_FAIL = `${ scope }/GET_PROMOTED_OPTIONS_FAIL`;
export const GET_PROMOTED_OPTIONS_SUCCESS = `${ scope }/GET_PROMOTED_OPTIONS_SUCCESS`;

export const POST_PROMOTED_CONTENT_START = `${ scope }/POST_PROMOTED_CONTENT_START`;
export const POST_PROMOTED_CONTENT_FAIL = `${ scope }/POST_PROMOTED_CONTENT_FAIL`;
export const POST_PROMOTED_CONTENT_SUCCESS = `${ scope }/POST_PROMOTED_CONTENT_SUCCESS`;

export const DELETE_PROMOTED_CONTENT_START = `${ scope }/DELETE_PROMOTED_CONTENT_START`;
export const DELETE_PROMOTED_CONTENT_FAIL = `${ scope }/DELETE_PROMOTED_CONTENT_FAIL`;
export const DELETE_PROMOTED_CONTENT_SUCCESS = `${ scope }/DELETE_PROMOTED_CONTENT_SUCCESS`;

export const GET_PROMOTED_CONTENT_START = `${ scope }/GET_PROMOTED_CONTENT_START`;
export const GET_PROMOTED_CONTENT_FAIL = `${ scope }/GET_PROMOTED_CONTENT_FAIL`;
export const GET_PROMOTED_CONTENT_SUCCESS = `${ scope }/GET_PROMOTED_CONTENT_SUCCESS`;

export const SET_OPPORTUNITY_REQUESTED = `${ scope }/SET_OPPORTUNITY_REQUESTED`;

export function getPromotedList() {
  return {
    type: GET_PROMOTED_LIST_START,
  };
}
export function getPromotedOptions() {
  return {
    type: GET_PROMOTED_OPTIONS_START,
  };
}
export function postPromotedContent(data) {
  return {
    type: POST_PROMOTED_CONTENT_START,
    data,
  };
}
export function deletePromotedContent(id) {
  return {
    type: DELETE_PROMOTED_CONTENT_START,
    id,
  };
}

export function getPromotedContent() {
  return {
    type: GET_PROMOTED_CONTENT_START,
  };
}
