import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { Button } from '@src/js/components/global';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { purifyHtml } from '@src/js/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { ModalBody, ModalFooter } from 'reactstrap';

type Props = {
  message?: string,
  action: () => void,
  cancelAction?: () => void,
  loading?: boolean,
  acceptText?: string,
  cancelText?: string,
  hideCancelBtn?: boolean,
  className?: string,
}

const ConfirmationModal = ({
  message, action, loading, cancelAction, acceptText, cancelText, hideCancelBtn, className = '',
}: Props) => {
  const intl = useIntl();
  const { hideModal } = useGlobalModalContext();

  const cancel = () => {
    if (cancelAction) cancelAction();
    hideModal();
  };

  const submit = () => {
    if (action) action();
    hideModal();
  };

  return (
    <>
      { loading && <Loader /> }
      { message && (
      <ModalBody>
        <p
          className={ `text-center m-t-1 blue-a ${ className }` }
          dangerouslySetInnerHTML={ {
            __html: purifyHtml(
              intl.formatMessage({ id: message, defaultMessage: message })),
          } }
        />
      </ModalBody>
      ) }
      <ModalFooter>
        <Button
          size='lg'
          buttonText={ acceptText || 'yes' }
          onClickFunc={ submit }
        />
        { !hideCancelBtn && (
          <Button
            size='lg'
            buttonText={ cancelText || 'cancel' }
            emptyButtonType='empty-grey'
            onClickFunc={ cancel }
          />
        ) }
      </ModalFooter>
    </>
  );
};

export default ConfirmationModal;
