import { ChatStyles } from '@app/models/Chat';
import { loadMessages } from '@app/store/chat/chatApi';
import {
  selectCurrentThread, selectHasMoreMessages, selectMessagesList, selectMessagesLoading,
  selectSocketMessage,
} from '@app/store/chat/chatSlice';
import EliteLoader from '@src/js/components/global/pageLoader/EliteLoader';
import useMessagesList from '@src/js/layouts/global/panels/messaging/components/useMessagesList';
import { useAppDispatch } from '@src/js/store';
import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

type Props = {
  styles?: ChatStyles,
}

const ChatMessages = ({ styles }: Props) => {
  const dispatch = useAppDispatch();
  const thread = useSelector(selectCurrentThread);
  const messages = useSelector(selectMessagesList);
  const hasMore = useSelector(selectHasMoreMessages);
  const loading = useSelector(selectMessagesLoading);
  const parentDiv = useRef<HTMLDivElement>(null);
  const renderMessages = useMessagesList({ thread, messages });
  const socketMessage = useSelector(selectSocketMessage);

  const next = () => {
    if (loading || !thread?.id || !messages.length) return;
    dispatch(loadMessages({ threadId: thread?.id, offset: messages[0].id }));
  };

  useEffect(() => {
    if (parentDiv.current) {
      parentDiv.current.scrollTo({
        top: 99999,
        behavior: 'smooth',
      });
    }
  }, [socketMessage]);

  return (
    <>
      <div
        className={ styles?.content ? styles.content : 'messages-panel__content' }
        id='messageBox'
      >
        <div
          id='messagesContainer'
          ref={ parentDiv }
        >
          <InfiniteScroll
            dataLength={ renderMessages.length }
            next={ next }
            hasMore={ hasMore }
            loader={ null }
            scrollableTarget='messagesContainer'
            inverse
          >
            {renderMessages}
          </InfiniteScroll>
        </div>
      </div>
      {loading && (
      <div className='message-loader'>
        <EliteLoader />
      </div>
      )}
    </>
  );
};

export default ChatMessages;
