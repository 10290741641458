import {
  ONBOARDING_INIT_START,
  ONBOARDING_INIT_ERROR,
  ONBOARDING_INIT_SUCCESS,
  LOGIN_ONBOARDING_USER_ERROR,
  LOGIN_ONBOARDING_USER_START,
  LOGIN_ONBOARDING_USER_SUCCESS,
  LOGOUT_ONBOARDING_USER_ERROR,
  LOGOUT_ONBOARDING_USER_START,
  LOGOUT_ONBOARDING_USER_SUCCESS,
} from '@src/js/actions/onboarding/onboardingBasic';

const initialState = {
  loginLoading: false,
  loginError: null,
  onboardingUser: null,
  logoutLoading: false,
  logoutError: null,
};

const actionsMap = {
  [ONBOARDING_INIT_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ONBOARDING_INIT_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [ONBOARDING_INIT_SUCCESS]: (state, action) => ({
    ...state,
    onboardingUser: action.data.user,
    loading: false,
    error: null,
  }),
  [LOGIN_ONBOARDING_USER_START]: (state) => ({
    ...state,
    loginLoading: true,
    loginError: null,
  }),
  [LOGIN_ONBOARDING_USER_ERROR]: (state, action) => ({
    ...state,
    loginLoading: false,
    loginError: action.error?.response?.message,
  }),
  [LOGIN_ONBOARDING_USER_SUCCESS]: (state, action) => {
    let reCaptcha = false;
    if (action.data.reCaptcha !== undefined) {
      reCaptcha = action.data.reCaptcha;
    }
    return {
      ...state,
      loginLoading: false,
      loginError: null,
      onboardingUser: action.data,
      loginReCaptcha: reCaptcha,
    };
  },
  [LOGOUT_ONBOARDING_USER_START]: (state) => ({
    ...state,
    logoutLoading: true,
    logoutError: null,
  }),
  [LOGOUT_ONBOARDING_USER_ERROR]: (state, action) => ({
    ...state,
    logoutLoading: false,
    logoutError: action.error?.response?.message,
  }),
  [LOGOUT_ONBOARDING_USER_SUCCESS]: (state, action) => ({
    ...state,
    logoutLoading: false,
    logoutError: null,
    onboardingUser: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
