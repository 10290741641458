import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { purifyHtml } from '@src/js/utils';
import { messages } from '@app/components/public/etinerary/Modal/constant';
import { Images } from '@src/js/constants/images';

const DesktopContentTab = ({ tab, intl }) => {
  const message = messages(tab);

  return (
    <div className='etinerary-modal-desktop'>
      <div className='content-tab'>
        <div className='content-tab-container-image'>
          <img
            className='content-tab-image'
            src={ Images[`ETINERARY_${ tab.toUpperCase() }`] }
            alt={ `elite-${ tab }` }
          />
        </div>
        <div className='content-tab-body'>
          <div className='content-tab-title'>
            <FormattedMessage id={ message.title } />
          </div>
          <div className='content-tab-description'>
            {intl.formatMessage({ id: message.description }, {
              span: chunks => purifyHtml(`<span>${ chunks.join('') }</span>`),
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

DesktopContentTab.propTypes = {
  tab: PropTypes.string.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(DesktopContentTab);
