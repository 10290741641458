import fetchResource from '@src/js/api/fetch-resource';

export function getIndexProcesses(filters) {
  let url = 'api/admin/onboarding/processes';

  url += filters ? `?${ new URLSearchParams(filters) }` : '';

  return fetchResource(url);
}

export function getAdminProcess(hash) {
  return fetchResource(`api/admin/onboarding/process/${ hash }`);
}

export function adminUpdateProcess(data, hash) {
  return fetchResource(`api/admin/onboarding/process/${ hash }/save`, {
    method: 'PATCH',
    body: {
      ...data,
    },
  });
}

export function adminSubmitProcess(data, hash) {
  return fetchResource(`api/admin/onboarding/process/${ hash }/submit`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

export function adminCreateCompany(hash, crmId) {
  return fetchResource(`api/admin/onboarding/finalize/${ hash }${
    crmId
      ? `?${ new URLSearchParams({ crmId }) }`
      : ''
  }`, {
    method: 'POST',
  });
}

export function adminDeleteCompany(hash) {
  return fetchResource(`api/admin/onboarding/process/${ hash }`, {
    method: 'DELETE',
  });
}
