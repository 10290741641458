export type Pagination = {
  page: number,
  pageCount: number,
  perPage: number,
  totalCount: number,
}
export type PaginatedResponse<T> = {
  results: T[],
  pagination: Pagination
}

export function isPaginatedResponse<T>(resp: any): resp is PaginatedResponse<T> {
  return resp.pagination !== undefined;
}
