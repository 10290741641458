const scope = 'Etinerary';

const GET_TEMPLATE_REQUEST = `${ scope }/GET_TEMPLATE_REQUEST`;
const GET_TEMPLATE_SUCCESS = `${ scope }/GET_TEMPLATE_SUCCESS`;
const GET_TEMPLATE_FAIL = `${ scope }/GET_TEMPLATE_FAIL`;

const SEND_TEMPLATE_REQUEST = `${ scope }/SEND_TEMPLATE_REQUEST`;
const SEND_TEMPLATE_SUCCESS = `${ scope }/SEND_TEMPLATE_SUCCESS`;
const SEND_TEMPLATE_FAIL = `${ scope }/SEND_TEMPLATE_FAIL`;

const DOWNLOAD_TEMPLATE_REQUEST = `${ scope }/DOWNLOAD_TEMPLATE_REQUEST`;
const DOWNLOAD_TEMPLATE_SUCCESS = `${ scope }/DOWNLOAD_TEMPLATE_SUCCESS`;
const DOWNLOAD_TEMPLATE_FAIL = `${ scope }/DOWNLOAD_TEMPLATE_FAIL`;

const CREATE_NEW_LEGAL_REQUEST = `${ scope }/CREATE_NEW_LEGAL_REQUEST`;
const CREATE_NEW_LEGAL_SUCCESS = `${ scope }/CREATE_NEW_LEGAL_SUCCESS`;
const CREATE_NEW_LEGAL_FAIL = `${ scope }/CREATE_NEW_LEGAL_FAIL`;

const LINK_DOWNLOAD = '/api/etinerary/download/terms';

const SEND_CONTACT_REQUEST = `${ scope }/SEND_CONTACT_REQUEST`;
const SEND_CONTACT_SUCCESS = `${ scope }/SEND_CONTACT_SUCCESS`;
const SEND_CONTACT_FAIL = `${ scope }/SEND_CONTACT_FAIL`;

export {
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAIL,
  SEND_TEMPLATE_REQUEST,
  SEND_TEMPLATE_SUCCESS,
  SEND_TEMPLATE_FAIL,
  DOWNLOAD_TEMPLATE_REQUEST,
  DOWNLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_TEMPLATE_FAIL,
  CREATE_NEW_LEGAL_REQUEST,
  CREATE_NEW_LEGAL_SUCCESS,
  CREATE_NEW_LEGAL_FAIL,
  LINK_DOWNLOAD,
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL,
};
