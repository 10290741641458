import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import FileToUpload from '@app/models/FileToUpload';
import { showToastrError } from '@app/store/global/actions';
import { Button } from '@src/js/components/global';
import { ectPath } from '@src/js/constants/routes';
import { formatBytes } from '@src/js/utils';
import React from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  title: string,
  maxSize: number,
  acceptedExtensionsLabel: string,
  acceptedExtensions: Accept,
  parentId: number,
  parentType: string,
  entity: string,
  onClose: () => void,
  dataRoomType: string,
  companySlug: string,
  notification: string,
}

const UploadMultipleDocumentModal = ({
  title, maxSize, acceptedExtensionsLabel,
  acceptedExtensions, entity, parentId = 0, parentType, onClose,
  dataRoomType, companySlug, notification,
}: Props) => {
  const dispatch = useDispatch();
  const { showModal } = useGlobalModalContext();
  const uploadUrl = `${ ectPath() }/api/media/`;

  const onFileChange = (files: File[]) => {
    if (!files || files.length === 0) return;

    const filesToUpload = files.map(file => {
      const uuid = uuidv4();
      const params = {
        file,
        uploadUrl: uploadUrl + uuid,
        entity,
        parentType,
        parentId,
        dataRoomType,
        companySlug,
        notification,
      };
      return new FileToUpload(params);
    });
    showModal({
      type: ModalTypes.MultipleDocumentUpload,
      title,
      config: { size: 'md', className: 'multiple-uploading-modal' },
      data: { title, onCloseAction: onClose, filesToUpload },
      hideHeader: true,
      replace: true,
    });
  };

  return (
    <ModalBody>
      <div
        className='multi-upload-modal__wrapper'
      >
        <Dropzone
          accept={ acceptedExtensions }
          maxSize={ maxSize }
          onDrop={ onFileChange }
          onDropRejected={ () => dispatch(showToastrError()) }
          multiple={ true }
          useFsAccessApi={ false }
        >
          {({ getRootProps, getInputProps }) => (
            <div { ...getRootProps() }>
              <input { ...getInputProps() } />
              { title }
              <span className='icomoon-cloud-upload' />
              <p className='multi-upload-modal__drag-and-drop'>
                <FormattedMessage id='multiple_upload.dragAndDrop' />
              </p>
              <p className='multi-upload-modal__drag-and-drop'>
                <FormattedMessage id='multiple_upload.or' />
              </p>
              <span className='upload-card__placeholder-button'>
                <Button buttonText='upload_browse' />
              </span>
              {maxSize && (
              <p className='multi-upload-modal__upload-parameters m-b-0'>
                <FormattedMessage id='multiple_upload.maxSize' />
                { formatBytes(maxSize) }
              </p>
              )}
              {acceptedExtensionsLabel && (
              <p className='multi-upload-modal__upload-parameters'>
                <FormattedMessage id='multiple_upload.accepted' />
                { acceptedExtensionsLabel }
              </p>
              )}
            </div>
          )}
        </Dropzone>
      </div>
    </ModalBody>
  );
};

export default UploadMultipleDocumentModal;
