import React from 'react';
import { ON_SAVE_ONBOARDING } from '@app/store/onboarding/actions';
import { PARTNER_FORM_STEPS } from '@app/store/onboarding/constants';
import {
  selectCurrentStep, selectOnboardingSubmitted, selectValidation,
} from '@app/store/onboarding/selectors';
import { Button } from '@src/js/components/global';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';

const Footer = ({ steps }) => {
  const dispatch = useDispatch();
  const submitted = selectOnboardingSubmitted();
  const currentStep = selectCurrentStep();
  const validation = selectValidation();
  const admin = isAdminRole() || isPra();

  const stepIndex = steps.findIndex(s => s === currentStep);

  const submitDisabled = validation && (Object.values(validation).findIndex(v => v === false) >= 0 || submitted);

  const stepBack = () => {
    if (stepIndex === 0) {
      return;
    }
    dispatch({
      type: ON_SAVE_ONBOARDING, step: currentStep, action: 'stepChange', newStep: steps[stepIndex - 1],
    });
  };
  const stepNext = () => {
    if (stepIndex === steps.length - 1) {
      return;
    }
    dispatch({
      type: ON_SAVE_ONBOARDING, step: currentStep, action: 'stepChange', newStep: steps[stepIndex + 1],
    });
  };

  const submit = () => {
    if (submitDisabled) {
      return;
    }
    dispatch({
      type: ON_SAVE_ONBOARDING, step: currentStep, action: 'submit', newStep: null,
    });
  };
  const save = () => {
    dispatch({
      type: ON_SAVE_ONBOARDING, step: currentStep, action: 'save', newStep: null,
    });
  };

  return (
    <>
      <div className='compliance__buttons-group elite-white-box--form'>
        <div className='compliance__buttons-pair'>
          <Button buttonText='save' onClickFunc={ save } />
        </div>
        <div className='compliance__buttons-pair'>
          <Button
            buttonText='back'
            additionalClass={ `${ !admin && currentStep === steps[1] ? 'eop-btn-hidden' : 'eop-btn-inverted' }` }
            onClickFunc={ stepBack }
          />
          { currentStep === PARTNER_FORM_STEPS.FINAL_SECTION
            ? (
              <Button
                additionalClass={ submitDisabled ? 'eop-btn opacity-5' : 'eop-btn' }
                onClickFunc={ submit }
                buttonText='main.submit'
                disabled={ submitDisabled }
              />
            )
            : (
              <Button
                buttonText='next'
                onClickFunc={ stepNext }
              />
            )
          }
        </div>
      </div>
      <EmailSupport bottom />
    </>
  );
};

Footer.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default Footer;
