import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSection, InputSelect, InputText } from '@app/components/global/forms';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import FormattedMessageHTML from '@app/components/global/FormattedMessageHTML';
import { YES_OR_NO } from '@app/components/public/compliance/constants';
import {
  selectOnboardingData, selectOnboardingSave, selectCurrentStep, selectFirstVisit,
} from '@app/store/onboarding/selectors';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';

const Declarations = ({ handleSave, isPartner }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const {
    register, control, formState: { errors }, getValues, watch, trigger,
  } = useForm({
    defaultValues: isPartner ? onboardingData : onboardingData?.company?.declarations,
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      if (isPartner) {
        handleSave(getValues());
      } else {
        handleSave({ company: { ...onboardingData?.company, declarations: getValues() } });
      }
    }
  }, [onSave]);

  const declarations = watch();
  const showEventDisclose = declarations && (declarations.criminalOffence
      || declarations.criminalOffense
      || declarations.adverseFinding
      || declarations.relevantParty
      || declarations.bankrupt
      || declarations.criminalProceedings
      || declarations.refusedTerminated);

  return (
    <form>
      <FormSection sectionTitle='onboarding.declarations.declarations'>
        <EmailSupport />
        <FormattedMessageHTML
          id='onboarding.declarations.description'
          className='compliance__description w-100'
        />
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='onboarding.declarations.criminalOffense' />
          </p>
          <InputSelect
            name={ isPartner ? 'criminalOffence' : 'criminalOffense' }
            errors={ errors }
            control={ control }
            options={ YES_OR_NO }
            className='small-select'
            fieldNoClear
          />
        </div>
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='onboarding.declarations.adverseFinding' />
          </p>
          <InputSelect
            name='adverseFinding'
            errors={ errors }
            control={ control }
            options={ YES_OR_NO }
            className='small-select'
            fieldNoClear
          />
        </div>
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='onboarding.declarations.relevantParty' />
          </p>
          <InputSelect
            name='relevantParty'
            errors={ errors }
            control={ control }
            options={ YES_OR_NO }
            className='small-select'
            fieldNoClear
          />
        </div>
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='onboarding.declarations.bankrupt' />
          </p>
          <InputSelect
            name='bankrupt'
            errors={ errors }
            control={ control }
            options={ YES_OR_NO }
            className='small-select'
            fieldNoClear
          />
        </div>
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='onboarding.declarations.criminalProceedings' />
          </p>
          <InputSelect
            name='criminalProceedings'
            errors={ errors }
            control={ control }
            options={ YES_OR_NO }
            className='small-select'
            fieldNoClear
          />
        </div>
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='onboarding.declarations.refusedTerminated' />
          </p>
          <InputSelect
            name='refusedTerminated'
            errors={ errors }
            control={ control }
            options={ YES_OR_NO }
            className='small-select'
            fieldNoClear
          />
        </div>
        { showEventDisclose && (
        <div className='w-100'>
          <p className='compliance__question required'>
            <FormattedMessage id='compliance.form.declarations.eventDisclose' />
          </p>
          <InputText
            name='content'
            errors={ errors }
            register={ register }
            required
          />
        </div>
        )}
      </FormSection>
    </form>
  );
};

Declarations.propTypes = {
  handleSave: PropTypes.func,
  isPartner: PropTypes.bool,
};

export default Declarations;
