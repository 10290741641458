import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EM_USER_INFO_START,
  GET_EM_USER_INFO_ERROR,
  GET_EM_USER_INFO_SUCCESS,
} from '@src/js/marketplace/actions/getUserInfo';
import * as api from '@src/js/marketplace/api/getUserInfo';

function* getUserInfo() {
  try {
    const data = yield call(() => api.getUserInfo());
    yield put({ type: GET_EM_USER_INFO_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_EM_USER_INFO_ERROR, error });
  }
}

function* getUserInfoWatcher() {
  yield takeLatest(GET_EM_USER_INFO_START, getUserInfo);
}

export default [
  getUserInfoWatcher(),
];
