import React from 'react';
import { Field } from 'react-final-form';

import { TextInput } from '@src/js/components/global/inputs';

const FieldsNewLegal = () => (
  <div className='container-fields-new-legal-form'>
    <Field
      additionalClass='m-t-2'
      name='name'
      fieldName='name'
      fieldLabel='main.name'
      placeholder='main.name'
      fieldType='text'
      component={ TextInput }
      fieldRequired={ true }
      min={ 2 }
      maxLength={ 256 }
    />
    <Field
      additionalClass='m-t-2'
      name='surname'
      fieldName='surname'
      fieldLabel='main.surname'
      placeholder='main.surname'
      fieldType='text'
      component={ TextInput }
      fieldRequired={ true }
      min={ 2 }
      maxLength={ 256 }
    />
    <Field
      additionalClass='m-t-2'
      name='email'
      fieldName='email'
      fieldLabel='etinerary.upgradeModal.email'
      placeholder='etinerary.upgradeModal.email'
      fieldType='text'
      component={ TextInput }
      fieldRequired={ true }
      min={ 2 }
      maxLength={ 256 }
    />
    <Field
      additionalClass='m-t-2'
      name='jobTitle'
      fieldName='jobTitle'
      fieldLabel='main.role'
      placeholder='etinerary.upgradeModal.legalRepresentative'
      fieldType='text'
      component={ TextInput }
      fieldRequired={ true }
      min={ 2 }
      maxLength={ 256 }
    />
  </div>
);

export default FieldsNewLegal;
