export const GET_MANAGER_COMPANIES_START = 'GET_MANAGER_COMPANIES_START';
export const GET_MANAGER_COMPANIES_ERROR = 'GET_MANAGER_COMPANIES_ERROR';
export const GET_MANAGER_COMPANIES_SUCCESS = 'GET_MANAGER_COMPANIES_SUCCESS';

export function getManagerCompanies(filters) {
  return {
    type: GET_MANAGER_COMPANIES_START,
    filters,
  };
}
