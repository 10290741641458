import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_SINGLE_LIBRARY_START,
  GET_SINGLE_LIBRARY_ERROR,
  GET_SINGLE_LIBRARY_SUCCESS,
} from '@src/js/actions/library/singleLibrary';

import * as api from '@src/js/api/library/singleLibrary';

function* getSingleLibrary(options) {
  try {
    const data = yield call(() => api.getSingleLibrary(options.slug));
    yield put({ type: GET_SINGLE_LIBRARY_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SINGLE_LIBRARY_ERROR, error });
  }
}

function* getSingleLibraryWatcher() {
  yield takeLatest(GET_SINGLE_LIBRARY_START, getSingleLibrary);
}

export default [
  getSingleLibraryWatcher(),
];
