import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_BULK_MESSAGE_OPTIONS_START,
  GET_BULK_MESSAGE_OPTIONS_ERROR,
  GET_BULK_MESSAGE_OPTIONS_SUCCESS,
  GET_BULK_MESSAGE_USER_IDS_START,
  GET_BULK_MESSAGE_USER_IDS_ERROR,
  GET_BULK_MESSAGE_USER_IDS_SUCCESS,
  UPLOAD_BULK_FILE_START,
  UPLOAD_BULK_FILE_ERROR,
  UPLOAD_BULK_FILE_SUCCESS,
} from '@src/js/actions/bulkMessage/bulkMessageGetUsers';
import * as api from '@src/js/api/bulkMessage/bulkMessageGetUsers';

function* getBulkMessageUsers(filters) {
  try {
    const data = yield call(() => api.getBulkMessageUsers(filters.filters));
    yield put({ type: GET_BULK_MESSAGE_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_BULK_MESSAGE_OPTIONS_ERROR, error });
  }
}

function* getBulkMessageUserIds(filters) {
  try {
    const data = yield call(() => api.getBulkMessageUserIds(filters.filters));
    yield put({ type: GET_BULK_MESSAGE_USER_IDS_SUCCESS, data });
    filters.filters.resolve(data);
  } catch (error) {
    yield put({ type: GET_BULK_MESSAGE_USER_IDS_ERROR, error });
    filters.filters.reject(error);
  }
}

function* uploadBulkFile(options) {
  try {
    const data = yield call(() => api.uploadBulkFile(options.file.file));
    yield put({ type: UPLOAD_BULK_FILE_SUCCESS, data, options });
    options.file.resolve(data);
  } catch (error) {
    yield put({ type: UPLOAD_BULK_FILE_ERROR, error });
    options.file.reject(error.response);
  }
}

function* getBulkMessageUsersWatcher() {
  yield takeLatest(GET_BULK_MESSAGE_OPTIONS_START, getBulkMessageUsers);
}

function* getBulkMessageUserIdsWatcher() {
  yield takeLatest(GET_BULK_MESSAGE_USER_IDS_START, getBulkMessageUserIds);
}

function* uploadBulkFileWatcher() {
  yield takeLatest(UPLOAD_BULK_FILE_START, uploadBulkFile);
}

export default [
  getBulkMessageUsersWatcher(),
  getBulkMessageUserIdsWatcher(),
  uploadBulkFileWatcher(),
];
