import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import { showToastrError, showToastrSuccess } from '@app/store/global/actions/index';
import {
  COMPANY_CONTACT_MESSAGE_SEND_START,
  COMPANY_CONTACT_MESSAGE_SEND_ERROR,
  COMPANY_CONTACT_MESSAGE_SEND_SUCCESS,
} from '@src/js/actions/company/companyContactMessageSend';
import * as api from '@src/js/api/company/companyContactMessageSend';

function* companyContactMessageSend(options) {
  try {
    const data = yield call(() => api.companyContactMessageSend(options.data));
    yield all([
      put(showToastrSuccess('notification.success_title', 'company_form.modal.notification.messageSuccess')),
      put({ type: COMPANY_CONTACT_MESSAGE_SEND_SUCCESS, data }),
    ]);
  } catch (error) {
    yield all([
      put({ type: COMPANY_CONTACT_MESSAGE_SEND_ERROR, error }),
      showToastrError(),
    ]);
  }
}

function* companyContactMessageSendWatcher() {
  yield takeLatest(COMPANY_CONTACT_MESSAGE_SEND_START, companyContactMessageSend);
}

export default [
  companyContactMessageSendWatcher(),
];
