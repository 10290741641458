import BadgeEliteCertified from '@assets/img/svg/icons/Logo/Badge-Elite-Certified.svg';
import BadgeCertified from '@assets/img/svg/icons/Logo/Elite-Certified.png';
import EliteSmall from '@assets/img/logo/elite-small.png';
import EliteCertified from '@assets/img/logo/EliteCertified.png';
import UserAvatar from '@assets/img/logo/avatar.jpg';
import BackgroundCard from '@assets/img/background/bg_profile_card.jpg';
import BackgroundEventsCard from '@assets/img/background/seconda_cover.png';
import BackgroundFundingProcess from '@assets/img/background/76-acciaio.jpg';
import Coursera from '@assets/img/logo/coursera.png';
import OrderArrowBoth from '@assets/img/arrow/arrows.png';
import OrderArrowUp from '@assets/img/arrow/up.png';
import OrderArrowDown from '@assets/img/arrow/down.png';
import FundingWidget from '@assets/img/funding/funding.png';
import EtineraryFunding from '@assets/img/etinerary/funding.png';
import EtineraryLibrary from '@assets/img/etinerary/library.png';
import EtineraryNews from '@assets/img/etinerary/news.png';
import EtineraryContact from '@assets/img/etinerary/contact.jpg';
import Bg404 from '@assets/img/background/404_bg.jpg';
import DefaultLogo from '@assets/img/logo/default-logo.png';
import FluentArrowGrowth from '@assets/img/funding/fluent_arrow-growth.svg';
import FluentBalance from '@assets/img/funding/fluent_balance.svg';
import FluentNewspaper from '@assets/img/funding/fluent_newspaper.svg';
import Upload from '@assets/img/upload.png';
import EliteMedium from '@assets/img/logo/elite-medium.png';
import BannerLogo from '@assets/img/elite-logo-banner.png';
import PlayStore from '@assets/img/app-banner/play-store.svg';
import AppStore from '@assets/img/app-banner/app-store.svg';
import EliteLogo from '@assets/img/logo/elite-logo.png';
import PasswordForm from '@assets/img/background/password_form.png';
import attendance from '@assets/img/elite-certificates/attendance.png';
import finance from '@assets/img/elite-certificates/finance.png';
import governance from '@assets/img/elite-certificates/governance.png';
import sustainability from '@assets/img/elite-certificates/sustainability.png';
import growth from '@assets/img/matchmaking/growth_strategy.png';
import digital from '@assets/img/matchmaking/digital_compass.jpg';
import library from '@assets/img/matchmaking/library.png';
import equity from '@assets/img/funding/equity.png';
import debt from '@assets/img/funding/debt.png';
import IPO from '@assets/img/funding/IPO.png';
import TeaserHeading from '@assets/img/teaser-heading.png';
import SearchHeaderBg from '@assets/img/background/search_header_background.png';
import Model3D from '@assets/img/background/3d-model.png';
import DocImg from '@assets/img/logo/elite-doc-img.svg';

export const Images = {
  AppStore,
  BackgroundCard,
  BackgroundEventsCard,
  BackgroundFundingProcess,
  BadgeCertified,
  BadgeEliteCertified,
  BannerLogo,
  Bg404,
  Coursera,
  DefaultLogo,
  DocImg,
  ETINERARY_CONTACT: EtineraryContact,
  ETINERARY_FINANCIAL_ADVISORS: EtineraryNews,
  ETINERARY_FUNDING: EtineraryFunding,
  ETINERARY_LIBRARY: EtineraryLibrary,
  ETINERARY_NEWS: EtineraryNews,
  ETINERARY_SINGLE_TRANSACTIONS: EtineraryLibrary,
  EliteCertified,
  EliteLogo,
  EliteMedium,
  EliteSmall,
  FluentArrowGrowth,
  FluentBalance,
  FluentNewspaper,
  FundingWidget,
  Model3D,
  OrderArrowBoth,
  OrderArrowDown,
  OrderArrowUp,
  PasswordForm,
  PlayStore,
  SearchHeaderBg,
  TeaserHeading,
  Upload,
  UserAvatar,
  certificate: {
    attendance,
    finance,
    governance,
    sustainability,
  },
  matchmaking: {
    growth,
    digital,
    library,
  },
  funding: {
    equity,
    debt,
    IPO,
  },
};
