import fetchResource from '@src/js/api/fetch-resource';

export const getFormattedFilters = (filters) => {
  const newFilters = { ...filters };

  if (filters.companies?.length) {
    newFilters.companies = filters.companies.map(company => company.value).join();
  }

  if (filters.programmes?.length) {
    newFilters.programmes = filters.programmes.map(programme => programme.value).join();
  }
  return newFilters;
};

export const ADMIN_USERS_BASE = 'api/admin/users';

export function adminFilterParams(url, filters) {
  const newFilters = getFormattedFilters(filters);
  let responseUrl = url;

  if (newFilters) {
    responseUrl += `?${ new URLSearchParams(newFilters) }`;
  }

  return responseUrl.replaceAll('null', '');
}

export function adminGetUsers(filters) {
  return fetchResource(adminFilterParams(ADMIN_USERS_BASE, filters));
}

export function getUserFilterCompanyOptions(filterOptions) {
  const url = `api/admin/users/filter-company-options?page=${ filterOptions.page }`
    + `&query=${ filterOptions.query }`;

  return fetchResource(url, {
    method: 'GET',
  });
}
