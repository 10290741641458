import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { purifyHtml } from '@src/js/utils';

const FormattedMessageHTML = ({ id, className }) => (
  <FormattedMessage id={ id }>
    { htmlMessage => (
      <p className={ `${ className } blue-a` } dangerouslySetInnerHTML={ { __html: purifyHtml(htmlMessage) } } />
    ) }
  </FormattedMessage>
);

FormattedMessageHTML.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FormattedMessageHTML.defaultProps = {
  className: '',
};

export default FormattedMessageHTML;
