import {
  UPLOAD_ONBOARDING_FILE_START,
  UPLOAD_ONBOARDING_FILE_ERROR,
  UPLOAD_ONBOARDING_FILE_SUCCESS,
  ADMIN_UPLOAD_ONBOARDING_FILE_START,
  ADMIN_UPLOAD_ONBOARDING_FILE_ERROR,
  ADMIN_UPLOAD_ONBOARDING_FILE_SUCCESS,
  UPLOAD_ONBOARDING_CLEAR_FILE_STATE,
} from '@src/js/actions/onboarding/file/uploadOnboardingFile';

const initialState = {
  loading: false,
  error: null,
  file: null,
  errorStatus: null,
  options: null,
};

const actionsMap = {
  [UPLOAD_ONBOARDING_FILE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [UPLOAD_ONBOARDING_FILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    errorStatus: action.error.status,
  }),
  [UPLOAD_ONBOARDING_FILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    file: action.data,
    options: action.options,
  }),
  [ADMIN_UPLOAD_ONBOARDING_FILE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_UPLOAD_ONBOARDING_FILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    errorStatus: action.error.status,
  }),
  [ADMIN_UPLOAD_ONBOARDING_FILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    file: action.data,
    options: action.options,
  }),
  [UPLOAD_ONBOARDING_CLEAR_FILE_STATE]: (state) => ({
    ...state,
    file: null,
    error: null,
    errorStatus: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
