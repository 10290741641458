import fetchResource from '@src/js/api/fetch-resource';

export function getEventsForUser({
  page,
  eventType,
  selectedTab,
  slug,
  companyType,
  finanzaAgevolata = false,
  search,
}) {
  const params = {
    page, eventType, selectedTab, finanzaAgevolata,
  };
  if (companyType) {
    params.companyType = companyType;
  }
  if (slug) {
    params.slug = slug;
  }
  if (finanzaAgevolata) {
    params.finanzaAgevolata = finanzaAgevolata;
  }
  if (eventType) {
    params.type = eventType;
  }
  if (search) {
    params.search = search;
  }
  const query = new URLSearchParams(params);

  return fetchResource(`api/events?${ query }`);
}

export function getEventBasicData(slug) {
  const url = `api/event/${ slug }/basic`;

  return fetchResource(url);
}

export function getEventData(slug) {
  const url = `api/event/${ slug }/data`;

  return fetchResource(url);
}

export function subscribeMembersAPI(slug, formData) {
  const url = `api/event/${ slug }/subscribe`;

  return fetchResource(url, {
    method: 'PUT',
    body: formData,
  });
}

export function getCommunity({ slug, params }) {
  const query = new URLSearchParams(params);
  return fetchResource(`api/event/${ slug }/community?${ query }`, {
    method: 'GET',
  });
}

export function getCommunityPermissions({ slug, params }) {
  const query = new URLSearchParams(params);
  return fetchResource(`api/admin/event/${ slug }/community/permissions/users?${ query }`, {
    method: 'GET',
  });
}

export function editPermission({ slug, uuid, action }) {
  return fetchResource(`api/admin/event/${ slug }/community/permissions/${ uuid }/${ action }`, {
    method: action === 'add' ? 'POST' : 'DELETE',
  });
}

export function subscribeExternal(slug, data) {
  return fetchResource(`api/admin/event/${ slug }/subscribe`, {
    method: 'PUT',
    body: data,
  });
}

export function unsubscribeUser(slug, data, filters) {
  return fetchResource(`api/admin/event/${ slug }/subscribe`, {
    method: 'DELETE',
    body: data,
    filters,
  });
}

export function getUnregisteredUsers(params) {
  const search = new URLSearchParams(params);
  return fetchResource(`api/admin/users/company/list?${ search }`, {
    method: 'GET',
  });
}

export function unsubscribe(slug) {
  return fetchResource(`api/event/${ slug }/unsubscribe`, {
    method: 'POST',
    noContentType: true,
  });
}
