import { useSelector } from 'react-redux';

export const selectCurrentStep = () => useSelector(state => state.onboarding?.currentStep);
export const selectTouchedSteps = () => useSelector(state => state.onboarding?.touchedSteps);
export const selectValidation = () => useSelector(state => state.onboarding?.validation);
export const selectFirstVisit = () => useSelector(state => state.onboarding?.firstVisit);
export const selectOnboarding = () => useSelector(state => state.onboarding);
export const selectOnboardingData = () => useSelector(state => state.onboarding?.data);
export const selectOnboardingLoading = () => useSelector(state => state.onboarding?.loading);
export const selectOnboardingError = () => useSelector(state => state.onboarding?.error);
export const selectOnboardingSave = () => useSelector(state => state.onboarding?.onSave);
export const selectOnboardingSubmitted = () => useSelector(state => state.onboarding?.submitted);
export const selectTemplateData = () => useSelector(state => state.onboardingTemplate);
export const selectRegisterLoading = () => useSelector(state => state.onboarding?.registerLoading);
export const selectRegisterData = () => useSelector(state => state.onboarding?.registerData);
export const selectPartnerFormOptions = () => useSelector(
  state => state.partnerFormOptions?.partnerFormOptions?.results);
export const selectCrmAccounts = () => useSelector(state => state.onboarding?.crmAccounts);
export const selectCrmPagination = () => useSelector(state => state.onboarding?.crmPagination);
