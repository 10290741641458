import fetchResource from '@src/js/api/fetch-resource';

export function onboardingInit() {
  return fetchResource('api/onboarding/init');
}

export function loginOnboardingUser(credentialsData) {
  return fetchResource('api/onboarding/login', {
    method: 'POST',
    'body': credentialsData,
  });
}

export function logoutOnboardingUser() {
  return fetchResource('api/onboarding/logout', {
    method: 'POST',
  });
}
