import fetchResource from '@src/js/api/fetch-resource';

export function getInitInfo() {
  const url = 'api/localization/messages';

  return fetchResource(
    url,
    {
      method: 'GET',
    }
  );
}

export function getSessionInfo() {
  const url = 'api/session-info';

  return fetchResource(
    url,
    {
      method: 'GET',
    }
  );
}
