import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field, Form } from 'react-final-form';
import Button from '@src/js/components/global/buttons/Buttons';
import TextInput from '@src/js/components/global/inputs/TextInput';
import { routeCodes, withLang } from '@src/js/constants/routes';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import inputValidation from '@src/js/helpers/InputValidation';
import { loginOnboardingUser } from '@src/js/actions/onboarding/onboardingBasic';
import loadEnvVariable from '@src/js/static/LoadEnv';

class LoginForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    onboardingUser: PropTypes.object,
    loginErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    loginReCaptcha: PropTypes.bool,
    loginLoading: PropTypes.bool,
    activeLanguage: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
    };

    if (props.loginReCaptcha) {
      this.reCaptchaRef = React.createRef();
      window.recaptchaOptions = {
        lang: props.activeLanguage,
      };
    }
  }

  onCaptchaChange = () => {
    const {
      dispatch,
    } = this.props;

    const grecaptcha = window.grecaptcha;

    const dataToSend = {
      _username: document.querySelector('[name=\'username\']').value,
      _password: document.querySelector('[name=\'password\']').value,
      gRecaptchaResponse: grecaptcha.getResponse(),
    };

    dispatch(loginOnboardingUser(dataToSend));
  };

  handleSubmit = async form => {
    const {
      dispatch,
      loginReCaptcha,
    } = this.props;

    const grecaptcha = window.grecaptcha;

    const dataToSend = {
      '_username': form.username,
      '_password': form.password,
    };

    if (loginReCaptcha) {
      if (grecaptcha && grecaptcha.getResponse()) {
        grecaptcha.reset();
      }
      grecaptcha.execute();
    } else {
      dispatch(loginOnboardingUser(dataToSend));
    }
  };

  togglePasswordVisibility = () => {
    const {
      passwordVisible,
    } = this.state;

    this.setState({
      passwordVisible: !passwordVisible,
    });
  };

  validate = values => {
    const errors = {};

    errors.password = inputValidation.composeValidators(
      inputValidation.minLengthValidator(7),
      inputValidation.passwordValidator,
      inputValidation.requiredValidator
    )(values.password);

    errors.username = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.emailValidator
    )(values.username);

    return errors;
  };

  render() {
    const {
      onboardingUser,
      loginErrorMessage,
      loginReCaptcha,
      loginLoading,
    } = this.props;

    const {
      passwordVisible,
    } = this.state;

    if (onboardingUser !== null && onboardingUser.isLoggedIn) {
      return window.location = routeCodes.ONBOARDING_FORM.replace(':processHash', onboardingUser.userProcessHash);
    }

    return (
      <Form
        onSubmit={ this.handleSubmit }
        validate={ this.validate }
        mutators={ {
          trimText: (args, state, utils) => {
            utils.changeValue(state, 'username', () => args[0]?.target.value.trim());
          },
        } }
        render={ ({
          handleSubmit, invalid, dirtySinceLastSubmit, form,
        }) => (
          <form
            className='login-form'
            id='loginForm'
            onSubmit={ (e) => {
              e.preventDefault();
              handleSubmit();
              return false;
            } }
          >
            <div className='login-form__container'>
              <div className='login-form__content'>
                <div className='login-form__header'>
                  <h2 className='login-form__title'>
                    <FormattedMessage id='login.formTitle' />
                  </h2>
                </div>

                { loginErrorMessage && (
                  <div className='login-form__login-error'>
                    <FormattedMessage id={ loginErrorMessage } />
                  </div>
                ) }

                { onboardingUser && !onboardingUser.isLoggedIn && onboardingUser.message && (
                  <div className='login-form__login-error'>
                    <FormattedMessage id={ onboardingUser.message } />
                  </div>
                ) }

                { loginLoading && <Loader /> }

                <div className='login-form__body'>
                  <div className='col-12 mb-1 position-relative'>
                    <Field
                      name='username'
                      fieldName='username'
                      fieldLabel='login.labelEnterEmail'
                      placeholder='login.enterEmail'
                      fieldType='text'
                      component={ TextInput }
                      fieldOnChange={ form.mutators.trimText }
                    />
                  </div>
                </div>

                { /* todo @branko refactor this - Descoped- login is on EPW
                     multiple body classes arent needed/should be named differently */
                }
                <div className='login-form__body'>
                  <div className='col-12 mb-1 position-relative'>
                    <Field
                      name='password'
                      fieldName='password'
                      fieldLabel='login.labelEnterPassword'
                      placeholder='login.enterPassword'
                      fieldType={ passwordVisible ? 'text' : 'password' }
                      component={ TextInput }
                    />
                    <span
                      className={ passwordVisible ? 'icon-EyeOpenSmall show-password-eye'
                        : 'icon-EyeClosedSmall show-password-eye' }
                      onClick={ this.togglePasswordVisibility }
                      onKeyDown={ this.togglePasswordVisibility }
                      role='presentation'
                    />
                  </div>
                </div>

                { loginReCaptcha && (
                  <ReCAPTCHA
                    ref={ this.reCaptchaRef }
                    size='invisible'
                    sitekey={ loadEnvVariable('GOOGLE_RECAPTCHA_SITE_KEY') }
                    onChange={ this.onCaptchaChange }
                  />
                ) }

                <div className='login-form__body'>
                  <NavLink
                    className='login-form__forgot-password'
                    exact
                    to={ withLang(routeCodes.ONBOARDING_REQUEST_RESET_PASSWORD) }
                  >
                    <FormattedMessage id='login.forgotPassword' />
                  </NavLink>
                </div>

                <div className=''>
                  <Button
                    additionalClass='w-100 login-form__login_button'
                    buttonType='submit'
                    buttonText='dialog.confirm'
                    disabled={ !(!invalid || (invalid && dirtySinceLastSubmit)) }
                  />
                </div>
              </div>
            </div>
          </form>
        ) }
      />
    );
  }
}

const mapStateToProps = state => ({
  onboardingUser: state.onboardingBasic.onboardingUser,
  loginErrorMessage: state.onboardingBasic.loginError,
  loginReCaptcha: state.onboardingBasic.loginReCaptcha,
  loginLoading: state.onboardingBasic.loginLoading,
  activeLanguage: state.initInfo.activeLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default withRouter(connectWrapper);
