import produce from 'immer';
import {
  GET_LOUNGES_LIST_START, GET_LOUNGES_LIST_SUCCESS, GET_LOUNGES_LIST_FAIL,
  GET_PROGRAMMES_LOUNGES_START, GET_PROGRAMMES_LOUNGES_SUCCESS, GET_PROGRAMMES_LOUNGES_FAIL,
  CREATE_LOUNGE_START, CREATE_LOUNGE_SUCCESS, CREATE_LOUNGE_FAIL,
  UPDATE_LOUNGE_START, UPDATE_LOUNGE_SUCCESS, UPDATE_LOUNGE_FAIL,
  DELETE_LOUNGE_START, DELETE_LOUNGE_SUCCESS, DELETE_LOUNGE_FAIL,
} from '@app/store/programme/actions';

export const initialState = {
  data: null,
  loading: false,
  error: null,
  programmeData: [],
  programmeListData: null,
};

function programmeReducer(state = initialState, action) {
  return produce(state, draftState => {
    const draft = draftState;

    switch (action.type) {
      case GET_LOUNGES_LIST_START:
        draft.loading = true;
        draft.error = null;
        draft.data = null;
        break;
      case GET_LOUNGES_LIST_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = action.result;
        break;
      case GET_LOUNGES_LIST_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.data = null;
        break;
      case CREATE_LOUNGE_START:
      case UPDATE_LOUNGE_START:
      case DELETE_LOUNGE_START:
        draft.loading = true;
        draft.error = null;
        break;
      case CREATE_LOUNGE_FAIL:
      case UPDATE_LOUNGE_FAIL:
      case DELETE_LOUNGE_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case CREATE_LOUNGE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = {
          items:
          [
            ...state.data.items,
            ...[{ ...action.result, ...action.data }],
          ],
        };
        draft.programmeData = [...state.programmeData,
          ...[{ ...action.result, ...action.data }],
        ];
        break;
      case UPDATE_LOUNGE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = {
          items:
          [
            ...[...state.data.items].filter(lounge => lounge.id !== action.data.id),
            ...[action.data],
          ],
        };
        draft.programmeData = [
          ...[...state.programmeData].filter(lounge => lounge.id !== action.data.id),
          ...[action.data],
        ];
        break;
      case DELETE_LOUNGE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = {
          items: [...state.data.items]
            .filter(lounge => lounge.id !== action.id),
        };
        draft.programmeData = [...state.programmeData]
          .filter(lounge => lounge.id !== action.id);
        break;

      case GET_PROGRAMMES_LOUNGES_START:
        draft.loading = true;
        draft.error = null;
        draft.programmeData = [];
        break;
      case GET_PROGRAMMES_LOUNGES_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.programmeData = action.result;
        break;
      case GET_PROGRAMMES_LOUNGES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.programmeData = [];
        break;

      default: break;
    }
  });
}

export default programmeReducer;
