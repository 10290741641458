import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_SECTORS_START,
  GET_SECTORS_ERROR,
  GET_SECTORS_SUCCESS,
  GET_SECOND_SECTORS_START,
  GET_SECOND_SECTORS_ERROR,
  GET_SECOND_SECTORS_SUCCESS,
} from '@src/js/actions/taxonomy/sectors';

import * as api from '@src/js/api/taxonomy/sectors';

function* getSectors(options) {
  try {
    const data = yield call(() => api.getSectors(options.parent));
    yield put({ type: GET_SECTORS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SECTORS_ERROR, error });
  }
}

function* getSecondLevelSectors() {
  try {
    const results = yield call(() => api.getSecondLevelSectors());
    yield put({ type: GET_SECOND_SECTORS_SUCCESS, results });
  } catch (error) {
    yield put({ type: GET_SECOND_SECTORS_ERROR, error });
  }
}

export function* getSectorsWatcher() {
  yield takeLatest(GET_SECTORS_START, getSectors);
}

export function* getSecondLevelSectorsWatcher() {
  yield takeLatest(GET_SECOND_SECTORS_START, getSecondLevelSectors);
}

export default [
  getSectorsWatcher(),
  getSecondLevelSectorsWatcher(),
];
