import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_NEWS_INDEX_START,
  GET_NEWS_INDEX_ERROR,
  GET_NEWS_INDEX_SUCCESS,
} from '@src/js/actions/news/newsIndex';

import * as api from '@src/js/api/news/newsIndex';

function* getNewsIndex(): Generator {
  try {
    const data = yield call(() => api.getNewsIndex());
    yield put({ type: GET_NEWS_INDEX_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NEWS_INDEX_ERROR, error });
  }
}

function* getNewsIndexWatcher() {
  yield takeLatest(GET_NEWS_INDEX_START, getNewsIndex);
}

export default [
  getNewsIndexWatcher(),
];
