import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PhoneInput, { getCountries } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import en from 'react-phone-number-input/locale/en.json';
import Select from '@app/components/global/forms/select/EliteSelect';
import { Warning } from './style';

const MobileInput = ({
  fieldLabel, fieldDisabled, fieldRequired, className,
  placeholder, input, meta, translate, defaultCountry,
}) => {
  const options = [
    { value: '', label: 'International' },
    ...getCountries().map(country => ({ value: country, label: en[country] })),
  ];
  const [country, setCountry] = useState(defaultCountry || '');

  const changeCountry = (e) => {
    input.onChange('');
    setCountry(e);
  };

  const getInputElement = () => (
    <div className='PhoneInput__wrapper'>
      <PhoneInput
        { ...input }
        placeholder={ placeholder }
        flags={ flags }
        defaultCountry={ country }
        country={ country }
        disabled={ fieldDisabled }
      />
      <Select
        value={ country }
        options={ options }
        onChange={ (e) => changeCountry(e.value || undefined) }
        fieldNoClear
        disabled={ fieldDisabled }
        isSearchable={ false }
      />
    </div>
  );

  const getDisabledElement = () => (fieldDisabled
    ? <span className='eop-text-input__input-icon icon-LockerSmall' />
    : null);

  const renderFieldLabel = () => (translate
    ? <FormattedMessage id={ fieldLabel } />
    : fieldLabel);

  const renderMobileInputBlock = () => (
    <div className='eop-text-input'>
      { getInputElement() }
      { getDisabledElement() }
    </div>
  );

  let validationErrorId = '';
  if (meta) {
    const validationError = (meta.error || meta.submitError);
    if (validationError) {
      validationErrorId = (validationError.id || validationError);
    }
  }

  return (
    <div className={ `eop-text-input ${ className }` }>
      <label className='eop-text-input__label'>
        { fieldRequired && <span className='required-icon' /> }
        { fieldLabel && renderFieldLabel() }
      </label>
      { renderMobileInputBlock() }
      { meta
        && (
          <Warning>
            {
              (
                meta.error
                || (meta.submitError && meta.dirtySinceLastSubmit === false)
              )
              && meta.touched
              && validationErrorId
              && (
              <FormattedMessage
                id={ validationErrorId }
              />
              )
            }
          </Warning>
        ) }
    </div>
  );
};

MobileInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string,
  fieldDisabled: PropTypes.bool,
  fieldRequired: PropTypes.bool,
  fieldMaxLength: PropTypes.number,
  additionalClass: PropTypes.string,
  className: PropTypes.string,
  validationMessageOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  min: PropTypes.number,
  translate: PropTypes.bool,
  defaultCountry: PropTypes.string,
};

MobileInput.defaultProps = {
  translate: true,
};

export default MobileInput;
