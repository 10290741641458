import produce from 'immer';

import {
  PARTNER_PARAMETERS_VISIBILITY_REQUEST,
  PARTNER_PARAMETERS_VISIBILITY_FAIL,
  PARTNER_PARAMETERS_VISIBILITY_SUCCESS,
} from '@src/js/actions/partner/partnerParametersVisibility';

export const initialState = {
  loading: false,
  error: null,
  partnerSlug: null,
  type: null,
};

/* eslint-disable default-case,no-param-reassign */
function fundingVisibilityReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case PARTNER_PARAMETERS_VISIBILITY_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case PARTNER_PARAMETERS_VISIBILITY_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.partnerSlug = action.slug;
        draft.type = action.parameters;
        break;
      case PARTNER_PARAMETERS_VISIBILITY_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  }
  );
}

export default fundingVisibilityReducer;
