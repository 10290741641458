import React, { useEffect } from 'react';
import ProgressBar from '@app/components/global/progressbar/ProgressBar';
import { StepColor } from '@app/components/global/progressbar/constants';
import {
  selectRegisterData, selectOnboarding, selectTemplateData,
} from '@app/store/onboarding/selectors';
import { PARTNER_FORM_STEPS } from '@app/store/onboarding/constants';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import * as Actions from '@app/store/onboarding/actions';
import { CLEAR_DATA, SET_ACTIVE_STEP, GET_DATA_START } from '@app/store/onboarding/actions';
import { selectLocalizationMessages } from '@app/store/global/selectors/initInfo';
import { DEFAULT_LANGUAGE } from '@src/js/layouts/forms/company/CompanyForm';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import { GET_PARTNER_FORM_OPTIONS_START } from '@src/js/actions/partner/partnerFormOptions';
import { Language, OnboardingTemplate, User } from '@src/js/constants/entities';
import OnboardingErrorPage from '@src/js/views/user/pages/Onboarding/OnboardingProcess/OnboardingErrorPage';
import { ON_SAVE_ONBOARDING } from '@app/store/onboarding/actions/index';
import { getDigitalSpecialistContact } from '@app/store/global/actions';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { getCountries, getProvinces, getRegions } from '@src/js/actions/taxonomy/countries';
import FinalPage from './FinalPage';
import PartnerSteps from './PartnerSteps';
import Footer from './Footer';

const PartnerOnboarding = ({ match }) => {
  const { showModal } = useGlobalModalContext();
  const registerData = selectRegisterData();
  const onboarding = selectOnboarding();
  const templateData = selectTemplateData();
  const localizationMessages = selectLocalizationMessages();
  const hash = match.params.templateHash || match.params.processHash;
  const dispatch = useDispatch();
  const language = templateData?.templateData?.lang || onboarding?.data?.template?.lang || DEFAULT_LANGUAGE;
  const admin = isAdminRole() || isPra();
  const getStepColor = (step) => {
    if (step === onboarding?.currentStep) {
      return StepColor.STATUS_ACTIVE;
    }
    if ((admin || onboarding?.currentStep !== PARTNER_FORM_STEPS.BASIC_INFO)
    && step === PARTNER_FORM_STEPS.BASIC_INFO) {
      return StepColor.STATUS_COMPLETED;
    }
    if (onboarding?.touchedSteps.includes(step) && step !== PARTNER_FORM_STEPS.FINAL_SECTION) {
      return onboarding?.validation?.[step] ? StepColor.STATUS_COMPLETED : StepColor.STATUS_INVALID;
    }
    return StepColor.STATUS_NOT_STARTED;
  };
  const stepValues = language === 'it'
    ? Object.values(PARTNER_FORM_STEPS)
    : Object.values(PARTNER_FORM_STEPS).filter(step => step !== PARTNER_FORM_STEPS.PARTNER_SERVICES);
  const steps = stepValues.map(step => ({
    step,
    color: getStepColor(step),
    label: `onboarding.progressbar.${ step }`,
    iconClass: `icon-ob-${ step }`,
    enabled: admin
    || (onboarding?.currentStep !== PARTNER_FORM_STEPS.BASIC_INFO && step !== PARTNER_FORM_STEPS.BASIC_INFO),
  }));

  const userType = admin ? User.TYPE_ADMIN : User.TYPE_ONBOARDING;

  const stepChange = (step) => {
    if (step === onboarding?.currentStep) {
      return;
    }
    dispatch({
      type: Actions.ON_SAVE_ONBOARDING, step: onboarding?.currentStep, action: 'stepChange', newStep: step,
    });
  };

  const onCloseAction = () => {
    dispatch({
      type: ON_SAVE_ONBOARDING, step: onboarding?.currentStep, action: 'save', newStep: null,
    });
  };

  const onSubmitAction = () => {
    dispatch({
      type: ON_SAVE_ONBOARDING, step: onboarding?.currentStep, action: 'submit', newStep: null,
    });
  };

  useEffect(() => {
    dispatch(getDigitalSpecialistContact());
    dispatch(getCountries());
    dispatch(getRegions(Language.IT));
    dispatch(getProvinces(Language.IT));
    return () => {
      dispatch({ type: CLEAR_DATA });
    };
  }, []);
  useEffect(() => {
    if (match.params.processHash) {
      dispatch({ type: SET_ACTIVE_STEP, step: PARTNER_FORM_STEPS.PARTNER_SUMMARY });
      dispatch({
        type: GET_DATA_START, hash, isAdmin: admin, isPartner: true,
      });
      dispatch({ type: GET_PARTNER_FORM_OPTIONS_START, userType });
    } else {
      dispatch({ type: SET_ACTIVE_STEP, step: PARTNER_FORM_STEPS.BASIC_INFO });
    }
  }, [match]);

  useEffect(() => {
    if (!admin
      && onboarding?.data?.template?.docType === OnboardingTemplate.TYPE_SIGNATURE_REQUIRED
      && onboarding?.data?.terms?.signedDocument
      && !onboarding?.data?.submitted
    ) {
      const file = onboarding.data.terms.signedDocument;
      const messages = {
        title: localizationMessages[language]['onboarding.genericTitle'],
        content: localizationMessages[language]['onboarding.modal.content'].replace('%fileUrl%', file.url),
        cancel: localizationMessages[language].cancel,
        confirm: localizationMessages[language]['dialog.confirm'],
        download: localizationMessages[language].download,
      };
      showModal({
        type: ModalTypes.OnboardingSubmit,
        title: messages.title,
        config: { size: 'md' },
        onCloseAction,
        data: {
          onCloseAction, onSubmitAction, file, messages,
        },
      });
    }
  }, [onboarding?.data]);

  const title = registerData ? 'onboarding.sectionOneThankYouPage.title' : null;
  const content = registerData ? 'onboarding.sectionOneThankYouPage.content' : 'compliance.form.finalPage.description';

  if (onboarding?.loading) {
    return <Container className='compliance__container'><Loader /></Container>;
  }
  if (onboarding?.error) {
    return <OnboardingErrorPage error={ onboarding.error } />;
  }

  return (
    <IntlProvider
      textComponent={ React.Fragment }
      locale={ language }
      messages={ localizationMessages[language] }
    >
      <Container className='compliance__container relative'>
        {onboarding?.updateLoading && <Loader />}
        {onboarding?.data?.submitted || registerData ? <FinalPage title={ title } content={ content } />
          : (
            <>
              <ProgressBar
                steps={ steps }
                stepChange={ stepChange }
              />
              {(onboarding?.data || templateData) && <PartnerSteps hash={ hash } />}
              {onboarding?.currentStep !== PARTNER_FORM_STEPS.BASIC_INFO && <Footer steps={ stepValues } />}
            </>
          )
        }
      </Container>
    </IntlProvider>
  );
};

PartnerOnboarding.propTypes = {
  match: PropTypes.object,
};

export default withRouter(PartnerOnboarding);
