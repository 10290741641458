export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';

export const CLEAR_IMAGE_STATE = 'CLEAR_IMAGE_STATE';

export function uploadImage(image, entity, imageType, value, languageCode) {
  return {
    type: UPLOAD_IMAGE_START,
    image,
    entity,
    imageType,
    value,
    languageCode,
  };
}

export function clearImage() {
  return {
    type: CLEAR_IMAGE_STATE,
  };
}
