import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { getLanguages } from '@src/js/helpers/helpers';
import { Language } from '@app/models/Language';
import Select from 'react-select';

type Props = {
  lang: Language,
  setLang: (lang: Language) => void,
  additionalClass?: string,
  required?: boolean,
  label?: string,
  tooltip?: string,
}

const LanguageSelect = ({
  lang, setLang, additionalClass,
  required, label, tooltip,
}: Props) => {
  const languages = getLanguages() as Language[];

  return (
    <div className={ `eop-text-textarea m-t-2 ${ additionalClass || '' }` }>
      <div className='eop-text-textarea__label'>
        {required && <span className='eop-text-input__label__required-icon'>*</span>}
        {label && (
        <>
          <FormattedMessage id={ label } />
          {tooltip && (
          <span className='eop-text-textarea__label__info'>
            <span
              className='icon-medium-Info eop-text-input__label__info-icon'
              data-tip
              data-for='textArea-tooltip'
            />
            <ReactTooltip
              id='textArea-tooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id={ tooltip } />
            </ReactTooltip>
          </span>
          )}
        </>
        )}
        <div className='elite-select'>
          <Select
            name='lang'
            defaultValue={ {
              value: lang,
              label: <FormattedMessage id={ `lang_${ lang }` } />,
            } }
            className='eop-text-textarea__label__selectbox m-t-0'
            options={ languages.map((langCode, idx) => ({
              index: idx,
              value: langCode,
              label: <FormattedMessage id={ `lang_${ langCode }` } />,
            })) }
            onChange={ el => {
              if (el) {
                setLang(el.value);
              }
            } }
          />
        </div>
      </div>
    </div>
  );
};

export default LanguageSelect;
