import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@src/js/components/global/buttons/Buttons';

const UploadPlaceholder = ({ isLocked }) => (
  <div className='upload-card__placeholder'>
    <p>
      <span className='upload-card__placeholder-text'>
        <FormattedMessage id='upload_drag_drop' />
      </span>
      <span className='upload-card__placeholder-content'>
        <FormattedMessage id='upload_browse' />
      </span>
      <span className='upload-card__placeholder-button'>
        <Button buttonText='upload_browse' />
      </span>
      { isLocked && (<span className='eop-text-input-input-icon icon-LockerSmall m-l-2' />) }
    </p>
  </div>
);

UploadPlaceholder.propTypes = {
  isLocked: PropTypes.bool,
};

export default UploadPlaceholder;
