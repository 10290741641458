import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import * as api from '@app/store/global/api';

import { toastError, toastSuccess } from '@app/components/global/toastr';
import {
  CROSS_SELLING_FAIL, CROSS_SELLING_START, CROSS_SELLING_SUCCESS, CROSS_SELLING_VIEW_FAIL,
  CROSS_SELLING_VIEW_START, CROSS_SELLING_VIEW_SUCCESS, SHOW_TOASTR, TOASTR_ERROR, TOASTR_SUCCESS,
  GET_DIGITAL_SPECIALIST_FAIL, GET_DIGITAL_SPECIALIST_START, GET_DIGITAL_SPECIALIST_SUCCESS,
} from '@app/store/global/actions';

function* showToastr({
  toastrType, title, message, translate,
}) {
  let ttl = title;
  let msg = message;

  if (translate) {
    const activeLanguage = yield select((state) => state.initInfo?.activeLanguage);
    const translations = yield select((state) => state.initInfo?.localizationMessages[activeLanguage]);
    ttl = translations[title] || title;
    msg = translations[message] || message;
  }
  switch (toastrType) {
    case TOASTR_ERROR:
      toastError(ttl, msg);
      break;
    case TOASTR_SUCCESS:
      toastSuccess(ttl, msg);
      break;
    default:
      break;
  }
}

function* crossSelling() {
  try {
    const data = yield call(() => api.crossSellingPopup());
    yield put({ type: CROSS_SELLING_SUCCESS, data });
  } catch (error) {
    yield put({ type: CROSS_SELLING_FAIL, error });
  }
}

function* crossSellingView() {
  try {
    yield call(() => api.crossSellingView());
    yield put({ type: CROSS_SELLING_VIEW_SUCCESS });
  } catch (error) {
    yield put({ type: CROSS_SELLING_VIEW_FAIL, error });
  }
}

function* getDigitalSpecialistContact() {
  try {
    const data = yield call(() => api.getDigitalSpecialistContact());
    yield put({ type: GET_DIGITAL_SPECIALIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_DIGITAL_SPECIALIST_FAIL, error });
  }
}

function* root() {
  yield takeEvery(SHOW_TOASTR, showToastr);
  yield takeEvery(CROSS_SELLING_START, crossSelling);
  yield takeEvery(CROSS_SELLING_VIEW_START, crossSellingView);
  yield takeEvery(GET_DIGITAL_SPECIALIST_START, getDigitalSpecialistContact);
}

export default root;
