export const GET_BUSINESS_NEED_START = 'GET_BUSINESS_NEED_START';

export const EDIT_BUSINESS_NEED_START = 'EDIT_BUSINESS_NEED_START';
export const EDIT_BUSINESS_NEED_ERROR = 'EDIT_BUSINESS_NEED_ERROR';
export const EDIT_BUSINESS_NEED_SUCCESS = 'EDIT_BUSINESS_NEED_SUCCESS';

export const CREATE_BUSINESS_NEED_START = 'CREATE_BUSINESS_NEED_START';
export const CREATE_BUSINESS_NEED_ERROR = 'CREATE_BUSINESS_NEED_ERROR';
export const CREATE_BUSINESS_NEED_SUCCESS = 'CREATE_BUSINESS_NEED_SUCCESS';

export const GET_BUSINESS_NEED_SECTORS_START = 'GET_BUSINESS_NEED_SECTORS_START';
export const GET_BUSINESS_NEED_SECTORS_ERROR = 'GET_BUSINESS_NEED_SECTORS_ERROR';
export const GET_BUSINESS_NEED_SECTORS_SUCCESS = 'GET_BUSINESS_NEED_SECTORS_SUCCESS';

export function getBusinessNeeds(user) {
  return {
    type: GET_BUSINESS_NEED_START,
    user,
  };
}

export function createBusinessNeed(businessNeed, isAdmin) {
  return {
    type: CREATE_BUSINESS_NEED_START,
    businessNeed,
    isAdmin,
  };
}

export function editBusinessNeed(businessNeedData, isAdmin, showToastr = true) {
  return {
    type: EDIT_BUSINESS_NEED_START,
    businessNeedData,
    isAdmin,
    showToastr,
  };
}

export function getBusinessNeedSectors() {
  return {
    type: GET_BUSINESS_NEED_SECTORS_START,
  };
}
