import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import inputValidation from '@src/js/helpers/InputValidation';
import EliteCKEditor from './EliteCKEditor';
import './ck-editor.scss';

type Props = {
  name: string,
  className?: string,
  label?: string,
  tooltip?: string,
  required?: boolean,
  translate: boolean,
  placeholder?: string,
  imageControl?: ImageControl,
  videoControl?: boolean,
  htmlControl?: boolean,
  formControl?: boolean,
  disabled?: boolean
  hidden?: boolean
  maxLength?: number;
  requiredLabel?: boolean;
}

type ImageControl ={
  imageType: string,
  entity: string
}

const RichTextField = ({
  name, className, label, required, tooltip, translate = true, placeholder, formControl,
  imageControl, videoControl, htmlControl, disabled, hidden, maxLength, requiredLabel = true,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  if (hidden) {
    return (
      <Field
        name={ name }
        render={ () => null }
      />
    );
  }

  const validateLength = maxLength ? inputValidation.maxLengthValidator(maxLength) : undefined;
  const validateRequired = required ? inputValidation.requiredEditorValidator : undefined;
  const validate = validateLength && validateRequired
    ? inputValidation.composeValidators(validateLength, validateRequired) : validateLength || validateRequired;

  return (
    <div className={ `eop-text-textarea ${ className }` } data-name={ name }>
      <label className='eop-text-textarea__label'>
        { required && requiredLabel && <span className='required-icon' /> }
        { label && fieldLabel }
        { tooltip && (
        <>
          <span className='icon-medium-Info font-size-icon' data-tip data-for={ `${ name }-tooltip` } />
          <ReactTooltip
            id={ `${ name }-tooltip` }
            place='top'
            effect='solid'
            multiline
            className='w-25'
          >
            { fieldTooltip }
          </ReactTooltip>
        </>
        )}
        { disabled && (
          <span className='eop-text-textarea__input-icon--rich icon-LockerSmall' />
        )}
      </label>
      <Field
        name={ name }
        validate={ validate }
      >
        {({ input, meta }) => (
          <>
            { ready && (
            <EliteCKEditor
              field={ input }
              translate={ translate }
              placeholder={ placeholder }
              imageControl={ imageControl }
              videoControl={ videoControl }
              htmlControl={ htmlControl }
              formControl={ formControl }
              disabled={ disabled }
            />
            ) }
            { meta && (
            <span className='error-warning'>
              {
                ((meta.error && (meta.dirty || meta.touched || meta.submitFailed))
                  || (meta.submitError && meta.dirtySinceLastSubmit === false))
                && (
                <FormattedMessage
                  id={ meta.error?.id || meta.submitError?.id || meta.error || meta.submitError }
                  values={ meta.error?.values || meta.submitError?.values }
                />
                )
              }
            </span>
            )}
          </>
        ) }
      </Field>
    </div>
  );
};

export default RichTextField;
