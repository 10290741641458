import {
  GET_ALL_OPPORTUNITIES_CLEAR,
  GET_ALL_OPPORTUNITIES_START,
  GET_ALL_OPPORTUNITIES_ERROR,
  GET_ALL_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getAllOpportunities';

const initialState = {
  loading: false,
  error: null,
  opportunities: {
    PageSize: 0,
    CurrentPage: 0,
    TotalPage: 0,
    Items: [],
  },
  filter: {},
};

const actionsMap = {
  [GET_ALL_OPPORTUNITIES_CLEAR]: () => initialState,
  [GET_ALL_OPPORTUNITIES_START]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
    filter: action.filter,
  }),
  [GET_ALL_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    opportunities: initialState.opportunities,
  }),
  [GET_ALL_OPPORTUNITIES_SUCCESS]: (state, action) => {
    const concatArray = action.data.CurrentPage !== 0
      ? state.opportunities.Items.concat(action.data.Items)
      : action.data.Items;
    return {
      ...state,
      loading: false,
      error: null,
      opportunities: {
        ...action.data,
        Items: concatArray,
      },
      filter: {
        ...state.filter,
        page: action.data.CurrentPage,
      },
    };
  },
  'UPDATE_OPPORTUNITY': (state, action) => {
    const opportunities = [...state.opportunities.Items];
    const idx = opportunities.findIndex(o => o.Id === action.opportunity.Id);
    if (idx >= 0) {
      opportunities[idx] = action.opportunity;
    }
    return {
      ...state,
      opportunities: { ...state.opportunities, Items: opportunities },
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
