import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Loader } from '@src/js/components/global';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  useForm, FormProvider,
} from 'react-hook-form';
import { InputSelect } from '@app/components/global/forms';
import { ModalBody, ModalFooter } from 'reactstrap';
import { postPromotedContent } from '@app/store/content/promotedContent/actions';
import { selectPromotedPostLoading } from '@app/store/content/promotedContent/selectors';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { usePrevious } from '@src/js/helpers/hooks';
import { getContentOptions } from '@app/store/content/promotedContent/api';

const PromotedContentModal = ({
  companyTypes, languages, contentTypes,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const loading = selectPromotedPostLoading();
  const postSuccess = useSelector(state => state.promotedContent.postSuccess);
  const prevPostSuccess = usePrevious(postSuccess);
  const [optionsPage, setOptionsPage] = useState(1);
  const [cacheResetter, setCacheResetter] = useState(0);
  const [search, setSearch] = useState();
  const methods = useForm({
    mode: 'onChange',
  });
  const {
    watch, formState: { errors }, control, handleSubmit, setValue,
  } = methods;
  const contentType = watch('contentType');
  const { hideModal } = useGlobalModalContext();

  useEffect(() => {
    setValue('contentId', null);
    setOptionsPage(1);
    setCacheResetter(cacheResetter + 1);
  }, [contentType]);

  useEffect(() => {
    if (postSuccess && prevPostSuccess === false) {
      hideModal();
    }
  }, [postSuccess, prevPostSuccess]);

  const onSubmit = data => {
    const submidData = { ...data, contentId: data.contentId.value };
    dispatch(postPromotedContent(submidData));
  };

  const loadContentOptions = async currentSearch => {
    if (!contentType) {
      return new Promise().reject;
    }
    let page = optionsPage;
    if (search !== currentSearch) {
      setOptionsPage(1);
      setCacheResetter(cacheResetter + 1);
      page = 1;
    }

    return getContentOptions({
      contentType, page, search: currentSearch,
    }).then(
      data => {
        setSearch(currentSearch);
        setOptionsPage(optionsPage + 1);
        return {
          options: data?.results,
          hasMore: data?.pagination?.page < data?.pagination?.pageCount,
        };
      }).catch(() => ({
      options: [],
      hasMore: false,
    })
    );
  };

  useEffect(() => () => hideModal(), []);

  const companyTypesOptions = companyTypes
    .map(company => ({ ...company, label: intl.formatMessage({ id: company.label }) }));
  const languagesOptions = languages
    .map(lang => ({ ...lang, label: intl.formatMessage({ id: lang.label }) }));
  const contentTypesOptions = contentTypes
    .map(cont => ({ ...cont, label: intl.formatMessage({ id: cont.label }) }));

  return (
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(onSubmit) } className='m-t-3'>
        { loading && <Loader /> }
        <ModalBody>
          <section className='elite-form__section'>
            <div className='elite-form__full-row'>
              <InputSelect
                name='userType'
                errors={ errors }
                control={ control }
                label='promotedList.companyType'
                options={ companyTypesOptions }
                required
                fieldNoClear
              />
            </div>
            <div className='elite-form__full-row'>
              <InputSelect
                name='lang'
                errors={ errors }
                control={ control }
                label='promotedList.language'
                options={ languagesOptions }
                required
                fieldNoClear
              />
            </div>
            <div className='elite-form__full-row'>
              <InputSelect
                name='contentType'
                errors={ errors }
                control={ control }
                label='promotedList.contentType'
                options={ contentTypesOptions }
                required
                fieldNoClear
              />
            </div>
            <div className='elite-form__full-row'>
              <InputSelect
                name='contentId'
                control={ control }
                errors={ errors }
                label='promotedList.contentTitle'
                loadOptions={ loadContentOptions }
                cacheUniqs={ [cacheResetter] }
                debounceTimeout={ 500 }
                required
              />
            </div>
          </section>
        </ModalBody>
        <ModalFooter className='m-t-3'>
          <Button
            size='lg'
            buttonText='dialog.confirm'
            buttonType='submit'
            disabled={ false }
          />
          <Button
            size='lg'
            buttonText='cancel'
            emptyButtonType='empty-grey'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

PromotedContentModal.propTypes = {
  companyTypes: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  contentTypes: PropTypes.array.isRequired,
};

export default PromotedContentModal;
