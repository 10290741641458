export const PARTNER_DOCUMENTS_UPLOAD_START = 'PARTNER_DOCUMENTS_UPLOAD_START';
export const PARTNER_DOCUMENTS_UPLOAD_ERROR = 'PARTNER_DOCUMENTS_UPLOAD_ERROR';
export const PARTNER_DOCUMENTS_UPLOAD_SUCCESS = 'PARTNER_DOCUMENTS_UPLOAD_SUCCESS';

export function partnerDocumentsUpload(files, slug) {
  return {
    type: PARTNER_DOCUMENTS_UPLOAD_START,
    files,
    slug,
  };
}
