import produce from 'immer';

import {
  GET_TEMPLATES_REQUESTED,
  GET_TEMPLATES_SUCCEEDED,
  GET_TEMPLATES_FAILED, UPDATE_TEMPLATE_REQUESTED, UPDATE_TEMPLATE_SUCCEEDED, UPDATE_TEMPLATE_FAILED,
} from '@src/js/oneplatform/privateMarketNetwork/container/constants';

export const initialState = {
  loading: false,
  error: null,
  update: {
    loading: false,
    error: null,
    updated: false,
  },
  templates: {
    list: null,
    formOptions: null,
  },
};

/* eslint-disable default-case,no-param-reassign */
function PrivateMarketTemplateReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_TEMPLATES_REQUESTED:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_TEMPLATES_SUCCEEDED:
        draft.loading = false;
        draft.error = null;
        draft.templates = {
          list: action.payload.templates,
          formOptions: action.payload.formOptions,
        };
        break;
      case GET_TEMPLATES_FAILED:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case UPDATE_TEMPLATE_REQUESTED:
        draft.update = {
          loading: true,
          error: null,
          updated: false,
        };
        break;
      case UPDATE_TEMPLATE_SUCCEEDED:
        draft.update = {
          loading: false,
          error: null,
          updated: true,
        };
        break;
      case UPDATE_TEMPLATE_FAILED:
        draft.update = {
          loading: false,
          error: action.payload,
          updated: false,
        };
        break;
    }
  });
}
/* eslint-enable */

export default PrivateMarketTemplateReducer;
