import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_MY_OPPORTUNITIES_START,
  GET_MY_OPPORTUNITIES_ERROR,
  GET_MY_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getMyOpportunities';

import * as api from '@src/js/marketplace/api/getMyOpportunities';

function* getMyOpportunities(options) {
  const data = yield call(() => api.getMyOpportunities(options.filters));
  try {
    yield put({ type: GET_MY_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MY_OPPORTUNITIES_ERROR, error });
  }
}

function* getMyOpportunitiesWatcher() {
  yield takeLatest(GET_MY_OPPORTUNITIES_START, getMyOpportunities);
}

export default [
  getMyOpportunitiesWatcher(),
];
