import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { editPermission } from '@src/js/actions/events/events';

const CommunityPermissionsItem = ({
  slug, user, intl, setEdited,
}) => {
  const dispatch = useDispatch();

  const toggleView = () => {
    setEdited();
    dispatch(editPermission(slug, user.uuid, user.communityAccess ? 'delete' : 'add'));
  };

  return (
    <div className='peer-item cursor-default'>
      <div className='peer-item-logo'>
        <img src={ user.avatar } alt='avatar' />
      </div>
      <div className='peer-item-subject'>
        <div
          className='peer-item-subject--bold'
        >
          { user.fullName }
        </div>
        <div className='peer-item-subject__info'>
          <span className='peer-item-subject__info--bold'>
            { user.jobTitle }
          </span>
          { user.company && <span>{ `, ${ user.company }` }</span> }
        </div>

      </div>
      { user.registered && (
      <div className='peer-item-button' title={ intl.formatMessage({ id: 'events.archive.registered' }) }>
        <span
          className={ 'icon icon__selected icomoon-clipboard cursor-default' }
        />
      </div>
      ) }
      {!user.registered && (
      <div className='peer-item-button' title={ intl.formatMessage({ id: 'view' }) }>
        <span
          className={ `icon ${ user.communityAccess ? 'icon__selected' : '' } icomoon-eye cursor-pointer` }
          onClick={ toggleView }
          role='presentation'
        />
      </div>
      )}
    </div>
  );
};

CommunityPermissionsItem.propTypes = {
  user: PropTypes.object.isRequired,
  slug: PropTypes.string,
  intl: PropTypes.object,
  setEdited: PropTypes.func,
};

export default injectIntl(CommunityPermissionsItem);
