import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_COMPANIES_START,
  ADMIN_GET_COMPANIES_ERROR,
  ADMIN_GET_COMPANIES_SUCCESS,
} from '@src/js/actions/company/adminCompanies';

import * as api from '@src/js/api/company/adminCompanies';

function* adminGetCompanies(options) {
  try {
    const data = yield call(() => api.adminGetCompanies(options.filters));
    yield put({ type: ADMIN_GET_COMPANIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_COMPANIES_ERROR, error });
  }
}

export function* adminGetCompaniesWatcher() {
  yield takeLatest(ADMIN_GET_COMPANIES_START, adminGetCompanies);
}

export default [
  adminGetCompaniesWatcher(),
];
