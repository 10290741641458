import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_WIDGET_NEWS_START,
  GET_WIDGET_NEWS_ERROR,
  GET_WIDGET_NEWS_SUCCESS,
} from '@src/js/actions/news/widgetNews';

import * as api from '@src/js/api/news/widgetNews';

function* getWidgetNews(options) {
  try {
    const data = yield call(() => api.getWidgetNews(options.slug));
    yield put({ type: GET_WIDGET_NEWS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_WIDGET_NEWS_ERROR, error });
  }
}

function* getWidgetNewsWatcher() {
  yield takeLatest(GET_WIDGET_NEWS_START, getWidgetNews);
}

export default [
  getWidgetNewsWatcher(),
];
