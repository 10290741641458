export const GET_TEMPLATE_DATA_START = 'GET_TEMPLATE_DATA_START';
export const GET_TEMPLATE_DATA_ERROR = 'GET_TEMPLATE_DATA_ERROR';
export const GET_TEMPLATE_DATA_SUCCESS = 'GET_TEMPLATE_DATA_SUCCESS';

export function getTemplateData(templateHash) {
  return {
    type: GET_TEMPLATE_DATA_START,
    templateHash,
  };
}
