import {
  takeLatest, call, put, takeEvery, all, select,
} from 'redux-saga/effects';

import {
  GET_DEAL_START,
  GET_DEAL_FAIL,
  GET_DEAL_SUCCESS,
  UPDATE_DEAL_START,
  UPDATE_DEAL_FAIL,
  UPDATE_DEAL_SUCCESS,
  GET_DEAL_LIST_START,
  GET_DEAL_LIST_FAIL,
  GET_DEAL_LIST_SUCCESS,
  DELETE_DEAL_START,
  DELETE_DEAL_FAIL,
  DELETE_DEAL_SUCCESS,
} from '@app/store/funding/actions/deal';

import * as api from '@app/store/funding/api/deal';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions/index';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';

function* getDealList({ params }) {
  try {
    const data = yield call(() => api.getDealList(params));
    yield put({ type: GET_DEAL_LIST_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: GET_DEAL_LIST_FAIL, error });
  }
}

function* getDeal({ id }) {
  try {
    const data = yield call(() => api.getDeal(id));
    yield put({ type: GET_DEAL_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_DEAL_FAIL, error });
  }
}

function* updateDeal({ id, data, history }) {
  try {
    if (id) {
      yield call(() => api.updateDeal(id, data));
    } else {
      yield call(() => api.createDeal(data));
    }
    yield put({ type: UPDATE_DEAL_SUCCESS });
    history.push(withLang(adminRouteCodes.DEALS));
  } catch (error) {
    yield put({ type: UPDATE_DEAL_FAIL, error });
    yield put(showToastrError());
  }
}

function* deleteDeal({ id }) {
  try {
    const data = yield call(() => api.deleteDeal(id));
    const params = yield select((state) => state.deal.params);
    yield all([
      put({ type: DELETE_DEAL_SUCCESS, data }),
      put(showToastrSuccess()),
    ]);
    if (params) {
      yield put({ type: GET_DEAL_LIST_START, params: { ...params, page: 0 } });
    }
  } catch (error) {
    yield all([
      put({ type: DELETE_DEAL_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getDealWatcher() {
  yield takeLatest(GET_DEAL_START, getDeal);
}

function* editDealWatcher() {
  yield takeLatest(UPDATE_DEAL_START, updateDeal);
}

function* getDealListWatcher() {
  yield takeEvery(GET_DEAL_LIST_START, getDealList);
}

function* deleteDealWatcher() {
  yield takeLatest(DELETE_DEAL_START, deleteDeal);
}

export default [
  getDealWatcher(),
  editDealWatcher(),
  getDealListWatcher(),
  deleteDealWatcher(),
];
