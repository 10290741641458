import AsyncSelectPaginate from '@app/components/global/forms/select/AsyncSelectPaginate';
import { SelectValue } from '@app/components/global/forms/select/select';
import DataTable, { TableColDef } from '@app/components/global/list/DataTable';
import { Partner } from '@app/models';
import { getPartnersAction } from '@src/js/actions/partner';
import { getUserFormCompanyOptions } from '@src/js/api/user/formOptions';
import { Button } from '@src/js/components/global';
import { RootState } from '@src/js/store';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import ActionButton from './ActionButton';

const columnsDef = (field: string, values: Partner[], setValues: (v: Partner[]) => void): TableColDef<Partner>[] => [
  {
    field: 'name', headerName: 'name', width: 50,
  },
  {
    field: 'programme', headerName: 'programme_label', width: 15,
  },
  {
    field: 'country', headerName: 'country', width: 15,
  },
  {
    field,
    headerName: '',
    width: 20,
    component: (item) => <ActionButton item={ item } values={ values } setValues={ setValues } />,
  },
];

type Filters = {
  page: number,
  search: string,
  programmes: SingleValue<SelectValue>|MultiValue<SelectValue>,
  status: number,
  limit: number,
  excludedIds: number[],
}

type Props = {
  field: string,
  addValues: (values: Partner[]) => void,
  excludedIds: number[],
}

const PageSize = 5;

const PartnerModal = ({ field, addValues, excludedIds }: Props) => {
  const [values, setValues] = useState<Partner[]>([]);
  const columns = columnsDef(field, values, setValues);
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const intl = useIntl();
  const [filters, setFilters] = useState<Filters>({
    page: 1, search: '', programmes: [], status: 1, limit: PageSize, excludedIds,
  });

  const confirm = () => {
    addValues(values);
    hideModal();
  };

  const { partners, loading } = useSelector<RootState, any>((state) => state.partners);

  useEffect(() => {
    dispatch(getPartnersAction(filters));
  }, [filters, dispatch]);

  const searchPlaceholder = intl.formatMessage({ id: 'search' });

  return (
    <ModalBody>
      <div className='grid-container__search grid-col-3 m-t-4'>
        <DebounceInput
          debounceTimeout={ 500 }
          placeholder={ searchPlaceholder }
          onChange={ (e) => setFilters({ ...filters, page: 1, search: e.target.value }) }
          value={ filters.search }
          className='search-input'
        />
        <AsyncSelectPaginate
          onChange={ (programmes) => setFilters({ ...filters, page: 1, programmes }) }
          loadAsyncOptions={ getUserFormCompanyOptions }
          placeholder='partner.form.filter.placeholder.programme'
          name='programmes'
          value={ filters.programmes }
          className='filters'
          type='programmes'
          multi
        />
      </div>
      <DataTable
        page={ filters.page }
        columns={ columns }
        items={ partners?.results || [] }
        total={ partners?.count || 0 }
        pageSize={ PageSize }
        loading={ loading }
        handlePage={ (page) => setFilters({ ...filters, page }) }
      />
      <ModalFooter>
        <Button
          size='md'
          buttonText='dialog.confirm'
          onClickFunc={ confirm }
        />
        <Button
          size='md'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </ModalBody>
  );
};

export default PartnerModal;
