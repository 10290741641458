import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container } from './styled';

export default class Footer extends Component {
  static propTypes = {
    children: PropTypes.element,
  };

  render() {
    const { children } = this.props;

    return (
      <Container>
        { children }
      </Container>
    );
  }
}
