import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const GridContainer = ({
  children,
  columns,
  className,
  typeListGrid,
}) => {
  let cols = children;
  if (!Array.isArray(children)) {
    cols = [children];
  }
  const rowsNumber = Math.ceil(cols.length / columns);
  const rows = new Array(rowsNumber);
  for (let rowIdx = 0; rowIdx < rowsNumber; rowIdx++) {
    const rowContent = [];
    for (let childIdx = 0; childIdx < columns; childIdx++) {
      const child = cols[childIdx + (columns * rowIdx)];
      if (child) {
        rowContent.push(
          <Fragment key={ childIdx }>{ child }</Fragment>
        );
      }
    }

    rows.push(<Fragment key={ rowIdx }>{ rowContent }</Fragment>);
  }

  return (
    <div className={ `${ className || '' } grid-container__${ typeListGrid } grid-col-${ columns }` }>
      { rows }
    </div>
  );
};

GridContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  columns: PropTypes.number,
  className: PropTypes.string,
  typeListGrid: PropTypes.string.isRequired,
};

GridContainer.defaultProps = {
  columns: 4,
};

export default GridContainer;
