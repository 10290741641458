import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormSection, InputText,
} from '@app/components/global/forms';
import { useFieldArray } from 'react-hook-form';
import { Button } from '@src/js/components/global';

const Advisors = ({ form }) => {
  const { control, formState: { errors }, register } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companyAdvisor',
  });
  const supportingFields = useFieldArray({
    control,
    name: 'companySupportingAdmission',
  });
  const defaultContact = {
    name: null,
    relationshipLength: null,
  };
  const defaultSupportingContact = {
    name: null,
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultContact);
    }
    if (supportingFields.fields.length === 0) {
      supportingFields.append(defaultSupportingContact);
    }
  }, [append, supportingFields.append]);

  return (
    <>
      <FormSection
        sectionTitle='onboarding.companyProfile.corporateAdvisor'
        titleClass='d-flex gap-8 align-items-center'
      >
        {fields.map((item, index) => (
          <Fragment key={ item.id }>
            { index !== 0 ? (
              <div className='w-100 d-flex justify-content-end m-b-1'>
                <div role='presentation' className='compliance__button-close' onClick={ () => remove(index) }>
                  <span className='icon-medium-Close' />
                </div>
              </div>
            ) : (
              <div className='w-100' />
            )
            }
            <>
              <InputText
                name={ `companyAdvisor[${ index }].name` }
                errors={ errors }
                register={ register }
                label='onboarding.companyProfile.corporateAdvisorName'
                defaultValue={ item.name }
              />
              <InputText
                name={ `companyAdvisor[${ index }].relationshipLength` }
                errors={ errors }
                register={ register }
                label='onboarding.companyProfile.corporateAdvisorLengthRelationship'
                defaultValue={ item.relationshipLength }
              />
            </>
          </Fragment>

        ))}
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => append(defaultContact) }
          />
        </div>
      </FormSection>
      <FormSection
        sectionTitle='onboarding.companyProfile.advisorSupportingAdmission'
        titleClass='d-flex gap-8 align-items-center'
      >
        {supportingFields.fields.map((item, index) => (
          <Fragment key={ item.id }>
            { index !== 0 ? (
              <div className='w-100 d-flex justify-content-end m-b-1'>
                <div
                  role='presentation'
                  className='compliance__button-close'
                  onClick={ () => supportingFields.remove(index) }
                >
                  <span className='icon-medium-Close' />
                </div>
              </div>
            ) : (
              <div className='w-100' />
            )
            }
            <InputText
              name={ `companySupportingAdmission[${ index }].name` }
              errors={ errors }
              register={ register }
              label='onboarding.companyProfile.advisorSupportingAdmissionName'
              defaultValue={ item.name }
            />
          </Fragment>
        ))}
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => supportingFields.append(defaultSupportingContact) }
          />
        </div>
      </FormSection>
    </>
  );
};

Advisors.propTypes = {
  form: PropTypes.object,
};

export default Advisors;
