import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CommunityPermissionsItem from '@src/js/views/user/pages/events/CommunityPermissionsItem';
import PropTypes from 'prop-types';
import { Loader } from '@src/js/components/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DebounceInput } from 'react-debounce-input';
import { getCommunityPermissions } from '@src/js/actions/events/events';

const CommunityModal = ({ slug, getCommunityData }) => {
  const dispatch = useDispatch();
  const permissionsData = useSelector(state => state.events.permissionsData);
  const permissionsLoading = useSelector(state => state.events.permissionsLoading);
  const permissionsError = useSelector(state => state.events.permissionsError);
  const { pageCount } = permissionsData?.pagination || {};
  const [filters, setFilters] = useState({ page: 1, search: '' });
  const [edited, setEdited] = useState(false);
  const editedRef = useRef();
  editedRef.current = edited;

  const handleSearchChange = (e) => {
    const newFilters = { page: 1, search: e.target.value || '' };
    setFilters(newFilters);
  };

  const nextUsers = () => {
    const nextPage = { ...filters, page: filters.page + 1 };
    setFilters(nextPage);
  };

  useEffect(() => () => {
    if (editedRef.current) {
      getCommunityData();
    }
  }, []);

  useEffect(() => {
    dispatch(getCommunityPermissions(slug, filters));
  }, [filters]);

  return (
    <ModalBody className='m-y-4'>
      { permissionsError && (
        <div className='message-error'>
          {<FormattedMessage id='error' />}
        </div>
      ) }
      <div className='search-peers'>
        <DebounceInput
          debounceTimeout={ 500 }
          onChange={ handleSearchChange }
          placeholder='Search'
        />
      </div>
      <div className='peers-list-container-members' id='peersContainer'>
        <InfiniteScroll
          dataLength={ permissionsData?.results?.length || 0 }
          next={ nextUsers }
          hasMore={ filters.page < pageCount }
          scrollableTarget='peersContainer'
        >
          { permissionsData?.results?.map(user => (
            <CommunityPermissionsItem
              key={ user.uuid }
              slug={ slug }
              user={ user }
              setEdited={ () => setEdited(true) }
            />
          )) }
          { permissionsLoading && <Loader /> }
        </InfiniteScroll>
        { permissionsData?.results?.length === 0 && (
          <div className='no-data'>
            <FormattedMessage id='noResults' />
          </div>
        ) }
      </div>
    </ModalBody>
  );
};

CommunityModal.propTypes = {
  slug: PropTypes.string,
  getCommunityData: PropTypes.func,
};

export default CommunityModal;
