import { Action } from 'redux';

export const GET_NEWS_INDEX_START = 'GET_NEWS_INDEX_START';
export const GET_NEWS_INDEX_ERROR = 'GET_NEWS_INDEX_ERROR';
export const GET_NEWS_INDEX_SUCCESS = 'GET_NEWS_INDEX_SUCCESS';

export function getNewsIndex(): Action {
  return {
    type: GET_NEWS_INDEX_START,
  };
}
