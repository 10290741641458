import {
  FormSection,
  InputFileUpload,
  InputMobile,
} from '@app/components/global/forms';
import {
  DOCUSIGN_START, ON_SAVE_ONBOARDING,
  UPDATE_VALIDATION,
} from '@app/store/onboarding/actions';
import { PARTNER_FORM_STEPS } from '@app/store/onboarding/constants';
import { selectOnboarding, selectValidation } from '@app/store/onboarding/selectors';
import { Button } from '@src/js/components/global';
import { Warning } from '@src/js/components/global/inputs/style';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { OnboardingTemplate } from '@src/js/constants/entities';
import { apiPath } from '@src/js/constants/routes';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

const Signature = ({
  hash, partnerDetails, isPartner,
}) => {
  const {
    getValues, formState: { errors }, control, watch,
  } = useFormContext();
  const dispatch = useDispatch();
  const onboarding = selectOnboarding();
  const validation = selectValidation();
  const terms = watch('terms.accepted');
  const marketingPolicy = watch('terms.marketingPolicy');
  const thirdPartyPolicy = watch('terms.thirdPartyPolicy');
  const signedDocument = watch('terms.signedDocument');
  const uploadRequired = onboarding?.data?.template?.docType === OnboardingTemplate.TYPE_UPLOAD_REQUIRED;
  const signatureRequired = onboarding?.data?.template?.docType === OnboardingTemplate.TYPE_SIGNATURE_REQUIRED;

  const isAdmin = isAdminRole() || isPra();
  const downloadPath = `${ apiPath() }/api/${ isAdmin ? 'admin/' : '' }`
  + `onboarding/process/${ hash }/form/download`;
  const docusign = () => {
    const mobilePhone = isPartner ? getValues('mobilePhone') : getValues('terms.mobilePhone');
    if (mobilePhone) {
      dispatch({
        type: DOCUSIGN_START,
        mobilePhone,
        terms: {
          accepted: terms,
          marketingPolicy: marketingPolicy === true || marketingPolicy === 'true',
          thirdPartyPolicy: thirdPartyPolicy === true || thirdPartyPolicy === 'true',
        },
        hash,
      });
    }
  };

  const invalidPreviousSteps = validation && Object.entries(validation)
    .filter(([k, v]) => k !== PARTNER_FORM_STEPS.FINAL_SECTION && v === false).length > 0;

  const downloadDisabled = invalidPreviousSteps
    || marketingPolicy === null || thirdPartyPolicy === null || !terms;

  const downloadForm = () => {
    if (downloadDisabled) return;
    dispatch({
      type: ON_SAVE_ONBOARDING, step: onboarding.currentStep, action: 'save', newStep: null, downloadPath,
    });
  };

  useEffect(() => {
    const val = { ...validation };
    if (downloadDisabled
        || (signatureRequired && !signedDocument)
        || (uploadRequired && !signedDocument)
    ) {
      val[PARTNER_FORM_STEPS.FINAL_SECTION] = false;
      dispatch({ type: UPDATE_VALIDATION, validation: val });
    } else {
      val[PARTNER_FORM_STEPS.FINAL_SECTION] = true;
      dispatch({ type: UPDATE_VALIDATION, validation: val });
    }
  }, [downloadDisabled, signedDocument]);

  return (
    <>
      <div className='elite-white-box--single-line flex-h-gap-48 relative p-b-3'>
        <section className='elite-form__section'>
          {!partnerDetails && (
          <div className='button-container'>
            <Button
              additionalClass='eop-btn long-text'
              buttonText='onboarding.button.downloadForm'
              disabled={ downloadDisabled }
              onClickFunc={ downloadForm }
            />
          </div>
          )}
          { downloadDisabled && (
          <Warning>
            <FormattedMessage id='onboarding.final.invalidForm' />
          </Warning>
          ) }
        </section>
      </div>

      { uploadRequired && (
      <>
        <hr className='compliance__divider' />

        <FormSection>
          <div className='w-100'>
            <InputFileUpload
              name='terms.signedDocument'
              label='onboarding.final.uploadSignedPDF'
              acceptedTypes={ dropzoneExtensions.SIGNED_PDF }
              required
              url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding/document` }
            />
          </div>
        </FormSection>
      </>
      )}

      { signatureRequired && !signedDocument && (
      <>
        <hr className='compliance__divider' />

        <FormSection>
          <p className='compliance__description w-100'>
            <FormattedMessage id='onboarding.final.docusign.description' />
          </p>
          <InputMobile
            name={ isPartner ? 'mobilePhone' : 'terms.mobilePhone' }
            control={ control }
            errors={ errors }
            label='onboarding.sectionOne.mobilePhone'
            required
          />
          <div className='d-flex--center m-b-2'>
            <Button
              additionalClass='eop-btn long-text'
              buttonText='onboarding.final.docusign.button'
              onClickFunc={ docusign }
              disabled={ downloadDisabled || isAdmin }
            />
          </div>
        </FormSection>
      </>
      )}

      { signatureRequired && signedDocument && (
      <>
        <hr className='compliance__divider' />

        <FormSection>
          <InputFileUpload
            name='terms.signedDocument'
            label='onboarding.final.signedPDF'
            removable={ false }
          />
        </FormSection>
      </>
      )}
    </>
  );
};

Signature.propTypes = {
  hash: PropTypes.string.isRequired,
  partnerDetails: PropTypes.bool,
  isPartner: PropTypes.bool,
};

export default Signature;
