import { InputSelect } from '@app/components/global/forms';
import { ItemObj } from '@app/components/global/forms/select/select';
import { UserForm } from '@app/components/public/user/UserProfile';
import { getUserFormCompanyOptions } from '@src/js/api/user/formOptions';
import { RolesUser } from '@src/js/constants/entities';
import { RootState } from '@src/js/store';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const UserRoles = () => {
  const {
    watch, formState: { errors }, control, setValue,
  } = useFormContext<UserForm>();
  const formOptions = useSelector<RootState, { roles: string[] }>(state => state.userFormOptions.userFormOptions);

  const userRoles = watch('userRoles');

  const checkRole = (role: string) => userRoles?.map(r => r.value).includes(role);

  const rolesOptions = formOptions?.roles?.map(
    userRole => ({ value: userRole, label: userRole.replace('_', ' ') })) || [];

  return (
    <>
      <InputSelect
        name='userRoles'
        label='user.userRoles'
        multi
        options={ rolesOptions }
        simpleValue={ false }
        control={ control }
        errors={ errors }
        onChange={ (v) => setValue('userRoles', v as ItemObj<string>[]) }
      />

      { checkRole(RolesUser.ROLE_RELATIONSHIP_MANAGER) && (
      <InputSelect
        name='companyRelationshipManagers'
        label='user.companyRelationshipManagers'
        multi
        loadAsyncOptions={ getUserFormCompanyOptions }
        type='companiesToManage'
        control={ control }
        errors={ errors }
        cacheUniqs={ [userRoles] }
      />
      ) }

      { checkRole(RolesUser.ROLE_PROGRAMME_ADMIN) && (
      <InputSelect
        name='programmeAdmins'
        label='user.programmeAdmins'
        multi
        loadAsyncOptions={ getUserFormCompanyOptions }
        type='programmes'
        control={ control }
        errors={ errors }
        cacheUniqs={ [userRoles] }
      />
      ) }

      { checkRole(RolesUser.ROLE_EDITOR) && (
      <InputSelect
        name='programmeEditors'
        label='user.programmeEditors'
        multi
        loadAsyncOptions={ getUserFormCompanyOptions }
        type='programmes'
        control={ control }
        errors={ errors }
        cacheUniqs={ [userRoles] }
      />
      ) }
    </>
  );
};

export default UserRoles;
