const scope = 'TAXONOMY';

export const GET_COUNTRIES_TREE_START = `${ scope }/GET_COUNTRIES_TREE_START`;
export const GET_COUNTRIES_TREE_SUCCESS = `${ scope }/GET_COUNTRIES_TREE_SUCCESS`;
export const GET_COUNTRIES_TREE_FAIL = `${ scope }/GET_COUNTRIES_TREE_FAIL`;

export const GET_SECTORS_TREE_START = `${ scope }/GET_SECTORS_TREE_START`;
export const GET_SECTORS_TREE_SUCCESS = `${ scope }/GET_SECTORS_TREE_SUCCESS`;
export const GET_SECTORS_TREE_FAIL = `${ scope }/GET_SECTORS_TREE_FAIL`;

export const GET_TURNOVER_ETINERARIES_START = `${ scope }/GET_TURNOVER_ETINERARIES_START`;
export const GET_TURNOVER_ETINERARIES_SUCCESS = `${ scope }/GET_TURNOVER_ETINERARIES_SUCCESS`;
export const GET_TURNOVER_ETINERARIES_FAIL = `${ scope }/GET_TURNOVER_ETINERARIES_FAIL`;

export const GET_PARTNER_EXPERTISES_START = `${ scope }/GET_PARTNER_EXPERTISES_START`;
export const GET_PARTNER_EXPERTISES_SUCCESS = `${ scope }/GET_PARTNER_EXPERTISES_SUCCESS`;
export const GET_PARTNER_EXPERTISES_FAIL = `${ scope }/GET_PARTNER_EXPERTISES_FAIL`;

export const GET_PARTNER_SECTORS_START = `${ scope }/GET_PARTNER_SECTORS_START`;
export const GET_PARTNER_SECTORS_SUCCESS = `${ scope }/GET_PARTNER_SECTORS_SUCCESS`;
export const GET_PARTNER_SECTORS_FAIL = `${ scope }/GET_PARTNER_SECTORS_FAIL`;

export const GET_PARTNER_SECTORS_TREE_START = `${ scope }/GET_PARTNER_SECTORS_TREE_START`;
export const GET_PARTNER_SECTORS_TREE_SUCCESS = `${ scope }/GET_PARTNER_SECTORS_TREE_SUCCESS`;
export const GET_PARTNER_SECTORS_TREE_FAIL = `${ scope }/GET_PARTNER_SECTORS_TREE_FAIL`;

export const GET_DIMENSIONS_START = `${ scope }/GET_DIMENSIONS_START`;
export const GET_DIMENSIONS_SUCCESS = `${ scope }/GET_DIMENSIONS_SUCCESS`;
export const GET_DIMENSIONS_FAIL = `${ scope }/GET_DIMENSIONS_FAIL`;

export const GET_STARTUP_STAGES_START = `${ scope }/GET_STARTUP_STAGES_START`;
export const GET_STARTUP_STAGES_SUCCESS = `${ scope }/GET_STARTUP_STAGES_SUCCESS`;
export const GET_STARTUP_STAGES_FAIL = `${ scope }/GET_STARTUP_STAGES_FAIL`;

export const GET_ETINERARY_COMPANY_TYPES_START = `${ scope }/GET_ETINERARY_COMPANY_TYPES_START`;
export const GET_ETINERARY_COMPANY_TYPES_SUCCESS = `${ scope }/GET_ETINERARY_COMPANY_TYPES_SUCCESS`;
export const GET_ETINERARY_COMPANY_TYPES_FAIL = `${ scope }/GET_ETINERARY_COMPANY_TYPES_FAIL`;

export function getCountriesTree() {
  return {
    type: GET_COUNTRIES_TREE_START,
  };
}
export function getSectorsTree() {
  return {
    type: GET_SECTORS_TREE_START,
  };
}
export function getTurnoverEtineraries() {
  return {
    type: GET_TURNOVER_ETINERARIES_START,
  };
}
export function getPartnerExpertises() {
  return {
    type: GET_PARTNER_EXPERTISES_START,
  };
}
export function getPartnerSectors() {
  return {
    type: GET_PARTNER_SECTORS_START,
  };
}
export function getPartnerSectorsTree() {
  return {
    type: GET_PARTNER_SECTORS_TREE_START,
  };
}
export function getDimensions() {
  return {
    type: GET_DIMENSIONS_START,
  };
}
export function getStartupStages() {
  return {
    type: GET_STARTUP_STAGES_START,
  };
}
export function getEtineraryCompanyTypes() {
  return {
    type: GET_ETINERARY_COMPANY_TYPES_START,
  };
}
