import produce from 'immer';

import {
  GET_BUSINESSNEED_TABLE_DATA_START,
  GET_BUSINESSNEED_TABLE_DATA_SUCCESS,
  GET_BUSINESSNEED_TABLE_DATA_ERROR,
  GET_ADMIN_BUSINESSNEED_START,
  GET_ADMIN_BUSINESSNEED_SUCCESS,
  GET_ADMIN_BUSINESSNEED_ERROR,
} from '@src/js/marketplace/actions/admin/businessNeedTable';

export const initialState = {
  loading: false,
  error: null,
  data: [],

  businessNeeds: {
    loading: false,
    error: null,
    data: [],
  },
};

/* eslint-disable default-case,no-param-reassign */
function businessNeedTableReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_BUSINESSNEED_TABLE_DATA_START:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_BUSINESSNEED_TABLE_DATA_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = action.data;
        break;
      case GET_BUSINESSNEED_TABLE_DATA_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case GET_ADMIN_BUSINESSNEED_START:
        draft.businessNeeds = {
          loading: true,
          error: null,
          data: [],
        };
        break;
      case GET_ADMIN_BUSINESSNEED_SUCCESS: {
        const newBusinessNeeds = action.data.map((businessNeed, idx) => {
          const newBusinessNeed = { ...businessNeed };
          if (!businessNeed.Title) {
            newBusinessNeed.Title = `Business Need ${ idx + 1 }`;
          }
          return newBusinessNeed;
        });

        draft.businessNeeds = {
          loading: false,
          error: null,
          data: newBusinessNeeds,
        };
        break;
      }
      case GET_ADMIN_BUSINESSNEED_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
    }
  });
}

/* eslint-enable */

export default businessNeedTableReducer;
