import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { downloadFile } from '@app/components/global/forms/utils';
import {
  createFolder, downloadTask, deleteFile, DOWNLOAD_TASK_FORCE_CANCEL,
} from '@app/store/documentsArea/actions';

import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { DataRoomPermissions } from '@src/js/constants/entities';
import { selectFileListData } from '@app/store/documentsArea/selectors';
import { ectPath } from '@src/js/constants/routes';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { usePrevious } from '@src/js/helpers/hooks';
import { useParams } from 'react-router-dom';

const Actions = ({
  intl, node, slug, type, dataRoomType, cantBeMoved, training,
}) => {
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const dataroomData = selectFileListData();
  const { hideModal, showModal } = useGlobalModalContext();
  const downloadCompleted = useSelector((state) => state.dataroom?.downloadTaskCompleted);
  const prevDownloadCompleted = usePrevious(downloadCompleted);
  const viewable = ['pdf', 'jpg', 'jpeg', 'png', 'gif'].includes(node.type);
  const addFolder = () => {
    const defaultName = { name: intl.formatMessage({ id: 'documentArea.newFolder' }), parent: node.id };
    dispatch(createFolder(dataRoomType, slug, defaultName, folderId));
  };

  useEffect(() => {
    if (downloadCompleted && downloadCompleted !== prevDownloadCompleted) {
      hideModal();
    }
  }, [downloadCompleted, prevDownloadCompleted, hideModal]);

  const uploadFile = () => {
    document.getElementById(`file-upload-${ node.id }`).click();
  };

  const download = () => {
    if (type === 'folder') {
      const task = {
        downloadId: node.id,
        entity: training ? 'event' : 'dataroom',
        slug,
        type: 'folder',
      };
      showModal({
        type: ModalTypes.Loading,
        title: 'documentArea.loadingModal.title',
        config: { size: 'md' },
        onCloseAction: { type: DOWNLOAD_TASK_FORCE_CANCEL },
        data: {
          loading: true,
          action: { type: DOWNLOAD_TASK_FORCE_CANCEL },
          message: 'documentArea.loadingModal.message',
        },
      });
      dispatch(downloadTask(task));
    } else {
      const url = `${ ectPath() }/api/media/${ type }/${ training
        ? 'event' : 'dataroom' }/${ node.id }${ training ? `?slug=${ slug }` : '' }`;
      downloadFile(url);
    }
  };

  const deleteNode = () => {
    const hasContent = type === 'folder' && (node.folders?.length > 0 || node.files?.length > 0);
    showModal({
      title: type === 'folder' ? 'documentArea.deleteModal.titleFolder' : 'documentArea.deleteModal.titleFile',
      type: ModalTypes.Confirmation,
      data: {
        action: () => dispatch(deleteFile(slug, node.id, dataRoomType, type === 'folder', folderId)),
        message: hasContent ? 'documentArea.deleteModal.warning' : '',
        className: 'font-weight-bold',
      },
    });
  };

  const permissionModalTitle = intl.formatMessage({ id: 'documentArea.permissionsModal.title' },
    { name: node.name ?? node.title }
  );

  const findFiles = (folder) => {
    if (folder.folders?.length) {
      for (let index = 0; index < folder.folders.length; index++) {
        const f = folder.folders[index];
        if (f.files.length || findFiles(f)) return true;
      }
    }
    return false;
  };

  const canDownload = () => {
    if (type === 'file' || node.files?.length) return true;
    return findFiles(node);
  };

  return (
    <div className='fs-node__actions'>

      { (((node.permission > DataRoomPermissions.watermark
        || ((viewable || type === 'folder') && node.permission === DataRoomPermissions.watermark)) && canDownload())
        || node.permission >= DataRoomPermissions.edit) && (
        <UncontrolledDropdown>
          <DropdownToggle tag='div'>
            <div
              className='icon-three-dots fs-node__action-icon'
              role='presentation'
            />
          </DropdownToggle>
          <DropdownMenu end>
            { type === 'folder' && node.permission >= DataRoomPermissions.edit && (
            <DropdownItem
              tag='div'
              className='fs-node__dropdown-item'
              onClick={
                () => showModal({
                  type: ModalTypes.RenameFolder,
                  title: 'documentArea.renameFolder',
                  config: { size: 'md' },
                  data: {
                    slug, folder: node, dataRoomType, folderId,
                  },
                }) }
            >
              <span className='icon-small-Edit' />
              <FormattedMessage id='documentArea.renameFolder' />
            </DropdownItem>
            )}
            { node.permission === DataRoomPermissions.addUser && (
            <DropdownItem
              tag='div'
              className='fs-node__dropdown-item'
              onClick={
                () => showModal({
                  type: ModalTypes.FilePermissions,
                  title: permissionModalTitle,
                  translate: false,
                  config: { size: 'lg' },
                  data: {
                    slug, id: node.id, type, dataRoomType,
                  },
                }) }
            >
              <span className='icomoon-user-plus' />
              <FormattedMessage id='documentArea.permissions' />
            </DropdownItem>
            )}
            { type === 'folder' && node.permission >= DataRoomPermissions.edit && (
            <>
              <DropdownItem tag='div' className='fs-node__dropdown-item' onClick={ addFolder }>
                <span className='icon-folder-plus' />
                <FormattedMessage id='documentArea.addFolder' />
              </DropdownItem>
              <DropdownItem tag='div' className='fs-node__dropdown-item' onClick={ uploadFile }>
                <span className='icon-FileNew' />
                <FormattedMessage id='documentArea.addFile' />
              </DropdownItem>
            </>
            )
            }
            { !cantBeMoved && node.permission >= DataRoomPermissions.edit && (
            <DropdownItem
              tag='div'
              className='fs-node__dropdown-item'
              onClick={ () => (dataroomData?.folders
                ? showModal({
                  type: ModalTypes.MoveFile,
                  title: 'documentArea.moveModal.title',
                  config: { size: 'md' },
                  data: {
                    file: node, slug, isFolder: type === 'folder', dataRoomType, folderId: parseInt(folderId, 10),
                  },
                })
                : {}) }
            >
              <span
                className='icon-Transfer'
                role='presentation'
              />
              <FormattedMessage id='documentArea.moveTo' />
            </DropdownItem>
            )}
            { (node.permission > DataRoomPermissions.watermark
              || ((viewable || type === 'folder') && node.permission === DataRoomPermissions.watermark))
              && canDownload() && (
              <DropdownItem tag='div' className='fs-node__dropdown-item' onClick={ download }>
                <span className='icon-Download' />
                <FormattedMessage id='documentArea.download' />
              </DropdownItem>
            )}
            { node.permission >= DataRoomPermissions.edit && (
            <DropdownItem
              tag='div'
              className='fs-node__dropdown-item'
              onClick={ deleteNode }
            >
              <span
                className='icon-TrashEmpty'
                role='presentation'
              />
              <FormattedMessage id='documentArea.delete' />
            </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );
};

Actions.propTypes = {
  intl: PropTypes.object,
  node: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  training: PropTypes.bool,
  dataRoomType: PropTypes.string,
  cantBeMoved: PropTypes.bool,
};

Actions.defaultProps = {
  training: false,
};

export default injectIntl(Actions);
