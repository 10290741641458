import {
  VIEW_ALL_OPPORTUNITIES_START,
  VIEW_ALL_OPPORTUNITIES_ERROR,
  VIEW_ALL_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/viewAllOpportunities';

const initialState = {
  loading: false,
  error: null,
  opportunities: {
    CurrentPage: 0,
    TotalPage: 0,
    PageSize: 0,
    Items: [],
  },
  filters: { search: '', page: 0 },
};

const actionsMap = {
  [VIEW_ALL_OPPORTUNITIES_START]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
    filters: action.filters,
  }),
  [VIEW_ALL_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    opportunities: {
      CurrentPage: 0,
      TotalPage: 0,
      PageSize: 0,
      Items: [],
    },
  }),
  [VIEW_ALL_OPPORTUNITIES_SUCCESS]: (state, action) => {
    const concatArray = action.page !== 0
      ? state.opportunities.Items.concat(action.data.Items)
      : action.data.Items;
    return {
      ...state,
      loading: false,
      error: null,
      opportunities: {
        ...action.data,
        Items: concatArray,
      },
    };
  },
  'UPDATE_OPPORTUNITY': (state, action) => {
    const opportunities = [...state.opportunities.Items];
    const idx = opportunities.findIndex(o => o.Id === action.opportunity.Id);
    if (idx >= 0) {
      opportunities[idx] = action.opportunity;
    }
    return {
      ...state,
      opportunities: { ...state.opportunities, Items: opportunities },
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
