import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button, Loader } from '@src/js/components/global';
import { useDispatch, useSelector } from 'react-redux';
import { moveFile, moveFileList } from '@app/store/documentsArea/actions';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { DataRoomPermissions } from '@src/js/constants/entities';
import { FormattedMessage } from 'react-intl';
import { File, Folder } from '@app/models/DataRoom';
import { RootState } from '@src/js/store';
import FilesTree from './FilesTree';
import MoveBreadcrumb from './MoveBreadcrumb';

type Props = {
  file: File|Folder,
  slug: string,
  dataRoomType: string,
  isFolder: boolean,
  folderId: number,
}

const MoveFileModal = ({
  file, slug, dataRoomType, isFolder, folderId = 0,
}: Props) => {
  const dispatch = useDispatch();
  const [destinationFolder, setDestinationFolder] = useState(folderId);
  const dataRoomData = useSelector<RootState, Folder|null>(state => state.dataroom.moveFileListData);
  const dataRoomLoading = useSelector<RootState, boolean>(state => state.dataroom.moveFileListLoading);
  const { hideModal } = useGlobalModalContext();
  useEffect(() => {
    dispatch(moveFileList(slug, dataRoomType, folderId));
  }, [dataRoomType, slug, dispatch, folderId]);

  const transferFile = () => {
    dispatch(moveFile({
      slug, id: file.id, parent: destinationFolder, isFolder, dataRoomType, folderId,
    }));
    hideModal();
  };

  const onFolderClick = (folder?: Folder) => {
    if (destinationFolder === folder?.id) return;
    dispatch(moveFileList(slug, dataRoomType, folder?.id));
    setDestinationFolder(folder?.id || 0);
  };

  const parents = dataRoomData?.parents;
  const currentFolder = parents?.[parents.length - 1];
  const permissionToMove = currentFolder && currentFolder.permission >= DataRoomPermissions.edit;

  return (
    <ModalBody className='m-y-4'>
      {parents && parents.length > 0 && (
        <MoveBreadcrumb
          parents={ dataRoomData?.parents }
          onFolderClick={ onFolderClick }
        />
      )}
      {dataRoomLoading && <Loader />}
      <FilesTree
        onFolderClick={ onFolderClick }
        dataroomData={ dataRoomData }
        companySlug={ slug }
        dataRoomType={ dataRoomType }
        exclude={ isFolder ? file.id : null }
        destinationFolder={ destinationFolder }
        noFilesAndButtons
      />
      {!permissionToMove && !dataRoomLoading
       && <h5 className='fs-node__title--folder'><FormattedMessage id='documentArea.noFolders' /></h5>}
      <ModalFooter>
        <Button
          size='md'
          buttonText='dialog.confirm'
          onClickFunc={ transferFile }
          disabled={ destinationFolder === folderId || !permissionToMove }
        />
        <Button
          size='md'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </ModalBody>
  );
};

export default MoveFileModal;
