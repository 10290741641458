import {
  takeLatest, takeEvery, call, put,
} from 'redux-saga/effects';
import { OnboardingTemplate } from '@src/js/constants/entities';

import { handleFilters } from '@src/js/containers/PageTable/utils';

import * as api from '@src/js/api/membership';
import * as apiOptions from '@src/js/api/user/formOptions';
import * as apiTemplateForm from '@src/js/api/membership/submitTemplateFormData';
import * as apiTemplateOptions from '@src/js/api/onboarding/templates/admin/adminTemplateOptions';
import * as apiApproveProcess from '@src/js/api/membership/adminApproveProcess';
import { adminChangeTemplateStatus } from '@src/js/api/onboarding/templates/admin/adminTemplateIndex';

import {
  ADMIN_GET_LIST_PROCESSES_REQUESTED,
  ADMIN_GET_LIST_TEMPLATES_REQUESTED,
  GET_FORM_COMPANY_OPTIONS_REQUESTED,
  PROCESSES,
  TEMPLATES,
  SUBMIT_MEMBERSHIP_FORM_REQUESTED,
  ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED,
  ADMIN_APPROVE_PROCESS_REQUESTED,
  HANDLE_TEMPLATE_TRAININGS_REQUESTED,
} from '@src/js/oneplatform/membership/pages/home/container/constants';
import {
  submitTemplateFormFailed, submitTemplateFormSucceeded,
  adminGetListProcessesSucceeded,
  adminGetListProcessesFailed,
  adminGetListTemplatesSucceeded,
  adminGetListTemplatesFailed,
  getFormCompanyOptionsSucceded,
  getFormCompanyOptionsFailed,
  adminGetTemplateOptionsSucceeded,
  adminGetTemplateOptionsFailed,
  adminApproveProcessSucceeded,
  adminApproveProcessFailed,
  handleTemplateTrainingSucceeded,
  handleTemplateTrainingFailed,
} from './actions';

function* getListProcesses({ filters }) {
  try {
    const data = yield call(() => api.getListMembership(PROCESSES, handleFilters(filters)));
    yield put(adminGetListProcessesSucceeded(data));
  } catch (error) {
    yield put(adminGetListProcessesFailed(error));
  }
}

function* getListTemplates({ page }) {
  try {
    const data = yield call(() => api.getListMembership(TEMPLATES, { page, membership: true }));
    yield put(adminGetListTemplatesSucceeded(data));
  } catch (error) {
    yield put(adminGetListTemplatesFailed(error));
  }
}

function* getFormCompanyOptions({ formOptions }) {
  try {
    const data = yield call(() => apiOptions.getUserFormCompanyOptions(formOptions.values));
    yield put(getFormCompanyOptionsSucceded(data));
    formOptions.resolve(data);
  } catch (error) {
    yield put(getFormCompanyOptionsFailed(error));
    formOptions.reject(error);
  }
}

function* submitTemplateFormData({ formData }) {
  try {
    const data = yield call(() => apiTemplateForm.submitTemplateFormData(formData));
    yield put(submitTemplateFormSucceeded(data));
  } catch (error) {
    yield put(submitTemplateFormFailed(error));
  }
}

function* adminGetTemplateOptions() {
  try {
    const data = yield call(() => apiTemplateOptions.adminGetTemplateOptions(OnboardingTemplate.KIND_MEMBERSHIP));
    yield put(adminGetTemplateOptionsSucceeded(data));
  } catch (error) {
    yield put(adminGetTemplateOptionsFailed(error));
  }
}

function* adminApproveProcess({ hash }) {
  try {
    const data = yield call(() => apiApproveProcess.adminApproveProcess(hash));
    yield put(adminApproveProcessSucceeded(data));
  } catch (error) {
    yield put(adminApproveProcessFailed(error));
  }
}

function* handleTemplateTraining({
  payload: {
    hash, status, callbacks,
  } = {},
}) {
  const { loading, succeded, failed } = callbacks ?? {};
  try {
    if (loading) loading();
    const data = yield call(() => adminChangeTemplateStatus(hash, status));
    yield put(handleTemplateTrainingSucceeded(data));
    if (succeded) succeded();
  } catch (error) {
    yield put(handleTemplateTrainingFailed(error));
    if (failed) failed();
  }
}

function* root() {
  yield takeLatest(ADMIN_GET_LIST_PROCESSES_REQUESTED, getListProcesses);
  yield takeLatest(ADMIN_GET_LIST_TEMPLATES_REQUESTED, getListTemplates);
  yield takeLatest(GET_FORM_COMPANY_OPTIONS_REQUESTED, getFormCompanyOptions);
  yield takeLatest(SUBMIT_MEMBERSHIP_FORM_REQUESTED, submitTemplateFormData);
  yield takeLatest(ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED, adminGetTemplateOptions);
  yield takeLatest(ADMIN_APPROVE_PROCESS_REQUESTED, adminApproveProcess);
  yield takeEvery(HANDLE_TEMPLATE_TRAININGS_REQUESTED, handleTemplateTraining);
}

export default root;
