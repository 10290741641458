import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GridContainer from '@app/components/global/list/GridContainer';
import LibraryCard from '@app/components/public/network/library/LibraryCard';
import { Loader } from '@src/js/components/global';
import { isEtinerary } from '@src/js/helpers/helpers';

const LibraryBlock = ({
  libraries, title, description, actionTitle, action, loading, inWidget, disabled, finanzaAgevolata,
}) => {
  const isDm = isEtinerary();
  return (
    <div className={ `library-block${
      inWidget || finanzaAgevolata ? ' remove-border' : ''
    }${ inWidget && !isDm ? ' no-shadow' : '' }` }
    >
      <section className='library__content'>
        { title && (
          <div className='library-block__title'>
            <FormattedMessage id={ title } />
            { action && (
              <div className='library-block__title-action' onClick={ action } role='presentation'>
                <FormattedMessage id={ actionTitle } />
              </div>
            )}
          </div>
        )}
        { !inWidget && description && (
          <div className='library-block__subtitle'>
            <div className='library-block__subtitle-description'>
              <FormattedMessage id={ description } />
            </div>
          </div>
        )}
        { inWidget
          ? libraries.map(library => <LibraryCard key={ library.slug } library={ library } disabled={ disabled } />)
          : (
            <GridContainer
              columns={ 3 }
              typeListGrid={ 'cards' }
              className={ `library-block__library__list library__list ${ inWidget ? 'm-t-0' : '' }` }
            >
              { libraries
                .map(library => <LibraryCard key={ library.slug } library={ library } disabled={ disabled } />)}
            </GridContainer>
          )
        }
      </section>
      {loading && <Loader position='relative' background='transparent' />}
    </div>
  );
};

LibraryBlock.propTypes = {
  libraries: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  actionTitle: PropTypes.string,
  action: PropTypes.func,
  loading: PropTypes.bool,
  inWidget: PropTypes.bool,
  finanzaAgevolata: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default LibraryBlock;
