import { takeLatest, call, put } from 'redux-saga/effects';

import {
  CREATE_LIBRARY_START,
  CREATE_LIBRARY_ERROR,
  CREATE_LIBRARY_SUCCESS,
} from '@src/js/actions/library/createLibrary';

import * as api from '@src/js/api/library/createLibrary';

function* createLibrary(options) {
  try {
    const data = yield call(() => api.createLibrary(options.data.values));
    yield put({ type: CREATE_LIBRARY_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: CREATE_LIBRARY_ERROR, error });
    options.data.reject(error);
  }
}

function* createLibraryWatcher() {
  yield takeLatest(CREATE_LIBRARY_START, createLibrary);
}

export default [
  createLibraryWatcher(),
];
