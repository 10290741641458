const scope = 'GLOBAL';
export const SHOW_TOASTR = `${ scope }/SHOW_TOASTR`;
export const TOASTR_SUCCESS = 'TOASTR_SUCCESS';
export const TOASTR_ERROR = 'TOASTR_ERROR';
export const CROSS_SELLING_START = 'CROSS_SELLING_START';
export const CROSS_SELLING_SUCCESS = 'CROSS_SELLING_SUCCESS';
export const CROSS_SELLING_FAIL = 'CROSS_SELLING_FAIL';
export const CROSS_SELLING_VIEW_START = 'CROSS_SELLING_VIEW_START';
export const CROSS_SELLING_VIEW_SUCCESS = 'CROSS_SELLING_VIEW_SUCCESS';
export const CROSS_SELLING_VIEW_FAIL = 'CROSS_SELLING_VIEW_FAIL';
export const GET_DIGITAL_SPECIALIST_START = `${ scope }/GET_DIGITAL_SPECIALIST_START`;
export const GET_DIGITAL_SPECIALIST_SUCCESS = `${ scope }/GET_DIGITAL_SPECIALIST_SUCCESS`;
export const GET_DIGITAL_SPECIALIST_FAIL = `${ scope }/GET_DIGITAL_SPECIALIST_FAIL`;
export const SET_CONFIRMED_PROFILE = `${ scope }/SET_CONFIRMED_PROFILE`;

export function showToastrSuccess(title = 'notification.success_title',
  message = 'notification.submit.success', translate = true) {
  return {
    type: SHOW_TOASTR,
    toastrType: TOASTR_SUCCESS,
    title,
    message,
    translate,
  };
}

export function showToastrError(title = 'notification.error_title',
  message = 'notification.submit.error', translate = true) {
  return {
    type: SHOW_TOASTR,
    toastrType: TOASTR_ERROR,
    title,
    message,
    translate,
  };
}

export function getDigitalSpecialistContact() {
  return {
    type: GET_DIGITAL_SPECIALIST_START,
  };
}

export function setConfirmedProfile() {
  return {
    type: SET_CONFIRMED_PROFILE,
  };
}
