import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import {
  COMPANY_CERTIFICATION_START,
  COMPANY_CERTIFICATION_ERROR,
  COMPANY_CERTIFICATION_SUCCESS,
  REQUEST_CERTIFICATION_START,
  REQUEST_CERTIFICATION_SUCCESS,
  REQUEST_CERTIFICATION_ERROR,

} from '@src/js/actions/company/companyCertification';

import * as api from '@src/js/api/company/companyCertification';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';

function* toggleCompanyCertification(options) {
  try {
    const data = yield call(() => api.toggleCompanyCertification(options.slug));
    yield put({ type: COMPANY_CERTIFICATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: COMPANY_CERTIFICATION_ERROR, error });
  }
}

function* requestCertification({ notificationType }) {
  try {
    yield call(() => api.requestCompanyCertification(notificationType));
    yield all([
      put({ type: REQUEST_CERTIFICATION_SUCCESS }),
      put(showToastrSuccess('notification.success_title', 'certificate.requestSuccess')),
    ]);
  } catch (error) {
    yield all([
      put({ type: REQUEST_CERTIFICATION_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* companyCertificationWatcher() {
  yield takeLatest(COMPANY_CERTIFICATION_START, toggleCompanyCertification);
  yield takeLatest(REQUEST_CERTIFICATION_START, requestCertification);
}

export default [
  companyCertificationWatcher(),
];
