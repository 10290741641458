import { SET_SELECTED_TAB_SUCCESS } from '@src/js/marketplace/actions/marketplaceDashboard';

const initialState = {
  selectedTab: 0,
};

const actionsMap = {
  [SET_SELECTED_TAB_SUCCESS]: (state, action) => ({
    ...state,
    selectedTab: action.index,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
