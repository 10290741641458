export const COMPANY_CONTACT_MESSAGE_SEND_START = 'COMPANY_CONTACT_MESSAGE_SEND_START';
export const COMPANY_CONTACT_MESSAGE_SEND_ERROR = 'COMPANY_CONTACT_MESSAGE_SEND_ERROR';
export const COMPANY_CONTACT_MESSAGE_SEND_SUCCESS = 'COMPANY_CONTACT_MESSAGE_SEND_SUCCESS';

export function contactMessageSend(data) {
  return {
    type: COMPANY_CONTACT_MESSAGE_SEND_START,
    data,
  };
}
