import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import { showToastrSuccess, showToastrError } from '@app/store/global/actions';

import { GET_DATA_START } from '@app/store/customReminder/actions';

import {
  GET_EVENTS_START,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  GET_SINGLE_EVENT_BASIC_START,
  GET_SINGLE_EVENT_BASIC_ERROR,
  GET_SINGLE_EVENT_BASIC_SUCCESS,
  GET_SINGLE_EVENT_DATA_START,
  GET_SINGLE_EVENT_DATA_ERROR,
  GET_SINGLE_EVENT_DATA_SUCCESS,
  GET_UPCOMING_EVENTS_START,
  GET_UPCOMING_EVENTS_ERROR,
  GET_UPCOMING_EVENTS_SUCCESS,
  GET_COMMUNITY_START,
  GET_COMMUNITY_ERROR,
  GET_COMMUNITY_SUCCESS,
  GET_COMMUNITY_PERMISSIONS_START,
  GET_COMMUNITY_PERMISSIONS_ERROR,
  GET_COMMUNITY_PERMISSIONS_SUCCESS,
  EDIT_PERMISSION_START,
  EDIT_PERMISSION_ERROR,
  EDIT_PERMISSION_SUCCESS,
  SUBSCRIBE_EXTERNAL_START,
  SUBSCRIBE_EXTERNAL_FAIL,
  SUBSCRIBE_EXTERNAL_SUCCESS,
  UNSUBSCRIBE_EXTERNAL_START,
  UNSUBSCRIBE_EXTERNAL_FAIL,
  UNSUBSCRIBE_EXTERNAL_SUCCESS,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_START,
  UNSUBSCRIBE_FAIL,
} from '@src/js/actions/events/events';

import * as api from '@src/js/api/events/events';

function* getUserEvents({
  page, eventType, selectedTab, slug, companyType, search,
}) {
  try {
    const data = yield call(() => api.getEventsForUser({
      page,
      eventType,
      selectedTab,
      slug,
      companyType,
      search,
    }));
    yield put({ type: GET_EVENTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_EVENTS_ERROR, error });
  }
}

function* getEventBasicData(options) {
  try {
    const data = yield call(() => api.getEventBasicData(options.slug));
    yield put({ type: GET_SINGLE_EVENT_BASIC_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SINGLE_EVENT_BASIC_ERROR, error });
  }
}

function* getEventData(options) {
  try {
    const data = yield call(() => api.getEventData(options.slug));
    yield put({ type: GET_SINGLE_EVENT_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SINGLE_EVENT_DATA_ERROR, error });
  }
}

function* getUpcomingEvents({ finanzaAgevolata = false }) {
  try {
    const data = yield call(() => api.getEventsForUser({
      page: 1,
      eventType: 'events',
      selectedTab: 'upcoming',
      slug: null,
      companyType: null,
      finanzaAgevolata,
    }));
    yield put({ type: GET_UPCOMING_EVENTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_UPCOMING_EVENTS_ERROR, error });
  }
}

function* getCommunity({ slug, params }) {
  const page = params.page || 1;
  try {
    const results = yield call(() => api.getCommunity({ slug, params }));
    yield put({ type: GET_COMMUNITY_SUCCESS, results, page });
  } catch (error) {
    yield put({ type: GET_COMMUNITY_ERROR, error });
  }
}

function* getCommunityPermissions({ slug, params }) {
  const page = params.page || 1;
  try {
    const results = yield call(() => api.getCommunityPermissions({ slug, params }));
    yield put({ type: GET_COMMUNITY_PERMISSIONS_SUCCESS, results, page });
  } catch (error) {
    yield put({ type: GET_COMMUNITY_PERMISSIONS_ERROR, error });
  }
}

function* editPermission({ slug, uuid, action }) {
  try {
    yield call(() => api.editPermission({ slug, uuid, action }));
    yield all([
      put({ type: EDIT_PERMISSION_SUCCESS, uuid }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: EDIT_PERMISSION_ERROR }),
      put(showToastrError()),
    ]);
  }
}

function* subscribeExternal({ slug, data }) {
  try {
    yield call(() => api.subscribeExternal(slug, data));
    yield all([
      put({ type: SUBSCRIBE_EXTERNAL_SUCCESS }),
      put(showToastrSuccess()),
      put({
        type: GET_DATA_START,
        slug,
        params: { registered: true, addDinner: true },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: SUBSCRIBE_EXTERNAL_FAIL }),
      put(showToastrError('notification.error_title', error?.response?.description || 'notification.submit.error')),
    ]);
  }
}

function* unsubscribeUser({ slug, data, filters }) {
  try {
    yield call(() => api.unsubscribeUser(slug, data));
    yield all([
      put({ type: UNSUBSCRIBE_EXTERNAL_SUCCESS }),
      put(showToastrSuccess()),
      put({
        type: GET_DATA_START,
        slug,
        params: filters,
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: UNSUBSCRIBE_EXTERNAL_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* unsubscribe({ slug, removeParams }) {
  try {
    yield call(() => api.unsubscribe(slug));
    yield all([
      put({ type: UNSUBSCRIBE_SUCCESS }),
      put(showToastrSuccess()),
      put({ type: GET_SINGLE_EVENT_BASIC_START, slug }),
      put({ type: GET_COMMUNITY_START, slug, params: { page: 1 } }),
    ]);
    if (removeParams) {
      removeParams();
    }
  } catch (error) {
    yield all([
      put({ type: UNSUBSCRIBE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_EVENTS_START, getUserEvents);
  yield takeLatest(GET_SINGLE_EVENT_BASIC_START, getEventBasicData);
  yield takeLatest(GET_SINGLE_EVENT_DATA_START, getEventData);
  yield takeLatest(GET_UPCOMING_EVENTS_START, getUpcomingEvents);
  yield takeLatest(GET_COMMUNITY_START, getCommunity);
  yield takeLatest(GET_COMMUNITY_PERMISSIONS_START, getCommunityPermissions);
  yield takeLatest(EDIT_PERMISSION_START, editPermission);
  yield takeLatest(SUBSCRIBE_EXTERNAL_START, subscribeExternal);
  yield takeLatest(UNSUBSCRIBE_EXTERNAL_START, unsubscribeUser);
  yield takeLatest(UNSUBSCRIBE_START, unsubscribe);
}

export default root;
