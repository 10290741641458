import { showToastrSuccess, showToastrError } from '@app/store/global/actions';
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import {
  adminGetProcesses,
  ADMIN_GET_INDEX_PROCESSES_START,
  ADMIN_GET_INDEX_PROCESSES_ERROR,
  ADMIN_GET_INDEX_PROCESSES_SUCCESS,
  ADMIN_GET_PROCESS_START,
  ADMIN_GET_PROCESS_ERROR,
  ADMIN_GET_PROCESS_SUCCESS,
  ADMIN_UPDATE_PROCESS_SUCCESS,
  ADMIN_UPDATE_PROCESS_ERROR,
  ADMIN_UPDATE_PROCESS_START,
  ADMIN_CREATE_COMPANY_ONBOARDING_SUCCESS,
  ADMIN_CREATE_COMPANY_ONBOARDING_ERROR,
  ADMIN_CREATE_COMPANY_ONBOARDING_START,
  ADMIN_DELETE_COMPANY_ONBOARDING_START,
  ADMIN_DELETE_COMPANY_ONBOARDING_ERROR,
  ADMIN_DELETE_COMPANY_ONBOARDING_SUCCESS,
} from '@src/js/actions/onboarding/processes/adminProcesses';

import * as api from '@src/js/api/onboarding/processes/indexProcesses';

function* getIndexProcesses(options) {
  try {
    const data = yield call(() => api.getIndexProcesses(options.filters));
    yield put({ type: ADMIN_GET_INDEX_PROCESSES_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_INDEX_PROCESSES_ERROR, error });
  }
}

function* getAdminProcess(options) {
  try {
    const data = yield call(() => api.getAdminProcess(options.hash));
    yield put({ type: ADMIN_GET_PROCESS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_PROCESS_ERROR, error });
  }
}

function* adminUpdateProcess(options) {
  try {
    // const data = yield call(() => api.adminUpdateProcess(options.data.values, options.data.hash));

    const data = yield call(() => {
      if (options && options.data.isSubmit) {
        return api.adminSubmitProcess(options.data.values, options.data.hash);
      }

      return api.adminUpdateProcess(options.data.values, options.data.hash);
    });
    yield put({ type: ADMIN_UPDATE_PROCESS_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: ADMIN_UPDATE_PROCESS_ERROR, error });
    options.data.reject(error.response);
  }
}

function* adminCreateCompany({ hash, filters, crmId }) {
  try {
    const data = yield call(() => api.adminCreateCompany(hash, crmId));
    yield put({ type: ADMIN_CREATE_COMPANY_ONBOARDING_SUCCESS, data });
    yield all([
      put(adminGetProcesses(filters)),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.create.success_message')),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_CREATE_COMPANY_ONBOARDING_ERROR, error }),
      put(showToastrError('notification.error_title', error.response?.errors?.[0])),
    ]);
  }
}

function* adminDeleteCompany(options) {
  try {
    const data = yield call(() => api.adminDeleteCompany(options.data.deleteCompanyHash));
    yield all([
      put({ type: ADMIN_DELETE_COMPANY_ONBOARDING_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.delete.success_message')),
      put(adminGetProcesses(options.filters)),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_DELETE_COMPANY_ONBOARDING_ERROR, error }),
      put(showToastrError('notification.error_title', error.response?.errors?.[0])),
    ]);
  }
}

function* getIndexProcessesWatcher() {
  yield takeLatest(ADMIN_GET_INDEX_PROCESSES_START, getIndexProcesses);
}

function* getAdminProcessWatcher() {
  yield takeLatest(ADMIN_GET_PROCESS_START, getAdminProcess);
}

function* adminUpdateProcessWatcher() {
  yield takeLatest(ADMIN_UPDATE_PROCESS_START, adminUpdateProcess);
}

function* adminCreateCompanyWatcher() {
  yield takeLatest(ADMIN_CREATE_COMPANY_ONBOARDING_START, adminCreateCompany);
}

function* adminDeleteCompanyWatcher() {
  yield takeLatest(ADMIN_DELETE_COMPANY_ONBOARDING_START, adminDeleteCompany);
}

export default [
  getIndexProcessesWatcher(),
  getAdminProcessWatcher(),
  adminUpdateProcessWatcher(),
  adminCreateCompanyWatcher(),
  adminDeleteCompanyWatcher(),
];
