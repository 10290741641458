import fetchResource from '@src/js/api/fetch-resource';

export function adminGetUser(uuid) {
  return fetchResource(`api/admin/users/${ uuid }`, {
    method: 'GET',
  });
}

export function adminEditUser(user) {
  return fetchResource(`api/admin/users/${ user.uuid }`, {
    method: 'PUT',
    'body': user,
  });
}

export function adminCreateUser(user) {
  return fetchResource('api/admin/users', {
    method: 'POST',
    'body': user,
  });
}

export function adminDisableUser(uuid) {
  return fetchResource(`api/admin/users/${ uuid }`, {
    method: 'DELETE',
    uuid,
  });
}

export function adminDisableUserNotifications(uuid) {
  return fetchResource(`api/admin/users/${ uuid }/disable-notifications`, {
    method: 'PUT',
    uuid,
  });
}

export function changeCompanyCreationPermission(data) {
  return fetchResource(`api/admin/user/${ data.userUuid }/permissions/company/create`, {
    method: 'PATCH',
    'body': data,
  });
}
