import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START,
  ADMIN_LIST_EVENT_ATTENDING_COMPANIES_ERROR,
  ADMIN_LIST_EVENT_ATTENDING_COMPANIES_SUCCESS,
  ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START,
  ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS,
  ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START,
  ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_SUCCESS,
  ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START,
  ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS,
  ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START,
  ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_ERROR,
  ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_SUCCESS,
} from '@src/js/actions/events/adminEventAttendingCompanies';

import * as api from '@src/js/api/events/adminEventAttendingCompanies';

function* listAttendingCompanies(options) {
  try {
    const data = yield call(() => api.listEventAttendingCompanies(options.slug, options.filters));
    yield put({ type: ADMIN_LIST_EVENT_ATTENDING_COMPANIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_LIST_EVENT_ATTENDING_COMPANIES_ERROR, error });
  }
}

function* saveAttendCompanies(options) {
  try {
    const data = yield call(() => api.saveAttendCompaniesApiCall(options.slug, options.filters));
    yield put({ type: ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR, error });
  }
}

function* uninviteCompanies(options) {
  try {
    const data = yield call(() => api.uninviteCompaniesApiCall(options.slug));
    yield put({ type: ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_ERROR, error });
  }
}

function* removeAttendCompanies(options) {
  try {
    const data = yield call(() => api.removeAttendCompaniesApiCall(options.slug, options.filters));
    yield put({ type: ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_ERROR, error });
  }
}

function* removeSubscribeCompanies(options) {
  try {
    const data = yield call(() => api.removeSubscribeCompaniesApiCall(options.slug, options.filters));
    yield put({ type: ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_ERROR, error });
  }
}

function* listAttendingCompaniesWatcher() {
  yield takeLatest(ADMIN_LIST_EVENT_ATTENDING_COMPANIES_START, listAttendingCompanies);
}

function* saveAttendCompaniesWatcher() {
  yield takeLatest(ADMIN_SAVE_ATTENDING_COMPANIES_FOR_EVENT_START, saveAttendCompanies);
}

function* uninviteCompaniesWatcher() {
  yield takeLatest(ADMIN_UNINVITE_ALL_COMPANIES_FOR_EVENT_START, uninviteCompanies);
}

function* removeAttendCompaniesWatcher() {
  yield takeLatest(ADMIN_REMOVE_ATTENDING_COMPANIES_FOR_EVENT_START, removeAttendCompanies);
}

function* removeSubscribeCompaniesWatcher() {
  yield takeLatest(ADMIN_REMOVE_SUBSCRIBE_COMPANIES_FOR_EVENT_START, removeSubscribeCompanies);
}

export default [
  listAttendingCompaniesWatcher(),
  saveAttendCompaniesWatcher(),
  uninviteCompaniesWatcher(),
  removeAttendCompaniesWatcher(),
  removeSubscribeCompaniesWatcher(),
];
