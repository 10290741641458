import React, { Component } from 'react';
import SetPasswordForm from '@src/js/components/forms/SetPasswordForm';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import { routeCodes, withLang } from '@src/js/constants/routes';
import loadEnvVariable from '@src/js/static/LoadEnv';

class OnboardingSetPassword extends Component {
  render() {
    return (
      <div className='set-password-page'>
        <MetaTitle title='setPassword.formTitle' />
        <div className='row'>
          <div className='col-xs-12 row-no-padding'>
            <SetPasswordForm
              className='set-password-form'
              loadingMessage='setPassword.loadingMessage'
              title='setPassword.formTitle'
              urlSetPassword='api/onboarding/users/password/set'
              urlValidateToken='api/onboarding/users/token/validate'
              urlRecreateUserToken='api/onboarding/user/recreate-user-token'
              redirectUrl={ withLang(routeCodes.ONBOARDING_LOGIN) }
              publicRedirectUrl={ loadEnvVariable('PUBLIC_WEBSITE_URL') }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OnboardingSetPassword;
