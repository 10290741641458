import fetchResource from '@src/js/api/fetch-resource';

export function getUserFormOptions() {
  return fetchResource('api/admin/users/form-options');
}

export function getUserFormCompanyOptions(formOptions) {
  let url = 'api/users/form-company-options';

  if (formOptions) {
    url += `?${ new URLSearchParams(formOptions) }`;
  }

  return fetchResource(url, {
    method: 'GET',
  });
}
