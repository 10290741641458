import React from 'react';
import PropTypes from 'prop-types';

const ContentHeaderContainer = ({ children, className }) => (
  <div className={ `container ${ className || '' }` }>
    <div className='row'>
      <div className='elite-profile-header-content'>
        { children }
      </div>
    </div>
  </div>

);

ContentHeaderContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentHeaderContainer;
