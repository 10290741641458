import {
  COMPANY_COURSERA_ACCESS_START,
  COMPANY_COURSERA_ACCESS_ERROR,
  COMPANY_COURSERA_ACCESS_SUCCESS,
} from '@src/js/actions/company/companyCourseraAccess';

const initialState = {
  loading: false,
  error: null,
  companySlug: null,
};

const actionsMap = {
  [COMPANY_COURSERA_ACCESS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    companySlug: null,
  }),
  [COMPANY_COURSERA_ACCESS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    companySlug: null,
  }),
  [COMPANY_COURSERA_ACCESS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    companySlug: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
