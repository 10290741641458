import {
  GET_LIBRARY_OPTIONS_START,
  GET_LIBRARY_OPTIONS_ERROR,
  GET_LIBRARY_OPTIONS_SUCCESS,
  CLEAR_LIBRARY_OPTIONS,
} from '@src/js/actions/library/getLibraryOptions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const actionsMap = {
  [GET_LIBRARY_OPTIONS_START]: (state) => ({
    ...state,
    data: null,
    loading: true,
    error: null,
  }),
  [GET_LIBRARY_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    data: null,
    loading: false,
    error: action.error,
  }),
  [GET_LIBRARY_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.data,
    loading: false,
    error: null,
  }),
  [CLEAR_LIBRARY_OPTIONS]: () => initialState,
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
