export const PARTNER_PARAMETERS_VISIBILITY_REQUEST = 'PARTNER_PARAMETERS_VISIBILITY_REQUEST';
export const PARTNER_PARAMETERS_VISIBILITY_FAIL = 'PARTNER_PARAMETERS_VISIBILITY_FAIL';
export const PARTNER_PARAMETERS_VISIBILITY_SUCCESS = 'PARTNER_PARAMETERS_VISIBILITY_SUCCESS';

export function toggleParametersVisibility(slug, type) {
  return {
    type: PARTNER_PARAMETERS_VISIBILITY_REQUEST,
    slug,
    parameters: type,
  };
}
