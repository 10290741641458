import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage } from '@hookform/error-message';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  name: string,
  label?: string,
  register: UseFormRegister<any>,
  translate?: boolean,
  readOnly?: boolean,
  required?: boolean,
  placeholder?: string,
  errors: object,
  maxLength?: number,
  minLength?: number,
  rows?: number,
  className?: string,
  defaultValue?: string,
  disabled?: boolean,
}

const InputTextArea = ({
  translate = true, label, placeholder, required = false, name, readOnly = false,
  maxLength, minLength, register, errors, rows, className = '', defaultValue, disabled,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate ? intl.formatMessage({ id: label }) : label;
  const fieldPlacheholder = translate && placeholder ? intl.formatMessage({ id: placeholder }) : placeholder;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  const maxLengthError = maxLength ? {
    value: maxLength,
    message: intl.formatMessage({ id: 'global_form.error.max_length' }, { maxLength }),
  } : undefined;
  const minLengthError = minLength ? {
    value: minLength,
    message: intl.formatMessage({ id: 'global_form.error.min_length' }, { minLength }),
  } : undefined;

  return (
    <div className={ `eop-text-textarea ${ className }` }>
      <div className='eop-text-textarea__label'>
        { required && <span className='required-icon' /> }
        { label && fieldLabel }
      </div>
      <div className='eop-text-textarea__input'>
        <textarea
          rows={ rows }
          readOnly={ readOnly }
          placeholder={ fieldPlacheholder }
          defaultValue={ defaultValue }
          disabled={ disabled }
          { ...register(name, {
            required: requiredError,
            maxLength: maxLengthError,
            minLength: minLengthError,
          }) }
        />
      </div>
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

export default InputTextArea;
