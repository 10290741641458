import { AdminEventFilters } from '@src/js/reducers/events/adminEvent';
import { Action } from 'redux';

export const ADMIN_GET_EVENT_FORM_OPTIONS_START = 'ADMIN_GET_EVENT_FORM_OPTIONS_START';
export const ADMIN_GET_EVENT_FORM_OPTIONS_SUCCESS = 'ADMIN_GET_EVENT_FORM_OPTIONS_SUCCESS';
export const ADMIN_GET_EVENT_FORM_OPTIONS_ERROR = 'ADMIN_GET_EVENT_FORM_OPTIONS_ERROR';

export const ADMIN_GET_EVENTS_START = 'ADMIN_GET_EVENTS_START';
export const ADMIN_GET_EVENTS_ERROR = 'ADMIN_GET_EVENTS_ERROR';
export const ADMIN_GET_EVENTS_SUCCESS = 'ADMIN_GET_EVENTS_SUCCESS';

export const ADMIN_GET_EVENTS_FILTER_OPTIONS_START = 'ADMIN_GET_EVENTS_FILTER_OPTIONS_START';
export const ADMIN_GET_EVENTS_FILTER_OPTIONS_ERROR = 'ADMIN_GET_EVENTS_FILTER_OPTIONS_ERROR';
export const ADMIN_GET_EVENTS_FILTER_OPTIONS_SUCCESS = 'ADMIN_GET_EVENTS_FILTER_OPTIONS_SUCCESS';

export const ADMIN_POST_EVENTS_REMINDER_START = 'ADMIN_POST_EVENTS_REMINDER_START';
export const ADMIN_POST_EVENTS_REMINDER_SUCCESS = 'ADMIN_POST_EVENTS_REMINDER_SUCCESS';
export const ADMIN_POST_EVENTS_REMINDER_ERROR = 'ADMIN_POST_EVENTS_REMINDER_ERROR';

export const ADMIN_CLEAR_REMINDER = 'ADMIN_CLEAR_REMINDER';

export interface AdminGetEventsAction extends Action {
  filters?: AdminEventFilters
}

export interface AdminGetEventFormOptionsAction extends Action {
  slug?: string
}

export interface AdminGetEventFilterOptionsAction extends Action {
  filterOptions: any
}

export interface AdminReminderEventAction extends Action {
  slug: string;
  registered: boolean;
  templateId: number;
  scheduling?: object;
}

export function getEventFormOptions(slug?: string): AdminGetEventFormOptionsAction {
  return {
    type: ADMIN_GET_EVENT_FORM_OPTIONS_START,
    slug,
  };
}

export function getAdminEvents(filters?: AdminEventFilters): AdminGetEventsAction {
  return {
    type: ADMIN_GET_EVENTS_START,
    filters,
  };
}

export function getAdminEventsFilterOptions(filterOptions: object): AdminGetEventFilterOptionsAction {
  return {
    type: ADMIN_GET_EVENTS_FILTER_OPTIONS_START,
    filterOptions,
  };
}

export function reminderEvent(
  slug: string,
  registered: boolean,
  templateId: number,
  scheduling?: object
): AdminReminderEventAction {
  return {
    type: ADMIN_POST_EVENTS_REMINDER_START,
    slug,
    registered,
    templateId,
    scheduling,
  };
}

export function schedulingDone() {
  return {
    type: ADMIN_CLEAR_REMINDER,
  };
}
