import DataTable, { TableColDef } from '@app/components/global/list/DataTable';
import { Library } from '@app/models';
import { adminGetLibrary } from '@src/js/actions/library/adminLibrary';
import { Button } from '@src/js/components/global';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import LanguageFlags from '@app/components/global/list/LanguageFlags';
import { RootState } from '@src/js/store';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import ActionButton from './ActionButton';

const columnsDef = (lang: string, values: Library[], setValues: (v: Library[]) => void): TableColDef<Library>[] => [
  {
    field: 'title', headerName: 'title', width: 50,
  },
  {
    field: 'date', headerName: 'date', width: 15,
  },
  {
    field: 'language',
    headerName: 'language',
    width: 15,
    component: (item) => <LanguageFlags languages={ item.languages } />,
  },
  {
    field: `details.${ lang }.libraries`,
    headerName: '',
    width: 20,
    component: (item) => <ActionButton item={ item } values={ values } setValues={ setValues } />,
  },
];

type Filters = {
  page: number,
  search: string,
  status: number,
  limit: number,
  excludedIds: number[],
  videoOnDemand: boolean,
}

type Props = {
  lang: string;
  addValues: (values: Library[]) => void;
  excludedIds: number[];
  videoOnDemand: boolean;
};

const PageSize = 5;

const LibraryModal = ({
  lang, addValues, excludedIds, videoOnDemand = false,
}: Props) => {
  const [values, setValues] = useState<Library[]>([]);
  const columns = columnsDef(lang, values, setValues);
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const intl = useIntl();
  const [filters, setFilters] = useState<Filters>({
    page: 1, search: '', status: 2, limit: PageSize, excludedIds, videoOnDemand,
  });

  const { indexLibrary, loading } = useSelector<RootState, any>((state) => state.adminLibrary);

  const confirm = () => {
    addValues(values);
    hideModal();
  };

  useEffect(() => {
    dispatch(adminGetLibrary(filters));
  }, [filters, dispatch]);

  const searchPlaceholder = intl.formatMessage({ id: 'search' });

  return (
    <ModalBody>
      <div className='grid-container__search grid-col-3 m-t-4'>
        <DebounceInput
          debounceTimeout={ 500 }
          placeholder={ searchPlaceholder }
          onChange={ (e) => setFilters({ ...filters, page: 1, search: e.target.value }) }
          value={ filters.search }
          className='search-input'
        />
      </div>
      <DataTable
        page={ indexLibrary?.meta?.pagination?.page || 1 }
        columns={ columns }
        items={ indexLibrary?.data || [] }
        total={ indexLibrary?.meta?.pagination?.totalCount || 0 }
        pageSize={ indexLibrary?.meta?.pagination?.perPage || PageSize }
        loading={ loading }
        handlePage={ (page) => setFilters({ ...filters, page }) }
      />
      <ModalFooter>
        <Button
          size='md'
          buttonText='dialog.confirm'
          onClickFunc={ confirm }
        />
        <Button
          size='md'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ hideModal }
        />
      </ModalFooter>
    </ModalBody>
  );
};

export default LibraryModal;
