export const FORM_STEPS = {
  COMPANY_SUMMARY: 'companySummary',
  COMPANY_PROFILE: 'companyProfile',
  DIRECTORS: 'directors',
  FUNDING_HISTORY: 'fundingHistory',
  DECLARATIONS: 'declarations',
  CLIENT_CATEGORIZATION: 'clientCategorization',
  FINAL_SECTION: 'finalSection',
  AML_GENERAL: 'amlGeneralReview',
  AML_CHECKLIST: 'amlCheckList',
};

export const USER_STEPS = [
  FORM_STEPS.COMPANY_SUMMARY,
  FORM_STEPS.COMPANY_PROFILE,
  FORM_STEPS.DIRECTORS,
  FORM_STEPS.FUNDING_HISTORY,
  FORM_STEPS.DECLARATIONS,
  FORM_STEPS.CLIENT_CATEGORIZATION,
  FORM_STEPS.FINAL_SECTION,
];

export const COMPLIANCE_STEPS = [
  FORM_STEPS.COMPANY_SUMMARY,
  FORM_STEPS.COMPANY_PROFILE,
  FORM_STEPS.DIRECTORS,
  FORM_STEPS.FUNDING_HISTORY,
  FORM_STEPS.DECLARATIONS,
  FORM_STEPS.CLIENT_CATEGORIZATION,
  FORM_STEPS.AML_GENERAL,
  FORM_STEPS.AML_CHECKLIST,
];

export const ADMIN_STEPS = [
  ...COMPLIANCE_STEPS,
  FORM_STEPS.FINAL_SECTION,
];
