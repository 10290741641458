import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const getTextColor = props => {
  switch (props.type) {
    case 'red':
      return '#fe4d6a';
    case 'disabled':
      return '#D1D2D6';
    case 'green':
      return '#009639';
    default:
      return '#009DCD';
  }
};

const getCursor = props => (props.disabled ? 'default' : 'pointer');

export const NavLinkStyled = styled(NavLink)`
  color: inherit;
  text-decoration: inherit;
`;

export const Container = styled.span(props => ({
  color: getTextColor(props),
  display: 'inline-block',
  textTransform: 'uppercase',
  fontSize: '1.4rem',
  fontWeight: '700',
  textDecoration: 'none',
  position: 'center',
  padding: '0 1rem',
  cursor: getCursor(props),
}));
