import React from 'react';
import { MultipleUploadStatus } from '@src/js/constants/entities';
import FileToUpload from '@app/models/FileToUpload';

type Props = {
  file: FileToUpload,
}

const MultipleDocumentCard = ({ file }: Props) => {
  const status = file.getStatus();
  const progress = file.getProgress();
  const name = file.getFileName();
  const size = file.getFileSize();
  const nameArray = name.split('.');
  const type = nameArray[nameArray.length - 1];

  return (
    <div
      className={ `fs-node__primary-infos--file multiple-uploading-modal__file bg-${
        status === MultipleUploadStatus.uploading ? progress : status
      }` }
    >
      <div className='fs-node__file-logo'>
        <small>{ type }</small>
        <span className='icon icon-small-Doc' />
      </div>
      <div className='multiple-uploading-modal__file-infos'>
        <h5 className='fs-node__title--file m-0' title={ name }>{ name }</h5>
        <p>{ size }</p>
      </div>
      { status === MultipleUploadStatus.uploading && progress > 0 && (
      <p className='multiple-uploading-modal__file-status'>
        { progress }
        %
      </p>
      ) }
      { status === MultipleUploadStatus.uploaded && (
      <span className='status-icon icomoon-checkmark' />
      ) }
      { status === MultipleUploadStatus.cancelled && (
      <span className='status-icon icomoon-minus' />
      ) }
      { status === MultipleUploadStatus.failed && (
      <span className='status-icon icomoon-cross' />
      ) }
    </div>
  );
};

export default MultipleDocumentCard;
