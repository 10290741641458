export const GET_FEATURED_OPPORTUNITY_START = 'GET_FEATURED_OPPORTUNITY_START';
export const GET_FEATURED_OPPORTUNITY_ERROR = 'GET_FEATURED_OPPORTUNITY_ERROR';
export const GET_FEATURED_OPPORTUNITY_SUCCESS = 'GET_FEATURED_OPPORTUNITY_SUCCESS';

export function getFeaturedOpportunities(filters) {
  return {
    type: GET_FEATURED_OPPORTUNITY_START,
    filters,
  };
}
