import React from 'react';
import { ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Button } from '@src/js/components/global';
import { Images } from '@src/js/constants/images';
import { purifyHtml } from '@src/js/utils/index';
import { SEND_CONTACT_REQUEST } from '@app/store/etinerary/constants';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { useIntl } from 'react-intl';

const EtineraryContactModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const userInfo = selectUserInfo();
  const { hideModal } = useGlobalModalContext();

  const disabledButton = userInfo?.etineraryContacted === true;

  const submit = () => {
    dispatch({ type: SEND_CONTACT_REQUEST });
    hideModal();
  };
  return (
    <ModalBody>
      <div className='etinerary-contact-modal__wrapper'>
        <div className='etinerary-contact-modal__top'>
          <div
            className='etinerary-contact-modal__content blue-a'
            dangerouslySetInnerHTML={ {
              __html: purifyHtml(intl.formatMessage({ id: 'etinerary.contact_modal.header' })),
            } }
          />
        </div>
        <div className='etinerary-contact-modal__container'>
          <div className='etinerary-contact-modal__left'>
            <div
              className='etinerary-contact-modal__content blue-a'
              dangerouslySetInnerHTML={ {
                __html: purifyHtml(intl.formatMessage({ id: 'etinerary.contact_modal.content' })),
              } }
            />
            <Button
              className='etinerary-contact-modal__button'
              size='lg'
              buttonText={ disabledButton ? 'etinerary.modal.buttonInvited' : 'funding.questions.body' }
              onClickFunc={ submit }
              disabled={ disabledButton }
            />
          </div>
          <div className='etinerary-contact-modal__right'>
            <div className='image-wrapper'>
              <img src={ Images.ETINERARY_CONTACT } alt='etinerary-contact-modal' />
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
  );
};

export default EtineraryContactModal;
