import React, { useEffect } from 'react';
import { showToastrError } from '@app/store/global/actions';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { formatBytes } from '@src/js/utils';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Button from '@src/js/components/global/buttons/Buttons';
import { ModalBody } from 'reactstrap';
import { FileCard } from '@src/js/views/user/pages/Onboarding/OnboardingProcess/components/styled';
import FilePreview from '@src/js/components/global/cards/FilePreview';
import { FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@app/components/global/forms';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { Loader } from '@src/js/components/global';
import { clearFile, uploadFile } from '@src/js/actions/file/uploadFile';
import { sendMessage } from '@app/store/chat/chatApi';
import { MessageBody, sendGAEvent, Thread } from '@app/models/Chat';
import { RootState, useAppDispatch } from '@src/js/store';
import { useLocation } from 'react-router-dom';
import { selectUploadFile } from '@app/store/company/selectors';
import { fileConstants } from '@src/js/constants/entities';

type Props = {
  thread: Thread | null;
  file?: File;
  type: 'image' | 'document';
};

type AttachmentBody = MessageBody & ({
  documentId?: number,
  imageId?: number,
})

const FileAttachmentModal = ({ thread, file, type }: Props) => {
  const dispatch = useAppDispatch();
  const { hideModal } = useGlobalModalContext();
  const loggedUserId = useSelector<RootState, string>(state => state.initInfo.userInfo.uuid);
  const location = useLocation();
  const { file: uploadedAttachment, loading, error } = selectUploadFile();

  const formMethods = useForm<{ description: string }>({ mode: 'all', defaultValues: {} });
  const {
    formState: { errors }, handleSubmit, register,
  } = formMethods;

  const uploadAttachment = (f: File) => {
    const formData = new FormData();
    formData.append('file', f);
    dispatch(uploadFile({ file: formData }, 'message', type));
  };

  const onFileChange = (files: File[]) => {
    if (!files || files.length === 0) return;
    uploadAttachment(files[0]);
  };

  useEffect(() => {
    if (file) {
      uploadAttachment(file);
    }
  }, [file]);

  const bodyHtml = (description = ''):string => {
    if (!uploadedAttachment) return '';
    if (type === 'image') {
      return `<p class="m-0 font-size-caption">${ description || uploadedAttachment.name }</p>
  <div class="d-flex file-preview-card">
    <img class="m-r-1" src='${ uploadedAttachment.url }' />
    <a target="_blank" rel="noopener noreferrer" href="${ uploadedAttachment.url }">
      <span class="icomoon-arrow-down cursor-pointer"></span>
    </a>
  </div>`;
    }
    return `<p class="m-0 font-size-caption">${ description || uploadedAttachment.name }</p>
  <div class="card file-preview-card d-flex m-t-1">
    <img src="/assets/img/svg/icons/Big/Doc.svg" alt="Document" />
    <p class="m-0 m-x-1 font-size-caption">${ uploadedAttachment.name }</p>
    <a target="_blank" rel="noopener noreferrer" href="${ uploadedAttachment.url }">
      <span class="icomoon-arrow-down cursor-pointer"></span>
    </a>
  </div>`;
  };

  const onSubmit = (attachmentFormData: { description: string }) => {
    if (!uploadedAttachment || !thread) return;

    const subjectValue = thread && thread.participantImages.length > 1 ? thread.subject : '';
    const recipients = thread?.participants.map(p => p.uuid) || [];
    const body: AttachmentBody = {
      body: '',
      recipient_uids: recipients,
      subject: subjectValue,
    };

    if (type === 'image') body.imageId = uploadedAttachment.id;
    if (type === 'document') body.documentId = uploadedAttachment.id;

    body.body = bodyHtml(attachmentFormData.description);

    dispatch(sendMessage({ threadId: thread.id, body }));
    sendGAEvent(recipients, loggedUserId, location.pathname);
    hideModal();
  };

  useEffect(() => () => {
    hideModal();
    dispatch(clearFile());
  }, []);

  if (uploadedAttachment && !error) {
    return (
      <ModalBody className='file-attachment'>
        <p className='file-attachment__title'>
          <FormattedMessage id={ `chat.attachment.uploaded.${ type }` } />
        </p>
        <FormProvider { ...formMethods }>
          <form onSubmit={ handleSubmit(onSubmit) }>
            { type === 'image' && (
              <img src={ uploadedAttachment.url } alt='attachment' />
            )}
            { type === 'document' && (
              <FileCard xs={ 12 } sm={ 6 } className='card file-attachment__card'>
                <FilePreview
                  file={ uploadedAttachment }
                  arrow={ false }
                  removeFileAction={ () => dispatch(clearFile()) }
                />
              </FileCard>
            )}
            <InputText
              name='description'
              errors={ errors }
              register={ register }
              label='company_form.description'
            />
            <div className='d-flex justify-content-between m-t-4'>
              <Button
                buttonText='cancel'
                emptyButtonType='empty-blue'
                onClickFunc={ hideModal }
              />
              <Button buttonText='send' buttonType='submit' />
            </div>
          </form>
        </FormProvider>
      </ModalBody>
    );
  }

  return (
    <ModalBody className='file-attachment__upload'>
      <div className='multi-upload-modal__wrapper'>
        { loading && <Loader />}
        <Dropzone
          accept={ type === 'image'
            ? dropzoneExtensions.CHAT_IMAGE_MIMETYPE : dropzoneExtensions.CHAT_DOCUMENT_MIMETYPE }
          maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
          onDrop={ onFileChange }
          onDropRejected={ () => dispatch(showToastrError()) }
          multiple={ true }
          useFsAccessApi={ false }
        >
          {({ getRootProps, getInputProps }) => (
            <div { ...getRootProps() }>
              <input { ...getInputProps() } />
              <p className='title'>
                <FormattedMessage id={ `chat.attachment.${ type }` } />
              </p>
              <span className='icomoon-cloud-upload' />
              <p className='multi-upload-modal__drag-and-drop'>
                <FormattedMessage id='multiple_upload.dragAndDrop' />
              </p>
              <p className='multi-upload-modal__drag-and-drop'>
                <FormattedMessage id='multiple_upload.or' />
              </p>
              <span className='upload-card__placeholder-button'>
                <Button buttonText='upload_browse' />
              </span>
              <p className='multi-upload-modal__upload-parameters m-b-0'>
                <FormattedMessage id='multiple_upload.maxSize' />
                { formatBytes(fileConstants.UPLOAD_SIZE_LIMIT) }
              </p>
            </div>
          )}
        </Dropzone>
      </div>
    </ModalBody>
  );
};

export default FileAttachmentModal;
