import { takeLatest, call, put } from 'redux-saga/effects';
import * as api from '@src/js/marketplace/api/admin/businessNeedTable';

import {
  GET_BUSINESSNEED_TABLE_DATA_START,
  GET_ADMIN_BUSINESSNEED_START,
  getBusinessNeedTableDataSuccess,
  getBusinessNeedTableDataFail,
  getAdminBusinessNeedSuccess,
  getAdminBusinessNeedFail,
} from '@src/js/marketplace/actions/admin/businessNeedTable';

function* getBusinessNeedData(options) {
  try {
    const data = yield call(() => api.getBusinessNeedData(options.options));
    yield put(getBusinessNeedTableDataSuccess(data));
  } catch (error) {
    yield put(getBusinessNeedTableDataFail(error));
  }
}

function* getAdminBusinessNeeds({ id }) {
  try {
    const data = yield call(() => api.getAdminBusinessNeedsApi(id));
    yield put(getAdminBusinessNeedSuccess(data));
  } catch (error) {
    yield put(getAdminBusinessNeedFail(error));
  }
}

function* root() {
  yield takeLatest(GET_BUSINESSNEED_TABLE_DATA_START, getBusinessNeedData);
  yield takeLatest(GET_ADMIN_BUSINESSNEED_START, getAdminBusinessNeeds);
}

export default root;
