export const CREATE_TASK_START = 'CREATE_TASK_START';
export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';

export const GET_COMPANIES_FOR_TASK_CREATION_START = 'GET_COMPANIES_FOR_TASK_CREATION_START';
export const GET_COMPANIES_FOR_TASK_CREATION_ERROR = 'GET_COMPANIES_FOR_TASK_CREATION_ERROR';
export const GET_COMPANIES_FOR_TASK_CREATION_SUCCESS = 'GET_COMPANIES_FOR_TASK_CREATION_SUCCESS';

export const SAVE_COMPANIES_SLUGS = 'SAVE_COMPANIES_SLUGS';
export const EMPTY_COMPANIES_SLUGS = 'EMPTY_COMPANIES_SLUGS';

export const GET_COMPANIES_SLUG_FOR_TASK_CREATION_START = 'GET_COMPANIES_SLUG_FOR_TASK_CREATION_START';
export const GET_COMPANIES_SLUG_FOR_TASK_CREATION_ERROR = 'GET_COMPANIES_SLUG_FOR_TASK_CREATION_ERROR';
export const GET_COMPANIES_SLUG_FOR_TASK_CREATION_SUCCESS = 'GET_COMPANIES_SLUG_FOR_TASK_CREATION_SUCCESS';

export const GET_TASK_COMPANIES_LIST_START = 'GET_TASK_COMPANIES_LIST_START';
export const GET_TASK_COMPANIES_LIST_ERROR = 'GET_TASK_COMPANIES_LIST_ERROR';
export const GET_TASK_COMPANIES_LIST_SUCCESS = 'GET_TASK_COMPANIES_LIST_SUCCESS';

export const GET_TASKS_START = 'GET_TASKS_START';
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';

export const GET_TASK_START = 'GET_TASK_START';
export const GET_TASK_ERROR = 'GET_TASK_ERROR';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';

export const GET_TASK_COMMENTS_START = 'GET_TASK_COMMENTS_START';
export const GET_TASK_COMMENTS_ERROR = 'GET_TASK_COMMENTS_ERROR';
export const GET_TASK_COMMENTS_SUCCESS = 'GET_TASK_COMMENTS_SUCCESS';

export const GET_TASK_DOCUMENTS_START = 'GET_TASK_DOCUMENTS_START';
export const GET_TASK_DOCUMENTS_ERROR = 'GET_TASK_DOCUMENTS_ERROR';
export const GET_TASK_DOCUMENTS_SUCCESS = 'GET_TASK_DOCUMENTS_SUCCESS';

export const ADD_TASK_DOCUMENT_START = 'ADD_TASK_DOCUMENT_START';
export const ADD_TASK_DOCUMENT_ERROR = 'ADD_TASK_DOCUMENT_ERROR';
export const ADD_TASK_DOCUMENT_SUCCESS = 'ADD_TASK_DOCUMENT_SUCCESS';

export const ADD_TASK_COMMENT_START = 'ADD_TASK_COMMENT_START';
export const ADD_TASK_COMMENT_ERROR = 'ADD_TASK_COMMENT_ERROR';
export const ADD_TASK_COMMENT_SUCCESS = 'ADD_TASK_COMMENT_SUCCESS';

export const REMOVE_TASK_DOCUMENT_START = 'REMOVE_TASK_DOCUMENT_START';
export const REMOVE_TASK_DOCUMENT_ERROR = 'REMOVE_TASK_DOCUMENT_ERROR';
export const REMOVE_TASK_DOCUMENT_SUCCESS = 'REMOVE_TASK_DOCUMENT_SUCCESS';

export const GET_TASKS_PERMISSIONS_START = 'GET_TASKS_PERMISSIONS_START';
export const GET_TASKS_PERMISSIONS_ERROR = 'GET_TASKS_PERMISSIONS_ERROR';
export const GET_TASKS_PERMISSIONS_SUCCESS = 'GET_TASKS_PERMISSIONS_SUCCESS';

export const GET_TASKS_HEADER_START = 'GET_TASKS_HEADER_START';
export const GET_TASKS_HEADER_ERROR = 'GET_TASKS_HEADER_ERROR';
export const GET_TASKS_HEADER_SUCCESS = 'GET_TASKS_HEADER_SUCCESS';

export const SET_TASK_STATUS_START = 'SET_TASK_STATUS_START';
export const SET_TASK_STATUS_ERROR = 'SET_TASK_STATUS_ERROR';
export const SET_TASK_STATUS_SUCCESS = 'SET_TASK_STATUS_SUCCESS';

export const EMPTY_TASK_DOCUMENTS = 'EMPTY_TASK_DOCUMENTS';

export const ADD_UPLOADED_DOCUMENT = 'ADD_UPLOADED_DOCUMENT';

export const CLEAR_TASK_LIST = 'CLEAR_TASK_LIST';

export function createTask(task) {
  return {
    type: CREATE_TASK_START,
    task,
  };
}

export function getTaskListAction(filters) {
  return {
    type: GET_TASKS_START,
    filters,
  };
}

export function getTaskCompaniesList(taskSlug, companiesSlugs, page = 1, searchParam = null) {
  return {
    type: GET_TASK_COMPANIES_LIST_START,
    taskSlug,
    companiesSlugs,
    page,
    searchParam,
  };
}

export function getCompaniesForTaskCreation(filter = null) {
  return {
    type: GET_COMPANIES_FOR_TASK_CREATION_START,
    filter,
  };
}

export function saveCompaniesSlugs(companySlugs) {
  return {
    type: SAVE_COMPANIES_SLUGS,
    companySlugs,
  };
}

export function emptyCompaniesSlugs() {
  return {
    type: EMPTY_COMPANIES_SLUGS,
  };
}

export function getCompaniesSlugsForTaskCreation(filter = null) {
  return {
    type: GET_COMPANIES_SLUG_FOR_TASK_CREATION_START,
    filter,
  };
}

export function getTaskDocuments(documentsIds) {
  return {
    type: GET_TASK_DOCUMENTS_START,
    documentsIds,
  };
}

export function addTaskDocument(requestData) {
  return {
    type: ADD_TASK_DOCUMENT_START,
    requestData,
  };
}

export function addUploadedDocument(uploadedFileId) {
  return {
    type: ADD_UPLOADED_DOCUMENT,
    uploadedFileId,
  };
}

export function removeTaskDocumentAction(documentId) {
  return {
    type: REMOVE_TASK_DOCUMENT_START,
    documentId,
  };
}

export function emptyTaskDocuments() {
  return {
    type: EMPTY_TASK_DOCUMENTS,
  };
}

export function getTaskPermissions(company) {
  return {
    type: GET_TASKS_PERMISSIONS_START,
    company,
  };
}

export function setTaskStatus(task, statusName) {
  return {
    type: SET_TASK_STATUS_START,
    task,
    statusName,
  };
}

export function getTaskData(task) {
  return {
    type: GET_TASK_START,
    task,
  };
}
export function getHeaderData() {
  return {
    type: GET_TASKS_HEADER_START,
  };
}

export function getTaskComments(task) {
  return {
    type: GET_TASK_COMMENTS_START,
    task,
  };
}

export function addTaskComment(task, comment) {
  return {
    type: ADD_TASK_COMMENT_START,
    task,
    comment,
  };
}

export function clearTaskList() {
  return {
    type: CLEAR_TASK_LIST,
  };
}
