import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';
import {
  GET_TOPIC_LIST_START,
  GET_TOPIC_LIST_FAIL,
  GET_TOPIC_LIST_SUCCESS,
  GET_TOPIC_LIST_NAVIGATION_START,
  GET_TOPIC_LIST_NAVIGATION_FAIL,
  GET_TOPIC_LIST_NAVIGATION_SUCCESS,
  GET_TOPIC_START,
  GET_TOPIC_FAIL,
  GET_TOPIC_SUCCESS,
  UPDATE_TOPIC_START,
  UPDATE_TOPIC_FAIL,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_STATUS_START,
  UPDATE_TOPIC_STATUS_FAIL,
  UPDATE_TOPIC_STATUS_SUCCESS,
} from '@app/store/content/topic/actions';
import * as api from '@app/store/content/topic/api';

function* getTopicList({
  search, page, programmes, statuses,
}) {
  try {
    const result = yield call(() => api.getTopicList({
      page, search, programmes, statuses,
    }));
    yield put({ type: GET_TOPIC_LIST_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_TOPIC_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getTopicListNavigation() {
  try {
    const result = yield call(() => api.getTopicListNavigation());
    yield put({ type: GET_TOPIC_LIST_NAVIGATION_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_TOPIC_LIST_NAVIGATION_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getTopic({ id }) {
  try {
    const result = yield call(() => api.getTopic({ id }));
    yield put({ type: GET_TOPIC_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_TOPIC_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* updateTopic({ id, formData, history }) {
  try {
    yield call(() => (id
      ? api.updateTopic(id, formData)
      : api.createTopic(formData)
    ));
    yield all([
      put({ type: UPDATE_TOPIC_SUCCESS }),
      put(showToastrSuccess()),
    ]);
    history.push(withLang(adminRouteCodes.TOPIC));
  } catch (error) {
    yield all([
      put({ type: UPDATE_TOPIC_FAIL, error }),
      put(showToastrError('notification.error_title', error.response?.description || 'notification.submit.error')),
    ]);
  }
}

function* updateTopicStatus({ id, status }) {
  try {
    yield call(() => api.updateTopicStatus({ id, status }));
    yield all([
      put({ type: UPDATE_TOPIC_STATUS_SUCCESS, id, status }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: UPDATE_TOPIC_STATUS_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_TOPIC_LIST_START, getTopicList);
  yield takeLatest(GET_TOPIC_LIST_NAVIGATION_START, getTopicListNavigation);
  yield takeLatest(GET_TOPIC_START, getTopic);
  yield takeLatest(UPDATE_TOPIC_START, updateTopic);
  yield takeLatest(UPDATE_TOPIC_STATUS_START, updateTopicStatus);
}

export default root;
