import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { purifyHtml } from '@src/js/utils';
import { Warning } from './style';

export default class Checkbox extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    fieldLabel: PropTypes.string,
    additionalClass: PropTypes.string,
    fieldType: PropTypes.string,
    fieldLabelValues: PropTypes.object,
    fieldName: PropTypes.string.isRequired,
    fieldDisabled: PropTypes.bool,
    translate: PropTypes.bool,
    required: PropTypes.bool,
    validationMessageOnly: PropTypes.bool,
    isLocked: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    translate: true,
    additionalClass: '',
  };

  renderFieldLabel = () => {
    const { translate, fieldLabel, fieldLabelValues } = this.props;

    return translate
      ? (
        <FormattedMessage id={ fieldLabel } values={ fieldLabelValues }>
          { innerHTMLMessage => (
            <span
              className='blue-a'
              dangerouslySetInnerHTML={ { __html: purifyHtml(innerHTMLMessage) } }
            />
          ) }
        </FormattedMessage>
      ) : <span>{ fieldLabel }</span>;
  };

  render() {
    const {
      input, meta, fieldLabel, fieldName, fieldDisabled, required,
      validationMessageOnly, isLocked, fieldType, onChange, additionalClass,
    } = this.props;

    let validationErrorId = '';

    if (meta) {
      const validationError = (meta.error || meta.submitError);
      if (validationError) {
        validationErrorId = (validationError.id || validationError);
      }
    }

    const radioInput = {};
    if (onChange) {
      radioInput.onChange = onChange;
    }

    return (
      <div className={ `eop-checkbox ${ additionalClass }` }>
        <div className='d-flex'>
          <input
            type={ fieldType || 'checkbox' }
            { ...input }
            { ...radioInput }
            name={ fieldName }
            disabled={ fieldDisabled }
            required={ required }
          />
          <label>
            { required && <span className='required-icon' /> }
            { fieldLabel && this.renderFieldLabel() }
            { isLocked && (<span className='eop-text-input-input-icon icon-LockerSmall m-l-1' />) }
          </label>
        </div>
        { meta && (
          <p className='eop-checkbox__helper'>
            <Warning>
              { (meta.error || (meta.submitError && meta.dirtySinceLastSubmit === false))
                && (meta.touched || (
                  validationMessageOnly
                  && (meta.dirty || meta.dirtySinceLastSubmit)
                  && meta.submitFailed
                ))
              && validationErrorId && (
              <FormattedMessage id={ validationErrorId } />
              ) }
            </Warning>
          </p>
        ) }
      </div>
    );
  }
}
