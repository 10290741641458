import {
  GET_EXTERNAL_COMPANY_FORM_OPTIONS_START,
  GET_EXTERNAL_COMPANY_FORM_OPTIONS_ERROR,
  GET_EXTERNAL_COMPANY_FORM_OPTIONS_SUCCESS,
  CLEAR_EXTERNAL_COMPANY_FORM_OPTIONS,
  SELF_REGISTRATION_CREATE_COMPANY_START,
  SELF_REGISTRATION_CREATE_COMPANY_ERROR,
  SELF_REGISTRATION_CREATE_COMPANY_SUCCESS,
} from '@src/js/actions/self-registration/company/externalCompanyFormOptions';

const initialState = {
  loading: false,
  error: null,
  company: null,
  companyFormOptions: null,
  createCompanyLoading: false,
  createCompanyError: null,
};

const actionsMap = {
  [GET_EXTERNAL_COMPANY_FORM_OPTIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    companyFormOptions: null,
  }),
  [GET_EXTERNAL_COMPANY_FORM_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    companyFormOptions: action.data,
  }),
  [GET_EXTERNAL_COMPANY_FORM_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [CLEAR_EXTERNAL_COMPANY_FORM_OPTIONS]: () => initialState,
  [SELF_REGISTRATION_CREATE_COMPANY_START]: (state) => ({
    ...state,
    createCompanyLoading: true,
    createCompanyError: null,
  }),
  [SELF_REGISTRATION_CREATE_COMPANY_ERROR]: (state, action) => ({
    ...state,
    createCompanyLoading: false,
    createCompanyError: action.error.response.errors,
  }),
  [SELF_REGISTRATION_CREATE_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    createCompanyLoading: false,
    createCompanyError: null,
    company: action.data.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
