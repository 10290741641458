import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import * as PropTypes from 'prop-types';
import { funding } from './constant';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import DesktopContentTab from './DesktopContentTab';

const EtineraryModal = ({ type }) => {
  const [tab, setTab] = useState(funding);
  useEffect(() => {
    if (type) setTab(type);
  }, [type]);

  return (
    <div className='etinerary-modal-row'>
      <div className='etinerary-modal-container'>
        <Header />
        <Body tab={ tab } type={ type } onSelectTab={ (it) => setTab(it) } />
        <Footer />
      </div>
      { !isMobile && <DesktopContentTab tab={ tab } /> }
    </div>
  );
};

EtineraryModal.propTypes = {
  type: PropTypes.string,
};

export default EtineraryModal;
