import { showToastrError } from '@app/store/global/actions';
import { ErrorMessage } from '@hookform/error-message';
import fetchResource from '@src/js/api/fetch-resource';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { fileConstants } from '@src/js/constants/entities';
import { Images } from '@src/js/constants/images';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

type Props = {
  name: string,
  label?: string,
  translate?: boolean,
  required?: boolean,
  className?: string,
  disabled?: boolean,
  url: string,
  defaultImage?: string,
  externalUrl?: boolean,
};

const InputImage = ({
  name, label, translate = true, required, disabled, className = '', url, defaultImage, externalUrl,
}: Props) => {
  const {
    control, formState: { errors }, setValue, watch,
  } = useFormContext<FieldValues>();
  const intl = useIntl();
  const dispatch = useDispatch();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const image = watch(name);

  const handleDrop = (files: File[]) => {
    if (files.length === 0) return null;

    const formData = new FormData();
    formData.append('image', files[0]);
    fetchResource(url, {
      method: 'POST',
      body: formData,
      noContentType: true,
      externalUrl,
    }).then(resp => {
      const response = resp?.image || resp;
      setValue(name, { ...response, url: URL.createObjectURL(files[0]) });
      return resp;
    }).catch(() => {
      dispatch(showToastrError('notification.error_title', 'notification.error_upload'));
    });

    return null;
  };
  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: dropzoneExtensions.IMAGES,
    maxSize: fileConstants.UPLOAD_SIZE_LIMIT,
    useFsAccessApi: false,
  });

  const loading = false;

  const handleDelete = () => setValue(name, null);

  return (
    <div className={ className } data-name={ name }>
      <p className='upload-caption'>
        { label && required && <span className='required-icon' /> }
        { label && fieldLabel }
      </p>
      <Controller
        name={ name }
        control={ control }
        rules={ { required: required ? 'global_form.error.required' : undefined } }
        render={ () => (
          <div className='image-input__container'>
            <div className='image-input__wrapper'>
              <div className='image-input'>
                <div className='image-input__image'>
                  <div { ...getRootProps({ style: { maxHeight: '100%', maxWidth: '100%' } }) }>
                    <input { ...getInputProps() } />
                    { loading && <Loader isOverlay={ false } /> }
                    { image
                      ? (
                        <div className='upload-card__content'>
                          <img src={ typeof image === 'object' ? image.url : image } alt={ name } />
                        </div>
                      ) : (
                        <div className='upload-card__placeholder'>
                          <img src={ defaultImage || Images.DocImg } alt='' />
                        </div>
                      ) }
                  </div>
                </div>
                { !disabled && (
                <div
                  className='image-input__button'
                  onClick={ open }
                  role='presentation'
                >
                  <span className='icon icon-small-Edit' />
                </div>
                ) }
                { image && !loading && !disabled && (
                <div
                  onClick={ handleDelete }
                  className='image-input__button image-input__button--remove'
                  role='presentation'
                >
                  <span className='icon icon-small-Close' />
                </div>
                ) }
              </div>
              <ErrorMessage
                errors={ errors }
                name={ name }
                render={ ({ message }) => (
                  <span className='error-message'>
                    {intl.formatMessage({ id: message })}
                  </span>
                ) }
              />
            </div>
          </div>
        ) }
      />

    </div>
  );
};

export default InputImage;
