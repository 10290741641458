import {
  takeLatest, put, call, all,
} from 'redux-saga/effects';
import {
  getAdminBusinessNeedRequest,
} from '@src/js/marketplace/actions/admin/businessNeedTable';
import {
  clearFilters,
  getBusinessNeeds,
} from '@src/js/marketplace/actions/businessNeeds';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions/index';
import {
  EDIT_BUSINESS_NEED_START,
  EDIT_BUSINESS_NEED_ERROR,
  EDIT_BUSINESS_NEED_SUCCESS,
  CREATE_BUSINESS_NEED_SUCCESS,
  CREATE_BUSINESS_NEED_ERROR,
  CREATE_BUSINESS_NEED_START,
  GET_BUSINESS_NEED_SECTORS_START,
  GET_BUSINESS_NEED_SECTORS_ERROR,
  GET_BUSINESS_NEED_SECTORS_SUCCESS,
} from '@src/js/marketplace/actions/modal/businessNeedModal';
import * as api from '@src/js/marketplace/api/modal/businessNeed';

function* createBusinessNeed({
  businessNeed, isAdmin,
}) {
  try {
    const data = yield call(() => api.createBusinessNeed(businessNeed, isAdmin));
    yield put({ type: CREATE_BUSINESS_NEED_SUCCESS, data });
    if (isAdmin) {
      yield put(getAdminBusinessNeedRequest(businessNeed.UserId));
    } else {
      yield put(getBusinessNeeds());
    }

    yield put(showToastrSuccess('notification.success_title', 'marketplace.modal.success.create'));
  } catch (error) {
    yield all([
      put(showToastrError()),
      put({ type: CREATE_BUSINESS_NEED_ERROR, error }),
    ]);
  }
}

function* editBusinessNeed({
  businessNeedData, isAdmin, showToastr,
}) {
  try {
    const data = yield call(() => api.editBusinessNeed(businessNeedData, isAdmin));
    yield put({ type: EDIT_BUSINESS_NEED_SUCCESS, data });
    if (isAdmin) {
      yield put(getAdminBusinessNeedRequest(businessNeedData.UserId));
    } else {
      yield put(getBusinessNeeds());
    }
    yield put(clearFilters());
    if (showToastr) {
      yield put(showToastrSuccess());
    }
  } catch (error) {
    yield all([
      put(showToastrError()),
      put({ type: EDIT_BUSINESS_NEED_ERROR, error }),
    ]);
  }
}

function* getBusinessNeedSectors() {
  try {
    const data = yield call(() => api.getBusinessNeedSectors());
    yield put({ type: GET_BUSINESS_NEED_SECTORS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_BUSINESS_NEED_SECTORS_ERROR, error });
  }
}

function* createBusinessNeedWatcher() {
  yield takeLatest(CREATE_BUSINESS_NEED_START, createBusinessNeed);
}

function* editBusinessNeedWatcher() {
  yield takeLatest(EDIT_BUSINESS_NEED_START, editBusinessNeed);
}

function* getBusinessNeedSectorsWatcher() {
  yield takeLatest(GET_BUSINESS_NEED_SECTORS_START, getBusinessNeedSectors);
}

export default [
  createBusinessNeedWatcher(),
  editBusinessNeedWatcher(),
  getBusinessNeedSectorsWatcher(),
];
