import { useSelector } from 'react-redux';

export const selectFolderLoading = () => useSelector(state => state.dataroom?.folderLoading);
export const selectFileLoading = () => useSelector(state => state.dataroom?.fileLoading);
export const selectFolderListData = () => useSelector(state => state.dataroom?.folderListData);
export const selectFileListData = () => useSelector(state => state.dataroom?.fileListData);
export const selectFileListError = () => useSelector(state => state.dataroom?.fileListError);
export const selectPermissions = () => useSelector(state => state.dataroom?.permissions);
export const selectDataRoomList = () => useSelector(state => state.dataroom?.dataRoomList);
export const selectDataRoomListLoading = () => useSelector(state => state.dataroom?.dataRoomListLoading);
