import fetchResource from '@src/js/api/fetch-resource';

const baseUrl = 'api/admin/catalogue';
const url = `${ baseUrl }/messages`;
const urlPost = `${ baseUrl }/message`;

export function getTranslations(filters) {
  return fetchResource(`${ url }?${ new URLSearchParams(filters) }`, {
    method: 'GET',
  });
}

export function sendTranslation(newTranslation) {
  return fetchResource(urlPost, {
    method: 'POST',
    body: newTranslation,
  });
}
