import { takeLatest, call, put } from 'redux-saga/effects';

import {
  DELETE_USER_IMAGE_START,
  DELETE_USER_IMAGE_ERROR,
  DELETE_USER_IMAGE_SUCCESS,
} from '@src/js/actions/image/userImage';

import * as api from '@src/js/api/image/userImage';

function* deleteUserImage(options) {
  try {
    const data = yield call(() => api.deleteUserImage(options.id));
    yield put({ type: DELETE_USER_IMAGE_SUCCESS, data });
  } catch (error) {
    yield put({ type: DELETE_USER_IMAGE_ERROR, error });
  }
}

function* deleteUserImageWatcher() {
  yield takeLatest(DELETE_USER_IMAGE_START, deleteUserImage);
}

export default [
  deleteUserImageWatcher(),
];
