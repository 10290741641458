import { loadSingleThread, loadUnreadThreads } from '@app/store/chat/chatApi';
import { addSocketMessage, selectThreadsList, setSocketUnreadThreads } from '@app/store/chat/chatSlice';
import { setTaskSocketNotificationNumber } from '@src/js/actions/tasks/socketTaskNotifications';
import { isComplianceUserRole } from '@src/js/helpers/helpers';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { useAppDispatch } from '@src/js/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';

type Props = {
  userId: string,
}
const websocketUrl = loadEnvVariable('MESSAGING_WEBSOCKET_URL') || '';

const WebsocketComponent = ({ userId }: Props) => {
  const dispatch = useAppDispatch();
  const threads = useSelector(selectThreadsList);

  const onMessage = (event: MessageEvent<string>) => {
    const msg = JSON.parse(event.data);
    const data = msg.data ? JSON.parse(msg.data) : null;
    switch (msg.channel) {
      case `/messages/user/${ userId }`:
        dispatch(addSocketMessage(data));
        if (threads.findIndex(t => t.id === data.threadId) === -1) {
          dispatch(loadSingleThread({ threadId: data.threadId }));
        }
        break;
      case `/messages/unread-threads/user/${ userId }`:
        dispatch(setSocketUnreadThreads(data));
        break;
      case `/tasks/notification/user/${ userId }`:
        dispatch(setTaskSocketNotificationNumber(data));
        break;
      default:
        break;
    }
  };
  const { sendMessage, readyState } = useWebSocket(`${ websocketUrl }`, {
    onMessage,
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: 10000,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (!isComplianceUserRole()) dispatch(loadUnreadThreads());
  }, [dispatch]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage(JSON.stringify({
        action: 'subscribe',
        channel: `/messages/user/${ userId }`,
      }));
      sendMessage(JSON.stringify({
        action: 'subscribe',
        channel: `/messages/unread-threads/user/${ userId }`,
      }));
      sendMessage(JSON.stringify({
        action: 'subscribe',
        channel: `/tasks/notification/user/${ userId }`,
      }));
    }
  }, [readyState, sendMessage, userId]);

  return null;
};

export default WebsocketComponent;
