import {
  GET_BULK_MESSAGE_COMPANIES_START,
  GET_BULK_MESSAGE_COMPANIES_ERROR,
  GET_BULK_MESSAGE_COMPANIES_SUCCESS,
} from '@src/js/actions/bulkMessage/bulkMessageGetCompanies';

const initialState = {
  loading: false,
  error: null,
  companies: {},
};

const actionsMap = {
  [GET_BULK_MESSAGE_COMPANIES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    companies: {},
  }),
  [GET_BULK_MESSAGE_COMPANIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    companies: {},
  }),
  [GET_BULK_MESSAGE_COMPANIES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    companies: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const reducerFunction = actionsMap[action.type];
  return reducerFunction ? reducerFunction(state, action) : state;
}
