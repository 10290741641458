import { SET_TASK_SOCKET_NOTIFICATIONS } from '@src/js/actions/tasks/socketTaskNotifications';

const initialState = {
  taskNotificationNumber: null,
};

const actionsMap = {
  // Async action
  [SET_TASK_SOCKET_NOTIFICATIONS]: (state, action) => ({
    ...state,
    taskNotificationNumber: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
