import localeInfoEN from 'rc-pagination/lib/locale/en_US';
import localeInfoAR from 'rc-pagination/lib/locale/ar_EG';
import localeInfoFR from 'rc-pagination/lib/locale/fr_FR';
import localeInfoIT from 'rc-pagination/lib/locale/it_IT';
import localeInfoPT from 'rc-pagination/lib/locale/pt_PT';
import localeInfoES from 'rc-pagination/lib/locale/es_ES';

export function getPaginatorLocaleMessages(language) {
  /* eslint-disable id-length */
  const paginatorLabels = {
    en: localeInfoEN,
    ar: localeInfoAR,
    fr: localeInfoFR,
    it: localeInfoIT,
    pt: localeInfoPT,
    es: localeInfoES,
  };
  /* eslint-disable id-length */

  return paginatorLabels[language];
}
