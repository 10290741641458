import {
  ADD_TRAINING_REQUESTED,
  ADD_TRAINING_SUCCEEDED,
  ADD_TRAINING_FAILED,
  GET_TRAININGS_REQUESTED,
  GET_TRAININGS_SUCCEEDED,
  GET_TRAININGS_FAILED,
} from '@src/js/oneplatform/trainings/container/constants';

function getTrainingsRequested(filters) {
  return {
    type: GET_TRAININGS_REQUESTED,
    filters,
  };
}
function getTrainingsSucceeded(data) {
  return {
    type: GET_TRAININGS_SUCCEEDED,
    payload: data,
  };
}
function getTrainingsFailed(error) {
  return {
    type: GET_TRAININGS_FAILED,
    payload: error,
  };
}

function addTrainingRequested(payload) {
  return {
    type: ADD_TRAINING_REQUESTED,
    payload,
  };
}
function addTrainingSucceeded(payload) {
  return {
    type: ADD_TRAINING_SUCCEEDED,
    payload,
  };
}
function addTrainingFailed(error) {
  return {
    type: ADD_TRAINING_FAILED,
    payload: error,
  };
}

export {
  addTrainingRequested,
  addTrainingSucceeded,
  addTrainingFailed,
  getTrainingsRequested,
  getTrainingsSucceeded,
  getTrainingsFailed,
};
