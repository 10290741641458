export const GET_NEWS_OPTIONS_START = 'GET_NEWS_OPTIONS_START';
export const GET_NEWS_OPTIONS_ERROR = 'GET_NEWS_OPTIONS_ERROR';
export const GET_NEWS_OPTIONS_SUCCESS = 'GET_NEWS_OPTIONS_SUCCESS';
export const CLEAR_NEWS_OPTIONS = 'CLEAR_NEWS_OPTIONS';

export function getNewsOptions(slug) {
  return {
    type: GET_NEWS_OPTIONS_START,
    slug,
  };
}

export function clearNewsOptions() {
  return {
    type: CLEAR_NEWS_OPTIONS,
  };
}
