import '@src/css/global.scss';

import { Provider } from 'react-redux';
import { WindowManager } from 'react-window-decorators';
import { BrowserRouter as Router } from 'react-router-dom';

import React from 'react';
import ReactDOM from 'react-dom';
import ScrollToTop from '@app/components/global/ScrollToTop';
import App from '@src/js/views/App';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '@app/pages/ErrorPage';
import { store } from './store';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/ar';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/it';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/pt';
import '@formatjs/intl-relativetimeformat/locale-data/ar';

import { breakpoints } from './constants/breakpoints';

export const wm = new WindowManager([
  {
    name: 'mobile',
    media: '(min-width: 0)',
  },
  {
    name: 'desktop',
    media: `(min-width: ${ breakpoints.SMALL }px)`,
  },
], 50);

function startApp() {
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={ ErrorPage } onReset={ () => window.location.reload() }>
      <Provider store={ store }>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
}

startApp();
