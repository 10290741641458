import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_OPPORTUNITIES_FOR_COMPANY_START,
  GET_OPPORTUNITIES_FOR_COMPANY_ERROR,
  GET_OPPORTUNITIES_FOR_COMPANY_SUCCESS,
} from '@src/js/actions/marketplace/getOpportunitiesForCompany';
import * as api from '@src/js/api/marketplace/getOpportunitiesForCompany';

function* getOpportunitiesForCompany({ company, publisherType }) {
  try {
    const data = yield call(() => api.getOpportunitiesForCompany(company, publisherType));
    yield put({ type: GET_OPPORTUNITIES_FOR_COMPANY_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITIES_FOR_COMPANY_ERROR, error });
  }
}

function* getOpportunitiesForCompanyWatcher() {
  yield takeLatest(GET_OPPORTUNITIES_FOR_COMPANY_START, getOpportunitiesForCompany);
}

export default [
  getOpportunitiesForCompanyWatcher(),
];
