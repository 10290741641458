export const GET_COMPANY_PUBLIC_PROFILE_START = 'GET_COMPANY_PUBLIC_PROFILE_START';
export const GET_COMPANY_PUBLIC_PROFILE_ERROR = 'GET_COMPANY_PUBLIC_PROFILE_ERROR';
export const GET_COMPANY_PUBLIC_PROFILE_SUCCESS = 'GET_COMPANY_PUBLIC_PROFILE_SUCCESS';
export const CLEAR_COMPANY_PUBLIC_PROFILE = 'CLEAR_COMPANY_PUBLIC_PROFILE';
export const GET_COMPANY_BASIC_DATA_START = 'GET_COMPANY_BASIC_DATA_START';
export const GET_COMPANY_BASIC_DATA_ERROR = 'GET_COMPANY_BASIC_DATA_ERROR';
export const GET_COMPANY_BASIC_DATA_SUCCESS = 'GET_COMPANY_BASIC_DATA_SUCCESS';
export const GET_COMPANY_FINANCIALS_DATA_START = 'GET_COMPANY_FINANCIALS_DATA_START';
export const GET_COMPANY_FINANCIALS_DATA_ERROR = 'GET_COMPANY_FINANCIALS_DATA_ERROR';
export const GET_COMPANY_FINANCIALS_DATA_SUCCESS = 'GET_COMPANY_FINANCIALS_DATA_SUCCESS';
export const EDIT_COMPANY_START = 'EDIT_COMPANY_START';
export const EDIT_COMPANY_ERROR = 'EDIT_COMPANY_ERROR';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_ETINEARY_STATUS_START = 'EDIT_ETINERARY_STATUS_START';
export const EDIT_ETINEARY_STATUS_ERROR = 'EDIT_ETINERARY_STATUS_ERROR';
export const EDIT_ETINEARY_STATUS_SUCCESS = 'EDIT_ETINERARY_STATUS_SUCCESS';

export function getCompanyBasicData(slug) {
  return {
    type: GET_COMPANY_BASIC_DATA_START,
    slug,
  };
}

export function getCompanyFinancialsData(slug, lang) {
  return {
    type: GET_COMPANY_FINANCIALS_DATA_START,
    slug,
    lang,
  };
}

export function getCompanyPublicProfile(slug) {
  return {
    type: GET_COMPANY_PUBLIC_PROFILE_START,
    slug,
  };
}

export function clearCompanyPublicProfile() {
  return {
    type: CLEAR_COMPANY_PUBLIC_PROFILE,
  };
}

export function editCompany(company, userType) {
  return {
    type: EDIT_COMPANY_START,
    company,
    userType,
  };
}

export function editEtineraryStatus(slug, status) {
  return {
    type: EDIT_ETINEARY_STATUS_START,
    slug,
    status,
  };
}
