import { FormattedMessage } from 'react-intl';
import React from 'react';

import { Field } from 'react-final-form';
import DropZoneInput from '@app/components/global/forms/DropZoneInput';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { CertificateTypes, fileConstants } from '@src/js/constants/entities';

const CompanyFormCertificates = () => (
  <div className='edit-company-page edit-company-page__section'>
    <div className='row'>
      <div className='col-xs'>
        <div className='edit-company-page__title edit-user-page-tab-content-title'>
          <FormattedMessage id='company_form.eliteCertificates' />
        </div>
      </div>
    </div>
    <div className='flex flex-wrap'>
      {CertificateTypes.map((type) => (
        <div className='card col-12 col-md-6 p-y-2' key={ type }>
          <Field name={ `certificates.${ type }` }>
            {(fieldProps) => (
              <DropZoneInput
                { ...fieldProps.input }
                label={ `certificate.${ type }.title.short` }
                fileType='document'
                entity='company'
                accept={ dropzoneExtensions.PDF }
                maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
              />
            ) }
          </Field>
        </div>
      ))}

    </div>
  </div>
);

export default CompanyFormCertificates;
