import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Loader } from '@src/js/components/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DebounceInput } from 'react-debounce-input';
import { getPermissions, setPermissions } from '@app/store/documentsArea/actions';
import PropTypes from 'prop-types';
import { selectPermissions } from '@app/store/documentsArea/selectors';
import PermissionsItem from './PermissionsItem';

const PermissionsModal = ({
  slug, id, type, dataRoomType,
}) => {
  const dispatch = useDispatch();
  const permissions = selectPermissions();
  const usersLength = permissions?.data ? permissions?.data.length : 0;
  const [filters, setFilters] = useState({ offset: 0, search: '', reset: true });

  const loadUsers = (filter) => {
    const options = {
      ...filter, id, entity: type, dataRoomType, slug,
    };
    dispatch(getPermissions(options));
  };

  const nextUsers = () => {
    const nextPage = { ...filters, offset: filters.offset + 10, reset: false };
    setFilters(nextPage);
    loadUsers(nextPage);
  };

  useEffect(() => {
    loadUsers(filters);
  }, [id]);

  const handleSearchChange = (e) => {
    const newFilters = { offset: 0, search: e.target.value || '', reset: true };
    setFilters(newFilters);
    loadUsers(newFilters);
  };

  const grantPermissions = (user, permission) => {
    const data = { user, permission };
    dispatch(setPermissions(slug, id, dataRoomType, type, data));
  };

  return (
    <ModalBody className='m-y-4'>
      { permissions?.error && (
        <div className='message-error'>
          <FormattedMessage id='error' />
        </div>
      ) }
      <div className='search-peers'>
        <FormattedMessage id={ 'search' }>
          {
            placeholderMessage => (
              <DebounceInput
                debounceTimeout={ 500 }
                onChange={ handleSearchChange }
                placeholder={ placeholderMessage }
              />
            )
          }
        </FormattedMessage>
      </div>
      <div className='peers-list-container-members' id='peersContainer'>
        <InfiniteScroll
          dataLength={ usersLength }
          next={ nextUsers }
          hasMore={ permissions?.hasMore }
          loader={ <Loader position='relative' background='transparent' /> }
          scrollableTarget='peersContainer'
        >
          { permissions?.data?.map(user => (
            <PermissionsItem
              key={ user.uuid }
              user={ user }
              grantPermissions={ grantPermissions }
            />
          )) }
        </InfiniteScroll>
        { permissions?.data?.length === 0 && (
          <div className='no-data'>
            <FormattedMessage id='noResults' />
          </div>
        ) }
      </div>
    </ModalBody>
  );
};

PermissionsModal.propTypes = {
  slug: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  dataRoomType: PropTypes.string,
};

export default PermissionsModal;
