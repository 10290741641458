import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useIntl } from 'react-intl';
import {
  Control, Controller, FieldValues, RegisterOptions, Validate,
} from 'react-hook-form';
import { DebounceInput } from 'react-debounce-input';
import { validateLength, validatePattern, validateText } from './InputTextValidation';
import InputLabel from './partials/InputLabel';

type Props = {
  name: string,
  label?: string,
  type?: 'text'|'password'|'email'|'number'|'tel'|'url'|'percentage'|'textNumber'|'textEmail',
  control: Control<any>,
  translate?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  required?: boolean,
  integerOnly?: boolean,
  placeholder?: string,
  errors: object,
  maxLength?: number,
  minLength?: number,
  minValue?: number,
  maxValue?: number,
  className?: string,
  setValueAs?: (v: string|number) => string|number,
  tooltip?: string,
  validate?: Validate<any, FieldValues>,
  fixedPrefix?: string,
  autoComplete?: string;
}

const InputDebounce = ({
  name, label, type = 'text', translate = true, disabled = false, readonly, placeholder,
  required = false, errors, maxLength, minLength, minValue, maxValue, className = '',
  setValueAs, tooltip, integerOnly, validate, fixedPrefix, autoComplete, control,
}: Props) => {
  const intl = useIntl();
  const fieldPlacheholder = translate && placeholder ? intl.formatMessage({ id: placeholder }) : placeholder;

  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  const { maxLengthError, minLengthError } = validateLength({ maxLength, minLength, intl });

  const validatorObj: RegisterOptions = {
    required: requiredError,
    maxLength: maxLengthError,
    minLength: minLengthError,
    setValueAs,
    validate: validate || validateText({
      maxValue, minValue, type, integerOnly, intl,
    }),
  };

  if ((type === 'number' && setValueAs === undefined)) {
    validatorObj.valueAsNumber = true;
  } else {
    validatorObj.pattern = validatePattern({ intl, type });
  }

  return (
    <div className={ `eop-text-input ${ className }` }>
      <InputLabel
        name={ name }
        label={ label }
        tooltip={ tooltip }
        translate={ translate }
        required={ required }
      />
      <div className='eop-text-input__input'>
        { fixedPrefix && (
          <div className='eop-text-input__fixed-prefix'>{ fixedPrefix }</div>
        )}
        <Controller
          name={ name }
          control={ control }
          rules={ validatorObj }
          render={ ({ field }) => (
            <DebounceInput
              { ...field }
              className='eop-text-input__input-item'
              type={ type === 'percentage' || type === 'textNumber' || type === 'textEmail' ? 'text' : type }
              disabled={ disabled }
              readOnly={ readonly }
              placeholder={ fieldPlacheholder }
              autoComplete={ autoComplete }
              debounceTimeout={ 500 }
            />
          ) }
        />
        { (disabled || readonly) && <span className='eop-text-input__input-icon icon-LockerSmall' /> }
      </div>
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

export default InputDebounce;
