import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_NAVIGATION_START,
  GET_NAVIGATION_ERROR,
  GET_NAVIGATION_SUCCESS,
} from '@src/js/actions/navigation/navigation';

import * as api from '@src/js/api/navigation/navigation';

function* getNavigation() {
  try {
    const data = yield call(() => api.getNavigation());
    yield put({ type: GET_NAVIGATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NAVIGATION_ERROR, error });
  }
}

export function* getNavigationWatcher() {
  yield takeLatest(GET_NAVIGATION_START, getNavigation);
}

export default [
  getNavigationWatcher(),
];
