import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import PdfViewer from '@app/components/global/pdfViewer/PdfViewer';
import { ectPath } from '@src/js/constants/routes';

const DocumentViewerModal = ({ file, slug }) => {
  const [content, setContent] = useState();
  const url = `${ ectPath() }/api/media/file/${ file.training
    ? 'event' : 'dataroom' }/${ file.id }?slug=${ slug }`;

  useEffect(() => {
    if (file.type === 'pdf') {
      setContent(
        <PdfViewer
          file={ {
            url,
            withCredentials: true,
          } }
          alt={ `file-${ file.type }` }
        />
      );
    } else {
      setContent(<img className='documentviewer-modal__img' src={ url } alt={ `file-${ file.type }` } />);
    }
  }, []);

  return (
    <ModalBody
      className={ file.type !== 'pdf' ? 'documentviewer-modal--centered' : 'documentviewer-modal' }
    >
      {content}
    </ModalBody>
  );
};

DocumentViewerModal.propTypes = {
  file: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

export default DocumentViewerModal;
