import {
  ADMIN_GET_INDEX_NEWS_START,
  ADMIN_GET_INDEX_NEWS_ERROR,
  ADMIN_GET_INDEX_NEWS_SUCCESS,
} from '@src/js/actions/news/adminNews';

const initialState = {
  loading: false,
  error: null,
  indexNews: null,
};

const actionsMap = {
  [ADMIN_GET_INDEX_NEWS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_GET_INDEX_NEWS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_GET_INDEX_NEWS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    indexNews: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
