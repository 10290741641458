import {
  GET_USER_FORM_OPTIONS_START,
  GET_USER_FORM_OPTIONS_SUCCESS,
  GET_USER_FORM_OPTIONS_ERROR,
} from '@src/js/actions/user/formOptions';

const initialState = {
  loading: false,
  error: null,
  userFormOptions: null,
  companiesLoading: false,
  companiesError: null,
  companiesFormOptions: null,
};

const actionsMap = {
  [GET_USER_FORM_OPTIONS_START]: (state) => ({
    ...state,
    loading: true,
  }),
  [GET_USER_FORM_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    userFormOptions: action.data,
  }),
  [GET_USER_FORM_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
