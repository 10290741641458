import {
  CREATE_NEWS_START,
  CREATE_NEWS_ERROR,
  CREATE_NEWS_SUCCESS,
} from '@src/js/actions/news/createNews';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const actionsMap = {
  [CREATE_NEWS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [CREATE_NEWS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [CREATE_NEWS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
