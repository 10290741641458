import InputSwitch from '@app/components/global/forms/InputSwitch';
import { UserForm } from '@app/components/public/user/UserProfile';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

type Props = {
  name: string,
  label: string,
  disabled: boolean,
}

export const PreferencesItem = ({ name, label, disabled }: Props) => {
  const { control, formState: { errors } } = useFormContext<UserForm>();

  return (
    <div className={ `preferences__item${ disabled ? ' disabled' : '' }` }>
      <div className='preferences__item--left'>
        <div className='preferences__item--big'>
          <FormattedMessage id={ `user.preferences.${ label }` } />
        </div>
        <div className='preferences__item--regular'>
          <FormattedMessage id={ `user.preferences.${ label }Description` } />
        </div>
      </div>
      <div>
        <InputSwitch
          name={ name }
          disabled={ disabled }
          control={ control }
          errors={ errors }
        />
      </div>
    </div>
  );
};
