import produce from 'immer';

import {
  GET_MEMBERSHIP_COMPANY_REQUESTED,
  GET_MEMBERSHIP_COMPANY_SUCCEEDED,
  GET_MEMBERSHIP_COMPANY_FAILED,
  SAVE_MEMBERSHIP_PROCESS_REQUESTED,
  SAVE_MEMBERSHIP_PROCESS_SUCCEEDED,
  SAVE_MEMBERSHIP_PROCESS_FAILED,
  GET_ACTIVE_STEP,
  GET_FORM_STEP_REQUESTED,
  GET_FORM_STEP_SUCCEEDED,
  GET_FORM_STEP_FAILED,
  UPLOAD_FILE_REQUESTED,
  UPLOAD_FILE_SUCCEEDED,
  UPLOAD_FILE_FAILED,
  DOCUSIGN_MEMBERSHIP_START, DOCUSIGN_MEMBERSHIP_SUCCESS, DOCUSIGN_MEMBERSHIP_FAIL,
} from '@src/js/oneplatform/membership/pages/form/container/constants';

export const initialState = {
  membershipCompany: {
    loading: false,
    error: null,
    company: null,
    terms: null,
    initialized: null,
    option: {
      template: null,
      countries: [],
      members: [],
      translations: null,
    },
  },
  saveMembershipCompany: {
    loading: false,
    error: null,
    saved: false,
    created: false,
  },
  steps: [
    {
      id: 1, touched: true, valid: false, current: true,
    },
    {
      id: 2, touched: false, valid: false, current: false,
    },
    {
      id: 3, touched: false, valid: false, current: false,
    },
  ],

  uploadSignedDocument: {
    loading: false,
    error: null,
    file: null,
  },

  currentStepIndex: 0,
  loading: false,
  error: null,
};

/* eslint-disable default-case,no-param-reassign */
function MembershipFormReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_MEMBERSHIP_COMPANY_REQUESTED:
        draft.membershipCompany = {
          ...state.membershipCompany,
          loading: true,
          error: null,
        };
        break;
      case GET_MEMBERSHIP_COMPANY_SUCCEEDED:
        draft.membershipCompany = {
          loading: false,
          error: null,
          ...action.payload,
        };
        break;
      case GET_MEMBERSHIP_COMPANY_FAILED:
        draft.membershipCompany = {
          ...state.membershipCompany,
          loading: false,
          error: action.payload,
        };
        break;
      case SAVE_MEMBERSHIP_PROCESS_REQUESTED:
        draft.saveMembershipCompany = {
          loading: true,
          error: null,
          saved: false,
        };
        break;
      case SAVE_MEMBERSHIP_PROCESS_SUCCEEDED: {
        draft.saveMembershipCompany = {
          loading: false,
          error: null,
          saved: true,
          created: action.payload.submitted === true,
        };

        Object.keys(action.payload.steps).forEach(key => {
          draft.steps[key - 1].valid = action.payload.steps[key];
        });
        break;
      }
      case SAVE_MEMBERSHIP_PROCESS_FAILED:
        draft.saveMembershipCompany = {
          loading: false,
          error: action.payload,
          saved: false,
        };
        break;
      case GET_ACTIVE_STEP: {
        const currentStep = action.payload;
        const newArray = draft.steps.map(
          step => ({ ...step, ...(step.id !== currentStep.id ? { current: false } : currentStep) })
        );
        draft.steps = newArray;
        draft.currentStepIndex = (currentStep.id - 1);
        break;
      }
      case GET_FORM_STEP_REQUESTED:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_FORM_STEP_SUCCEEDED:
        draft.steps.map(step => step.valid = action.payload);
        draft.loading = false;
        draft.error = null;
        break;
      case GET_FORM_STEP_FAILED:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case UPLOAD_FILE_REQUESTED:
        draft.uploadSignedDocument = {
          loading: true,
          error: null,
          file: null,
        };
        break;
      case UPLOAD_FILE_SUCCEEDED:
        draft.uploadSignedDocument = {
          loading: false,
          error: null,
          file: action.payload,
        };
        break;
      case UPLOAD_FILE_FAILED:
        draft.uploadSignedDocument = {
          loading: false,
          error: action.payload,
          file: null,
        };
        break;
      case DOCUSIGN_MEMBERSHIP_START:
        draft.loading = true;
        break;
      case DOCUSIGN_MEMBERSHIP_SUCCESS:
        window.location.href = action.result?.redirectUrl;
        break;
      case DOCUSIGN_MEMBERSHIP_FAIL:
        draft.loading = false;
        break;
    }
  });
}
/* eslint-enable */

export default MembershipFormReducer;
