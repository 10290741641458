import { ChatMessage, Thread } from '@app/models/Chat';
import { Message, MessageTypes } from '@src/js/constants/entities';
import MessageBox from '@src/js/layouts/global/panels/messaging/components/MessageBox';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  messages: ChatMessage[],
  thread: Thread|null,
}

const useMessagesList = ({ messages, thread }: Props) => {
  const renderMessages = [];
  const dateFromString = (dateString: string) => (
    new Date(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')).setHours(0, 0, 0, 0));
  const shouldRenderDelimiter = (i: number) => {
    const todayDate = new Date().setHours(0, 0, 0, 0);
    const message = messages[i];
    const previousMessage = messages[i - 1];
    const messageDate = dateFromString(message.created);

    return messageDate === todayDate
    && (!previousMessage || (previousMessage && dateFromString(previousMessage.created) !== todayDate));
  };

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    if (shouldRenderDelimiter(i)) {
      renderMessages.push(
        <div
          className='messages-panel__delimiter'
          key={ `delimiter_${ message.id }` }
        >
          <h2><span><FormattedMessage id='messages.today' /></span></h2>
        </div>
      );
    }
    if (message.body === Message.USER_LEFT_CHAT) {
      renderMessages.push(
        <div className='user-left' key={ message.id }>
          <span>
            { message.user === 'you' ? (
              <FormattedMessage
                id='messages.youLeftChat'
              />
            ) : (
              <FormattedMessage
                id='messages.userLeftChat'
                values={ { 'user': message.user } }
              />
            )}
          </span>
        </div>
      );
    } else if (message.messageData?.type === MessageTypes.SYSTEM) {
      renderMessages.push(
        <div className='system' key={ message.id }>
          <span>
            { message.messageData?.translation
              ? <FormattedMessage id={ message.body } />
              : message.body }
          </span>
        </div>
      );
    } else {
      renderMessages.push(
        <MessageBox
          key={ message.id }
          message={ message }
        />
      );
    }
  }
  if (thread?.enabled === false) {
    renderMessages.push(
      <div className='user-left' key='disabledUser'>
        <span><FormattedMessage id='messages.userLeftElite' /></span>
      </div>
    );
  }

  return renderMessages;
};

export default useMessagesList;
