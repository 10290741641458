import fetchResource from '@src/js/api/fetch-resource';

export function getPartnerCompanies(slug, filters) {
  let url = `api/partners/${ slug }/companies`;

  if (filters) {
    url += `?${ new URLSearchParams(filters) }`;
  }

  return fetchResource(url);
}
