import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loader } from '@src/js/components/global';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { reminderEvent, schedulingDone } from '@src/js/actions/events/adminEvents';
import { useHistory } from 'react-router-dom';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { InputDate, InputText } from '@app/components/global/forms';

type Props = {
  slug: string,
  registered: boolean,
  templateId: number,
  custom: boolean,
}

type ScheduleForm = {
  receivers: { value: string }[],
  executionDate: null|Date,
  notificationDate: null|Date,
}

const ScheduleReminderModal = ({
  slug, registered, templateId, custom,
}: Props) => {
  const { hideModal } = useGlobalModalContext();
  const { reminderLoading, reminderDone } = useSelector((state: any) => state.adminEvents);
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const [isScheduled, setIsSchedule] = useState(false);

  const methods = useForm<ScheduleForm>({
    defaultValues: {
      receivers: [{ value: '' }],
      executionDate: null,
      notificationDate: null,
    },
    mode: 'all',
  });
  const {
    formState: { errors, isValid }, register, control, trigger, getValues, watch,
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'receivers',
  });

  let buttonText = isScheduled ? 'event_list.reminder.schedule' : 'send';
  if (custom) {
    buttonText = 'next';
  }

  const buttonAction = () => {
    if (isScheduled && !isValid) {
      trigger();
      return;
    }
    let scheduling;
    if (isScheduled) {
      scheduling = {
        executionDate: getValues('executionDate'),
        notificationDate: getValues('notificationDate'),
        receivers: getValues('receivers').map(r => r.value),
      };
    }
    if (custom) {
      history.push({
        pathname: `${ withLang(adminRouteCodes.EVENT) }/${ slug }/custom/${ templateId }`,
        state: { isScheduled, scheduling },
      });
      hideModal();
      return;
    }
    dispatch(reminderEvent(slug, registered, templateId, scheduling));
  };

  useEffect(() => {
    if (reminderDone) {
      dispatch(schedulingDone());
      hideModal();
    }
  }, [reminderDone]);

  return (
    <>
      {reminderLoading && <Loader />}
      <ModalBody className='schedule-reminder-modal'>
        <div className={ 'eop-checkbox d-flex m-b-0 justify-content-center' }>

          <input
            type='checkbox'
            onChange={ () => setIsSchedule(!isScheduled) }
            checked={ isScheduled }
          />
          <p className='eop-text-input__label font-size-icon m-b-0'>
            <FormattedMessage id='event_list.reminder.schedule' />
          </p>
        </div>
        { isScheduled && (
        <FormProvider { ...methods }>
          <form className='m-t-3'>
            <InputDate
              name='executionDate'
              errors={ errors }
              control={ control }
              label='events.scheduleReminder.schedulationDate'
              showTimeInput
              dateFormat='dd/MM/yyyy HH:mm'
              minDate={ new Date() }
              required
              className='m-b-2'
            />
            <InputDate
              name='notificationDate'
              errors={ errors }
              control={ control }
              label='events.scheduleReminder.notificationDate'
              showTimeInput
              dateFormat='dd/MM/yyyy HH:mm'
              minDate={ new Date() }
              maxDate={ watch('executionDate') || undefined }
              placeholder='events.scheduleReminder.placeholder'
              className='m-b-2'
            />
            {fields.map((item, index) => (
              <div key={ item.id } className='elite-form__full-row'>
                <div className='elite-form__section d-flex relative'>
                  <InputText
                    className='elite-form__full-row m-b-2'
                    name={ `receivers[${ index }].value` }
                    errors={ errors }
                    register={ register }
                    label={ `${ intl.formatMessage({ id: 'events.scheduleReminder.receiver' }) } ${ index + 1 }` }
                    type='email'
                    required
                    translate={ false }
                  />
                  <div role='presentation' className='elite-form__button-close' onClick={ () => remove(index) }>
                    <span className='icon-medium-Close' />
                  </div>
                </div>
              </div>
            ))}
            <Button
              buttonText='company_form.addNew'
              onClickFunc={ () => append({ value: '' }) }
              additionalClass='w-25'
            />
          </form>
        </FormProvider>
        )}
      </ModalBody>
      <div className='modal__footer-buttons'>
        <Button
          buttonText={ buttonText }
          onClickFunc={ buttonAction }
        />
        <Button emptyButtonType='empty-grey' buttonText='cancel' onClickFunc={ hideModal } />
      </div>
    </>
  );
};

export default ScheduleReminderModal;
