import styled from 'styled-components';
import { Col as ReactstrapCol, Col, Row } from 'reactstrap';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { ThemeColors, FontWeight, FontSize } from '@src/_v2/js/constants';
import { Breakpoints } from '@src/_v2/js/constants/breakpoints';
import IconStyled from '@src/_v2/js/components/Icons/Icon/Icon';

export const OnboardingFormHeader = styled.h2`
  letter-spacing: 0.1rem;
  color: ${ ThemeColors.black };
  font-weight: ${ FontWeight.normal } !important;
`;

export const ProgressbarBox = styled(Col)(props => `
  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    margin-top: 1.5rem;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 2;
    background-color: ${ ThemeColors.white };
    box-shadow: 0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
      display: ${ props.display };
    }
  }
`);

export const ProgressbarContainer = styled.div`
  padding: 2.5rem 0 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    flex-direction: column;
    text-align: start;
    padding: 0;
  }
`;

export const ProgressbarStepContainer = styled.div(props => `
  width: 13%;
  cursor: ${
  (props.color === 'blue' && 'auto')
  || (props.enabled ? 'pointer' : 'not-allowed')
};
  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    width: 100%;
    display: flex;

    &:not(:last-of-type) > div:last-of-type {
      border-bottom: 0.1rem solid ${ ThemeColors.softBlue };
    }
  }

  &:not(:first-of-type):not(:last-of-type) > div:first-child {
    ::before, ::after {
      content:'';
      height: 100%;
      border-left:0.1rem solid ${ ThemeColors.lightGrey };
      position:absolute;
      left: 50%;
      top: 100%;
      z-index: -1;
    }

    &::before {
      top: -100%;
    }
  }

`);

export const ProgressbarCircle = styled.div(props => `
  position: relative;
  padding: 0;
  display: block;
  margin: auto;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: .2rem solid ${
  (props.color === 'red' && 'rgba(0, 0, 0, 0.3)')
  || (props.color === 'green' && 'rgba(0, 0, 0, 0.3)')
  || (props.color === 'blue' && ThemeColors.blue)
  || (ThemeColors.lightGrey)
};
  background-color: ${
  (props.color === 'red' && ThemeColors.red)
  || (props.color === 'green' && ThemeColors.green)
  || (ThemeColors.softBlue)
};
  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    width: 3.5rem;
    height: 3.5rem;
  }
`);

export const ProgressbarCircleCaption = styled.div(props => `
  color: ${
  (props.color === 'green' && ThemeColors.green)
  || (props.color === 'red' && ThemeColors.red)
  || (props.color === 'blue' && ThemeColors.blue)
  || (ThemeColors.mediumGrey)
};
  margin-top: 1rem;
  font-weight: ${ FontWeight.medium };
  display: inline-block;
  line-height: 1.5rem;
  letter-spacing: 0.045rem;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    flex-basis: 85%;
    max-width: 85%;
    padding-bottom: 1rem;
    line-height: 3.5rem;
  }
`);

export const ProgressbarCircleIcon = styled.span.attrs(props => ({
  className: `icon-ob-${ props.step }`,
  style: {
    color:
        (props.color === 'green' && ThemeColors.white)
        || (props.color === 'red' && ThemeColors.white)
        || (props.color === 'blue' && ThemeColors.blue)
        || (ThemeColors.lightGrey),
  },
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    font-size: 1.6rem;
    left: 52%;
  }
`;

export const ProgressbarLine = styled.span`
  width: 78%;
  height: 0.1rem;
  background-color: ${ ThemeColors.lightGrey };
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  margin: auto;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    display: none;
  }
`;

export const FullWidthRow = styled(Row).attrs(props => ({
  style: {
    borderTop: `${ props.first !== 'false' ? `0.1rem solid ${ ThemeColors.lightGrey }` : '0' }`,
    paddingTop: '5rem',
  },
}))`
  width: 100%;
  margin-bottom: 2rem;
  margin-left: 0;
`;

export const CloseButtonContainer = styled(Col)`
  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    padding-left: 0.5rem;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.05rem;
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.icon };
  text-transform: uppercase;
  padding: 0 1rem;

  span {
    font-size: ${ FontSize.icon };
  }

  span:last-of-type {
    color: ${ ThemeColors.red };
    padding: 0.5rem;
  }

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    top: 0.5rem;
    right: 1.5rem;
    padding: 0;
  }
`;

export const FinancialsContainer = styled.div`
  min-height: 20rem;
`;

export const TableWrapperCol = styled(Col)`
  overflow-x: auto;
`;

export const FinancialsTable = styled(Table)`
  table-layout: fixed;
  width: 100%;
  min-width: 90rem;

  th,
  td {
    font-size: ${ FontSize.subTitle };
  }

  thead {
    tr th {
      padding-right: 0;

      .eop-selectbox { /* stylelint-disable-line selector-max-compound-selectors */
        display: inline-flex;
        padding-right: 0.5rem;
        margin-bottom: 0;
      }

      .Select-control { /* stylelint-disable-line selector-max-compound-selectors */
        width: 9rem;

        .Select-value { /* stylelint-disable-line selector-max-compound-selectors */
          padding-right: 1.5rem !important;
        }
      }

      &:nth-child(1) {
        .Select-control { /* stylelint-disable-line selector-max-compound-selectors */
          width: 6rem;
        }
      }
    }
  }

  tbody {
    td:nth-child(1) {
      font-weight: ${ FontWeight.bold };
    }

    .eop-text-input {
      width: 10rem;
      background: transparent;

      &__input {
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &__input-icon {
        padding-top: 0.35rem;
      }

      &__helper {
        background: transparent;
        padding: 0;
        min-height: 0;
      }

      &__input-item {
        width: 70%;
        background: transparent;
      }
    }

    tr:nth-child(odd) {
      background: ${ ThemeColors.softBlue };

      .eop-text-input__input-item {
        background: ${ ThemeColors.softBlue };
      }

      .eop-text-input__input-icon {
        background: ${ ThemeColors.softBlue };
      }
    }

    tr:nth-child(even) {
      background: ${ ThemeColors.white };
    }

    tr:last-child {
      border-bottom: 0.1rem solid ${ ThemeColors.lightGrey };
    }

    input:disabled {
      color: ${ ThemeColors.mediumGrey };
    }
  }

  .hidden {
    display: none;
  }
`;

export const FileCard = styled(ReactstrapCol)`
  flex-grow: 1;
  max-width: 75%;
  margin: 1rem -1.5rem;

  @media only screen and (max-width: ${ Breakpoints.MEDIUM }px) {
    max-width: 100%;

    .file-preview-card {
      padding: 2.5rem 2rem;

      .file-preview-card__content__name {
        font-size: ${ FontSize.small };
      }
    }
  }
`;

export const StatementLabel = styled.div`
  margin-top: 1rem;
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.caption };
`;

export const Icon = styled(IconStyled)`
  margin-right: 0.4rem;
  font-size: ${ FontSize.subTitle };
  position: relative;
  top: -0.1rem;
`;

export const TableCellStyled = styled(TableCell)(props => `
  padding-right: 1rem !important;
  ${ props.percentageWidth && `width: ${ props.percentageWidth }% } ` }
`);
