import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_INDEX_LIBRARY_START,
  ADMIN_GET_INDEX_LIBRARY_ERROR,
  ADMIN_GET_INDEX_LIBRARY_SUCCESS,
} from '@src/js/actions/library/adminLibrary';

import * as api from '@src/js/api/library/adminLibrary';

function* adminGetLibrary(options) {
  try {
    const data = yield call(() => api.adminGetLibrary(options.filters));
    yield put({ type: ADMIN_GET_INDEX_LIBRARY_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_INDEX_LIBRARY_ERROR, error });
  }
}

function* adminGetLibraryWatcher() {
  yield takeLatest(ADMIN_GET_INDEX_LIBRARY_START, adminGetLibrary);
}

export default [
  adminGetLibraryWatcher(),
];
