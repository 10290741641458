import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  GET_BANNER_LIST_START,
  GET_BANNER_LIST_FAIL,
  GET_BANNER_LIST_SUCCESS,
  GET_BANNER_START,
  GET_BANNER_FAIL,
  GET_BANNER_SUCCESS,
  UPDATE_BANNER_START,
  UPDATE_BANNER_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_STATUS_START,
  UPDATE_BANNER_STATUS_FAIL,
  UPDATE_BANNER_STATUS_SUCCESS,
} from '@app/store/content/banner/actions';
import * as api from '@app/store/content/banner/api';
import { BannerAction } from '@app/store/content/banner/reducer';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';

function* getBannerList({
  search, page, companyTypes, bannerType, bannerLang, bannerStatus,
}: BannerAction): Generator {
  try {
    const result = yield call(() => api.getBannerList({
      page, search, companyTypes, bannerType, bannerLang, bannerStatus,
    }));
    yield put({ type: GET_BANNER_LIST_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_BANNER_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getBanner({ id }: BannerAction): Generator {
  try {
    const result = yield call(() => api.getBanner(id));
    yield put({ type: GET_BANNER_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_BANNER_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* updateBanner({ id, formData, history }: BannerAction): Generator {
  try {
    yield call(() => (id
      ? api.updateBanner(id, formData)
      : api.createBanner(formData)
    ));
    yield all([
      put({ type: UPDATE_BANNER_SUCCESS }),
      put(showToastrSuccess()),
    ]);
    history.push(withLang(adminRouteCodes.BANNER));
  } catch (error: any) {
    yield all([
      put({ type: UPDATE_BANNER_FAIL, error }),
      put(showToastrError('notification.error_title', error.response?.description || 'notification.submit.error')),
    ]);
  }
}

function* updateBannerStatus({ id, status }: BannerAction): Generator {
  try {
    yield call(() => api.updateBannerStatus({ id, status }));
    yield all([
      put({ type: UPDATE_BANNER_STATUS_SUCCESS, id, status }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: UPDATE_BANNER_STATUS_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_BANNER_LIST_START, getBannerList);
  yield takeLatest(GET_BANNER_START, getBanner);
  yield takeLatest(UPDATE_BANNER_START, updateBanner);
  yield takeLatest(UPDATE_BANNER_STATUS_START, updateBannerStatus);
}

export default root;
