import fetchResource from '@src/js/api/fetch-resource';

export function uploadOnboardingImage(image, entity, imageType, uploadIndex) {
  const paramUploadIndex = uploadIndex ? `?uploadIndex=${ uploadIndex }` : '';

  return fetchResource(`api/onboarding/image/${ entity }/${ imageType }${ paramUploadIndex }`, {
    method: 'POST',
    body: image,
    noContentType: true,
  });
}

export function adminUploadOnboardingImage(image, entity, imageType, uploadIndex) {
  const paramUploadIndex = uploadIndex ? `?uploadIndex=${ uploadIndex }` : '';

  return fetchResource(`api/admin/onboarding/image/${ entity }/${ imageType }${ paramUploadIndex }`, {
    method: 'POST',
    body: image,
    noContentType: true,
  });
}
