export const SEARCH_START = 'SEARCH_START';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';

export function getSearchResults(search) {
  return {
    type: SEARCH_START,
    search,
  };
}
