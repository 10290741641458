import { cloneDeep } from 'lodash';

export const mapFormOptions = (formOptions = [], intl) => {
  const formOptionArray = cloneDeep(formOptions);

  const allIndex = formOptionArray.findIndex((element) => element.id === 0);
  if (allIndex >= 0) {
    formOptionArray.splice(allIndex);
  }

  const map = {};
  const nodes = [];

  for (let i = 0; i < formOptionArray.length; i += 1) {
    if (formOptionArray[i].children) {
      formOptionArray[i].children = null;
    }
    formOptionArray[i].value = formOptionArray[i].id;
    if (formOptionArray[i].counter) {
      formOptionArray[i].label = `${ intl
        ? intl.formatMessage({ id: formOptionArray[i].name })
        : formOptionArray[i].name } (${ formOptionArray[i].counter })`;
    } else {
      formOptionArray[i].label = intl ? intl.formatMessage({ id: formOptionArray[i].name }) : formOptionArray[i].name;
    }
  }

  formOptionArray.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  for (let i = 0; i < formOptionArray.length; i += 1) {
    map[formOptionArray[i].id] = i;
  }

  for (let i = 0; i < formOptionArray.length; i += 1) {
    const node = formOptionArray[i];
    if (node.parentId && formOptionArray[map[node.parentId]]) {
      if (Array.isArray(formOptionArray[map[node.parentId]].children)) {
        formOptionArray[map[node.parentId]].children.push(node);
      } else {
        formOptionArray[map[node.parentId]].children = [node];
      }
    } else {
      nodes.push(node);
    }
  }

  return nodes;
};

export const downloadFile = (url, name = 'file.pdf') => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const stringToNumber = (v, decimals = 2) => {
  if (!v || typeof v !== 'string') return null;
  let value = v;
  if (value.indexOf(' ') > 0 || value.indexOf(' ') > 0) {
    value = value.split(' ').join('');
    value = value.split(' ').join('');
  }
  if (value.indexOf('.') < value.length - decimals - 1) {
    value = value.replace(/\./g, '');
  }
  if (value.indexOf(',') < value.length - decimals - 1) {
    value = value.replace(/,/g, '');
  }
  if (value.indexOf(',') > 0) {
    value = value.replace(',', '.');
  }
  return parseFloat(value);
};

const latinLanguages = ['it', 'es', 'pt'];

export const localeFormatNumber = (v, lang) => {
  const language = lang === 'ar' ? 'en' : lang;
  if (!v || typeof v !== 'string' || Number.isNaN(parseFloat(v))) return null;
  let value = v;
  if (value.indexOf(' ') > 0 || value.indexOf(' ') > 0) {
    value = value.toString().split(' ').join('');
    value = value.split(' ').join('');
  }
  let n = parseFloat(value);
  if (value.indexOf(',') > 0) {
    if (latinLanguages.includes(language) || language === 'fr') {
      n = parseFloat(value.toString().replace(/\./g, '').replace(',', '.'));
    } else {
      n = parseFloat(value.toString().replace(/,/g, ''));
    }
  }
  return typeof n === 'number' && !Number.isNaN(n)
    ? n.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
};

export const DEFAULT_LANGUAGE = 'en';

export const stringToSlug = str => (
  str.normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
);
