import fetchResource from '@src/js/api/fetch-resource';

export function getPartnerLibraries(filters) {
  let url = 'api/partner/library';

  if (filters) {
    url += `?${ new URLSearchParams(filters) }`;
  }

  return fetchResource(url);
}

export function changeLibraryStatus(library) {
  const path = `api/partner/library/${ library.slug }`;
  return fetchResource(path, {
    method: 'PATCH',
    body: library,
  });
}
