export const ADMIN_CREATE_TEMPLATE_START = 'ADMIN_CREATE_TEMPLATE_START';
export const ADMIN_CREATE_TEMPLATE_ERROR = 'ADMIN_CREATE_TEMPLATE_ERROR';
export const ADMIN_CREATE_TEMPLATE_SUCCESS = 'ADMIN_CREATE_TEMPLATE_SUCCESS';

export function adminCreateTemplateAction(template) {
  return {
    type: ADMIN_CREATE_TEMPLATE_START,
    template,
  };
}
