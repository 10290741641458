import fetchResource from '@src/js/api/fetch-resource';

export function listEventAttendingCompanies(slug, filters) {
  let url = `api/admin/events/${ slug }/restrictions`;

  if (filters) {
    const f = Object.fromEntries(Object.entries(filters).filter(([, v]) => v !== null && v !== undefined));
    const query = new URLSearchParams(f);

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}

export function saveAttendCompaniesApiCall(slug, filters) {
  const url = `api/admin/events/${ slug }/restrictions/add`;
  const body = Object.fromEntries(Object.entries(filters)
    .map(([key, value]) => ([key, Array.isArray(value) ? value.join(',') : value])));

  return fetchResource(url, {
    method: 'PUT',
    body,
  });
}

export function uninviteCompaniesApiCall(slug) {
  const url = `api/admin/events/${ slug }/restrictions/uninvite_all`;

  return fetchResource(url, {
    method: 'DELETE',
  });
}

export function removeAttendCompaniesApiCall(slug, filters) {
  const url = `api/admin/events/${ slug }/restrictions/remove_attend`;

  return fetchResource(url, {
    method: 'PUT',
    'body': filters,
  });
}

export function removeSubscribeCompaniesApiCall(slug, filters) {
  const url = `api/admin/events/${ slug }/restrictions/remove_subscribe`;

  return fetchResource(url, {
    method: 'PUT',
    'body': filters,
  });
}
