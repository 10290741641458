import fetchResource from '@src/js/api/fetch-resource';

export function getManagerCompanies(filters) {
  let url = 'api/user/relationship-manager/companies';

  if (filters) {
    url += `?${ new URLSearchParams(filters) }`;
  }

  return fetchResource(url);
}
