import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_COMPANY_PUBLIC_PROFILE_START,
  GET_COMPANY_PUBLIC_PROFILE_ERROR,
  GET_COMPANY_PUBLIC_PROFILE_SUCCESS,
  GET_COMPANY_BASIC_DATA_START,
  GET_COMPANY_BASIC_DATA_ERROR,
  GET_COMPANY_BASIC_DATA_SUCCESS,
  GET_COMPANY_FINANCIALS_DATA_START,
  GET_COMPANY_FINANCIALS_DATA_ERROR,
  GET_COMPANY_FINANCIALS_DATA_SUCCESS,
  EDIT_COMPANY_START,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_SUCCESS,
  EDIT_ETINEARY_STATUS_START,
  EDIT_ETINEARY_STATUS_ERROR,
  EDIT_ETINEARY_STATUS_SUCCESS,
} from '@src/js/actions/company/company';

import * as api from '@src/js/api/company/company';

function* getCompanyPublicProfile(options) {
  try {
    const data = yield call(() => api.getCompanyPublicProfile(options.slug));
    yield put({ type: GET_COMPANY_PUBLIC_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANY_PUBLIC_PROFILE_ERROR, error });
  }
}

function* getCompanyBasicData(options) {
  try {
    const data = yield call(() => api.getCompanyBasicData(options.slug));
    yield put({ type: GET_COMPANY_BASIC_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANY_BASIC_DATA_ERROR, error });
  }
}

function* getCompanyFinancialsData(options) {
  try {
    const data = yield call(() => api.getCompanyFinancialsData(options.slug));
    yield put({ type: GET_COMPANY_FINANCIALS_DATA_SUCCESS, data, lang: options.lang });
  } catch (error) {
    yield put({ type: GET_COMPANY_FINANCIALS_DATA_ERROR, error });
  }
}

function* editCompany(options) {
  try {
    const data = yield call(() => api.editCompany(options.company.values, options.userType));
    yield put({ type: EDIT_COMPANY_SUCCESS, data });
    options.company.resolve(data.data);
  } catch (error) {
    yield put({ type: EDIT_COMPANY_ERROR, error });
    options.company.reject(error.response);
  }
}

function* editEtineraryStatus(options) {
  try {
    yield call(() => api.editEtineraryStatus(options.slug, options.status));
    yield put({ type: EDIT_ETINEARY_STATUS_SUCCESS });
  } catch (error) {
    yield put({ type: EDIT_ETINEARY_STATUS_ERROR, error });
  }
}

function* getCompanyPublicProfileWatcher() {
  yield takeLatest(GET_COMPANY_PUBLIC_PROFILE_START, getCompanyPublicProfile);
}

function* getCompanyBasicDataWatcher() {
  yield takeLatest(GET_COMPANY_BASIC_DATA_START, getCompanyBasicData);
}

function* getCompanyFinancialsDataWatcher() {
  yield takeLatest(GET_COMPANY_FINANCIALS_DATA_START, getCompanyFinancialsData);
}

function* editCompanyWatcher() {
  yield takeLatest(EDIT_COMPANY_START, editCompany);
}

function* editEtineraryStatusWatcher() {
  yield takeLatest(EDIT_ETINEARY_STATUS_START, editEtineraryStatus);
}

export default [
  getCompanyPublicProfileWatcher(),
  getCompanyBasicDataWatcher(),
  getCompanyFinancialsDataWatcher(),
  editCompanyWatcher(),
  editEtineraryStatusWatcher(),
];
