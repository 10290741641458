import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_PARAMETERS_VISIBILITY_REQUEST,
  PARTNER_PARAMETERS_VISIBILITY_FAIL,
  PARTNER_PARAMETERS_VISIBILITY_SUCCESS,
} from '@src/js/actions/partner/partnerParametersVisibility';

import * as api from '@src/js/api/partner/partnerParametersVisibility';

function* togglePartnerParametersVisibility({ slug, parameters }) {
  try {
    const data = yield call(() => api.togglePartnerParametersVisibility(slug, parameters));
    yield put({ type: PARTNER_PARAMETERS_VISIBILITY_SUCCESS, data, parameters });
  } catch (error) {
    yield put({ type: PARTNER_PARAMETERS_VISIBILITY_FAIL, error });
  }
}

function* root() {
  yield takeLatest(PARTNER_PARAMETERS_VISIBILITY_REQUEST, togglePartnerParametersVisibility);
}

export default root;
