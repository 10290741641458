import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DateObj, isDateObject } from '@app/models/DateTime';

type Props = {
  name: string,
  label?: string,
  translate?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  required?: boolean,
  placeholder?: string,
  errors: object,
  control: Control<any>,
  defaultValue?: string|object,
  dateFormat?: string,
  className?: string,
  showTimeInput?: boolean,
  isClearable?: boolean,
  minDate?: Date,
  maxDate?: Date,
  onChange?: (d: Date|null) => void,
  showTime?: {
    showTimeSelect: boolean,
    timeFormat: string,
    timeIntervals: number,
    timeCaption: string,
  }
}

const InputDate = ({
  name, label, translate = true, disabled, placeholder, control, dateFormat,
  required, errors, defaultValue, className = '', showTimeInput, isClearable, readonly, onChange, showTime,
  minDate, maxDate,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldPlaceholder = translate && placeholder ? intl.formatMessage({ id: placeholder }) : placeholder;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;

  const selected = (value?: DateObj|string|null) => {
    try {
      if (isDateObject(value)) {
        return new Date(value.date);
      }
      return value ? new Date(value) : null;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className={ `eop-text-datepicker ${ className }` } data-name={ name }>
      <label className='eop-text-datepicker__label'>
        { required && <span className='required-icon' /> }
        { label && fieldLabel }
      </label>
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        rules={ { required: requiredError } }
        render={ ({ field }) => (
          <>
            <DatePicker
              className='eop-text-datepicker__input'
              { ...showTime }
              disabled={ disabled }
              readOnly={ readonly }
              dateFormat={ dateFormat }
              aria-label={ fieldPlaceholder }
              showTimeInput={ showTimeInput }
              isClearable={ !disabled && isClearable }
              placeholderText={ fieldPlaceholder }
              onChange={ (e) => (onChange ? onChange(e) : field.onChange(e)) }
              selected={ selected(field.value) }
              minDate={ minDate }
              maxDate={ maxDate }
            />
            { !field.value && !disabled && (
            <span className='eop-text-datepicker__input-icon icon-small-Calendar' />
            ) }
            { (disabled || readonly) && (
            <span className='eop-text-datepicker__input-icon icon-LockerSmall' />
            ) }
          </>
        ) }
      />
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

export default InputDate;
