import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import Header from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components/Header/Header';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { Images } from '@src/js/constants/images';

class FundingWidget extends Component {
  render() {
    const fundingLink = withLang(routeCodes.OPPORTUNITIES);
    const imgUrl = Images.FundingWidget;

    return (
      <DashboardWidget id='dashboard-funding-widget' className='p-x-1 p-y-1'>
        <Header>
          <FormattedMessage id='funding.opportunities.heading' />
        </Header>
        <a href={ fundingLink } target='_blank' rel='noopener noreferrer'>
          <img src={ imgUrl } alt='funding' />
        </a>
      </DashboardWidget>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.initInfo.initInfoError,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(FundingWidget);

export default withRouter(connectWrapper);
