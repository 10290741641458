import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { get as lodashGet } from 'lodash';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TextInput from '@src/js/components/global/inputs/TextInput';
import SelectBox from '@src/js/components/global/inputs/Selectbox';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import { getAtecoCodesOptions } from '@src/js/actions/company/companyFormOptions';

class CompanyFormProgramInfo extends Component {
  static propTypes = {
    companyFormOptions: PropTypes.object,
    provinces: PropTypes.array,
    regions: PropTypes.array,
    isGrowth: PropTypes.bool,
    isGrowthStartup: PropTypes.bool,
    isInvestor: PropTypes.bool,
    isBroker: PropTypes.bool,
    isEtinerary: PropTypes.bool,
    dispatch: PropTypes.func,
    isProgrammeItaly: PropTypes.bool,
    ateco: PropTypes.object,
    formValues: PropTypes.object,
    formMutators: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      search: {
        code: '',
        page: 1,
      },
    };
  }

    loadAtecoOptions = async (currentQuery) => {
      const { dispatch } = this.props;
      const { search } = this.state;
      const dataToSend = {
        code: currentQuery,
        page: search.page > 1 ? search.page : 1,
      };

      if (search.code !== currentQuery) {
        dataToSend.page = 1;
      }

      return new Promise((resolve, reject) => {
        dispatch(getAtecoCodesOptions({ values: dataToSend, resolve, reject }));
      }).then((data) => {
        this.setState({
          search: {
            code: currentQuery,
            page: data?.results && data?.pagination?.page < data?.pagination?.pageCount
              ? data.pagination.page + 1
              : 1,
          },
        });

        return {
          options: data?.results ? data.results
            .map(item => ({ value: item.id, label: item.code, id: item.id })) : [],
          hasMore: !!(data?.results && data?.pagination?.page < data?.pagination?.pageCount),
        };
      }).catch(() => ({
        options: [],
        hasMore: false,
      }));
    };

    render() {
      const {
        companyFormOptions, isGrowth, isGrowthStartup, isInvestor, isBroker, isEtinerary,
        isProgrammeItaly, ateco, formValues, provinces, formMutators, regions,
      } = this.props;
      const countries = companyFormOptions?.results?.countries || [];
      const country = formValues?.basic?.country;
      const region = formValues?.basic?.region;
      const isCountryItaly = countries?.find(c => c.id === country)?.name === 'Italy';
      const regionOptions = regions?.map(region => ({ ...region, label: region.name, value: region.name }));
      const provinceOptions = provinces?.filter(
        province => province.parentId === regions?.find(r => r.name === region)?.id
      );

      return (
        <div className='edit-company-page edit-company-page__section'>
          <div className='row'>
            <div className='col-xs'>
              <div className='edit-company-page__title edit-user-page-tab-content-title'>
                <FormattedMessage id='company_form.generalInformation' />
              </div>
              { (isGrowth || isGrowthStartup || isEtinerary) && (
              <Field
                name='basic.vatNumber'
                fieldName='basic.vatNumber'
                fieldType='text'
                fieldLabel='company_form.vatNumber'
                fieldMaxLength={ 200 }
                component={ TextInput }
                fieldRequired={ isEtinerary }
              />
              ) }
              { (isGrowth || isGrowthStartup || isInvestor || isBroker) && (
              <Field
                name='basic.phone'
                fieldName='basic.phone'
                fieldType='text'
                fieldLabel='company_form.phone'
                fieldMaxLength={ 100 }
                component={ TextInput }
              />
              ) }
              { (isGrowth || isGrowthStartup || isInvestor || isBroker) && (
              <Field
                name='basic.website'
                fieldName='basic.website'
                fieldType='text'
                fieldLabel='company_form.website'
                fieldMaxLength={ 200 }
                component={ TextInput }
              />
              ) }
              <Field
                name='basic.country'
                fieldName='basic.country'
                fieldType='text'
                fieldLabel='country'
                options={ countries && countries
                  .map(country => ({ value: country.id, label: country.name })) }
                component={ SelectBox }
                onChange={ formMutators.setCountry }
              />
              { (isGrowth || isGrowthStartup) && (
              <>
                {isCountryItaly
                  ? (
                    <>
                      <Field
                        name='basic.region'
                        fieldName='basic.region'
                        fieldType='text'
                        fieldLabel='company_form.region'
                        options={ regionOptions || [] }
                        component={ SelectBox }
                      />
                      <Field
                        name='basic.provinceId'
                        fieldName='basic.provinceId'
                        fieldType='text'
                        fieldLabel='company_form.province'
                        options={ provinceOptions || [] }
                        component={ SelectBox }
                      />
                    </>
                  )
                  : (
                    <>
                      <Field
                        name='basic.region'
                        fieldName='basic.region'
                        fieldType='text'
                        fieldLabel='company_form.region'
                        fieldMaxLength={ 200 }
                        component={ TextInput }
                      />
                      <Field
                        name='basic.province'
                        fieldName='basic.province'
                        fieldType='text'
                        fieldLabel='company_form.province'
                        fieldMaxLength={ 200 }
                        component={ TextInput }
                      />
                    </>
                  )}
                <Field
                  name='basic.city'
                  fieldName='basic.city'
                  fieldType='text'
                  fieldLabel='company_form.city'
                  s
                  fieldMaxLength={ 200 }
                  component={ TextInput }
                />
              </>
              ) }
              { (isGrowth || isGrowthStartup || isInvestor || isBroker) && (
                <>
                  <Field
                    name='basic.address'
                    fieldName='basic.address'
                    fieldType='text'
                    fieldLabel='company_form.address'
                    fieldMaxLength={ 200 }
                    component={ TextInput }
                  />
                  <Field
                    name='basic.postalCode'
                    fieldName='basic.postalCode'
                    fieldType='text'
                    fieldLabel='company_form.postalCode'
                    fieldMaxLength={ 200 }
                    component={ TextInput }
                  />
                </>
              ) }
              { isProgrammeItaly && isGrowth && (
              <Field
                name='basic.ateco'
                fieldName='basic.ateco'
                fieldType='text'
                fieldLabel='company_form.ateco'
                loadOptions={ this.loadAtecoOptions }
                initialValue={ ateco }
                component={ SelectBox }
              />
              ) }
              { (isInvestor || isBroker) && (
              <>
                <Field
                  name='basic.isin'
                  fieldName='basic.isin'
                  fieldType='text'
                  fieldLabel='company_form.isin'
                  fieldMaxLength={ 12 }
                  component={ TextInput }
                />
                <Field
                  name='basic.subtype'
                  fieldName='basic.subtype'
                  fieldType='text'
                  fieldLabel='company_form.subtype'
                  options={ lodashGet(companyFormOptions, 'results.companySubtypes')
                  && companyFormOptions.results.companySubtypes.map(type => (
                    { value: type.value, label: <FormattedMessage id={ type.label } /> })) }
                  component={ SelectBox }
                />
              </>
              ) }
              { isEtinerary && (
              <Field
                name='basic.employeeNumber'
                fieldName='basic.employeeNumber'
                fieldLabel='company_form.employeeNumber'
                placeholder='company_form.employeeNumber'
                fieldType='number'
                component={ TextInput }
                fieldRequired
                min={ 1 }
              />
              )}
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  companyFormOptions: state.companyFormOptions.companyFormOptions,
  provinces: state.countries?.provinces?.results,
  regions: state.countries?.regions?.results,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormProgramInfo);

export default withRouter(connectWrapper);
