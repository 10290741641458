import useCookieControl from '@app/hooks/useCookieControl';
import useBreakpoint from '@app/services/useBreakpoint';
import FooterDesktop from '@src/js/layouts/footer/FooterDesktop';
import FooterMobile from '@src/js/layouts/footer/FooterMobile';
import React from 'react';

const Footer = () => {
  useCookieControl();
  const { breakpoint } = useBreakpoint();
  return (
    <>
      <div className='footer-brand-colours'>
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
        <div className='col' />
      </div>
      { breakpoint === 'mobile' ? <FooterMobile /> : <FooterDesktop /> }
    </>
  );
};

export default Footer;
