import RequestResetPasswordForm from '@src/js/components/forms/RequestResetPasswordForm';
import MetaTitle from '@src/js/components/global/meta/MetaTitle';
import React, { Component } from 'react';

class RequestResetPassword extends Component {
  render() {
    return (
      <>
        <div className='login-header'>
          <div className='container'>
            <div className='main-navigation__logo-icon icon-EliteLogo' />
          </div>
        </div>
        <div className='login-sub-header' />
        <MetaTitle title='login.formTitle' />

        <div className='container set-password-page'>
          <RequestResetPasswordForm
            className='set-password-form'
            url='api/users/password/reset'
          />
        </div>
      </>
    );
  }
}

export default RequestResetPassword;
