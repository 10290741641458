import {
  call, put, takeEvery, all,
} from 'redux-saga/effects';
import { showToastrError } from '@app/store/global/actions';
import * as api from '@app/store/network/api';
import {
  GET_RELEVANT_START,
  GET_RELEVANT_FAIL,
  GET_RELEVANT_SUCCESS,
  GET_VIEW_ALL_START,
  GET_VIEW_ALL_FAIL,
  GET_VIEW_ALL_SUCCESS,
} from '@app/store/network/actions';

function* getDirectoryRelevant({ section, boxNumber }) {
  try {
    const result = yield call(() => api.getDirectoryRelevant(section, boxNumber));
    yield put({
      type: GET_RELEVANT_SUCCESS, section, result, boxNumber,
    });
  } catch (error) {
    yield all([
      put({ type: GET_RELEVANT_FAIL, error }),
      put(showToastrError('notification.error_title', error.message)),
    ]);
  }
}

function* getDirectoryViewAll({
  section, boxNumber, page, filters,
}) {
  try {
    const result = yield call(() => api.getDirectoryViewAll(section, boxNumber, page, filters));
    yield put({ type: GET_VIEW_ALL_SUCCESS, section, result });
  } catch (error) {
    yield all([
      put({ type: GET_VIEW_ALL_FAIL, error }),
      put(showToastrError('notification.error_title', error.message)),
    ]);
  }
}

function* root() {
  yield takeEvery(GET_RELEVANT_START, getDirectoryRelevant);
  yield takeEvery(GET_VIEW_ALL_START, getDirectoryViewAll);
}

export default root;
