import globalMessages from '@src/js/oneplatform/messages';
import messages from './messages';

const scope = 'Trainings';

const ADD_TRAINING_REQUESTED = `${ scope }/ADD_TRAINING_REQUESTED`;
const ADD_TRAINING_SUCCEEDED = `${ scope }/ADD_TRAINING_SUCCEEDED`;
const ADD_TRAINING_FAILED = `${ scope }/ADD_TRAINING_FAILED`;

const GET_TRAININGS_REQUESTED = `${ scope }/GET_TRAININGS_REQUESTED`;
const GET_TRAININGS_SUCCEEDED = `${ scope }/GET_TRAININGS_SUCCEEDED`;
const GET_TRAININGS_FAILED = `${ scope }/GET_TRAININGS_FAILED`;

const BASE_FILTERS = {
  company: '',
  eventDate: '',
  eventDateBefore: '',
  eventDateAfter: '',
  page: 1,
  programme: [],
  search: '',
  type: 2,
};

const ITEMS_TRAININGS_THEAD = {
  name: globalMessages.name,
  type: globalMessages.type,
  status: globalMessages.status,
  eventDate: messages.eventDate,
  notification: messages.notification,
  action: globalMessages.action,
};

const PER_PAGE = 10;

const PROGRAMMES = 'programmes';

export {
  ADD_TRAINING_REQUESTED,
  ADD_TRAINING_SUCCEEDED,
  ADD_TRAINING_FAILED,
  GET_TRAININGS_REQUESTED,
  GET_TRAININGS_SUCCEEDED,
  GET_TRAININGS_FAILED,
  BASE_FILTERS,
  ITEMS_TRAININGS_THEAD,
  PROGRAMMES,
  PER_PAGE,
};
