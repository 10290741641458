import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { RequestCallTypes } from '@src/js/constants/entities';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const BannerRenew = () => {
  const { showModal } = useGlobalModalContext();

  const onClick = () => {
    showModal({
      type: ModalTypes.RequestCall,
      title: 'membership.renew_modal.title',
      config: { size: 'lg' },
      data: {
        requestType: RequestCallTypes.MembershipRenew,
      },
    });
  };

  return (
    <div className='banner-renew'>
      <div onClick={ onClick } role='presentation' className='d-flex'>
        <span className='icon-LockerSmall' />
        <span className='banner-renew__text'><FormattedMessage id='membership.renew' /></span>
      </div>
    </div>
  );
};

export default BannerRenew;
