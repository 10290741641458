import { EventReminder } from '@app/models/EventReminder';
import { EventAction } from '@app/store/event/reducer';
import fetchResource from '@src/js/api/fetch-resource';

export function getReminderTemplateList({
  page, search, types, slug,
}: EventAction) {
  const query = new URLSearchParams({ page: page.toString(), search, types: types.join(',') });
  return fetchResource(
    `api/event/${ slug }/reminder/template?${ query }`, {
      method: 'GET',
    });
}

export function getEvent(slug: string) {
  return fetchResource(`api/event/${ slug }`, {
    method: 'GET',
  });
}

export function getReminderTemplate(slug: string, id?: number) {
  return fetchResource(`api/event/${ slug }/reminder/template/${ id }`, {
    method: 'GET',
  });
}

export function createReminderTemplate(slug: string, data?: EventReminder.Template) {
  return fetchResource(`api/event/${ slug }/reminder/template`, {
    method: 'POST',
    body: {
      ...data,
    },
  });
}

export function editReminderTemplate(slug: string, data?: EventReminder.Template) {
  return fetchResource(`api/event/${ slug }/reminder/template/${ data?.id }`, {
    method: 'PUT',
    body: {
      ...data,
    },
  });
}
