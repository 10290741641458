import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const ChatLinks = ({
  insertLink,
  editBoxRef,
}: {
  insertLink: (link: string, selection: Range|undefined) => void,
  editBoxRef: React.RefObject<HTMLDivElement>
}) => {
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const linkRef = useRef<HTMLInputElement>(null);
  const [savedRanges, setSavedRanges] = useState<Range[]>([]);
  const intl = useIntl();

  const saveRanges = () => {
    const selection = window.getSelection();
    if (selection) {
      const ranges = [];
      for (let i = 0; i < selection.rangeCount; ++i) {
        ranges.push(selection.getRangeAt(i));
      }
      setSavedRanges(ranges);
    }
  };

  const toggleLinkDialog = () => {
    if (!isLinkDialogOpen) saveRanges();
    setIsLinkDialogOpen(!isLinkDialogOpen);
  };

  const close = () => {
    if (!linkRef?.current) return;
    linkRef.current.value = '';
    setIsLinkDialogOpen(false);
  };

  const insert = () => {
    if (!linkRef?.current?.value.trim()) return;
    const selection = window.getSelection();
    if (savedRanges.length > 0 && selection) {
      selection.removeAllRanges();
      savedRanges
        .filter(({ startContainer: { nodeName } }) => nodeName === 'TR')
        .forEach(range => {
          selection.addRange(range);
        });
      savedRanges
        .filter(({ startContainer: { nodeName } }) => nodeName !== 'TR')
        .forEach(range => {
          selection.addRange(range);
        });
    }
    insertLink(linkRef.current.value.trim(),
      savedRanges.filter(({ startContainer: { nodeName } }) => nodeName !== 'TR')[0]
    );
    close();
  };

  const keyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      insert();
    }
  };

  useEffect(() => {
    if (editBoxRef?.current) {
      editBoxRef?.current.addEventListener('mouseup', saveRanges);
    }
    return () => editBoxRef?.current?.removeEventListener('mouseup', saveRanges);
  }, [editBoxRef?.current, saveRanges]);

  return (
    <div className='relative message-area__link-button'>
      <div
        className={ `message-area__link-button-container${ isLinkDialogOpen ? '' : ' d-none' }` }
      >
        <input
          ref={ linkRef }
          placeholder={ intl.formatMessage({ id: 'chat.insertLink.placeholder' }) }
          onKeyUp={ keyUp }
        />
        <button type='button' className='btn-close' aria-label='Close' onClick={ close } />
        <button className='eop-btn eop-btn-action-blue' type='button' onClick={ insert }>
          <FormattedMessage id='chat.insertLink' />
        </button>
      </div>
      <button
        type='button'
        onClick={ toggleLinkDialog }
        className='message-area__link-button'
      >
        <span
          className='icon icomoon-link'
          title={ intl.formatMessage({ id: 'chat.insertLink' }) }
        />
      </button>
    </div>
  );
};

export default ChatLinks;
