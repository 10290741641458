import React from 'react';
import { ModalBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@src/js/components/global';
import PropTypes from 'prop-types';
import { CROSS_SELLING_VIEW_START } from '@app/store/global/actions';
import { langRouteParam, withLang } from '@src/js/constants/routes';
import { purifyHtml } from '@src/js/utils';
import { ReactGaService } from '@app/services/ReactGaService';
import { EventGa } from '@app/models/EventGa';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const CrossSellingModal = ({ name }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const history = useHistory();
  const link = intl.formatMessage({ id: 'cross_selling.link' });

  const action = () => {
    ReactGaService.instance.event(new EventGa('Marketing', 'cross_selling', `Cross selling ${ name }`));
    dispatch({ type: CROSS_SELLING_VIEW_START });
    if (link.startsWith('/')) {
      history.push(withLang(`${ langRouteParam }${ link }`));
    } else {
      window.location = link;
    }
    hideModal();
  };

  return (
    <>
      <ModalBody className='membership-modal-body'>
        <FormattedMessage id='cross_selling.content'>
          { htmlMessage => (
            <p className='blue-a' dangerouslySetInnerHTML={ { __html: purifyHtml(htmlMessage) } } />
          ) }
        </FormattedMessage>
      </ModalBody>
      <div className='modal__footer-buttons'>
        <Button buttonText='cross_selling.action' onClickFunc={ action } />
        <Button buttonText='cross_selling.cancel' onClickFunc={ hideModal } />
      </div>
    </>
  );
};

CrossSellingModal.propTypes = {
  name: PropTypes.string,
};

export default CrossSellingModal;
