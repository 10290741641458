import { takeLatest, call, put } from 'redux-saga/effects';

import {
  TASK_SET_USER_PERMISSIONS_START,
  TASK_SET_USER_PERMISSIONS_ERROR,
  TASK_SET_USER_PERMISSIONS_SUCCESS,
} from '@src/js/actions/tasks/taskSetUserPermissions';

import * as api from '@src/js/api/tasks/taskSetUserPermissions';

function* taskSetUserPermissions(options) {
  try {
    const data = yield call(() => api.taskSetUserPermissions(options.slug, options.permissions));
    yield put({ type: TASK_SET_USER_PERMISSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TASK_SET_USER_PERMISSIONS_ERROR, error });
  }
}

export function* taskSetUserPermissionsWatcher() {
  yield takeLatest(TASK_SET_USER_PERMISSIONS_START, taskSetUserPermissions);
}

export default [
  taskSetUserPermissionsWatcher(),
];
