import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { ChatStyles, MessageBody, sendGAEvent } from '@app/models/Chat';
import { sendMessage } from '@app/store/chat/chatApi';
import { selectCurrentThread, selectMessagesLoading } from '@app/store/chat/chatSlice';
import { RootState, useAppDispatch } from '@src/js/store';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { clearTags, focusChatEditBox } from '@src/js/layouts/global/panels/messaging/ChatUtils';
import ChatEmoji from './components/ChatEmoji';
import ChatLinks from './components/ChatLinks';

type Props = {
  styles?: ChatStyles,
}

const ChatFooter = ({
  styles,
}: Props) => {
  const intl = useIntl();
  const { showModal } = useGlobalModalContext();
  const loggedUserId = useSelector<RootState, string>(state => state.initInfo.userInfo.uuid);
  const thread = useSelector(selectCurrentThread);
  const loadingMessage = useSelector(selectMessagesLoading);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const editBoxRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const updateButton = () => {
    if (!buttonRef?.current) return;
    if (editBoxRef?.current?.innerHTML && clearTags(editBoxRef?.current?.innerHTML)) {
      buttonRef.current.classList.remove('chat__send--disabled');
    } else {
      buttonRef.current.classList.add('chat__send--disabled');
    }
  };

  const insertLink = (link: string, selection: Range|undefined) => {
    if (!editBoxRef.current) return;
    editBoxRef.current.focus();
    let httpsLink = link.replace('http:', 'https:');
    if (!httpsLink.startsWith('https')) {
      httpsLink = `https://${ httpsLink }`;
    }
    // firefox fix
    if (selection?.startOffset === selection?.endOffset) {
      const el = document.createElement('a');
      el.href = httpsLink;
      el.innerHTML = link;
      el.target = '_blank';
      if ((selection?.startContainer?.parentNode as HTMLDivElement)?.id === 'message-area') {
        selection?.insertNode(el);
      } else {
        document.getElementById('message-area')?.appendChild(el);
      }
      updateButton();
      return;
    }
    const uuid = `link__{{${ uuidv4() }}}`;
    const executed = document.execCommand('createLink', false, httpsLink);
    if (executed) {
      updateButton();
      return;
    }
    focusChatEditBox();
    document.execCommand('createLink', false, uuid);
    const linkElement = document.querySelector(`a[href="${ uuid }"`) as HTMLAnchorElement;
    linkElement.target = '_blank';
    linkElement.href = httpsLink;
    linkElement.innerHTML = link;
    updateButton();
  };

  const resetPlaceholder = () => {
    if (!editBoxRef?.current?.innerHTML) return;
    if (!clearTags(editBoxRef?.current?.innerHTML).length) {
      editBoxRef.current.innerHTML = '';
    }
  };

  const send = () => {
    if (!editBoxRef?.current?.innerHTML || !clearTags(editBoxRef.current.innerHTML) || !thread) return;
    const subjectValue = thread && thread.participantImages.length > 1 ? thread.subject : '';

    const recipients = thread.participants.map(p => p.uuid) || [];

    const body: MessageBody = {
      body: editBoxRef.current.innerHTML,
      recipient_uids: recipients,
      subject: subjectValue,
    };

    dispatch(sendMessage({ threadId: thread.id, body }));

    sendGAEvent(recipients, loggedUserId, location.pathname);
    resetPlaceholder();
    focusChatEditBox();
    updateButton();
  };

  const keyDownHandler = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      send();
    }
  };

  const openAttachmentModal = (type: 'image'|'document', file?: File) => {
    showModal({
      type: ModalTypes.FileAttachmentModal,
      config: { size: 'md', className: 'multi-upload-modal' },
      data: {
        thread,
        file,
        type,
      },
    });
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (!e.clipboardData) return;
    const clipboardItems = e.clipboardData.files;
    if (clipboardItems['0']) {
      const pastedItem: File = clipboardItems['0'];
      const type = /^image\//.test(pastedItem.type) ? 'image' : 'document';
      openAttachmentModal(type, pastedItem);
    }
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    if (text) {
      document.execCommand('insertHTML', false, text);
    }
  };

  useEffect(() => {
    document.execCommand('defaultParagraphSeparator', false, 'p');
  }, []);

  useEffect(() => {
    focusChatEditBox();
  }, [thread?.id]);

  if (!thread || thread.left || !thread.enabled || thread.closed) return null;

  return (
    <div className={ styles && styles.footer ? styles.footer : 'messages-panel__footer messages-page' }>
      <ChatEmoji
        updateButton={ updateButton }
        editBoxRef={ editBoxRef }
        key={ thread.id }
      />
      <div className='eop-text-textarea__input'>
        <div
          key={ thread.id }
          id='message-area'
          aria-label='message area'
          data-name='message-area'
          onInput={ updateButton }
          placeholder={ intl.formatMessage({ id: 'messages.writeMessagePlaceHolder' }) }
          onKeyDown={ keyDownHandler }
          onPaste={ handlePaste }
          ref={ editBoxRef }
          role='presentation'
          contentEditable
          onBlur={ resetPlaceholder }
        />
      </div>
      <span
        className='icon icomoon-image'
        onClick={ () => openAttachmentModal('image') }
        role='presentation'
        title={ intl.formatMessage({ id: 'chat.icons.image' }) }
      />
      <span
        className='icon icomoon-attachment'
        onClick={ () => openAttachmentModal('document') }
        role='presentation'
        title={ intl.formatMessage({ id: 'chat.icons.document' }) }
      />
      <ChatLinks insertLink={ insertLink } editBoxRef={ editBoxRef } />
      <button
        className={ `eop-btn chat__send${
          !editBoxRef?.current?.innerHTML
          || !clearTags(editBoxRef.current.innerHTML)
          || loadingMessage
            ? ' chat__send--disabled'
            : ''
        }` }
        type='button'
        ref={ buttonRef }
        onClick={ send }
      >
        { styles && styles.footer ? (
          <FormattedMessage id='send' />
        ) : (
          <span className='icon icon__selected icon-small-Send' />
        ) }
      </button>
    </div>
  );
};

export default ChatFooter;
