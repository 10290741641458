import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TaskCard from '@src/js/views/user/pages/Dashboard/components/TaskWidget/TaskCard';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import { ButtonLink } from '@src/_v2/js/components/buttons';
import { Header, Footer } from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components';
import { getTaskListAction } from '@src/js/actions/tasks/task';
import { EmptyList, TaskList, TaskWrapper } from '@src/js/views/user/pages/Dashboard/components/TaskWidget/styled';

const TaskWidget = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.initInfo.userInfo);
  const taskList = useSelector(state => state.tasks.taskList);
  const tasksPageUrl = `/companies/${ userInfo.companySlug }/tasks`;

  useEffect(() => {
    if (userInfo.companySlug) {
      const filters = {
        company: userInfo.companySlug,
      };
      dispatch(getTaskListAction(filters));
    }
  }, []);

  return (taskList && taskList.length > 0)
    ? (
      <DashboardWidget className='p-x-1 p-y-1'>
        <Header>
          <FormattedMessage id='navigation.tasks' />
        </Header>
        { (taskList && taskList.length === 0) ? (
          <TaskWrapper>
            <EmptyList>
              <FormattedMessage id='tasks.empty.all' />
            </EmptyList>
          </TaskWrapper>
        ) : (
          <TaskList>
            { taskList.slice(0, 5).map(task => <TaskCard task={ task } key={ task.slug } userInfo={ userInfo } />) }
          </TaskList>
        )}
        <Footer>
          <ButtonLink text='viewAll' to={ tasksPageUrl } />
        </Footer>
      </DashboardWidget>
    )
    : null;
};

export default TaskWidget;
