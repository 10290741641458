import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import SelectBoxFilterBlock from './SelectBoxFilterBlock';

export default
class SelectBoxFilter extends Component {
  static propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    fieldLabel: PropTypes.string,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    selectedValues: PropTypes.array,
    prefix: PropTypes.string,
    formMutator: PropTypes.func,
    toggleOpened: PropTypes.bool,
    hiddenSearchBox: PropTypes.bool,
  }

  render() {
    const {
      input, options, fieldLabel, fieldName, onChange, placeholder, selectedValues, prefix,
      formMutator, toggleOpened, hiddenSearchBox,
    } = this.props;

    const selectBoxFilterProps = {
      ...input,
      placeholder,
      name: fieldName,
    };

    if (onChange) {
      selectBoxFilterProps.onChange = onChange;
    }

    selectBoxFilterProps.options = options;

    if (selectedValues) {
      selectBoxFilterProps.value = selectedValues;
    }

    return (
      <SelectBoxFilterBlock
        { ...selectBoxFilterProps }
        prefix={ prefix }
        label={ fieldLabel }
        fieldName={ fieldName }
        formMutator={ formMutator }
        toggleOpened={ toggleOpened }
        hiddenSearchBox={ hiddenSearchBox }
      />
    );
  }
}
