import fetchResource from '@src/js/api/fetch-resource';

export function uploadOnboardingFile(file, entity, fileType) {
  return fetchResource(`api/onboarding/file/${ entity }/${ fileType }`, {
    method: 'POST',
    body: file,
    noContentType: true,
  });
}

export function adminUploadOnboardingFile(file, entity, fileType) {
  return fetchResource(`api/admin/onboarding/file/${ entity }/${ fileType }`, {
    method: 'POST',
    body: file,
    noContentType: true,
  });
}
