import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '@src/js/api/session/getAllSessions';

import {
  GET_ALL_SESSIONS_START,
  GET_ALL_SESSIONS_ERROR,
  GET_ALL_SESSIONS_SUCCESS,
} from '@src/js/actions/session/getAllSessions';

function* getAllSessions() {
  try {
    const data = yield call(() => api.getAllSessions());
    yield put({ type: GET_ALL_SESSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ALL_SESSIONS_ERROR, error });
  }
}

function* getAllSessionsWatcher() {
  yield takeLatest(GET_ALL_SESSIONS_START, getAllSessions);
}

export default [
  getAllSessionsWatcher(),
];
