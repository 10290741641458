import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Button from '@src/js/components/global/buttons/Buttons';
import { routeCodes, withLang } from '@src/js/constants/routes';
import TextInput from '@src/js/components/global/inputs/TextInput';
import inputValidation from '@src/js/helpers/InputValidation';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { requestResetPassword } from '@src/js/api/requestResetPassword';
import { connect } from 'react-redux';
import ResetPasswordNotificationOverlay from './ResetPasswordNotificationOverlay';

class RequestResetPasswordForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    activeLanguage: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirectTo: false,
      openNotificationOverlay: false,
      submitting: false,
      mailError: null,
    };

    this.reCaptchaRef = React.createRef();
    window.recaptchaOptions = {
      lang: props.activeLanguage,
    };
  }

  onCaptchaChange = () => {
    const { url } = this.props;
    const grecaptcha = window.grecaptcha;

    const emailAddress = document.querySelector('[name=\'emailAddress\']').value;

    this.setState({ submitting: true, mailError: null });
    return requestResetPassword(emailAddress, grecaptcha.getResponse(), url)
      .then(() => {
        this.setState({ submitting: false });
        this.captchaPromiseResolve();
        return null;
      }).catch(errors => {
        this.setState({
          submitting: false,
          mailError: errors.response.message,
        });
        this.captchaPromiseReject(errors);
      });
  };

  setRedirectTo(redirectTo) {
    this.setState({
      redirectTo,
    });
  }

  submitForm = () => {
    const grecaptcha = window.grecaptcha;

    if (grecaptcha && grecaptcha.getResponse()) {
      grecaptcha.reset();
    }

    return new Promise((resolve, reject) => {
      this.captchaPromiseResolve = resolve;
      this.captchaPromiseReject = reject;
      grecaptcha.execute();
    }).then(() => {
      this.setRedirectTo(withLang(routeCodes.DASHBOARD));
      this.showNotificationOverlay();
      return null;
    }).catch(errors => errors);
  };

  validate = values => {
    const errors = {};

    errors.emailAddress = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.emailValidator
    )(values.emailAddress);

    return errors;
  };

  showNotificationOverlay() {
    this.setState({
      openNotificationOverlay: true,
    });
  }

  render() {
    const {
      redirectTo, openNotificationOverlay, submitting, mailError,
    } = this.state;
    const { className } = this.props;

    if (openNotificationOverlay && redirectTo) {
      return (
        <ResetPasswordNotificationOverlay redirectTo={ redirectTo } />
      );
    }

    return (
      <Form
        validate={ this.validate }
        onSubmit={ this.submitForm }
        render={ ({ handleSubmit }) => (
          <form
            className={ ClassNames(className) }
            onSubmit={ (e) => {
              e.preventDefault();
              handleSubmit();
            } }
          >
            <div className='set-password-form-container'>
              <div className='set-password-form-content'>

                { submitting && <Loader /> }

                <div className='set-password-form-header'>
                  <h2 className='set-password-form-title'>
                    <FormattedMessage id='requestResetPassword.formTitle' />
                  </h2>
                </div>
                <span className='set-password-form-description'>
                  <FormattedMessage id='requestResetPassword.formDescription' />
                </span>
                { mailError && (
                  <div className='set-password-form-error'>
                    { mailError }
                  </div>
                ) }
                <div className='set-password-form-body'>
                  <div className='eop-text-input col-12 mb-1 position-relative'>
                    <Field
                      name='emailAddress'
                      fieldName='emailAddress'
                      fieldLabel='requestResetPassword.enterEmail'
                      fieldType='email'
                      component={ TextInput }
                    />
                  </div>
                </div>

                <ReCAPTCHA
                  ref={ this.reCaptchaRef }
                  size='invisible'
                  sitekey={ loadEnvVariable('GOOGLE_RECAPTCHA_SITE_KEY') }
                  onChange={ this.onCaptchaChange }
                />

                <div className='set-password-form-footer'>
                  <Button
                    additionalClass='w-100'
                    buttonType='submit'
                    buttonText='send'
                  />
                </div>
              </div>
            </div>
          </form>
        ) }
      />
    );
  }
}

const mapStateToProps = state => ({
  activeLanguage: state.initInfo.activeLanguage,
});

const connectWrapper = connect(mapStateToProps)(RequestResetPasswordForm);

export default withRouter(connectWrapper);
