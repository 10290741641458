export const GET_OPPORTUNITY_PROFILE_START = 'GET_OPPORTUNITY_PROFILE_START';
export const GET_OPPORTUNITY_PROFILE_ERROR = 'GET_OPPORTUNITY_PROFILE_ERROR';
export const GET_OPPORTUNITY_PROFILE_SUCCESS = 'GET_OPPORTUNITY_PROFILE_SUCCESS';

export const GET_RELATED_OPPORTUNITIES_START = 'GET_RELATED_OPPORTUNITIES_START';
export const GET_RELATED_OPPORTUNITIES_ERROR = 'GET_RELATED_OPPORTUNITIES_ERROR';
export const GET_RELATED_OPPORTUNITIES_SUCCESS = 'GET_RELATED_OPPORTUNITIES_SUCCESS';

export function getOpportunityProfile(id) {
  return {
    type: GET_OPPORTUNITY_PROFILE_START,
    id,
  };
}

export function getRelatedOpportunities(name) {
  return {
    type: GET_RELATED_OPPORTUNITIES_START,
    name,
  };
}
