import globalMessages from '@src/js/oneplatform/messages';

const scope = 'PrivateMarketTemplates';

const GET_TEMPLATES_REQUESTED = `${ scope }/GET_TEMPLATES_REQUESTED`;
const GET_TEMPLATES_SUCCEEDED = `${ scope }/GET_TEMPLATES_SUCCEEDED`;
const GET_TEMPLATES_FAILED = `${ scope }/GET_TEMPLATES_FAILED`;

const UPDATE_TEMPLATE_REQUESTED = `${ scope }/UPDATE_TEMPLATE_REQUESTED`;
const UPDATE_TEMPLATE_SUCCEEDED = `${ scope }/UPDATE_TEMPLATE_SUCCEEDED`;
const UPDATE_TEMPLATE_FAILED = `${ scope }/UPDATE_TEMPLATE_FAILED`;

const ITEMS_ETINERARY_NETWORK_THEAD = {
  name: globalMessages.name,
  modified: globalMessages.templates.modified,
  type: globalMessages.type,
  action: globalMessages.action,
};

const TEMPLATE_TYPE_LTD = 1;
const TEMPLATE_TYPE_SIM = 2;
const TEMPLATE_TYPE_SIM_ITA = 3;
const TEMPLATE_TYPE_LTD_TRANSLATION = 'privateMarket.templates.form.legalOption.ltd';
const TEMPLATE_TYPE_SIM_TRANSLATION = 'privateMarket.templates.form.legalOption.sim';
const TEMPLATE_TYPE_SIM_ITA_TRANSLATION = 'privateMarket.templates.form.legalOption.simIta';

export {
  GET_TEMPLATES_REQUESTED,
  GET_TEMPLATES_SUCCEEDED,
  GET_TEMPLATES_FAILED,
  UPDATE_TEMPLATE_REQUESTED,
  UPDATE_TEMPLATE_SUCCEEDED,
  UPDATE_TEMPLATE_FAILED,
  ITEMS_ETINERARY_NETWORK_THEAD,
  TEMPLATE_TYPE_LTD,
  TEMPLATE_TYPE_LTD_TRANSLATION,
  TEMPLATE_TYPE_SIM,
  TEMPLATE_TYPE_SIM_TRANSLATION,
  TEMPLATE_TYPE_SIM_ITA,
  TEMPLATE_TYPE_SIM_ITA_TRANSLATION,
};
