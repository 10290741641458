export const PARTNER_COURSERA_ACCESS_START = 'PARTNER_COURSERA_ACCESS_START';
export const PARTNER_COURSERA_ACCESS_ERROR = 'PARTNER_COURSERA_ACCESS_ERROR';
export const PARTNER_COURSERA_ACCESS_SUCCESS = 'PARTNER_COURSERA_ACCESS_SUCCESS';

export function togglePartnerCourseraAccess(slug) {
  return {
    type: PARTNER_COURSERA_ACCESS_START,
    slug,
  };
}
