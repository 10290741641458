import { takeLatest, call, put } from 'redux-saga/effects';

import {
  EDIT_LIBRARY_START,
  EDIT_LIBRARY_ERROR,
  EDIT_LIBRARY_SUCCESS,
} from '@src/js/actions/library/editLibrary';

import * as api from '@src/js/api/library/editLibrary';

function* editLibrary(options) {
  try {
    const data = yield call(() => api.editLibrary(options.data.values, options.data.slug));
    yield put({ type: EDIT_LIBRARY_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: EDIT_LIBRARY_ERROR, error });
    options.data.reject(error);
  }
}

function* editLibraryWatcher() {
  yield takeLatest(EDIT_LIBRARY_START, editLibrary);
}

export default [
  editLibraryWatcher(),
];
