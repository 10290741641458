import { takeLatest, call, put } from 'redux-saga/effects';

import {
  HEADER_ELASTICSEARCH_START,
  HEADER_ELASTICSEARCH_ERROR,
  HEADER_ELASTICSEARCH_SUCCESS,
} from '@src/js/actions/elasticsearchHeader';

import * as api from '@src/js/api/elasticsearch';

function* getResults(options) {
  try {
    const data = yield call(() => api.getElasticsearchResults(options.query, options.page, options.limit));
    yield put({ type: HEADER_ELASTICSEARCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: HEADER_ELASTICSEARCH_ERROR, error });
  }
}

function* createElasticsearchWatcher() {
  yield takeLatest(HEADER_ELASTICSEARCH_START, getResults);
}

export default [
  createElasticsearchWatcher(),
];
