import produce from 'immer';

import {
  ADD_TRAINING_REQUESTED,
  ADD_TRAINING_SUCCEEDED,
  ADD_TRAINING_FAILED,
  GET_TRAININGS_REQUESTED,
  GET_TRAININGS_SUCCEEDED,
  GET_TRAININGS_FAILED,
} from '@src/js/oneplatform/trainings/container/constants';

export const initialState = {
  loading: false,
  error: null,
  subscribe: {
    loading: false,
    error: false,
    subscribed: false,
    allFilteredSubscribed: false,
  },
  trainings: {
    list: null,
    count: null,
  },
};

/* eslint-disable default-case,no-param-reassign */
function TrainingsReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_TRAININGS_REQUESTED:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_TRAININGS_SUCCEEDED:
        draft.loading = false;
        draft.error = null;
        draft.trainings = {
          list: action.payload?.results,
          count: action.payload?.count,
        };
        break;
      case GET_TRAININGS_FAILED:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case ADD_TRAINING_REQUESTED:
        draft.subscribe = {
          loading: true,
          error: null,
          subscribed: false,
          allFilteredSubscribed: false,
        };
        if (action.payload.isAllFiltered) draft.loading = true;
        break;
      case ADD_TRAINING_SUCCEEDED:
        draft.subscribe = {
          loading: false,
          error: null,
          subscribed: true,
          allFilteredSubscribed: !!action.payload.isAllFiltered,
        };
        if (action.payload.isAllFiltered) draft.loading = false;
        break;
      case ADD_TRAINING_FAILED:
        draft.subscribe.loading = false;
        draft.subscribe.error = action.payload;
        if (action.payload.isAllFiltered) draft.loading = false;
        break;
    }
  });
}
/* eslint-enable */

export default TrainingsReducer;
