import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';
import * as api from '@app/store/translations/api';
import { GET_INIT_INFO_START } from '@src/js/actions/initInfo';

import { showToastrSuccess, showToastrError } from '@app/store/global/actions';
import { getTranslationsSuccess, getTranslationsFail } from '@app/store/translations/actions';
import {
  GET_TRANSLATIONS_DATA_REQUEST,
  SEND_TRANSLATIONS_DATA_REQUEST,
  SEND_TRANSLATIONS_DATA_SUCCESS,
  SEND_TRANSLATIONS_DATA_FAIL,
} from '@app/store/translations/constants';

function* getTranslations({ filters }) {
  try {
    const data = yield call(() => api.getTranslations(filters));
    yield put(getTranslationsSuccess(data));
  } catch (error) {
    yield put(getTranslationsFail(error));
  }
}

function* sendTranslation({ text, message }) {
  try {
    const data = yield call(() => api.sendTranslation(text));
    yield all([
      put({ type: SEND_TRANSLATIONS_DATA_SUCCESS, data }),
      put({ type: GET_INIT_INFO_START }),
    ]);
    if (message) {
      yield put(showToastrSuccess(message.successTitle, message.successMsg, false));
    }
  } catch (error) {
    yield put({ type: SEND_TRANSLATIONS_DATA_FAIL, error });
    if (message.toastr) {
      yield put(showToastrError(message.errorTitle, message.errorMsg, false));
    }
  }
}

function* root() {
  yield takeLatest(GET_TRANSLATIONS_DATA_REQUEST, getTranslations);
  yield takeLatest(SEND_TRANSLATIONS_DATA_REQUEST, sendTranslation);
}

export default root;
