import { createDropdown } from '@ckeditor/ckeditor5-ui';
import { Plugin } from '@ckeditor/ckeditor5-core';
import { formIcon } from './Icons';
import DynamicsFormView from './DynamicsFormView';
/**
 * The plugin insert dynamics form.
 *
 * @extends module:core/plugin~Plugin
 */
export default class DynamicsControl extends Plugin {
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return 'DynamicsControl';
  }

  /**
   * @inheritDoc
   */
  init() {
    const { editor } = this;

    editor.ui.componentFactory.add('dynamicsControl', locale => {
      const dropdown = createDropdown(locale);
      const form = this.createFormView();
      dropdown.panelView.children.add(form);

      dropdown.buttonView.set({
        label: 'Dynamics Control',
        icon: formIcon,
        tooltip: true,
      });

      dropdown.render();

      return dropdown;
    });
  }

  createFormView() {
    const editor = this.editor;
    const formView = new DynamicsFormView(editor.locale);

    this.listenTo(formView, 'submit', () => {
      const blockElement = formView.blockInputView.fieldView.element as HTMLInputElement;
      const scriptElement = formView.scriptInputView.fieldView.element as HTMLInputElement;
      const divElement = formView.divInputView.fieldView.element as HTMLInputElement;
      const trackingElement = formView.trackingInputView.fieldView.element as HTMLInputElement;
      const websiteElement = formView.websiteInputView.fieldView.element as HTMLInputElement;
      const hostnameElement = formView.hostnameInputView.fieldView.element as HTMLInputElement;
      const content = {
        blockId: blockElement.value,
        scriptLoader: scriptElement.value,
        divId: divElement.value,
        scriptTracking: trackingElement.value,
        websiteId: websiteElement.value,
        hostname: hostnameElement.value,
      };

      const entries = Object.entries(content).filter(([_, v]) => v !== null && v !== '');

      if (entries.length > 0) {
        editor.model.change(writer => {
          const obj = JSON.stringify(Object.fromEntries(entries));
          editor.model.insertContent(writer.createText(`{{dynamics:${ obj }}}`));
        });
      }

      editor.editing.view.focus();
    });

    this.listenTo(formView, 'cancel', () => {
      editor.editing.view.focus();
    });

    return formView;
  }
}
