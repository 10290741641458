import { breakpoints } from '@src/js/constants/breakpoints';
import { useEffect, useState } from 'react';

const useBreakpoint = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const onWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  return {
    width,
    height,
    breakpoint: width < breakpoints.SMALL ? 'mobile' : 'desktop',
  };
};

export default useBreakpoint;
