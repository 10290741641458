import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { funding, tabs } from './constant';

import Tab from './Tab';

const Body = ({ tab, onSelectTab, type }) => {
  const [firstTab, setFirstTab] = useState(funding);
  const [otherTabs, setOtherTabs] = useState([]);

  useEffect(() => {
    const others = [...tabs];
    others.splice(0, 1);
    setOtherTabs(others);
  }, []);

  useEffect(() => {
    if (type) {
      const others = [...tabs];
      const index = tabs.indexOf(type);
      others.splice(index, 1);
      setFirstTab(tabs[index]);
      setOtherTabs(others);
    }
  }, [type]);

  return (
    <div className='etinerary-modal-body'>
      <Tab
        key={ firstTab }
        tabKey={ firstTab }
        onSelectTab={ () => onSelectTab(firstTab) }
        selected={ tab === firstTab }
      />
      <div className='etinerary-modal-others'>
        <FormattedMessage id='etinerary.modal.others' />
      </div>
      {otherTabs.map(el => (
        <Tab
          key={ el }
          tabKey={ el }
          onSelectTab={ () => onSelectTab(el) }
          selected={ tab === el }
        />
      ))}
    </div>
  );
};

Body.propTypes = {
  tab: PropTypes.string.isRequired,
  onSelectTab: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default Body;
