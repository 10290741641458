import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_PARTNER_COMPANIES_START,
  GET_PARTNER_COMPANIES_ERROR,
  GET_PARTNER_COMPANIES_SUCCESS,
} from '@src/js/actions/partner/partnerCompanies';

import * as api from '@src/js/api/partner/partnerCompanies';

function* getPartnerCompanies(options) {
  try {
    const data = yield call(() => api.getPartnerCompanies(options.slug, options.filters));
    yield put({ type: GET_PARTNER_COMPANIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PARTNER_COMPANIES_ERROR, error });
  }
}

function* getPartnerCompaniesWatcher() {
  yield takeLatest(GET_PARTNER_COMPANIES_START, getPartnerCompanies);
}

export default [
  getPartnerCompaniesWatcher(),
];
