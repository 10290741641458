import { takeLatest, call, put } from 'redux-saga/effects';
import { format } from 'date-fns';

import * as api from '@src/js/api/trainings';

import { handleFilters } from '@src/js/containers/PageTable/utils';

import {
  ADD_TRAINING_REQUESTED,
  GET_TRAININGS_REQUESTED,
} from '@src/js/oneplatform/trainings/container/constants';
import {
  addTrainingSucceeded,
  addTrainingFailed,
  getTrainingsSucceeded,
  getTrainingsFailed,
} from './actions';

function* addTraining({ payload }) {
  const { companySlug, callbacks, ...filters } = payload;
  const { loading, succeded, failed } = callbacks ?? {};
  try {
    if (loading) loading();
    const data = yield call(() => api.addTrainings(companySlug, handleFilters(filters)));
    if (data?.count > 0) {
      yield put(addTrainingSucceeded({ isAllFiltered: !succeded }));
      if (succeded) succeded();
    } else throw new Error('added failed!');
  } catch (error) {
    yield put(addTrainingFailed({ error, isAllFiltered: !failed }));
    if (failed) failed();
  }
}

function* getTrainings({
  filters: {
    eventDateBefore,
    eventDateAfter,
    ...values
  } = {},
}) {
  try {
    const filters = {
      ...values,
      eventCreatedEnd: eventDateBefore ? format(new Date(eventDateBefore), 'dd-MM-yyyy') : '',
      eventCreatedStart: eventDateAfter ? format(new Date(eventDateAfter), 'dd-MM-yyyy') : '',
    };
    const data = yield call(() => api.getTrainings(handleFilters(filters)));
    yield put(getTrainingsSucceeded(data));
  } catch (error) {
    yield put(getTrainingsFailed(error));
  }
}

function* root() {
  yield takeLatest(ADD_TRAINING_REQUESTED, addTraining);
  yield takeLatest(GET_TRAININGS_REQUESTED, getTrainings);
}

export default root;
