import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS_START,
  GET_REGIONS_ERROR,
  GET_REGIONS_SUCCESS,
  GET_PROVINCES_START,
  GET_PROVINCES_ERROR,
  GET_PROVINCES_SUCCESS,
} from '@src/js/actions/taxonomy/countries';

const initialState = {
  loading: false,
  error: null,
  countries: null,
  regions: null,
};

const actionsMap = {
  [GET_COUNTRIES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    countries: null,
  }),
  [GET_COUNTRIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_COUNTRIES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    countries: action.data,
  }),
  [GET_REGIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    regions: null,
  }),
  [GET_REGIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_REGIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    regions: action.data,
  }),
  [GET_PROVINCES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    provinces: null,
  }),
  [GET_PROVINCES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_PROVINCES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    provinces: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
