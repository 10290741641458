import {
  takeEvery, put,
} from 'redux-saga/effects';

import {
  SET_SELECTED_TAB_START,
  SET_SELECTED_TAB_SUCCESS,
} from '@src/js/marketplace/actions/marketplaceDashboard';

function* selectedTab(data) {
  yield put({ type: SET_SELECTED_TAB_SUCCESS, index: data.index });
}

export function* selectedTabWatcher() {
  yield takeEvery(SET_SELECTED_TAB_START, selectedTab);
}

export default [
  selectedTabWatcher(),
];
