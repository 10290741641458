import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_COMPANY_PERMISSIONS_START,
  GET_COMPANY_PERMISSIONS_ERROR,
  GET_COMPANY_PERMISSIONS_SUCCESS,
} from '@src/js/actions/company/companyPermissions';

import * as api from '@src/js/api/company/companyPermissions';

function* getCompanyPermissions(options) {
  try {
    const data = yield call(() => api.getCompanyPermissions(options.slug, options.source));
    yield put({ type: GET_COMPANY_PERMISSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANY_PERMISSIONS_ERROR, error });
  }
}

function* getCompanyPermissionsWatcher() {
  yield takeLatest(GET_COMPANY_PERMISSIONS_START, getCompanyPermissions);
}

export default [
  getCompanyPermissionsWatcher(),
];
