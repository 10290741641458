import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_BANNERS_START,
  GET_BANNERS_ERROR,
  GET_BANNERS_SUCCESS,
} from '@src/js/actions/banners';

import * as api from '@src/js/api/banners';

function* getBanners({ bannerType }) {
  try {
    const data = yield call(() => api.getBanners(bannerType));
    yield put({ type: GET_BANNERS_SUCCESS, data, bannerType });
  } catch (error) {
    yield put({ type: GET_BANNERS_ERROR, error });
  }
}

export function* getBannersWatcher() {
  yield takeEvery(GET_BANNERS_START, getBanners);
}

export default [
  getBannersWatcher(),
];
