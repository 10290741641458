import React from 'react';
import { FormattedMessage } from 'react-intl';

const Header = () => (
  <>
    <div className='etinerary-modal-header-title'>
      <FormattedMessage id='etinerary.modal.title' />
    </div>
    <div className='etinerary-modal-header-subtitle'>
      <FormattedMessage id='etinerary.modal.subtitle' />
    </div>
  </>
);

export default Header;
