import React, { Fragment } from 'react';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { RequestCallTypes } from '@src/js/constants/entities';
import { Images } from '@src/js/constants/images';
import { textLength } from '@src/js/constants/textLength';
import { dateFormat } from '@src/js/utils/date';
import { purifyHtml } from '@src/js/utils';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { Library } from '@app/models';
import { selectActiveLanguage, selectUserInfo } from '@app/store/global/selectors/initInfo';
import { handleDimensionsStrings } from './utils';

type Props = {
  library: Library;
  route?: string;
  disabled?: boolean;
  noShadow?: boolean;
};

const LibraryCard = ({
  library,
  disabled,
  noShadow,
  route = routeCodes.LIBRARY,
}: Props) => {
  const intl = useIntl();
  const activeLanguage = selectActiveLanguage();
  const { showModal } = useGlobalModalContext();
  const userInfo = selectUserInfo();
  let formattedTitle = library.title.substring(0, textLength.ARTICLE_TITLE);
  let lastSpace = formattedTitle.lastIndexOf(' ');
  if (lastSpace && formattedTitle.length === textLength.ARTICLE_TITLE) {
    formattedTitle = library.title.substring(0, lastSpace);
    formattedTitle = formattedTitle.concat('...');
  }

  let formattedContent = library.content
    .replace(/{{(wistia|video):[^{]*}}/gm, '')
    .substring(0, textLength.ARTICLE_CONTENT);
  lastSpace = formattedContent.lastIndexOf(' ');
  if (lastSpace && formattedContent.length === textLength.ARTICLE_CONTENT) {
    formattedContent = library.content.substring(0, lastSpace);
    formattedContent = formattedContent.concat('...');
  }

  const openRenewModal = () => {
    showModal({
      type: ModalTypes.RequestCall,
      title: 'membership.renew_modal.title',
      config: { size: 'lg' },
      data: {
        requestType: RequestCallTypes.MembershipRenew,
      },
    });
  };

  const coverLibrary = () => {
    if (library.videoUrl && userInfo.membershipRenew) {
      return (
        <div
          className='article-block__cover wistia-placeholder cursor-pointer'
          onClick={ openRenewModal }
          role='presentation'
        >
          <img
            src='/assets/img/WistiaBlurred.jpg'
            width='359'
            height='180'
            alt='Wistia'
            className='wistia_embed elite-profile-content-wistia-embed'
          />
        </div>
      );
    }
    if (library.videoUrl?.content) {
      return (
        <div className='wistia-video'>
          <div>
            <iframe
              src={ library.videoUrl?.content }
              frameBorder='0'
              scrolling='no'
              className='wistia_embed elite-profile-content-wistia-embed'
              name='wistia_embed'
              title='Wistia Embed'
              width='100%'
              height='100%'
              allowFullScreen
            />
          </div>
        </div>
      );
    }

    return (
      <NavLink
        className='article-block__cover'
        exact
        to={ !disabled ? `${ withLang(route) }/${ library.slug }` : '#' }
        style={ {
          padding: '1rem',
          background: `url(${
            library.thumbnail ? library.thumbnail : Images.EliteSmall
          }) no-repeat center content-box`,
          backgroundSize: 'contain',
        } }
      />
    );
  };

  const isVideoPills = library.videoUrl && library.speakers && library.speakers?.length > 0;

  return (
    <article className={ `article-block${ noShadow ? ' no-shadow' : '' }${
      isVideoPills ? ' vod-card' : '' }` }
    >
      <div className='article-block__header'>{coverLibrary()}</div>
      <div
        className={ `article-block__content${
          isVideoPills ? ' vod-card__title' : ''
        }` }
      >
        { !isVideoPills && (
          <div className='article-block__category-date'>
            <h4>
              {library?.dimension
                && (typeof library.dimension === 'string'
                  ? library.dimension
                  : handleDimensionsStrings(library.dimension, intl).join(
                    ', '
                  ))}
            </h4>
            <h4>{dateFormat(library.date, 'dd MMM yyyy', activeLanguage)}</h4>
          </div>
        )}
        { userInfo.membershipRenew && library.videoUrl ? (
          <div
            className='article-block__title cursor-pointer'
            role='presentation'
            onClick={ openRenewModal }
          >
            {formattedTitle}
          </div>
        ) : (
          <NavLink
            className='article-block__title cursor-pointer'
            exact
            to={ !disabled ? `${ withLang(route) }/${ library.slug }` : '#' }
          >
            {formattedTitle}
          </NavLink>
        )}

        { !isVideoPills && (
          <div className={ 'article-block__wrapper' }>
            <p
              key={ formattedContent }
              className='article-block__wrapper-paragraphs blue-a'
              dangerouslySetInnerHTML={ { __html: purifyHtml(formattedContent) } }
            />
          </div>
        )}
        { isVideoPills && (
          <div className='vod-card__content'>
            <div className='vod-card__video-info'>
              <h4 className='eop-text-color-teal'>{library.videoDuration}</h4>
              <h4>{dateFormat(library.date, 'dd MMM yyyy', activeLanguage)}</h4>
            </div>
            {library.speakers?.map((speaker) => (
              <Fragment key={ speaker.fullName }>
                <div className='vod-card__speaker'>
                  <p className='vod-card__speaker-fullName'>
                    {speaker.fullName}
                  </p>
                  <p className='vod-card__speaker-description'>
                    {speaker.description}
                  </p>
                </div>
                <hr />
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </article>
  );
};

export default LibraryCard;
