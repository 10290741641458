import React from 'react';
import { FormattedMessage } from 'react-intl';

export const YES_OR_NO = [
  { value: true, label: <FormattedMessage id='yes' /> },
  { value: false, label: <FormattedMessage id='no' /> },
];

export const registeredOffice = [
  {
    value: 0,
    label: <FormattedMessage id='compliance.form.company.clientRegisteredOffices.certificateOfIncorporation' />,
  },
  { value: 1, label: <FormattedMessage id='compliance.form.company.clientRegisteredOffices.companiesHouse' /> },
];

export const clientsBusinessAddress = [
  { value: 0, label: <FormattedMessage id='compliance.form.company.clientsBusinessAddress.companiesHouse' /> },
  { value: 1, label: <FormattedMessage id='compliance.form.company.clientsBusinessAddress.addressConfirmation' /> },
];

export const clientHeadquartersAddress = [
  { value: 0, label: <FormattedMessage id='compliance.form.company.clientHeadquartersAddress.governmentDocument' /> },
  { value: 1, label: <FormattedMessage id='compliance.form.company.clientHeadquartersAddress.invoiceCopy' /> },
];

export const mifidClassification = [
  { value: 0, label: <FormattedMessage id='compliance.form.aml.mifidClassification.retail' /> },
  { value: 1, label: <FormattedMessage id='compliance.form.aml.mifidClassification.eligibleCounterpart' /> },
  { value: 2, label: <FormattedMessage id='compliance.form.aml.mifidClassification.professional' /> },
];

export const costumerDiligenceLevel = [
  { value: 0, label: <FormattedMessage id='compliance.form.aml.costumerDiligenceLevel.enhanced' /> },
  { value: 1, label: <FormattedMessage id='compliance.form.aml.costumerDiligenceLevel.simplified' /> },
  { value: 2, label: <FormattedMessage id='compliance.form.aml.costumerDiligenceLevel.standard' /> },
];
