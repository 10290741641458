type Obj = {
  [key: string]: Value
}
type Value = Obj|string|number|null|undefined|boolean|Value[];

export const objectValue = (obj: Obj, key: string): Value => {
  let value: Value = obj;
  key.split('.').forEach(k => {
    if (!(value instanceof Object) || Array.isArray(value)) {
      value = undefined;
      return;
    }
    value = value[k];
  });

  return value;
};
