import styled from 'styled-components';
import {
  FontSize, FontWeight, ThemeColors, TruncateLine,
} from '@src/_v2/js/constants';
import { Breakpoints } from '@src/_v2/js/constants/breakpoints';

export const Container = styled.div`
  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    .card.file-preview-card {
      margin-right: 0;
    }
  }
`;
export const FileName = styled.div`
  flex-grow: 1;
  font-weight: ${ FontWeight.normal };
  font-size: ${ FontSize.iconLarge };
  flex-direction: row;
  height: 2.2rem;
  letter-spacing: 0.05rem;
  ${ TruncateLine };

  a {
    color: ${ ThemeColors.black };
    text-decoration: none;
  }
`;

export const RemoveIcon = styled.div`
  position: absolute;
  top: 0;
  right: -4.2rem;
  background-color: ${ ThemeColors.softBlue };
  width: 4rem;
  height: 4rem;
  font-size: ${ FontSize.caption };
  text-align: center;
  padding: 0;
  cursor: pointer;
  z-index: 1;

  ::before {
    vertical-align: -75%;
    font-family: eliteIconfontSmall, sans-serif;
    content: '\\E90B';
    color: ${ ThemeColors.lightRed };
    font-size: ${ FontSize.subHeader };
  }

  :hover {
    background-color: ${ ThemeColors.lightRed };

    ::before {
      color: ${ ThemeColors.softBlue };
    }
  }

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    right: 0;
  }
`;
