import React from 'react';

const EliteLoader = () => (
  <div className='page-items-loading-wrapper' style={ { display: 'none' } }>
    <svg
      className='loader-spinning'
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M89 50C89 71.5391 71.5391 89 50 89C28.4609 89 11 71.5391 11 50C11 28.4609 28.4609 11 50 11C71.5391
        11 89 28.4609 89 50ZM19.8245 50C19.8245 66.6655 33.3345 80.1755 50 80.1755C66.6655 80.1755 80.1755
        66.6655 80.1755 50C80.1755 33.3345 66.6655 19.8245 50 19.8245C33.3345 19.8245 19.8245 33.3345 19.8245 50Z'
        fill='#E5E8EA'
        fillOpacity='0.2'
      />
      <path
        d='M84.5877 50C87.0246 50 89.0258 51.9816 88.7507 54.4029C88.3405 58.0127 87.4271 61.5548 86.0313
        64.9247C84.0714 69.6564 81.1986 73.9557 77.5772 77.5772C73.9557 81.1986 69.6564 84.0714 64.9247
        86.0313C61.5548 87.4271 58.0127 88.3405 54.4029 88.7507C51.9816 89.0258 50 87.0246 50 84.5877V84.5877C50
        82.1509 51.9858 80.2085 54.3966 79.8535C56.8469 79.4926 59.2491 78.8306 61.5477 77.8785C65.2087 76.3621
        68.5352 74.1394 71.3373 71.3373C74.1394 68.5352 76.3621 65.2087 77.8785 61.5477C78.8306 59.2491 79.4926
        56.8469 79.8535 54.3966C80.2085 51.9858 82.1509 50 84.5877 50V50Z'
        fill='currentColor'
      />
    </svg>
  </div>
);

export default EliteLoader;
