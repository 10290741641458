import fetchResource from '@src/js/api/fetch-resource';
import { ectPath } from '@src/js/constants/routes';

export function toggleCompanyCertification(slug) {
  return fetchResource(`api/admin/companies/${ slug }/certify`, {
    method: 'PUT',
    slug,
  });
}

export function requestCompanyCertification(notificationType) {
  return fetchResource(`${ ectPath() }/api/certificate/request`, {
    method: 'POST',
    body: {
      notificationType,
    },
    externalUrl: true,
  });
}
