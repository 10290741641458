import FormattedMessageHTML from '@app/components/global/FormattedMessageHTML';
import { Icons } from '@src/js/constants/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FinalPage = ({ title, content }) => (
  <section className='elite-white-box--form text-center flex-h-gap-48 m-t-5'>
    { title && <div className='font-size-headline font-weight-bold'><FormattedMessage id={ title } /></div> }
    { content && (
      <div className='d-flex flex-h-gap-16 flex-all-center'>
        <FormattedMessageHTML
          id={ content }
          className='
          font-size-subheader elite-text-color-medium-grey lh-24 font-weight-medium compliance__final-section-text'
        />
      </div>
    )}
    <img src={ Icons.Sent } alt='Message Sent' />
  </section>
);

FinalPage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default FinalPage;
