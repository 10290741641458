import {
  GET_SPECIFIC_OPPORTUNITIES_ERROR,
  GET_SPECIFIC_OPPORTUNITIES_START,
  GET_SPECIFIC_OPPORTUNITIES_SUCCESS,
  GET_SUCCESS_STORIES_START,
  GET_SUCCESS_STORIES_ERROR,
  GET_SUCCESS_STORIES_SUCCESS,
  GET_FUNDING_INTERVIEWS_START, GET_FUNDING_INTERVIEWS_ERROR, GET_FUNDING_INTERVIEWS_SUCCESS,
  GET_FUNDING_INSIGHTS_START, GET_FUNDING_INSIGHTS_ERROR, GET_FUNDING_INSIGHTS_SUCCESS,
  GET_FINANZA_OPPORTUNITIES_ERROR, GET_FINANZA_OPPORTUNITIES_START, GET_FINANZA_OPPORTUNITIES_SUCCESS,
} from '@app/store/funding/actions';

export const initialState = {
  fundingOptions: [
    {
      id: 1,
      heading: 'funding.fundingOptions.transaction.heading',
      body: 'funding.fundingOptions.transaction.body',
      icon: 'equity',
      action: 'action',
    },
    {
      id: 2,
      heading: 'funding.fundingOptions.basketBond.heading',
      body: 'funding.fundingOptions.basketBond.body',
      icon: 'debt',
      action: 'action',
    },
    {
      id: 3,
      heading: 'funding.fundingOptions.introduction.heading',
      body: 'funding.fundingOptions.introduction.body',
      icon: 'IPO',
      action: 'action',
    },
  ],
  opportunities: null,
  opportunitiesLoading: false,
  opportunitiesError: null,
  successStoriesLoading: false,
  successStoriesError: null,
  successStories: null,
  finanzaOpportunities: null,
  fundingInterviews: [],
  fundingInterviewsLoading: false,
  fundingInterviewsError: false,
  fundingInsights: [],
  fundingInsightsLoading: false,
  fundingInsightsError: false,
};

const actionsMap = {
  [GET_SPECIFIC_OPPORTUNITIES_START]: (state) => ({
    ...state,
    opportunitiesError: null,
    opportunitiesLoading: true,
    opportunities: null,
  }),
  [GET_SPECIFIC_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    opportunitiesError: action.error.message,
    opportunitiesLoading: false,
  }),
  [GET_SPECIFIC_OPPORTUNITIES_SUCCESS]: (state, action) => ({
    ...state,
    opportunitiesError: null,
    opportunitiesLoading: false,
    opportunities: action.data.data?.items,
  }),
  [GET_SUCCESS_STORIES_START]: (state) => ({
    ...state,
    successStoriesLoading: true,
    successStoriesError: null,
    successStories: state.successStories,
  }),
  [GET_SUCCESS_STORIES_ERROR]: (state, action) => ({
    ...state,
    successStoriesLoading: false,
    successStoriesError: action.error.message,
    successStories: null,
  }),
  [GET_SUCCESS_STORIES_SUCCESS]: (state, action) => {
    let successStories;
    if (action.data.meta.pagination.page > 1) {
      successStories = state.successStories.concat(action.data.data.items);
    } else {
      successStories = action.data.data.items;
    }

    return {
      ...state,
      successStoriesLoading: false,
      successStoriesError: null,
      successStories,
    };
  },
  [GET_FUNDING_INTERVIEWS_START]: (state) => ({
    ...state,
    fundingInterviewsLoading: true,
    fundingInterviewsError: null,
  }),
  [GET_FUNDING_INTERVIEWS_ERROR]: (state, action) => ({
    ...state,
    fundingInterviewsLoading: false,
    fundingInterviewsError: action.error.message,
    fundingInterviews: null,
  }),
  [GET_FUNDING_INTERVIEWS_SUCCESS]: (state, action) => {
    let fundingInterviews;
    if (action.data.meta.pagination.page > 1) {
      fundingInterviews = state.fundingInterviews.concat(action.data.data.items);
    } else {
      fundingInterviews = action.data.data.items;
    }

    return {
      ...state,
      fundingInterviewsLoading: false,
      fundingInterviewsError: null,
      fundingInterviews,
    };
  },
  [GET_FUNDING_INSIGHTS_START]: (state) => ({
    ...state,
    fundingIsightsLoading: true,
    fundingInsightsError: null,
  }),
  [GET_FUNDING_INSIGHTS_ERROR]: (state, action) => ({
    ...state,
    fundingIsightsLoading: false,
    fundingInsightsError: action.error.message,
    fundingInsights: null,
  }),
  [GET_FUNDING_INSIGHTS_SUCCESS]: (state, action) => ({
    ...state,
    fundingIsightsLoading: false,
    fundingInsightsError: null,
    fundingInsights: action.data.items,
  }),
  [GET_FINANZA_OPPORTUNITIES_START]: (state) => ({
    ...state,
    opportunitiesError: null,
    opportunitiesLoading: true,
    finanzaOpportunities: null,
  }),
  [GET_FINANZA_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    opportunitiesError: action.error.message,
    opportunitiesLoading: false,
  }),
  [GET_FINANZA_OPPORTUNITIES_SUCCESS]: (state, action) => ({
    ...state,
    opportunitiesError: null,
    opportunitiesLoading: false,
    finanzaOpportunities: action.data.data?.items,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
