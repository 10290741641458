import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_PARTNER_FORM_OPTIONS_START,
  GET_PARTNER_FORM_OPTIONS_SUCCESS,
  GET_PARTNER_FORM_OPTIONS_ERROR,
} from '@src/js/actions/partner/partnerFormOptions';

import { getPartnerFormOptions as getPartnerFormOptionsApi } from '@src/js/api/partner/partnerFormOptions';

function* getPartnerFormOptions(options) {
  try {
    const data = yield call(() => getPartnerFormOptionsApi(options.slug, options.userType));
    yield put({ type: GET_PARTNER_FORM_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PARTNER_FORM_OPTIONS_ERROR, error });
  }
}

export function* getPartnerFormOptionsWatcher() {
  yield takeLatest(GET_PARTNER_FORM_OPTIONS_START, getPartnerFormOptions);
}

export default [
  getPartnerFormOptionsWatcher(),
];
