import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_EVENT_FORM_OPTIONS_START,
  ADMIN_GET_EVENT_FORM_OPTIONS_SUCCESS,
  ADMIN_GET_EVENT_FORM_OPTIONS_ERROR,
} from '@src/js/actions/events/adminEvents';

import { getEventFormOptions as getEventFormOptionsApi } from '@src/js/api/events/adminEvent';

function* getEventFormOptions(options) {
  try {
    const data = yield call(() => getEventFormOptionsApi(options.slug));
    yield put({ type: ADMIN_GET_EVENT_FORM_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_EVENT_FORM_OPTIONS_ERROR, error });
  }
}

export function* getEventFormOptionsWatcher() {
  yield takeLatest(ADMIN_GET_EVENT_FORM_OPTIONS_START, getEventFormOptions);
}

export default [
  getEventFormOptionsWatcher(),
];
