export const EDIT_NEWS_START = 'EDIT_NEWS_START';
export const EDIT_NEWS_ERROR = 'EDIT_NEWS_ERROR';
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';

export function editNews(data) {
  return {
    type: EDIT_NEWS_START,
    data,
  };
}
