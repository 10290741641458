import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import * as PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { get as lodashGet } from 'lodash';
import { FormError } from '@src/js/layouts/forms/company/components/FormSubmit/styled';
import { showToastrError } from '@app/store/global/actions';
import CompanyInfo from
  '@src/js/views/user/pages/ManagerCompanies/components/CreateCompanyModal/components/CompanyInfo/CompanyInfo';
import UserInfo from
  '@src/js/views/user/pages/ManagerCompanies/components/CreateCompanyModal/components/UserInfo';
import Button from '@src/js/components/global/buttons/Buttons';
import { Checkbox } from '@src/js/components/global/inputs';
import {
  getExternalCompanyFormOptionsAction, selfRegistrationCreateCompanyAction,
} from '@src/js/actions/self-registration/company/externalCompanyFormOptions';
import * as CompanyFormModalService from
  '@src/js/views/user/pages/ManagerCompanies/components/CreateCompanyModal/services/CompanyFormModalService';
import PageLoader from '@src/js/components/global/pageLoader/PageLoader';
import { routeCodes, withLang } from '@src/js/constants/routes';
import loadEnvVariable from '@src/js/static/LoadEnv';
import {
  ButtonsContainer, Container, ContainerSuccessBox, SuccessBox, SuccessBoxContent,
} from './styled';

const recaptchaRef = React.createRef();

const initialState = {
  errors: null,
  formData: null,
  formLoader: false,
  isTermsAccepted: false,
  shouldShowSuccessMessage: false,
  gRecaptchaResponse: null,
  startValidation: false,
};

class CreateCompanyModal extends Component {
  static propTypes = {
    intl: PropTypes.object,
    createCompany: PropTypes.func,
    companyFormOptions: PropTypes.object,
    companyFormOptionsError: PropTypes.object,
    getCompanyFormOptions: PropTypes.func,
    showToastrErrorAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = initialState;

    localStorage.setItem('redirect', withLang(routeCodes.DASHBOARD));
  }

  componentDidMount() {
    const { getCompanyFormOptions } = this.props;
    getCompanyFormOptions();
  }

  componentDidUpdate() {
    const { companyFormOptionsError, showToastrErrorAction } = this.props;

    if (companyFormOptionsError) {
      showToastrErrorAction('notification.error_title', 'companyFormOptionsError.response');
    }
  }

  setFormMutator = () => ({
    ...arrayMutators,
    setSector: (args, state, utils) => {
      utils.changeValue(state, 'basic.sector', () => args[0]);
    },
    setSecondLevelSector: (args, state, utils) => {
      utils.changeValue(state, 'basic.secondLevelSector', () => args[0]);
    },
    setTurnover: (args, state, utils) => {
      utils.changeValue(state, 'basic.turnover', () => args[0]);
    },
    setCompanyEtineraryType: (args, state, utils) => {
      utils.changeValue(state, 'basic.etineraryCompanyType', () => args[0]);
    },
    setIsTermsAndConditionChecked: (args, state, utils) => {
      utils.changeValue(state, 'termsIsAccepted', () => args[0]);
    },
  });

  handleTermsAndConditionChange = (event, form) => {
    const isChecked = event.target.checked;

    this.setState({
      isTermsAccepted: isChecked,
    }, () => {
      form.mutators.setIsTermsAndConditionChecked(isChecked);
    });
  };

  validateForm = (values) => {
    const validate = CompanyFormModalService.validateForm(this, values, true);
    const find = (value) => {
      if (typeof value === 'object') {
        return Object.values(value).find(find);
      }
      return value !== undefined;
    };
    const hasError = Object.values(validate).find(find) !== undefined;
    this.setState({
      formError: hasError,
    });
    return validate;
  };

  getReferrer = () => {
    const location = (window.location !== window.parent.location)
      ? document.referrer
      : document.location.href;
    const url = new URL(location);
    const currentUrl = new URL(document.location.href);

    return currentUrl?.searchParams?.get('ref') || `${ url.protocol }//${ url.hostname }`;
  };

  resetGoogleRecaptcha = () => {
    recaptchaRef.current.reset();
    this.setState({
      gRecaptchaResponse: null,
    });
  };

  handleSubmit = (submittedCompany) => {
    const { createCompany, showToastrErrorAction } = this.props;
    const { gRecaptchaResponse } = this.state;
    const companyData = CompanyFormModalService.copyObject(submittedCompany);
    CompanyFormModalService.setFormLoader(this, true);

    if (!companyData.basic) {
      companyData.basic = {};
    }

    if (companyData?.basic?.sectors?.length === 2) {
      companyData.basic.sector = companyData.basic.sectors[0]?.id;
      companyData.basic.secondLevelSector = companyData.basic.sectors[1]?.id;
    }
    companyData.referer = this.getReferrer();
    companyData.gRecaptchaResponse = gRecaptchaResponse;

    companyData.dates = {
      eliteMemberSince: null,
      dateIncorporation: null,
    };
    companyData.basic.turnoverCurrency = loadEnvVariable('ID_EURO_VALUE');

    return new Promise((resolve, reject) => { // eslint-disable-line consistent-return
      createCompany({ values: companyData, resolve, reject });
    }).then(() => {
      CompanyFormModalService.setFormLoader(this, false);
      this.setState({ shouldShowSuccessMessage: true });
      return null;
    }).catch(submitError => {
      this.resetGoogleRecaptcha();
      CompanyFormModalService.setFormLoader(this, false);
      const firstErrorKey = Object.keys(submitError)[0];
      const message = lodashGet(submitError, 'basic.name') ? submitError.basic.name : submitError[firstErrorKey];

      showToastrErrorAction('notification.error_title', message);

      return submitError;
    });
  };

  resetForm = (reset) => {
    this.setState({
      shouldShowSuccessMessage: false,
      isTermsAccepted: false,
    });
    reset();
  };

  renderSuccessBox = (reset) => (
    <SuccessBox>
      <Row>
        <SuccessBoxContent>
          <FormattedMessage id='selfRegistration.companyForm.createSuccessMessage' />
        </SuccessBoxContent>
      </Row>
      <ButtonsContainer>
        <Button buttonText='ok' onClickFunc={ () => this.resetForm(reset) } />
      </ButtonsContainer>
    </SuccessBox>
  );

  handleRecaptchaChange = (value) => {
    this.setState({
      gRecaptchaResponse: value,
    });
  };

  renderFormFields = (form, reset) => {
    const { companyFormOptions, intl } = this.props;
    const {
      isTermsAccepted, gRecaptchaResponse, formError, startValidation,
    } = this.state;
    const sitekey = loadEnvVariable('GOOGLE_RECAPTCHA_SITE_KEY');

    return (
      <>
        <UserInfo />
        <CompanyInfo
          intl={ intl }
          companyFormOptions={ companyFormOptions }
          form={ form }
        />
        <Field
          name='termsIsAccepted'
          fieldName='termsIsAccepted'
          fieldLabel='company_form.termsAndConditionLabel'
          component={ Checkbox }
          input={ {
            onChange: (e) => this.handleTermsAndConditionChange(e, form),
            checked: isTermsAccepted,
          } }
        />
        <ReCAPTCHA ref={ recaptchaRef } sitekey={ sitekey } onChange={ this.handleRecaptchaChange } />
        <Row>
          <ButtonsContainer>
            <Button
              buttonType='submit'
              buttonText='save'
              additionalClass='m-r-2'
              disabled={ form.getState().submitting || !gRecaptchaResponse }
              onClickFunc={ () => this.setState({ startValidation: true }) }
            />
            <Button buttonText='cancel' emptyButtonType='empty-grey' onClickFunc={ reset } />
          </ButtonsContainer>
        </Row>
        <Row>
          <ButtonsContainer>
            <FormError style={ { visibility: startValidation && formError ? 'visible' : 'hidden' } }>
              <FormattedMessage id='selfRegistration.error' />
            </FormError>
          </ButtonsContainer>
        </Row>
      </>
    );
  };

  render() {
    const { formLoader, shouldShowSuccessMessage } = this.state;

    const RenderedContainer = shouldShowSuccessMessage ? ContainerSuccessBox : Container;

    return (
      <RenderedContainer>
        <Form
          validate={ this.validateForm }
          onSubmit={ this.handleSubmit }
          mutators={ this.setFormMutator() }
          render={ ({ handleSubmit, form, reset }) => (
            <form onSubmit={ handleSubmit }>
              { shouldShowSuccessMessage ? this.renderSuccessBox(reset) : this.renderFormFields(form, reset) }
            </form>
          )
          }
        />
        { formLoader && <PageLoader /> }
      </RenderedContainer>
    );
  }
}

/**
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state) => ({
  companyFormOptionsLoading: state.externalCompanyFormOptions.loading,
  companyFormOptionsError: state.externalCompanyFormOptions.error,
  companyFormOptions: state.externalCompanyFormOptions.companyFormOptions,
  companyError: state.externalCompanyFormOptions.error,
  companyLoading: state.externalCompanyFormOptions.loading,
  company: state.externalCompanyFormOptions.company,
});

/**
 * @param {Function} dispatch
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  createCompany: (company) => dispatch(selfRegistrationCreateCompanyAction(company)),
  getCompanyFormOptions: () => dispatch(getExternalCompanyFormOptionsAction()),
  showToastrErrorAction: (title, message) => dispatch(showToastrError(title, message)),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCompanyModal)));
