import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { PreferencesItem } from '@app/components/public/user/PreferencesItem';
import { useFormContext } from 'react-hook-form';
import { UserForm } from '@app/components/public/user/UserProfile';
import InputSwitch from '@app/components/global/forms/InputSwitch';
import {
  getProgramme,
  isBoth, isEtinerary, isGrowth, isGrowthStartup, isHidden, isProgramme,
} from '@src/js/helpers/helpers';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { PartnerProgramme } from '@src/js/constants/entities';

type Notification = {
  name: 'messages'|'isTracking'|'network'|'loginReminder'|'businessOpportunity',
  label: string,
}

const UserPreferences = () => {
  const {
    control, formState: { errors }, setValue, watch,
  } = useFormContext<UserForm>();
  const enabledRoles = !isHidden() && (isGrowthStartup() || isGrowth() || isProgramme() || isBoth());
  const growthCompanies = isGrowthStartup() || isGrowth();
  const { showModal } = useGlobalModalContext();

  const notifications: Notification[] = enabledRoles ? [
    { name: 'messages', label: 'messages' },
    { name: 'isTracking', label: 'tracking' },
    { name: 'network', label: 'generalUpdates' },
  ] : [];

  const programme = getProgramme();

  if ((growthCompanies || isEtinerary()) && (PartnerProgramme.ITALY === programme
      || PartnerProgramme.INTERNATIONAL === programme)) {
    notifications.push({ name: 'businessOpportunity', label: 'businessOpportunity' });
  }

  if (growthCompanies) {
    notifications.push({ name: 'loginReminder', label: 'loginReminder' });
  }

  const setAllNotificationOff = () => {
    notifications.forEach(n => {
      setValue(n.name, false);
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked === false) {
      showModal({
        type: ModalTypes.Confirmation,
        title: 'user.preferences.generalConfirmation',
        config: { size: 'md' },
        onTop: true,
        data: {
          action: () => {
            setValue('isSubscribed', false);
            setAllNotificationOff();
          },
          message: 'user.preferences.generalConfirmationMsg',
        },
      });
      return;
    }
    setValue('isSubscribed', e.target.checked);
  };

  const isSubscribed = watch('isSubscribed');

  return (
    <div className='preferences'>
      <div className='preferences__header'>
        <div className='preferences__header--big'>
          <FormattedMessage id='user.preferences.notifications' />
        </div>
        <div className='preferences__header--regular'>
          <FormattedMessage id='user.preferences.headerDescription' />
        </div>
      </div>

      <div className='preferences__item p-b-6'>
        <div className='preferences__item--left'>
          <div className='preferences__item--big'>
            <FormattedMessage id='user.preferences.general' />
          </div>
          <div className='preferences__item--regular'>
            <FormattedMessage id='user.preferences.generalDescription' />
          </div>
        </div>
        <InputSwitch
          name='isSubscribed'
          control={ control }
          errors={ errors }
          onChange={ onChange }
        />
      </div>
      {notifications.map(notification => (
        <PreferencesItem
          key={ notification.name }
          name={ notification.name }
          label={ notification.label }
          disabled={ !isSubscribed }
        />
      ))}
    </div>
  );
};

export default UserPreferences;
