import { UPDATE_DATA_START } from '@app/store/onboarding/actions';
import { COMPANY_FORM_STEPS } from '@app/store/onboarding/constants';
import { selectCurrentStep, selectOnboardingData, selectOnboardingSave } from '@app/store/onboarding/selectors';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { OnboardingTemplate } from '@src/js/constants/entities';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  BasicInfo, CompanySummary, Fundamentals, Declarations, Final, Directors,
} from './sections';
import CompanyProfile from './sections/CompanyProfile';

const CompanySteps = ({ hash }) => {
  const currentStep = selectCurrentStep();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const dispatch = useDispatch();
  const isAdmin = isAdminRole() || isPra();

  const handleSave = (formData, downloadPath) => {
    if (onSave.action !== 'save') {
      window.scrollTo(0, 0);
    }
    let data = { ...onboardingData };
    if (formData && onSave.step) {
      data = { ...data, ...formData };

      if (onboardingData?.template.docType === OnboardingTemplate.TYPE_SIGNATURE_REQUIRED
        && onSave.action !== 'submit'
        && currentStep !== COMPANY_FORM_STEPS.FINAL_SECTION) {
        data.terms = { ...data.terms, signedDocument: null };
      }
    }
    const params = {
      notify: onSave.action === 'submit' || onSave.action === 'save',
      submit: onSave.action === 'submit',
    };
    dispatch({
      type: UPDATE_DATA_START, hash, data, isAdmin, params, onSave, downloadPath,
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case COMPANY_FORM_STEPS.BASIC_INFO:
        return <BasicInfo hash={ hash } />;
      case COMPANY_FORM_STEPS.COMPANY_SUMMARY:
        return <CompanySummary handleSave={ handleSave } />;
      case COMPANY_FORM_STEPS.COMPANY_PROFILE:
        return <CompanyProfile handleSave={ handleSave } />;
      case COMPANY_FORM_STEPS.DIRECTORS:
        return <Directors handleSave={ handleSave } />;
      case COMPANY_FORM_STEPS.FUNDAMENTALS:
        return <Fundamentals handleSave={ handleSave } />;
      case COMPANY_FORM_STEPS.DECLARATIONS:
        return <Declarations handleSave={ handleSave } />;
      case COMPANY_FORM_STEPS.FINAL_SECTION:
        return <Final handleSave={ handleSave } hash={ hash } />;
      default:
        return <Loader />;
    }
  };

  return renderStep();
};

CompanySteps.propTypes = {
  hash: PropTypes.string.isRequired,
  intl: PropTypes.object,
};

export default CompanySteps;
