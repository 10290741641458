import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import SelectBox from '@src/js/components/global/inputs/Selectbox';
import { getLanguages } from '@src/js/helpers/helpers';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import ReactTooltip from 'react-tooltip';

const DEFAULT_LANGUAGE = 'en';

const LanguageSelect = ({
  lang, setLang, additionalClass, fieldRequired, fieldLabel, tooltip,
}) => {
  const languages = getLanguages();

  return (
    <div className={ `eop-text-textarea ${ additionalClass || '' }` }>
      <div className='eop-text-textarea__label'>
        { fieldRequired && <span className='eop-text-input__label__required-icon'>*</span> }
        { fieldLabel && (
        <>
          <FormattedMessage id={ fieldLabel } />
          { tooltip && (
          <span className='eop-text-textarea__label__info'>
            <span
              className='icon-medium-Info eop-text-input__label__info-icon'
              data-tip
              data-for='textArea-tooltip'
            />
            <ReactTooltip
              id='textArea-tooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id={ tooltip } />
            </ReactTooltip>
          </span>
          ) }
        </>
        )}
        <Field
          selectedValues={ {
            value: lang,
            label: <FormattedMessage id={ `lang_${ lang }` } />,
          } }
          name='lang'
          fieldName='lang'
          fieldType='text'
          additionalClass='eop-text-textarea__label__selectbox m-t-0'
          fieldNoClear={ true }
          options={ languages.map((langCode, idx) => ({
            index: idx,
            value: langCode,
            label: <FormattedMessage id={ `lang_${ langCode }` } />,
          })) }
          onChange={ el => { setLang(el.value); } }
          component={ SelectBox }
          simpleValue={ false }
        />
      </div>
    </div>
  );
};

LanguageSelect.propTypes = {
  lang: PropTypes.string,
  setLang: PropTypes.func,
  additionalClass: PropTypes.string,
  fieldRequired: PropTypes.bool,
  fieldLabel: PropTypes.string,
  tooltip: PropTypes.string,
};

LanguageSelect.defaultProps = {
  lang: DEFAULT_LANGUAGE,
};

export default LanguageSelect;
