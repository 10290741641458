import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import CheckboxTree from 'react-checkbox-tree';

const InputSelect = ({
  intl, name, label, translate, disabled, required, errors, control,
  nodes, onChange, defaultValue, className, tooltip, checked,
  expanded, numeric,
}) => {
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  const [checkedItems, setCheckedItems] = useState(checked || []);
  const [expandedItems, setExpandedItems] = useState(expanded || []);

  const renderTree = (props) => {
    const change = (value) => {
      let values = [...value];
      if (numeric) {
        values = value.map(v => +v);
      }
      setCheckedItems(values);
      if (onChange) {
        return onChange(values);
      }
      return props.onChange(values);
    };

    if (name === 'partnerType') {
      nodes.forEach((node, i) => {
        if (node.name === 'partner_sectors.other') {
          nodes.push(nodes.splice(i, 1)[0]);
        }
      });
    }

    return (
      <CheckboxTree
        { ...props }
        nodes={ nodes }
        checked={ checkedItems }
        expanded={ expandedItems }
        disabled={ disabled }
        onCheck={ change }
        onExpand={ (value) => setExpandedItems(value) }
      />
    );
  };

  return (
    <div className={ `eop-selectbox ${ className }` }>
      <label className='eop-selectbox__label'>
        { (required) && <span className='required-icon' /> }
        { label && fieldLabel }
        { tooltip && (
          <>
            <span className='icon-medium-Info font-size-icon' data-tip data-for={ `${ name }-tooltip` } />
            <ReactTooltip
              id={ `${ name }-tooltip` }
              place='top'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              { fieldTooltip }
            </ReactTooltip>
          </>
        )}
      </label>
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        rules={ { required: requiredError } }
        render={ ({ field }) => renderTree(field) }
      />
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

InputSelect.propTypes = {
  intl: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  nodes: PropTypes.array.isRequired,
  checked: PropTypes.array,
  expanded: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  numeric: PropTypes.bool,
};

InputSelect.defaultProps = {
  translate: true,
  disabled: false,
  required: false,
  className: '',
  checked: [],
  expanded: [],
};

export default injectIntl(InputSelect);
