const scope = 'MARKETPLACE_ADMIN';

const GET_BUSINESSNEED_TABLE_DATA_START = `${ scope }/GET_BUSINESSNEED_TABLE_DATA_START`;
const GET_BUSINESSNEED_TABLE_DATA_SUCCESS = `${ scope }/GET_BUSINESSNEED_TABLE_DATA_SUCCESS`;
const GET_BUSINESSNEED_TABLE_DATA_ERROR = `${ scope }/GET_BUSINESSNEED_TABLE_DATA_ERROR`;
const GET_ADMIN_BUSINESSNEED_START = `${ scope }/GET_ADMIN_BUSINESSNEED_START`;
const GET_ADMIN_BUSINESSNEED_SUCCESS = `${ scope }/GET_ADMIN_BUSINESSNEED_SUCCESS`;
const GET_ADMIN_BUSINESSNEED_ERROR = `${ scope }/GET_ADMIN_BUSINESSNEED_ERROR`;

function getBusinessNeedTableDataRequest(filters) {
  return {
    type: GET_BUSINESSNEED_TABLE_DATA_START,
    options: filters,
  };
}

function getBusinessNeedTableDataSuccess(data) {
  return {
    type: GET_BUSINESSNEED_TABLE_DATA_SUCCESS,
    data,
  };
}

function getBusinessNeedTableDataFail(error) {
  return {
    type: GET_BUSINESSNEED_TABLE_DATA_ERROR,
    payload: error,
  };
}

function getAdminBusinessNeedRequest(id) {
  return {
    type: GET_ADMIN_BUSINESSNEED_START,
    id,
  };
}

function getAdminBusinessNeedSuccess(data) {
  return {
    type: GET_ADMIN_BUSINESSNEED_SUCCESS,
    data,
  };
}

function getAdminBusinessNeedFail(error) {
  return {
    type: GET_ADMIN_BUSINESSNEED_ERROR,
    payload: error,
  };
}

export {
  GET_BUSINESSNEED_TABLE_DATA_START,
  GET_BUSINESSNEED_TABLE_DATA_SUCCESS,
  GET_BUSINESSNEED_TABLE_DATA_ERROR,
  GET_ADMIN_BUSINESSNEED_START,
  GET_ADMIN_BUSINESSNEED_SUCCESS,
  GET_ADMIN_BUSINESSNEED_ERROR,
  getBusinessNeedTableDataRequest,
  getBusinessNeedTableDataSuccess,
  getBusinessNeedTableDataFail,
  getAdminBusinessNeedRequest,
  getAdminBusinessNeedSuccess,
  getAdminBusinessNeedFail,
};
