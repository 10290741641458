export const ADMIN_CHANGE_LIBRARY_STATUS_START = 'ADMIN_CHANGE_LIBRARY_STATUS_START';
export const ADMIN_CHANGE_LIBRARY_STATUS_ERROR = 'ADMIN_CHANGE_LIBRARY_STATUS_ERROR';
export const ADMIN_CHANGE_LIBRARY_STATUS_SUCCESS = 'ADMIN_CHANGE_LIBRARY_STATUS_SUCCESS';

export function adminChangeLibraryStatus(slug, status) {
  return {
    type: ADMIN_CHANGE_LIBRARY_STATUS_START,
    slug,
    status,
  };
}
