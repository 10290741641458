import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Loader } from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components/index';
import ControlPanel from './ControlPanel';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const PdfViewer = ({
  file,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoading(false);
    setTotalPages(numPages);
  };

  const onDocumentLoadFail = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <>
      { !loading && !error && (
      <ControlPanel
        numPages={ totalPages }
        pageNumber={ pageNumber }
        setPageNumber={ setPageNumber }
        scale={ scale }
        setScale={ setScale }
      />
      )}
      <div
        className='documentviewer-modal__wrapper'
      >
        { loading && <Loader /> }
        <div>
          <Document
            file={ file }
            onLoadSuccess={ onDocumentLoadSuccess }
            onLoadError={ onDocumentLoadFail }
            options={ options }
            loading=''
          >
            <Page
              pageNumber={ pageNumber }
              scale={ scale }
              renderTextLayer={ false }
            />
          </Document>
        </div>
      </div>
    </>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.object.isRequired,
};

export default PdfViewer;
