import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ScrollToTopOnMount from '@src/js/components/global/ScrollToTopOnMount';

class ResetPasswordNotificationOverlay extends Component {
  static propTypes = {
    redirectTo: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  /**
   * Closes notification and redirect to login page.
   *
   * @returns {Redirect}
   */
  closeNotificationHandler = () => {
    this.setState({
      redirect: true,
    });
  };

  /**
   * @override
   *
   * @returns {JSX}
   */
  render() {
    const { redirect } = this.state;
    const { redirectTo } = this.props;

    if (redirect) {
      return (
        <ScrollToTopOnMount>
          <Redirect to={ redirectTo } />
        </ScrollToTopOnMount>
      );
    }

    return (
      <div className='reset-password-notification'>
        <div className='reset-password-notification-container'>
          <div className='reset-password-notification-content'>
            <div className='reset-password-notification-close'>
              <span
                onClick={ this.closeNotificationHandler }
                onKeyUp={ this.closeNotificationHandler }
                className='close'
                role='presentation'
              />
            </div>
            <div className='reset-password-notification-header'>
              <div className='reset-password-notification-title'>
                <FormattedMessage id='resetPasswordNotification.title' />
              </div>
            </div>
            <div className='reset-password-notification-body'>
              <div className='notification-icon'>
                <div className='inner-circle'>
                  <div className='message icon-medium-Messages'>
                    <div className='check-mark-container'>
                      <span className='icon-small-Check' />
                    </div>
                  </div>
                </div>
              </div>

              <h2>
                <FormattedMessage id='resetPasswordNotification.messageTitle' />
              </h2>
            </div>
            <div className='reset-password-notification-footer'>
              <button type='button' onClick={ this.closeNotificationHandler } className='eop-btn w-75'>
                <FormattedMessage id='dialog.close' />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordNotificationOverlay;
