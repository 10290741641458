import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getRelevantOpportunities(options) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/business-need/${ options.id }/products`;
  if (options.filters) {
    const query = new URLSearchParams({ ...options.filters, infinite: options?.filters?.limit === 3 });
    url = `${ url }?${ query }`;
  }

  return fetchResource(
    url,
    {
      method: 'GET',
      externalUrl: true,
    });
}

export function getRestrictedOpportunities() {
  const url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/restricted-products`;

  return fetchResource(
    url,
    {
      method: 'GET',
      externalUrl: true,
    });
}
