import { takeLatest, call, put } from 'redux-saga/effects';

import {
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_SUCCESS,
} from '@src/js/actions/image/uploadImage';

import * as api from '@src/js/api/image/uploadImage';

function* uploadImage(options) {
  try {
    const data = yield call(() => api.uploadImage(
      options.image.image, options.entity, options.imageType, options.value
    ));
    yield put({ type: UPLOAD_IMAGE_SUCCESS, data, options });
    options.image.resolve(data);
  } catch (error) {
    yield put({ type: UPLOAD_IMAGE_ERROR, error });
    options.image.reject(error.response);
  }
}

function* uploadImageWatcher() {
  yield takeLatest(UPLOAD_IMAGE_START, uploadImage);
}

export default [
  uploadImageWatcher(),
];
