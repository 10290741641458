import fetchResource from '@src/js/api/fetch-resource';

export function getCountries() {
  return fetchResource('api/taxonomy/countries');
}

export function getRegions(country) {
  return fetchResource(`api/taxonomy/countries/regions/${ country }`);
}

export function getProvinces(country) {
  return fetchResource(`api/taxonomy/countries/provinces/${ country }`);
}
