import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import * as iconsData from '@src/_v2/js/components/Icons/Icon/Icon';
import {
  ButtonSmall,
  ButtonMedium,
  ButtonLarge,
  DownArrow,
  Icon,
  ButtonText,
} from './style';

const getButtonSize = (size) => {
  switch (size) {
    case 'lg':
      return ButtonLarge;
    case 'md':
      return ButtonMedium;
    case 'sm':
      return ButtonSmall;
    default:
      return ButtonMedium;
  }
};

const renderText = (translate, text) => (translate
  ? <FormattedMessage id={ text } />
  : text);

const Button = (props) => {
  const {
    buttonType,
    icon,
    onClick,
    disabled,
    blank,
    color,
    className,
    downArrow,
    iconPosition,
    rounded,
    size,
    translate,
    text,
  } = props;

  const ButtonSize = getButtonSize(size);

  return (
    <ButtonSize
      className={ className }
      rounded={ rounded }
      size={ size }
      blank={ blank }
      color={ color }
      type={ buttonType }
      onClick={ onClick }
      disabled={ disabled && 'disabled' }
    >
      <ButtonText>
        { iconPosition === 'left' && icon && <Icon className={ iconsData.icons[icon] } /> }
        { renderText(translate, text) }
        { downArrow && <DownArrow /> }
      </ButtonText>
    </ButtonSize>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonType: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  color: PropTypes.oneOf(['white', 'grey']),
  rounded: PropTypes.bool,
  onClick: PropTypes.func,
  downArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  blank: PropTypes.bool,
  icon: PropTypes.oneOf(iconsData.iconKeys),
  translate: PropTypes.bool,
};

Button.defaultProps = {
  buttonType: 'button',
  size: 'md',
  iconPosition: 'left',
  translate: true,
};

export default Button;
