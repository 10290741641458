import fetchResource from '@src/js/api/fetch-resource';

const getFormattedFilters = (filters) => {
  const newFilters = { ...filters };

  if (filters.companies) {
    newFilters.companies = filters.companies.map(company => company.value).join();
  }

  return newFilters;
};

export function getBulkMessageUsers(filters) {
  const newFilters = getFormattedFilters(filters);
  let url = 'api/admin/bulk-message/users';

  if (newFilters) {
    const query = new URLSearchParams(newFilters);

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}

export function getBulkMessageUserIds(filters) {
  const newFilters = getFormattedFilters(filters.filters);
  let url = 'api/admin/bulk-message/users/ids';

  if (newFilters) {
    const query = new URLSearchParams(newFilters);
    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}

export function uploadBulkFile(file) {
  return fetchResource('api/admin/bulk-message/parse-csv', {
    method: 'POST',
    body: file,
    noContentType: true,
  });
}
