import {
  GET_SECTORS_START,
  GET_SECTORS_ERROR,
  GET_SECTORS_SUCCESS,
  GET_SECOND_SECTORS_START,
  GET_SECOND_SECTORS_ERROR,
  GET_SECOND_SECTORS_SUCCESS,
} from '@src/js/actions/taxonomy/sectors';

const initialState = {
  loading: false,
  error: null,
  sectors: null,
  secondLevelSectors: null,
};

const actionsMap = {
  [GET_SECTORS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    sectors: null,
  }),
  [GET_SECTORS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_SECTORS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    sectors: action.data,
  }),
  [GET_SECOND_SECTORS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    secondLevelSectors: null,
  }),
  [GET_SECOND_SECTORS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [GET_SECOND_SECTORS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    secondLevelSectors: action.results,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
