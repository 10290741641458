import { uploadImage } from '@src/js/actions/image/uploadImage';
import Button from '@src/js/components/global/buttons/Buttons';
import SelectBox from '@src/js/components/global/inputs/Selectbox';
import TextInput from '@src/js/components/global/inputs/TextInput';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { CompanySlide, fileConstants } from '@src/js/constants/entities';
import { Images } from '@src/js/constants/images';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import inputValidation from '@src/js/helpers/InputValidation';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';

class CompanyFormSlides extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    uploadedImage: PropTypes.object,
    formMutators: PropTypes.object,
    entity: PropTypes.string,
    languageCode: PropTypes.string,
  };

  static defaultProps = {
    entity: 'company',
  };

  componentDidUpdate(prevProps) {
    const {
      entity,
      uploadedImage,
      formMutators,
    } = this.props;

    if (prevProps.uploadedImage.image !== uploadedImage.image) {
      if (
        uploadedImage.options.entity === this.getSlideTypeByEntity(entity)
        && uploadedImage.image && uploadedImage.image.id
      ) {
        const slideIndex = uploadedImage.options.value;
        const languageCode = uploadedImage.options.languageCode;

        formMutators.setSlide(languageCode, slideIndex, 'content', uploadedImage.image.id.toString());
        formMutators.setSlide(languageCode, slideIndex, 'uploading', undefined);
      }
    }
  }

  handleSlideImageRemove = (index, fields) => {
    if (fields) {
      fields.remove(index);
    }
  };

  getSlideTypeByEntity = (entity) => {
    switch (entity) {
      case 'company':
        return 'cslide';
      case 'partner':
        return 'pslide';
      default:
        return null;
    }
  };

  handleSlideDropRejected = (languageCode, droppedFiles, slideIndex) => {
    const {
      formMutators,
    } = this.props;

    let error = 'dataRoom.imageMimeTypeMessage';

    if (droppedFiles.length > 1) {
      error = 'dataRoom.numberOfFiles';
    }

    if (droppedFiles[0].size > fileConstants.UPLOAD_SIZE_LIMIT) {
      error = 'dataRoom.maxSizeMessage';
    }

    formMutators.setSlide(languageCode, slideIndex, 'error', error);
  };

  handleSlideDrop = (languageCode, acceptedFiles, slideIndex) => {
    const {
      entity,
      dispatch,
      formMutators,
    } = this.props;

    if (acceptedFiles.length === 0) {
      return null;
    }

    formMutators.setSlide(languageCode, slideIndex, 'imageUrl', URL.createObjectURL(acceptedFiles[0]));
    formMutators.setSlide(languageCode, slideIndex, 'uploading', true);
    formMutators.setSlide(languageCode, slideIndex, 'error', null);

    const image = new FormData();
    image.append('image', acceptedFiles[0]);

    return new Promise((resolve, reject) => {
      dispatch(uploadImage(
        { image, resolve, reject },
        this.getSlideTypeByEntity(entity),
        'image',
        slideIndex,
        languageCode
      ));
    }).catch((errors) => {
      formMutators.setSlide(languageCode, slideIndex, 'uploading', false);
      formMutators.setSlide(languageCode, slideIndex, 'error', errors.cslideImage);
      return errors;
    });
  };

  handleChangeType = (slideIndex, option, languageCode) => {
    const {
      formMutators,
    } = this.props;

    formMutators.setSlide(languageCode, slideIndex, 'content', undefined);
    formMutators.setSlide(languageCode, slideIndex, 'imageUrl', undefined);
    formMutators.setSlide(languageCode, slideIndex, 'type', option);
  };

  render() {
    const {
      languageCode,
      formMutators,
    } = this.props;

    const composeValidators = (...validators) => value => validators.reduce(
      (error, validator) => error || validator(value), undefined
    );

    const mediaTypes = [
      { value: 1, label: <FormattedMessage id='company_form.image' /> },
      { value: 2, label: 'Wistia' },
    ];

    return (
      <div>
        <div className='row'>
          <div className='col-xs p-b-3'>
            <Button
              buttonType='button'
              buttonText='company_form.addMedia'
              onClickFunc={ () => formMutators.insert(`slides.${ languageCode }`, undefined) }
              additionalClass='m-r-2'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs'>
            <FieldArray name={ `slides.${ languageCode }` }>
              { ({ fields }) => fields.map((name, index) => {
                const thisMedia = fields.value[index];
                const isUploading = (thisMedia && thisMedia.uploading);
                let selectedType = null;

                if (thisMedia && thisMedia.type && thisMedia.type.value) {
                  selectedType = thisMedia.type.value;
                }

                if (selectedType === null && thisMedia && thisMedia.type) {
                  selectedType = thisMedia.type;
                }

                return (
                  <div key={ name }>
                    <div
                      className='edit-company-page__subsection edit-company-page__subsection-media'
                    >
                      <FormattedMessage id='company_form.media' />
                      <div
                        className='edit-company-page__subsection-media--remove'
                        role='presentation'
                        onClick={ () => this.handleSlideImageRemove(index, fields) }
                      >
                        <span>
                          <FormattedMessage id='company_form.removeMedia' />
                        </span>
                        <span className='icon icon-CloseSmall' />
                      </div>
                    </div>
                    <div className='edit-company-page__subsection-media__content'>
                      <div className='edit-company-page__subsection-media__content__buttons'>
                        { index !== 0 && (
                        <span
                          className='icon-ArrowUpSmall'
                          onClick={ () => fields.swap(index, index - 1) }
                          role='presentation'
                        />
                        )}
                        { index !== fields.length - 1 && index >= 0 && (
                        <span
                          className='icon-ArrowDownSmall'
                          onClick={ () => fields.swap(index, index + 1) }
                          role='presentation'
                        />
                        )}
                      </div>
                      <div className='edit-company-page__subsection-media__content__media'>
                        <div className='row'>
                          <div className='col-xs-12 col-lg-6'>
                            <Field
                              name={ `${ name }.type` }
                              fieldName='slides.type'
                              fieldType='text'
                              fieldLabel='company_form.mediaType'
                              fieldDisabled={ isUploading }
                              fieldRequired
                              fieldNoClear
                              options={ mediaTypes }
                              onChange={ (option) => this.handleChangeType(index, option, languageCode) }
                              validate={ inputValidation.requiredValidator }
                              component={ SelectBox }
                            />
                          </div>
                        </div>
                        { selectedType === CompanySlide.TYPE_WISTIA
                        && (
                          <Field
                            name={ `${ name }.content` }
                            fieldName='slides.content'
                            fieldType='text'
                            fieldLabel='company_form.videoUrl'
                            fieldRequired
                            fieldMaxLength={ 200 }
                            validate={ composeValidators(
                              inputValidation.urlValidator,
                              inputValidation.requiredValidator
                            ) }
                            component={ TextInput }
                          />
                        ) }
                        { selectedType === CompanySlide.TYPE_IMAGE
                        && (
                          <div className='card m-b-2'>
                            { thisMedia.error && (
                              <div className='row'>
                                <div className='col-lg-12 m-t-1'>
                                  <Alert color='red'>
                                    <FormattedMessage id={ thisMedia.error } />
                                  </Alert>
                                </div>
                              </div>
                            ) }
                            <div className='col-lg-4 upload-card__container upload-card__container-fixed'>
                              { isUploading && <Loader /> }
                              <Dropzone
                                accept={ dropzoneExtensions.IMAGES }
                                maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
                                onDrop={ files => this.handleSlideDrop(languageCode, files, index) }
                                onDropRejected={ files => this.handleSlideDropRejected(languageCode, files, index) }
                                multiple={ false }
                                useFsAccessApi={ false }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div { ...getRootProps({ style: { height: '100%' } }) }>
                                    <input { ...getInputProps() } />
                                    {thisMedia.imageUrl ? (
                                      <div className='upload-card__content'>
                                        <img
                                          src={ thisMedia.imageUrl }
                                          alt=''
                                          onLoad={ URL.revokeObjectURL(thisMedia) }
                                        />
                                      </div>
                                    ) : (
                                      <div className='upload-card__placeholder'>
                                        <img
                                          src={ Images.Upload }
                                          alt=''
                                        />
                                        <p>
                                          <FormattedMessage id='upload_drag_drop' />
                                          <span className='upload-card__placeholder-content'>
                                            <FormattedMessage id='upload_browse' />
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                            <Field
                              name={ `${ name }.content` }
                              fieldName='slides.content'
                              fieldType='text'
                              fieldLabel=''
                              component={ TextInput }
                              validate={ inputValidation.requiredValidator }
                              validationMessageOnly
                            />
                          </div>
                        ) }
                      </div>
                    </div>
                  </div>
                );
              }) }
            </FieldArray>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadedImage: state.uploadImage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormSlides);

export default withRouter(connectWrapper);
