import {
  RM_CREATE_COMPANY_START,
  RM_CREATE_COMPANY_ERROR,
  RM_CREATE_COMPANY_SUCCESS,
} from '@src/js/actions/company/relationshipManagerCompany';

const initialState = {
  loading: false,
  error: null,
  company: null,
};

const actionsMap = {
  [RM_CREATE_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [RM_CREATE_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response.errors,
  }),
  [RM_CREATE_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    company: action.data.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
