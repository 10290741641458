export const GET_PARTNER_FORM_OPTIONS_START = 'GET_PARTNER_FORM_OPTIONS_START';
export const GET_PARTNER_FORM_OPTIONS_SUCCESS = 'GET_PARTNER_FORM_OPTIONS_SUCCESS';
export const GET_PARTNER_FORM_OPTIONS_ERROR = 'GET_PARTNER_FORM_OPTIONS_ERROR';
export const CLEAR_PARTNER_FORM_OPTIONS = 'CLEAR_PARTNER_FORM_OPTIONS';

export function getPartnerFormOptions(slug, userType) {
  return {
    type: GET_PARTNER_FORM_OPTIONS_START,
    slug,
    userType,
  };
}

export function clearPartnerFormOptions() {
  return {
    type: CLEAR_PARTNER_FORM_OPTIONS,
  };
}
