export const GET_PARTNERS_START = 'GET_PARTNERS_START';
export const GET_PARTNERS_ERROR = 'GET_PARTNERS_ERROR';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNER_START = 'GET_PARTNER_START';
export const GET_PARTNER_ERROR = 'GET_PARTNER_ERROR';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const CREATE_PARTNER_START = 'CREATE_PARTNER_START';
export const CREATE_PARTNER_ERROR = 'CREATE_PARTNER_ERROR';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const EDIT_PARTNER_START = 'EDIT_PARTNER_START';
export const EDIT_PARTNER_ERROR = 'EDIT_PARTNER_ERROR';
export const EDIT_PARTNER_SUCCESS = 'EDIT_PARTNER_SUCCESS';
export const DISABLE_PARTNER_START = 'DISABLE_PARTNER_START';
export const DISABLE_PARTNER_ERROR = 'DISABLE_PARTNER_ERROR';
export const DISABLE_PARTNER_SUCCESS = 'DISABLE_PARTNER_SUCCESS';
export const GET_PARTNER_PUBLIC_PROFILE_START = 'GET_PARTNER_PUBLIC_PROFILE_START';
export const GET_PARTNER_PUBLIC_PROFILE_ERROR = 'GET_PARTNER_PUBLIC_PROFILE_ERROR';
export const GET_PARTNER_PUBLIC_PROFILE_SUCCESS = 'GET_PARTNER_PUBLIC_PROFILE_SUCCESS';
export const CLEAR_PARTNER_PUBLIC_PROFILE = 'CLEAR_PARTNER_PUBLIC_PROFILE';

export function getPartnerPublicProfile(slug) {
  return {
    type: GET_PARTNER_PUBLIC_PROFILE_START,
    slug,
  };
}

export function getPartners(filters) {
  return {
    type: GET_PARTNERS_START,
    filters,
  };
}

export function getPartner(id) {
  return {
    type: GET_PARTNER_START,
    id,
  };
}

export function editPartner(partner, userType) {
  return {
    type: EDIT_PARTNER_START,
    partner,
    userType,
  };
}

export function createPartner(partner, userType) {
  return {
    type: CREATE_PARTNER_START,
    partner,
    userType,
  };
}

export function disablePartner(slug, filters) {
  return {
    type: DISABLE_PARTNER_START,
    slug,
    filters,
  };
}

export function clearPartnerPublicProfile() {
  return {
    type: CLEAR_PARTNER_PUBLIC_PROFILE,
  };
}
