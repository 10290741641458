export const GET_COUNTRIES_START = 'GET_COUNTRIES_START';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_REGIONS_START = 'GET_REGIONS_START';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_PROVINCES_START = 'GET_PROVINCES_START';
export const GET_PROVINCES_ERROR = 'GET_PROVINCES_ERROR';
export const GET_PROVINCES_SUCCESS = 'GET_PROVINCES_SUCCESS';

export function getCountries() {
  return {
    type: GET_COUNTRIES_START,
  };
}

export function getRegions(country = 'it') {
  return {
    type: GET_REGIONS_START,
    country,
  };
}

export function getProvinces(country = 'it') {
  return {
    type: GET_PROVINCES_START,
    country,
  };
}
