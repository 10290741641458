import { SET_OPPORTUNITY_REQUESTED } from '@app/store/content/promotedContent/actions';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  takeLatest, put, call, all,
} from 'redux-saga/effects';
import {
  SEND_REQUEST_START,
  SEND_REQUEST_ERROR,
  SEND_REQUEST_SUCCESS,
} from '@src/js/marketplace/actions/modal/requestInfoModal';
import * as api from '@src/js/marketplace/api/modal/requestInfoModal';

function* sendRequestInfo(options) {
  try {
    const data = yield call(() => api.marketplaceProductSendMessage(options.data.requestInfoData));
    const opportunity = { ...options.data.opportunity, Requested: true };
    yield all([
      put({ type: SEND_REQUEST_SUCCESS, data }),
      put({ type: 'UPDATE_OPPORTUNITY', opportunity }),
      put(showToastrSuccess('marketplace.modal.success', 'company_form.modal.notification.messageSuccess')),
      put({ type: SET_OPPORTUNITY_REQUESTED }),
    ]);
  } catch (error) {
    yield all([
      put({ type: SEND_REQUEST_ERROR, error }),
      put(showToastrError('marketplace.modal.error', 'company_form.modal.notification.messageError')),
    ]);
  }
}

function* sendRequestInfoWatcher() {
  yield takeLatest(SEND_REQUEST_START, sendRequestInfo);
}

export default [
  sendRequestInfoWatcher(),
];
