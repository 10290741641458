import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_RELEVANT_OPPORTUNITIES_START,
  GET_RELEVANT_OPPORTUNITIES_ERROR,
  GET_RELEVANT_OPPORTUNITIES_SUCCESS,
  GET_DASHBOARD_OPPORTUNITIES_START,
  GET_DASHBOARD_OPPORTUNITIES_SUCCESS,
  GET_DASHBOARD_OPPORTUNITIES_ERROR,
  GET_RESTRICTED_OPPORTUNITIES_START,
  GET_RESTRICTED_OPPORTUNITIES_ERROR,
  GET_RESTRICTED_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getRelevantOpportunities';

import * as api from '@src/js/marketplace/api/getRelevantOpportunities';

function* getRelevantOpportunities(options) {
  try {
    const data = yield call(() => api.getRelevantOpportunities(options));
    yield put({ type: GET_RELEVANT_OPPORTUNITIES_SUCCESS, data, businessNeedId: options.id });
  } catch (error) {
    yield put({ type: GET_RELEVANT_OPPORTUNITIES_ERROR, error });
  }
}

function* getDashboardOpportunities(options) {
  try {
    const data = yield call(() => api.getRelevantOpportunities(options));
    yield put({ type: GET_DASHBOARD_OPPORTUNITIES_SUCCESS, data, businessNeedId: options.id });
  } catch (error) {
    yield put({ type: GET_DASHBOARD_OPPORTUNITIES_ERROR, error });
  }
}

function* getRestrictedOpportunities() {
  try {
    const data = yield call(() => api.getRestrictedOpportunities());
    yield put({ type: GET_RESTRICTED_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_RESTRICTED_OPPORTUNITIES_ERROR, error });
  }
}

function* getRelevantOpportunitiesWatcher() {
  yield takeEvery(GET_RELEVANT_OPPORTUNITIES_START, getRelevantOpportunities);
}

function* getDashboardOpportunitiesWatcher() {
  yield takeEvery(GET_DASHBOARD_OPPORTUNITIES_START, getDashboardOpportunities);
}

function* getRestrictedOpportunitiesWatcher() {
  yield takeEvery(GET_RESTRICTED_OPPORTUNITIES_START, getRestrictedOpportunities);
}

export default [
  getRelevantOpportunitiesWatcher(),
  getDashboardOpportunitiesWatcher(),
  getRestrictedOpportunitiesWatcher(),
];
