export const GET_SINGLE_LIBRARY_START = 'GET_SINGLE_LIBRARY_START';
export const GET_SINGLE_LIBRARY_ERROR = 'GET_SINGLE_LIBRARY_ERROR';
export const GET_SINGLE_LIBRARY_SUCCESS = 'GET_SINGLE_LIBRARY_SUCCESS';
export const CLEAR_SINGLE_LIBRARY = 'CLEAR_SINGLE_LIBRARY';

export function getSingleLibrary(slug) {
  return {
    type: GET_SINGLE_LIBRARY_START,
    slug,
  };
}

export function clearSingleLibrary() {
  return {
    type: CLEAR_SINGLE_LIBRARY,
  };
}
