import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getMatchmakingMembers(id, page, limit) {
  const url = `/user/business-need/${ id }/companies?limit=${ limit }&page=${ page }&infinite=${ limit === 3 }`;
  return fetchResource(
    `${ loadEnvVariable('MARKETPLACE_API_PATH') }${ url }`,
    {
      method: 'GET',
      externalUrl: true,
    });
}
