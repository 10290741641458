import { takeLatest, put, call } from 'redux-saga/effects';
import { getRelevantOpportunities } from '@src/js/marketplace/api/getRelevantOpportunities';
import { getFeaturedOpportunities } from '@src/js/marketplace/api/getFeaturedOpportunities';
import { getMostViewedOpportunities } from '@src/js/marketplace/api/getMostViewedOpportunities';
import { getLatestOpportunities } from '@src/js/marketplace/api/getLatestOpportunities';
import { getOpportunities } from '@src/js/marketplace/api/getOpportunityData';
import {
  VIEW_ALL_OPPORTUNITIES_START,
  VIEW_ALL_OPPORTUNITIES_ERROR,
  VIEW_ALL_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/viewAllOpportunities';

function* viewAllOpportunities(options) {
  try {
    let data;

    switch (options.opportunityType) {
      case 'relevant':
        data = yield call(() => getRelevantOpportunities(options));
        break;
      case 'featured':
        data = yield call(() => getFeaturedOpportunities(options));
        break;
      case 'mostViewed':
        data = yield call(() => getMostViewedOpportunities(options));
        break;
      case 'latest':
        data = yield call(() => getLatestOpportunities(options));
        break;
      default:
        data = yield call(() => getOpportunities(options));
        break;
    }
    yield put({ type: VIEW_ALL_OPPORTUNITIES_SUCCESS, data, page: options.filters.page });
  } catch (error) {
    yield put({ type: VIEW_ALL_OPPORTUNITIES_ERROR, error });
  }
}

function* viewAllOpportunitiesWatcher() {
  yield takeLatest(VIEW_ALL_OPPORTUNITIES_START, viewAllOpportunities);
}

export default [
  viewAllOpportunitiesWatcher(),
];
