export const EDIT_LIBRARY_START = 'EDIT_LIBRARY_START';
export const EDIT_LIBRARY_ERROR = 'EDIT_LIBRARY_ERROR';
export const EDIT_LIBRARY_SUCCESS = 'EDIT_LIBRARY_SUCCESS';

export function editLibrary(data) {
  return {
    type: EDIT_LIBRARY_START,
    data,
  };
}
