export const PARTNER_CREATE_LIBRARY_START = 'PARTNER_CREATE_LIBRARY_START';
export const PARTNER_CREATE_LIBRARY_ERROR = 'PARTNER_CREATE_LIBRARY_ERROR';
export const PARTNER_CREATE_LIBRARY_SUCCESS = 'PARTNER_CREATE_LIBRARY_SUCCESS';

export function partnerCreateLibrary(data) {
  return {
    type: PARTNER_CREATE_LIBRARY_START,
    data,
  };
}
