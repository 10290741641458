export const LOGIN_ONBOARDING_USER_START = 'LOGIN_ONBOARDING_USER_START';
export const LOGIN_ONBOARDING_USER_ERROR = 'LOGIN_ONBOARDING_USER_ERROR';
export const LOGIN_ONBOARDING_USER_SUCCESS = 'LOGIN_ONBOARDING_USER_SUCCESS';

export const LOGOUT_ONBOARDING_USER_START = 'LOGOUT_ONBOARDING_USER_START';
export const LOGOUT_ONBOARDING_USER_ERROR = 'LOGOUT_ONBOARDING_USER_ERROR';
export const LOGOUT_ONBOARDING_USER_SUCCESS = 'LOGOUT_ONBOARDING_USER_SUCCESS';

export const ONBOARDING_INIT_START = 'ONBOARDING_INIT_START';
export const ONBOARDING_INIT_ERROR = 'ONBOARDING_INIT_ERROR';
export const ONBOARDING_INIT_SUCCESS = 'ONBOARDING_INIT_SUCCESS';

export function onboardingInit() {
  return {
    type: ONBOARDING_INIT_START,
  };
}

export function loginOnboardingUser(credentialsData) {
  return {
    type: LOGIN_ONBOARDING_USER_START,
    credentialsData,
  };
}

export function logoutOnboardingUser() {
  return {
    type: LOGOUT_ONBOARDING_USER_START,
  };
}
