import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_DOCUMENTS_GET_FILES_START,
  PARTNER_DOCUMENTS_GET_FILES_ERROR,
  PARTNER_DOCUMENTS_GET_FILES_SUCCESS,
} from '@src/js/actions/partner/partnerDocumentsFiles';

import * as api from '@src/js/api/partner/partnerDocumentsFiles';

function* getPartnerDocumentsFiles(options) {
  try {
    const data = yield call(() => api.partnerDocumentsGetFiles(options.slug, options.offset));
    yield put({ type: PARTNER_DOCUMENTS_GET_FILES_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: PARTNER_DOCUMENTS_GET_FILES_ERROR, error });
  }
}

function* getPartnerDocumentsFilesWatcher() {
  yield takeLatest(PARTNER_DOCUMENTS_GET_FILES_START, getPartnerDocumentsFiles);
}

export default [
  getPartnerDocumentsFilesWatcher(),
];
