import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontSize, FontWeight, ThemeColors } from '@src/_v2/js/constants';

export const TooltipContainer = styled.div`
  font-size: ${ FontSize.small };
  color: ${ ThemeColors.white };
  font-weight: ${ FontWeight.bold };
  text-transform: initial;
  letter-spacing: 0.04rem;
  margin-top: 0.3rem;
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
  &.type-dark.place-top {
    background-color: ${ ThemeColors.blue };
    padding: 0.3rem 1rem;

    &::after {
      border-top-color: ${ ThemeColors.blue };
    }
  }
`;
