import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

export default class MetaTitle extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    adminPrefix: PropTypes.bool,
    translate: PropTypes.bool,
  };

  static defaultProps = {
    translate: true,
  };

  render() {
    const {
      title,
      adminPrefix,
      translate,
    } = this.props;

    const adminPrefixText = adminPrefix ? 'ADMIN ' : '';
    const adminSuffix = ' | ELITE';

    return (
      translate
        ? (
          <FormattedMessage id={ title }>
            {translatedTitle => (
              <Helmet defaultTitle='ELITE Platform'>
                <title>{ adminPrefixText + translatedTitle + adminSuffix }</title>
              </Helmet>
            )}
          </FormattedMessage>
        )
        : (
          <Helmet defaultTitle='ELITE Platform'>
            <title>{ adminPrefixText + title + adminSuffix }</title>
          </Helmet>
        )
    );
  }
}
