import fetchResource from '@src/js/api/fetch-resource';

export function getDirectoryRelevant(section, boxNumber) {
  return fetchResource(`api/network/companies/${ section }/relevant${ boxNumber ? `/${ boxNumber }` : '' }`, {
    method: 'GET',
  });
}

export function getDirectoryViewAll(section, boxNumber, page, filter) {
  const params = new URLSearchParams({ page, ...filter });
  return fetchResource(`api/network/companies/${ section }${ boxNumber ? `/${ boxNumber }` : '' }?${ params }`, {
    method: 'GET',
  });
}
