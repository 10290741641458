import produce from 'immer';

import {
  GET_DIGITAL_SPECIALIST_START,
  GET_DIGITAL_SPECIALIST_SUCCESS,
  GET_DIGITAL_SPECIALIST_FAIL,
  CROSS_SELLING_SUCCESS,
  CROSS_SELLING_START,
  CROSS_SELLING_FAIL,
} from '@app/store/global/actions';

export const initialState = {
  digitalSpecialist: null,
  crossSellingData: null,
};

function globalReducer(state = initialState, action) {
  return produce(state, (d) => {
    const draft = d;
    switch (action.type) {
      case GET_DIGITAL_SPECIALIST_START:
      case GET_DIGITAL_SPECIALIST_FAIL:
        draft.digitalSpecialist = null;
        break;
      case GET_DIGITAL_SPECIALIST_SUCCESS:
        draft.digitalSpecialist = action.data;
        break;
      case CROSS_SELLING_START:
      case CROSS_SELLING_FAIL:
        draft.crossSellingData = null;
        break;
      case CROSS_SELLING_SUCCESS:
        draft.crossSellingData = action.data;
        break;
      default:
        break;
    }
  }
  );
}

export default globalReducer;
