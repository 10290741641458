import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  selectOnboardingData,
  selectOnboardingSave,
  selectCurrentStep,
  selectFirstVisit,
} from '@app/store/onboarding/selectors';
import { FormProvider, useForm } from 'react-hook-form';
import FormSection from '@app/components/global/forms/FormSection';
import InputTextArea from '@app/components/global/forms/InputTextArea';
import InputText from '@app/components/global/forms/InputText';
import { injectIntl } from 'react-intl';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import MultiSelect from '@app/components/global/forms/select/MultiSelect';
import { selectSectors } from '@app/store/global/selectors/taxonomy';
import InputSelect from '@app/components/global/forms/select/InputSelect';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import { InputFileUpload, InputImage } from '@app/components/global/forms';
import Advisors from './Advisors';

const CompanyProfile = ({ handleSave, intl }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const sectors = selectSectors();
  const isAdmin = isAdminRole() || isPra();
  const form = useForm({
    defaultValues: onboardingData?.company,
    mode: 'onChange',
  });
  const {
    getValues, formState: { errors }, register, control, setValue, trigger,
  } = form;

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      setTimeout(trigger, 50);
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      const formData = { ...getValues() };
      if (formData.sectors?.length) {
        formData.secondLevelSector = formData.sectors[1]?.id || formData.sectors[0]?.id || null;
        formData.sector = formData.sectors[3]?.id || formData.sectors[2]?.id || null;
      }
      delete formData.sectors;
      handleSave({ company: formData });
    }
  }, [onSave]);

  const registrationEvidenceOptions = [
    { value: 1, label: intl.formatMessage({ id: 'onboarding.companyProfile.registrationEvidenceCertificate' }) },
    { value: 2, label: intl.formatMessage({ id: 'onboarding.companyProfile.registrationEvidenceCompanyHouse' }) },
  ];

  return (
    <FormProvider { ...form }>
      <form>
        <FormSection sectionTitle='onboarding.companyProfile.title'>
          <EmailSupport />
          <InputImage
            name='logo'
            label='onboarding.companyProfile.companyLogo'
            required
            className='elite-form__full-row'
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/image/onboarding_company/logo` }
          />
        </FormSection>
        <FormSection>
          <div className='elite-form__section__half'>
            <MultiSelect
              name='sectors'
              errors={ errors }
              control={ control }
              labels={ ['onboarding.companyProfile.industry', 'onboarding.companyProfile.sectors'] }
              requiredLevels={ 2 }
              valueOptions={ sectors || [] }
              setValue={ setValue }
              prefix='sectors'
              defaultValue={ getValues('sector') || getValues('secondLevelSector') }
            />
          </div>
          <div className='elite-form__section__half'>
            <InputText
              name='website'
              errors={ errors }
              register={ register }
              label='onboarding.companyProfile.website'
              type='url'
              required
            />
            <InputText
              name='urlTwitter'
              errors={ errors }
              register={ register }
              label='onboarding.companyProfile.twitter'
              type='url'
            />
            <InputText
              name='urlLinkedIn'
              errors={ errors }
              register={ register }
              label='onboarding.companyProfile.linkedin'
              type='url'
            />
          </div>
        </FormSection>

        <FormSection>
          <InputTextArea
            name='companyDetails.description'
            maxLength={ 5000 }
            errors={ errors }
            register={ register }
            label='onboarding.companyDetails.description'
            className='elite-form__full-row compliance__text-area-onboarding'
            required
          />
          <InputTextArea
            name='companyDetails.strategy'
            maxLength={ 5000 }
            errors={ errors }
            register={ register }
            label='onboarding.companyDetails.strategy'
            className='elite-form__full-row compliance__text-area-onboarding'
          />
        </FormSection>
        <Advisors form={ form } />
        <hr className='compliance__divider' />
        <FormSection>
          <InputFileUpload
            name='filePresentation'
            label='onboarding.companyProfile.companyPresentation'
            required
            downloadable
            acceptedTypes={ dropzoneExtensions.DOCUMENTS }
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding_company/presentation` }
          />
          <InputFileUpload
            name='companyOtherDocuments'
            errors={ errors }
            control={ control }
            label='onboarding.companyProfile.otherDocuments'
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding/document` }
            downloadable
            multiple
            acceptedTypes={ dropzoneExtensions.DOCUMENTS }
          />
        </FormSection>
        <FormSection>
          <InputSelect
            name='evidenceType'
            control={ control }
            errors={ errors }
            options={ registrationEvidenceOptions }
            label='onboarding.companyProfile.registrationEvidence'
            required
          />
          <InputFileUpload
            name='fileEvidenceUpload'
            label='onboarding.companyProfile.registrationEvidenceUpload'
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding_company/registration_evidence` }
            required
            downloadable
            acceptedTypes={ dropzoneExtensions.DOCUMENTS }
          />
        </FormSection>
      </form>
    </FormProvider>
  );
};

CompanyProfile.propTypes = {
  handleSave: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(CompanyProfile);
