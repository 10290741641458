import {
  takeLatest, call, put, all, select,
} from 'redux-saga/effects';

import { showToastrSuccess, showToastrError } from '@app/store/global/actions';
import {
  ADMIN_GET_EVENTS_START,
  ADMIN_GET_EVENTS_ERROR,
  ADMIN_GET_EVENTS_SUCCESS,
  ADMIN_GET_EVENTS_FILTER_OPTIONS_START,
  ADMIN_GET_EVENTS_FILTER_OPTIONS_ERROR,
  ADMIN_GET_EVENTS_FILTER_OPTIONS_SUCCESS,
  ADMIN_POST_EVENTS_REMINDER_START,
  ADMIN_POST_EVENTS_REMINDER_ERROR,
  ADMIN_POST_EVENTS_REMINDER_SUCCESS,
  AdminReminderEventAction,
  AdminGetEventFilterOptionsAction,
  AdminGetEventsAction,
} from '@src/js/actions/events/adminEvents';

import * as api from '@src/js/api/events/adminEvents';
import { ADMIN_EVENT_SET_FILTERS } from '@src/js/actions/events/adminEvent';
import { AdminEventFilters } from '@src/js/reducers/events/adminEvent';
import { RootState } from '@src/js/store';

function* getAdminEvents({ filters }: AdminGetEventsAction): Generator {
  try {
    const usedFilters = filters || (yield select((state: RootState) => state.adminEvent.filters)) as AdminEventFilters;

    const data = yield call(() => api.getAdminEvents(usedFilters));
    yield put({ type: ADMIN_GET_EVENTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_EVENTS_ERROR, error });
  }
}

function* getAdminEventsFilterOptions(options: AdminGetEventFilterOptionsAction): Generator {
  try {
    const data = yield call(() => api.getAdminEventsFilterOptions(options.filterOptions?.values));
    yield put({ type: ADMIN_GET_EVENTS_FILTER_OPTIONS_SUCCESS, data });
    options.filterOptions.resolve(data);
  } catch (error: any) {
    yield put({ type: ADMIN_GET_EVENTS_FILTER_OPTIONS_ERROR, error });
    options.filterOptions.reject(error.response);
  }
}

function* reminderEvent({
  slug, registered, templateId, scheduling,
}: AdminReminderEventAction): Generator {
  try {
    const data = yield call(() => api.postReminderEvent(slug, registered, templateId, scheduling));
    yield all([
      put({ type: ADMIN_POST_EVENTS_REMINDER_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.success_message')),
    ]);
  } catch (error: any) {
    yield all([
      put({ type: ADMIN_POST_EVENTS_REMINDER_ERROR, error }),
      put(showToastrError('notification.error_title', error.response?.description || 'notification.error_message')),
    ]);
  }
}

function* adminEventsWatcher() {
  yield takeLatest(ADMIN_GET_EVENTS_START, getAdminEvents);
  yield takeLatest(ADMIN_EVENT_SET_FILTERS, getAdminEvents);
  yield takeLatest(ADMIN_GET_EVENTS_FILTER_OPTIONS_START, getAdminEventsFilterOptions);
  yield takeLatest(ADMIN_POST_EVENTS_REMINDER_START, reminderEvent);
}

export default adminEventsWatcher;
