import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class SelectBoxItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    prefix: PropTypes.string,
    intl: PropTypes.object,
    handleClick: PropTypes.func,
    closeButton: PropTypes.bool,
    deleteFunc: PropTypes.func,
  }

  static defaultProps = {
    closeButton: false,
  }

  translateName = (item, prefix) => {
    const { intl } = this.props;

    return !prefix || prefix.length === 0 ? item.Name
      : intl.formatMessage({ id: prefix + item.Name });
  }

  render() {
    const {
      item, prefix, handleClick, closeButton, deleteFunc,
    } = this.props;

    return closeButton ? (
      <div
        className='select-box-filter-item'
        key={ item.Id }
      >
        <span
          role='button'
          tabIndex='0'
          className='icon-CloseSmall select-box-filter-item-icon'
          onClick={ () => deleteFunc(item.Id) }
          onKeyUp={ () => deleteFunc(item.Id) }
        />
        <span className='select-box-filter-item-label__selected'>
          { this.translateName(item, prefix) }
        </span>
      </div>
    ) : (
      <div
        className='select-box-filter-item'
        key={ item.Id }
        tabIndex='0'
        role='button'
        onClick={ () => handleClick(item.Id) }
        onKeyUp={ () => handleClick(item.Id) }
      >
        <span className='select-box-filter-item-label'>
          { this.translateName(item, prefix) }
        </span>
      </div>
    );
  }
}

export default injectIntl(SelectBoxItem);
