export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

export const CLEAR_FILE_STATE = 'CLEAR_FILE_STATE';

export function uploadFile(file, entity, fileType, value, reminderLang = null) {
  return {
    type: UPLOAD_FILE_START,
    file,
    entity,
    fileType,
    value,
    reminderLang,
  };
}

export function clearFile() {
  return {
    type: CLEAR_FILE_STATE,
  };
}

export function setReminderUploadError(error, reminderLang) {
  return {
    type: UPLOAD_FILE_ERROR,
    error,
    reminderLang,
  };
}
