import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMessagesLoading } from '@app/store/chat/chatSlice';
import EmojiPicker from '@emoji-mart/react';
import { useIntl } from 'react-intl';

const ChatEmoji = (
  { updateButton, editBoxRef }:
  { updateButton: () => void, editBoxRef: React.RefObject<HTMLDivElement> }
) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const messageLoading = useSelector(selectMessagesLoading);
  const intl = useIntl();

  const toggleEmojiPicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const addEmoji = (emoji: string) => {
    if (!editBoxRef?.current) return;
    const executed = document.execCommand('insertHTML', false, emoji);
    if (!executed) {
      editBoxRef.current.innerHTML += emoji;
    }
    updateButton();
  };

  useEffect(() => {
    if (messageLoading) {
      setIsPickerOpen(false);
    }
  }, [messageLoading]);

  return (
    <div className='relative d-flex icon-smile'>
      <span
        className='icon icomoon-smile'
        onClick={ toggleEmojiPicker }
        role='presentation'
        title={ intl.formatMessage({ id: 'chat.icons.emoticons' }) }
      />
      <div
        className={ `emoji-picker-container${ isPickerOpen ? '' : ' d-none' }` }
      >
        <EmojiPicker
          data={ window.EMOJI_DATA }
          onEmojiSelect={ (emojiData: { native: string }) => addEmoji(emojiData.native) }
          theme='light'
        />
      </div>
      <div
        className={ `emoji-picker-overlay${ isPickerOpen ? ' clickable' : '' }` }
        onClick={ () => setIsPickerOpen(false) }
        role='presentation'
      />
    </div>
  );
};
export default ChatEmoji;
