import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { DocumentContainer } from '@src/js/views/user/pages/Onboarding/OnboardingProcess/styled';
import { FormSection } from '@app/components/global/forms';
import { FormattedMessage, injectIntl } from 'react-intl';
import { purifyHtml } from '@src/js/utils';

const Privacy = ({
  control, errors, intl, isPartner, getValues,
}) => {
  const privacyInformation = intl.formatMessage({
    id: `onboarding.final.privacyInformation${ isPartner ? 'Partner' : 'Company' }`,
  });
  const marketingPolicy = intl.formatMessage({ id: 'onboarding.final.marketingPolicy' });
  const thirdPartyPolicy = intl.formatMessage({ id: 'onboarding.final.thirdPartyPolicy' });

  const validate = (fieldName) => getValues(fieldName) === true || getValues(fieldName) === false;

  return (
    <FormSection sectionTitle='privacy'>
      <div className='w-100'>
        <div className='privacy-container'>
          <DocumentContainer
            className='blue-a'
            dangerouslySetInnerHTML={ { __html: purifyHtml(privacyInformation) } }
          />
        </div>

        <Controller
          name='terms.marketingPolicy'
          control={ control }
          rules={ { validate: () => validate('terms.marketingPolicy') } }
          render={ ({ field }) => {
            const change = v => field.onChange(v);
            return (
              <>
                <div className={ `privacy-radio${ errors?.terms?.marketingPolicy ? ' m-b-1' : ' m-b-2' }` }>
                  <div className='eop-checkbox'>
                    <div className='d-flex'>
                      <label>
                        <input
                          { ...field }
                          type='radio'
                          value={ true }
                          onChange={ () => change(true) }
                          className='eop-checkbox m-r-1'
                          checked={ field.value === true }
                        />
                        <span><FormattedMessage id='privacy.authorises' /></span>
                      </label>
                    </div>
                  </div>
                  <div className='eop-checkbox'>
                    <div className='d-flex'>
                      <label>
                        <input
                          { ...field }
                          type='radio'
                          value={ false }
                          onChange={ () => change(false) }
                          className='eop-checkbox m-r-1'
                          checked={ field.value === false }
                        />
                        <span><FormattedMessage id='privacy.doesNotAuthorise' /></span>
                      </label>
                    </div>
                  </div>
                </div>
                { errors?.terms?.marketingPolicy && (
                  <div className='m-b-2'>
                    <ErrorMessage
                      errors={ errors }
                      name='terms.marketingPolicy'
                      render={ () => (
                        <span className='error-message'>
                          { intl.formatMessage({ id: 'global_form.error.required' }) }
                        </span>
                      ) }
                    />
                  </div>
                ) }
              </>
            );
          } }
        />

        <div className='privacy-container'>
          <DocumentContainer
            className='blue-a'
            dangerouslySetInnerHTML={ { __html: purifyHtml(marketingPolicy) } }
          />
        </div>

        <Controller
          name='terms.thirdPartyPolicy'
          control={ control }
          rules={ { validate: () => validate('terms.thirdPartyPolicy') } }
          render={ ({ field }) => {
            const change = v => field.onChange(v);
            return (
              <>
                <div className={ `privacy-radio${ errors?.terms?.thirdPartyPolicy ? ' m-b-1' : ' m-b-2' }` }>
                  <div className='eop-checkbox'>
                    <div className='d-flex'>
                      <label>
                        <input
                          { ...field }
                          type='radio'
                          value={ true }
                          onChange={ () => change(true) }
                          className='eop-checkbox m-r-1'
                          checked={ field.value === true }
                        />
                        <span><FormattedMessage id='privacy.authorises' /></span>
                      </label>
                    </div>
                  </div>
                  <div className='eop-checkbox'>
                    <div className='d-flex'>
                      <label>
                        <input
                          { ...field }
                          type='radio'
                          value={ false }
                          onChange={ () => change(false) }
                          className='eop-checkbox m-r-1'
                          checked={ field.value === false }
                        />
                        <span><FormattedMessage id='privacy.doesNotAuthorise' /></span>
                      </label>
                    </div>
                  </div>
                </div>
                { errors?.terms?.thirdPartyPolicy && (
                  <div className='m-b-2'>
                    <ErrorMessage
                      errors={ errors }
                      name='terms.thirdPartyPolicy'
                      render={ () => (
                        <span className='error-message'>
                          { intl.formatMessage({ id: 'global_form.error.required' }) }
                        </span>
                      ) }
                    />
                  </div>
                ) }
              </>
            );
          } }
        />

        <div className='privacy-container'>
          <DocumentContainer
            className='blue-a'
            dangerouslySetInnerHTML={ { __html: purifyHtml(thirdPartyPolicy) } }
          />
        </div>
      </div>
    </FormSection>
  );
};

Privacy.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  intl: PropTypes.object,
  isPartner: PropTypes.bool,
  getValues: PropTypes.func,
};

export default injectIntl(Privacy);
