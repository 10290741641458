/* eslint-disable import/max-dependencies */
import { combineReducers } from 'redux';
import PrivateMarketTemplateReducer from '@src/js/oneplatform/privateMarketNetwork/container/ducks/reducer';
import globalReducer from '@app/store/global/reducer/global';
import etineraryReducer from '@app/store/etinerary/reducer';
import translationsReducers from '@app/store/translations/reducer/index';
import funding from '@app/store/funding/reducer';
import parametersPartnersReducer from '@app/store/funding/reducer/partner';
import complianceReducer from '@app/store/compliance/reducer';
import libraryReducer from '@app/store/library/reducer';
import taxonomyReducer from '@app/store/taxonomy/reducer';
import businessNeedTableReducer from '@src/js/marketplace/reducers/admin/businessNeedTable';
import dataroomReducer from '@app/store/documentsArea/reducer';
import onboardingReducer from '@app/store/onboarding/reducer';
import broker from '@app/store/funding/reducer/broker';
import deal from '@app/store/funding/reducer/deal';
import programmeReducer from '@app/store/programme/reducer';
import reminderArchiveReducer from '@app/store/reminderArchive/reducer';
import customReminderReducer from '@app/store/customReminder/reducer';
import eventAttendanceReducer from '@app/store/eventAttendance/reducer';
import signDocumentReducer from '@app/store/company/reducer';
import networkReducer from '@app/store/network/reducer';
import promotedContentReducer from '@app/store/content/promotedContent/reducer';
import topicReducer from '@app/store/content/topic/reducer';
import libraryPathReducer from '@app/store/content/libraryPath/reducer';
import eventReducer from '@app/store/event/reducer';
import marketplaceDashboard from '@src/js/marketplace/reducers/marketplaceDashboard';
import marketplaceReducers from '@src/js/marketplace/reducers/index';
import membershipReducer from '@src/js/oneplatform/membership/pages/home/container/ducks/reducer';
import membershipFormReducer from '@src/js/oneplatform/membership/pages/form/container/ducks/reducer';
import trainingsReducer from '@src/js/oneplatform/trainings/container/ducks/reducer';
import newsList from '@src/js/reducers/news/newsList';
import bannerContent from '@app/store/content/banner/reducer';
import companyWidgetReducer from '@app/store/companyWidget/reducer';
import { chatSlice } from '@app/store/chat/chatSlice';
import partnerFormOptions from './partner/partnerFormOptions';
import userFormOptions from './user/formOptions';
import companyFormOptions from './company/companyFormOptions';
import externalCompanyFormOptions from './self-registration/company/externalCompanyFormOptions';
import adminCompanies from './company/adminCompanies';
import adminCompany from './company/adminCompany';
import relationshipManagerCompany from './company/relationshipManagerCompany';
import adminNews from './news/adminNews';
import adminNewsSingle from './news/adminNewsSingle';
import adminLibrary from './library/adminLibrary';
import adminLibrarySingle from './library/adminLibrarySingle';
import company from './company/company';
import companyPermissions from './company/companyPermissions';
import companyDocumentsFiles from './company/companyDocumentsFiles';
import companyDocumentsUpload from './company/companyDocumentsUpload';
import companyDocumentsDelete from './company/companyDocumentsDelete';
import companyDocumentsPermissions from './company/companyDocumentsPermissions';
import companyCertification from './company/companyCertification';
import companyCourseraAccess from './company/companyCourseraAccess';
import companyManager from './company/companyManager';
import companyContactMessageSend from './company/companyContactMessageSend';
import partners from './partner/partners';
import partner from './partner/partner';
import tasks from './tasks/task';
import taskGetUserPermissions from './tasks/taskGetUserPermissions';
import taskSetUserPermissions from './tasks/taskSetUserPermissions';
import socketTaskNotifications from './tasks/socketTaskNotifications';
import partnerBasicData from './partner/partnerBasicData';
import partnerDocumentsFiles from './partner/partnerDocumentsFiles';
import partnerDocumentsPermissions from './partner/partnerDocumentsPermissions';
import partnerDocumentsUpload from './partner/partnerDocumentsUpload';
import partnerDocumentDelete from './partner/partnerDocumentDelete';
import partnerCompanies from './partner/partnerCompanies';
import partnerPermissions from './partner/partnerPermissions';
import partnerCourseraAccess from './partner/partnerCourseraAccess';
import programmes from './programme/programmes';
import programme from './programme/programme';
import relationManagers from './user/getRelationManagers';
import contactUser from './user/getContactUser';
import adminUser from './user/adminUser';
import adminUsers from './user/adminUsers';
import user from './user/user';
import users from './user/users';
import uploadImage from './image/uploadImage';
import deleteImage from './image/deleteImage';
import userImage from './image/userImage';
import banners from './banners';
import search from './search';
import countries from './taxonomy/countries';
import turnovers from './taxonomy/turnovers';
import currencies from './taxonomy/currencies';
import sectors from './taxonomy/sectors';
import industries from './taxonomy/industries';
import adminEvents from './events/adminEvents';
import events from './events/events';
import adminEvent from './events/adminEvent';
import adminEventAttendingCompanies from './events/adminEventAttendingCompanies';
import uploadFile from './file/uploadFile';
import adminEventFormOptions from './events/adminEventFormOptions';
import tokenValidation from './tokenValidation';
import navigation from './navigation/navigation';
import initInfo from './initInfo';
import elasticsearch from './elasticsearch';
import elasticsearchHeader from './elasticsearchHeader';
import widget from './widget';
import newsIndex from './news/newsIndex';
import singleNews from './news/singleNews';
import widgetNews from './news/widgetNews';
import createNews from './news/createNews';
import editNews from './news/editNews';
import newsOptions from './news/getNewsOptions';
import createLibrary from './library/createLibrary';
import editLibrary from './library/editLibrary';
import libraryOptions from './library/getLibraryOptions';
import partnerLibraries from './library/partnerLibraries';
import partnerCreateLibrary from './library/partnerCreateLibrary';
import partnerEditLibrary from './library/partnerEditLibrary';
import partnerLibraryOptions from './library/partnerGetLibraryOptions';
import getOpportunitiesForCompany from './marketplace/getOpportunitiesForCompany';
import indexLibrary from './library/indexLibrary';
import featuredLibrary from './library/featuredLibrary';
import singleLibrary from './library/singleLibrary';
import networking from './networking/networking';
import adminProcesses from './onboarding/processes/indexProcesses';
import adminTemplateIndex from './onboarding/templates/admin/adminTemplateIndex';
import adminTemplateModal from './onboarding/templates/admin/adminTemplateModal';
import adminTemplateOptions from './onboarding/templates/admin/adminTemplateOptions';
import adminCreateTemplate from './onboarding/templates/admin/adminCreateTemplate';
import uploadOnboardingImage from './onboarding/image/uploadOnboardingImage';
import uploadOnboardingFile from './onboarding/file/uploadOnboardingFile';
import onboardingBasic from './onboarding/onboardingBasic';
import onboardingProcess from './onboarding/onboardingProcess';
import onboardingTemplate from './onboarding/onboardingTemplate';
import onboardingRegister from './onboarding/onboardingRegister';
import bulkMessageOptions from './bulkMessage/bulkMessageGetUsers';
import bulkMessageCompanies from './bulkMessage/bulkMessageGetCompanies';
import bulkMessageSender from './bulkMessage/bulkMessageGetSender';
import sendBulkMessage from './bulkMessage/sendBulkMessage';
import companyViews from './company/companyViews';
import allSessions from './session/getAllSessions';
import deleteSessions from './session/deleteSessions';
import partnerParametersVisibilityReducers from './partner/partnerParametersVisibility';

export default combineReducers({
  user,
  adminUser,
  adminUsers,
  adminCompanies,
  adminCompany,
  relationshipManagerCompany,
  adminNews,
  adminNewsSingle,
  adminLibrary,
  adminLibrarySingle,
  adminProcesses,
  adminTemplateIndex,
  adminTemplateModal,
  adminTemplateOptions,
  adminCreateTemplate,
  userFormOptions,
  companyFormOptions,
  externalCompanyFormOptions,
  partnerFormOptions,
  programmes,
  programme,
  company,
  companyPermissions,
  companyDocumentsFiles,
  companyDocumentsUpload,
  companyDocumentsDelete,
  companyDocumentsPermissions,
  companyCertification,
  companyCourseraAccess,
  companyManager,
  companyContactMessageSend,
  partners,
  partner,
  tasks,
  taskGetUserPermissions,
  taskSetUserPermissions,
  socketTaskNotifications,
  partnerBasicData,
  partnerDocumentsFiles,
  partnerDocumentsPermissions,
  partnerDocumentsUpload,
  partnerDocumentDelete,
  partnerCompanies,
  partnerPermissions,
  partnerCourseraAccess,
  adminEvent,
  adminEventAttendingCompanies,
  adminEvents,
  adminEventFormOptions,
  events,
  uploadFile,
  uploadImage,
  deleteImage,
  userImage,
  banners,
  search,
  countries,
  turnovers,
  currencies,
  sectors,
  industries,
  tokenValidation,
  navigation,
  initInfo,
  elasticsearch,
  elasticsearchHeader,
  users,
  widget,
  newsIndex,
  newsList,
  singleNews,
  widgetNews,
  createNews,
  newsOptions,
  editNews,
  createLibrary,
  editLibrary,
  libraryOptions,
  partnerLibraries,
  partnerCreateLibrary,
  partnerEditLibrary,
  partnerLibraryOptions,
  getOpportunitiesForCompany,
  indexLibrary,
  featuredLibrary,
  singleLibrary,
  relationManagers,
  contactUser,
  funding,
  networking,
  uploadOnboardingImage,
  uploadOnboardingFile,
  onboardingBasic,
  onboardingProcess,
  onboardingTemplate,
  onboardingRegister,
  bulkMessageOptions,
  bulkMessageCompanies,
  bulkMessageSender,
  sendBulkMessage,
  companyViews,
  broker,
  allSessions,
  deleteSessions,
  marketplaceDashboard,
  marketplaceReducers,
  membership: membershipReducer,
  membershipForm: membershipFormReducer,
  trainings: trainingsReducer,
  privateMarketTemplates: PrivateMarketTemplateReducer,
  etinerary: etineraryReducer,
  translations: translationsReducers,
  parametersPartners: parametersPartnersReducer,
  partnerParametersVisibility: partnerParametersVisibilityReducers,
  businessNeedTableData: businessNeedTableReducer,
  compliance: complianceReducer,
  library: libraryReducer,
  taxonomy: taxonomyReducer,
  dataroom: dataroomReducer,
  onboarding: onboardingReducer,
  deal,
  programmeLounges: programmeReducer,
  reminderArchive: reminderArchiveReducer,
  customReminder: customReminderReducer,
  eventAttendance: eventAttendanceReducer,
  global: globalReducer,
  signDocument: signDocumentReducer,
  promotedContent: promotedContentReducer,
  network: networkReducer,
  topic: topicReducer,
  libraryPath: libraryPathReducer,
  event: eventReducer,
  companyWidget: companyWidgetReducer,
  bannerContent,
  chat: chatSlice.reducer,
});
