import { takeLatest, call, put } from 'redux-saga/effects';
import {
  SEND_BULK_MESSAGE_START,
  SEND_BULK_MESSAGE_ERROR,
  SEND_BULK_MESSAGE_SUCCESS,
} from '@src/js/actions/bulkMessage/sendBulkMessage';
import * as api from '@src/js/api/bulkMessage/sendBulkMessage';

function* sendBulkMessage(options) {
  try {
    const data = yield call(() => api.sendBulkMessage(options.data.bulkMessageData, options.data.callback));
    options.data.resolve(data);
    yield put({ type: SEND_BULK_MESSAGE_SUCCESS, data });
  } catch (error) {
    yield put({ type: SEND_BULK_MESSAGE_ERROR, error });
    options.data.reject(error);
  }
}

function* sendBulkMessageWatcher() {
  yield takeLatest(SEND_BULK_MESSAGE_START, sendBulkMessage);
}

export default [
  sendBulkMessageWatcher(),
];
