export const SEND_BULK_MESSAGE_START = 'SEND_BULK_MESSAGE_START';
export const SEND_BULK_MESSAGE_ERROR = 'SEND_BULK_MESSAGE_ERROR';
export const SEND_BULK_MESSAGE_SUCCESS = 'SEND_BULK_MESSAGE_SUCCESS';

export function sendBulkMessage(data) {
  return {
    type: SEND_BULK_MESSAGE_START,
    data,
  };
}
