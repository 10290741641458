import React from 'react';
import * as PropTypes from 'prop-types';
import { TargetBlank } from '@src/_v2/js/constants';
import { Icons } from '@src/js/constants/icons';
import { Icon } from './styled';

const DocumentIcon = (props) => {
  const { href } = props;

  return (
    <Icon>
      <a href={ href } { ...TargetBlank }>
        <img src={ Icons.Doc } alt='Document' />
      </a>
    </Icon>
  );
};

DocumentIcon.propTypes = {
  href: PropTypes.string,
};

export default DocumentIcon;
