export const PARTNER_DOCUMENTS_DELETE_START = 'PARTNER_DOCUMENTS_DELETE_START';
export const PARTNER_DOCUMENTS_DELETE_ERROR = 'PARTNER_DOCUMENTS_DELETE_ERROR';
export const PARTNER_DOCUMENTS_DELETE_SUCCESS = 'PARTNER_DOCUMENTS_DELETE_SUCCESS';

export function partnerDocumentDelete(fileSlug, slug) {
  return {
    type: PARTNER_DOCUMENTS_DELETE_START,
    fileSlug,
    slug,
  };
}
