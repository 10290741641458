import {
  GET_NEWS_INDEX_START,
  GET_NEWS_INDEX_ERROR,
  GET_NEWS_INDEX_SUCCESS,
} from '@src/js/actions/news/newsIndex';
import { NewsIndex } from '@app/models/SingleNews';
import { Action } from 'redux';
import produce from 'immer';

export type NewsIndexState = {
  loading: boolean,
  error: unknown,
  newsIndex: null|NewsIndexResult,
};

type NewsIndexResult = {
  inspiringStories: null|NewsIndex[]
  video: null|NewsIndex[]
  mediaCenter: null|NewsIndex[]
}

interface NewsIndexPayload extends Action {
  error: unknown
  data: null|{
    // eslint-disable-next-line camelcase
    inspiring_stories: null|NewsIndex[]
    video: null|NewsIndex[]
    // eslint-disable-next-line camelcase
    media_center: null|NewsIndex[]
  }
}

const initialState: NewsIndexState = {
  loading: false,
  error: null,
  newsIndex: null,
};

export default (state = initialState, action: NewsIndexPayload) => produce(state, (draftState) => {
  const draft = draftState;
  switch (action.type) {
    case GET_NEWS_INDEX_START:
      draft.loading = true;
      draft.error = null;
      break;
    case GET_NEWS_INDEX_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_NEWS_INDEX_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.newsIndex = {
        inspiringStories: action?.data?.inspiring_stories ?? null,
        video: action?.data?.video ?? null,
        mediaCenter: action?.data?.media_center ?? null,
      };
      break;
    default:
      break;
  }
});
