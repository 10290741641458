import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';

import inputValidation from '@src/js/helpers/InputValidation';

import FieldsNewLegal from './FieldsNewLegal';

const NewLegal = ({
  footer,
  handleFormSubmit,
}) => {
  const validate = values => {
    const errors = {};

    errors.name = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.minLengthValidator(2),
      inputValidation.maxLengthValidator(50)
    )(values.name);

    errors.surname = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.minLengthValidator(2),
      inputValidation.maxLengthValidator(50)
    )(values.surname);

    errors.email = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.emailValidator)(values.email);

    errors.jobTitle = inputValidation.composeValidators(
      inputValidation.requiredValidator,
      inputValidation.minLengthValidator(2),
      inputValidation.maxLengthValidator(50)
    )(values.jobTitle);

    return errors;
  };

  return (
    <>
      <div className='content-description'>
        <FormattedMessage id='etinerary.upgradeModal.inviteRepresentativeDescription' />
      </div>
      <Form
        onSubmit={ values => handleFormSubmit?.({
          firstName: values?.name,
          lastName: values?.surname,
          email: values?.email,
          jobTitle: values?.jobTitle,
        }) }
        validate={ validate }
        render={ ({
          handleSubmit, invalid,
        }) => (
          <form
            onSubmit={ handleSubmit }
            id='new_legal_user_form'
            role='presentation'
            className='etinerary-new-legar-user-form'
          >
            <FieldsNewLegal />
            { footer(invalid) }
          </form>
        ) }
      />
    </>
  );
};

NewLegal.propTypes = {
  footer: PropTypes.node,
  handleFormSubmit: PropTypes.func.isRequired,
};

export default NewLegal;
