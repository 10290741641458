import { Action } from 'redux';
import { SingleNews } from '@app/models/SingleNews';
import {
  GET_SINGLE_NEWS_START,
  GET_SINGLE_NEWS_ERROR,
  GET_SINGLE_NEWS_SUCCESS,
} from '@src/js/actions/news/singleNews';

export type SingleNewsState = {
  loading: boolean,
  error: unknown,
  singleNews: null|SingleNewsResult,
};

type SingleNewsResult = {
  news: SingleNews
  relatedNews: SingleNews,
}

interface SingleNewsPayload extends Action {
  error: unknown
  data: null|SingleNewsResult;
}

const initialState: SingleNewsState = {
  loading: false,
  error: null,
  singleNews: null,
};

type ActionsMap = {
  [key: string]: (state: SingleNewsState, action: SingleNewsPayload) => SingleNewsState;
};

const actionsMap: ActionsMap = {
  [GET_SINGLE_NEWS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    singleNews: null,
  }),
  [GET_SINGLE_NEWS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_SINGLE_NEWS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    singleNews: action.data,
  }),
};

export default function reducer(state = initialState, action: SingleNewsPayload) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
