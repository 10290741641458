const scope = 'EVENT_ATTENDANCE';

export const GET_LIST_START = `${ scope }/GET_LIST_START`;
export const GET_LIST_FAIL = `${ scope }/GET_LIST_FAIL`;
export const GET_LIST_SUCCESS = `${ scope }/GET_LIST_SUCCESS`;

export const ADD_ATTENDANCE_START = `${ scope }/ADD_ATTENDANCE_START`;
export const ADD_ATTENDANCE_FAIL = `${ scope }/ADD_ATTENDANCE_FAIL`;
export const ADD_ATTENDANCE_SUCCESS = `${ scope }/ADD_ATTENDANCE_SUCCESS`;

export const REMOVE_ATTENDANCE_START = `${ scope }/REMOVE_ATTENDANCE_START`;
export const REMOVE_ATTENDANCE_FAIL = `${ scope }/REMOVE_ATTENDANCE_FAIL`;

export const UPDATE_ATTENDANCE_LOCATION_START = `${ scope }/UPDATE_ATTENDANCE_LOCATION_START`;
export const UPDATE_ATTENDANCE_LOCATION_SUCCESS = `${ scope }/UPDATE_ATTENDANCE_LOCATION_SUCCESS`;
export const UPDATE_ATTENDANCE_LOCATION_FAIL = `${ scope }/UPDATE_ATTENDANCE_LOCATION_FAIL`;

export function getEventAttendanceList(slug, params) {
  return {
    type: GET_LIST_START,
    slug,
    params,
  };
}

export function addAttendance(slug, data, filters) {
  return {
    type: ADD_ATTENDANCE_START,
    slug,
    data,
    filters,
  };
}

export function removeAttendance(slug, id, filters) {
  return {
    type: REMOVE_ATTENDANCE_START,
    slug,
    id,
    filters,
  };
}

export function updateAttendanceLocation(slug, id, data) {
  return {
    type: UPDATE_ATTENDANCE_LOCATION_START,
    slug,
    id,
    data,
  };
}
