export const GET_LATEST_OPPORTUNITIES_START = 'GET_LATEST_OPPORTUNITIES_START';
export const GET_LATEST_OPPORTUNITIES_ERROR = 'GET_LATEST_OPPORTUNITIES_ERROR';
export const GET_LATEST_OPPORTUNITIES_SUCCESS = 'GET_LATEST_OPPORTUNITIES_SUCCESS';

export function getLatestOpportunities(filters) {
  return {
    type: GET_LATEST_OPPORTUNITIES_START,
    filters,
  };
}
