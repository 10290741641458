import React from 'react';
import * as PropTypes from 'prop-types';
import { ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { Button } from '@src/js/components/global';

const UpgradeModalFooter = ({
  activeStep,
  handleBack,
  handleClose,
  handleNext,
  handleRepresentative,
  disabled,
  isRepresentative,
}) => {
  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Button
              size='lg'
              buttonText={ 'main.yes' }
              shouldTranslateText={ true }
              onClickFunc={ () => {
                handleRepresentative(true);
                handleNext();
              } }
            />
            <Button
              size='lg'
              buttonText={ 'main.no' }
              shouldTranslateText={ true }
              onClickFunc={ () => {
                handleRepresentative(false);
                handleNext();
              } }
            />
          </>
        );
      case 1:
        return (
          <>
            <div
              className='etinerary-back-button'
              onClick={ () => handleBack() }
              role='presentation'
            >
              <FormattedMessage id='back' />
            </div>
            <Button
              size='lg'
              buttonText={ isRepresentative ? 'main.submit' : 'etinerary.upgradeModal.invite' }
              buttonType='submit'
              disabled={ disabled }
            />
          </>
        );
      case 2:
        return (
          <Button
            size='lg'
            buttonText={ 'close' }
            shouldTranslateText={ true }
            onClickFunc={ handleClose }
          />
        );
      default:
        return '';
    }
  };
  return (
    <ModalFooter className={ clsx({
      'etinerary-upgrade-modal-footer': activeStep !== 1,
      'etinerary-new-legar-user-footer': activeStep === 1,
    }) }
    >
      {renderStep()}
    </ModalFooter>
  );
};

UpgradeModalFooter.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleRepresentative: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isRepresentative: PropTypes.bool,
};

export default UpgradeModalFooter;
