import React, { useEffect } from 'react';
import {
  selectOnboardingSave, selectOnboarding, selectCurrentStep, selectFirstVisit,
} from '@app/store/onboarding/selectors';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { FormSection, InputCheckbox } from '@app/components/global/forms';
import { purifyHtml } from '@src/js/utils';
import { Privacy } from '@app/components/public/onboarding/sections';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import Signature from './Signature';

const Final = ({
  handleSave, hash, partnerDetails, isPartner, readyOnly,
}) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onSave = selectOnboardingSave();
  const onboarding = selectOnboarding();
  const form = useForm({
    defaultValues: onboarding?.data,
    mode: 'onChange',
  });

  const {
    getValues, formState: { errors }, control, trigger,
  } = form;

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      handleSave(getValues(), onSave?.downloadPath);
    }
  }, [onSave]);

  return (
    <FormProvider { ...form }>
      <form>
        <FormSection
          sectionTitle={ onboarding?.data.template?.title }
          translate={ false }
        >
          <EmailSupport />
          <div className='w-100'>
            <div
              className='document-container blue-a'
              dangerouslySetInnerHTML={ { __html: purifyHtml(onboarding?.data?.template?.editorial || '') } }
            />
            {!partnerDetails && (
              <div className='flex-h-gap-16'>
                <InputCheckbox
                  name='terms.accepted'
                  label='onboarding.final.confirmTermsAndConditions'
                  errors={ errors }
                  control={ control }
                  required
                />
              </div>
            )}
          </div>
        </FormSection>

        <hr className='compliance__divider' />

        <Privacy control={ control } errors={ errors } isPartner={ isPartner } getValues={ getValues } />
        { !readyOnly
      && (
      <Signature
        hash={ hash }
        form={ form }
        partnerDetails={ partnerDetails }
        isPartner={ isPartner }
      />
      ) }
      </form>
    </FormProvider>
  );
};

Final.propTypes = {
  handleSave: PropTypes.func,
  hash: PropTypes.string.isRequired,
  partnerDetails: PropTypes.bool,
  isPartner: PropTypes.bool,
  readyOnly: PropTypes.bool,
};

export default Final;
