import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { ThemeColors, FontSize, Breakpoints } from '@src/_v2/js/constants';

export const ReduxToaster = styled(ToastContainer)`
  position: absolute;

  .top-center {
    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      left: 5%;
      margin-left: 0;
      width: 90%;
    }

    @media only screen and (min-width: ${ Breakpoints.SMALL }px) and (max-width: ${ Breakpoints.LARGE }px) {
      left: 10%;
      margin-left: 0;
      width: 80%;
    }

    @media only screen and (min-width: ${ Breakpoints.LARGE }px) {
      left: 30%;
      margin-left: -9%;
      width: 61%;
    }

    .close-toastr.toastr-control {
      color: ${ ThemeColors.white };
      opacity: 1;
      min-width: 4rem;
    }

    .rrt-success {
      background: ${ ThemeColors.green };

      .close-toastr.toastr-control {
        background: ${ ThemeColors.green };
      }
    }

    .toastr.animated {
      border-radius: 0;
    }

    .toastr.rrt-error {
      background: ${ ThemeColors.red };

      .close-toastr.toastr-control {
        background: ${ ThemeColors.red };
      }
    }

    .rrt-middle-container {
      height: 7rem;
      line-height: 5.3rem;
      width: 80%;

      @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
        margin-left: 6rem;
        margin-right: 5.5rem;
      }

      @media only screen and (min-width: ${ Breakpoints.SMALL }px) {
        margin-left: 8rem;
      }

      .rrt-title,
      .rrt-text {
        font-size: ${ FontSize.caption };
      }

      .rrt-title {
        display: inline;
      }

      .rrt-text {
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 6rem;

        @media only screen and (max-width: ${ Breakpoints.EXTRA_SMALL }px) {
          display: none;
        }
      }
    }
  }
`;
