import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_CREATE_LIBRARY_START,
  PARTNER_CREATE_LIBRARY_ERROR,
  PARTNER_CREATE_LIBRARY_SUCCESS,
} from '@src/js/actions/library/partnerCreateLibrary';

import * as api from '@src/js/api/library/partnerCreateLibrary';

function* partnerCreateLibrary(options) {
  try {
    const data = yield call(() => api.partnerCreateLibrary(options.data.values));
    yield put({ type: PARTNER_CREATE_LIBRARY_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: PARTNER_CREATE_LIBRARY_ERROR, error });
    options.data.reject(error);
  }
}

function* partnerCreateLibraryWatcher() {
  yield takeLatest(PARTNER_CREATE_LIBRARY_START, partnerCreateLibrary);
}

export default [
  partnerCreateLibraryWatcher(),
];
