import produce from 'immer';

import {
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAIL,
  SEND_TEMPLATE_REQUEST,
  SEND_TEMPLATE_SUCCESS,
  SEND_TEMPLATE_FAIL,
  DOWNLOAD_TEMPLATE_REQUEST,
  DOWNLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_TEMPLATE_FAIL,
  CREATE_NEW_LEGAL_REQUEST,
  CREATE_NEW_LEGAL_SUCCESS,
  CREATE_NEW_LEGAL_FAIL,
} from '@app/store/etinerary/constants';

export const initialState = {
  loading: false,
  error: null,
  data: {
    editorial: null,
    docType: false,
    legalUser: {},
  },
  download: {
    loading: false,
    error: null,
    data: {
      name: null,
      slug: null,
    },
    downloaded: false,
  },
  requestSent: false,
  showModal: false,
};

/* eslint-disable default-case,no-param-reassign */
function etineraryReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_TEMPLATE_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case GET_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = action.payload;
        break;
      case GET_TEMPLATE_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        break;
      case CREATE_NEW_LEGAL_REQUEST:
      case SEND_TEMPLATE_REQUEST:
        draft.loading = true;
        draft.error = null;
        draft.requestSent = false;
        break;
      case CREATE_NEW_LEGAL_SUCCESS:
      case SEND_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.requestSent = true;
        break;
      case CREATE_NEW_LEGAL_FAIL:
      case SEND_TEMPLATE_FAIL:
        draft.loading = false;
        draft.error = action.payload;
        draft.requestSent = false;
        break;
      case DOWNLOAD_TEMPLATE_REQUEST:
        draft.download = {
          loading: true,
          error: false,
          succeded: false,
          data: initialState.download.data,
        };
        break;
      case DOWNLOAD_TEMPLATE_SUCCESS:
        draft.download = {
          loading: false,
          error: false,
          succeded: true,
          data: action.payload,
        };
        break;
      case DOWNLOAD_TEMPLATE_FAIL:
        draft.download = {
          loading: false,
          error: action.payload,
          succeded: false,
        };
        break;
    }
  }
  );
}

/* eslint-enable */

export default etineraryReducer;
