import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_NEWS_OPTIONS_START,
  GET_NEWS_OPTIONS_ERROR,
  GET_NEWS_OPTIONS_SUCCESS,
} from '@src/js/actions/news/getNewsOptions';

import * as api from '@src/js/api/news/getNewsOptions';

function* getNewsOptions(options) {
  try {
    const data = yield call(() => api.getNewsOptions(options.slug));
    yield put({ type: GET_NEWS_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NEWS_OPTIONS_ERROR, error });
  }
}

function* getNewsOptionsWatcher() {
  yield takeLatest(GET_NEWS_OPTIONS_START, getNewsOptions);
}

export default [
  getNewsOptionsWatcher(),
];
