import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_INDUSTRIES_START,
  GET_INDUSTRIES_ERROR,
  GET_INDUSTRIES_SUCCESS,
} from '@src/js/actions/taxonomy/industries';

import * as api from '@src/js/api/taxonomy/industries';

function* getIndustries() {
  try {
    const data = yield call(() => api.getIndustries());
    yield put({ type: GET_INDUSTRIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_INDUSTRIES_ERROR, error });
  }
}

export function* getIndustriesWatcher() {
  yield takeLatest(GET_INDUSTRIES_START, getIndustries);
}

export default [
  getIndustriesWatcher(),
];
