export const GET_PEERS_START = 'GET_PEERS_START';
export const GET_PEERS_ERROR = 'GET_PEERS_ERROR';
export const GET_PEERS_SUCCESS = 'GET_PEERS_SUCCESS';
export const GET_PEERS_LIST_START = 'GET_PEERS_LIST_START';
export const GET_PEERS_LIST_ERROR = 'GET_PEERS_LIST_ERROR';
export const GET_PEERS_LIST_SUCCESS = 'GET_PEERS_LIST_SUCCESS';
export const CLEAR_PEERS = 'CLEAR_PEERS';

export const GET_REFERRERS_REQUESTED = 'GET_REFERRERS_REQUESTED';
export const GET_REFERRERS_SUCCEEDED = 'GET_REFERRERS_SUCCEEDED';
export const GET_REFERRERS_FAILED = 'GET_REFERRERS_FAILED';

export const ADD_REFERRERS_REQUESTED = 'ADD_REFERRERS_REQUESTED';
export const ADD_REFERRERS_SUCCEEDED = 'ADD_REFERRERS_SUCCEEDED';
export const ADD_REFERRERS_FAILED = 'ADD_REFERRERS_FAILED';

export const CALL_REFERRER_REQUESTED = 'CALL_REFERRER_REQUESTED';
export const CALL_REFERRER_SUCCEEDED = 'CALL_REFERRER_SUCCEEDED';
export const CALL_REFERRER_FAILED = 'CALL_REFERRER_FAILED';

export const REFERRER_DASHBOARD = 'dashboard';
export const REFERRER_FUNDING = 'funding';
export const REFERRER_CALL = 'call';

export function getPeers(threadId, offset, search) {
  return {
    type: GET_PEERS_START,
    threadId,
    offset,
    search,
  };
}

export function getPeersList({
  offset, search, referrerType, companyId, isCompany, reset,
}) {
  return {
    type: GET_PEERS_LIST_START,
    offset,
    search,
    referrerType,
    companyId,
    isCompany,
    reset,
  };
}

export function clearPeers() {
  return {
    type: CLEAR_PEERS,
  };
}

export function getReferrers(slug, isCompany, referrerType, isAdmin = false) {
  return {
    type: GET_REFERRERS_REQUESTED,
    slug,
    isCompany,
    referrerType,
    isAdmin,
  };
}

export function getReferrersSucceeded(data) {
  return {
    type: GET_REFERRERS_SUCCEEDED,
    payload: data,
  };
}

export function getReferrersFailed(error) {
  return {
    type: GET_REFERRERS_FAILED,
    payload: error,
  };
}

export function addReferrers(slug, isCompany, referrers, referrerType, messages) {
  return {
    type: ADD_REFERRERS_REQUESTED,
    slug,
    isCompany,
    referrers,
    referrerType,
    messages,
  };
}

export function addReferrersSucceeded(data) {
  return {
    type: ADD_REFERRERS_SUCCEEDED,
    payload: data,
  };
}

export function addReferrersFailed(error) {
  return {
    type: ADD_REFERRERS_FAILED,
    payload: error,
  };
}

export function callReferrer({ phone }, requestType) {
  return {
    type: CALL_REFERRER_REQUESTED,
    phone,
    requestType,
  };
}

export function callReferrerSucceeded(data) {
  return {
    type: CALL_REFERRER_SUCCEEDED,
    payload: data,
  };
}

export function callReferrerFailed(error) {
  return {
    type: CALL_REFERRER_FAILED,
    payload: error,
  };
}
