import { useSelector } from 'react-redux';

const selectActiveLanguage = () => useSelector(state => state.initInfo?.activeLanguage);
const selectLocalizationMessages = () => useSelector(state => state.initInfo?.localizationMessages);
const selectUserInfo = () => useSelector(state => state.initInfo?.userInfo);
const selectBanners = (type) => useSelector(state => state.banners?.banners[type]?.data);
const selectLoadingBanners = (type) => useSelector(state => state.banners?.banners[type]?.loading);

export {
  selectActiveLanguage,
  selectLocalizationMessages,
  selectUserInfo,
  selectBanners,
  selectLoadingBanners,
};
