import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';

import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  ADMIN_COPY_EVENT_ERROR,
  ADMIN_COPY_EVENT_START,
  ADMIN_COPY_EVENT_SUCCESS,
  ADMIN_CREATE_EVENT_ERROR,
  ADMIN_CREATE_EVENT_START,
  ADMIN_CREATE_EVENT_SUCCESS,
  ADMIN_DISABLE_EVENT_ERROR,
  ADMIN_DISABLE_EVENT_START,
  ADMIN_DISABLE_EVENT_SUCCESS,
  ADMIN_EDIT_EVENT_ERROR,
  ADMIN_EDIT_EVENT_START,
  ADMIN_EDIT_EVENT_SUCCESS,
  ADMIN_PUBLISH_EVENT_ERROR,
  ADMIN_PUBLISH_EVENT_START,
  ADMIN_PUBLISH_EVENT_SUCCESS,
  ADMIN_SEND_TEST_REMINDER_ERROR,
  ADMIN_SEND_TEST_REMINDER_START,
  ADMIN_SEND_TEST_REMINDER_SUCCESS,
  ADMIN_SET_AS_FEATURED_EVENT_ERROR,
  ADMIN_SET_AS_FEATURED_EVENT_START,
  ADMIN_SET_AS_FEATURED_EVENT_SUCCESS,
  ADMIN_EVENT_SET_FILTERS,
  ADMIN_RELOAD_EVENT,
  AdminCreateEventAction,
  AdminEditEventAction,
  AdminEventAction,
  AdminSendEventTestReminderAction,
  setFilters as setFiltersAction,
  AdminSetFiltersEventAction,
} from '@src/js/actions/events/adminEvent';

import * as api from '@src/js/api/events/adminEvent';
import { RootState } from '@src/js/store';
import { AdminEventFilters, FILTERS_COOKIE_NAME } from '@src/js/reducers/events/adminEvent';
import Cookies from 'js-cookie';

function* reloadEvent(): Generator {
  const filters = (yield select((state: RootState) => state.adminEvent.filters)) as AdminEventFilters;

  yield put(setFiltersAction({ ...filters, page: 1 }));
}

function* disableEvent(options: AdminEventAction): Generator {
  try {
    const data = yield call(() => api.disableEvent(options.slug));
    yield put({ type: ADMIN_DISABLE_EVENT_SUCCESS, data });
    yield all([
      put(showToastrSuccess('notification.success_title', 'notification.company_form.delete.success_message')),
      put({ type: ADMIN_RELOAD_EVENT }),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_DISABLE_EVENT_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* publishEvent(options: AdminEventAction): Generator {
  try {
    const data = yield call(() => api.publishEvent(options.slug));
    yield all([
      put({ type: ADMIN_PUBLISH_EVENT_SUCCESS, data }),
      put({ type: ADMIN_RELOAD_EVENT }),
    ]);
  } catch (error) {
    yield put({ type: ADMIN_PUBLISH_EVENT_ERROR });
  }
}

function* copyEvent(options: AdminEventAction): Generator {
  try {
    const data = yield call(() => api.copyEvent(options.slug));
    yield all([
      put({ type: ADMIN_COPY_EVENT_SUCCESS, data }),
      put({ type: ADMIN_RELOAD_EVENT }),
    ]);
  } catch (error) {
    yield put({ type: ADMIN_COPY_EVENT_ERROR, error });
  }
}

function* featuredEvent(options: AdminEventAction): Generator {
  try {
    const data = yield call(() => api.featuredEvent(options.slug));
    yield all([
      put({ type: ADMIN_SET_AS_FEATURED_EVENT_SUCCESS, data }),
      put({ type: ADMIN_RELOAD_EVENT }),
    ]);
  } catch (error) {
    yield put({ type: ADMIN_SET_AS_FEATURED_EVENT_ERROR });
  }
}

function* createEvent({ event }: AdminCreateEventAction): Generator {
  try {
    const data = yield call(() => api.createEvent(event));
    yield all([
      put({ type: ADMIN_CREATE_EVENT_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.create.success_message')),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_CREATE_EVENT_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* editEvent({ event, slug }: AdminEditEventAction): Generator {
  try {
    const data = yield call(() => api.editEvent(event, slug));
    yield all([
      put({ type: ADMIN_EDIT_EVENT_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.edit.success_message')),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_EDIT_EVENT_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* sendEventTestReminder({ slug, templateId, submitData }: AdminSendEventTestReminderAction): Generator {
  try {
    yield call(() => api.sendEventTestReminder(slug, templateId, submitData));
    yield all([
      put({ type: ADMIN_SEND_TEST_REMINDER_SUCCESS }),
      put(showToastrSuccess()),
    ]);
  } catch (error: any) {
    yield all([
      put({ type: ADMIN_SEND_TEST_REMINDER_ERROR }),
      put(showToastrError('notification.error_title', error?.response?.description || 'notification.error_message')),
    ]);
  }
}

function setFilters({ filters }: AdminSetFiltersEventAction) {
  Cookies.set(FILTERS_COOKIE_NAME, JSON.stringify(filters), { expires: 30, path: '/', secure: true });
}

function* adminEventWatcher() {
  yield takeLatest(ADMIN_DISABLE_EVENT_START, disableEvent);
  yield takeLatest(ADMIN_PUBLISH_EVENT_START, publishEvent);
  yield takeLatest(ADMIN_COPY_EVENT_START, copyEvent);
  yield takeLatest(ADMIN_SET_AS_FEATURED_EVENT_START, featuredEvent);
  yield takeLatest(ADMIN_CREATE_EVENT_START, createEvent);
  yield takeLatest(ADMIN_EDIT_EVENT_START, editEvent);
  yield takeLatest(ADMIN_SEND_TEST_REMINDER_START, sendEventTestReminder);
  yield takeLatest(ADMIN_EVENT_SET_FILTERS, setFilters);
  yield takeLatest(ADMIN_RELOAD_EVENT, reloadEvent);
}

export default adminEventWatcher;
