import {
  View,
  LabeledFieldView,
  createLabeledInputText,
  ButtonView,
  submitHandler,
  ViewCollection,
  FocusableView,
} from '@ckeditor/ckeditor5-ui';
import { icons } from '@ckeditor/ckeditor5-core';
import { Locale } from '@ckeditor/ckeditor5-utils';

export default class VideoFormView extends View {
  linkInputView: LabeledFieldView;
  saveButtonView: ButtonView;
  cancelButtonView: ButtonView;
  wistiaButtonView: ButtonView;
  videoButtonView: ButtonView;
  childViews: ViewCollection<FocusableView|View>;

  constructor(locale: Locale) {
    super(locale);

    this.wistiaButtonView = this.createTab('wistia', 'ck-tab-button');
    this.videoButtonView = this.createTab('video', 'ck-tab-button');
    this.wistiaButtonView.isOn = true;
    this.linkInputView = this.createInput('Link target');

    this.saveButtonView = this.createButton('Save', icons.check, 'ck-button-save');
    this.saveButtonView.type = 'submit';

    this.cancelButtonView = this.createButton('Cancel', icons.cancel, 'ck-button-cancel');
    this.cancelButtonView.delegate('execute').to(this, 'cancel');

    const tabButtons = this.buttonGroup([
      this.wistiaButtonView,
      this.videoButtonView,
    ], 'ck-tab-buttons');
    const actionButtons = this.buttonGroup([
      this.cancelButtonView,
      this.saveButtonView,
    ], 'ck-action-buttons');

    this.childViews = this.createCollection([
      tabButtons,
      this.linkInputView,
      actionButtons,
    ]);

    this.setTemplate({
      tag: 'form',
      attributes: {
        class: ['ck', 'ck-video-form'],
        tabindex: '-1',
      },
      children: this.childViews,
    });
  }

  render() {
    super.render();

    submitHandler({
      view: this,
    });
  }

  focus() {
    this.linkInputView.focus();
  }

  createInput(label: string) {
    const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
    labeledInput.label = label;

    return labeledInput;
  }

  createButton(label: string, icon: string, className: string) {
    const button = new ButtonView(this.locale);
    button.set({
      label,
      icon,
      tooltip: true,
      class: className,
    });

    return button;
  }

  createTab(label: string, className: string) {
    const button = new ButtonView(this.locale);
    button.set({
      label,
      class: className,
      withText: true,
      isToggleable: true,
    });

    button.delegate('execute').to(this, 'sourceSelect');
    this.listenTo(this, 'sourceSelect', (val) => {
      const src = val.source as { label: string };
      button.isOn = src.label === label;
    });

    return button;
  }

  buttonGroup(children: View[], className: string) {
    const buttonGroup = new View();
    const buttons = this.createCollection(children);

    buttonGroup.setTemplate({
      tag: 'div',
      attributes: {
        class: ['ck', className],
      },
      children: buttons,
    });

    return buttonGroup;
  }
}
