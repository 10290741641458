import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_INDEX_LIBRARY_START,
  GET_INDEX_LIBRARY_ERROR,
  GET_INDEX_LIBRARY_SUCCESS,
} from '@src/js/actions/library/indexLibrary';

import * as api from '@src/js/api/library/indexLibrary';

function* getIndexLibrary(options) {
  try {
    const data = yield call(() => api.getIndexLibrary(options.filters));
    yield put({ type: GET_INDEX_LIBRARY_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_INDEX_LIBRARY_ERROR, error });
  }
}

function* getIndexLibraryWatcher() {
  yield takeLatest(GET_INDEX_LIBRARY_START, getIndexLibrary);
}

export default [
  getIndexLibraryWatcher(),
];
