import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_BULK_MESSAGE_COMPANIES_START,
  GET_BULK_MESSAGE_COMPANIES_ERROR,
  GET_BULK_MESSAGE_COMPANIES_SUCCESS,
} from '@src/js/actions/bulkMessage/bulkMessageGetCompanies';
import * as api from '@src/js/api/bulkMessage/bulkMessageGetCompanies';

function* getBulkMessageCompanies(filters) {
  try {
    const data = yield call(() => api.getBulkMessageCompanies(filters.filters.values));
    yield put({ type: GET_BULK_MESSAGE_COMPANIES_SUCCESS, data });
    filters.filters.resolve(data);
  } catch (error) {
    yield put({ type: GET_BULK_MESSAGE_COMPANIES_ERROR, error });
    filters.filters.reject(error.response);
  }
}

function* getBulkMessageCompaniesWatcher() {
  yield takeLatest(GET_BULK_MESSAGE_COMPANIES_START, getBulkMessageCompanies);
}

export default [
  getBulkMessageCompaniesWatcher(),
];
