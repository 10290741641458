export const GET_BULK_MESSAGE_SENDER_START = 'GET_BULK_MESSAGE_SENDER_START';
export const GET_BULK_MESSAGE_SENDER_ERROR = 'GET_BULK_MESSAGE_SENDER_ERROR';
export const GET_BULK_MESSAGE_SENDER_SUCCESS = 'GET_BULK_MESSAGE_SENDER_SUCCESS';

export function getBulkMessageSender(filters) {
  return {
    type: GET_BULK_MESSAGE_SENDER_START,
    filters,
  };
}
