import { enableES5, produce } from 'immer';
import {
  GET_RELEVANT_OPPORTUNITIES_START,
  GET_RELEVANT_OPPORTUNITIES_ERROR,
  GET_RELEVANT_OPPORTUNITIES_SUCCESS,
  GET_DASHBOARD_OPPORTUNITIES_START,
  GET_DASHBOARD_OPPORTUNITIES_SUCCESS,
  GET_DASHBOARD_OPPORTUNITIES_ERROR,
  GET_RESTRICTED_OPPORTUNITIES_START,
  GET_RESTRICTED_OPPORTUNITIES_ERROR,
  GET_RESTRICTED_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getRelevantOpportunities';

enableES5();

const initialState = {
  loading: false,
  error: null,
  relevantOpportunities: {},
  dashboardOpportunities: { Items: [] },
  filters: {},
  restrictedOpportunities: { Items: [] },
};

function updateRelevantStorage(action) {
  let relevantOpportunitiesCount = JSON.parse(localStorage.getItem('relevant-opportunities-count'));
  if (typeof relevantOpportunitiesCount !== 'object') {
    relevantOpportunitiesCount = {};
  }
  relevantOpportunitiesCount[action.businessNeedId] = action.data.CurrentPage + 1;
  localStorage.setItem('relevant-opportunities-count', JSON.stringify(relevantOpportunitiesCount));
}

const actionsMap = {
  [GET_RELEVANT_OPPORTUNITIES_START]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
    filters: action.filters,
  }),
  [GET_RELEVANT_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_RELEVANT_OPPORTUNITIES_SUCCESS]: (state, action) => {
    updateRelevantStorage(action);
    const data = { ...state.relevantOpportunities };
    data[action.businessNeedId] = action.data;

    return {
      ...state,
      loading: false,
      relevantOpportunities: data,
    };
  },
  [GET_DASHBOARD_OPPORTUNITIES_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_DASHBOARD_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_DASHBOARD_OPPORTUNITIES_SUCCESS]: (state, action) => {
    updateRelevantStorage(action);
    const dashboardOpportunities = { ...action.data, businessNeedId: action.businessNeedId };

    return {
      ...state,
      loading: false,
      dashboardOpportunities,
    };
  },

  [GET_RESTRICTED_OPPORTUNITIES_START]: (state) => ({
    ...state,
    loading: true,
  }),
  [GET_RESTRICTED_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_RESTRICTED_OPPORTUNITIES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    restrictedOpportunities: action.data,
  }),

  /* eslint-disable no-param-reassign */
  'UPDATE_OPPORTUNITY': (state, action) => produce(state, draft => {
    const relevant = draft.relevantOpportunities;
    const dashboard = draft.dashboardOpportunities;
    const restricted = draft.restrictedOpportunities;
    Object.keys(relevant).forEach((key) => {
      const idx = relevant[key].Items.findIndex(o => o.Id === action.opportunity.Id);
      if (idx >= 0) {
        relevant[key].Items[idx] = action.opportunity;
      }
    });
    const idx = dashboard.Items.findIndex(o => o.Id === action.opportunity.Id);
    if (idx >= 0) {
      dashboard.Items[idx] = action.opportunity;
    }
    const idxRestricted = restricted.Items.findIndex(o => o.Id === action.opportunity.Id);
    if (idxRestricted >= 0) {
      restricted.Items[idxRestricted] = action.opportunity;
    }
  }),
  /* eslint-enable */
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
