import {
  PARTNER_DOCUMENTS_GET_FILES_START,
  PARTNER_DOCUMENTS_GET_FILES_ERROR,
  PARTNER_DOCUMENTS_GET_FILES_SUCCESS,
} from '@src/js/actions/partner/partnerDocumentsFiles';

const initialState = {
  loading: false,
  error: {},
  files: null,
  totalFiles: [],
};

const actionsMap = {
  [PARTNER_DOCUMENTS_GET_FILES_START]: (state) => ({
    ...state,
    loading: true,
    error: {},
  }),
  [PARTNER_DOCUMENTS_GET_FILES_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [PARTNER_DOCUMENTS_GET_FILES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: {},
    files: action.data.documents,
    totalFiles: action.options?.offset && action.data.documents?.files
      ? [...state.totalFiles, ...action.data.documents.files]
      : action.data.documents?.files,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
