import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_LIBRARY_OPTIONS_START,
  GET_LIBRARY_OPTIONS_ERROR,
  GET_LIBRARY_OPTIONS_SUCCESS,
} from '@src/js/actions/library/getLibraryOptions';

import * as api from '@src/js/api/library/getLibraryOptions';

function* getLibraryOptions(options) {
  try {
    const data = yield call(() => api.getLibraryOptions(options.slug));
    yield put({ type: GET_LIBRARY_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_LIBRARY_OPTIONS_ERROR, error });
  }
}

function* getLibraryOptionsWatcher() {
  yield takeLatest(GET_LIBRARY_OPTIONS_START, getLibraryOptions);
}

export default [
  getLibraryOptionsWatcher(),
];
