import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';
import {
  GET_LIBRARY_PATH_LIST_START,
  GET_LIBRARY_PATH_LIST_FAIL,
  GET_LIBRARY_PATH_LIST_SUCCESS,
  GET_LIBRARY_PATH_START,
  GET_LIBRARY_PATH_FAIL,
  GET_LIBRARY_PATH_SUCCESS,
  UPDATE_LIBRARY_PATH_START,
  UPDATE_LIBRARY_PATH_FAIL,
  UPDATE_LIBRARY_PATH_SUCCESS,
  UPDATE_LIBRARY_PATH_STATUS_START,
  UPDATE_LIBRARY_PATH_STATUS_FAIL,
  UPDATE_LIBRARY_PATH_STATUS_SUCCESS,
  GET_VIDEO_ON_DEMAND_START,
  GET_VIDEO_ON_DEMAND_FAIL,
  GET_VIDEO_ON_DEMAND_SUCCESS,
} from '@app/store/content/libraryPath/actions';
import * as api from '@app/store/content/libraryPath/api';

function* getLibraryPathList({
  search, page, languages, statuses,
}) {
  try {
    const result = yield call(() => api.getLibraryPathList({
      page, search, languages, statuses,
    }));
    yield put({ type: GET_LIBRARY_PATH_LIST_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_LIBRARY_PATH_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getLibraryPath({ id }) {
  try {
    const result = yield call(() => api.getLibraryPath({ id }));
    yield put({ type: GET_LIBRARY_PATH_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_LIBRARY_PATH_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* updateLibraryPath({ id, formData, history }) {
  try {
    yield call(() => (id
      ? api.updateLibraryPath(id, formData)
      : api.createLibraryPath(formData)
    ));
    yield all([
      put({ type: UPDATE_LIBRARY_PATH_SUCCESS }),
      put(showToastrSuccess()),
    ]);
    history.push(withLang(adminRouteCodes.PATHS));
  } catch (error) {
    yield all([
      put({ type: UPDATE_LIBRARY_PATH_FAIL, error }),
      put(showToastrError('notification.error_title', error.response?.description || 'notification.submit.error')),
    ]);
  }
}

function* updateLibraryPathStatus({ id, status }) {
  try {
    yield call(() => api.updateLibraryPathStatus({ id, status }));
    yield all([
      put({ type: UPDATE_LIBRARY_PATH_STATUS_SUCCESS, id, status }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: UPDATE_LIBRARY_PATH_STATUS_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getVideoOnDemand() {
  try {
    const result = yield call(() => api.getVideoOnDemand());
    yield put({ type: GET_VIDEO_ON_DEMAND_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_VIDEO_ON_DEMAND_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_LIBRARY_PATH_LIST_START, getLibraryPathList);
  yield takeLatest(GET_LIBRARY_PATH_START, getLibraryPath);
  yield takeLatest(UPDATE_LIBRARY_PATH_START, updateLibraryPath);
  yield takeLatest(UPDATE_LIBRARY_PATH_STATUS_START, updateLibraryPathStatus);
  yield takeLatest(GET_VIDEO_ON_DEMAND_START, getVideoOnDemand);
}

export default root;
