import { Button } from '@src/js/components/global';
import React, { useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { showToastrError } from '@app/store/global/actions';
import { Peer } from '@app/models/Chat';
import PeersList from './PeersList';

type Props = {
  threadId: number|null,
  members: string[],
  submit: (participants: Peer[]) => void,
}

const ChatMemberModal = ({ members, threadId, submit }: Props) => {
  const dispatch = useDispatch();
  const [newMembers, setNewMembers] = useState<Peer[]>([]);

  const selectPeer = (peer: Peer) => {
    const selectedPeerIndex = newMembers.findIndex(p => p.uuid === peer.uuid);

    if (selectedPeerIndex === -1) {
      setNewMembers([...newMembers, peer]);

      return;
    }

    const tmpMembers = [...newMembers];
    tmpMembers.splice(selectedPeerIndex, 1);

    setNewMembers(tmpMembers);
  };

  const addMembers = () => {
    if (newMembers.length === 0) {
      dispatch(showToastrError('messages.chooseOne'));
      return;
    }

    submit(newMembers);
  };

  const m = newMembers.map(p => p.uuid);

  return (
    <ModalBody>
      <PeersList
        threadId={ threadId }
        members={ [...members, ...m] }
        selectPeer={ selectPeer }
      />
      <ModalFooter>
        <Button
          additionalClass='messages-page__modal-footer'
          size='lg'
          buttonText='add'
          onClickFunc={ addMembers }
        />
      </ModalFooter>
    </ModalBody>
  );
};

export default ChatMemberModal;
