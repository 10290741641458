import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import {
  showToastrError, showToastrSuccess,
} from '@app/store/global/actions';
import { downloadFile } from '@app/components/global/forms/utils';
import * as api from '@app/store/onboarding/api';
import * as Actions from '@app/store/onboarding/actions';

function* getOnboardingData({ hash, isAdmin, isPartner }) {
  try {
    const data = yield call(() => api.getOnboarding(hash, isAdmin, isPartner));
    yield put({ type: Actions.GET_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: Actions.GET_DATA_FAIL, error });
  }
}

function* updateOnboarding({
  hash, data, isAdmin, params, onSave, downloadPath, isPartner,
}) {
  try {
    const result = yield call(() => api.updateOnboarding(hash, data, isAdmin, params, isPartner));
    if (onSave.action === 'stepChange' && onSave.newStep) {
      yield all([
        put({ type: Actions.UPDATE_DATA_SUCCESS, result, data }),
        put({ type: Actions.SET_ACTIVE_STEP, step: onSave.newStep }),
      ]);
    } else {
      yield put({ type: Actions.UPDATE_DATA_SUCCESS, result, data });
      if (onSave.action === 'save' || onSave.action === 'submit') {
        yield put(
          showToastrSuccess('notification.success_title', 'membership.notification.save.success')
        );
      }
      if (downloadPath) {
        downloadFile(downloadPath);
      }
    }
  } catch (error) {
    yield put({ type: Actions.UPDATE_DATA_FAIL, error: error?.message });
    yield put(
      showToastrError('notification.error_title', 'membership.notification.save.error')
    );
  }
}

function* onboardingRegister({ templateHash, data }) {
  try {
    const result = yield call(() => api.onboardingRegister(data, templateHash));
    yield put({ type: Actions.REGISTER_SUCCESS, data: result });
  } catch (error) {
    yield put({ type: Actions.REGISTER_FAIL, error });
    yield put(
      showToastrError('notification.error_title', error.response?.errors?.toaster || 'notification.error_message')
    );
  }
}

function* docusign({ mobilePhone, terms, hash }) {
  try {
    const result = yield call(() => api.docusign({
      mobilePhone,
      terms,
      hash,
    }));
    yield put({ type: Actions.DOCUSIGN_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: Actions.DOCUSIGN_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* getCrmAccounts({ params }) {
  try {
    const result = yield call(() => api.getCrmAccounts(params));
    yield put({ type: Actions.GET_CRM_ACCOUNTS_SUCCESS, result, params });
  } catch (error) {
    yield all([
      put({ type: Actions.GET_CRM_ACCOUNTS_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* updateBasic({ data, hash, step }) {
  try {
    const result = yield call(() => api.updateBasic(hash, data));
    yield put({ type: Actions.UPDATE_BASIC_SUCCESS, result, data });
    if (step) {
      yield put({ type: Actions.SET_ACTIVE_STEP, step });
    } else {
      yield put(
        showToastrSuccess('notification.success_title', 'membership.notification.save.success')
      );
    }
  } catch (error) {
    yield all([
      put({ type: Actions.UPDATE_BASIC_FAIL }),
      put(
        showToastrError('notification.error_title',
          Object.values(error.response || {})[0] || 'membership.notification.save.error')
      ),
    ]);
  }
}

function* root() {
  yield takeLatest(Actions.GET_DATA_START, getOnboardingData);
  yield takeLatest(Actions.UPDATE_DATA_START, updateOnboarding);
  yield takeLatest(Actions.REGISTER_START, onboardingRegister);
  yield takeLatest(Actions.DOCUSIGN_START, docusign);
  yield takeLatest(Actions.GET_CRM_ACCOUNTS_START, getCrmAccounts);
  yield takeLatest(Actions.UPDATE_BASIC_START, updateBasic);
}

export default root;
