export const GET_BULK_MESSAGE_COMPANIES_START = 'GET_BULK_MESSAGE_COMPANIES_START';
export const GET_BULK_MESSAGE_COMPANIES_ERROR = 'GET_BULK_MESSAGE_COMPANIES_ERROR';
export const GET_BULK_MESSAGE_COMPANIES_SUCCESS = 'GET_BULK_MESSAGE_COMPANIES_SUCCESS';

export function getBulkMessageCompanies(filters) {
  return {
    type: GET_BULK_MESSAGE_COMPANIES_START,
    filters,
  };
}
