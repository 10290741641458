import {
  InputCheckboxTree,
  InputFileUpload, InputImage,
  InputText,
  InputTextArea,
} from '@app/components/global/forms';
import FormSection from '@app/components/global/forms/FormSection';
import { mapFormOptions } from '@app/components/global/forms/utils';
import { taskAssigned } from '@app/components/public/onboarding/constants';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import {
  selectCurrentStep,
  selectFirstVisit,
  selectOnboardingData,
  selectOnboardingSave,
  selectPartnerFormOptions,
} from '@app/store/onboarding/selectors';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { Language } from '@src/js/constants/entities';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

const PartnerProfile = ({ handleSave, intl }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const partnerFormOptions = selectPartnerFormOptions();
  const sectors = mapFormOptions(partnerFormOptions?.sectors, intl);
  const expertises = mapFormOptions(partnerFormOptions?.expertises, intl);
  const sectorsOfInterest = mapFormOptions(partnerFormOptions?.sectorsOfInterest, intl);
  const partnerSector = partnerFormOptions?.sectors
    .find(elem => elem.name === 'partner_sectors.corporateBroker')?.parentId;
  const partnerType = partnerFormOptions?.sectors?.filter(elem => elem.parentId === partnerSector)?.map(elem => ({
    value: elem.id,
    label: intl.formatMessage({ id: elem.name }),
  })).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  const locations = partnerFormOptions?.locations || [];
  const isAdmin = isAdminRole() || isPra();
  const methods = useForm({
    defaultValues: onboardingData,
    mode: 'onChange',
  });
  const {
    getValues, formState: { errors }, register, control, watch, trigger,
  } = methods;

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      handleSave(getValues());
    }
  }, [onSave]);

  return (
    <FormProvider { ...methods }>
      <form>
        <FormSection sectionTitle='onboarding.form.partnerProfile'>
          <EmailSupport />
          <InputImage
            name='logo'
            label='onboarding.form.partnerProfile.logo'
            required
            className={ 'elite-form__full-row' }
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/image/onboarding_partner/logo` }
          />
          <InputText
            name='website'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.website'
            type='url'
            required
            className={ 'elite-form__full-row' }
          />
          <InputText
            name='urlTwitter'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.twitter'
            type='url'
            className={ 'elite-form__full-row' }
          />
          <InputText
            name='urlLinkedIn'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.linkedIn'
            type='url'
            className={ 'elite-form__full-row' }
          />
        </FormSection>

        <hr className='compliance__divider' />

        <FormSection>
          <InputCheckboxTree
            name='partnerType'
            label='onboarding.form.partnerProfile.partnerType'
            errors={ errors }
            control={ control }
            options={ sectors }
            nodes={ partnerType || [] }
            checked={ watch('partnerType') }
            required
            numeric
            className={ 'elite-form__full-row' }
          />
          <InputCheckboxTree
            name='sectorsOfInterest'
            label='onboarding.form.partnerProfile.sectorOfInterest'
            errors={ errors }
            control={ control }
            nodes={ sectorsOfInterest || [] }
            checked={ watch('sectorsOfInterest') }
            required
            numeric
            className={ 'elite-form__full-row' }
          />
          <InputCheckboxTree
            name='expertises'
            label='onboarding.form.partnerProfile.expertise'
            errors={ errors }
            control={ control }
            nodes={ expertises || [] }
            checked={ watch('expertises') }
            required
            numeric
            className={ 'elite-form__full-row' }
          />
          <InputTextArea
            name='description'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.description'
            maxLength={ 5000 }
            className='elite-form__full-row compliance__text-area-onboarding'
            required
          />
          <InputTextArea
            name='strategy'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.strategy'
            maxLength={ 5000 }
            className='elite-form__full-row compliance__text-area-onboarding'
          />
          <InputFileUpload
            name='filePresentation'
            label='onboarding.form.partnerProfile.presentation'
            url={ `api${ isAdmin ? '/admin' : '' }/onboarding/file/onboarding_company/presentation` }
            required
            className={ 'elite-form__full-row' }
            downloadable
            acceptedTypes={ dropzoneExtensions.DOCUMENTS }
          />
        </FormSection>

        <hr className='compliance__divider' />

        <FormSection>
          <div className='eop-selectbox__label d-flex align-items-center eop-text-color-medium-grey w-100'>
            <FormattedMessage id='onboarding.form.partnerProfile.targetCompanySize' />
            <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ 'targetCompanySize-tooltip' } />
            <ReactTooltip
              id={ 'targetCompanySize-tooltip' }
              place='top'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='onboarding.form.partnerProfile.targetCompanySizeTooltip' />
            </ReactTooltip>
          </div>
          <InputText
            name='targetCompanySizeMin'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.targetCompanySizeMin'
            required
            type='number'
          />
          <InputText
            name='targetCompanySizeMax'
            errors={ errors }
            register={ register }
            label='onboarding.form.partnerProfile.targetCompanySizeMax'
            required
            type='number'
          />
          <InputCheckboxTree
            name='salesArea'
            label='onboarding.form.partnerProfile.salesArea'
            errors={ errors }
            control={ control }
            nodes={ locations || [] }
            checked={ watch('salesArea') }
            required
            numeric
            className={ 'elite-form__full-row' }
          />
          { onboardingData?.template?.lang === Language.IT && (
          <InputCheckboxTree
            name='taskAssigned'
            label='onboarding.form.partnerProfile.taskAssigned'
            errors={ errors }
            control={ control }
            nodes={ taskAssigned || [] }
            checked={ watch('taskAssigned') }
            tooltip='onboarding.form.partnerProfile.taskAssignedTooltip'
            required
            numeric
            className={ 'elite-form__full-row' }
          />
          )}
        </FormSection>
      </form>
    </FormProvider>
  );
};

PartnerProfile.propTypes = {
  handleSave: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(PartnerProfile);
