import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import * as api from '@app/store/companyWidget/api';
import {
  GET_TRAININGS_SUMMARY_START,
  GET_TRAININGS_SUMMARY_FAIL,
  GET_TRAININGS_SUMMARY_SUCCESS,
  GET_TASKS_SUMMARY_START,
  GET_TASKS_SUMMARY_FAIL,
  GET_TASKS_SUMMARY_SUCCESS,
  GET_PROFILE_SUMMARY_START,
  GET_PROFILE_SUMMARY_FAIL,
  GET_PROFILE_SUMMARY_SUCCESS,
  GET_EVENTS_SUMMARY_START,
  GET_EVENTS_SUMMARY_FAIL,
  GET_EVENTS_SUMMARY_SUCCESS,
  GET_LIBRARIES_SUMMARY_START,
  GET_LIBRARIES_SUMMARY_FAIL,
  GET_LIBRARIES_SUMMARY_SUCCESS,
  GET_OPPORTUNITIES_SUMMARY_START,
  GET_OPPORTUNITIES_SUMMARY_FAIL,
  GET_OPPORTUNITIES_SUMMARY_SUCCESS,
  SEND_EVENTS_PUBLICATION_REQUEST_START,
  SEND_EVENTS_PUBLICATION_REQUEST_FAIL,
  SEND_EVENTS_PUBLICATION_REQUEST_SUCCESS,
} from '@app/store/companyWidget/actions';

function* getTrainingsSummary(): Generator {
  try {
    const result = yield call(() => api.getTrainingsSummary());
    yield put({ type: GET_TRAININGS_SUMMARY_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_TRAININGS_SUMMARY_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getTasksSummary(): Generator {
  try {
    const result = yield call(() => api.getTasksSummary());
    yield put({ type: GET_TASKS_SUMMARY_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_TASKS_SUMMARY_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getProfileSummary(): Generator {
  try {
    const result = yield call(() => api.getProfileSummary());
    yield put({ type: GET_PROFILE_SUMMARY_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_PROFILE_SUMMARY_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getPartnerEventsSummary(): Generator {
  try {
    const result = yield call(() => api.getPartnerEventsSummary());
    yield put({ type: GET_EVENTS_SUMMARY_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_EVENTS_SUMMARY_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getPartnerLibrariesSummary(): Generator {
  try {
    const result = yield call(() => api.getPartnerLibrariesSummary());
    yield put({ type: GET_LIBRARIES_SUMMARY_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_LIBRARIES_SUMMARY_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getPartnerOpportunitySummary(): Generator {
  try {
    const result = yield call(() => api.getPartnerOpportunitySummary());
    yield put({ type: GET_OPPORTUNITIES_SUMMARY_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_OPPORTUNITIES_SUMMARY_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* sendEventsPublicationRequest(): Generator {
  try {
    yield call(() => api.sendEventsPublicationRequest());
    yield all([
      put({ type: SEND_EVENTS_PUBLICATION_REQUEST_SUCCESS }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: SEND_EVENTS_PUBLICATION_REQUEST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_TRAININGS_SUMMARY_START, getTrainingsSummary);
  yield takeLatest(GET_TASKS_SUMMARY_START, getTasksSummary);
  yield takeLatest(GET_PROFILE_SUMMARY_START, getProfileSummary);
  yield takeLatest(GET_EVENTS_SUMMARY_START, getPartnerEventsSummary);
  yield takeLatest(GET_LIBRARIES_SUMMARY_START, getPartnerLibrariesSummary);
  yield takeLatest(GET_OPPORTUNITIES_SUMMARY_START, getPartnerOpportunitySummary);
  yield takeLatest(SEND_EVENTS_PUBLICATION_REQUEST_START, sendEventsPublicationRequest);
}

export default root;
