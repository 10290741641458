import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectBusinessNeedsData } from '@src/js/marketplace/selectors/businessNeedTable';
import BusinessNeedBlock from '@src/js/marketplace/components/businessNeed/BusinessNeedBlock';
import { getAdminBusinessNeedRequest } from '@src/js/marketplace/actions/admin/businessNeedTable';

const BusinessNeedPopup = ({ id }) => {
  const dispatch = useDispatch();
  const selectBusinessNeeds = selectBusinessNeedsData();

  useEffect(() => {
    dispatch(getAdminBusinessNeedRequest(id));
  }, []);

  return (
    <BusinessNeedBlock
      businessNeeds={ selectBusinessNeeds }
      noBox
      isAdmin
      userId={ id }
    />
  );
};

BusinessNeedPopup.propTypes = {
  id: PropTypes.number,
};

export default BusinessNeedPopup;
