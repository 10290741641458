import { Language } from '@app/models/Language';
import useFetch from '@app/services/useFetch';
import { showToastrError } from '@app/store/global/actions';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Loader } from '@src/js/components/global';
import { Selectbox } from '@src/js/components/global/inputs';

type Option = {
  value: string,
  label: string,
  quote: string,
}

type Props = {
  outputLang: Language;
  onTranslate: (e: string) => void;
  options: Option[],
  buttonText?: string;
};

const TranslateButton = ({
  outputLang, onTranslate, options, buttonText,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedLang, setSelectedLang] = useState(options?.length > 0 ? options[0] : null);
  const { data, loading, request } = useFetch(
    'api/translate',
    {
      method: 'POST',
      body: {
        sourceLang: selectedLang?.value,
        targetLang: outputLang,
        quote: selectedLang?.quote,
        options: {
          tag_handling: 'html',
        },
      },
    },
    false,
    () => null,
    () => dispatch(showToastrError())
  );

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      onTranslate(data[0]);
    }
  }, [data, onTranslate]);

  return (
    <div className='translate-button'>
      <button
        onClick={ request }
        className='eop-btn relative'
        type='button'
      >
        { loading && <Loader position='absolute' /> }
        { buttonText || <FormattedMessage id='translateFrom' />}
      </button>
      <Selectbox
        onChange={ setSelectedLang }
        options={ options }
        initialValue={ options?.length > 0 ? options[0] : null }
        fieldNoClear
        simpleValue={ false }
        input={ undefined }
        fieldLabel={ undefined }
        placeholder={ undefined }
        fieldName={ undefined }
        additionalClass='translate-button-selector'
        fieldRequired={ undefined }
        fieldDisabled={ undefined }
        noResultsText={ undefined }
        multi={ undefined }
        loadOptions={ undefined }
        selectedValues={ undefined }
        meta={ undefined }
        searchable={ undefined }
        translate={ undefined }
        labelClass={ undefined }
      />
    </div>
  );
};

export default TranslateButton;
