const scope = 'MembershipForm';

const GET_ACTIVE_STEP = `${ scope }/GET_ACTIVE_STEP`;
const GET_FORM_STEP_REQUESTED = `${ scope }/GET_FORM_STEP_REQUESTED`;
const GET_FORM_STEP_SUCCEEDED = `${ scope }/GET_FORM_STEP_SUCCEEDED`;
const GET_FORM_STEP_FAILED = `${ scope }/GET_FORM_STEP_FAILED`;

const GET_MEMBERSHIP_COMPANY_REQUESTED = `${ scope }/GET_MEMBERSHIP_COMPANY_REQUESTED`;
const GET_MEMBERSHIP_COMPANY_SUCCEEDED = `${ scope }/GET_MEMBERSHIP_COMPANY_SUCCEEDED`;
const GET_MEMBERSHIP_COMPANY_FAILED = `${ scope }/GET_MEMBERSHIP_COMPANY_FAILED`;

const SAVE_MEMBERSHIP_PROCESS_REQUESTED = `${ scope }/SAVE_MEMBERSHIP_PROCESS_REQUESTED`;
const SAVE_MEMBERSHIP_PROCESS_SUCCEEDED = `${ scope }/SAVE_MEMBERSHIP_PROCESS_SUCCEEDED`;
const SAVE_MEMBERSHIP_PROCESS_FAILED = `${ scope }/SAVE_MEMBERSHIP_PROCESS_FAILED`;

const UPLOAD_FILE_REQUESTED = `${ scope }/UPLOAD_FILE_REQUESTED`;
const UPLOAD_FILE_SUCCEEDED = `${ scope }/UPLOAD_FILE_SUCCEEDED`;
const UPLOAD_FILE_FAILED = `${ scope }/UPLOAD_FILE_FAILED`;

const DOCUSIGN_MEMBERSHIP_START = `${ scope }/DOCUSIGN_MEMBERSHIP_START`;
const DOCUSIGN_MEMBERSHIP_SUCCESS = `${ scope }/DOCUSIGN_MEMBERSHIP_SUCCESS`;
const DOCUSIGN_MEMBERSHIP_FAIL = `${ scope }/DOCUSIGN_MEMBERSHIP_FAIL`;

const BLANK_CONTACT = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phone: '',
};

const SPLIT_PAYMENT_OPTIONS = [
  { label: 'membership.company.splitPayment.yes', value: true },
  { label: 'membership.company.splitPayment.no', value: false },
];

const STEPS = {
  BASIC_INFORMATION: 'basic',
  CONTACTS: 'contacts',
  FINAL: 'final',
};

export {
  GET_ACTIVE_STEP,
  STEPS,
  GET_FORM_STEP_REQUESTED,
  GET_FORM_STEP_SUCCEEDED,
  GET_FORM_STEP_FAILED,
  GET_MEMBERSHIP_COMPANY_REQUESTED,
  GET_MEMBERSHIP_COMPANY_SUCCEEDED,
  GET_MEMBERSHIP_COMPANY_FAILED,
  SAVE_MEMBERSHIP_PROCESS_REQUESTED,
  SAVE_MEMBERSHIP_PROCESS_SUCCEEDED,
  SAVE_MEMBERSHIP_PROCESS_FAILED,
  UPLOAD_FILE_REQUESTED,
  UPLOAD_FILE_SUCCEEDED,
  UPLOAD_FILE_FAILED,
  BLANK_CONTACT,
  SPLIT_PAYMENT_OPTIONS,
  DOCUSIGN_MEMBERSHIP_START,
  DOCUSIGN_MEMBERSHIP_SUCCESS,
  DOCUSIGN_MEMBERSHIP_FAIL,
};
