import produce from 'immer';

import {
  ADMIN_GET_LIST_PROCESSES_REQUESTED,
  ADMIN_GET_LIST_PROCESSES_SUCCEEDED,
  ADMIN_GET_LIST_PROCESSES_FAILED,
  ADMIN_GET_LIST_TEMPLATES_REQUESTED,
  ADMIN_GET_LIST_TEMPLATES_SUCCEEDED,
  ADMIN_GET_LIST_TEMPLATES_FAILED,
  GET_FORM_COMPANY_OPTIONS_REQUESTED,
  GET_FORM_COMPANY_OPTIONS_SUCCEEDED,
  GET_FORM_COMPANY_OPTIONS_FAILED,
  SUBMIT_MEMBERSHIP_FORM_REQUESTED,
  SUBMIT_MEMBERSHIP_FORM_SUCCEEDED,
  SUBMIT_MEMBERSHIP_FORM_FAILED,
  ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED,
  ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED,
  ADMIN_GET_TEMPLATE_OPTIONS_FAILED,
  ADMIN_APPROVE_PROCESS_REQUESTED,
  ADMIN_APPROVE_PROCESS_SUCCEEDED,
  ADMIN_APPROVE_PROCESS_FAILED,
  CREATE_TEMPLATE_CLEAR,
} from '@src/js/oneplatform/membership/pages/home/container/constants';

export const initialState = {
  processes: {
    loading: false,
    error: null,
    list: null,
    process: null,
    created: false,
    programmeFormOptions: {
      loading: false,
      error: false,
      list: null,
    },
  },
  approveProcess: {
    loading: false,
    error: null,
    created: false,
  },
  templates: {
    loading: false,
    error: null,
    list: null,
    templateOptions: null,
    statusLoading: false,
    statusError: null,
    statusChanged: null,
  },
  createTemplate: {
    loading: false,
    error: false,
    created: false,
  },
};

/* eslint-disable default-case,no-param-reassign */
function MembershipReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADMIN_GET_LIST_PROCESSES_REQUESTED:
        draft.processes = {
          ...state.processes,
          loading: true,
          error: null,
        };
        break;
      case ADMIN_GET_LIST_PROCESSES_SUCCEEDED:
        draft.processes = {
          ...state.processes,
          loading: false,
          error: null,
          list: action.payload,
        };
        break;
      case ADMIN_GET_LIST_PROCESSES_FAILED:
        draft.processes = {
          ...state.processes,
          loading: false,
          error: action.payload,
        };
        break;
      case ADMIN_GET_LIST_TEMPLATES_REQUESTED:
        draft.templates = {
          ...state.templates,
          loading: true,
          error: null,
        };
        break;
      case ADMIN_GET_LIST_TEMPLATES_SUCCEEDED:
        draft.templates = {
          ...state.templates,
          loading: false,
          error: null,
          list: action.payload,
        };
        break;
      case ADMIN_GET_LIST_TEMPLATES_FAILED:
        draft.templates = {
          ...state.templates,
          loading: false,
          error: action.payload,
        };
        break;
      case GET_FORM_COMPANY_OPTIONS_REQUESTED:
        draft.processes = {
          ...state.processes,
          programmeFormOptions: {
            loading: true,
            error: null,
          },
        };
        break;
      case GET_FORM_COMPANY_OPTIONS_SUCCEEDED:
        draft.processes = {
          ...state.processes,
          programmeFormOptions: {
            loading: false,
            error: null,
            list: action.payload,
          },
        };
        break;
      case GET_FORM_COMPANY_OPTIONS_FAILED:
        draft.processes = {
          ...state.processes,
          programmeFormOptions: {
            loading: false,
            error: action.payload,
          },
        };
        break;
      case SUBMIT_MEMBERSHIP_FORM_REQUESTED:
        draft.createTemplate = {
          loading: true,
          error: false,
          created: false,
        };
        break;
      case SUBMIT_MEMBERSHIP_FORM_SUCCEEDED:
        draft.createTemplate = {
          loading: false,
          error: false,
          created: true,
        };
        break;
      case SUBMIT_MEMBERSHIP_FORM_FAILED:
        draft.createTemplate = {
          loading: false,
          error: action.payload,
          created: false,
        };
        break;
      case CREATE_TEMPLATE_CLEAR:
        draft.createTemplate = initialState.createTemplate;
        break;
      case ADMIN_GET_TEMPLATE_OPTIONS_REQUESTED:
        draft.templates.loading = true;
        draft.templates.error = null;
        break;
      case ADMIN_GET_TEMPLATE_OPTIONS_SUCCEEDED:
        draft.templates.loading = false;
        draft.templates.templateOptions = action.payload.data;
        break;
      case ADMIN_GET_TEMPLATE_OPTIONS_FAILED:
        draft.templates.loading = false;
        draft.templates.error = action.payload;
        break;
      case ADMIN_APPROVE_PROCESS_REQUESTED:
        draft.approveProcess.loading = true;
        draft.approveProcess.error = null;
        draft.approveProcess.created = false;
        break;
      case ADMIN_APPROVE_PROCESS_SUCCEEDED:
        draft.approveProcess.loading = false;
        draft.approveProcess.created = true;
        break;
      case ADMIN_APPROVE_PROCESS_FAILED:
        draft.approveProcess.loading = false;
        draft.approveProcess.error = action.payload;
        break;
    }
  });
}
/* eslint-enable */

export default MembershipReducer;
