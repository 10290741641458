import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  getPromotedList as getList,
  GET_PROMOTED_LIST_START,
  GET_PROMOTED_LIST_FAIL,
  GET_PROMOTED_LIST_SUCCESS,
  GET_PROMOTED_OPTIONS_START,
  GET_PROMOTED_OPTIONS_FAIL,
  GET_PROMOTED_OPTIONS_SUCCESS,
  POST_PROMOTED_CONTENT_START,
  POST_PROMOTED_CONTENT_FAIL,
  POST_PROMOTED_CONTENT_SUCCESS,
  DELETE_PROMOTED_CONTENT_START,
  DELETE_PROMOTED_CONTENT_FAIL,
  DELETE_PROMOTED_CONTENT_SUCCESS,
  GET_PROMOTED_CONTENT_START,
  GET_PROMOTED_CONTENT_FAIL,
  GET_PROMOTED_CONTENT_SUCCESS,
} from '@app/store/content/promotedContent/actions';
import * as api from '@app/store/content/promotedContent/api';

function* getPromotedList() {
  try {
    const result = yield call(() => api.getPromotedList());
    yield put({ type: GET_PROMOTED_LIST_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_PROMOTED_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* deletePromotedContent({ id }) {
  try {
    yield call(() => api.deletePromotedContent(id));
    yield put({ type: DELETE_PROMOTED_CONTENT_SUCCESS, id });
  } catch (error) {
    yield all([
      put({ type: DELETE_PROMOTED_CONTENT_FAIL, id }),
      put(showToastrError()),
    ]);
  }
}

function* postPromotedContent({ data }) {
  try {
    yield call(() => api.postPromotedContent(data));
    yield all([
      put({ type: POST_PROMOTED_CONTENT_SUCCESS }),
      put(showToastrSuccess()),
      put(getList()),
    ]);
  } catch (error) {
    yield all([
      put({ type: POST_PROMOTED_CONTENT_FAIL }),
      put(showToastrError('notification.error_title',
        error?.response?.description || 'notification.submit.error')),
    ]);
  }
}

function* getPromotedOptions() {
  try {
    const result = yield call(() => api.getPromotedOptions());
    yield put({ type: GET_PROMOTED_OPTIONS_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_PROMOTED_OPTIONS_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* getPromotedContent() {
  try {
    const result = yield call(() => api.getPromotedContent());
    yield put({ type: GET_PROMOTED_CONTENT_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_PROMOTED_CONTENT_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_PROMOTED_LIST_START, getPromotedList);
  yield takeLatest(DELETE_PROMOTED_CONTENT_START, deletePromotedContent);
  yield takeLatest(POST_PROMOTED_CONTENT_START, postPromotedContent);
  yield takeLatest(GET_PROMOTED_OPTIONS_START, getPromotedOptions);
  yield takeLatest(GET_PROMOTED_CONTENT_START, getPromotedContent);
}

export default root;
