export const PARTNER_DOCUMENTS_GET_FILES_START = 'PARTNER_DOCUMENTS_GET_FILES_START';
export const PARTNER_DOCUMENTS_GET_FILES_ERROR = 'PARTNER_DOCUMENTS_GET_FILES_ERROR';
export const PARTNER_DOCUMENTS_GET_FILES_SUCCESS = 'PARTNER_DOCUMENTS_GET_FILES_SUCCESS';

export function partnerDocumentsGetFiles(slug, offset) {
  return {
    type: PARTNER_DOCUMENTS_GET_FILES_START,
    slug,
    offset,
  };
}
