export const GET_NETWORKING_START = 'GET_NETWORKING_START';
export const GET_NETWORKING_ERROR = 'GET_NETWORKING_ERROR';
export const GET_NETWORKING_SUCCESS = 'GET_NETWORKING_SUCCESS';

export function getNetworking(basicData) {
  return {
    type: GET_NETWORKING_START,
    basicData,
  };
}
