import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_GET_LIBRARY_OPTIONS_START,
  PARTNER_GET_LIBRARY_OPTIONS_ERROR,
  PARTNER_GET_LIBRARY_OPTIONS_SUCCESS,
} from '@src/js/actions/library/partnerGetLibraryOptions';

import * as api from '@src/js/api/library/partnerGetLibraryOptions';

function* partnerGetLibraryOptions(options) {
  try {
    const data = yield call(() => api.partnerGetLibraryOptions(options.slug));
    yield put({ type: PARTNER_GET_LIBRARY_OPTIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: PARTNER_GET_LIBRARY_OPTIONS_ERROR, error });
  }
}

function* partnerGetLibraryOptionsWatcher() {
  yield takeLatest(PARTNER_GET_LIBRARY_OPTIONS_START, partnerGetLibraryOptions);
}

export default [
  partnerGetLibraryOptionsWatcher(),
];
