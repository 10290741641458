import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormSection, InputSelect, InputText,
} from '@app/components/global/forms';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from '@src/js/components/global';
import { YES_OR_NO } from '@app/components/public/compliance/constants';
import {
  selectOnboardingSave, selectOnboardingData, selectCurrentStep, selectFirstVisit,
} from '@app/store/onboarding/selectors';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';

const Contacts = ({ handleSave, partnerDetails }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const {
    getValues, formState: { errors }, register, control, watch, trigger,
  } = useForm({
    defaultValues: onboardingData,
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });
  const invoicingFields = useFieldArray({
    control,
    name: 'invoicingContacts',
  });
  const defaultContact = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    platformAccount: false,
    phone: '',
  };
  const defaultInvoicingContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultContact);
    }
    if (invoicingFields.fields.length === 0) {
      invoicingFields.append(defaultInvoicingContact);
    }
  }, [append, invoicingFields.append]);

  useEffect(() => {
    if (onSave?.action) {
      handleSave(getValues());
    }
  }, [onSave]);

  return (
    <>
      <FormSection
        sectionTitle='onboarding.progressbar.contacts'
        titleClass='d-flex gap-8 align-items-center'
      >
        <EmailSupport />
        {fields.map((item, index) => (
          <Fragment key={ item.id }>
            { index !== 0 && (
              <div className='w-100 d-flex justify-content-end m-t-3 m-b-1'>
                <div role='presentation' className='compliance__button-close' onClick={ () => remove(index) }>
                  <span className='icon-medium-Close' />
                </div>
              </div>
            ) }
            <>
              <InputText
                name={ `contacts[${ index }].firstName` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.firstName'
                defaultValue={ item.firstName }
                required
              />
              <InputText
                name={ `contacts[${ index }].lastName` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.lastName'
                defaultValue={ item.lastName }
                required
              />
              <InputText
                name={ `contacts[${ index }].jobTitle` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.jobTitle'
                defaultValue={ item.jobTitle }
                required
              />
              <InputText
                name={ `contacts[${ index }].email` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.email'
                defaultValue={ item.email }
                required={ watch(`contacts[${ index }].platformAccount`) }
                type='textEmail'
              />
              <InputText
                name={ `contacts[${ index }].phone` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.phone'
                defaultValue={ item.phone }
                required={ watch(`contacts[${ index }].platformAccount`) }
                type='tel'
              />
              <InputSelect
                name={ `contacts[${ index }].platformAccount` }
                errors={ errors }
                control={ control }
                label='onboarding.directors.platformAccount'
                options={ YES_OR_NO }
                defaultValue={ item.platformAccount }
                fieldNoClear
                boolRequired
              />
            </>
          </Fragment>

        ))}
        {!partnerDetails && (
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => append(defaultContact) }
          />
        </div>
        )}
      </FormSection>
      <FormSection
        sectionTitle='onboarding.invoicing.invoicing'
        titleClass='d-flex gap-8 align-items-center'
      >
        {invoicingFields.fields.map((item, index) => (
          <Fragment key={ item.id }>
            { index !== 0 && (
              <div className='w-100 d-flex justify-content-end m-t-3 m-b-1'>
                <div
                  role='presentation'
                  className='compliance__button-close'
                  onClick={ () => invoicingFields.remove(index) }
                >
                  <span className='icon-medium-Close' />
                </div>
              </div>
            ) }

            <>
              <InputText
                name={ `invoicingContacts[${ index }].firstName` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.firstName'
                defaultValue={ item.firstName }
                required
              />
              <InputText
                name={ `invoicingContacts[${ index }].lastName` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.lastName'
                defaultValue={ item.lastName }
                required
              />
              <InputText
                name={ `invoicingContacts[${ index }].email` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.email'
                defaultValue={ item.email }
                required
                type='textEmail'
              />
              <InputText
                name={ `invoicingContacts[${ index }].phone` }
                errors={ errors }
                register={ register }
                label='onboarding.directors.phone'
                defaultValue={ item.phone }
                type='tel'
                required
              />
            </>
          </Fragment>
        ))}
        {!partnerDetails && (
        <div className='w-100'>
          <Button
            buttonText='company_form.addNew'
            onClickFunc={ () => invoicingFields.append(defaultContact) }
          />
        </div>
        )}
      </FormSection>
    </>
  );
};

Contacts.propTypes = {
  handleSave: PropTypes.func,
  partnerDetails: PropTypes.bool,
};

export default Contacts;
