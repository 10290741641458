import { takeLatest, call, put } from 'redux-saga/effects';

import {
  COMPANY_DOCUMENTS_UPLOAD_START,
  COMPANY_DOCUMENTS_UPLOAD_ERROR,
  COMPANY_DOCUMENTS_UPLOAD_SUCCESS,
} from '@src/js/actions/company/companyDocumentsUpload';

import * as api from '@src/js/api/company/companyDocumentsUpload';

function* uploadCompanyDocumentsFiles(options) {
  try {
    const data = yield call(() => api.companyDocumentsUpload(options.files, options.slug));
    yield put({ type: COMPANY_DOCUMENTS_UPLOAD_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: COMPANY_DOCUMENTS_UPLOAD_ERROR, error });
  }
}

function* uploadCompanyDocumentsFileWatcher() {
  yield takeLatest(COMPANY_DOCUMENTS_UPLOAD_START, uploadCompanyDocumentsFiles);
}

export default [
  uploadCompanyDocumentsFileWatcher(),
];
