export const SET_SELECTED_ADMIN_TAB_START = 'SET_SELECTED_ADMIN_TAB_START';
export const SET_SELECTED_ADMIN_TAB_SUCCESS = 'SET_SELECTED_ADMIN_TAB_SUCCESS';

export const SET_PRODUCTS_FILTERS = 'SET_PRODUCTS_FILTERS';
export const SET_PRODUCTS_FILTERS_SUCCESS = 'SET_PRODUCTS_FILTERS_SUCCESS';
export const SET_REQUESTS_FILTERS = 'SET_REQUESTS_FILTERS';
export const SET_REQUESTS_FILTERS_SUCCESS = 'SET_REQUESTS_FILTERS_SUCCESS';

export const GET_ADMIN_OPPORTUNITIES_START = 'GET_ADMIN_OPPORTUNITIES_START';
export const GET_ADMIN_OPPORTUNITIES_ERROR = 'GET_ADMIN_OPPORTUNITIES_ERROR';
export const GET_ADMIN_OPPORTUNITIES_SUCCESS = 'GET_ADMIN_OPPORTUNITIES_SUCCESS';

export const GET_ADMIN_REQUESTS_START = 'GET_ADMIN_REQUESTS_START';
export const GET_ADMIN_REQUESTS_ERROR = 'GET_ADMIN_REQUESTS_ERROR';
export const GET_ADMIN_REQUESTS_SUCCESS = 'GET_ADMIN_REQUESTS_SUCCESS';

export const GET_ADMIN_TYPES_START = 'GET_ADMIN_TYPES_START';
export const GET_ADMIN_TYPES_ERROR = 'GET_ADMIN_TYPES_ERROR';
export const GET_ADMIN_TYPES_SUCCESS = 'GET_ADMIN_TYPES_SUCCESS';

export const GET_ADMIN_STATISTICS_START = 'GET_ADMIN_STATISTICS_START';
export const GET_ADMIN_STATISTICS_ERROR = 'GET_ADMIN_STATISTICS_ERROR';
export const GET_ADMIN_STATISTICS_SUCCESS = 'GET_ADMIN_STATISTICS_SUCCESS';

export const ADMIN_PRODUCT_STATUS_UPDATE_START = 'ADMIN_PRODUCT_STATUS_UPDATE_START';
export const ADMIN_PRODUCT_STATUS_UPDATE_ERROR = 'ADMIN_PRODUCT_STATUS_UPDATE_ERROR';
export const ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS = 'ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS';

export const ADMIN_REQUEST_STATUS_UPDATE_START = 'ADMIN_REQUEST_STATUS_UPDATE_START';
export const ADMIN_REQUEST_STATUS_UPDATE_ERROR = 'ADMIN_REQUEST_STATUS_UPDATE_ERROR';
export const ADMIN_REQUEST_STATUS_UPDATE_SUCCESS = 'ADMIN_REQUEST_STATUS_UPDATE_SUCCESS';

export function setSelectedAdminTab(index) {
  return {
    type: SET_SELECTED_ADMIN_TAB_START,
    index,
  };
}

export function setProductsFilters(filters) {
  return {
    type: SET_PRODUCTS_FILTERS,
    filters,
  };
}

export function setRequestsFilters(filters) {
  return {
    type: SET_REQUESTS_FILTERS,
    filters,
  };
}

export function getAdminOpportunities(filters) {
  return {
    type: GET_ADMIN_OPPORTUNITIES_START,
    filters,
  };
}

export function getAdminRequests(filters) {
  return {
    type: GET_ADMIN_REQUESTS_START,
    filters,
  };
}

export function getAdminTypes(filters) {
  return {
    type: GET_ADMIN_TYPES_START,
    filters,
  };
}

export function getAdminStatistics() {
  return {
    type: GET_ADMIN_STATISTICS_START,
  };
}

export function adminProductStatusUpdate(options) {
  return {
    type: ADMIN_PRODUCT_STATUS_UPDATE_START,
    options,
  };
}

export function adminRequestStatusUpdate(options) {
  return {
    type: ADMIN_REQUEST_STATUS_UPDATE_START,
    options,
  };
}
