import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@src/js/components/global/buttons/Buttons';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSessions } from '@src/js/actions/session/getAllSessions';
import { deleteAllSessions, deleteSingleSession } from '@src/js/actions/session/deleteSessions';
import { RootState } from '@src/js/store';

type Session = {
  id: string,
  device: string,
  isCurrentSession: boolean,
  isAppSession: boolean,
}

type SessionState = {
  loading: boolean,
  sessions: Session[],
}

const UserSessions = () => {
  const dispatch = useDispatch();
  const { loading, sessions } = useSelector<RootState, SessionState>(state => state.allSessions);
  const { singleSessionLoading, allSessionsLoading } = useSelector<RootState, any>(state => state.deleteSessions);

  useEffect(() => {
    dispatch(getAllSessions());
  }, [dispatch]);

  return (
    <>
      {!loading
    && !singleSessionLoading
    && !allSessionsLoading
    && (
      <>
        { sessions
        && sessions.map(session => (
          <div className='preferences' key={ session.id }>
            <div className='preferences__item'>
              <div className='preferences__item--left'>
                <div className='preferences__item--big'>
                  <FormattedMessage id='session.currently-active' />
                </div>
                <div className='preferences__item--regular'>
                  { session.device }
                </div>
              </div>
              <div className='btn-delete-session'>
                <div>
                  { !session.isCurrentSession && (
                    <Button
                      buttonText={ 'session.disconnect' }
                      onClickFunc={ () => dispatch(deleteSingleSession(session)) }
                    />
                  ) }
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className='btn-delete-all-sessions'>
          <Button
            customClass='eop-btn__transparent'
            buttonText={ 'session.disconnect.all' }
            iconPositionLeft={ true }
            icon='icon icon-medium-Logout'
            onClickFunc={ () => dispatch(deleteAllSessions()) }
          />
        </div>
      </>
    )}
      { (loading || singleSessionLoading || allSessionsLoading) && <Loader /> }
    </>
  );
};

export default UserSessions;
