import produce from 'immer';
import {
  TrainingsResponse, TasksResponse, ProfileCompletionResponse, CountResponse, OpportunityResponse,
} from '@app/models/CompanyWidgetPanels';

import {
  GET_TRAININGS_SUMMARY_START,
  GET_TRAININGS_SUMMARY_FAIL,
  GET_TRAININGS_SUMMARY_SUCCESS,
  GET_TASKS_SUMMARY_START,
  GET_TASKS_SUMMARY_FAIL,
  GET_TASKS_SUMMARY_SUCCESS,
  GET_PROFILE_SUMMARY_START,
  GET_PROFILE_SUMMARY_FAIL,
  GET_PROFILE_SUMMARY_SUCCESS,
  GET_EVENTS_SUMMARY_START,
  GET_EVENTS_SUMMARY_FAIL,
  GET_EVENTS_SUMMARY_SUCCESS,
  GET_LIBRARIES_SUMMARY_START,
  GET_LIBRARIES_SUMMARY_FAIL,
  GET_LIBRARIES_SUMMARY_SUCCESS,
  GET_OPPORTUNITIES_SUMMARY_START,
  GET_OPPORTUNITIES_SUMMARY_FAIL,
  GET_OPPORTUNITIES_SUMMARY_SUCCESS,
  SEND_EVENTS_PUBLICATION_REQUEST_START,
  SEND_EVENTS_PUBLICATION_REQUEST_FAIL,
  SEND_EVENTS_PUBLICATION_REQUEST_SUCCESS,
} from '@app/store/companyWidget/actions';
import { Action } from 'redux';
import { ResponseError } from '@app/models/Error';

export type PanelState = {
  trainingsData: TrainingsResponse|null,
  trainingsError: unknown,
  trainingsLoading: boolean,
  tasksData: TasksResponse|null,
  tasksError: unknown,
  tasksLoading: boolean,
  profileData: ProfileCompletionResponse|null,
  profileError: unknown,
  profileLoading: boolean,
  eventsData: CountResponse|null,
  eventsError: unknown,
  eventsLoading: boolean,
  libraryData: CountResponse|null,
  libraryError: unknown,
  libraryLoading: boolean,
  opportunityData: OpportunityResponse|null,
  opportunityError: unknown,
  opportunityLoading: boolean,
}

interface PanelAction extends Action<string> {
  slug: string,
  error: ResponseError,
  result: TrainingsResponse|TasksResponse|ProfileCompletionResponse,
  id: string,
  status: string,
}

export const initialState: PanelState = {
  trainingsData: null,
  trainingsError: null,
  trainingsLoading: false,
  tasksData: null,
  tasksError: null,
  tasksLoading: false,
  profileData: null,
  profileError: null,
  profileLoading: false,
  eventsData: null,
  eventsError: null,
  eventsLoading: false,
  libraryData: null,
  libraryError: null,
  libraryLoading: false,
  opportunityData: null,
  opportunityError: null,
  opportunityLoading: false,
};

function isTrainingResp(resp: any): resp is TrainingsResponse {
  return resp.registered !== undefined;
}

function isCountResp(resp: any): resp is CountResponse {
  return resp.count !== undefined;
}

function isTaskResp(resp: any): resp is TasksResponse {
  return resp.open !== undefined;
}

function isProfileResp(resp: any): resp is ProfileCompletionResponse {
  return resp.en !== undefined;
}

function isOpportunityResp(resp: any): resp is OpportunityResponse {
  return resp.published !== undefined && resp.pending !== undefined && resp.views !== undefined;
}

function companyWidgetReducer(state = initialState, action: PanelAction) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_TRAININGS_SUMMARY_START:
        draft.trainingsData = null;
        draft.trainingsError = null;
        draft.trainingsLoading = true;
        break;
      case GET_TRAININGS_SUMMARY_FAIL:
        draft.trainingsData = null;
        draft.trainingsError = action.error;
        draft.trainingsLoading = false;
        break;
      case GET_TRAININGS_SUMMARY_SUCCESS:
        draft.trainingsLoading = false;
        if (!isTrainingResp(action.result)) break;
        draft.trainingsData = action.result;
        draft.trainingsError = null;
        break;
      case GET_TASKS_SUMMARY_START:
        draft.tasksData = null;
        draft.tasksError = null;
        draft.tasksLoading = true;
        break;
      case GET_TASKS_SUMMARY_FAIL:
        draft.tasksData = null;
        draft.tasksError = action.error;
        draft.tasksLoading = false;
        break;
      case GET_TASKS_SUMMARY_SUCCESS:
        draft.tasksLoading = false;
        if (!isTaskResp(action.result)) break;
        draft.tasksData = action.result;
        draft.tasksError = null;
        break;
      case GET_PROFILE_SUMMARY_START:
        draft.profileData = null;
        draft.profileError = null;
        draft.profileLoading = true;
        break;
      case GET_PROFILE_SUMMARY_FAIL:
        draft.profileData = null;
        draft.profileError = action.error;
        draft.profileLoading = false;
        break;
      case GET_PROFILE_SUMMARY_SUCCESS:
        draft.profileLoading = false;
        if (!isProfileResp(action.result)) break;
        draft.profileData = action.result;
        draft.profileError = null;
        break;
      case GET_EVENTS_SUMMARY_START:
        draft.eventsData = null;
        draft.eventsError = null;
        draft.eventsLoading = true;
        break;
      case GET_EVENTS_SUMMARY_FAIL:
        draft.eventsData = null;
        draft.eventsError = action.error;
        draft.eventsLoading = false;
        break;
      case GET_EVENTS_SUMMARY_SUCCESS:
        draft.eventsLoading = false;
        if (!isCountResp(action.result)) break;
        draft.eventsData = action.result;
        draft.eventsError = null;
        break;
      case SEND_EVENTS_PUBLICATION_REQUEST_START:
        draft.eventsError = null;
        draft.eventsLoading = true;
        break;
      case SEND_EVENTS_PUBLICATION_REQUEST_FAIL:
        draft.eventsError = action.error;
        draft.eventsLoading = false;
        break;
      case SEND_EVENTS_PUBLICATION_REQUEST_SUCCESS:
        draft.eventsError = null;
        break;
      case GET_LIBRARIES_SUMMARY_START:
        draft.libraryData = null;
        draft.libraryError = null;
        draft.libraryLoading = true;
        break;
      case GET_LIBRARIES_SUMMARY_FAIL:
        draft.libraryData = null;
        draft.libraryError = action.error;
        draft.libraryLoading = false;
        break;
      case GET_LIBRARIES_SUMMARY_SUCCESS:
        draft.libraryLoading = false;
        if (!isCountResp(action.result)) break;
        draft.libraryData = action.result;
        draft.libraryError = null;
        break;
      case GET_OPPORTUNITIES_SUMMARY_START:
        draft.opportunityData = null;
        draft.opportunityError = null;
        draft.opportunityLoading = true;
        break;
      case GET_OPPORTUNITIES_SUMMARY_FAIL:
        draft.opportunityData = null;
        draft.opportunityError = action.error;
        draft.opportunityLoading = false;
        break;
      case GET_OPPORTUNITIES_SUMMARY_SUCCESS:
        draft.opportunityLoading = false;
        if (!isOpportunityResp(action.result)) break;
        draft.opportunityData = action.result;
        draft.opportunityError = null;
        break;
      default:
        break;
    }
  }
  );
}

export default companyWidgetReducer;
