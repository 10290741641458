import fetchResource from '@src/js/api/fetch-resource';

export function getLibraryPathList({
  search, page, statuses, languages,
}) {
  const params = new URLSearchParams({
    page, search, statuses, languages,
  });
  return fetchResource(`api/library-path?${ params }`, {
    method: 'GET',
  });
}

export function getLibraryPath({ id }) {
  return fetchResource(`api/library-path/${ id }`, {
    method: 'GET',
  });
}

export function updateLibraryPath(id, data) {
  return fetchResource(`api/library-path/${ id }`, {
    method: 'PUT',
    body: { ...data },
  });
}

export function createLibraryPath(data) {
  return fetchResource('api/library-path', {
    method: 'POST',
    body: { ...data },
  });
}

export function updateLibraryPathStatus({ id, status }) {
  return fetchResource(`api/library-path/${ id }`, {
    method: 'PATCH',
    body: { status },
  });
}

export function getVideoOnDemand() {
  return fetchResource('api/video-on-demand', {
    method: 'GET',
  });
}
