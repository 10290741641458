import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InputSelect, InputText, InputCreatableSelect } from '@app/components/global/forms';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { Event, YES_OR_NO } from '@src/js/constants/entities';

const EventSubscribeParticipantAccordion = ({
  intl, item, setAccordionIndex, accordionIndex, index,
  membersOptions, location, companyMembers, defaultMember, methods,
  canRegisterPhysical, isDinnerChecked, isSpeakerChecked,
  canRegisterDinner, canRegisterSpeaker,
}) => {
  const {
    watch, formState: { errors }, register, control, setValue, getValues,
  } = methods;
  const userInfo = selectUserInfo();
  const locationOptions = [
    { value: 1, label: intl.formatMessage({ id: 'subscribeModal.digital' }) },
  ];

  if (canRegisterPhysical || watch(`participant[${ index }].location`) === Event.LOCATION_PHYSICAL) {
    locationOptions.push({ value: 2, label: intl.formatMessage({ id: 'subscribeModal.physical' }) });
  }

  const setParticipant = (user) => {
    if (user) {
      const selectedUser = companyMembers.find(member => member.uuid === user.value);
      if (selectedUser) {
        const loc = getValues(`participant[${ index }].location`);
        const newUser = {
          'name': selectedUser.fullName,
          'email': selectedUser.email,
          'jobPosition': selectedUser.jobPosition,
          'uuid': user.uuid,
          'userSelect': user.value,
          'location': location !== Event.LOCATION_MIXED ? location : loc || null,
          'dietaryRequirements': null,
          'companyName': userInfo?.companyName,
        };
        if (isDinnerChecked) {
          newUser.dinner = getValues(`participant[${ index }].dinner`);
          newUser.speaker = getValues(`participant[${ index }].speaker`);
        }
        setValue(`participant[${ index }]`, newUser, { shouldValidate: true });
      } else {
        setValue(`participant[${ index }].userSelect`, user, { shouldValidate: true });
        setValue(`participant[${ index }].name`, user.value, { shouldValidate: true });
        setValue(`participant[${ index }].uuid`, null, { shouldValidate: true });
      }
    } else {
      const resettedValues = defaultMember;
      resettedValues.dinner = getValues(`participant[${ index }].dinner`);
      resettedValues.speaker = getValues(`participant[${ index }].speaker`);
      setValue(`participant[${ index }]`, defaultMember);
    }
  };

  const dinnerWatcher = watch(`participant[${ index }].dinner`);
  const speakerWatcher = watch(`participant[${ index }].speaker`);

  useEffect(() => {
    if (dinnerWatcher === null && !canRegisterDinner) {
      setValue(`participant[${ index }].dinner`, false, { shouldValidate: true });
    }
  }, [canRegisterDinner]);
  useEffect(() => {
    if (speakerWatcher === null && !canRegisterSpeaker) {
      setValue(`participant[${ index }].speaker`, false, { shouldValidate: true });
    }
  }, [canRegisterSpeaker]);
  useEffect(() => {
    if (dinnerWatcher === false) {
      setValue(`participant[${ index }].speaker`, false, { shouldValidate: true });
    }
  }, [dinnerWatcher]);

  const isUserSelected = () => {
    const userSelect = watch(`participant[${ index }].userSelect`);
    return userSelect && typeof userSelect === 'string';
  };

  return (
    <div key={ item.id } className='elite-form__full-row'>
      <div
        className='headline'
        role='presentation'
        onClick={ () => setAccordionIndex(accordionIndex !== index ? index : undefined) }
      >
        { accordionIndex !== index && <span className='icon-ArrowDownSmall headline__icon' /> }
        { accordionIndex === index && <span className='icon-ArrowUpSmall headline__icon' /> }
        <p className={ `headline__title ${ errors?.participant?.[index] ? 'error' : '' }` }>
          <FormattedMessage id='subscribeModal.member' values={ { memberKey: index + 1 } } />
          { `: ${ watch(`participant[${ index }].name`) || '' }` }
        </p>
      </div>
      <div className={ accordionIndex !== index ? 'd-none' : '' }>
        <div className='w-100 elite-form__section m-t-1'>
          <div className='d-none'>
            <InputText
              name={ `participant[${ index }].name` }
              errors={ errors }
              register={ register }
              defaultValue={ item.name }
            />
            <InputSelect
              name={ `participant[${ index }].uuid` }
              errors={ errors }
              control={ control }
              options={ membersOptions }
              defaultValue={ item.uuid }
            />
          </div>
          <InputCreatableSelect
            name={ `participant[${ index }].userSelect` }
            errors={ errors }
            control={ control }
            label='subscribeModal.name'
            tooltip='subscribeModal.namePlaceholder'
            options={ membersOptions }
            onChange={ (user) => setParticipant(user) }
            required
          />
          <InputText
            name={ `participant[${ index }].companyName` }
            errors={ errors }
            register={ register }
            label='subscribeModal.company'
            defaultValue={ item.companyName }
            disabled
          />
          <InputText
            name={ `participant[${ index }].email` }
            errors={ errors }
            register={ register }
            label='subscribeModal.email'
            defaultValue={ item.email }
            required
            type='email'
            readonly={ isUserSelected() }
          />
          <InputText
            name={ `participant[${ index }].jobPosition` }
            errors={ errors }
            register={ register }
            label='subscribeModal.role'
            defaultValue={ item.jobPosition }
            required
            readonly={ isUserSelected() }
          />
          <InputSelect
            name={ `participant[${ index }].location` }
            errors={ errors }
            control={ control }
            label='subscribeModal.location'
            options={ locationOptions }
            defaultValue={ item.location }
            className={ location !== Event.LOCATION_MIXED ? 'd-none' : '' }
            required={ location === Event.LOCATION_MIXED }
            fieldNoClear
          />
          { isDinnerChecked && (
            <>
              <InputSelect
                name={ `participant[${ index }].dinner` }
                label='subscribeModal.dinner'
                errors={ errors }
                control={ control }
                options={ YES_OR_NO }
                disabled={ !canRegisterDinner && !dinnerWatcher }
                boolRequired
                fieldNoClear
              />
              { isSpeakerChecked && (
                <InputSelect
                  name={ `participant[${ index }].speaker` }
                  label='subscribeModal.dinner.speaker'
                  errors={ errors }
                  control={ control }
                  options={ YES_OR_NO }
                  disabled={ (!canRegisterSpeaker && !speakerWatcher) || dinnerWatcher === false }
                  boolRequired
                  fieldNoClear
                />
              ) }
            </>
          )}
          {
            (watch(`participant[${ index }].location`) === Event.LOCATION_PHYSICAL
            || watch(`participant[${ index }].dinner`)) && (
              <InputText
                name={ `participant[${ index }].dietaryRequirements` }
                errors={ errors }
                register={ register }
                label='subscribeModal.dietaryRequirements'
                defaultValue={ item.dietaryRequirements }
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

EventSubscribeParticipantAccordion.propTypes = {
  intl: PropTypes.object,
  item: PropTypes.object,
  setAccordionIndex: PropTypes.func,
  accordionIndex: PropTypes.number,
  index: PropTypes.number,
  methods: PropTypes.object,
  membersOptions: PropTypes.array,
  location: PropTypes.number,
  companyMembers: PropTypes.array,
  defaultMember: PropTypes.object,
  canRegisterPhysical: PropTypes.bool,
  isDinnerChecked: PropTypes.bool,
  isSpeakerChecked: PropTypes.bool,
  canRegisterDinner: PropTypes.bool,
  canRegisterSpeaker: PropTypes.bool,
};

export default injectIntl(EventSubscribeParticipantAccordion);
