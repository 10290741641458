export const COMPANY_DOCUMENTS_PERMISSIONS_START = 'COMPANY_DOCUMENTS_PERMISSIONS_START';
export const COMPANY_DOCUMENTS_PERMISSIONS_ERROR = 'COMPANY_DOCUMENTS_PERMISSIONS_ERROR';
export const COMPANY_DOCUMENTS_PERMISSIONS_SUCCESS = 'COMPANY_DOCUMENTS_PERMISSIONS_SUCCESS';

export function companyDocumentsPermissions(slug) {
  return {
    type: COMPANY_DOCUMENTS_PERMISSIONS_START,
    slug,
  };
}
