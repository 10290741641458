import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { InputTextArea } from '@app/components/global/forms';
import {
  selectMessageDataLoading,
  selectRequestInfoLoading,
} from '@app/store/global/selectors/message';
import { contactMessageSend } from '@src/js/actions/company/companyContactMessageSend';
import { Loader } from '@src/js/components/global';
import { sendRequestInfo } from '@src/js/marketplace/actions/modal/requestInfoModal';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ModalBody } from 'reactstrap';

const ContactModal = ({
  entity, slug, opportunity,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    register, formState: { errors }, handleSubmit,
  } = useForm({ mode: 'all' });

  const { hideModal } = useGlobalModalContext();

  const messageDataLoading = opportunity ? selectRequestInfoLoading() : selectMessageDataLoading();

  const onSubmit = data => {
    if (data) {
      if (opportunity) {
        const requestInfoData = {
          productId: opportunity.Id,
          Message: data.messageContent,
        };
        dispatch(sendRequestInfo({
          requestInfoData, opportunity,
        }));
      } else {
        const body = {
          contactMessageData: data,
          entityType: entity,
          companySlug: slug,
        };
        dispatch(contactMessageSend(body));
      }
      hideModal();
    }
    return false;
  };

  return (
    <ModalBody>
      <form onSubmit={ handleSubmit(onSubmit) } className='contact-modal'>
        <InputTextArea
          name='messageContent'
          errors={ errors }
          register={ register }
          label='company_form.modal.messageContent'
          placeholder='company_form.modal.message'
          className='contact-modal__textarea eop-text-textarea'
          maxLength={ 256 }
          rows={ 1 }
          required
        />
        { messageDataLoading && <Loader /> }
        <input type='submit' value={ intl.formatMessage({ id: 'send' }) } className='eop-btn m-t-1 m-b-3' />
      </form>
    </ModalBody>
  );
};

ContactModal.propTypes = {
  entity: PropTypes.string,
  slug: PropTypes.string,
  opportunity: PropTypes.object,
};
export default ContactModal;
