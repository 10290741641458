import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { getCompanyWidget } from '@src/js/actions/widget';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { Icon } from '@src/_v2/js/components/Icons';
import { ButtonLink } from '@src/_v2/js/components/buttons';
import NetworkingCard from '@app/components/global/cards/NetworkingCard';
import {
  Container, CompanyData, CompanyMembers, ShowMoreContainer, Logo, CompanyDataContent, Sector, CompanyName,
  CompanyMemberRow, ShowMoreButton, ShowMoreButtonInner, CompanyBottomHolder, ProgrammePartnerContent,
} from './styled';
import ProgrammePartner from './components/ProgrammePartner/ProgrammePartner';

class CompanyWidget extends Component {
  static propTypes = {
    error: PropTypes.string,
    getCompanyWidgetAction: PropTypes.func,
    companyWidget: PropTypes.object,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMoreMembers: false,
    };
  }

  componentDidMount() {
    const { getCompanyWidgetAction } = this.props;

    getCompanyWidgetAction();
  }

  toggleShowMore = () => {
    const { showMoreMembers } = this.state;

    this.setState({
      showMoreMembers: !showMoreMembers,
    });
  };

  getRouteToEntity = (isEdit = false, isSingle = false) => {
    const { companyWidget } = this.props;

    let route = companyWidget.type === 'company'
      ? withLang(isSingle ? routeCodes.MY_COMPANY : `${ routeCodes.COMPANIES }/${ companyWidget.slug }`)
      : withLang(`${ routeCodes.PARTNERS }/${ companyWidget.slug }`);

    if (isEdit) {
      route = `${ route }/edit`;
    }

    return route;
  };

  render() {
    const { showMoreMembers } = this.state;
    const {
      error,
      companyWidget,
      intl,
    } = this.props;

    return (
      <DashboardWidget>
        { error && <FormattedMessage id={ error.toString() } /> }
        { companyWidget && (
          <Container>
            <CompanyData>
              <Logo>
                <NavLink exact to={ this.getRouteToEntity(false, true) }>
                  <img src={ companyWidget.logo } alt={ companyWidget.name } />
                </NavLink>
              </Logo>
              <CompanyDataContent>
                <Sector>
                  { companyWidget?.sectors?.length > 0 && `${ companyWidget.sectors
                    .map(sector => intl.formatMessage({ id: sector }))
                    .slice(0, 4).join(', ') }${ companyWidget?.sectors?.length > 4 ? '...' : '' }`
                  }
                </Sector>
                <NavLink exact to={ this.getRouteToEntity(false, true) }>
                  <CompanyName>{ companyWidget.name }</CompanyName>
                </NavLink>
              </CompanyDataContent>
              <ProgrammePartnerContent xs={ 6 }>
                { companyWidget.partner && (
                  <ProgrammePartner
                    key={ companyWidget.partner.slug }
                    partner={ companyWidget.partner }
                  />
                ) }
              </ProgrammePartnerContent>
            </CompanyData>
            <CompanyMembers>
              { companyWidget.members.length > 0 && (
                <CompanyMemberRow className={ `${ (showMoreMembers ? 'full' : 'hidden') }` }>
                  { companyWidget.members.map(member => (
                    <div key={ member.id } className='col-sm-6 col-xs-12 networking-card__p-y'>
                      <NetworkingCard
                        user={ { uuid: member.id, ...member } }
                      />
                    </div>
                  )) }
                </CompanyMemberRow>
              ) }
              <ShowMoreContainer>
                { companyWidget.members.length > 4 && (
                  <ShowMoreButton
                    onClick={ () => this.toggleShowMore() }
                    onKeyPress={ () => this.toggleShowMore() }
                    role='presentation'
                  >
                    <ShowMoreButtonInner>
                      <FormattedMessage id={ showMoreMembers ? 'show_less' : 'show_more' } />
                      <Icon icon={ showMoreMembers ? 'up' : 'down' } />
                    </ShowMoreButtonInner>
                  </ShowMoreButton>
                ) }
              </ShowMoreContainer>
            </CompanyMembers>
            { companyWidget.canEdit && (
              <CompanyBottomHolder id='dashboard-company-widget'>
                <ButtonLink text='company_edit' to={ this.getRouteToEntity(true) } />
              </CompanyBottomHolder>
            ) }
          </Container>
        ) }
      </DashboardWidget>
    );
  }
}

/**
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state) => ({
  error: state.widget.error,
  companyWidget: state.widget.companyWidget,
});

/**
 * @param {Function} dispatch
 *
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  getCompanyWidgetAction: () => dispatch(getCompanyWidget()),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyWidget);

export default injectIntl(withRouter(connectWrapper));
