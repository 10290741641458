import React, { useEffect } from 'react';
import { Button, Loader } from '@src/js/components/global';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { InputText } from '@app/components/global/forms';
import { ModalBody, ModalFooter } from 'reactstrap';
import { sendEventTestReminder } from '@src/js/actions/events/adminEvent';
import { RootState } from '@src/js/store';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

type Props = {
  slug: string,
  templateId: string
}

const EventTestReminderModal = ({ slug, templateId }: Props) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const loading = useSelector<RootState, boolean>(state => state.adminEvent.sendTestLoading);
  const methods = useForm({
    mode: 'onChange',
  });
  const {
    formState: { errors }, register, handleSubmit,
  } = methods;

  const onSubmit = (submitData: object): void => {
    dispatch(sendEventTestReminder(slug, templateId, submitData));
    hideModal();
  };

  useEffect(() => () => {
    hideModal();
  }, []);

  return (
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(onSubmit) } className='m-t-3'>
        { loading && <Loader /> }
        <ModalBody>
          <section className='elite-form__section'>
            <div className='elite-form__full-row'>
              <InputText
                name='email'
                type='email'
                errors={ errors }
                register={ register }
                label='user.email'
                required
              />
            </div>
          </section>
        </ModalBody>
        <ModalFooter className='m-t-3'>
          <Button
            size='lg'
            buttonText='dialog.confirm'
            buttonType='submit'
            disabled={ false }
          />
          <Button
            size='lg'
            buttonText='cancel'
            emptyButtonType='empty-grey'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

export default EventTestReminderModal;
