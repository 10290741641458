import { useSelector } from 'react-redux';

const selectContactUser = (contactType) => useSelector(state => state.contactUser?.[contactType]);
const selectFundingOptions = () => useSelector(state => state.funding?.fundingOptions);
const selectOpportunities = () => useSelector(state => state.funding?.opportunities);
const selectFinanzaOpportunities = () => useSelector(state => state.funding?.finanzaOpportunities);
const selectOpportunitiesLoading = () => useSelector(state => state.funding?.opportunitiesLoading);
const selectBrokerList = () => useSelector(state => state.broker?.brokerList);
const selectBrokerListMore = () => useSelector(state => state.broker?.hasMore);
const selectBrokerListLoading = () => useSelector(state => !!state.broker?.brokerListLoading);
const selectSuccessStories = () => useSelector(state => state.funding?.successStories);
const selectSuccessStoriesLoading = () => useSelector(state => state.funding?.successStoriesLoading);
const selectSuccessStoriesError = () => useSelector(state => state.funding?.successStoriesError);

const selectFundingInterviews = () => useSelector(state => state.funding?.fundingInterviews);
const selectFundingInterviewsLoading = () => useSelector(state => state.funding?.fundingInterviewsLoading);
const selectFundingInterviewsError = () => useSelector(state => state.funding?.fundingInterviewsError);

const selectFundingInsights = () => useSelector(state => state.funding?.fundingInsights);
const selectFundingInsightsLoading = () => useSelector(state => state.funding?.fundingInsightsLoading);
const selectFundingInsightsError = () => useSelector(state => state.funding?.fundingInsightsError);

const selectParametersPartnersLoading = () => useSelector(state => state.parametersPartners?.loading);
const selectParametersPartnersError = () => useSelector(state => state.parametersPartners?.error);
const selectParametersPartners = () => useSelector(state => state.parametersPartners?.partners);

const selectDealList = () => useSelector(state => state.deal?.dealList);
const selectDealListLoading = () => useSelector(state => !!state.deal?.dealListLoading);

export {
  selectContactUser,
  selectFundingOptions,
  selectOpportunities,
  selectOpportunitiesLoading,
  selectBrokerList,
  selectBrokerListLoading,
  selectSuccessStories,
  selectSuccessStoriesLoading,
  selectSuccessStoriesError,
  selectFundingInterviews,
  selectFundingInterviewsLoading,
  selectFundingInterviewsError,
  selectFundingInsights,
  selectFundingInsightsLoading,
  selectFundingInsightsError,
  selectParametersPartnersLoading,
  selectParametersPartnersError,
  selectParametersPartners,
  selectBrokerListMore,
  selectDealList,
  selectDealListLoading,
  selectFinanzaOpportunities,
};
