import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import {
  externalRoutes, routeCodes, withLang,
} from '@src/js/constants/routes';
import {
  isInstitutionalInvestor, isInvestorRelator,
  getTourId,
} from '@src/js/helpers/helpers';
import { exitImpersonateSession, logoutUser } from '@src/js/actions/user/user';
import { Company, Impersonate, Partner } from '@src/js/constants/entities';
import { formatUrlToSecure } from '@src/js/helpers/formatUrlToSecure';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { useIntercom } from 'react-use-intercom';
import { usePrevious } from '@src/js/helpers/hooks';
import Search from '@src/js/layouts/header/Search';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { useEtineraryModal } from '@src/js/layouts/header/navigation/utils';
import LanguageSelector from '@src/js/components/dropdowns/LanguageSelector';
import { selectUnreadThreads } from '@app/store/chat/chatSlice';

const MainMenuDesktop = ({
  navigation, setActiveItem, setSubNavigation, toggleSearch, showSearch,
}) => {
  const dispatch = useDispatch();
  const userData = selectUserInfo();
  const { startTour } = useIntercom();
  const { showModal, checkType } = useEtineraryModal();
  const impersonateUserSuccess = useSelector(state => state.user.impersonateUserSuccess);
  const exitImpersonateSessionSuccess = useSelector(state => state.user.exitImpersonateSessionSuccess);
  const unreadThreads = useSelector(selectUnreadThreads);
  const taskNotificationNumber = useSelector(state => state.socketTaskNotifications.taskNotificationNumber);

  const prevImpersonateUserSuccess = usePrevious(impersonateUserSuccess);
  const prevImpersonateSessionSuccess = usePrevious(exitImpersonateSessionSuccess);

  useEffect(() => {
    if (
      (!prevImpersonateUserSuccess && impersonateUserSuccess)
      || (!prevImpersonateSessionSuccess && exitImpersonateSessionSuccess)
    ) {
      window.location.replace(withLang(routeCodes.HOME));
    }
  }, [impersonateUserSuccess, exitImpersonateSessionSuccess]);

  const handleLogout = (e) => {
    e.preventDefault();

    dispatch(logoutUser());
  };

  const stopImpersonatingUser = () => {
    dispatch(exitImpersonateSession());
  };

  const showTaskNumberNotification = () => parseInt(taskNotificationNumber, 10) > 0;

  const renderTaskLink = () => (
    <NavLink
      id='main-navigation__tasks'
      activeClassName='main-navigation__right-section__nav-item--active'
      className='main-navigation__right-section__nav-item'
      exact
      to={ withLang(routeCodes.MY_COMPANY_TASKS) }
    >
      { showTaskNumberNotification() && (
      <span className='main-navigation__right-section__nav-item-notification'>
        { taskNotificationNumber }
      </span>
      ) }
      <span className='icon main-navigation__right-section__nav-item-icon icon-medium-Notification' />
      <span className='main-navigation__right-section__nav-item-caption'>
        <FormattedMessage id='navigation.tasks' />
      </span>
    </NavLink>
  );
  const tourId = getTourId();
  const startIntercom = () => {
    if (tourId) startTour(tourId);
  };

  if (userData === null || !userData.isLoggedIn) {
    window.location = formatUrlToSecure(loadEnvVariable('APP_BASE_DOMAIN'));
    return null;
  }

  const navItemClass = 'main-navigation__right-section__nav-item';
  const navItemActiveClass = `${ navItemClass }--active`;
  const isSearchPage = window.location.toString().includes('search');
  const hasAccess = userData
    && userData.companyType !== Company.TYPE_ETINERARY;
  const partnerBasic = userData.partnerType === Partner.TYPE_BASIC;
  const isComplianceUser = userData.userRoles
    .find((el) => el === 'ROLE_COMPLIANCE_CLUB_DEAL' || el === 'ROLE_COMPLIANCE_SIM');
  const investor = isInstitutionalInvestor() || isInvestorRelator();

  return (
    <nav className='main-navigation'>
      <div className='container'>
        <div className='row middle-xs'>
          <NavLink className='main-navigation__logo' to={ withLang(routeCodes.HOME) }>
            <div className='main-navigation__logo-icon icon-EliteWhite' />
          </NavLink>

          <div className='main-navigation__links'>
            { Object.keys(navigation).map(key => {
              if (externalRoutes.includes(key)) {
                return (
                  <a
                    key={ key }
                    id={ `main-navigation__${ key }` }
                    className='main-navigation__links-item'
                    href={ routeCodes[key.toUpperCase()] }
                  >
                    <FormattedMessage id={ `navigation.${ key }` } />
                  </a>
                );
              }

              const etineraryModalType = checkType(key, userData);
              return (
                <NavLink
                  key={ key }
                  id={ `main-navigation__${ key }` }
                  activeClassName={ `main-navigation__links-item${ !etineraryModalType
                    ? '--active' : '' }` }
                  className='main-navigation__links-item'
                  exact={ key === 'home' }
                  to={ withLang(routeCodes[key.toUpperCase()]) }
                  onClick={ () => (etineraryModalType ? showModal(etineraryModalType) : setSubNavigation(key)) }
                  isActive={ (match, location) => setActiveItem(match, location, key) }
                >
                  <FormattedMessage id={ `navigation.${ key }` } />
                  { etineraryModalType && <span className='etinerary-indicator' /> }
                </NavLink>
              );
            }) }
          </div>

          <div className='main-navigation__right-section'>
            { hasAccess && !isComplianceUser && (
            <>
              { !partnerBasic && !investor && (
                <div id='main-navigation__search' className='main-navigation__right-section__search-wrapper'>
                  <span
                    className={ `${ navItemClass } ${ isSearchPage && navItemActiveClass }` }
                    onClick={ toggleSearch }
                    onKeyPress={ toggleSearch }
                    role='presentation'
                  >
                    <span className='icon main-navigation__right-section__nav-item-icon icon-SearchMedium' />
                    <span className='main-navigation__right-section__nav-item-caption'>
                      <FormattedMessage id='navigation.search' />
                    </span>
                  </span>

                  { showSearch && <Search toggleSearch={ toggleSearch } /> }
                </div>
              ) }

              { userData?.companyVisibility !== Company.VISIBILITY_HIDDEN
               && userData?.partnerVisibility !== Partner.VISIBILITY_HIDDEN
               && !isComplianceUser && !investor && (
               <NavLink
                 id='main-navigation__messages'
                 activeClassName={ `${ navItemActiveClass }` }
                 className={ navItemClass }
                 exact
                 to={ withLang(routeCodes.MESSAGES) }
               >
                 { unreadThreads && unreadThreads.length > 0 && (
                 <span className='main-navigation__right-section__nav-item-notification'>
                   { unreadThreads.length }
                 </span>
                 ) }
                 <span className='icon main-navigation__right-section__nav-item-icon icon-medium-Chat' />
                 <span className='main-navigation__right-section__nav-item-caption'>
                   <FormattedMessage id='navigation.messages' />
                 </span>
               </NavLink>
              ) }
            </>
            ) }
            { userData && userData.companySlug && !isComplianceUser && renderTaskLink() }
            <LanguageSelector />
            <UncontrolledDropdown id='main-navigation__dropdown' className='dropdown-profile'>

              <DropdownToggle tag='a' className='main-navigation__right-section__nav-item dropdown-profile__toggle'>
                <img className='dropdown-profile__toggle-avatar' alt='avatar' src={ userData.avatar } />
                <span className='main-navigation__right-section__nav-item-caption dropdown-profile__toggle-caption'>
                  { userData.userType === Impersonate.IS_IMPERSONATED ? (
                    <span className='
                    main-navigation__right-section__nav-item-caption
                    dropdown-profile__toggle-caption dropdown-profile__toggle-caption--impersonated-user'
                    >
                      <span className='badge badge-red'>
                        { `${ userData.firstName.substr(0, 6) }...` }
                      </span>
                    </span>
                  ) : (
                    <span
                      className='main-navigation__right-section__nav-item-caption dropdown-profile__toggle-caption'
                    >
                      <FormattedMessage id='me' />
                    </span>
                  ) }
                </span>
              </DropdownToggle>

              <DropdownMenu end>
                <DropdownItem tag='div' className='dropdown-item-username'>
                  <div className='dropdown-item-link'>
                    <FormattedMessage id='hello' />
                    { ', ' }
                    { userData.firstName }
                  </div>
                </DropdownItem>

                <div id='dropdown-menu-edit' className='dropdown-menu-group'>
                  <DropdownItem tag='div'>
                    <NavLink
                      className='dropdown-item-link'
                      exact
                      to={ `${ withLang(routeCodes.MY_PROFILE) }` }
                    >
                      <span className='icon icon-small-Edit' />
                      <FormattedMessage id='editProfile' />
                    </NavLink>
                  </DropdownItem>
                </div>

                { tourId && (
                <div className='dropdown-menu-group'>
                  <DropdownItem tag='div'>
                    <div
                      className='dropdown-item-link'
                      onClick={ () => startIntercom() }
                      role='presentation'
                    >
                      <span className='icon icon-Intercom' />
                      <FormattedMessage id='intercomTour' />
                    </div>
                  </DropdownItem>
                </div>
                ) }

                <div className='dropdown-menu-group'>
                  { userData.userType === Impersonate.IS_IMPERSONATED && (
                  <DropdownItem tag='div'>
                    <li
                      className='dropdown-item-link dropdown-item-link--impersonated-user'
                      onClick={ stopImpersonatingUser }
                      onKeyPress={ stopImpersonatingUser }
                      role='menuitem'
                    >
                      <span className='icon icon-medium-Switch' />
                      <FormattedMessage id='exitImpersonateSession' />
                    </li>
                  </DropdownItem>
                  ) }

                  <DropdownItem tag='div'>
                    <li
                      className='dropdown-item-link'
                      onClick={ handleLogout }
                      onKeyPress={ handleLogout }
                      role='menuitem'
                      tabIndex='0'
                    >
                      <span className='icon icon-medium-Logout' />
                      <FormattedMessage id='logout' />
                    </li>
                  </DropdownItem>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    </nav>
  );
};

MainMenuDesktop.propTypes = {
  navigation: PropTypes.object,
  toggleSearch: PropTypes.func,
  showSearch: PropTypes.bool,
  setSubNavigation: PropTypes.func,
  setActiveItem: PropTypes.func,
};

export default MainMenuDesktop;
