import { EventGa } from '@app/models/EventGa';
import { ReactGaService } from '@app/services/ReactGaService';
import { routeCodes, withLang } from '@src/js/constants/routes';

export type Participant = {
  uuid: string,
  name: string,
  jobTitle: string,
  company: string,
  companySlug: string,
  isCompany: boolean,
  isCompanyEnabled: boolean,
}

export type ChatStyles = {
  masterClass?: string,
  header?: string,
  content?: string,
  footer?: string,
};

export type HeaderData = {
  subject: string,
  participants: Participant[],
  shouldBeBold: boolean,
  editableSubject: boolean,
  enabled: boolean,
};

export type MessageData = {
  type: 'system'|'meeting',
  translation: boolean,
  meetingId?: number,
}

export type ChatMessage = {
  id: number,
  messageId: string,
  threadId: string,
  body: string,
  user: string,
  userUuid: string,
  created: string,
  messageData: MessageData|null,
}

export type ThreadPayload = {
  thread: {
    thread: string,
    messages: ChatMessage[],
  }
  hasMore: boolean,
  subscriptionLoading: boolean,
  subscriptionError: null,
  subscribedThreads: null,
  loadingUserThreads: boolean,
  errorUserThreads: null,
}

export type Thread = {
  id: string,
  modified: number,
  subject: string,
  participants: Participant[],
  participantImages: string[],
  lastMessage: ChatMessage|null,
  hasUnreadMessages: boolean,
  enabled: boolean, // other user is enabled, always true on group chat
  body: string,
  left: boolean, // you left chat
  groupChat: boolean,
  closed: boolean, // other participant(s) has left chat
}

export type Peer = {
  uuid: string,
  fullName: string,
  company: string,
  jobTitle: string,
  avatar: string,
  companySlug: string,
  isCompany: boolean,
  isCompanyEnabled: boolean,
  enabled: boolean,
  selected: boolean,
}

export type MessageBody = {
  body: string,
  // eslint-disable-next-line camelcase
  recipient_uids: string[],
  subject: string,
}

export const sendGAEvent = (recipients: string[], loggedUserId: string, pathname: string) => {
  ReactGaService.instance.event(new EventGa('Messaging', 'send_message', recipients.join(), {
    dimension1: loggedUserId,
  }));

  if (pathname === withLang(routeCodes.EOP_FUNDING)
    || pathname === withLang(routeCodes.TRANSACTIONS)
    || pathname === withLang(routeCodes.BASKET_BONDS)
    || pathname === withLang(routeCodes.INTRODUCTION_SERVICES)) {
    ReactGaService.instance.event(new EventGa(
      'Messaging from Funding',
      'send_message_funding',
      recipients.join(),
      { dimension1: loggedUserId }
    ));
  }
};
