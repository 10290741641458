import {
  ELASTICSEARCH_START,
  ELASTICSEARCH_ERROR,
  ELASTICSEARCH_SUCCESS,
  ELASTICSEARCH_CLEAR,
  ELASTICSEARCH_COMPANY_START,
  ELASTICSEARCH_COMPANY_ERROR,
  ELASTICSEARCH_COMPANY_SUCCESS,
  ELASTICSEARCH_SEARCH_START,
  ELASTICSEARCH_SEARCH_ERROR,
  ELASTICSEARCH_SEARCH_SUCCESS,
} from '@src/js/actions/elasticsearch';

const initialState = {
  loading: false,
  error: null,
  elasticsearch: null,
};

const actionsMap = {
  [ELASTICSEARCH_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ELASTICSEARCH_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ELASTICSEARCH_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    elasticsearch: action.data,
  }),
  [ELASTICSEARCH_CLEAR]: () => initialState,
  [ELASTICSEARCH_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ELASTICSEARCH_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ELASTICSEARCH_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    elasticsearch: action.data,
  }),
  [ELASTICSEARCH_SEARCH_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ELASTICSEARCH_SEARCH_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ELASTICSEARCH_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    elasticsearch: {
      counters: {},
      pagination: {},
      results: action.data,
      tabs: {},
    },
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
