export const CREATE_NEWS_START = 'CREATE_NEWS_START';
export const CREATE_NEWS_ERROR = 'CREATE_NEWS_ERROR';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';

export function createNews(data) {
  return {
    type: CREATE_NEWS_START,
    data,
  };
}
