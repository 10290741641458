import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { ArrowRenderer, LockerRenderer } from '@app/components/global/forms/select/Renderers';

const InputCreatableSelect = ({
  intl, name, label, translate, disabled, placeholder, control, options,
  required, errors, onChange, defaultValue, fieldNoClear, searchable, className, boolRequired,
  tooltip,
}) => {
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  const fieldPlacheholder = translate && placeholder ? intl.formatMessage({ id: placeholder }) : placeholder;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  const defaultInput = { value: null, label: '' };
  const optionsWrapper = useRef(null);
  const inputRef = useRef(null);
  const [openOption, setOpenOption] = useState(false);
  const [typing, setTyping] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [input, setInput] = useState(defaultValue && options.length > 0
    ? options.find(option => option.value === defaultValue)
    : defaultInput
  );

  const renderSelect = (field) => {
    let filteredOptions = options;

    if (input.value && typing) {
      const filter = options.filter(option => option.label.toLocaleLowerCase()
        .includes(input.label.toLocaleLowerCase()));
      const found = options.some(option => option.label === input.label);
      filteredOptions = !found
        ? [...[{ value: input.value, label: input.label }], ...filter]
        : filter;
    } else if (typing && typeof input !== 'object') {
      filteredOptions = options.filter(option => option.value === input);
    }

    const selectboxProps = {
      ...field,
      options,
      searchable,
      components: {
        DropdownIndicator: ArrowRenderer,
      },
    };

    if (disabled) {
      selectboxProps.className = 'eop-input-disabled';
      selectboxProps.isDisabled = true;
      selectboxProps.components.DropdownIndicator = LockerRenderer;
    }

    if (fieldNoClear) {
      selectboxProps.components.ClearIndicator = () => '';
    }

    const change = (e) => {
      if (e) {
        setInput(e);
      } else {
        setInput(defaultInput);
      }
      if (onChange) {
        return onChange(e);
      }
      return selectboxProps.onChange(e?.value);
    };

    const changeValue = (value) => {
      setTyping(true);
      setOpenOption(true);
      if (value && typeof value === 'object') {
        const option = options.find(op => op.label === value.label);
        if (option) {
          setSelectedOption(value.value);
          change(option);
          return;
        }
        if (value.value) {
          setSelectedOption(value.value);
          change(value);
          return;
        }
        setSelectedOption('');
        change({ value: null, label: '' });
        return;
      }
      const found = options.find(option => option.value === value);
      if (found) {
        setSelectedOption(value);
        change(found);
        return;
      }
      if (value) {
        setSelectedOption(value);
        change({ value, label: value });
      } else {
        setSelectedOption(value);
        change(null);
      }
    };

    const onBlur = () => {
      setOpenOption(false);
      setTyping(false);
    };

    const clickValue = (value) => {
      changeValue(value);
      onBlur();
    };

    const arrowHandler = (e) => {
      if (![9, 13, 38, 40].includes(e.keyCode)) return;
      const index = filteredOptions.findIndex((element) => element.value === selectedOption);
      const scrollTop = optionsWrapper.current.scrollTop;
      if (e.keyCode === 38 && index > 0) {
        setSelectedOption(filteredOptions[index - 1].value);
        if ((index - 1) * 30 < scrollTop) {
          optionsWrapper.current.scrollTop -= 30;
        }
      }
      if (e.keyCode === 40 && index < filteredOptions.length - 1) {
        setSelectedOption(filteredOptions[index + 1].value);
        if ((index + 1) * 30 > scrollTop + 150) {
          optionsWrapper.current.scrollTop += 30;
        }
      }
      if (e.keyCode === 9 || e.keyCode === 13) {
        changeValue(filteredOptions[index].value);
        onBlur();
      }
    };

    let value = '';
    if (typeof input === 'object') {
      value = input.label;
    } else {
      const find = options.filter(option => option.value === input)[0];
      if (find) {
        value = find.label;
      }
    }

    return (
      <div className='input-creatable-select'>
        <div className='d-flex w-100 align-items-center'>
          <input
            ref={ inputRef }
            className='input-creatable-select__input'
            value={ value }
            onChange={ e => changeValue({ value: e.target.value, label: e.target.value }) }
            onFocus={ () => setOpenOption(true) }
            onKeyDown={ e => arrowHandler(e) }
            placeholder={ fieldPlacheholder }
            onBlur={ onBlur }
            type='text'
          />
          { input.value && (
            <span
              className='icon-CloseSmall eop-selectbox-clear input-creatable-select__reset-option m-r-2'
              onClick={ () => changeValue(null) }
              role='presentation'
            />
          ) }
          <span
            className='icon-ArrowDownSmall eop-selectbox-arrow cursor-pointer m-r-1 font-size-caption'
            onClick={ () => setOpenOption(!openOption) }
            role='presentation'
          />
        </div>
        {openOption && (
        <div
          ref={ optionsWrapper }
          className='input-creatable-select__options-wrapper'
        >
          { filteredOptions.length > 0 && filteredOptions.map(option => (
            <div
              key={ option.value }
              value={ value }
              onMouseEnter={ () => setSelectedOption(option.value) }
              onMouseDown={ () => clickValue(option.value) }
              onKeyPress={ () => clickValue(option.value) }
              role='presentation'
              className={ `input-creatable-select__option ${
                selectedOption === option.value ? 'elite-background-very-light-blue' : ''
              }` }
            >
              {option.label}

            </div>
          ))}
          { filteredOptions.length === 0 && <div>No results</div> }
        </div>
        )}
      </div>
    );
  };

  return (
    <div className={ `eop-selectbox ${ className }` } data-name={ name }>
      <label className='eop-selectbox__label'>
        { (required || boolRequired) && <span className='required-icon' /> }
        { label && fieldLabel }
        { tooltip && (
          <>
            <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ `${ name }-tooltip` } />
            <ReactTooltip
              id={ `${ name }-tooltip` }
              place='top'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              { fieldTooltip }
            </ReactTooltip>
          </>
        )}
      </label>
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        rules={ { required: requiredError } }
        render={ ({ field }) => renderSelect(field) }
      />
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

InputCreatableSelect.propTypes = {
  intl: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  translate: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  boolRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType(([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
  ])),
  fieldNoClear: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.string,
};

InputCreatableSelect.defaultProps = {
  translate: true,
  disabled: false,
  required: false,
  boolRequired: false,
  searchable: true,
  placeholder: 'global_form.select.placeholder',
  className: '',
};

export default injectIntl(InputCreatableSelect);
