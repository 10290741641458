import { showToastrError } from '@app/store/global/actions/index';
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import {
  UPLOAD_ONBOARDING_FILE_START,
  UPLOAD_ONBOARDING_FILE_ERROR,
  UPLOAD_ONBOARDING_FILE_SUCCESS,
  ADMIN_UPLOAD_ONBOARDING_FILE_START,
  ADMIN_UPLOAD_ONBOARDING_FILE_ERROR,
  ADMIN_UPLOAD_ONBOARDING_FILE_SUCCESS,
} from '@src/js/actions/onboarding/file/uploadOnboardingFile';

import * as api from '@src/js/api/onboarding/file/uploadOnboardingFile';

function* uploadOnboardingFile(options) {
  try {
    const data = yield call(() => api.uploadOnboardingFile(options.file.file, options.entity, options.fileType));
    yield put({ type: UPLOAD_ONBOARDING_FILE_SUCCESS, data, options });
    options.file.resolve(data);
  } catch (error) {
    const errMessage = Object.values(error.response)?.[0] || 'notification.error_upload';
    yield all([
      put({ type: UPLOAD_ONBOARDING_FILE_ERROR, error }),
      put(showToastrError('notification.error_title', errMessage)),
    ]);
    options.file.reject(error.response);
  }
}

function* adminUploadOnboardingFile(options) {
  try {
    const data = yield call(() => api.adminUploadOnboardingFile(options.file.file, options.entity, options.fileType));
    yield put({ type: ADMIN_UPLOAD_ONBOARDING_FILE_SUCCESS, data, options });
    options.file.resolve(data);
  } catch (error) {
    const errMessage = Object.values(error.response)?.[0] || 'notification.error_upload';
    yield all([
      put({ type: ADMIN_UPLOAD_ONBOARDING_FILE_ERROR, error }),
      put(showToastrError('notification.error_title', errMessage)),
    ]);
    options.file.reject(error.response);
  }
}

function* uploadFileWatcher() {
  yield takeLatest(UPLOAD_ONBOARDING_FILE_START, uploadOnboardingFile);
}

function* adminUploadFileWatcher() {
  yield takeLatest(ADMIN_UPLOAD_ONBOARDING_FILE_START, adminUploadOnboardingFile);
}

export default [
  uploadFileWatcher(),
  adminUploadFileWatcher(),
];
