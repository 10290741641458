export const ADMIN_TEMPLATE_SHOW_MODAL = 'ADMIN_TEMPLATE_SHOW_MODAL';
export const ADMIN_TEMPLATE_HIDE_MODAL = 'ADMIN_TEMPLATE_HIDE_MODAL';
export const ADMIN_RESET_TEMPLATE = 'ADMIN_RESET_TEMPLATE';

export function showTemplateModal(templateType) {
  return {
    type: ADMIN_TEMPLATE_SHOW_MODAL,
    templateType,
  };
}

export function hideTemplateModal() {
  return {
    type: ADMIN_TEMPLATE_HIDE_MODAL,
  };
}

export function resetTemplate() {
  return {
    type: ADMIN_RESET_TEMPLATE,
  };
}
