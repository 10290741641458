import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_OPPORTUNITY_PROFILE_START,
  GET_OPPORTUNITY_PROFILE_ERROR,
  GET_OPPORTUNITY_PROFILE_SUCCESS,
  GET_RELATED_OPPORTUNITIES_START,
  GET_RELATED_OPPORTUNITIES_ERROR,
  GET_RELATED_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getOpportunityProfile';

import * as api from '@src/js/marketplace/api/getOpportunityProfile';
import * as apiLatest from '@src/js/marketplace/api/getLatestOpportunities';

function* getOpportunityProfile(options) {
  try {
    const data = yield call(() => api.getOpportunityProfile(options.id));
    yield put({ type: GET_OPPORTUNITY_PROFILE_SUCCESS, data });
    yield put({ type: GET_RELATED_OPPORTUNITIES_START, name: data.Name });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_PROFILE_ERROR, error });
  }
}

function* getRelatedOpportunities() {
  try {
    const data = yield call(() => apiLatest.getLatestOpportunities());
    yield put({ type: GET_RELATED_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_RELATED_OPPORTUNITIES_ERROR, error });
  }
}

function* getOpportunityProfileWatcher() {
  yield takeLatest(GET_OPPORTUNITY_PROFILE_START, getOpportunityProfile);
}

function* getRelatedOpportunitiesWatcher() {
  yield takeLatest(GET_RELATED_OPPORTUNITIES_START, getRelatedOpportunities);
}

export default [
  getOpportunityProfileWatcher(),
  getRelatedOpportunitiesWatcher(),
];
