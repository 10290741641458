import {
  UPLOAD_FILE_START,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  CLEAR_FILE_STATE,
} from '@src/js/actions/file/uploadFile';

export const initialState = {
  loading: false,
  error: null,
  file: null,
  reminderAttachmentsFile: null,
  reminderAttachmentsError: null,
  reminderAttachmentsLoading: false,
};

const actionsMap = {
  [UPLOAD_FILE_START]: (state, action) => (action.reminderLang
    ? {
      ...state,
      reminderAttachmentsError: null,
      reminderAttachmentsLoading: true,
    }
    : {
      ...state,
      loading: true,
      error: null,
    }),
  [UPLOAD_FILE_ERROR]: (state, action) => (action.reminderLang
    ? {
      ...state,
      reminderAttachmentsLoading: false,
      reminderAttachmentsError: action.error.message,
      errorStatus: action.error.status,
    }
    : {
      ...state,
      loading: false,
      error: action.error.message,
      errorStatus: action.error.status,
    }),
  [UPLOAD_FILE_SUCCESS]: (state, action) => (action?.options?.reminderLang
    ? {
      ...state,
      reminderAttachmentsLoading: false,
      reminderAttachmentsError: null,
      reminderAttachmentsFile: { ...action.data, ...{ lang: action?.options?.reminderLang } },
    }
    : {
      ...state,
      loading: false,
      error: null,
      file: action.data,
      options: action.options,
    }),
  [CLEAR_FILE_STATE]: (state) => ({
    ...state,
    file: null,
    reminderAttachmentsFile: null,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
