import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_GET_COMPANY_START,
  ADMIN_GET_COMPANY_ERROR,
  ADMIN_GET_COMPANY_SUCCESS,
  ADMIN_CREATE_COMPANY_START,
  ADMIN_CREATE_COMPANY_ERROR,
  ADMIN_CREATE_COMPANY_SUCCESS,
  ADMIN_EDIT_COMPANY_START,
  ADMIN_EDIT_COMPANY_ERROR,
  ADMIN_EDIT_COMPANY_SUCCESS,
  ADMIN_DISABLE_COMPANY_START,
  ADMIN_DISABLE_COMPANY_ERROR,
  ADMIN_DISABLE_COMPANY_SUCCESS,
  EDIT_COMPANY_FINANCIALS_START,
  EDIT_COMPANY_FINANCIALS_ERROR,
  EDIT_COMPANY_FINANCIALS_SUCCESS,
  UPDATE_COMPANY_FINANCIALS_START,
  UPDATE_COMPANY_FINANCIALS_ERROR,
  UPDATE_COMPANY_FINANCIALS_SUCCESS,
} from '@src/js/actions/company/adminCompany';

import * as api from '@src/js/api/company/adminCompany';

function* adminGetCompany(options) {
  try {
    const data = yield call(() => api.adminGetCompany(options.id));
    yield put({ type: ADMIN_GET_COMPANY_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_COMPANY_ERROR, error });
  }
}

function* adminCreateCompany(options) {
  try {
    const data = yield call(() => api.adminCreateCompany(options.company.values, options.userType));
    yield put({ type: ADMIN_CREATE_COMPANY_SUCCESS, data });
    options.company.resolve();
  } catch (error) {
    yield put({ type: ADMIN_CREATE_COMPANY_ERROR, error });
    options.company.reject(error.response);
  }
}

function* adminEditCompany(options) {
  try {
    const data = yield call(() => api.adminEditCompany(options.company.values));
    yield put({ type: ADMIN_EDIT_COMPANY_SUCCESS, data });
    options.company.resolve();
  } catch (error) {
    yield put({ type: ADMIN_EDIT_COMPANY_ERROR, error });
    options.company.reject(error.response);
  }
}

function* adminDisableCompany(options) {
  try {
    const data = yield call(() => api.adminDisableCompany(options.slug));
    yield put({ type: ADMIN_DISABLE_COMPANY_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_DISABLE_COMPANY_ERROR });
  }
}

function* editCompanyFinancials({ slug, lang }) {
  try {
    const data = yield call(() => api.editCompanyFinancials(slug));
    yield put({ type: EDIT_COMPANY_FINANCIALS_SUCCESS, data, lang });
  } catch (error) {
    yield put({ type: EDIT_COMPANY_FINANCIALS_ERROR, error });
  }
}

function* updateCompanyFinancials(options) {
  try {
    const data = yield call(() => api.updateCompanyFinancials(
      options.data.values.companySlug,
      options.data.values.companyFinancials
    ));
    yield put({ type: UPDATE_COMPANY_FINANCIALS_SUCCESS, data, lang: options.lang });
    options.data.resolve();
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_FINANCIALS_ERROR, error });
    options.data.reject(error.response);
  }
}

function* adminGetCompanyWatcher() {
  yield takeLatest(ADMIN_GET_COMPANY_START, adminGetCompany);
}

function* adminCreateCompanyWatcher() {
  yield takeLatest(ADMIN_CREATE_COMPANY_START, adminCreateCompany);
}

function* adminEditCompanyWatcher() {
  yield takeLatest(ADMIN_EDIT_COMPANY_START, adminEditCompany);
}

function* adminDisableCompanyWatcher() {
  yield takeLatest(ADMIN_DISABLE_COMPANY_START, adminDisableCompany);
}

function* editCompanyFinancialsWatcher() {
  yield takeLatest(EDIT_COMPANY_FINANCIALS_START, editCompanyFinancials);
}

function* updateCompanyFinancialsWatcher() {
  yield takeLatest(UPDATE_COMPANY_FINANCIALS_START, updateCompanyFinancials);
}

export default [
  adminGetCompanyWatcher(),
  adminCreateCompanyWatcher(),
  adminEditCompanyWatcher(),
  adminDisableCompanyWatcher(),
  editCompanyFinancialsWatcher(),
  updateCompanyFinancialsWatcher(),
];
