const scope = 'CUSTOM_REMINDER';

export const GET_DATA_START = `${ scope }/GET_DATA_START`;
export const GET_DATA_FAIL = `${ scope }/GET_DATA_FAIL`;
export const GET_DATA_SUCCESS = `${ scope }/GET_DATA_SUCCESS`;

export const GET_USERS_START = `${ scope }/GET_USERS_START`;
export const GET_USERS_FAIL = `${ scope }/GET_USERS_FAIL`;
export const GET_USERS_SUCCESS = `${ scope }/GET_USERS_SUCCESS`;

export const SEND_CUSTOM_REMINDER_START = `${ scope }/SEND_CUSTOM_REMINDER_START`;
export const SEND_CUSTOM_REMINDER_FAIL = `${ scope }/SEND_CUSTOM_REMINDER_FAIL`;
export const SEND_CUSTOM_REMINDER_SUCCESS = `${ scope }/SEND_CUSTOM_REMINDER_SUCCESS`;

export const ADD_USER = `${ scope }/ADD_USER`;
export const REMOVE_USER = `${ scope }/REMOVE_USER`;
export const CLEAR_USERS = `${ scope }/CLEAR_USERS`;

export function getCustomReminderList(slug, params) {
  return {
    type: GET_DATA_START,
    slug,
    params,
  };
}

export function getCustomReminderUsers(slug, params) {
  return {
    type: GET_USERS_START,
    slug,
    params,
  };
}

export function sendCustomReminder(slug, data, templateId, scheduling) {
  return {
    type: SEND_CUSTOM_REMINDER_START,
    slug,
    data,
    templateId,
    scheduling,
  };
}

export function addUser(id) {
  return {
    type: ADD_USER,
    id,
  };
}

export function removeUser(id) {
  return {
    type: REMOVE_USER,
    id,
  };
}

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}
