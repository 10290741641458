import produce from 'immer';

import {
  GET_DEAL_START,
  GET_DEAL_SUCCESS,
  GET_DEAL_FAIL,
  UPDATE_DEAL_FAIL,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_START,
  GET_DEAL_LIST_START,
  GET_DEAL_LIST_FAIL,
  GET_DEAL_LIST_SUCCESS,
} from '@app/store/funding/actions/deal';

const initialState = {
  loading: false,
  error: null,
  deal: null,
  dealList: [],
  pageCount: 0,
  params: null,
};

function deal(state = initialState, action) {
  return produce(state, (d) => {
    const draft = d;
    switch (action.type) {
      case GET_DEAL_START:
        draft.loading = true;
        draft.error = null;
        draft.deal = null;
        break;
      case GET_DEAL_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        draft.deal = null;
        break;
      case GET_DEAL_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.deal = action.data;
        break;
      case UPDATE_DEAL_START:
        draft.loading = true;
        draft.error = null;
        break;
      case UPDATE_DEAL_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        break;
      case UPDATE_DEAL_SUCCESS:
        draft.loading = false;
        draft.error = null;
        break;
      case GET_DEAL_LIST_START:
        draft.loading = true;
        draft.error = null;
        draft.pageCount = 0;
        break;
      case GET_DEAL_LIST_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        break;
      case GET_DEAL_LIST_SUCCESS:
        draft.loading = false;
        draft.dealList = action.data.currentPage > 0 ? state.dealList.concat(action.data.items) : action.data.items;
        draft.pageCount = action.data.pagesCount;
        draft.params = action.params;
        break;
      default:
        break;
    }
  });
}

export default deal;
