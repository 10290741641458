import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import * as api from '@app/store/etinerary/api';

import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  getTemplateSuccess,
  getTemplateFail,
  sendTemplateSuccess,
  sendTemplateFail,
  downloadTemplateSuccess,
  downloadTemplateFail,
  createNewLegalSuccess,
  createNewLegalFail,
} from '@app/store/etinerary/actions';
import {
  GET_TEMPLATE_REQUEST,
  SEND_TEMPLATE_REQUEST,
  DOWNLOAD_TEMPLATE_REQUEST,
  CREATE_NEW_LEGAL_REQUEST,
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL,
} from '@app/store/etinerary/constants';

function* getTemplate() {
  try {
    const data = yield call(() => api.getTemplate());
    yield put(getTemplateSuccess(data));
  } catch (error) {
    yield put(getTemplateFail(error));
  }
}

function* sendTemplate({ payload }) {
  const { callbackSuccess, callbackFailed, ...values } = payload || {};
  try {
    const data = yield call(() => api.sendTemplate(values));
    yield put(sendTemplateSuccess(data));
    if (callbackSuccess) callbackSuccess();
  } catch (error) {
    yield put(sendTemplateFail(error));
    if (callbackFailed) callbackFailed();
  }
}

function* downloadTemplate() {
  try {
    yield call(() => api.downloadTemplate());
    yield put(downloadTemplateSuccess());
  } catch (error) {
    yield put(downloadTemplateFail(error));
  }
}

function* createNewLegal({ payload }) {
  const { callbackSuccess, callbackFailed, ...values } = payload || {};
  try {
    const data = yield call(() => api.createNewLegal(values));
    yield put(createNewLegalSuccess(data));
    if (callbackSuccess) callbackSuccess();
  } catch (error) {
    yield put(createNewLegalFail(error));
    if (callbackFailed) callbackFailed();
  }
}

function* contactRequest() {
  try {
    const data = yield call(() => api.contactRequest());
    yield all([
      put({ type: SEND_CONTACT_SUCCESS, data }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: SEND_CONTACT_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_TEMPLATE_REQUEST, getTemplate);
  yield takeLatest(SEND_TEMPLATE_REQUEST, sendTemplate);
  yield takeLatest(DOWNLOAD_TEMPLATE_REQUEST, downloadTemplate);
  yield takeLatest(CREATE_NEW_LEGAL_REQUEST, createNewLegal);
  yield takeLatest(SEND_CONTACT_REQUEST, contactRequest);
}

export default root;
