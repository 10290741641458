import {
  takeLatest, call, put, takeEvery, all,
} from 'redux-saga/effects';
import {
  getAdminBusinessNeedRequest,
} from '@src/js/marketplace/actions/admin/businessNeedTable';
import {
  GET_BUSINESSNEEDS_START,
  GET_BUSINESSNEEDS_ERROR,
  GET_BUSINESSNEEDS_SUCCESS,
  DELETE_BUSINESSNEED_START,
  DELETE_BUSINESSNEED_ERROR,
  DELETE_BUSINESSNEED_SUCCESS,
  SET_FILTERS_SUCCESS,
  SET_FILTERS,
} from '@src/js/marketplace/actions/businessNeeds';

import * as api from '@src/js/marketplace/api/businessNeeds';
import {
  GET_RELEVANT_OPPORTUNITIES_START,
  GET_DASHBOARD_OPPORTUNITIES_START,
} from '@src/js/marketplace/actions/getRelevantOpportunities';
import { GET_MATCHMAKING_MEMBERS_START } from '@src/js/marketplace/actions/matchmakingMembers';

function alternateBusinessNeeds(data) {
  const storedBusinessNeeds = localStorage.getItem('dashboard-business-needs');
  let stored = storedBusinessNeeds ? JSON.parse(storedBusinessNeeds) : [];
  let businessNeed = data.find(b => b.HasProducts && !stored.includes(b.Id));
  if (!businessNeed) {
    businessNeed = data[0];
    stored = [];
  }
  if (businessNeed?.HasProducts) {
    stored.push(businessNeed.Id);
  }
  localStorage.setItem('dashboard-business-needs', JSON.stringify(stored));
  return businessNeed;
}

function* getBusinessNeeds({ options }) {
  try {
    const relevantCount = localStorage.getItem('relevant-opportunities-count');
    const relevantOpportunitiesCount = relevantCount ? JSON.parse(relevantCount) : {};
    const matchmakingMembersCount = localStorage.getItem('matchmaking-members-count');
    const membersCount = matchmakingMembersCount ? JSON.parse(matchmakingMembersCount) : {};

    const data = yield call(() => api.getBusinessNeeds());

    if (options?.alternate) {
      const businessNeed = alternateBusinessNeeds(data);
      const page = relevantOpportunitiesCount[businessNeed.Id] ? relevantOpportunitiesCount[businessNeed.Id] : 0;
      localStorage.setItem('relevant-opportunities-count', JSON.stringify(relevantOpportunitiesCount));
      yield put({ type: GET_DASHBOARD_OPPORTUNITIES_START, id: businessNeed.Id, filters: { page, limit: 3 } });
    } else {
      yield* data.map(function* (businessNeed) {
        const page = relevantOpportunitiesCount[businessNeed.Id] ? relevantOpportunitiesCount[businessNeed.Id] : 0;
        localStorage.setItem('relevant-opportunities-count', JSON.stringify(relevantOpportunitiesCount));
        yield put({ type: GET_RELEVANT_OPPORTUNITIES_START, id: businessNeed.Id, filters: { page, limit: 3 } });
      });
      yield* data.map(function* (businessNeed) {
        const page = membersCount[businessNeed.Id] ? membersCount[businessNeed.Id] : 0;
        localStorage.setItem('matchmaking-members-count', JSON.stringify(membersCount));
        yield put({
          type: GET_MATCHMAKING_MEMBERS_START,
          id: businessNeed.Id,
          page,
          limit: 3,
        });
      });
    }
    yield put({ type: GET_BUSINESSNEEDS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_BUSINESSNEEDS_ERROR, error });
  }
}

function* deleteBusinessNeed({ businessNeed, isAdmin, userId }) {
  try {
    const data = yield call(() => api.deleteBusinessNeed(businessNeed));
    yield all([
      put({ type: DELETE_BUSINESSNEED_SUCCESS, data }),
      put({ type: GET_BUSINESSNEEDS_START }),
    ]);
    if (isAdmin) yield put(getAdminBusinessNeedRequest(userId));
  } catch (error) {
    yield put({ type: DELETE_BUSINESSNEED_ERROR, error });
  }
}

function* setBusinessNeedFilters(options) {
  yield put({ type: SET_FILTERS_SUCCESS, filters: options.filters });
}

function* getBusinessNeedsWatcher() {
  yield takeLatest(GET_BUSINESSNEEDS_START, getBusinessNeeds);
}

function* deleteBusinessNeedWatcher() {
  yield takeLatest(DELETE_BUSINESSNEED_START, deleteBusinessNeed);
}

function* setBusinessNeedFiltersWatcher() {
  yield takeEvery(SET_FILTERS, setBusinessNeedFilters);
}

export default [
  getBusinessNeedsWatcher(),
  deleteBusinessNeedWatcher(),
  setBusinessNeedFiltersWatcher(),
];
