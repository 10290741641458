import { DirectorySections } from '@src/js/constants/entities';
import produce from 'immer';

import {
  GET_RELEVANT_START,
  GET_RELEVANT_FAIL,
  GET_RELEVANT_SUCCESS,
  GET_VIEW_ALL_START,
  GET_VIEW_ALL_FAIL,
  GET_VIEW_ALL_SUCCESS,
} from '@app/store/network/actions';

export const initialState = {
  businessneed: [],
  content: [],
  workshop: {},
  latest: [],
  loading: false,
  error: null,
  data: [],
  pagination: {},
  title: '',
  certificate: { results: [] },
};

function networkReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_RELEVANT_START:
      case GET_RELEVANT_FAIL:
        if (action.section === DirectorySections.WORKSHOP) {
          draft.workshop[action.boxNumber] = [];
        } else {
          draft[action.section] = [];
        }
        break;
      case GET_RELEVANT_SUCCESS:
        if (action.section === DirectorySections.WORKSHOP) {
          draft.workshop[action.boxNumber] = action.result;
        } else {
          draft[action.section] = action.result;
        }
        break;
      case GET_VIEW_ALL_START:
        draft.loading = true;
        draft.error = null;
        if (action.page === 1) {
          draft.data = [];
        }
        draft.pagination = {};
        draft.title = '';
        break;
      case GET_VIEW_ALL_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.data = action.result?.page === 1
          ? action.data
          : [...draft.data, ...action.result.results];
        draft.pagination = action.result?.pagination;
        draft.title = action.result?.title;
        break;
      case GET_VIEW_ALL_FAIL:
        draft.loading = false;
        draft.error = action.error;
        draft.data = [];
        draft.pagination = {};
        draft.title = '';
        break;
      default:
        break;
    }
  });
}

export default networkReducer;
