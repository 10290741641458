import React from 'react';
import Creatable from 'react-select/creatable';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { MultiValue, SingleValue } from 'react-select';
import {
  InputSelectProps, SelectValidator, SelectValue, isSelectValueArray, isSelectValueObj,
} from './select';
import Select from './EliteSelect';
import AsyncSelectPaginate from './AsyncSelectPaginate';
import {
  ArrowRenderer, LoadingRenderer, LockerRenderer, NullRenderer,
} from './Renderers';

type Props = InputSelectProps & {
  field: ControllerRenderProps<FieldValues, string>
}

type Components = {
  DropdownIndicator: () => JSX.Element,
  LoadingIndicator: () => JSX.Element,
  ClearIndicator?: () => JSX.Element,
}

type ValidateInput = {
  validator?: SelectValidator,
  boolRequired?: boolean,
  required?: boolean,
  intl: IntlShape
}
export const validateSelect = ({
  validator, boolRequired, intl, required,
}: ValidateInput): SelectValidator => {
  const rules: SelectValidator = {
    validate: () => true,
  };
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;
  if (boolRequired && !validator?.validate) {
    rules.validate = (value) => value === true || value === false
      || intl.formatMessage({ id: 'global_form.error.required' });
  } else if (!validator?.validate) {
    rules.required = requiredError;
  } else {
    rules.validate = validator.validate;
  }

  return rules;
};

export const RenderSelect = ({
  name, disabled = false, placeholder = 'global_form.select.placeholder',
  options, loadOptions, multi, onChange, fieldNoClear, searchable = true, type,
  translate = true, defaultValue, cacheUniqs, className, loadAsyncOptions,
  creatable, simpleValue = true, field, noResultsText = 'global_form.select.no_results', debounceTimeout, formatOptions,
}: Props) => {
  const intl = useIntl();
  const noResults = intl.formatMessage({ id: noResultsText });
  const fieldPlacheholder = translate && placeholder ? intl.formatMessage({ id: placeholder }) : placeholder;

  const components: Components = {
    DropdownIndicator: ArrowRenderer,
    LoadingIndicator: LoadingRenderer,
  };

  let fieldClassName = className;

  if (disabled) {
    fieldClassName += ' eop-input-disabled';
    components.DropdownIndicator = LockerRenderer;
  }

  if (fieldNoClear) {
    components.ClearIndicator = NullRenderer;
  }

  const change = (v: MultiValue<SelectValue>|SingleValue<SelectValue>) => {
    if (onChange) {
      return onChange(v);
    }
    if (multi && isSelectValueArray(v)) {
      const valuesArray = v.map(val => {
        if (simpleValue) {
          return val;
        }
        return isSelectValueObj(val) ? val.value : val;
      });
      return field.onChange(valuesArray);
    }
    const result = isSelectValueObj(v) ? v.value : v;
    return field.onChange(result !== undefined ? v : null);
  };

  if (creatable) {
    return (
      <Creatable
        className={ fieldClassName }
        name={ name }
        defaultValue={ defaultValue }
        value={ field.value }
        options={ options }
        placeholder={ fieldPlacheholder }
        isSearchable={ searchable }
        onChange={ change }
        isDisabled={ disabled }
        components={ components }
        isMulti={ multi }
      />
    );
  }

  if (loadAsyncOptions) {
    return (
      <AsyncSelectPaginate
        value={ field.value }
        name={ name }
        onChange={ change }
        loadAsyncOptions={ loadAsyncOptions }
        type={ type }
        placeholder={ placeholder }
        className={ fieldClassName }
        multi={ multi }
        formatOptions={ formatOptions }
        disabled={ disabled }
        fieldNoClear={ fieldNoClear }
        cacheUniqs={ cacheUniqs }
      />
    );
  }

  return loadOptions
    ? (
      <AsyncPaginate
        name={ name }
        value={ field.value }
        onChange={ change }
        placeholder={ fieldPlacheholder }
        loadOptions={ loadOptions }
        options={ options }
        key={ name }
        className='w-100'
        isMulti={ multi }
        components={ components }
        isDisabled={ disabled }
        cacheUniqs={ cacheUniqs }
        debounceTimeout={ debounceTimeout }
      />
    )
    : (
      <Select
        name={ name }
        value={ field.value }
        defaultValue={ defaultValue }
        onChange={ change }
        noResultsText={ noResults }
        options={ options }
        placeholder={ fieldPlacheholder }
        searchable={ searchable }
        className={ `w-100 ${ fieldClassName }` }
        disabled={ disabled }
        multi={ multi }
        simpleValue={ simpleValue }
        clearable={ !fieldNoClear }
        components={ components }
      />
    );
};
