import React from 'react';
import * as PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { injectIntl } from 'react-intl';
import { ReactTooltipStyled, TooltipContainer } from '@src/_v2/js/components/tooltips/EopTooltip/style';

const CircleAvatar = (props) => {
  const {
    src,
    alt,
    className,
    tooltipText,
    text,
    intl,
    unsetMinHeight,
  } = props;

  return (
    <div
      className={ ClassNames('circle-avatar', className) }
      data-tip
      data-for={ tooltipText }
    >
      <img
        className={ unsetMinHeight ? 'avatar avatar_widget' : 'avatar' }
        alt={ intl.formatMessage({ id: alt }) }
        src={ src }
      />
      {tooltipText && (
        <ReactTooltipStyled id={ tooltipText } effect='solid'>
          <TooltipContainer>
            { text ? tooltipText : intl.formatMessage({ id: tooltipText }) }
          </TooltipContainer>
        </ReactTooltipStyled>
      )}
    </div>
  );
};

CircleAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  intl: PropTypes.object,
  className: PropTypes.string,
  tooltipText: PropTypes.string,
  text: PropTypes.bool,
  unsetMinHeight: PropTypes.bool,
};

CircleAvatar.defaultProps = {
  alt: 'avatar',
  text: false,
  unsetMinHeight: false,
};

export default injectIntl(CircleAvatar);
