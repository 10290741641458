import { GET_BANNERS_ERROR, GET_BANNERS_START, GET_BANNERS_SUCCESS } from '@src/js/actions/banners';

const initialState = {
  loading: false,
  error: null,
  banners: {},
};

const actionsMap = {
  [GET_BANNERS_START]: (state, action) => ({
    ...state,
    banners: {
      ...state.banners,
      [action.bannerType]: {
        loading: true,
        error: null,
        data: null,
      },
    },
  }),
  [GET_BANNERS_ERROR]: (state, action) => ({
    ...state,
    [action.bannerType]: {
      loading: false,
      error: action.error.message,
      data: null,
    },
  }),
  [GET_BANNERS_SUCCESS]: (state, action) => ({
    ...state,
    banners: {
      ...state.banners,
      [action.bannerType]: {
        data: action.data,
        loading: false,
        error: null,
      },
    },
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
