import { takeLatest, call, put } from 'redux-saga/effects';

import {
  CREATE_TASK_SUCCESS,
  CREATE_TASK_START,
  CREATE_TASK_ERROR,
  GET_TASKS_ERROR,
  GET_TASKS_START,
  GET_TASKS_SUCCESS,
  GET_TASK_ERROR,
  GET_TASK_START,
  GET_TASK_SUCCESS,
  GET_TASK_COMMENTS_ERROR,
  GET_TASK_COMMENTS_START,
  GET_TASK_COMMENTS_SUCCESS,
  GET_TASKS_PERMISSIONS_START,
  GET_TASKS_PERMISSIONS_ERROR,
  GET_TASKS_PERMISSIONS_SUCCESS,
  SET_TASK_STATUS_START,
  SET_TASK_STATUS_ERROR,
  SET_TASK_STATUS_SUCCESS,
  GET_TASK_DOCUMENTS_START,
  GET_TASK_DOCUMENTS_ERROR,
  GET_TASK_DOCUMENTS_SUCCESS,
  REMOVE_TASK_DOCUMENT_SUCCESS,
  REMOVE_TASK_DOCUMENT_ERROR,
  REMOVE_TASK_DOCUMENT_START,
  ADD_TASK_DOCUMENT_SUCCESS,
  ADD_TASK_DOCUMENT_ERROR,
  ADD_TASK_DOCUMENT_START,
  GET_TASKS_HEADER_SUCCESS,
  GET_TASKS_HEADER_ERROR,
  GET_TASKS_HEADER_START,
  ADD_TASK_COMMENT_SUCCESS,
  ADD_TASK_COMMENT_ERROR,
  ADD_TASK_COMMENT_START,
  GET_COMPANIES_FOR_TASK_CREATION_START,
  GET_COMPANIES_FOR_TASK_CREATION_ERROR,
  GET_COMPANIES_FOR_TASK_CREATION_SUCCESS,
  GET_COMPANIES_SLUG_FOR_TASK_CREATION_START,
  GET_COMPANIES_SLUG_FOR_TASK_CREATION_ERROR,
  GET_COMPANIES_SLUG_FOR_TASK_CREATION_SUCCESS,
  GET_TASK_COMPANIES_LIST_START,
  GET_TASK_COMPANIES_LIST_SUCCESS,
  GET_TASK_COMPANIES_LIST_ERROR,
} from '@src/js/actions/tasks/task';

import * as api from '@src/js/api/tasks/tasks';

function* createTask(options) {
  try {
    let data;
    if (options?.task?.values?.hasMultipleCompanies === true
      || options?.task?.values?.additional_companies?.length > 0) {
      data = yield call(() => api.createMultipleTask(options.task.values));
    } else {
      data = yield call(() => api.createTask(options.task.values));
    }
    yield put({ type: CREATE_TASK_SUCCESS, data });
    options.task.resolve();
  } catch (error) {
    yield put({ type: CREATE_TASK_ERROR, error });
    options.task.reject(error.response);
  }
}

function* getCompaniesForTaskCreation(options) {
  try {
    const data = yield call(() => api.getCompaniesForTaskCreation(options.filter));
    yield put({ type: GET_COMPANIES_FOR_TASK_CREATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANIES_FOR_TASK_CREATION_ERROR, error });
  }
}

function* getCompaniesSlugsForTaskCreation(options) {
  try {
    const data = yield call(() => api.getCompaniesSlugsForTaskCreation(options.filter));
    yield put({ type: GET_COMPANIES_SLUG_FOR_TASK_CREATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_COMPANIES_SLUG_FOR_TASK_CREATION_ERROR, error });
  }
}

function* getTaskCompaniesList(options) {
  try {
    const data = yield call(() => api.getTaskCompaniesList(
      options.taskSlug, options.companiesSlugs, options.page, options.searchParam
    ));
    yield put({ type: GET_TASK_COMPANIES_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASK_COMPANIES_LIST_ERROR, error });
  }
}

function* getTaskDocuments(options) {
  try {
    const data = yield call(() => api.getTaskDocuments(options.documentsIds));
    yield put({ type: GET_TASK_DOCUMENTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASK_DOCUMENTS_ERROR, error });
  }
}

function* addTaskDocument(options) {
  try {
    const data = yield call(() => api.addTaskDocument(options.requestData));
    yield put({ type: ADD_TASK_DOCUMENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADD_TASK_DOCUMENT_ERROR, error });
  }
}

function* removeTaskDocument(options) {
  try {
    const data = yield call(() => api.removeTaskDocument(options.documentId));
    yield put({ type: REMOVE_TASK_DOCUMENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: REMOVE_TASK_DOCUMENT_ERROR, error });
  }
}

function* getTaskList(options) {
  try {
    const data = yield call(() => api.getTaskList(options.filters));
    yield put({ type: GET_TASKS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASKS_ERROR, error });
  }
}

function* getTaskPermissions(options) {
  try {
    const data = yield call(
      () => api.getTaskPermissions(options.company)
    );
    yield put({ type: GET_TASKS_PERMISSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASKS_PERMISSIONS_ERROR, error });
  }
}

function* setTaskStatus(options) {
  try {
    let data;
    if (options?.task?.hasMultipleCompanies === true && options.statusName === 'delete') {
      data = api.changeStateMultipleTask(options.task);
    } else {
      data = api.setTaskStatus(options.task.slug, options.statusName);
    }
    yield put({ type: SET_TASK_STATUS_SUCCESS, data });
  } catch (error) {
    yield put({ type: SET_TASK_STATUS_ERROR, error });
  }
}

function* getTaskData(options) {
  try {
    const data = yield call(
      () => api.getTaskData(options.task)
    );
    yield put({ type: GET_TASK_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASK_ERROR, error });
  }
}

function* getHeaderData() {
  try {
    const data = yield call(
      () => api.getHeaderData()
    );
    yield put({ type: GET_TASKS_HEADER_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASKS_HEADER_ERROR, error });
  }
}
function* getTaskComments(options) {
  try {
    const data = yield call(
      () => api.getTaskComments(options.task)
    );
    yield put({ type: GET_TASK_COMMENTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TASK_COMMENTS_ERROR, error });
  }
}

function* addTaskComment(options) {
  try {
    const data = yield call(
      () => api.addTaskComment(options.task, options.comment)
    );
    yield put({ type: ADD_TASK_COMMENT_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADD_TASK_COMMENT_ERROR, error });
  }
}

function* createTaskWatcher() {
  yield takeLatest(CREATE_TASK_START, createTask);
}

function* getCompaniesForTaskCreationWatcher() {
  yield takeLatest(GET_COMPANIES_FOR_TASK_CREATION_START, getCompaniesForTaskCreation);
}

function* getCompaniesSlugsForTaskCreationWatcher() {
  yield takeLatest(GET_COMPANIES_SLUG_FOR_TASK_CREATION_START, getCompaniesSlugsForTaskCreation);
}

function* getTaskCompaniesListWatcher() {
  yield takeLatest(GET_TASK_COMPANIES_LIST_START, getTaskCompaniesList);
}

function* getTaskDocumentsWatcher() {
  yield takeLatest(GET_TASK_DOCUMENTS_START, getTaskDocuments);
}

function* addTaskDocumentWatcher() {
  yield takeLatest(ADD_TASK_DOCUMENT_START, addTaskDocument);
}

function* removeTaskDocumentWatcher() {
  yield takeLatest(REMOVE_TASK_DOCUMENT_START, removeTaskDocument);
}

function* getTaskListWatcher() {
  yield takeLatest(GET_TASKS_START, getTaskList);
}

function* getTaskPermissionsWatcher() {
  yield takeLatest(GET_TASKS_PERMISSIONS_START, getTaskPermissions);
}

function* setTaskStatusWatcher() {
  yield takeLatest(SET_TASK_STATUS_START, setTaskStatus);
}

function* getTaskDataWatcher() {
  yield takeLatest(GET_TASK_START, getTaskData);
}

function* getHeaderDataWatcher() {
  yield takeLatest(GET_TASKS_HEADER_START, getHeaderData);
}

function* getTaskCommentsWatcher() {
  yield takeLatest(GET_TASK_COMMENTS_START, getTaskComments);
}

function* addTaskCommentWatcher() {
  yield takeLatest(ADD_TASK_COMMENT_START, addTaskComment);
}

export default [
  createTaskWatcher(),
  getTaskDocumentsWatcher(),
  addTaskDocumentWatcher(),
  removeTaskDocumentWatcher(),
  getTaskListWatcher(),
  getTaskPermissionsWatcher(),
  setTaskStatusWatcher(),
  getTaskDataWatcher(),
  getHeaderDataWatcher(),
  getTaskCommentsWatcher(),
  addTaskCommentWatcher(),
  getCompaniesForTaskCreationWatcher(),
  getCompaniesSlugsForTaskCreationWatcher(),
  getTaskCompaniesListWatcher(),
];
