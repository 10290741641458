import { EventFormObj } from '@app/models/Event';
import { AdminEventFilters } from '@src/js/reducers/events/adminEvent';
import { Action } from 'redux';

export const ADMIN_CREATE_EVENT_START = 'ADMIN_CREATE_EVENT_START';
export const ADMIN_CREATE_EVENT_ERROR = 'ADMIN_CREATE_EVENT_ERROR';
export const ADMIN_CREATE_EVENT_SUCCESS = 'ADMIN_CREATE_EVENT_SUCCESS';
export const ADMIN_EDIT_EVENT_START = 'ADMIN_EDIT_EVENT_START';
export const ADMIN_EDIT_EVENT_ERROR = 'ADMIN_EDIT_EVENT_ERROR';
export const ADMIN_EDIT_EVENT_SUCCESS = 'ADMIN_EDIT_EVENT_SUCCESS';
export const ADMIN_DISABLE_EVENT_START = 'ADMIN_DISABLE_EVENT_START';
export const ADMIN_DISABLE_EVENT_ERROR = 'ADMIN_DISABLE_EVENT_ERROR';
export const ADMIN_DISABLE_EVENT_SUCCESS = 'ADMIN_DISABLE_EVENT_SUCCESS';
export const ADMIN_PUBLISH_EVENT_START = 'ADMIN_PUBLISH_EVENT_START';
export const ADMIN_PUBLISH_EVENT_ERROR = 'ADMIN_PUBLISH_EVENT_ERROR';
export const ADMIN_PUBLISH_EVENT_SUCCESS = 'ADMIN_PUBLISH_EVENT_SUCCESS';
export const ADMIN_COPY_EVENT_START = 'ADMIN_COPY_EVENT_START';
export const ADMIN_COPY_EVENT_ERROR = 'ADMIN_COPY_EVENT_ERROR';
export const ADMIN_COPY_EVENT_SUCCESS = 'ADMIN_COPY_EVENT_SUCCESS';
export const ADMIN_SET_AS_FEATURED_EVENT_START = 'ADMIN_SET_AS_FEATURED_EVENT_START';
export const ADMIN_SET_AS_FEATURED_EVENT_ERROR = 'ADMIN_SET_AS_FEATURED_EVENT_ERROR';
export const ADMIN_SET_AS_FEATURED_EVENT_SUCCESS = 'ADMIN_SET_AS_FEATURED_EVENT_SUCCESS';
export const ADMIN_SEND_TEST_REMINDER_START = 'ADMIN_SEND_TEST_REMINDER_START';
export const ADMIN_SEND_TEST_REMINDER_ERROR = 'ADMIN_SEND_TEST_REMINDER_ERROR';
export const ADMIN_SEND_TEST_REMINDER_SUCCESS = 'ADMIN_SEND_TEST_REMINDER_SUCCESS';
export const ADMIN_EVENT_SET_FILTERS = 'ADMIN_EVENT_SET_FILTERS';
export const ADMIN_RELOAD_EVENT = 'ADMIN_RELOAD_EVENT';
export const ADMIN_RESET_EVENT = 'ADMIN_RESET_EVENT';

export interface AdminEventAction extends Action {
  slug: string
}

export interface AdminEditEventAction extends AdminEventAction {
  event: EventFormObj
}

export interface AdminCreateEventAction extends Action {
  event: EventFormObj,
}

export interface AdminSendEventTestReminderAction extends AdminEventAction {
  templateId: string;
  submitData: object;
}

export interface AdminSetFiltersEventAction extends Action {
  filters: AdminEventFilters,
}

export function editEvent(event: EventFormObj, slug: string): AdminEditEventAction {
  return {
    type: ADMIN_EDIT_EVENT_START,
    event,
    slug,
  };
}

export function createEvent(event: EventFormObj): AdminCreateEventAction {
  return {
    type: ADMIN_CREATE_EVENT_START,
    event,
  };
}

export function disableEvent(slug: string): AdminEventAction {
  return {
    type: ADMIN_DISABLE_EVENT_START,
    slug,
  };
}

export function publishEvent(slug: string): AdminEventAction {
  return {
    type: ADMIN_PUBLISH_EVENT_START,
    slug,
  };
}

export function copyEvent(slug: string): AdminEventAction {
  return {
    type: ADMIN_COPY_EVENT_START,
    slug,
  };
}

export function featuredEvent(slug: string): AdminEventAction {
  return {
    type: ADMIN_SET_AS_FEATURED_EVENT_START,
    slug,
  };
}

export function sendEventTestReminder(
  slug: string,
  templateId: string,
  submitData: object
): AdminSendEventTestReminderAction {
  return {
    type: ADMIN_SEND_TEST_REMINDER_START,
    slug,
    templateId,
    submitData,
  };
}

export function setFilters(filters: AdminEventFilters): AdminSetFiltersEventAction {
  return {
    type: ADMIN_EVENT_SET_FILTERS,
    filters,
  };
}
