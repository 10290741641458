import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import {
  Container,
  NavLinkStyled,
} from './style';

export const BUTTON_LINK_TYPE_DISABLED = 'disabled';
export const BUTTON_LINK_TYPE_RED = 'red';
export const BUTTON_LINK_TYPE_GREEN = 'green';
export const BUTTON_LINK_TYPE_DEFAULT = 'default';

const ButtonLink = (props) => {
  const {
    className,
    text,
    type,
    disabled,
    to,
    onClick,
  } = props;

  return (
    <NavLinkStyled exact strict to={ !disabled && to ? to : '#' }>
      <Container type={ type } disabled={ disabled } onClick={ onClick } className={ className }>
        { <FormattedMessage id={ text } defaultMessage={ text } /> }
      </Container>
    </NavLinkStyled>
  );
};

ButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'red', 'green', 'disabled']),
};

export default ButtonLink;
