import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getAdminOpportunities(filters) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/admin/products`;
  if (filters) {
    const query = new URLSearchParams(filters);
    url = `${ url }?${ query }`;
  }

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getAdminRequests(filters) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/admin/requests`;
  if (filters) {
    const query = new URLSearchParams(filters);
    url = `${ url }?${ query }`;
  }

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getAdminTypes(filters) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/admin/type-admins`;
  if (filters) {
    const query = new URLSearchParams(filters);
    url = `${ url }?${ query }`;
  }

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getAdminStatistics() {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_API_PATH') }/admin/statistics`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function adminProductStatusUpdate(options) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_API_PATH') }/admin/product/${ options.productId }/update`, {
    method: 'PATCH',
    body: options,
    externalUrl: true,
  });
}

export function adminRequestStatusUpdate(options) {
  return fetchResource(`${ loadEnvVariable('MARKETPLACE_API_PATH') }/admin/request/${ options.requestId }/update`, {
    method: 'POST',
    body: options,
    externalUrl: true,
  });
}
