import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { Label, RequiredIcon, Warning } from '@src/js/components/global/inputs/style';
import { EopCheckboxGrid } from './style';

export default class CheckboxGrid extends Component {
  static propTypes = {
    checked: PropTypes.array,
    options: PropTypes.array,
    fieldLabel: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    fieldRequired: PropTypes.bool,
    fieldDisabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  /**
   * CheckboxTree returns values as array with string values
   * @param values
   */
  setValues = (values) => {
    const { onChange } = this.props;
    const numericValues = values.map(dimension => Number(dimension));

    onChange(numericValues);
  };

  render() {
    const {
      options,
      fieldName,
      fieldLabel,
      fieldRequired,
      fieldDisabled,
      checked,
    } = this.props;

    return (
      <EopCheckboxGrid>
        { fieldLabel
        && (
          <Label>
            { fieldRequired && <RequiredIcon /> }
            <FormattedMessage id={ fieldLabel } />
          </Label>
        ) }
        { options
        && (
          <CheckboxTree
            name={ fieldName }
            nodes={ options }
            checked={ checked }
            onCheck={ this.setValues }
            disabled={ fieldDisabled }
          />
        )}
        { fieldRequired && checked && checked.length === 0
        && (
          <Warning>
            <FormattedMessage id='global_form.error.required' />
          </Warning>
        ) }
      </EopCheckboxGrid>
    );
  }
}
