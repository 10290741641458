import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import * as api from '@app/store/company/api';
import {
  GET_MEMBERS_LIST_START,
  GET_MEMBERS_LIST_FAIL,
  GET_MEMBERS_LIST_SUCCESS,
  SEND_SIGN_DOCUMENT_START,
  SEND_SIGN_DOCUMENT_FAIL,
  SEND_SIGN_DOCUMENT_SUCCESS,
  SIGN_DOCUMENT_START,
  SIGN_DOCUMENT_FAIL,
  SIGN_DOCUMENT_SUCCESS,
  GET_SIGN_DOCUMENT_START,
  GET_SIGN_DOCUMENT_FAIL,
  GET_SIGN_DOCUMENT_SUCCESS,
} from '@app/store/company/actions';

function* getCompanyMembers({ slug, filters }) {
  try {
    const result = yield call(() => api.getCompanyMembers(slug, filters));
    yield put({ type: GET_MEMBERS_LIST_SUCCESS, result, reset: filters?.page === 1 });
  } catch (error) {
    yield all([
      put({ type: GET_MEMBERS_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* sendSignDocument({ id, uuid }) {
  try {
    yield call(() => api.sendSignDocument(id, uuid));
    yield all([
      put({ type: SEND_SIGN_DOCUMENT_SUCCESS }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: SEND_SIGN_DOCUMENT_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* signDocument({ mobilePhone, uuid }) {
  try {
    const result = yield call(() => api.signDocument(mobilePhone, uuid));
    yield put({ type: SIGN_DOCUMENT_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: SIGN_DOCUMENT_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getSignDocument({ uuid }) {
  try {
    const result = yield call(() => api.getSignDocument(uuid));
    yield put({ type: GET_SIGN_DOCUMENT_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_SIGN_DOCUMENT_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(GET_MEMBERS_LIST_START, getCompanyMembers);
  yield takeLatest(SEND_SIGN_DOCUMENT_START, sendSignDocument);
  yield takeLatest(SIGN_DOCUMENT_START, signDocument);
  yield takeLatest(GET_SIGN_DOCUMENT_START, getSignDocument);
}

export default root;
