import { createSelector } from 'reselect';
import { initialState as initialStateInitInfo } from '@src/js/reducers/initInfo';
import { initialState } from '@app/store/etinerary/reducer';
import { initialState as initialStateUploadFile } from '@src/js/reducers/file/uploadFile';

const selectInitInfo = (state) => state.initInfo || initialStateInitInfo;

const selectEtinerary = (state) => state.etinerary || initialState;

const selectUploadFile = (state) => state.uploadFile || initialStateUploadFile;

const makeSelectLoading = () => createSelector(selectEtinerary, (substate) => !!substate.loading);

const makeSelectError = () => createSelector(selectEtinerary, (substate) => substate.error);

const makeSelectData = () => createSelector(selectEtinerary, (substate) => substate.data);

const makeSelectRequestSent = () => createSelector(selectEtinerary, (substate) => substate.requestSent);

const makeSelectUser = () => createSelector(selectInitInfo, (substate) => substate.userInfo);

const makeSelectUploadFile = () => createSelector(selectUploadFile, (substate) => substate.file);

export {
  makeSelectLoading,
  makeSelectError,
  makeSelectData,
  makeSelectRequestSent,
  makeSelectUser,
  makeSelectUploadFile,
};
