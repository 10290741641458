import React from 'react';
import UserBasicInfo from '@app/components/public/user/UserBasicInfo';
import { FormType } from '@app/components/public/user/UserProfile';
import UserRoles from '@app/components/public/user/UserRoles';
import UserSessions from '@app/components/public/user/UserSessions';
import UserPreferences from '@app/components/public/user/UserPreferences';

export const userTabs = (formType: FormType) => {
  const tabs = [
    {
      title: `user_${ formType === 'create' ? 'create' : 'edit' }Profile`,
      icon: 'Edit',
      component: <UserBasicInfo formType={ formType } />,
    },
  ];

  if (formType === 'my_profile') {
    tabs.push(
      {
        title: 'user_preferences',
        icon: 'Settings',
        component: <UserPreferences />,
      },
      {
        title: 'user_sessions',
        icon: 'Settings',
        component: <UserSessions />,
      }
    );
  } else {
    tabs.push({
      title: 'user_userRoles',
      icon: 'Users',
      component: <UserRoles />,
    });
  }

  return tabs;
};
