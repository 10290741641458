export const GET_PARTNER_PERMISSIONS_START = 'GET_PARTNER_PERMISSIONS_START';
export const GET_PARTNER_PERMISSIONS_ERROR = 'GET_PARTNER_PERMISSIONS_ERROR';
export const GET_PARTNER_PERMISSIONS_SUCCESS = 'GET_PARTNER_PERMISSIONS_SUCCESS';

export function getPartnerPermissions(slug, source) {
  return {
    type: GET_PARTNER_PERMISSIONS_START,
    slug,
    source,
  };
}
