import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getBroker(brokerId) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/brokers/${ brokerId }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function createBroker(data) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/brokers`, {
    method: 'POST',
    body: { ...data },
    externalUrl: true,
  });
}

export function updateBroker(id, data) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/brokers/${ id }`, {
    method: 'PUT',
    body: { ...data },
    externalUrl: true,
  });
}

export function getBrokerList(params) {
  const query = new URLSearchParams(params);
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/brokers?${ query }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function deleteBroker(id) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/brokers/${ id }`, {
    method: 'DELETE',
    externalUrl: true,
  });
}
