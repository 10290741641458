import fetchResource from '@src/js/api/fetch-resource';

export function createEvent(event) {
  return fetchResource('api/admin/events', {
    method: 'POST',
    body: event,
  });
}

export function disableEvent(slug) {
  return fetchResource(`api/admin/events/${ slug }`, {
    method: 'DELETE',
    slug,
  });
}

export function publishEvent(slug) {
  return fetchResource(`api/admin/events/${ slug }/publish`, {
    method: 'PUT',
    slug,
  });
}

export function copyEvent(slug) {
  return fetchResource('api/admin/events/copy', {
    method: 'POST',
    body: slug,
  });
}

export function featuredEvent(slug) {
  return fetchResource(`api/admin/events/${ slug }/featured`, {
    method: 'PUT',
    slug,
  });
}

export function editEvent(event, slug) {
  return fetchResource(`api/admin/events/${ slug }`, {
    method: 'PUT',
    'body': event,
  });
}

export function getEventFormOptions(slug) {
  let url = 'api/admin/events/form-options';

  if (slug) {
    url = `${ url }/${ slug }`;
  }

  return fetchResource(url);
}

export function sendEventTestReminder(slug, templateId, body) {
  return fetchResource(`api/event/${ slug }/reminder/${ templateId }/test`, {
    method: 'POST',
    body,
  });
}
