import * as Actions from '@app/store/event/actions';
import * as api from '@app/store/event/api';
import { EventAction } from '@app/store/event/reducer';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

function* getEvent({ slug }: EventAction): Generator {
  try {
    const event = yield call(() => api.getEvent(slug));
    yield put({ type: Actions.GET_EVENT_SUCCESS, event });
  } catch (error) {
    yield all([
      put({ type: Actions.GET_EVENT_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* getReminderTemplate({ slug, id }: EventAction): Generator {
  try {
    const template = yield call(() => api.getReminderTemplate(slug, id));
    yield put({ type: Actions.GET_REMINDER_TEMPLATE_SUCCESS, template });
  } catch (error) {
    yield all([
      put({ type: Actions.GET_REMINDER_TEMPLATE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* reminderTemplateList(action: EventAction): Generator {
  try {
    const data = yield call(() => api.getReminderTemplateList(action));
    yield put({ type: Actions.REMINDER_TEMPLATE_LIST_SUCCESS, data });
  } catch (error) {
    yield all([
      put({ type: Actions.REMINDER_TEMPLATE_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* createReminderTemplate({ slug, template }: EventAction): Generator {
  try {
    yield call(() => api.createReminderTemplate(slug, template));
    yield all([
      put({ type: Actions.CREATE_REMINDER_TEMPLATE_SUCCESS }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: Actions.CREATE_REMINDER_TEMPLATE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* editReminderTemplate({ slug, template }: EventAction): Generator {
  try {
    yield call(() => api.editReminderTemplate(slug, template));
    yield all([
      put({ type: Actions.EDIT_REMINDER_TEMPLATE_SUCCESS }),
      put(showToastrSuccess()),
    ]);
  } catch (error) {
    yield all([
      put({ type: Actions.EDIT_REMINDER_TEMPLATE_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* root() {
  yield takeLatest(Actions.GET_EVENT_START, getEvent);
  yield takeLatest(Actions.REMINDER_TEMPLATE_LIST_START, reminderTemplateList);
  yield takeLatest(Actions.GET_REMINDER_TEMPLATE_START, getReminderTemplate);
  yield takeLatest(Actions.CREATE_REMINDER_TEMPLATE_START, createReminderTemplate);
  yield takeLatest(Actions.EDIT_REMINDER_TEMPLATE_START, editReminderTemplate);
}

export default root;
