import { all, fork } from 'redux-saga/effects';
import fundingSagas from '@app/store/funding/sagas';
/* eslint-disable import/max-dependencies */
import etinerarySagas from '@app/store/etinerary/sagas';
import translationsSagas from '@app/store/translations/sagas/index';
import broker from '@app/store/funding/sagas/broker';
import deal from '@app/store/funding/sagas/deal';
import parametersPartnersSagas from '@app/store/funding/sagas/partner';
import file from '@app/store/global/sagas';
import compliance from '@app/store/compliance/sagas';
import businessNeedTable from '@src/js/marketplace/sagas/admin/businessNeedTable';
import dataroom from '@app/store/documentsArea/sagas';
import onboarding from '@app/store/onboarding/sagas';
import library from '@app/store/library/sagas';
import taxonomy from '@app/store/taxonomy/sagas';
import programme from '@app/store/programme/sagas';
import reminderArchive from '@app/store/reminderArchive/sagas';
import customReminder from '@app/store/customReminder/sagas';
import eventAttendance from '@app/store/eventAttendance/sagas';
import companiesMembers from '@app/store/company/sagas';
import network from '@app/store/network/sagas';
import promotedContent from '@app/store/content/promotedContent/sagas';
import topic from '@app/store/content/topic/sagas';
import libraryPath from '@app/store/content/libraryPath/sagas';
import marketplaceDashboard from '@src/js/marketplace/sagas/marketplaceDashboard';
import marketplaceSagas from '@src/js/marketplace/sagas/index';
import membershipSagas from '@src/js/oneplatform/membership/pages/home/container/ducks/saga';
import membershipFormSagas from '@src/js/oneplatform/membership/pages/form/container/ducks/saga';
import trainingSagas from '@src/js/oneplatform/trainings/container/ducks/saga';
import event from '@app/store/event/sagas';
import privateMarketTemplateSagas from '@src/js/oneplatform/privateMarketNetwork/container/ducks/saga';
import companyWidget from '@app/store/companyWidget/sagas';
import bannerContent from '@app/store/content/banner/sagas';
import adminUserSagas from './user/adminUser';
import adminCompaniesSagas from './company/adminCompanies';
import adminCompanySagas from './company/adminCompany';
import relationshipManagerCompanySagas from './company/relationshipManagerCompany';
import adminNewsSagas from './news/adminNews';
import adminNewsSingleSagas from './news/adminNewsSingle';
import adminLibrarySagas from './library/adminLibrary';
import adminLibrarySingleSagas from './library/adminLibrarySingle';
import programmesSagas from './programme/programmes';
import programmeSagas from './programme/programme';
import relationManagersSaga from './user/getRelationManagers';
import contactUserSaga from './user/getContactUser';
import adminUsersSagas from './user/adminUsers';
import usersSagas from './user/users';
import userSagas from './user/user';
import companySagas from './company/company';
import companyFormOptionsSagas from './company/companyFormOptions';
import externalCompanyFormOptionsSagas from './self-registration/company/externalCompanyFormOptions';
import companyDocumentsFilesSagas from './company/companyDocumentsFiles';
import companyDocumentsUploadSagas from './company/companyDocumentsUpload';
import companyDocumentsDeleteSagas from './company/companyDocumentsDelete';
import companyCertificationSagas from './company/companyCertification';
import companyCourseraAccessSagas from './company/companyCourseraAccess';
import companyDocumentsPermissionsSagas from './company/companyDocumentsPermissions';
import companyContactMessageSend from './company/companyContactMessageSend';
import partnersSagas from './partner/partners';
import partnerSagas from './partner/partner';
import tasksSagas from './tasks/tasks';
import taskSetUserPermissionsSagas from './tasks/taskSetUserPermissions';
import taskGetUserPermissionsSagas from './tasks/taskGetUserPermissions';
import adminEventsSagas from './events/adminEvents';
import adminEventSagas from './events/adminEvent';
import adminEventAttendingCompanies from './events/adminEventAttendingCompanies';
import adminEventFormOptionsSagas from './events/adminEventFormOptions';
import eventsSagas from './events/events';
import uploadImageSagas from './image/uploadImage';
import uploadFileSagas from './file/uploadFile';
import deleteImageSagas from './image/deleteImage';
import userImageSagas from './image/userImage';
import bannerSagas from './banners';
import searchSagas from './search';
import countriesSagas from './taxonomy/countries';
import turnoverSagas from './taxonomy/turnovers';
import currenciesSagas from './taxonomy/currencies';
import sectorSagas from './taxonomy/sectors';
import industriesSagas from './taxonomy/industries';
import formOptionsSagas from './user/formOptions';
import tokenValidation from './tokenValidation';
import partnerFormOptionsSagas from './partner/partnerFormOptions';
import navigationSagas from './navigation/navigation';
import initInfo from './initInfo';
import elasticsearch from './elasticsearch';
import elasticsearchHeader from './elasticsearchHeader';
import widget from './widget';
import companyPermissions from './company/companyPermissions';
import partnerBasicData from './partner/partnerBasicData';
import partnerPermissions from './partner/partnerPermissions';
import partnerDocumentsFiles from './partner/partnerDocumentsFiles';
import partnerDocumentsPermissions from './partner/partnerDocumentsPermissions';
import partnerDocumentsUpload from './partner/partnerDocumentsUpload';
import partnerDocumentDelete from './partner/partnerDocumentDelete';
import partnerCompanies from './partner/partnerCompanies';
import partnerCourseraAccess from './partner/partnerCourseraAccess';
import newsIndex from './news/newsIndex';
import newsList from './news/newsList';
import singleNews from './news/singleNews';
import widgetNews from './news/widgetNews';
import createNews from './news/createNews';
import editNews from './news/editNews';
import getNewsOptions from './news/getNewsOptions';
import createLibrary from './library/createLibrary';
import editLibrary from './library/editLibrary';
import getLibraryOptions from './library/getLibraryOptions';
import partnerCreateLibrary from './library/partnerCreateLibrary';
import partnerEditLibrary from './library/partnerEditLibrary';
import partnerGetLibraryOptions from './library/partnerGetLibraryOptions';
import indexLibrary from './library/indexLibrary';
import featuredLibrary from './library/featuredLibrary';
import singleLibrary from './library/singleLibrary';
import partnerLibraries from './library/partnerLibraries';
import companyManager from './company/companyManager';
import getOpportunitiesForCompany from './marketplace/getOpportunitiesForCompany';
import networking from './networking/networking';
import getIndexProcesses from './onboarding/processes/indexProcesses';
import adminTemplateIndex from './onboarding/templates/admin/adminTemplateIndex';
import adminCreateTemplate from './onboarding/templates/admin/adminCreateTemplate';
import uploadOnboardingImage from './onboarding/image/uploadOnboardingImage';
import uploadOnboardingFile from './onboarding/file/uploadOnboardingFile';
import onboardingProcess from './onboarding/onboardingProcess';
import onboardingTemplate from './onboarding/onboardingTemplate';
import onboardingBasic from './onboarding/onboardingBasic';
import onboardingRegister from './onboarding/onboardingRegister';
import adminTemplateOptions from './onboarding/templates/admin/adminTemplateOptions';
import getBulkMessageUsers from './bulkMessage/bulkMessageGetUsers';
import getBulkMessageCompanies from './bulkMessage/bulkMessageGetCompanies';
import getBulkMessageSender from './bulkMessage/bulkMessageGetSender';
import sendBulkMessage from './bulkMessage/sendBulkMessage';
import companyViews from './company/companyViews';
import getAllSessions from './session/getAllSessions';
import deleteSessions from './session/deleteSessions';
import partnerParametersVisibility from './partner/partnerParametersVisibility';

/* eslint-enable import/max-dependencies */
export default function* rootSaga() {
  yield all([
    ...adminUserSagas,
    ...adminCompaniesSagas,
    ...adminCompanySagas,
    ...relationshipManagerCompanySagas,
    ...adminUsersSagas,
    ...adminNewsSagas,
    ...adminNewsSingleSagas,
    ...adminLibrarySagas,
    ...adminLibrarySingleSagas,
    ...usersSagas,
    ...userSagas,
    ...userImageSagas,
    ...programmesSagas,
    ...tasksSagas,
    ...taskSetUserPermissionsSagas,
    ...taskGetUserPermissionsSagas,
    ...programmeSagas,
    ...relationManagersSaga,
    ...contactUserSaga,
    ...companySagas,
    ...companyFormOptionsSagas,
    ...externalCompanyFormOptionsSagas,
    ...companyDocumentsFilesSagas,
    ...companyDocumentsUploadSagas,
    ...companyDocumentsDeleteSagas,
    ...companyDocumentsPermissionsSagas,
    ...companyContactMessageSend,
    ...companyCertificationSagas,
    ...companyCourseraAccessSagas,
    ...companyManager,
    ...partnersSagas,
    ...adminEventAttendingCompanies,
    ...adminEventFormOptionsSagas,
    ...partnerSagas,
    ...uploadImageSagas,
    ...uploadFileSagas,
    ...deleteImageSagas,
    ...bannerSagas,
    ...searchSagas,
    ...countriesSagas,
    ...turnoverSagas,
    ...sectorSagas,
    ...industriesSagas,
    ...currenciesSagas,
    ...formOptionsSagas,
    ...tokenValidation,
    ...partnerFormOptionsSagas,
    ...partnerBasicData,
    ...partnerPermissions,
    ...partnerDocumentsFiles,
    ...partnerDocumentsPermissions,
    ...partnerDocumentsUpload,
    ...partnerDocumentDelete,
    ...partnerCourseraAccess,
    ...partnerCompanies,
    ...navigationSagas,
    ...initInfo,
    ...elasticsearch,
    ...elasticsearchHeader,
    ...widget,
    ...companyPermissions,
    ...newsIndex,
    ...newsList,
    ...singleNews,
    ...widgetNews,
    ...createNews,
    ...editNews,
    ...getNewsOptions,
    ...createLibrary,
    ...editLibrary,
    ...getLibraryOptions,
    ...partnerCreateLibrary,
    ...partnerEditLibrary,
    ...partnerGetLibraryOptions,
    ...getOpportunitiesForCompany,
    ...indexLibrary,
    ...featuredLibrary,
    ...singleLibrary,
    ...partnerLibraries,
    ...fundingSagas,
    ...networking,
    ...onboardingBasic,
    ...onboardingProcess,
    ...onboardingTemplate,
    ...onboardingRegister,
    ...getIndexProcesses,
    ...uploadOnboardingImage,
    ...uploadOnboardingFile,
    ...adminTemplateIndex,
    ...adminTemplateOptions,
    ...adminCreateTemplate,
    ...getBulkMessageUsers,
    ...getBulkMessageCompanies,
    ...getBulkMessageSender,
    ...sendBulkMessage,
    ...companyViews,
    ...broker,
    ...deal,
    ...getAllSessions,
    ...deleteSessions,
    ...marketplaceDashboard,
    ...marketplaceSagas,
    yield fork(adminEventsSagas),
    yield fork(adminEventSagas),
    yield fork(eventsSagas),
    yield fork(membershipSagas),
    yield fork(membershipFormSagas),
    yield fork(trainingSagas),
    yield fork(privateMarketTemplateSagas),
    yield fork(etinerarySagas),
    yield fork(translationsSagas),
    yield fork(partnerParametersVisibility),
    yield fork(parametersPartnersSagas),
    yield fork(businessNeedTable),
    yield fork(file),
    yield fork(compliance),
    yield fork(dataroom),
    yield fork(onboarding),
    yield fork(library),
    yield fork(taxonomy),
    yield fork(programme),
    yield fork(reminderArchive),
    yield fork(customReminder),
    yield fork(eventAttendance),
    yield fork(companiesMembers),
    yield fork(promotedContent),
    yield fork(network),
    yield fork(topic),
    yield fork(libraryPath),
    yield fork(event),
    yield fork(companyWidget),
    yield fork(bannerContent),
  ]);
}
