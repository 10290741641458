import {
  ADMIN_COPY_EVENT_ERROR,
  ADMIN_COPY_EVENT_START,
  ADMIN_COPY_EVENT_SUCCESS,
  ADMIN_CREATE_EVENT_ERROR,
  ADMIN_CREATE_EVENT_START,
  ADMIN_CREATE_EVENT_SUCCESS,
  ADMIN_DISABLE_EVENT_ERROR,
  ADMIN_DISABLE_EVENT_START,
  ADMIN_DISABLE_EVENT_SUCCESS,
  ADMIN_EDIT_EVENT_ERROR,
  ADMIN_EDIT_EVENT_START,
  ADMIN_EDIT_EVENT_SUCCESS,
  ADMIN_PUBLISH_EVENT_ERROR,
  ADMIN_PUBLISH_EVENT_START,
  ADMIN_PUBLISH_EVENT_SUCCESS,
  ADMIN_RESET_EVENT,
  ADMIN_SEND_TEST_REMINDER_ERROR,
  ADMIN_SEND_TEST_REMINDER_START,
  ADMIN_SEND_TEST_REMINDER_SUCCESS,
  ADMIN_SET_AS_FEATURED_EVENT_ERROR,
  ADMIN_SET_AS_FEATURED_EVENT_START,
  ADMIN_SET_AS_FEATURED_EVENT_SUCCESS,
  ADMIN_EVENT_SET_FILTERS,
} from '@src/js/actions/events/adminEvent';
import { Action } from 'redux';
import cookies from 'js-cookie';
import { EventFormObj } from '@app/models/Event';
import produce from 'immer';
import { ItemObj } from '@app/components/global/forms/select/select';

export type AdminEventState = {
  loading: boolean,
  error: unknown,
  event: null|EventFormObj,
  sendTestLoading: boolean,
  success: boolean,
  filters?: AdminEventFilters,
};

export type AdminEventFilters = {
  search: string;
  eventCreatedStart?: null|Date;
  eventCreatedEnd?: null|Date;
  programme: ItemObj<string>[];
  type: string;
  category?: string;
  location?: string;
  page: number;
  status?: 'published',
  limit?: number,
  excludedIds?: number[],
}

export const FILTERS_COOKIE_NAME = 'filterseventsEOP';

const cookie = JSON.parse(cookies.get(FILTERS_COOKIE_NAME) ?? '{}');
const initialFilters: AdminEventFilters = {
  search: cookie?.search || '',
  eventCreatedStart: cookie?.eventCreatedStart ? new Date(cookie.eventCreatedStart) : null,
  eventCreatedEnd: cookie?.eventCreatedEnd ? new Date(cookie.eventCreatedEnd) : null,
  programme: cookie?.programme || [],
  type: cookie?.type || '',
  category: cookie?.category || '',
  location: cookie?.location || '',
  page: cookie?.page || 1,
};

const initialState: AdminEventState = {
  loading: false,
  error: null,
  event: null,
  sendTestLoading: false,
  success: false,
  filters: initialFilters,
};

interface ActionPayload extends Action {
  status: string,
  error?: { response: string },
  data?: EventFormObj,
  filters?: AdminEventFilters
}

function adminEventReducer(state = initialState, action: ActionPayload) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case ADMIN_CREATE_EVENT_START:
      case ADMIN_EDIT_EVENT_START:
      case ADMIN_DISABLE_EVENT_START:
      case ADMIN_PUBLISH_EVENT_START:
      case ADMIN_COPY_EVENT_START:
      case ADMIN_SET_AS_FEATURED_EVENT_START:
        draft.loading = true;
        draft.error = null;
        draft.success = false;
        break;
      case ADMIN_CREATE_EVENT_ERROR:
      case ADMIN_EDIT_EVENT_ERROR:
      case ADMIN_DISABLE_EVENT_ERROR:
      case ADMIN_PUBLISH_EVENT_ERROR:
      case ADMIN_COPY_EVENT_ERROR:
      case ADMIN_SET_AS_FEATURED_EVENT_ERROR:
        draft.loading = false;
        draft.error = action.error?.response;
        draft.success = false;
        break;
      case ADMIN_DISABLE_EVENT_SUCCESS:
      case ADMIN_PUBLISH_EVENT_SUCCESS:
      case ADMIN_COPY_EVENT_SUCCESS:
      case ADMIN_SET_AS_FEATURED_EVENT_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.event = action.data || null;
        break;
      case ADMIN_CREATE_EVENT_SUCCESS:
      case ADMIN_EDIT_EVENT_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.event = action.data || null;
        draft.success = true;
        break;
      case ADMIN_SEND_TEST_REMINDER_START:
        draft.sendTestLoading = true;
        break;
      case ADMIN_SEND_TEST_REMINDER_ERROR:
      case ADMIN_SEND_TEST_REMINDER_SUCCESS:
        draft.sendTestLoading = false;
        break;
      case ADMIN_RESET_EVENT:
        draft.loading = false;
        draft.success = false;
        draft.error = null;
        draft.event = null;
        break;
      case ADMIN_EVENT_SET_FILTERS:
        draft.filters = action.filters;
        break;
      default:
        break;
    }
  });
}

export default adminEventReducer;
