export const GET_EXTERNAL_COMPANY_FORM_OPTIONS_START = 'GET_EXTERNAL_COMPANY_FORM_OPTIONS_START';
export const GET_EXTERNAL_COMPANY_FORM_OPTIONS_SUCCESS = 'GET_EXTERNAL_COMPANY_FORM_OPTIONS_SUCCESS';
export const GET_EXTERNAL_COMPANY_FORM_OPTIONS_ERROR = 'GET_EXTERNAL_COMPANY_FORM_OPTIONS_ERROR';
export const CLEAR_EXTERNAL_COMPANY_FORM_OPTIONS = 'CLEAR_EXTERNAL_COMPANY_FORM_OPTIONS';

export const SELF_REGISTRATION_CREATE_COMPANY_START = 'SELF_REGISTRATION_CREATE_COMPANY_START';
export const SELF_REGISTRATION_CREATE_COMPANY_ERROR = 'SELF_REGISTRATION_CREATE_COMPANY_ERROR';
export const SELF_REGISTRATION_CREATE_COMPANY_SUCCESS = 'SELF_REGISTRATION_CREATE_COMPANY_SUCCESS';

export function getExternalCompanyFormOptionsAction() {
  return {
    type: GET_EXTERNAL_COMPANY_FORM_OPTIONS_START,
  };
}

export function selfRegistrationCreateCompanyAction(company) {
  return {
    type: SELF_REGISTRATION_CREATE_COMPANY_START,
    company,
  };
}

export function clearCompanyFormOptionsAction() {
  return {
    type: CLEAR_EXTERNAL_COMPANY_FORM_OPTIONS,
  };
}
