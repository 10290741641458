import { Action } from 'redux';

export const GET_NEWS_LIST_START = 'GET_NEWS_LIST_START';
export const GET_NEWS_LIST_ERROR = 'GET_NEWS_LIST_ERROR';
export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS';

export interface NewsListAction extends Action {
  newsType: string
  page: number
}
export default function getNewsList(newsType: string, page = 1): NewsListAction {
  return {
    type: GET_NEWS_LIST_START,
    newsType,
    page,
  };
}
