import styled from 'styled-components';
import { ThemeColors, FontSize, FontWeight } from '@src/_v2/js/constants';

export const Container = styled.div`
  padding: 1.5rem 1rem 0.5rem;
  text-align: right;
  text-transform: uppercase;

  a {
    color: ${ ThemeColors.blue };
    font-weight: ${ FontWeight.bold };
    font-size: ${ FontSize.caption };
    text-decoration: none;
  }

  &.border {
    border-top: 0.1rem solid ${ ThemeColors.softGrey };
  }
`;
