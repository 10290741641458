import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';
import loadEnvVariable from '@src/js/static/LoadEnv';

class AdminMenu extends Component {
  static propTypes = {
    navigation: PropTypes.object,
  };

  renderLink = (key, url) => (
    <a
      key={ key }
      className='secondary-navigation__links-item'
      href={ url }
    >
      <FormattedMessage id={ `navigation.${ key }` } />
    </a>
  );

  renderNavLink = (key, to) => (
    <NavLink
      key={ key }
      activeClassName='secondary-navigation__links-item--active'
      className='secondary-navigation__links-item'
      to={ to }
    >
      <FormattedMessage id={ `navigation.${ key }` } />
    </NavLink>
  );

  render() {
    const {
      navigation,
    } = this.props;

    return (
      <nav className='secondary-navigation admin-navigation' onClick={ () => null } role='presentation'>
        <div className='container'>
          <div className='row'>
            <div className='secondary-navigation__links'>
              <div className='secondary-navigation__links__admin'>ADMIN</div>
              { Object.keys(navigation).map(key => ((key === 'growth')
                ? this.renderLink(key, loadEnvVariable('GROWTH_URL'))
                : this.renderNavLink(key, withLang(adminRouteCodes[key.toUpperCase()])))) }
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(AdminMenu);
