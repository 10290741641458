import PromotedContentModal from '@app/components/admin/content/content/PromotedContentModal';
import EventModal from '@app/components/admin/content/topic/modals/EventModal';
import LibraryModal from '@app/components/admin/content/topic/modals/LibraryModal';
import PartnerModal from '@app/components/admin/content/topic/modals/PartnerModal';
import ModalPreview from '@app/components/admin/content/translations/ModalPreview';
import ReminderContentModal from '@app/components/admin/events/ReminderContentModal';
import ScheduleReminderModal from '@app/components/admin/events/ScheduleReminderModal';
import TrainingAttendanceModal from '@app/components/admin/events/TrainingAttendanceModal';
import TrainingSubscribeUserModal from '@app/components/admin/events/TrainingSubscribeUserModal';
import FinalizeCompanyModal from '@app/components/admin/onboarding/FinalizeCompanyModal';
import EditLoungesModal from '@app/components/admin/programme/EditLoungesModal';
import CommunityModal from '@app/components/global/modals/CommunityModal';
import ConfirmDataModal from '@app/components/global/modals/ConfirmData';
import ConfirmationModal from '@app/components/global/modals/ConfirmationModal';
import ContactModal from '@app/components/global/modals/ContactModal';
import CrossSellingModal from '@app/components/global/modals/CrossSellingModal';
import LoadingModal from '@app/components/global/modals/LoadingModal';
import MembersModal from '@app/components/global/modals/MembersModal';
import MultipleDocumentUploadingModal from '@app/components/global/modals/MultipleDocumentUploadingModal';
import RequestCallModal from '@app/components/global/modals/RequestCallModal';
import SignDocumentModal from '@app/components/global/modals/SignDocumentModal';
import UploadMultipleDocumentModal from '@app/components/global/modals/UploadMultipleDocumentModal';
import DirectorsModal from '@app/components/public/compliance/sections/directors/DirectorsModal';
import EtineraryContactModal from '@app/components/public/etinerary/ContactModal/EtineraryContactModal';
import EtineraryModal from '@app/components/public/etinerary/Modal/EtineraryModal';
import UpgradeModal from '@app/components/public/etinerary/UpgradeModal/UpgradeModal';
import BrokerModal from '@app/components/public/funding/Broker/BrokerModal';
import ComplianceModal from '@app/components/public/funding/ComplianceModal';
import EventSubscribeParticipantModal
  from '@app/components/public/network/events/subscribe/EventSubscribeParticipantModal';
import OnboardingSubmitModal from '@app/components/public/onboarding/OnboardingSubmitModal';
import BusinessNeedPopup from '@src/js/marketplace/components/admin/BusinessNeedPopup';
import BusinessNeedModal from '@src/js/marketplace/components/modal/BusinessNeedModal';
import DocumentViewerModal from '@src/js/views/admin/pages/company/documentsArea/DocumentViewerModal';
import MoveFileModal from '@src/js/views/admin/pages/company/documentsArea/MoveFileModal';
import PermissionsModal from '@src/js/views/admin/pages/company/documentsArea/PermissionsModal';
import EventTestReminderModal from '@app/components/admin/events/create/EventTestReminderModal';
import ResetPasswordModal from '@src/js/views/user/pages/Dashboard/components/ResetPasswordModal/ResetPasswordModal';
import BannerPreview from '@app/components/admin/content/banners/BannerPreview';
import EventPartnerModal from '@app/components/admin/events/create/EventPartnerModal';
import { ModalProps } from 'reactstrap';
import RenameFolderModal from '@src/js/views/admin/pages/company/documentsArea/RenameFolderModal';
import ChatMember from '@src/js/views/user/pages/Messages/modal/ChatMemberModal';
import ChatFileAttachment from '@src/js/layouts/global/panels/messaging/modals/FileAttachmentModal';

export enum ModalTypes {
  Confirmation = 1,
  SignDocument,
  Members,
  BusinessNeed,
  EventTestReminder,
  ConfirmUserData,
  ResetPassword,
  CrossSelling,
  Broker,
  Contact,
  RequestCall,
  TrainingCommunity,
  TrainingSubscribeUser,
  PromotedContent,
  FinalizeCompany,
  MultipleDocumentUpload,
  UploadMultipleDocument,
  EditLounges,
  Directors,
  AddPartner,
  AddLibrary,
  TopicEvent,
  OnboardingSubmit,
  MoveFile,
  DocumentViewer,
  FilePermissions,
  Loading,
  ReminderContent,
  BusinessNeedPopUp,
  TranslationPreview,
  TrainingAttendance,
  Compliance,
  Upgrade,
  EventSubscribeParticipant,
  EtineraryContact,
  EtineraryFunding,
  ScheduleReminder,
  Banner,
  EventPartner,
  RenameFolder,
  ChatMemberModal,
  FileAttachmentModal,
}

type Components = {
  [key in ModalTypes]: (props: any) => JSX.Element|null
}

export const ModalComponents: Components = {
  [ModalTypes.Confirmation]: ConfirmationModal,
  [ModalTypes.SignDocument]: SignDocumentModal,
  [ModalTypes.Members]: MembersModal,
  [ModalTypes.BusinessNeed]: BusinessNeedModal,
  [ModalTypes.EventTestReminder]: EventTestReminderModal,
  [ModalTypes.ConfirmUserData]: ConfirmDataModal,
  [ModalTypes.ResetPassword]: ResetPasswordModal,
  [ModalTypes.CrossSelling]: CrossSellingModal,
  [ModalTypes.Broker]: BrokerModal,
  [ModalTypes.Contact]: ContactModal,
  [ModalTypes.RequestCall]: RequestCallModal,
  [ModalTypes.TrainingCommunity]: CommunityModal,
  [ModalTypes.TrainingSubscribeUser]: TrainingSubscribeUserModal,
  [ModalTypes.PromotedContent]: PromotedContentModal,
  [ModalTypes.FinalizeCompany]: FinalizeCompanyModal,
  [ModalTypes.MultipleDocumentUpload]: MultipleDocumentUploadingModal,
  [ModalTypes.UploadMultipleDocument]: UploadMultipleDocumentModal,
  [ModalTypes.EditLounges]: EditLoungesModal,
  [ModalTypes.Directors]: DirectorsModal,
  [ModalTypes.AddPartner]: PartnerModal,
  [ModalTypes.AddLibrary]: LibraryModal,
  [ModalTypes.TopicEvent]: EventModal,
  [ModalTypes.OnboardingSubmit]: OnboardingSubmitModal,
  [ModalTypes.MoveFile]: MoveFileModal,
  [ModalTypes.DocumentViewer]: DocumentViewerModal,
  [ModalTypes.FilePermissions]: PermissionsModal,
  [ModalTypes.Loading]: LoadingModal,
  [ModalTypes.ReminderContent]: ReminderContentModal,
  [ModalTypes.BusinessNeedPopUp]: BusinessNeedPopup,
  [ModalTypes.TranslationPreview]: ModalPreview,
  [ModalTypes.TrainingAttendance]: TrainingAttendanceModal,
  [ModalTypes.Compliance]: ComplianceModal,
  [ModalTypes.Upgrade]: UpgradeModal,
  [ModalTypes.EventSubscribeParticipant]: EventSubscribeParticipantModal,
  [ModalTypes.EtineraryContact]: EtineraryContactModal,
  [ModalTypes.EtineraryFunding]: EtineraryModal,
  [ModalTypes.ScheduleReminder]: ScheduleReminderModal,
  [ModalTypes.Banner]: BannerPreview,
  [ModalTypes.EventPartner]: EventPartnerModal,
  [ModalTypes.RenameFolder]: RenameFolderModal,
  [ModalTypes.ChatMemberModal]: ChatMember,
  [ModalTypes.FileAttachmentModal]: ChatFileAttachment,
};

export type ModalConfig = {
  onTop?: boolean,
  replace?: boolean,
  title?: string,
  data?: object,
  translate?: boolean,
  config?: ModalProps,
  hideHeader?: boolean,
  type: ModalTypes,
}
