import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getOpportunitiesForCompany(companyId, publisherType) {
  const params = {
    page: 0,
    limit: 4,
    publisherId: companyId,
    publisherType,
  };
  const query = new URLSearchParams(params);
  const EM_PATH = loadEnvVariable('MARKETPLACE_API_PATH');
  return fetchResource(
    `${ EM_PATH }/user/products?${ query }`,
    {
      method: 'GET',
      externalUrl: true,
    });
}
