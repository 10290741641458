import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Base64 } from 'js-base64';
import { routeCodes, withLang } from '@src/js/constants/routes';
import ProgrammeHome from './user/pages/Programme/ProgrammeHome';

class EOPEditCompanyRoute extends Component {
  static propTypes = {
    userData: PropTypes.object,
  };

  render() {
    const etineraryCompanyMember = 512;

    const {
      userData,
    } = this.props;

    // eslint-disable-next-line no-bitwise
    if ((userData.type && Base64.decode(userData.type) & etineraryCompanyMember)) {
      return (
        <Route
          render={ () => <ProgrammeHome /> }
          { ...this.props }
        />
      );
    }

    return (
      <Redirect exact from={ routeCodes.PROGRAMME } to={ withLang(routeCodes.TRAININGS) } />
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.initInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(EOPEditCompanyRoute);

export default withRouter(connectWrapper);
