import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { fileConstants } from '@src/js/constants/entities';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';

export class UploadDropzone extends Component {
  static propTypes = {
    handleDrop: PropTypes.func,
    handleDropRejected: PropTypes.func,
    handleDelete: PropTypes.func,
    image: PropTypes.object,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    defaultImage: PropTypes.string,
    customError: PropTypes.string,
  };

  static defaultProps = {
    isLoading: false,
    isDisabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.dropzoneRef = React.createRef();
  }

  handleFileDrop = (acceptedFiles) => {
    const {
      handleDrop,
    } = this.props;

    if (acceptedFiles.length === 0) {
      return null;
    }

    this.clearError();
    handleDrop(acceptedFiles);
    return true;
  };

  handleFileDropRejected = (droppedFiles) => {
    const {
      handleDropRejected, customError,
    } = this.props;

    let error = 'dataRoom.imageMimeTypeMessage';

    if (customError && customError !== error) {
      error = customError;
    }

    if (droppedFiles.length > 1) {
      error = 'dataRoom.numberOfFiles';
    }

    if (droppedFiles[0].size > fileConstants.UPLOAD_SIZE_LIMIT) {
      error = 'dataRoom.maxSizeMessage';
    }

    if (typeof handleDropRejected === 'function') {
      handleDropRejected(error);
    } else {
      this.setState({
        error,
      });
    }
  };

  clearError = () => {
    this.setState({
      error: null,
    });
  };

  handleFileDelete = () => {
    const {
      handleDelete,
    } = this.props;

    this.clearError();
    handleDelete();
  };

  render() {
    const {
      image,
      defaultImage,
      isLoading,
      isDisabled,
    } = this.props;
    const {
      error,
    } = this.state;

    return (
      <div className='image-input__wrapper'>
        <div className='image-input'>
          <div className='image-input__image'>
            <Dropzone
              accept={ dropzoneExtensions.IMAGES }
              maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
              onDrop={ this.handleFileDrop }
              onDropRejected={ this.handleFileDropRejected }
              multiple={ false }
              disableClick
              useFsAccessApi={ false }
              ref={ this.dropzoneRef }
            >
              {({ getRootProps, getInputProps }) => (
                <div { ...getRootProps({ style: { maxHeight: '100%', maxWidth: '100%' } }) }>
                  <input { ...getInputProps() } />
                  { isLoading && <Loader isOverlay={ false } /> }
                  { image
                    ? (
                      <div className='upload-card__content'>
                        <img
                          src={ image.preview }
                          alt=''
                          onLoad={ URL.revokeObjectURL(image) }
                        />
                      </div>
                    ) : (
                      <div className='upload-card__placeholder'>
                        <img
                          src={ defaultImage }
                          alt=''
                        />
                      </div>
                    )
                  }
                </div>
              )}
            </Dropzone>
          </div>
          { !isDisabled
          && (
            <div
              className='image-input__button'
              onClick={ () => { this.dropzoneRef.current.open(); } }
              onKeyPress={ () => { this.dropzoneRef.current.open(); } }
              role='presentation'
            >
              <span className='icon icon-small-Edit' />
            </div>
          ) }

          { image && !isLoading && !isDisabled
          && (
            <div
              onClick={ this.handleFileDelete }
              onKeyPress={ this.handleFileDelete }
              className='image-input__button image-input__button--remove'
              role='presentation'
            >
              <span className='icon icon-small-Close' />
            </div>
          ) }
        </div>
        { error && (
          <div className='image-input__error'>
            <p>
              <FormattedMessage id={ error } />
            </p>
          </div>
        ) }
      </div>
    );
  }
}
