import { takeLatest, call, put } from 'redux-saga/effects';

import {
  DELETE_IMAGE_START,
  DELETE_IMAGE_ERROR,
  DELETE_IMAGE_SUCCESS,
} from '@src/js/actions/image/deleteImage';

import * as api from '@src/js/api/image/deleteImage';

function* deleteImage(options) {
  try {
    const data = yield call(() => api.deleteImage(options.id, options.entity, options.imageType));
    yield put({ type: DELETE_IMAGE_SUCCESS, data });
  } catch (error) {
    yield put({ type: DELETE_IMAGE_ERROR, error });
  }
}

function* deleteImageWatcher() {
  yield takeLatest(DELETE_IMAGE_START, deleteImage);
}

export default [
  deleteImageWatcher(),
];
