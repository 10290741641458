import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeCodes, withLang } from '@src/js/constants/routes';
import { Company } from '@src/js/constants/entities';

class EOPNetworkRoute extends Component {
  static propTypes = {
    userData: PropTypes.object,
  };

  render() {
    const {
      userData,
    } = this.props;

    if (userData.companyType === Company.TYPE_ETINERARY
      || userData.companyType === Company.TYPE_INSTITUTIONAL_INVESTOR) {
      return (
        <Redirect exact from={ routeCodes.NETWORK } to={ withLang(routeCodes.LIBRARY) } />
      );
    }
    return (
      <Redirect exact from={ routeCodes.NETWORK } to={ withLang(routeCodes.DIRECTORY) } />
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.initInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(EOPNetworkRoute);

export default withRouter(connectWrapper);
