import React from 'react';
import { Images } from '@src/js/constants/images';

type Props = {
    resetErrorBoundary: () => void,
}
const ErrorPage = ({ resetErrorBoundary }: Props) => (
  <div className='elite-404-container'>
    <img
      src={ Images.Bg404 }
      alt='Not Found Background'
      className='elite-404-bg-image'
    />
    <div className='elite-404-text-container'>
      <span className='elite-404-title'>
        Error
      </span>
      <div className='elite-404-subtitle'>
        Something went wrong. Try to reload the page.
        <button
          className='eop-btn'
          type='button'
          onClick={ resetErrorBoundary }
        >
          Reload
        </button>
      </div>
    </div>
  </div>
);

export default ErrorPage;
