import {
  takeLatest, call, put, takeEvery,
} from 'redux-saga/effects';

import {
  GET_OPPORTUNITY_LOCATIONS_START,
  GET_OPPORTUNITY_LOCATIONS_ERROR,
  GET_OPPORTUNITY_LOCATIONS_SUCCESS,
  GET_OPPORTUNITY_SECTORS_START,
  GET_OPPORTUNITY_SECTORS_ERROR,
  GET_OPPORTUNITY_SECTORS_SUCCESS,
  GET_OPPORTUNITY_TYPE_START,
  GET_OPPORTUNITY_TYPE_ERROR,
  GET_OPPORTUNITY_TYPE_SUCCESS,
  GET_OPPORTUNITY_EXPERTISE_SUCCESS,
  GET_OPPORTUNITY_EXPERTISE_ERROR,
  GET_OPPORTUNITY_EXPERTISE_START,
  GET_OPPORTUNITY_DIMENSION_SUCCESS,
  GET_OPPORTUNITY_DIMENSION_ERROR,
  GET_OPPORTUNITY_DIMENSION_START,
  GET_OPPORTUNITY_ADVISOR_TYPE_SUCCESS,
  GET_OPPORTUNITY_ADVISOR_TYPE_ERROR,
  GET_OPPORTUNITY_ADVISOR_TYPE_START,
  GET_OPPORTUNITY_COVER_IMAGE_START,
  GET_OPPORTUNITY_COVER_IMAGE_ERROR,
  GET_OPPORTUNITY_COVER_IMAGE_SUCCESS,
  GET_OPPORTUNITY_COMPANY_START,
  GET_OPPORTUNITY_COMPANY_ERROR,
  GET_OPPORTUNITY_COMPANY_SUCCESS,
  GET_OPPORTUNITY_DATA_START,
  GET_OPPORTUNITY_DATA_ERROR,
  GET_OPPORTUNITY_DATA_SUCCESS,
  SAVE_OPPORTUNITY_START,
  SAVE_OPPORTUNITY_ERROR,
  SAVE_OPPORTUNITY_SUCCESS,
  EDIT_OPPORTUNITY_START,
  EDIT_OPPORTUNITY_ERROR,
  EDIT_OPPORTUNITY_SUCCESS,
  GET_OPPORTUNITY_PUBLISHERS_START,
  GET_OPPORTUNITY_PUBLISHERS_ERROR,
  GET_OPPORTUNITY_PUBLISHERS_SUCCESS,
  SET_FILTERS,
  SET_FILTERS_SUCCESS,
} from '@src/js/marketplace/actions/getOpportunityData';

import * as api from '@src/js/marketplace/api/getOpportunityData';
import { TagType } from '@src/js/marketplace/constants';

function* getOpportunityLocations() {
  try {
    const data = yield call(() => api.getOpportunityLocations());
    yield put({ type: GET_OPPORTUNITY_LOCATIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_LOCATIONS_ERROR, error });
  }
}

function* getOpportunitySectors() {
  try {
    const data = yield call(() => api.getOpportunitySectors());
    yield put({ type: GET_OPPORTUNITY_SECTORS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_SECTORS_ERROR, error });
  }
}

function* getOpportunityType() {
  try {
    const data = yield call(() => api.getOpportunityType());
    yield put({ type: GET_OPPORTUNITY_TYPE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_TYPE_ERROR, error });
  }
}

function* getOpportunityExpertise({ advisorTypes }) {
  try {
    const data = yield call(() => api.getOpportunityTags(TagType.TAG_TYPE_EXPERTISE, advisorTypes));
    yield put({ type: GET_OPPORTUNITY_EXPERTISE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_EXPERTISE_ERROR, error });
  }
}

function* getOpportunityDimension() {
  try {
    const data = yield call(() => api.getOpportunityTags(TagType.TAG_TYPE_DIMENSION));
    yield put({ type: GET_OPPORTUNITY_DIMENSION_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_DIMENSION_ERROR, error });
  }
}

function* getOpportunityAdvisorType() {
  try {
    const data = yield call(() => api.getOpportunityAdvisorType());
    yield put({ type: GET_OPPORTUNITY_ADVISOR_TYPE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_ADVISOR_TYPE_ERROR, error });
  }
}

function* getOpportunityCoverImage(image) {
  try {
    const data = yield call(() => api.getOpportunityCoverImage(image));
    image.resolve(data);
    yield put({ type: GET_OPPORTUNITY_COVER_IMAGE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_COVER_IMAGE_ERROR, error });
    image.reject();
  }
}

function* getOpportunityCompany(options) {
  try {
    const data = yield call(() => api.getOpportunityCompany(options));
    yield put({ type: GET_OPPORTUNITY_COMPANY_SUCCESS, data });
    options.filterOptions.resolve(data);
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_COMPANY_ERROR, error });
  }
}

function* getOpportunityData(options) {
  try {
    const data = yield call(() => api.getOpportunityData(options));
    yield put({ type: GET_OPPORTUNITY_DATA_SUCCESS, data });
    const sectors = data.EmProductSectors.map(item => item.Id);
    const countries = data.Countries.map(item => item.Id);
    yield put({ type: SET_FILTERS, filters: { sectors, countries } });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_DATA_ERROR, error });
  }
}

function* saveOpportunityData(options) {
  try {
    const data = yield call(() => api.saveOpportunityData(options.opportunityDataForm.opportunityDataForm));
    yield put({ type: SAVE_OPPORTUNITY_SUCCESS, data });
    options.opportunityDataForm.resolve(data);
  } catch (error) {
    yield put({ type: SAVE_OPPORTUNITY_ERROR, error });
    options.opportunityDataForm.reject(error);
  }
}

function* editOpportunityData(options) {
  try {
    const data = yield call(() => api.editOpportunityData(options.opportunityDataForm.opportunityDataForm));
    yield put({ type: EDIT_OPPORTUNITY_SUCCESS, data });
    options.opportunityDataForm.resolve(data);
  } catch (error) {
    yield put({ type: EDIT_OPPORTUNITY_ERROR, error });
    options.opportunityDataForm.reject(error);
  }
}

function* getOpportunityPublishers(options) {
  try {
    const data = yield call(() => api.getOpportunityPublishers(options.filters));
    yield put({ type: GET_OPPORTUNITY_PUBLISHERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_OPPORTUNITY_PUBLISHERS_ERROR, error });
  }
}

function* setOpportunityEditFilters(options) {
  yield put({ type: SET_FILTERS_SUCCESS, filters: options.filters });
}

function* getMyOpportunityLocationsWatcher() {
  yield takeLatest(GET_OPPORTUNITY_LOCATIONS_START, getOpportunityLocations);
}

function* getMyOpportunitySectorsWatcher() {
  yield takeLatest(GET_OPPORTUNITY_SECTORS_START, getOpportunitySectors);
}

function* getMyOpportunityTypeWatcher() {
  yield takeLatest(GET_OPPORTUNITY_TYPE_START, getOpportunityType);
}

function* getOpportunityCoverImageWatcher() {
  yield takeLatest(GET_OPPORTUNITY_COVER_IMAGE_START, getOpportunityCoverImage);
}

function* getOpportunityCompanyWatcher() {
  yield takeLatest(GET_OPPORTUNITY_COMPANY_START, getOpportunityCompany);
}

function* getOpportunityDataWatcher() {
  yield takeLatest(GET_OPPORTUNITY_DATA_START, getOpportunityData);
}

function* saveOpportunityWatcher() {
  yield takeLatest(SAVE_OPPORTUNITY_START, saveOpportunityData);
}

function* editOpportunityWatcher() {
  yield takeLatest(EDIT_OPPORTUNITY_START, editOpportunityData);
}

function* getOpportunityExpertiseWatcher() {
  yield takeLatest(GET_OPPORTUNITY_EXPERTISE_START, getOpportunityExpertise);
}

function* getOpportunityDimensionWatcher() {
  yield takeLatest(GET_OPPORTUNITY_DIMENSION_START, getOpportunityDimension);
}

function* getOpportunityAdvisorTypeWatcher() {
  yield takeLatest(GET_OPPORTUNITY_ADVISOR_TYPE_START, getOpportunityAdvisorType);
}

function* getMyOpportunityPublisherWatcher() {
  yield takeLatest(GET_OPPORTUNITY_PUBLISHERS_START, getOpportunityPublishers);
}

function* setOpportunityEditFiltersWatcher() {
  yield takeEvery(SET_FILTERS, setOpportunityEditFilters);
}

export default [
  getMyOpportunityLocationsWatcher(),
  getMyOpportunitySectorsWatcher(),
  getMyOpportunityTypeWatcher(),
  getOpportunityCoverImageWatcher(),
  getOpportunityCompanyWatcher(),
  getOpportunityDataWatcher(),
  saveOpportunityWatcher(),
  editOpportunityWatcher(),
  getMyOpportunityPublisherWatcher(),
  getMyOpportunityLocationsWatcher(),
  getMyOpportunitySectorsWatcher(),
  getMyOpportunityTypeWatcher(),
  getOpportunityExpertiseWatcher(),
  getOpportunityDimensionWatcher(),
  getOpportunityAdvisorTypeWatcher(),
  setOpportunityEditFiltersWatcher(),

];
