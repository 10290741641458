export const CREATE_LIBRARY_START = 'CREATE_LIBRARY_START';
export const CREATE_LIBRARY_ERROR = 'CREATE_LIBRARY_ERROR';
export const CREATE_LIBRARY_SUCCESS = 'CREATE_LIBRARY_SUCCESS';

export function createLibrary(data) {
  return {
    type: CREATE_LIBRARY_START,
    data,
  };
}
