export const PARTNER_EDIT_LIBRARY_START = 'PARTNER_EDIT_LIBRARY_START';
export const PARTNER_EDIT_LIBRARY_ERROR = 'PARTNER_EDIT_LIBRARY_ERROR';
export const PARTNER_EDIT_LIBRARY_SUCCESS = 'PARTNER_EDIT_LIBRARY_SUCCESS';

export function partnerEditLibrary(data) {
  return {
    type: PARTNER_EDIT_LIBRARY_START,
    data,
  };
}
