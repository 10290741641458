import React from 'react';
import PropTypes from 'prop-types';

const ProfileHeaderWrapper = ({ children }) => (
  <div className='elite-profile-header'>
    <div className='elite-profile-header-image'>
      { children }
    </div>
  </div>
);

ProfileHeaderWrapper.propTypes = {
  children: PropTypes.node,
};

export default ProfileHeaderWrapper;
