import produce from 'immer';

import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  GET_USERS_START,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  SEND_CUSTOM_REMINDER_START,
  SEND_CUSTOM_REMINDER_FAIL,
  SEND_CUSTOM_REMINDER_SUCCESS,
  ADD_USER,
  REMOVE_USER,
  CLEAR_USERS,
} from '@app/store/customReminder/actions';

export const initialState = {
  data: null,
  dataLoading: false,
  dataError: null,
  usersIds: [],
  sendLoading: false,
};

function customReminderReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_DATA_START:
        draft.dataLoading = true;
        draft.dataError = null;
        draft.data = null;
        break;
      case GET_DATA_SUCCESS:
        draft.dataLoading = false;
        draft.dataError = null;
        draft.data = action.result;
        break;
      case GET_DATA_FAIL:
        draft.dataLoading = false;
        draft.dataError = action.error;
        draft.data = null;
        break;

      case GET_USERS_START:
        draft.dataLoading = true;
        draft.dataError = null;
        break;
      case GET_USERS_SUCCESS:
        draft.dataLoading = false;
        draft.dataError = null;
        draft.usersIds = state.usersIds.concat(action.result);
        break;
      case GET_USERS_FAIL:
        draft.dataLoading = false;
        draft.dataError = action.error;
        draft.usersIds = null;
        break;

      case SEND_CUSTOM_REMINDER_START:
        draft.sendLoading = true;
        break;
      case SEND_CUSTOM_REMINDER_SUCCESS:
      case SEND_CUSTOM_REMINDER_FAIL:
        draft.sendLoading = false;
        break;

      case ADD_USER:
        draft.usersIds.push(action.id);
        break;
      case REMOVE_USER: {
        const idx = state.usersIds.indexOf(action.id);
        if (idx !== -1) {
          draft.usersIds.splice(idx, 1);
        }
        break;
      }
      case CLEAR_USERS:
        draft.usersIds = [];
        break;
      default:
        break;
    }
  }
  );
}

export default customReminderReducer;
