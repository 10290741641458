import styled from 'styled-components';
import { Table as TableRs } from 'reactstrap';
import {
  Breakpoints, FontSize, FontWeight, ThemeColors,
} from '@src/_v2/js/constants';

export const Table = styled(TableRs)`
  width: 100%;
`;
export const TableCell = styled.td(props => `
  display: table-cell;
  color: ${ ThemeColors.black };
  padding: ${ props.big ? '2rem 2.4rem' : '1rem' };
  text-align: left;
  border-bottom: 0.1rem solid rgba(224, 224, 224, 1);
  font-size: ${ FontSize.medium };
  font-weight: ${ FontWeight.normal };
  ${ props.percentageWidth && `width: ${ props.percentageWidth }% } ` };
  ${ props.minHeight && 'min-height: 6.5rem;' }

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    padding: 2rem 1rem;
  }
`);
export const TableCellOneline = styled.td(props => `
  display: table-cell;
  color: ${ ThemeColors.black };
  padding: ${ props.big ? '2rem 2.4rem' : '1rem' };
  text-align: left;
  border-bottom: 0.1rem solid rgba(224, 224, 224, 1);
  font-size: ${ FontSize.medium };
  font-weight: ${ FontWeight.normal };
  ${ props.percentageWidth && `width: ${ props.percentageWidth }% } ` };
  ${ props.maxSizeWidth && `max-width: ${ props.maxSizeWidth }rem } ` };
  white-space: nowrap;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    padding: 2rem 1rem;
  }
`);

export const EMTableCell = styled.td(props => `
  display: table-cell;
  color: ${ ThemeColors.black };
  padding: 0.4rem 1.4rem;
  font-size: ${ FontSize.medium };
  font-weight: ${ FontWeight.medium };
  ${ props.percentageWidth && `width: ${ props.percentageWidth }% } ` }

  & .table__name {
    text-align: left;
  }

  &.table__name {
    white-space: pre-wrap;
    word-break: break-word;
  }
`);

export const TableRow = styled.tr`
  height: 5rem;
  width: 100%;
`;

export const TableBody = styled.tbody`
  font-size: ${ FontSize.medium };
`;

export const TableHead = styled.thead`
  font-size: ${ FontSize.medium };

  ${ TableCell } {
    color: ${ ThemeColors.mediumGrey };
    font-size: ${ FontSize.caption };
    background-color: ${ ThemeColors.softBlue };
    font-weight: ${ FontWeight.medium };
  }

  ${ EMTableCell } {
    font-size: ${ FontSize.icon };
    font-weight: ${ FontWeight.bold };
    line-height: ${ FontSize.xl };
  }
`;
