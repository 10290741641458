import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_EDIT_LIBRARY_START,
  PARTNER_EDIT_LIBRARY_ERROR,
  PARTNER_EDIT_LIBRARY_SUCCESS,
} from '@src/js/actions/library/partnerEditLibrary';

import * as api from '@src/js/api/library/partnerEditLibrary';

function* partnerEditLibrary(options) {
  try {
    const data = yield call(() => api.partnerEditLibrary(options.data.values, options.data.slug));
    yield put({ type: PARTNER_EDIT_LIBRARY_SUCCESS, data });
    options.data.resolve(data);
  } catch (error) {
    yield put({ type: PARTNER_EDIT_LIBRARY_ERROR, error });
    options.data.reject(error);
  }
}

function* partnerEditLibraryWatcher() {
  yield takeLatest(PARTNER_EDIT_LIBRARY_START, partnerEditLibrary);
}

export default [
  partnerEditLibraryWatcher(),
];
