import fetchResource from '@src/js/api/fetch-resource';

export function getPeers(threadId, offset, search) {
  const params = {
    search,
    offset,
  };
  const query = new URLSearchParams(params);
  return fetchResource(`api/users/peers${ threadId ? `/${ threadId }` : '' }?${ query }`);
}

export function getPeersList(offset, search, referrerType, companyId, isCompany) {
  const params = {
    search,
    offset,
    referrerType,
    companyId,
    isCompany,
  };
  const query = new URLSearchParams(params);
  return fetchResource(`api/users/peers?${ query }`);
}

export function getReferrers(slug, isCompany, referrerType, isAdmin) {
  const query = new URLSearchParams({ referrerType });
  const url = `api${ isAdmin ? '/admin' : '' }/${ isCompany ? 'companies' : 'partners' }`;
  return fetchResource(`${ url }/${ slug }/get-referrers?${ query }`, {
    method: 'GET',
  });
}

export function addReferrers(slug, isCompany, referrers, referrerType) {
  const query = new URLSearchParams({ referrerType });
  return fetchResource(`api/admin/${ isCompany ? 'companies' : 'partners' }/${ slug }/add-referrers?${ query }`, {
    method: 'POST',
    body: { referrers },
  });
}

export function callReferrer(phone, requestType) {
  return fetchResource('api/notifications/request-call', {
    method: 'POST',
    body: {
      phone,
      requestType,
    },
  });
}
