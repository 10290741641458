import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_MATCHMAKING_MEMBERS_START,
  GET_MATCHMAKING_MEMBERS_ERROR,
  GET_MATCHMAKING_MEMBERS_SUCCESS,
} from '@src/js/marketplace/actions/matchmakingMembers';

import * as api from '@src/js/marketplace/api/matchmakingMembers';

function* getMatchmakingMembers(options) {
  try {
    const data = yield call(() => api.getMatchmakingMembers(options.id, options.page, options.limit));
    yield put({ type: GET_MATCHMAKING_MEMBERS_SUCCESS, data, businessNeedId: options.id });
  } catch (error) {
    yield put({ type: GET_MATCHMAKING_MEMBERS_ERROR, error });
  }
}

function* getMatchmakingMembersWatcher() {
  yield takeEvery(GET_MATCHMAKING_MEMBERS_START, getMatchmakingMembers);
}

export default [
  getMatchmakingMembersWatcher(),
];
