import { Language } from '@app/models/Language';
import loadEnvVariable from '@src/js/static/LoadEnv';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';

type Props = {
  languages: Language[],
}

const LanguageFlags = ({ languages }: Props) => (
  <div className='d-flex'>
    {languages.map((languageCode) => (
      <div className='p-1' key={ languageCode }>
        <ReactCountryFlag
          cdnUrl={ `${ loadEnvVariable('API_PATH') }/images/flags-svg/` }
          countryCode={ languageCode.startsWith('lang-') ? languageCode : `lang-${ languageCode }` }
          svg
          className='country-flag'
        />
      </div>
    ))}
  </div>
);

export default LanguageFlags;
