import React from 'react';
import { Field } from 'react-final-form';
import { TextInput } from '@src/js/components/global/inputs';

const UserInfo = () => (
  <>
    <Field
      name='firstname'
      fieldName='firstname'
      fieldLabel='user.firstName'
      placeholder='user.firstName'
      fieldType='text'
      component={ TextInput }
      fieldRequired
      min={ 2 }
      maxLength={ 256 }
    />
    <Field
      name='lastname'
      fieldName='lastname'
      fieldLabel='user.lastName'
      placeholder='user.lastName'
      fieldType='text'
      component={ TextInput }
      fieldRequired
      min={ 2 }
      maxLength={ 256 }
    />
    <Field
      name='email'
      fieldName='email'
      fieldLabel='user.email'
      placeholder='user.email'
      fieldType='text'
      component={ TextInput }
      fieldRequired
      min={ 2 }
      maxLength={ 256 }
    />
    <Field
      name='jobTitle'
      fieldName='jobTitle'
      fieldLabel='user.jobTitle'
      placeholder='user.jobTitle'
      fieldType='text'
      component={ TextInput }
      fieldRequired
      min={ 2 }
      maxLength={ 256 }
    />
  </>
);

export default UserInfo;
