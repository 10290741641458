import { DateTime } from 'luxon';
import { capitalize } from '@src/js/utils/index';

export const dateFormat = (date: string|Date, fmt: string, locale?: string, gmt = true): string => {
  let dateLuxon = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
  if (gmt) dateLuxon = dateLuxon.setZone('gmt');
  return capitalize(dateLuxon.toFormat(fmt, locale ? { locale } : {}));
};

export const dateParse = (date: string, fmt: string, gmt = true): Date => {
  const dateLuxon = DateTime.fromFormat(date, fmt);
  if (gmt) dateLuxon.setZone('gmt');
  return dateLuxon.toJSDate();
};

export const addDays = (date: Date, days: number):Date => {
  date.setDate(date.getDate() + days);
  return date;
};

export const gmtToDate = (date: string|Date): string => {
  const val = typeof date === 'string' ? new Date(date) : date;
  return new Date(val.getTime() + val.getTimezoneOffset() * 60000).toISOString();
};

export const dateToGMT = (date: string|Date): string => {
  const val = typeof date === 'string' ? new Date(date) : date;
  return new Date(val.getTime() - val.getTimezoneOffset() * 60000).toISOString();
};
