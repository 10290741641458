import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { copyObject } from '@src/js/helpers/helpers';
import {
  getOpportunityAdvisorType,
  getOpportunityDimension,
  getOpportunityExpertise,
  getOpportunityLocations,
} from '@src/js/marketplace/actions/getOpportunityData';
import {
  createBusinessNeed, editBusinessNeed, getBusinessNeedSectors,
} from '@src/js/marketplace/actions/modal/businessNeedModal';
import React, { useEffect } from 'react';
import TreeFilter from '@src/js/components/filterblock/TreeFilter';
import Header from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components/Header/Header';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import Button from '@src/_v2/js/components/buttons/Button/Button';
import { FormattedMessage } from 'react-intl';
import SelectBoxFilter from '@src/js/components/filterblock/SelectBoxFilter';
import ReactTooltip from 'react-tooltip';
import { RootState } from '@src/js/store';

type Props = {
  businessNeed: BusinessNeed;
  isAdmin: boolean;
  userId: string;
};

type BusinessNeed = {
  Id?: string,
  Countries: (number | { Id: number })[];
  Sectors: (number | { Id: number })[];
  Expertise: (number | { Id: number })[];
  Types: (number | { Id: number })[];
  Dimensions: (number | { Id: number })[];
};

type BusinessNeedOption = {
  Items: {
    Id: number;
    Name: string;
    Type: number;
    ParentId: number;
    Advisors: {
      Id: number;
      Name: string;
      Type: number;
      ParentId: number;
    }[];
  }[];
  CurrentPage: number;
  TotalPage: number;
  PageSize: number;
  Count: number;
};

const BusinessNeedModal = ({ businessNeed, isAdmin, userId }: Props) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const opportunityLocations = useSelector<RootState, BusinessNeedOption>(
    state => state.marketplaceReducers.getOpportunitiesData.opportunityLocations);
  const businessNeedSectors = useSelector<RootState, BusinessNeedOption>(
    state => state.marketplaceReducers.businessNeedModal.businessNeedSectors);
  const opportunityExpertise = useSelector<RootState, BusinessNeedOption>(
    state => state.marketplaceReducers.getOpportunitiesData.opportunityExpertise);
  const opportunityDimension = useSelector<RootState, BusinessNeedOption>(
    state => state.marketplaceReducers.getOpportunitiesData.opportunityDimension);
  const opportunityAdvisorType = useSelector<RootState, BusinessNeedOption>(
    state => state.marketplaceReducers.getOpportunitiesData.opportunityAdvisorType);

  const handleSelectedValues = (selectedValues: (number|{ Id: number })[]) => {
    if (selectedValues?.length > 0) {
      return selectedValues.map(
        selectedValue => (typeof selectedValue === 'object' && selectedValue?.Id) || selectedValue);
    }

    return [];
  };

  const getInitialValues = () => {
    const values: BusinessNeed = {
      Countries: [],
      Sectors: [],
      Expertise: [],
      Types: [],
      Dimensions: [],
    };
    values.Countries = businessNeed ? handleSelectedValues(businessNeed?.Countries) : [];
    values.Sectors = businessNeed ? handleSelectedValues(businessNeed?.Sectors) : [];
    values.Expertise = businessNeed ? handleSelectedValues(businessNeed?.Expertise) : [];
    values.Types = businessNeed ? handleSelectedValues(businessNeed?.Types) : [];
    values.Dimensions = businessNeed ? handleSelectedValues(businessNeed?.Dimensions) : [];

    return values;
  };

  const methods = useForm({
    defaultValues: getInitialValues(),
    mode: 'onChange',
  });
  const {
    watch, control, handleSubmit, setValue,
  } = methods;
  const values = watch();

  useEffect(() => {
    dispatch(getBusinessNeedSectors());
    dispatch(getOpportunityLocations());
    dispatch(getOpportunityDimension());
    dispatch(getOpportunityAdvisorType());
    return () => hideModal();
  }, []);

  useEffect(() => {
    if (values?.Types.length > 0) {
      dispatch(getOpportunityExpertise(values?.Types || []));
    }
  }, [dispatch, values?.Types]);

  const changeAdvisorTypes = (advisorValues: number[]) => {
    const newExpertiseValues: number[] = [];
    const expertiseItems = opportunityExpertise?.Items?.filter((i: { Id: number }) => values.Expertise.includes(i.Id));
    if (expertiseItems?.length > 0) {
      expertiseItems.forEach(
        (expertise: { Id: number; Advisors: { Id: number }[] }) => {
          const expertiseAdvisors = expertise?.Advisors?.map((a) => a.Id);
          if (expertiseAdvisors.some((e) => advisorValues.includes(e))) {
            newExpertiseValues.push(expertise.Id);
          }
        }
      );
    }
    setValue('Types', advisorValues);
    setValue('Expertise', newExpertiseValues);
  };

  const onSubmit = async (businessNeedFormData: BusinessNeed) => {
    const businessNeedData = copyObject(businessNeedFormData);
    Object.keys(businessNeedData).forEach((key) => {
      businessNeedData[key] = businessNeedData[key].map((item: object) => ({
        Id: +item,
      }));
    });

    businessNeedData.UserId = isAdmin ? userId : null;

    if (businessNeed) {
      businessNeedData.Id = businessNeed.Id;
      dispatch(editBusinessNeed(businessNeedData, isAdmin));
    } else {
      dispatch(createBusinessNeed(businessNeedData, isAdmin));
    }
    hideModal();
  };

  const submitDisabled = Object.values(values)?.map(i => i.length > 0).filter(Boolean).length < 2;

  return (
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <ModalBody className='business-need-modal'>
          <div className='modal-header eop-text-color-black' data-tip data-for='titleTooltip'>
            <Header>
              <FormattedMessage id='marketplace.businessNeed.modal.title' />
            </Header>
            <ReactTooltip
              id='titleTooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='marketplace.modal.tooltip.title' />
            </ReactTooltip>
          </div>

          <div data-tip data-for='typesTooltip'>
            { opportunityAdvisorType?.Items && (
              <Controller
                name='Types'
                control={ control }
                render={ ({ field }) => (
                  <SelectBoxFilter
                    field={ field }
                    fieldName='Types'
                    fieldLabel='filterIndex.advisorType.label'
                    placeholder='filterIndex.advisorType.search'
                    options={ opportunityAdvisorType.Items }
                    prefix='partner_sectors.'
                    toggleOpened={ values.Types && values.Types.length > 0 }
                    onChange={ changeAdvisorTypes }
                    selectedValues={ values.Types || [] }
                    hiddenSearchBox
                  />
                ) }
              />
            ) }
            <ReactTooltip
              id='typesTooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='marketplace.modal.tooltip.advisorType' />
            </ReactTooltip>
          </div>

          <div data-tip data-for='expertiseTooltip'>
            { values?.Types.length > 0 && opportunityExpertise?.Items?.length > 0 && (
              <Controller
                name='Expertise'
                control={ control }
                render={ ({ field }) => (
                  <SelectBoxFilter
                    field={ field }
                    fieldName='Expertise'
                    fieldLabel='filterIndex.expertise.label'
                    placeholder='filterIndex.expertise.search'
                    options={ opportunityExpertise.Items || [] }
                    prefix='partner_expertise.'
                    toggleOpened={ true }
                    onChange={ (e: number[]) => setValue('Expertise', e) }
                    selectedValues={ values.Expertise || [] }
                    hiddenSearchBox
                  />
                ) }
              />
            ) }
            <ReactTooltip
              id='expertiseTooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='marketplace.modal.tooltip.expertise' />
            </ReactTooltip>
          </div>

          <div data-tip data-for='dimensionsTooltip'>
            { opportunityDimension?.Items?.length > 0 && (
              <Controller
                name='Dimensions'
                control={ control }
                render={ ({ field }) => (
                  <SelectBoxFilter
                    field={ field }
                    fieldName='Dimensions'
                    fieldLabel='filterIndex.dimension.label'
                    placeholder='filterIndex.dimension.search'
                    options={ opportunityDimension?.Items || [] }
                    prefix='dimension.'
                    toggleOpened={ values.Dimensions && values.Dimensions.length > 0 }
                    onChange={ (e: number[]) => setValue('Dimensions', e) }
                    selectedValues={ values.Dimensions || [] }
                    hiddenSearchBox
                  />
                ) }
              />
            ) }
            <ReactTooltip
              id='dimensionsTooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='marketplace.modal.tooltip.dimension' />
            </ReactTooltip>
          </div>

          <div data-tip data-for='sectorsTooltip'>
            { businessNeedSectors && businessNeedSectors.Items && (
              <Controller
                name='Sectors'
                control={ control }
                render={ ({ field }) => (
                  <TreeFilter
                    field={ field }
                    fieldName='Sectors'
                    fieldLabel='filterIndex.secondLevelSectorCounters.label'
                    placeholder='filterIndex.secondLevelSectorCounters.search'
                    prefix='sectors.'
                    options={ businessNeedSectors.Items }
                    onChange={ (e: number[]) => setValue('Sectors', e?.filter(i => i !== 0)) }
                    selectedValues={ values.Sectors || [] }
                    toggleOpened={ values.Sectors && values.Sectors.length > 0 }
                  />
                ) }
              />
            ) }
            <ReactTooltip
              id='sectorsTooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='marketplace.modal.tooltip.sector' />
            </ReactTooltip>
          </div>

          <div data-tip data-for='countriesTooltip'>
            { opportunityLocations && opportunityLocations.Items && (
              <Controller
                name='Countries'
                control={ control }
                render={ ({ field }) => (
                  <TreeFilter
                    field={ field }
                    fieldName='Countries'
                    fieldLabel='filterIndex.countryCounters.label'
                    placeholder='filterIndex.countryCounters.search'
                    options={ opportunityLocations.Items }
                    onChange={ (e: number[]) => setValue('Countries', e?.filter(i => i !== 0)) }
                    selectedValues={ values.Countries || [] }
                    toggleOpened={ values.Countries && values.Countries.length > 0 }
                  />
                ) }
              />
            ) }
            <ReactTooltip
              id='countriesTooltip'
              place='right'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              <FormattedMessage id='marketplace.modal.tooltip.country' />
            </ReactTooltip>
          </div>

        </ModalBody>
        <ModalFooter className='businessNeed-modal__footer justify-content-between'>
          <Button
            text='marketplace.businessNeed.modal.save'
            className='businessNeed-modal__button'
            buttonType='submit'
            disabled={ submitDisabled }
            size='sm'
          />
          <span className='businessNeed-modal__label'>
            <FormattedMessage id='marketplace.modal.footer.label' />
          </span>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

export default BusinessNeedModal;
