import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { defaultPath, routeCodes } from '@src/js/constants/routes';
import SetPassword from '@src/js/views/user/pages/SetPassword';
import ResetPassword from '@src/js/views/user/pages/ResetPassword';
import RequestResetPassword from '@src/js/views/user/pages/RequestResetPassword';
import Login from '@src/js/views/user/pages/Login';
import SelfRegistration from '@src/js/views/iFrame/SelfRegistration/SelfRegistration';
import { formatUrlToSecure } from '@src/js/helpers/formatUrlToSecure';
import loadEnvVariable from '@src/js/static/LoadEnv';

export default class PublicRoutes extends Component {
  render() {
    return (
      <Switch>
        { /* when adding new route, also add check in EOPRouter.jsx when setting redirect localStorage item */ }
        <Route exact path={ routeCodes.SET_PASSWORD } component={ SetPassword } />
        <Route exact path={ routeCodes.RESET_PASSWORD } component={ ResetPassword } />
        <Route exact path={ routeCodes.REQUEST_RESET_PASSWORD } component={ RequestResetPassword } />
        <Route exact path={ routeCodes.SELF_REGISTRATION } component={ SelfRegistration } />
        <Route exact path={ routeCodes.LOGIN } render={ Login } />

        { /* NOTE: this is a default matcher, don't add anything after it */ }
        <Route
          exact
          path={ defaultPath }
          component={ () => {
            window.location = formatUrlToSecure(loadEnvVariable('PUBLIC_WEBSITE_URL'));
            return null;
          } }
        />
      </Switch>
    );
  }
}
