import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import {
  GET_PARTNER_START,
  GET_PARTNER_ERROR,
  GET_PARTNER_SUCCESS,
  CREATE_PARTNER_START,
  CREATE_PARTNER_ERROR,
  CREATE_PARTNER_SUCCESS,
  EDIT_PARTNER_START,
  EDIT_PARTNER_ERROR,
  EDIT_PARTNER_SUCCESS,
  DISABLE_PARTNER_START,
  DISABLE_PARTNER_ERROR,
  DISABLE_PARTNER_SUCCESS,
  GET_PARTNER_PUBLIC_PROFILE_START,
  GET_PARTNER_PUBLIC_PROFILE_ERROR,
  GET_PARTNER_PUBLIC_PROFILE_SUCCESS,
} from '@src/js/actions/partner/partners';

import * as api from '@src/js/api/partner/partners';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import { getPartnersAction } from '@src/js/actions/partner';

function* getPartner(options) {
  try {
    const data = yield call(() => api.getPartner(options.id));
    yield put({ type: GET_PARTNER_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PARTNER_ERROR, error });
  }
}

function* createPartner(options) {
  try {
    const data = yield call(() => api.createPartner(options.partner.values));
    yield put({ type: CREATE_PARTNER_SUCCESS, data });
    options.partner.resolve();
  } catch (error) {
    yield put({ type: CREATE_PARTNER_ERROR, error });
    options.partner.reject(error.response);
  }
}

function* editPartner(options) {
  try {
    const data = yield call(() => api.editPartner(options.partner.values, options.userType));
    yield put({ type: EDIT_PARTNER_SUCCESS, data });
    options.partner.resolve(data.data);
  } catch (error) {
    yield put({ type: EDIT_PARTNER_ERROR, error });
    options.partner.reject(error.response);
  }
}

function* disablePartner({ slug, filters }) {
  try {
    const data = yield call(() => api.disablePartner(slug));
    yield all([
      put({ type: DISABLE_PARTNER_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.disable.success_message')),
      put(getPartnersAction(filters)),
    ]);
  } catch (error) {
    yield all([
      put({ type: DISABLE_PARTNER_ERROR }),
      put(showToastrError()),
    ]);
  }
}

function* getPartnerPublicProfile(options) {
  try {
    const data = yield call(() => api.getPartnerPublicProfile(options.slug));
    yield put({ type: GET_PARTNER_PUBLIC_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PARTNER_PUBLIC_PROFILE_ERROR, error });
  }
}

function* getPartnerWatcher() {
  yield takeLatest(GET_PARTNER_START, getPartner);
}
function* createPartnerWatcher() {
  yield takeLatest(CREATE_PARTNER_START, createPartner);
}

function* editPartnerWatcher() {
  yield takeLatest(EDIT_PARTNER_START, editPartner);
}

function* disablePartnerWatcher() {
  yield takeLatest(DISABLE_PARTNER_START, disablePartner);
}

function* getPartnerPublicProfileWatcher() {
  yield takeLatest(GET_PARTNER_PUBLIC_PROFILE_START, getPartnerPublicProfile);
}

export default [
  getPartnerWatcher(),
  createPartnerWatcher(),
  editPartnerWatcher(),
  disablePartnerWatcher(),
  getPartnerPublicProfileWatcher(),
];
