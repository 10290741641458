export const DELETE_USER_IMAGE_START = 'DELETE_USER_IMAGE_START';
export const DELETE_USER_IMAGE_ERROR = 'DELETE_USER_IMAGE_ERROR';
export const DELETE_USER_IMAGE_SUCCESS = 'DELETE_USER_IMAGE_SUCCESS';

export default function deleteUserImage(id) {
  return {
    type: DELETE_USER_IMAGE_START,
    id,
  };
}
