import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getDeal(dealId) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/deals/${ dealId }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function createDeal(data) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/deals`, {
    method: 'POST',
    body: { ...data },
    externalUrl: true,
  });
}

export function updateDeal(id, data) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/deals/${ id }`, {
    method: 'PUT',
    body: { ...data },
    externalUrl: true,
  });
}

export function getDealList(params) {
  const query = new URLSearchParams(params);
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/deals?${ query }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function deleteDeal(id) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/deals/${ id }`, {
    method: 'DELETE',
    externalUrl: true,
  });
}
