import { SEND_CONTACT_SUCCESS } from '@app/store/etinerary/constants';
import {
  GET_INIT_INFO_START,
  GET_INIT_INFO_ERROR,
  GET_INIT_INFO_SUCCESS,
  UPDATE_ACTIVE_LANGUAGE,
  SET_PREFERRED_LANGUAGE_START,
  SET_PREFERRED_LANGUAGE_ERROR,
  SET_PREFERRED_LANGUAGE_SUCCESS,
  UPDATE_USER_AVATAR, GET_SESSION_INFO_SUCCESS,
} from '@src/js/actions/initInfo';

import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  EXIT_IMPERSONATE_SESSION_SUCCESS,
  IMPERSONATE_USER_SUCCESS,
  LOGIN_USER_START,
  LOGIN_USER_ERROR,
  LOGOUT_USER_START,
  LOGOUT_USER_ERROR,
} from '@src/js/actions/user/user';
import { SET_CONFIRMED_PROFILE } from '@app/store/global/actions';
import { getEOPCookieLanguage, setDefaultLanguageEOP } from '@src/js/helpers/helpers';

export const initialState = {
  initInfoLoading: false,
  initInfoError: null,
  userInfo: null,
  localizationMessages: null,
  loginLoading: false,
  logoutSuccess: false,
  setPrefLangError: null,
  setPrefLangLoading: false,
};

const actionsMap = {
  [GET_INIT_INFO_START]: (state) => ({
    ...state,
    initInfoLoading: true,
  }),
  [GET_INIT_INFO_ERROR]: (state, action) => ({
    ...state,
    initInfoLoading: false,
    // todo fix this - if api is unavailable, we are stuck on logo and get console error
    // uncaught at getInitInfo TypeError: Cannot read property 'response' of undefined
    initInfoError: action.error?.response,
  }),
  [GET_INIT_INFO_SUCCESS]: (state, action) => {
    const data = action.data;
    const localizationMessages = [];
    const userData = data?.user ?? null;
    const cookieLang = getEOPCookieLanguage();
    let activeLanguage = Object.keys(data.localization).includes(cookieLang) ? cookieLang : 'en';

    Object.keys(data.localization).forEach(locale => {
      localizationMessages[locale] = data.localization[locale];
    });

    if (userData?.preferredLanguage) {
      activeLanguage = userData.preferredLanguage;
    }

    if (cookieLang !== activeLanguage) {
      setDefaultLanguageEOP(activeLanguage);
    }

    return {
      ...state,
      localizationMessages,
      activeLanguage,
      userInfo: userData,
      initInfoLoading: false,
      initInfoError: null,
    };
  },
  [GET_SESSION_INFO_SUCCESS]: (state, action) => {
    const session = action.session;
    return {
      ...state,
      session,
    };
  },
  [LOGIN_USER_START]: (state) => ({
    ...state,
    loginLoading: true,
  }),
  [LOGIN_USER_ERROR]: (state) => ({
    ...state,
    loginLoading: false,
  }),
  [LOGIN_USER_SUCCESS]: (state, action) => {
    const data = action.data;
    let userData = null;
    let loginErrorMessage = false;
    let loginReCaptcha = null;
    const cookieLang = getEOPCookieLanguage();
    let activeLanguage = cookieLang ?? 'en';
    if (data.message) {
      loginErrorMessage = data.message;
      delete data.message;
    }
    if (data.reCaptcha) {
      loginReCaptcha = data.reCaptcha;
      delete data.reCaptcha;
    }
    if (data.preferredLanguage) {
      activeLanguage = data.preferredLanguage;
    }
    if (data.isLoggedIn) {
      userData = data;
    }

    if (cookieLang !== activeLanguage) {
      setDefaultLanguageEOP(activeLanguage);
    }

    return {
      ...state,
      activeLanguage,
      userInfo: userData,
      initInfoLoading: false,
      loginErrorMessage,
      loginReCaptcha,
      loginLoading: false,
    };
  },
  [LOGOUT_USER_SUCCESS]: (state) => ({
    ...state,
    initInfoLoading: false,
    initInfoError: null,
    userInfo: null,
    logoutSuccess: true,
  }),
  [LOGOUT_USER_ERROR]: (state) => ({
    ...state,
    logoutSuccess: false,
  }),
  [LOGOUT_USER_START]: (state) => ({
    ...state,
    logoutSuccess: false,
  }),
  [IMPERSONATE_USER_SUCCESS]: (state, action) => {
    const cookieLang = getEOPCookieLanguage();
    let activeLanguage = cookieLang ?? 'en';

    if (cookieLang !== activeLanguage) {
      setDefaultLanguageEOP(activeLanguage);
    }

    if (action.data.impersonatedUser.preferredLanguage) {
      activeLanguage = action.data.impersonatedUser.preferredLanguage;
    }
    return {
      ...state,
      activeLanguage,
      userInfo: action.data.impersonatedUser,
    };
  },
  [EXIT_IMPERSONATE_SESSION_SUCCESS]: (state, action) => {
    const cookieLang = getEOPCookieLanguage();
    let activeLanguage = cookieLang ?? 'en';

    if (cookieLang !== activeLanguage) {
      setDefaultLanguageEOP(activeLanguage);
    }

    if (action.data.originalUser.preferredLanguage) {
      activeLanguage = action.data.originalUser.preferredLanguage;
    }
    return {
      ...state,
      activeLanguage,
      userInfo: action.data.originalUser,
    };
  },
  [UPDATE_ACTIVE_LANGUAGE]: (state, action) => {
    const cookieLang = getEOPCookieLanguage();

    if (cookieLang !== action.languageCode) {
      setDefaultLanguageEOP(action.languageCode);
    }

    return {
      ...state,
      activeLanguage: action.languageCode,
    };
  },
  [SET_PREFERRED_LANGUAGE_START]: (state) => ({
    ...state,
    setPrefLangLoading: true,
    setPrefLangError: null,
  }),
  [SET_PREFERRED_LANGUAGE_ERROR]: (state, action) => ({
    ...state,
    setPrefLangLoading: false,
    setPrefLangError: action.error,
  }),
  [SET_PREFERRED_LANGUAGE_SUCCESS]: (state, action) => {
    const cookieLang = getEOPCookieLanguage();

    if (cookieLang !== action.data) {
      setDefaultLanguageEOP(action.data);
    }

    return {
      ...state,
      setPrefLangLoading: false,
      setPrefLangError: null,
      activeLanguage: action.data,
      userInfo: {
        ...state.userInfo,
        preferredLanguage: action.data,
      },
    };
  },
  [UPDATE_USER_AVATAR]: (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      avatar: action.url,
    },
  }),
  [SEND_CONTACT_SUCCESS]: (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      etineraryContacted: action.data.etineraryContacted,
    },
  }),
  [SET_CONFIRMED_PROFILE]: (state) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      confirmData: false,
    },
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
