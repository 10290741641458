import { TargetBlank } from '@src/_v2/js/constants';
import { AppLink } from '@src/js/constants/entities';
import { legalLinks } from '@src/js/constants/routes';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

class FooterDesktop extends Component {
  static propTypes = {
    activeLanguage: PropTypes.string,
  };

  render() {
    const { activeLanguage } = this.props;

    const termsUrl = activeLanguage === 'it' ? legalLinks.TERMS_IT : legalLinks.TERMS;
    const appleUrl = activeLanguage === 'it' ? AppLink.APPLE_IT : AppLink.APPLE_EN;
    const androidUrl = activeLanguage === 'it' ? AppLink.ADNDROID_IT : AppLink.ADNDROID_EN;

    return (
      <footer className='desktop'>
        <div className='primary-footer'>
          <Container>
            <Row className='middle-xs between-xs'>
              <div className='footer-logo' />
              <div className='social-block'>
                <a
                  className='social-icons'
                  href={ appleUrl }
                  aria-label='Apple'
                  role='button'
                  { ...TargetBlank }
                >
                  <span className='icon-Apple' />
                </a>
                <a
                  className='social-icons'
                  href={ androidUrl }
                  aria-label='Android'
                  role='button'
                  { ...TargetBlank }
                >
                  <span className='icon-Android' />
                </a>
                <a
                  className='social-icons'
                  href='https://www.linkedin.com/company/elite_group/'
                  aria-label='LinkedIn'
                  role='button'
                  { ...TargetBlank }
                >
                  <span className='icon-Linkedin' />
                </a>
                <a
                  className='social-icons'
                  href='https://www.youtube.com/channel/UCeuczF5_iDMgQpVlr25B4IA'
                  aria-label='YouTube'
                  role='button'
                  { ...TargetBlank }
                >
                  <span className='icon-Youtube' />
                </a>
              </div>
            </Row>
          </Container>
        </div>

        <div className='secondary-footer'>
          <Container>
            <Row className='between-xs'>
              <div>
                <div className='secondary-footer-links'>
                  <a
                    className='secondary-footer-links-item'
                    href={ termsUrl }
                    { ...TargetBlank }
                  >
                    <FormattedMessage id='navigation.terms' />
                  </a>
                </div>
              </div>

              <div className='copyright'>
                { `© ${ (new Date()).getFullYear() } ELITE Group` }
              </div>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  activeLanguage: state.initInfo.activeLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(FooterDesktop);

export default withRouter(connectWrapper);
