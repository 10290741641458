import {
  all,
  call, put,
  takeLatest,
} from 'redux-saga/effects';

import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import {
  ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_ERROR,
  ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START,
  ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_SUCCESS,
  ADMIN_CREATE_USER_ERROR,
  ADMIN_CREATE_USER_START,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_DISABLE_USER_ERROR,
  ADMIN_DISABLE_USER_NOTIFICATIONS_ERROR,
  ADMIN_DISABLE_USER_NOTIFICATIONS_START,
  ADMIN_DISABLE_USER_NOTIFICATIONS_SUCCESS,
  ADMIN_DISABLE_USER_START,
  ADMIN_DISABLE_USER_SUCCESS,
  ADMIN_EDIT_USER_ERROR,
  ADMIN_EDIT_USER_START,
  ADMIN_EDIT_USER_SUCCESS,
  ADMIN_GET_USER_ERROR,
  ADMIN_GET_USER_START,
  ADMIN_GET_USER_SUCCESS,
} from '@src/js/actions/user/adminUser';
import * as api from '@src/js/api/user/adminUser';
import { adminRouteCodes, withLang } from '@src/js/constants/routes';

function* adminGetUser(options) {
  try {
    const data = yield call(() => api.adminGetUser(options.id));
    yield put({ type: ADMIN_GET_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_USER_ERROR, error });
  }
}

function* adminCreateUser({ user, history }) {
  try {
    const data = yield call(() => api.adminCreateUser(user));
    yield all([
      put({ type: ADMIN_CREATE_USER_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.create.success_message')),
    ]);
    history.push(withLang(adminRouteCodes.USERS));
  } catch (error) {
    yield all([
      put({ type: ADMIN_CREATE_USER_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* adminEditUser({ user, history }) {
  try {
    const data = yield call(() => api.adminEditUser(user));
    yield all([
      put({ type: ADMIN_EDIT_USER_SUCCESS, data }),
      put(showToastrSuccess('notification.success_title', 'notification.company_form.edit.success_message')),
    ]);
    history.push(withLang(adminRouteCodes.USERS));
  } catch (error) {
    yield all([
      put({ type: ADMIN_EDIT_USER_ERROR, error }),
      put(showToastrError()),
    ]);
  }
}

function* adminDisableUser(options) {
  try {
    const data = yield call(() => api.adminDisableUser(options.userId));
    yield put({ type: ADMIN_DISABLE_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_DISABLE_USER_ERROR });
  }
}

function* adminDisableUserNotifications(options) {
  try {
    const data = yield call(() => api.adminDisableUserNotifications(options.userId));
    yield all([
      put(showToastrSuccess('notification.success_title', 'disableNotifications.successText')),
      put({ type: ADMIN_DISABLE_USER_NOTIFICATIONS_SUCCESS, data }),
    ]);
  } catch (error) {
    yield all([
      put(showToastrError()),
      put({ type: ADMIN_DISABLE_USER_NOTIFICATIONS_ERROR }),
    ]);
  }
}

function* changeCompanyCreationPermission(options) {
  try {
    const data = yield call(() => api.changeCompanyCreationPermission(options.data));
    yield put({ type: ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_ERROR });
  }
}

function* adminGetUserWatcher() {
  yield takeLatest(ADMIN_GET_USER_START, adminGetUser);
}

function* adminCreateUserWatcher() {
  yield takeLatest(ADMIN_CREATE_USER_START, adminCreateUser);
}

function* adminDisableUserWatcher() {
  yield takeLatest(ADMIN_DISABLE_USER_START, adminDisableUser);
}

function* adminDisableUserNotificationsWatcher() {
  yield takeLatest(ADMIN_DISABLE_USER_NOTIFICATIONS_START, adminDisableUserNotifications);
}

function* adminEditUserWatcher() {
  yield takeLatest(ADMIN_EDIT_USER_START, adminEditUser);
}

function* changeCompanyCreationPermissionWatcher() {
  yield takeLatest(ADMIN_CHANGE_COMPANY_CREATION_PERMISSIONS_START, changeCompanyCreationPermission);
}

export default [
  adminGetUserWatcher(),
  adminCreateUserWatcher(),
  adminDisableUserWatcher(),
  adminDisableUserNotificationsWatcher(),
  adminEditUserWatcher(),
  changeCompanyCreationPermissionWatcher(),
];
