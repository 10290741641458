import React from 'react';
import PropTypes from 'prop-types';
import { toast, Slide } from 'react-toastify';

const ToastDisplay = ({ title, message }) => (
  <>
    <strong>{title}</strong>
    {' '}
    {message}
  </>
);

ToastDisplay.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

const toasterOptions = {
  position: 'top-center',
  theme: 'colored',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Slide,
};

export const toastSuccess = (title, message) => {
  toast.success(<ToastDisplay title={ title } message={ message } />, toasterOptions);
};

export const toastError = (title, message) => {
  toast.error(<ToastDisplay title={ title } message={ message } />, toasterOptions);
};
