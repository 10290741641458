export const ThemeColors = {
  // Euronext (palette -> https://connect2-local.dept.ie/live__ui-kit__colour-palette.html)
  teal: '#00958f',
  lightTeal: '#40aa9f',
  teal75: '#80c6bf',
  teal50: '#bfe3df',
  teal25: '#e6f4f2',
  darkTeal: '#053f38',
  tiberGreen: '#001924',
  darkGreyTwo: '#c1c8ca',
  lightGreyTwo: '#e5e8ea',
  green: '#009639',
  red: '#fe4d6a',
  orange: '#ffab2b',

  // Primary
  blue: '#009DCD',
  black: '#001924',
  white: '#FFFFFF',

  // Secondary
  darkGrey: '#454D57',
  mediumGrey: '#727981',
  gray: '#999999',
  lightGrey: '#D1D2D6',
  softGrey: '#F8F8F8',
  deepBlue: '#20344C',
  darkBlue: '#0077B5',
  mediumBlue: '#66C4E0',
  lightBlue: '#CDEBF5',
  softBlue: '#F4F7F9',
  lightRed: '#F35555',

  // Tertiary
  softGreen: '#F4FCF6',
  softRed: '#FEF6F6',
  silver: '#EEEEEE',
};
