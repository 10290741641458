import {
  FormSection,
  InputMobile,
  InputSelect, InputText,
} from '@app/components/global/forms';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import { REGISTER_START, UPDATE_BASIC_START } from '@app/store/onboarding/actions';
import { COMPANY_FORM_STEPS, PARTNER_FORM_STEPS } from '@app/store/onboarding/constants';
import {
  selectOnboardingData, selectOnboardingSave,
  selectRegisterLoading, selectTemplateData,
} from '@app/store/onboarding/selectors';
import { getTemplateData } from '@src/js/actions/onboarding/onboardingTemplate';
import { Button } from '@src/js/components/global';
import Loader from '@src/js/components/global/pageLoader/PageLoader';
import { isAdminRole, isPra } from '@src/js/helpers/helpers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Language } from '@src/js/constants/entities';

const BasicInfo = ({
  hash, isPartner,
}) => {
  const onboardingData = selectOnboardingData();
  const templateData = selectTemplateData();
  const loading = selectRegisterLoading();
  const dispatch = useDispatch();
  const admin = isAdminRole() || isPra();
  const {
    getValues, formState: { isValid, errors }, register, control, trigger, watch,
  } = useForm({
    defaultValues: onboardingData?.basic,
    mode: 'onChange',
    revalidateMode: 'onChange',
  });
  const onSave = selectOnboardingSave();
  const templateHash = admin ? onboardingData?.template?.hash : hash;

  const countries = templateData?.templateFormOptions?.countries?.map(country => ({
    label: country.name,
    value: country.id,
  })) || [];
  const currentCountry = watch('country');
  const isLangEn = templateData.templateData.lang === Language.EN;
  const isLangIt = templateData.templateData.lang === Language.IT;
  const isLangFr = templateData.templateData.lang === Language.FR;
  const isCountryNl = templateData.templateFormOptions?.countries?.find(
    c => c.alphaTwo === 'nl'
  )?.id === currentCountry;

  const onSubmit = (step) => {
    const data = getValues();
    trigger();
    if (isValid) {
      if (admin) {
        dispatch({
          type: UPDATE_BASIC_START, data, hash, step,
        });
      } else {
        dispatch({
          type: REGISTER_START,
          data,
          templateHash,
        });
      }
    }
  };

  useEffect(() => {
    if (onSave?.action) {
      onSubmit(onSave.newStep);
    }
  }, [onSave]);

  useEffect(() => {
    if (templateHash) {
      dispatch(getTemplateData(templateHash));
    }
  }, [templateHash, dispatch]);

  return (
    <form>
      { loading && <Loader />}
      <FormSection sectionTitle='onboarding.sectionOne.basic'>
        <EmailSupport />
        <InputText
          name='companyName'
          register={ register }
          errors={ errors }
          label='onboarding.sectionOne.companyName'
          required
        />
        <InputText
          name='vatNumber'
          register={ register }
          errors={ errors }
          type={ isLangIt ? 'textNumber' : 'text' }
          label='onboarding.sectionOne.vatNumber'
          required
          tooltip={ isLangIt ? 'onboarding.form.vat.tooltip' : null }
        />
        {
          isLangFr ? (
            <InputText
              name='siren'
              register={ register }
              errors={ errors }
              type={ 'textNumber' }
              label='onboarding.sectionOne.siren'
              required
            />
          ) : null
        }
        <InputSelect
          name='country'
          errors={ errors }
          control={ control }
          options={ countries }
          label='onboarding.sectionOne.country'
          required
        />
        {
          isLangEn && isCountryNl ? (
            <InputText
              name='coc'
              register={ register }
              errors={ errors }
              type={ 'textNumber' }
              label='onboarding.sectionOne.coc'
            />
          ) : null
        }
        <InputText
          name='firstName'
          register={ register }
          errors={ errors }
          label='onboarding.sectionOne.firstName.partner'
          required
        />
        <InputText
          name='lastName'
          register={ register }
          errors={ errors }
          label='onboarding.sectionOne.lastName.partner'
          required
        />
        <InputText
          name='jobTitle'
          register={ register }
          errors={ errors }
          label='onboarding.sectionOne.jobTitle'
          required
        />
        <InputText
          name='email'
          register={ register }
          errors={ errors }
          label='onboarding.sectionOne.email'
          type='textEmail'
          required
        />
        {isPartner && !isLangFr && (
        <InputText
          name='taxCode'
          register={ register }
          errors={ errors }
          label='onboarding.sectionOne.taxCode'
          required
        />
        )}
        <InputMobile
          name='mobilePhone'
          defaultCountry={ isLangIt ? 'IT' : '' }
          control={ control }
          errors={ errors }
          label='onboarding.sectionOne.mobilePhone'
          required
          tooltip='onboarding.form.mobile.tooltip'
        />
      </FormSection>
      <div className={
        `compliance__buttons-group elite-white-box--form ${ admin ? '' : 'compliance__buttons-group--align-end' }` }
      >
        {admin && (
          <div className='compliance__buttons-pair'>
            <Button
              additionalClass='eop-btn'
              onClickFunc={ () => onSubmit(null) }
              buttonText='save'
            />
          </div>
        )}
        <div className='compliance__buttons-pair align-self-end'>
          <Button
            additionalClass='eop-btn'
            onClickFunc={ () => onSubmit(
              isPartner ? PARTNER_FORM_STEPS.PARTNER_SUMMARY : COMPANY_FORM_STEPS.COMPANY_SUMMARY) }
            buttonText={ admin ? 'next' : 'main.submit' }
          />
        </div>
      </div>
      <EmailSupport bottom />
    </form>
  );
};

BasicInfo.propTypes = {
  hash: PropTypes.string.isRequired,
  isPartner: PropTypes.bool,
};

export default BasicInfo;
