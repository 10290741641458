const scope = 'COMPANY_WIDGET';

export const GET_TRAININGS_SUMMARY_START = `${ scope }/GET_TRAININGS_SUMMARY_START`;
export const GET_TRAININGS_SUMMARY_FAIL = `${ scope }/GET_TRAININGS_SUMMARY_FAIL`;
export const GET_TRAININGS_SUMMARY_SUCCESS = `${ scope }/GET_TRAININGS_SUMMARY_SUCCESS`;

export const GET_TASKS_SUMMARY_START = `${ scope }/GET_TASKS_SUMMARY_START`;
export const GET_TASKS_SUMMARY_FAIL = `${ scope }/GET_TASKS_SUMMARY_FAIL`;
export const GET_TASKS_SUMMARY_SUCCESS = `${ scope }/GET_TASKS_SUMMARY_SUCCESS`;

export const GET_PROFILE_SUMMARY_START = `${ scope }/GET_PROFILE_SUMMARY_START`;
export const GET_PROFILE_SUMMARY_FAIL = `${ scope }/GET_PROFILE_SUMMARY_FAIL`;
export const GET_PROFILE_SUMMARY_SUCCESS = `${ scope }/GET_PROFILE_SUMMARY_SUCCESS`;

export const GET_EVENTS_SUMMARY_START = `${ scope }/GET_EVENTS_SUMMARY_START`;
export const GET_EVENTS_SUMMARY_FAIL = `${ scope }/GET_EVENTS_SUMMARY_FAIL`;
export const GET_EVENTS_SUMMARY_SUCCESS = `${ scope }/GET_EVENTS_SUMMARY_SUCCESS`;

export const GET_LIBRARIES_SUMMARY_START = `${ scope }/GET_LIBRARIES_SUMMARY_START`;
export const GET_LIBRARIES_SUMMARY_FAIL = `${ scope }/GET_LIBRARIES_SUMMARY_FAIL`;
export const GET_LIBRARIES_SUMMARY_SUCCESS = `${ scope }/GET_LIBRARIES_SUMMARY_SUCCESS`;

export const GET_OPPORTUNITIES_SUMMARY_START = `${ scope }/GET_OPPORTUNITIES_SUMMARY_START`;
export const GET_OPPORTUNITIES_SUMMARY_FAIL = `${ scope }/GET_OPPORTUNITIES_SUMMARY_FAIL`;
export const GET_OPPORTUNITIES_SUMMARY_SUCCESS = `${ scope }/GET_OPPORTUNITIES_SUMMARY_SUCCESS`;

export const SEND_EVENTS_PUBLICATION_REQUEST_START = `${ scope }/SEND_EVENTS_PUBLICATION_REQUEST_START`;
export const SEND_EVENTS_PUBLICATION_REQUEST_FAIL = `${ scope }/SEND_EVENTS_PUBLICATION_REQUEST_FAIL`;
export const SEND_EVENTS_PUBLICATION_REQUEST_SUCCESS = `${ scope }/SEND_EVENTS_PUBLICATION_REQUEST_SUCCESS`;

export function getTrainingsSummary() {
  return {
    type: GET_TRAININGS_SUMMARY_START,
  };
}

export function getTasksSummary() {
  return {
    type: GET_TASKS_SUMMARY_START,
  };
}

export function getProfileSummary() {
  return {
    type: GET_PROFILE_SUMMARY_START,
  };
}

export function getPartnerEventsSummary() {
  return {
    type: GET_EVENTS_SUMMARY_START,
  };
}

export function getPartnerLibrariesSummary() {
  return {
    type: GET_LIBRARIES_SUMMARY_START,
  };
}

export function getPartnerOpportunitySummary() {
  return {
    type: GET_OPPORTUNITIES_SUMMARY_START,
  };
}

export function sendEventsPublicationRequest() {
  return {
    type: SEND_EVENTS_PUBLICATION_REQUEST_START,
  };
}
