import fetchResource from '@src/js/api/fetch-resource';

export function getBulkMessageSender(filters) {
  let url = 'api/admin/bulk-message/sender-options';
  if (filters) {
    const query = new URLSearchParams(filters);

    url = `${ url }?${ query }`;
  }

  return fetchResource(url);
}
