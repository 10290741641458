import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ClassNames from 'classnames';
import { get as lodashGet } from 'lodash';
import ReactTooltip from 'react-tooltip';
import {
  Warning, RequiredIcon, FixedInputPrefix, TextInputContainer,
} from './style';

export default class TextInput extends Component {
  static propTypes = {
    fieldType: PropTypes.oneOf(['text', 'password', 'email']).isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    fieldDisabled: PropTypes.bool,
    fieldRequired: PropTypes.bool,
    fieldMaxLength: PropTypes.number,
    fieldOnChange: PropTypes.func,
    fieldOnFocus: PropTypes.func,
    fieldOnBlur: PropTypes.func,
    additionalClass: PropTypes.string,
    className: PropTypes.string,
    validationMessageOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    min: PropTypes.number,
    fixedPrefix: PropTypes.string,
    translate: PropTypes.bool,
    fieldTooltip: PropTypes.string,
  };

  static defaultProps = {
    translate: true,
  };

  renderTextInputBlockWithFixedPrefix = () => {
    const { fixedPrefix } = this.props;

    return (
      <TextInputContainer>
        <FixedInputPrefix>
          { fixedPrefix }
        </FixedInputPrefix>

        <div className='eop-text-input__input eop-text-input-with-fixed-prefix'>
          { this.getInputElement() }
          { this.getDisabledElement() }
        </div>
      </TextInputContainer>
    );
  };

  renderTextInputBlock = () => (
    <div className='eop-text-input__input'>
      { this.getInputElement() }
      { this.getDisabledElement() }
    </div>
  );

  getInputElement = () => {
    const {
      min, input, fieldType, placeholder, fieldMaxLength, fieldDisabled,
      translate, fieldOnChange, fieldOnFocus, fieldOnBlur,
    } = this.props;

    let inputEl = (
      <input
        { ...input }
        className='eop-text-input__input-item'
        type={ fieldType }
        maxLength={ fieldMaxLength }
        min={ min }
        disabled={ fieldDisabled && 'disabled' }
        onFocus={ fieldOnFocus || input.onFocus }
        onBlur={ fieldOnBlur || input.onBlur }
      />
    );

    if (placeholder) {
      if (translate) {
        inputEl = (
          <FormattedMessage id={ placeholder }>
            {
              placeholderMessage => (
                <input
                  { ...input }
                  className='eop-text-input__input-item'
                  type={ fieldType }
                  placeholder={ placeholderMessage }
                  maxLength={ fieldMaxLength }
                  min={ min }
                  disabled={ fieldDisabled && 'disabled' }
                  onChange={ fieldOnChange || input.onChange }
                />
              )
            }
          </FormattedMessage>
        );
      } else {
        inputEl = (
          <input
            { ...input }
            className='eop-text-input__input-item'
            type={ fieldType }
            maxLength={ fieldMaxLength }
            placeholder={ placeholder }
            min={ min }
            disabled={ fieldDisabled && 'disabled' }
            onChange={ fieldOnChange || input.onChange }
          />
        );
      }
    }

    return inputEl;
  };

  getDisabledElement = () => {
    const { fieldDisabled } = this.props;

    return fieldDisabled
      ? <span className='eop-text-input__input-icon icon-LockerSmall' />
      : null;
  };

  renderFieldLabel = () => {
    const {
      translate, fieldLabel, fieldName, fieldTooltip,
    } = this.props;

    const tooltipText = translate && fieldTooltip ? <FormattedMessage id={ fieldTooltip } /> : fieldTooltip;

    const tooltip = fieldTooltip ? (
      <>
        <span className='icon-medium-Info font-size-icon m-l-1' data-tip data-for={ `${ fieldName }-tooltip` } />
        <ReactTooltip
          id={ `${ fieldName }-tooltip` }
          place='top'
          effect='solid'
          multiline={ true }
          className='w-25'
        >
          { tooltipText }
        </ReactTooltip>
      </>
    ) : null;

    return translate
      ? (
        <>
          <FormattedMessage id={ fieldLabel } />
          {fieldTooltip && tooltip}
        </>
      )
      : (
        <>
          { fieldLabel }
          {fieldTooltip && tooltip}
        </>
      );
  };

  render() {
    const {
      meta, fieldLabel, fieldRequired, additionalClass, validationMessageOnly, fixedPrefix, className,
    } = this.props;

    let minValue = '';
    let maxValue = '';
    let maxLength = '';
    let minLength = '';
    let validationErrorId = '';

    if (meta) {
      const validationError = (meta.error || meta.submitError);
      if (validationError) {
        validationErrorId = (validationError.id || validationError);
        maxLength = lodashGet(validationError, 'values.maxLength');
        minLength = lodashGet(validationError, 'values.minLength');
        maxValue = lodashGet(validationError, 'values.maxValue');
        minValue = lodashGet(validationError, 'values.minValue');
      }
    }

    return (
      <div className={ ClassNames('eop-text-input', className, additionalClass) }>
        <label
          className={
            ClassNames('eop-text-input__label', {
              'eop-text-input__label--validation-only': validationMessageOnly,
            })
          }
        >
          { fieldRequired && <RequiredIcon /> }
          { fieldLabel && this.renderFieldLabel() }
          { fixedPrefix
            ? this.renderTextInputBlockWithFixedPrefix()
            : this.renderTextInputBlock()
          }
        </label>
        { meta
        && (
          <Warning className='Warning'>
            {
              (
                meta.error
                || (meta.submitError && meta.dirtySinceLastSubmit === false)
              )
              && (
                meta.touched
                || (
                  validationMessageOnly
                  && (meta.dirty || meta.dirtySinceLastSubmit)
                  && meta.submitFailed
                )
              )
              && validationErrorId
              && (
                <FormattedMessage
                  id={ validationErrorId }
                  values={ {
                    maxLength,
                    minLength,
                    minValue,
                    maxValue,
                  } }
                />
              )
            }
          </Warning>
        ) }
      </div>
    );
  }
}
