export const ADMIN_GET_COMPANIES_START = 'ADMIN_GET_COMPANIES_START';
export const ADMIN_GET_COMPANIES_ERROR = 'ADMIN_GET_COMPANIES_ERROR';
export const ADMIN_GET_COMPANIES_SUCCESS = 'ADMIN_GET_COMPANIES_SUCCESS';

export function adminGetCompanies(filters) {
  return {
    type: ADMIN_GET_COMPANIES_START,
    filters,
  };
}
