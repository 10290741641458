import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ClassNames from 'classnames';
import { routeCodes } from '@src/js/constants/routes';
import { purifyHtml } from '@src/js/utils';
import { useAppDispatch } from '@src/js/store';
import { getNetworking } from '@src/js/actions/networking/companyAndPartner';

type Props = {
  companies: any[],
  selectedCompany: number|null,
  onSelectCompany: (val: number|null) => void
}

const SuggestedCompanies = ({ companies, selectedCompany, onSelectCompany }: Props) => {
  const dispatch = useAppDispatch();
  const getCompanyLogo = (company: any) => {
    const urlImage = routeCodes.IMAGE_LOGO
      .replace(':entityName', company.company.entityName)
      .replace(':entityId', company.company.id)
      .replace(':entityModified', Date.parse(company.company.modified).toString());

    return urlImage;
  };

  const isCompanySelected = (companyId: number) => selectedCompany === companyId;

  useEffect(() => {
    dispatch(getNetworking(false));
  }, [dispatch]);

  return (
    companies
    && (
      <>
        <div className='new-message-title'>
          <FormattedMessage id='navigation.networking' />
        </div>
        <div className='peers-list-container'>
          {companies && companies.map((company) => (
            <div
              key={ company.company.id }
              className={ ClassNames('peer-item',
                { 'company-item__selected': isCompanySelected(company.company.id) }) }
              role='presentation'
              onClick={ () => onSelectCompany(company.company.id) }
            >
              <div className='peer-item-logo-networking'>
                <img src={ getCompanyLogo(company) } alt='Avatar' />
              </div>
              <div className='peer-item-subject'>
                <div className='peer-item-subject--bold'>
                  {company.company.name}
                </div>
                <div className='peer-item-subject__info'>
                  <p
                    dangerouslySetInnerHTML={ { __html: purifyHtml(company.description) } }
                  />
                </div>
              </div>
            </div>
          )
          )}
        </div>
      </>
    )
  );
};

export default SuggestedCompanies;
