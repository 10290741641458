import React, { useEffect, useState } from 'react';
import {
  FormSection, InputText, InputSelect,
} from '@app/components/global/forms';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  selectOnboardingData, selectOnboardingSave, selectFirstVisit, selectCurrentStep,
} from '@app/store/onboarding/selectors';
import { Language } from '@src/js/constants/entities';
import InputDate from '@app/components/global/forms/InputDate';
import { selectCountries, selectProvinces, selectRegions } from '@app/store/global/selectors/taxonomy';
import { dateToISOString } from '@app/components/public/onboarding/utils';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';

const CompanySummary = ({ handleSave, intl }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const options = [
    { label: intl.formatMessage({ id: 'onboarding.companySummary.splitPayment.yes' }), value: true },
    { label: intl.formatMessage({ id: 'onboarding.companySummary.splitPayment.no' }), value: false },
  ];
  const onboardingData = selectOnboardingData();
  const countries = selectCountries()?.map(country => ({ label: country.name, value: country.id }));
  const provinces = selectProvinces() || [];
  const regions = selectRegions()?.map(region => ({ ...region, label: region.name, value: region.name }));
  const onSave = selectOnboardingSave();
  const {
    register, formState: { errors }, getValues, control, watch, setValue, trigger,
  } = useForm({
    defaultValues: onboardingData?.company,
    mode: 'onChange',
  });
  const country = watch('country');
  const region = watch('region');
  const [provincesOption, setProvincesOption] = useState(provinces);
  const [init, setInit] = useState(false);
  const showRegions = country && country === regions?.[0]?.parentId;

  useEffect(() => {
    if (onSave?.action) {
      const formData = { ...getValues() };
      formData.dateIncorporation = dateToISOString(formData.dateIncorporation);
      handleSave({ company: formData });
    }
  }, [onSave]);

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (region) {
      setProvincesOption(provinces?.filter(
        province => province.parentId === regions?.find(r => r.name === region)?.id
      ));
    }
  }, [region]);

  useEffect(() => {
    if (!init && regions?.length > 0 && provinces?.length > 0) {
      setProvincesOption(provinces?.filter(
        province => province.parentId === regions?.find(r => r.name === region)?.id
      ));
      setInit(true);
    }
  }, [regions, provinces, init]);

  const changeCountry = (e) => {
    setValue('country', e?.value);
    setValue('region', null);
    setValue('provinceId', null);
  };

  const changeRegion = (e) => {
    setValue('region', e?.value || null);
    setValue('provinceId', null);
  };

  const langIT = onboardingData?.template?.lang === Language.IT;
  const langFR = onboardingData?.template?.lang === Language.FR;

  const fields = ['city', 'address', 'postalCode'];

  return (
    <form>
      <FormSection sectionTitle='onboarding.companySummary.companySummary'>
        <EmailSupport />
        <InputText
          name='brandName'
          register={ register }
          errors={ errors }
          type='text'
          label='onboarding.companySummary.brandName'
        />
        { showRegions ? (
          <>
            <InputSelect
              name='region'
              control={ control }
              errors={ errors }
              options={ regions }
              label='onboarding.companySummary.region'
              required={ langIT }
              onChange={ changeRegion }
              simpleValue={ false }
            />
            <InputSelect
              name='provinceId'
              control={ control }
              errors={ errors }
              options={ provincesOption }
              label='onboarding.companySummary.province'
              required={ langIT }
              simpleValue
              disabled={ !region }
            />
          </>
        ) : (
          <InputText
            name='region'
            register={ register }
            errors={ errors }
            type='text'
            label='onboarding.companySummary.region'
            required={ langIT }
          />
        ) }
        {
          fields.map((key) => (
            <InputText
              key={ key }
              name={ key }
              register={ register }
              errors={ errors }
              type={ key === 'phone' ? 'tel' : 'text' }
              label={ `onboarding.companySummary.${ key }` }
              required
            />
          ))
        }
        <InputDate
          name='dateIncorporation'
          control={ control }
          errors={ errors }
          label='onboarding.companySummary.dateOfIncorporation'
          dateFormat='dd/MM/yyyy'
          required
        />
        <InputSelect
          name='country'
          control={ control }
          errors={ errors }
          options={ countries }
          onChange={ changeCountry }
          label='onboarding.companySummary.country'
          required
          simpleValue={ false }
        />
        { !(langIT || langFR) && (
          <InputText
            name='registrationNumber'
            register={ register }
            errors={ errors }
            type='text'
            label='onboarding.companySummary.registrationNumber'
            required
          />
        )}
        <InputText
          name='phone'
          register={ register }
          errors={ errors }
          type='tel'
          label='onboarding.companySummary.phone'
          required
        />
      </FormSection>
      <div className='elite-white-box' />
      <div className='elite-white-box--single-line split-payment'>
        { langIT && (
        <InputSelect
          className='eop-selectbox--single-line'
          name='splitPayment'
          label='onboarding.companySummary.splitPaymentCompany'
          errors={ errors }
          control={ control }
          options={ options }
          fieldNoClear
          boolRequired
        />
        )}
      </div>
    </form>
  );
};

CompanySummary.propTypes = {
  handleSave: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(CompanySummary);
