import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Loader } from '@src/js/components/global';
import { getCompanyMembers, sendSignDocument } from '@app/store/company/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { selectSignDocument, selectUploadFile } from '@app/store/company/selectors';
import { DebounceInput } from 'react-debounce-input';
import { dropzoneExtensions } from '@src/js/constants/acceptExtensions';
import { fileConstants } from '@src/js/constants/entities';
import { clearFile, uploadFile } from '@src/js/actions/file/uploadFile';
import DropZone from 'react-dropzone';
import UploadPlaceholder from '@src/js/components/global/imageEditor/UploadPlaceholder';
import FilePreview from '@src/js/components/global/cards/FilePreview';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const SignDocumentModal = ({ company }) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const companyMembers = selectSignDocument();
  const uploadedFileSelector = selectUploadFile();
  const uploadedFile = uploadedFileSelector?.file;
  const uploadedFileLoading = uploadedFileSelector?.loading;
  const uploadedFileError = uploadedFileSelector?.error;
  const signLoading = uploadedFileSelector?.signDocumentLoading;
  const members = companyMembers?.data?.members || [];
  const hasMore = companyMembers?.data?.hasMore || false;
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [dropzoneError, setDropzoneError] = useState(null);
  const [filters, setFilters] = useState({ page: 1, search: '' });

  const handleSearchChange = (e) => {
    const newFilters = { page: 1, search: e.target.value || '' };
    setFilters(newFilters);
  };

  const nextUsers = () => {
    const nextPage = { ...filters, page: filters.page + 1 };
    setFilters(nextPage);
  };

  const handleSignFilesDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    const file = new FormData();
    file.append('file', acceptedFiles[0]);
    setDropzoneError(null);
    dispatch(uploadFile({ file }, 'sign', 'document'));
  };

  const handleSignFilesDropRejected = (droppedFiles) => {
    let error = 'eventDocument.mimeTypeMessage';

    if (droppedFiles.length > 1) {
      error = 'dataRoom.numberOfFiles';
    }

    if (droppedFiles[0].size > fileConstants.UPLOAD_SIZE_LIMIT) {
      error = 'dataRoom.maxSizeMessage';
    }
    setDropzoneError(error);
  };

  const send = () => {
    dispatch(sendSignDocument(uploadedFile?.id, selectedUuid));
    hideModal();
  };

  useEffect(() => () => dispatch(clearFile()), []);

  useEffect(() => {
    dispatch(getCompanyMembers(company.slug, filters));
  }, [filters]);

  return (
    <>
      <ModalBody className='m-y-4'>
        <div className='row'>
          <div className='col-lg-12 col-xs-12'>
            <div className='card m-b-2'>
              {uploadedFile
                ? (
                  <FilePreview
                    file={ uploadedFile }
                    arrow={ false }
                    removeFileAction={ () => dispatch(clearFile()) }
                    downloadable
                  />
                )
                : (
                  <div className='upload-card__container upload-card__container-fixed'>
                    {uploadedFileLoading && <Loader />}
                    <DropZone
                      accept={ dropzoneExtensions.PDF }
                      maxSize={ fileConstants.UPLOAD_SIZE_LIMIT }
                      onDrop={ handleSignFilesDrop }
                      onDropRejected={ handleSignFilesDropRejected }
                      multiple={ false }
                      style={ { height: '100%' } }
                      useFsAccessApi={ false }
                    >
                      {({ getInputProps, getRootProps }) => (
                        <div { ...getRootProps() }>
                          <input { ...getInputProps() } />
                          <UploadPlaceholder />
                        </div>
                      )}
                    </DropZone>
                  </div>
                )
              }
              {(dropzoneError || uploadedFileError) && (
                <p className='eop-text-color-red'>
                  <FormattedMessage id={ dropzoneError || uploadedFileError } />
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='search-peers'>
          <DebounceInput
            debounceTimeout={ 500 }
            onChange={ handleSearchChange }
            placeholder='Search'
          />
        </div>
        <div className='peers-list-container-members' id='peersContainer'>
          <InfiniteScroll
            dataLength={ members.length }
            next={ nextUsers }
            hasMore={ hasMore }
            loader={ <Loader position='relative' background='transparent' /> }
            scrollableTarget='peersContainer'
          >
            {members.map(user => (
              <div
                key={ user.uuid }
                className={ `peer-item${ user.uuid === selectedUuid ? ' elite-background-light-blue' : '' }` }
                onClick={ () => setSelectedUuid(user.uuid) }
                role='presentation'
              >
                <div className='peer-item-logo'>
                  <img src={ user.avatar } alt='avatar' />
                </div>
                <div className='peer-item-subject'>
                  <div
                    className='peer-item-subject--bold'
                  >
                    {user.fullName}
                  </div>
                  <div className='peer-item-subject__info'>
                    <span className='peer-item-subject__info--bold'>
                      {user.jobPosition}
                    </span>
                    <span>{`, ${ user.email }`}</span>
                  </div>

                </div>
              </div>
            ))}
          </InfiniteScroll>
          {members.length === 0 && (
            <div className='no-data'>
              <FormattedMessage id='noResults' />
            </div>
          )}
        </div>
      </ModalBody>
      <div className='modal__footer-buttons'>
        <div>
          {signLoading && <Loader />}
          <Button
            buttonText='send'
            onClickFunc={ send }
            disabled={ !selectedUuid || !uploadedFile }
          />
        </div>
        <Button
          emptyButtonType='empty-grey'
          buttonText='cancel'
          onClickFunc={ hideModal }
        />
      </div>
    </>
  );
};

SignDocumentModal.propTypes = {
  company: PropTypes.object.isRequired,
};

export default SignDocumentModal;
