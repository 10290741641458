export const GET_BANNERS_START = 'GET_BANNERS_START';
export const GET_BANNERS_ERROR = 'GET_BANNERS_ERROR';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';

export function getBannersAction(bannerType) {
  return {
    type: GET_BANNERS_START,
    bannerType,
  };
}
