import { Folder } from '@app/models/DataRoom';
import React from 'react';

type Props = {
  parents: Folder[],
  onFolderClick: (folder?: Folder) => void,
}

const MoveBreadcrumb = ({ parents, onFolderClick }: Props) => {
  const folderAbove = parents.length > 2 ? parents[parents.length - 2] : null;
  const currentFolder = parents.length > 1 ? parents[parents.length - 1] : null;

  return (
    <div className='elite-profile-content-text-title'>
      <div
        className='documents-area__breadcrumb'
        role='presentation'
      >
        <button
          className='link-button'
          onClick={ () => onFolderClick() }
        >
          <span className='icomoon-home font-size-title eop-text-color-blue' />
        </button>
        {parents.length > 3 && (
          <>
            <p>{'>'}</p>
            <p>...</p>
          </>
        )}
        {folderAbove && (
          <>
            <p>{'> '}</p>
            <button
              className='link-button eop-text-color-blue'
              onClick={ () => onFolderClick(folderAbove) }
            >
              <p>{folderAbove.name}</p>
            </button>
          </>
        )}
        {currentFolder && (
          <>
            <p>{'> '}</p>
            <p>{currentFolder.name}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default MoveBreadcrumb;
