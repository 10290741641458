import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import styles from './styles';

const useStepIconStyles = makeStyles(styles);

const StepIcon = ({ active, completed }) => {
  const classes = useStepIconStyles();

  return (
    <div
      className={ `${ classes.root } ${ active ? classes.active : '' }` }
    >
      <div className={ clsx(classes.circle, {
        [classes.completed]: completed,
        [classes.circleActive]: active,
      }) }
      />
    </div>
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

export default StepIcon;
