import fetchResource from '@src/js/api/fetch-resource';

export function adminGetLibrary(filters) {
  let url = 'api/admin/library';

  if (filters) {
    url += `?${ new URLSearchParams(filters) }`;
  }

  return fetchResource(url);
}
