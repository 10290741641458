export const GET_BULK_MESSAGE_OPTIONS_START = 'GET_BULK_MESSAGE_OPTIONS_START';
export const GET_BULK_MESSAGE_OPTIONS_ERROR = 'GET_BULK_MESSAGE_OPTIONS_ERROR';
export const GET_BULK_MESSAGE_OPTIONS_SUCCESS = 'GET_BULK_MESSAGE_OPTIONS_SUCCESS';
export const GET_BULK_MESSAGE_USER_IDS_START = 'GET_BULK_MESSAGE_USER_IDS_START';
export const GET_BULK_MESSAGE_USER_IDS_ERROR = 'GET_BULK_MESSAGE_USER_IDS_ERROR';
export const GET_BULK_MESSAGE_USER_IDS_SUCCESS = 'GET_BULK_MESSAGE_USER_IDS_SUCCESS';
export const UPLOAD_BULK_FILE_START = 'UPLOAD_BULK_FILE_START';
export const UPLOAD_BULK_FILE_ERROR = 'UPLOAD_BULK_FILE_ERROR';
export const UPLOAD_BULK_FILE_SUCCESS = 'UPLOAD_BULK_FILE_SUCCESS';

export function getBulkMessageUsers(filters) {
  return {
    type: GET_BULK_MESSAGE_OPTIONS_START,
    filters,
  };
}

export function getBulkMessageUserIds(filters) {
  return {
    type: GET_BULK_MESSAGE_USER_IDS_START,
    filters,
  };
}

export function uploadBulkFile(file) {
  return {
    type: UPLOAD_BULK_FILE_START,
    file,
  };
}
