export const clearTags = (str: string): string => str
  .replaceAll('<br>', '')
  .replaceAll('<p>', '')
  .replaceAll('&nbsp;', '')
  .replaceAll('</p>', '')
  .trim();

export const focusChatEditBox = () => {
  const p = document.getElementById('message-area');
  const s = window.getSelection();
  const r = document.createRange();
  if (!p || !s || !r) return;
  p.innerHTML = '\u00a0';
  r.selectNodeContents(p);
  s.removeAllRanges();
  s.addRange(r);
  document.execCommand('delete', false);
};
