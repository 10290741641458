import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';

import {
  ADMIN_CREATE_TEMPLATE_START,
  ADMIN_CREATE_TEMPLATE_ERROR,
  ADMIN_CREATE_TEMPLATE_SUCCESS,
} from '@src/js/actions/onboarding/templates/admin/adminCreateTemplate';
import {
  ADMIN_TEMPLATE_HIDE_MODAL, ADMIN_RESET_TEMPLATE,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateModal';

import * as api from '@src/js/api/onboarding/templates/admin/adminCreateTemplate';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';

function* adminCreateTemplate(options) {
  try {
    const data = yield call(() => api.adminCreateTemplate(options.template));
    yield all([
      put({ type: ADMIN_CREATE_TEMPLATE_SUCCESS, data }),
      put({ type: ADMIN_TEMPLATE_HIDE_MODAL }),
      put({ type: ADMIN_RESET_TEMPLATE }),
      put(showToastrSuccess('notification.success_title', 'notification.onboarding.template.create.success_message')),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_CREATE_TEMPLATE_ERROR, error }),
      put({ type: ADMIN_TEMPLATE_HIDE_MODAL }),
      put({ type: ADMIN_RESET_TEMPLATE }),
      put(showToastrError('notification.error_title', 'notification.onboarding.template.create.error_message')),
    ]);
  }
}

function* adminCreateTemplateWatcher() {
  yield takeLatest(ADMIN_CREATE_TEMPLATE_START, adminCreateTemplate);
}

export default [
  adminCreateTemplateWatcher(),
];
