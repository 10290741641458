import { Control } from 'react-hook-form';
import { GroupBase, MultiValue, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

export type ValueObj = {value: SelectValue, label: string, id?: string, name?: string, parentId?: string};
export type ItemObj<T> = {value: T, label: string};

export type SelectValue = SelectValue[]|string|ValueObj|number|boolean|undefined;

export type SelectValidator = {
  validate: (v: SelectValue) => true|string,
  message?: string,
  translate?: boolean,
  required?: string|false,
}

export type SelectOnChangeValue = SingleValue<SelectValue>|MultiValue<SelectValue>;

export type InputSelectProps = {
  name: string,
  label?: string,
  translate?: boolean,
  disabled?: boolean,
  required?: boolean,
  boolRequired?: boolean,
  placeholder?: string,
  errors: object,
  control: Control<any, any>,
  options?: MultiValue<SelectValue>,
  loadOptions?: LoadOptions<SelectValue, GroupBase<SelectValue>, SelectValue>,
  loadAsyncOptions?: (payload: SelectPayload) => Promise<SelectResponse>,
  noResultsText?: string,
  multi?: boolean,
  onChange?: (v: SingleValue<SelectValue>|MultiValue<SelectValue>) => void,
  defaultValue?: SingleValue<SelectValue>|MultiValue<SelectValue>,
  fieldNoClear?: boolean,
  searchable?: boolean,
  className?: string,
  tooltip?: string,
  creatable?: boolean,
  type?: string,
  validator?: SelectValidator,
  simpleValue?: boolean,
  cacheUniqs?: readonly any[],
  debounceTimeout?: number,
  formatOptions?: (o: any) => any,
}

export type SelectPayload = { page: number, query: string }

export type SelectResponse = {
  results: any[],
  pagination: {
    hasMoreItems: boolean,
    nextPage: number,
  }
}

export function isSelectValueArray(value: SingleValue<SelectValue>
    |MultiValue<SelectValue>): value is MultiValue<SelectValue> {
  return typeof value === 'object' && Array.isArray(value);
}

export function isSelectValueObj(value: SingleValue<SelectValue>|MultiValue<SelectValue>): value is ValueObj {
  return typeof value === 'object' && !Array.isArray(value)
  && !isSelectValueArray(value) && value !== null;
}

export function toSimpleValue(value: SingleValue<SelectValue>|MultiValue<SelectValue>): SelectValue {
  if (isSelectValueArray(value)) {
    return value.map(v => (isSelectValueObj(v) ? v.value : v));
  }
  if (isSelectValueObj(value)) {
    return value.value;
  }

  return value as SelectValue;
}
