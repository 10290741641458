export const UPLOAD_ONBOARDING_FILE_START = 'ONBOARDING_UPLOAD_FILE_START';
export const UPLOAD_ONBOARDING_FILE_ERROR = 'ONBOARDING_UPLOAD_FILE_ERROR';
export const UPLOAD_ONBOARDING_FILE_SUCCESS = 'ONBOARDING_UPLOAD_FILE_SUCCESS';
export const ADMIN_UPLOAD_ONBOARDING_FILE_START = 'ADMIN_UPLOAD_ONBOARDING_FILE_START';
export const ADMIN_UPLOAD_ONBOARDING_FILE_ERROR = 'ADMIN_UPLOAD_ONBOARDING_FILE_ERROR';
export const ADMIN_UPLOAD_ONBOARDING_FILE_SUCCESS = 'ADMIN_UPLOAD_ONBOARDING_FILE_SUCCESS';

export const UPLOAD_ONBOARDING_CLEAR_FILE_STATE = 'UPLOAD_ONBOARDING_CLEAR_FILE_STATE';

export function uploadOnboardingFile(file, entity, fileType, value) {
  return {
    type: UPLOAD_ONBOARDING_FILE_START,
    file,
    entity,
    fileType,
    value,
  };
}

export function adminUploadOnboardingFile(file, entity, fileType, value) {
  return {
    type: ADMIN_UPLOAD_ONBOARDING_FILE_START,
    file,
    entity,
    fileType,
    value,
  };
}

export function clearOnboardingFile() {
  return {
    type: UPLOAD_ONBOARDING_CLEAR_FILE_STATE,
  };
}
