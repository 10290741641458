const scope = 'BROKER';
export const UPDATE_BROKER_START = `${ scope }/UPDATE_START`;
export const UPDATE_BROKER_FAIL = `${ scope }/UPDATE_FAIL`;
export const UPDATE_BROKER_SUCCESS = `${ scope }/UPDATE_SUCCESS`;
export const GET_BROKER_START = `${ scope }/GET_START`;
export const GET_BROKER_FAIL = `${ scope }/GET_FAIL`;
export const GET_BROKER_SUCCESS = `${ scope }/GET_SUCCESS`;
export const GET_BROKER_LIST_START = `${ scope }/GET_LIST_START`;
export const GET_BROKER_LIST_FAIL = `${ scope }/GET_LIST_FAIL`;
export const GET_BROKER_LIST_SUCCESS = `${ scope }/GET_LIST_SUCCESS`;
export const DELETE_BROKER_START = `${ scope }/DELETE_START`;
export const DELETE_BROKER_FAIL = `${ scope }/DELETE_FAIL`;
export const DELETE_BROKER_SUCCESS = `${ scope }/DELETE_SUCCESS`;

export function getBrokerList(params) {
  return {
    type: GET_BROKER_LIST_START,
    params,
  };
}

export function getBroker(brokerId) {
  return {
    type: GET_BROKER_START,
    brokerId,
  };
}

export function updateBroker(id, data, history) {
  return {
    type: UPDATE_BROKER_START,
    data,
    id,
    history,
  };
}

export function deleteBroker(id) {
  return {
    type: DELETE_BROKER_START,
    id,
  };
}
