import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_SINGLE_NEWS_START,
  GET_SINGLE_NEWS_ERROR,
  GET_SINGLE_NEWS_SUCCESS,
  SingleNewsAction,
} from '@src/js/actions/news/singleNews';

import * as api from '@src/js/api/news/singleNews';

function* getSingleNews({ slug }: SingleNewsAction): Generator {
  try {
    const data = yield call(() => api.getSingleNews(slug));
    yield put({ type: GET_SINGLE_NEWS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SINGLE_NEWS_ERROR, error });
  }
}

function* getSingleNewsWatcher() {
  yield takeLatest(GET_SINGLE_NEWS_START, getSingleNews);
}

export default [
  getSingleNewsWatcher(),
];
