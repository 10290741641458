import produce from 'immer';

import {
  GET_DATA_START,
  GET_DATA_FAIL,
  GET_DATA_SUCCESS,
  GET_DIMENSIONS_START,
  GET_DIMENSIONS_FAIL,
  GET_DIMENSIONS_SUCCESS,
  INCREASE_ROTATION,
} from '@app/store/library/actions';

const rotationIdx = Number(localStorage.getItem('widget-library-rotate')) || 0;
localStorage.setItem('widget-library-rotate', rotationIdx + 1);

export const initialState = {
  data: {},
  loading: {},
  error: {},
  pagination: {},
  dimensions: [],
  rotationIdx,
};

function libraryReducer(state = initialState, action) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_DATA_START:
        draft.loading[action.name] = true;
        draft.error[action.name] = null;
        if (action.params.page === 0) {
          draft.data[action.name] = null;
        }
        draft.pagination = {};
        break;
      case GET_DATA_SUCCESS:
        draft.loading[action.name] = false;
        draft.error[action.name] = null;
        draft.data[action.name] = action.page === 0
          ? action.data
          : [...draft.data[action.name], ...action.data];
        draft.pagination.count = action.count;
        draft.pagination.currentPage = action.currentPage;
        if (action.name === 'featured') {
          if (state.rotationIdx > action.data.length - 1) {
            draft.rotationIdx = 0;
            localStorage.setItem('widget-library-rotate', 1);
          }
        }
        break;
      case GET_DATA_FAIL:
        draft.loading[action.name] = false;
        draft.error[action.name] = action.error;
        draft.data[action.name] = null;
        draft.pagination = {};
        break;
      case GET_DIMENSIONS_START:
        draft.dimensions = [];
        break;
      case GET_DIMENSIONS_SUCCESS:
        draft.dimensions = action.data;
        break;
      case GET_DIMENSIONS_FAIL:
        draft.dimensions = [];
        break;
      case INCREASE_ROTATION:
        if (!state.data?.featured?.length) break;
        draft.rotationIdx = state.rotationIdx + 1 > state.data.featured.length - 1 ? 0 : state.rotationIdx + 1;
        localStorage.setItem('widget-library-rotate', draft.rotationIdx + 1);
        break;
      default:
        break;
    }
  }
  );
}

export default libraryReducer;
