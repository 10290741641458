import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectActiveLanguage, selectBanners } from '@app/store/global/selectors/initInfo';
import { getBannersAction } from '@src/js/actions/banners';
import { purifyHtmlBanners } from '@src/js/utils';
import { ReactGaService } from '@app/services/ReactGaService';
import { EventGa } from '@app/models/EventGa';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';

const DashboardBanner = ({
  type, disabledLoad, wrapperClass, widgetClass,
}) => {
  const dispatch = useDispatch();
  const activeLanguage = selectActiveLanguage();
  const banner = selectBanners(type);

  useEffect(() => {
    if (!disabledLoad) {
      dispatch(getBannersAction(type));
    }
  }, [activeLanguage]);

  const sendBannerStats = (action) => {
    ReactGaService.instance.event(new EventGa(
      'Marketing',
      `banner_${ action.toLowerCase() }`,
      `${ action } banner with id ${ banner.tracking }`));
  };

  return (
    <>
      { banner && Object.keys(banner).length > 0 && sendBannerStats('View') }
      { banner && Object.keys(banner).length > 0 && (
      <div
        onClick={ () => sendBannerStats('Click') }
        onKeyDown={ () => sendBannerStats('Click') }
        role='presentation'
        className={ wrapperClass }
      >
        <DashboardWidget className={ widgetClass }>
          <div
            id={ banner.tracking }
            className='blue-a'
            dangerouslySetInnerHTML={ { __html: purifyHtmlBanners(banner.contentHtml) } }
          />
        </DashboardWidget>
      </div>
      ) }
    </>
  );
};

DashboardBanner.propTypes = {
  type: PropTypes.string,
  disabledLoad: PropTypes.bool,
  wrapperClass: PropTypes.string,
  widgetClass: PropTypes.string,
};

export default DashboardBanner;
