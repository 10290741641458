export const TASK_GET_USER_PERMISSIONS_START = 'TASK_GET_USER_PERMISSIONS_START';
export const TASK_GET_USER_PERMISSIONS_ERROR = 'TASK_GET_USER_PERMISSIONS_ERROR';
export const TASK_GET_USER_PERMISSIONS_SUCCESS = 'TASK_GET_USER_PERMISSIONS_SUCCESS';

export function taskGetUserPermissions(slug, offset, search) {
  return {
    type: TASK_GET_USER_PERMISSIONS_START,
    slug,
    offset,
    search,
  };
}
