import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Step from './Step';

const ProgressBar = ({ steps, stepChange }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <div className='header'>
      <span className='header-hamburger' role='presentation' onClick={ () => setMobileMenu(!mobileMenu) }>
        { !mobileMenu && <span className='header-hamburger-icons icon-medium-Hamburger' /> }
        { mobileMenu && <span className='header-hamburger-icons icon-medium-Close' /> }
      </span>
      <Col className={ `progressbar ${ mobileMenu && 'show' }` }>
        <div className='progressbar-container'>
          {steps.map(step => (<Step { ...step } stepChange={ stepChange } key={ step.step } />)) }
        </div>
      </Col>
    </div>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.array.isRequired,
  stepChange: PropTypes.func.isRequired,
};

export default ProgressBar;
