export const ADMIN_CHANGE_NEWS_STATUS_START = 'ADMIN_CHANGE_NEWS_STATUS_START';
export const ADMIN_CHANGE_NEWS_STATUS_ERROR = 'ADMIN_CHANGE_NEWS_STATUS_ERROR';
export const ADMIN_CHANGE_NEWS_STATUS_SUCCESS = 'ADMIN_CHANGE_NEWS_STATUS_SUCCESS';

export function adminChangeNewsStatus(slug, status) {
  return {
    type: ADMIN_CHANGE_NEWS_STATUS_START,
    slug,
    status,
  };
}
