import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getCompliance(slug, isAdmin) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api${ isAdmin ? '/admin' : '' }/compliance/${ slug }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function updateCompliance(slug, data, isAdmin, params) {
  const urlParams = params ? new URLSearchParams(params) : '';
  return fetchResource(
    `${ loadEnvVariable('COMPLIANCE_URL') }/api${ isAdmin ? '/admin' : '' }/compliance/${ slug }?${ urlParams }`,
    {
      method: 'PUT',
      body: data,
      externalUrl: true,
    });
}

export function getComplianceList(filter) {
  const params = new URLSearchParams(filter);
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/compliances?${ params }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getComplianceAdmin(slug) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/compliance/${ slug }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function signCompliance(slug, tableKey) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/compliance/${ slug }/sign`, {
    method: 'POST',
    externalUrl: true,
    body: { Key: tableKey },
  });
}

export function clearSignCompliance(signId) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/compliance/sign/${ signId }`, {
    method: 'DELETE',
    externalUrl: true,
  });
}

export function approveCompliance(slug, state) {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/admin/compliance/${ slug }/approval`, {
    method: 'PUT',
    externalUrl: true,
    body: { state },
  });
}

export function getComplianceStatus() {
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/compliance/info`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function getMembers(isAdmin, slug) {
  const url = isAdmin ? `admin/members/${ slug }` : 'members';
  return fetchResource(`${ loadEnvVariable('COMPLIANCE_URL') }/api/${ url }`, {
    method: 'GET',
    externalUrl: true,
  });
}

export function createCompliance(slug, data, method) {
  return fetchResource(
    `${ loadEnvVariable('COMPLIANCE_URL') }/api/compliance/${ slug }`,
    {
      method,
      body: data,
      externalUrl: true,
    });
}

export function fundingOption(data) {
  return fetchResource(
    `${ loadEnvVariable('COMPLIANCE_URL') }/api/compliance/funding-option`,
    {
      method: 'POST',
      body: data,
      externalUrl: true,
    });
}
