import { useSelector } from 'react-redux';

const selectLoading = () => useSelector(state => state.businessNeedTableData?.loading);
const selectError = () => useSelector(state => state.businessNeedTableData?.error);
const selectData = () => useSelector(state => state.businessNeedTableData?.data);
const selectBusinessNeedsData = () => useSelector(state => state.businessNeedTableData?.businessNeeds?.data);

export {
  selectLoading,
  selectError,
  selectData,
  selectBusinessNeedsData,
};
