import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ControlPanel = ({
  numPages,
  pageNumber,
  setPageNumber,
  scale,
  setScale,
}) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;
  const isMinZoom = scale <= 0.5;
  const isMaxZoom = scale >= 2.0;

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };

  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };

  const goToPrevPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };

  const goToPage = (event) => {
    const value = Number(event.target.value);
    if (value < 1 || value > numPages) return;
    setPageNumber(value);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  return (
    <div className='control-panel__wrapper'>
      <div className='control-panel'>
        <div
          className='control-panel__button'
          onClick={ goToFirstPage }
          onKeyPress={ goToFirstPage }
          role='presentation'
        >
          <span className='icomoon-first' />
        </div>
        <div
          className='control-panel__button'
          onClick={ goToPrevPage }
          onKeyPress={ goToPrevPage }
          role='presentation'
        >
          <span className='icomoon-backward2' />
        </div>
        <div className='control-panel__page-selection'>
          <div><FormattedMessage id='pdfViewer.page' /></div>
          <input
            className='control-panel__input'
            name='pdfviewer-goToPage'
            type='number'
            min={ 1 }
            max={ numPages || 1 }
            value={ pageNumber }
            onChange={ goToPage }
          />
          <div>
            <FormattedMessage id='pdfViewer.of' />
            { numPages }
          </div>
        </div>
        <div
          className='control-panel__button'
          name='pdfviewer-goToNextPage'
          onClick={ goToNextPage }
          onKeyPress={ goToNextPage }
          role='presentation'
        >
          <span className='icomoon-forward3' />
        </div>
        <div
          className='control-panel__button'
          name='pdfviewer-goToLastPage'
          onClick={ goToLastPage }
          onKeyPress={ goToLastPage }
          role='presentation'
        >
          <span className='icomoon-last' />
        </div>

        <div
          className='control-panel__button'
          name='pdfviewer-zoomIn'
          onClick={ zoomIn }
          onKeyPress={ zoomIn }
          role='presentation'
        >
          <span className='icomoon-zoom-in' />
        </div>
        <div
          className='control-panel__button'
          name='pdfviewer-zoomOut'
          onClick={ zoomOut }
          onKeyPress={ zoomOut }
          role='presentation'
        >
          <span className='icomoon-zoom-out' />
        </div>
      </div>
    </div>
  );
};

ControlPanel.propTypes = {
  numPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
  setScale: PropTypes.func.isRequired,
};

export default ControlPanel;
