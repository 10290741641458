import {
  GET_NEWS_LIST_START,
  GET_NEWS_LIST_ERROR,
  GET_NEWS_LIST_SUCCESS,
} from '@src/js/actions/news/newsList';
import { NewsIndex } from '@app/models/SingleNews';
import { Action } from 'redux';
import { Pagination } from '@app/models/Pagination';
import produce from 'immer';

export type NewsListState = {
  loading: boolean,
  error: unknown,
  news: NewsIndex[],
  pagination: null|Pagination
};

interface NewsListPayload extends Action {
  error: unknown
  data: null|{
    data: { items: NewsIndex[] }
    meta: { pagination: Pagination }
  }
}

const initialState: NewsListState = {
  loading: false,
  error: null,
  news: [],
  pagination: null,
};

export default (state = initialState, action: NewsListPayload) => produce(state, (draftState) => {
  const draft = draftState;
  switch (action.type) {
    case GET_NEWS_LIST_START:
      draft.loading = true;
      draft.error = null;
      break;
    case GET_NEWS_LIST_ERROR:
      draft.loading = false;
      draft.error = action.error;
      break;
    case GET_NEWS_LIST_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.news = action.data?.meta.pagination.page === 1
        ? action.data?.data.items || []
        : state.news.concat(action.data?.data.items || []);
      draft.pagination = action.data?.meta.pagination ?? null;
      break;
    default:
      break;
  }
});
