import React from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@src/js/components/global';
import PropTypes from 'prop-types';
import { purifyHtml } from '@src/js/utils/index';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';

const OnboardingSubmitModal = ({
  onSubmitAction, messages,
}) => {
  const { hideModal } = useGlobalModalContext();
  const submit = () => {
    hideModal();
    onSubmitAction();
  };

  return (
    <>
      <ModalBody>
        <div
          className='modal-body-description m-y-4 blue-a'
          dangerouslySetInnerHTML={ { __html: purifyHtml(messages.content) } }
        />
      </ModalBody>
      <ModalFooter>
        <Button
          size='lg'
          buttonText={ messages.confirm }
          onClickFunc={ submit }
          shouldTranslateText={ false }
        />
      </ModalFooter>
    </>
  );
};

OnboardingSubmitModal.propTypes = {
  onSubmitAction: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
};

export default OnboardingSubmitModal;
