import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DebounceInput } from 'react-debounce-input';
import CheckboxTree from 'react-checkbox-tree';
import {
  Container,
  FilterContainer,
  FilterContentWrapper,
  FilterLabel,
} from './styled';

class BusinessNeedTreeFilter extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    toggleOpened: PropTypes.bool,
    fieldName: PropTypes.string,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    formMutator: PropTypes.func,
    selectedValue: PropTypes.object,
    intl: PropTypes.object,
    filterGroup: PropTypes.string,
    value: PropTypes.array,
  }

  constructor(props) {
    super(props);
    const {
      options, toggleOpened,
    } = this.props;
    this.state = {
      open: toggleOpened === true,
      filteredItems: options,
      tree: {
        expanded: [0],
        checked: props.value,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { options, selectedValue } = this.props;
    const { filterSearchValue, tree } = this.state;
    if (options && prevProps.options !== options) {
      this.setState({
      }, () => this.searchFilters({ target: { value: filterSearchValue } }));
    }
    if (options && prevProps.selectedValue !== selectedValue) {
      this.setState({
        tree: {
          expanded: tree.expanded,
          checked: selectedValue || [],
        },
      }, () => this.searchFilters({ target: { value: filterSearchValue } }));
    }
  }

  toggleFilters = () => {
    const { open } = this.state;

    this.setState({
      open: !open,
    });
  }

  searchFilters = (e) => {
    const { options } = this.props;
    const { tree } = this.state;
    const searchValue = e.target.value || '';

    const filtered = this.removeRedundant(options, searchValue);
    const expanded = searchValue === '' ? tree.expanded : this.open([], filtered);
    if (expanded.indexOf(0) < 0) {
      expanded.push(0);
    }
    this.setState({
      filterSearchValue: searchValue,
      filteredItems: filtered,
      tree: {
        checked: tree.checked,
        expanded,
      },
    });
  };

  onCheck = (_, node) => {
    const { tree } = this.state;
    const { onChange, fieldName, formMutator } = this.props;
    const children = this.childrenIds(node);
    let checked = [...tree.checked];
    if (node.checked) {
      checked = checked.concat(children);
    } else {
      checked = checked.filter((elem) => !children.includes(elem));
    }
    this.setState({
      tree: {
        checked,
        expanded: tree.expanded,
      },
    });
    onChange(Object.values(checked), formMutator, fieldName);
  }

  childrenIds = (node) => {
    const values = [node.value];
    node.children?.forEach(child => {
      values.push(...this.childrenIds(child));
    });
    return values;
  }

  renderFilters = () => {
    const { prefix, filterGroup } = this.props;
    const { filteredItems, tree } = this.state;
    return (
      <div className={ 'check-box-tree-em' }>
        <CheckboxTree
          name={ filterGroup }
          nodes={ this.handleHierarchy(filteredItems, prefix) }
          checked={ tree.checked }
          expanded={ tree.expanded }
          onCheck={ this.onCheck }
          onExpand={
            expanded => {
              this.setState(
                {
                  tree: {
                    expanded,
                    checked: tree.checked,
                  },
                }
              );
            }
          }
        />
      </div>
    );
  };

  open = (res, items) => {
    for (let i = items.length - 1; i >= 0; i--) {
      res.push(items[i].Item.Id);
      if (items[i].Children) {
        this.open(res, items[i].Children);
      }
    }
    return res;
  }

  callback = (item, value) => {
    const { prefix, intl } = this.props;
    const label = !prefix || prefix.length === 0 ? item.Item.Name
      : intl.formatMessage({ id: prefix + item.Item.Name });
    return label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };

  removeRedundant = (items, value) => {
    const res = [];
    for (let i = items.length - 1; i >= 0; i--) {
      const match = this.callback(items[i], value);
      const app = this.removeRedundant(items[i].Children, value);
      if (match || app.length !== 0) {
        res.push({
          ...items[i],
          Children: match ? items[i].Children : app,
        });
      }
    }
    return res;
  }

  handleHierarchy = (paginator, msgPrefix) => {
    const { intl } = this.props;
    const reducer = (item) => {
      const children = item.Children && item.Children.length > 0 ? item.Children.map(reducer) : null;
      const label = !msgPrefix || msgPrefix.length === 0 ? item.Item.Name
        : intl.formatMessage({ id: msgPrefix + item.Item.Name });
      return { label, value: item.Item.Id, children };
    };
    const items = paginator ? paginator.map(reducer) : [];
    return [{
      value: 0,
      label: 'All',
      children: items,
    }];
  }

  renderFilterContent = () => null;

  render() {
    const { placeholder, label } = this.props;
    const { open, filterSearchValue } = this.state;

    return (
      <Container className='filter'>
        <div className='filters-programme'>
          <FilterLabel>
            <div
              className='filter-title'
              role='button'
              tabIndex='0'
              onClick={ this.toggleFilters }
              onKeyUp={ this.toggleFilters }
            >
              <span className='filter-title-label__uppercase'>
                <FormattedMessage id={ label } />
              </span>
              <span className={ `${ open ? 'icon-ArrowUpSmall' : 'icon-ArrowRightSmall' } icon-up` } />
            </div>
            { this.renderFilterContent() }
          </FilterLabel>
          { open && (
            <FilterContainer>
              <div className='filter-search-wrapper'>
                <span className='icon-SearchSmall' />
                <FormattedMessage id={ placeholder }>
                  { placeholderMessage => (
                    <DebounceInput
                      debounceTimeout={ 500 }
                      placeholder={ placeholderMessage }
                      onChange={ this.searchFilters }
                      value={ filterSearchValue }
                    />
                  ) }
                </FormattedMessage>
              </div>
              <FilterContentWrapper>
                { this.renderFilters() }
              </FilterContentWrapper>
            </FilterContainer>
          ) }
        </div>
      </Container>
    );
  }
}

export default injectIntl(BusinessNeedTreeFilter);
