import React from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@src/js/components/global/buttons/Buttons';

const SafetyModal = (props) => {
  const {
    isOpen,
    message,
    cancelFunc,
    yesFunc,
  } = props;

  return (
    <Modal isOpen={ isOpen } toggle={ cancelFunc }>
      <div className='modal-header'>
        <h2 className='modal-title'>
          { message && <FormattedMessage id={ message } /> }
        </h2>
        <span className='close' onClick={ cancelFunc } role='presentation' />
      </div>
      <ModalFooter>
        <Button
          size='lg'
          buttonText='yes'
          onClickFunc={ yesFunc }
        />
        <Button
          size='lg'
          buttonText='cancel'
          emptyButtonType='empty-grey'
          onClickFunc={ cancelFunc }
        />
      </ModalFooter>
    </Modal>
  );
};

SafetyModal.propTypes = {
  isOpen: PropTypes.bool,
  cancelFunc: PropTypes.func,
  yesFunc: PropTypes.func,
  message: PropTypes.string,
};

SafetyModal.defaultProps = {
  message: 'confirmationModal.default_message',
};

export default SafetyModal;
