import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ClassNames from 'classnames';
import { RequiredIcon, Warning, Label } from './style';

export default class TextArea extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fieldName: PropTypes.string.isRequired,
    fieldMaxLength: PropTypes.number,
    fieldLabel: PropTypes.string,
    fieldRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    helperText: PropTypes.string,
    validate: PropTypes.func,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    translate: PropTypes.bool,
    style: PropTypes.object,
  };

  static defaultProps = {
    readOnly: false,
    translate: true,
    rows: 1,
    style: {},
  };

  render() {
    const {
      input, meta, rows, fieldLabel, fieldMaxLength, placeholder, readOnly, fieldRequired, className, translate, style,
    } = this.props;

    let textareaElement = (
      <textarea
        { ...input }
        maxLength={ fieldMaxLength }
        rows={ rows }
        className='test-textarea'
        readOnly={ readOnly }
        style={ style }
      />
    );

    if (placeholder) {
      textareaElement = (translate) ? (
        <FormattedMessage id={ placeholder }>
          {
            placeholderMessage => (
              <textarea
                { ...input }
                maxLength={ fieldMaxLength }
                rows={ rows }
                className='test-textarea'
                placeholder={ placeholderMessage }
                readOnly={ readOnly }
                style={ style }
              />
            )
          }
        </FormattedMessage>
      ) : (
        <textarea
          { ...input }
          maxLength={ fieldMaxLength }
          rows={ rows }
          className='test-textarea'
          placeholder={ placeholder }
          readOnly={ readOnly }
          style={ style }
        />
      );
    }

    return (
      <div className={ ClassNames('eop-text-textarea', className) }>
        <Label className='eop-text-textarea__label'>
          { fieldRequired && <RequiredIcon /> }
          { fieldLabel && (translate ? <FormattedMessage id={ fieldLabel } /> : fieldLabel) }
        </Label>
        <div className='eop-text-textarea__input'>
          { textareaElement }
        </div>
        { meta && (
          <Warning>
            {
              (meta.error || (meta.submitError && meta.dirtySinceLastSubmit === false))
              && meta.touched && (<FormattedMessage id={ meta.error || meta.submitError } />)
            }
          </Warning>
        )
        }
      </div>
    );
  }
}
