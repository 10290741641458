/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import {
  ChatStyles, Peer, sendGAEvent,
} from '@app/models/Chat';
import useBreakpoint from '@app/services/useBreakpoint';
import {
  addMembersToChat, setThreadSubject,
  unsubscribeChat,
} from '@app/store/chat/chatApi';
import {
  clearChat, closeChatPopup, selectCurrentThread,
} from '@app/store/chat/chatSlice';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { Button } from '@src/js/components/global';
import { breakpoints } from '@src/js/constants/breakpoints';
import { Company } from '@src/js/constants/entities';
import { usePrevious } from '@src/js/helpers/hooks';
import MessageCompany from '@src/js/layouts/global/panels/messaging/components/MessageCompany';
import MessageDescription from '@src/js/layouts/global/panels/messaging/components/MessageDescription';
import { useAppDispatch } from '@src/js/store';
import React, {
  useEffect,
  useRef, useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const trimString = (data: string) => {
  let result = data;
  if (data.length === 1) {
    result = data.trim();
  }
  return result;
};

type Props = {
  styles?: ChatStyles,
  setSearch?: (search: string) => void
}

const ChatHeader = ({
  styles, setSearch,
}: Props) => {
  const { showModal, hideModal } = useGlobalModalContext();
  const intl = useIntl();
  const [editHeader, setEditHeader] = useState(false);
  const [isEllipsed, setIsEllipsed] = useState(false);
  const [openParticipants, setOpenParticipants] = useState(false);
  const { breakpoint, width } = useBreakpoint();
  const thread = useSelector(selectCurrentThread);
  const prevThread = usePrevious(thread);
  const headerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const loggedUser = selectUserInfo();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const headerStyle = () => {
    if (breakpoint === 'mobile' || width <= breakpoints.SMALL) {
      return 'messages-panel__header--short';
    }
    return styles?.masterClass ? 'messages-panel__header' : 'messages-panel__header--description';
  };

  const shouldSeeAddMemberButton = () => {
    if (loggedUser && thread) {
      return !thread.closed && !thread.left && thread.enabled && (Company.TYPE_GROWTH === loggedUser.companyType
        || Company.TYPE_GROWTH_STARTUP === loggedUser.companyType
        || loggedUser.partnerType);
    }
    return false;
  };

  const handleThreadSubjectSave = () => {
    const subject = trimString(inputRef?.current?.value || '');
    dispatch(setThreadSubject({ threadId: thread?.id, subject }));
    setEditHeader(false);
  };

  const removeParticipantAction = () => {
    if (!thread) return;
    const recipients = thread.participants.map(p => p.uuid) || [];
    dispatch(unsubscribeChat({ threadId: thread.id }));
    sendGAEvent(recipients, loggedUser.id, location.pathname);
  };

  const addMembers = (users: Peer[]) => {
    if (thread?.id) {
      dispatch(addMembersToChat({ threadId: thread.id, users }));
      if (setSearch) setSearch('');
    }
    hideModal();
  };

  useEffect(() => {
    if (headerRef?.current) {
      setIsEllipsed(headerRef.current.offsetWidth < headerRef.current.scrollWidth);
    }
  }, []);

  useEffect(() => {
    if (prevThread?.participants !== thread?.participants && headerRef?.current) {
      setIsEllipsed(headerRef.current.offsetWidth < headerRef.current.scrollWidth);
    }
  }, [thread, prevThread]);

  const close = () => {
    if (breakpoint === 'mobile') {
      document.body.classList.remove('noScroll');
    }
    dispatch(closeChatPopup());
    dispatch(clearChat());
  };

  if (!thread) return null;

  return (
    <div className={ styles?.header ? styles.header : 'messages-panel__header' }>
      { thread && !editHeader ? (
        <div className={ styles?.header ? `${ styles.header }--bold` : 'bold' }>
          { thread.subject || <FormattedMessage id='messages.default' /> }
          { thread.groupChat
        && !editHeader
        && (breakpoint !== 'mobile' || width >= breakpoints.SMALL)
        && (
          <span
            role='presentation'
            className='icon icon-small-Edit'
            onClick={ () => setEditHeader(true) }
          />
        )
      }
        </div>
      ) : (
        <div className='col-sm-6'>
          <div className='eop-text-input__input'>
            <input
              name='threadSubject'
              className='eop-text-input__input-item'
              type='text'
              placeholder={ thread.subject || intl.formatMessage({ id: 'messages.default' }) }
              ref={ inputRef }
            />
          </div>
          <span role='presentation' className='icon icon-small-Check' onClick={ handleThreadSubjectSave } />
        </div>
      ) }
      <div className={ headerStyle() }>
        { thread && thread.participants && (
        <div className='d-block w-100'>
          <span
            ref={ headerRef }
            className={ `d-block w-100 messages-panel__header--${
              thread.groupChat ? 'regular' : 'bold' }${
              openParticipants ? '' : ' ellipsis'
            }` }
          >
            <MessageDescription participants={ thread.participants } />
            <MessageCompany participants={ thread.participants } />
          </span>
          { isEllipsed && styles?.masterClass && (
          <span
            className='eop-text-color-blue font-weight-medium cursor-pointer no-select'
            onClick={ () => setOpenParticipants(!openParticipants) }
            role='presentation'
          >
            {' '}
            <FormattedMessage id={ `messages.${ openParticipants ? 'showLess' : 'showMore' }` } />
          </span>
          )}
        </div>
        ) }
      </div>
      <div>
        { styles?.masterClass && !thread.left && (
        <Button
          buttonType='button'
          buttonText='messages.leave'
          additionalClass='eop-btn-red m-t-1'
          onClickFunc={
          () => showModal({
            type: ModalTypes.Confirmation,
            title: 'messages.delete',
            config: { size: 'md' },
            onTop: true,
            data: { action: removeParticipantAction },
          })
        }
        />
        )}
        <span
          className='close'
          onClick={ close }
          role='presentation'
        />
        { styles?.masterClass && thread.id && shouldSeeAddMemberButton() && (
        <Button
          buttonType='button'
          buttonText='messages.addMembers'
          additionalClass='eop-btn-blue m-t-1 m-x-1'
          onClickFunc={ () => showModal({
            type: ModalTypes.ChatMemberModal,
            title: 'messages.addMembers',
            config: { size: 'md' },
            data: { threadId: thread.id, members: [], submit: addMembers },
          }) }
        />
        ) }
      </div>
    </div>
  );
};

export default ChatHeader;
