const handleProgramme = (programme) => (Array.isArray(programme)
  ? programme.map(item => item.value).join()
  : programme);

const handleFilters = (filters) => ({
  ...filters,
  programme: handleProgramme(filters?.programme),
});

export {
  handleProgramme,
  handleFilters,
};
