import React, {
  createContext,
  useCallback,
  useContext, useMemo,
  useState,
} from 'react';

import BaseModal from '@app/components/global/BaseModal';
import { ModalConfig } from '@app/components/global/ModalComponents';

type GlobalModalState = {
 showModal: (modalProps: ModalConfig) => void;
 hideModal: () => void;
 clearModalQueue: () => void;
 getModalQueue: () => void;
};

const initalState: GlobalModalState = {
  showModal: () => undefined,
  hideModal: () => undefined,
  clearModalQueue: () => undefined,
  getModalQueue: () => undefined,
};

export const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

type Props = {
  children: JSX.Element|JSX.Element[]
}
export const GlobalModal = ({ children }: Props) => {
  const [queue, setQueue] = useState<ModalConfig[]>([]);
  const modalProps = queue?.[0];

  const showModal = useCallback((modal: ModalConfig) => {
    if (modal.replace) {
      setQueue([modal]);
    } else if (modal.onTop) {
      setQueue([modal].concat(queue));
    } else {
      setQueue(queue.concat(modal));
    }
  }, [setQueue, queue]);

  const hideModal = useCallback(() => {
    setQueue(queue.slice(1));
  }, [setQueue, queue]);

  const clearModalQueue = useCallback(() => {
    setQueue([]);
  }, [setQueue]);

  const getModalQueue = useCallback(() => queue, [queue]);

  const value = useMemo<GlobalModalState>(() => ({
    showModal, hideModal, clearModalQueue, getModalQueue,
  }),
  [showModal, hideModal, clearModalQueue, getModalQueue]);

  return (
    <GlobalModalContext.Provider value={ value }>
      {children}
      <BaseModal modalProps={ modalProps } hideModal={ hideModal } />
    </GlobalModalContext.Provider>
  );
};
