import fetchResource from '@src/js/api/fetch-resource';

export function getPromotedList() {
  return fetchResource('api/promoted-content/list', {
    method: 'GET',
  });
}
export function getPromotedOptions() {
  return fetchResource('api/promoted-content/form-options', {
    method: 'GET',
  });
}
export function postPromotedContent(form) {
  return fetchResource('api/promoted-content', {
    method: 'POST',
    body: form,
  });
}
export function deletePromotedContent(id) {
  return fetchResource(`api/promoted-content/${ id }`, {
    method: 'DELETE',
  });
}
export function getContentOptions({ contentType, page, search }) {
  const params = new URLSearchParams({ page, search });
  return fetchResource(`api/promoted-content/${ contentType }?${ params }`, {
    method: 'GET',
  });
}
export function getPromotedContent() {
  return fetchResource('api/promoted-content', {
    method: 'GET',
  });
}
