import styled from 'styled-components';
import { Col, Row as rsRow } from 'reactstrap';
import {
  Breakpoints,
  ThemeColors,
  FontSize,
  FontWeight,
} from '@src/_v2/js/constants';

export const Container = styled.div`
  padding: 2.5rem;
`;

export const CompanyBottomHolder = styled.div`
  padding-top: 2rem;
  text-align: right;

  a {
    color: ${ ThemeColors.blue };
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${ FontSize.caption };
    font-weight: ${ FontWeight.bold };
  }
`;

export const Logo = styled.div`
  position: relative;
  height: 10rem;
  width: 10rem;
  min-width: 10rem;
  border: 0.1rem solid ${ ThemeColors.lightGrey };
  background-color: ${ ThemeColors.white };
  margin-right: 2rem;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    order: 2;
    margin-right: 0;
    border: unset;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    flex: 0 0 auto;
    border: 1rem solid ${ ThemeColors.white };
  }
`;

export const CompanyData = styled.div`
  display: flex;

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const GrowthIcon = styled.img`
  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    display: flex;
    margin: 1rem auto;
    position: static;
    width: 3rem;
  }

  margin-top: 1.5rem;
  width: 3rem;
`;

export const CompanyDataContent = styled.div`
  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    order: 1;
    text-align: center;
  }
`;

export const ProgrammePartnerContent = styled(Col)`
  padding: 0;

  @media only screen and (min-width: ${ Breakpoints.LARGE }px) {
    margin-left: auto;
    padding-left: 6rem;
  }

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    order: 3;
    min-width: 30rem;
  }

  @media only screen and (min-width: ${ Breakpoints.SMALL }px) {
    margin-left: auto;
  }
`;

export const Sector = styled.span`
  color: ${ ThemeColors.mediumGrey };
  font-size: ${ FontSize.subTitle };
  display: block;
  margin-bottom: 0.5rem;
`;

export const CompanyName = styled.div`
  color: ${ ThemeColors.black };
  font-size: ${ FontSize.subHeader };
  font-weight: ${ FontWeight.bold };

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    display: inline-flex;
  }
`;

export const CompanyMembers = styled.div`
  margin-top: 2rem;

  @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
    margin-top: 0;
  }
`;

export const CompanyMemberRow = styled(rsRow)`
  .member-card__container:nth-child(2n+1) {
    padding-right: 0.5rem;
    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  .member-card__container:nth-child(2n) {
    padding-left: 0.5rem;
    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  &.full {
    max-height: unset;
    overflow: unset;
  }

  &.hidden {
    max-height: 35rem;
    overflow: hidden;
    @media only screen and (max-width: ${ Breakpoints.SMALL }px) {
      max-height: 36rem;
    }
  }
`;

export const ShowMoreContainer = styled.div`
  text-align: center;
`;

export const ShowMoreButton = styled.div`
  margin-top: 2rem;
  cursor: pointer;
  color: ${ ThemeColors.blue };
  font-weight: ${ FontWeight.bold };
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
`;

export const ShowMoreButtonInner = styled.div`
  font-size: ${ FontSize.icon };

  .icon-ArrowDownSmall,
  .icon-ArrowUpSmall {
    position: absolute;
    top: 0.6rem;
    margin-left: 0.5rem;
    font-size: ${ FontSize.icon };
    line-height: 0;
  }
`;
