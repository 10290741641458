import React, { useEffect, useState } from 'react';
import {
  FormSection, InputText, InputSelect,
} from '@app/components/global/forms';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  selectOnboardingData, selectOnboardingSave, selectCurrentStep, selectFirstVisit,
} from '@app/store/onboarding/selectors';
import { Language } from '@src/js/constants/entities';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';
import { selectCountries, selectProvinces, selectRegions } from '@app/store/global/selectors/taxonomy';

const PartnerSummary = ({ handleSave, intl }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const countries = selectCountries();
  const provinces = selectProvinces() || [];
  const [provincesOption, setProvincesOption] = useState(provinces);
  const [init, setInit] = useState(false);
  const regions = selectRegions()?.map(region => ({ ...region, label: region.name, value: region.name }));
  const options = [
    { label: intl.formatMessage({ id: 'onboarding.companySummary.splitPayment.yes' }), value: true },
    { label: intl.formatMessage({ id: 'onboarding.companySummary.splitPayment.no' }), value: false },
  ];
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const {
    register, formState: { errors }, getValues, control, trigger, watch, setValue,
  } = useForm({
    defaultValues: onboardingData,
    mode: 'onChange',
  });

  const country = watch('country');
  const region = watch('region');
  const showRegions = country && country === regions?.[0]?.parentId;
  const langIT = onboardingData?.template?.lang === Language.IT;

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      handleSave(getValues());
    }
  }, [onSave]);

  useEffect(() => {
    if (region) {
      setProvincesOption(provinces?.filter(
        province => province.parentId === regions?.find(r => r.name === region)?.id
      ));
    }
  }, [region]);

  useEffect(() => {
    if (!init && regions?.length > 0 && provinces?.length > 0) {
      setProvincesOption(provinces?.filter(
        province => province.parentId === regions?.find(r => r.name === region)?.id
      ));
      setInit(true);
    }
  }, [regions, provinces, init]);

  const isCountryItaly = countries?.find(c => c.id === country)?.name === 'Italy';
  let fields = ['address', 'city', 'postalCode', 'region', 'phone'];
  if (showRegions && langIT && isCountryItaly) {
    fields = ['city', 'address', 'postalCode', 'phone', 'invoiceCode'];
  }

  const changeRegion = (e) => {
    setValue('region', e?.value || null);
    setValue('provinceId', null);
  };

  return (
    <form>
      <FormSection sectionTitle='onboarding.companySummary.companySummary'>
        <EmailSupport />
        { showRegions && langIT && isCountryItaly && (
          <>
            <InputSelect
              name='region'
              control={ control }
              errors={ errors }
              options={ regions }
              label='onboarding.companySummary.region'
              required={ langIT }
              onChange={ changeRegion }
              simpleValue={ false }
            />
            <InputSelect
              name='provinceId'
              control={ control }
              errors={ errors }
              options={ provincesOption }
              label='onboarding.companySummary.province'
              required={ langIT }
              simpleValue
              disabled={ !region }
            />
          </>
        )}
        {
          fields.map((key) => (
            <InputText
              key={ key }
              name={ key }
              register={ register }
              errors={ errors }
              type={ key === 'phone' ? 'tel' : 'text' }
              label={ `onboarding.companySummary.${ key }` }
              required={ onboardingData?.template?.lang !== Language.EN || key !== 'region' }
            />
          ))
        }
      </FormSection>
      <div className='elite-white-box--single-line split-payment'>
        { onboardingData?.template?.lang === Language.IT && (
        <InputSelect
          className='eop-selectbox--single-line'
          name='splitPayment'
          label='onboarding.companySummary.splitPayment'
          errors={ errors }
          control={ control }
          options={ options }
          fieldNoClear
          boolRequired
        />
        )}
      </div>
    </form>
  );
};

PartnerSummary.propTypes = {
  handleSave: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(PartnerSummary);
