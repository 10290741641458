import InputDebounce from '@app/components/global/forms/InputDebounce';
import { checkSlugAvailable } from '@app/store/global/api/checkSlugAvailable';
import EliteLoader from '@src/js/components/global/pageLoader/EliteLoader';
import React, { useState } from 'react';
import { Control, FieldValues, Validate } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Props = {
  initialSlugValue: string,
  fixedPrefix: string,
  formType: string,
  name: string,
  label?: string,
  control: Control<any>,
  errors: object,
  translate?: boolean,
  disabled?: boolean,
  required?: boolean,
  className?: string,
}

const InputSlug = ({
  initialSlugValue, fixedPrefix, formType, name = 'slug', disabled,
  control, errors, label, translate, className, required,
}: Props) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [prevValue, setPrevValue] = useState(initialSlugValue);

  const validate: Validate<string, FieldValues> = async (value) => {
    if (!value) {
      return intl.formatMessage({ id: 'global_form.error.required' });
    }

    if (!value.match(/^[a-z0-9-]+$/)) {
      return intl.formatMessage({ id: 'news_form.invalidSlug' });
    }

    if (value === initialSlugValue) {
      return true;
    }
    setPrevValue(value);
    if (value !== prevValue) {
      setLoading(true);
      try {
        const result = await checkSlugAvailable(value, formType);
        if (result?.data === false) {
          return intl.formatMessage({ id: 'news_form.slugUnavailable' });
        }
      } catch (error: any) {
        return error?.message !== 'result is null' ? error?.message : null;
      } finally {
        setLoading(false);
      }
    }

    return true;
  };

  return (
    <div className='slug-container elite-form__full-row'>
      <div className='d-flex'>
        <InputDebounce
          name={ name }
          label={ label }
          translate={ translate }
          type='text'
          control={ control }
          errors={ errors }
          validate={ validate }
          disabled={ disabled }
          required={ required }
          className={ className }
          fixedPrefix={ fixedPrefix }
        />
        {loading && (
          <div className='slug-availability-loader'>
            <EliteLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputSlug;
