import {
  COMPANY_DOCUMENTS_PERMISSIONS_START,
  COMPANY_DOCUMENTS_PERMISSIONS_ERROR,
  COMPANY_DOCUMENTS_PERMISSIONS_SUCCESS,
} from '@src/js/actions/company/companyDocumentsPermissions';

const initialState = {
  loading: false,
  error: {},
  canUpload: null,
  canDelete: null,
};

const actionsMap = {
  [COMPANY_DOCUMENTS_PERMISSIONS_START]: (state) => ({
    ...state,
    loading: true,
    error: {},
    canUpload: null,
    canDelete: null,
  }),
  [COMPANY_DOCUMENTS_PERMISSIONS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    canUpload: false,
    canDelete: false,
  }),
  [COMPANY_DOCUMENTS_PERMISSIONS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: {},
    canUpload: action.data.canUpload,
    canDelete: action.data.canDelete,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
