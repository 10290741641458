import {
  GET_USER_START,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GET_MY_PROFILE_START,
  GET_MY_PROFILE_ERROR,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_COMPANY_MEMBERS_START,
  GET_MY_COMPANY_MEMBERS_ERROR,
  GET_MY_COMPANY_MEMBERS_SUCCESS,
  EDIT_USER_START,
  EDIT_USER_ERROR,
  EDIT_USER_SUCCESS,
  EDIT_MY_PROFILE_START,
  EDIT_MY_PROFILE_ERROR,
  EDIT_MY_PROFILE_SUCCESS,
  LOGIN_USER_START,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_START,
  LOGOUT_USER_ERROR,
  IMPERSONATE_USER_START,
  IMPERSONATE_USER_ERROR,
  IMPERSONATE_USER_SUCCESS,
  EXIT_IMPERSONATE_SESSION_START,
  EXIT_IMPERSONATE_SESSION_ERROR,
  EXIT_IMPERSONATE_SESSION_SUCCESS,
  RECREATE_USER_TOKEN_START,
  RECREATE_USER_TOKEN_ERROR,
  RECREATE_USER_TOKEN_SUCCESS,

} from '@src/js/actions/user/user';

const initialState = {
  loading: null,
  error: null,
  user: null,
  userCompanyMembers: null,
  impersonateUserLoading: null,
  impersonateUserError: null,
  impersonateUserSuccess: null,
  impersonatedUser: null,
  exitImpersonateSessionLoading: null,
  exitImpersonateSessionError: null,
  exitImpersonateSessionSuccess: null,
  initInfo: null,
  recreateUserToken: null,
  recreateUserTokenLoading: null,
  recreateUserTokenError: null,
};

const actionsMap = {
  [GET_USER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [GET_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [GET_MY_PROFILE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_MY_PROFILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [GET_MY_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [GET_MY_COMPANY_MEMBERS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_MY_COMPANY_MEMBERS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [GET_MY_COMPANY_MEMBERS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    userCompanyMembers: action.data,
  }),
  [EDIT_USER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [EDIT_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [EDIT_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [EDIT_MY_PROFILE_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [EDIT_MY_PROFILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [EDIT_MY_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [LOGIN_USER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [LOGIN_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [LOGIN_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [LOGOUT_USER_START]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
  [LOGOUT_USER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [IMPERSONATE_USER_START]: (state) => ({
    ...state,
    impersonateUserLoading: true,
    impersonateUserError: null,
    impersonateUserSuccess: null,
    impersonatedUser: null,
  }),
  [IMPERSONATE_USER_ERROR]: (state, action) => ({
    ...state,
    impersonateUserLoading: false,
    impersonateUserError: action.error,
    impersonateUserSuccess: false,
    impersonatedUser: false,
  }),
  [IMPERSONATE_USER_SUCCESS]: (state) => ({
    ...state,
    impersonateUserLoading: false,
    impersonateUserError: false,
    impersonateUserSuccess: true,
  }),
  [EXIT_IMPERSONATE_SESSION_START]: (state) => ({
    ...state,
    exitImpersonateSessionLoading: true,
    exitImpersonateSessionError: null,
    exitImpersonateSessionSuccess: null,
  }),
  [EXIT_IMPERSONATE_SESSION_ERROR]: (state, action) => ({
    ...state,
    exitImpersonateSessionLoading: false,
    exitImpersonateSessionError: action.error,
    exitImpersonateSessionSuccess: false,
  }),
  [EXIT_IMPERSONATE_SESSION_SUCCESS]: (state) => ({
    ...state,
    exitImpersonateSessionLoading: false,
    exitImpersonateSessionError: false,
    exitImpersonateSessionSuccess: true,
  }),
  [RECREATE_USER_TOKEN_START]: (state) => ({
    ...state,
    recreateUserTokenLoading: true,
    recreateUserTokenError: null,
    recreateUserToken: null,
  }),
  [RECREATE_USER_TOKEN_ERROR]: (state, action) => ({
    ...state,
    recreateUserTokenLoading: false,
    recreateUserTokenError: action.error.response,
    recreateUserToken: null,
  }),
  [RECREATE_USER_TOKEN_SUCCESS]: (state, action) => ({
    ...state,
    recreateUserTokenLoading: false,
    recreateUserTokenError: null,
    recreateUserToken: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
export { initialState };
