import fetchResource from '@src/js/api/fetch-resource';

export function getCustomReminderList(slug, params) {
  const query = new URLSearchParams(params);
  return fetchResource(`api/event/${ slug }/reminder/custom/users?${ query }`, {
    method: 'GET',
  });
}

export function getCustomReminderUsers(slug, params) {
  const q = { ...params };
  delete q.page;
  const query = new URLSearchParams(q);
  return fetchResource(`api/event/${ slug }/reminder/custom/users/ids?${ query }`, {
    method: 'GET',
  });
}

export function sendCustomReminder(slug, data, templateId, scheduling) {
  return fetchResource(`api/event/${ slug }/reminder/${ templateId }/custom`, {
    method: 'POST',
    body: {
      usersId: data,
      ...scheduling,
    },
  });
}
