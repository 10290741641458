import {
  GET_PARTNER_START,
  GET_PARTNER_ERROR,
  GET_PARTNER_SUCCESS,
  CREATE_PARTNER_START,
  CREATE_PARTNER_ERROR,
  CREATE_PARTNER_SUCCESS,
  EDIT_PARTNER_START,
  EDIT_PARTNER_ERROR,
  EDIT_PARTNER_SUCCESS,
  DISABLE_PARTNER_START,
  DISABLE_PARTNER_ERROR,
  DISABLE_PARTNER_SUCCESS,
  GET_PARTNER_PUBLIC_PROFILE_START,
  GET_PARTNER_PUBLIC_PROFILE_ERROR,
  GET_PARTNER_PUBLIC_PROFILE_SUCCESS,
  CLEAR_PARTNER_PUBLIC_PROFILE,
} from '@src/js/actions/partner/partners';

const initialState = {
  loading: false,
  error: null,
  partner: null,
  newPartner: null,
};

const actionsMap = {
  [GET_PARTNER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [GET_PARTNER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [GET_PARTNER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    partnerData: action.data,
  }),
  [CREATE_PARTNER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    newPartner: null,
  }),
  [CREATE_PARTNER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [CREATE_PARTNER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    newPartner: action.data,
  }),
  [EDIT_PARTNER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [EDIT_PARTNER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [EDIT_PARTNER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    user: action.data,
  }),
  [DISABLE_PARTNER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [DISABLE_PARTNER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [DISABLE_PARTNER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    user: action.data,
  }),
  [GET_PARTNER_PUBLIC_PROFILE_START]: (state) => ({
    ...state,
    partner: null,
    loading: true,
    error: null,
  }),
  [GET_PARTNER_PUBLIC_PROFILE_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [GET_PARTNER_PUBLIC_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    partner: action.data,
  }),
  [CLEAR_PARTNER_PUBLIC_PROFILE]: () => initialState,
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
