import {
  GET_BULK_MESSAGE_SENDER_START,
  GET_BULK_MESSAGE_SENDER_ERROR,
  GET_BULK_MESSAGE_SENDER_SUCCESS,
} from '@src/js/actions/bulkMessage/bulkMessageGetSender';

const initialState = {
  loading: false,
  error: null,
  users: {},
};

const actionsMap = {
  [GET_BULK_MESSAGE_SENDER_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    users: {},
  }),
  [GET_BULK_MESSAGE_SENDER_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    users: {},
  }),
  [GET_BULK_MESSAGE_SENDER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    users: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const reducerFunction = actionsMap[action.type];
  return reducerFunction ? reducerFunction(state, action) : state;
}
