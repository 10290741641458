import React from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { purifyHtml, useShallowSelector } from '@src/js/utils';
import ClassNames from 'classnames';
import { createNewLegalRequest } from '@app/store/etinerary/actions';
import { makeSelectUser } from '@app/store/etinerary/selectors/index';
import { showToastrError } from '@app/store/global/actions';
import NewLegal from './NewLegal';
import LegalRepresentative from './LegalRepresentative';

const UpgradeModalBody = ({
  activeStep,
  footer,
  handleNext,
  intl,
  isRepresentative,
}) => {
  const dispatch = useDispatch();
  const user = useShallowSelector(makeSelectUser);

  const toastError = () => {
    dispatch(showToastrError('notification.error_title', 'etinerary.upgradeModal.errorMessage.notRepresentative'));
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className='etinerary-upgrade-modal-description'>
            <FormattedMessage
              id={ 'etinerary.upgradeModal.areYouRepresentative' }
              values={ { company: user?.companyName } }
            />
          </div>
        );
      case 1:
        return (
          <>
            <div className='etinerary-upgrade-modal-description'>
              <FormattedMessage id={ isRepresentative
                ? 'etinerary.upgradeModal.legalRepresentative'
                : 'etinerary.upgradeModal.inviteRepresentative' }
              />
            </div>
            {isRepresentative
              ? (
                <LegalRepresentative
                  callbackFailed={ toastError }
                  footer={ footer }
                  handleNext={ handleNext }
                />
              )
              : (
                <NewLegal
                  footer={ footer }
                  handleFormSubmit={ (values) => {
                    dispatch(createNewLegalRequest({
                      ...values,
                      callbackSuccess: handleNext,
                      callbackFailed: toastError,
                    }));
                  } }
                />
              )}
          </>
        );
      case 2:
        return (
          <div className='etinerary-upgrade-modal-description align-left'>
            <div
              className='blue-a'
              dangerouslySetInnerHTML={ {
                __html: purifyHtml(intl.formatMessage({
                  id: isRepresentative
                    ? 'etinerary.upgradeModal.enablePrivateMarket'
                    : 'etinerary.upgradeModal.underApprovalDescription',
                })),
              } }
            />
          </div>
        );
      default:
        return '';
    }
  };
  return (
    <ModalBody
      className={ ClassNames('etinerary-upgrade-modal-body', {
        'etinerary__new__legal_body': activeStep === 1 && !isRepresentative,
      }) }
    >
      {renderStep()}
    </ModalBody>
  );
};

UpgradeModalBody.propTypes = {
  activeStep: PropTypes.number.isRequired,
  footer: PropTypes.node,
  handleNext: PropTypes.func.isRequired,
  isRepresentative: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(UpgradeModalBody);
