export const ONBOARDING_REGISTER_START = 'ONBOARDING_REGISTER_START';
export const ONBOARDING_REGISTER_ERROR = 'ONBOARDING_REGISTER_ERROR';
export const ONBOARDING_REGISTER_SUCCESS = 'ONBOARDING_REGISTER_SUCCESS';

export function onboardingRegister(data, templateHash) {
  return {
    type: ONBOARDING_REGISTER_START,
    data,
    templateHash,
  };
}
