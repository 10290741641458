import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Creatable from 'react-select/creatable';
import { get as lodashGet } from 'lodash';
import { ArrowRenderer, LockerRenderer } from '@app/components/global/forms/select/Renderers';
import { RequiredIcon, Warning } from './style';

export default class CreatableSelectBox extends Component {
  static propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    fieldLabel: PropTypes.string,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    additionalClass: PropTypes.string,
    fieldRequired: PropTypes.bool,
    fieldDisabled: PropTypes.bool,
    fieldNoClear: PropTypes.bool,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
    onCreateOption: PropTypes.func,
    selectedValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number]),
    meta: PropTypes.object,
    searchable: PropTypes.bool,
    translate: PropTypes.bool,
  };

  static defaultProps = {
    searchable: true,
    translate: true,
  };

  getValidationError = (meta) => {
    let minValue = '';
    let maxValue = '';
    let maxLength = '';
    let minLength = '';
    let validationErrorId = '';

    if (meta) {
      const validationError = (meta.error || meta.submitError);
      if (validationError) {
        validationErrorId = (validationError.id || validationError);
        maxLength = lodashGet(validationError, 'values.maxLength');
        minLength = lodashGet(validationError, 'values.minLength');
        maxValue = lodashGet(validationError, 'values.maxValue');
        minValue = lodashGet(validationError, 'values.minValue');
      }
    }

    return {
      minValue,
      maxValue,
      maxLength,
      minLength,
      validationErrorId,
    };
  };

  renderFieldLabel = () => {
    const { translate, fieldLabel } = this.props;

    return translate
      ? <FormattedMessage id={ fieldLabel } />
      : fieldLabel;
  };

  renderPlaceHolder = (selectboxProps) => {
    const { translate, placeholder } = this.props;

    const translated = (
      <FormattedMessage id={ placeholder }>
        {
                  placeholderMessage => (
                    <Creatable
                      { ...selectboxProps }
                      placeholder={ placeholderMessage }
                    />
                  )
                }
      </FormattedMessage>
    );
    const select = (
      <Creatable
        { ...selectboxProps }
        placeholder={ placeholder }
      />
    );
    return translate ? translated : select;
  }

  render() {
    const {
      input,
      options,
      fieldLabel,
      fieldName,
      multi,
      onChange,
      onCreateOption,
      selectedValues,
      fieldRequired,
      fieldDisabled,
      fieldNoClear,
      additionalClass,
      placeholder,
      meta,
      searchable,
    } = this.props;
    const {
      minValue, maxValue, maxLength, minLength, validationErrorId,
    } = this.getValidationError(meta);

    const selectboxProps = {
      ...input,
      components: {
        DropdownIndicator: ArrowRenderer,
      },
      options,
      placeholder,
      multi,
      name: fieldName,
    };

    if (onChange) {
      selectboxProps.onChange = onChange;
    }

    if (onCreateOption) {
      selectboxProps.onCreateOption = onCreateOption;
    }

    if (selectedValues) {
      selectboxProps.value = selectedValues;
    }

    if (fieldDisabled) {
      selectboxProps.className = 'eop-input-disabled';
      selectboxProps.isDisabled = true;
      selectboxProps.components.DropdownIndicator = LockerRenderer;
    }

    if (fieldNoClear) {
      selectboxProps.components.ClearIndicator = () => '';
    }

    selectboxProps.isSearchable = searchable;

    return (
      <div className={ ClassNames('eop-selectbox', additionalClass) }>
        <label className='eop-selectbox__label'>
          { fieldLabel && fieldRequired && <RequiredIcon /> }
          { fieldLabel && this.renderFieldLabel() }
          { placeholder
            ? this.renderPlaceHolder(selectboxProps)
            : <Creatable { ...selectboxProps } />
          }
          { meta && (
            <Warning>
              {
                (meta.error || (meta.submitError && meta.dirtySinceLastSubmit === false))
                && meta.touched && (
                  <FormattedMessage
                    id={ validationErrorId }
                    values={ {
                      maxLength,
                      minLength,
                      minValue,
                      maxValue,
                    } }
                  />
                ) }
            </Warning>
          ) }
        </label>
      </div>
    );
  }
}
