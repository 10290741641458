import {
  GET_BUSINESSNEEDS_START,
  GET_BUSINESSNEEDS_ERROR,
  GET_BUSINESSNEEDS_SUCCESS,
  DELETE_BUSINESSNEED_START,
  DELETE_BUSINESSNEED_ERROR,
  DELETE_BUSINESSNEED_SUCCESS,
  SET_FILTERS_SUCCESS,
  CLEAR_FILTERS,
} from '@src/js/marketplace/actions/businessNeeds';
import { EDIT_BUSINESS_NEED_SUCCESS } from '@src/js/marketplace/actions/modal/businessNeedModal';

const initialState = {
  loading: false,
  error: null,
  businessNeeds: null,
  filters: {},
};

const actionsMap = {
  [GET_BUSINESSNEEDS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    businessNeeds: [],
  }),
  [GET_BUSINESSNEEDS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    businessNeeds: [],
  }),
  [GET_BUSINESSNEEDS_SUCCESS]: (state, action) => {
    const newBusinessNeeds = action.data.map((businessNeed, idx) => {
      const newBusinessNeed = { ...businessNeed };
      if (!businessNeed.Title) {
        newBusinessNeed.Title = `Business Need ${ idx + 1 }`;
      }
      return newBusinessNeed;
    });
    return {
      ...state,
      loading: false,
      error: null,
      businessNeeds: newBusinessNeeds,
    };
  },
  [DELETE_BUSINESSNEED_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [DELETE_BUSINESSNEED_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [DELETE_BUSINESSNEED_SUCCESS]: (state, action) => {
    const newBusinessNeeds = [];
    if (state.businessNeeds) {
      state.businessNeeds.forEach(businessNeed => {
        if (businessNeed?.Id !== action?.data?.Id) {
          newBusinessNeeds.push(businessNeed);
        }
      });
    }
    return {
      ...state,
      loading: false,
      error: null,
      businessNeeds: newBusinessNeeds,
    };
  },
  [SET_FILTERS_SUCCESS]: (state, action) => ({
    ...state,
    filters: action.filters,
  }),
  [CLEAR_FILTERS]: (state) => ({
    ...state,
    filters: initialState.filters,
  }),
  [EDIT_BUSINESS_NEED_SUCCESS]: (state) => {
    const newBusinessNeeds = [...state.businessNeeds];
    return {
      ...state,
      loading: false,
      error: null,
      businessNeeds: newBusinessNeeds,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
