import styled from 'styled-components';
import { ThemeColors, FontWeight, FontSize } from '@src/_v2/js/constants';

export const Container = styled.div`
  margin-top: 2rem;
  background-color: ${ ThemeColors.white };
  box-shadow: 0 0.2rem 0.5rem ${ ThemeColors.lightGrey };

  div .thread-item {
    border-top: 0.1rem solid ${ ThemeColors.softGrey };

    &__subject {
      font-weight: ${ FontWeight.bold };
      font-size: ${ FontSize.caption };
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  h1 {
    margin: 0;
    padding: 0;
  }

  .compass-image {
    height: 28rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
