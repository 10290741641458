import { takeLatest, call, put } from 'redux-saga/effects';

import {
  COMPANY_DOCUMENTS_PERMISSIONS_START,
  COMPANY_DOCUMENTS_PERMISSIONS_ERROR,
  COMPANY_DOCUMENTS_PERMISSIONS_SUCCESS,
} from '@src/js/actions/company/companyDocumentsPermissions';

import * as api from '@src/js/api/company/companyDocumentsPermissions';

function* getCompanyDocumentsPermissions(options) {
  try {
    const data = yield call(() => api.companyDocumentsPermissions(options.slug));
    yield put({ type: COMPANY_DOCUMENTS_PERMISSIONS_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: COMPANY_DOCUMENTS_PERMISSIONS_ERROR, error });
  }
}

function* getCompanyDocumentsPermissionsWatcher() {
  yield takeLatest(COMPANY_DOCUMENTS_PERMISSIONS_START, getCompanyDocumentsPermissions);
}

export default [
  getCompanyDocumentsPermissionsWatcher(),
];
