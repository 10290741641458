const scope = 'PROGRAMME';

export const GET_LOUNGES_LIST_START = `${ scope }/GET_LOUNGES_LIST_START`;
export const GET_LOUNGES_LIST_SUCCESS = `${ scope }/GET_LOUNGES_LIST_SUCCESS`;
export const GET_LOUNGES_LIST_FAIL = `${ scope }/GET_LOUNGES_LIST_FAIL`;

export const GET_PROGRAMMES_LOUNGES_START = `${ scope }/GET_PROGRAMMES_LOUNGES_START`;
export const GET_PROGRAMMES_LOUNGES_SUCCESS = `${ scope }/GET_PROGRAMMES_LOUNGES_SUCCESS`;
export const GET_PROGRAMMES_LOUNGES_FAIL = `${ scope }/GET_PROGRAMMES_LOUNGES_FAIL`;

export const CREATE_LOUNGE_START = `${ scope }/CREATE_LOUNGE_START`;
export const CREATE_LOUNGE_SUCCESS = `${ scope }/CREATE_LOUNGE_SUCCESS`;
export const CREATE_LOUNGE_FAIL = `${ scope }/CREATE_LOUNGE_FAIL`;

export const UPDATE_LOUNGE_START = `${ scope }/UPDATE_LOUNGE_START`;
export const UPDATE_LOUNGE_SUCCESS = `${ scope }/UPDATE_LOUNGE_SUCCESS`;
export const UPDATE_LOUNGE_FAIL = `${ scope }/UPDATE_LOUNGE_FAIL`;

export const DELETE_LOUNGE_START = `${ scope }/DELETE_LOUNGE_START`;
export const DELETE_LOUNGE_SUCCESS = `${ scope }/DELETE_LOUNGE_SUCCESS`;
export const DELETE_LOUNGE_FAIL = `${ scope }/DELETE_LOUNGE_FAIL`;

export function getLoungesList() {
  return {
    type: GET_LOUNGES_LIST_START,
  };
}

export function getProgrammesLounges(id) {
  return {
    type: GET_PROGRAMMES_LOUNGES_START,
    id,
  };
}

export function createLounge(data) {
  return {
    type: CREATE_LOUNGE_START,
    data,
  };
}

export function updateLounge(data) {
  return {
    type: UPDATE_LOUNGE_START,
    data,
  };
}

export function deleteLounge(id) {
  return {
    type: DELETE_LOUNGE_START,
    id,
  };
}
