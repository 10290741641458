import {
  GET_FEATURED_LIBRARY_START,
  GET_FEATURED_LIBRARY_ERROR,
  GET_FEATURED_LIBRARY_SUCCESS,
} from '@src/js/actions/library/featuredLibrary';

const initialState = {
  featuredLoading: false,
  featuredError: null,
  featuredLibrary: null,
};

const actionsMap = {
  [GET_FEATURED_LIBRARY_START]: state => ({
    ...state,
    featuredLoading: true,
    featuredError: null,
  }),
  [GET_FEATURED_LIBRARY_ERROR]: (state, action) => ({
    ...state,
    featuredLoading: false,
    featuredError: action.error.response,
  }),
  [GET_FEATURED_LIBRARY_SUCCESS]: (state, action) => ({
    ...state,
    featuredLoading: false,
    featuredError: null,
    featuredLibrary: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
