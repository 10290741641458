export const GET_OPPORTUNITY_LOCATIONS_START = 'GET_OPPORTUNITY_LOCATIONS_START';
export const GET_OPPORTUNITY_LOCATIONS_ERROR = 'GET_OPPORTUNITY_LOCATIONS_ERROR';
export const GET_OPPORTUNITY_LOCATIONS_SUCCESS = 'GET_OPPORTUNITY_LOCATIONS_SUCCESS';

export const GET_OPPORTUNITY_SECTORS_START = 'GET_OPPORTUNITY_SECTORS_START';
export const GET_OPPORTUNITY_SECTORS_ERROR = 'GET_OPPORTUNITY_SECTORS_ERROR';
export const GET_OPPORTUNITY_SECTORS_SUCCESS = 'GET_OPPORTUNITY_SECTORS_SUCCESS';

export const GET_OPPORTUNITY_TYPE_START = 'GET_OPPORTUNITY_TYPE_START';
export const GET_OPPORTUNITY_TYPE_ERROR = 'GET_OPPORTUNITY_TYPE_ERROR';
export const GET_OPPORTUNITY_TYPE_SUCCESS = 'GET_OPPORTUNITY_TYPE_SUCCESS';

export const GET_OPPORTUNITY_EXPERTISE_START = 'GET_OPPORTUNITY_EXPERTISE_START';
export const GET_OPPORTUNITY_EXPERTISE_ERROR = 'GET_OPPORTUNITY_EXPERTISE_ERROR';
export const GET_OPPORTUNITY_EXPERTISE_SUCCESS = 'GET_OPPORTUNITY_EXPERTISE_SUCCESS';

export const GET_OPPORTUNITY_DIMENSION_START = 'GET_OPPORTUNITY_DIMENSION_START';
export const GET_OPPORTUNITY_DIMENSION_ERROR = 'GET_OPPORTUNITY_DIMENSION_ERROR';
export const GET_OPPORTUNITY_DIMENSION_SUCCESS = 'GET_OPPORTUNITY_DIMENSION_SUCCESS';

export const GET_OPPORTUNITY_ADVISOR_TYPE_START = 'GET_OPPORTUNITY_ADVISOR_TYPE_START';
export const GET_OPPORTUNITY_ADVISOR_TYPE_ERROR = 'GET_OPPORTUNITY_ADVISOR_TYPE_ERROR';
export const GET_OPPORTUNITY_ADVISOR_TYPE_SUCCESS = 'GET_OPPORTUNITY_ADVISOR_TYPE_SUCCESS';

export const GET_OPPORTUNITY_COVER_IMAGE_START = 'GET_OPPORTUNITY_COVER_IMAGE_START';
export const GET_OPPORTUNITY_COVER_IMAGE_ERROR = 'GET_OPPORTUNITY_COVER_IMAGE_ERROR';
export const GET_OPPORTUNITY_COVER_IMAGE_SUCCESS = 'GET_OPPORTUNITY_COVER_IMAGE_SUCCESS';

export const GET_OPPORTUNITY_COMPANY_START = 'GET_OPPORTUNITY_COMPANY_START';
export const GET_OPPORTUNITY_COMPANY_ERROR = 'GET_OPPORTUNITY_COMPANY_ERROR';
export const GET_OPPORTUNITY_COMPANY_SUCCESS = 'GET_OPPORTUNITY_COMPANY_SUCCESS';

export const GET_OPPORTUNITY_DATA_START = 'GET_OPPORTUNITY_DATA_START';
export const GET_OPPORTUNITY_DATA_ERROR = 'GET_OPPORTUNITY_DATA_ERROR';
export const GET_OPPORTUNITY_DATA_SUCCESS = 'GET_OPPORTUNITY_DATA_SUCCESS';

export const SAVE_OPPORTUNITY_START = 'SAVE_OPPORTUNITY_START';
export const SAVE_OPPORTUNITY_ERROR = 'SAVE_OPPORTUNITY_ERROR';
export const SAVE_OPPORTUNITY_SUCCESS = 'SAVE_OPPORTUNITY_SUCCESS';

export const EDIT_OPPORTUNITY_START = 'EDIT_OPPORTUNITY_START';
export const EDIT_OPPORTUNITY_ERROR = 'EDIT_OPPORTUNITY_ERROR';
export const EDIT_OPPORTUNITY_SUCCESS = 'EDIT_OPPORTUNITY_SUCCESS';

export const GET_OPPORTUNITY_PUBLISHERS_CLEAR = 'GET_OPPORTUNITY_PUBLISHERS_CLEAR';
export const GET_OPPORTUNITY_PUBLISHERS_START = 'GET_OPPORTUNITY_PUBLISHER_START';
export const GET_OPPORTUNITY_PUBLISHERS_ERROR = 'GET_OPPORTUNITY_PUBLISHER_ERROR';
export const GET_OPPORTUNITY_PUBLISHERS_SUCCESS = 'GET_OPPORTUNITY_PUBLISHER_SUCCESS';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const SET_OPPORTUNITY_PUBLISHER = 'SET_OPPORTUNITY_PUBLISHER';
export const RESET_OPPORTUNITY_DATA = 'RESET_OPPORTUNITY_DATA';

export function getOpportunityLocations() {
  return {
    type: GET_OPPORTUNITY_LOCATIONS_START,
  };
}

export function getOpportunitySectors() {
  return {
    type: GET_OPPORTUNITY_SECTORS_START,
  };
}

export function getOpportunityType() {
  return {
    type: GET_OPPORTUNITY_TYPE_START,
  };
}

export function getOpportunityExpertise(advisorTypes) {
  return {
    type: GET_OPPORTUNITY_EXPERTISE_START,
    advisorTypes,
  };
}

export function getOpportunityDimension() {
  return {
    type: GET_OPPORTUNITY_DIMENSION_START,
  };
}

export function getOpportunityAdvisorType() {
  return {
    type: GET_OPPORTUNITY_ADVISOR_TYPE_START,
  };
}

export function getOpportunityCoverImage(image, resolve, reject) {
  return {
    type: GET_OPPORTUNITY_COVER_IMAGE_START,
    image,
    resolve,
    reject,
  };
}

export function getOpportunityCompany(filterOptions) {
  return {
    type: GET_OPPORTUNITY_COMPANY_START,
    filterOptions,
  };
}

export function getOpportunityData(opportunityId) {
  return {
    type: GET_OPPORTUNITY_DATA_START,
    opportunityId,
  };
}

export function resetOpportunityData() {
  return {
    type: RESET_OPPORTUNITY_DATA,
  };
}

export function saveOpportunity(opportunityDataForm) {
  return {
    type: SAVE_OPPORTUNITY_START,
    opportunityDataForm,
  };
}

export function editOpportunity(opportunityDataForm) {
  return {
    type: EDIT_OPPORTUNITY_START,
    opportunityDataForm,
  };
}

export function getOpportunityPublishers(filters) {
  return {
    type: GET_OPPORTUNITY_PUBLISHERS_START,
    filters,
  };
}

export function setOpportunityEditFilters(filters) {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setOpportunityPublisher(publisher) {
  return {
    type: SET_OPPORTUNITY_PUBLISHER,
    publisher,
  };
}
