import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  useForm, FormProvider,
} from 'react-hook-form';
import { ModalBody, ModalFooter } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button, Loader } from '@src/js/components/global';
import { InputText, InputSelect, InputCreatableSelect } from '@app/components/global/forms';
import GridContainer from '@app/components/global/list/GridContainer';
import { Event, YES_OR_NO } from '@src/js/constants/entities';
import { selectEventsData } from '@app/store/event/selectors';
import { getEventBasicData, subscribeExternal } from '@src/js/actions/events/events';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { getUnregisteredUsers } from '@src/js/api/events/events';

const TrainingSubscribeUserModal = ({ slug }) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const intl = useIntl();
  const eventBasicData = selectEventsData()?.eventBasicData;
  const attendingCompany = eventBasicData?.attendingCompany;
  const eventLocation = eventBasicData?.location;
  const isDinnerChecked = eventBasicData?.dinner;
  const isSpeakerChecked = eventBasicData?.speaker;
  const eventLoading = selectEventsData()?.eventBasicLoading;
  const [user, setUser] = useState(null);
  const [membersPage, setMembersPage] = useState(1);
  const [cacheResetter, setCacheResetter] = useState(0);
  const [search, setSearch] = useState();
  const [unregisteredUsersList, setUnregisteredUsersList] = useState([]);

  const defaultMember = {
    fullName: '',
    uuid: null,
    firstName: '',
    lastName: '',
    email: '',
    companySlug: '',
    companyName: '',
    jobPosition: '',
    dietaryRequirements: '',
  };

  const locationOptions = [
    { value: Event.LOCATION_DIGITAL, label: intl.formatMessage({ id: 'subscribeModal.digital' }) },
    { value: Event.LOCATION_PHYSICAL, label: intl.formatMessage({ id: 'subscribeModal.physical' }) },
  ];

  const methods = useForm({
    mode: 'all',
  });
  const {
    watch, formState: { errors }, register, control, setValue, handleSubmit,
  } = methods;

  const loadMembersOptions = async (currentSearch) => {
    let page = membersPage;
    if (search !== currentSearch) {
      setMembersPage(1);
      setCacheResetter(cacheResetter + 1);
      page = 1;
    }

    const dataToSend = {
      search: currentSearch,
      slug,
      page,
    };

    return getUnregisteredUsers(dataToSend)
      .then((data) => {
        setSearch(currentSearch);
        setMembersPage(page + 1 || 1);
        setUnregisteredUsersList(page === 1 ? data.results : unregisteredUsersList.concat(data.results));
        return {
          options: data?.results.map(result => (
            { label: `${ result.firstName } ${ result.lastName }`, value: result.email }
          )) || [],
          hasMore: data?.pagination?.page < data?.pagination?.pageCount,
        };
      }).catch(error => error);
  };

  const onSubmit = data => {
    const submitData = { ...data };
    if (submitData.location === Event.LOCATION_DIGITAL) {
      delete submitData.dietaryRequirements;
    }
    dispatch(subscribeExternal(slug, data));
    hideModal();
  };

  useEffect(() => {
    if (eventLocation !== Event.LOCATION_MIXED) {
      setValue('location', eventLocation);
    }
  }, [eventLocation]);

  useEffect(() => {
    dispatch(getEventBasicData(slug));
    return () => hideModal();
  }, []);

  const setCompany = (company) => {
    const isCustom = company?.value === company?.label;
    setValue('companySlug', isCustom ? null : company.value, { shouldValidate: true });
    setValue('companyName', company?.label, { shouldValidate: true });
  };

  const setParticipant = (usr) => {
    setUser(usr);
    if (usr) {
      const selectedUser = unregisteredUsersList.find(member => member.email === usr.value);
      if (selectedUser) {
        setValue('uuid', selectedUser.uuid);
        setValue('firstName', selectedUser.firstName, { shouldValidate: true });
        setValue('lastName', selectedUser.lastName, { shouldValidate: true });
        setValue('email', selectedUser.email, { shouldValidate: true });
        setValue('companyName', selectedUser.company, { shouldValidate: true });
        setValue('jobPosition', selectedUser.jobPosition, { shouldValidate: true });
      } else {
        setValue('uuid', undefined);
      }
    } else {
      setValue('uuid', defaultMember.uuid);
      setValue('firstName', defaultMember.firstName);
      setValue('lastName', defaultMember.lastName);
      setValue('email', defaultMember.email);
      setValue('companyName', defaultMember.company);
      setValue('jobPosition', defaultMember.jobPosition);
    }
  };

  const uuid = watch('uuid');
  const dinnerWatcher = watch('dinner');
  const dinnerOnlyWatcher = watch('dinnerOnly');
  useEffect(() => {
    if (dinnerWatcher === false) {
      setValue('speaker', false, { shouldValidate: true });
      setValue('dinnerOnly', false, { shouldValidate: true });
    }
  }, [dinnerWatcher]);

  return (
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(onSubmit) } className='m-t-3'>
        <ModalBody>
          { eventLoading && <Loader /> }
          <FormattedMessage id={ 'manage_event_restrictions.userSelection' } />
          <GridContainer
            columns={ 2 }
            typeListGrid={ 'search' }
          >
            <div className='eop-selectbox'>
              <AsyncPaginate
                onChange={ (usr) => setParticipant(usr) }
                value={ user?.value }
                placeholder={ <FormattedMessage id='subscribeModal.searchBy' /> }
                debounceTimeout={ 500 }
                loadOptions={ loadMembersOptions }
                cacheUniqs={ [cacheResetter] }
              />
            </div>
          </GridContainer>
          <FormattedMessage id={ 'manage_event_restrictions.addCustomUser' } />
          <GridContainer
            columns={ 2 }
            typeListGrid={ 'search' }
          >
            <InputText
              name='firstName'
              errors={ errors }
              register={ register }
              label='user.firstName'
              readonly={ !!uuid }
              required={ !uuid }
            />
            <InputText
              name='lastName'
              errors={ errors }
              register={ register }
              label='user.lastName'
              readonly={ !!uuid }
              required={ !uuid }
            />
            <InputText
              name='email'
              type='email'
              errors={ errors }
              register={ register }
              label='user.email'
              readonly={ !!uuid }
              required={ !uuid }
            />
            {uuid
              ? (
                <InputText
                  name='companyName'
                  errors={ errors }
                  register={ register }
                  label='user.company'
                  readonly
                />
              )
              : (
                <InputCreatableSelect
                  name='companyName'
                  errors={ errors }
                  control={ control }
                  label='user.company'
                  options={ attendingCompany }
                  required
                  onChange={ (company) => setCompany(company) }
                />
              )}
            <InputText
              name='jobPosition'
              errors={ errors }
              register={ register }
              label='subscribeModal.role'
              defaultValue={ watch('jobPosition') }
              readonly={ !!uuid }
              required={ !uuid }
            />
            <InputSelect
              name='location'
              errors={ errors }
              control={ control }
              label='event_form.location'
              options={ locationOptions }
              required
              fieldNoClear
              disabled={ eventLocation !== Event.LOCATION_MIXED }
            />
            { isDinnerChecked && (
              <>
                <InputSelect
                  name='dinner'
                  label='subscribeModal.dinner'
                  errors={ errors }
                  control={ control }
                  options={ YES_OR_NO }
                  boolRequired
                  required
                  fieldNoClear
                />
                { isSpeakerChecked && (
                  <InputSelect
                    name='speaker'
                    label='subscribeModal.dinner.speaker'
                    errors={ errors }
                    control={ control }
                    options={ YES_OR_NO }
                    disabled={ dinnerWatcher === false }
                    boolRequired
                    required
                    fieldNoClear
                  />
                ) }
                <InputSelect
                  name='dinnerOnly'
                  label='subscribeModal.dinner.only'
                  errors={ errors }
                  control={ control }
                  options={ YES_OR_NO }
                  defaultValue={ false }
                  disabled={ dinnerWatcher === false }
                  boolRequired
                  required
                  fieldNoClear
                />
              </>
            )}
            { (watch('location') === Event.LOCATION_PHYSICAL || dinnerWatcher || dinnerOnlyWatcher)
             && (
             <InputText
               name='dietaryRequirements'
               errors={ errors }
               register={ register }
               label='subscribeModal.dietaryRequirements'
               disabled={ watch('location') === Event.LOCATION_DIGITAL && !dinnerWatcher && !dinnerOnlyWatcher }
             />
             )
            }
          </GridContainer>
        </ModalBody>
        <ModalFooter className='m-t-3'>
          <Button
            size='lg'
            buttonText='dialog.confirm'
            buttonType='submit'
            disabled={ false }
          />
          <Button
            size='lg'
            buttonText='cancel'
            emptyButtonType='empty-grey'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

TrainingSubscribeUserModal.propTypes = {
  slug: PropTypes.string,
};

export default TrainingSubscribeUserModal;
