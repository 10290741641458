import React from 'react';
import { FormattedMessage } from 'react-intl';
import { purifyHtml } from '@src/js/utils';
import { Message } from '@src/js/constants/entities';
import { Thread } from '@app/models/Chat';

type Props = {
  thread: Thread,
  isSelected: boolean,
  getMessages: (thread: Thread) => void,
}

const ThreadItem = ({ thread, isSelected, getMessages }: Props) => {
  const renderImages = (images: string[]) => images.map((image, i) => {
    const x = images.length === 2 ? i + 1 : i;
    return (
      <div className={ images.length === 1 ? 'logo__single' : `logo logo--${ x }` } key={ x }>
        <img key={ x } src={ image } alt='avatar' />
      </div>
    );
  }
  );

  return (
    <div>
      {thread && thread.participantImages
          && (
            <div
              role='presentation'
              className={ isSelected ? 'thread-item selected' : 'thread-item' }
              key={ thread.id }
              onClick={ (e) => {
                e.preventDefault();
                getMessages(thread);
              } }
            >
              <div className='thread-item__overlay' />
              {isSelected ? (
                <span className='blue-line' />
              ) : (
                <span className={ thread.hasUnreadMessages ? 'blue-dot' : 'empty-line' } />
              )
              }
              {thread && thread.participantImages
                && (
                  <div className='thread-item__logo-container'>
                    {renderImages(thread.participantImages)}
                    {thread.participants.length > 4
                      && (
                        <div className='thread-item__logo-container--plus-sign'>
                          +
                          {thread.participants.length - 1}
                        </div>
                      )
                    }
                  </div>
                )
              }
              <div className='thread-item__content-container'>
                <div className='thread-item__subject'>
                  {thread.subject === ''
                    && <FormattedMessage id='messages.default' />
                  }
                  {thread.subject !== '' && thread.subject}
                </div>
                {thread.lastMessage
                  && (
                    <div className='thread-item__message blue-a'>
                      {!thread.lastMessage.messageData?.translation && (
                        <>
                          <span className='thread-item__message--bold'>
                            {thread.lastMessage.user === 'you'
                              ? <FormattedMessage id='you' /> : thread.lastMessage.user }
                          </span>
                          <span role='presentation'>: </span>

                        </>
                      )}

                      {thread.lastMessage.body === Message.USER_LEFT_CHAT
                        && !thread.lastMessage.messageData?.translation
                        && (
                          <span className='thread-item__message'>
                            <FormattedMessage
                              id={ thread.lastMessage.user === 'you'
                                ? 'messages.youLeftChat' : 'messages.userLeftChat' }
                              values={ { 'user': '' } }
                            />
                          </span>
                        )}

                      {thread.lastMessage.body !== Message.USER_LEFT_CHAT
                        && !thread.lastMessage.messageData?.translation
                        && (
                          <span
                            className='thread-item__message blue-a'
                            dangerouslySetInnerHTML={ { __html: purifyHtml(thread.lastMessage.body) } }
                          />
                        )}

                      {thread.lastMessage.messageData?.translation && thread.lastMessage?.body
                        && (
                          <span className='thread-item__message'>
                            <i>
                              <FormattedMessage
                                id={ thread.lastMessage?.body }
                              />
                            </i>
                          </span>
                        )}
                    </div>
                  )
                }
                <div className='thread-item__message'>
                  {thread.participants?.[0]?.company && !thread.lastMessage
                    && (
                      <span className='thread-item__message'>
                        <span className='thread-item__message'>
                          {thread.participants[0].company}
                        </span>
                      </span>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
    </div>
  );
};

export default ThreadItem;
