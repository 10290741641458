import {
  GET_PARAMETERS_PARTNERS_REQUEST,
  GET_PARAMETERS_PARTNERS_SUCCESS,
  GET_PARAMETERS_PARTNERS_FAIL,
} from '@app/store/funding/constants';

export function getParametersPartnersRequest(search, type) {
  return {
    type: GET_PARAMETERS_PARTNERS_REQUEST,
    search,
    parameters: type,
  };
}

export function getParametersPartnersSuccess(data) {
  return {
    type: GET_PARAMETERS_PARTNERS_SUCCESS,
    payload: data,
  };
}

export function getParametersPartnersFail(error) {
  return {
    type: GET_PARAMETERS_PARTNERS_FAIL,
    error,
  };
}
