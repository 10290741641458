import { takeLatest, call, put } from 'redux-saga/effects';

import {
  ADMIN_CHANGE_LIBRARY_STATUS_START,
  ADMIN_CHANGE_LIBRARY_STATUS_ERROR,
  ADMIN_CHANGE_LIBRARY_STATUS_SUCCESS,
} from '@src/js/actions/library/adminLibrarySingle';

import * as api from '@src/js/api/library/adminLibrarySingle';

function* adminChangeLibraryStatus(options) {
  try {
    const data = yield call(() => api.adminChangeLibraryStatus(options.slug, options.status));
    yield put({ type: ADMIN_CHANGE_LIBRARY_STATUS_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: ADMIN_CHANGE_LIBRARY_STATUS_ERROR });
  }
}

function* adminChangeLibraryStatusWatcher() {
  yield takeLatest(ADMIN_CHANGE_LIBRARY_STATUS_START, adminChangeLibraryStatus);
}

export default [
  adminChangeLibraryStatusWatcher(),
];
