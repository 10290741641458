import { Action } from 'redux';

export const ADMIN_GET_TEMPLATE_OPTIONS_START = 'ADMIN_GET_TEMPLATE_OPTIONS_START';
export const ADMIN_GET_TEMPLATE_OPTIONS_ERROR = 'ADMIN_GET_TEMPLATE_OPTIONS_ERROR';
export const ADMIN_GET_TEMPLATE_OPTIONS_SUCCESS = 'ADMIN_GET_TEMPLATE_OPTIONS_SUCCESS';

export const TEMPLATE_CLEAR_OPTIONS = 'TEMPLATE_CLEAR_OPTIONS';

export interface GetTemplateOptionsAction extends Action {
  kind: string
}

export function adminGetTemplateOptionsAction(kind: string): GetTemplateOptionsAction {
  return {
    type: ADMIN_GET_TEMPLATE_OPTIONS_START,
    kind,
  };
}

export function clearTemplateOptionsAction(): Action {
  return {
    type: TEMPLATE_CLEAR_OPTIONS,
  };
}
