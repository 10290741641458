import React, { ChangeEvent } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { useIntl } from 'react-intl';

type Props = {
  name: string,
  label?: string,
  translate?: boolean,
  disabled?: boolean,
  required?: boolean,
  errors: object,
  control: Control<any>,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  defaultValue?: boolean,
  className?: string,
  tooltip?: string,
}

const InputCheckbox = ({
  name, label, translate = true, disabled, control,
  required, errors, onChange, defaultValue = false, className = '',
  tooltip,
}: Props) => {
  const intl = useIntl();
  const fieldLabel = translate && label ? intl.formatMessage({ id: label }) : label;
  const fieldTooltip = translate && tooltip ? intl.formatMessage({ id: tooltip }) : tooltip;
  const requiredError = required ? intl.formatMessage({ id: 'global_form.error.required' }) : false;

  const renderCheckbox = (fieldProps: ControllerRenderProps<any, string>) => {
    const change = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        return onChange(e);
      }
      return fieldProps.onChange(e?.target?.checked);
    };

    return (
      <input
        { ...fieldProps }
        value=''
        type='checkbox'
        onChange={ change }
        disabled={ disabled }
        checked={ !!fieldProps.value }
      />
    );
  };

  return (
    <div>
      <div className={ `eop-checkbox d-flex m-b-0 align-items-center ${ className }` }>
        <Controller
          name={ name }
          control={ control }
          defaultValue={ !!defaultValue }
          rules={ { required: requiredError } }
          render={ ({ field }) => renderCheckbox(field) }
        />
        <label className='eop-text-input__label font-size-icon'>
          { (required) && <span className='required-icon' /> }
          { label && fieldLabel }
          { disabled && (<span className='eop-text-input-input-icon icon-LockerSmall m-l-1' />) }
        </label>
        { tooltip && (
          <>
            <span className='icon-medium-Info font-size-icon' data-tip data-for={ `${ name }-tooltip` } />
            <ReactTooltip
              id={ `${ name }-tooltip` }
              place='top'
              effect='solid'
              multiline={ true }
              className='w-25'
            >
              { fieldTooltip }
            </ReactTooltip>
          </>
        )}
      </div>
      <ErrorMessage
        errors={ errors }
        name={ name }
        render={ ({ message }) => <span className='error-message'>{message}</span> }
      />
    </div>
  );
};

export default InputCheckbox;
