export const TOKEN_VALIDATION_START = 'TOKEN_VALIDATION_START';
export const TOKEN_VALIDATION_ERROR = 'TOKEN_VALIDATION_ERROR';
export const TOKEN_VALIDATION_SUCCESS = 'TOKEN_VALIDATION_SUCCESS';

export function validateToken(token, email, urlValidateToken) {
  return {
    type: TOKEN_VALIDATION_START,
    token,
    email,
    urlValidateToken,
  };
}
