export const GET_MOST_VIEWED_OPPORTUNITIES_START = 'GET_MOST_VIEWED_OPPORTUNITIES_START';
export const GET_MOST_VIEWED_OPPORTUNITIES_ERROR = 'GET_MOST_VIEWED_OPPORTUNITIES_ERROR';
export const GET_MOST_VIEWED_OPPORTUNITIES_SUCCESS = 'GET_MOST_VIEWED_OPPORTUNITIES_SUCCESS';

export function getMostViewedOpportunities(filters) {
  return {
    type: GET_MOST_VIEWED_OPPORTUNITIES_START,
    filters,
  };
}
