import styled from 'styled-components';
import { Container as rsContainer, Col as rsCol } from 'reactstrap';
import { FontSize, ThemeColors } from '@src/_v2/js/constants';

export const Container = styled(rsContainer)`
  background-color: ${ ThemeColors.white };
  padding: 1.5rem;
`;

export const ContainerSuccessBox = styled(rsContainer)`
  background-color: ${ ThemeColors.white };
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const SuccessBox = styled(Container)`
  width: 100%;
  height: auto;
`;

export const SuccessBoxContent = styled(rsCol)`
  font-size: ${ FontSize.caption };
  letter-spacing: 0.04rem;
  text-align: center;
`;

export const ButtonsContainer = styled(rsCol)`
  display: flex;
  align-content: center;
  justify-content: center;
`;
