export const GET_RELEVANT_OPPORTUNITIES_START = 'GET_RELEVANT_OPPORTUNITIES_START';
export const GET_RELEVANT_OPPORTUNITIES_ERROR = 'GET_RELEVANT_OPPORTUNITIES_ERROR';
export const GET_RELEVANT_OPPORTUNITIES_SUCCESS = 'GET_RELEVANT_OPPORTUNITIES_SUCCESS';

export const GET_DASHBOARD_OPPORTUNITIES_START = 'GET_DASHBOARD_OPPORTUNITIES_START';
export const GET_DASHBOARD_OPPORTUNITIES_ERROR = 'GET_DASHBOARD_OPPORTUNITIES_ERROR';
export const GET_DASHBOARD_OPPORTUNITIES_SUCCESS = 'GET_DASHBOARD_OPPORTUNITIES_SUCCESS';

export const GET_RESTRICTED_OPPORTUNITIES_START = 'GET_RESTRICTED_OPPORTUNITIES_START';
export const GET_RESTRICTED_OPPORTUNITIES_ERROR = 'GET_RESTRICTED_OPPORTUNITIES_ERROR';
export const GET_RESTRICTED_OPPORTUNITIES_SUCCESS = 'GET_RESTRICTED_OPPORTUNITIES_SUCCESS';

export function getRelevantOpportunities(id, filters) {
  return {
    type: GET_RELEVANT_OPPORTUNITIES_START,
    id,
    filters,
  };
}

export function getRestrictedOpportunities() {
  return {
    type: GET_RESTRICTED_OPPORTUNITIES_START,
  };
}
