import {
  ADMIN_GET_USERS_START,
  ADMIN_GET_USERS_ERROR,
  ADMIN_GET_USERS_SUCCESS,
  GET_USER_FILTER_COMPANY_OPTIONS_START,
  GET_USER_FILTER_COMPANY_OPTIONS_SUCCESS,
  GET_USER_FILTER_COMPANY_OPTIONS_ERROR,
} from '@src/js/actions/user/adminUsers';

const initialState = {
  loading: false,
  error: null,
  users: null,
  loadingPeers: false,
  errorPeers: null,
  peers: null,
  hasMorePeers: true,
  companiesLoading: false,
  companiesError: null,
  companiesFilterOptions: null,
};

const actionsMap = {
  [ADMIN_GET_USERS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
    users: null,
  }),
  [ADMIN_GET_USERS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [ADMIN_GET_USERS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    users: action.data,
  }),
  [GET_USER_FILTER_COMPANY_OPTIONS_START]: (state) => ({
    ...state,
    companiesLoading: true,
    companiesError: null,
    companiesFilterOptions: null,
  }),
  [GET_USER_FILTER_COMPANY_OPTIONS_ERROR]: (state, action) => ({
    ...state,
    companiesLoading: false,
    companiesError: action.error.message,
  }),
  [GET_USER_FILTER_COMPANY_OPTIONS_SUCCESS]: (state, action) => ({
    ...state,
    companiesLoading: false,
    companiesFilterOptions: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
