import { takeLatest, call, put } from 'redux-saga/effects';

import {
  PARTNER_DOCUMENTS_PERMISSIONS_START,
  PARTNER_DOCUMENTS_PERMISSIONS_ERROR,
  PARTNER_DOCUMENTS_PERMISSIONS_SUCCESS,
} from '@src/js/actions/partner/partnerDocumentsPermissions';

import * as api from '@src/js/api/partner/partnerDocumentsPermissions';

function* getPartnerDocumentsPermissions(options) {
  try {
    const data = yield call(() => api.partnerDocumentsPermissions(options.slug));
    yield put({ type: PARTNER_DOCUMENTS_PERMISSIONS_SUCCESS, data, options });
  } catch (error) {
    yield put({ type: PARTNER_DOCUMENTS_PERMISSIONS_ERROR, error });
  }
}

function* getPartnerDocumentsPermissionsWatcher() {
  yield takeLatest(PARTNER_DOCUMENTS_PERMISSIONS_START, getPartnerDocumentsPermissions);
}

export default [
  getPartnerDocumentsPermissionsWatcher(),
];
