import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_ALL_OPPORTUNITIES_START,
  GET_ALL_OPPORTUNITIES_ERROR,
  GET_ALL_OPPORTUNITIES_SUCCESS,
} from '@src/js/marketplace/actions/getAllOpportunities';
import * as api from '@src/js/marketplace/api/getOpportunityData';

function* getAllOpportunities(options) {
  try {
    const data = yield call(() => api.getOpportunities(options.filter));
    yield put({ type: GET_ALL_OPPORTUNITIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ALL_OPPORTUNITIES_ERROR, error });
  }
}

function* getAllOpportunitiesWatcher() {
  yield takeLatest(GET_ALL_OPPORTUNITIES_START, getAllOpportunities);
}

export default [
  getAllOpportunitiesWatcher(),
];
