import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import SelectBox from '@src/js/components/global/inputs/Selectbox';

class CompanyFormTurnoverFields extends Component {
  static propTypes = {
    companyFormOptions: PropTypes.object,
    isEtinerary: PropTypes.bool,
  };

  render() {
    const {
      companyFormOptions,
      isEtinerary,
    } = this.props;

    const turnovers = isEtinerary
      ? companyFormOptions?.results?.etineraryTurnovers
      : companyFormOptions?.results?.turnovers;

    return (
      <div className='row'>
        <div className='col-lg-6 col-xs-12'>
          <Field
            name='basic.turnover'
            fieldName='basic.turnover'
            fieldType='text'
            fieldLabel='company_form.turnover'
            options={ turnovers?.map(turnover => ({ value: turnover.id, label: turnover.name }))
            }
            component={ SelectBox }
            fieldRequired
          />
        </div>
        <div className='col-lg-6 col-xs-12'>
          <Field
            name='basic.turnoverCurrency'
            fieldName='basic.turnoverCurrency'
            fieldType='text'
            fieldLabel='company_form.turnoverCurrency'
            options={ companyFormOptions
            && companyFormOptions.results.currencies
            && companyFormOptions.results.currencies.map(currency => {
              const formattedCurrency = (
                <FormattedMessage id={ `company_form.currency.${ currency.name }` } />
              );

              return ({ value: currency.id, label: formattedCurrency });
            })
            }
            component={ SelectBox }
            fieldRequired
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyFormOptions: state.companyFormOptions.companyFormOptions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormTurnoverFields);

export default withRouter(connectWrapper);
