import fetchResource from '@src/js/api/fetch-resource';
import loadEnvVariable from '@src/js/static/LoadEnv';

export function getFeaturedOpportunities(options) {
  let url = `${ loadEnvVariable('MARKETPLACE_API_PATH') }/user/products`;
  if (options.filters) {
    const query = new URLSearchParams({ ...options.filters, featured: true });
    url = `${ url }?${ query }`;
  }

  return fetchResource(url, {
    method: 'GET',
    externalUrl: true,
  });
}
