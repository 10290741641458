import fetchResource from '@src/js/api/fetch-resource';
import { SelectValue } from '@app/components/global/forms/select/select';
import { MultiValue, SingleValue } from 'react-select';
import { Banner } from '@app/models/Banner';

export function getBannerList({
  search, page, companyTypes, bannerType, bannerLang, bannerStatus,
}: {
  search: string,
  page: number,
  companyTypes: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerType: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerLang: SingleValue<SelectValue> | MultiValue<SelectValue>,
  bannerStatus: SingleValue<SelectValue> | MultiValue<SelectValue>,
}) {
  const params = new URLSearchParams({
    search,
    page: page.toString(),
    companyType: companyTypes ? companyTypes.toString() : '',
    bannerType: bannerType ? bannerType.toString() : '',
    language: bannerLang ? bannerLang.toString() : '',
    status: bannerStatus ? bannerStatus.toString() : '',
  });
  return fetchResource(`api/banner?${ params }`, {
    method: 'GET',
  });
}

export function getBanner(id: number) {
  return fetchResource(`api/banner/${ id }`, {
    method: 'GET',
  });
}

export function updateBanner(id: number, data: Banner) {
  return fetchResource(`api/banner/${ id }`, {
    method: 'PUT',
    body: { ...data },
  });
}

export function createBanner(data: Banner) {
  return fetchResource('api/banner', {
    method: 'POST',
    body: { ...data },
  });
}

export function updateBannerStatus({ id, status }: {id: number, status: 'published'|'deleted' }) {
  return fetchResource(`api/banner/${ id }`, {
    method: 'PATCH',
    body: { status },
  });
}
