import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_CONTACT_USER_START,
  GET_CONTACT_USER_ERROR,
  GET_CONTACT_USER_SUCCESS,
  ContactUserAction,
} from '@app/store/funding/actions/contactUser';

import * as api from '@src/js/api/user/getContactUser';

function* getContactUser({ contactType }: ContactUserAction): Generator {
  try {
    const data = yield call(() => api.getContactUser(contactType));
    yield put({ type: GET_CONTACT_USER_SUCCESS, data, contactType });
  } catch (error) {
    yield put({ type: GET_CONTACT_USER_ERROR, error });
  }
}

export function* getContactUserWatcher() {
  yield takeLatest(GET_CONTACT_USER_START, getContactUser);
}

export default [
  getContactUserWatcher(),
];
