import {
  PARTNER_CREATE_LIBRARY_START,
  PARTNER_CREATE_LIBRARY_ERROR,
  PARTNER_CREATE_LIBRARY_SUCCESS,
} from '@src/js/actions/library/partnerCreateLibrary';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const actionsMap = {
  [PARTNER_CREATE_LIBRARY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [PARTNER_CREATE_LIBRARY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [PARTNER_CREATE_LIBRARY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
