export const TASK_SET_USER_PERMISSIONS_START = 'TASK_SET_USER_PERMISSIONS_START';
export const TASK_SET_USER_PERMISSIONS_ERROR = 'TASK_SET_USER_PERMISSIONS_ERROR';
export const TASK_SET_USER_PERMISSIONS_SUCCESS = 'TASK_SET_USER_PERMISSIONS_SUCCESS';

export function taskSetUserPermissions(slug, permissions) {
  return {
    type: TASK_SET_USER_PERMISSIONS_START,
    slug,
    permissions,
  };
}
