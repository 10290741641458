import { Company } from '@src/js/constants/entities';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';

const checkShowModalEtinerary = (key, userData) => {
  const isEtinerary = userData?.companyType === Company.TYPE_ETINERARY;
  const isEtineraryHasAccess = userData?.etineraryStatus === Company.ETINERARY_STATUS_ACCEPTED;
  const contactModal = (['network', 'programme'].includes(key) && !isEtineraryHasAccess)
  || ['directory', 'marketplace'].includes(key);
  const fundingModal = ['opportunities', 'introduction_services',
    'funding_data_room', 'finanza_agevolata'].includes(key) && !isEtineraryHasAccess;
  if (isEtinerary) {
    if (contactModal) {
      return 'contactModal';
    }
    if (fundingModal) {
      return 'fundingModal';
    }
    return null;
  }
  return null;
};

export function useEtineraryModal() {
  const { showModal, hideModal } = useGlobalModalContext();

  const showEtineraryModal = (modalType, type) => {
    if (modalType === 'contactModal') {
      return showModal({
        type: ModalTypes.EtineraryContact,
        title: 'etinerary.contact_modal.title',
        config: {
          size: 'lg',
          className: 'etinerary-contact-modal',
        },
      });
    }
    return showModal({
      type: ModalTypes.EtineraryFunding,
      config: {
        size: 'lg',
        className: 'etinerary-modal-wrapper',
      },
      data: { type },
    });
  };

  return { showModal: showEtineraryModal, hideModal, checkType: checkShowModalEtinerary };
}

export const scrollToAnchor = (hash) => {
  let time = 0;
  const id = hash.replace('#', '');
  if (!hash) return;
  const interval = setInterval(() => {
    const element = document.getElementById(id);
    const loader = document.querySelector('.loader-spinning');
    const allImagesLoaded = [...document.querySelectorAll('div img')]
      .every(im => !!im.complete);
    if (element && !loader && allImagesLoaded) {
      element.scrollIntoView();
      clearInterval(interval);
    }
    if (time > 60) {
      clearInterval(interval);
    }
    time++;
  }, 500);
};
