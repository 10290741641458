import { takeLatest, call, put } from 'redux-saga/effects';

import {
  GET_PARTNER_PERMISSIONS_START,
  GET_PARTNER_PERMISSIONS_ERROR,
  GET_PARTNER_PERMISSIONS_SUCCESS,
} from '@src/js/actions/partner/partnerPermissions';

import * as api from '@src/js/api/partner/partnerPermissions';

function* getPartnerPermissions(options) {
  try {
    const data = yield call(() => api.getPartnerPermissions(options.slug, options.source));
    yield put({ type: GET_PARTNER_PERMISSIONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PARTNER_PERMISSIONS_ERROR, error });
  }
}

function* getPartnerPermissionsWatcher() {
  yield takeLatest(GET_PARTNER_PERMISSIONS_START, getPartnerPermissions);
}

export default [
  getPartnerPermissionsWatcher(),
];
