import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import { ModalTypes } from '@app/components/global/ModalComponents';
import { Icon } from '@src/_v2/js/components/Icons';
import { copyObject } from '@src/js/helpers/helpers';
import { deleteBusinessNeed } from '@src/js/marketplace/actions/businessNeeds';
import { editBusinessNeed } from '@src/js/marketplace/actions/modal/businessNeedModal';
import {
  ShowMoreButton,
  ShowMoreButtonInner,
} from '@src/js/views/user/pages/Dashboard/components/CompanyWidget/styled';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const maxElements = 9;

const BusinessNeedItem = ({
  businessNeed, toggleBusinessNeedModal, userId, isAdmin,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [titleValue, setTitleValue] = useState(businessNeed.Title);
  const { showModal, hideModal } = useGlobalModalContext();

  const getConditions = () => {
    const conditions = [];
    if (businessNeed.EmbeddedCountries && businessNeed.EmbeddedCountries.length > 0) {
      businessNeed.EmbeddedCountries.forEach(country => {
        conditions.push(country.Name);
      });
    }
    if (businessNeed.Sectors && businessNeed.Sectors.length > 0) {
      businessNeed.Sectors.forEach(sector => {
        conditions.push(intl.formatMessage({ id: `sectors.${ sector.Name }` }));
      });
    }
    if (businessNeed.Expertise && businessNeed.Expertise.length > 0) {
      businessNeed.Expertise.forEach(expertise => {
        conditions.push(intl.formatMessage({ id: `partner_expertise.${ expertise.Name }` }));
      });
    }
    if (businessNeed.Types && businessNeed.Types.length > 0) {
      businessNeed.Types.forEach(type => {
        conditions.push(intl.formatMessage({ id: `partner_sectors.${ type.Name }` }));
      });
    }
    if (businessNeed.Dimensions && businessNeed.Dimensions.length > 0) {
      businessNeed.Dimensions.forEach(dimension => {
        conditions.push(intl.formatMessage({ id: `dimension.${ dimension.Name }` }));
      });
    }
    return conditions;
  };

  const toggleEditTitle = () => {
    const newBusinessNeed = copyObject(businessNeed);
    newBusinessNeed.UserId = isAdmin ? userId : null;

    if (editMode) {
      newBusinessNeed.Title = titleValue;
      dispatch(editBusinessNeed(newBusinessNeed, isAdmin, false));
    }

    setEditMode(!editMode);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      toggleEditTitle();
    }
  };

  const renderBusinessNeedHeader = () => {
    if (!editMode) {
      return (
        <div className='businessNeed-widget__title'>
          <h3>{ businessNeed.Title }</h3>
          <span className='icon-small-Edit' role='presentation' onClick={ toggleEditTitle } />
        </div>
      );
    }
    return (
      <div className='businessNeed-widget__title'>
        <input
          value={ titleValue }
          name='businessNeed-widget__title'
          onChange={ e => setTitleValue(e.target.value) }
          onKeyDown={ handleKeyDown }
        />
        <span className='icon-small-Edit' role='presentation' onClick={ toggleEditTitle } />
      </div>
    );
  };

  const toggleShowMore = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const showDeleteModal = () => {
    showModal({
      type: ModalTypes.Confirmation,
      title: 'marketplace.businessNeeds.confirmDelete',
      config: { size: 'md' },
      onTop: true,
      data: {
        action: () => {
          dispatch(deleteBusinessNeed(businessNeed, isAdmin, userId));
          hideModal();
        },
      },
    });
  };

  const conditions = getConditions();

  return (
    <div className='businessNeed-widget__item'>
      { renderBusinessNeedHeader() }
      <div className='businessNeed-widget__labels'>
        { conditions?.slice(0, showMoreOptions ? conditions.length : maxElements).map(condition => (
          <span
            className='businessNeed-widget__label'
            key={ condition }
          >
            { condition }
          </span>
        ))}
      </div>
      { conditions?.length > maxElements && (
        <div>
          <ShowMoreButton
            onClick={ toggleShowMore }
            onKeyPress={ toggleShowMore }
            role='presentation'
          >
            <ShowMoreButtonInner>
              <FormattedMessage id={ showMoreOptions ? 'show_less' : 'show_more' } />
              <Icon icon={ showMoreOptions ? 'up' : 'down' } />
            </ShowMoreButtonInner>
          </ShowMoreButton>
        </div>
      )}
      <div className='businessNeed-widget__footer'>
        <button
          className='businessNeed-widget__edit'
          onClick={ () => toggleBusinessNeedModal(businessNeed) }
        >
          <FormattedMessage id='edit' />
        </button>
        <button
          className='businessNeed-widget__delete'
          onClick={ showDeleteModal }
        >
          <FormattedMessage id='delete' />
        </button>
      </div>
    </div>
  );
};

BusinessNeedItem.propTypes = {
  businessNeed: PropTypes.object,
  toggleBusinessNeedModal: PropTypes.func,
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
};

export default BusinessNeedItem;
