import produce from 'immer';

import {
  GET_LIBRARY_PATH_LIST_START,
  GET_LIBRARY_PATH_LIST_FAIL,
  GET_LIBRARY_PATH_LIST_SUCCESS,
  GET_LIBRARY_PATH_START,
  GET_LIBRARY_PATH_FAIL,
  GET_LIBRARY_PATH_SUCCESS,
  UPDATE_LIBRARY_PATH_START,
  UPDATE_LIBRARY_PATH_FAIL,
  UPDATE_LIBRARY_PATH_SUCCESS,
  UPDATE_LIBRARY_PATH_STATUS_START,
  UPDATE_LIBRARY_PATH_STATUS_FAIL,
  UPDATE_LIBRARY_PATH_STATUS_SUCCESS,
  GET_VIDEO_ON_DEMAND_START,
  GET_VIDEO_ON_DEMAND_FAIL,
  GET_VIDEO_ON_DEMAND_SUCCESS,
} from '@app/store/content/libraryPath/actions';
import { LibraryPath, Library } from '@app/models';
import { LibraryPathListItem } from '@app/models/LibraryPath';
import { Action } from 'redux';
import { PaginatedResponse, isPaginatedResponse } from '@app/models/Pagination';
import { ResponseError } from '@app/models/Error';

export type LibraryPathState = {
  libraryPath: LibraryPath|null,
  loading: boolean,
  libraryPathList: LibraryPathListItem[],
  error: unknown,
  totalCount: number,
  videoOnDemandList: VideoOnDemand[],
}

export type VideoOnDemand = {
  title: string,
  libraries: Library[],
}

interface LibraryPathAction extends Action<string> {
  error: ResponseError,
  result: PaginatedResponse<LibraryPath>|LibraryPath|VideoOnDemand[],
  id: string,
  status: string,
}

const initialState: LibraryPathState = {
  loading: false,
  error: null,
  libraryPath: null,
  libraryPathList: [],
  totalCount: 0,
  videoOnDemandList: [],
};

function libraryPathReducer(state = initialState, action: LibraryPathAction) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case GET_LIBRARY_PATH_LIST_START:
        draft.loading = true;
        draft.error = null;
        draft.totalCount = 0;
        break;
      case GET_LIBRARY_PATH_LIST_FAIL:
      case GET_LIBRARY_PATH_FAIL:
      case UPDATE_LIBRARY_PATH_FAIL:
      case UPDATE_LIBRARY_PATH_STATUS_FAIL:
      case GET_VIDEO_ON_DEMAND_FAIL:
        draft.loading = false;
        draft.error = action.error.message;
        break;
      case GET_LIBRARY_PATH_LIST_SUCCESS:
        draft.loading = false;
        if (!isPaginatedResponse<LibraryPathListItem>(action.result)) break;
        draft.libraryPathList = action.result.results;
        draft.totalCount = action.result.pagination.totalCount;
        draft.error = null;
        break;
      case GET_LIBRARY_PATH_START:
        draft.loading = true;
        draft.error = null;
        draft.libraryPath = null;
        break;
      case GET_LIBRARY_PATH_SUCCESS:
        draft.loading = false;
        draft.libraryPath = action.result as LibraryPath;
        draft.error = null;
        break;
      case UPDATE_LIBRARY_PATH_START:
      case UPDATE_LIBRARY_PATH_STATUS_START:
      case UPDATE_LIBRARY_PATH_SUCCESS:
        draft.loading = false;
        draft.error = null;
        break;
      case UPDATE_LIBRARY_PATH_STATUS_SUCCESS:
        draft.libraryPathList = state.libraryPathList.map(libraryPath => {
          if (Number(action.id) === libraryPath.id) {
            return {
              ...libraryPath,
              status: action.status,
            };
          }
          return libraryPath;
        });
        draft.loading = false;
        draft.error = null;
        break;
      case GET_VIDEO_ON_DEMAND_START:
        draft.loading = true;
        draft.error = null;
        draft.videoOnDemandList = [];
        break;
      case GET_VIDEO_ON_DEMAND_SUCCESS:
        draft.loading = false;
        draft.videoOnDemandList = action.result as VideoOnDemand[];
        draft.error = null;
        break;
      default:
        break;
    }
  }
  );
}

export default libraryPathReducer;
