import {
  COMPANY_CONTACT_MESSAGE_SEND_START,
  COMPANY_CONTACT_MESSAGE_SEND_ERROR,
  COMPANY_CONTACT_MESSAGE_SEND_SUCCESS,
} from '@src/js/actions/company/companyContactMessageSend';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const actionsMap = {
  [COMPANY_CONTACT_MESSAGE_SEND_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [COMPANY_CONTACT_MESSAGE_SEND_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [COMPANY_CONTACT_MESSAGE_SEND_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    data: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
