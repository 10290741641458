import { ChatStyles } from '@app/models/Chat';
import { findCreateNewThread } from '@app/store/chat/chatApi';
import { selectUserUuid } from '@app/store/chat/chatSlice';
import ChatFooter from '@src/js/layouts/global/panels/messaging/ChatFooter';
import ChatHeader from '@src/js/layouts/global/panels/messaging/ChatHeader';
import ChatMessages from '@src/js/layouts/global/panels/messaging/components/ChatMessages';
import loadEnvVariable from '@src/js/static/LoadEnv';
import { useAppDispatch } from '@src/js/store';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

type Props = {
  styles?: ChatStyles,
  setSearch?: (search: string) => void,
}

const Chat = ({ styles, setSearch }: Props) => {
  const dispatch = useAppDispatch();
  const userUuid = useSelector(selectUserUuid);

  useEffect(() => {
    if (userUuid) {
      dispatch(findCreateNewThread({ participants: [userUuid] }));
    }
  }, [dispatch, userUuid]);

  return (
    <div className={ styles?.masterClass ? styles.masterClass : 'messages-panel' }>
      <ChatHeader setSearch={ setSearch } styles={ styles } />
      <ChatMessages styles={ styles } />
      <ChatFooter
        styles={ styles }
      />
      <div className='messages-panel__link'>
        <a className='messages-panel__report-link' href={ `mailto:${ loadEnvVariable('MAIL_SELF_INTERVIEW') }` }>
          <FormattedMessage id='messages.report' />
        </a>
      </div>
    </div>
  );
};

export default Chat;
