import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { SelectValue, isSelectValueArray, isSelectValueObj } from './select';

type Props = {
  className?: string,
  name: string,
  placeholder?: string|object|SelectValue[],
  multi?: boolean,
  value: SingleValue<SelectValue>|MultiValue<SelectValue>,
  simpleValue?: boolean,
  options?: MultiValue<SelectValue>,
  onChange: (v: SingleValue<SelectValue>|MultiValue<SelectValue>) => void,
  defaultValue?: SingleValue<SelectValue>|MultiValue<SelectValue>,
  clearable?: boolean,
  disabled?: boolean,
  noResultsText?: string,
  components?: object,
  searchable?: boolean,
}

const EliteSelect = ({
  className, name, placeholder, multi, value, simpleValue,
  options, onChange, defaultValue, clearable = true, disabled,
  noResultsText, components, searchable = true,
}: Props) => {
  let fieldValue: SingleValue<SelectValue>|MultiValue<SelectValue> = value;
  if (value !== undefined && value !== null && value !== '' && (simpleValue || (!isSelectValueObj(value) && !multi))) {
    if (multi) {
      fieldValue = options?.filter(option => isSelectValueArray(value) && isSelectValueObj(option)
      && value.includes(option.value ?? option.id)) ?? null;
    } else {
      fieldValue = options?.find(option => isSelectValueObj(option)
      && (value === option.value || value === option.id)) ?? null;
    }
  }

  const fieldOnChange = (val: SingleValue<SelectValue>|MultiValue<SelectValue>) => {
    const singleValue = isSelectValueObj(val) ? val.value : '';

    return (
      simpleValue
        ? onChange(multi && isSelectValueArray(val) ? val?.map(v => isSelectValueObj(v) && v.value) : singleValue)
        : onChange(val)
    );
  };

  return (
    <div className='elite-select'>
      <Select
        className={ className }
        name={ name }
        placeholder={ placeholder }
        isMulti={ multi }
        value={ fieldValue }
        onChange={ fieldOnChange }
        options={ options || [] }
        defaultValue={ defaultValue }
        isClearable={ clearable }
        isDisabled={ disabled }
        noOptionsMessage={ () => noResultsText }
        components={ components }
        isSearchable={ searchable }
      />
    </div>
  );
};

export default EliteSelect;
