import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { showToastrError } from '@app/store/global/actions';
import * as api from '@app/store/eventAttendance/api';
import {
  GET_LIST_START,
  GET_LIST_FAIL,
  GET_LIST_SUCCESS,
  ADD_ATTENDANCE_START,
  ADD_ATTENDANCE_FAIL,
  ADD_ATTENDANCE_SUCCESS,
  REMOVE_ATTENDANCE_START,
  REMOVE_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_LOCATION_START,
  UPDATE_ATTENDANCE_LOCATION_SUCCESS,
  UPDATE_ATTENDANCE_LOCATION_FAIL,
} from '@app/store/eventAttendance/actions';

function* getEventAttendanceList({ slug, params }) {
  try {
    const result = yield call(() => api.getEventAttendanceList(slug, params));
    yield put({ type: GET_LIST_SUCCESS, result });
  } catch (error) {
    yield all([
      put({ type: GET_LIST_FAIL, error }),
      put(showToastrError()),
    ]);
  }
}

function* addAttendance({ slug, data, filters }) {
  try {
    yield call(() => api.addAttendance(slug, data));
    if (filters) {
      yield put({ type: GET_LIST_START, slug, params: filters });
    } else {
      yield put({ type: ADD_ATTENDANCE_SUCCESS, data });
    }
  } catch (error) {
    yield all([
      put({ type: ADD_ATTENDANCE_FAIL, data }),
      put(showToastrError()),
    ]);
  }
}

function* removeAttendance({ slug, id, filters }) {
  try {
    yield call(() => api.removeAttendance(slug, id));
    yield put({ type: GET_LIST_START, slug, params: filters });
  } catch (error) {
    yield all([
      put({ type: REMOVE_ATTENDANCE_FAIL }),
      put(showToastrError()),
    ]);
  }
}

function* updateAttendanceLocation({ slug, id, data }) {
  try {
    yield call(() => api.updateAttendanceLocation(slug, id, data));
    yield put({
      type: UPDATE_ATTENDANCE_LOCATION_SUCCESS, slug, id, data,
    });
  } catch (error) {
    yield put({ type: UPDATE_ATTENDANCE_LOCATION_FAIL, id });
  }
}

function* root() {
  yield takeLatest(GET_LIST_START, getEventAttendanceList);
  yield takeLatest(ADD_ATTENDANCE_START, addAttendance);
  yield takeLatest(REMOVE_ATTENDANCE_START, removeAttendance);
  yield takeLatest(UPDATE_ATTENDANCE_LOCATION_START, updateAttendanceLocation);
}

export default root;
