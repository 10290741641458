import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ children, dashboard }) => (
  <div className={ `container-content${ dashboard ? ' m-t-0' : '' }` }>
    { children }
  </div>
);

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dashboard: PropTypes.bool,
};

export default Content;
