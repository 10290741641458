import React, { useState } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { EventPartner } from '@app/models';
import { InputImage, InputRichText, InputText } from '@app/components/global/forms';
import { Button } from '@src/js/components/global';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import LanguageSelect from '@app/components/global/forms/select/LanguageSelect';
import { DEFAULT_LANGUAGE, Language } from '@app/models/Language';
import { getLanguages } from '@src/js/helpers/helpers';

type Props = {
  partner?: EventPartner,
  addValue: (value: EventPartner) => void,
  updateValue: (value: EventPartner) => void,
}
const EventPartnerModal = ({ addValue, partner, updateValue }: Props) => {
  const languages = getLanguages() as Language[];
  const { hideModal } = useGlobalModalContext();
  const defaultValues: EventPartner = {
    id: partner?.id ?? null,
    name: partner?.name || '',
    logo: partner?.logo || null,
    details: {
      en: { description: partner?.details?.en?.description || null },
      it: { description: partner?.details?.it?.description || null },
      es: { description: partner?.details?.es?.description || null },
      ar: { description: partner?.details?.ar?.description || null },
      fr: { description: partner?.details?.fr?.description || null },
      pt: { description: partner?.details?.pt?.description || null },
    },
  };
  const methods = useForm<EventPartner>({ defaultValues });
  const [lang, setLang] = useState<Language>(DEFAULT_LANGUAGE);
  const {
    handleSubmit, register,
    formState: { errors, isValid }, control,
  } = methods;
  const onSubmit = (values: EventPartner) => {
    if (values.id !== null && values.id >= 0) {
      updateValue(values);
    } else {
      addValue(values);
    }
    hideModal();
  };

  return (
    <FormProvider { ...methods }>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <ModalBody>
          <div>
            <InputImage
              name='logo'
              url='api/image/global/image'
            />
            <InputText
              name='name'
              label='name'
              register={ register }
              errors={ errors }
              required
            />
            <LanguageSelect
              lang={ lang }
              setLang={ setLang }
              label='event_form.description'
            />
            { languages.map(languageCode => (
              <InputRichText
                key={ languageCode }
                name={ `details.${ languageCode }.description` }
                control={ control }
                errors={ errors }
                className={ lang !== languageCode ? 'd-none' : '' }
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            size='md'
            buttonText='dialog.confirm'
            buttonType='submit'
            disabled={ !isValid }
          />
          <Button
            size='md'
            buttonText='cancel'
            emptyButtonType='empty-grey'
            onClickFunc={ hideModal }
          />
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

export default EventPartnerModal;
