import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import ClassNames from 'classnames';
import EliteLoader from './EliteLoader';

export default class PageLoader extends Component {
  static propTypes = {
    position: PropTypes.string,
    background: PropTypes.string,
    isOverlay: PropTypes.bool,
    fixed: PropTypes.bool,
  };

  static defaultProps = {
    isOverlay: true,
    fixed: false,
  };

  render() {
    const {
      position,
      background,
      isOverlay,
      fixed,
    } = this.props;

    const style = {};

    if (position !== undefined) {
      style.position = position;
    }

    if (background !== undefined) {
      style.background = background;
    }

    const classes = ClassNames('page-items-loading', {
      'page-items-loading-overlay': isOverlay,
      'page-items-loading-fixed': fixed,
    });

    return (
      <div className={ classes } style={ style }>
        <EliteLoader />
      </div>
    );
  }
}
