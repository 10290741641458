import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { downloadFile } from '@app/components/global/forms/utils';
import { Icons } from '@src/js/constants/icons';

const ellipsisMiddle = (str) => {
  if (str?.length > 30) {
    return `${ str.substr(0, 17) }...${ str.substr(str.length - 10, str.length) }`;
  }
  return str;
};

export default class FilePreview extends Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    arrow: PropTypes.bool,
    removeFileAction: PropTypes.func,
    downloadable: PropTypes.bool,
    removable: PropTypes.bool,
  };

  static defaultProps = {
    arrow: true,
    removable: true,
  };

  removeItem(item) {
    const { removeFileAction } = this.props;

    removeFileAction(item);
  }

  download() {
    const { file, downloadable } = this.props;
    if (downloadable && file?.url) downloadFile(file.url, file.name);
  }

  render() {
    const {
      file, arrow, downloadable, removable,
    } = this.props;
    const shortName = ellipsisMiddle(file.name);

    return (
      <div className='card file-preview-card'>
        { removable && (
        <div
          role='button'
          tabIndex='0'
          className='card card__remove'
          onClick={ () => { this.removeItem(file.id); } }
          onKeyUp={ () => { this.removeItem(file.id); } }
        />
        )}
        <div className='card file-preview-card__icon'>
          <img
            src={ Icons.Doc }
            alt='Document'
          />
        </div>
        <div
          className={ `card file-preview-card__content ${ downloadable && file?.url ? 'cursor-pointer' : '' }` }
          onClick={ () => this.download() }
          role='presentation'
        >
          <div className='card file-preview-card__content__name'>{ shortName }</div>
        </div>
        { arrow && <span className='card file-preview-card__arrow' /> }
      </div>
    );
  }
}
