const scope = 'LIBRARY_PATH';

export const GET_LIBRARY_PATH_LIST_START = `${ scope }/GET_LIBRARY_PATH_LIST_START`;
export const GET_LIBRARY_PATH_LIST_FAIL = `${ scope }/GET_LIBRARY_PATH_LIST_FAIL`;
export const GET_LIBRARY_PATH_LIST_SUCCESS = `${ scope }/GET_LIBRARY_PATH_LIST_SUCCESS`;

export const GET_LIBRARY_PATH_START = `${ scope }/GET_LIBRARY_PATH_START`;
export const GET_LIBRARY_PATH_FAIL = `${ scope }/GET_LIBRARY_PATH_FAIL`;
export const GET_LIBRARY_PATH_SUCCESS = `${ scope }/GET_LIBRARY_PATH_SUCCESS`;

export const UPDATE_LIBRARY_PATH_START = `${ scope }/UPDATE_LIBRARY_PATH_START`;
export const UPDATE_LIBRARY_PATH_FAIL = `${ scope }/UPDATE_LIBRARY_PATH_FAIL`;
export const UPDATE_LIBRARY_PATH_SUCCESS = `${ scope }/UPDATE_LIBRARY_PATH_SUCCESS`;

export const UPDATE_LIBRARY_PATH_STATUS_START = `${ scope }/UPDATE_LIBRARY_PATH_STATUS_START`;
export const UPDATE_LIBRARY_PATH_STATUS_FAIL = `${ scope }/UPDATE_LIBRARY_PATH_STATUS_FAIL`;
export const UPDATE_LIBRARY_PATH_STATUS_SUCCESS = `${ scope }/UPDATE_LIBRARY_PATH_STATUS_SUCCESS`;

export const GET_VIDEO_ON_DEMAND_START = `${ scope }/GET_VIDEO_ON_DEMAND_START`;
export const GET_VIDEO_ON_DEMAND_FAIL = `${ scope }/GET_VIDEO_ON_DEMAND_FAIL`;
export const GET_VIDEO_ON_DEMAND_SUCCESS = `${ scope }/GET_VIDEO_ON_DEMAND_SUCCESS`;

export function getLibraryPathList({
  page, search, statuses, languages,
}) {
  return {
    type: GET_LIBRARY_PATH_LIST_START,
    search,
    page,
    statuses,
    languages,
  };
}

export function getLibraryPath(id) {
  return {
    type: GET_LIBRARY_PATH_START,
    id,
  };
}

export function updateLibraryPath({ id, formData, history }) {
  return {
    type: UPDATE_LIBRARY_PATH_START,
    id,
    formData,
    history,
  };
}

export function updateLibraryPathStatus({ id, status }) {
  return {
    type: UPDATE_LIBRARY_PATH_STATUS_START,
    id,
    status,
  };
}

export function getVideoOnDemand() {
  return {
    type: GET_VIDEO_ON_DEMAND_START,
  };
}
