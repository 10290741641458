import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFooter } from 'reactstrap';
import * as PropTypes from 'prop-types';
import Button from '@src/js/components/global/buttons/Buttons';
import {
  addReferrers, REFERRER_DASHBOARD, REFERRER_FUNDING, REFERRER_CALL,
} from '@src/js/actions/user/users';
import { selectReferrers } from '@app/store/global/selectors/users';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tab, Container } from '@src/_v2/js/components/buttons/Tabs/styled';
import { useGlobalModalContext } from '@app/components/global/GlobalModal';
import PeersList from './PeersList';

const MembersModal = ({
  company,
  isCompany,
}) => {
  const dispatch = useDispatch();
  const { hideModal } = useGlobalModalContext();
  const intl = useIntl();
  const referrers = selectReferrers();
  const [members, setMembers] = useState({ current: referrers || [] });
  useEffect(() => {
    setMembers({ current: referrers || [] });
  }, [referrers]);
  const tabs = [
    {
      id: REFERRER_DASHBOARD,
      text: 'members.tab.dashboard',
    },
    {
      id: REFERRER_FUNDING,
      text: 'members.tab.funding',
    },
    {
      id: REFERRER_CALL,
      text: 'members.tab.call',
    }];
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  const tabClickHandler = (tab) => {
    if (selectedTab === tab) {
      return;
    }
    setSelectedTab(tab);
  };

  const handleSelectMember = (peer) => {
    if (members?.current?.includes(peer.uuid)) {
      const newArray = members.current.filter(uuid => uuid !== peer.uuid);
      setMembers({ current: newArray });
    } else {
      setMembers({ current: selectedTab === REFERRER_CALL ? [peer.uuid] : [...members.current, peer.uuid] });
    }
  };

  const messages = {
    successTitle: intl.formatMessage({ id: 'notification.success_title' }),
    successMessage: intl.formatMessage({ id: 'members.notification.success' }),
    errorTitle: intl.formatMessage({ id: 'notification.error_title' }),
    errorMessage: intl.formatMessage({ id: 'members.notification.error' }),
  };

  const submit = () => {
    dispatch(addReferrers(company.slug, isCompany, members?.current, selectedTab, messages));
    hideModal();
  };

  return (
    <>
      <div className='flex'>
        <Container className='w-100 d-flex justify-content-between'>
          {tabs.map(tab => (
            <Tab
              className='m-0'
              key={ tab.id }
              selected={ tab.id.toLowerCase() === selectedTab.toLowerCase() }
              onClick={ () => tabClickHandler(tab.id) }
            >
              <FormattedMessage id={ tab.text } />
            </Tab>
          ))}
        </Container>
      </div>
      <PeersList
        company={ company }
        isCompany={ isCompany }
        referrerType={ selectedTab }
        handleSelectMember={ handleSelectMember }
        members={ members }
      />
      <ModalFooter>
        <Button
          additionalClass='messages-page__modal-footer'
          size='lg'
          buttonText='dialog.confirm'
          onClickFunc={ submit }
        />
      </ModalFooter>
    </>
  );
};

MembersModal.propTypes = {
  company: PropTypes.object.isRequired,
  isCompany: PropTypes.bool,
};

MembersModal.defaultProps = {
  isCompany: false,
};

export default MembersModal;
