import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSection, InputSelect } from '@app/components/global/forms';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PARTNER_SERVICES } from '@app/components/public/onboarding/constants';
import {
  selectOnboardingData, selectOnboardingSave, selectCurrentStep, selectFirstVisit,
} from '@app/store/onboarding/selectors';
import EmailSupport from '@app/components/public/onboarding/sections/EmailSupport';

const PartnerServices = ({ handleSave }) => {
  const currentStep = selectCurrentStep();
  const isFirstVisit = selectFirstVisit();
  const onboardingData = selectOnboardingData();
  const onSave = selectOnboardingSave();
  const {
    control, formState: { errors }, getValues, trigger,
  } = useForm({
    defaultValues: onboardingData,
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isFirstVisit[currentStep]) {
      trigger();
    }
  }, []);

  useEffect(() => {
    if (onSave?.action) {
      handleSave(getValues());
    }
  }, [onSave]);

  return (
    <form>
      <FormSection sectionTitle='onboarding.progressbar.partnerServices'>
        <EmailSupport />
        <div className='w-100'>
          <p className='compliance__description'>
            <FormattedMessage id='onboarding.partnerServices.subscription' />
          </p>
          <InputSelect
            name='partnerServices'
            errors={ errors }
            control={ control }
            options={ PARTNER_SERVICES }
            className='small-select'
            fieldNoClear
            required
          />
        </div>
        <div className='w-100'>
          <p className='compliance__description'>
            <FormattedMessage id='onboarding.partnerServices.description' />
          </p>
        </div>
      </FormSection>
    </form>
  );
};

PartnerServices.propTypes = {
  handleSave: PropTypes.func,
};

export default PartnerServices;
