import fetchResource from '@src/js/api/fetch-resource';

export function getTrainings(filters) {
  let url = 'api/admin/events';

  if (filters) {
    url += `?${ new URLSearchParams(filters) }`;
  }

  return fetchResource(url);
}

export function addTrainings(slug, filters) {
  const url = `api/admin/events/company/${ slug }/add`;

  return fetchResource(url, {
    method: 'PUT',
    'body': filters,
  });
}
