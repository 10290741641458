import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';

import {
  ADMIN_GET_TEMPLATE_LIST_START,
  ADMIN_GET_TEMPLATE_LIST_ERROR,
  ADMIN_GET_TEMPLATE_LIST_SUCCESS,
  ADMIN_CHANGE_STATUS_TEMPLATES_START,
  ADMIN_CHANGE_STATUS_TEMPLATES_ERROR,
  ADMIN_CHANGE_STATUS_TEMPLATES_SUCCESS,
  ADMIN_GET_ONE_TEMPLATE_START,
  ADMIN_GET_ONE_TEMPLATE_ERROR,
  ADMIN_GET_ONE_TEMPLATE_SUCCESS,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateIndex';
import {
  ADMIN_TEMPLATE_SHOW_MODAL,
} from '@src/js/actions/onboarding/templates/admin/adminTemplateModal';

import * as api from '@src/js/api/onboarding/templates/admin/adminTemplateIndex';
import { showToastrError, showToastrSuccess } from '@app/store/global/actions';
import { OnboardingTemplate } from '@src/js/constants/entities';

function* adminGetTemplateList(options) {
  try {
    const data = yield call(() => api.adminGetTemplateList(options.filters));
    yield put({ type: ADMIN_GET_TEMPLATE_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_GET_TEMPLATE_LIST_ERROR, error });
  }
}

function* adminChangeTemplateStatus({ hash, status, filters }) {
  try {
    const data = yield call(() => api.adminChangeTemplateStatus(hash, status));
    yield all([
      put({ type: ADMIN_CHANGE_STATUS_TEMPLATES_SUCCESS, data }),
      put(showToastrSuccess('onboarding.toastr.success', status === OnboardingTemplate.STATUS_ENABLED
        ? 'onboarding.toastr.enable'
        : 'onboarding.toastr.disable'
      )),
      put({ type: ADMIN_GET_TEMPLATE_LIST_START, filters }),
    ]);
  } catch (error) {
    yield all([
      put({ type: ADMIN_CHANGE_STATUS_TEMPLATES_ERROR, error }),
      put(showToastrError('onboarding.toastr.error', error)),
    ]);
  }
}

function* adminGetOneTemplate(options) {
  try {
    const data = yield call(() => api.adminGetOneTemplate(options.selectedTemplate));
    yield all([
      put({ type: ADMIN_GET_ONE_TEMPLATE_SUCCESS, data }),
      put({ type: ADMIN_TEMPLATE_SHOW_MODAL }),
    ]);
  } catch (error) {
    yield put({ type: ADMIN_GET_ONE_TEMPLATE_ERROR, error });
  }
}

function* adminGetTemplateListWatcher() {
  yield takeLatest(ADMIN_GET_TEMPLATE_LIST_START, adminGetTemplateList);
}

function* adminChangeTemplateStatusWatcher() {
  yield takeLatest(ADMIN_CHANGE_STATUS_TEMPLATES_START, adminChangeTemplateStatus);
}

function* adminGetOneTemplateWatcher() {
  yield takeLatest(ADMIN_GET_ONE_TEMPLATE_START, adminGetOneTemplate);
}

export default [
  adminGetTemplateListWatcher(),
  adminChangeTemplateStatusWatcher(),
  adminGetOneTemplateWatcher(),
];
