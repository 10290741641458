import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import DashboardWidget from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/DashboardWidget';
import { Company } from '@src/js/constants/entities';
import Header from '@src/js/views/user/pages/Dashboard/components/DashboardWidget/components/Header/Header';
import { routeCodes } from '@src/js/constants/routes';
import FundingWidget from '@src/js/views/user/pages/Dashboard/components/FundingWidget/FundingWidget';
import { CompassImages } from '@src/js/constants/compassImages';

class CompassWidget extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    userData: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const initBanner = this.initBanner();
    this.state = {
      showDashboard: initBanner.showDashboard,
      isGrowth: initBanner.isGrowth,
      isFunding: initBanner.isFunding,
    };
  }

  initBanner = () => {
    const { userData, loading } = this.props;
    if (userData.companyType === Company.TYPE_ETINERARY) {
      return {
        showDashboard: !loading,
        isGrowth: userData.compassType === Company.COMPASS_TYPE_GROWTH,
        isFunding: false,
      };
    }

    const showDashboard = userData && !loading && (userData.compassType === Company.COMPASS_TYPE_GROWTH
      || userData.compassType === Company.COMPASS_TYPE_DIGITAL);

    const isGrowth = showDashboard && userData.compassType === Company.COMPASS_TYPE_GROWTH;
    const isFunding = Cookies.get('isFundingWidget') === 'true';
    if (isGrowth) {
      Cookies.set('isFundingWidget', !isFunding, { secure: true });
    }
    return {
      showDashboard,
      isGrowth,
      isFunding,
    };
  }

  render() {
    const { userData, match } = this.props;
    const { showDashboard, isGrowth, isFunding } = this.state;

    let compassLink = '';

    if (userData.compassType === Company.COMPASS_TYPE_GROWTH) {
      compassLink = `${ routeCodes.COMPASS_TYPE_GROWTH }/company/${ userData.companySlug }/assessment/`;
    }
    if (userData.compassType === Company.COMPASS_TYPE_DIGITAL) {
      compassLink = routeCodes.COMPASS_TYPE_DIGITAL;
    }

    const imgUrl = CompassImages[isGrowth ? 'growth' : 'etinerary'][match.params.language];

    if (!showDashboard) {
      return null;
    }

    if (isFunding) {
      return <FundingWidget />;
    }

    return (
      <DashboardWidget id='dashboard-compass-widget' className='p-x-1 p-y-1'>
        <Header>
          <FormattedMessage id='companyShape' />
        </Header>
        <a href={ compassLink } target='_blank' rel='noopener noreferrer'>
          <img src={ imgUrl } alt='compass' />
        </a>
      </DashboardWidget>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.initInfo.initInfoLoading,
  error: state.initInfo.initInfoError,
  userData: state.initInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompassWidget);

export default withRouter(connectWrapper);
