const scope = 'Onboarding';

export const SET_ACTIVE_STEP = `${ scope }/SET_ACTIVE_STEP`;
export const GET_DATA_START = `${ scope }/GET_DATA_START`;
export const GET_DATA_SUCCESS = `${ scope }/GET_DATA_SUCCESS`;
export const GET_DATA_FAIL = `${ scope }/GET_DATA_FAIL`;
export const UPDATE_DATA_START = `${ scope }/UPDATE_DATA_START`;
export const UPDATE_DATA_SUCCESS = `${ scope }/UPDATE_DATA_SUCCESS`;
export const UPDATE_DATA_FAIL = `${ scope }/UPDATE_DATA_FAIL`;
export const ON_SAVE_ONBOARDING = `${ scope }/ON_SAVE_ONBOARDING`;
export const CLEAR_DATA = `${ scope }/CLEAR_DATA`;
export const REGISTER_START = `${ scope }/REGISTER_START`;
export const REGISTER_SUCCESS = `${ scope }/REGISTER_SUCCESS`;
export const REGISTER_FAIL = `${ scope }/REGISTER_FAIL`;
export const UPDATE_VALIDATION = `${ scope }/UPDATE_VALIDATION`;
export const DOCUSIGN_START = `${ scope }/DOCUSIGN_START`;
export const DOCUSIGN_SUCCESS = `${ scope }/DOCUSIGN_SUCCESS`;
export const DOCUSIGN_FAIL = `${ scope }/DOCUSIGN_FAIL`;
export const GET_CRM_ACCOUNTS_START = `${ scope }/GET_CRM_ACCOUNTS_START`;
export const GET_CRM_ACCOUNTS_SUCCESS = `${ scope }/GET_CRM_ACCOUNTS_SUCCESS`;
export const GET_CRM_ACCOUNTS_FAIL = `${ scope }/GET_CRM_ACCOUNTS_FAIL`;
export const UPDATE_BASIC_START = `${ scope }/UPDATE_BASIC_START`;
export const UPDATE_BASIC_SUCCESS = `${ scope }/UPDATE_BASIC_SUCCESS`;
export const UPDATE_BASIC_FAIL = `${ scope }/UPDATE_BASIC_FAIL`;

export const getCrmAccounts = (params) => ({
  type: GET_CRM_ACCOUNTS_START,
  params,
});
