import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { purifyHtml } from '@src/js/utils';

const ReminderContentModal = ({ content }) => (
  <>
    <ModalBody className='m-y-4 flex-h-gap-24'>
      <div
        className='flex-h-gap-16 reminder-archive-model blue-a'
        dangerouslySetInnerHTML={ { __html: purifyHtml(content) } }
      />
    </ModalBody>
    <ModalFooter />
  </>
);

ReminderContentModal.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ReminderContentModal;
