export const GET_BUSINESSNEEDS_START = 'GET_BUSINESSNEEDS_START';
export const GET_BUSINESSNEEDS_ERROR = 'GET_BUSINESSNEEDS_ERROR';
export const GET_BUSINESSNEEDS_SUCCESS = 'GET_BUSINESSNEEDS_SUCCESS';
export const DELETE_BUSINESSNEED_START = 'DELETE_BUSINESSNEED_START';
export const DELETE_BUSINESSNEED_ERROR = 'DELETE_BUSINESSNEED_ERROR';
export const DELETE_BUSINESSNEED_SUCCESS = 'DELETE_BUSINESSNEED_SUCCESS';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export function getBusinessNeeds(options) {
  return {
    type: GET_BUSINESSNEEDS_START,
    options,
  };
}

export function deleteBusinessNeed(businessNeed, isAdmin = false, userId = null) {
  return {
    type: DELETE_BUSINESSNEED_START,
    businessNeed,
    isAdmin,
    userId,
  };
}

export function setBusinessNeedFilters(filters) {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function clearFilters() {
  return {
    type: CLEAR_FILTERS,
  };
}
