import fetchResource from '@src/js/api/fetch-resource';
import { parsePhoneNumber } from 'react-phone-number-input';

export function getOnboarding(hash, isAdmin, isPartner) {
  return fetchResource(`api${ isAdmin ? '/admin' : '' }/onboarding/${ isPartner ? 'partner' : 'process' }/${ hash }`, {
    method: 'GET',
  });
}

export function updateOnboarding(hash, data, isAdmin, params, isPartner) {
  const urlParams = params ? new URLSearchParams(params) : '';
  return fetchResource(`api${ isAdmin ? '/admin' : '' }/onboarding/${
    isPartner ? 'partner' : 'process' }/${ hash }?${ urlParams }`,
  {
    method: 'PUT',
    body: data,
  });
}

export function onboardingRegister(data, templateHash) {
  return fetchResource(`api/onboarding/user/registration/${ templateHash }`, {
    method: 'POST',
    body: data,
  });
}

export function uploadFile({
  file, field, entity, fileType, isAdmin, onboarding,
}) {
  const url = `api${
    isAdmin ? '/admin' : ''
  }${ onboarding ? '/onboarding' : ''
  }/${ fileType }/${ entity }/${ field }`;
  return fetchResource(url, {
    method: 'POST',
    body: file,
    noContentType: true,
  });
}

export function docusign({ mobilePhone, terms, hash }) {
  return fetchResource(`api/onboarding/sign/${ hash }`, {
    method: 'POST',
    body: {
      mobilePhone: parsePhoneNumber(mobilePhone).nationalNumber,
      countryCode: parsePhoneNumber(mobilePhone).countryCallingCode,
      terms,
    },
  });
}

export function getCrmAccounts(params) {
  const query = new URLSearchParams(params);
  return fetchResource(`api/crm/account/prospect?${ query }`, {
    method: 'GET',
  });
}

export function updateBasic(hash, data) {
  return fetchResource(`api/admin/onboarding/process/basic/${ hash }`,
    {
      method: 'PUT',
      body: data,
    });
}
