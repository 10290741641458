import { useSelector } from 'react-redux';

export const selectCurrentStep = () => useSelector(state => state.compliance?.currentStep);
export const selectComplianceData = () => useSelector(state => state.compliance?.data);
export const selectComplianceLoading = () => useSelector(state => state.compliance?.dataLoading);
export const selectComplianceError = () => useSelector(state => state.compliance?.dataError);
export const selectComplianceUpdateLoading = () => useSelector(state => state.compliance?.updateDataLoading);
export const selectComplianceUpdateError = () => useSelector(state => state.compliance?.updateDataError);
export const selectComplianceAdminData = () => useSelector(state => state.compliance?.adminData);
export const selectComplianceAdminLoading = () => useSelector(state => state.compliance?.adminDataLoading);
export const selectComplianceAdminError = () => useSelector(state => state.compliance?.adminDataError);
export const selectComplianceAdminUpdateLoading = () => useSelector(state => state.compliance?.updateAdminDataLoading);
export const selectComplianceAdminUpdateError = () => useSelector(state => state.compliance?.updateAdminDataError);
export const selectComplianceList = () => useSelector(state => state.compliance?.list);
export const selectComplianceListLoading = () => useSelector(state => state.compliance?.listLoading);
export const selectComplianceListError = () => useSelector(state => state.compliance?.listError);
export const selectComplianceSign = () => useSelector(state => state.compliance?.signSuccess);
export const selectComplianceSignData = () => useSelector(state => state.compliance?.signData);
export const selectComplianceSignLoading = () => useSelector(state => state.compliance?.signLoading);
export const selectComplianceSignError = () => useSelector(state => state.compliance?.signError);
export const selectComplianceApprove = () => useSelector(state => state.compliance?.approveSuccess);
export const selectComplianceApproveLoading = () => useSelector(state => state.compliance?.approveLoading);
export const selectComplianceApproveError = () => useSelector(state => state.compliance?.approveError);
export const selectComplianceStatus = () => useSelector(state => state.compliance?.status);
export const selectComplianceStatusLoading = () => useSelector(state => state.compliance?.statusLoading);
export const selectComplianceStatusError = () => useSelector(state => state.compliance?.statusError);
export const selectComplianceRequestSent = () => useSelector(state => state.compliance?.requestSent);
export const selectComplianceSave = () => useSelector(state => state.compliance?.onSave);
export const selectTouchedSteps = () => useSelector(state => state.compliance?.touchedSteps);
export const selectComplianceValidation = () => useSelector(state => state.compliance?.validation);
export const selectComplianceSubmitted = () => useSelector(state => state.compliance?.submitted);
export const selectCompanyMembers = () => useSelector(state => state.compliance?.members);
