import fetchResource from '@src/js/api/fetch-resource';

export function getOnboardingData(processHash) {
  return fetchResource(`api/onboarding/process/${ processHash }`);
}

export function submitOnboarding(data) {
  return fetchResource('api/onboarding/submit', {
    method: 'POST',
    body: data,
  });
}

export function saveOnboarding(data, sendEmail) {
  return fetchResource('api/onboarding/save', {
    method: 'POST',
    body: {
      ...data,
      sendEmail,
    },
  });
}
