export const GET_PARTNER_BASIC_DATA_START = 'GET_PARTNER_BASIC_DATA_START';
export const GET_PARTNER_BASIC_DATA_ERROR = 'GET_PARTNER_BASIC_DATA_ERROR';
export const GET_PARTNER_BASIC_DATA_SUCCESS = 'GET_PARTNER_BASIC_DATA_SUCCESS';

export function getPartnerBasicData(slug) {
  return {
    type: GET_PARTNER_BASIC_DATA_START,
    slug,
  };
}
