import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getLanguages } from '@src/js/helpers/helpers';
import { getProgrammesLounges } from '@app/store/programme/actions';
import LanguageSelect from '@src/js/layouts/global/panels/form/LanguageSelect';
import { Company } from '@src/js/constants/entities';
import Field from '@src/js/helpers/FinalFormFieldAdapter';
import SelectBox from '@src/js/components/global/inputs/Selectbox';
import DatePicker from '@src/js/components/global/inputs/Datepicker';
import { format } from 'date-fns';
import RichTextField from '@app/components/global/forms/RichTextField';

class CompanyFormProgramInfo extends Component {
  static propTypes = {
    companyType: PropTypes.number,
    companyFormOptions: PropTypes.object,
    selectedMemberSince: PropTypes.object,
    handleMemberSince: PropTypes.func,
    languageCode: PropTypes.string,
    mutators: PropTypes.object,
    isDisabledFunc: PropTypes.func,
    setLanguageCode: PropTypes.func,
    programmeLounges: PropTypes.object,
    dispatch: PropTypes.func,
    intl: PropTypes.object,
  };

  componentDidMount() {
    const { companyFormOptions } = this.props;
    this.handleProgramme();
    const programme = companyFormOptions?.results?.company?.basic?.programme;
    this.changeLounge(programme);
  }

  componentDidUpdate(prevProps) {
    const { programmeLounges } = this.props;
    if (prevProps.programmeLounges !== programmeLounges
      && programmeLounges.programmeData.length > 0) {
      this.handleLounge();
    }
  }

  handleProgramme = () => {
    const {
      companyFormOptions,
      mutators,
    } = this.props;

    if (companyFormOptions?.results?.canEditPartner === false) {
      mutators.setBasicProgramme(companyFormOptions?.results?.programmes[0]?.id);
    }
  };

  handleLounge = () => {
    const {
      companyFormOptions,
      mutators,
      programmeLounges,
    } = this.props;

    const filterLounges = programmeLounges?.programmeData?.filter(
      l => companyFormOptions?.results?.company?.basic?.lounges.some(e => e.id === l.id)
    );

    if (filterLounges) {
      mutators.setLounge(filterLounges.map(lounge => lounge.id));
    }
  }

  handleDateMemberSinceChange = (date) => {
    const { handleMemberSince, mutators } = this.props;

    mutators.setBasicDateMemberSince(date ? format(date, 'yyyy/MM/dd') : null);
    handleMemberSince(date);
  }

  changeProgramme = (programme) => {
    const { mutators } = this.props;
    mutators.setBasicProgramme(programme);
    this.changeLounge(programme);
    mutators.setLounge([]);
  }

  changeLounge = (programme) => {
    const { dispatch, companyFormOptions } = this.props;

    if (programme && companyFormOptions?.results?.canEditPartner) {
      dispatch(getProgrammesLounges(programme));
    }
  }

  render() {
    const {
      companyType,
      companyFormOptions,
      selectedMemberSince,
      languageCode,
      isDisabledFunc,
      setLanguageCode,
      programmeLounges,
      intl,
    } = this.props;

    const canEditPartner = companyFormOptions?.results?.canEditPartner;
    const isGrowthStartup = (companyType === Company.TYPE_GROWTH_STARTUP);
    const languages = getLanguages();
    const { programmeData } = programmeLounges;

    return (
      <div className='edit-company-page edit-company-page__section'>
        <div className='row'>
          <div className='col-xs'>
            <div className='edit-company-page__title edit-user-page-tab-content-title'>
              <FormattedMessage id='company_form.programInformation' />
            </div>
          </div>
        </div>

        <Field
          name='basic.dateMemberSince'
          fieldLabel='company_form.memberSince'
          selected={ selectedMemberSince }
          onChange={ this.handleDateMemberSinceChange }
          fieldRequired={ !isGrowthStartup }
          component={ DatePicker }
          fieldDisabled={ isDisabledFunc('basic.dateMemberSince') }
        />

        <Field
          name='basic.programme'
          fieldName='basic.programme'
          fieldType='text'
          fieldLabel='company_form.program'
          options={ companyFormOptions?.results?.programmes?.map(program => (
            { value: program.id, label: program.name }))
          }
          onChange={ this.changeProgramme }
          component={ SelectBox }
          fieldRequired
          fieldDisabled={ isDisabledFunc('basic.programme') || !canEditPartner }
        />

        { canEditPartner && (
          <>
            <div className='collaboration'>
              <FormattedMessage id='company_form.collaboration' />
              { ':' }
            </div>
            <Field
              name='basic.lounges'
              fieldName='basic.lounges'
              fieldType='text'
              multi={ true }
              fieldLabel={ intl.formatMessage({ id: 'company_form.lounges' }) }
              placeholder={ intl.formatMessage({ id: 'global_form.select.placeholder' }) }
              options={ programmeData.map(lounge => ({ value: lounge.id, label: lounge.name })) }
              component={ SelectBox }
              translate={ false }
              fieldDisabled={ isDisabledFunc('basic.lounges') }
            />
          </>
        ) }

        { isGrowthStartup && (
          <Field
            name='basic.startupStage'
            fieldName='basic.startupStage'
            fieldType='text'
            fieldLabel='company_form.startupStage'
            options={ companyFormOptions?.results?.startupStages?.map(startupStage => (
              { value: startupStage.id, label: startupStage.name }))
            }
            component={ SelectBox }
          />
        ) }

        <div id='about'>
          <LanguageSelect
            fieldLabel='company_form.about'
            lang={ languageCode }
            setLang={ (el) => setLanguageCode(el) }
            tooltip='company_form.tooltip'
          />
          {languages.map(lang => (
            <div key={ `${ lang }-about` }>
              <RichTextField
                name={ `details.${ lang }.about` }
                maxLength={ 5000 }
                hidden={ lang !== languageCode }
              />
            </div>
          ))}
        </div>

        <div id='strength'>
          <LanguageSelect
            fieldLabel='company_form.strengths'
            lang={ languageCode }
            setLang={ (el) => setLanguageCode(el) }
            tooltip='company_form.tooltip'
          />
          {languages.map(lang => (
            <div key={ `${ lang }-strength` }>
              <RichTextField
                name={ `details.${ lang }.strength` }
                maxLength={ 5000 }
                hidden={ lang !== languageCode }
              />
            </div>
          ))}
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyFormOptions: state.companyFormOptions.companyFormOptions,
  programmeLounges: state.programmeLounges,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

const connectWrapper = connect(mapStateToProps, mapDispatchToProps)(CompanyFormProgramInfo);

export default injectIntl(withRouter(connectWrapper));
