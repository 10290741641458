import styled from 'styled-components';
import {
  ThemeColors, FontWeight, FontSize, Breakpoints,
} from '@src/_v2/js/constants';

const getCalculatedTabStyle = props => {
  let style = {
    color: ThemeColors.mediumGrey,
  };

  if (props.selected) {
    style = {
      color: ThemeColors.black,
      fontWeight: FontWeight.bold,
      borderBottom: `3px solid ${ ThemeColors.blue }`,
    };
  }

  return style;
};

export const Tab = styled.li(props => ({
  ...getCalculatedTabStyle(props),
}));

export const Container = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: none;

  @media only screen and (max-width: ${ Breakpoints.SMALL_EXPANDED }px) {
    width: 100%;
  }

  ${ Tab } {
    flex: 0 0 auto;
    height: 4rem;
    margin: 0 3rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.04rem;
    font-size: ${ FontSize.caption };
    text-transform: uppercase;
    text-decoration: none;
    padding: 0;
    vertical-align: middle;
    position: relative;
    cursor: pointer;

    @media only screen and (max-width: ${ Breakpoints.SMALL_EXPANDED }px) {
      margin: 0 1.5rem;
    }
  }
`;
