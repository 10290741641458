import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@src/js/components/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import PeerItem from '@src/js/layouts/global/panels/messaging/components/PeerItem';
import { DebounceInput } from 'react-debounce-input';
import { clearPeers, getPeers } from '@src/js/actions/user/users';
import { RootState } from '@src/js/store';
import { Company, Partner } from '@src/js/constants/entities';
import { selectUserInfo } from '@app/store/global/selectors/initInfo';
import { Peer } from '@app/models/Chat';
import { NetworkingCompany } from '@app/models/Company';
import SuggestedCompanies from './SuggestedCompanies';

type Props = {
  threadId: number|null,
  members: string[],
  selectPeer: (peer: Peer) => void,
}

const PeersList = ({ members, selectPeer, threadId }: Props) => {
  const dispatch = useDispatch();

  const peers = useSelector<RootState, Peer[]>((state) => state.users?.peers);
  const hasMore = useSelector<RootState, boolean>((state) => state.users?.hasMorePeers);
  const companyPartners = useSelector<RootState, NetworkingCompany[]>(state => state.networking?.companyPartner);
  const loggedUser = selectUserInfo();
  const ref = useRef<HTMLInputElement|null>(null);

  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [loadedPeers, setLoadedPeers] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number|null>(null);

  const peersLength = peers?.length ?? 0;

  useEffect(() => () => {
    dispatch(clearPeers());
  }, []);

  useEffect(() => {
    dispatch(getPeers(threadId, page, searchString));
  }, [searchString, page]);

  useEffect(() => {
    if (peers) {
      setLoadedPeers([...loadedPeers, ...peers]);
    }
  }, [peers]);

  useEffect(() => {
    const company = companyPartners.filter(partner => partner.company.id === selectedCompany)[0];

    if (company) {
      setLoadedPeers(company.company.members);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setLoadedPeers([]);
    setSearchString(e.target?.value || '');
  };

  const rows = loadedPeers?.map(peer => (
    <PeerItem
      key={ peer.uuid }
      peer={ peer }
      isSelected={ members?.includes(peer.uuid) }
      selectPeer={ selectPeer }
      isMemberSelection
    />
  ));

  const shouldSeeSuggestedCompanies = !threadId && loggedUser.partnerType !== Partner.TYPE_BASIC
    && loggedUser.companyType !== Company.TYPE_INSTITUTIONAL_INVESTOR;

  return (
    <>
      <div className='search-peers'>
        <DebounceInput
          inputRef={ ref }
          debounceTimeout={ 500 }
          onChange={ handleSearchChange }
          placeholder='Search'
        />
      </div>
      { shouldSeeSuggestedCompanies && (
      <>
        <div className='suggested-company'>
          <div className='suggested-company-list'>
            <SuggestedCompanies
              companies={ companyPartners }
              selectedCompany={ selectedCompany }
              onSelectCompany={ setSelectedCompany }
            />
          </div>
        </div>
        <div className='new-message-title'>
          <FormattedMessage id='messages.users' />
        </div>
      </>
      ) }
      <div className='peers-list-container-members' id='peersContainer'>
        <InfiniteScroll
          dataLength={ peersLength }
          next={ () => setPage(page + 1) }
          hasMore={ hasMore && !selectedCompany }
          loader={ <Loader position='relative' background='transparent' /> }
          scrollableTarget='peersContainer'
        >
          { rows }
        </InfiniteScroll>
        { peersLength === 0 && (
        <div className='no-data'>
          <FormattedMessage id='noResults' />
        </div>
        ) }
      </div>
    </>
  );
};

export default PeersList;
