import {
  SET_SELECTED_ADMIN_TAB_SUCCESS,
  GET_ADMIN_OPPORTUNITIES_START,
  GET_ADMIN_OPPORTUNITIES_ERROR,
  GET_ADMIN_OPPORTUNITIES_SUCCESS,
  GET_ADMIN_REQUESTS_START,
  GET_ADMIN_REQUESTS_ERROR,
  GET_ADMIN_REQUESTS_SUCCESS,
  SET_PRODUCTS_FILTERS_SUCCESS,
  GET_ADMIN_TYPES_START,
  GET_ADMIN_TYPES_ERROR,
  GET_ADMIN_TYPES_SUCCESS,
  GET_ADMIN_STATISTICS_START,
  GET_ADMIN_STATISTICS_ERROR,
  GET_ADMIN_STATISTICS_SUCCESS,
  ADMIN_PRODUCT_STATUS_UPDATE_START,
  ADMIN_PRODUCT_STATUS_UPDATE_ERROR,
  ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS,
  SET_REQUESTS_FILTERS_SUCCESS, ADMIN_REQUEST_STATUS_UPDATE_START, ADMIN_REQUEST_STATUS_UPDATE_ERROR,
} from '@src/js/marketplace/actions/admin/marketplaceAdmin';

const initialState = {
  selectedTab: 0,
  adminOpportunitiesLoading: false,
  adminOpportunitiesError: null,
  adminOpportunities: null,
  adminRequestsLoading: false,
  adminRequestsError: null,
  adminRequests: null,
  adminProductsFilters: {
    page: 0,
  },
  adminRequestsFilters: {
    page: 0,
  },
  adminTypesLoading: false,
  adminTypesError: null,
  adminTypes: null,
  adminStatisticsLoading: false,
  adminStatisticsError: null,
  adminStatistics: null,
  updateProductLoading: false,
  updateProductError: null,
  updateProduct: null,
  updateRequestLoading: false,
  updateRequestError: null,
  updateRequest: null,
};

const actionsMap = {
  [SET_SELECTED_ADMIN_TAB_SUCCESS]: (state, action) => ({
    ...state,
    selectedTab: action.index,
  }),
  [SET_PRODUCTS_FILTERS_SUCCESS]: (state, action) => ({
    ...state,
    adminProductsFilters: action.filters,
  }),
  [SET_REQUESTS_FILTERS_SUCCESS]: (state, action) => ({
    ...state,
    adminRequestsFilters: action.filters,
  }),
  [GET_ADMIN_OPPORTUNITIES_START]: (state) => ({
    ...state,
    adminOpportunitiesLoading: true,
    adminOpportunitiesError: null,
    adminOpportunities: null,
  }),
  [GET_ADMIN_OPPORTUNITIES_ERROR]: (state, action) => ({
    ...state,
    adminOpportunitiesLoading: false,
    adminOpportunitiesError: action.error,
    adminOpportunities: null,
  }),
  [GET_ADMIN_OPPORTUNITIES_SUCCESS]: (state, action) => ({
    ...state,
    adminOpportunitiesLoading: false,
    adminOpportunitiesError: null,
    adminOpportunities: action.data,
  }),
  [GET_ADMIN_REQUESTS_START]: (state) => ({
    ...state,
    adminRequestsLoading: true,
    adminRequestsError: null,
    adminRequests: null,
  }),
  [GET_ADMIN_REQUESTS_ERROR]: (state, action) => ({
    ...state,
    adminRequestsLoading: false,
    adminRequestsError: action.error,
    adminRequests: null,
  }),
  [GET_ADMIN_REQUESTS_SUCCESS]: (state, action) => ({
    ...state,
    adminRequestsLoading: false,
    adminRequestsError: null,
    adminRequests: action.data,
  }),
  [GET_ADMIN_TYPES_START]: (state) => ({
    ...state,
    adminTypesLoading: true,
    adminTypesLoadingError: null,
    adminTypes: null,
  }),
  [GET_ADMIN_TYPES_ERROR]: (state, action) => ({
    ...state,
    adminTypesLoading: false,
    adminTypesLoadingError: action.error,
    adminTypes: null,
  }),
  [GET_ADMIN_TYPES_SUCCESS]: (state, action) => ({
    ...state,
    adminTypesLoading: false,
    adminTypesLoadingError: null,
    adminTypes: action.data,
  }),
  [GET_ADMIN_STATISTICS_START]: (state) => ({
    ...state,
    adminStatisticsLoading: true,
    adminStatisticsError: null,
    adminStatistics: null,
  }),
  [GET_ADMIN_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    adminStatisticsLoading: false,
    adminStatisticsError: action.error,
    adminStatistics: null,
  }),
  [GET_ADMIN_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    adminStatisticsLoading: false,
    adminStatisticsError: null,
    adminStatistics: action.data,
  }),
  [ADMIN_PRODUCT_STATUS_UPDATE_START]: (state) => ({
    ...state,
    updateProductLoading: true,
    updateProductError: null,
    updateProduct: null,
  }),
  [ADMIN_PRODUCT_STATUS_UPDATE_ERROR]: (state, action) => ({
    ...state,
    updateProductLoading: false,
    updateProductError: action.error,
    updateProduct: null,
  }),
  [ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    updateProductLoading: false,
    updateProductError: null,
    updateProduct: action.data,
  }),
  [ADMIN_REQUEST_STATUS_UPDATE_START]: (state) => ({
    ...state,
    updateRequestLoading: true,
    updateRequestError: null,
    updateRequest: null,
  }),
  [ADMIN_REQUEST_STATUS_UPDATE_ERROR]: (state, action) => ({
    ...state,
    updateRequestLoading: false,
    updateRequestError: action.error,
    updateRequest: null,
  }),
  [ADMIN_PRODUCT_STATUS_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    updateRequestLoading: false,
    updateRequestError: null,
    updateRequest: action.data,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
