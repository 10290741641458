import { BaseCompanyPartner } from '@app/models/BaseCompanyPartner';
import {
  COMPANY_GET_VIEWS_START,
  COMPANY_GET_VIEWS_ERROR,
  COMPANY_GET_VIEWS_SUCCESS,
} from '@src/js/actions/company/companyViews';
import produce from 'immer';
import { Action } from 'redux';

export type CompanyViewsState = {
  loading: boolean
  error?: unknown
  companies?: BaseCompanyPartner[];
}

interface ActionPayload extends Action<string> {
  status: string,
  error?: { response: string },
  data?: BaseCompanyPartner[],
}

const initialState = {
  loading: false,
  error: null,
} as CompanyViewsState;

export default function reducer(state = initialState, action: ActionPayload) {
  return produce(state, (draftState) => {
    const draft = draftState;
    switch (action.type) {
      case COMPANY_GET_VIEWS_START:
        draft.loading = true;
        draft.error = null;
        break;
      case COMPANY_GET_VIEWS_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case COMPANY_GET_VIEWS_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.companies = action.data;
        break;
      default:
        break;
    }
  });
}
