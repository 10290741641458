import { localeFormatNumber } from '@app/components/global/forms/utils';
import { financialsKeys } from '@app/store/onboarding/constants';
import {
  ADMIN_GET_COMPANY_START,
  ADMIN_GET_COMPANY_ERROR,
  ADMIN_GET_COMPANY_SUCCESS,
  ADMIN_CREATE_COMPANY_START,
  ADMIN_CREATE_COMPANY_ERROR,
  ADMIN_CREATE_COMPANY_SUCCESS,
  ADMIN_EDIT_COMPANY_START,
  ADMIN_EDIT_COMPANY_ERROR,
  ADMIN_EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FINANCIALS_START,
  EDIT_COMPANY_FINANCIALS_ERROR,
  EDIT_COMPANY_FINANCIALS_SUCCESS,
  UPDATE_COMPANY_FINANCIALS_START,
  UPDATE_COMPANY_FINANCIALS_ERROR,
  UPDATE_COMPANY_FINANCIALS_SUCCESS,
  ADMIN_DISABLE_COMPANY_START,
  ADMIN_DISABLE_COMPANY_ERROR,
  ADMIN_DISABLE_COMPANY_SUCCESS,
} from '@src/js/actions/company/adminCompany';

export const formatFinancials = (financialsData, language) => {
  const data = { ...financialsData };
  const financials = data.companyFinancials.map(financial => {
    const formattedFinancial = { ...financial };
    financialsKeys.forEach((key) => {
      formattedFinancial[key] = localeFormatNumber(financial[key], language);
    });

    return formattedFinancial;
  });
  data.companyFinancials = financials;

  return data;
};

const initialState = {
  loading: false,
  error: null,
  company: null,
  companyFinancialsData: null,
  companyFinancialsLoading: false,
  companyFinancialsError: null,
};

const actionsMap = {
  [ADMIN_GET_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_GET_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_GET_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    companyData: action.data,
  }),
  [ADMIN_CREATE_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_CREATE_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_CREATE_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    company: action.data,
  }),
  [ADMIN_EDIT_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_EDIT_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_EDIT_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    company: action.data,
  }),
  [ADMIN_DISABLE_COMPANY_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [ADMIN_DISABLE_COMPANY_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [ADMIN_DISABLE_COMPANY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    company: action.data,
  }),
  [UPDATE_COMPANY_FINANCIALS_START]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [UPDATE_COMPANY_FINANCIALS_ERROR]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.response,
  }),
  [UPDATE_COMPANY_FINANCIALS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
  [EDIT_COMPANY_FINANCIALS_START]: (state) => ({
    ...state,
    companyFinancialsLoading: true,
    companyFinancialsError: null,
  }),
  [EDIT_COMPANY_FINANCIALS_ERROR]: (state, action) => ({
    ...state,
    companyFinancialsLoading: false,
    companyFinancialsError: action.error.response,
  }),
  [EDIT_COMPANY_FINANCIALS_SUCCESS]: (state, action) => ({
    ...state,
    companyFinancialsLoading: false,
    companyFinancialsData: formatFinancials(action.data, action.lang),
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
