import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SafetyModal from '@src/js/views/admin/SafetyModal';
import {
  getProgrammesLounges, createLounge, updateLounge, deleteLounge,
} from '@app/store/programme/actions';
import { selectLoungesData } from '@app/store/programme/selectors';
import { Loader, Button } from '@src/js/components/global';

const EditLoungesModal = ({ programme }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [newLounge, setNewLounge] = useState('');
  const [editLounge, setEditLounge] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const { programmeData, loading } = selectLoungesData();

  useEffect(() => {
    dispatch(getProgrammesLounges(programme.id));
  }, []);

  const addNewLounge = () => {
    if (!newLounge) return;
    dispatch(createLounge({ name: newLounge, programme: programme.id }));
    setNewLounge('');
  };

  const setEditMode = (id) => {
    if (Object.keys(editLounge).length > 0) {
      setEditLounge({});
    } else {
      const lounge = programmeData.find(item => item.id === id);
      setEditLounge({ ...lounge, ...{ programme: programme.id } });
    }
  };

  const saveEditLounge = () => {
    dispatch(updateLounge(editLounge));
    setEditLounge({});
  };

  const deleteConfirmetion = (id) => {
    setConfirmDelete(true);
    setDeletingId(id);
  };

  const dispatchDelete = () => {
    dispatch(deleteLounge(deletingId));
    setConfirmDelete(false);
    setDeletingId(null);
  };

  return (
    <>
      {loading && <Loader />}
      <ModalBody className='m-y-4'>
        <div className='eop-text-input'>
          <div className='edit-lounges-modal__input-wrapper'>
            <div className='eop-text-input__input'>
              <input
                name='programme.addNewLounge'
                className='eop-text-input__input-item'
                type='text'
                placeholder={ intl.formatMessage({ id: 'programme.addNewLounge' }) }
                value={ newLounge }
                onChange={ e => setNewLounge(e.target.value) }
                onKeyDown={ e => (e.keyCode === 13 ? addNewLounge() : null) }
              />
            </div>
            <Button
              buttonText='company_form.addNew'
              onClickFunc={ addNewLounge }
              additionalClass='w-25'
            />
          </div>
        </div>

        <div className='edit-lounges-modal'>
          { programmeData && programmeData.map(lounge => (
            <div className='edit-lounges-modal__lounge-row' key={ lounge.id }>
              <div className='edit-lounges-modal__primary-infos'>
                { !(lounge.id === editLounge.id) ? (<div className='edit-lounges-modal__name'>{lounge.name}</div>)
                  : (
                    <input
                      name='programme.editLounge'
                      className='fs-node__input'
                      type='text'
                      value={ editLounge.name }
                      onChange={ e => setEditLounge({ ...editLounge, ...{ name: e.target.value } }) }
                      onKeyDown={ e => (e.keyCode === 13 ? saveEditLounge() : null) }
                    />
                  )}
                <span
                  name={ `programme.edit-${ lounge.id }` }
                  className='icon-small-Edit m-l-1'
                  role='presentation'
                  onClick={ () => setEditMode(lounge.id) }
                />
              </div>
              <span
                name={ `programme.delete-${ lounge.id }` }
                className='icon-TrashEmpty'
                role='presentation'
                onClick={ () => deleteConfirmetion(lounge.id) }
              />
            </div>
          )) }

        </div>
      </ModalBody>
      <SafetyModal
        isOpen={ confirmDelete }
        cancelFunc={ () => setConfirmDelete(false) }
        yesFunc={ dispatchDelete }
        message='programme.confirmation'
      />
    </>
  );
};

EditLoungesModal.propTypes = {
  programme: PropTypes.object,
};

export default EditLoungesModal;
