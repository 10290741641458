import { Action } from 'redux';

export const COMPANY_GET_VIEWS_START = 'COMPANY_GET_VIEWS_START';
export const COMPANY_GET_VIEWS_ERROR = 'COMPANY_GET_VIEWS_ERROR';
export const COMPANY_GET_VIEWS_SUCCESS = 'COMPANY_GET_VIEWS_SUCCESS';

export interface GetCompanyViewsAction extends Action {
  slug: string,
  entityType: string,
  limit?: number
}
export function getCompanyViews(slug: string, entityType: string, limit?: number): GetCompanyViewsAction {
  return {
    type: COMPANY_GET_VIEWS_START,
    slug,
    entityType,
    limit,
  };
}
